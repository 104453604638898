import type { DataNameRoot, PlotXyComputedData, SeedHisto, SeedHistoData } from './xy_plotTypes'

import invariant from 'invariant'
import { list } from 'radash'
import { truncGaussian } from '../sharedFunctions/mathScry'
import { logTime } from '../sharedFunctions/timer'
import { lastVal } from '../sharedFunctions/utils'
import { plotLayoutConsts } from '../viewPlotXY/plotXyLayoutConsts'
import {
  calcKernelWidthAndSmoothingIndex,
  getLinearCoefficients
} from './plotUtils'
import { getSeedHistogram } from './seedHistogram'



type ConvolutionParams = {
  isPDFwithUnitProbability: boolean
  isFreqWithDataTypeString: boolean
  useFreqWeighting: boolean
  useSmoothToLinFit: boolean
  conserveAbsArea: boolean
  kernelWidthInNumBins: number
  seriesLineSmoothing: number
  smoothingIndex: number
  clampAntiSymBvaluesNoLessThanZero: boolean
  clampAntiSymBvaluesNoMoreThanZero: boolean
}

export const getDefaultConvolutionParams = (): ConvolutionParams => {
  const obj: ConvolutionParams = {
    isPDFwithUnitProbability: false,
    isFreqWithDataTypeString: false,
    useFreqWeighting: false,
    useSmoothToLinFit: false,
    conserveAbsArea: false,
    kernelWidthInNumBins: 0,
    seriesLineSmoothing: 1,
    smoothingIndex: 0,
    clampAntiSymBvaluesNoLessThanZero: false,
    clampAntiSymBvaluesNoMoreThanZero: false,
  }
  return Object.seal(obj)
}




export const getSmoothedDataBySeries = (plt: PlotXyComputedData,
  dataNameRoot: DataNameRoot, isSmoothed: boolean): number[][] => {

  const seedHisto = getSeedHistogram(plt, false)
  const numSeries = plt.seriesOrder.length
  const smoothedData = []  // the final returned array
  const isPDF = (dataNameRoot === 'freq')

  // convolution parameters:
  var cp = getDefaultConvolutionParams() // sealed obj (above)
  if (isPDF && plt.basisA.internalDataType === 'string') {
    cp.isPDFwithUnitProbability = false
    cp.isFreqWithDataTypeString = true
    cp.useFreqWeighting = false
    cp.conserveAbsArea = true
    cp.useSmoothToLinFit = false
    cp.clampAntiSymBvaluesNoLessThanZero = true
  }
  else if (isPDF && plt.basisA.internalDataType === 'number') {
    cp.isPDFwithUnitProbability = true
    cp.isFreqWithDataTypeString = false
    cp.useFreqWeighting = false
    cp.conserveAbsArea = true
    // min/max filtered B has no meaning for histograms
    // And no need to clamp AntiSym points for histograms because
    // they are hardwired to zero by the isPDF attribute.
    cp.clampAntiSymBvaluesNoLessThanZero = true
    cp.clampAntiSymBvaluesNoMoreThanZero = false
  }
  else {   // internalDataType === 'number' or a string mapped to integer index:
    cp.isPDFwithUnitProbability = false
    cp.isFreqWithDataTypeString = false
    cp.useFreqWeighting = (dataNameRoot === 'mean' ||
      dataNameRoot === 'variance')   // Do not use freq weights for sum,min,max
    cp.conserveAbsArea = false;
    cp.useSmoothToLinFit = true;

    // min/max filtered B has no meaning for histograms
    // And no need to clamp AntiSym points for histograms because
    // they are hardwired to zero by the isPDF attribute.
    // ({min:minFilteredB,max:maxFilteredB} = plt.basisB.rangeFilteredAllSeries)
    //cp.clampAntiSymBvaluesNoLessThanZero = (minFilteredB >= 0)
    //cp.clampAntiSymBvaluesNoMoreThanZero = (maxFilteredB <= 0)
  }

  for (var sKey = 0; sKey < numSeries; sKey++) {
    if (seedHisto.data[sKey].isEmptySeedHistoFreqBinning) { continue }
    // Need to convert the user's seriesLineSmoothing parameter into
    // absolute kernel size; Measured as both -
    //    seedHisto.kernelWidthInNumBins  - convolution parameter
    //    smoothingIndex    - Is this 0, 1st, 2nd, 3rd, ...   smoothing option?

    // Next line is a value from 1 to 100, set by the smoothing slider,
    // which determines the degree of smoothing.
    cp.seriesLineSmoothing = seedHisto.data[sKey].seriesLineSmoothing
    // We use the smoothing algorithms (this code) for unsmoothed data as well!!
    // For unsmoothed plottedValueB, we use the first (no smoothing) lineSmoothing value.
    // We could more easily just plot the direct average, sum, min, ... for each
    // bin.  However, plotPts of the raw binned statistics are only valid for those
    // bins that may have values.  For plotting 'area' style, we also need the
    // interpolated pts between real values.  Since the smoothing algorithm also (must)
    // include the interpolated pts, it is easier to get the smoothed values, with
    // seriesLineSmoothing set to 1 (no smoothing), because these are the same as the
    // originnally binned statistics, PLUS interpolated points in-between.
    if (!isSmoothed) { cp.seriesLineSmoothing = 1 }
    const { kernelWidthInNumBins, smoothingIndex } =
      calcKernelWidthAndSmoothingIndex(seedHisto, cp.seriesLineSmoothing)
    cp.kernelWidthInNumBins = kernelWidthInNumBins
    cp.smoothingIndex = smoothingIndex
    const dataPath = `data[${sKey}].${dataNameRoot}.smoothed[${smoothingIndex}]`
    smoothedData[sKey] = seedHisto.data[sKey][dataNameRoot].smoothed[smoothingIndex]
    // Does this smoothed curve already exist?
    if (!smoothedData[sKey]) {
      // Create smoothed data
      createSmoothed(seedHisto, dataNameRoot, cp, sKey)
      smoothedData[sKey] = seedHisto.data[sKey][dataNameRoot].smoothed[smoothingIndex]
      if (seedHisto.DEBUG) {
        console.log(`  Calc  ${dataPath}`)
        logTime('id_PlotXyComputedData', `Calc ${dataPath}`)
      }
    }
    else { // reusing data!
      if (seedHisto.DEBUG) { console.log(`  Reuse ${dataPath}`) }
    }
  }
  return smoothedData
}



const createSmoothed = (seedHisto: SeedHisto, dataNameRoot: DataNameRoot,
  cp: ConvolutionParams, sKey: number): void => {


  // CASE: no data to smooth -- backbone array remains set to null
  if (seedHisto.data[sKey].isEmptySeedHistoFreqBinning) { return }

  const { Aindex_LeftPlotEdge, Aindex_RightPlotEdge, backboneNumSets,
    binWidthOptions, arrayBinsPerPDFbinWidth } = seedHisto
  const isPDF = cp.isPDFwithUnitProbability

  createBackboneValues(seedHisto, dataNameRoot, sKey, cp) // create or re-use
  logTime('id_PlotXyComputedData', `Calc  data[${sKey}].${dataNameRoot}.backbones`)
  const backboneSetIndex = Math.min(cp.smoothingIndex, backboneNumSets - 1)
  const backbone = seedHisto.data[sKey][dataNameRoot].backbone[backboneSetIndex]
  const weights = seedHisto.data[sKey].stats_numSamplesPerBinInterpolated
  const newSmoothedData = Array(seedHisto.seedHistoLength).fill(0)

  const { Aindex_FirstCount, Aindex_FinalCount,
    Aindex_First2Count, Aindex_Final2Count,
    Aindex_FirstPDFzero, Aindex_FinalPDFzero } = seedHisto.data[sKey]

  // This is the range of convolved indexed points:
  let x1_index = Aindex_FirstCount  // assumption
  let x2_index = Aindex_FinalCount  // assumption
  // Exceptions:
  if (dataNameRoot === 'variance') {
    x1_index = Aindex_First2Count
    x2_index = Aindex_Final2Count
  }
  if (dataNameRoot === 'freq' && seedHisto.isBasisAdataTypeNumber) {
    x1_index = Aindex_FirstPDFzero
    x2_index = Aindex_FinalPDFzero
  }
  if (seedHisto.isBasisAdataTypeString) {
    x1_index = Aindex_LeftPlotEdge
    x2_index = Aindex_RightPlotEdge
  }


  // Next line used to be '/2'  Since the wides tap is current 3 tickWidths
  // (check in plotLayoutConsts file), and the extension of the seedHisto axis
  // is 3 extra tick marks to left/right of the plot, then one can decide just
  // 'how far' we should look to the far left/right when doing convolution.
  // Only constraint is the maximumLegalTapRange must fall within the range
  // of the backbone data (x1_index to x2_index)
  const maximumLegalTapRange = Math.max(Math.floor((x2_index - x1_index) / 1.1), 1)
  const thisTapRange = Math.min(cp.kernelWidthInNumBins, maximumLegalTapRange)
  const taps = getKernalTapWeights(thisTapRange, 1)
  const numTaps = taps.length
  const tapIndexOffset = (numTaps - 1) / 2

  // Rules for setting the antiSymmetryPts
  var antiSymmetryPts
  if (isPDF) {
    // We lock the edge of the fitted curve to the bottom axis. (zero)
    var y1_val = 0
    var y2_val = 0
    antiSymmetryPts = {
      x1_index, x2_index, y1_val, y2_val,
      slope1_perBin: 1, slope2_perBin: 1
    }
  } else if (cp.isFreqWithDataTypeString) {
    // Has a backbone like freq plots. (above code)
    // Has anti-symmetry points like scatter plots.
    antiSymmetryPts = getAntiSymPts_Yintercepts(seedHisto.data[sKey], dataNameRoot,
      cp, x1_index, x2_index, taps, backbone);
    ({ y1_val, y2_val } = antiSymmetryPts)
  } else {
    // Smoothing scattered points!  Determine antiSymmetry points
    // which are a function of the smoothing kernel width.
    // These points are unique to each smoothed line.
    antiSymmetryPts = getAntiSymPts_Yintercepts(seedHisto.data[sKey], dataNameRoot,
      cp, x1_index, x2_index, taps, backbone);
    ({ y1_val, y2_val } = antiSymmetryPts)
  }

  // When the filtered B values are all positive or all negative,
  // add an additional constraint to the antiSymmetryPts.
  // The entire convolution, including the calculated anti-symmetry points
  // are AFTER we have removed the linFitBinnedVal term.
  // Hence not less than (or greater than) zero will always be true ~ half
  // the time, since ~half the data is >0 and half <0. (above/below linFit line).
  // Either we get rid of this option, or we have to adjust the '0' min/max
  // value to ignore the linFit term.  For example, if the linFit at leftAntisymPt
  // has a value of '10', then all antisym pts must be > -10.
  const linFit_atX1_index = seedHisto.data[sKey][dataNameRoot].linFitBinnedVal?.[x1_index]
  const linFit_atX2_index = seedHisto.data[sKey][dataNameRoot].linFitBinnedVal?.[x2_index]
  if (cp.clampAntiSymBvaluesNoLessThanZero) {
    y1_val = Math.max(-linFit_atX1_index, y1_val)
    y2_val = Math.max(-linFit_atX2_index, y2_val)
  }
  if (cp.clampAntiSymBvaluesNoMoreThanZero) {
    y1_val = Math.min(-linFit_atX1_index, y1_val)
    y2_val = Math.min(-linFit_atX2_index, y2_val)
  }

  // We save the antisymmetry point -- in DEBUG mode, the antisymmetry
  // points are rendered as annotations in the plot.  This is where we keep the info.
  seedHisto.data[sKey][dataNameRoot].antiSymmetryPts[cp.smoothingIndex] = antiSymmetryPts

  const useWeightingOfOne = !cp.useFreqWeighting
  const twoX1 = 2 * x1_index
  const twoX2 = 2 * x2_index
  const twoY1 = 2 * y1_val
  const twoY2 = 2 * y2_val
  const getMirroredBvalue = (i: number): number => {
    if (i < x1_index) { return twoY1 - backbone[twoX1 - i] }
    else if (i === x1_index) { return y1_val }
    else if (i < x2_index) { return backbone[i] }
    else if (i === x2_index) { return y2_val }
    else { return twoY2 - backbone[twoX2 - i] }
  }
  const getMirroredWeight = (i: number): number => {
    if (useWeightingOfOne) { return 1 }
    else if (i < x1_index) { return weights[twoX1 - i] }
    else if (i <= x2_index) { return weights[i] }
    else { return weights[twoX2 - i] }
  }

  // Finally, the convolution double loop. (Over each outputPt; Over each tap for each outputPt;)
  // And the code to create the array:  [PlotXYpoint]
  var absAreaUnderTheBackbone = 0
  var absAreaUnderTheCurve = 0

  // For smoothed histograms:
  // Include a potential extended domain for histograms
  const worseCaseExtendedDomain = cp.isPDFwithUnitProbability
    ? binWidthOptions[0] * arrayBinsPerPDFbinWidth
    : 0
  const indexLoopLowerLimit = Math.max(x1_index, Aindex_LeftPlotEdge - worseCaseExtendedDomain)
  const indexLoopUpperLimit = Math.min(x2_index, Aindex_RightPlotEdge + worseCaseExtendedDomain)
  for (let outputPtIndex = indexLoopLowerLimit; outputPtIndex <= indexLoopUpperLimit; outputPtIndex++) {

    var summedCount = 0
    var summedB = 0
    var b = 0
    var count = 0
    var alignedOutputPtIndex = outputPtIndex - tapIndexOffset
    for (let tapIndex = 0; tapIndex < numTaps; tapIndex++) {
      // the outputPtIndex is the current 'center Pt' of the convolution kernal
      // the outputPtIndex is array index of the saved convolved value.
      // The alignedOutputPtIndex is the index left/right of the current tap, aligned with current tapValue.
      if (isPDF) {   // distributions
        b = getMirroredBvalue(alignedOutputPtIndex)
        summedB += b * taps[tapIndex]
        summedCount += taps[tapIndex]
      } else {
        // 2Col or 3Col plot family;  Data always from seedHisto
        b = getMirroredBvalue(alignedOutputPtIndex)
        count = getMirroredWeight(alignedOutputPtIndex)
        summedCount += count * taps[tapIndex]
        summedB += count * taps[tapIndex] * b
        if (isNaN(summedB)) {
          b = getMirroredBvalue(alignedOutputPtIndex)
        }
      }
      alignedOutputPtIndex++
      if (process.env.NODE_ENV === 'development' && (isNaN(summedB) || isNaN(summedCount))) {
        invariant(false, 'Summed values or count for convolution is NaN')
      }
    }

    // summedB may have some computational noise in cases where
    // the convolution is left/right symmetric and the expected
    // value should be === Zero
    // Set very small values to zero. Realistic values should never
    // be < 1 / numberSamples
    if (Math.abs(summedB) < 1e-10) { summedB = 0 }
    var backboneBvalue = getMirroredBvalue(outputPtIndex)
    absAreaUnderTheBackbone += Math.abs(backboneBvalue * summedCount)
    absAreaUnderTheCurve += Math.abs(summedB)
    newSmoothedData[outputPtIndex] = (isPDF)
      ? summedB
      : (summedCount === 0) ? 0 : summedB / summedCount
  }


  // If the linear term was removed prior to convolution,add that
  // term back.  Saved smoothed data arrays are what we see 'as plotted values'.
  if (cp.useSmoothToLinFit) {
    const linFitBinnedVal = seedHisto.data[sKey][dataNameRoot].linFitBinnedVal
    for (let jj = Aindex_FirstCount; jj <= Aindex_FinalCount; jj++) {
      newSmoothedData[jj] += linFitBinnedVal[jj]
    }
  }

  //Post convolution scaling of the B values.
  if (isPDF || cp.conserveAbsArea) {
    let scale = (absAreaUnderTheCurve > 0)
      ? absAreaUnderTheBackbone / absAreaUnderTheCurve
      : 1
    for (let kk = 0; kk < seedHisto.seedHistoLength; kk++) { newSmoothedData[kk] *= scale }
    //console.log('area normalization', scale)
  }

  seedHisto.data[sKey][dataNameRoot].smoothed[cp.smoothingIndex] = newSmoothedData
  seedHisto.data[sKey][dataNameRoot].antiSymmetryPts[cp.smoothingIndex] = antiSymmetryPts
  return
}




const getAntiSymPts_Yintercepts = (seriesData: SeedHistoData, dataNameRoot: string, cp: ConvolutionParams,
  x1_index: number, x2_index: number, tapWeights: number[], backbone: number[]) => {
  // Modifies the antiSymmetry points (in place.)
  //
  //    - All smoothed curves will always pass through the antisymmetry points.
  //        These points are the left/right 'anchors' of a smoothed line.
  //        Also, the left/right extent of the rendered line.
  //        These left/right anchor points are calculated 'by series'.
  //    - Currently, we fix the x_values on the first/last desired point
  //        in the fitted curved.  For scatter type plots this will be the
  //        first/last real (freq>0) values for each series.
  //        For histograms, this the first 'zero' value to the left/right
  //        of the first/last real (freq>0) value for each series.
  //    - This function modifies ONLY the y_values.  We assume the x_values
  //        are exactly at the left/right edges of the curve and hence, do not
  //        move them.  Other options would be to place the x values halfway
  //        between 'grid' points, or halfway 'towards' the first mirrored
  //        point.  But this only adds complexity and I see no compelling
  //        reason at this time.
  //    - We use linear regression of data pts 'near' the left/right edges
  //         to calculate the antiSymmetry points.
  //    - 'Nearby' is defined as within 1 width of the smoothing kernel,
  //        or in software: the half/width of the tapWeights array.
  //    - 'Nearby' and antisymmetry pts = function( amount of smoothing )
  //    - In the limit of:
  //           Zero smoothing - 'Nearby' is the first or last point,
  //              The antiSymmetry pts are first/last filtered data pts.
  //              The non-smoothed curve (the backbone) ends at these points.
  //       Infinite smoothing - 'Nearby' is ALL filtered data.
  //              The antiSymmetry pts fall on the line of linear regression.
  //              The smoothed curve is the line of linear regression.
  //              Software can't support 'infinite' kernel widths, but one can
  //              observe how a smoothed curve approaches a linear regression.

  //    - 'Nearby' linear fits use the backbone data(efficient), rather than the
  //      raw filtered data (arbitrary size).
  //    - In other words, the backbone (ignoring the interpolated data) has
  //      identical slope/intercept as the filtered raw data.
  //      (Excepting the fact that A values in the backbone are binned, and not
  //      exactly precise.  Still the C0/C1 calculated from the backbone is
  //      ~3-4 digits of accuracy to the C0/C1 from the raw filtered data.
  //      Plenty close enough. One can verify a backbone is correct by comparing
  //      the match of C0/C1 for filtered rows to C0/C1 of the seedHisto backbone.


  // The algorithm has flaws when:
  // using freq.binnedVal => Chases the slope/intercept of last two isolated pts.
  // using stats_numSamplesPerBinInterpolated (interpolated version of above) => weighted
  //             predominately by the empty interpolated values between large gaps in data.
  // Both approaches have strengths and weakness.
  // Can't decide, but something inbetween looks better behaved than either.
  // Hence my 'fudgedWeights' expression below. (10 parts isoPts; 1 part interpolated pts.)
  //   1 part interpolated term tends to accumulate in areas of sparse real points and MAY
  //   become the dominate approach.
  const fudgedWeights = seriesData.freq.binnedVal.map((b, i) => (b + 0.1 * seriesData.stats_numSamplesPerBinInterpolated[i]))

  const weights = (cp.useFreqWeighting)
    ? fudgedWeights
    //? seriesData.stats_numSamplesPerBinInterpolated
    //? seriesData.freq.binnedVal
    : Array(seriesData.freq.binnedVal.length).fill(1)
  const halfTaps = tapWeights.slice((tapWeights.length - 1) / 2)
  const y1 = getYfitFromBackboneFitNearEdge(weights,
    x1_index, x2_index, halfTaps, backbone, 'leftEdge')
  const y2 = getYfitFromBackboneFitNearEdge(weights,
    x1_index, x2_index, halfTaps, backbone, 'rightEdge')
  if (isNaN(y1.yIntercept)) {
    invariant(false, `y1 y-Intercept in antisymmetry point isNAN - ${y1.yIntercept}`)
  }
  if (isNaN(y2.yIntercept)) {
    invariant(false, `y2 y-Intercept in antisymmetry point isNAN - ${y2.yIntercept}`)
  }
  return {
    x1_index, x2_index,
    y1_val: y1.yIntercept,
    y2_val: y2.yIntercept,
    slope1_perBin: y1.ySlope,
    slope2_perBin: y2.ySlope
  }
}

// Unitless 'A' least squares.   Works best!
// Uses the seedHisto index of x1 (or x2) as the orgin (A=0)
// And A stepSize for each histo bin is '1'.
// Since we only need the intercept, we don't care about the slope term at this time.
// Another way to say the same thing:
//   This algorithm uses the unitless binning system of the seedHistogram.
const getYfitFromBackboneFitNearEdge = (weights: number[], x1_index: number, x2_index: number,
  halfTaps: number[], backbone: number[], leftOrRight: 'leftEdge' | 'rightEdge')
  : { yIntercept: number, ySlope: number } => {

  // This function ONLY used for scatter line smoothing (isPDF false)
  // So we use the backboneSets data.
  //var thisCount = seriesData.freq.binnedVal  // Count ignoring interpolated points

  var indexStart = (leftOrRight === 'leftEdge') ? x1_index : x2_index
  var sA = 0, sB = 0, sAA = 0, sAB = 0, numSamples = 0, totalWeight = 0, sBB = 1
  for (let i = 0; i < halfTaps.length; i++) {
    var seedHistoIndex = (leftOrRight === 'leftEdge')
      ? indexStart + i
      : indexStart - i
    var count = weights[seedHistoIndex]
    var weight = halfTaps[i] * count
    if (weight === 0) { continue }
    numSamples += count
    totalWeight += weight
    var B = backbone[seedHistoIndex]
    sA += weight * i
    sAA += weight * i * i
    sB += weight * B
    sAB += weight * i * B
  }
  // Special case when all the contributing information
  // is from a single 'A' coordinate (specifically the
  // first or last count.   Fitting a line through this
  // data using linear regression gives vertical line (infinite slope)
  // and garbage yIntercept.
  // For this specific purpose we want to use the average A value for
  // the intercept and a slope of zero.  So:
  //   Don't call linear regression function as it will return correct
  //   vertical line as the fitted regression.
  //   Do use a special case in this function.
  if (numSamples === 0) {
    // We are fitting the assym-pt at a location with no data!
    // Can occur with basisA string axis where the first and last enumerated
    // strings have no sampled data.
    var yIntercept = 0
    var ySlope = 0
  } else if ((sAA === 0 && sA === 0) || (totalWeight * sAA - sA * sA) === 0) {
    yIntercept = sB / totalWeight
    ySlope = 0
  } else {
    ({ intercept_BfA: yIntercept, slope_BfA: ySlope } =
      getLinearCoefficients(sA, sAA, sB, sBB, sAB, numSamples, totalWeight))
  }
  return { yIntercept, ySlope }
}



const createBackboneValues = (seedHisto: SeedHisto, dataNameRoot: DataNameRoot, sKey: number, cp: ConvolutionParams): void => {

  const dataPath = `data[${sKey}][${dataNameRoot}]`
  const data = seedHisto.data[sKey]
  // CASE:  data already exist;  Re-use!
  if (data[dataNameRoot].backbone[0]) {
    if (seedHisto.DEBUG) { console.log(`  Reuse ${dataPath}.backbone values`) }
    return
  }

  // CASE: Empty seedHisto series.  May be that the series data is empty.
  // Or maybe the seriesData is completely out-of-range of the the binned A axis.
  if (seedHisto.data[sKey].isEmptySeedHistoFreqBinning) {
    if (seedHisto.DEBUG) { console.log(`  Calc  ${dataPath}.backbone for empty sKey`) }
    // Create the backbones, so they exist.  Doesn't matter what data they contain as
    // the flag 'isEmptySeedHistoFreqBinning' means nothing will be rendered.
    data[dataNameRoot].linFitBinnedVal = Array(seedHisto.seedHistoLength).fill(0)
    data[dataNameRoot].linFitAdjBinnedVal = Array(seedHisto.seedHistoLength).fill(0)
    for (let j = 0; j < seedHisto.backboneNumSets; j++) {
      data[dataNameRoot].backbone[j] = Array(seedHisto.seedHistoLength).fill(0)
    }
    return
  }

  // Path to create any new backbone drops through this point
  if (seedHisto.DEBUG) {
    console.log(`  Calc  ${dataPath}.backbone values`)
  }

  const numSamplesPerBin = data.freq.binnedVal
  const numBackboneSets = seedHisto.backboneNumSets
  var fromIndex = data.Aindex_FirstCount  // assumption
  var toIndex = data.Aindex_FinalCount  // assumption
  var freqRequiredForValidPoint = 1
  if (dataNameRoot === 'freq') {
    // Smoothing algorithm for freq ONLY used for seedHisto.isBasisAdataTypeString
    // Number dataTypes use a custom seedHistogram binning algorithm
    freqRequiredForValidPoint = 0  // Non-valid points have null values
  } if (dataNameRoot === 'variance') {
    fromIndex = data.Aindex_First2Count  // exception
    toIndex = data.Aindex_Final2Count   // exception
    freqRequiredForValidPoint = 2
  }


  if (cp.useSmoothToLinFit) {
    if (!data[dataNameRoot].linFitAdjBinnedVal) {
      // In this case, the backbone (values to be smoothed) has the linear term
      // removed and we convolve only the delta's above/below linear term.
      if (seedHisto.DEBUG) { console.log(`  Calc  ${dataPath}.linFitAdjBinnedVal`) }
      setLinFitAdjBinnedVal(seedHisto.data[sKey], dataNameRoot)
    } else {
      if (seedHisto.DEBUG) { console.log(`  Reuse ${dataPath}.linFitAdjBinnedVal`) }
    }
  }
  else {  // We do not remove a linFit term
    data[dataNameRoot].linFitBinnedVal = Array(seedHisto.seedHistoLength).fill(0)
    data[dataNameRoot].linFitAdjBinnedVal = data[dataNameRoot].binnedVal
  }

  // IF cp.useSmoothToLinFit THEN these values have the first order term (slope) removed.
  // ELSE these values are just a copy of the binnedVals.
  const linFitAdjBinnedVal = data[dataNameRoot].linFitAdjBinnedVal

  // CASE:  classical Histogram with basisA.internalDataType = 'number'
  if (dataNameRoot === 'freq' && cp.isPDFwithUnitProbability) {
    // Build backbone for smth_freq:
    // Freq (number) interpolation:
    //    NOT between arbitrary bins with freq > 0
    //    BUT between the minBinWidth Histogram bin centers and bin values.
    // Freq (number) backbone:
    //    No tweaking the 'B' values for local left/right averaging.
    //    Only backboneSet[0] is calculated
    //    And we copy backbone0 to the entire backbone set for consistency in the code.
    createPDF_Backbone(seedHisto, sKey)
    const backbone0 = interpolate_PDF_Backbone(seedHisto, sKey)
    for (let m = 1; m < numBackboneSets; m++) {
      data[dataNameRoot].backbone[m] = backbone0
    }
    return
  }

  // CASE: xyScatter data; mean values plotted; Only case (but most common) where
  // we used tweakedB data (lateral smoothing prior to convolution).
  else if (dataNameRoot === 'mean') {
    // Special treatment because the backbone uses:
    //   - interpolated data for backbone[0]
    //   - tweakedB data for backbone[lastIndex]
    // Looks 'laterally' to provide quicker convergence in sparse data areas.
    // Only used for 'mean' value.  Doesn't seem to make logical sense for others.
    var tweakedB = (seedHisto.DEBUG && sKey % 2 === 1)
      ? linFitAdjBinnedVal   // IN DEBUG MODE, odd series DO NOT use tweakedB; Useful for comparisons.
      : getTweakBvalues_localLeastSquares(linFitAdjBinnedVal, fromIndex, toIndex,
        numSamplesPerBin, freqRequiredForValidPoint)
    // First/last Backbone arrays MUST be interpolated (general usage case)
    // AND we must interpolate each backBoneSet[i] between backBoneSet[0] and backBoneSet[Max]
    const numBackboneSets = seedHisto.backboneNumSets
    const maxSetIndex = numBackboneSets - 1
    const tweakB_interpolated = getInterpolatedData(tweakedB, fromIndex, toIndex,
      numSamplesPerBin, freqRequiredForValidPoint)
    const linFitAdjBinnedVal_interpolated = getInterpolatedData(linFitAdjBinnedVal, fromIndex, toIndex,
      numSamplesPerBin, freqRequiredForValidPoint)
    data[dataNameRoot].backbone[0] = linFitAdjBinnedVal_interpolated
    data[dataNameRoot].backbone[maxSetIndex] = tweakB_interpolated
    // Next function assumes interpolates backbone[1], ... , backbone[maxSetIndex-1}. (linearly)
    interpolateBackBoneSets(seedHisto, dataNameRoot, sKey, fromIndex, toIndex)
    return
  }

  // DEFAULT CASES:  xyScatter data, where backbone does NOT used the tweakB data.
  //                smth_freq data, where basisA.internalDataType === 'string'
  // All backboneSet values are identical!
  const linFitAdjBinnedVal_interpolated = getInterpolatedData(linFitAdjBinnedVal, fromIndex, toIndex,
    numSamplesPerBin, freqRequiredForValidPoint)
  for (let m = 0; m < seedHisto.backboneNumSets; m++) {
    data[dataNameRoot].backbone[m] = linFitAdjBinnedVal_interpolated
  }
  return
}


// Function to compare 'equivalence' between two arrays
// when they are calculated with different approaches

export const compareArray = (arr0: number[], arr1: number[]) => {
  if (arr0.length !== arr1.length) {
    invariant(false, `CompareArray - lengths NOT equal %{arr0.length} ${arr1.length}`)
  }
  arr0.forEach((val0, i) => {
    var val1 = arr1[i]
    if (val0 !== 0 && val1 !== 0) {
      var err = Math.abs(val0 - val1) / (Math.abs(val0) + Math.abs(val1))
      if (err >= 1e-10) { debugger }
    }
  })
}


const setLinFitAdjBinnedVal = (seedHistoData_sKey: SeedHistoData, dataNameRoot: DataNameRoot) => {

  // In this algorithm data represents binnedVal and smoothedVals for 'mean', 'sum', 'min', ....
  const binnedVal = seedHistoData_sKey[dataNameRoot].binnedVal  // by Aindex bins; value of 'mean', or 'sum', ....
  const { Aindex_FirstCount, Aindex_FinalCount, freq, isEmptySeedHistoFreqBinning,
    stats_sumLogB, stats_sumLogBLogB, stats_sumB, stats_sumBB } = seedHistoData_sKey
  if (isEmptySeedHistoFreqBinning) {
    seedHistoData_sKey[dataNameRoot].linFitAdjBinnedVal = binnedVal
    seedHistoData_sKey[dataNameRoot].linFitBinnedVal = Array(binnedVal.length).fill(0)
    return
  }
  const isVariance = (dataNameRoot === 'variance' || dataNameRoot === 'varianceLogB')
  //const willUseLogarithmicScaleB = ( dataNameRoot === 'varianceLogB' || dataNameRoot === 'meanLogB')

  //Everything after this point assumes 'useFreqWeighting === true'
  // Otherwise, parent should have never called this function.
  if (dataNameRoot === 'meanLogB') {
    var sumBArr = stats_sumLogB
    var sumBBArr = stats_sumLogBLogB
  } else if (isVariance) {
    // At each bin we have a single variance value -- may be the variance(linearB's) or variance(logB's)
    // but we still want to weight the fitted lin (and subsequent smoothed line) by the number of samples.
    // Here, we just need the linear fit to the sample,

    // IF we decide to use this number, it looks funny as currently plotted:
    //     Needs to be sqrt(variance) to get sigma
    //     Needs to be plotted as mean +/- sigma
    // Currently looks 'very wrong' in the logarithmic scale because of above two factors.
    // Perhaps better to do the variance in linear domain???
    // Save this design issue for later, if ever.
    sumBArr = Array(binnedVal.length).fill(null)
    sumBBArr = Array(binnedVal.length).fill(null)
    for (var A = Aindex_FirstCount; A <= Aindex_FinalCount; A++) {
      var thisFreq = freq.binnedVal[A] - 1
      if (thisFreq < 1) { continue }
      sumBArr[A] = binnedVal[A] * thisFreq
      sumBBArr[A] = binnedVal[A] ** 2 * thisFreq
    }
  } else {   // Case of linear B scale, mean value
    sumBArr = stats_sumB
    sumBBArr = stats_sumBB
  }
  var sumA = 0
  var sumAA = 0
  var sumAB = 0
  var sumB = 0
  var sumBB = 0
  var numPts = 0
  for (A = Aindex_FirstCount; A <= Aindex_FinalCount; A++) {
    thisFreq = freq.binnedVal[A]
    if (isVariance) { thisFreq-- }
    if (thisFreq < 1) { continue }  // Skip bins without any samples.
    numPts += thisFreq
    sumA += thisFreq * A
    sumAA += thisFreq * A * A
    sumB += sumBArr[A]
    sumBB += sumBBArr[A]
    sumAB += A * sumBArr[A]   // A * sumBArr[A] === thisFreq * A * sumB === thisFreq * A * (sumB / thisFreq)
  }
  const { intercept_BfA, slope_BfA } = getLinearCoefficients(sumA, sumAA, sumB, sumBB, sumAB, numPts, numPts)
  const linFitBinnedVal = Array(binnedVal.length).fill(null)
  const linFitAdjBinnedVal = Array(binnedVal.length).fill(null)
  for (A = Aindex_FirstCount; A <= Aindex_FinalCount; A++) {
    var fitVal = intercept_BfA + A * slope_BfA
    linFitBinnedVal[A] = fitVal     // A value for every bin
    linFitAdjBinnedVal[A] = (binnedVal[A]) ? binnedVal[A] - fitVal : null  // A value ONLY for non-null bins
  }
  seedHistoData_sKey[dataNameRoot].linFitBinnedVal = linFitBinnedVal
  seedHistoData_sKey[dataNameRoot].linFitAdjBinnedVal = linFitAdjBinnedVal
}

type TweakedPts = {
  a: number
  b: number
}

const getTweakBvalues_localLeastSquares = (binnedVal: number[], fromIndex: number, toIndex: number,
  numSamplesPerBin: number[], minSamplesPerBin: number = 1): number[] => {

  // At each binned A-coord we have a aveB value.  Or this bin may be empty.
  // We consider each non-empty bin.  Order of processing them does not matter.
  // Call this the 'active point', and consider its 'A' coordinate '0'.
  // At the activePt, look left to find nearby points; look right to find nearby points.
  // 'Nearby' is an emperical setting to tune algorithm.
  // Given nearby points, consisting of pts immediate to left, immediately to right,
  // and the active pt(s) itself, do a least squares fit.  Solve for a new 'B' value
  // at the active point. This new value replaces the current value aveB value.

  // For pts in 'isolation' (no nearby left/right data) the new 'B' = old aveB.  (no change)

  // For 'dense' areas we do NOT tweak the values.  We have sufficient information
  // to capture local curvature WITHOUT laterally smearing of the values.  ( new 'B' = old aveB )

  // For 'sparse' active points (between 1 to MAX_DISTANCE_TO_LOOK nearby pts) this
  // algorithm provides significant improved 'B' values.

  // Emperically determined 'tuning' parameters.  How far do we look (left & right)
  // in order to get a better sense for the 'local neighborhood'?
  // We put two constraints on the definition of 'local'.
  var MAX_DISTANCE_TO_LOOK = 25  // in units of binWidths
  const MAX_POINTS_TO_FIND = 20   // Maximum pts sampled for each of left, center, right.
  // For example, we may take up to, no more than '6' pts to the left. But never look farther
  // than 15 bins to the left.  Hence, number pts to the left may be between 0 to 6 samples.
  // Best case we have 6 left pts, 6 center pts, and 6 right pts.
  // Worse case we have a single active pt.

  // When there is NO active pt (count/freq === 0) THEN: newB = binnedVal (typically null)

  // TWEAKED B values are a fit of the 'linear' B values.
  // NOT a fit of the log10(B values).
  // This is true weather the B axis is logarithmic or linear.
  // This is consistent with our definition of the binnedVal value.
  // binnedVals in the seedHistogram does NOT change, regardless of whether
  // it is plotted on a linear or logarithmic scale.  binnedVal is
  // always the arithmetic mean!!   Btweaked are always least
  // square fits of the arithmetic values.

  const newB = Array(binnedVal.length).fill(null)

  const getRightPts = (i: number): TweakedPts[] => {
    var pts = Array<TweakedPts>()
    var jMax = Math.min(i + MAX_DISTANCE_TO_LOOK, numSamplesPerBin.length)
    for (let j = i + 1; j <= jMax; j++) {
      var thisCount = numSamplesPerBin[j]
      // NOTE: the a coord is '0' for the active pt.
      // a coord is -1,-2, ... for pts to left.
      // a coord is +1,+2, ... for pts to right
      while (thisCount >= minSamplesPerBin && pts.length < MAX_POINTS_TO_FIND) {
        pts.push({ a: j - i, b: binnedVal[j] })
        thisCount--
      }
    }
    return pts
  }

  const getLeftPts = (i: number): TweakedPts[] => {
    var pts = Array<TweakedPts>()
    var jMin = Math.max(i - MAX_DISTANCE_TO_LOOK, 0)
    for (let j = i - 1; j >= jMin; j--) {
      var thisCount = numSamplesPerBin[j]
      while (thisCount >= minSamplesPerBin && pts.length < MAX_POINTS_TO_FIND) {
        pts.push({ a: j - i, b: binnedVal[j] })
        thisCount--
      }
    }
    return pts
  }

  for (let i = fromIndex; i <= toIndex; i++) {

    var count = numSamplesPerBin[i]
    // This if skips points that are missing, and/or dense areas.
    if (count < minSamplesPerBin || count >= MAX_POINTS_TO_FIND) {
      newB[i] = binnedVal[i]
      continue
    }
    var Lpts = getLeftPts(i)
    var Rpts = getRightPts(i)
    var Cpts = Array(Math.min(MAX_POINTS_TO_FIND, count)).fill({ a: 0, b: binnedVal[i] })
    var pts = [...Lpts, ...Cpts, ...Rpts]

    // This if ignores points that have no left/right neighboring pts.
    // Least squares result equals the original binnedVal.
    if (Cpts.length >= 1 && Lpts.length === 0 && Rpts.length === 0) {
      newB[i] = binnedVal[i]
      continue
    }

    // Some nearby pts to do a local least squares.
    // This is the path that MAY tweak some of the sparsely spaced binnedVals.
    let sB = 0, sA = 0, sAA = 0, sAB = 0
    for (let k = 0; k < pts.length; k++) {
      sB += pts[k].b
      sAA += pts[k].a * pts[k].a
      sA += pts[k].a
      sAB += pts[k].a * pts[k].b
    }
    var sBB = 1 // don't care
    newB[i] = getLinearCoefficients(sA, sAA, sB, sBB, sAB, pts.length, pts.length).intercept_BfA
  }
  return newB
}




export const getInterpolatedData = (data: number[], fromIndex: number, toIndex: number,
  numSamplesPerBin: number[], freqRequiredForValidPt: number = 1): number[] => {

  const newArray = Array(data.length).fill(null)
  var lastIndex = fromIndex
  var lastValue = data[fromIndex]
  // Next line handles degenerate case of fromIndex === toIndex
  newArray[fromIndex] = data[fromIndex]
  for (let i = fromIndex + 1; i <= toIndex; i++) {
    var newValue = data[i]
    // Next line skips MOST points.  IF we skip a point, that point will later be interpolated.
    // After all, can't interpolate anything until we find the next 'real' valued point!

    // Next line skips over bins with 'no data' as defined by null;
    // Also skips over data if
    //   stringAxis histograms - do NOT skip over 'zero' freq points. (freqRequiredForValidPt === 0)
    //   mean, sum, etc - skip over 'zero' freq points (freqRequiredForValidPt === 1)
    //   var - skip over 'zero && one' freq points (freqRequiredForValidPt === 2)
    var isValidInterpolatablePoint = (numSamplesPerBin[i] !== null &&
      numSamplesPerBin[i] >= freqRequiredForValidPt)
    if (!isValidInterpolatablePoint) { continue }
    var deltaIndex = (i - lastIndex)
    var deltaValue = (newValue - lastValue)
    for (let j = lastIndex; j <= i; j++) {
      let fraction = (j - lastIndex) / deltaIndex
      newArray[j] = (lastValue + fraction * deltaValue)
    }
    lastIndex = i
    lastValue = newValue
  }
  return newArray
}



const interpolateBackBoneSets = (seedHisto: SeedHisto, dataNameRoot: DataNameRoot,
  sKey: number, fromIndex: number, toIndex: number) => {
  const numBackboneSets = seedHisto.backboneNumSets
  const numFractionalSteps = numBackboneSets - 1
  const firstBackbone = seedHisto.data[sKey][dataNameRoot].backbone[0]
  const lastBackbone = seedHisto.data[sKey][dataNameRoot].backbone[numBackboneSets - 1]
  // Pre-allocate space for the new arrays
  const newBackboneArr = Array(numBackboneSets)
  // newBackboneArr is the full length, but we are interested
  // in new values between the first and the last backbones.
  for (let i = 1; i < numBackboneSets - 1; i++) {
    newBackboneArr[i] = Array(firstBackbone.length).fill(null)
  }
  // Interpolation:
  // All values will be null, except between fromIndex and toIndex inclusive.
  for (let j = fromIndex; j <= toIndex; j++) {
    var deltaValue = (lastBackbone[j] - firstBackbone[j]) / numFractionalSteps
    for (let k = 1; k < numBackboneSets - 1; k++) {
      newBackboneArr[k][j] = firstBackbone[j] + k * deltaValue
    }
  }
  // assign newBackbones to the seedHisto data structure
  // Again, first and last backbones already exist in the seedHisto.
  // We are only creating the interpolated set in-between
  for (let ii = 1; ii < numBackboneSets - 1; ii++) {
    seedHisto.data[sKey][dataNameRoot].backbone[ii] = newBackboneArr[ii]
  }
  return
}




const getKernalTapWeights = (widthInBins: number, kernelScale: number): number[] => {
  var taps = []
  var tapIndexRange = Math.trunc(widthInBins + 0.5)
  const stdDevsBetweenTaps = 2 / widthInBins
  // Because for loop screws up syntax highlighting:
  for (const val of list(-tapIndexRange, +tapIndexRange + 1)) {
    var alignedTapWeight = truncGaussian(val * stdDevsBetweenTaps)
    if (alignedTapWeight > 0) {
      taps.push(Math.max(0, alignedTapWeight))
    }
  }
  // normalize the array of taps, such that sum(taps) = kernelScale
  const sum = taps.reduce((a, b) => a + b, 0)
  taps = taps.map(x => x * kernelScale / sum)
  //console.log( '     widthInBins, taps.length', widthInBins, taps.length )
  return taps
}



const createPDF_Backbone = (seedHisto: SeedHisto, sKey: number): number[] => {
  // Compress the seedHisto into the smallest legal binWidth available.
  // This will be the last (smallest) binWidth option.

  const { binWidthOptions, arrayBinWidth, data, seedHistoLength } = seedHisto
  const smallestLegalBinWidth = lastVal(binWidthOptions)
  const stepSize = Math.round(smallestLegalBinWidth / arrayBinWidth)
  seedHisto.arrayBinsPerPDFbinWidth = stepSize
  const halfStepSize = stepSize / 2
  const { stats_numSamplesPerBinCumulative } = data[sKey]
  const thisBackbone = Array(seedHistoLength).fill(0)
  thisBackbone[0] = 0   // smoothings ignores all points prior to seedHisto
  // This line not really needed, but added for clarification.
  // Important:
  // Making a decision that the visible PDF is at full resolution,
  // regardless of whether the use histo binSize is modified.  Hence
  // Changing the histo binSize slider lumps data into larger bins, but
  // the PDF will always be derived from the smallestLegalBinWidth.

  var firstNonZeroBin = -1
  var finalNonZeroBin = -1
  for (let i = stepSize; i <= seedHistoLength - stepSize; i += stepSize) {
    let count = stats_numSamplesPerBinCumulative[i + halfStepSize]
      - stats_numSamplesPerBinCumulative[i - halfStepSize]
    thisBackbone[i] = count
    // First nonZero count initializes firstPDFnonZero; Some
    if (count > 0 && firstNonZeroBin === -1) { firstNonZeroBin = i }
    if (count > 0) { finalNonZeroBin = Math.max(finalNonZeroBin, i) }
  }
  seedHisto.data[sKey].Aindex_FirstPDFzero = Math.max(firstNonZeroBin - stepSize, 1)
  seedHisto.data[sKey].Aindex_FinalPDFzero = Math.min(finalNonZeroBin + stepSize, seedHistoLength - 2)
  // The plotPts actually rendered depend on the freq.binnedVal NOT being null.
  // For Histograms, we want the PDF to begin/end at 'zero' points left/right of the first/last points.
  // Up until now, we didn't know where the bin centers are locationed.
  // Here we change the freq.binnedVal from null, to zero (from 'missingInfo', to '0' frequency).
  seedHisto.data[sKey].freq.binnedVal[seedHisto.data[sKey].Aindex_FirstPDFzero] = 0
  seedHisto.data[sKey].freq.binnedVal[seedHisto.data[sKey].Aindex_FinalPDFzero] = 0
  // Smoothing ignores all points 'after' the seedHisto
  // Points right of the histo bin array are all cumulated in the last array bin.
  // Next line not really needed, but added so last value in smoothed arrays is clearly zero.
  thisBackbone[seedHistoLength - 1] = 0
  seedHisto.data[sKey].freq.backbone[0] = thisBackbone
  return seedHisto.data[sKey].freq.backbone[0]
}




const interpolate_PDF_Backbone = (seedHisto: SeedHisto, sKey: number): number[] => {
  // All counts are lumped in every 4rth bin (or other; a design constant!)
  //    3 goals:
  //    Find the antiSymmetry point x1 value:  The left  'zero' of a series histogram
  //    Find the antiSymmetry point x2 value:  The right 'zero' of a series histogram
  //    Interpolate the empty points between histogram bins.
  // Overwrites the existing backbone; (doesn't change existing binCounts, but
  // fills in the empty values between bin counts.
  const stepSize = seedHisto.arrayBinsPerPDFbinWidth
  const numSmoothingOptions = plotLayoutConsts.numSmoothingKernelSteps
  const data = seedHisto.data[sKey]
  const thisBackbone = data.freq.backbone[0]
  const startIndex = data.Aindex_FirstPDFzero
  const stopIndex = data.Aindex_FinalPDFzero
  // We use the same anti symmetry Points object for each on 'n' smoothing options
  const antiSymPts = Array(numSmoothingOptions).fill({
    startIndex, y1_val: 0, slope1_perBin: 1,
    stopIndex, y2_val: 0, slope2_perBin: 1
  })
  data.freq.antiSymmetryPts = antiSymPts
  // Linear interpolation between the center bin positions/counts.
  for (let i = startIndex + stepSize; i <= stopIndex; i += stepSize) {
    var priorCount = thisBackbone[i - stepSize]
    var deltaCount = (thisBackbone[i] - priorCount)
    var deltaCountPerStep = deltaCount / stepSize
    for (let j = 1; j < stepSize; j++) {
      thisBackbone[i - stepSize + j] = (priorCount + j * deltaCountPerStep)
    }
  }
  return data.freq.backbone[0]  // Same as thisBackbone
}

