import type { ScryInputString, HighlightArray, ScryTaggingErrorCheck } from '../sharedFunctions/parseScryInputStringTypes'
import type { OpCodeName, ScryFormula } from './formulaTypes'

//import escapeStringRegexp from 'escape-string-regexp'
import { newScryInputString, SISgetMatchInfo, SIStag, SIStagEmptySpace } from '../sharedFunctions/parseScryInputString'
import {findNumberExp, findNumberFloat, findNumberInteger,
   findNumberB60seconds, findNumberB60B60seconds}  from '../sharedFunctions/isTypeNumber'
import {reservedFuncKeys, reservedOperators,
  reservedIllegalOperators, reservedKeywords,
  getDefaultScryFormula } from './formulaTypes'
import constants   from '../sharedComponents/constants'


const escapeStringRegexp = ( strg:string ) : string => {
	// Escape characters with special meaning either inside or outside character sets.
	// Use a simple backslash escape when it’s always valid, and a `\xnn` escape when the simpler form would be disallowed by Unicode patterns’ stricter grammar.
	return  strg.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
              .replace(/-/g, '\\x2d');
}

const multChar = constants.multiplyChar


// Create the regular expression for searching colNames.
// Allows for variable spacing between words within colName.
// Canonical colName form: 'My name is John' should search
// for and tag:  'my     Name is  john' in an expression.
const reColName = ( colName:string ) : RegExp => {
  const escapedString = escapeStringRegexp( colName )
  // Next line replaces the space between words with the regEx code for '1 or more spaces'
  const regExpString = escapedString.replace( / +/g, ' +' )
  return new RegExp( `(${regExpString})`, 'i' )
}

// Create the regular expression for searching COL_KEY_CANONICAL_FORM.
// This 'unsubstituted' format may remain in the formula in case of any
// reference to a deleted colKey.
const reCOL_KEY_CANONICAL_FORM = ( len:number ) : RegExp => {
  const numDigits = len - constants.COL_KEY_CANONICAL_FORM.length - 1
  return new RegExp( `(${constants.COL_KEY_CANONICAL_FORM}\\d{${numDigits}}_)`, 'i' )
}


const parseIndents = ( result: ScryFormula ) => {
  result.workStrg.forEach( (thisWorkStrg)=> {
    const index = SIStag(thisWorkStrg,  /(^ +)/, 'indent', 0, {}  )
    if ( index >= 0 ) {
      // Value is the number of indented spaces.  Same as 'end' index since start index is always zero.
      thisWorkStrg.matchArr[0].value = thisWorkStrg.matchArr[0].end
    } else if ( thisWorkStrg.matchArr[0].end === 0 ) {
      // 'unmatched' content replaced with the 'indent'
      thisWorkStrg.matchArr[0].name = 'indent'
    } else {
      // 'unmatched' content pushed to 2nd substring.
      // value will become the indent 'level'.  Let -1 mean the indent level not yet been determined.
      thisWorkStrg.matchArr.unshift( { name: 'indent', text: '', start:0, end:0, value:-1 } )
    }
  })
}


const parseHash = ( result: ScryFormula, colTitleArr: string[] ) => {
  // A '#' can have 3 interpretations:
  // A character within a colName. (highest priority)
  // Otherwise the beginning of a comment. (2nd highest priority)
  // Otherwise a colName using a hash inside a comment (last default choice)
  //
  // Example: let 'Census #People' and 'Census #Cows' be column names.
  // And we are parsing this python expression:
  //
  //     a = 2 * Census #People    # Census #Cows will give wrong value for a
  //
  // 1st '#' is part of a colName.  Because its usage as a valid colName takes precedence 
  // 2nd '#' begins a comment.  Because this hash matches no col name, hence MUST be a comment
  // 3rd '#' is a hash symbol inside a comment.  Because the preceeding '#' defined what follows as a comment.
  const colKeysWithHashInColName : number[] = []
  for ( const [colKey, thisTitle] of colTitleArr.entries() ) {
    if ( thisTitle.includes( '#' ) ) {  
      colKeysWithHashInColName.push(colKey) 
    }
  }
  colKeysWithHashInColName.sort( (a,b)=> colTitleArr[b].length - colTitleArr[a].length )

  // For each line (expression); while we sucessfully find untagged colNames; for each colName containing a '#':
  result.workStrg.forEach( (inStrg) => {
    let thisExpression = inStrg.inputStrg
    let leftmostHash = thisExpression.indexOf( '#' )
    let keepSearching = true
    while ( keepSearching ) {
        let matchedCurrentHash = false
        // Check this single '#' (leftmost) against all possible colNames that include a '#'
        for (let j=0; j<colKeysWithHashInColName.length; j++) {
            const colKey = colKeysWithHashInColName[j]
            const colName = colTitleArr[colKey]
            const m = thisExpression.match( reColName(colName) )
            // Did we match, and if so, is the last char of the match equal to or right of the leftmost '#'
            if ( m  && m.index && m.index <= leftmostHash && m.index+m[1].length >= leftmostHash ) {
              SIStag(inStrg,  reColName(colName), 'colName', colKey, {})
              // reset thisExpression and leftmostHash so they represent the text'after' above tagged string.
              // $FlowFixMe   flow does not know index exist
              thisExpression = thisExpression.slice( m.index + m[1].length )
              leftmostHash = thisExpression.indexOf( '#' )
              matchedCurrentHash = true
            }
        }
        if ( matchedCurrentHash === false ) {
          // Leftmost '#' matches NO colNames.  It defines the start of a comment
          SIStag(inStrg,  /( *#.*$)/, 'comment', 0, {searchEndOfString:true}  )
          keepSearching = false
        }
    }
  })
}

/*
const parseTokens_obsolete = ( result: ScryFormula, derivedColAttributesArray: DerivedColAttributesArray, colKey:number ) : void => {
  // There should be no colNames matching constants.COL_KEY_CANONICAL_FORM.
  // They should have been all substituted with the current column '.colTitle' attribute.
  // UNLESS the column was deleted.  In which case how do we choose to parse the colName token?
  // Better to NOT show the title. But show that bad colKey reference.
  // Hence our column substitution algorithm will ONLY substitue valid colNames.
  // Invalid references to a deleted colName remain in the formula text, as saved in the state.
  // In other words, as '_CoLuMnKeYxxx'.
  // In this function, and 'tagColNamesOfLength_obsolete()', we want to tag this reserved string as a colName token.
  var longestColNameLength = Math.max( longestFuncNameLength, constants.COL_KEY_CANONICAL_FORM.length+3+1 )  // + 3 digits + underscore
  var answer : {errorID:string, highlightArray: HighlightArray} = {errorID:'', highlightArray:[]}
  derivedColAttributesArray.forEach( thisCol => {
   if (thisCol.isDeleted) return
   let len = thisCol.colTitle.length
   if (len > longestColNameLength) longestColNameLength = len
  })
  // tag all varNames of length > longestColNameLength
  result.workStrg.forEach( (inStrg, lineNum) => {
    answer = tagVarNamesOfLength( inStrg, longestColNameLength, lineNum )
  })
  result.workStrg.forEach( (inStrg, lineNum) => {
    if ( answer.errorID !== '' ) { return }
    // decrease len one char at a time, until len is single character
    // Those functions that take a lineNum argument MAY return an errorID
    for (let len = longestColNameLength; len>=1; len--) {
        tagColNamesOfLength_obsolete( inStrg, len, derivedColAttributesArray )
        answer = tagFuncNamesOfLength(inStrg, len, lineNum )
        if ( answer.errorID !== '' ) { break }
        if ( answer.warningID !== '' ) { result.warningID = answer.warningID }
        tagKeywordsOfLength( inStrg, len, colKey )
        answer = tagConstOfLength(    inStrg, len, lineNum )
        if ( answer.errorID !== '' ) { break }
        answer = tagIllegalOperators( inStrg, len, lineNum )
        if ( answer.errorID !== '' ) { break }
        tagOperatorsOfLength(inStrg, len )
        answer = tagVarNamesOfLength( inStrg, len, lineNum )
        if ( answer.errorID !== '' ) { break }
    }
  })
  result.errorID = answer.errorID
  result.highlightArray = answer.highlightArray
  return
}
*/

const parseTokens = ( result: ScryFormula, colKey: number, colTitleArr: string[], isBadColNameArr: boolean[], 
                        isBadColNameBecauseRedundantArr : boolean[],  isDeletedArr : boolean[] ) : void => {
  // There should be no colNames matching constants.COL_KEY_CANONICAL_FORM.
  // They should have been all substituted with the current column '.colTitle' attribute.
  // UNLESS the column was deleted!  In which case how do we choose to parse the colName token?
  // Better to NOT show the title. But show that bad colKey reference.
  // Hence our column substitution algorithm will ONLY substitue valid colNames.
  // Invalid references to a deleted colName remain in the formula text, as saved in the state.
  // In other words, as '_CoLuMnKeYxxx'.
  // In this function, and 'tagColNamesOfLength()', we want to tag this reserved string as a colName token.
  const {workStrg} = result
  let answer: ScryTaggingErrorCheck = {errorID:'', warningID:'', highlightArray:[]}
  let longestColNameLength = constants.COL_KEY_CANONICAL_FORM.length+3+1  // + 3 digits + underscore
  for ( let i = 0; i < colTitleArr.length; i++ ) {
    if ( isDeletedArr[i] ) {continue}
    longestColNameLength = Math.max( longestColNameLength, colTitleArr[i].length )
  }
  // tag all varNames of length > longestColNameLength
  for ( const [lineNum, inStrg] of workStrg.entries() ) {  
    answer = tagVarNamesOfLength( inStrg, longestColNameLength, lineNum )
  }
  for ( const [lineNum, inStrg] of workStrg.entries() ) {  
    if ( answer.errorID !== '' ) { return }
    // decrease len one char at a time, until len is single character
    // Those functions that take a lineNum argument MAY return an errorID
    for (let len = longestColNameLength; len>=1; len--) {
        tagColNamesOfLength( inStrg, len, colTitleArr, isBadColNameArr, isBadColNameBecauseRedundantArr, isDeletedArr )
        answer = tagFuncNamesOfLength(inStrg, len)
        if ( answer.errorID !== '' ) { break }
        if ( answer.warningID !== '' ) { result.warningID = answer.warningID }
        tagKeywordsOfLength( inStrg, len, colKey )
        answer = tagConstOfLength(    inStrg, len, lineNum )
        if ( answer.errorID !== '' ) { break }
        answer = tagIllegalOperators( inStrg, len, lineNum )
        if ( answer.errorID !== '' ) { break }
        tagOperatorsOfLength(inStrg, len )
        answer = tagVarNamesOfLength( inStrg, len, lineNum )
        if ( answer.errorID !== '' ) { break }
    }
  }
  result.errorID = answer.errorID
  result.highlightArray = answer.highlightArray
  return
}
/*
const tagColNamesOfLength_obsolete = (inStrg: ScryInputString, len:number, derivedColAttributesArray: Array<DerivedColAttributes> ): void => {
      derivedColAttributesArray.forEach( (thisCol, colKey) => {
        if ( thisCol.colTitle.length !== len ) { return }
        if ( thisCol.isBadColName && !thisCol.isBadColNameBecauseRedundant ) { return }
        if ( thisCol.isDeleted ) { return }
        while( SIStag(inStrg,  reColName(thisCol.colTitle), 'colName', colKey, {} )  >= 0 ) { }
      })
      // Searching for any CANONICAL_FORM, colKey unknown digits.
      // These only appear in a formula when the colKey was deleted.
      const lenCF = constants.COL_KEY_CANONICAL_FORM.length
      if ( len > lenCF && len <= lenCF + 4 ) {
        let re = reCOL_KEY_CANONICAL_FORM(len)
        while (true) {
          var index = SIStag(inStrg,  re, 'colName', -1, {} )
          if (index === -1) { break }
          var  {text} = SISgetMatchInfo(inStrg, index)
          var colKey = text.trim().slice(0,-1).slice( lenCF )
          inStrg.matchArr[index].value = Number(colKey)
        }
      }
}
*/

const tagColNamesOfLength = (inStrg: ScryInputString, len:number, 
        colTitleArr: string[],
        isBadColNameArr: boolean[], 
        isBadColNameBecauseRedundantArr : boolean[], 
        isDeletedArr : boolean[] ) : void => {    

  //const {colTitleArr, isBadColNameArr, isBadColNameBecauseRedundantArr, isDeletedArr} = paramsObj
  for ( const [colKey,title] of colTitleArr.entries() ) {  
    if ( title.length !== len || isDeletedArr[colKey]) { continue }
    if ( isBadColNameArr[colKey] && !isBadColNameBecauseRedundantArr[colKey] ) { continue }
    while( SIStag(inStrg,  reColName(title), 'colName', colKey, {} )  >= 0 ) { }
  }
  // Searching for any CANONICAL_FORM, colKey unknown digits.
  // This is how expressions are saved to resources.
  // We need to identify these a 'colName', and when displayed,
  // this canonical form is replaced with the current colTitle.
  const lenCF = constants.COL_KEY_CANONICAL_FORM.length
  if ( len > lenCF && len <= lenCF + 4 ) {
    const re = reCOL_KEY_CANONICAL_FORM(len)
    while (true) {
      const index = SIStag(inStrg,  re, 'colName', -1, {} )
      if (index === -1) { break }
      const  {text} = SISgetMatchInfo(inStrg, index) 
      const colKey = text.trim().slice(0,-1).slice( lenCF )
      inStrg.matchArr[index].value = Number(colKey)
    }
  }
}


const tagVarNamesOfLength = ( inStrg: ScryInputString, lenMin:number, lineNum:number ) => {
      // This function will test for, and return an error, when the varName
      // begins with an underscore.  Valid in python, but we choose not to support.
      const re = new RegExp( `([a-zA-Z_][a-zA-Z_\\d]{${lenMin-1},})` )
      let didMatch = true
      let errorID = ''
      let highlightArray:HighlightArray = []
      while( didMatch ) {
        const index = SIStag(inStrg,  re, 'varName', 0, {} )
        if (index === -1) { didMatch = false}
        else {
          // didMatch, but error test for a leading '_'
          const {start,end,text} = SISgetMatchInfo(inStrg,  index )
          if (text[0] === '_' ) {
            errorID = `'Named Values' must begin with a letter.`
            highlightArray = [{lineNum, start, end}]
            didMatch = false
          }
          // And set the match's 'value' to the trimmed varName
          inStrg.matchArr[index].value = text.trim()
        }
      }
      return {errorID, warningID:'', highlightArray}
}


const tagFuncNamesOfLength = (inStrg: ScryInputString, len:number ) : ScryTaggingErrorCheck => {
      // Matches functions of specified length
      const funcNames = reservedFuncKeys
      const errorID = ''
      let warningID = ''
      const highlightArray : HighlightArray = []
      funcNames.forEach ( thisName => {
        if (thisName.length + 1 !== len || errorID !== '') return
        let didMatch = true
        const reCaseInsensitive = new RegExp( `( *${thisName} *\\( *)`, 'i' )
        // Keep finding matches until no more to find, or any match throws an error
        while( errorID === '' && didMatch ) {
            const index = SIStag(inStrg, reCaseInsensitive, 'func'+thisName, '', {})
            if ( index === -1) {
              didMatch = false
            } else {
              const { text } = SISgetMatchInfo(inStrg,  index )
              // ERROR TEST: verify funcName was written in lower case
              // We test the match string again, but without case insensitivity
              const reCaseSensitive = new RegExp( `( *${thisName} *\\( *)` )
              const m = text.match( reCaseSensitive )
              if (!m ) {
                // text is original case-insensitve match.  Slice(0,-1) removes trailing open paren.
                let trimmedName = text.trim().slice(0,-1)
                trimmedName = trimmedName.trim()
                warningID = `WARNING: ' ${trimmedName} ' saved as Python: ' ${thisName} '`
              }
            }
        }
      })
      return {errorID, warningID, highlightArray}
}


const tagOperatorsOfLength = (inStrg: ScryInputString, len:number ) : void => {
      //  '<=', '>=', '==', '!=', '**', '//',
      //  '<', '>', ',', '+', '-', '*', '/', '%' ,'(', ')', '#',
      //  'and', 'or', 'not'
      if ( len > 3 ) return
      reservedOperators.forEach ( op => {
        if ( op.length !== len ) return
        const escapedString = escapeStringRegexp( op )
        const re = new RegExp( `( *${escapedString} *)`, 'i' )
        const opCodeName = 'op_' + op
        while( SIStag(inStrg, re, opCodeName, '', {/*placeholder*/}) >= 0 ) {}
      })
}

const tagIllegalOperators = (inStrg: ScryInputString, len:number, lineNum: number) : ScryTaggingErrorCheck => {
      //[ '&&', '||', '+=', '-=', '*=', '/=', '%=', '**=', '!' ]
      let errorID = ''
      let highlightArray : HighlightArray= []
      if ( len > 3 ) return {errorID, warningID:'', highlightArray}
      reservedIllegalOperators.forEach ( op => {
        if (op.length !== len || errorID !== '' ) { return }
        const escapedString = escapeStringRegexp( op )
        const re = new RegExp( `( *${escapedString} *)`, 'i' )
        const opCodeName = 'op_bad' + op
        const index = SIStag(inStrg, re, opCodeName, '', {})
        if ( index >= 0 ) {
          const {start, end} = SISgetMatchInfo(inStrg,  index )
          highlightArray = [{lineNum, start, end}]
          //[ '&&', '||', '+=', '-=', '*=', '/=', '%=', '**=', '!' ]
          switch ( op ) {
            case '^' :   errorID = "Use ** for exponents (Python syntax)"; break
            case '&&':   errorID = "Use 'and'  (Python syntax)"; break
            case '||':   errorID = "Use 'or'  (Python syntax)"; break
            case '!' :   errorID = "Use 'not' or '!='  (Python syntax)"; break
            case '+=':   errorID = "+= not supported. Use 'a = a + b' (Keeping it simple)"; break
            case '-=':   errorID = "-= not supported. Use 'a = a - b' (Keeping it simple)"; break
            case '*=':   errorID = `${multChar}= not supported. Use 'a = a * b' (Keeping it simple)`; break
            case '/=':   errorID = "/= not supported. Use 'a = a / b' (Keeping it simple)"; break
            case '%=':   errorID = "%= not supported. Use 'a = a % b' (Keeping it simple)"; break
            case '**=':  errorID = `${multChar}${multChar}= not supported. Use 'a = a ${multChar}${multChar} b' (Keeping it simple)`; break
            default:
          }
        }
      })
      return {errorID, warningID:'', highlightArray}
}


const tagConstOfLength = ( inStrg: ScryInputString, minLen:number, lineNum:number ) : ScryTaggingErrorCheck => {
      let result
      // CASE: B60B60
      while (true) {
        result = findNumberB60B60seconds (inStrg, lineNum, 'UNSIGNED', minLen )
        if ( result.numberType !== 'B60B60' ) { break }
        if ( result.errorID !== '') {
          return { errorID: result.errorID, warningID:'', highlightArray: result.highlightArray }
        }
      }
      // CASE: B60
      while (true) {
        result = findNumberB60seconds (inStrg, lineNum, 'UNSIGNED', minLen )
        if ( result.numberType !== 'B60' ) { break }
        if ( result.errorID !== '') {
          return { errorID: result.errorID, warningID:'', highlightArray: result.highlightArray }
        }
      }
      // CASE: EXPONENTIAL '1e2'.
      while (true) {
        result = findNumberExp (inStrg, lineNum, 'EXPRESSION', minLen )
        if ( result.numberType !== 'EXPONENTIAL' ) { break }
        if ( result.errorID !== '') {
          return { errorID: result.errorID, warningID:'', highlightArray: result.highlightArray }
        }
      }
      // CASE: FLOAT
      while (true) {
        result = findNumberFloat (inStrg, lineNum, 'UNSIGNED', minLen )
        if ( result.numberType !== 'FLOAT' ) { break }
        if ( result.errorID !== '') {
          return { errorID: result.errorID, warningID:'', highlightArray: result.highlightArray }
        }
      }
      // CASE: INTEGER
      while (true) {
        result = findNumberInteger (inStrg, lineNum, 'UNSIGNED', minLen )
        if ( result.numberType !== 'INTEGER' ) { break }
        if ( result.errorID !== '') {
          return { errorID: result.errorID, warningID:'', highlightArray: result.highlightArray }
        }
      }
      // If none of the tagged constants had any errors:
      return  {errorID: '', warningID:'', highlightArray:[] }
}


const tagKeywordsOfLength = ( inStrg:ScryInputString, len:number, colKey:number ) : void => {
    if ( len > 6 ) { return }
    reservedKeywords.forEach( thisKey => {
        if (thisKey.length !== len ) { return }
        const re = new RegExp( `( *${thisKey} *)`, 'i' )
        let tagName  = 'flow' + thisKey
        let tagValue = 0
        if ( thisKey === 'None' ) { tagName = 'None';       tagValue = 0 }  // tagValue never used; return value is always ''  (empty string)
        if ( thisKey === 'pi' )   { tagName = 'constpi';    tagValue = 3.141592653589793 }
        if ( thisKey === 'True' ) { tagName = 'constTrue';  tagValue = 1 }
        if ( thisKey === 'False' ){ tagName = 'constFalse'; tagValue = 0 }
        if ( thisKey === 'return'){ tagValue = colKey }
        while( true ) {
          const index = SIStag(inStrg, re, tagName, tagValue, {})
          if (index === -1 ) { break }
        }
    })
}

/*
const tagColonEndLine =(st:ScryInputString): number=>{
  // DO NOT match stray ':'.
  // Colons belong at the end of some flow lines, or embedded in numbers of temporal format.
  // All other colons are left un-tagged.  They will eventually be 'unrecognized characters'.
    if ( SISgetIndex(st, 'comment') >= 0 ) {  // If comment string exist, search immediately before it:
      return st.tag( /( *: *$)/i, 'flow:', 0, {searchImmediatelyBefore:'comment'} )
    } else {   // Else the colon should be at the very end of the ScryInputString:
      return st.tag( /( *: *$)/i, 'flow:', 0, {searchEndOfString:true} )
    }
}
*/


const isBlankLine_or_isCommentLine = ( expression:string, parsedResult: ScryFormula, lineNum: number ) 
                                                    : { isBlankLine:boolean, isCommentLine:boolean } => {
  if ( expression.trim().length === 0 ) {
    return {isBlankLine:true, isCommentLine:false}
  }  
  if ( parsedResult.formulaSubStrings[lineNum][1] &&
       parsedResult.formulaSubStrings[lineNum][1].name === 'comment' ) {
    return {isBlankLine: false, isCommentLine: true }
  }
  return {isBlankLine: false, isCommentLine: false }
}



/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
//
//  MAIN PARSING FUNCTION
//
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////

export const formulaParser = ( formula: string[], colKey : number, colTitleArr : string[], isBadColNameBecauseRedundantArr :boolean[], 
                                isDeletedArr: boolean[], isBadColNameArr: boolean[] ) : ScryFormula => {
    // This is the object that we will return!
    // It gets passed around, incrementally evolving into its final complete form.
    // If at any time the errorID gets set, we may interrupt the parsing process and
    // return the partially completed 'result'.
    const thisScryFormula = getDefaultScryFormula( )
    thisScryFormula.formulaStrings = formula
    thisScryFormula.formulaSubStrings = Array.from( {length: formula.length}, () => [] )
    // We create the Class that parses subStrings - one for each expression (line of text).
    // It initializes as one 'unmatched' subString, its length and content
    // equal to the expression line to parse.
    thisScryFormula.formulaStrings.forEach( (thisExpression,lineNum) => {
      thisScryFormula.workStrg[lineNum] = newScryInputString( thisExpression )
    })
    parseIndents( thisScryFormula )
    parseHash( thisScryFormula, colTitleArr )
    parseTokens( thisScryFormula, colKey, colTitleArr, isBadColNameArr, isBadColNameBecauseRedundantArr, isDeletedArr )
    thisScryFormula.workStrg.forEach( thisWorkStrg => {
      SIStagEmptySpace(thisWorkStrg, 'emptySpace' )  // Occasionally, empty space may be squeezed between tagged subStrings.
    })
    // Create the formulaSubStrings. Very nearly equal the
    // workStrg[lineNum].matchArr[] .
    for (let i=0; i<thisScryFormula.formulaSubStrings.length; i++ ) {
      const matchArr = thisScryFormula.workStrg[i].matchArr
    // matchArr.forEach( thisMatch=>{
      for ( const thisMatch of matchArr ) {
        const {name, start, end, value}  = thisMatch
        const text = thisScryFormula.formulaStrings[i].slice(start,end)
        thisScryFormula.formulaSubStrings[i].push({name: name as OpCodeName, text, value, start, end})
      }
      // Insert a 'endReturn' substring at the end each 'return' line.
      // But before any potential 'comment'
      // Works as both a 'op_)' AND the future opcode for the jump address.
      if ( thisScryFormula.formulaSubStrings[i][1] && thisScryFormula.formulaSubStrings[i][1].name === 'flowreturn' ) {
        const lastTokenIndex = thisScryFormula.formulaSubStrings[i].length - 1
        const lastToken = thisScryFormula.formulaSubStrings[i][lastTokenIndex]
        if ( lastToken.name === 'comment' ) {
          thisScryFormula.formulaSubStrings[i].splice( lastTokenIndex, 0,
            {name:'endReturn', text:'', value:'', start:lastToken.start, end:lastToken.start} )
        } else {
          thisScryFormula.formulaSubStrings[i].push( {name:'endReturn', text:'', value:'', start:lastToken.end, end:lastToken.end} )
        }
      }
    }
    // Classify each formula line as one (and only one) of: blankline (whitespace only), comment, or expression.
    // Expression is the catch-all and will return some parse error if not a valid format.
    thisScryFormula.formulaStrings.forEach( (thisExpr,i) => {
      const { isBlankLine, isCommentLine,  } = isBlankLine_or_isCommentLine(thisExpr, thisScryFormula, i )
      thisScryFormula.isBlankLine[i]   = isBlankLine
      thisScryFormula.isCommentLine[i] = isCommentLine
      thisScryFormula.isExpression[i]  = ( !isBlankLine && !isCommentLine )
    })
    const isEmptyFormula =  thisScryFormula.isExpression.every( x=> x===false )
    if ( isEmptyFormula ) {
      thisScryFormula.errorID = "Missing a formula. (Example: 'return 3 + 5' )"
    }
    return thisScryFormula
}


export default formulaParser
