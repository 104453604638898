import constants from '../sharedComponents/constants'

const boxChar = String.fromCharCode(0x2610)

const cleanScryInputText = ( currentStrg:string,
    keepNonBreakingSpaces:boolean,
    ignoreLineFeeds:boolean,
    ignoreSpaces:boolean,
    mergeSpaces:boolean,
) : string => {

/*
  // This code will replace unicode hex syntax with the unicode symbol.
  // Not sure how an input string would have the unicode hex, so this
  // is not currently used.

  const reg2Hex   = /\\x([\dabcdef]{2})/i       // two hex digits
  const reg4Hex   = /\\u([\dabcdef]{4})/i       // four hex digits
  const regAnyHex = /\\u{([\dabcdef]{1,6})}/i   // one to six hex digits
  var testRegExArray = [reg2Hex, reg4Hex, regAnyHex ]

  testRegExArray.forEach( thisRegEx => {
    var m = currentStrg.match( thisRegEx )
    while ( m ) {
      var matchedUnicodeChar = String.fromCodePoint( parseInt(m[1],16) )
      currentStrg = currentStrg.replace( m[0], matchedUnicodeChar )
      m = currentStrg.match( thisRegEx )   // Any more unicode matches?
    }
  })
*/

  // Example test string to test replacement of astral characters.
  // Astral characters: Happy face, domino, bold B, and trebleClef.
  // Non-normalized character:  c followed by accent \u030A
  // Need to print out the string to convert \u030A to an unicode accent character.
  // console.log( 'A\u030A A\u030A A\u030A' )
  // console.log( 'c\u030A c\u030A c\u030A' )
  // Here is a copy of what is printed from above.
  // Cut/paste into any of our inputs.
  // a😀b𝐁Å Å Åd𝄞e🀵  mn  p     -> These A's are written as A\u030A    - The combined code exists
  // a😀b𝐁c̊ c̊ c̊d𝄞e🀵  mn  p     -> These c's are written as c\u030A    - The combined code does not exist.

  // Normalization of sequential unicode units
  currentStrg = currentStrg.normalize()
  // Replace 'illegal' (we choose not to support) astral unicode characters.
  currentStrg = currentStrg.replace( /[\ud800-\udfff][\ud800-\udfff]/ig, boxChar )

  // remove zeroWidth spaces??
  // Allow users to glue two characters together so there is no possibility of line-breaking.
  // Let's keep this for now.
  //currentStrg = currentStrg.replace( /[\u200B, \uFEFF]/ig, '' )

  // Replace all finite width spaces and nbSpaces with a regular space
  // Cosmetically, keeping the various width spaces is probably not harmful.
  // However, it would require all of our string processing to become aware
  // of the entire set of possible space characters.
  currentStrg = currentStrg.replace( /[\u2000-\u200A\u202F\u205f\u3000\t]/g, ' ' )
  if ( !keepNonBreakingSpaces ) {
    let re = new RegExp( `${constants.nonBreakingSpace}`, 'g' )
    currentStrg = currentStrg.replace( re, ' ' )
  }

  if ( ignoreLineFeeds ) {
    currentStrg = currentStrg.replace( /\n\r/g, '' )
  }

  if ( ignoreSpaces ) {
    currentStrg = currentStrg.replace( / /g, '' )
  }

  if ( mergeSpaces ) {
    currentStrg = currentStrg.replace( / {2,}/g, ' ' )
    if ( keepNonBreakingSpaces ) {
      // merge nbSpaces as well.
      let re = new RegExp( `${constants.nonBreakingSpace}{2,}`, 'g' )
      currentStrg = currentStrg.replace( re, constants.nonBreakingSpace )
    }
  }

  return currentStrg
}




// 2ND GENERATION, SMARTER VERSION OF ABOVE FUNCTION.
// Added a return value for newSelectionStart (cursor position).
// It is easy to lose track of the position in degenerate cases when
// cleaning deletes characters both BEFORE AND AFTER the cursor position.
// This almost never happens in normal edit/cut/paste operations.
// Because the edit/cut/paste is always to one side or the other of the cursor.
// (Edit/cut/paste ?never? straddles the cursor.)

// But I found an exception.  Don't believe the exception I found is
// still valid, because it is now handled in EditTextWithLinks component.
// However, can't get my head around proving that there are NO exceptions
// to above rule.  Easier to just make this function a bit smarter --
// Do the cleaning in two half steps.  Before the cursor, then after the
// cursor.  Thereby allowing one to keep track of the cursor position.

// Improvements:
//    1) Use clean function ONLY on portion of string prior to selectionStop.
//       Measure this strings length.  Any cleaned (removed) characters
//       MUST be prior to selection Stop.  Adjust selectionStop accordingly.
//    2) Concatenate the cleaned string with the remaining uncleaned portion
//       of the string AFTER selectionStop. We need to merge the string (not just
//       clean the backPortion) because we wish to also clean at the joining pt.
//       Clean the merged string.  Any additional deleted chars come AFTER newSelectionStop.
//       No adjustment needed to newSelectionStop
//
//  Hence we now have both the cleaned string, and a robust position for
//  newSelecionStart.

export type CleanTextOptions = {
  deleteLeadingSpaces?: boolean   // Defaults to true
  keepNonBreakingSpaces?: boolean // If not kept, converted to a regular space. Defaults to false
  ignoreLineFeeds?: boolean       // If true, They are deleted. Defaults to true
  ignoreSpaces?: boolean          // If true, They are deleted. Defaults to false
  mergeSpaces?: boolean           // If true, Leaves only one consequtive space (or non-breaking space). Defaults to true
  maxCharCount?: number           // Limit the string length. Truncate the rest. Defaults to one million
}

export const cleanScryInputText2 = ( strg:string, cursor:number, inputOptions:CleanTextOptions = {})
           : { newValue:string, newSelectionStop:number}  => {


  if (typeof strg != 'string'){
    debugger
    console.log('cleanScryInputText2 was passed something that is not a string! - will convert')
    strg = String(strg)
  }

    // Options are applied in the order of this list.
  const {
    // Functionality that is NOT AN OPTION:
    // -- Normalize characters
    // -- Replace 'illegal' (we choose not to support) astral unicode characters with box character
    //    We only support 2byte characters, never 4byte characters.  We can always trust text.length.
    // -- With exception of nonBreaking spaces, replace all other spaces
    //    with a regular space.  Whether a nonBreaking space is converted to
    //    a regular space is an option.  IF converted to a regular space,
    //    AND mergeSpaces = true (defaults), THEN non-breaking spaces are
    //    reduced to a single regular space.  (Typical usage).
    //
    // OPTIONS:
    deleteLeadingSpaces = true,
    keepNonBreakingSpaces = false,  // If not kept, converted to a regular space
    ignoreLineFeeds = true,         // If true, They are deleted
    ignoreSpaces = false,           // If true, They are deleted
    mergeSpaces = true,             // If true, Leaves only one consequtive space (or non-breaking space)
    maxCharCount = 1000000,         // Limit the string length. Truncate the rest
  } = inputOptions


/*
  const test = 'A\u030A A\u030A A\u030A'
  const cleanTest  = test.normalize( )    // Using this form.
  const cleanTestNFC  = test.normalize('NFC' )
  const cleanTestNFD  = test.normalize('NFD' )
  const cleanTestNFKD = test.normalize('NFKD' )
  const cleanTestNFKC = test.normalize('NFKC' )
  console.log ( cleanTest, cleanTest.length )
  console.log ( cleanTestNFC, cleanTestNFC.length )
  console.log ( cleanTestNFD, cleanTestNFD.length )
  console.log ( cleanTestNFKD, cleanTestNFKD.length )
  console.log ( cleanTestNFKC, cleanTestNFKC.length )
*/

  // cursor is beginning-of-line or end-of-line
  // Shortcut;  Just process the full strg in one pass.
  if (cursor === 0 || cursor === strg.length) {
      if ( deleteLeadingSpaces ) { strg = strg.trimStart( ) }
      var newValue = cleanScryInputText( strg, keepNonBreakingSpaces,
                               ignoreLineFeeds, ignoreSpaces, mergeSpaces )
      var newSelectionStop = ( cursor > 0 ) ? newValue.length : 0
    }

  else {
      // general case where we need to maintain the cursor position:
      // Hence, we must clean the front, then clean combined full string separately.
      // Doubles the number of RegExp calls.
      var frontPortion = strg.slice( 0, cursor )
      var backPortion  = strg.slice( cursor )
      if ( deleteLeadingSpaces ) { frontPortion = frontPortion.trimLeft( ) }
      const cleanedFrontPortion = cleanScryInputText( frontPortion, keepNonBreakingSpaces,
                                                 ignoreLineFeeds,  ignoreSpaces, mergeSpaces )
      const fullString = cleanedFrontPortion + backPortion
      newSelectionStop = cleanedFrontPortion.length
      newValue = cleanScryInputText( fullString, keepNonBreakingSpaces, ignoreLineFeeds,
        ignoreSpaces, mergeSpaces  )
  }

  newValue = newValue.slice( 0, maxCharCount )
  newSelectionStop = Math.min( newSelectionStop, maxCharCount )

  return { newValue, newSelectionStop }
}
