import type {
  PlotXyComputedData,
  SeedHisto,
  SeedHistoData,
  SparsePlotPt,
  StringAxis_TextToIndexObj
} from './xy_plotTypes'

import invariant from 'invariant'
import { isEqual } from 'radash'
import { logTime } from '../sharedFunctions/timer'
import { lastVal } from '../sharedFunctions/utils'
import { plotLayoutConsts } from '../viewPlotXY/plotXyLayoutConsts'
import { getBinWidthOptions } from './plotUtils'
import { getInterpolatedData } from './seedSmoothing'
import { getDefaultPlotPtsByDataName, getDefaultSeedHisto, getDefaultSeedHistoData } from './xy_plotTypes'

/*
DESIGN of basisA Axis for distribution plots :

Listed are the design constraints, and my solutions (Rules), by priority.
The first 3 rules are the centerpiece of the design.  Because they allow
a solution that does NOT depend on a circular dependency.  Changing
and of the first 3 rules would be a major teardown/rebuilt.
However, rules_4 and beyond are more flexible.

Rule_0:
  Histogram bins MUST straddle the tick labels. Consider a case of
  'age' (in years) plotted on basisA.  Then the histogram bin for
  age === 28 has three alternatives.  We can drawn the bar:
      between age27 and age28 tick locations
      between age28 and age29 tick locations
      straddling age28 tick location (and the corresponding tick mark if rendered)
  ONLY the straddling option is unambiguous for enumerated axes.


Rule_1:
  Never render a partial bar at left/right edges of histogram plot.
  Hence, the rangeFiltered may be smaller than the rendered room
  (called the domain[min,max] in react-vis) that is required.
  But the axis range  = f( tickSize, and binSize)
        and tickSize  = f( rangeCulled )
        and rangeCulled = f( tickSize, binSize)
  This a circular dependency.  I suspect a solution is posible, but
  choose NOT to find it. (Confusing to me, and hell to test/debug).

  Solution:
      - Break the circular dependency.
      - smartAxisScale( ) by design, ignores the extra space potentially
        needed on left/right edges of a histogram (or any bar plot).
      - When we need extra space on left or right, extend the react-vis
        domain by 1/2 a binWidth on either or both sides.

Rule_2:
  A 'one pass' solution REQUIRES smartAxisScale( ) and getBinWidthOptions( )
  to use an identical rule.  Rule is:
       The largest user binWidth option === the smartAxisScale() tickSize.
       All smaller binWidth options MUST be integer multiples of tickSize.
       (Typically 1,2,4,8,16 ...  but not always)
  In practice, you will find
   - smartAxisScale( ) calls the function: getBinWidthOptions().
   - smartAxisScale sets the tickSize to binWidthOptions[0] (the largest).
  Throughout the seedHistogram code, just remember:
      The basisA tickSize ALWAYS equals the largest binWidth Option. (Rule_2)
      This binWidth must be centered on the tick labels. (Rule_0)
      Any extension to the axis to show bars rendered on the edges of the
      plot are a completely independent extension of the react-vis domain( Rule_1 )


Rule_3:
  All histogram bin width options MUST be integer multiples of the largest bin
  width option.   For example, if the largest histogram bin width option
  is 5, then the successively smaller options are [5, 2.5, 1, 0.5, 0.25]


Rule_4:
  The binWidth options we provide the user MAY be a subset of the
  available options.  This is because the user data MAY be enumerated.
  For example: consider an 'age' distribution, and 'age' is enumerated
  to 1 year intervals.  Then a binWidth option of 1/2 year or 1/4 year can
  be plotted (zero), but overSampling is not only confusing to the user,
  but it destroys concepts like interpolation and normalization.
  Hence, our rule is: never provide the user a binWidth option smaller
  than 'minSpacingInA'.  seedHisto Object owns the binWidth options.
  The smallest values (last) in this array may be pop()'ed  such that the
  smallest option is never less than minSpacingInA.
  (minSpacinginA = seedHisto.arrayBinWidth * seedHisto.arrayBinsPerMinIntervalInA)


Rule_5:
  This code ALWAYS defines the 'smallest bin width option' as the
  option provided by the code.  The user may not see this option
  if the minSpacingInA is a restriction.  But that restriction is
  enforced by the interface (slider).  Hence, no mention of, nor need
  to think about the minSpacingInA inside this module.  Only understand
  that while testing, the interface MAY not give you all the binWidthOptions.


Rule_6:
  When we smooth a histogram ('pdf plot types'), it looks better if we
  have smoothing points between the histogram bins.  This is easiest if
  we add extra, higher resolution to the seedHisto.  (2x, 4x, 8x, ...  )
  Tradeoff is the extra computation cost during convolution.  I'm currently
  using 4x resolution in the seed histogram.  ( 4x more dense than the
  smallest binWidth option). Looks nice and speed seems fine.
  But we could easily back off to 2x if we find some slow cases.


Rule_7:
  Which A coordinates belong to which bins?
  filteredValues.A are POINTS on the basisA axis.
  seedHisto[i] represents a RANGE on the basisA axis.
  For example, consider the next binWidth consisting of 4
  high resolution seedHisto bins:
                  e
      |  a  |  b  |  c  |  d  |
     i=4   i=5   i=6   i=7   i=8

  Value a is counted in histoBin[5]
  Value b, c, and d in histoBins 6,7,8 respective.
  Value e belongs to histoBin[6]
  This is a MUST! Because the binWidth must staddle a tickMark.
  And i=6 is a potential tickMark.
    If e === 24.0000 and basisA is 'age', then regions a, c, and d
    may very well be empty!  All values of '24' fall into bin[6]
    Perfectly fine for enumerated axes.)

  Why can't we assign value 'e' to RANGE 'c' and just
  shift the entire drawing by one index??  We can.
  However, this means i=odd are the points that straddle the tickMarks,
  and tickSizes and binWidths (usually factors of 2,4,10,...) will
  always fall on odd histo indices. Yuch!

  Hence just remember the first full binWidth (assume binWidth = 4):
                 e
     |  a  |  b  |  c  |  d  |
    i=0   i=1   i=2   i=3   i=4

  i=0 (by design) will fall on an axis tickMark.  (However, this
      tickMark is 'out-of-view' at left side of plot.) But still
      useful to think of tickMarks as extending indefinitly beyond
      the left/right borders of the plot.
  i=0 The Count value in i=0 is all filteredRow A values 'Prior' to the
      start of our seedHistogram binning.
  i=2 is a POINT exactly in the middle of a binWidth === 4
  i=1, i=2  values (sum) are the number of filteredValues in the 'left' half of the bin.
  i=3, i=4  values (sum) are the number of filteredValues in the 'right' half of the bin.
  freq[1] + freq[2] + freq[3] + freq[4] = total freq in first bin.
  cumCount[4]-cumCount[0] = total freq in first bin.  (This calc used in practice.)

  Depending on context, a seedHisto 'Index' represents:
      POINT coordinate on basisA axis
      RANGE to its left to capture freq[i] values
      COUNT of filteredValues in RANGE to the Left
      CUMULATIVE_COUNT of all filteredValues to the left of POINT seedHisto[i].

Rule_8: Easier, quickest code if we make the seedHistogram 'unitless'.
  We need 3 values to map between unitless seedHisto and users basisA coordinates:
      1) seedHisto.Avalue_FirstAxisTick  (A axis value of left edge of an unconstrained axis
           derived by smartAxis().  A unconstrained axis value is the value smartAxis()
           will choose as the 'best choice'.  The user of course can constrain the left
           edge to any value.)
      2) seedHisto.Aindex_FirstAxisTick  (The corresponding index of left edge
           of the unconstrained axis.)
      3) seedHisto.TickWidth === the width in axis values of a major axis tick.

  Hence the A coordinate of any seed histogram index 'i' is:
      seedHistoBinWidth = axisTickWidth / arrayBinsPerAxisTick
      A_coord = (index-Aindex_FirstAxisTick)*seedHistoBinWidth + Avalue_FirstAxisTick
  And a corresponding reverse mapping function.

Rule_9:
  The center of a bin is ALWAYS on the tick marks.  Hence, to calculate bin values:
  The seed histogram is the 'cumulative' count at each index.  Hence the count
  is the cumValue( lastIndex of this bin) - cumValue( firstIndex of this bin)


Rule_10:
  We use a higher resolution for the 'pdf' convolutions.   However, it would
  be nice (and less confusing to user) to show the initial (un-convolved)
  pdf curve such that it exactly matches the histogram.  I call this initial
  un-convolved curve the 'backbone'.  Because the seedHistogram has 4x resolution,
  the raw highRes histo[] DOES NOT match the rendered histogram:

  For example:                    index:  19, 20, 21, 22, 23, 24, 25, 26, ...
                     highRes histo count:  2,  4,  1,  4,  5,  7,  6,  9  ...  raw freq
           rendered bars at min BinWidth:  -, 11,  -,  -,  -, 27,  -,  -  ...  freq at bin Centers
  from above line we create the backbone:  ?, 11, 15, 19, 23, 27,  ?,  ?  ...  Interpolated values between bin Centers

  The 'backbone' is the dataStream that is convolved.  Watch closely when you
  convolve a 'pdf' -  At zero convolution width you will see the backbone
  exactly matches the histogram at minBinWidth.  As you move the smoothing slider to
  the first nonZero setting, the intermediate convolved points will become visible.



SUMMARY:

  Throughout the seedHistogram code, just remember:
      The basisA 'tickSize' ALWAYS equals the largest binWidth Option.
      An unconstrained axis, from smartAxis(), always 6-10 integer tickSizes.
      The first visible summed bin located at the left tickMark is:
           Aindex_FirstAxisTick and AvalueFirstAxisTick
      The subsequent rendered 'summed' bins are at a fixed spacing.
      The largest binWidth is the same as the tickSize.
      The smaller binWidths divide evenly into the tickSize.
      At each tickMark, the summed height and rendered bar should always
            straddle the tickMark. (For side-by-side bars, the 'group'
            straddles the tickMark.   For a single series or stacked
            histogram, the bar should exactly straddle the tickMark.)
*/


// export const getBinnedDataBySeries = (seedHisto:SeedHisto, dataNameRoot: DataName): number[][] => {
//   const answer: number[][] = []
//   seedHisto.data.forEach( (thisSeriesData: SeedHistoData, sKey: number): void =>
//     answer[sKey] = thisSeriesData[dataNameRoot].binnedVal
//   )
//   return answer
// }


// Next two functions are inverses:
export const getIndex_From_Avalue = (Ain: number, seedHisto: SeedHisto): number => {
  const { willUseLogarithmicSeedHistoBins, Avalue_FirstAxisTick,
    arrayBinWidth, Aindex_FirstAxisTick } = seedHisto
  var A = (willUseLogarithmicSeedHistoBins) ? Math.abs(Ain) : Ain
  A = 0.999999999999 * (willUseLogarithmicSeedHistoBins ? Math.log10(A) : A)
  return Math.ceil((A - Avalue_FirstAxisTick) / arrayBinWidth) + Aindex_FirstAxisTick
}

export const getAvalue_From_Index = (i: number, seedHisto: SeedHisto): number => {
  const { willUseLogarithmicSeedHistoBins, Avalue_FirstAxisTick,
    arrayBinWidth, Aindex_FirstAxisTick } = seedHisto
  let A = Avalue_FirstAxisTick + (i - Aindex_FirstAxisTick) * arrayBinWidth
  return (willUseLogarithmicSeedHistoBins) ? Math.pow(10, A) : A
}

// Lots of ways to do this next calculation.
// Whatever makes most sense to you as the coder:
// Just debug carefully!

export const getClosestCenterBinIndex_FromAnyIndex = (i: number,
  index_FirstTick: number, binsPerUserBinWidth: number) => {
  const halfBinWidth = binsPerUserBinWidth / 2
  const priorBinCenterIndex = Math.floor((i - index_FirstTick) / binsPerUserBinWidth)
    * binsPerUserBinWidth + index_FirstTick
  const closestBinCenterIndex = (i - priorBinCenterIndex > halfBinWidth)
    ? priorBinCenterIndex + binsPerUserBinWidth
    : priorBinCenterIndex
  return closestBinCenterIndex
}

const seedHistoModuleDEBUG = false

// This version of seed histogram DOES NOT require pre-sorted (by A) sortedRowKeys array
// Even though input data is currently ALWAYS sorted (leastA to mostA).
// But I don't see any speedup from using a 'sorted assumption' within
// this algorithm.   Hence, I'm using an unsorted assumption so current
// algorithm is more general.
export const getSeedHistogram = (plt: PlotXyComputedData, DEBUG: boolean = false)
  : SeedHisto => {

  // xy_createPlotXyComputedData is ALWAYS initialized with basisA.seedHisto = null.
  // However, once basisA.seedHisto exist, then we use it!
  if (plt.basisA.seedHisto) {
    if (DEBUG) {
      console.log('   Reuse seedHisto object')
    }
    return plt.basisA.seedHisto
  }

  const { seriesRowPlotPts_sortedInA, basisA, seriesOrder } = plt
  const { stringTextToIndexObj: stringTextToIndexObjA,
    stringTextToIndex_sortedKeys: stringTextToIndex_sortedKeysA } = basisA
  const seedHisto = getDefaultSeedHisto()
  seedHisto.isBasisAdataTypeNumber = (basisA.internalDataType === 'number')
  seedHisto.isBasisAdataTypeString = (basisA.internalDataType === 'string')
  seedHisto.willUseLogarithmicB = plt.basisB.willUseLogarithmicScale

  // Assumption
  var willUseLogarithmicSeedHistoBins = basisA.willUseLogarithmicScale
  const RANGE_IN_DECADES_TO_USE_LINEAR_SEED_HISTO = 1.51

  // Exception:  We are rendering a histogram (plotColDataType is 1 column).
  // AND basisA data range is 'barely' logarithmic where 'barely' is defined by next constant
  // THEN we will build a linear seed histogram; and it will be mapped to logarithmic basis.
  // We do this for histograms so the binWidths stay nice easy to understand widths,
  // even as we modify the binWidth slider.
  if (willUseLogarithmicSeedHistoBins && plt.plotColDataType === '1Col') {
    let rnge = basisA.domainUnconstrained
    let logRangeInDecades = rnge[1] - rnge[0]
    if (logRangeInDecades <= RANGE_IN_DECADES_TO_USE_LINEAR_SEED_HISTO) {
      willUseLogarithmicSeedHistoBins = false
    }
  }
  seedHisto.willUseLogarithmicSeedHistoBins = willUseLogarithmicSeedHistoBins

  // Next value is whether the 'User' has constrained the A axis.
  // It is NOT directly used for the the design of the seedHistogram
  // unitless axis.  However, it does break the set of typical inputs
  // into two classes:  Is the A-axis defined by smartAxis() function or
  // is the A-axis (either or min or max) defined by the user?
  const isUserConstrainedBasisA = basisA.isMinForced ||
    basisA.isMaxForced

  const { arrayBinsPerSmallestBinWidthOption,   // Design constants in default seedHisto
    tableExtensionInUnitsOfAxisTickWidth } = seedHisto
  seedHisto.DEBUG = seedHistoModuleDEBUG || DEBUG
  const { min: unconstrainedMin, max: unconstrainedMax } = basisA.rangeUnconstrainedSmartAxis
  const { min: constrainedMin, max: constrainedMax } = basisA.rangeSmartAxisAllSeries
  seedHisto.Avalue_LeftPlotEdge = constrainedMin
  seedHisto.Avalue_RightPlotEdge = constrainedMax

  // We need a way to know whether any give smoothing kernel numerical size (saved state)
  // is either the first available size, 2nd, 3rd, ...
  // Here are the constants and code that generate the available kernel width options.
  // We build an array of options, so elsewhere we can 'lookup' the current smoothing value
  // and map it to the 'nth' possible state.
  const { minSmoothingKernelDisplayFixed: displayFixed, minSmoothingKernelWidth: minKern,
    maxSmoothingKernelWidth: maxKern, numSmoothingKernelSteps: kernSteps } = plotLayoutConsts

  seedHisto.smoothingKernelWidthLabels = []
  for (let i = 0; i <= kernSteps; i++) {
    var userVal = minKern * Math.pow(maxKern / minKern, i / kernSteps)
    seedHisto.smoothingKernelWidthLabels.push(Number(userVal.toFixed(displayFixed)))
  }

  // Case of linear seedHistogram binning
  if (!seedHisto.willUseLogarithmicSeedHistoBins) {
    // - All of the units below can be mapped to the visually rendered axis.
    // - All histo bins will align nicely, centered on the rendered tick marks.
    // - The 'unitless' seedHistogram maps linearly to the rendered axis.
    var result = getBinWidthOptions(unconstrainedMin, unconstrainedMax,
      basisA.tickFormatRule, false)
    seedHisto.binWidthOptions = result.values
    seedHisto.binWidthLabels = result.binWidthLabels
    seedHisto.binWidthLabelsLog = []
    var axisTickWidth = seedHisto.axisTickWidth = seedHisto.binWidthOptions[0]
    var axisFullWidth = seedHisto.axisFullWidth = unconstrainedMax - unconstrainedMin
    seedHisto.arrayBinWidth = lastVal(seedHisto.binWidthOptions) / arrayBinsPerSmallestBinWidthOption
    var arrayBinsPerAxisTick = seedHisto.arrayBinsPerAxisTick =
      Math.round(axisTickWidth / seedHisto.arrayBinWidth)
    seedHisto.Avalue_FirstAxisTick = unconstrainedMin
    seedHisto.Avalue_LastAxisTick = unconstrainedMax
    seedHisto.Aindex_FirstAxisTick = tableExtensionInUnitsOfAxisTickWidth * arrayBinsPerAxisTick
    seedHisto.Aindex_LastAxisTick = seedHisto.Aindex_FirstAxisTick +
      Math.round(axisFullWidth / axisTickWidth) * arrayBinsPerAxisTick
  }

  // Case of logarithmic seedHistogram binning; numeric basisA
  // if ( seedHisto.willUseLogarithmicSeedHistoBins ) {
  else {

    // The Bigger Picture:
    //     1) All table values map exactly to plotPt values:
    //            plotPt.A = tableValue, count(tableValues), or Mean(tableValues)
    //     2) Crosshairs values map exactly to plotPtvalues and table values.
    //     3) We currently support two nonlinear axis types:
    //        (log10 and NormalProbability).  The nonlinear mapping a values
    //        is done inside reactVis!  Specifically:
    //        - We set A and B value reactVis getter functions that sometimes use
    //          non-linear xForms:  x = log10( abs( plotPt.A )) // log A axis
    //                              y =      ( plotPt.B )       // linear B axis
    //        - The reactVis axis tickMarks, gridlines, and labels are
    //          specified in A values, but mapped to the nonlinear locations.
    //        - Ranges all correspond to plotPt A/B values (table values).
    //     4) One exception:  We must set the reactVis XY plot area domain
    //        directly.   linearAxis: Domain = [       leftTick,        rightTick  ]
    //                 nonLinearAxis: Domain = [ log10(leftTick), log10(rightTick) ]
    //
    // So how to manage nonLinear histograms?  Without changing the above rules?
    //     - We MUST build the seed histogram using a Log10 constant spacing.
    //     - We conceptually create a 'logarithmic shadow Axis' that sits below
    //       the nonlinear rendered axis.  Created in our mind. Its attributes
    //       specified in the seedHisto data structure, and set immediately below.
    //     - We need to use a log10 domain to build the seedHistogram.
    //     - However, the plotPts (rendering bin centers, widths) need A values
    //       matching the renderedAxis.
    //     - Hence:
    //          1) Go to the log10 domain to create ticksizes, binSizes, etc.
    //                for the nonlinear axis. (createSeedHistogram below)
    //
    // Characteristics of the shadowAxis:
    // - The seedHistogram and shadowAxis are linear!
    // - The leftMost tickMark of the renderedAxis will align
    //   with the leftMost tickMark of the shadowAxis.
    // - Only the leftMost bin is guaranteed to be centered over
    //   the leftmost tick mark. In general, seedHisto bins, and
    //   corresponding rendered bars almost never align with 'nice'
    //   renderedAxis values.
    // - At tick marks on the renderedAxis that are multiples of
    //   10 * the leftMost tickMark, these bins will also be centered.
    // - All other bins do NOT align with the renderedAxis tick marks.
    // - The shadow axis has a completely independent calculation of
    //      of tickSize, numberTicksPerAxis, axisLength, & binSizes.
    //      These values derive from the getBinWidthOptions() function.
    //      This is equivalent of calling smartAxis( ) a second time.
    //      However, most of smartAxis( ) is formatting issues. The
    //      underlying function that provides 'nice' tickSizes is
    //      getBinWidthOptions( ).
    // - Hence the shadowAxis, when created with an integer number of
    //   shadowAxis tickSizes is NOT the same length.  We set its length
    //   to be the shortest shadow axis length that covers the entire
    //   renderedAxis length.  Hence a logarithmic seedHistogram is
    //   longer array length than strictly necessary.
    // - Equally spaced distances on a logarithmic axis corresponds
    //   to equal multiplicative 'factors'.
    //      logAxis:    (constant binFactor = A_stopValue / A_startValue)
    //      linearAxis: (constant binWidth  = A_stopValue - A_startValue)
    // - For log histogram axis, the slider bar choices/labels, and the
    //   crosshairs display of the bin size use 'binFactors', not 'binWidths'.

    const logConstrainedMin = Math.log10(constrainedMin)
    const logConstrainedMax = Math.log10(constrainedMax)
    result = getBinWidthOptions(logConstrainedMin, logConstrainedMax, 'defaultEng', true)
    seedHisto.binWidthOptions = result.values
    seedHisto.binWidthLabels = result.binWidthLabels
    seedHisto.binWidthLabelsLog = seedHisto.binWidthLabels.map((x: string): string => String(1 / Number(x)))
    axisTickWidth = seedHisto.axisTickWidth = seedHisto.binWidthOptions[0]
    var numAxisTicks = Math.ceil((logConstrainedMax - logConstrainedMin) / axisTickWidth)
    axisFullWidth = seedHisto.axisFullWidth = numAxisTicks * axisTickWidth
    seedHisto.arrayBinWidth = lastVal(seedHisto.binWidthOptions) / arrayBinsPerSmallestBinWidthOption

    arrayBinsPerAxisTick = seedHisto.arrayBinsPerAxisTick =
      Math.round(axisTickWidth / seedHisto.arrayBinWidth)
    seedHisto.Avalue_FirstAxisTick = logConstrainedMin
    seedHisto.Avalue_LastAxisTick = logConstrainedMin + seedHisto.axisFullWidth
    seedHisto.Aindex_FirstAxisTick = tableExtensionInUnitsOfAxisTickWidth * arrayBinsPerAxisTick
    seedHisto.Aindex_LastAxisTick = seedHisto.Aindex_FirstAxisTick +
      Math.round(axisFullWidth / axisTickWidth) * arrayBinsPerAxisTick

  }
  /*
      // Case of logarithmic seedHistogram binning, string basisA.
      if ( seedHisto.willUseLogarithmicSeedHistoBins && seedHisto.isBasisAdataTypeNumber && false) {
            const logConstrainedMin = Math.log10(constrainedMin)
            const logConstrainedMax = Math.log10(constrainedMax)
            result = getBinWidthOptions( logConstrainedMin, logConstrainedMax, 'defaultEng', true )
            seedHisto.binWidthOptions = result.values
            seedHisto.binWidthLabels  = result.binWidthLabels
            seedHisto.binWidthLabelsLog = seedHisto.binWidthLabels.map( x=> String( 1/x ) )
            axisTickWidth = seedHisto.axisTickWidth = seedHisto.binWidthOptions[0]
            numAxisTicks= Math.ceil((logConstrainedMax - logConstrainedMin)/axisTickWidth)
            axisFullWidth = seedHisto.axisFullWidth = numAxisTicks * axisTickWidth
            seedHisto.arrayBinWidth   = lastVal(seedHisto.binWidthOptions) / arrayBinsPerSmallestBinWidthOption

            arrayBinsPerAxisTick  = seedHisto.arrayBinsPerAxisTick =
                                        Math.round(axisTickWidth / seedHisto.arrayBinWidth)
            seedHisto.Avalue_FirstAxisTick = logConstrainedMin
            seedHisto.Avalue_LastAxisTick  = logConstrainedMin + seedHisto.axisFullWidth
            seedHisto.Aindex_FirstAxisTick = tableExtensionInUnitsOfAxisTickWidth * arrayBinsPerAxisTick
            seedHisto.Aindex_LastAxisTick  = seedHisto.Aindex_FirstAxisTick +
                                  Math.round( axisFullWidth / axisTickWidth ) * arrayBinsPerAxisTick
      }
  */

  // SeedHisto is set up and scaled to the unConstrainedAxis limits.
  // We need and want nice even tick marks for seedHisto definition.
  // For convolutions and the calculation of min/max B values, we only
  // need to be concerned with what will fit in the plotted area!
  seedHisto.Aindex_LeftPlotEdge = Math.floor(getIndex_From_Avalue(constrainedMin, seedHisto))
  seedHisto.Aindex_RightPlotEdge = Math.ceil(getIndex_From_Avalue(constrainedMax, seedHisto))

  const numSeries = seriesRowPlotPts_sortedInA.length
  seedHisto.seedHistoLength = arrayBinsPerAxisTick *
    (Math.round(axisFullWidth / axisTickWidth) + 2 * tableExtensionInUnitsOfAxisTickWidth) + 2

  // For example, with 200 bins per 8 tickIntervals:
  // index: 0      - numSamples with A values < seedHisto bins
  // 1    - 200    - seedHisto bins in extension left of rendered plot
  // 201  - 1000   - seedHisto bins over the rendered 'unconstrained' axis
  // 1001 - 1200   - seedHisto bins in extension right of rendered plot
  // 1201          - numSamples with A values > seedHisto bins

  // This set of params is the mapping of the unitless seedHist 'A'
  // binning scale, to the user's basisA scale (ticks, units, etc)!!
  // Can't be done with fewer params.
  //
  // We also need to know if the user changed their constrained range
  // of plotted data.  It is possible to change this range, but NOT
  // change the 'well-behaved' coord system (unconstrained smartAxis
  // range) used by the seedHistogram.  For example, if I change the
  // basisA range of rendered strings from 1-62, to 1-63, then I
  // expect new data at string 63 to be included in the seedHisto.
  // Although adding date[63] does NOT change the 'well-behaved'
  // seedHisto axis.

  const constParamsObj = {
    willUseLogarithmicSeedHistoBins,
    willUseLogAWithNegativeData: plt.basisA.willUseLogWithNegativeData,
    willUseLogarithmicB: plt.basisB.willUseLogarithmicScale,
    //willUseLogBWithNegativeData : plt.basisB.willUseLogWithNegativeData,

    Avalue_FirstAxisTick: seedHisto.Avalue_FirstAxisTick,
    Aindex_FirstAxisTick: seedHisto.Aindex_FirstAxisTick,
    arrayBinWidth: seedHisto.arrayBinWidth,
    seedHistoLength: seedHisto.seedHistoLength,
    // Also rebuild seedHisto in special case of a
    // distribution plot of a basisA.internalDataType === string.
    isSeedHistoFreqOfStringAxis:
      (plt.plotColDataType === '1Col' && seedHisto.isBasisAdataTypeString),
    isSeedHistoFreqOfNumericAxis:
      (plt.plotColDataType === '1Col' && seedHisto.isBasisAdataTypeNumber),
    isBasisAdataTypeString: seedHisto.isBasisAdataTypeString,
    Avalue_LeftPlotEdge: seedHisto.Avalue_LeftPlotEdge,  // constrained min
    Avalue_RightPlotEdge: seedHisto.Avalue_RightPlotEdge, // constrained max
  }

  var isOverallBinningModified = false  // assumption
  //  Eight corner cases are:
  //    [UnConstrainedLogALinB, IsConstrainedLogALinB, UnConstrainedLinALinB, IsConstrainedLinALinB,
  //     UnConstrainedLogALogB, IsConstrainedLogALogB, UnConstrainedLinALogB, IsConstrainedLinALogB]
  const cornerCase =
    `${isUserConstrainedBasisA ? 'IsConstrained' : 'UnConstrained'}`
    + `${constParamsObj.willUseLogarithmicSeedHistoBins ? 'LogA' : 'LinA'}`
    + `${constParamsObj.willUseLogarithmicB ? 'LogB' : 'LinB'}` as CornerCase
  const isCornerCaseModified = (cornerCase !== lastMemoizedSeedHistoCornerCase)
  lastMemoizedSeedHistoCornerCase = cornerCase

  for (var sKey = 0; sKey < seriesRowPlotPts_sortedInA.length; sKey++) {
    // If any params change by seriesKey, then we must use unique
    // objects for each series key!  Here is debugging case for smoothing
    // where debugging is True, and we only use tweakedB values for
    // even numbered series.  Without this extra parameter, then two
    // identical series will use the same memoized data, even if the
    // underlying code flow for using tweakedB values is even/odd  (on/off).
    var paramsObj = {
      isOddDebuggingSeries: seedHisto.DEBUG && (sKey % 2 === 1),
      ...constParamsObj
    }
    var pointersObj = {
      seriesRowPlotPts_sortedInA: seriesRowPlotPts_sortedInA[sKey],
      stringTextToIndexObjA,
      stringTextToIndex_sortedKeysA
    }
    var { result: thisResult, isNewCalc } = seedHistoBinning_stdMemoization(
      paramsObj, pointersObj, cornerCase, seedHisto, numSeries, sKey)
    seedHisto.data[sKey] = thisResult
    // What are all the possible ways the current set of plotPts could
    // be out-of-date ??
    // 1 - For this sKey, this is a newly calculated binning.
    // 2 - memoization returned a different corner case.
    // 3 - memoization returned same corner case, but different memoized case.
    // I suspect isCornerCaseModified is a redundant, unneccessary text as it
    // should also be covered by any change in the returned seedHisto.data[sKey].
    // But I will keep it here for clarity.
    isOverallBinningModified = isOverallBinningModified ||
      isNewCalc || isCornerCaseModified ||
      !lastMemoizedSeedHistoData[sKey] ||
      lastMemoizedSeedHistoData[sKey] !== seedHisto.data[sKey]
    lastMemoizedSeedHistoData[sKey] = seedHisto.data[sKey]
  }


  // When can we no longer trust the save plotPts?
  // Probably yet more to add to these criteria!!
  const isSeriesOrderModified = !isEqual(plt.seriesOrder, lastSeriesOrder)
  if (isOverallBinningModified || isSeriesOrderModified || false) {
    lastPlotPtsByDataName = getDefaultPlotPtsByDataName()
    lastSeriesOrder = plt.seriesOrder
  }


  // Save a copy of the last plotPts into seedHisto.
  // Just a convienent place for the application to access the plotPts.
  seedHisto.plotPtsByDataName = lastPlotPtsByDataName

  // Next minInterval calculation is relatively quick, but unnecessary for majority of plot state changes
  // Since we already have a 'change' flag available, I'll memoize last result.
  // Note: this is a conservative test.  isOverallBinningModified may change due to B axis changes
  // and not effect the 'A' axis.  But a conservative test is easy is adequate for next function.
  if (isOverallBinningModified) {
    seedHisto.arrayBinsPerMinIntervalInA = memoizedMinInterval = getMinIntervalInA(seedHisto)
    if (seedHisto.DEBUG) { console.log('  Calc  seedHisto.arrayBinsPerMinIntervalInA') }
  } else {
    seedHisto.arrayBinsPerMinIntervalInA = memoizedMinInterval
    if (seedHisto.DEBUG) { console.log('  Reuse seedHisto.arrayBinsPerMinIntervalInA') }
  }

  const minInterval = seedHisto.arrayBinsPerMinIntervalInA * seedHisto.arrayBinWidth
  while (seedHisto.binWidthOptions.length > 1 &&
    lastVal(seedHisto.binWidthOptions) < minInterval) {
    seedHisto.binWidthOptions.pop()
    seedHisto.binWidthLabels.pop()
    seedHisto.binWidthLabelsLog.pop()
  }

  // Regardless of what the resource has specified as the binWidth index,
  // we will create a 'legalBinWidthIndex' that is within range of above created options.
  seedHisto.legalBinWidthIndex = Math.min(plt.histogramBinIndex, seedHisto.binWidthOptions.length - 1)

  // Copy the users seriesLineSmoothing (1 per series) to the seedHisto structure
  for (const sKey of seriesOrder) {
    seedHisto.data[sKey].seriesLineSmoothing = plt.seriesAttributesArray[sKey].seriesLineSmoothing
  }

  logTime('id_PlotXyComputedData', 'Create the seedHistogram')
  plt.basisA.seedHisto = seedHisto
  return seedHisto
}



// PlotPts just keeps the 'last' available set of plotted points.
// This structure is very different from the memoized SeedHisto data:
//       seedHisto.data[sKey]   is memoized by sKey!
//       seedHisto.plotPts      is memoized by dataName.  sKey is embedded in each plotPt
// The plotPts memoized data is saved here because:
//     - The information on when to 'clear' the structure is found here.
//     - Specifically we clear the structure if the data[sKey] is modified for ANY sKey.
//     - And we clear if we switch between any of the memoized seedHisto corners
var lastMemoizedSeedHistoCornerCase: CornerCase | undefined = undefined
var lastMemoizedSeedHistoData = Array<SeedHistoData>()   //by SeriesKey
export var lastPlotPtsByDataName = getDefaultPlotPtsByDataName()
var lastSeriesOrder = Array<number>()


/////////////////////////////////////////////////////
//
//    Memoized SeedHisto dataStructures
//
////////////////////////////////////////////////////

// Only define 8 memoized seedHistograms.data;
// WE MEMOIZED THE DATA OBJECT WITHIN THE SEEDHISTO;  NOT THE SEEDHISTO
// SEEDHISTO IS FAST TO CREATE ON THE FLY;  ONLY THE DATA WITHIN IS DIFFICULT.
// Each corner is potentially numSeries+1 in length
// Hence we are memoizing by:  (8 corner cases) x (numSeries+1)

type SeedHistoBinningParams = {
  willUseLogarithmicSeedHistoBins: boolean
  willUseLogAWithNegativeData: boolean
  willUseLogarithmicB: boolean
  Avalue_FirstAxisTick: number
  Aindex_FirstAxisTick: number
  arrayBinWidth: number
  seedHistoLength: number
  isSeedHistoFreqOfStringAxis: boolean
  isSeedHistoFreqOfNumericAxis: boolean
  isBasisAdataTypeString: boolean
  Avalue_LeftPlotEdge: number
  Avalue_RightPlotEdge: number
  isOddDebuggingSeries: boolean
}

type SeedHistoBinningPointers = {
  seriesRowPlotPts_sortedInA: SparsePlotPt[]
  stringTextToIndexObjA: StringAxis_TextToIndexObj | null
  stringTextToIndex_sortedKeysA: string[] | null
}

type SeedHistoBinningResult = {
  result: SeedHistoData
  isNewCalc: boolean
}

type CornerCase = 'UnConstrainedLogALinB' | 'IsConstrainedLogALinB' | 'UnConstrainedLinALinB' | 'IsConstrainedLinALinB' |
  'UnConstrainedLogALogB' | 'IsConstrainedLogALogB' | 'UnConstrainedLinALogB' | 'IsConstrainedLinALogB'

type SeedHistoMemoization = {
  paramsObj: SeedHistoBinningParams
  pointersObj: SeedHistoBinningPointers
  result: SeedHistoData
}

type CornerCaseToSeedHistoMemoization = {
  [key in CornerCase]: SeedHistoMemoization[]
}

const memoized_SeedHistoData_byCornerCase: CornerCaseToSeedHistoMemoization = {
  UnConstrainedLogALinB: [],
  IsConstrainedLogALinB: [],
  UnConstrainedLinALinB: [],
  IsConstrainedLinALinB: [],
  UnConstrainedLogALogB: [],
  IsConstrainedLogALogB: [],
  UnConstrainedLinALogB: [],
  IsConstrainedLinALogB: []
}
var memoizedMinInterval = 1

const seedHistoBinning_stdMemoization = (
  paramsObj: SeedHistoBinningParams, pointersObj: SeedHistoBinningPointers,
  cornerCase: CornerCase, seedHisto: SeedHisto,
  numSeries: number, sKey: number): SeedHistoBinningResult => {

  const thisCorner = memoized_SeedHistoData_byCornerCase[cornerCase]
  // Loop through 'n' SeedHistoData objects
  // Each representing differed seriesRowPlotPts_sortedInA (aka sKeys).
  for (let i = 0; i < thisCorner.length; i++) {
    var doPointersMatch = (
      pointersObj.seriesRowPlotPts_sortedInA === thisCorner[i].pointersObj.seriesRowPlotPts_sortedInA
      && pointersObj.stringTextToIndexObjA === thisCorner[i].pointersObj.stringTextToIndexObjA
      && pointersObj.stringTextToIndex_sortedKeysA === thisCorner[i].pointersObj.stringTextToIndex_sortedKeysA
    )
    if (!doPointersMatch) { continue }
    if (!isEqual(thisCorner[i].paramsObj, paramsObj)) { continue }
    // Found a match!
    if (seedHisto.DEBUG) {
      console.log(`  Reuse sKey_${sKey} seedHisto - ` + cornerCase + ` memoizationIndex:${i}`)
    }
    return { result: thisCorner[i].result, isNewCalc: false }
  }

  // No Memoized Match!
  // Create a new SeedHistoData result.
  const result = seedHistoBinning(paramsObj, pointersObj, seedHisto, sKey)
  // Push new result in index:0
  thisCorner.unshift({ paramsObj, pointersObj, result })
  // Pop any prior results older than the last numSeries+1 calculations
  while (thisCorner.length > numSeries + 1) { thisCorner.pop() }
  if (seedHisto.DEBUG) {
    console.log(`  Calc  sKey_${sKey} seedHisto - ` + cornerCase + ` memoizationIndex:${0}`
    )
  }
  return { result, isNewCalc: true }
}



const seedHistoBinning = (paramsObj: SeedHistoBinningParams, pointersObj: SeedHistoBinningPointers,
  seedHisto: SeedHisto, sKey: number): SeedHistoData => {

  const { seedHistoLength, isSeedHistoFreqOfStringAxis, Avalue_LeftPlotEdge, Avalue_RightPlotEdge,
    isBasisAdataTypeString, willUseLogAWithNegativeData } = paramsObj
  const { seriesRowPlotPts_sortedInA, stringTextToIndexObjA,
    stringTextToIndex_sortedKeysA } = pointersObj
  const seriesData = getDefaultSeedHistoData() // the object we will return

  // Freq encoding:
  // We can't start with an assumption that freq = 0 (as with all other binned stats)!
  // We need to reserve '0' to mean:
  //     This is     the plotted A index of an enumerated string and hence initialize to zero.
  // As opposed to:
  //     This is not the plotted A index of an enumerated string and hence initialized to null.
  // 'zero'   means an enumerated string, that appeared '0' times in the filtered series data.
  //  non-zero means an enumerated string, that appeared 'n' times in the filtered series data.
  // 'null' means missing data (interpolated seedHisto bin between enumerated strings)
  const binnedVal_freq = seriesData.freq.binnedVal = Array(seedHistoLength).fill(null)
  const stats_numSamplesPerBinCumulative
    = seriesData.stats_numSamplesPerBinCumulative = Array(seedHistoLength).fill(0)
  const stats_sumB = seriesData.stats_sumB = Array(seedHistoLength).fill(0)
  const stats_sumBB = seriesData.stats_sumBB = Array(seedHistoLength).fill(0)
  const stats_sumLogB = seriesData.stats_sumLogB = Array(seedHistoLength).fill(0)
  const stats_sumLogBLogB = seriesData.stats_sumLogBLogB = Array(seedHistoLength).fill(0)
  const stats_minB = seriesData.stats_minB = Array(seedHistoLength).fill(+Infinity)
  const stats_maxB = seriesData.stats_maxB = Array(seedHistoLength).fill(-Infinity)
  const binnedVal_mean = seriesData.mean.binnedVal = Array(seedHistoLength).fill(null)
  const binnedVal_variance = seriesData.variance.binnedVal = Array(seedHistoLength).fill(null)
  const binnedVal_meanLogB = seriesData.meanLogB.binnedVal = Array(seedHistoLength).fill(null)
  const binnedVal_varianceLogB = seriesData.varianceLogB.binnedVal = Array(seedHistoLength).fill(null)
  const binnedVal_sum = seriesData.sum.binnedVal = Array(seedHistoLength).fill(null)
  const binnedVal_min = seriesData.min.binnedVal = Array(seedHistoLength).fill(null)
  const binnedVal_max = seriesData.max.binnedVal = Array(seedHistoLength).fill(null)
  const numEnumeratedStringsPerBin = Array(seedHistoLength).fill(0)

  // Assumptions:
  seriesData.isEmptySeries = false
  seriesData.isEmptySeedHistoFreqBinning = false
  var firstCount = +Infinity  // The first bin with a value over this series
  var finalCount = -Infinity  // The last  bin with a value over this series
  var first2Count = +Infinity  // The first bin with 2 values over this series (valid variance)
  var final2Count = -Infinity  // The last  bin with 2 values over this series

  if (seriesRowPlotPts_sortedInA.length === 0) {
    seriesData.isEmptySeries = true
    seriesData.isEmptySeedHistoFreqBinning = true
    return seriesData
  }
  const lastBinIndex = seedHistoLength - 1
  var isEmptySeries = true  // assumption

  const accumulateBinStats = (index: number, Bval: number) => {
    var logB = Math.log10(Bval)
    stats_sumB[index] += Bval
    stats_sumBB[index] += Bval * Bval
    stats_sumLogB[index] += logB
    stats_sumLogBLogB[index] += logB * logB
    stats_minB[index] = Math.min(stats_minB[index], Bval)
    stats_maxB[index] = Math.max(stats_maxB[index], Bval)
  }

  // CASE #1:  Freq Histogram over enumerated strings
  // Bval comes for the freq count inside the stringTextToIndex object
  if (isSeedHistoFreqOfStringAxis) {
    invariant(stringTextToIndex_sortedKeysA, `isSeedHistoFreqOfStringAxis is true but stringTextToIndex_sortedKeysA is ${stringTextToIndex_sortedKeysA}`)
    invariant(stringTextToIndexObjA, `isSeedHistoFreqOfStringAxis is true but stringTextToIndexObjA is ${stringTextToIndexObjA}`)
    for (var strgIndex = Avalue_LeftPlotEdge; strgIndex <= Avalue_RightPlotEdge; strgIndex++) {
      var thisString = stringTextToIndex_sortedKeysA[strgIndex - 1]
      var stringInfo = stringTextToIndexObjA[thisString]
      var index = getIndex_From_Avalue(strgIndex, seedHisto)
      index = Math.min(Math.max(0, index), lastBinIndex)
      // binnedVal_freq:  null implies missing data (initialized values)
      // Each strgIndex corresponds to a seedHist 'index'.  Only the seedHisto
      // indices at integer string coordinates have a value.  Zero is also a
      // valid value for freq plots.
      var Bval = stringInfo.freq[sKey]  // In this case Bval and freq ARE the same thing (May be zero)
      binnedVal_freq[index] += Bval
      numEnumeratedStringsPerBin[index]++
      if (index === 0 || index === lastBinIndex) { continue }
      // First and last valid freq counts MAY include 'zero' strings!
      // Different from data in plotPts, where first/last points never include freq zero data.
      firstCount = Math.min(firstCount, index)
      finalCount = Math.max(finalCount, index)
      if (Bval > 0) {
        isEmptySeries = false
        accumulateBinStats(index, Bval)
      }
    }
  }

  // CASE #2 - Bval comes from the set of plotPoints.
  else {
    for (const pt of seriesRowPlotPts_sortedInA) {
      var filteredAvalue = willUseLogAWithNegativeData ? Math.abs(pt.A) : pt.A
      index = getIndex_From_Avalue(filteredAvalue, seedHisto)
      index = Math.min(Math.max(0, index), lastBinIndex)
      binnedVal_freq[index]++
      if (index === 0 || index === lastBinIndex) { continue }
      isEmptySeries = false
      // if isBasisAdataTypeString, then next two lines do nothing as first/last count
      // are already at the max range of the enumerated string axis.
      // We can skip these next two line (or not, first/last range is already at the full extent)
      firstCount = Math.min(firstCount, index)
      finalCount = Math.max(finalCount, index)
      if (binnedVal_freq[index] >= 2) {
        first2Count = Math.min(first2Count, index)
        final2Count = Math.max(final2Count, index)
      }
      accumulateBinStats(index, pt.B)
    }
  }
  seriesData.isEmptySeedHistoFreqBinning = isEmptySeries

  // now calc 'binned values' (mean, var, sum, ... ) from prior accumulated statistics.
  // 2ND LOOP OVER ALL SEED HISTOGRAM BINS:
  var currentCumValue = 0
  for (var i = firstCount; i <= finalCount; i++) {
    var numSamples = binnedVal_freq[i]
    if (isBasisAdataTypeString && numEnumeratedStringsPerBin[i] > 0) {
      // Special case for basisA using enumerated strings:
      //   numSamples === null (space between plotStrings where we may interpolate values) => binnedVal_freq = null  (missingInfo)
      //   numSamples === 0    (An enumerated string, but this specific sKey has no data;  => binnedVal_freq = 0     (freq == 0)
      //   numSamples === 1    (1  enumerated string or plotPt in this bin => binnedVal_freq = stats_SumB            (Bval of n=1)
      //   numSamples >   1    (>1 enumerated string or plotPt in this bin => binnedVal_freq = stats_SumB/numSamples (ave Bval of n>1)
      binnedVal_freq[i] = stats_sumB[i] / numEnumeratedStringsPerBin[i]
    }

    // Otherwise numSamples is the number of plotPts falling into each bin.
    // Statistics can be accumulated ONLY for bins with real data.
    else if (numSamples > 0) {
      // For these plots binnedVal_freq is number samples per bin
      // and potential weighting factor for some seedSmoothing options.
      binnedVal_mean[i] = stats_sumB[i] / numSamples
      binnedVal_meanLogB[i] = stats_sumLogB[i] / numSamples
      binnedVal_sum[i] = stats_sumB[i]
      binnedVal_min[i] = stats_minB[i]
      binnedVal_max[i] = stats_maxB[i]
      if (numSamples >= 2) {   // Variance stat is valid only when numSamples >= 2
        binnedVal_variance[i] = Math.max(0, (numSamples * stats_sumBB[i] - stats_sumB[i] ** 2)) / (numSamples * (numSamples - 1))
        binnedVal_varianceLogB[i] = Math.max(0, (numSamples * stats_sumLogBLogB[i] - stats_sumLogB[i] ** 2)) / (numSamples * (numSamples - 1))
      } else {
        binnedVal_variance[i] = null
        binnedVal_varianceLogB[i] = null
      }
    }
    currentCumValue += binnedVal_freq[i]
    stats_numSamplesPerBinCumulative[i] = currentCumValue
  }

  if (seriesData.isEmptySeedHistoFreqBinning === false) {  // At least one real point in seedHisto range
    for (i = finalCount; i < stats_numSamplesPerBinCumulative.length; i++) {
      stats_numSamplesPerBinCumulative[i] = currentCumValue
    }
  }
  seriesData.Aindex_FirstCount = Math.min(Math.max(firstCount, 0), seedHistoLength - 1)
  seriesData.Aindex_First2Count = Math.min(Math.max(first2Count, 0), seedHistoLength - 1)
  seriesData.Aindex_FinalCount = Math.max(Math.min(finalCount, seedHistoLength - 1), 0)
  seriesData.Aindex_Final2Count = Math.max(Math.min(final2Count, seedHistoLength - 1), 0)

  seriesData.stats_numSamplesPerBinInterpolated = getInterpolatedData(
    binnedVal_freq, seriesData.Aindex_FirstCount,
    seriesData.Aindex_FinalCount, binnedVal_freq, 0)
  return seriesData  // Memoized code ALWAYS returns an object:
}




const getMinIntervalInA = (seedHisto: SeedHisto): number => {
  // minInterval is in units of arrayBinWidth (integer value)
  // We ignore first and last bin when determining the minInterval.
  // Because these two bins contain the 'out-of-range' number of pts.
  // 'out-of-range' points NEVER included in minInterval determination.

  // minInterval is defined as 'over all series'.  Not completely sure
  // whether one value over all series is sufficient. Change later if
  // necessary
  var minInterval = Infinity
  var lastPositiveFreqIndex = -Infinity  // If BfreqArr[1] does have data,
  for (let i = 1; i < seedHisto.seedHistoLength - 1; i++) {
    var isPositiveFreq = seedHisto.data.some(
      // Ask whether series is empty first, else freq.binnedVal data does not exist.
      thisSeriesObj => (!thisSeriesObj.isEmptySeries && (thisSeriesObj.freq.binnedVal?.[i] || 0) > 0)
    )
    if (isPositiveFreq) {
      var thisInterval = Math.abs(i - lastPositiveFreqIndex)
      lastPositiveFreqIndex = i
      minInterval = Math.min(minInterval, thisInterval)
    }
  }
  return minInterval
}
