import React from 'react'
import type { AnyAction, Dispatch } from '@reduxjs/toolkit'
import {isUndoAvailable, createUndoAction} from '../redux/undoReducer'
import {connect}     from 'react-redux'
import type {RootState} from '../redux/store'
import { ParentType } from '../jsonapi/types'

//This should be updated to use react-error-boundary library
//which is a better implementation of the same idea.  It would also provide
//proper redirect back to the start and reset the state.
//In the case where the error is not recoverable for a plot it could simply be deleted.

type RequiredProps = {
  name: string,
}
type OwnProps = {
  tableid: string,
  plotid: string,
  view: string,
  children: React.ReactNode,
  parentId: string,
  parentType: string,
}

type LocalState = {
  hasError: boolean,
  errorMessage?: string,
  errorCount: number,
}

type StateProps = {
  undoState: RootState['undo'],
}

type DispatchProps = {
  undo: (parentType: ParentType, parentId: string) => void,
}

type Props = RequiredProps & Partial<OwnProps> & Partial<StateProps> & Partial<DispatchProps>

class ErrorBoundaryClass extends React.Component<Props, LocalState> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, errorCount: 0};
  }
  static getDerivedStateFromError(error: Error) {
    // This occurs when an error is thrown in a child component
    // Before the DOM is updated.
    // By setting hasError here we will display the oops page temporarily if we can undo the error.
    console.log('ErrorBoundary getDerivedStateFromError:', error);
    return { 
      hasError: true,
      errorMessage: error.message, 
    };
  }

  componentDidCatch(error: Error, info: {componentStack: string}) {
    // After the DOM is updated we can perform side effects
    // We've already rendered the Oops page, now undo the error if there is an undo available in plots or tables.
    console.log('ErrorBoundary caught an error:', error, info);
    const {errorCount} = this.state;
    //const {plots, tables} = this.props.state.undo.parents
    const {tableid, plotid, view, undo} = this.props;
    const undoCheck = (type: ParentType, id: string) => {
      if (undo && isUndoAvailable(this.props.undoState, type, id)) {
        undo(type, id);
        this.setState({ hasError: false, errorCount: errorCount + 1 });
        return true;
      }
      return false;
    };
    if (plotid && view === 'xyPlotView') {
      if (undoCheck("plots", plotid)) {
        return;
      }
    } else if (tableid && view === 'tableView') {
      if (undoCheck("tables", tableid)) {
        return;
      }
    }
    this.setState({ hasError: true }) // This will be the case of no undo available
  }

  render() {
    if (this.state.hasError) {
      // render fallback UI
      return (
        <div className='rc_ErrorBoundary'>
          <h1><span role='img' aria-label='sad face'>☹️</span> Oops, something went wrong</h1>
          <h3>Try the back button, or refresh the page</h3>
          <h3>If that doesn't work, please report the error to the development team.</h3>
          <div>
          {this.state.errorCount > 0 ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p style={{ margin: 0, padding: 0 }}>Error count: {this.state.errorCount}</p>
              <p style={{ margin: 0, padding: 0 }}>tableid: {this.props.tableid}</p>
              <p style={{ margin: 0, padding: 0 }}>plotid: {this.props.plotid}</p>
              <p style={{ margin: 0, padding: 0 }}>view: {this.props.view}</p>
              </div>
          ) : (
            <p> No undo for view "{this.props.view}" found. </p>
          )}
          {this.state.errorMessage ? (
            <p style={{ margin: 0, padding: 0 }}>Error message: {this.state.errorMessage}</p>
          ) : (
            <p style={{ margin: 0, padding: 0 }}>No error message available</p>
          )}
          </div>
        </div>
      )
    }
    return this.props.children
  }
}
const mapState = (state: RootState, ownProps: Partial<OwnProps>): StateProps => {
  return {
    undoState: state.undo,
  }
}
const mapDisp = (dispatch: Dispatch<AnyAction>, ownProps: Partial<OwnProps>): DispatchProps => (
  {
    undo: (parentType: ParentType, parentId: string): void => {
      dispatch(createUndoAction({parentType, parentId}))
    },
  }
)

const ErrorBoundary = connect(mapState, mapDisp)(ErrorBoundaryClass)
export default ErrorBoundary