import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { Level, Notification } from '../viewNotifications/react-notification-system/constants'

// import {RNS_SHOW_NOTIFICATION, RNS_HIDE_NOTIFICATION, RNS_REMOVE_ALL_NOTIFICATIONS} from './const'

// export default function Notifications(state = [], action = {}) {
//   switch(action.type) {
//     case RNS_SHOW_NOTIFICATION:
//       const { type, ...rest } = action
//       return [
//         ...state,
//         { ...rest, uid: action.uid}
//       ]
//     case RNS_HIDE_NOTIFICATION:
//       return state.filter(notification => {
//         return notification.uid !== action.uid
//       })
//     case RNS_REMOVE_ALL_NOTIFICATIONS:
//       return []
//     default:
//       return state
//   }
// }

export type NotificationState = Notification[]

const defaultNotificationsState: NotificationState = Array<Notification>()

type PayloadWrapper<T> = {
  payload: T
}

const sharedPrepareUtil = (notification: Notification, level: Level): PayloadWrapper<Notification> => ({
  payload: {
    ...notification,
    uid: notification.uid || Date.now(),
    level
  }
})

const sharedReducer = (state: NotificationState, action: PayloadAction<Notification>): void => {
  const notification = action.payload
  state.push(notification)
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: defaultNotificationsState,
  reducers: {
    show: {
      reducer(state, action: PayloadAction<Notification>) {
        sharedReducer(state, action)
      },
      prepare(notification: Notification, level: Level) {
        return sharedPrepareUtil(notification, level)
      }
    },
    success: {
      reducer(state, action: PayloadAction<Notification>) {
        sharedReducer(state, action)
      },
      prepare(notification: Notification) {
        return sharedPrepareUtil(notification, 'success')
      }
    },
    error: {
      reducer(state, action: PayloadAction<Notification>) {
        sharedReducer(state, action)
      },
      prepare(notification: Notification) {
        return sharedPrepareUtil(notification, 'error')
      }
    },
    warning: {
      reducer(state, action: PayloadAction<Notification>) {
        sharedReducer(state, action)
      },
      prepare(notification: Notification) {
        return sharedPrepareUtil(notification, 'warning')
      }
    },
    info: {
      reducer(state, action: PayloadAction<Notification>) {
        sharedReducer(state, action)
      },
      prepare(notification: Notification) {
        return sharedPrepareUtil(notification, 'info')
      }
    },
    hide: (state, action: PayloadAction<number>): void => {
      const uid = action.payload
      const matchByUid = (element: Notification) => (
        element.uid === uid
      )
      const index = state.findIndex(matchByUid)
      if (index >= 0) {
        state.splice(index, 1)
      }
    },
    removeAll: (state): void => {
      state.splice(0, Infinity)
    }
  }
})


export const {
  show,
  success,
  error,
  warning,
  info,
  hide,
  removeAll
} = notificationsSlice.actions

export const actions = notificationsSlice.actions

export default notificationsSlice.reducer
