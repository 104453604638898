//  DEBUGGING!
// One should be able to change this number (half, double, 10x) and the visible
// plot should NOT change!  This number only determines the resolution of the
// rendered plot features.
// If the plot does change, then somewhere in this file, or more likely the
// xy_plotCalculator, is using constant units of width or height.  Rather than
// something proportional to the nominlaReactVisSize.

// Too little resolution smears the lines.
// Too much resolution makes angled or curved lines 'staircased', rather than antialiased.
const nominalReactVisSize = 1500   // emperical choice by JPS; Changing is possible, but will need
// some upgrades to Crosshairs module, so Crosshairs display
// remains ~constant size.


// Next number changes ALL plot font sizes proportionally.
const nominalFontScale = .034 * nominalReactVisSize   // set emperically
// Next number changes ALL Marks sizes proportionally.
// Watch smallest and largest options to see how this shifts the entire scale.
const nominalMarkScale = 0.004 * nominalReactVisSize
const nominalPlotLineThickness = 0.003 * nominalReactVisSize
const nominalAxisLineThickness = 0.002 * nominalReactVisSize

const samplingDensityOptions = [0.0001, 0.0002, 0.0005, 0.001, 0.002, 0.005,
  0.01, 0.02, 0.05, 0.1, 0.25, 0.5, 0.75, 1]
const samplingDensityKeys = ['0.0001', '0.0002', '0.0005', '0.001', '0.002', '0.005',
  '0.01', '0.02', '0.05', '0.1', '0.25', '0.5', '0.75', '1'] as const
export type SamplingDensityKey = typeof samplingDensityKeys[number]
const samplingDensityLabels = ['.01%', '.02%', '.05%', '.1%', '.2%', '.5%',
  '1%', '2%', '5%', '10%', '25%', '50%', '75%', 'All']


const initialPlotLayoutConsts = {

  // These two font sizes are in 'Px'.  Unlike above fonts, they are NOT proportionally sized to the plot.
  mainTitleFontSize: 28,
  publisherTitleFontSize: 24,

  // When we use string axis for plotting, we need to put some maximum
  // limit on the length of the string!  Because a table is designed to
  // support a 'text report of arbitray length' within each cell.
  // We certainly cannot use text of arbitray long length as plot tick labels!
  // So here we will limit the max length (number characters) when filtering
  // plotPts, and enumerating a string axis value.
  maxCharactersForEnumStringAxes: 30,

  // Everything else (constants in this file, constants in plotCalculator) are
  // either unitless, or if a width/hieght,offset,etc (units of distance) then
  // some proportion of the nominlaReactVisSize.
  nominalReactVisSize,   // The nominal size for the reactVis square plotted area.

  // This is the font size factor to increase/decrease fonts (from nominal) for specific purposes
  nominalFontScale,   // set emperically (above)
  nominalMarkScale,
  nominalPlotLineThickness,
  nominalAxisLineThickness,

  // These factors resize fonts 'relative' to each other.
  fontSizeFactorTickLabel: .70,
  fontSizeFactorLegend: 0.90,
  fontSizeFactorAxisTitle: 1.10,
  fontSizeFactorAxisSubTitle: .70,

  barSizeFactor_stringAxis: 0.6,
  barSizeFactor_numberAxis: 0.86,


  lightGridColor: '#e0e0e0',
  nominalGridColor: '#404040',

  histogramBinIndexForNewPlots: 2,

  minSmoothingKernelDisplayFixed: 1,
  minSmoothingKernelWidth: 1,   // In number of SeedBinCounts
  maxSmoothingKernelWidth: 100,
  numSmoothingKernelSteps: 35,  // 5 steps per factor of 2.
  seriesLineSmoothingForNewPlots: 8,
  bottomCanvasOpacityForNewPlots: 0.75,

  samplingDensityOptions,
  samplingDensityLabels,
  seriesSamplingDensityOptionForNewPlots: 1,

  samplingSizeMinimum: 400,   // Our first displayed option is the one resulting in > this value
  numPlotPtsWhichEnablesRandomSampling: 4000,
  // Note: At limit of numPlotPts jsut over 4000, options displayed
  // are 10% (just over four hundred), 25%, 75%, 100%



  //aspectRatioLabels : ['5:8 (0.62)','8:11 (0.73)','5:6 (0.83)', '1:1 (1.00)','6:5 (1.20)','11:8 (1.38)','8:5 (1.60)','16:9 (1.78)'],
  //aspectRatioOptions : [0.62, 0.73, 0.83, 1.00, 1.20, 1.38, 1.60, 1.78],
  //aspectRatioOptionForNewPlots: 1.38,

  aspectRatioLabels: [
    '1.6:1', '1.56:1', '1.52:1', '1.48:1', '1.44:1', '1.4:1', '1.36:1', '1.32:1', '1.28:1',
    '1.24:1', '1.2:2', '1.16:1', '1.12:1', '1.08:1', '1.04:1', '1:1',
    '1:1.04', '1:1.08', '1:1.12', '1:1.16', '1:1.2', '1:1.24', '1:1.28', '1:1.32', '1:1.36',
    '1:1.4', '1:1.44', '1:1.48', '1:1.52', '1:1.56', '1:1.6',
    '1:1.64', '1:1.68', '1:1.72', '1:1.76', '1:1.8', '1:1.85', '1:1.9', '1:1.95', '1:2'],

  aspectRatioOptions: [.6250, .6410, .6579, .6757, .6944, .7143, .7353, .7576,
    .7813, .8064, .8333, .8621, .8829, .9295, .9615,
    1.00, 1.04, 1.08, 1.12, 1.16, 1.20, 1.24, 1.28,
    1.32, 1.36, 1.40, 1.44, 1.48, 1.52, 1.56, 1.60,
    1.64, 1.68, 1.72, 1.76, 1.80, 1.85, 1.90, 1.95, 2.00],
  aspectRatioOptionForNewPlots: 1.38,

  // Most fonts
  fontSizeMinFactor: 0.6,
  fontSizeMaxFactor: 1.3,

  // Title/Publisher font sizes:
  fontSizeTitleMinFactor: 0.8,
  fontSizeTitleMaxFactor: 1.3,

  // Need a wider range for tick values?
  // To accomodate string axis labels (better compression at low end of range)
  tickFontSizeMinFactor: 0.6,
  tickFontSizeMaxFactor: 1.3,

  percentilePointsPerDiagonalAtMaxDensity: 800,

  xTickAngleLabels: ['0', '30', '45', '60', '75', '90'],  // Skips 15 degrees
  xTickAngleValues: [0, -30, -45, -60, -75, -90],
  xAxisLabelAngleForNewPlots: -60,

  // Legends are the width of the required text, but never less than this value:
  minLegendWidth: .05 * nominalReactVisSize,
  backgroundColor: 'white',

}

export type PlotLayoutConsts = typeof initialPlotLayoutConsts
export const plotLayoutConsts: PlotLayoutConsts = Object.assign({}, initialPlotLayoutConsts)
