import type { JSX } from 'react'
import { PureComponent } from 'react'
import { SCRY_BLUE } from '../sharedComponents/constants'

type OwnProps = {
  children: JSX.Element | JSX.Element[] | string,
  onClick: () => void,
}

type DefaultProps = {
  disabled: boolean,
}

type Props = OwnProps & DefaultProps

class NavTopButton extends PureComponent<Props> {

  static defaultProps = {
    disabled: false
  }

  render() {
    const { children, disabled, onClick } = this.props
    return (
      <button
        className={'rc_NavTopButton'}
        onClick={onClick}
        style={{
          //display: 'relative',
          appearance: 'none',
          background: SCRY_BLUE, //'#24292e',
          border: 'none',
          color: disabled ? 'gray' : '#fff',
          cursor: disabled ? 'auto' : 'pointer',
          fontSize: 'inherit',
          fontWeight: 'inherit',
          outline: 'none',
          marginLeft: 30,
          userSelect: 'none',
          whiteSpace: 'nowrap',
        }}
        disabled={disabled ? true : undefined}
      >
        {children}
      </button>
    )
  }
}

export default NavTopButton
