import type {FpLayout}              from '../appCode/getSbFpConfigMappings'
import type {MouseEvent}            from 'react'
import type {FpParentState, 
             SetFpParentState}      from './FpParent'
import type {DraggableData}         from 'react-draggable'

import {useState}                   from 'react'
import {DraggableCore}              from 'react-draggable'
import {TabControl3, 
        getTabControlHeight}        from '../sharedComponents/TabControl3'
import constants                    from '../sharedComponents/constants'
import {sessionStateChangeDispatch} from '../sharedComponents/reactDispatch'
import {SVGresizeTextEdit}          from '../SVGs/SVGresizeTextEdit'
import {FP_MIN_TOP_RIGHT_OFFSET }   from './FpParent'


const BACKGROUND = constants.SCRY_WHITE
const TITLE_BAR_HEIGHT = 22
const TITLE_BAR_FONTSIZE = 14
const BLUE_OUTLINE_WIDTH = 3     
const BLACK_OUTLINE_WIDTH = 1
const BORDER_COLOR = constants.SCRY_DARK_BLUE
export const FP_CONTAINER_ADDITIONAL_HEIGHT = TITLE_BAR_HEIGHT + 2*BLUE_OUTLINE_WIDTH + BLACK_OUTLINE_WIDTH
export const FP_CONTAINER_ADDITIONAL_WIDTH = 2*(BLUE_OUTLINE_WIDTH+BLACK_OUTLINE_WIDTH)
const PADDING_TOP = ( TITLE_BAR_HEIGHT - TITLE_BAR_FONTSIZE ) / 2 - 1

const resizeHeightWidth = 15

type ChildState = { childWidth: number, childHeight: number }
type SetChildState= ( _:ChildState)=>void


// Initial cursor position
// With respect to the FP child top/left corner.
// Origin of these coords is irrelevant.  We only care about the delta.
let startDragX = 0
let startDragY = 0
// initial 'x' params of FP
let startChildWidth = 0
let startFpLeft = 0
let startFpWidth = 0
// initial 'y' params of FP
let startChildHeight = 0
let startFpTop = 0
let startFpHeight = 0
// constant values calced at dragStart
let startAvailableDragToRight = 0
let startAvailableDragToBottom = 0


const onDragStartHandler =( position: DraggableData, childState: ChildState, 
                    parentState: FpParentState, setParentState: SetFpParentState) => {
    //if (isTIMER_ENABLED) { startTimer('dragFP') };
    startDragX = position.x
    startDragY = position.y;
    ({childWidth: startChildWidth, childHeight: startChildHeight} = childState);
    ({fpLeft:startFpLeft, fpTop:startFpTop, fpWidth:startFpWidth, fpHeight:startFpHeight} = parentState);
    // How much room is available to drag to the right, or to the bottom?
    const rightEdgeOfFp  = startFpLeft + startFpWidth
    const bottomEdgeOfFp = startFpTop  + startFpHeight
    // We allow bottom edge to shift ONLY as far as the bottom edge of window.
    // Even though user can place the fpParent such that bottom edge ( and drag control) are off screen.
    // In other words, there is a more restrictive constraint on the location
    // of the bottom edge during resizing. Specifically, the drag control better remain visible!
    const maxAllowableRightEdgeOfFp  = window.innerWidth  - FP_MIN_TOP_RIGHT_OFFSET
    const maxAllowableBottomEdgeOfFp = window.innerHeight
    startAvailableDragToRight  = maxAllowableRightEdgeOfFp  - rightEdgeOfFp
    startAvailableDragToBottom = maxAllowableBottomEdgeOfFp - bottomEdgeOfFp
    // Another special consideration for bottom edge. 
    // Note that the right edge of the FP MUST always fall within the window.
    // However, the bottom edge is allowed to be 'off screen' -- design goal is FPclose button always visible.
    // Hence startAvailableDragToBottom may be <= 0, if:
    //    1 - equals zero if bottom of FP aligns with bottom of browser.
    //    2 - FP is 'barely offscreen' and drag control partially visible. 
    //    3 - drag control completely off screen, resizing not possible.
    // We only need to worry about case #2.
    // We just treat is like case #1 by forcing:
    startAvailableDragToBottom = Math.max(0, startAvailableDragToBottom)
    // Setting next state 'false' prevents the FP parent from executing 
    // its constraints() function.  We don't want the FP to jump around
    // if/when it occludes the underlying selected cell.
    // At least NOT during resizing.  At end of resizing, we will
    // set this param back to true.  Then the FP parent MAY reposition itself
    // after a resize to avoid occluding the selected cell.
    const newState = {...parentState, isActiveChildDrag: false}
    setParentState( newState)
}


const onDragHandler = ( position: DraggableData, fpLayout: FpLayout,
                childState: ChildState, setChildState: SetChildState,
                parentState: FpParentState, setParentState : SetFpParentState) => {
    //if (isTIMER_ENABLED) { logTime('dragFP', 'onDragHandler') }
    const { minResizeX, maxResizeX, minResizeY, maxResizeY } = fpLayout
    const { fpLeft: currentFpLeft, fpTop: currentFpTop} = parentState
    const deltaFpLeft = currentFpLeft - startFpLeft
    const deltaFpTop = currentFpTop - startFpTop
    let deltaCursorLeft= position.x + deltaFpLeft - startDragX 
    let deltaCursorTop = position.y + deltaFpTop  - startDragY
    let newChildWidth  = deltaCursorLeft + startChildWidth
    let newChildHeight = deltaCursorTop  + startChildHeight
    // Above two values are NOT constrained to min/max width.  We will do that next.
    newChildWidth  = Math.max( minResizeX,  Math.min( maxResizeX,  newChildWidth )) 
    newChildHeight = Math.max( minResizeY, Math.min( maxResizeY, newChildHeight))  
    deltaCursorLeft = newChildWidth  - startChildWidth
    deltaCursorTop  = newChildHeight - startChildHeight
    // If window is an infinite plane, the new Width/Height are simple:
    const newParentWidth  = deltaCursorLeft + startFpWidth
    const newParentHeight = deltaCursorTop  + startFpHeight
    // But eventually, Width/Height MAY take the FP 'off screen'.
    // This is because deltaCursorLeft/Top is greater than the initially available drag space.
    // This is the 'excess' beyond what we will allow.  Allways >= 0.
    const excessDragRight  = Math.max( 0, deltaCursorLeft - startAvailableDragToRight)
    const excessDragBottom = Math.max( 0, deltaCursorTop - startAvailableDragToBottom)
    // We prevent the drag control from exiting the view by shifting
    // the FP left/top edge to keep the right/bottom edge in view.
    // When in this mode, the right/bottom edge of the FP will appear
    // stationary, but the width/height changes by shifting the left/top edge.
    // In most cases, the excess terms will be zero.
    const newFpLeft = startFpLeft - excessDragRight
    const newFpTop  = startFpTop  - excessDragBottom
    const newChildState = {...childState, childWidth:newChildWidth, childHeight:newChildHeight}
    setChildState(newChildState)
    const newParentState = {...parentState, childWidth: newChildWidth,  childHeight: newChildHeight,
                                            fpWidth   : newParentWidth, fpHeight   : newParentHeight,
                                            fpLeft    : newFpLeft,      fpTop      : newFpTop,
                                            isActiveChildDrag: true}
    setParentState( newParentState)
}


const onDragStopHandler = ( fpParentState: FpParentState, setFpParentState : SetFpParentState ) => {
    //if (isTIMER_ENABLED) { stopTimer('dragFP') }
    const newState = {...fpParentState, isActiveChildDrag: false}
    setFpParentState( newState)
}


const closeHandler = ( e : MouseEvent ) => { 
  e.stopPropagation()
  const thisMod = [{newVal: 'none', path: 'activeFp.fpName'}]  
  sessionStateChangeDispatch( thisMod, 'Close floating palette' )
}




type FpContainerProps = {
  legalDisplayedTabIndex: number,
  fpLayout: FpLayout,
  fpParentState: FpParentState,
  setFpParentState: SetFpParentState
}

export const FpContainer : React.FC<FpContainerProps> = ( props ) => {
    const {legalDisplayedTabIndex, fpLayout, fpParentState, setFpParentState} = props
    const {childWidth, childHeight, fpWidth, fpHeight} = fpParentState
    const [childState, setChildState] = useState({childWidth, childHeight})
    const {activeFp, tabInfoArr, childInfoArr, titleBarStrg, isResizableX, isResizableY } = fpLayout 
    const isDraggable = (isResizableY || isResizableX)
    const {fpName} = activeFp
    const {RenderedChild} = childInfoArr[legalDisplayedTabIndex]
    const {totalTabControlHeight} = getTabControlHeight( tabInfoArr )
    return (
      <div className={'rc_fpContainer'}
        style={{
          position: 'relative',
          left: 0, top: 0,
          width : fpWidth,
          height: fpHeight,
          filter: 'drop-shadow(3px 3px 6px rgba(0, 0, 0, 1))',
        }}
        tabIndex={0} // Necessary to capture & stop propagation of keyPress events
        onMouseUp={ (e)=> e.stopPropagation() }
        onKeyDown={ (e)=> e.stopPropagation() }
        onMouseMove={ (e)=> e.stopPropagation() } // Stops tooltips under the floating palette
      >
            <div
              className='floatingPaletteTitleBar'
              style={{
                color: 'black', background: 'DarkGray',
                borderTopLeftRadius: '6px', borderTopRightRadius: '6px',
                width: '100%', height: TITLE_BAR_HEIGHT,
                fontSize: TITLE_BAR_FONTSIZE,
                paddingTop: PADDING_TOP,
                borderWidth : '1px 1px 0px 1px',
                borderColor: 'black', borderStyle: 'solid',
              }}>

                <div
                  className={'unselectable'}
                  style={{ fontWeight: 'bold', textAlign: 'center' }}
                > {titleBarStrg} </div>

                        <button
                          style={{
                            position: 'absolute', top:PADDING_TOP + 1, right:3, 
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            outline: 'none',
                          }}
                          onClick={ e=>closeHandler(e) }
                        >
                            <svg
                              height='14px'
                              style={{fill: 'currentColor'}}
                              viewBox='-6 -6 12 12'
                              width='14px'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <polygon points='1.5 0  5.5 4.5  4 6  0 2  -4 6  -5.5 4.5  -1.5 0  -5.5 -4.5  -4 -6  0 -2  4 -6  5.5 -4.5  1.5 0' />
                            </svg>
                        </button>
                </div>

                <div  className={'Container_BlackBorder'}
                  style={{
                    // Thin black border
                    width: '100%',
                    height: fpHeight - TITLE_BAR_HEIGHT,
                    boxSizing: 'border-box',
                    borderLeftWidth: BLACK_OUTLINE_WIDTH,
                    borderBottomWidth: BLACK_OUTLINE_WIDTH,
                    borderRightWidth: BLACK_OUTLINE_WIDTH,
                    borderTopWidth: 0,
                    borderColor: 'black',
                    borderStyle: 'solid',
                  }}>

                    <div className={'Container_BlueBorder'}
                      style={{
                        // Thicker blue border
                        boxSizing: 'border-box',
                        height: '100%', width:'100%',
                        background: BACKGROUND,
                        borderBottomWidth: BLUE_OUTLINE_WIDTH,
                        borderRightWidth: BLUE_OUTLINE_WIDTH,
                        borderTopWidth: BLUE_OUTLINE_WIDTH,
                        borderColor: BORDER_COLOR, borderStyle: 'solid', 
                      }}>

                        { totalTabControlHeight > 0 &&
                        <div
                          className={'TabControl_AllocatedSpace'}
                          style={{
                            // Tab control had better fit exactly in this space.
                            position: 'absolute',
                            left: BLUE_OUTLINE_WIDTH + BLACK_OUTLINE_WIDTH, 
                            top: TITLE_BAR_HEIGHT + BLUE_OUTLINE_WIDTH,
                            width: childWidth,
                            height: totalTabControlHeight,
                            overflow: 'visible', 
                          }}>
                                <TabControl3 
                                  tabInfoArr={tabInfoArr} 
                                  activeTabSessionPath={`fpStates_ByFpName.${fpName}.activeTab`}
                                  width={childWidth}
                                  legalDisplayedTabIndex={legalDisplayedTabIndex}/>
                        </div>
                        }

                        <div
                          className={'FpContent_AllocatedSpace'}
                          style={{
                            // FP child had better fit exactly in this space.
                            position: 'absolute',
                            left: BLUE_OUTLINE_WIDTH + BLACK_OUTLINE_WIDTH, 
                            top: TITLE_BAR_HEIGHT + BLUE_OUTLINE_WIDTH + totalTabControlHeight,
                            width: childWidth,
                            height: childHeight,                        
                            overflow: 'visible', 
                          }}>
                                <RenderedChild 
                                    activeFp={activeFp}
                                    fpParentState={fpParentState}
                                    setFpParentState={setFpParentState}
                                />

                                {isDraggable &&  <>
                                <DraggableCore
                                    enableUserSelectHack={false}
                                    //handle='.floatingPaletteResizeControl'
                                    onStart={ ( _, data ) => onDragStartHandler(data, childState, fpParentState, setFpParentState)}
                                    onDrag ={ ( _, data ) => onDragHandler(data, fpLayout, childState, setChildState, fpParentState, setFpParentState)}
                                    onStop ={ ( ) => onDragStopHandler( fpParentState, setFpParentState) }>  
                                          <div style={{   
                                              position: 'absolute', bottom: 0, right: 0, 
                                              width: resizeHeightWidth, 
                                              height: resizeHeightWidth,
                                              //backgroundColor: 'red',
                                          }}> 

                                              <SVGresizeTextEdit/> 

                                          </div>
                                </DraggableCore> </>}

                        </div>
                    </div>
            </div>
      </div>
    )
  
}


