import type { RootState }           from '../redux/store'  // my testPalette given access to sessionState
import type { ActiveFp } from '../types'
import type {FpLayout, FpChildRenderProps} from '../appCode/getSbFpConfigMappings'

import {useSelector}                from 'react-redux'
import {useContext}                 from 'react'
import {TableComputedDataContext}   from '../viewTableTriple/TtGetComputedData'
import {activeFpToTableViewTile}    from '../viewTable/getTableTile'
import FilterRules                      from '../sharedComponents/FilterRules'
import {getFilterRulesWidthAndHeight}   from '../sharedComponents/FilterRules'

const MARGIN_LR = 10    // Left and right margin
const MARGIN_TB = 10    // Top and bottom margin
const MAX_NUM_FILTERS = 8

export const useGetTableRowFiltering : (arg0:ActiveFp)=>FpLayout = (activeFp)=>{ 
    // ALL floating palettes within must use identical useContext and useSelector calls.
    // I believe this is true, even when changing views.  (Any change that would
    // allow the floatPalette to remain open between fpName changes or view changes)
    const tCD = useContext( TableComputedDataContext )
    useSelector( (state:RootState) => state.session.activeFp )
    useSelector( (state:RootState) => state.session.fpStates_ByFpName['fpTest'] )
    // For now, put an upper limit on the number of filters.  After this limit,
    // the empty filter at bottom will be out-of-view, hence user cannot append
    // any more filters.
    const numFilters = Math.min(tCD.tablelook.attributes.rowFilters.length, MAX_NUM_FILTERS)
    let {width, height} = getFilterRulesWidthAndHeight('2line', numFilters)
    // Add in the margins used in our Fp container
    width  += 2*MARGIN_LR
    height += 2*MARGIN_TB
    // Only a single tab, so it will not display tabs.
    const tab0 = {  
        tabIndex: 0,      // We set activeTab value, even when tabs are not displayed.                              
        tabStrg1: 'none', // Only a single tab, so this is not displayed.
        tabStrg2 : 'none', 
        isErroneous: false,
    }
    const child0 = {
        childWidth: width,  
        childHeight: height,  
        RenderedChild: TableFp_RowFiltering
    }
    // Single tab, Not dependent on canEdit or tabIndex
    return { 
        activeFp,     // To use this test palette, assign this function to the  'tableMainTitle' key in getFpLayout_ByFpName
        titleBarStrg: 'Row Filters', 
        isDraggable: true,
        isResizableX: false,
        isResizableY: false,
        minResizeX: 100,
        minResizeY: 100,
        maxResizeX: 100,
        maxResizeY: 100, 
        tabInfoArr: [tab0],
        childInfoArr: [child0], 
        getSelectedCellGeometry: ( ) => { return activeFpToTableViewTile(tCD) }  
    }
}



const TableFp_RowFiltering : React.FC<FpChildRenderProps> = () => {
    const tableComputedData = useContext( TableComputedDataContext )
    return (
        < div style={{marginLeft:MARGIN_LR, marginTop:MARGIN_TB}}>
            <FilterRules
                    tableComputedData={tableComputedData}
                    multiLineMode={'2line'}
                    resourceName={'rowFilters'} 
                    maxNumFilters={MAX_NUM_FILTERS}/>
        </div>
    )
}