import type {
  AddressFamily,
  AxiosBasicCredentials,
  AxiosProxyConfig,
  AxiosResponse,
  Method,
  RawAxiosRequestHeaders,
  ResponseType,
  TransitionalOptions,
  responseEncoding
} from 'axios'

export type ParentType = 'plots' | 'tables'
export type ResourceType = ParentType | 'tabledatas' | 'tablelooks' | 'users' | 'userspis' | 'tags' | 'changes' | 'searchtables' | 'logins' | 'sources' | 'sourcemls' | 'sourcesvs'

export type ResourceId = {
  id: string,
  type: ResourceType,
  meta?: Object,
}

export type Attributes = {[key: string]: any}

type Links = {
  self: string,
}

type BaseRelationship = {
  links?: {
    self: string,    // External link to this resource
    related: string,
  },
  meta?: {
    relation: string,
    readOnly: boolean,
  },
  data: any,
}

export type SingleRelationship = BaseRelationship & {
  data: ResourceId | null,
}

export const getDefaultSingleRelationship = () : SingleRelationship => {
  const newObj = { data: null }
  return Object.seal( newObj )
}


export type MultiRelationship = BaseRelationship & {
  data: Array<ResourceId>,
}
export const getDefaultMultiRelationship = () : MultiRelationship => {
  const newObj = { data: [] }
  return Object.seal( newObj )
}


export type Relationships = {
  [key: string]: BaseRelationship,
}

export type Resource = ResourceId & {
  attributes: Attributes,
  links?: Links,
  relationships?: Relationships,
}



// JsonApiError is not an exact match of the jsonapi spec,
// but it does match what jsonapi-server returns:
// a subset of the spec, with status, code,
// title, and detail always present.
export type JsonApiError = {
  status: string,
  code: string,
  title: string,
  detail: string,
  meta?: Object,
}

export type PageParams = {
  offset: number,
  limit: number,
  total: number,
}

export type JsonApiDocument = {
  data?: Resource | null | Array<Resource>,
  errors?: Array<JsonApiError>,
  included?: Array<Resource>,
  jsonapi?: {
    version?: string,
    meta?: Object,
  },
  links?: {
    first?: string,
    last?: string,
    next?: string,
    prev?: string,
    related?: string,
    self?: string,
  },
  meta?: {
    page?: PageParams
  },
}

type Milliseconds = number
type MaxUploadRate = number
type MaxDownloadRate = number

export type SerializableAxiosConfig = {
  url?: string
  method?: Method | string
  baseURL?: string
  headers?: RawAxiosRequestHeaders
  timeout?: Milliseconds
  timeoutErrorMessage?: string
  withCredentials?: boolean
  auth?: AxiosBasicCredentials
  responseType?: ResponseType
  responseEncoding?: responseEncoding | string
  xsrfCookieName?: string
  xsrfHeaderName?: string
  maxContentLength?: number
  maxBodyLength?: number
  maxRedirects?: number
  maxRate?: number | [MaxUploadRate, MaxDownloadRate]
  socketPath?: string | null
  proxy?: AxiosProxyConfig | false
  decompress?: boolean
  transitional?: TransitionalOptions
  insecureHTTPParser?: boolean
  family?: AddressFamily
}

export type AxiosError = {
  message?: string
  code?: string
  config?: SerializableAxiosConfig
  request?: any
  response?: AxiosResponse<any, JsonApiDocument>
}

export type EndpointResult = JsonApiDocument & {endpoint: string}

export type EndpointError = AxiosError & {endpoint: string}

export type ResourceError = AxiosError & {resource: Resource}

export type ResponseError = Error & {response: JsonApiDocument}

export type LocalMod = {
  newVal: any,
  op: 'set'|'add'|'del',
  path: string,
}

export type LocalUpdatePayload = {
  id: string,
  mods: Array<LocalMod>,
  type: ResourceType,
}

// key is resource id
export type ResourceMap = {
  [key: string]: Resource | undefined
}

export type ResourceTypeMap = {
  [key in ResourceType]: ResourceMap
}

export type ResourceIdList = Array<string>

export type ResourceTypeIdList = {
  [key in ResourceType]: ResourceIdList
}

export const defaultResourceTypeIdList = (): ResourceTypeIdList => ({
  changes: [],
  logins: [],
  plots: [],
  searchtables: [],
  sourcemls: [],
  sources: [],
  sourcesvs: [],
  tabledatas: [],
  tablelooks: [],
  tables: [],
  tags: [],
  users: [],
  userspis: []
})
