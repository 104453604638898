import type { InternalColDataType } from '../types'

import invariant from 'invariant'
import { EPSILON } from './plotUtils'

export type DegenerateRangeParams = {
  minR: number
  maxR: number
  isMinForced: boolean
  isMaxForced: boolean
  minR_filteredData: number
  maxR_filteredData: number
  numFilteredRowPts: number
  willUseLogarithmicScale: boolean
  internalDataType: InternalColDataType
}

export type DegenerateRangeResponse = {
  newMin: number
  newMax: number
  newIsMinForced: boolean
  newIsMaxForced: boolean
}

export const findAndRepairDegenerateRange = (params: DegenerateRangeParams): DegenerateRangeResponse => {
  const { isMinForced, isMaxForced, minR_filteredData, maxR_filteredData,
    internalDataType, willUseLogarithmicScale } = params
  let { minR, maxR } = params

  // 1st check is whether this is the 'default' range.
  // When there is no filtered data, rangeCulled  may be at default initial values
  // We check the userConstrained range, rather than filtered range, because filtered range
  // for basisB is always at default conditions for distribution plots.
  // default is +/- Infinity, but sometimes it appears to be cast to a finite
  // number.
  if (minR > 1e308 && maxR < -1e308) {
    // This value works for linear and logarithmic scales
    return { newMin: 1, newMax: 10, newIsMinForced: false, newIsMaxForced: false }
  }

  // When one extent is forced, and the other is not set,
  // then we can get min/max of +/- Infinite values.
  if (isMaxForced && minR === +Infinity) { minR = maxR }
  if (isMinForced && maxR === -Infinity) { maxR = minR }

  //var range = Math.abs(maxR - minR)
  var range = (maxR - minR)
  if (process.env.NODE_ENV === 'development') {
    invariant(isFinite(minR) && isFinite(maxR),
      `Non-numeric range into findAndRepairDegenerateRange (${minR},${maxR})`)
  }

  // If we have a finite range, all is good!
  if (range > EPSILON) {
    return {
      newMin: minR, newMax: maxR,
      newIsMinForced: isMinForced,
      newIsMaxForced: isMaxForced
    }
  }


  // BEYOND THIS POINT:
  // range <= zero, which implies one constraints defined, other undefined but 'out-of-view'.
  // Both constraints defined but equal.

  var newMinMax = { min: 0, max: 0 }

  // Code beyond this point:
  // Case of a 'degenerate' range for one of two possible reasons:
  //  a)  There are no points to plot, hence rangeCulled on input has no
  //      meaning. (And nothing will be encoded in the range value!
  //  b)  There is one plot point, or multiple plot points at the same
  //      coord.  In this case rangeCulled.min === rangeCulled.max
  //  Up to Scry to provide 'rules' for degenerate cases.  The rules are located
  //  here because there are many corner cases.  But one could consider
  //  this entire function just the frontEnd of smartAxisScale.
  //  Essentially this entire function:
  //    - starts with the culled plotPts range. (Adjusted for user constraints)
  //    - and defines the Scry rules in those cases of degenerate ranges.

  var isSomePts = true
  if (isMaxForced && !isMinForced && maxR < minR) { isSomePts = false }
  if (isMinForced && !isMaxForced && minR > maxR) { isSomePts = false }

  //var numPointsMode:string = (numCulledRowPts === 0 ? 'zeroPt' : 'somePts')
  var numPointsMode: string = (isSomePts ? 'somePts' : 'zeroPt')
  var scaleMode: string = (internalDataType === 'string')
    ? 'string'
    : ((willUseLogarithmicScale)
      ? 'numberLogarithmic'
      : 'numberLinear')
  var constraintMode: string = (isMinForced && isMaxForced)
    ? 'bothConstraint'
    : (isMaxForced
      ? 'maxConstraint'
      : (isMinForced
        ? 'minConstraint'
        : 'noConstraint'))

  var isLinear = (scaleMode === 'numberLinear')
  var isMinConstrained = (constraintMode === 'minConstraint')
  var isMaxConstrained = (constraintMode === 'maxConstraint')
  var isNotConstrained = (constraintMode === 'noConstraint')

  // Worse case missing information:
  //    culled range is min:0, max:0
  //    Could be because user set both constraints - they may or may not be points at zero!
  //    Could be because user set one constraint, and filteredData (somePts) are at zero.
  // We need to create some scale to display the plot, but how do
  // we render any axis such that the zero is still in-range, but all
  // other filtered data is 'out-of-range'?
  //
  // Helps to have more information, which is minR_filteredData & maxR_filteredData
  if (isLinear && minR === maxR && minR === 0) {
    if (isLinear && minR_filteredData >= 0) {
      // Culling retained zero, one, or many pts, and values MUST be zero
      // All other culled (removed) points (if they exist) are greater than zero.
      return { newMin: -1, newMax: 0, newIsMinForced: true, newIsMaxForced: true }
    }
    if (isLinear && maxR_filteredData <= 0) {
      // Culling retained zero, one, or many pts, and values MUST be zero
      // All other culled points (if they exist) are less than zero
      return { newMin: 0, newMax: 1, newIsMinForced: true, newIsMaxForced: true }
    }
    if (isLinear && minR_filteredData < 0 && maxR_filteredData > 0) {
      // Culling retained one or more pts with values of zero.
      // And all other filtered points are scattered at unknown locations.
      // We want to draw a legal axis that includes zero, but no other
      // culled points.
      // All we can do is make a guess!
      return { newMin: -.001, newMax: .001, newIsMinForced: true, newIsMaxForced: true }
    }
  }

  // Case of isSomePts = false, and one constraint set.
  // In other words, the constrained value forced all the viable data 'out-of-range'
  if (isLinear && !isSomePts && isMaxForced && !isMinForced) {
    if (maxR < minR && maxR === 0) {
      return { newMin: -1, newMax: maxR, newIsMinForced: isMinForced, newIsMaxForced: isMaxForced }
    }
    if (maxR < minR && maxR > 0) {
      return { newMin: 0, newMax: maxR, newIsMinForced: isMinForced, newIsMaxForced: isMaxForced }
    }
    if (maxR < minR && maxR < 0) {
      return { newMin: 2 * maxR, newMax: maxR, newIsMinForced: isMinForced, newIsMaxForced: isMaxForced }
    }
  }
  if (isLinear && !isSomePts && isMinForced && !isMaxForced) {
    if (minR > maxR && minR === 0) {
      return { newMin: minR, newMax: 1, newIsMinForced: isMinForced, newIsMaxForced: isMaxForced }
    }
    if (minR > maxR && minR > 0) {
      return { newMin: minR, newMax: 2 * minR, newIsMinForced: isMinForced, newIsMaxForced: isMaxForced }
    }
    if (minR > maxR && minR < 0) {
      return { newMin: minR, newMax: 0, newIsMinForced: isMinForced, newIsMaxForced: isMaxForced }
    }
  }

  // Common case of no range & 'bothConstraints', meaning constraints are equal.
  // Common because it can occur when user is switching the direction
  // of the axis (swapping the min/max constraint values)
  if (isLinear) {
    return { newMin: minR - 1, newMax: maxR + 1, newIsMinForced: isMinForced, newIsMaxForced: isMaxForced }
  }

  // Cases where a zero value on a requested logarithmic scale will force scale to linear !
  // These are cases where there is not enough freedom remaining such that we can avoid
  // drawing the axis through the zero point.
  // Still a degenerate case, but we will fallback to the 'numberLinear' rules.
  if (willUseLogarithmicScale && numPointsMode === 'zeroPt') {
    if (isMinConstrained && minR === 0) scaleMode = 'numberLinear'
    if (isMaxConstrained && maxR === 0) scaleMode = 'numberLinear'
  }
  if (willUseLogarithmicScale && numPointsMode === 'somePts') {
    if (isMinConstrained && (minR === 0 || maxR === 0)) scaleMode = 'numberLinear'
    if (isMaxConstrained && (minR === 0 || maxR === 0)) scaleMode = 'numberLinear'
    if (isMinConstrained && minR < 0 && maxR > 0) scaleMode = 'numberLinear'
    if (isMaxConstrained && maxR > 0 && minR < 0) scaleMode = 'numberLinear'
    if (isNotConstrained && minR === 0) scaleMode = 'numberLinear'   // single pt at zero
  }

  // Worse case missing information:
  //    culled range is min:0, max:0
  //    Could be because user set both constraints - they may or may not be points at zero!
  //    Could be because user set one constraint, and filteredData (somePts) are at zero.
  // We need to create some scale to display the plot, but how do
  // we render any axis such that the zero is still in-range, but all
  // other filtered data is 'out-of-range'?
  //
  // Helps to have more information, which is minR_filteredData & maxR_filteredData
  if (isLinear && minR === maxR && minR === 0) {
    if (isLinear && minR_filteredData >= 0) {
      // Culling retained zero, one, or many pts, and values MUST be zero
      // All other culled points (if they exist) are greater than zero.
      return { newMin: -1, newMax: 0, newIsMinForced: true, newIsMaxForced: true }
    }
    if (isLinear && maxR_filteredData <= 0) {
      // Culling retained zero, one, or many pts, and values MUST be zero
      // All other culled points (if they exist) are less than zero
      return { newMin: 0, newMax: 1, newIsMinForced: true, newIsMaxForced: true }
    }
    if (isLinear && minR_filteredData < 0 && maxR_filteredData > 0) {
      // Culling retained one or more pts with values of zero.
      // And all other filtered points are scattered at unknown locations.
      // We want to draw a legal axis that includes zero, but no other
      // culled points.
      // All we can do is make a guess!
      return { newMin: 1e-99, newMax: 1E+99, newIsMinForced: true, newIsMaxForced: true }
    }
  }

  var combinedMode: string = numPointsMode + '_' + scaleMode + '_' + constraintMode

  switch (combinedMode) {

    // Tested JPS 7/28/19
    case 'zeroPt_string_noConstraint':
    case 'zeroPt_string_minConstraint':
    case 'zeroPt_string_maxConstraint':
    case 'zeroPt_string_bothConstraint':
      // Need to pick string coord such
      // that it is out-of-range.
      // With no labels displayed.
      newMinMax = { min: minR, max: maxR }
      break
    case 'somePts_string_noConstraint':
    case 'somePts_string_minConstraint':
    case 'somePts_string_maxConstraint':
    case 'somePts_string_bothConstraint':
      // Zero range and somePts mean a single
      // string; minV === maxV; Ok to plot
      // a string value if it exists.  Or
      // nothing if this minV has no corresponding
      // string.
      newMinMax = { min: minR, max: maxR }
      break

    // verified
    case 'zeroPt_numberLinear_noConstraint':
      newMinMax = { min: 0, max: 100 }
      break
    case 'zeroPt_numberLinear_minConstraint':
      if (minR < 0) newMinMax = { min: minR, max: 0 }
      if (minR === 0) newMinMax = { min: 0, max: 100 }
      if (minR > 0) newMinMax = { min: minR, max: minR * 2 }
      break
    case 'zeroPt_numberLinear_maxConstraint':
      if (maxR < 0) newMinMax = { min: 2 * maxR, max: maxR }
      if (maxR === 0) newMinMax = { min: -100, max: 0 }
      if (maxR > 0) newMinMax = { min: 0, max: maxR }
      break

    // verified    minR === maxR
    case 'somePts_numberLinear_noConstraint':
      if (minR < 0) newMinMax = { min: 2 * minR, max: 0 }
      if (minR === 0) newMinMax = { min: -100, max: 100 }
      if (minR > 0) newMinMax = { min: 0, max: 2 * minR }
      break
    case 'somePts_numberLinear_minConstraint':
      // minR === maxR occurs when the minR > all plottable data.
      if (minR === maxR) newMinMax = { min: minR, max: 2 * minR }
      else newMinMax = { min: minR, max: minR + 2 * (maxR - minR) }  // center pt
      break
    case 'somePts_numberLinear_maxConstraint':
      if (minR === maxR) newMinMax = { min: maxR / 2, max: maxR }
      else newMinMax = { min: maxR - 2 * (maxR - minR), max: maxR }  //center pt
      break

    // verified
    case 'zeroPt_numberLogarithmic_noConstraint':
      newMinMax = { min: 1, max: 10 }
      break
    case 'zeroPt_numberLogarithmic_minConstraint':
      if (minR < 0) newMinMax = { min: minR, max: minR / 2 }
      // if ( minR === 0 ) not allowed. Was forced to 'numberLinear' earlier.
      if (minR > 0) newMinMax = { min: minR, max: minR * 2 }
      break
    case 'zeroPt_numberLogarithmic_maxConstraint':
      if (maxR < 0) newMinMax = { min: maxR * 2, max: maxR }
      // if ( minR === 0 ) not allowed. Was forced to 'numberLinear'
      if (maxR > 0) newMinMax = { min: maxR / 2, max: maxR }
      break


    // verified   minR === maxR
    case 'somePts_numberLogarithmic_noConstraint':
      if (minR < 0) newMinMax = { min: minR * 2, max: minR / 2 }
      // if ( minR === 0 ) not allowed. Was forced to 'numberLinear'
      if (minR > 0) newMinMax = { min: minR / 2, max: minR * 2 }
      break
    case 'somePts_numberLogarithmic_minConstraint':
      if (minR === maxR && minR < 0) newMinMax = { min: minR, max: minR / 10 }  // if minR < 0 then maxR < 0
      else if (minR === maxR && minR > 0) newMinMax = { min: minR, max: 10 * minR }  // if minR < 0 then maxR < 0
      else if (minR < 0) newMinMax = { min: minR, max: -maxR * maxR / minR }  // if minR < 0 then maxR < 0
      // if ( minR === 0 ) not allowed. Was forced to 'numberLinear' earlier.
      // last case is minR > 0
      else newMinMax = { min: minR, max: maxR * maxR / minR }  // if minR > 0 then maxR > 0
      break
    case 'somePts_numberLogarithmic_maxConstraint':
      if (minR === maxR && maxR < 0) newMinMax = { min: maxR * 10, max: maxR }  // if minR < 0 then maxR < 0
      else if (minR === maxR && maxR > 0) newMinMax = { min: maxR / 10, max: maxR }  // if minR < 0 then maxR < 0
      else if (maxR < 0) newMinMax = { min: -minR * minR / maxR, max: maxR }
      // if ( minR === 0 ) not allowed. Was forced to 'numberLinear' earlier.
      // last case is maxR > 0
      else newMinMax = { min: minR * minR / maxR, max: maxR }
      break

    default:
      if (process.env.NODE_ENV === 'development') {
        invariant(false, `Unexpected case: ${combinedMode} in function findAndRepairDegenerateRange()`)
      } else {
        newMinMax = { min: 0, max: 100 }
      }
  }
  return { newMin: newMinMax.min, newMax: newMinMax.max, newIsMinForced: isMinForced, newIsMaxForced: isMaxForced }
}
