import type { JSX } from 'react'
import { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { SCRY_BLUE } from '../sharedComponents/constants'

type OwnProps = {
  children: JSX.Element | JSX.Element[] | string,
  url: string,
  isSelected: boolean,
  closePopup: () => void,
}

class NavColLink extends PureComponent<OwnProps> {

  render() {
    const { url, isSelected, children, closePopup } = this.props
    //console.log( 'Render NavColLink', url )
    return (
      <Link
        className={'rc_NavColLink'}
        style={{
          color: 'white',
          background: isSelected ? 'grey' : SCRY_BLUE,
          boxSizing: 'border-box',
          fontWeight: 'bold',
          paddingTop: '6px',
          paddingBottom: '6px',
          paddingLeft: '16px',
          textDecoration: 'none'
        }}
        to={url}
        onClick={closePopup}
      >
        {children}
      </Link>
    )
  }
}

export default NavColLink
