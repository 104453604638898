import { PayloadAction, createSlice } from "@reduxjs/toolkit"

// export const initAuth = createAction(APP_AUTH_INIT, (): void => {return})
// export const setAuthToken = createAction(APP_AUTH_TOKEN, (authToken: string): string => (authToken))
// export const setAuthUserId = createAction(APP_AUTH_USERID, (authUserId: string): string => (authUserId))
// export const setSourcesSort = createAction(APP_SOURCES_SORT, (sourcesSort: string): string => (sourcesSort))
// export const setTablesSort = createAction(APP_TABLES_SORT, (tablesSort: string): string => (tablesSort))
// export const setUsersQuery = createAction(APP_USERS_QUERY, (usersQuery: string): string => (usersQuery))
// export const setUsersSort = createAction(APP_USERS_SORT, (usersSort: string): string => (usersSort))

export type AppState = {
  authToken: string
  authUserId: string
  sourcesSort: string
  tablesSort: string
  tablesQuery: string
  tablesTags: Array<string>
  usersQuery: string
  usersSort: string
}

const defaultAppState: AppState = {
  authToken: localStorage.getItem('token') || '',
  authUserId: localStorage.getItem('userid') || '',
  sourcesSort: '',
  tablesSort: '',
  tablesQuery: '',
  tablesTags: [],
  usersQuery: '',
  usersSort: '',
}

// const setAuthTokenHandler = (state: AppState, action: ActionType<typeof setAuthToken>): AppState => {
//   const authToken = action.payload
//   const newState = immutable.wrap(state).set(['authToken'], authToken).value()
//   return newState
// }

// const setAuthUserIdHandler = (state: AppState, action: ActionType<typeof setAuthUserId>): AppState => {
//   const authUserId = action.payload
//   const newState = immutable.wrap(state).set(['authUserId'], authUserId).value()
//   return newState
// }

// const setPlotsSortHandler = (state: AppState, action: ActionType<typeof setPlotsSort>): AppState => {
//   const plotsSort = action.payload
//   const newState = immutable.wrap(state).set(['plotsSort'], plotsSort).value()
//   return newState
// }

// const setSourcesSortHandler = (state: AppState, action: ActionType<typeof setSourcesSort>): AppState => {
//   const sourcesSort = action.payload
//   const newState = immutable.wrap(state).set(['sourcesSort'], sourcesSort).value()
//   return newState
// }

// const setUsersSortHandler = (state: AppState, action: ActionType<typeof setUsersSort>): AppState => {
//   const usersSort = action.payload
//   const newState = immutable.wrap(state).set(['usersSort'], usersSort).value()
//   return newState
// }

// const setTablesQueryHandler = (state: AppState, action: ActionType<typeof setTablesQuery>): AppState => {
//   const tablesQuery = action.payload
//   const newState = immutable.wrap(state).set(['tablesQuery'], tablesQuery).value()
//   return newState
// }

// const setTablesSortHandler = (state: AppState, action: ActionType<typeof setTablesSort>): AppState => {
//   const tablesSort = action.payload
//   const newState = immutable.wrap(state).set(['tablesSort'], tablesSort).value()
//   return newState
// }

// const addTablesTagsHandler = (state: AppState, action: ActionType<typeof addTablesTags>): AppState => {
//   const tablesTag = action.payload
//   if (state.tablesTags.indexOf(tablesTag) < 0) {
//     const newState = immutable.wrap(state).push(['tablesTags'], tablesTag).value()
//     return newState
//   }
//   return state
// }

// const removeTablesTagsHandler = (state: AppState, action: ActionType<typeof removeTablesTags>): AppState => {
//   const tablesTag = action.payload
//   const index = state.tablesTags.indexOf(tablesTag)
//   if (index >= 0) {
//     const newState = immutable.wrap(state).del(['tablesTags', index]).value()
//     return newState
//   }
//   return state
// }

// const setTablesFocusHandler = (state: AppState, action:  ActionType<typeof setTablesFocus>): AppState => {
//   const tablesFocus = action.payload
//   const newState = immutable.wrap(state).set(['tablesFocus'], tablesFocus).value()
//   return newState
// }

// const setUsersQueryHandler = (state: AppState, action: ActionType<typeof setUsersQuery>): AppState => {
//   const usersQuery = action.payload
//   const newState = immutable.wrap(state).set(['usersQuery'], usersQuery).value()
//   return newState
// }

// const appReducer = (state: AppState = defaultAppState, action: AppAction): AppState => {
//   switch (action.type) {
//     case APP_AUTH_TOKEN:
//       return setAuthTokenHandler(state, action)
//     case APP_AUTH_USERID:
//       return setAuthUserIdHandler(state, action)
//     case APP_PLOTS_SORT:
//       return setPlotsSortHandler(state, action)
//     case APP_SOURCES_SORT:
//       return setSourcesSortHandler(state, action)
//     case APP_TABLES_FOCUS:
//       return setTablesFocusHandler(state, action)
//     case APP_TABLES_QUERY:
//       return setTablesQueryHandler(state, action)
//     case APP_TABLES_SORT:
//       return setTablesSortHandler(state, action)
//     case APP_TABLES_TAGS_ADD:
//       return addTablesTagsHandler(state, action)
//     case APP_TABLES_TAGS_REMOVE:
//       return removeTablesTagsHandler(state, action)
//     case APP_USERS_QUERY:
//       return setUsersQueryHandler(state, action)
//     case APP_USERS_SORT:
//       return setUsersSortHandler(state, action)
//     default:
//       return state
//   }
// }

// export default appReducer

const appSlice = createSlice({
  name: 'app',
  initialState: defaultAppState,
  reducers: {
    initAuth(_): void {
    },
    setAuthToken(state, action: PayloadAction<string>): void {
      const authToken = action.payload
      state.authToken = authToken
    },
    setAuthUserId(state, action: PayloadAction<string>): void {
      const authUserId = action.payload
      state.authUserId = authUserId
    },
    setSourcesSort(state, action: PayloadAction<string>): void {
      const sourcesSort = action.payload
      state.sourcesSort = sourcesSort
    },
    setTablesSort(state, action: PayloadAction<string>): void {
      const tablesSort = action.payload
      state.tablesSort = tablesSort
    },
    setUsersQuery(state, action: PayloadAction<string>): void {
      const usersQuery = action.payload
      state.usersQuery = usersQuery
    },
    setUsersSort(state, action: PayloadAction<string>): void {
      const usersSort = action.payload
      state.usersSort = usersSort
    }
  }
})

export const {
  initAuth,
  setAuthToken,
  setAuthUserId,
  setSourcesSort,
  setTablesSort,
  setUsersQuery,
  setUsersSort
} = appSlice.actions

export const APP_AUTH_INIT = appSlice.actions.initAuth.toString()
export const APP_AUTH_TOKEN = appSlice.actions.setAuthToken.toString()
export const APP_AUTH_USERID = appSlice.actions.setAuthUserId.toString()

export default appSlice.reducer
