import { PureComponent } from 'react'
import { SCRY_DARK_BLUE } from '../sharedComponents/constants'

/*
// For debugging, grossly exagerate the next two heights
const heightAtCenterOfTaper = 3
const heightAtEdgeOfTaper   = 8  // Keep even
const totalComponentHeight  = heightAtEdgeOfTaper + heightAtCenterOfTaper
const CurveContainerHeight  = heightAtEdgeOfTaper/2

// Top placements
const positionTop_topHalf = 1
const positionTop_bottomHalf = 1 + CurveContainerHeight + heightAtCenterOfTaper

// tapering curve lengths:
const lrLen = '80'
const tbLen = heightAtEdgeOfTaper / 2
*/

type OwnProps = {
  background: string,
}
type DefaultProps = {
  heightAtCenterOfTaper: number
  heightAtEdgeOfTaper: number
}
type Props = OwnProps & DefaultProps

class HorzTaperedDivider extends PureComponent<Props> {

  static defaultProps: DefaultProps = {
    // For debugging, grossly exagerate the next two heights
    heightAtCenterOfTaper : 3,
    heightAtEdgeOfTaper   : 8, // Keep even
  }

  render () {
    const {background, heightAtEdgeOfTaper, heightAtCenterOfTaper} = this.props

    const totalComponentHeight  = heightAtEdgeOfTaper + heightAtCenterOfTaper
    const CurveContainerHeight  = heightAtEdgeOfTaper/2
    // Top placements
    const positionTop_topHalf = 1
    const positionTop_bottomHalf = 1 + CurveContainerHeight + heightAtCenterOfTaper
    // tapering curve lengths:
    const lrLen = '80'
    const tbLen = heightAtEdgeOfTaper / 2

    return (
      <div className={'rc_HorzTaperedComponent'}
          style={{
            flex: '0 0 auto',
            position:'relative', top:0, left:0,
            height:totalComponentHeight, width:'100%',
            // Use pink for debugging
            background,
            //background: 'pink',
          }}>

        <div className={'center'}
            style={{
                // This is the blue that 'leaks' through the two curved edges.
                // But the blue will also leak throught at the top/bottom edges
                // due to anti-aliasing (I believe) and will be visible as thin
                // faint blue edge.  So, we can insure this never appears
                // By making SCRY_DARK_BLUE container a total height that
                // is one pixel smaller at both the top/bottom edges.
                position:'absolute', left:0,
                top    :positionTop_topHalf+1 , // One pixel internal to the parent.
                height : totalComponentHeight-2,  // One pixel smaller at top/bottom edge
                width  : '100%',
                background:SCRY_DARK_BLUE,
            }}/>

        <div className={'topHalf'}
          style={{
              position:'absolute', top:positionTop_topHalf, left:0,
              height: CurveContainerHeight, width: '100%',
              borderRadius: `0px 0px ${lrLen}px ${lrLen}px / 0px 0px ${tbLen}px ${tbLen}px`,
              // Use green for debugging:
              //background: 'green',
              background,
          }}/>
        <div className={'bottomHalf'}
          style={{
              position:'absolute', top: positionTop_bottomHalf, left:0,
              height: CurveContainerHeight, width:'100%',
              borderRadius: `${lrLen}px ${lrLen}px 0px 0px / ${tbLen}px ${tbLen}px 0px 0px`,
              // Use red for debugging:
              //background: 'red',
              background,
          }}/>



      </div>
    )
  }
}

export default HorzTaperedDivider
