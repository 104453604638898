import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export type TooltipState = {
  toolTipStr: string | null
  toolTipTargetHeight: number
  toolTipTargetLeft: number
  toolTipTargetTop: number
  toolTipTargetWidth: number
  toolTipPlacement: string
  toolTipAddedExtension: number
  toolTipVisible: boolean
  toolTipOverride: boolean
}

const defaultTooltipState: TooltipState = {
  toolTipStr: null,
  toolTipTargetHeight: 0,
  toolTipTargetLeft: 0,
  toolTipTargetTop: 0,
  toolTipTargetWidth: 0,
  toolTipPlacement: 'above',
  toolTipAddedExtension: 0,
  toolTipVisible: false,
  toolTipOverride: false
}

const tooltipSlice = createSlice({
  name: 'tooltip',
  initialState: defaultTooltipState,
  reducers: {
    createTooltipDisplayAction(state, action: PayloadAction<TooltipState>): void {
      const {toolTipStr,
        toolTipTargetHeight,
        toolTipTargetLeft,
        toolTipTargetTop,
        toolTipTargetWidth,
        toolTipPlacement,
        toolTipAddedExtension,
      } = action.payload
      if (state.toolTipOverride) {
        // If the tooltip is overridden, don't change the display
        return
      }
      if (toolTipStr !== state.toolTipStr) {
        state.toolTipStr = toolTipStr
      }
      if (toolTipTargetHeight !== state.toolTipTargetHeight) {
        state.toolTipTargetHeight = toolTipTargetHeight
      }
      if (toolTipTargetLeft !== state.toolTipTargetLeft) {
        state.toolTipTargetLeft = toolTipTargetLeft
      }
      if (toolTipTargetTop !== state.toolTipTargetTop) {
        state.toolTipTargetTop = toolTipTargetTop
      }
      if (toolTipTargetWidth !== state.toolTipTargetWidth) {
        state.toolTipTargetWidth = toolTipTargetWidth
      }
      if (toolTipPlacement !== state.toolTipPlacement) {
        state.toolTipPlacement = toolTipPlacement
      }
      if (toolTipAddedExtension !== state.toolTipAddedExtension) {
        state.toolTipAddedExtension = toolTipAddedExtension
      }
    },
    createTooltipVisibilityAction(state, action: PayloadAction<boolean>): void {
      const toolTipVisible = action.payload
      if (state.toolTipOverride) {
        // If the tooltip is overridden, don't change the visibility
        return
      }
      if (toolTipVisible !== state.toolTipVisible) {
        state.toolTipVisible = toolTipVisible
      }
    },
    createTooltipVisibilityOverride(state, action: PayloadAction<boolean>): void {
      const toolTipOverride = action.payload
      if (toolTipOverride !== state.toolTipOverride) {
        state.toolTipOverride = toolTipOverride
        state.toolTipVisible = false
      }

    }
  }
})

export const {
  createTooltipDisplayAction,
  createTooltipVisibilityAction,
  createTooltipVisibilityOverride
} = tooltipSlice.actions

export const TOOLTIP_DISPLAY = tooltipSlice.actions.createTooltipDisplayAction.toString()

export default tooltipSlice.reducer
