import type { ReactNode } from 'react'
import { Component } from 'react'
import { SCRY_BLUE, TOP_MENUBAR_HEIGHT, NAV_COLUMN_WIDTH } from '../sharedComponents/constants'
import NavUserMenu from '../sharedComponents/NavUserMenu'
import NotifyReplayButton from '../viewNotifications/NotifyReplayButton'

type OwnProps = {
  innerWidth: number   // This input only used to trigger a re-render on window resizing.
  children?: ReactNode
}

export default class TopMenuBar extends Component<OwnProps> {

  render() {
    const { children } = this.props
    return (
      <div className={'rc_TopMenuBar'}
        style={{
          position: 'absolute',
          left: NAV_COLUMN_WIDTH,
          top: 0,
          height: TOP_MENUBAR_HEIGHT,
          width: window.innerWidth - NAV_COLUMN_WIDTH,
          backgroundColor: SCRY_BLUE,
          color: 'rgba(255,255,255,0.85)',
          display: 'flex',
          flexFlow: 'row nowrap',
          paddingLeft: '30px',
          paddingRight: '40px',
        }}>

        {/* Buttons to the left */}
        <div
          style={{
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flex: '0 0 auto'
          }}>
          {children}
        </div>

        {/* This is the space between buttons on the left versus right.
          This is the only item allowed to resize its width.
          Keeps buttons to the far left and far right */}
        <div
          style={{
            width: 30,
            flex: '1 1 auto'
          }} />

        {/* Buttons to the right */}
        <div
          style={{
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: '0 0 auto'
          }} >
          <div style={{
            flex: '0 0 auto'
          }}>
            <NotifyReplayButton />
          </div>
          <div
            style={{
              flex: '0 0 auto'
            }}>
            <NavUserMenu />
          </div>
        </div>
      </div>
    )
  }
}
