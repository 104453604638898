import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SessionState, getDefaultSessionState } from '../appCode/getDefaultSessionState'
import { LightweightMod } from '../types'

type SparseSessionState = Partial<SessionState>
//export type SessionState = SessionState //used by store.ts
const DEBUG = false

// Helper function to perform deep merge
function deepMerge(target: any, source: any): void {
  if (typeof target === 'object' && typeof source === 'object') {
    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        if (typeof source[key] === 'object' && !Array.isArray(source[key])) {
          if (!target[key]) {
            target[key] = {};
          }
          deepMerge(target[key], source[key]);
        } else {
          target[key] = source[key];
        }
      }
    }
  }
}

// Helper function to perform deep comparison
function deepCompare(target: any, source: any, path: string = '', changes: any = {}): any {
  if (typeof target === 'object' && typeof source === 'object') {
    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        const newPath = path ? `${path}.${key}` : key;
        if (typeof source[key] === 'object' && !Array.isArray(source[key])) {
          if (!target[key]) {
            changes[newPath] = { old: undefined, new: source[key] };
          } else {
            deepCompare(target[key], source[key], newPath, changes);
          }
        } else {
          if (target[key] !== source[key]) {
            changes[newPath] = { old: target[key], new: source[key] };
          }
        }
      }
    }
  }
  return changes;
}

const sessionSlice = createSlice({
  name: 'session',
  initialState: getDefaultSessionState(),
  reducers: {
    updateSession(state, action: PayloadAction<SparseSessionState | LightweightMod[]>) {
      if (Array.isArray(action.payload)) {
        console.error('updateSession: LightweightMod[] not implemented - use reactDispatch')
      } else if (typeof action.payload === 'object') {
        if (DEBUG) {
          const payload = action.payload as SessionState;
          const changes = deepCompare(state, payload);
          if (Object.keys(changes).length === 0) {
            console.warn('updateSession: no changes detected');
          } else {
            console.table(changes);
          }
        }
        deepMerge(state, action.payload); // Modify the draft state directly
      }
    }
  }
});

export const { updateSession } =
  sessionSlice.actions

export default sessionSlice.reducer
