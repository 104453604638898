const joi = require('joi')

const userAttributes = {
  userDescription: joi.object().keys({
    text: joi.string().allow(''),
    links: joi.array().items(joi.object().keys({
      startCharIndex: joi.number().integer(),
      stopCharIndex: joi.number().integer(),
      href: joi.string().allow('')
    }))
  }),
  updatedDate: joi.date().iso().description('Date of last update to public profile.'),

  // server side managed data (read only to clients)
  username: joi.string().meta('readonly')
    .description('The user’s public username. To modify this change the username in the userspi.'),
  createdDate: joi.date().iso().allow(null).meta('readonly').description('Read only date user joined.'),
  numTables: joi.number().integer().allow(null).meta('readonly').description('Read only count of tables owned by user.'),
  numFollowers: joi.number().integer().allow(null).meta('readonly').description('Read only count of followers.'),
  role: joi.string().allow(null).optional().meta('readonly').description('Read only role of user.'),
}

module.exports = userAttributes
