import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type {AxiosRequestConfig} from 'axios'
import equal from 'fast-deep-equal'
// import invariant from 'invariant'
import type { WritableDraft } from 'immer/dist/types/types-external.js'
// Can't use radash here, because special code is needed to read from an immer WritableDraft,
// which breaks the radash get/set functions.
import type { EndpointError, EndpointResult, JsonApiDocument, LocalUpdatePayload, PageParams, ParentType, Resource, ResourceError, ResourceId, ResourceType, ResourceTypeMap } from './types'
import { ParentID } from '../redux/undoReducer'

import validator from '../sharedFunctions/validator'
import stringToPath from './lodash_mod/stringToPath'
import invariant from 'invariant'

// Map of resource types keyed by resource id.
// Never delete from this, since stale references might
// possibly linger after resource is deleted.
const typeLookupMap: Map<string, ResourceType> = new Map()

export const lookUpTypeFromId = (resourceId: string): ResourceType => {
  const resType = typeLookupMap.get(resourceId)
  invariant(resType, `Resouce type not found for resourceId ${resourceId}`)
  return resType
}

// Map of parent Ids keyed by resource id.
// Managed in sync with typeLookupMap.
const parentLookupMap: Map<string, ParentID> = new Map()

const addResourceToMap = (resource: Resource): void => {
  if (!typeLookupMap.has(resource.id)) {
    typeLookupMap.set(resource.id, resource.type)

    // Tabledatas and tablelooks have a parent which is the table.
    // For now, plots are single resource, so parent is same as
    // the original resource.
    // No parents stored for views other than plots and tables,
    // since no undo yet for other views.
    // TODO: add undo for editing user profile
    let parentType: ParentType | undefined
    let parentId: string | undefined
    if (resource.type === 'tabledatas' || resource.type === 'tablelooks') {
      parentType = 'tables'
      const tableRel = resource.relationships?.table
      invariant(tableRel, `Resource ${resource.type} ${resource.id} has no table relationship`)
      parentId = tableRel.data.id
    } else if (resource.type === 'plots') {
      parentType = 'plots'
      parentId = resource.id
    }

    if (parentType && parentId) {
      const parent: ParentID = {
        parentType, parentId
      }
      parentLookupMap.set(resource.id, parent)
    }
  }
}

export const lookUpParentFromId = (resourceId: string): ParentID => {
  const parentId = parentLookupMap.get(resourceId)
  invariant(parentId, `ParentId not found for resourceId ${resourceId}`)
  return parentId
}

export type ListIdFragment = {
  type: ResourceType
  listIdFragment: string // delete all lists with listId containing this sub string
}

// key is endpoint URL string
type RequestsMap = {
  [key: string]: Promise<JsonApiDocument>
}
export const pendingRequests: RequestsMap = {}

export type ApiState = {
  config: AxiosRequestConfig<JsonApiDocument>,
  lists: {
    [key in ResourceType]: {
      // key is listId string
      [key: string]: {
        ids: Array<string>,
        total: number,
      }
    }
  },
  resources: ResourceTypeMap,
  status: {
    isCreating: number
    isReading: number
    isUpdating: number
    isDeleting: number
  }
}

const defaultApiState: ApiState = {
  config:  {baseURL: `${window._datatables_.serverURL}jsonapi/`},
  lists: {
    changes: {},
    logins: {},
    plots: {},
    searchtables: {},
    sourcemls: {},
    sources: {},
    sourcesvs: {},
    tabledatas: {},
    tablelooks: {},
    tables: {},
    tags: {},
    users: {},
    userspis: {}
  },
  resources: {
    changes: {},
    logins: {},
    plots: {},
    searchtables: {},
    sourcemls: {},
    sources: {},
    sourcesvs: {},
    tabledatas: {},
    tablelooks: {},
    tables: {},
    tags: {},
    users: {},
    userspis: {}
  },
  status: {
    isCreating: 0,
    isReading: 0,
    isUpdating: 0,
    isDeleting: 0
  }
}

export type ListParams = {
  query?: string
  sort?: string
  'filter[table][data][id]'?: string
  'filter[owner][data][id]'?: string
  'filter[tags][data][id]'?: Array<string>
  'filter[follows][data][id]'?: string
}

const forceUpdateOrInsertResource = (state: ApiState, newResource: Resource): void => {
  if (typeof newResource !== 'object') {
    return
  }

  addResourceToMap(newResource)

  state.resources[newResource.type][newResource.id] = newResource
}

const updateOrInsertResource = (state: ApiState, newResource: Resource): void => {
  if (typeof newResource !== 'object') {
    return
  }

  const curResource = state.resources[newResource.type][newResource.id]

  addResourceToMap(newResource)

  state.resources[newResource.type][newResource.id] = newResource
  if (curResource) {
    if (!equal(curResource, newResource)) {
      // Merge readonly props from server, otherwise trust client state for now.
      // Server managed (readonly) props are all in attributes for now.
      const readOnlyAttributes = validator.getReadOnlyAttributes(newResource.type)
      for (const readOnlyAttribute of readOnlyAttributes) {
        const clientValue = curResource.attributes[readOnlyAttribute]
        const serverValue = newResource.attributes[readOnlyAttribute]
        if (clientValue !== serverValue) {
          state.resources[newResource.type][newResource.id]!.attributes[readOnlyAttribute] = serverValue
        }
      }
      // const attributes = Object.keys(newResource.attributes)
      // attributes.forEach((attribute: string): void => {
      //   const clientValue = curResource.attributes[attribute]
      //   const serverValue = newResource.attributes[attribute]
      //   if (clientValue !== serverValue) {
      //     immState = immState.set(['resources', newResource.type, newResource.id, 'attributes', attribute], serverValue)
      //   }
      // })
    }
  }
}

const removeResourceFromState = (state: ApiState, resource: ResourceId): void => {
  const resourceMap = state.resources[resource.type]
  delete resourceMap[resource.id]
}

const updateOrInsertResourcesIntoState = (state: ApiState, data: Array<Resource>|Resource|null|undefined, included: Array<Resource>|undefined): void => {
  if (data || included) {
    const dataResources = data
        ? Array.isArray(data) ? data : [data]
        : []
    const resources = dataResources.concat(included || [])
    for (const resource of resources) {
      updateOrInsertResource(state, resource)
    }
  }
}

export const queryToListId = (url: string): string => {
  const queryOffset = url.indexOf('?')
  if (queryOffset >= 0) {
    const queryStr = url.substring(queryOffset + 1)
    const queryArray = queryStr.split('&')
    const listId = queryArray
          // filter out params related to pagination
          // and params with no value
          .filter((item: string): boolean => (!item.startsWith('page') && !item.endsWith('=')))
          // sort params for stable listIds
          .sort()
          // put it all back together
          .join('&')
    return listId
  }
  return ''
}

export const paramsToListId = (params: ListParams): string => {
  const keys = Object.keys(params)
          // filter out params related to pagination
          .filter((key: string): boolean => (!key.startsWith('page')))
          // sort params for stable listIds
          .sort() as (keyof ListParams)[]
  let listId = ''
  let and = ''
  const maybeAddParam = (key: keyof ListParams, value: string|undefined): void => {
    // filter out params with no value
    // 0 and false are allowed, but empty string is not
    if (value !== undefined && (value || value !== '')) {
      listId += `${and}${key}=${encodeURI(value.toString())}`
      and = '&'
    }
  }
  for (const key of keys) {
    const value = params[key]
    if (Array.isArray(value)) {
      // copy array for immutability (sort below break immutability otherwise)
      const copy = value.slice(0)
      // sort values for consistent listIds
      const sortedCopy = copy.sort()
      for (const subValue of sortedCopy) {
        maybeAddParam(key, subValue)
      }
    } else {
      maybeAddParam(key, value)
    }
  }
  return listId
}

const updateOrCreateListInState = (state: ApiState, selfLink: string, data: Array<Resource>|Resource, page: PageParams | undefined): void => {
  // extract the type and listId  from the self link
  const relSelfLink = state.config.baseURL ? selfLink.replace(state.config.baseURL, '') : selfLink
  const queryOffset = relSelfLink.indexOf('?')
  const type = queryOffset >= 0 ? relSelfLink.substring(0,queryOffset) as ResourceType : relSelfLink as ResourceType
  if (type.indexOf('/') >= 0) {
    // this is fetching an individual resource, or some sub query.
    // Don’t add these to the lists.
    return
  }
  const listId = queryToListId(relSelfLink)

  // clone the existing lists ids array, or make a new one if not present
  const existingList = state.lists[type][listId]?.ids
  const updatedList = existingList ? existingList.slice() : []
  // create the path for the new list if necessary
  if (!existingList) {
    state.lists[type][listId] = {ids: [], total: Number.NaN}
  }

  // insert the ids from the payload into the new lists Ids
  if (data) {
    const dataArray = Array.isArray(data) ? data : [data]
    const offset = page ? page.offset : 0
    const idsLen = offset + dataArray.length
    if (updatedList.length < idsLen) {
      updatedList.length = idsLen
    }
    const len = dataArray.length
    for (let index = 0; index < len; index++)  {
      const item = dataArray[index]
      updatedList[offset + index] = item.id
    }
  }

  if (equal(existingList, updatedList)) {
    return
  }

  // update the newState
  const total = page ? Number(page.total) : Number.NaN // JSON api may return total as a string rather than a number
  state.lists[type][listId].ids = updatedList
  state.lists[type][listId].total = total
}

// Reducers
// export const api = handleActions({
//   [actionTypes.API_SET_AXIOS_CONFIG]: (state: ApiState, action: ActionType<typeof setAxiosConfig>): ApiState => {
//     const {payload: config} = action
//     //return immutable.wrap(state).set(['config'], config).value()
//     return immutable.wrap(state).set(['config'], config).value()
//   },

//   [actionTypes.API_HYDRATE]: (state: ApiState, action: ActionType<typeof hydrateStore>): ApiState => {
//     const {payload: jsonApiDoc} = action
//     const newState = updateOrInsertResourcesIntoState(state, jsonApiDoc.data, jsonApiDoc.included)
//     return newState
//   },

//   [actionTypes.API_UPDATE_LOCAL]: (state: ApiState, action: ActionType<typeof updateResourceLocally>): ApiState => {
//     const {type, id, mods} = action.payload

//     const basePath = ['resources', type, id]
//     const curResource = _.get(state, basePath, null)
//     if (curResource) {
//       let immState = immutable.wrap(state)
//       mods.forEach((mod: LocalMod): void => {
//         const resourcePath = _.toPath(mod.path)
//         const fullPath = basePath.concat(resourcePath)
//         if (mod.op === 'set') {
//           immState = immState.set(fullPath, mod.newVal)
//         } else if (mod.op === 'add') {
//           const pathArray = _.toPath(fullPath)
//           const lastEntry = pathArray.pop()
//           immState = immState.insert(pathArray.join('.'), mod.newVal, lastEntry)
//         } else { // mod.op === 'del'
//           immState = immState.del(fullPath)
//         }
//       })
//       return immState.value()
//     }

//     return state
//   },

//   [actionTypes.API_ADD_LOCAL]: (state: ApiState, action: ActionType<typeof addResourcesLocally>): ApiState => {
//     const {resources} = action.payload
//     if (resources) {
//       return resources.reduce(forceUpdateOrInsertResource, state)
//     } else {
//       return state
//     }
//   },

//   [actionTypes.API_DELETE_LOCAL]: (state: ApiState, action: ActionType<typeof deleteResourceLocally>): ApiState => {
//     const {type, id} = action.payload
//     if (type && id) {
//       return removeResourceFromState(state, {type, id})
//         .value()
//     } else {
//       return state
//     }
//   },

//   [actionTypes.API_WILL_CREATE]: (state: ApiState): ApiState => {
//     status.isCreating = status.isCreating + 1
//     return state
//   },

//   [actionTypes.API_CREATED]: (state: ApiState, action: ActionType<typeof apiActions.apiCreated>): ApiState => {
//     const {payload: jsonApiDoc} = action
//     const newState = updateOrInsertResourcesIntoState(state, jsonApiDoc.data, jsonApiDoc.included)
//     status.isCreating = status.isCreating - 1
//     return newState
//   },

//   [actionTypes.API_CREATE_FAILED]: (state: ApiState): ApiState => {
//     status.isCreating = status.isCreating - 1
//     return state
//   },

//   [actionTypes.API_WILL_READ]: (state: ApiState, action: ActionType<typeof apiActions.apiWillRead>): ApiState => {
//     const {endpoint, promise} = action.payload
//     pendingRequests[endpoint] = promise
//     status.isReading = status.isReading + 1
//     return state
//   },

//   [actionTypes.API_READ]: (state: ApiState, action: ActionType<typeof apiActions.apiRead>): ApiState => {
//     const {payload: jsonApiDoc} = action
//     let newState = updateOrInsertResourcesIntoState(state, jsonApiDoc.data, jsonApiDoc.included)

//     const selfLink = _.get(jsonApiDoc, ['links', 'self'], null)
//     if (selfLink) {
//       const page = _.get(jsonApiDoc, ['meta', 'page'], null)
//       newState = updateOrCreateListInState(newState, selfLink, jsonApiDoc.data, page)
//     }

//     delete pendingRequests[jsonApiDoc.endpoint]
//     status.isReading = status.isReading - 1
//     return newState
//   },

//   [actionTypes.API_READ_FAILED]: (state: ApiState, action: ActionType<typeof apiActions.apiReadFailed>): ApiState => {
//     const {payload: error} = action
//     delete pendingRequests[error.endpoint]
//     status.isReading = status.isReading - 1
//     return state
//   },

//   [actionTypes.API_WILL_UPDATE]: (state: ApiState, action: ActionType<typeof apiActions.apiWillUpdate>): ApiState => {
//     status.isUpdating = status.isUpdating + 1
//     return state
//   },

//   [actionTypes.API_UPDATED]: (state: ApiState, action: ActionType<typeof apiActions.apiUpdated>): ApiState => {
//     const {payload: jsonApiDoc} = action
//     const newState = updateOrInsertResourcesIntoState(state, jsonApiDoc.data, jsonApiDoc.included)
//     status.isUpdating = status.isUpdating - 1
//     return newState
//   },

//   [actionTypes.API_UPDATE_FAILED]: (state: ApiState, action: ActionType<typeof apiActions.apiUpdateFailed>): ApiState => {
//     status.isUpdating = status.isUpdating - 1
//     return state
//   },

//   [actionTypes.API_WILL_DELETE]: (state: ApiState, action: ActionType<typeof apiActions.apiWillDelete>): ApiState => {
//     status.isDeleting = status.isDeleting + 1
//     return state
//   },

//   [actionTypes.API_DELETED]: (state: ApiState, action: ActionType<typeof apiActions.apiDeleted>): ApiState => {
//     const {payload: resource} = action
//     status.isDeleting = status.isDeleting - 1
//     return removeResourceFromState(state, resource)
//       .value()
//   },

//   [actionTypes.API_DELETE_FAILED]: (state: ApiState, action: ActionType<typeof apiActions.apiDeleteFailed>): ApiState => {
//     status.isDeleting = status.isDeleting - 1
//     return state
//   },

//   // [actionTypes.API_CLEAR_STATE]: (state: ApiState, action: ActionType<typeof apiClearState>): ApiState => {
//   //   return immutable.wrap(state).set([], defaultApiState).value()
//   // },

// }, defaultApiState)

const isIndex = (key: string): boolean => {
  const num = Number(key)
  return num > -1 && num % 1 === 0
}

const setOnDraft = (object: WritableDraft<any>, path: string[], value: any): void => {
  // based on the lodash baseSet at https://github.com/lodash/lodash/blob/main/src/.internal/baseSet.ts
  const length = path.length
  const lastIndex = length - 1

  let index = -1
  let nested = object

  while (nested != null && ++index < length) {
    const key = path[index]
    let newValue = value

    if (index !== lastIndex) {
      const objValue = nested[key]
      newValue = (objValue !== undefined)
        ? objValue
        : (isIndex(path[index + 1]) ? [] : {})
    }
    nested[key] = newValue
    nested = nested[key]
  }
}

const getFromDraft = (object: WritableDraft<any>, path: string[]): WritableDraft<any> | undefined => {
  // based on the lodash baseGet at https://github.com/lodash/lodash/blob/main/src/.internal/baseGet.ts
  let index = 0
  const length = path.length

  while (object != null && index < length) {
    object = object[path[index++]]
  }
  return (index && index === length) ? object : undefined
}

const apiSlice = createSlice({
  name: 'api',
  initialState: defaultApiState,
  reducers: {
    setAxiosConfig(state, action: PayloadAction<AxiosRequestConfig<JsonApiDocument>>): void {
      state.config = action.payload
    },
    hydrateStore(state, action: PayloadAction<JsonApiDocument>): void {
      const jsonApiDoc = action.payload
      updateOrInsertResourcesIntoState(state, jsonApiDoc.data, jsonApiDoc.included)
    },
    updateResourceLocally(state, action: PayloadAction<LocalUpdatePayload>): void {
      const {type, id, mods} = action.payload

      const curResource = state.resources[type][id]
      if (curResource) {
        for (const mod of mods) {
          const path: string[] = stringToPath(mod.path)
          if (mod.op === 'set') {
            //console.log(current(state))
            setOnDraft(curResource, path, mod.newVal)
            //console.log(current(state))
          } else {
            const lastPath = path.pop()
            const arrayToModify = getFromDraft(curResource, path)
            if (arrayToModify) {
              invariant(arrayToModify.splice, 'got non array with add or del op code in updateLocal')
              if (mod.op === 'add') {
                arrayToModify.splice(Number(lastPath), 0, mod.newVal)
              } else { // mod.op === 'del'
                arrayToModify.splice(Number(lastPath), 1)
              }
            }
          }
        }
      }
    },
    addResourcesLocally(state, action: PayloadAction<Array<Resource>>): void {
      const resources = action.payload
      if (resources) {
        for (const resource of resources) {
          forceUpdateOrInsertResource(state, resource)
        }
      }
    },
    deleteResourceLocally(state, action: PayloadAction<ResourceId>): void {
      const {type, id} = action.payload
      if (type && id) {
        removeResourceFromState(state, {type, id})
      }
    },
    apiWillCreate(state): void {
      state.status.isCreating = state.status.isCreating + 1
    },
    apiCreated(state, action: PayloadAction<JsonApiDocument>): void {
      const jsonApiDoc = action.payload
      updateOrInsertResourcesIntoState(state, jsonApiDoc.data, jsonApiDoc.included)
      state.status.isCreating = state.status.isCreating - 1
    },
    apiCreateFailed(state, action: PayloadAction<ResourceError>): void {
      state.status.isCreating = state.status.isCreating - 1
    },
    apiWillRead(state, action: PayloadAction<string>): void {
      state.status.isReading = state.status.isReading + 1
    },
    apiRead(state, action: PayloadAction<EndpointResult>): void {
      const jsonApiDoc = action.payload
      updateOrInsertResourcesIntoState(state, jsonApiDoc.data, jsonApiDoc.included)

      const selfLink = jsonApiDoc.links?.self
      if (selfLink) {
        const page = jsonApiDoc.meta?.page
        if (jsonApiDoc.data) {
          updateOrCreateListInState(state, selfLink, jsonApiDoc.data, page)
        }
      }

      delete pendingRequests[jsonApiDoc.endpoint]
      state.status.isReading = state.status.isReading - 1
    },
    apiReadFailed(state, action: PayloadAction<EndpointError>): void {
      const error = action.payload
      delete pendingRequests[error.endpoint]
      state.status.isReading = state.status.isReading - 1
    },
    apiWillUpdate(state, action: PayloadAction<Resource>): void {
      state.status.isUpdating = state.status.isUpdating + 1
    },
    apiUpdated(state, action: PayloadAction<EndpointResult>): void {
      const jsonApiDoc = action.payload
      updateOrInsertResourcesIntoState(state, jsonApiDoc.data, jsonApiDoc.included)
      state.status.isUpdating = state.status.isUpdating - 1
    },
    apiUpdateFailed(state, action: PayloadAction<ResourceError>): void {
      state.status.isUpdating = state.status.isUpdating - 1
    },
    apiWillDelete(state, action: PayloadAction<Resource>): void {
      state.status.isDeleting = state.status.isDeleting + 1
    },
    apiDeleted(state, action: PayloadAction<Resource>): void {
      const resource = action.payload
      state.status.isDeleting = state.status.isDeleting - 1
      removeResourceFromState(state, resource)
    },
    apiDeleteFailed(state, action: PayloadAction<EndpointError>): void {
      state.status.isDeleting = state.status.isDeleting - 1
    },
    apiClearState(state): void {
      state = defaultApiState
    },
    deleteListByListIdFragment(state, action: PayloadAction<ListIdFragment>): void {
      const {type, listIdFragment} = action.payload
      for (const listId in state.lists[type]) {
        if (listId.includes(listIdFragment)) {
          delete state.lists[type][listId]
        }
      }
    },
    deleteListByContents(state, action: PayloadAction<ResourceId>): void {
      const {type, id} = action.payload
      for (const listId in state.lists[type]) {
        if (state.lists[type][listId].ids.find((idInList) => (idInList === id))) {
          delete state.lists[type][listId]
        }
      }
    }
  }
})

export const {
  setAxiosConfig,
  hydrateStore,
  updateResourceLocally,
  addResourcesLocally,
  deleteResourceLocally,
  apiWillCreate,
  apiCreated,
  apiCreateFailed,
  apiWillRead,
  apiRead,
  apiReadFailed,
  apiWillUpdate,
  apiUpdated,
  apiUpdateFailed,
  apiWillDelete,
  apiDeleted,
  apiDeleteFailed,
  apiClearState,
  deleteListByListIdFragment,
  deleteListByContents
} = apiSlice.actions

export const apiActions = apiSlice.actions

export default apiSlice.reducer
