import { Component } from 'react'
import type { ReactNode } from 'react'

class ViewWrapper extends Component<{
  children: ReactNode
}> {
  render() {
    const {children} = this.props
    return (
      <div className={'rc_ViewWrapper'}
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          flexWrap: 'nowrap',
          height: '100%',
          overflow: 'auto',
          width: '100%',
        }}
      >
        {children}
      </div>
    )
  }
}

export default ViewWrapper
