import type { PlotPt, ReactLayer } from './xy_plotTypes'

import { Delaunay } from 'd3-delaunay'
import invariant from 'invariant'

// These points always added to every delaunay.
// They are far out of scope of the plot and will never be found by crosshairs code.
// But they force the delaunay points space to always be 2D, so algorithm doesn't fail
// in degenerate corner cases. ( Otherwise fails for a set of 3 or more colinear pts)
const OFFSITE_PARKING_y0 = + Math.max(window.innerHeight, window.innerWidth) * 4
const OFFSITE_PARKING_y1 = + Math.max(window.innerHeight, window.innerWidth) * 3
const OFFSITE_PARKING_x0 = 0
const OFFSITE_PARKING_x1 = 500

/*
 HOW DELAUNAY IS DELETED between react state changes:
    Almost every state change affects the plot pt locations with respect
    to the mouse position.  There are enough dependencies that the
    easiest approach (coding and conceptually) is deleting the D3 delaunay
    structures EVERY time plotXyComputedData is executed.  Hence the default
    behavior of plotXyComputedData creation is to always clear() any existing
    delaunay data structures.

 HOW DELAUNAY IS CREATED:

 1) The mouseMove event handler in Crosshairs module:
    We ask if delaunay exits.  The first time we ask it will
    NEVER exist!  On this initial event, mouseMove handler calls
    createDelaunayD3structures().   (this module)

 2) This module continues to execute, in parallel with
    potentially additional calls to the mouseMove event handler,
    until all the D3 structures are build.
    During this 'build' phase, the mouseMove handler quicky determines
    that:
      - D3 structures do not yet exist
      - AND mouseMove handler has already initiated the build process.
      - Nothing to do except wait, and a quick exit from mouseMove handler.

 3) Eventually, the D3 structures will exist.  In which case
    mouseMoveHandler calls nearestJointXY2()   (this module)
    If there is no nearby point, another quick exit without displaying
    crosshairs.  If there is a nearby point, mark it with a
    set of green crosshairs, and display the crosshairs
    information panel.

 4) Practically every other action on the plot will trigger
    a createPlotXyComputedData call, in which case the D3 structures
    are cleared, and the mouseMove Event Handle returns to step #1.
*/


interface DelaunayStructure extends Delaunay<Float64Array> {
  startSeed: number;
}

interface DelaunayIndexMapItem {
  x: number;
  y: number;
  plotPtIndex: number;
}
//type DelaunayD3structure = Delaunay<Float64Array>
interface D3andIndexMap {
  delaunayD3structure: DelaunayStructure;
  delaunayIndexMap: DelaunayIndexMapItem[];
}
type DelaunayIndexMap = DelaunayIndexMapItem[]

// State parameters - shared with CrossHairs.mouseMoveEventHandler
var delaunayBuildInProgess = false
var delaunayD3structures: DelaunayStructure[] = []  // Empty array or full length array. NEVER a partial calculation!
var delaunayIndexMaps: DelaunayIndexMap[] = []

export const isDelaunayBuildInProgress = (): boolean => delaunayBuildInProgess
export const getDelaunayD3structures = (): DelaunayStructure[] => delaunayD3structures
export const clearDelaunayD3structures = (): void => {
  delaunayD3structures = []
  delaunayBuildInProgess = false
}

// This is the code called on the 1st frame of moving the mouse over the plot.
// This build function is quick enough (<10 mouseMove frames) that
// the user doesn't even realize they are waiting for this 'build process'.
export const createDelaunayD3structures = (renderedLayersArray: ReactLayer[]): void => {
  delaunayBuildInProgess = true
  const tempDelaunayD3structures: DelaunayStructure[] = []  // Don't write to the delaunayD3structures until completely valid!
  const tempDelaunayIndexMaps: DelaunayIndexMap[] = []  // Ditto
  renderedLayersArray.forEach(thisLayer => {
    var { plotPts, sKey, leftPlotPtToMousePx, bottomPlotPtToMousePx } = thisLayer
    var result = createSingleD3Structure(leftPlotPtToMousePx, bottomPlotPtToMousePx, plotPts, sKey)
    tempDelaunayD3structures.push(result.delaunayD3structure)
    tempDelaunayIndexMaps.push(result.delaunayIndexMap)
  })
  delaunayIndexMaps = tempDelaunayIndexMaps
  delaunayD3structures = tempDelaunayD3structures
  delaunayBuildInProgess = false
}



/* Timing experiments:  How long does it take to build the delaunay
   D3 structures once the mouse begins moving over the plot area?

  Tested plot included 1/2 Million pts.
      - 5 series of 100K pts apiece.
      - Results are average of 10 trials.

  No snapping to pixel grid and mouse in motion (mouse events) : 955ms
  No snapping to pixel grid and no interrupting mouse events   : 880ms
  Snap points to 1 pixel grid : 279ms
  Snap points to 2 pixel grid : 185ms
  Snap points to 3 pixel grid : 168ms
*/

// In case of an empty rendered layer. (Many possible ways the user
// can have no visible plotted data).  In this case, we want the
// code to still build the D3 array.  Code below will include
// only the 3 OFFSITE_PARKING locations.  Valid result, but will
// always be out-of-range of the crosshairs find( ) function.

const createSingleD3Structure = (leftXform: Function, bottomXform: Function, data: PlotPt[], sKey: number): D3andIndexMap => {
  const delaunayIndexMap: DelaunayIndexMapItem[] = []
  for (let plotPtIndex = 0; plotPtIndex < data.length; plotPtIndex++) {
    const pt = data[plotPtIndex];
    var x = Math.round(bottomXform(pt, sKey) / 2) * 2 //round to even mousePx
    var y = Math.round(leftXform(pt, sKey) / 2) * 2
    if (!isNaN(x + y)) delaunayIndexMap.push({ x, y, plotPtIndex })
  }
  // Offsite pts also need to be included in this array, because they can/will
  // eventually be the 'closest' pt.  I use index zero as reference back to the
  // plotPts, however, we never expect it to be used as it should never fall
  // within the 'currentMinPlotSqDist' for found pts.
  delaunayIndexMap.push({ x: OFFSITE_PARKING_x0, y: OFFSITE_PARKING_y0, plotPtIndex: 0 })
  delaunayIndexMap.push({ x: OFFSITE_PARKING_x0, y: OFFSITE_PARKING_y1, plotPtIndex: 0 })
  delaunayIndexMap.push({ x: OFFSITE_PARKING_x1, y: OFFSITE_PARKING_y1, plotPtIndex: 0 })
  const numDelaunayPts = delaunayIndexMap.length
  const floatArray = new Float64Array(2 * numDelaunayPts)
  for (let i = 0; i < numDelaunayPts; i++) {
    floatArray[2 * i] = delaunayIndexMap[i].x
    floatArray[2 * i + 1] = delaunayIndexMap[i].y
  }
  const delaunayD3structure: DelaunayStructure = new Delaunay(floatArray) as DelaunayStructure
  invariant(delaunayD3structure, 'Creating a Delaunay structured failed. But we expect this to never fail. Why??')
  delaunayD3structure.startSeed = 0 // Doesn't exist in d3-delaunay v6.0.0
  while (delaunayD3structure.inedges[delaunayD3structure.startSeed] === -1) { delaunayD3structure.startSeed++ }
  return { delaunayD3structure, delaunayIndexMap }
}

interface NearestJoint {
  delaunayArrayIndex: number
  plotPtIndex: number
  mouseFoundX_px: number
  mouseFoundY_px: number
}

export const nearestJointXY2 = (Apx: number, Bpx: number, delaunayLayersArray: ReactLayer[]): NearestJoint => {
  let currentNearestX: number = 0
  let currentNearestY: number = 0
  let currentMinPlotSqDist = Infinity
  let currentNearestLayerIndex: number = 0
  let currentNearestPlotPtIndex: number | null = null

  const zip = (a: ReactLayer[], b: DelaunayStructure[]): [ReactLayer, DelaunayStructure][] =>
    a.map((e, i) => [e, b[i]])
  let layerIndex = -1

  for (const [delaunayLayer, dd] of zip(delaunayLayersArray, delaunayD3structures)) {
    layerIndex++
    if (!dd || !delaunayLayer.plotPts) {
      continue
    }
    let delaunayPtIndex = dd.find(Apx, Bpx, dd.startSeed)
    if (delaunayPtIndex > delaunayLayer.plotPts.length) {
      continue //found offscreen point
    }
    dd.startSeed = delaunayPtIndex
    const foundX = dd.points[delaunayPtIndex * 2]
    const foundY = dd.points[delaunayPtIndex * 2 + 1]
    let plotSqDist = (Apx - foundX) ** 2 + (Bpx - foundY) ** 2
    if (plotSqDist <= currentMinPlotSqDist) {
      currentNearestX = foundX
      currentNearestY = foundY
      currentMinPlotSqDist = plotSqDist
      currentNearestLayerIndex = layerIndex
      currentNearestPlotPtIndex = delaunayIndexMaps[layerIndex][delaunayPtIndex].plotPtIndex
    }
  }

  const CAPTURE_RANGE_SQUARED = 400 // within 20 mouse px
  // Case of nothing nearby found;
  if (currentNearestPlotPtIndex === null ||
    currentMinPlotSqDist > CAPTURE_RANGE_SQUARED) {
    return {
      delaunayArrayIndex: -1,
      plotPtIndex: 0,
      mouseFoundX_px: 0,
      mouseFoundY_px: 0
    }
  }
  // Case of found a 'nearby' point (less than our capture distance)
  return {
    delaunayArrayIndex: currentNearestLayerIndex,
    plotPtIndex: currentNearestPlotPtIndex,
    mouseFoundX_px: currentNearestX,
    mouseFoundY_px: currentNearestY
  }
}



