import {PureComponent, MouseEvent}  from 'react' 
import type {DraggableData, DraggableEvent} from 'react-draggable'
import type {TableComputedData, DomNode} from '../computedDataTable/getDefaultTableComputedData'
import {sessionStateChangeDispatch} from '../sharedComponents/reactDispatch'
//import invariant from 'invariant'
import SVGwrapper2                  from '../SVGs/SVGwrapper2'
import SVGpointhand                 from '../SVGs/SVGpointhand' 
import {DraggableCore}              from 'react-draggable'
import {initTableResponsiveState, 
        updateTableBlocks, 
        restoreInitialTableResponsiveState,
        calcThumbOffset_fromScrollValue,
        calcThumbLength, synchScrollLeft,}    from './tableResponsiveState'
import {initiateScrollTopAnimation,
        setNewResponsiveStateScrollTop,
        isScrollTopAnimationActive,
        restoreInitialTableResponsiveStateVertScroll,
        stopScrollTopAnimation}     from './tableResponsiveStateVertScroll' 
        
const px = (value: number) : string => { return `${value}px` }

// Don't believe two XY sizes helps, because my SVG container forces the SVG to 'fit'
const HAND_CURSOR_SIZE_Y : number = 32  // Emperically matches scroll control 'handSVG' size to the operating system cursor
const HAND_CURSOR_SIZE_X : number = 32  // Emperically matches scroll control 'handSVG' size to the operating system cursor
const HAND_CURSOR_PARKING_LEFT : number = -5000
// These two are fixed offsets to place the 'point of finger cursor' at 'point of opSystem cursor' (Emperical)
const HAND_CURSOR_FIXED_LEFT : number = -0.40 * HAND_CURSOR_SIZE_X
const HAND_CURSOR_FIXED_TOP  : number = -0.25 * HAND_CURSOR_SIZE_Y

const ENABLE_CUSTOM_CURSOR : boolean = false

type OwnProps = {
  containerPx: number,
  contentPx: number,
  scrollPositionPx : number,
  scrollName: 'vertScroll' | 'horzScroll',
  minScrollHeightBeforeHidden: number,
  top: number,
  right: number,
  foregroundColor: string,
  tableComputedData: TableComputedData,
}
export default class ScrollControl extends PureComponent<OwnProps> {

  calcScrollPosition_fromThumbOffset = ( thumbOffset : number) : number =>{
    //const containerPx = w_thisFrame.movingAllocated
    //const contentPx   = w_thisFrame.movingRequired
    const {containerPx, contentPx} = this.props
    const thumbLength = calcThumbLength(containerPx, contentPx)
    const scrollPosition = Math.round( thumbOffset / (containerPx - thumbLength) * (contentPx - containerPx) )
    return Math.min( contentPx - containerPx, Math.max(0,scrollPosition))
  }

  startMousePosition: number = 0     // at dragStart
  startThumbOffset  : number = 0     // at dragStart
  currentThumbOffset: number = 0   // Scrolled Offset of the thumb from rail's top or left (px) 
  thumbLength       : number = 0   // This thumbLength never changes during use of scrollBars.
  layoutMainDomNode : DomNode = undefined
  grabPositionOffsetWithinTheHorzThumb = 0
  grabPositionOffsetWithinTheVertThumb = 0
  isDnD_ThumbActive : boolean = false   // Need to recognize a mouseClick on thumb 
                                        // is NOT a mouseClick on the rail.

  /* Four event handles
      Click on the rail -> position the content at proportional location of the click
      handleDragStart  -> Initial a drag event
      handleDrag       -> synch the layout DOM to the control
      handleDragStop   -> dispatch a scrollLeft or scrollTop sessionState change
  */
     
  handleDragStart = ( e: DraggableEvent, position:DraggableData ) :void  => {
    //console.log( 'call to handleDragStart')
    // Scroll controls DO NOT suspend pointerEvents. (No DnD needs to suspend pointerEvents)
    e.preventDefault( )
    e.stopPropagation( )
    this.isDnD_ThumbActive = true
    if ( ENABLE_CUSTOM_CURSOR ) { this.customCursorStart( position.x, position.y ) }
    const { scrollName, containerPx, contentPx, scrollPositionPx, 
      tableComputedData:tCD} = this.props
    this.startMousePosition = (scrollName === 'horzScroll' ) ? position.x : position.y
    this.startThumbOffset = calcThumbOffset_fromScrollValue( containerPx, contentPx, scrollPositionPx )
    this.thumbLength = calcThumbLength( containerPx, contentPx ) 
    if ( scrollName === 'horzScroll' ) {
      initTableResponsiveState( tCD )
    } else {  // 'vertScroll'
      setNewResponsiveStateScrollTop( tCD.scrollTop )
      initiateScrollTopAnimation( tCD )  
    }
    //console.log( 'position on dragStart', position.x, position.y, this.startThumbOffset)
  }

  handleDrag = ( e: DraggableEvent, position:DraggableData ):void => {
    e.preventDefault( )
    e.stopPropagation( )
    //console.log( "call to handleDrag" )
    if ( ENABLE_CUSTOM_CURSOR ) {  this.customCursorDrag( position.x, position.y ) }
    const {scrollName, containerPx, contentPx, tableComputedData:tCD } = this.props
    const deltaMousePosition= ( ( scrollName === 'horzScroll' ) ? position.x : position.y ) - this.startMousePosition
    let currentThumbOffset = this.startThumbOffset + deltaMousePosition
    // Clamp the currentThumbOffset to between 0 and (containerPx - thumbLength)
    currentThumbOffset = Math.max( 0, Math.min( currentThumbOffset, containerPx - this.thumbLength))
    const newScrollPosition =this.calcScrollPosition_fromThumbOffset( currentThumbOffset )
    this.currentThumbOffset = currentThumbOffset
    //console.log( 'position on drag', position.x, position.y, currentThumbOffset)
    if ( scrollName === 'horzScroll' ) {
      synchScrollLeft( newScrollPosition, containerPx, contentPx ) 
      updateTableBlocks( tCD.widthObj, newScrollPosition )
    } else { // 'vertScroll
      setNewResponsiveStateScrollTop(  newScrollPosition )  // Shared function in tableResponsiveState.
    }
  }

  handleDragStop = ( ) : void => {                        
      const newVal = this.calcScrollPosition_fromThumbOffset( this.currentThumbOffset )
      let mods
      if ( this.props.scrollName === 'vertScroll' ) { 
        stopScrollTopAnimation( )  // Our 'Request' to stop animation. 
        // Usually takes numRowgroups-1 frames (worseCase) to catch-up rendering
        if ( isScrollTopAnimationActive ) {  // true when in catch-up mode; false when animation is complete/exited.
            setTimeout( this.handleDragStop, 20 )
            return
        }
        // Animation is complete! 
        restoreInitialTableResponsiveStateVertScroll()
        mods = [ {newVal, path: 'activeTableScrollTop'}]
        sessionStateChangeDispatch( mods, 'vertical scrollBar' )
      } 
      else {  // 'horzScroll'
        restoreInitialTableResponsiveState() 
        mods = [ {newVal, path: 'activeTableScrollLeft'}]
        sessionStateChangeDispatch( mods, 'horizontal scrollBar' )
      }
      if ( ENABLE_CUSTOM_CURSOR ) { this.customCursorStop( ) }
      this.isDnD_ThumbActive = false
  }


  handleRailClick= ( event: MouseEvent<HTMLDivElement> ):void => {
      event.stopPropagation( )
      if ( this.isDnD_ThumbActive ) { return }  // NOT a mouseClick on the rail!
      //console.log( 'call to handleRailClick')
      const {target, clientY, clientX } = event
      if (target instanceof HTMLDivElement) {
        const {containerPx, contentPx} = this.props
        const {top: targetTop, left: targetLeft} = target.getBoundingClientRect()
        const x = clientX - targetLeft
        const y = clientY - targetTop
        const clickOffset =  this.props.scrollName === 'horzScroll' ? x : y
        const thumbLength = calcThumbLength( containerPx, contentPx ) // 'length' along the drag direction.
        // If clickOffset too close to top/bottom edge (left/right for x axis scroll),
        // then position thumb at top or bottom edge
        let newThumbOffset = 0
        if ( clickOffset < thumbLength / 2 ) {
          newThumbOffset = 0
        } else if ( clickOffset > containerPx - thumbLength / 2 ) {
          newThumbOffset = containerPx - thumbLength
        } else {
          // Else center the thumb at the clickOffset position
          newThumbOffset = Math.round( clickOffset - thumbLength / 2 )
        }
        const newVal = this.calcScrollPosition_fromThumbOffset( newThumbOffset )
        let mods
        if ( this.props.scrollName === 'vertScroll' ) { 
          mods = [ {newVal, path: 'activeTableScrollTop'}]
          sessionStateChangeDispatch( mods, 'vertical scrollBar' )
        } else {  // 'horzScroll'
          mods = [ {newVal, path: 'activeTableScrollLeft'}]
          sessionStateChangeDispatch( mods, 'horizontal scrollBar' )
        }
      }
  }

  initRailNode = (element: HTMLDivElement): void => {
      //this.rail  = element
      if (this.props.scrollName === 'vertScroll' ) {
        this.props.tableComputedData.tableDomNodes.vertScrollRail = element
      } else {
        this.props.tableComputedData.tableDomNodes.horzScrollRail = element
      }
  }
  initThumbNode = (element: HTMLDivElement): void => {
      //this.thumb = element
      if (this.props.scrollName === 'vertScroll' ) {
        this.props.tableComputedData.tableDomNodes.vertScrollThumb = element
      } else {
        this.props.tableComputedData.tableDomNodes.horzScrollThumb = element
      }
  }


/*  START OF CUSTOM CURSOR THAT TRACKS THE RENDERED (not the 'to be rendered in two frames hence') SCROLL POSITION */
/*  START OF CUSTOM CURSOR THAT TRACKS THE RENDERED (not the 'to be rendered in two frames hence') SCROLL POSITION */

  customHandCursorNode   : DomNode = null
  customPointerCursorNode: DomNode = null
  initCustomHandCursorNode = (element: HTMLDivElement)  : void => { this.customHandCursorNode    = element }
  initCustomPointerCusorNode = (element: HTMLDivElement): void => { this.customPointerCursorNode = element }

  constraintCursorToRail = (x:number, y:number) : {newX:number, newY:number} => {
      return { newX:x, newY:y }
      /*
      const scrollThumbWidth = this.props.tableComputedData.styleObj.scrollThumbWidth
      if ( this.props.scrollName === 'vertScroll' ) {
        let newX = Math.max( 0, Math.min( x, this.scrollThumbWidth))
        return { newX, newY:y }
      } 
      else {  //'horzScroll'
        let newY = Math.max( 0, Math.min( y, this.scrollThumbWidth))
        return { newX:x, newY }
      }*/
  }
  customCursorStart = ( x:number, y:number ) : void => {
      //console.log( 'customCursorStart' )
      const{ newX, newY} = this.constraintCursorToRail(x,y)
      if (this.customHandCursorNode) {
        this.customHandCursorNode.style.left = px(newX + HAND_CURSOR_FIXED_LEFT)
        this.customHandCursorNode.style.top  = px(newY + HAND_CURSOR_FIXED_TOP )
      }
      const actionScrollNode = this.props.tableComputedData.tableDomNodes.actionScroll
      if (actionScrollNode) { actionScrollNode.style.cursor = 'none' }
  }
  customCursorDrag  = ( x:number, y:number) : void => {
      //console.log( 'customCursorDrag' )
      if (this.customHandCursorNode) {
        const{ newX, newY} = this.constraintCursorToRail(x,y)
        this.customHandCursorNode.style.left = px(newX + HAND_CURSOR_FIXED_LEFT)
        this.customHandCursorNode.style.top  = px(newY + HAND_CURSOR_FIXED_TOP )
      }
  }
  customCursorStop  = ( ) : void => {
      //console.log( 'customCursorStop' )
      if (this.customHandCursorNode) {
        this.customHandCursorNode.style.left = px(HAND_CURSOR_PARKING_LEFT)
      }
      const actionScrollNode = this.props.tableComputedData.tableDomNodes.actionScroll
      if (actionScrollNode) { actionScrollNode.style.cursor = '' }
  }

/*  END OF CUSTOM CURSOR THAT TRACKS RENDERED SCROLL POSITION */
/*  END OF CUSTOM CURSOR THAT TRACKS RENDERED SCROLL POSITION */


  render() {
    const { containerPx, contentPx, scrollPositionPx, scrollName, minScrollHeightBeforeHidden,
      top, right, foregroundColor, tableComputedData  } = this.props
    const isHidden = ( contentPx <= containerPx || containerPx < minScrollHeightBeforeHidden )
    const scrollThumbWidth = tableComputedData.styleObj.scrollThumbWidth //'width' orthogonal to the drag direction
    if ( containerPx === 0 ) { return null }
    const thumbLength = calcThumbLength( containerPx, contentPx ) // 'length' along the drag direction.
    const thumbOffset = calcThumbOffset_fromScrollValue( containerPx, contentPx, scrollPositionPx )
    const thumbColor = foregroundColor.localeCompare( '#ffffff' ) === 0 ? '#606060' : '#C0C0C0'
    let width, height, transform, thumbWidth, thumbHeight
    if (scrollName === 'vertScroll') {
        width = scrollThumbWidth
        height = containerPx
        thumbWidth = scrollThumbWidth
        thumbHeight = thumbLength
        transform = `translate( 0px, ${thumbOffset}px )`
    } else {   // scrollName === 'horzScroll'
        width = containerPx
        height = scrollThumbWidth
        thumbWidth = thumbLength
        thumbHeight = scrollThumbWidth
        transform = `translate( ${thumbOffset}px, 0px )`
    } 

    return (
      <div className={'rc_ScrollControl'}
        ref={ this.initRailNode }
        onClick={ (e)=>this.handleRailClick(e) }
        style={{
          width, height, position: 'absolute', top, right,
          visibility : isHidden ? 'hidden' : 'unset',
          //background : 'red'
        }}
      >
          <DraggableCore
            enableUserSelectHack={false}
            onStart={ (e,data) => this.handleDragStart( e, data ) }
            onDrag ={ (e,data) => this.handleDrag( e, data ) }
            onStop ={ () => this.handleDragStop( ) }
          >
              <div className='OvalThumbBar'
                onClick={(e)=> e.stopPropagation() }
                ref= { this.initThumbNode } 
                style={{position: 'relative',
                        display: 'block',
                        width: thumbWidth,
                        height: thumbHeight,
                        transform, 
                        background : thumbColor,
                        borderRadius: scrollThumbWidth / 2,
                        border: '1px solid ' + foregroundColor,
                        cursor: 'pointer',
                        boxSizing: 'border-box',}}
              />
          </DraggableCore>

{ ENABLE_CUSTOM_CURSOR &&
          <div className='CustomDragCursor'
            ref={this.initCustomHandCursorNode}
            style={{ position: 'absolute',
              top : 0, left: HAND_CURSOR_PARKING_LEFT,
              height: HAND_CURSOR_SIZE_Y, width:  HAND_CURSOR_SIZE_X,
            }}>
                <SVGwrapper2>
                  <SVGpointhand color={foregroundColor} width={HAND_CURSOR_SIZE_X} height={HAND_CURSOR_SIZE_Y} />
                </SVGwrapper2>
          </div>
}

      </div>
    )
  }
}
