import { PureComponent } from 'react'
import { TABLE_CONTROLS_OPACITY } from '../sharedComponents/constants'

type OwnProps = {
  width: number
  height: number
  color: string
}

export default class SVGsortCol extends PureComponent<OwnProps> {

  render() {
    //const { typeID  } = this.props
    //var transformRule = 'scale(1,1)'  // Default svg drawn as upArrow
    //if (typeID === 'sortUp'  ) transformRule = 'scale(1,1)'
    //if (typeID === 'sortDown') transformRule = 'scale(1,-1)'
    return (
      <svg
        className={'rc_SVGsortCol'}
        xmlns="http://www.w3.org/2000/svg"
        style={{
          isolation: 'isolate',
          display: 'block',
          opacity: TABLE_CONTROLS_OPACITY
        }}
        viewBox="0 0 40 40"
        preserveAspectRatio='none'
        width={this.props.width}
        height={this.props.height}
      >
        <defs>
          <clipPath id="_clipPath_iX3nLpEPlx2KvoWem8f1iVYxuCxkkr5L">
            <rect width="100%" height="100%" />
          </clipPath>
        </defs>
        <g clipPath="url(#_clipPath_iX3nLpEPlx2KvoWem8f1iVYxuCxkkr5L)">
          <g id="Group">
            <path d=" M 20 3.165 L 28.227 14.917 L 11.773 14.917 L 20 3.165 Z M 20 1 L 9.5 16 L 30.5 16 L 20 1 Z  "
              fill={this.props.color}
              vectorEffect="non-scaling-stroke"
              strokeWidth="1.5"
              stroke={this.props.color}
              strokeLinejoin="miter"
              strokeLinecap="butt"
              strokeMiterlimit="4" />
            <path d=" M 20 36.835 L 11.773 25.083 L 28.227 25.083 L 20 36.835 Z  M 20 39 L 30.5 24 L 9.5 24 L 20 39 Z "
              fill={this.props.color}
              vectorEffect="non-scaling-stroke"
              strokeWidth="1.5"
              stroke={this.props.color}
              strokeLinejoin="miter"
              strokeLinecap="butt"
              strokeMiterlimit="4" />
          </g>
        </g>
      </svg>
    )
  }
}

//top triangle, with center NOT filled: <path d=" M 20 3.165 L 28.227 14.917 L 11.773 14.917 L 20 3.165 Z  M 20 1 L 9.5 16 L 30.5 16 L 20 1 Z "
//bottom triangle, with center NOT filled: <path d=" M 20 36.835 L 11.773 25.083 L 28.227 25.083 L 20 36.835 Z  M 20 39 L 30.5 24 L 9.5 24 L 20 39 Z "

/*

<svg xmlns="http://www.w3.org/2000/svg"
   style={{isolation:'isolate', transform:transformRule}}
   viewBox="0 0 10 10"
   width="100%"
   height="100%">
   <defs>
     <clipPath id="_clipPath_JLBdcLCwVJ5m9ycDjsfle6mMgv2cV9rL">
       <rect width="10" height="10"/>
     </clipPath>
   </defs>
   <g clipPath="url(#_clipPath_JLBdcLCwVJ5m9ycDjsfle6mMgv2cV9rL)">
     <g id="Group">
       <path d=" M 0.541 8.81 L 5 2.51 L 9.459 8.81 L 0.541 8.81 Z "
         fill="rgb(162,162,162)"/>
       <path d=" M 5 3.02 L 8.918 8.555 L 1.082 8.555 L 5 3.02 Z  M 5 2 L 0 9.065 L 10 9.065 L 5 2 Z "
        fill="rgb(0,0,0)"/>
     </g>
   </g>
 </svg>


<svg
  xmlns="http://www.w3.org/2000/svg"
  style={{isolation:'isolate', transform:transformRule}}
  viewBox="0 0 100 100"
  width="100%"
  height="100%">
  <defs>
    <clipPath id="_clipPath_0iRd6zwNleMgxx4u4FTfvOcMrYLx5Jtu">
      <rect width="100" height="100"/>
    </clipPath>
  </defs>
  <g clipPath="url(#_clipPath_0iRd6zwNleMgxx4u4FTfvOcMrYLx5Jtu)">
    <path d=" M 89 83.235 C 88.996 84.099 88.794 84.929 88.361 85.662 C 87.328 87.512 85.11 88.59 82.336 88.577 L 17.597 88.293 C 14.789 88.28 12.614 87.216 11.599 85.325 C 10.584 83.466 10.859 81.101 12.422 78.838 L 44.317 32.395 C 45.813 30.227 47.999 28.99 50.278 29 C 52.59 29.01 54.732 30.267 56.208 32.447 L 87.665 79.169 C 88.517 80.516 89.006 81.925 89 83.235 L 89 83.235 Z  M 15.905 82.882 C 15.904 82.946 15.904 83.01 15.937 83.042 C 16.002 83.17 16.529 83.46 17.586 83.465 L 82.325 83.75 C 83.415 83.754 83.912 83.469 83.979 83.341 C 84.045 83.214 84.015 82.638 83.425 81.772 L 51.968 35.05 C 51.41 34.248 50.751 33.766 50.157 33.763 C 49.562 33.761 48.899 34.237 48.334 35.034 L 16.44 81.477 C 16.04 82.147 15.906 82.626 15.905 82.882 L 15.905 82.882 Z "
    fill="rgb(0,0,0)"
    vectorEffect="non-scaling-stroke"
    strokeWidth="5"
    stroke="rgb(0,0,0)"
    strokeLinejoin="miter"
    strokeLinecap="butt"
    strokeMiterlimit="4"/>
  </g>
</svg>



<svg
  xmlns="http://www.w3.org/2000/svg"
  style={{isolation:'isolate', transform:transformRule}}
  viewBox="0 0 44 44"
  width="100%"
  height="100%">
  <defs>
    <clipPath id="_clipPath_8qEGTnwBffgawOvMsIZowIVWTrglthvc">
      <rect width="40"
        height="40"/>
      </clipPath>
    </defs>
    <g clipPath="url(#_clipPath_8qEGTnwBffgawOvMsIZowIVWTrglthvc)">
      <rect
        x="0" y="0"
        width="44"
        height="44"
        fill="none"/>
      <path
        d=" M 20 20 L 38 38 L 2 38 L 20 20 Z "
        fillRule="evenodd"
        fill="none"
        vectorEffect="non-scaling-stroke"
        strokeWidth="2"
        stroke="rgb(0,0,0)"
        strokeLinejoin="round"
        strokeLinecap="butt"
        strokeMiterlimit="1.414"/>
    </g>
  </svg>

  */
