
import type { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { get } from 'radash'
import { createResource } from '../jsonapi'
import { notifyJsonApiError } from './notifyReducer'
import { asyncDispatch } from '../sharedFunctions/utils'
import type { GetStateFunc, RouterHistory, Table, Tabledata, Tablelook } from '../types'

export const createTableResourcesThunk = (table: Table, tabledata: Tabledata, tablelook: Tablelook, history: RouterHistory) => {
  return async (dispatch: Dispatch<AnyAction>, getState: GetStateFunc): Promise<void> => {
    try {
      if (table && tabledata && tablelook) {
        await asyncDispatch(dispatch, createResource(table))
        await asyncDispatch(dispatch, createResource(tabledata))
        await asyncDispatch(dispatch, createResource(tablelook))
        history.push(`/table/${table.id}`)
      }
    } catch (err) {
      const errors = get(err, 'response.data.errors', null)
      if (errors) {
        dispatch(notifyJsonApiError({errors}))
      }
    }
  }
}
