import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

// export const THUMB_ADD = 'THUMB_ADD'
// export const THUMB_DELETE = 'THUMB_DELETE'


// export const createThumbnailAddAction = createAction(THUMB_ADD,
//                   (plotid: string, dataURL: string) => ({dataURL, plotid}))

// export const createThumbnailDeleteAction = createAction(THUMB_DELETE,
//   (plotid: string) => ({ plotid })
// )

// export type ThumbnailAction = ActionType<typeof createThumbnailAddAction>
// export type ThumbnailDeleteAction = ActionType<typeof createThumbnailDeleteAction>


// keys of ThumbnailState are plotids
// values of ThumbnailState are dataURLs
export type ThumbnailState = {
  [key: string]: string
}

const defaultThumbnailState: ThumbnailState = {}

type ThumbnailPayload = {
  dataUrl: string
  plotid: string
}

// const thumbnailAddHandler = (state: ThumbnailState, action: ThumbnailAction): ThumbnailState => {
//   const {dataURL, plotid} = action.payload
//   let oldDataURL = _.get(state, [plotid], null)
//   let immState = immutable.wrap(state)
//   if (!oldDataURL || oldDataURL !== dataURL) {
//     immState = immState.set([plotid], dataURL)
//   }
//   return immState.value()
// }

// const thumbnailDeleteHandler = (state: ThumbnailState, action: ThumbnailDeleteAction): ThumbnailState => {
//   const { plotid } = action.payload
//   const immState = immutable.wrap(state)
//   return immState.del([plotid]).value()
// }

// const thumbnailReducer = (state: ThumbnailState = defaultThumbnailState, action: ThumbnailAction): ThumbnailState => {
//   switch (action.type) {
//     case THUMB_ADD:
//       return thumbnailAddHandler(state, action)
//     case THUMB_DELETE:
//       return thumbnailDeleteHandler(state, action)
//     default:
//   }
//   return state
// }

// export default thumbnailReducer

const thumbnailSlice = createSlice({
  name: 'thumbnail',
  initialState: defaultThumbnailState,
  reducers: {
    createThumbnailAddAction(state, action: PayloadAction<ThumbnailPayload>): void {
      const {dataUrl, plotid} = action.payload
      let oldDataURL = state[plotid]
      if (!oldDataURL || oldDataURL !== dataUrl) {
        state[plotid] = dataUrl
      }
    },
    createThumbnailDeleteAction(state, action: PayloadAction<string>): void {
      const plotid = action.payload
      delete state[plotid]
    }
  }
})

export const {
  createThumbnailAddAction,
  createThumbnailDeleteAction
} = thumbnailSlice.actions

export default thumbnailSlice.reducer
