import type { AnyAction, Dispatch }   from '@reduxjs/toolkit'
import type { MouseEvent, KeyboardEvent} from 'react'
import type { RouteComponentProps }   from 'react-router-dom'                
import type { TooltipState }          from '../redux/tooltipReducer'  
import type { RootState }             from '../redux/store'

import {useSelector, connect}         from 'react-redux'
import {useContext}                   from 'react'
import {withRouter}                   from 'react-router-dom'
import {TableComputedDataContext}     from '../viewTableTriple/TtGetComputedData'
import {createTooltipDisplayAction,
       createTooltipVisibilityAction} from '../redux/tooltipReducer'
import TooltipContainer               from '../viewTooltips/TooltipContainer'
import {handlerMouseUp, 
        handlerMouseDown,
        handlerMouseMove }            from './tableMouseHandler'
import {handlerKeyPress}              from './handlerKeyPress'

// Components
import {LayoutMain}                 from './LayoutMain'
import {ActionDebugGetTableTile}   from './ActionDebugGetTableTile'
import {ActionScroll}               from './ActionScroll'
import {ActionSort}                 from './ActionSort'
import {ActionPinRow}               from './ActionPinRow'
import {ActionDndCol}               from './ActionDndCol'
import {FpParent}                   from '../floatingPaletteNew/FpParent'
import {SbParent3}                  from '../sideBars/SbParent3'

const RENDER_LayoutMain = true
const RENDER_DebugGetTableTile = false

type RouteMatchProps = {tableid: string}
type StateProps = {toolTipResource: TooltipState}
type DispatchProps = {
    displayTooltip: (toolTipStr: string, toolTipTargetHeight: number, toolTipTargetLeft: number,
      toolTipTargetTop: number, toolTipTargetWidth: number, toolTipPlacement: string,
      toolTipAddedExtension: number ) => void,
    hideTooltip: () => void,
}

type Props = StateProps & DispatchProps & RouteComponentProps<RouteMatchProps>

const ViewTableRender : React.FC<Props> = (props) => {
    const tableComputedData = useContext( TableComputedDataContext )
    const sessionState = useSelector( (state:RootState) => state.session )
    const {activeFp} = sessionState
    const {toolTipResource, hideTooltip, history, displayTooltip } = props
    const {tablelook} = tableComputedData
    const {isBrightField } = tablelook.attributes
    const {widthObj:w, heightObj:h } = tableComputedData
    // TODO: Add menuBar and NavBar components
    return (
      <div className={'rc_ViewTable'}
        style={{
          position:'relative', top:0, left:0,
          width: w.viewWidthPx, height: h.viewHeightPx,
        }}
        tabIndex={0} // Necessary to capture keyPress events 
        onKeyDown  ={ (e: KeyboardEvent)=> handlerKeyPress( e, tableComputedData, activeFp) }
        onMouseMove={ (e: MouseEvent)=> handlerMouseMove(e, tableComputedData, displayTooltip ) }
        onMouseDown={ (e: MouseEvent)=> handlerMouseDown( e, tableComputedData) }
        onMouseUp  ={ (e: MouseEvent)=> handlerMouseUp( e, tableComputedData, sessionState, hideTooltip, history)}
      >
              <TooltipContainer
                  tableComputedData={tableComputedData}
                  isBrightField={isBrightField}
                  toolTipResource={toolTipResource}
              />
              {RENDER_LayoutMain &&  <LayoutMain/> }
              <ActionSort/>
              <ActionDndCol/>
              <ActionScroll/>
              <ActionPinRow/>
              {RENDER_DebugGetTableTile && <ActionDebugGetTableTile/>}
              <SbParent3/>
              <FpParent/> 
      </div>
)}



const mapStateToProps = (state: RootState ): StateProps => {
  return { toolTipResource : state.tooltip }
}

const mapDisp = (dispatch: Dispatch<AnyAction>) : DispatchProps => (
  {
    displayTooltip: (toolTipStr: string, toolTipTargetHeight: number, toolTipTargetLeft: number,
      toolTipTargetTop: number, toolTipTargetWidth: number,
      toolTipPlacement:string, toolTipAddedExtension: number ): void => {
        dispatch(createTooltipDisplayAction({toolTipStr, toolTipTargetHeight,
          toolTipTargetLeft, toolTipTargetTop, toolTipTargetWidth, toolTipPlacement, toolTipAddedExtension,
          toolTipVisible: true, toolTipOverride: false}))
    },
    hideTooltip: (): void => {
      dispatch(createTooltipVisibilityAction(false))
    }
  }
)

const ViewTableConnected = connect(mapStateToProps, mapDisp)(ViewTableRender)
export const ViewTable = withRouter(ViewTableConnected)
