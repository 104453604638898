import type { TableDomNodes, TableComputedData,
                GetFormattedTableData } from '../computedDataTable/getDefaultTableComputedData'
import type {TableHeightObj}                  from '../computedDataTable/layoutCalculator'

import { fpResponsiveStateStart,
         fpResponsiveStateStop}               from '../floatingPaletteNew/FpParent'
import { fpResponsiveState_redrawCyanLine}    from '../floatingPaletteNew/FpParent'
import { activeFpToTableViewTile_rState } from '../viewTable/getTableTile'           
import { numberFormat, getCommasOnlyFormattingObj}  from '../sharedFunctions/numberFormat'
import { getRowGroupPlacement}                from '../computedDataTable/layoutCalculator'
import { calcThumbOffset_fromScrollValue, calcThumbLength }   from './tableResponsiveState'
import { startTimer, stopTimer, logTime }     from '../sharedFunctions/timer'
import constants                              from '../sharedComponents/constants'

const isTIMER_ENABLED = false 
const px = (value: number) : string => { return `${value}px` }


// These globals are variable, potentially changing each animation frame: 
export let isScrollTopAnimationActive : boolean = false  // Visible to the scrollControls. Tells us when
                                                         // scrollTop rendering is finished, so we can then
                                                         // issue a scrollTop state change.
let isRequestToStopAnimation  : boolean = false // Similar to above, but this is the 'request' (aka onDragStop)
                                                // to stop the animation.  However, the animation may not be
                                                // finished and worse case may require (n-1) addition rowGroup catch-ups.
let nextRowGroupToSynch : number  = 0   // Which of n-rowGroups available is next to re-render;  This value cycles
                                        // cycles through rowGroups such that we guarantee all 'n' groups
                                        // are rendered on any successive 'n' calls to synchScrollTop()
let priorFirstRowIndexArr : number[] = []   // The last frame (current displayed) rowIndex assigned to the
                                            // top of each row group.  WHEN next frame's required rowIndex
                                            // doesn't match, it means the rowGroup has switched position.
                                            // (top to bottom, or bottom moved to top).
                                            // Cell values in that rowGroup become 'stale'. (no longer valid).
let staleRowGroups : number[] = []  // rowGroups with stale data, 'waiting' for rendering to 'catch-up'
                                            // Animation ends NOT on dragStop, but on (dragStop && staleRowGroups.length === 0)
let scrollTop: number = 0  // set by onDrag() event handler.


// Some globals are static constants (static over any given vertical scroll animation) 
// These are set by initiateScrollTopAnimation()
let tCD: TableComputedData
let tableDomNodes: TableDomNodes
let heightObj: TableHeightObj
let visibleColKeys  : number[]      // The columns currently visible. We ONLY re-render these columns.
let numRowGroups    : number 
let rowsPerGroup    : number 
let rowGroupHeight  : number
let foregroundColor : string
let colDataTypeArr  : string[]
let dataAllocated   : number
let dataRequired    : number
let getFormattedTableData_fromColKeyRowIndex : GetFormattedTableData
let isRowNumberVisible: boolean
let showErrorsInRed   : boolean


// called by onDrag() event handler:
// This is a constantly changing value during drag.
// However, IF the user interrupts the drag() and just sits
// at a fixed position, THEN this value is a 'temporary' constant.
// HOWEVER, the scrollTop animation continues to run successive 
// annimation frames!  ScrollTop animation will eventually catch-up
// all stale frames, the just wait patiently for the next onDrag()
// to modify this value.
export const setNewResponsiveStateScrollTop = ( newOffset: number) : void => { 
    scrollTop = newOffset  
}

// This function is called by the onDragStop (vertical scroll) event handler.
// It tells this module that user is no longer interested in scrolling.
// Therefore, update any remaining stale rowGroups, then signal back to
// the dragStop eventHandler (using exported isScrollTopAnimationActive)
// that the animation is finished.
export const stopScrollTopAnimation = () : void => {
    isRequestToStopAnimation = true  // called by onDragStop event handler
}

export const initiateScrollTopAnimation = ( tCD_input: TableComputedData ) : void => { 
    tCD = tCD_input 
    //console.log( 'call to initiateScrollTopAnimation')
    // Constants over all animation frames:  
    tableDomNodes = tCD.tableDomNodes;
    // Global attributes referenced during scrolling animation:
    ({heightObj, getFormattedTableData_fromColKeyRowIndex, scrollTop} = tCD );
    ({numRowGroups, rowsPerGroup, rowGroupHeight, dataAllocated, dataRequired} = heightObj);
    ({isRowNumberVisible} = tCD.tablelook.attributes);
    ({foregroundColor} = tCD.styleObj);
    isScrollTopAnimationActive = true   // Set true on dragStart();  Set false when animation catchup is complete.               
    isRequestToStopAnimation   = false  // Set true on dragStop()
    showErrorsInRed = tCD.canEdit
    colDataTypeArr = tCD.derivedColAttributesArray.map( c => c.colDataType ) 
    // This next global stores the rowNumber for each rowGroup
    // During scrolling this potentially changes with each animation frame!
    priorFirstRowIndexArr = new Array(numRowGroups)
    for (let i=0; i < numRowGroups; i++ ) {
        const {firstRowIndex} = getRowGroupPlacement( i, scrollTop, numRowGroups, rowsPerGroup, rowGroupHeight )
        priorFirstRowIndexArr[i] = firstRowIndex
    }
    const {derivedColOrder, widthObj:w, scrollLeft} = tCD
    const hiddenIndexArr: number[] = tCD.tablelook.attributes.lookColumns.map( c => c.hidden )
    //colDataTypeArrIn
    visibleColKeys = []  // Limit re-rendering to only columns that are visible.
    for ( const [colIndex, colKey] of derivedColOrder.entries() ) {
      if ( hiddenIndexArr[colKey] > 0 || w.displayedColWidths[colKey] === 0 ) { /*  skip  hidden columns */ }  
      else if ( !w.isCombinedTable && colIndex < w.numLockedCols ) { visibleColKeys.push(colKey) }  // include locked columns
      else if ( w.startColLeft[colIndex] + w.displayedColWidths[colIndex]  < scrollLeft ) { /*  skip columns scrolled out-of-view to left */ }
      else if ( w.startColLeft[colIndex] > scrollLeft + w.movingAllocated ) { /* skip columns scrolled out-of-view to right */ }
      else { visibleColKeys.push(colKey) }   // All remaining columns are visible.
    }
    if ( isTIMER_ENABLED ) {  startTimer('tableResponsiveState')  }
    // Initialize the Fp cyan line animation.
    fpResponsiveStateStart() 
    // Call the first frame of recursive animation.
    scrollTopAnimation() 
}


const scrollTopAnimation = () : void => {
    //console.log( 'call to scrollTopAnimation')
    staleRowGroups = updateScrollTop( scrollTop )   // Translates rows vertically; re-renders 0 or 1 rowGrops. 
                                                    // Catches-up rowGroup rendering when possible.
    //updateFloatingPaletteHighlight( w_thisFrame )   // Re-renders the cyan connecting line, if visible.
    redrawVertScrollControl_fromScrollPosition( scrollTop, dataAllocated, dataRequired )  // Repositions the scrollBar thumb.
    synchCyanLineTop(scrollTop, heightObj )  // Repositions the cyan connecting line, if visible.
    if ( isTIMER_ENABLED ) { logTime('tableResponsiveState', `scrollTop`) }
    // Next block is the Exit Path when breaking the recursive loop!
    if ( isRequestToStopAnimation && staleRowGroups.length === 0 ) {
        //console.log( 'request to stop scrollTopAnimation')
        // ALL DONE!  Clean-up and exit the recursive loop.
        if ( isTIMER_ENABLED ) {  stopTimer('tableResponsiveState') }
        isScrollTopAnimationActive = false   // visible to the onDragStop event handler!
        isRequestToStopAnimation   = false
        return  
    }
    // Else, repeat until explicite request to stop animation.
    requestAnimationFrame( scrollTopAnimation )
}


export const restoreInitialTableResponsiveStateVertScroll = (  ) : void => {
   fpResponsiveStateStop( )
}


const synchCyanLineTop = ( scrollTop:number, h: TableHeightObj ) : void => {
    // This is layout information needed to find the cyanLine location, but is static during horz animations:
    const { scrollLeft, widthObj:w, sessionStateActiveFp, derivedColOrder, sortedRowKeys, pinnedRowKeys } = tCD
    const cyanLineTileLocation = activeFpToTableViewTile_rState(scrollLeft, scrollTop, w, h, 
                                        sessionStateActiveFp, derivedColOrder, sortedRowKeys, pinnedRowKeys) 
    fpResponsiveState_redrawCyanLine( cyanLineTileLocation ) 
}


const updateScrollTop = ( newScrollTop: number ) : number[] => {
    const firstRowIndexArr = []
    const commasOnlyFormattingObj = getCommasOnlyFormattingObj()   // If we our rendering line numbers.
    const {lockedRowGroups, movingRowGroups, lineNumRowGroups} = tableDomNodes
    // translate (position vertically) each rowGroup:
    for (let groupIndex=0; groupIndex<numRowGroups; groupIndex++ ) {
        const {topOffset, firstRowIndex} = getRowGroupPlacement( groupIndex, newScrollTop, 
                                            numRowGroups, rowsPerGroup, rowGroupHeight )
        firstRowIndexArr[groupIndex] = firstRowIndex
        let thisDomNode = lockedRowGroups[groupIndex]
        if ( thisDomNode ) {
          thisDomNode.style.transform = `translate(0px, ${topOffset}px)`
        }
        thisDomNode = movingRowGroups[groupIndex]
        if ( thisDomNode ) {
          thisDomNode.style.transform = `translate(0px, ${topOffset}px)`
        }
        thisDomNode = lineNumRowGroups[groupIndex]
        if ( isRowNumberVisible && thisDomNode ) {
          thisDomNode.style.transform = `translate(0px, ${topOffset}px)`
        }
    }
    //  Which (if any) rowGroup should be overwritten with new cell data?
    let updateGroup = -1  // Assumption; -1 mean NO rowGroup needs to be updated.
    const staleGroups = []   // Assumption;  There are no rowGroups with 'stale' values.
    for (let i=0; i<numRowGroups; i++) {
        const groupIndex = (nextRowGroupToSynch+i) % numRowGroups
        // First modified group (firstRowIndex changed!) becomes our updateGroup
        // There is ONLY one updateGroup per RAF.  They sequence sequentially. 0->n, 0->n, 0->n ...
        if ( updateGroup === -1 && firstRowIndexArr[groupIndex] !== priorFirstRowIndexArr[groupIndex] ) {
          updateGroup = groupIndex
        }
        // If there is more than one rowGroup that needs to be updated, we call this 'Unfinished Business'.
        else if ( firstRowIndexArr[groupIndex] !== priorFirstRowIndexArr[groupIndex] ) {
          staleGroups.push( groupIndex )
        }
    }
    // Save info needed for next animation frame:
    if ( updateGroup >= 0 ) {  // Save info needed for next animation frame:
        nextRowGroupToSynch = updateGroup + 1  // If I update rowGroup '3' this frame, then update '4' next frame.
                                               // OK if this number exceeds number of rowGroups, because we
                                               // use it like a clock (modulo) where rowGroup 'n' same as rowGroup '0'
        // For each pass, we MAY update '1' rowGroup.
        // In which case the firstRowIndex for that group also needs to be updated                                       
        priorFirstRowIndexArr[updateGroup] = firstRowIndexArr[updateGroup]  
    }
    // Overwrite the cell values for a single updateGroup!
    const columnsData = tableDomNodes.columnsData
    if ( updateGroup >= 0 ) {
        const firstRowIndex = firstRowIndexArr[updateGroup]
        // Row numbers:
        const curRowGroup = lineNumRowGroups[updateGroup]
        if ( isRowNumberVisible && curRowGroup ) {
            // Jason: I assume this fail typeScript because a child in the domTree 
            // may be some elemental prop like text, hence cannot be assumed a DomNode:
            const parentNode = curRowGroup.firstChild as HTMLElement
            const rowGroupNodes = parentNode.children as HTMLCollection
            for (let rowCounter=0; rowCounter<rowsPerGroup; rowCounter++ ) {
                const thisRowIndex = firstRowIndex + rowCounter + 1
                const newText = numberFormat( String(thisRowIndex), commasOnlyFormattingObj, 'html' )
                const cellNode = rowGroupNodes[rowCounter] as HTMLElement
                cellNode.innerText = newText
            }
        }
        // Table cells:
        for ( const colKey of visibleColKeys ) {
            const colDataType = colDataTypeArr[colKey]
            const isNumber = ( colDataType.slice(0,6) === 'number' )
            const childrenNodes = columnsData[updateGroup][colKey]?.children
            if (!childrenNodes) {
                continue
            }
            for (let rowCounter=0; rowCounter<rowsPerGroup; rowCounter++ ) {
                const thisRowIndex = firstRowIndex + rowCounter
                const cellNode = childrenNodes[rowCounter] as HTMLElement
                const {value:cellContent, isErroneous} = getFormattedTableData_fromColKeyRowIndex( colKey, thisRowIndex, 'html' );
                cellNode.style.color = ( showErrorsInRed && isErroneous && cellContent !== '' )
                        ? 'red'
                        : ( colDataType === 'hyperlink')
                            ? constants.LINK_COLOR
                            : foregroundColor
                if ( isNumber && cellContent.slice(0,6) === '<span>' ) {
                    cellNode.innerHTML = cellContent  // Exponential format:
                } else {
                    cellNode.innerText = cellContent  // default text values.
                }
            }
        }
    }
    return staleGroups
}


const redrawVertScrollControl_fromScrollPosition = ( scrollPosition: number, 
                                            containerPx: number, contentPx: number ) : void => {
    const {vertScrollRail:railNode, vertScrollThumb:thumbNode} = tableDomNodes 
    if ( railNode?.style ) { 
        railNode.style.height = px(containerPx)
        // IF/When NOT needed, we hide the scrollControl
        const isHidden = (containerPx >= contentPx)  
        railNode.style.visibility = isHidden ? 'hidden' : 'unset'
    }
    const thumbOffset = calcThumbOffset_fromScrollValue( containerPx, contentPx, scrollPosition )
    const thumbLength = calcThumbLength( containerPx, contentPx )
    if ( thumbNode?.style ) {
        thumbNode.style.height = px(thumbLength)
        thumbNode.style.transform = `translate(0px, ${thumbOffset}px)`
    }
}



