import { PureComponent } from 'react'
import { DropTarget } from 'react-dnd'
import type { ConnectDropTarget, DropTargetConnector, DropTargetMonitor } from 'react-dnd'
import { getCommasOnlyFormattingObj, numberFormatNoHTML } from '../sharedFunctions/numberFormat'
import type { PlotXyComputedData } from '../computedDataPlotXY/xy_plotTypes'
import { STYLE_EDITOR_RAISED_BUTTON } from '../sharedComponents/constants'
import reactDispatch from '../sharedComponents/reactDispatch'
import type { TableComputedData }  from '../computedDataTable/getDefaultTableComputedData'
import { getEmptyFilterRule } from '../types'
import type { FilterRule } from '../types'
import { deepClone} from '../sharedFunctions/utils'
import FilterRuleItem, {DragFilterRuleItemType} from './FilterRuleItem'

// Next object defines the geometric layout for components: FilterRules and FilterRuleItem
// Set debug to 'true' before working on the layout.
// A common layout must look clean for both the table's rowFilters HighlightedFloatingPalette
// and the plot's EditPlotSeries HighlightedFloatingPalette.

const filterLayout = {
  DEBUG : false,
  WIDTH_COLMATCH_TEXT : 140,
  WIDTH_RELATION_TEXT : 65,
  WIDTH_CARET : 20,
  WIDTH_VALUE : 125,
  GAP_BETWEEN_INPUTS : 3,

  // Constrain the second line such that the
  // 'remaining text' message is centered
  WIDTH_REMAINING_TEXT : 200,
  WIDTH_DND : 30,
  WIDTH_ENABLE_CONTROL: 0,      // Set by a constraint computation below
  WIDTH_DELETE_1lineMode : 20,
  WIDTH_DELETE_2lineMode : 0,   // Set by a constraint computation below
  TOTAL_WIDTH_1lineMode  : 0,   // computation below
  TOTAL_WIDTH_2lineMode  : 0,   // computation below

  FIRST_ROW_HEIGHT : 21,
  GAP_BETWEEN_ROWS_1lineMode : 1,
  SECOND_ROW_HEIGHT : 28,

  //MARGIN_LEFT_RIGHT: 10,
  //MARGIN_TOP: 6,
  //MARGIN_BOTTOM: 0,
  HEIGHT_STARTING_ROWS_LABEL_1lineMode: 20,
  HEIGHT_STARTING_ROWS_LABEL_2lineMode: 26,
  HEIGHT_INPUT_LABELS: 14,
  HEIGHT_ADD_FILTER_BUTTON: 18,
}

export type FilterLayoutObj = typeof filterLayout

// Remaining filterLayout parameters are computed, based on above params
const halfFirstLineWidth = (filterLayout.WIDTH_COLMATCH_TEXT + filterLayout.WIDTH_RELATION_TEXT +
                            2*(filterLayout.WIDTH_CARET + filterLayout.GAP_BETWEEN_INPUTS) +
                            filterLayout.WIDTH_VALUE)/2
filterLayout.WIDTH_ENABLE_CONTROL   = halfFirstLineWidth - filterLayout.WIDTH_REMAINING_TEXT/2
filterLayout.WIDTH_DELETE_2lineMode = filterLayout.WIDTH_ENABLE_CONTROL - filterLayout.WIDTH_DND
filterLayout.TOTAL_WIDTH_1lineMode  = 2*halfFirstLineWidth + filterLayout.WIDTH_DELETE_1lineMode
filterLayout.TOTAL_WIDTH_2lineMode  = 2*halfFirstLineWidth


export const getFilterRulesWidthAndHeight = (lineMode:string, numFilters:number) : {width: number, height: number} => {
    // What is the allocated totalWidth and totalHeight?
    const {TOTAL_WIDTH_1lineMode, TOTAL_WIDTH_2lineMode,
            HEIGHT_STARTING_ROWS_LABEL_1lineMode, 
            HEIGHT_STARTING_ROWS_LABEL_2lineMode,
            HEIGHT_INPUT_LABELS, FIRST_ROW_HEIGHT, SECOND_ROW_HEIGHT, GAP_BETWEEN_ROWS_1lineMode} = filterLayout
    var totalWidth   = (lineMode  === '1line' ) ? TOTAL_WIDTH_1lineMode : TOTAL_WIDTH_2lineMode
    var headerHeight = (lineMode === '2line' )
        ? HEIGHT_STARTING_ROWS_LABEL_2lineMode
        : HEIGHT_STARTING_ROWS_LABEL_1lineMode
    var totalHeight = headerHeight
    totalHeight += HEIGHT_INPUT_LABELS  // Always assume labeling for new FloatingPalettes
    if ( lineMode === '2line' ) {
      totalHeight += numFilters * (FIRST_ROW_HEIGHT + SECOND_ROW_HEIGHT)
    } else {
      totalHeight += numFilters * (FIRST_ROW_HEIGHT + GAP_BETWEEN_ROWS_1lineMode)
    }
    return {width: totalWidth, height: totalHeight}
      
}


type OwnProps = {
  tableComputedData: TableComputedData,
  plotXyComputedData?: PlotXyComputedData,
  multiLineMode: '1line' | '2line',
  resourceName: 'rowFilters' | 'seriesFilters' | 'commonFilters',
  seriesKey: number,
  maxNumFilters: number,
}
type CollectProps = {
  connectDropTarget: ConnectDropTarget,
}
type Props = OwnProps & CollectProps

class FilterRulesRender extends PureComponent<Props> {

  static defaultProps = {
    seriesKey : 0,
  }

  // We need a getRowFilters() to retrieve an array of
  // rowFilters from any of the three resource paths.
  getRowFilters = () : FilterRule[] | undefined => {
    switch (this.props.resourceName) {
      case 'rowFilters'    : return this.props.tableComputedData.tablelook.attributes.rowFilters
      case 'seriesFilters' : return this.props.plotXyComputedData?.seriesAttributesArray[this.props.seriesKey].seriesFilter
      case 'commonFilters' : return this.props.plotXyComputedData?.commonSeriesFilter
      default: return []
    }
  }

  // Useful for layout to know number of rowFitlers
  getNumFilters = ( inStrg:string ) : number => {
    const {tableComputedData, plotXyComputedData} = this.props
    switch (inStrg) {
      case 'rowFilters'    :
        return  tableComputedData.tablelook.attributes.rowFilters.length
      case 'seriesFilters' :
        return (plotXyComputedData) ? plotXyComputedData.seriesAttributesArray[this.props.seriesKey].seriesFilter.length : 0
      case 'commonFilters' :
        return (plotXyComputedData) ? plotXyComputedData.commonSeriesFilter.length : 0
      default: return 0
    }
  }

  // We need a getResourceName() to define the reactDispatch
  // location of any of the three resource paths.
  getResourcePath = () : string => {
    switch (this.props.resourceName) {
      case 'rowFilters'    : return( 'attributes.rowFilters' )
      case 'seriesFilters' : return( `attributes.series[${this.props.seriesKey}].seriesFilter` )
      case 'commonFilters' : return( 'attributes.commonSeriesFilter' )
      default: return ('')
    }
  }


  findItemIndex = (ruleKey: number): number => {
    var rowFilters = this.getRowFilters()
    if (rowFilters) {
      let index = rowFilters.findIndex((filterRule: FilterRule): boolean => (filterRule.key === ruleKey))
      if (index === rowFilters.length - 1) {
        index -= 1
      }
      return index
    } else {
      return -1
    }
  }

  moveItem = (ruleKey: number, atIndex: number, dragStop:boolean ): void => {
    if ( dragStop ) {
      reactDispatch( [],  'reorder filter rules', 'dragStop' )
      return
    }
    const rowFilters = this.getRowFilters()
    const curIndex = this.findItemIndex(ruleKey)
    if (curIndex !== atIndex) {
      let filtersClone = rowFilters ? deepClone(rowFilters) : []
      const deletedItemArray = filtersClone.splice(curIndex, 1)
      filtersClone.splice(atIndex, 0, deletedItemArray[0])
      const resourcePath = this.getResourcePath()
      const mods = [{ newVal: filtersClone, path: resourcePath}]
      reactDispatch(mods, 'reorder filter rules', 'drag' )
    }
  }

  nextAvailableKey = (): number => {
    const rowFilters = this.getRowFilters()
    const reducerFunction = (accumulator: number, filterRule: FilterRule) => (Math.max(accumulator, filterRule.key))
    const maxRuleKey = rowFilters ? rowFilters.reduce(reducerFunction, 0) : 0
    return maxRuleKey + 1
  }

  ensureEmptyFilter = () => {
    const rowFilters = this.getRowFilters()
    const lastRule = ( rowFilters && rowFilters.length > 0 ) ? rowFilters[ rowFilters.length-1 ] : null
    const isColKeySet = (lastRule && lastRule.colKey >= 0)
    // Allow a 'unset' relation to also include '', as this was the prior rule found in some resources.
    const isRelationSet = !(lastRule && (lastRule.relation === 'unset' || lastRule.relation as string === ''))
    if ( !lastRule || isColKeySet || isRelationSet ) {
      const newEmptyRule = { ...getEmptyFilterRule(), key: this.nextAvailableKey() }
      const newRulesArray = rowFilters ? deepClone( rowFilters ) : Array<FilterRule>()
      newRulesArray.push( newEmptyRule )
      const resourcePath = this.getResourcePath()
      const mods = [{ newVal: newRulesArray, path: resourcePath }]
      reactDispatch(mods, 'add empty filter rule')
    }
  }

  addEmptyFilter = () => {
    //console.log( 'handle addEmptyFilter' )
    const newEmptyRule = { ...getEmptyFilterRule(), key: this.nextAvailableKey() }
    const rowFilters = this.getRowFilters()
    const newRulesArray = rowFilters ? deepClone( rowFilters ) : Array<FilterRule>()
    newRulesArray.push( newEmptyRule )
    const resourcePath = this.getResourcePath()
    const mods = [{ newVal: newRulesArray, path: resourcePath }]
    reactDispatch(mods, 'add new filter rule')
  }

  componentDidMount() {
    if (this.props.resourceName === 'rowFilters' ) {
      this.ensureEmptyFilter()
    }
  }

  componentDidUpdate() {
    if (this.props.resourceName === 'rowFilters' ) {
      this.ensureEmptyFilter()
    }
  }

  render() {
    const {tableComputedData, connectDropTarget, multiLineMode, resourceName, seriesKey, maxNumFilters} = this.props
    //console.log( 'Render FilterRules', this.props )
    const {filteredRowKeys, filterRuleCounts, derivedColOrder} = tableComputedData
    const numUnfilteredRows = filteredRowKeys.length
    const rowFilters = this.getRowFilters( )
    const startingRowsStr = numberFormatNoHTML( String(numUnfilteredRows), getCommasOnlyFormattingObj() )
    const {DEBUG, WIDTH_COLMATCH_TEXT, WIDTH_RELATION_TEXT, WIDTH_VALUE, GAP_BETWEEN_INPUTS, WIDTH_CARET,
          TOTAL_WIDTH_1lineMode, TOTAL_WIDTH_2lineMode,
          HEIGHT_STARTING_ROWS_LABEL_1lineMode, HEIGHT_STARTING_ROWS_LABEL_2lineMode,
          HEIGHT_INPUT_LABELS, FIRST_ROW_HEIGHT, SECOND_ROW_HEIGHT, GAP_BETWEEN_ROWS_1lineMode,
          HEIGHT_ADD_FILTER_BUTTON,
        } = filterLayout
    const widthRelationAndGaps = WIDTH_RELATION_TEXT + 2*GAP_BETWEEN_INPUTS


    // When and where should be add the labeling for 'Match Col', 'Relation', and 'Match Value' labels ??
    const numCommonFilters = this.getNumFilters( 'commonFilters' )
    const numSeriesFilters = this.getNumFilters( 'seriesFilters' )
    var isCommonFiltersLabelingVisible = false   // Assumption
    var isSeriesFiltersLabelingVisible = false   // Assumption
    if ( numCommonFilters > 0 ) {
      isCommonFiltersLabelingVisible = true
    } else if ( numSeriesFilters > 0 ) {
      isSeriesFiltersLabelingVisible = true
    }
    var shouldRenderLabeling =
       ( resourceName === 'rowFilters' ) ||
       ( resourceName === 'commonFilters' && isCommonFiltersLabelingVisible ) ||
       ( resourceName === 'seriesFilters' && isSeriesFiltersLabelingVisible )

    // What is the allocated totalWidth and totalHeight?
    var totalWidth   = (multiLineMode  === '1line' ) ? TOTAL_WIDTH_1lineMode : TOTAL_WIDTH_2lineMode
    var headerHeight = ( multiLineMode === '2line' )
        ? HEIGHT_STARTING_ROWS_LABEL_2lineMode
        : HEIGHT_STARTING_ROWS_LABEL_1lineMode
    var totalHeight = headerHeight
    if ( shouldRenderLabeling ) { totalHeight += HEIGHT_INPUT_LABELS }
    if ( multiLineMode === '2line' ) {
      totalHeight += Number(rowFilters?.length) * (FIRST_ROW_HEIGHT + SECOND_ROW_HEIGHT)
    } else {
      totalHeight += Number(rowFilters?.length) * (FIRST_ROW_HEIGHT + GAP_BETWEEN_ROWS_1lineMode)
    }


    var headerText = `Starting Rows: ${startingRowsStr}`    // assumption is 'rowFilters'
    if ( resourceName === 'seriesFilters' ) {
      headerText = 'Series Data Filters\u00A0\u00A0( apply to this series ONLY )'
    } else if ( resourceName === 'commonFilters') {
      headerText = 'Common Data Filters\u00A0\u00A0( apply to ALL series )'
    }


    return connectDropTarget(
      <div className={'rc_FilterRules'}
        style={{
          position:'relative', top:0, left:0, // local coord system
          width: totalWidth, height: totalHeight,
          background: DEBUG ? 'aqua' : 'unset',
          fontSize: '14px'}}>

        <div className='centertext inlinediv'
          style={{width: '100%', height: headerHeight}}>
          {headerText}
        </div>

{ resourceName !== 'rowFilters' &&

        <button className={'addDataFiltersButton'}
          onClick={ this.addEmptyFilter }
          style={{
            position: 'absolute', top:-1, right:0,
            fontSize: 12,
            height: HEIGHT_ADD_FILTER_BUTTON, width: 36,
            textAlign: 'center',
            ...STYLE_EDITOR_RAISED_BUTTON,
            paddingLeft:3,
          }}>
           {'Add+'}
        </button>

}


{shouldRenderLabeling &&
        <div style={{height: HEIGHT_INPUT_LABELS, fontSize: '12px', paddingBottom: 2}}>
          <div className='centertext inlinediv' style={{width: WIDTH_COLMATCH_TEXT, background: DEBUG? 'pink' : 'unset'}}>
            Match Column
          </div>
          <div className='centertext inlinediv' style={{width: WIDTH_CARET}}/>
          <div className='centertext inlinediv' style={{width: widthRelationAndGaps, background: DEBUG? 'yellow' : 'unset'}}>
            Relation
          </div>
          <div className='centertext inlinediv' style={{width: WIDTH_CARET}}/>
          <div className='centertext inlinediv' style={{width: WIDTH_VALUE, background: DEBUG? 'pink' : 'unset'}}>
            Match Value(s)
          </div>
        </div>
}

{rowFilters && rowFilters.map((rule: FilterRule, ruleIndex: number) => {
          if (ruleIndex >= maxNumFilters ) { return null }
          return (
            <FilterRuleItem
              key={rule.key}
              derivedColOrder={derivedColOrder}
              derivedColAttributesArray={tableComputedData.derivedColAttributesArray}
              findItemIndex={this.findItemIndex}
              moveItem={this.moveItem}
              rowFilters={rowFilters}
              ruleCount={ruleIndex < filterRuleCounts.length ? filterRuleCounts[ruleIndex] : filteredRowKeys.length}
              ruleIndex={ruleIndex}
              seriesKey={seriesKey}

              resourceName={resourceName}
              resourcePath={ this.getResourcePath() }
              multiLineMode={multiLineMode}
              filterLayout={filterLayout}
            />
          )
})}

      </div>
    )
  }
}


const mapDropTarget = {
  drop: (props: Props, monitor: DropTargetMonitor): Object | null => {
    return null
  },
}
const mapCollectedProps = (connect: DropTargetConnector, monitor: DropTargetMonitor): CollectProps => (
  {
    connectDropTarget: connect.dropTarget(),
  }
)

const FilterRules = DropTarget(DragFilterRuleItemType, mapDropTarget, mapCollectedProps)(FilterRulesRender)
export default FilterRules
