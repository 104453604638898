import { PureComponent } from 'react'
import { TABLE_CONTROLS_OPACITY } from '../sharedComponents/constants'

type OwnProps = {
  width: number
  height: number
  color: string
  typeID: 'unhideOnly' | 'hideAndUnhide'
  indexID: number
}

export default class SVGhideUnhide extends PureComponent<OwnProps> {

  render() {
    const { indexID, typeID, width, height } = this.props
    const countText = (indexID > 9) ? '+' : String(indexID)
    // Case of shorter 'unhide' arrow only.
    if (typeID === 'unhideOnly') {
      return (
        <svg
          className={'rc_SVGdhideUnhide'}
          xmlns="http://www.w3.org/2000/svg"
          style={{
            isolation: "isolate",
            display: 'block',
            opacity: TABLE_CONTROLS_OPACITY
          }}
          viewBox="0 0 40 40"
          preserveAspectRatio='none'
          width={width}
          height={height}
        >
          <defs>
            <clipPath id="_clipPath_Xy2QrnbVIplGpwfLxegKRgno2abw2zPO">
              <rect width="40" height="40" />
            </clipPath>
          </defs>
          <g clipPath="url(#_clipPath_Xy2QrnbVIplGpwfLxegKRgno2abw2zPO)">
            <g id="Group">
              <path d=" M 10.483 18 L 22 9 L 10.483 0 L 10.483 18 Z " fill={this.props.color} />
              <rect x="1" y="6" width="17.017" height="6" transform="matrix(1,0,0,1,0,0)" fill={this.props.color} />
            </g>
            <line x1="0" y1="1" x2="0" y2="39"
              vectorEffect="non-scaling-stroke"
              strokeWidth="1"
              stroke={this.props.color}
              strokeLinejoin="miter"
              strokeLinecap="square"
              strokeMiterlimit="3" />

            <text x='12' y='36' textAnchor='middle' fill={this.props.color} fontSize='22' >{countText}</text>
          </g>
        </svg>
      )

      // Case of Hide arrow, but no unhide arrow.
    } else if (indexID === 0) {
      return (
        <svg
          className={'rc_SVGhideUnhide'}
          xmlns="http://www.w3.org/2000/svg"
          style={{
            isolation: "isolate", display: 'block',
            opacity: TABLE_CONTROLS_OPACITY
          }}
          viewBox="0 0 40 40"
          preserveAspectRatio='none'
          width="100%" height="100%">
          <defs>
            <clipPath id="_clipPath_Xy2QrnbVIplGpwfLxegKRgno2abw2zPO">
              <rect width="40" height="40" />
            </clipPath>
          </defs>
          <g id="Group">
            <path d=" M 20 21.996 L 8 30.955 L 20 39.996 L 20 21.996 Z " fill={this.props.color} />
            <rect x="19" y="28" width="22" height="6" transform="matrix(-1,-0.003,0.003,-1,52.858,62.092)" fill={this.props.color} />
          </g>
        </svg>
      )

      // Case of both a hide and unhide arrow
    } else {
      return (
        <svg
          className={'rc_SVGhideUnhide'}
          xmlns="http://www.w3.org/2000/svg"
          style={{ isolation: "isolate", display: 'block', opacity: TABLE_CONTROLS_OPACITY }}
          viewBox="0 0 40 40"
          preserveAspectRatio='none'
          width="100%" height="100%">
          <defs>
            <clipPath id="_clipPath_Xy2QrnbVIplGpwfLxegKRgno2abw2zPO">
              <rect width="40" height="40" />
            </clipPath>
          </defs>
          <g clipPath="url(#_clipPath_Xy2QrnbVIplGpwfLxegKRgno2abw2zPO)">
            <g id="Group">
              <path d=" M 14.483 18 L 26 9 L 14.483 0 L 14.483 18 Z " fill={this.props.color} />
              <rect x="1" y="6" width="17.017" height="6" transform="matrix(1,0,0,1,0,0)" fill={this.props.color} />
            </g>
            <g id="Group">
              <path d=" M 20 21.996 L 8 30.955 L 20 39.996 L 20 21.996 Z " fill={this.props.color} />
              <rect x="17" y="28" width="22" height="6" transform="matrix(-1,-0.003,0.003,-1,52.858,62.092)" fill={this.props.color} />
            </g>
            <text x='32' y='16' textAnchor='middle' fill={this.props.color} fontSize='22' >{countText}</text>
          </g>
        </svg>
      )
    }
  }
}
