import { PureComponent } from 'react'

const PRE_SPACER = 20
const HEIGHT = 20
const POST_SPACER =30
const FONT_SIZE = 16
const DEBUG = false

// text1 can be null - means do allocate this space
// text1 can be ''   - Means allocated the space but don't show anything
// text1 can be 'hello world' - Means allocate space and show text1
// text0 considered manditory, or why else call this component?


type Props = {
  text0: string
  text1?: string
}

export default class StyleTitle extends PureComponent<Props> {
  render( ) {
    const {text0, text1} = this.props
    const totalHeight = PRE_SPACER + HEIGHT + POST_SPACER + (text1 ? HEIGHT : 0)
    return (
      <div className={'rc_StyleTitle'}
        style={{
          flex: '0 0 auto',
          fontSize:FONT_SIZE,
          fontWeight:'bold',
          width:'100%',
          height:totalHeight,
          textAlign: 'center',
        }}
      >
          <div className={'SideBarTitleLine0'}
            style={{
              height:HEIGHT,
              paddingTop: (HEIGHT-FONT_SIZE)/2,
              marginTop: PRE_SPACER,
              marginBottom: text1 ? 0 : POST_SPACER,
              background: DEBUG ? 'pink' : 'none',
            }}> {text0} </div>

{ text1 &&
          <div className={'SideBarTitleLine1'}
            style={{
              //position: 'relative',
              //width: '100%', height:HEIGHT,
              height:HEIGHT,
              paddingTop: (HEIGHT-FONT_SIZE)/2,
              marginTop: 0,
              marginBottom: text1 ? POST_SPACER : 0,
              background: DEBUG ? 'yellow' : 'none',
            }}> {text1} </div>
}
      </div>
    )
  }
}
