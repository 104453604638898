const invalidCharsRegEx = /[\\/<>:"|?*]/g

// based on https://stackoverflow.com/questions/11112321/how-to-save-canvas-as-png-image

// call with code similar to:
// const canvases = document.getElementsByClassName('rv-xy-canvas-element')
// if (canvases.length > 0) {
//   const canvas = canvases[0]
//   const {title} = this.props.plot.attributes
//   if (canvas instanceof HTMLCanvasElement) {
//     canvasDownload(canvas, title + '.png')
//   }
// }

export const canvasDownload = (canvas: HTMLCanvasElement, filename: string) => {
  const cleanName = filename.replace(invalidCharsRegEx, '')
  const dataUrl = canvas.toDataURL('image/png')
  var tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = dataUrl
  tempLink.setAttribute('download', cleanName)
  if (!tempLink.download) {
    tempLink.setAttribute('target', '_blank')
  }
  const body = document.body
  if (body) {
    body.appendChild(tempLink)
    tempLink.click()
    body.removeChild(tempLink)
  }
}

// based on https://github.com/kennethjiang/js-file-download/blob/master/file-download.js

export const fileDownload = (data: BlobPart, filename: string, mime: string) => {
  const cleanName = filename.replace(invalidCharsRegEx, '')
  const blob = new Blob([data], {type: mime || 'application/octet-stream'})

  // previously we had a work around for downloads in Microsoft Internet Explorer,
  // but since that browser is now dead, I removed the workaround
  var blobURL = window.URL.createObjectURL(blob)
  var tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = blobURL
  tempLink.setAttribute('download', cleanName)

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (!tempLink.download) {
    tempLink.setAttribute('target', '_blank')
  }
  const body = document.body
  if (body) {
    body.appendChild(tempLink)
    tempLink.click()
    body.removeChild(tempLink)
  }
  window.URL.revokeObjectURL(blobURL)
}

export default fileDownload
