const joi = require('joi')

const tablelookAttributes = {
  isBrightField: joi.boolean()
    .description('Flag indicating field around tables is bright or dark.'),
  isEditMode: joi.boolean()
    .description('Flag indicating whether to set table Editors/viewers to editable.'),
  greyHeadCells: joi.number()
    .description('The greyScale of the locked cells.'),
  greyDataCells: joi.number()
    .description('The greyScale of the locked cells.'),
  greyAlternateRow: joi.number()
    .description('The greyScale of alternating rows.'),
  greyGridHorz: joi.number()
    .description('The grey scale of gridlines.'),
  greyGridVert: joi.number()
    .description('The grey scale of gridlines.'),
  borderThickness: joi.number().integer()
    .description('Thickness of the border abound the table.'),
  tableSpacing: joi.number().integer()
    .description('Thickness of gaps between tables.'),
  globalScale: joi.number()
    .description('Scale multiplier'),
  colOrder: joi.array()
    .items(joi.number().integer()).allow(null)
    .description('Array of column ids in the order they should display.'),
  
  columns: joi.array().items(joi.object().keys({
      colTitle: joi.string().allow(''),
      colSourceTitle: joi.string().allow(''),
      units: joi.string().allow(''),
      colDescription: joi.object().keys({
        text: joi.string().allow(''),
        links: joi.array().items(joi.object().keys({
          startCharIndex: joi.number().integer(),
          stopCharIndex: joi.number().integer(),
          href: joi.string().allow('')
        }))
      }),
      formula: joi.array().items(joi.string().allow(''))
               .description('Array of formula strings. One string per line.'),
      isDeleted: joi.boolean(),
      isDeletedNotRestorable: joi.boolean(),
      deletedDate: joi.string().allow(''),
      isDepCol: joi.boolean(),
      isKey: joi.boolean()
        .description('Flag indicating whether this column is part of the row key or not.'),
      colDataType: joi.string().valid('string', 'number', 'numberSeconds', 'numberDegrees', 'hyperlink')
    }).allow(null))
    .description('Definition for the columns of the tablelook'),

  lookColumns: joi.array().items(joi.object().keys({
      hidden: joi.number().integer()
        .description('Value indicating whether this column is hidden or not. 0 means not hidden. Greater than zero indicates hidden, where the smallest non-zero is the first hidden, and the largest value is the most recently hidden.'),
      width: joi.number()
        .description('Base Width at nominal global scale in pixels with 0.01 resolution.'),
      formatRule: joi.string(),
      precisionMin: joi.number()
        .description('Number of significant digits in min or std mode.'),
      precisionFixed: joi.number()
        .description('Number of fixed digits right of decimal in fixed mode.'),
      precisionMode: joi.string().allow('','std', 'fixed', 'min'),
      prefix: joi.string().allow(''),
      suffix: joi.string().allow(''),
    }).allow(null))
    .description('Per column appearance attributes.'),
  isRowNumberVisible: joi.boolean()
    .description('Flag indicating whether to show row numbers or not.'),
  rowFilters: joi.array().items(joi.object().keys({
      colKey: joi.number().integer()
        .description('Column ID for this filter rule'),
      enabled: joi.boolean().description('Boolean flag indicating if this rule should be applied.'),
      key: joi.number().integer().description('Unique (within this list of row filters) number to identify this filter rule'),
      relation: joi.string().valid('unset', '<', '<=', '<>', '=', '==', '>', '>=', 'all', 'any', 'none')
        .description('Contextual Query Language (CQL) relation to use when filtering the cells of this the column identified by colKey'),
      value: joi.string().allow('').allow(null)
        .description('space separated list of terms for the specified relation')
    }).allow(null))
    .description('Definition for filters limiting displayed rows.'),
  rowSpacing: joi.number()
    .description('The scaled height of each row'),
  rowSortColIds: joi.array()
    .items(joi.string())
    .description(`Array of signed col ids e.g. [ '+1','-0','-4']`),
  recentPlots: joi.array()
    .items(joi.string())
    .description('Array of most recently used plot uu_ids; Lastest used at first index.'),
  pinnedRowKeys: joi.array()
    .items( joi.number().integer().min(0) ).allow(null)
    .description('Array of row ids in the order they should display.'),
  minorState: joi.object().keys({
      isSideBarVisible: joi.boolean(),
      sideBarTabIndex: joi.number().integer(),
      scrollTop: joi.number(),
      scrollLeft: joi.number(),
      isEditorOpen: joi.boolean(),
      cellEditor_left: joi.number(),
      cellEditor_top : joi.number(),
      stringEditorSize: joi.array()
        .items( joi.object().keys({
          width:  joi.number().description( 'Width of textEditor for string dataTypes'),
          height: joi.number().description( 'Height of textEditor for string dataTypes')
        }).allow(null)),
      selection: joi.object().keys({
          name: joi.string().valid('tableHeader', 'pinnedCell', 'dataCell', 'mainTitle', 'publisherTitle', 'rowFiltering', '' ),
          colIndex: joi.number().integer(),
          rowIndex: joi.number().integer(),
        }).description('selected cell in a table.'),
    }).description('Recommenations for the size and position of the table.'),
  numLockedCols: joi.number().integer().description('The count of columns that are locked.'),
  publisherTitleFontScale: joi.number()
    .description('Scale multiplier for the source sub-title.'),
  mainTitleFontScale: joi.number()
    .description('Scale multiplier for the main table title.'),
  updatedDate: joi.date().iso().description('Date table appearance last modified.'),

  // server side managed data (read only to clients)
  createdDate: joi.date().iso().allow(null).meta('readonly').description('Read only date table created.'),
}

module.exports = tablelookAttributes
