import { PureComponent } from 'react'
import type { JSX } from 'react'

class FormNote extends PureComponent<{
  children: JSX.Element | JSX.Element[] | string
}> {
  render () {
    const {children} = this.props
    return (
      <p className={'rc_FormNote'}
        style={{
          color: '#586069',
          fontSize: '12px',
          margin: '4px 0 2px',
          minHeight: '17px',
        }}
      >
        {children}
      </p>
    )
  }
}

export default FormNote
