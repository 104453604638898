import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import invariant from 'invariant'
import type { ParentType, ResourceType } from '../jsonapi/types'
import type { MinorMod, Mod, ModOp } from '../types'

export type ParentID = {
  parentId: string,
  parentType: ParentType,
}

export type Trigger = 'do' | 'undo' | 'redo'

export type UndoParams = {
  parentId: string,
  parentType: ParentType,
  mods: Array<Mod>,
  title: string,
  actionGroup: string
}

export type UndoPayload = UndoParams & {
  time: number,
  trigger: Trigger,
  minorState: Object
}

export type MinorPayload = {
  mods: Array<MinorMod>,
  parentId: string,
  parentType: ParentType,
  resId: string,
  resType: ResourceType,
}

export type SaveChangesPayload = {
  parentId: string,
  parentType: ParentType,
  from: number,
  to: number,
}

export type SaveChangesCheck = {
  time: number
}

/*

JPS notes on actionGroup = 'drag' or 'dragStop'
These modules use drag, and code was modified to use actionGroup.
  LegendDnD  ( DnD of the series order in Define Plot Data tab.)
  FiltersDnD ( DnD of the row filtering order in Table Row Filters tab.)
  StyleEnumSlider
  StyleLinearSlider
  Table ColumnDnD
  ExtentResize (This module has an option to use a dispatch in the middle
                of drag to change table layout from 1=>2 tables, or 2=>1 table.
                This option is currently set to 'off'.  Even so, I added 'drag'
                and dragStop actionGroups to the dispatches.  In case we ever
                decide we want to turn this option back 'on'.

These modules use drag, but the state change ALWAYS occurs at dragStop.
Hence actionGroup is not needed or used:
  Column Resize
  3 Custom Table-ScrollControls

actionResizeWindow -- this action issues: reactDispatch ( mods, 'Browser Window Resize' )
I guess undo should ignore this action alltogether, as there is no way to UnDo it.

onTouchScroll -- reactDispatch( mods, 'Touch Scroll Used')
No access to a stopDrag indicator.  The module uses a timeout currently set to
100ms.  Criteria for timeout is 'Dragging has paused enough that I can stick in a
react state change without it probably being noticed, but soon enough such that
user doesn't have time to squeeze in some other action.'   This timeout is
probably too short for purpose of combining actions.  But a suitable timeout
for the UnDO code will almost certainly be too long for the onTouchScroll handler.
Difference is the onTouchScroll handler CANNOT predict when the next unrelated
action will occur.  Hence a very short timeout in order to keep the actions
in the proper order.  UnDo code can afford a longer timeout.  For example:
1000ms, or the next unrelated action - whichever comes first.

*/

// export const createDoAction = createAction('UNDO_DO_ACTION', (resType: string, resId: string, title: string, mods: Array<Mod>, actionGroup: string='', parentType?: ParentType, parentId?: string) => ({
//   payload: {
//     mods,
//     parentId: parentId ?? resId,
//     parentType: parentType ?? resType as ParentType,
//     resId,
//     resType,
//     time: Date.now(),
//     title,
//     trigger: 'do',
//     actionGroup,
//     minorState: {},
//   }
// }))

// export const createNoOpAction = createAction('UNDO_NOOP_ACTION', (resType: string, resId: string, title: string, mods: Array<Mod>, actionGroup: string='', parentType?: ParentType, parentId?: string) => ({
//   payload: {
//     mods,
//     parentId: parentId ?? resId,
//     parentType: parentType ?? resType as ParentType,
//     resId,
//     resType,
//     time: Date.now(),
//     title,
//     trigger: 'do',
//     actionGroup,
//     minorState: {},
//   }
// }))


// export const createMinorAction = createAction('UNDO_MINOR_ACTION', (resType: string, resId: string, mods: Array<MinorMod>, parentType?: ParentType, parentId?: string) => ({
//   payload: {
//     mods,
//     parentId: parentId ?? resId,
//     parentType: parentType ?? resType as ParentType,
//     resId,
//     resType
//   }
// }))

// export const createRedoAction = createAction<ParentID>('UNDO_REDO_ACTION')
// export const createUndoAction = createAction<ParentID>('UNDO_UNDO_ACTION')

// export const saveChangesCheck = createAction('UNDO_SAVE_CHANGES_CHECK', () => ({
//   payload: {
//     time: Date.now()
//   }
// }))

// export const saveChangesFail = createAction('UNDO_SAVE_CHANGES_FAIL', (parentType: ParentType, parentId: string) => ({
//   payload: {
//     parentType,
//     parentId
//   }
// }))
// export const saveChangesStart = createAction('UNDO_SAVE_CHANGES_START', (parentType: ParentType, parentId: string, from: number, to: number) => ({
//   payload: {
//     parentType,
//     parentId,
//     from,
//     to
//   }
// }))
// export const saveChangesSuccess = createAction('UNDO_SAVE_CHANGES_SUCCESS', (parentType: ParentType, parentId: string) => ({
//   payload: {
//     parentType,
//     parentId
//   }
// }))



// export const createDoAction = createAction(UNDO_DO_ACTION, (resType: string, resId: string, title: string, mods: Array<Mod>, actionGroup:string='', parentType: ?string, parentId: ?string): UndoPayload =>
//                                               ({
//                                                   mods,
//                                                   parentId: parentId ? parentId : resId,
//                                                   parentType: parentType ? parentType : resType,
//                                                   resId,
//                                                   resType,
//                                                   time: Date.now(),
//                                                   title,
//                                                   trigger: 'do',
//                                                   actionGroup,
//                                                   minorState: {},
//                                                 }))
// export const createMinorAction = createAction(UNDO_MINOR_ACTION, (resType: string, resId: string, mods: Array<MinorMod>, parentType: ?string, parentId: ?string): MinorPayload =>
//                                               ({
//                                                   mods,
//                                                   parentId: parentId ? parentId : resId,
//                                                   parentType: parentType ? parentType : resType,
//                                                   resId,
//                                                   resType,
//                                                 }))
// export const createRedoAction = createAction(UNDO_REDO_ACTION, (parentType: string, parentId: string): ParentID =>
//                                               ({parentType, parentId}))
// export const createUndoAction = createAction(UNDO_UNDO_ACTION, (parentType: string, parentId: string): ParentID =>
//                                               ({parentType, parentId}))
// export const saveChangesCheck = createAction(UNDO_SAVE_CHANGES_CHECK, (): SaveChangesCheck =>
//                                               ({time: Date.now()}))
// export const saveChangesFail = createAction(UNDO_SAVE_CHANGES_FAIL, (parentType: string, parentId: string): ParentID =>
//                                               ({parentId, parentType}))
// export const saveChangesStart = createAction(UNDO_SAVE_CHANGES_START, (parentType: string, parentId: string, from: number, to: number): SaveChangesPayload =>
//                                               ({
//                                                   from,
//                                                   parentId,
//                                                   parentType,
//                                                   to
//                                               }))
// export const saveChangesSuccess = createAction(UNDO_SAVE_CHANGES_SUCCESS, (parentType: string, parentId: string): ParentID =>
//                                               ({parentId, parentType}))

// export type UndoAction = ActionType<typeof createDoAction>
//                           | ActionType<typeof createRedoAction>
//                           | ActionType<typeof createUndoAction>
//                           | ActionType<typeof saveChangesFail>
//                           | ActionType<typeof saveChangesStart>
//                           | ActionType<typeof saveChangesSuccess>

export type UndoLog = {
  actions: Array<UndoPayload>,
  redoLimitIndex: number,
  undoPointerIndex: number,
}

export const defaultUndoLog = (): UndoLog => ({
  actions: [],
  redoLimitIndex: -1,
  undoPointerIndex: 0,
})

export type UndoLogs = {
  [key: string]: UndoLog
}

export type UndoParents = {
  [key in ParentType]: UndoLogs
}

type SaveStatus = {
  pendingSaveIndex: number,
  savedAtIndex: number,
}

const defaultSaveStatus = (): SaveStatus => ({
  pendingSaveIndex: -1,
  savedAtIndex: -1,
})

type SaveStatusItems = {
  [key: string]: SaveStatus // parent id
}

type SaveStatuses = {
  [key in ParentType]: SaveStatusItems
}

// Acts is an object whose keys are resource types.
// The values of those keys are objects whose keys are resource ids for that type.
// The value for each resource id is an UndoLog containing an array of undo
// payloads in the order recieved, and a undoPointerIndex to which action will be undone
// with the next undo command.
export type UndoState = {
  parents: UndoParents
  saveStatus: SaveStatuses
  curActionGroup: string
}

const defaultUndoState: UndoState = {
  parents: {
    plots: {},
    tables: {}
  },
  saveStatus: {
    plots: {},
    tables: {}
  },
  curActionGroup: ''
}

const manageCurActionGroup = (state: UndoState, actionGroup: string): boolean => {
  const startFresh = state.curActionGroup === ''
  if (actionGroup.endsWith('Stop')) {
    state.curActionGroup = ''
  } else {
    state.curActionGroup = actionGroup
  }
  return startFresh
}

const isSimpleAction = (action: UndoPayload): boolean => {
  const {mods} = action
  if (mods.length === 1) {
    const newValType = typeof mods[0].newVal
    const isSimple = newValType === 'boolean' || newValType === 'number' || newValType === 'string'
    return isSimple
  }
  return false
}

// const doActionHandler = (state: UndoState, action): UndoState => {
//   const payload: UndoPayload = action.payload
//   const {actionGroup, mods, parentId, parentType, time} = payload
//   const status = _.get(saveStatus, [parentType, parentId], defaultSaveStatus())
//   const {pendingSaveIndex, savedAtIndex} = status
//   const undoLog = _.get(state, ['parents', parentType, parentId], defaultUndoLog())
//   const {actions} = undoLog
//   const actionsLength = actions.length
//   const lastIndex = actionsLength - 1
//   let immState = immutable.wrap(state)
//   const startFresh = manageCurActionGroup(actionGroup)

//   // If the most recent action has not been sent to the server (pending or saved)
//   // it might be possible to combine it with the previous action
//   if (lastIndex > pendingSaveIndex && lastIndex > savedAtIndex) {
//     const lastIndexStr = String(lastIndex)
//     const prevAction = _.get(state, ['parents', parentType, parentId, 'actions', lastIndexStr], null)
//     // Check to see if this is a simple action modifying the same resource and is
//     // within a quarter second of the previous action.
//     if (isSimpleAction(payload)
//       && prevAction
//       && prevAction.mods[0].restype === mods[0].resType
//       && prevAction.mods[0].resId === mods[0].resId
//       && prevAction.trigger === 'do'
//       && time - prevAction.time < 250
//     ) {
//       // This is a candidate for modifying the existing undo action, rather than pushing a new one.
//       // Check if the previous action was also simple and was on the same path
//       if (isSimpleAction(prevAction)
//         && mods[0].path === prevAction.mods[0].path
//       ) {
//         // it's a match!
//         immState = immState.set(['parents', parentType, parentId, 'actions', lastIndexStr, 'time'], time)
//         immState = immState.set(['parents', parentType, parentId, 'actions', lastIndexStr, 'mods', '0', 'newVal'], mods[0].newVal)
//         const newState = immState.value()
//         return newState
//       }
//     }
//     // if we are in an actionGroup, and previous action is in same group,
//     // combine actions regardless of time or complexity, unless startFresh is set.
//     if (!startFresh && prevAction && prevAction.actionGroup && curActionGroup.startsWith(prevAction.actionGroup)) {
//       immState = immState.set(['parents', parentType, parentId, 'actions', lastIndexStr, 'time'], time)
//       mods.forEach((mod: Mod): void => {
//         const matchingIndex = prevAction.mods.findIndex((el: Mod): boolean => (el.path === mod.path && el.resId === mod.resId))
//         if (matchingIndex >= 0) {
//           immState = immState.set(['parents', parentType, parentId, 'actions', lastIndexStr, 'mods', matchingIndex, 'newVal'], mod.newVal)
//         } else {
//           // no match, so append it
//           immState = immState.push(['parents', parentType, parentId, 'actions', lastIndexStr, 'mods'], mod)
//         }
//       })
//       const newState = immState.value()
//       return newState
//     }
//   }

//   if (!_.has(state, ['parents', parentType])) {
//     saveStatus[parentType] = {}
//     immState = immState.set(['parents', parentType], {})
//   }
//   if (!_.has(state, ['parents', parentType, parentId])) {
//     saveStatus[parentType][parentId] = defaultSaveStatus()
//     immState = immState.set(['parents', parentType, parentId], defaultUndoLog())
//   } else {
//     immState = immState.set(['parents', parentType, parentId, 'undoPointerIndex'], actionsLength)
//     immState = immState.set(['parents', parentType, parentId, 'redoLimitIndex'], -1)
//   }
//   immState = immState.push(['parents', parentType, parentId, 'actions'], action.payload)
//   const newState = immState.value()
//   return newState
// }

// const noOpActionHandler = (state: UndoState, action: ActionType<typeof createDoAction>): UndoState => {
//   // need to manage curActionGroup, even on a no-op action
//   const payload: UndoPayload = action.payload
//   const {actionGroup} = payload
//   manageCurActionGroup(actionGroup)
//   return state
// }

// const redoActionHandler = (state: UndoState, action: ActionType<typeof createRedoAction>): UndoState => {
//   const {parentId, parentType} = action.payload
//   const undoLog = state.parents[parentType][parentId]
//   const undoPointerIndex = undoLog.undoPointerIndex
//   const doAction = undoLog.actions[undoPointerIndex + 1]
//   const redoAction = {
//     ...doAction,
//     time: Date.now(),
//     trigger: 'redo',
//     actionGroup: '',
//   }
//   let immState = immutable.wrap(state)
//   immState = immState.set(['parents', parentType, parentId, 'undoPointerIndex'], undoPointerIndex + 1)
//   immState = immState.push(['parents', parentType, parentId, 'actions'], redoAction)
//   const newState = immState.value()
//   return newState
// }

// const saveChangesFailHandler = (state: UndoState, action: ActionType<typeof saveChangesFail>): UndoState => {
//   const {parentType, parentId} = action.payload
//   const {pendingSaveIndex} = saveStatus[parentType][parentId]
//   invariant(pendingSaveIndex > -1, 'saveChangesFailHandler whithout pending save.')
//   saveStatus[parentType][parentId].pendingSaveIndex = -1
//   return state
// }

// const saveChangesStartHandler = (state: UndoState, action: ActionType<typeof saveChangesStart>): UndoState => {
//   const {parentType, parentId, from, to} = action.payload
//   const {pendingSaveIndex, savedAtIndex} = saveStatus[parentType][parentId]
//   invariant(pendingSaveIndex === -1, 'saveChangesStartHandler when there is already a pending save.')
//   invariant(savedAtIndex === from, 'Trying to save changes with wrong start point')
//   saveStatus[parentType][parentId].pendingSaveIndex = to
//   return state
// }

// const saveChangesSuccessHandler = (state: UndoState, action: ActionType<typeof saveChangesSuccess>): UndoState => {
//   const {parentType, parentId} = action.payload
//   const {pendingSaveIndex} = saveStatus[parentType][parentId]
//   invariant(pendingSaveIndex > -1, 'saveChangesSuccessHandler whithout pending save.')
//   saveStatus[parentType][parentId].savedAtIndex = pendingSaveIndex
//   saveStatus[parentType][parentId].pendingSaveIndex = -1
//   return state
// }

// const undoActionHandler = (state: UndoState, action: ActionType<typeof createUndoAction>): UndoState => {
//   const {parentId, parentType} = action.payload
//   const undoLog = state.parents[parentType][parentId]
//   const {actions, undoPointerIndex, redoLimitIndex} = undoLog
//   const doAction = actions[undoPointerIndex]
//   const {minorState, mods, title} = doAction
//   const reverseMods = []
//   for (let i = mods.length - 1; i >= 0; i--) {
//     const originalMod = mods[i]
//     const reverseMod = {
//       newVal: originalMod.oldVal,
//       oldVal: originalMod.newVal,
//       op: originalMod.op === 'del' ? 'add' : originalMod.op === 'add' ? 'del' : originalMod.op,
//       path: originalMod.path,
//       resId: originalMod.resId,
//       resType: originalMod.resType,
//     }
//     reverseMods.push(reverseMod)
//   }
//   const undoAction: UndoPayload = {
//     mods: reverseMods,
//     parentId,
//     parentType,
//     time: Date.now(),
//     title,
//     trigger: 'undo',
//     actionGroup: '',
//     minorState: minorState,
//   }
//   let immState = immutable.wrap(state)
//   invariant(undoPointerIndex > -1, `handler for UNDO_UNDO_ACTION called with invalid undoPointerIndex value: ${undoPointerIndex}`)
//   immState = immState.set(['parents', parentType, parentId, 'undoPointerIndex'], undoPointerIndex - 1)
//   if (redoLimitIndex === -1) {
//     immState = immState.set(['parents', parentType, parentId, 'redoLimitIndex'], undoPointerIndex)
//   }
//   immState = immState.push(['parents', parentType, parentId, 'actions'], undoAction)
//   const newState = immState.value()
//   return newState
// }

// const undoReducer = (state: UndoState = defaultUndoState, action: UndoAction): UndoState => {
//   switch (action.type) {
//     case UNDO_DO_ACTION:
//       return doActionHandler(state, action)
//     case UNDO_NOOP_ACTION:
//       return noOpActionHandler(state, action)
//     case UNDO_REDO_ACTION:
//       return redoActionHandler(state, action)
//     case UNDO_SAVE_CHANGES_FAIL:
//       return saveChangesFailHandler(state, action)
//     case UNDO_SAVE_CHANGES_START:
//       return saveChangesStartHandler(state, action)
//     case UNDO_SAVE_CHANGES_SUCCESS:
//       return saveChangesSuccessHandler(state, action)
//     case UNDO_UNDO_ACTION:
//       return undoActionHandler(state, action)
//     default:
//       return state
//   }
// }

const undoSlice = createSlice({
  name: 'undo',
  initialState: defaultUndoState,
  reducers: {
    createDoAction: {
      reducer(state, action: PayloadAction<UndoPayload>): void {
        const payload = action.payload
        const {actionGroup, mods, parentId, parentType, time} = payload
        const status = state.saveStatus[parentType][parentId] ?? defaultSaveStatus()
        const {pendingSaveIndex, savedAtIndex} = status
        const undoLog = state.parents[parentType][parentId] ?? defaultUndoLog()
        const {actions} = undoLog
        const actionsLength = actions.length
        const lastIndex = actionsLength - 1
        const startFresh = manageCurActionGroup(state, actionGroup)

        // If the most recent action has not been sent to the server (pending or saved)
        // it might be possible to combine it with the previous action
        if (lastIndex > pendingSaveIndex && lastIndex > savedAtIndex) {
          const prevAction = state.parents[parentType]?.[parentId]?.actions?.[lastIndex]
          // Check to see if this is a simple action modifying the same resource and is
          // within a quarter second of the previous action.
          if (isSimpleAction(payload)
            && prevAction
            && prevAction.mods[0].resType === mods[0].resType
            && prevAction.mods[0].resId === mods[0].resId
            && prevAction.trigger === 'do'
            && time - prevAction.time < 250
          ) {
            // This is a candidate for modifying the existing undo action, rather than pushing a new one.
            // Check if the previous action was also simple and was on the same path
            if (isSimpleAction(prevAction)
              && mods[0].path === prevAction.mods[0].path
            ) {
              // it's a match!
              state.parents[parentType][parentId].actions[lastIndex].time = time
              state.parents[parentType][parentId].actions[lastIndex].mods[0].newVal = mods[0].newVal
              return
            }
          }
          // if we are in an actionGroup, and previous action is in same group,
          // combine actions regardless of time or complexity, unless startFresh is set.
          if (!startFresh && prevAction && prevAction.actionGroup && state.curActionGroup.startsWith(prevAction.actionGroup)) {
            state.parents[parentType][parentId].actions[lastIndex].time = time
            for (const mod of mods) {
              const matchingIndex = prevAction.mods.findIndex((el: Mod): boolean => (el.path === mod.path && el.resId === mod.resId))
              if (matchingIndex >= 0) {
                state.parents[parentType][parentId].actions[lastIndex].mods[matchingIndex].newVal = mod.newVal
              } else {
                // no match, so append it
                state.parents[parentType][parentId].actions[lastIndex].mods.push(mod)
              }
            }
            return
          }
        }

        if (!state.parents[parentType]) {
          state.saveStatus[parentType] = {}
          state.parents[parentType] = {}
        }
        if (!state.parents[parentType][parentId]) {
          state.saveStatus[parentType][parentId] = defaultSaveStatus()
          state.parents[parentType][parentId] = defaultUndoLog()
        } else {
          state.parents[parentType][parentId].undoPointerIndex = actionsLength
          state.parents[parentType][parentId].redoLimitIndex = -1
        }
        state.parents[parentType][parentId].actions.push(action.payload)
      },
      prepare(undo: UndoParams) {
        const trigger: Trigger = 'do'
        return {
          payload: {
            ...undo,
            time: Date.now(),
            trigger,
            minorState: {} // TODO: JKS remove minor state. Temp fix is check for isEmpty and ignore in undoMiddleware
          }
        }
      }
    },
    createNoOpAction(state, action: PayloadAction<UndoPayload>): void {
        // need to manage curActionGroup, even on a no-op action
        const {actionGroup} = action.payload
        manageCurActionGroup(state, actionGroup)
    },
    createMinorAction(state, action: PayloadAction<MinorPayload>): void {
    },
    createRedoAction(state, action: PayloadAction<ParentID>): void {
      const {parentId, parentType} = action.payload
      const undoLog = state.parents[parentType][parentId]
      const undoPointerIndex = undoLog.undoPointerIndex
      const doAction = undoLog.actions[undoPointerIndex + 1]
      const trigger: Trigger = 'redo'
      const redoAction = {
        ...doAction,
        time: Date.now(),
        trigger,
        actionGroup: '',
      }
      state.parents[parentType][parentId].undoPointerIndex = undoPointerIndex + 1
      state.parents[parentType][parentId].actions.push(redoAction)
    },
    saveChangesCheck(state, action: PayloadAction<SaveChangesCheck>): void {
    },
    saveChangesFail(state, action: PayloadAction<ParentID>): void {
      const {parentType, parentId} = action.payload
      const {pendingSaveIndex} = state.saveStatus[parentType][parentId]
      invariant(pendingSaveIndex > -1, 'saveChangesFailHandler whithout pending save.')
      state.saveStatus[parentType][parentId].pendingSaveIndex = -1
    },
    saveChangesStart(state, action: PayloadAction<SaveChangesPayload>): void {
      const {parentType, parentId, from, to} = action.payload
      const {pendingSaveIndex, savedAtIndex} = state.saveStatus[parentType][parentId]
      invariant(pendingSaveIndex === -1, 'saveChangesStartHandler when there is already a pending save.')
      invariant(savedAtIndex === from, 'Trying to save changes with wrong start point')
      state.saveStatus[parentType][parentId].pendingSaveIndex = to
    },
    saveChangesSuccess(state, action: PayloadAction<ParentID>): void {
      const {parentType, parentId} = action.payload
      const {pendingSaveIndex} = state.saveStatus[parentType][parentId]
      invariant(pendingSaveIndex > -1, 'saveChangesSuccessHandler whithout pending save.')
      state.saveStatus[parentType][parentId].savedAtIndex = pendingSaveIndex
      state.saveStatus[parentType][parentId].pendingSaveIndex = -1
    },
    createUndoAction(state, action: PayloadAction<ParentID>): void {
      const {parentId, parentType} = action.payload
      const undoLog = state.parents[parentType][parentId]
      const {actions, undoPointerIndex, redoLimitIndex} = undoLog
      const doAction = actions[undoPointerIndex]
      const {minorState, mods, title} = doAction
      const reverseMods = []
      for (let i = mods.length - 1; i >= 0; i--) {
        const originalMod = mods[i]
        const reverseOp: ModOp = originalMod.op === 'del' ? 'add' : originalMod.op === 'add' ? 'del' : originalMod.op
        const reverseMod = {
          newVal: originalMod.oldVal,
          oldVal: originalMod.newVal,
          op: reverseOp,
          path: originalMod.path,
          resId: originalMod.resId,
          resType: originalMod.resType,
        }
        reverseMods.push(reverseMod)
      }
      const undoAction: UndoPayload = {
        mods: reverseMods,
        parentId,
        parentType,
        time: Date.now(),
        title,
        trigger: 'undo',
        actionGroup: '',
        minorState: minorState,
      }
      invariant(undoPointerIndex > -1, `handler for UNDO_UNDO_ACTION called with invalid undoPointerIndex value: ${undoPointerIndex}`)
      state.parents[parentType][parentId].undoPointerIndex = undoPointerIndex - 1
      if (redoLimitIndex === -1) {
        state.parents[parentType][parentId].redoLimitIndex = undoPointerIndex
      }
      state.parents[parentType][parentId].actions.push(undoAction)
    }
  }
})

const generateTitle = (undoLog: UndoLog, isRedo: boolean) => {
  const undoPayload = undoLog.actions[undoLog.undoPointerIndex + (isRedo ? 1 : 0)]
  const {title, trigger} = undoPayload
  const contextStr = trigger === 'do' ? '' : `${trigger} of `
  return `${isRedo ? 'Redo': 'Undo'} ${contextStr}${title}`
}
// undo selectors
export const getUndoTitle = (state: any, parentType: string, parentId: string): string | null => {
  const undoLog = state.undo.parents[parentType][parentId]
  if (undoLog && undoLog.actions.length > 0 && undoLog.undoPointerIndex > -1) {
    return generateTitle(undoLog, false)
  }
  return null
}

export const isUndoAvailable = (undoState: any, parentType: string, parentId: string): boolean => {
  const undoLog = undoState.parents[parentType][parentId]
  return undoLog && undoLog.actions.length > 0 && undoLog.undoPointerIndex > -1
}

export const getRedoTitle = (state: any, parentType: string, parentId: string): string | null => {
  const undoLog = state.undo.parents[parentType][parentId]
  if (undoLog && undoLog.undoPointerIndex < undoLog.redoLimitIndex && undoLog.undoPointerIndex > -2) {
    return generateTitle(undoLog, true)
  }
  return null
}

export const {
  createDoAction,
  createNoOpAction,
  createMinorAction,
  createRedoAction,
  saveChangesCheck,
  saveChangesFail,
  saveChangesStart,
  saveChangesSuccess,
  createUndoAction
} = undoSlice.actions

export default undoSlice.reducer
