const isActive: {[key: string]: boolean} = {}
export type TimeStamp = {
  msg: string
  time: Date
}
const timeStamps: {[key: string]: TimeStamp[]} = {}
var   timeoutFunctionID: number | undefined = undefined
const autoTimeOutInterval = 5000
var   currentActiveID = ''

const timeout_stopTimer = () => {
  //console.log( 'Call to timeout_stopTimer', currentActiveID, timeoutFunctionID )
  stopTimer(currentActiveID)
}

export const startTimer = ( id:string ): void => {
  // If timer is already active, do nothing.  Do NOT reset the prior timestamps
  if (isActive[id]) { return }

  isActive[id] = true
  currentActiveID = id
  timeStamps[id] = []
  timeStamps[id].push( {msg:`Start Timer for: ${id}`, time : new Date()} )
  timeoutFunctionID = window.setTimeout( timeout_stopTimer, autoTimeOutInterval )
  console.log( 'Call to startTimer', id, timeoutFunctionID)
}

export const logTime = ( id:string, msg:string ) : void => {
  if ( isActive[id] === undefined) { return }
  // Put an upper limit on size of buffer,
  // in case logTime is accidentally placed
  // inside some high iteration loop.
  if ( timeStamps[id].length > 100 ) { return }
  timeStamps[id].push( {msg, time : new Date()} )
  if (timeoutFunctionID) {window.clearTimeout( timeoutFunctionID )}
  timeoutFunctionID = window.setTimeout( timeout_stopTimer, autoTimeOutInterval )
  //console.log( 'Call to logTime', id, timeoutFunctionID)
}

// Automatic Timeout!

export const stopTimer = (id: string, forceRAF: boolean = false, inhibitOutput: boolean = false): TimeStamp[] => {
  if ( isActive[id] === undefined ) { return []  }

  var returnVal

  if ( forceRAF === false ) {
    if (!inhibitOutput) {
      outputTimeStamps( id )
    }
    returnVal = timeStamps[id].slice()
    delete isActive[id]
    delete timeStamps[id]
    return returnVal
  }

  // Otherwise, run two additional animation frames
  var animationFrameCounter = 0
  window.requestAnimationFrame(
    function timerFunc () {
      if (animationFrameCounter < 1 ) {
        //stopFrame0Time = new Date()
        //startFrame1Time = stopFrame0Time
        //animationFrameCounter++
        timeStamps[id].push( {msg: `Animation Frame ${animationFrameCounter}`, time : new Date()} )
        animationFrameCounter++
        window.requestAnimationFrame( timerFunc )
        return
      }
      timeStamps[id].push( {msg: `Animation Frame ${animationFrameCounter}`, time : new Date()} )
      if (!inhibitOutput) outputTimeStamps( id )
      delete isActive[id]
      delete timeStamps[id]
  })
  return timeStamps[id].slice()
}

const outputTimeStamps = ( id: string ): void => {
  console.log( 'TIMER (cumTime, deltaTime):' )
  var timeArray = timeStamps[id]
  var startTime = timeArray[0].time.getTime()
  var lastTime  = startTime
  timeArray.forEach ( (thisLog: TimeStamp): void => {
      console.log( '   ', thisLog.time.getTime() - startTime, thisLog.time.getTime() - lastTime, thisLog.msg )
      lastTime = thisLog.time.getTime()
  })
  console.log('')
}
