import type { PlotRange, PlotXyComputedData, SparsePlotPt } from './xy_plotTypes'

import invariant from 'invariant'
import { list, isEqual } from 'radash'
import { thinSpaceChar, smallPercentChar } from '../sharedComponents/constants'
import {
  percentileFromStdDev,
  stdDevFromPercentile
} from '../sharedFunctions/mathScry'
import { typedKeys } from '../sharedFunctions/utils'
import { plotLayoutConsts } from '../viewPlotXY/plotXyLayoutConsts'
import { cullPlotPts_Aaxis_binarySearch } from './cullPlotPts'
import { smartAxisScale } from './smartAxisScale'


export const createBasisB_PercentileAxis = (plt: PlotXyComputedData): void => {
  const { basisB } = plt
  const { min, max } = basisB.rangeCulledAllSeries
  // In case of degenerate case with no data:
  basisB.rangeSmartAxisAllSeries = (min === Infinity) ? { min: 0, max: 100 } : { min, max }
  smartAxisScale(basisB)

  basisB.axisTitle = 'Percentile'
  basisB.axisSubTitle = ''
  basisB.axisTitleShort = undefined
  basisB.tickFormatSuffixStrg = '%'
  basisB.internalDataType = 'number'
  basisB.isMaxDomainAuto = true
  basisB.isMinDomainAuto = true
  basisB.usersMaxDomain = ''
  basisB.usersMinDomain = ''
  basisB.nonlinearXform = (i) => i
  basisB.tickFormatRule = 'defaultEng'
}


export const createBasisB_NormalProbAxis = (plt: PlotXyComputedData): void => {
  const { basisB } = plt
  const { min, max } = basisB.rangeCulledAllSeries
  // Safety catch for no data:
  basisB.rangeSmartAxisAllSeries = (min === Infinity) ? { min: 1, max: 99 } : { min, max }
  // Next function will convert entire axis to domain and scale of +/- stdDeviations.
  // Also forces title, subTitle, nonLinearXform, domainSmartAxis, etc.
  let stdDevMin = stdDevFromPercentile(basisB.rangeSmartAxisAllSeries.min)
  let stdDevMax = stdDevFromPercentile(basisB.rangeSmartAxisAllSeries.max)
  const scaleExtensionInStdDev = .05 * (stdDevMax - stdDevMin) + .1
  stdDevMin -= scaleExtensionInStdDev
  stdDevMax += scaleExtensionInStdDev
  const { tickVisValuesLight, tickVisValuesDark, tickUserStringsNoHTML } =
    getNormalProbabilityTickVisValues(stdDevMin, stdDevMax)
  basisB.tickVisValues = tickVisValuesDark
  basisB.tickVisValuesLight = tickVisValuesLight
  basisB.tickUserStringsNoHTML = tickUserStringsNoHTML
  basisB.tickUserStringsMeasureOnly = tickUserStringsNoHTML
  // Trick to fit the unusual 'tick string widths' to plot a bit better:
  // Above strings lengths DO NOT include the last two characters: ' %'
  // They are added as a suffix.
  // Which is OK because the longest strings always occur 'beyond (above or below)' the
  // area of the subtitle:  Specifically strings like 99.9, 99.99, 99.999 ...  or 0.1, 0.02, 0.001, ...
  // However, in the case of percential data where the axis is shorter than '1 %' to ' 99 %',
  // Then:  In this case, our measured string lengths are too short and they will overlap
  // with the subtitle.  As most distributions include more than 100 samples, this
  // case is rare.  But easy to fix without degrading the look of the majority of plots.
  // If the first number(tickValue) is < 1 (specifically, 0.1, 0.01, ...) then
  // we want to measure a worse case tick string width of 4 characters!  Not 
  // what is currently in tickUserStringsNoHTML which as all 2 characters or less.
  // So just add ' %' to each measured string.  So room allotted is for a full 4 character string.
  if (Number(tickUserStringsNoHTML[0]) >= 1) {
    basisB.tickUserStringsMeasureOnly = basisB.tickUserStringsMeasureOnly.map(
      strg => strg + thinSpaceChar + smallPercentChar)
  }
  basisB.axisTitle = 'Percentile'
  basisB.axisTitleShort = undefined
  basisB.axisSubTitle = 'Normal Probability Scale'
  basisB.domainConstrained = [stdDevMin, stdDevMax]
  basisB.domainUnconstrained = [stdDevMin, stdDevMax]
  basisB.domainExtended = [stdDevMin, stdDevMax]
  basisB.internalDataType = 'number'
  basisB.tickFormatSuffixStrg = smallPercentChar
  basisB.isMaxDomainAuto = true
  basisB.isMinDomainAuto = true
  basisB.usersMaxDomain = ''
  basisB.usersMinDomain = ''
}




const getNormalProbabilityTickVisValues = (min: number, max: number): {
  tickVisValuesLight: number[],
  tickVisValuesDark: number[],
  tickUserStringsNoHTML: string[]
} => {
  const tickVisValuesLight = Array<number>()
  const tickVisValuesDark = Array<number>()
  const tickUserStringsNoHTML = Array<string>()
  const minPercentile = percentileFromStdDev(min)
  const maxPercentile = percentileFromStdDev(max)

  // All of this block is to get some reasonable tick labeling,
  // allowing for only a protion of the total B axis being visible,
  // based on choice of basisA user defined limits.
  const extremeLowLabels = [0.00001, 0.0001, 0.001, 0.01, .1, 1]
  const extremeHighLabels = [99, 99.9, 99.99, 99.999, 99.9999, 99.99999]
  const stdIntermediateLabels = [4, 10, 25, 50, 75, 90, 96]
  // Default labeling, from zero to one are:
  let labeledTicks = [...extremeLowLabels, ...stdIntermediateLabels, ...extremeHighLabels]
  if (maxPercentile < 91 && minPercentile > 9) {
    if (maxPercentile - minPercentile < 40) {
      labeledTicks = list(10, 91, 5)
    }
    if (maxPercentile - minPercentile < 20) {
      labeledTicks = list(10, 91, 2)
    }
    if (maxPercentile - minPercentile < 10) {
      labeledTicks = list(10, 91, 1)
    }
  }
  if (minPercentile >= 65) {
    const temp = list(65, 100, 5)
    labeledTicks = [...extremeHighLabels, ...temp]
  }
  if (maxPercentile <= 35) {
    const temp = list(5, 36, 5)
    labeledTicks = [...extremeLowLabels, ...temp]
  }


  // This is the set of ranges for ticks
  const ranges = [
    [.00001, .0000901, .00001],   // From A to B by C, in that order
    [.0001, .000901, .0001],
    [.001, .00901, .001],
    [.01, .0901, .01],
    [.1, .901, .1],
    [1, 9.01, 1],
    [10, 89.01, 5],
    [90, 98.01, 1],
    [99, 99.801, .1],
    [99.9, 99.9801, .01],
    [99.99, 99.99801, .001],
    [99.999, 99.999901, .0001],
    [99.9999, 99.9999901, .00001],
  ]
  for (let r = 0; r < ranges.length; r++) {
    const iMin = ranges[r][0]
    const iMax = ranges[r][1]
    const iIncr = ranges[r][2]
    // Skip the entire range if it is out +/- stdDev
    if (iMax < minPercentile) {
      continue
    }
    if (iMin > maxPercentile) {
      continue
    }
    for (let i = iMin; i <= iMax; i = i + iIncr) {
      // Skip this tick if outside of +/- stdDev
      if (i < minPercentile || i > maxPercentile) {
        continue
      }
      const v = stdDevFromPercentile(i)
      const compareNumber = Number(i.toFixed(5))
      if (labeledTicks.includes(compareNumber)) {
        tickVisValuesDark.push(v)
        tickUserStringsNoHTML.push(String(compareNumber))
      } else {
        tickVisValuesLight.push(v)
      }
    }
  }

  return { tickVisValuesLight, tickVisValuesDark, tickUserStringsNoHTML }
}


// n is the number of total samples for each series plot
// Index of the order plotPoints go from 0 to n-1 total samples. (left to right on basisA)
// 1st  index has a percentile of    0.5  / n
// Last index has a percentile of (n-0.5) / n
const percentileOfIndex = (i: number, n: number): number => { return (i + 0.5) / n * 100 }



export const getPercentilePts_withMemory = (seriesRowPlotPts_sortedInA: SparsePlotPt[][],
  rangeCulledAllSeriesA: PlotRange, DEBUG: boolean = false): {
    culledPercentileData: SparsePlotPt[][],
    minBbySeries: number[],
    maxBbySeries: number[],
    rangeCulledAllSeriesB: PlotRange
  } => {

  const culledPercentileData = Array<SparsePlotPt[]>()
  let minAll = +Infinity
  let maxAll = -Infinity
  const minBbySeries = Array<number>()
  const maxBbySeries = Array<number>()
  const numSeries = seriesRowPlotPts_sortedInA.length
  for (let sKey = 0; sKey < seriesRowPlotPts_sortedInA.length; sKey++) {
    const paramsObj: PercentileParams = rangeCulledAllSeriesA
    const pointersObj: PercentileRefs = { seriesRowPlotPts_sortedInA: seriesRowPlotPts_sortedInA[sKey] }
    const { result, isNewCalc } = createPercentilePts_stdMemoization(paramsObj, pointersObj, numSeries)
    culledPercentileData[sKey] = result.culledPercentileData
    minAll = Math.min(minAll, result.minB)
    maxAll = Math.max(maxAll, result.maxB)
    minBbySeries[sKey] = result.minB
    maxBbySeries[sKey] = result.maxB
    if (DEBUG) {
      if (isNewCalc) {
        console.log(`  Create   PercentilePts[${sKey}]`)
      } else {
        console.log(`  Re-using PercentilePts[${sKey}]`)
      }
    }
  }
  const rangeCulledAllSeriesB = { min: minAll, max: maxAll }
  return { culledPercentileData, minBbySeries, maxBbySeries, rangeCulledAllSeriesB }
}

type MemoizedElement = {
  paramsObj: PercentileParams
  pointersObj: PercentileRefs
  result: PercentileResults
}
const memoizedArray = Array<MemoizedElement>()   // Initially empty. Grows up to, but never exceeds maxMemoizedLength
const createPercentilePts_stdMemoization = (paramsObj: PercentileParams, pointersObj: PercentileRefs, numSeries: number): {
  result: PercentileResults,
  isNewCalc: boolean
} => {
  for (let i = 0; i < memoizedArray.length; i++) {
    let doPointersMatch = true
    for (const key of typedKeys(pointersObj)) {
      if (pointersObj[key] !== memoizedArray[i].pointersObj[key]) {
        doPointersMatch = false
        break
      }
    }
    if (!doPointersMatch) {
      continue
    }
    if (!isEqual(memoizedArray[i].paramsObj, paramsObj)) {
      continue
    }
    // Found a match!
    return { result: memoizedArray[i].result, isNewCalc: false }
  }
  // No match found in above loop. Calc a new result.
  const result = createPercentilePts(paramsObj, pointersObj)
  memoizedArray.unshift({ paramsObj, pointersObj, result }) // memoize this result
  // enough memoized room for two distinct range in A: one unconstrained and one constrained
  while (memoizedArray.length > 2 * numSeries + 1) {
    memoizedArray.pop()
  }
  return { result, isNewCalc: true }
}

export type PercentileParams = PlotRange
export type PercentileRefs = {
  seriesRowPlotPts_sortedInA: SparsePlotPt[]
}

export type PercentileResults = {
  culledPercentileData: SparsePlotPt[]
  minB: number
  maxB: number
}


const createPercentilePts = (paramsObj: PercentileParams, pointersObj: PercentileRefs, DEBUG: boolean = false)
  : PercentileResults => {

  const { min: minA, max: maxA } = paramsObj
  const { seriesRowPlotPts_sortedInA } = pointersObj
  const numFilteredPts = seriesRowPlotPts_sortedInA.length
  // Case of an empty series:
  if (numFilteredPts === 0) {
    return { culledPercentileData: [], minB: +Infinity, maxB: -Infinity }
  }

  // NOTE about B values for 'normalProb' plots:
  //     1) Values for plotPoints: ALWAYS percentiles.
  //     2) Returned range: ALWAYS percentiles.
  //     3) However, to cull the data, we need to use the B axis
  //        size in units of StdDev !!  Hence, much of the
  //        internal code will use B values of:
  //              Btransform(percentileOfIndex( indexOfOrderedPlotPoints ))
  //        which is units of either percentiles or stdDevs,
  //        depending on the plottedValueB type.

  const lowMinus = minA - Math.abs(minA) * 0.000000000001
  const highPlus = maxA + Math.abs(maxA) * 0.000000000001
  const numPaddedPts = 1  // Adds 1 pt left/right of the 'A' axis lowM/highP limits (if possible).

  const result = cullPlotPts_Aaxis_binarySearch(seriesRowPlotPts_sortedInA, lowMinus, highPlus, numPaddedPts)
  const { numCulledFrontEnd, numCulledBackEnd, culledPts } = result
  if (process.env.NODE_ENV === 'development' &&
    seriesRowPlotPts_sortedInA.length !== numCulledFrontEnd + numCulledBackEnd + culledPts.length) {
    invariant(false, `Failed to account for all filtered data points`)
  }

  const numCulledPts = culledPts.length
  if (numCulledPts === 0) {
    return { culledPercentileData: [], minB: +Infinity, maxB: -Infinity }
  }

  let minB = percentileOfIndex(numCulledFrontEnd, numFilteredPts)
  let maxB = percentileOfIndex(numFilteredPts - 1 - numCulledBackEnd, numFilteredPts)


  // Next 3 minSpacing values are constants over all series.
  // They specify the 'spatial' closeness of any two consecutive plotted points.
  // No need to plot extremely dense percentile lines.  We can't see nor can
  // crosshairs distiguish between closely spaced plotted points.
  // We have three criteria for the spacing.  If any of the 3 exceed are
  // pre-defined density limit, then that next point is added to culledPlotPts.
  // We could generate a set of minimal culledPts for the percentile and
  // normalProb plots separately. (fewer rendered pts for each plot).
  // But easier for code to just generate one set of culledPts, and this one
  // set is used to render both percentile and normProb plots.  (Same pts, with
  // same B values, but mapping nonlinearly in case of the normProb axis.)
  // This single set contains more rendered pts, however purpose of this culling
  // is to eliminate potentially many thousands of plot points.  And not to worry about
  // whether the set that is left is 'oversized' by perhaps a hundred points.
  const ptsPerDiagonal = plotLayoutConsts.percentilePointsPerDiagonalAtMaxDensity
  const rangeStdDevs = stdDevFromPercentile(maxB) - stdDevFromPercentile(minB)
  const minAspacing = (maxA - minA) / ptsPerDiagonal
  const minBspacing = (maxB - minB) / ptsPerDiagonal
  const minBspacingStdDev = (rangeStdDevs) / ptsPerDiagonal

  const culledReturnArr = Array<SparsePlotPt>()
  let lastKeptValA = -Infinity  // Insures firstVisibleIndex is kept
  let lastKeptValB = -Infinity
  let lastKeptValStdDev = -Infinity
  const lastIndex = numCulledPts - 1
  for (let i = 0; i < numCulledPts; i++) {
    const thisPercentile = percentileOfIndex(numCulledFrontEnd + i, numFilteredPts) // B value as a percentile
    const newValB = thisPercentile
    const newValStdDev = stdDevFromPercentile(thisPercentile)
    // Set the B percentile value in the original filteredPts array
    culledPts[i].B = thisPercentile
    const { A: newValA, rowKey, Astring, Bstring } = culledPts[i]
    const isLastPoint = (i === lastIndex)
    const delA = newValA - lastKeptValA
    const delB = newValB - lastKeptValB
    const delB2 = newValStdDev - lastKeptValStdDev
    if (isLastPoint ||
      delA > minAspacing ||
      delB > minBspacing ||
      delB2 > minBspacingStdDev) {
      // Saved values are ALWAYS percentiles, regardless plottedValueB and basisB scaling.
      culledReturnArr.push({ type: 'sparse', A: newValA, B: thisPercentile, rowKey, Astring, Bstring })
      lastKeptValA = newValA
      lastKeptValB = newValB
      lastKeptValStdDev = newValStdDev
    }
  }
  return { culledPercentileData: culledReturnArr, minB, maxB }
}

