import type {OpCode, OpCodeName, ScryProgram, TempVarsObject} from './formulaTypes'
import {executeUnaryOp, executeBinaryOp} from './RPN_evaluate'
import {functionNamesAndArgCountObject, isBinaryOpCode, isFunctionOpCode, isOperatorOpCode, isUnaryOpCode}  from './formulaTypes'


/*
            PERFORMANCE DATA ON OPTIMIZATION.

Test table was the Parametric Equations table.
Belows is a sample of a typical parametric formula.
Note the constant Parameters!  Common feature
of parameter equations, and something we wish to
encourage in all formula (self-documenting).

Also note the unused equations for 'y-coord'.  This is
not an expected practice in most formulas.  However, since
we do not support common fuunctions, sharing common formulas
between multiple columns is a easier way to make sure
they stay in sync.


EXAMPLE PARAMETRIC FORMULA:

        # description:  hypotrochoid X-coord
        # A circle of radius b rolls on the inside of radius a.  The
        # point drawn is on circle b, distance c from center of circle b.
        xCenter = 8
        yCenter = 0
        scale = 1.5
        a = 1
        b = .7
        c = .9
        theta = Index / 1000 * 14 * pi
        x = ( a - b ) * cos( theta ) - c * cos( ( a / b - 1 ) * theta )
        y = ( a - b ) * sin( theta ) - c * sin( ( a / b - 1 ) * theta )
        x = scale * x + xCenter
        y = scale * y + yCenter
        return x


FIVE TEST CASES WITH DIFFERENT TYPES OF OPTIMIZATION
The timer is set 'True' in module 'updateTableComputedData.js'
The lines of code measured are in module: 'calcDerivedCols.js'
The measure interval is the time to run RPN_evaluate for
1001 rows by 28 dependent column formulas. (28K cells)
The number of opcodes is the sum of all the opcodes across
all 28 dependent columns.  ~46 opcodes per formula.

      1) NO OPTIMIZATION                              1303 opcodes   92.3ms

      2) Remove all unused lines by hand:              985 opcodes   78.8ms

      3) PUT BACK all the unused lines.
         Use only constants opt (reduction #1)         823 opcodes   64.9ms

      4) Prior test, plus remove unused lines( reduction #2 )
        (reductions #1, #2)                            657 opcodes   63.7ms

      5) Prior test, plus merging opcodes ( reduction #3 )
         (reductions #1, #2, #3)                       551 opcodes   64.5ms

Execution time per opcode = 63.7ms / (1303 opcodes * 1001 rows ) = 49ns / opcode
Execution time per cell   = 63.7mx / (28 cols * 1001 rows) = 2.3us / cell
Execution speed relative to native javascript??
  This is a entirely different experiment and difficult to do because we have
  to write custom code for each column.  So I did a simple test with just one
  column, using the formula shown above.  The js engine starts slow, then speeds
  up over looping throught rows AND also looping through columns.  In other words
  it appears to 'learn' from different formulas in prior columns!

  However, for the single column I tested, our emulator runs ~1/4 the speed
  of hardcoded javascript.

CONCLUSIONS:

  1) There is a large maintenance cost to 'merged-opcodes', but no
     speed improvement.  The optimizations in reduction #3 are likely
     already happening within the js V8 compiler.  So we will NOT use
     reduction #3.  And we comment out all the merged opcodes in
     RPN_evaluate.js modules.
  2) Reduction #2 (removing unused lines) also seems to provide no
     speed improvement.  However, there is no additional cost to us,
     other than the algorithm in this module.  And it does make
     the opcode program significantly shorter and easier to read.
     So for now we keep reduction #2 - removal of unused lines.
  3) Constants optimization (reduction #1) works well.  One open
     question is why does it work at all?  It implies that the
     V8 optimization is NOT doing some of what we do.  Since (I assume)
     the V8 team is bigger and more experienced, it's unlikely they
     overlooked this obvious optimization.  Suggesting that our
     Python emulator (our opcode functions running in a while loop)
     may be 'masking' the V8 engine from seeing the constants
     optimization.  Just a guess, but we should ask the first V8
     engineering we stumble across.
*/


export const RPN_optimize = (program: ScryProgram ) : ScryProgram => {

  const isNoun = (opCode:OpCode) : boolean => {
    const name=opCode.name
    if (name === 'constant' ||
        name === 'colName'   ||
        name === 'varName' )    return true
    return false
  }

  const isValidVerb = (opCode:OpCode) : boolean => {
    const name = opCode.name
    //if ( !isFunctionOpCode(name) && !isOperatorOpCode(name) ) {return false}
    if ( !isOperatorOpCode(name) ) {return false}
    if (name === 'op_=' || name === 'op_or' || name === 'op_and' || name === 'op_not' || name === 'uniSub' ) { return false }
    return true
  }

  const isSingleArgFunction = ( opCode:OpCode ) : boolean => {
    const name = opCode.name
    return (isFunctionOpCode(name) &&
            functionNamesAndArgCountObject[name][0] === 1 &&   // required arg count
            functionNamesAndArgCountObject[name][1] === 0 )    // optional arg count
  }


  // Debugging:
  // This function makes two optimization passes through the data.
  // Reduction #1&2, Reduction #3, and Reduction #4 are completely independent.
  // One can test each and evaluate thier performance independently.





  ///////////////////////////
  //
  //   Reduction #1a - reduce all constants to 'least operations'
  //   This targets commonly written values such as:
  //      1/2,  2*Pi,  exp(1), unaryMinus(constant),
  //      sqrt(2), 1/sqrt(2), sqrt(1/2), log(2), log(10),
  //   However, it is just as easy to search and replace generic sequences:
  //     'constant', 'constant', 'op_'
  //     'constant', 'func'
  //   I do the replacement interatively, until all unary and
  //   binary 'constant operations' have been reduced.
  //   We know we have completely reduced when a pass through the
  //   opcodes no longer produces a shorter program.
  //
  //
  //   Reduction #1b - replace varName opcodes with a constant,
  //      whenever the varName is known to have a constant value.
  //   How can I tell is a varName has a constant value?
  //         - IF the last time it was set with 'op_==', the value is a constant,
  //           AND the line setting the value is unconditional (no indent),
  //           THEN every subsequent access to the varName can be
  //           replaced with a constant.
  //          - If a varName is first set to an unconditional constant,
  //            then later set to some non-constant (either conditionally
  //            or not), then we remove it from our set of constant varNames.
  //          - If a varName is first set to an unconditional constant
  //            then subsequently set to a different constant, we update
  //            that varNames constant value.
  //          - If a varName is set to some Non-constant.  But later is
  //            reset to an unconditional-constant, we add to our set.
  //          - In other words, are set of varName constants is dynamic,
  //            and can be added or deleted to our set, or the constant
  //            value can change.
  //   This function needs to be combined with Reduction #1, as they
  //   can both make constant reductions iteratively.
  //
  //   This reduction will leave the 'op_=' line of code.
  //   (e.g 'parameterA = pi'), even though parameterA is no longer used.
  //   We could remove it from the program.   However, a more general
  //   'unused varName' reduction#3 will catch these lines and remove
  //   them.
  //
  ///////////////////////////


  let workingProgram : ScryProgram = program
  let reducedProgram : ScryProgram = program
  let workingPtr = 0  // Un-optimized program pointer.  Walk all opCodes
  let ptrMap: number[] = []
  let hasConverged = false
  let numOpCodesAtStartOfPass = 0
  let thisOpcode: OpCode, nextOpcode1: OpCode, nextOpcode2: OpCode, nextOpcode3: OpCode
  let constantVarNames: TempVarsObject = {}
  let didMakeOtherOpcodeModification


  const useReduction1 = true
  const useReduction2 = true
  const useReduction3 = false

  while ( !hasConverged && useReduction1 ) {  // Make as many passes through the program as needed, until
                          // no further optimizations are found.  We've converged to
                          // when the number of opCodes at end of pass are NO less
                          // than the number of opCodes at start of the pass.
      workingProgram = reducedProgram
      numOpCodesAtStartOfPass = workingProgram.length
      reducedProgram = []
      constantVarNames = {}
      workingPtr = 0
      didMakeOtherOpcodeModification = false

      while ( true ) {  // This while loop makes one full pass through the program

          thisOpcode = workingProgram[workingPtr]
          nextOpcode1 = ( workingPtr+1 < numOpCodesAtStartOfPass )
            ? workingProgram[workingPtr+1]
            : {name:'None', value:0, value2:0, lineNum: thisOpcode.lineNum, indentLevel: thisOpcode.indentLevel}
          nextOpcode2 = ( workingPtr+2 < numOpCodesAtStartOfPass )
            ? workingProgram[workingPtr+2]
            : {name:'None', value:0, value2:0, lineNum: thisOpcode.lineNum, indentLevel: thisOpcode.indentLevel}
          nextOpcode3 = ( workingPtr+3 < numOpCodesAtStartOfPass )
            ? workingProgram[workingPtr+3]
            : {name:'None', value:0, value2:0, lineNum: thisOpcode.lineNum, indentLevel: thisOpcode.indentLevel}

          // Object 'constantVarNames' management.
          // Object tracks whether a varName value is current a constant.
          if ( nextOpcode1.name === 'op_=' ) {
              // CASE: 'constant' preceding 'op_=' and no indent (Not a conditional expression)
              // Add varName and constant value to our set of constantVarNames
              if (thisOpcode.name === 'constant' && thisOpcode.indentLevel === 0) {
                // varName is located at nextOpcode1.value
                // constant is located at thisOpcode.value
                constantVarNames[ nextOpcode1.value ] = thisOpcode.value
                //console.log( `Add ${nextOpcode.value} to constantVarNames` )
              }
              // Case 'anything Else' preceding 'op_=', or an indented (condition) 'op_='
              // Either this varName is constant or it is Not -- no middle ground.
              // If not set constant above, then this varName does NOT belong in constantVarNames object
              else if ( constantVarNames[ nextOpcode1.value ] ) {
                // CASE: Setting varName, but it is not guaranteed to be a constant.
                // It may or may not already exist in constantVarNames.  But we know for certain
                // it should no longer be in constantVarNames.
                delete constantVarNames[ nextOpcode1.value ]
                //console.log( `Remove ${nextOpcode.value} from constantVarNames` )
              }
          }

          // CASE: opCode 'varName' ( retrieve varName's value ) AND the varName's value is found
          //   in the constantVarNames object.
          if ( thisOpcode.name === 'varName' && constantVarNames[thisOpcode.value]!==undefined ) {
            //console.log( `Replaced varName ${thisOpcode.value} with constant ${constantVarNames[ thisOpcode.value ]}`)
            thisOpcode.name = 'constant'
            thisOpcode.value = constantVarNames[thisOpcode.value]  // The constant value corresponding to constantVarName
            didMakeOtherOpcodeModification = true
          }

          // CASE:  constant followed by unary operator:
          // CASE:  constant followed by any single-argument function:
          // CASE:  constant, ... followed by multi-argument function ( example: min(2,3,4) )   NOT REDUCED!
          if ( thisOpcode.name === 'constant' && ( isUnaryOpCode(nextOpcode1.name) || isSingleArgFunction(nextOpcode1) )) {
            const newConstant = executeUnaryOp( thisOpcode.value, nextOpcode1.name )
            reducedProgram.push({
              name: 'constant',
              value: newConstant,
              lineNum: thisOpcode.lineNum,
              value2: thisOpcode.value2,
              indentLevel: thisOpcode.indentLevel
            })
            ptrMap[workingPtr]   = reducedProgram.length - 1
            ptrMap[workingPtr+1] = reducedProgram.length - 1
            workingPtr++; workingPtr++;
          }

          // CASE: constant, constant, followed by binary operator
          else if ( thisOpcode.name === 'constant' && nextOpcode1.name === 'constant' && isBinaryOpCode(nextOpcode2.name) ) {
            const newConstant = executeBinaryOp( thisOpcode.value, nextOpcode1.value, nextOpcode2.name )
            reducedProgram.push({
              name: 'constant',
              value: newConstant,
              lineNum: thisOpcode.lineNum,
              value2: thisOpcode.value2,
              indentLevel: thisOpcode.indentLevel
            })
            ptrMap[workingPtr]   = reducedProgram.length - 1
            ptrMap[workingPtr+1] = reducedProgram.length - 1
            ptrMap[workingPtr+2] = reducedProgram.length - 1
            workingPtr += 3
          }

          // Replace  divide by constant with mult by 1/constant
          // Makes code for more complex combinations easier.
          else if ( thisOpcode.name  === 'constant' && nextOpcode1.name === 'op_/' ) {
            // We need to protect against a divide by constant zero
            const newConstant = isFinite(Number(thisOpcode.value)) ? 1 / Number(thisOpcode.value) : NaN
            reducedProgram.push({
              name: 'constant',
              value: newConstant,
              lineNum: thisOpcode.lineNum,
              value2: thisOpcode.value2,
              indentLevel: thisOpcode.indentLevel
            })
            ptrMap[workingPtr]  = reducedProgram.length - 1
            workingPtr++
            reducedProgram.push({
              name: 'op_*',
              value: '',
              lineNum: nextOpcode1.lineNum,
              value2: nextOpcode1.value2,
              indentLevel: nextOpcode1.indentLevel
            })
            ptrMap[workingPtr]  = reducedProgram.length - 1
            workingPtr++
            didMakeOtherOpcodeModification = true
          }

          // Replace  subtract constant with add constant
          // Makes code for more complex combinations easier.
          else if ( thisOpcode.name  === 'constant' && nextOpcode1.name === 'op_-' ) {
            reducedProgram.push({
              name: 'constant',
              value: - Number(thisOpcode.value),
              lineNum: thisOpcode.lineNum,
              value2: thisOpcode.value2,
              indentLevel: thisOpcode.indentLevel
            })
            ptrMap[workingPtr]  = reducedProgram.length - 1
            workingPtr++
            reducedProgram.push({
              name: 'op_+',
              value: '',
              lineNum: nextOpcode1.lineNum,
              value2: nextOpcode1.value2,
              indentLevel: nextOpcode1.indentLevel
            })
            ptrMap[workingPtr]  = reducedProgram.length - 1
            workingPtr++
            didMakeOtherOpcodeModification = true
          }

          // CASE: constant, add/minus, constant, add/minus
          // Merge four opcodes into two:  newConstant, 'op_+'
          else if ( thisOpcode.name  === 'constant' && nextOpcode1.name === 'op_+'  &&
                            nextOpcode2.name === 'constant' && nextOpcode3.name === 'op_+' ) {
            const newConstant = Number(thisOpcode.value) + Number(nextOpcode2.value)
            reducedProgram.push({
              name: 'constant',
              value: newConstant,
              lineNum: thisOpcode.lineNum,
              value2: thisOpcode.value2,
              indentLevel: thisOpcode.indentLevel
            })
            reducedProgram.push({
              name: 'op_+',
              value: '',
              lineNum: thisOpcode.lineNum,
              value2: thisOpcode.value2,
              indentLevel: thisOpcode.indentLevel
            })
            ptrMap[workingPtr]   = reducedProgram.length - 2
            ptrMap[workingPtr+1] = reducedProgram.length - 1
            ptrMap[workingPtr+2] = reducedProgram.length - 1
            ptrMap[workingPtr+3] = reducedProgram.length - 1
            workingPtr += 4
          }

          // CASE: constant, mult/divide, constant, mult/div
          // Merge four opcodes into two:  newConstant, 'op_+'
          else if ( thisOpcode.name  === 'constant' && nextOpcode1.name === 'op_*' &&
                            nextOpcode2.name === 'constant' && nextOpcode3.name === 'op_*' ) {

            const newConstant = Number(thisOpcode.value) * Number(nextOpcode2.value)
            reducedProgram.push({
              name: 'constant',
              value: newConstant,
              lineNum: thisOpcode.lineNum,
              value2: thisOpcode.value2,
              indentLevel: thisOpcode.indentLevel
            })
            reducedProgram.push({
              name: 'op_*',
              value: '',
              lineNum: thisOpcode.lineNum,
              value2: thisOpcode.value2,
              indentLevel: thisOpcode.indentLevel
            })
            ptrMap[workingPtr]   = reducedProgram.length - 2
            ptrMap[workingPtr+1] = reducedProgram.length - 1
            ptrMap[workingPtr+2] = reducedProgram.length - 1
            ptrMap[workingPtr+3] = reducedProgram.length - 1
            workingPtr += 4
          }


          else {
            reducedProgram.push({...thisOpcode})
            ptrMap[workingPtr]  = reducedProgram.length - 1
            workingPtr++
          }

          if (workingPtr >= numOpCodesAtStartOfPass ) { break }
      }

      reassignJumpLocations( reducedProgram, workingProgram, ptrMap )

      // Was there any reduction in number of opCodes?
      // If yes, we will look for new potential optimizations.
      // If no, we are done with Reduction #1.
      const numOpCodesAtEndOfPass = reducedProgram.length
      if (numOpCodesAtStartOfPass === numOpCodesAtEndOfPass && !didMakeOtherOpcodeModification) {hasConverged = true}
      //console.log( 'constants optimization', numOpCodesAtStartOfPass, numOpCodesAtEndOfPass, hasConverged )
  }

  ///////////////////////////
  //
  //   Reduction #2 - eliminate 'unused' variables.
  //   Usually an error found by linter programs.  But our forumula
  //   construction encourages this type of code.  For
  //   example, parametric equations are best coded for both
  //   x and y calculations, then copied to two columns.
  //   One column returns x, the other y.   This helps keep
  //   the shared parametric formula 'in-sync' across 2 columns.
  //   In normal coding, this is easily solved with a shared
  //   function.   However, current (and probably forever),
  //   formula's do not support shared custom functions.
  //
  //   Strategy:
  //   - Loop through the opCodes in reverse.
  //   - The 'usedVarNamesObj' will track all used varNames.
  //     Whenever we read a varName, if it is NOT already
  //     in our 'usedVarNamesObj', then we add it.
  //   - IF we encounter a 'op_=' that sets a varName value,
  //     AND that varName is not in our 'usedVarNamesObj' (meaning later usage),
  //     THEN we have an unused varName.  Every opCode associated
  //     with this expression (a line of a formula) can be removed.
  //
  //   - Two steps
  //   1) Loop through opcodes backwards to determine an array
  //      of line numbers that can be deleted.
  //   2) Loop through opcodes forward, deleting all opcodes
  //      belonging to the unneccessay lines.  Easier done by
  //      looping forward direction so we can manage keeping
  //      any jump points up-to-date with shorter opcode list.
  //
  //
  ///////////////////////////



  const lineNumbersToDelete : Array<number> = []  //Init empty
  let counter = 0

  // Safety counter.  Hate infinite loops in Chrome!
  // While loop will break after convergence, which
  // is likely to be 1 to 4 iterations.
  while ( counter < 20 ) {

    const usedVarNamesObj : TempVarsObject = {}   // Init empty
    let foundNoNewDeletions = true
    for ( let opIndex=reducedProgram.length - 1; opIndex >= 0; opIndex-- ) {
      const thisOpcode = reducedProgram[opIndex]
      const thisName = thisOpcode.name
      const thisValue = thisOpcode.value
      const thisLineNum = thisOpcode.lineNum

      // Skip already deleted line numbers.  Opens up the possibility of
      // even earlier unused lines to be identified
      if ( lineNumbersToDelete.indexOf( thisLineNum ) === -1 ) {
        if ( thisName === 'varName' ) {
          // When a varName is used, add an obj attribute.  Attribute's value does not matter.
          // Only the attribute's presence.  But I will use lineNum as its useful for debugging
          usedVarNamesObj[thisValue] = thisLineNum
        }
      }

      // IF a varName assignment opCode AND this varName is NOT (subsequently) used,
      // THEN mark as a line to delete.
      if ( thisName === 'op_=' && usedVarNamesObj[thisValue] === undefined ) {
        // IF a varName assignment opCode AND this varName is NOT (later) used,
        // THEN a line to delete!
        // IF line to delete is already in our list, no need to add it again.
        if ( lineNumbersToDelete.indexOf( thisLineNum ) === -1 ) {
          lineNumbersToDelete.push( thisLineNum )
          foundNoNewDeletions = false
        }
      }
    }
    counter++
    if (false) {
      console.log( 'ConstantsOptimization, pass#:', counter, 'lineNumbersToDelete:' )
      lineNumbersToDelete.forEach( thisNum=>{
        console.log( '    ', thisNum )
      })
    }
    if (foundNoNewDeletions) {break}
  }
  //console.log( 'lineNumbersToDelete', lineNumbersToDelete )

  // To turn Phase #2 optimizations on/off
  const noop: OpCode = {
    name : 'noop',
    value: '',
    value2: '',
    indentLevel: 0, // Helpful for optimization and formatting column of opcodes.
    lineNum: 0,
  }

  if ( useReduction2 ) {
      // 2) Delete the corresponding opCodes; Use a forward loop.
      // NOT an iterative process.  One pass through the opCodes is all that's needed.
      workingProgram = reducedProgram
      reducedProgram = []
      workingPtr = 0
      ptrMap = []
      workingProgram.forEach( thisOpcode => {
        if ( lineNumbersToDelete.indexOf( thisOpcode.lineNum ) === -1 ) {
          // This is a keeper opcode
          reducedProgram.push({...thisOpcode})
        }
        // Also need to keep one opcode from deleted lines
        // IF that line is a potential jumppt.
        // Nothing to calculate.  Just the potential opcode jump point is
        // required.  Use the 'noop' (no operation) opcode.
        else if ( thisOpcode.indentLevel > 0 && thisOpcode.name === 'op_=' ) {
          reducedProgram.push({...noop, indentLevel: thisOpcode.indentLevel, lineNum:thisOpcode.lineNum })
        }

        // IF we didn't push an opcode in above two conditional tests,
        // THEN this opcode is deleted (not pushed)

        // Keep track of each original opcodes which has now
        // be moved to a new locations. (new index)
        ptrMap[workingPtr] = reducedProgram.length - 1
        workingPtr++
      })

      reassignJumpLocations( reducedProgram, workingProgram, ptrMap )
  }



  ///////////////////////////
  //
  //    Reduction #3 - Merge opcodes.
  //          - Reduces moving nouns on/off the stack unneccessary ( stack ptr 'jitter' )
  //          - Reduces looping through opcodes.
  //
  //
  //    Pairwise combinations of:
  //       values (nouns)    :  'const', 'colName', 'varName'
  //       operations(verbs) :  'op_...'  and  'func...'
  //
  //    Operations we will skip:
  //    'uniSub'   - Large effort as this option doubles the number of pairwise combinations.
  //               - Suspect marginal gain because unary operations do not cause stk pointer jitter.
  //    'op_='     - Already optimized during compile to noun/verb format.  Its 'value' attribute is already set.
  //    'op_or', 'op_and', 'op_not'  - Almost never will a noun (listed above) precede these verbs.  Not worth the effort.
  //
  //    Functions we will INCLUDE: All functions taking 1 argument.
  //    Functions we will EXCLUDE: min, max if the argument count is > 2.
  //
  ///////////////////////////

  // To turn Phase #3 optimizations on/off
  if ( false ) {

      workingPtr = 0  // Un-optimized program pointer.  Walk all opCodes
      ptrMap = []
      workingProgram = reducedProgram  // Output of Reduction #1, or original program input if Reduction #1 was not run.
      reducedProgram = []  // A hopefully reduced (shorter) program we will construct.
      numOpCodesAtStartOfPass = workingProgram.length

      // One pass through opcodes is sufficient.
      // There are currently no improvements that would accrue iteratively.
      // This loop is cycling through opcodes.
      // NOT a forEach because a single pass can consume 1 OR MORE opcodes
      while ( useReduction3 ) {

        thisOpcode  = workingProgram[workingPtr]
        nextOpcode1 = workingPtr+1 < numOpCodesAtStartOfPass
            ? workingProgram[workingPtr+1]
            : {name:'None', value:0, value2:0, lineNum: thisOpcode.lineNum, indentLevel: thisOpcode.indentLevel}

        // Combined trig/radians functions.
        if ( thisOpcode.name==='funcradians' && (nextOpcode1.name==='funcsin' || nextOpcode1.name==='funccos' )) {
          reducedProgram.push({
            name: nextOpcode1.name + '_radians' as OpCodeName,
            value: '',
            lineNum: nextOpcode1.lineNum,
            value2: thisOpcode.value2,
            indentLevel: thisOpcode.indentLevel
          })
          ptrMap[workingPtr]   = reducedProgram.length - 1
          ptrMap[workingPtr+1] = reducedProgram.length - 1
          workingPtr++; workingPtr++;
        }

        // Combined degree/trig functions.
        else if ( nextOpcode1.name==='funcdegrees' && (thisOpcode.name==='funcsin' || thisOpcode.name==='funccos' )) {
          reducedProgram.push({
            name: 'funcdegrees_' + thisOpcode.name.slice(4) as OpCodeName,
            value: '',
            lineNum: thisOpcode.lineNum,
            value2: thisOpcode.value2,
            indentLevel: thisOpcode.indentLevel
          })
          ptrMap[workingPtr]   = reducedProgram.length - 1
          ptrMap[workingPtr+1] = reducedProgram.length - 1
          workingPtr++; workingPtr++;
        }

        // Combined sqrt (abs, log, or log10)  functions.
        else if ( thisOpcode.name==='funcabs'  &&
                         (nextOpcode1.name==='funcsqrt' ||
                          nextOpcode1.name==='funclog'  ||
                          nextOpcode1.name==='funclog10' ))  {
          reducedProgram.push({
            name: nextOpcode1.name + '_abs' as OpCodeName,
            value: '',
            lineNum: thisOpcode.lineNum,
            value2: thisOpcode.value2,
            indentLevel: thisOpcode.indentLevel
          })
          ptrMap[workingPtr]   = reducedProgram.length - 1
          ptrMap[workingPtr+1] = reducedProgram.length - 1
          workingPtr++; workingPtr++;
        }


        // Pairwise Noun-Verb optimizations
        // These combine the sequential actions of
        //    - pushing a value on the stack (the noun)
        //    - operating on the prior two stack values, popping one value, and putting result on stack (the verb)
        // By combining noun/verb we avoid pushing, then poping the stack. And two opcodes become one.

        else if ( isNoun(thisOpcode) && isValidVerb(nextOpcode1) ) {
         reducedProgram.push({
            name : nextOpcode1.name + '_' + thisOpcode.name as OpCodeName,
            value: thisOpcode.value,
            lineNum: thisOpcode.lineNum,
            value2: thisOpcode.value2,
            indentLevel: thisOpcode.indentLevel
         })
         ptrMap[workingPtr]   = reducedProgram.length - 1
         ptrMap[workingPtr+1] = reducedProgram.length - 1
         workingPtr++; workingPtr++;
        }


        else {
          reducedProgram.push({...thisOpcode})
          ptrMap[workingPtr]  = reducedProgram.length - 1
          workingPtr++
        }

        if (workingPtr >= numOpCodesAtStartOfPass ) { break }

      }
      reassignJumpLocations( reducedProgram, workingProgram, ptrMap )
  }  // END OF REDUCTION #3


/*
  // Some test code to count the frequency of a specified pattern,
  // asking whether this is worthing of optimization
  let countPattern = 0
  for (let i=2; i<workingProgram.length; i++) {
    if ( workingProgram[i].name === 'op_=' ) {
      if (
        isNoun( workingProgram[i-1] )  ) { countPattern++ }
    }
  }
  console.log( countPattern )
*/





  return reducedProgram
}



const reassignJumpLocations = ( reducedProgram : ScryProgram, workingProgram: ScryProgram, ptrMap : Array<number> ) => {
    // Original jump ptr locations need to be corrected to
    // reflect a shorter list of opCodes:
    for (let i=0; i<reducedProgram.length; i++) {
      const opName = reducedProgram[i].name
      if ( opName === 'jump' || opName === 'flowif' || opName === 'flowelif' || opName === 'flowelse') {
        const unoptimizedProgramOpcodeNumber = ptrMap.indexOf( i )
        const unoptimizedProgramJumpValue = Number( workingProgram[unoptimizedProgramOpcodeNumber].value )
        const newJumpValue = ptrMap[ unoptimizedProgramJumpValue ]
        reducedProgram[i].value = newJumpValue
      }
    }
}
