import type {HighlightArray, ScryInputString}  from '../sharedFunctions/parseScryInputStringTypes'

export type SubStringInfo = {
  name:OpCodeName
  text:string
  start:number
  end:number
  value: string|number
}

export const validComparisonOpCodeNames = ['op_!=', 'op_<', 'op_<=', 'op_==', 'op_>', 'op_>='] as const
export type ComparisonOpCodeName = typeof validComparisonOpCodeNames[number]
export const validComparisonOpCodesSet = new Set(validComparisonOpCodeNames)
export const isComparisionOpCode = (opCodeName: string): opCodeName is ComparisonOpCodeName => {
  return validComparisonOpCodesSet.has(opCodeName as ComparisonOpCodeName)
}

export const validUnaryOpCodeNames = ['op_not', 'uniSub'] as const
export type UnaryOpCodeName = typeof validUnaryOpCodeNames[number]
export const validUnaryOpCodesSet = new Set(validUnaryOpCodeNames)
export const isUnaryOpCode = (opCodeName: string): opCodeName is UnaryOpCodeName => {
  return validUnaryOpCodesSet.has(opCodeName as UnaryOpCodeName)
}

export const validBinaryOpCodeNames = ['op_%', 'op_*', 'op_**', 'op_+', 'op_,', 'op_-', 'op_/', 'op_//', 'op_=', 'op_and', 'op_or'] as const
export type BinaryOpCodeName = typeof validBinaryOpCodeNames[number]
export const validBinaryOpCodesSet = new Set(validBinaryOpCodeNames)
export const isBinaryOpCode = (opCodeName: string): opCodeName is BinaryOpCodeName => {
  return validBinaryOpCodesSet.has(opCodeName as BinaryOpCodeName)
}

export const validOperatorOpCodeNames = [...validComparisonOpCodeNames, ...validUnaryOpCodeNames, ...validBinaryOpCodeNames] as const
export type OperatorOpCodeName = typeof validOperatorOpCodeNames[number]
export const validOperatorOpCodeSet = new Set(validOperatorOpCodeNames)
export const isOperatorOpCode = (opCodeName: string): opCodeName is OperatorOpCodeName => {
  return validOperatorOpCodeSet.has(opCodeName as OperatorOpCodeName)
}

export const validFunctionOpCodeNames = ['funcabs', 'funcacos', 'funcasin', 'funcatan', 'funcatan2',
  'funcceil', 'funccos', 'funcdegrees', 'funcexp', 'funcfloor', 'funcisnan', 'funcjpsfunc', 'funclog',
  'funclog10', 'funcmax', 'funcmin', 'funcradians', 'funcround', 'funcsin', 'funcsqrt', 'functan',
  'functrunc'] as const
export type FunctionOpCodeName = typeof validFunctionOpCodeNames[number]
export const validFunctionOpCodeSet = new Set(validFunctionOpCodeNames)
export const isFunctionOpCode = (opCodeName: string): opCodeName is FunctionOpCodeName => {
  return validFunctionOpCodeSet.has(opCodeName as FunctionOpCodeName)
}

export const validControlOpCodeNames = ['flowelif', 'flowelse', 'flowif', 'flowreturn', 'jump', 'noop'] as const
export type ControlOpCodeName = typeof validControlOpCodeNames[number]
export const validControlOpCodeSet = new Set(validControlOpCodeNames)
export const isControlOpCode = (opCodeName: string): opCodeName is ControlOpCodeName => {
  return validControlOpCodeSet.has(opCodeName as ControlOpCodeName)
}

export const validDataOpCodeNames = ['colName', 'constant', 'None', 'varName'] as const
export type DataOpCodeName = typeof validDataOpCodeNames[number]
export const validDataOpCodeSet = new Set(validDataOpCodeNames)
export const isDataOpCode = (opCodeName: string): opCodeName is DataOpCodeName => {
  return validDataOpCodeSet.has(opCodeName as DataOpCodeName)
}

export const validExecutableOpCodeNames = [...validOperatorOpCodeNames, ...validFunctionOpCodeNames, ...validControlOpCodeNames, ...validDataOpCodeNames]
export type ExecutableOpCodeName = typeof validExecutableOpCodeNames[number]
export const validExecutableOpCodeSet = new Set(validExecutableOpCodeNames)
export const isExecutableOpCode = (opCodeName: string): opCodeName is ExecutableOpCodeName => {
  return validExecutableOpCodeSet.has(opCodeName as ExecutableOpCodeName)
}

export type OpCodeFunctionMap = {
  [key in ExecutableOpCodeName]: () => void
}

export const validParseOnlyOpCodeNames = ['colNameWrapped', 'constB60', 'constB60B60', 'constExp',
  'constExpWrapped', 'constFalse', 'constFlt', 'constInt', 'constpi', 'constTrue', 'comment', 'indent',
  'elifElse', 'emptySpace', 'emptyString', 'endExp', 'endReturn', 'flowpass', 'missingElse',
  'op_(', 'op_)', 'op_:', 'setVarName', 'uniPlus', 'unmatched'] as const
export type ParseOnlyOpCodeName = typeof validParseOnlyOpCodeNames[number]
export const validParseOnlyOpCodeSet = new Set(validParseOnlyOpCodeNames)
export const isParseOnlyOpCode = (opCodeName: string): opCodeName is ParseOnlyOpCodeName => {
  return validParseOnlyOpCodeSet.has(opCodeName as ParseOnlyOpCodeName)
}

export const validOpCodeNames = [...validExecutableOpCodeNames, ...validParseOnlyOpCodeNames] as const
export type OpCodeName = typeof validOpCodeNames[number]
export const validOpCodeSet = new Set(validOpCodeNames)
export const isOpCode = (opCodeName: string): opCodeName is OpCodeName => {
  return validOpCodeSet.has(opCodeName as OpCodeName)
}

export type OpCode = {
  name : OpCodeName
  value: string | number
  value2:string | number
  indentLevel: number // Helpful for optimization and formatting column of opcodes.
  lineNum: number
}

export type TempValue = string | number
export type TempVarsObject = {
  [key: string]: TempValue
}

export type ResultByFormulaLineNum = {
  wasExecuted: boolean,
  exprResult: string|number,
  tmpVars: TempVarsObject
}

export type ScryProgram = OpCode[]

export type ScryFormula = {
  formulaStrings   : string[]
  formulaSubStrings: SubStringInfo[][]     // formulaSubStrings[line index][subString index]

  // Each line must be one (but only one) of the following options:
  isBlankLine: boolean[]   // NO VISIBLE TEXT
  isCommentLine: boolean[] // ONLY a comment (no other tokens)
  isExpression: boolean[]  // An expression, whether valid or not.  (NOT either of the other two alternatives)

  program_RPN: ScryProgram  // Program:Array<opcodes>   Not optimized; But linked (executable)
  program_OPT: ScryProgram  // Program:Array<opcodes>   Optimized and Linked      (executable)

  inputArgColKeys: Array<number>

  errorID : string
  warningID : string
  highlightArray: HighlightArray
  highlightLineNums: number[]
  highlightUnderline: number

  workStrg: ScryInputString[] // The class object that matches substrings from a long line of text.
}

export const getDefaultScryFormula = (): ScryFormula => {
  // Creates unique references on each call:
  // Alternative to doing a cloneDeep.
  const result =  {
     formulaStrings: [],
     formulaSubStrings: [],
     isBlankLine: [],
     isCommentLine: [],
     isExpression: [],
     inputArgColKeys: [],
     errorID : '',
     warningID: '',
     highlightArray: [],
     highlightLineNums: [],
     highlightUnderline: -1,
     program_RPN: [],        // Program:Array<opcodes>   Not optimized; But linked (executable)
     program_OPT: [],  // Program:Array<opcodes>   Optimized and Linked      (executable)
     workStrg: []
  }
  Object.seal( result )
  return result
}

export const reservedKeywords = [
   'if', 'else', 'elif', 'pass', 'return',
   'True', 'False', 'pi', 'None',
]

export type ArgCounts = [number, number] // first is required args count, next is optional args count
export type FunctionArgCount = {
  [key in FunctionOpCodeName]: ArgCounts
}

export const functionNamesAndArgCountObject: FunctionArgCount = {
  // name : [ requiredArgs, optionalArg ]
  funcabs: [1,0],
  funcacos: [1,0],
  funcasin: [1,0],
  funcatan: [1,0],
  funcatan2: [2,0],
  funcceil: [1,0],
  funccos: [1,0],
  funcdegrees: [1,0],
  funcexp: [1,0],
  funcfloor: [1,0],
  funcisnan: [1,0],
  funcjpsfunc: [2,0],
  funclog: [1,0],
  funclog10: [1,0],
  funcmax: [2,100],   // variable length
  funcmin: [2,100],   // variable length
  funcradians: [1,0],
  funcround: [1,1],
  funcsin: [1,0],
  funcsqrt: [1,0],
  functan: [1,0],
  functrunc: [1,0],
}

export type ComparisonOperatorToDisplayMap = {
  [key in ComparisonOpCodeName]: string
}

export const compOpToDisplayTextMap: ComparisonOperatorToDisplayMap = {
  'op_<'  : '&lt;',
  'op_<=' : '&lt;=',
  'op_>'  : '&gt;',
  'op_>=' : '&gt;=',
  'op_==' : '==',
  'op_!=' : '!=',
}

export const reservedFuncKeys = validFunctionOpCodeNames.map((opCodeName: FunctionOpCodeName) => (opCodeName.slice(4)))
export const reservedFuncNames = reservedFuncKeys.map( x=> x+'(' )
export const longestFuncNameLength = Math.max(...reservedFuncNames.map((fn) => (fn.length)))

export const reservedOperators = [
  '<=', '>=', '==', '!=', '**', '//',
  '<', '>', ',', '+', '-', '*', '/', '%' ,'(', ')', '#', '=', ':',
  'and', 'or', 'not'
]
export const reservedIllegalOperators = [ '^', '&&', '||', '+=', '-=', '*=', '/=', '%=', '**=', '!' ]
export const reservedIllegalPython = [ 'as', 'assert', 'break', 'class', 'continue', 'def',
  'del', 'except', 'exec', 'finally', 'for', 'from', 'global', 'import', 'in', 'is', 'lambda',
  'print', 'raise', 'try', 'while', 'with', 'yield' ]

export const reservedColNames = reservedKeywords.concat( reservedFuncNames,
     reservedOperators, reservedIllegalOperators, reservedIllegalPython )
