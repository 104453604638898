import {PureComponent, Fragment} from 'react'
import FloatingPalette        from '../floatingPalette/FloatingPalette'
import constants              from '../sharedComponents/constants'
import Upload2                from '../viewImport/Upload2'
import StyleSelectBox         from '../sharedComponents/StyleSelectBox'
import { HelpPanelAppendRows,
  HelpPanelReplaceData,
  HelpPanelPreviewApproveDiff,
  HelpPanelCloneTable,
  HelpPanelNewTable, }        from '../viewImport/ImportHelpPanels'
import HorzTaperedDivider     from '../sharedComponents/HorzTaperedDivider'
import {STYLE_EDITOR_RAISED_BUTTON}  from '../sharedComponents/constants'


const TOTAL_HEIGHT = 450
const TOTAL_WIDTH  = 550
const INITIAL_PLACEMENT_TOP  = constants.TOP_MENUBAR_HEIGHT + 100
const INITIAL_PLACEMENT_LEFT = 200
const FONT_SIZE = 16
const SELECTOR_WIDTH = 420
const SELECTOR_HEIGHT= 20
const DND_HEIGHT = 275
const HELP_PANEL_WIDTH = TOTAL_WIDTH  - 20
const HELP_PANEL_HEIGHT = TOTAL_HEIGHT - 200

type UpdateOption = 'newTable' | 'cloneTable' | 'replaceData' | 'appendRows' | 'previewApproveDiff'


type OwnProps = {
  closePopup  : () => void,
}

type LocalState = {
  currentLeft:number,
  currentTop: number,
  currentSelector: UpdateOption,
  isHelpNewTableActive:    boolean,
  isHelpCloneTableActive:  boolean,
  isHelpReplaceDataActive: boolean,
  isHelpAppendRowsActive:  boolean,
  isHelpPreviewApproveDiffActive: boolean,
}

class CreateTable extends PureComponent<OwnProps, LocalState> {

  constructor (props: OwnProps) {
    super(props)
    this.state = {
      currentLeft: INITIAL_PLACEMENT_LEFT,
      currentTop : INITIAL_PLACEMENT_TOP,
      currentSelector : 'newTable',
      isHelpNewTableActive: false,
      isHelpCloneTableActive: false,
      isHelpReplaceDataActive: false,
      isHelpAppendRowsActive: false,
      isHelpPreviewApproveDiffActive: false,
    }
  }


  onActiveDrag = (left:number, top:number) : void => {
    // Constraint the position:
    top = Math.max( 20, top )
    top = Math.min( window.innerHeight - TOTAL_HEIGHT - 20, top )
    left= Math.max( 20, left )
    left= Math.min( window.innerWidth - TOTAL_WIDTH - 20, left )
    this.setState( {currentLeft:left, currentTop:top} )
  }

  clearAllHelpStates = ():void => {
    this.setState({
      isHelpNewTableActive: false,
      isHelpCloneTableActive: false,
      isHelpReplaceDataActive: false,
      isHelpAppendRowsActive: false,
      isHelpPreviewApproveDiffActive: false,
    })
  }
  pushedHelpNewTable  = ():void => { 
    const lastState = this.state.isHelpNewTableActive
    this.clearAllHelpStates()
    if (lastState === false ) { this.setState({isHelpNewTableActive: true}) }
  }
  pushedHelpCloneTable  = ():void => { 
    const lastState = this.state.isHelpCloneTableActive
    this.clearAllHelpStates()
    if (lastState === false ) { this.setState({isHelpCloneTableActive: true}) }
  }
  pushedHelpReplaceData  = ():void => { 
    const lastState = this.state.isHelpReplaceDataActive
    this.clearAllHelpStates()
    if (lastState === false ) { this.setState({isHelpReplaceDataActive: true}) }
  }
  pushedHelpAppendRows  = ():void => { 
    const lastState = this.state.isHelpAppendRowsActive
    this.clearAllHelpStates()
    if (lastState === false ) { this.setState({isHelpAppendRowsActive: true}) }
  }
  pushedHelpPreviewApproveDiff = ():void => { 
    const lastState = this.state.isHelpPreviewApproveDiffActive
    this.clearAllHelpStates()
    if (lastState === false ) { this.setState({isHelpPreviewApproveDiffActive: true}) }
  }


  setNewTable           = ():void => {  this.setState({currentSelector:'newTable'}) }
  setCloneTable         = ():void => {  this.setState({currentSelector:'cloneTable'}) }
  setReplaceData        = ():void => {  this.setState({currentSelector:'replaceData'}) }
  setAppendRows         = ():void => {  this.setState({currentSelector:'appendRows'}) }
  setPreviewApproveDiff = ():void => {  this.setState({currentSelector:'previewApproveDiff'}) }


  render() {
    const {closePopup} = this.props
    //console.log( 'Call to CreateTable' )
    //const canCloneActiveTable =  ( table && tablelook && tabledata && tableComputedData)
    const {currentLeft, currentTop, currentSelector,  isHelpNewTableActive, isHelpCloneTableActive,
    isHelpReplaceDataActive, isHelpAppendRowsActive, isHelpPreviewApproveDiffActive} = this.state
    const isAnyHelpPanelVisible = 
      isHelpNewTableActive    ||
      isHelpCloneTableActive  ||
      isHelpReplaceDataActive ||
      isHelpAppendRowsActive  ||
      isHelpPreviewApproveDiffActive
      
    const styleSelectBoxContainer={
      display:'inline-block', 
      verticalAlign: 'center', 
      width: SELECTOR_WIDTH, 
      height:SELECTOR_HEIGHT,
      //background:'red'
    }

    return (

      <FloatingPalette
        className={'CreateTableFloatingPalette'}
        left={currentLeft}
        top={currentTop}
        height={TOTAL_HEIGHT}
        width={TOTAL_WIDTH}
        onClose={closePopup}
        onActiveDrag={this.onActiveDrag}
        title='Create New Table or Update Data of Active Table:'
        isDraggable={true}
      >

        <div className={'rc_CreateTable'}
          style={{ fontSize:FONT_SIZE, display:'block'}} >

            <div className={'newTableRow'} style={{marginTop:16}}>
                <button className={'helpWithNewTableButton'}
                  onClick={ this.pushedHelpNewTable }
                  style={{
                    display: 'inline-block', verticalAlign: 'top',
                    height: SELECTOR_HEIGHT-1, width: SELECTOR_HEIGHT*.8,
                    fontSize: 13, fontWeight: 'bold',
                    marginLeft: 15, 
                    paddingTop: 0,  paddingLeft: 3,
                    ...STYLE_EDITOR_RAISED_BUTTON,
                    // next background when unselected SHOULD match the background set in STYLE_EDITOR_RAISED_BUTTON
                    background: (isHelpNewTableActive) ? constants.HIGHLIGHT_COLOR_HALF : 'rgb(230,230,230)',
                  }}>{'?'}
                </button>
                <div style={styleSelectBoxContainer}>
                  <StyleSelectBox
                      indent={15}
                      id={'newTable'}
                      gapBoxText={5}
                      fontSize={FONT_SIZE}
                      textWidth={SELECTOR_WIDTH}
                      rowHeight={SELECTOR_HEIGHT}
                      onClick={ this.setNewTable }
                      isSelected={ (currentSelector==='newTable') ? true : false }
                      text={'Create a new table.'} />
              </div>
            </div>

            <div className={'cloneTableRow'} > 
                <button className={'helpWithCloneTableButton'}
                  onClick={ this.pushedHelpCloneTable }
                  style={{
                    display: 'inline-block', verticalAlign: 'top',
                    height: SELECTOR_HEIGHT-1, width: SELECTOR_HEIGHT*.8,
                    fontSize: 13, fontWeight: 'bold',
                    marginLeft: 15, 
                    paddingTop: 0,  paddingLeft: 3,
                    ...STYLE_EDITOR_RAISED_BUTTON,
                    // next background when unselected SHOULD match the background set in STYLE_EDITOR_RAISED_BUTTON
                    background: (isHelpCloneTableActive) ? constants.HIGHLIGHT_COLOR_HALF : 'rgb(230,230,230)',
                  }}>{'?'}
                </button>
                <div style={styleSelectBoxContainer}>
                    <StyleSelectBox
                        indent={15}
                        id={'cloneActiveTable'}
                        gapBoxText={5}
                        fontSize={FONT_SIZE}
                        textWidth={SELECTOR_WIDTH}
                        rowHeight={SELECTOR_HEIGHT}
                        onClick={ this.setCloneTable }
                        isSelected={ (currentSelector==='cloneTable') ? true : false }
                        isDisabled={true}
                        text={'Clone the active table.'} />
                </div>
            </div> 

            <div className={'replaceDataRow'} style={{marginTop:16}}> 
                <button className={'helpWithReplaceDataButton'}
                  onClick={ this.pushedHelpReplaceData }
                  style={{
                    display: 'inline-block', verticalAlign: 'top',
                    height: SELECTOR_HEIGHT-1, width: SELECTOR_HEIGHT*.8,
                    fontSize: 13, fontWeight: 'bold',
                    marginLeft: 15, 
                    paddingTop: 0,  paddingLeft: 3,
                    ...STYLE_EDITOR_RAISED_BUTTON,
                    // next background when unselected SHOULD match the background set in STYLE_EDITOR_RAISED_BUTTON
                    background: (isHelpReplaceDataActive) ? constants.HIGHLIGHT_COLOR_HALF : 'rgb(230,230,230)',
                  }}>{'?'}
                </button>
                <div style={styleSelectBoxContainer}> 
                    <StyleSelectBox
                        indent={15}
                        id={'replaceTableData'}
                        gapBoxText={5}
                        fontSize={FONT_SIZE}
                        textWidth={SELECTOR_WIDTH}
                        rowHeight={SELECTOR_HEIGHT}
                        onClick={ this.setReplaceData }
                        isSelected={ (currentSelector==='replaceData') ? true : false }
                        text={"Update (replace) the active table's values."} />
                </div>
            </div>

            <div className={'AppendRows_Row'} > 
                <button className={'helpWithAppendRowsButton'}
                  onClick={ this.pushedHelpAppendRows }
                  style={{
                    display: 'inline-block', verticalAlign: 'top',
                    height: SELECTOR_HEIGHT-1, width: SELECTOR_HEIGHT*.8,
                    fontSize: 13, fontWeight: 'bold',
                    marginLeft: 15, 
                    paddingTop: 0,  paddingLeft: 3,
                    ...STYLE_EDITOR_RAISED_BUTTON,
                    // next background when unselected SHOULD match the background set in STYLE_EDITOR_RAISED_BUTTON
                    background: (isHelpAppendRowsActive) ? constants.HIGHLIGHT_COLOR_HALF : 'rgb(230,230,230)',
                  }}>{'?'}
                </button>
                <div style={styleSelectBoxContainer}>
                    <StyleSelectBox
                      indent={15}
                      id={'appendRows'}
                      gapBoxText={5}
                      fontSize={FONT_SIZE}
                      textWidth={SELECTOR_WIDTH}
                      rowHeight={SELECTOR_HEIGHT}
                      onClick={ this.setAppendRows }
                      isSelected={ (currentSelector==='appendRows') ? true : false }
                      isDisabled={true}
                      text={'Append additional rows to active table.'} />
                </div>
            </div>

            <div className={'PreviewApproveDiff'} > 
                <button className={'helpWithPreviewApproveDiff'}
                  onClick={ this.pushedHelpPreviewApproveDiff }
                  style={{
                    display: 'inline-block', verticalAlign: 'top',
                    height: SELECTOR_HEIGHT-1, width: SELECTOR_HEIGHT*.8,
                    fontSize: 13, fontWeight: 'bold',
                    marginLeft: 15, 
                    paddingTop: 0,  paddingLeft: 3,
                    ...STYLE_EDITOR_RAISED_BUTTON,
                    // next background when unselected SHOULD match the background set in STYLE_EDITOR_RAISED_BUTTON
                    background: (isHelpPreviewApproveDiffActive) ? constants.HIGHLIGHT_COLOR_HALF : 'rgb(230,230,230)',
                  }}>{'?'}
                </button>
                <div style={styleSelectBoxContainer}>
                    <StyleSelectBox
                      indent={15}
                      id={'previewApproveDiff'}
                      gapBoxText={5}
                      fontSize={FONT_SIZE}
                      textWidth={SELECTOR_WIDTH}
                      rowHeight={SELECTOR_HEIGHT}
                      onClick={ this.setPreviewApproveDiff }
                      isSelected={ (currentSelector==='previewApproveDiff') ? true : false }
                      isDisabled={true}
                      text={'Preview & approve differences prior to replacement.'} />
                </div>
            </div>

{isAnyHelpPanelVisible && 
<Fragment>
            <div className={'vertSpacer'} style={{height:6}}/>
            <HorzTaperedDivider background={constants.FLOATING_PALETTE_BACKGROUND}/>
            {isHelpCloneTableActive &&
                  <HelpPanelCloneTable  
                    onDismissClick={this.clearAllHelpStates}
                    height={HELP_PANEL_HEIGHT}
                    left={10} width={HELP_PANEL_WIDTH}/>
            }
            {isHelpNewTableActive &&
                  <HelpPanelNewTable 
                    onDismissClick={this.clearAllHelpStates}
                    height={HELP_PANEL_HEIGHT}
                    left={10} width={HELP_PANEL_WIDTH}/>
            }
            {isHelpReplaceDataActive &&
                  <HelpPanelReplaceData  
                    onDismissClick={this.clearAllHelpStates}
                    height={HELP_PANEL_HEIGHT}
                    left={10} width={HELP_PANEL_WIDTH}/>
            }
            {isHelpAppendRowsActive &&
                  <HelpPanelAppendRows  
                    onDismissClick={this.clearAllHelpStates}
                    height={HELP_PANEL_HEIGHT}
                    left={10} width={HELP_PANEL_WIDTH}/>
            }
            {isHelpPreviewApproveDiffActive &&
                  <HelpPanelPreviewApproveDiff  
                    onDismissClick={this.clearAllHelpStates}
                    height={HELP_PANEL_HEIGHT}
                    left={10} width={HELP_PANEL_WIDTH}/>
            }
</Fragment>}

{ !isAnyHelpPanelVisible  &&      
            <Upload2 
              currentSelector={currentSelector} 
              totalWidth={TOTAL_WIDTH} 
              totalHeight={DND_HEIGHT}
            />
}
        </div>
        </FloatingPalette>
    )
  }
}

export default CreateTable
