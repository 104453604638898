import {PureComponent} from 'react'
import SVGwrapper2 from '../SVGs/SVGwrapper2'
import SVGfilter   from '../SVGs/SVGfilter'

type OwnProps = {
  height: number,
  numfilteredRowKeys: number,
  numRowsUnfiltered: number,
  top: number,
  left: number,
  width: number,
}
export default class FilterControl extends PureComponent<OwnProps> {
  render( ) {
    var {height, numfilteredRowKeys, numRowsUnfiltered, top, width, left} = this.props
    // SVG fontsize can be modified to change the max number of rows
    // that can be displayed.
    // Example: replace numRowsUnfiltered with: 1234567
    // You will see 7 digits is too long to display.
    // But one can edit the SVG fontsize to be smaller ( ~240) to fit seven digits.
    // I have current set the fontSize large enough to handle 6 digits with truncation.
    // Up to 999,999 rows.
    return (
      <div className='rc_FilterControl'
        style={{position  : 'absolute',
          height, width,
          top, left,
          //background: 'red',
          overflow:'hidden'
        }}>
            <SVGwrapper2>
              <SVGfilter
                width={height} height={height}
                numRowsUnfiltered={numRowsUnfiltered}
                numRowsFiltered={numfilteredRowKeys} />
            </SVGwrapper2>
      </div>
    )
  }
}
