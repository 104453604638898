import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { Component } from 'react'
import { connect } from 'react-redux'
import { replayNote } from '../redux/notifyReducer'

type OwnProps = {
}
type DispatchProps = {
  clickHandler: () => void,
}
type Props = OwnProps & DispatchProps

class NotifyReplayButtonRender extends Component<Props> {
  render () {
    const {clickHandler} = this.props
    return (
      <div className={'rc_NotifyReplayButton'}
        style={{
        paddingRight: '16px',
      }} data-rh='Notifications'>
        <svg
          height='16px'
          onClick={clickHandler}
          viewBox='0 0 14 16'
          width='14px'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M14 12v1H0v-1l.73-.58c.77-.77.81-2.55 1.19-4.42C2.69 3.23 6 2 6 2c0-.55.45-1 1-1s1 .45 1 1c0 0 3.39 1.23 4.16 5 .38 1.88.42 3.66 1.19 4.42l.66.58H14zm-7 4c1.11 0 2-.89 2-2H5c0 1.11.89 2 2 2z'
            fill='rgba(255,255,255,0.75)'
            fillRule='evenodd'
          />
        </svg>
    </div>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps => (
  {
    clickHandler: (): void => {
      dispatch(replayNote())
    },
  }
)

const NotifyReplayButton = connect(null, mapDispatchToProps)(NotifyReplayButtonRender)
export default NotifyReplayButton
