const joi = require('joi')

const sourceAttributes = {
  filename: joi.string().allow('').allow(null)
    .description('Name of file that was uploaded if import type is upload.'),
  importType: joi.string().valid('transfer', 'upload')
    .description('Type of action that created this source.'),
  url: joi.string().allow('').allow(null)
    .description('URL of the HTML page if importType is transfer.'),
  size: joi.number().integer()
    .description('Size of the related source data in bytes'),
  title: joi.string().allow('')
    .description('Title of the page at the url or the name of the file (minus extension) depending on importType'),

  // server side managed data (read only to clients)
  createdDate: joi.date().iso().allow(null).meta('readonly')
    .description('Read only date source was imported.'),
}

module.exports = sourceAttributes
