import type { ReactNode } from 'react'

import React, {PureComponent} from 'react'
import SVGwrapper2      from '../SVGs/SVGwrapper2'
import SVGkey           from '../SVGs/SVGkey'


type OwnProps = {
  borderLeftWidth :number,
  borderLeftColor :string,
  units :string,
  isKey: boolean,
  isKeyAndContainsErroneousRowNames: boolean,
  canEdit: boolean,
  title :string,
  borderThickness :number,
  foregroundColor :string,
  gridHorzColor :string,
  headerHeight :number,
  headTopRelative :number,
  fontSize :number,
  headerBackground :string,
  keyIconSize :number,
  numPinnedRows :number,
  pinnedHeight: number,
  shouldColorCodeTitleRed :boolean,
}
export default class ColumnTitle extends PureComponent<OwnProps> {
  render() {
    const {fontSize, units, headTopRelative, borderThickness, isKeyAndContainsErroneousRowNames, canEdit,
      foregroundColor, pinnedHeight, title, keyIconSize, borderLeftWidth, isKey, gridHorzColor,
      borderLeftColor, numPinnedRows, headerHeight, shouldColorCodeTitleRed, headerBackground } = this.props
    const unitsReactNode : ReactNode = createUnitsReactNode(units)
    const renderedTitle = (title === '') ? 'Missing Name' : title
    const svgColor = ( isKeyAndContainsErroneousRowNames && canEdit ) ? '#CC0000' : this.props.foregroundColor


    return (
      <div className={`rc_ColumnTitle`}
        style = {{ position: 'relative', top: headTopRelative, left:0,
        height: headerHeight + 2*borderThickness + pinnedHeight,
        width: '100%',
        overflow: 'hidden',
        borderWidth: `${borderThickness}px 0px  0px 0px`,  // Just the top edge
        borderColor: foregroundColor,   // May need to change this for CSV formatted style
        borderStyle: 'solid',
        boxSizing: 'border-box',
        }} >

            <div className={'CellTitleAndUnitsContainer'}
              style={{
                width: '100%', height: headerHeight,
                borderWidth: `0px 0px 0px ${borderLeftWidth}px`,
                borderColor: borderLeftColor,
                borderStyle: 'solid', 
                boxSizing: 'border-box'
              }}>

                    <div className={'ColumnTitleCell'}
                      style={{
                        position: 'relative',
                        background: headerBackground,
                        width : '100%', height: headerHeight/2,
                        paddingTop: (headerHeight * 0.5 - fontSize)/2,  // centered
                        whiteSpace : 'nowrap', fontWeight  : 'bold', fontSize,
                        textAlign: 'center', lineHeight  : 1,
                        color: (canEdit && shouldColorCodeTitleRed ) ? '#CC0000' : 'unset'}}
                    > {renderedTitle} </div>

                    <div className={'ColumnUnitsCell'}
                      style={{
                        position: 'relative',
                        background: headerBackground,
                        width : '100%', height: headerHeight/2,
                        borderBottomColor : gridHorzColor, 
                        borderWidth: `0px 0px ${numPinnedRows>0 ? 1 : 0}px 0px`,
                        boxSizing:'border-box', 
                        borderStyle:'solid',
                        textAlign: 'center', lineHeight: 1,
                        whiteSpace : 'nowrap', fontWeight  : 'normal',
                        fontSize: 0.9 * fontSize,
                      }}>

                            <div className={'BottomReferenceContainer'}
                              style={{ position:'absolute', width: '100%',
                              // We need to reference the text from the bottom of
                              // the cell to get superscripts properly aligned.
                              bottom: (headerHeight*.5 - 1.2*fontSize)/2,}}
                            > {unitsReactNode} </div>
                  </div>
          </div>


{isKey &&
          <div className={'HeaderKeyImage'}
            style={{ position: 'relative', left:3, top:-headerHeight,
            height: headerHeight, width:keyIconSize}}>
            <SVGwrapper2>
                <SVGkey color={svgColor} width={keyIconSize} height={keyIconSize} />
            </SVGwrapper2>
          </div>
}

      </div>
    )
  }
}


const thinSpaceChar = String.fromCharCode(0x2009)  // Half the normal space width  \u2009
const divideChar    = String.fromCharCode(0x2215)  // longer slash
const dotChar       = String.fromCharCode(0x00B7)  // mathematical dot operator

export const createUnitsReactNode = ( inStrg:string ) : ReactNode => {
  if ( !inStrg ) return null
  var newStrg = inStrg.trim()
  if ( newStrg === '' ) return null

  newStrg = newStrg.replace( / /g, thinSpaceChar )           //  Any remaining spaces use thinSpaces
  newStrg = newStrg.replace( /\s?\/\s?/g, `${thinSpaceChar}${divideChar}${thinSpaceChar}` )  //  Larger '/' from math symbols
  newStrg = newStrg.replace( /\s?\*\s?/g, `*` )   //  Get rid of spaces around a multiply char.
  newStrg = newStrg.replace( /\*\*\s*([+,-]?)\s*(\d+)/g, '<supDelimiter>$1$2<supDelimiter>' )  // Raise integer exponents

  // This function walks char by char looking for the colon or multiply char.
  // And replaces these characters with a ReactNode bold version: <b>:</b>  or  <b>{dotChar}</b>
  // Probably a more efficient method, but since units strings will be short, this is conceptually easy.
  const parseMultiplyAndColonChars = ( strg : string ) => {
        let workingStrg = ''
        Array.from(strg).forEach( (thisChar, i) => {
          if ( thisChar === ':' ) {
            if (workingStrg.length > 0) {
              reactNodeArray.push( workingStrg )
              workingStrg = ''
            }
            reactNodeArray.push( <b key={i}>:</b> )
          } else if ( thisChar === '*' ) {
            if (workingStrg.length > 0) {
              reactNodeArray.push( workingStrg )
              workingStrg = ''
            }
            reactNodeArray.push( <b key={i}>{dotChar}</b> )
          } else {
            workingStrg += thisChar
          }
        })
        if (workingStrg.length > 0) {
          reactNodeArray.push( workingStrg )
        }
  }


  var newStrgArray = newStrg.split( '<supDelimiter>' )
  var reactNodeArray: ReactNode[] = []
  newStrgArray.forEach( (subStrg,i) => {
    if ( i%2 === 0 ) {
      parseMultiplyAndColonChars( subStrg )
    } else {
      reactNodeArray.push(
        <sup key={i}>
          {subStrg}
        </sup>
      )
    }
  })
  return (
    <span>
      {reactNodeArray}
    </span>
  )
}
