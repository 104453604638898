import type { ActiveFp } from '../types'
import type { FpChildRenderProps, FpLayout } from '../appCode/getSbFpConfigMappings'

import { useContext } from 'react'
import constants from '../sharedComponents/constants'
import { TableComputedDataContext } from '../viewTableTriple/TtGetComputedData'

const FLOATING_PALETTE_BACKGROUND = constants.FLOATING_PALETTE_BACKGROUND
const TOTAL_HEIGHT = 612
const TOTAL_WIDTH  = 502
const INITIAL_PLACEMENT_TOP  = constants.TOP_MENUBAR_HEIGHT + 50
const INITIAL_PLACEMENT_LEFT = 200

const FAMILY_TITLE_TOPMARGIN = 8
const FAMILY_TITLE_HEIGHT = 20
const FAMILY_TITLE_FONT_SIZE = 18

const PLOT_TITLE_TOPMARGIN = 14
const PLOT_TITLE_HEIGHT = 15
const PLOT_TITLE_FONT_SIZE = 14

// This set the size of the SVG icons in phase1 (plot picker)
const SVG_CONTAINER_WIDTH   = 80
const SVG_CONTAINER_HEIGHT  = 66
const SVG_INNER_SQUARE_SIZE = 60

const FLOATING_PALETTE_TITLE_BAR_HEIGHT = 20
const FLOATING_PALETTE_TITLE_BAR_FONTSIZE = 14
const FLOATING_PALETTE_BLUE_OUTLINE_WIDTH = 4
const FLOATING_PALETTE_BLACK_OUTLINE_WIDTH = 1

const FLOATING_PALETTE_TITLE_HEIGHT = constants.FLOATING_PALETTE_TITLE_BAR_HEIGHT + constants.FLOATING_PALETTE_BLUE_OUTLINE_WIDTH
const FLOATING_PALETTE_LEFT_BORDER_WIDTH= constants.FLOATING_PALETTE_BLACK_OUTLINE_WIDTH + constants.FLOATING_PALETTE_BLUE_OUTLINE_WIDTH

// Where the selected plot type is positioned in phase3:
const SVG_LEFT_CENTERED_PHASE3 = 200
const SVG_LEFT_SHIFTED_PHASE3 = 320
const SELECTION_LEFT_CENTERED_PHASE3 = 120
const SELECTION_LEFT_SHIFTED_PHASE3 = 200
const SELECTION_TOP_PHASE3 = 160
const SELECTION_SCALE_PHASE3 = 2


const COL_INPUT_SELECTOR_WIDTH = 200

const SVG_CONTAINER_BOTTOMMARGIN = 10
const LEFT_RIGHT_MARGIN  = 6

const singleTitleRowHeight =
  + PLOT_TITLE_HEIGHT
  + SVG_CONTAINER_HEIGHT

const doubleTitleRowHeight =
  + 2 * PLOT_TITLE_HEIGHT
  + SVG_CONTAINER_HEIGHT

const scatterFamilyContainerHeight =
  + FAMILY_TITLE_TOPMARGIN
  + FAMILY_TITLE_HEIGHT
  + PLOT_TITLE_TOPMARGIN
  + singleTitleRowHeight
  + SVG_CONTAINER_BOTTOMMARGIN

const distributionFamilyContainerHeight =
    + FAMILY_TITLE_TOPMARGIN
    + FAMILY_TITLE_HEIGHT
    + PLOT_TITLE_TOPMARGIN
    + 1 * doubleTitleRowHeight
    + SVG_CONTAINER_BOTTOMMARGIN

const clonedPlotContainerHeight =
  + FAMILY_TITLE_TOPMARGIN
  + FAMILY_TITLE_HEIGHT
  + SVG_CONTAINER_HEIGHT
  + 30


const familyContainerStyle: React.CSSProperties = {
  width:6*SVG_CONTAINER_WIDTH + 2 * LEFT_RIGHT_MARGIN,
  background: FLOATING_PALETTE_BACKGROUND,
  flex: '1 1 auto',
}
const familyTitleStyle: React.CSSProperties = {
  fontSize : FAMILY_TITLE_FONT_SIZE,
  fontWeight: 'bold',
  height: FAMILY_TITLE_HEIGHT,
  paddingTop: (FAMILY_TITLE_HEIGHT - FAMILY_TITLE_FONT_SIZE)/2,
  marginTop: FAMILY_TITLE_TOPMARGIN,
  marginBottom: PLOT_TITLE_TOPMARGIN,
  textAlign: 'center',
  //background: 'pink',
}
const plotTitleStyleLine1: React.CSSProperties = {
  fontSize : PLOT_TITLE_FONT_SIZE,
  height: PLOT_TITLE_HEIGHT,
  paddingTop: (PLOT_TITLE_HEIGHT - PLOT_TITLE_FONT_SIZE)/2,
  textAlign: 'center',
}
const plotTitleStyleLine2: React.CSSProperties = {
  fontSize : PLOT_TITLE_FONT_SIZE,
  height: PLOT_TITLE_FONT_SIZE,
  paddingTop: (PLOT_TITLE_HEIGHT - PLOT_TITLE_FONT_SIZE)/2,
  textAlign: 'center',
}
const svgContainerStyle: React.CSSProperties = {
  width:SVG_CONTAINER_WIDTH,
  height:SVG_CONTAINER_HEIGHT,
  flex: '0 0 auto',
  //background:'orange',
}
const rowStyle: React.CSSProperties = {
  marginLeft: LEFT_RIGHT_MARGIN,
  marginRight: LEFT_RIGHT_MARGIN,
  display:'flex',
  flexFlow: 'row nowrap',
}
const plotOnClickContainerStyle: React.CSSProperties = {
  width:SVG_CONTAINER_WIDTH,
}


// Every named FP must provide this function. It is called by the FpParent component.
// Specifically, the parent needs this set of information BEFORE it creates


// the FpParent, FpContainer, and internal content.
export const useGetPlotCreate: (activeFp: ActiveFp) => FpLayout = (activeFp) => {
    const tCD = useContext( TableComputedDataContext )

    return {
        activeFp,
        titleBarStrg: 'Download Plot',
        legalDisplayedTabIndex: 0,
        isDraggable: true,
        tabInfoArr: [{
            tabIndex: 0,
            tabStrg1: 'placeholder',
            tabStrg2: 'placeholder',
            isErroneous: false,
        }],
        childInfoArr: [{
            childWidth: TOTAL_WIDTH,
            childHeight: TOTAL_HEIGHT,
            RenderedChild: NavColFp_PlotCreate,
        }],
        getSelectedCellGeometry: () => ({left: 0, top: 0, width: 0, height: 0, isCellVisible: false}), // No cyan highlight
    }
}

const NavColFp_PlotCreate: React.FC<FpChildRenderProps> = (props)=> {
    return null
}
