import {PureComponent} from 'react'
import Main from '../sharedComponents/Main'
import NavWrapper from '../sharedComponents/NavWrapper'
import TopMenuBar from '../viewMenuBar/TopMenuBar'
import ViewWrapper from '../sharedComponents/ViewWrapper'

class Install extends PureComponent {
  render () {
    return (
    <NavWrapper>
      <ViewWrapper>
        <TopMenuBar innerWidth={window.innerWidth}/>
        <Main>
          <div style={{padding:10}}>
            <h2>Transfering tables into DataTables</h2>
            <h3 style={{color: 'red'}}>NOTE: Currently only works in Google Chrome</h3>
            <ol>
              <li>Turn on your boomarks/favorites toolbar, (from the View menu of your browser)</li>
              <li><span>Drag this link into your Bookmarks toolbar: </span>
                { /* eslint-disable-next-line */ }
                <a href="javascript: (function () { var e = document.createElement('script'); e.setAttribute('src', 'https://dev.datatables.com:3443/extension/container.js'); document.body.appendChild(e); })();">DataTables</a>
              </li>
              <li>Visit a page on another site with a table in it, such as <a
                    href="https://en.wikipedia.org/wiki/List_of_tallest_buildings"
                    target="_blank" rel="noopener noreferrer">Tallest Buildings</a></li>
              <li>Click on the DataTables link in your bookmarks toolbar</li>
              <li>Tables will transfer from the page you are looking at into DataTables</li>
            </ol>
          </div>
        </Main>
      </ViewWrapper>
    </NavWrapper>
    )
  }
}

export default Install
