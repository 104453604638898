import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import invariant from 'invariant'
import type { ParseMeta, ParseResult } from 'papaparse'
import { list } from 'radash'
import { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import uuidv4 from 'uuid/v4'
import { getDefaultTable, getDefaultTabledata, getDefaultTablelook }  from '../types'
import type { Table, Tabledata, Tablelook } from '../types'
import type { ResourceId } from '../jsonapi/types'
import type { RootState } from '../redux/store'
import { createTableResourcesThunk } from '../redux/tableThunks'
import constants  from '../sharedComponents/constants'
import { cleanScryInputText2 }  from '../sharedFunctions/cleanScryInputText'
import { asyncDispatch } from '../sharedFunctions/utils'
import FileDropTarget2 from './FileDropTarget2'
import { detectColDataTypeAndFormat }  from './detectColDataTypeAndFormat'

const PAPA_TYPES = [
  'text/csv',
  'text/tab-separated-values',
  'text/plain'
]
const SHEETJS_TYPES = [
  'numbers', //file extension names also allowed in this set
  'application/vnd.ms-excel',
  'application/vnd.apple.numbers',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
]
const MAX_UPLOAD_SIZE = 20000000
const MAX_UPLOAD_SIZE_TXT = '20 Mbytes'
const UPLOAD_SIZE_TO_SHOW_PROGRESS_WIDGET = 4000000

type OwnProps = {
  currentSelector: string,
  totalWidth: number,
  totalHeight: number,
}
type StateProps = {
  userid: string,
}
type DispatchProps = {
  createTableResources: (table: Table, tabledata: Tabledata, tablelook: Tablelook) => void,
}
type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps

type LocalState = {
  errMsg: string[],
  progressMsg: string[]
}

class UploadRender extends Component<Props, LocalState> {

  constructor (props: Props) {
    super(props)
    this.state = {
      errMsg : [],
      progressMsg: [],  // This is the progress message;  Empty [] means no Progress Widget
                        // ['Creating Table'] will display this message followed by dynamic . . .
                        // ['Creating Table', 'Uploading Table to your account']
                        //     will put dynamic ... after 2nd line.
    }
  }

  setErrMsg = (msg: string[]) : void => {
    this.setState({errMsg: msg})
  }

  getDefaultParseResult = (): ParseResult<string[]> => {
    const meta: ParseMeta = {
      aborted: false,
      cursor: 0,
      delimiter:',',
      linebreak:'\n',
      truncated: false
    }
    const result: ParseResult<string[]> = {
      data:[],
      errors:[],
      meta
    }
    return result
  }

  processFile_part1 = (files: File[]): void => {
    if (!files) { return }
    var skipPapa = false

    // Error if > 1 file dropped
    if (files.length > 1) {
      // File object is empty if >1 file dropped
      this.setErrMsg( ['Please drop 1 and only 1 file.'] )
      return
    }

    // Error if not a supported MIME type.
    const file = files[0]
    if (PAPA_TYPES.indexOf(file.type) === -1) {
      // File object is empty if *.png file dropped.
      // see if this is a special extension type and mimetype is empty
      const fileext : string = file.name.split('.').pop() || ''
      if (SHEETJS_TYPES.indexOf(file.type) === -1 && SHEETJS_TYPES.indexOf(fileext) === -1){
        this.setErrMsg( [`${file.type || fileext} is not a supported file type.`] )
        return
      }
      skipPapa = true
    }

    // Error for files > MAX_UPLOAD_SIZE, since the server won’t accept them.
    // (Until we do some hard work to handle large files).
    if (file.size > MAX_UPLOAD_SIZE) {
      this.setErrMsg( [`This file is too large.`, `Current limit for filesize is ${MAX_UPLOAD_SIZE_TXT}`] )
      return
    }

    if ( file.size > UPLOAD_SIZE_TO_SHOW_PROGRESS_WIDGET ) {
      this.setState({progressMsg:['Parsing Data']})
    }

    if (!skipPapa){
      // Parse data, then continue error checking in 'part2'
      import('papaparse').then( module => {
        module.parse<string[], File>(file, {skipEmptyLines: true, complete: this.processFile_part2} )
      })
    } else {
      this.processFile_part2(this.getDefaultParseResult(), file)
    }


  }

  processFile_part2 = async (results: ParseResult<string[]>, file: File) => {
    const {createTableResources, userid, currentSelector} = this.props
    let {data, errors} = results
    var errMsg = new Array<string>()

    if (errors.length > 0 || data.length < 1) {
      try {
        const abuff = await file.arrayBuffer()
        const module = await import('xlsx')
        const ws = module.read(abuff)
        data = module.utils.sheet_to_json(ws.Sheets[ws.SheetNames[0]], {header:1, defval:'', blankrows:false})
      } catch (error) {
        this.setErrMsg([(error as Error).message])
        return
      }
    }

    // What errors can/may be returned by Papa.parse??
    if ( errors && errors.length > 0 ) {
      if (process.env.NODE_ENV === 'development' ) {
        invariant( false, `Unexpected import Error: ${errors}` )
      }
    }
    if (data.length < 1){
      this.setErrMsg(['Unable to import file,\n try to convert it to csv first'])
      return
    }

    // Error for Duplicate Source-Column-Name:
    /*
    var errMsg = checkForDuplicateColNames(data[0])
    if ( errMsg.length >0 ) {
      this.setErrMsg( errMsg )
      return
    }
    */
    data = nameEmptyColumns(data)
    // Error for non-rectangular data
    errMsg = checkForRectangularData(data)
    if ( errMsg.length >0 ) {
      this.setErrMsg( errMsg )
      return
    }

    // Merge pairs of columns that match a specific hyperlink naming convention,
    // whereby two adjacent columns are merged into a single column,
    // using our internal hyperlink format:  `[[label]]((path))`
    // This operates on the data array 'in-place'.
    const mergedHyperlinkColIndices = mergeHyperlinkLabelColumns(data)

    switch( currentSelector ) {

      case 'newTable':
        var table, tabledata, tablelook;
        ({table, tabledata, tablelook} = sv2table( userid, data, file, mergedHyperlinkColIndices ))
        if ( file.size > UPLOAD_SIZE_TO_SHOW_PROGRESS_WIDGET ) {
          this.setState({progressMsg:['Parsing Data', 'Uploading Data']})
        }
        createTableResources( table, tabledata, tablelook)   // calls createTableResourcesThunk
        return

      case 'replaceData':
      case 'cloneTable':
      case 'appendRows':
      case 'previewApproveDiff':
      default:
        return
    }
  }


  render() {
    return (
        <FileDropTarget2
            onFilesDrop={this.processFile_part1}
            totalWidth={this.props.totalWidth}
            totalHeight={this.props.totalHeight}
            passedErrMsg={this.state.errMsg}
            progressMsg={this.state.progressMsg}
        />
    )
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const userid = state.app.authUserId
  return {
    userid,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: OwnProps & RouteComponentProps): DispatchProps => (
  {
    createTableResources: (table, tabledata, tablelook): void => {
      asyncDispatch(dispatch, createTableResourcesThunk(table, tabledata, tablelook, ownProps.history))
    }
  }
)

const UploadConnected = connect(mapStateToProps, mapDispatchToProps)(UploadRender)
const Upload = withRouter(UploadConnected)
export default Upload




/****************************************************************************

    CODE TO CREATE NEW TABLE, TABLEDATE, TABLELOOK

*****************************************************************************/


const sv2table = (userid:string, data: string[][], fileObj: File, mergedHyperlinkColIndices: number[])
            : {table: Table, tabledata: Tabledata, tablelook:Tablelook} => {

  const tableid = uuidv4()
  const tabledataid = uuidv4()
  const tablelookid = uuidv4()
  const numRows = data.length - 1
  const numCols = data[0].length
  const table = getDefaultTable( numCols )
  table.id   = tableid
  table.attributes.tableTitle = 'placeHolder'

  //table.attributes.publisherTitle = constants.PUBLISHER_DEFAULT_TITLE
  const tableRelationships = table.relationships
  if (tableRelationships) {
    tableRelationships.owner.data     = { id: userid,      type: 'users' }
    tableRelationships.tabledata.data = { id: tabledataid, type: 'tabledatas' }
  }

  const tabledata = getDefaultTabledata()
  tabledata.id   = tabledataid
  const tabledataRelationships = tabledata.relationships
  if (tabledataRelationships) {
    tabledataRelationships.table.data = { id: tableid, type: 'tables' }
  }

  const tablelook = getDefaultTablelook(numRows, numCols)
  tablelook.id  = tablelookid
  const tableResourceId: ResourceId = { id: tableid, type: 'tables' }
  tablelook.relationships = { table: { data: tableResourceId } }

  // We've already error checked that we have a rectangular array.
  // We assume the first row is col Names
  const headerRow = data[0]
  for (let i = 0; i < numCols; i++) {
    var thisColumn = table.attributes.columns[i]
    var colTitle = (i < headerRow.length) && headerRow[i] ? headerRow[i] : `Unlabeled ${i + 1}`
    var {newValue:cleanedColName} = cleanScryInputText2( colTitle, 0  )
    thisColumn.colTitle = cleanedColName
    thisColumn.colSourceTitle = cleanedColName
  }
  table.attributes.numRowsUnfiltered = data.length - 1 // Minus the header row.

  // Allocate the necessary colValues arrays:
  tabledata.attributes.tableValues =  list(numCols-1).map( ()=>[] )

  // copy the rows, skipping the first since we used it for headers above.
  data.forEach( (row: Array<string>, index: number): void => {
    if (index > 0) {
      const rowLen = row.length
      const rowCopy = row.slice(0)
      // Pad rest of the required rowWide with empty strings.
      for (let i = rowLen; i < numCols; i++) {
        rowCopy.push('')
      }
      for (let colIndex=0; colIndex<numCols; colIndex++) {
        let thisVal = rowCopy[colIndex]
        var {newValue} = cleanScryInputText2( thisVal, 0  )
        tabledata.attributes.tableValues[colIndex].push( newValue )
      }
    }
  })

  //table.attributes.dataSources = []
  table.attributes.dataSources.push({
    dataSourceType: 'csvCreate',
    date    : (new Date()).toISOString(),
    file    : fileObj,
    numRows, numCols,
  })

  // Server currently MUST have these values 'missing'.  We intend to change
  // the server code at some later date.
  // TODO:
  delete table.attributes.createdDate
  delete table.attributes.updatedDate
  delete table.attributes.numStars

  table.attributes.tableTitle = createUniqueTableName(fileObj.name)
  tabledata.attributes.tableValues.forEach( (colData,colIndex) => {
    if (mergedHyperlinkColIndices.indexOf( colIndex ) === -1 ) {
      // NOT a merged column
      // Detect the dataType by sampling column values.  Majority rules.
      var {colDataType, formatRule, parsedData} = detectColDataTypeAndFormat( colData )
      // Overwrite the table values with the returned 'canonical form'
      tabledata.attributes.tableValues[colIndex] = parsedData
    } else {
      // Case of a merged column.  Force the dataType & formatRule
      colDataType = 'hyperlink'
      formatRule  = 'defaultString'
    }

    table.attributes.columns[colIndex].colDataType = colDataType
    tablelook.attributes.lookColumns[colIndex].formatRule = formatRule

    // Default formating object uses precisionFixed of 2 and precisonMode of min.
    // Hence the default will show no digits to the right of decimal UNLESS they exist.
    // The precision 'min' mode is not offered for B60 type numbers.  So change the
    // default precisionFixed to zero.
    if ( formatRule === 'B60seconds' || formatRule === 'B60B60seconds' ||
         formatRule === 'B60degrees' || formatRule === 'B60B60degrees' ) {
            tablelook.attributes.lookColumns[colIndex].precisionFixed = 0
    }
  })

  return { table, tabledata, tablelook }
}




const mergeHyperlinkLabelColumns = ( data : string[][] ) : number[] => {
    // modifies the data structure in place, by merging two columns IFF:
    // - The column name begins with RESERVED_HYPERLINK_LABEL_TEXT  ( '_Hyperlink Labels For_' in Jan, 2023)
    // - The text after the name exactly matches the next column's name.
    // The above two will be true for every hyperlink column writtn as a *.cvs created by our table output tools.
    // Should the user's wish to use this to add their own column of hyperlink labels,
    // then we will also recoginize their matching pair of columns and use their [[label]]((hyperlink)) pair.
    // If users make a mistake, there is no merge:
    //    - labels column will appear as a colum of dataType 'text'.
    //    - hyperlinks will be identified as dataType 'hyperlink', and default label assigned the link's path.
    const colNames = data[0]
    const colsToMergeIndices: number[] = []
    const reservedLabelLen = constants.RESERVED_HYPERLINK_LABEL_TEXT.length   // Don't change this value after we're in production.
    colNames.forEach( (thisColName, i) => {
      var labelPart = thisColName.slice(0,reservedLabelLen)
      var pathPart  = thisColName.slice(reservedLabelLen)
      var isReservedCol = (labelPart === constants.RESERVED_HYPERLINK_LABEL_TEXT)
      var isMatchToNextCol = (colNames[i+1] && pathPart === colNames[i+1] )
      if ( isReservedCol && isMatchToNextCol ) {
        colsToMergeIndices.push( i )
      }
    })
    // Shortcut, early exit for most input tables.  NO merged columns:
    if ( colsToMergeIndices.length === 0 ) { return [] }

    // Below this point is the code to merge columns, done row-by-row.
    // Work from right to left (largest colsToMergeIndex to smallest); makes splicing easier.
    colsToMergeIndices.reverse()
    // colNames (1st row)
    colsToMergeIndices.forEach( i => {
      colNames.splice( i,1 )
    })

    // rowValues
    for ( let rowIndex=1; rowIndex<data.length; rowIndex++ ) {
      var thisRow = data[rowIndex]
      for ( let dummy=0; dummy<colsToMergeIndices.length; dummy++ ) {
        var i = colsToMergeIndices[dummy]
        // splice is convenient function to grab the first column value, then
        // delete that index from the row array, in one step.  Splice returns
        // an array of length 1, hence the [0] indexing after splice.
        var {newValue:label} = cleanScryInputText2( thisRow.splice(i,1)[0],  0)
        var {newValue:path } = cleanScryInputText2( thisRow[i],              0)
        if ( path  === '' ) {
          thisRow[i] = ''
        } else if ( label === '' ) {
          thisRow[i] = `[[${path}]]((${path}))`
        } else {
          thisRow[i] = `[[${label}]]((${path}))`
        }
      }
    }

    // For each merged row, we should 'force' it to be dataType='hyperlink',
    // regardless of the validity of the column values.
    // We can't force the dataType value here, so we will simply pass back
    // the column indices of the newly merged 'hyperlink' columns.
    // We just compressed the numColumns, so the indices of the merged columns
    // are no longer what was initially determined.
    colsToMergeIndices.reverse( )  // revert back to smallest to largest index order.
    // Now colsToMergeIndices are what we discovered PRIOR to any columns being merged.
    const afterMerge_HyperlinkIndices: number[] = []
    colsToMergeIndices.forEach( (location, j)=>{
      // For example, suppose colsToMergeIndices = [3, 7, 22, 27]
      // After removing (in reverse order) columns 28, 23, 8, 4, then the
      // new location of merged columns will be at [3, 6, 20, 24]
      afterMerge_HyperlinkIndices.push( location - j )
    })
    return afterMerge_HyperlinkIndices
}


/*
const checkForDuplicateColNames = ( colNames:[string] ) : [string] => {
    const countObj = countBy( colNames )
    const keys = keys( countObj )
    const errMsg = []
    keys.forEach ( thisKey  => {
      if ( errMsg.length === 4 ) {
        errMsg.push( '. . .' )
      } else if ( errMsg.length > 4 ) {
        // No room for another line!
      } else if ( countObj[thisKey] > 1 ) {
        errMsg.push( `${countObj[thisKey]} instances of : ${thisKey}` )
      }
    })
    if (errMsg.length > 0) {
      errMsg.unshift( 'Error: Column Names are not unique!' )
    }
    return errMsg
}
*/

//https://stackoverflow.com/a/31540111
const getNextKey = function(key: string): string {
  if (key === 'Z' || key === 'z') {
    return String.fromCharCode(key.charCodeAt(0) - 25) + String.fromCharCode(key.charCodeAt(0) - 25); // AA or aa
  } else {
    var lastChar = key.slice(-1);
    var sub = key.slice(0, -1);
    if (lastChar === 'Z' || lastChar === 'z') {
      // If a string of length > 1 ends in Z/z,
      // increment the string (excluding the last Z/z) recursively,
      // and append A/a (depending on casing) to it
      return getNextKey(sub) + String.fromCharCode(lastChar.charCodeAt(0) - 25);
    } else {
      // (take till last char) append with (increment last char)
      return sub + String.fromCharCode(lastChar.charCodeAt(0) + 1);
    }
  }
  //return key; //unreachable
};

const nameEmptyColumns = (data : string[][] ) : string[][] => {
  //Rename empty column names in the form of A, B, C ... AA, BB, ...

  var counter = 0
  var letter = 'A'
  data[0].forEach ( name => {
    if (name === ''){
      data[0][counter] = letter
      letter = getNextKey(letter)
    }
    counter++
  })
  return data
}

type RowLength = {
  numCols: number
  freq: number
  examples: string[]
}

const checkForRectangularData = (data : string[][] ) : string[] => {

  const MAX_ROW_INDICES_SAVED = 5
  var discoveredRowLengths: RowLength[] = []

  // This loop is going to only look at data rows;  The header row (index 0) gets special treatment.
  for (let rowNum=1; rowNum<data.length; rowNum++) {
    var thisRowData = data[rowNum]
    var numCols = thisRowData.length
    if ( !discoveredRowLengths[numCols] ) {
      var newObj: RowLength = {
        numCols,
        freq: 1,
        examples: [String(rowNum)]
      }
      discoveredRowLengths[numCols] = newObj
    } else {
      var thisObj = discoveredRowLengths[numCols]
      thisObj.freq++
      if ( thisObj.examples.length < MAX_ROW_INDICES_SAVED ) {
        thisObj.examples.push( String(rowNum) )
      }
    }
  }

  // discoveredRowLength[ ] now contains an array of all the discoved numCols.
  // Convert the sparse array to dense. The compacted array length will be the number of
  // unique numCols that where discovered.
  discoveredRowLengths = discoveredRowLengths.filter( thisObj => thisObj ? true : false )
  // If only one numCol length was found, we don't care about the value, just care
  // that all rows have the identical numCols.  No error.  Return empty errMsg.
  if ( discoveredRowLengths.length === 1 &&
        data[0].length === discoveredRowLengths[0].numCols) { return [] }

  // At this point we know the array is NOT rectangular.
  // Sort the array, such the most frequently discovered numCol come 1st
  discoveredRowLengths.sort( (a,b)=> {
    return (a.freq<b.freq) ? 1 : -1
  })
  const mostFreqFoundNumCols = discoveredRowLengths[0].numCols
  const highestFreq = discoveredRowLengths[0].freq

  // Create a helpful error message
  const errMsg = []
  //errMsg[0] = 'Some rows have the wrong number of columns.'
  errMsg.push( `${highestFreq} rows have ${mostFreqFoundNumCols} values (assumed correct).` )
  if (data[0].length !== mostFreqFoundNumCols) {
    errMsg.push( `Error: The header line (first line) has ${data[0].length} values.` )
  }
  // Print out two more examples of improper row lengths:
  [1,2].forEach( i => {
    if ( !discoveredRowLengths[i] ) {return}
    var {freq, numCols, examples} = discoveredRowLengths[i]
    var rowList = examples.join(',')
    if (freq > MAX_ROW_INDICES_SAVED) { rowList += ', ...' }
    errMsg.push( `Error: ${freq} rows have ${numCols} values.  (Rows: ${rowList})` )
  })
  // if there are more than 3 different rowLengths found, just report a summary.
  var additionNumberOfErroneousRows = 0
  for ( let j=3; j<discoveredRowLengths.length; j++ ) {
    additionNumberOfErroneousRows += discoveredRowLengths[j].freq
  }
  if (additionNumberOfErroneousRows>0) {
    errMsg.push( `Error: ${additionNumberOfErroneousRows} rows have other various lengths.` )
  }
  return errMsg
}

const createUniqueTableName = (currentTableName: string) : string => {
  // Create a 'unique' table name by appending date/time to fileName
  const now = (new Date()).toISOString()
  return currentTableName + ' ' + now
}
