import type { Property } from 'csstype'
import type { ReactNode } from 'react'
import type { PlotPt, PlotXyComputedData } from '../computedDataPlotXY/xy_plotTypes'
import type { TableComputedData } from '../computedDataTable/getDefaultTableComputedData'
import type { InfoLine, InfoTable } from './createCrosshairsContent'

import React, { PureComponent, Component, Fragment } from 'react'
import {
  isDelaunayBuildInProgress,
  getDelaunayD3structures,
  createDelaunayD3structures,
  nearestJointXY2
} from '../computedDataPlotXY/delaunay'
import { getDefaultSparsePlotPt } from '../computedDataPlotXY/xy_plotTypes'
import { createDebuggingContent, createCrossHairsContent } from './createCrosshairsContent'


//import {startTimer, logTime, stopTimer} from '../sharedComponents/timer'
import { MAX_NUMBER_PINNED_ROWS } from '../sharedComponents/constants'
// import { numberFormatReactNode } from '../sharedFunctions/numberFormat'
import reactDispatch from '../sharedComponents/reactDispatch'
//import SVGwrapper2            from '../sharedComponents/SVGwrapper2'
//import SVGpin                 from '../table/SVGpin'

// If we ever decide to change the plotResolution (current 1500 pixels
// along the longest axis (width or height) of the plot.
// Then the layout constants below will need to be scaled.

// Mousemove action is recognized ONLY in the plottable area,
// plus this overlap on all four sides.  Purpose of overlap
// is to make it easier to locate to edge points.
const MOUSEMOVE_OVERLAP_TOLERANCE = 6
const CROSSHAIRS_CONTAINER_HEIGHT = 120
const SHOULD_USE_CROSSHAIRS = true    // To turn off the mouseMove handler.

export const FONT_SIZE = 14
//const PIN_ICON_SIZE = 30
//const PIN_ICON_OFFSET_FROM_CROSSHAIR_Y = 18
//const PIN_ICON_OFFSET_FROM_CROSSHAIR_X = 6
//const PLACEMENT_OF_PALETTE_FROM_PLOT_EDGE = 30

const DEBUG_showMouseRedCrosshairs = false  // Show the mouse position with red crosshairs
const DEBUG_MouseXforms = true  // Crosshairs displays mouse coord information (not plotpt info) for debugging Xforms
const DEBUG = true        // Adds color to the crosshairs table to debug formatting.

// This is the data structure that stores the displayed crosshairs 'content'.
// Each line describe by an opacity and 3 values;
// The set of 3 values are created in two versions.
//    -- measText version, to determine the necessary rendering width
//    -- react Node version, for rendering.



// Need the following sequence of calls to support DELETING A PINNED ROW.
// 1) in onClickHandler: if we delete a pinned row;
//        set local state variables 'mouseX, mouseY' to the current mouse position.
//        prior state of both variables was null, this this triggers a crosshairs render.
// 2) React state changes the pinnedRows state.  Triggers createPlotXyComputedData; triggers ComponentXY re-render.
// 3) This local state change to mouseX mouseY forces crosshairs component to also re-render
// 4) Crosshairs component updates, then didComponentUpdate sees non-null mouseX, mouseY.  So calls this.checkDelaunayPoints( mouseX, mouseY )
// 5) Rerun the delaunay check for nearestXY point and corresponding crosshairs palette display.
//
// Problem this solves: When deleting a pinnedRow, the crosshairs palette needs to be updated,
// but ONLY after plotXyComputedData has been re-created without said pinnedRow.  Otherwise, the deleted
// pinned row's data will persist in the crosshairs palette until the mouse is moved.

export type Props = {
  plotXyComputedData: PlotXyComputedData
  //table: Object
  tableComputedData: TableComputedData
  //plot: Object
}
export type State = {
  delaunayArrayIndex: number
  plotPtIndex: number
  plotPt: PlotPt
  mouseX_px: number
  mouseY_px: number
  mouseFoundX_px: number
  mouseFoundY_px: number
  mouseFoundX_value: number
  mouseFoundY_value: number
  plotPtBottom_value: number
  plotPtLeft_value: number
  plotPtBottom_domain: number
  plotPtLeft_domain: number
  plotPtBottom_px: number
  plotPtLeft_px: number
  sKey: number
  seriesTitle: string
  isPinnedRow: boolean
  isCrosshairsPalleteVisible: boolean
}
class CrosshairsXYRender extends Component<Props, State> {
  state = {
    delaunayArrayIndex: -1,
    plotPtIndex: 0,
    plotPt: getDefaultSparsePlotPt(),

    mouseX_px: 0,
    mouseY_px: 0,
    mouseFoundX_px: 0,
    mouseFoundY_px: 0,
    mouseFoundX_value: 0,
    mouseFoundY_value: 0,

    plotPtBottom_value: 0,
    plotPtLeft_value: 0,
    plotPtBottom_domain: 0,
    plotPtLeft_domain: 0,
    plotPtBottom_px: 0,
    plotPtLeft_px: 0,

    sKey: -1,
    seriesTitle: '',
    isPinnedRow: false,
    isCrosshairsPalleteVisible: false,
  }


  // This handler converts a valid rowPt and mouseClick into a
  // tablelook pinnedRow (or unpinning the row)
  onClickHandler = (e: React.MouseEvent) => {
    if (this.state.plotPtIndex === -1) {
      return
    }

    let pinnedRowKeys = this.props.plotXyComputedData.pinnedRowKeys
    let pinnedIndex = pinnedRowKeys.indexOf(this.state.plotPtIndex)
    var newPinnedRowKeys = pinnedRowKeys.slice()  // New object to be dispatched in mods[]
    if (pinnedIndex === -1) { // then Pin this row
      if (pinnedRowKeys.length < MAX_NUMBER_PINNED_ROWS) {   // Normal Case.  Room for a new pinned row, so append it to array.
        newPinnedRowKeys.push(this.state.plotPtIndex)
      } else { // no room. Replace last row with this newRowKey
        newPinnedRowKeys[MAX_NUMBER_PINNED_ROWS - 1] = this.state.plotPtIndex
      }
      this.setState({ isPinnedRow: true })
    } else { //then Un-Pin this row
      newPinnedRowKeys.splice(pinnedIndex, 1)
      const { left, top } = e.currentTarget.getBoundingClientRect()
      const x = e.clientX - left - MOUSEMOVE_OVERLAP_TOLERANCE
      const y = e.clientY - top - MOUSEMOVE_OVERLAP_TOLERANCE
      this.setState({ isPinnedRow: false, mouseX_px: x, mouseY_px: y })
    }
    reactDispatch([
      { newVal: newPinnedRowKeys, path: `attributes.pinnedRowKeys` }],
      `Pin Row`,
      '',  // No action group ( drag, dragStop, . . . )
      // This action needs to dispatch to tablelooks,
      // NOT plots like almost all other actions in the plot modules,
      // So pass these two extra optional arguments.
      'tablelooks',
      this.props.tableComputedData.tablelookid,
    )
  }

  noCrosshairsState = { delaunayArrayIndex: -1, isCrosshairsPalleteVisible: false }

  mouseLeaveHandler = (e: React.MouseEvent) => {
    this.setState(this.noCrosshairsState)
    return
  }


  mouseMoveHandler = (e: React.MouseEvent) => {

    if (!SHOULD_USE_CROSSHAIRS) { return }
    const { bottomAxis, leftAxis, pinnedRowKeys, seriesAttributesArray,
      delaunayLayersArray } = this.props.plotXyComputedData

    if (isDelaunayBuildInProgress()) { return }
    else if (getDelaunayD3structures().length === 0) {
      this.setState(this.noCrosshairsState)
      createDelaunayD3structures(delaunayLayersArray)
      return
    }

    // DelaunayD3 structures exist
    // We don't need them.
    // We only need to know they exist so nearestJointXY2() works.
    var { x: leftCaptureEdge, y: topCaptureEdge } = e.currentTarget.getBoundingClientRect()
    var mouseX_px = Math.round(e.clientX - leftCaptureEdge - MOUSEMOVE_OVERLAP_TOLERANCE)
    var mouseY_px = Math.round(e.clientY - topCaptureEdge - MOUSEMOVE_OVERLAP_TOLERANCE)
    const { delaunayArrayIndex, plotPtIndex, mouseFoundX_px, mouseFoundY_px }
      = nearestJointXY2(mouseX_px, mouseY_px, delaunayLayersArray)
    if (delaunayArrayIndex === -1) {
      this.setState(this.noCrosshairsState)
      return
    }

    // We found a nearby plotted point!
    // Here we retrieve or calculate any/all information needed for crosshair's display (and debugging)
    const renderedLayer = delaunayLayersArray[delaunayArrayIndex]
    const sKey = renderedLayer.sKey
    const plotPt = renderedLayer.plotPts[plotPtIndex]
    // Next found mouse values correspond to the snapped grid!
    // Used for debugging.  NOT the actual found plotPt's A/B values.
    // Will we extract the exact plotPt coordinate directly from the plotPt.
    const mouseFoundX_value = bottomAxis.mousePxToPlotTickValue(mouseFoundX_px)
    const mouseFoundY_value = leftAxis.mousePxToPlotTickValue(mouseFoundY_px)
    // Next six values derive directly from the found plotPt.
    // There is no snapping (even grid) inaccuracies.
    const plotPtBottom_domain = renderedLayer.getBottom(plotPt, sKey)   //renderedLayer.getBottom(plotPoint, sKey)
    const plotPtLeft_domain = renderedLayer.getLeft(plotPt, sKey)
    const plotPtBottom_value = bottomAxis.reverseNonlinearXform(plotPtBottom_domain)   //renderedLayer.getBottom(plotPoint, sKey)
    const plotPtLeft_value = leftAxis.reverseNonlinearXform(plotPtLeft_domain)
    const plotPtBottom_px = bottomAxis.domainValueToMousePx(plotPtBottom_domain)
    const plotPtLeft_px = leftAxis.domainValueToMousePx(plotPtLeft_domain)
    // Only one set of pinnedRows per table.
    // These rows may or may not be found in one or more filteredData[sKey]
    const pinnedRows = pinnedRowKeys
    var isPinnedRow = false // assumption
    if (renderedLayer.plottedValue === 'tableRow') {
      const maybePinnedPlotPt = renderedLayer.plotPts[plotPtIndex]
      if (pinnedRows.indexOf(maybePinnedPlotPt.rowKey) >= 0) {
        isPinnedRow = true
      }
    }
    const infoTable = (DEBUG_MouseXforms)
      ? createDebuggingContent(this.state, this.props)
      : createCrossHairsContent(this.state, this.props)
    const seriesTitle = seriesAttributesArray[sKey].seriesTitle
    var newState = ({
      delaunayArrayIndex,
      plotPtIndex: plotPtIndex,
      mouseX_px, mouseY_px,
      mouseFoundX_px, mouseFoundY_px,
      mouseFoundX_value, mouseFoundY_value,
      plotPtBottom_value, plotPtLeft_value,
      plotPtBottom_domain, plotPtLeft_domain,
      plotPtBottom_px, plotPtLeft_px,
      sKey, seriesTitle,
      plotPt, isPinnedRow, isCrosshairsPalleteVisible: true,
      infoTable,
    })
    this.setState(newState)
    return
  }
  render() {
    const { delaunayArrayIndex, isCrosshairsPalleteVisible,
      mouseX_px, mouseY_px, plotPtBottom_px, plotPtLeft_px } = this.state
    const { plotStyleObj: s, plotWidthObj: w, plotHeightObj: h } = this.props.plotXyComputedData
    const greenCrossHairsOpacity = (delaunayArrayIndex < 0) ? 0 : 1
    let infoTable: InfoTable,
      widthLeftOfColon: number,
      widthRightOfColon: number,
      widthTable1: number,
      widthTable2: number,
      widthTable3: number;

    if (delaunayArrayIndex >= 0) {
      ({ infoTable, widthLeftOfColon, widthRightOfColon, widthTable1, widthTable2, widthTable3 } = createDebuggingContent(this.state, this.props));
    } else {
      infoTable = [{ measTextArray: [''], valueNodeArray: [''], opacity: 0 }]
      widthLeftOfColon = 0;
      widthRightOfColon = 0;
      widthTable1 = 0;
      widthTable2 = 0;
      widthTable3 = 0;
    }
    const upperLeftCornerPlot_x = w.leftMarginPlusCenteringGap + w.totalLeftAxis_displayPx
    const upperLeftCornerPlot_y = h.totalCenteringGapTitlesLegend_displayPx
    const plotWidth_displayPx = w.plottedData * s.reactVisScale
    const plotHeight_displayPx = h.plottedData * s.reactVisScale
    const leftPlacement = (mouseX_px > plotWidth_displayPx / 2)
      ? 0.35 * plotWidth_displayPx - widthLeftOfColon
      : 0.65 * plotWidth_displayPx - widthLeftOfColon

    return (
      <div className={'CrosshairsXY'}>

        {DEBUG_showMouseRedCrosshairs && <Fragment>

          <div className={'Horizontal redLine:cursorPosition'}
            style={{
              position: 'absolute',
              height: 1,
              width: plotWidth_displayPx,
              top: upperLeftCornerPlot_y + mouseY_px,
              left: upperLeftCornerPlot_x,
              background: 'red', opacity: 1,
            }} />

          <div className={'Vertical redLine:cursorPosition'}
            style={{
              position: 'absolute',
              height: plotHeight_displayPx,
              width: 1,
              top: upperLeftCornerPlot_y,
              left: upperLeftCornerPlot_x + mouseX_px,
              background: 'red', opacity: 1,
            }} />

        </Fragment>}

        <div className={'Horizontal greenLine:cursorPosition'}
          style={{
            position: 'absolute',
            height: 1,
            width: plotWidth_displayPx,
            top: upperLeftCornerPlot_y + plotPtLeft_px,
            left: upperLeftCornerPlot_x,
            background: 'green',
            opacity: greenCrossHairsOpacity,
          }} />

        <div className={'Vertical greenLine:cursorPosition'}
          style={{
            position: 'absolute',
            height: plotHeight_displayPx,
            width: 1,
            top: upperLeftCornerPlot_y,
            left: upperLeftCornerPlot_x + plotPtBottom_px,
            background: 'green',
            opacity: greenCrossHairsOpacity,
          }} />

        <div className={'rc_CrosshairsXY'}
          onMouseLeave={this.mouseLeaveHandler}
          onMouseMove={this.mouseMoveHandler}
          onClick={this.onClickHandler}
          style={{
            // This div almost exactly overlays the plottable area
            // There is a small overlap on all four sides of 'MOUSEMOVE_OVERLAP_TOLERANCE'
            // X Y coords of the mouse match those of the plot (in Px) after adjusting for above overlap.
            position: 'absolute', // Defines a local coord system for the children.  (0,0) is upper
            // left corner of the blue overlay.
            left: upperLeftCornerPlot_x - MOUSEMOVE_OVERLAP_TOLERANCE,
            top: upperLeftCornerPlot_y - MOUSEMOVE_OVERLAP_TOLERANCE,
            height: plotHeight_displayPx + 2 * MOUSEMOVE_OVERLAP_TOLERANCE,
            width: plotWidth_displayPx + 2 * MOUSEMOVE_OVERLAP_TOLERANCE,
            //background: 'pink', opacity: 0.3,
          }} />

        {isCrosshairsPalleteVisible &&
          <div className={'Container_CrosshairPalette'}
            style={{
              // The bounding box (specically bottom, left, right are used
              // as placement edges for a crosshairs pallete of various
              // widths and heights.
              position: 'relative',
              left: upperLeftCornerPlot_x,
              width: plotWidth_displayPx,
              top: upperLeftCornerPlot_y - MOUSEMOVE_OVERLAP_TOLERANCE - CROSSHAIRS_CONTAINER_HEIGHT,
              height: CROSSHAIRS_CONTAINER_HEIGHT,
              //background: 'blue'
            }}>


            <div className={'CrosshairPalette'}
              style={{
                bottom: 0,
                left: leftPlacement,
                background: 'black',
                position: 'absolute',
                borderRadius: '4px',
                pointerEvents: 'none',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
                color: '#fff',
              }}>
              {infoTable.map((thisInfoLine: InfoLine, index: number) => {
                return (<InfoRow
                  key={String(index) + thisInfoLine.measTextArray[0]}
                  valueNodeArray={thisInfoLine.valueNodeArray}
                  widthTable1={widthTable1}
                  widthTable2={widthTable2}
                  widthTable3={widthTable3}
                  widthLeftOfColon={widthLeftOfColon}
                  widthRightOfColon={widthRightOfColon}
                  opacity={thisInfoLine.opacity}
                />)
              })}
            </div>
          </div>
        }

      </div>
    )
  }
}

const HORZ_SPACER = 8

type InfoRowProps = {
  widthLeftOfColon: number
  widthRightOfColon: number
  widthTable1: number
  widthTable2: number
  widthTable3: number
  valueNodeArray: ReactNode[]
  opacity: number
}

class InfoRow extends PureComponent<InfoRowProps> {

  render() {
    var { widthLeftOfColon, widthRightOfColon, widthTable1, widthTable2,
      widthTable3, valueNodeArray: values, opacity } = this.props
    var isBlankLine = (values[0] === '' && values[1] === '' && values[2] === '' && values[3] === '')
    var isRightHalf_of_Table = (values[2] !== '' || values[3] !== '')
    const spacerStyle = {
      display: 'inline-block',
      width: HORZ_SPACER,
    }
    const doubleSpacerStyle = {
      display: 'inline-block',
      width: 2 * HORZ_SPACER,
    }
    const cellStyle = {
      display: 'inline-block',
      boxSizing: 'border-box' as Property.BoxSizing,
      verticalAlign: 'bottom',
      //border: '1px solid red',    // For debugging bottom of cell alignments.
      height: (FONT_SIZE + 4),
      fontSize: FONT_SIZE,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }

    return (
      <div
        className={'InfoRow'}
        style={{ width: '100%' }}
      >
        {isBlankLine
          ? <div style={{ height: FONT_SIZE / 1.4 }} />
          : <div
            style={{
              fontSize: FONT_SIZE,
              fontWeight: 'normal',
              opacity,
              width: widthLeftOfColon + widthRightOfColon + 4 * HORZ_SPACER,
            }}
          >
            <div className={'HorzGap'} style={spacerStyle} />

            <div className={'widthLeftOfColon'}
              style={{
                ...cellStyle,
                width: widthLeftOfColon,
                textAlign: 'right',
                //background: DEBUG ? 'red' : 'unset',
              }}>
              {values[0]}
            </div>

            <div className={'HorzGap'} style={doubleSpacerStyle} />

            {!isRightHalf_of_Table &&
              <div className={'widthRightOfColon'}
                style={{
                  ...cellStyle,
                  width: widthRightOfColon,
                  textAlign: (values[2] === '') ? 'left' : 'center',
                  //background: DEBUG ? 'cyan' : 'unset',
                }}>
                {values[1]}
              </div>
            }
            {isRightHalf_of_Table && widthTable1 > 0 &&
              <div className={'widthTable1'}
                style={{
                  ...cellStyle,
                  width: widthTable1,
                  textAlign: 'center',
                  background: DEBUG ? 'olive' : 'unset',
                }}>
                {values[1]}
              </div>
            }
            {isRightHalf_of_Table && widthTable2 > 0 &&
              <Fragment>
                <div className={'HorzGap'} style={doubleSpacerStyle} />
                <div className={'widthTable2'}
                  style={{
                    ...cellStyle,
                    width: widthTable2,
                    textAlign: 'center',
                    background: DEBUG ? 'purple' : 'unset',
                  }}>
                  {values[2]}
                </div>
              </Fragment>
            }
            {isRightHalf_of_Table && widthTable3 > 0 &&
              <Fragment>
                <div className={'HorzGap'} style={doubleSpacerStyle} />
                <div className={'widthTable3'}
                  style={{
                    ...cellStyle,
                    width: widthTable3,
                    textAlign: 'center',
                    background: DEBUG ? 'blue' : 'unset',
                  }}>
                  {values[3]}
                </div>
              </Fragment>
            }
            <div className={'HorzGap'} style={spacerStyle} />

          </div>
        }</div>
    )
  }
}


export default CrosshairsXYRender