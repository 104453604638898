interface ParsedQuery {
  [key: string]: string;
}

// parse a query string into an object,
// done simply since qs (which is what the server uses) is so bloated.
const parse = (query: string): ParsedQuery | null => {
  const queryOffset = query.indexOf('?')
  if (queryOffset >= 0) {
    const queryStr = query.substring(queryOffset + 1)
    const queryArray = queryStr.split('&')
    const result: ParsedQuery = {}
    queryArray.forEach(item => {
      const equalsOffset = item.indexOf('=')
      if (equalsOffset > 0) {
        const key = item.substring(0, equalsOffset)
        const value = item.substring(equalsOffset + 1)
        result[key] = value
      }
    })
    return result
  }
  return null
}

const queryString = {
  parse
}

export default queryString