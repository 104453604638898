import type { AnyAction, Dispatch } from '@reduxjs/toolkit'
import type { ReactNode } from 'react'
import type { PlotHeightObj, PlotStyleObj, PlotWidthObj } from '../computedDataPlotXY/xy_plotCalculator'
import type { PlotXyComputedData, ReactLayer, ReactVisType } from '../computedDataPlotXY/xy_plotTypes'
import type { TableComputedData } from '../computedDataTable/getDefaultTableComputedData'
import type { RootState } from '../redux/store'
import type { ThumbnailState } from '../redux/thumbnailReducer'

import { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { calcEquivSize, getInternalMarkShape } from '../computedDataPlotXY/plotUtils'
import { plotCalculatorAsIcon } from '../computedDataPlotXY/xy_plotCalculator'

import AbstractSeries from '../react-vis/abstract-series'
import AreaHorizontalSeries from '../react-vis/area-horizontal-series'
import AreaHorizontalSeriesCanvas from '../react-vis/area-horizontal-series-canvas'
import AreaSeries from '../react-vis/area-series'
import AreaSeriesCanvas from '../react-vis/area-series-canvas'
import Borders from '../react-vis/borders'
import CustomLineMarkSeries from '../react-vis/custom-line-mark-series'
import CustomMarkSeries from '../react-vis/custom-mark-series'
import Label from '../react-vis/label'
//import LabelCanvas                  from './react-vis/label-canvas'
import Legend from '../react-vis/legend'
import LineMarkSeriesCanvas from '../react-vis/line-mark-series-canvas'
import LineSeries from '../react-vis/line-series'
import LineSeriesCanvas from '../react-vis/line-series-canvas'
import XYPlot from '../react-vis/xy-plot'
import MarkSeriesCanvas from '../react-vis/mark-series-canvas'
import HorizontalGridLines from '../react-vis/horizontal-grid-lines'

import RectScryBottomA from '../react-vis/rect-scry-bottomA'
import RectScryBottomACanvas from '../react-vis/rect-scry-bottomA-canvas'
import RectScryLeftA from '../react-vis/rect-scry-leftA'
import RectScryLeftACanvas from '../react-vis/rect-scry-leftA-canvas'

//import RectScryCanvas               from './react-vis/rect-scry-canvas'

//import VerticalBarSeries            from './react-vis/vertical-bar-series'
//import VerticalBarSeriesCanvas      from './react-vis/vertical-bar-series-canvas'
//import HorizontalBarSeries          from './react-vis/horizontal-bar-series'
//import HorizontalBarSeriesCanvas    from './react-vis/horizontal-bar-series-canvas'

import VerticalGridLines from '../react-vis/vertical-grid-lines'
import XAxis from '../react-vis/x-axis'
import YAxis from '../react-vis/y-axis'
import '../react-vis/style.css'

import { postThumbnail } from '../redux/thumbnailThunks'
import {
  addNumberFormatReactNodeSyntax,
  addStringFormatReactNodeSyntax
} from '../sharedFunctions/numberFormat'
import { asyncDispatch } from '../sharedFunctions/utils'

import ComponentXYerrMsg from './ComponentXYerrMsg'
// import CrosshairsXY from './CrosshairsXY'
import { rStateXY_Plot } from './xy_responsiveState'

//import invariant from 'invariant'
//import type {Action}                      from '../store'
//import type {PlotWidthObj,
//             PlotHeightObj,
//             PlotStyleObj}                from './xy_plotCalculator'
//import {createSingleAreaPolygon}          from './getPlotPts'


type OwnProps = {
  canEdit: boolean
  plotXyComputedData: PlotXyComputedData
  tableComputedData: TableComputedData
}
type DefaultProps = {
  shouldRenderSVG: boolean
  renderAsIcon: boolean
}
type StateProps = {
  thumbnail: ThumbnailState
}
type DispatchProps = {
  saveThumbnail: (plotid: string, plotWidthObj: PlotWidthObj, plotHeightObj: PlotHeightObj) => void
}
type Props = OwnProps & DefaultProps & StateProps & DispatchProps


class ComponentXYRender extends Component<Props> {

  static defaultProps = {
    shouldRenderSVG: false,
    renderAsIcon: false,
  }

  componentDidUpdate() {
    // Save the plot thumbnail after an update has occured
    const { plotid, plotWidthObj, plotHeightObj } = this.props.plotXyComputedData
    this.props.saveThumbnail(plotid, plotWidthObj, plotHeightObj)
  }
  initComponentXY_TransformContainer = (element: HTMLDivElement | null): void => {
    rStateXY_Plot.componentXY_TransformContainer = element
  }
  initComponentXY = (element: HTMLDivElement | null): void => {
    rStateXY_Plot.componentXY = element
  }

  render() {
    const { plotXyComputedData, shouldRenderSVG, renderAsIcon/*, tableComputedData*/ } = this.props
    const { fullPlotLevelErrMessage, canvasLayersArray, leftAxis, bottomAxis } = plotXyComputedData
    if (renderAsIcon) {
      const result = plotCalculatorAsIcon()
      var w = result.plotWidthObj
      var h = result.plotHeightObj
      var s = result.plotStyleObj
    } else {
      w = plotXyComputedData.plotWidthObj
      h = plotXyComputedData.plotHeightObj
      s = plotXyComputedData.plotStyleObj
    }

    const isErrorMsgDueToNoData = (fullPlotLevelErrMessage.length > 0)
    const shouldUseSVG = (shouldRenderSVG || renderAsIcon)

    // Assumption:  We render what we've been passed!
    var renderedLeftDomain = leftAxis.domainExtended.slice()
    var renderedBottomDomain = bottomAxis.domainExtended.slice()
    var leftTickUserStringsNoHTML = leftAxis.tickUserStringsNoHTML.slice()
    var bottomTickUserStringsNoHTML = bottomAxis.tickUserStringsNoHTML.slice()
    var leftTickUserValues = leftAxis.tickUserValues.slice()
    var bottomTickUserValues = bottomAxis.tickUserValues.slice()

    // Exceptions:
    // - If log scale plot of negative data, undo the earlier
    //   lie that treated this plot as all positive data.
    // - If leftAxis is string data type, then use a axis
    //   order of first string at top, last string at bottom.
    //   As one would normally read text.
    // - If user intentially inverted the axis, such that
    //   min/max need to swap places, do that now.
    if (leftAxis.willUseLogWithNegativeData) {
      renderedLeftDomain.reverse()
      leftTickUserStringsNoHTML = leftTickUserStringsNoHTML.map(x => '-' + x)
    }
    if (bottomAxis.willUseLogWithNegativeData) {
      renderedBottomDomain.reverse()
      bottomTickUserStringsNoHTML = bottomTickUserStringsNoHTML.map(x => '-' + x)
    }
    // If leftAxis is a string, invert the axis such that first strings
    // are in order of top-to-bottom  (opposite of a numeric axis!)
    if (leftAxis.internalDataType === 'string') {
      renderedLeftDomain.reverse()
    }
    // If leftAxis is an invertedAxis, swap the domain
    if (leftAxis.isInvertedAxis) {
      renderedLeftDomain.reverse()
    }
    // If bottomAxis is invertedAxis, swap the domain
    if (bottomAxis.isInvertedAxis) {
      renderedBottomDomain.reverse()
    }

    const XYPlot_props = {
      className: 'XY_Plot',
      width: w.reactVisArea,
      height: h.reactVisArea,
      margin: {
        top: h.legend + h.gapLegendPlot,
        bottom: h.totalBottomAxis,
        left: w.totalLeftAxis,
        right: w.overhangOfLastBottomAxisTickLabel
      },
      dontCheckIfEmpty: true,
      xDomain: renderedBottomDomain,
      yDomain: renderedLeftDomain,
      style: { position: 'absolute', top: 0, left: 0 },
    }

    /*

      1) In react-vis/update-utils, set the debugging state true.
      2) Starting Point Add this style to className={`XY_CanvasLayer_${i}`}
        style={{position:'absolute',
        top:0, left:0,
        background: 'green',
        top   : h.legend + h.gapLegendPlot,
        left  : w.totalLeftAxis,
        transform: `scale( ${(h.legend + h.gapLegendPlot)/200} , .5 )`,
        opacity:thisCanvas[0].canvasOpacity,
       }}>

      3) Starting Point: add this style to <XYPlot {...XYPlot_props} >
        const XYPlot_props2 = {
            className:'XY_Plot',
            width : 400,
            height: 400,
            style: {position:'absolute', top:0, left:0},
            transform: 'scale(1,1) translate(w.totalLeftAxis, h.legend + h.gapLegendPlot)',
            background:'pink',
           // margin: { top   : h.legend + h.gapLegendPlot,
            //          bottom: h.totalBottomAxis,
            //          left  : w.totalLeftAxis,
            //          right : w.overhangOfLastBottomAxisTickLabel },
            dontCheckIfEmpty: true,
            xDomain: renderedBottomDomain,
            yDomain: renderedLeftDomain,
        }
        */


    const plotMargins = XYPlot_props.margin

    return (
      <div className={'rc_ComponentXY'}
        ref={this.initComponentXY}
        style={{
          marginLeft: w.leftMarginPlusCenteringGap,
          marginTop: h.centeringGap,
          width: w.focalPlanePx, height: h.focalPlanePx,
          //background: 'green',
        }}>

        <div className={'rc_ComponentXY_ScaleContainer'}
          ref={this.initComponentXY_TransformContainer}
          style={{
            position: 'relative', top: 0, left: 0, // local Coord System
            transform: s.plotTransform,
            width: w.reactVisArea, height: h.reactVisArea,  // Before scaling!
            //background: 'pink',
          }}>


          {/* CASE: Everything MUST be in a single SVG ;
                This is either the user downloading an SVG,
                or case of developer creating a plot to 'renderAsIcon'. */}
          {shouldUseSVG &&
            <XYPlot {...XYPlot_props}>
              {true && renderGridLines(plotXyComputedData, renderAsIcon, s)}
              {true && canvasLayersArray.map((thisCanvas, i): ReactNode => {
                return thisCanvas.map((thisLayer, i): ReactNode => {
                  if (!thisLayer.isRendered) { return null }
                  return renderSingleReactLayer(thisLayer, shouldUseSVG, i)
                })
              }
              )}
              {/* Masking borders, blocking any rendered info outside the
                      XYPlot domain. Required for SVG visTypes. Anything we wish
                      to see beyond the border must be rendered AFTER  <Borders>  */}
              <Borders className={'Borders'} key={'Borders'} style={{ all: { fill: 'white' } }} />
              {true && renderLabelsAndLegend(plotXyComputedData, shouldUseSVG, renderAsIcon)}
              {true && renderAxes(plotXyComputedData,
                leftTickUserStringsNoHTML, leftTickUserValues,
                bottomTickUserStringsNoHTML, bottomTickUserValues,
                renderAsIcon, w, h, s)}
            </XYPlot>
          }

          { /* CASE:  Rev3 Plot Architecture
                  Grid lines, Labels, and Legend on bottom
                  One XYPlot for each renderedData item (map.renderedDataArray.forEach)
                  Axes last.  So axes appear over bars.  */ }
          {!shouldUseSVG &&
            <Fragment>
              <XYPlot {...XYPlot_props}>
                {true && renderGridLines(plotXyComputedData, renderAsIcon, s)}
                {true && renderLabelsAndLegend(plotXyComputedData, shouldRenderSVG, renderAsIcon)}
              </XYPlot>

              {true && canvasLayersArray.map((thisCanvas, i): ReactNode => {
                if (thisCanvas.length === 0) { return null }
                return (
                  <div className={`XY_CanvasLayer_${i}`}
                    key={`XY_BarPlot_group${i}`}
                    style={{
                      position: 'absolute', top: 0, left: 0,
                      opacity: thisCanvas[0].canvasOpacity
                    }}>
                    <XYPlot {...XYPlot_props} >
                      {thisCanvas.map((thisLayer, j): ReactNode => {
                        if (!thisLayer.isRendered) return null
                        //console.log( 'Rendering: ', thisLayer.description )
                        return (renderSingleReactLayer(thisLayer, shouldUseSVG, j))
                      })}
                    </XYPlot>
                  </div>
                )
              })}

              <div style={{ position: 'absolute', top: 0, left: 0 }}>
                <XYPlot {...XYPlot_props}>
                  {true && renderAxes(plotXyComputedData,
                    leftTickUserStringsNoHTML, leftTickUserValues,
                    bottomTickUserStringsNoHTML, bottomTickUserValues,
                    renderAsIcon, w, h, s)}
                </XYPlot>
              </div>
            </Fragment>
          }

          {true && isErrorMsgDueToNoData &&
            <ComponentXYerrMsg
              top={plotMargins.top} left={plotMargins.left}
              plotHeight={h.plottedData} plotWidth={w.plottedData}
              fontSize={s.fontSizeErrMsg}
              errMsg={plotXyComputedData.fullPlotLevelErrMessage}
            />
          }
        </div>

        {false/* &&
          <CrosshairsXY
            plotWidth={w.plottedData} plotHeight={h.plottedData}
            bottomAxisHeight={plotMargins.bottom}
            leftAxisWidth={plotMargins.left}
            topPlotMargin={plotMargins.top}
            plotXyComputedData={plotXyComputedData}
            tableComputedData={tableComputedData}
          />*/
        }

      </div>
    )
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  thumbnail: state.thumbnail,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps => (
  {
    // Passing plot widthObj and heightObj
    saveThumbnail: (plotid: string, plotWidthObj: PlotWidthObj, plotHeightObj: PlotHeightObj): void => {
      asyncDispatch(dispatch, postThumbnail(plotid, plotWidthObj, plotHeightObj))
    },
  }
)

const ComponentXY = connect(mapStateToProps, mapDispatchToProps)(ComponentXYRender)
export default ComponentXY




const renderGridLines = (plt: PlotXyComputedData, renderAsIcon: boolean, s: PlotStyleObj): ReactNode[] => {
  if (renderAsIcon) { return [] }
  const arrayedComponents = []
  arrayedComponents.push(
    <VerticalGridLines
      className={'VerticalGridLines_dark'}
      key={'vertGrid'}
      tickValues={plt.verticalGridLines.tickVisValues}
      style={{ stroke: plt.verticalGridLines.gridColor, strokeWidth: s.gridLineThickness }}
    />
  )

  arrayedComponents.push(
    <HorizontalGridLines
      className={'HorizontalGridLines_dark'}
      key={'horzGrid'}
      tickValues={plt.horizontalGridLines.tickVisValues}
      style={{ stroke: plt.horizontalGridLines.gridColor, strokeWidth: s.gridLineThickness }}
    />
  )

  if (plt.verticalGridLinesLight) {
    arrayedComponents.push(
      <VerticalGridLines
        className={'VerticalGridLines_light'}
        key={'vertGridLight'}
        tickValues={plt.verticalGridLinesLight.tickVisValues}
        style={{ stroke: plt.verticalGridLinesLight.gridColor, strokeWidth: s.gridLineThickness }}
      />
    )
  }

  if (plt.horizontalGridLinesLight) {
    arrayedComponents.push(
      <HorizontalGridLines
        className={'HorizontalGridLines_light'}
        key={'horzGridLight'}
        tickValues={plt.horizontalGridLinesLight.tickVisValues}
        style={{ stroke: plt.horizontalGridLinesLight.gridColor, strokeWidth: s.gridLineThickness }}
      />
    )
  }
  return arrayedComponents
}

type LegendItems = {
  color: string
  opacity: number
  title: string
  seriesKey: number
  errMsg: string
}

const renderLabelsAndLegend = (plt: PlotXyComputedData, shouldRenderSVG: boolean, renderAsIcon: boolean): ReactNode[] => {

  // OK to always use the plotXyComputedData's w,h,s results, because this
  // function is ONLY called when rendering the full plot (all details)

  //const LabelClass = shouldRenderSVG ? Label : LabelCanvas
  const LabelClass = Label
  if (renderAsIcon) { return [] }
  const { plotStyleObj: s, plotWidthObj: w, plotHeightObj: h,
    bottomAxis, leftAxis, seriesAttributesArray } = plt
  const legendItems = Array<LegendItems>()
  plt.seriesOrder.forEach(seriesKey => {
    var { color, seriesTitle, seriesOpacity, errMsg } = seriesAttributesArray[seriesKey]
    if (seriesTitle === '') {
      seriesTitle = '??'
    }
    legendItems.push({ color, opacity: seriesOpacity, title: seriesTitle, seriesKey, errMsg })
  })
  //x={w.plottedData/2}
  //y={h.plottedData + h.bottomAxisTickLabel + h.gap_TickLabel_Title + h.bottomAxisTitle
  const arrayedComponents = []
  arrayedComponents.push(
    <LabelClass label={bottomAxis.axisTitle} rotation={0}
      key={'bottomAxisTitle'}
      style={{ fontFamily: 'sans-serif', fontSize: s.fontSizeAxisTitle, fill: 'black', strokeWidth: 0 }}
      textAnchor='middle'
      x={w.plottedData / 2}
      y={h.plottedData + h.bottomAxisTickLabel + h.gap_TickLabel_Title + h.bottomAxisTitle}
    />
  )

  if (bottomAxis.axisSubTitle) {
    arrayedComponents.push(
      <LabelClass label={bottomAxis.axisSubTitle} rotation={0}
        key={'bottomAxisSubTitle'}
        style={{ fontFamily: 'sans-serif', fontSize: s.fontSizeAxisSubTitle, fill: 'black' }}
        textAnchor='middle'
        x={w.plottedData / 2}
        y={h.plottedData + h.bottomAxisTickLabel + h.gap_TickLabel_Title + h.bottomAxisTitle
          + h.bottomAxisSubTitle + h.gap_bottomAxisTitle_SubTitle}
      />
    )
  }

  arrayedComponents.push(
    <LabelClass label={leftAxis.axisTitle} rotation={-90}
      key={'leftAxisTitle'}
      style={{ fontFamily: 'sans-serif', fontSize: s.fontSizeAxisTitle, fill: 'black', strokeWidth: 0 }}
      textAnchor='middle'
      x={-w.leftOffsetTitle}
      y={h.plottedData / 2}
    />
  )

  if (leftAxis.axisSubTitle) {
    arrayedComponents.push(
      <LabelClass label={leftAxis.axisSubTitle} rotation={-90}
        key={'leftAxisSubTitle'}
        style={{ fontFamily: 'sans-serif', fontSize: s.fontSizeAxisSubTitle, fill: 'black' }}
        textAnchor='middle'
        x={-w.leftOffsetSubTitle}
        y={h.plottedData / 2}
      />
    )
  }

  arrayedComponents.push(
    <Legend
      className={'Legend'}
      key={'Legend'}
      items={legendItems}
      fontSize={s.fontSizeLegend}
      leftMargin={w.legendGapBetweenItems}
      gapBetweenItems={w.legendGapBetweenItems}
      gapAboveLine={h.legendGapAboveLine}
      lineThickness={h.legendLineThickness}
      gapLineText={h.legendGapLineText}
      gapBelowText={h.legendGapBelowText}
      style={{ fontSize: `${s.fontSizeLegend}px` }}
    />
  )

  return arrayedComponents
}


const renderAxes = (plt: PlotXyComputedData,
  leftTickUserStringsNoHTML: string[],
  leftTickUserValues: number[],
  bottomTickUserStringsNoHTML: string[],
  bottomTickUserValues: number[],
  isIcon: boolean, w: PlotWidthObj, h: PlotHeightObj, s: PlotStyleObj): ReactNode => {

  const { bottomAxis, leftAxis } = plt
  var leftTickFormat = (_: any, i: number): ReactNode => i
  var bottomTickFormat = (_: any, i: number): ReactNode => i

  // This functions for 'number' formats exponential values
  // using raised exponents.  We may or may not include the
  // significand, depending on whether there is only
  // 1 label per decade. (log plots)
  if (bottomAxis.internalDataType === 'number') {
    if (bottomAxis.labelsPerDecade === 1) {
      bottomTickFormat = (v, i) => addNumberFormatReactNodeSyntax(
        bottomTickUserStringsNoHTML[i],
        bottomAxis.tickFormatSuffixStrg,
        'plotSVG',
        false)  // last arg is 'shouldShowSignificand'
    } else {
      bottomTickFormat = (v, i) => addNumberFormatReactNodeSyntax(
        bottomTickUserStringsNoHTML[i],
        bottomAxis.tickFormatSuffixStrg,
        'plotSVG',
        true)
    }
  }
  // This function for strings formats the ordinal position (1st, 2nd, 3rd, 4th, ....)
  // The measured string for the plotCalculater is always uses 'th', because
  // plotCalculator only needs to get the string roughly the correct size.
  // This function allows us to format the string as pretty as we wish,
  // using HTML syntax. It adds the ordinal information after the enumerated string.
  if (bottomAxis.internalDataType === 'string') {
    bottomTickFormat = (v, i) => addStringFormatReactNodeSyntax(
      bottomTickUserValues[i],
      bottomTickUserStringsNoHTML[i],
    )
  }

  // See comments above.
  if (leftAxis.internalDataType === 'number') {
    if (leftAxis.labelsPerDecade === 1) {
      leftTickFormat = (v, i) => addNumberFormatReactNodeSyntax(
        leftTickUserStringsNoHTML[i],
        leftAxis.tickFormatSuffixStrg,
        'plotSVG',
        false) // last arg is 'shouldShowSignificand'
    } else {
      leftTickFormat = (v, i) => addNumberFormatReactNodeSyntax(
        leftTickUserStringsNoHTML[i],
        leftAxis.tickFormatSuffixStrg,
        'plotSVG',
        true)
    }
  }
  if (leftAxis.internalDataType === 'string') {
    leftTickFormat = (v, i) => addStringFormatReactNodeSyntax(
      leftTickUserValues[i],
      leftTickUserStringsNoHTML[i],
    )
  }


  const arrayedComponents = []
  arrayedComponents.push(
    <XAxis
      className={'Xaxis'}
      key={'XAxis'}
      tickValues={isIcon ? [] : bottomAxis.tickVisValues}
      tickFormat={bottomTickFormat}
      tickLabels={bottomAxis.tickUserStringsNoHTML /* Used only to retrigger a render */}
      tickLabelAngle={bottomAxis.labelAngle}
      title=''
      height={h.totalBottomAxis}
      tickSizeInner={s.axisTickSizeInner}
      tickSizeOuter={s.axisTickSizeOuter}
      tickPadding={h.bottomAxisTickPadding}
      axisLineOffset={h.zeroAxisTranslationIn_Y}
      style={{
        fill: 'black',
        fontFamily: 'sans-serif',
        border: '4px solid pink',
        ticks: {
          fontWeight: 'normal', fontSize: s.fontSizeTickLabel[bottomAxis.basisName],
          fill: 'black', stroke: 'none'
        },
        line: {
          stroke: 'black', strokeWidth: s.axisLineThickness
        },
      }}
    />
  )
  arrayedComponents.push(
    <YAxis
      className={'Yaxis'}
      key={'YAxis'}
      tickValues={isIcon ? [] : leftAxis.tickVisValues}
      tickFormat={leftTickFormat}
      tickLabels={leftTickUserStringsNoHTML /* Used to retrigger a render */}
      tickLabelAngle={0}
      title=''
      width={w.totalLeftAxis}
      tickSizeInner={s.axisTickSizeInner}
      tickSizeOuter={s.axisTickSizeOuter}
      tickPadding={w.leftAxisTickPadding}
      axisLineOffset={w.zeroAxisTranslationIn_X}
      style={{
        fill: 'black',
        fontFamily: 'sans-serif',
        ticks: {
          fontWeight: 'normal',
          fontSize: s.fontSizeTickLabel[leftAxis.basisName],
          fill: 'black',
          stroke: 'none'
        },
        line: { stroke: 'black', strokeWidth: s.axisLineThickness },
      }}
    />
  )
  return arrayedComponents
}

type ReactVisTypeToClass = {
  [key in ReactVisType]: typeof AbstractSeries
}
const typeToClassMapCanvas: ReactVisTypeToClass = {
  AreaSeries: AreaSeriesCanvas,
  AreaHorizontalSeries: AreaHorizontalSeriesCanvas,
  LineMarkSeries: LineMarkSeriesCanvas,
  LineSeries: LineSeriesCanvas,
  MarkSeries: MarkSeriesCanvas,

  RectScryBottomA: RectScryBottomACanvas,
  RectScryLeftA: RectScryLeftACanvas,

  //VerticalBarSeries: VerticalBarSeriesCanvas,
  //HorizontalBarSeries: HorizontalBarSeriesCanvas,
}

const typeToClassMapSVG: ReactVisTypeToClass = {
  AreaSeries: AreaSeries,
  AreaHorizontalSeries: AreaHorizontalSeries,
  LineMarkSeries: CustomLineMarkSeries,
  LineSeries: LineSeries,
  MarkSeries: CustomMarkSeries,

  RectScryBottomA: RectScryBottomA,
  RectScryLeftA: RectScryLeftA,

  //VerticalBarSeries: VerticalBarSeries,
  //HorizontalBarSeries: HorizontalBarSeries,
}


const renderSingleReactLayer = (thisRenderedLayer: ReactLayer, useSVG: boolean, renderIndex: number): ReactNode => {
  // This function assumes one graphics layer per call.
  // renderIndex should sequence sequentially
  var { markSize, markShape, color, plotPts, sKey, plotPtAttributeB,
    visTypeReact, lineThickness, seriesOpacity,
    getLeft, getLeft0, getBottom, getBottom0 } = thisRenderedLayer
  if (!plotPts || plotPts.length === 0) { return null }
  var strokeColor = visTypeReact !== 'MarkSeries' ? color : ''
  var internalMarkShape = getInternalMarkShape(markShape, markSize)
  var visMarkSize = calcEquivSize(internalMarkShape, markSize)
  const SeriesClass = (useSVG)
    ? typeToClassMapSVG[visTypeReact]
    : typeToClassMapCanvas[visTypeReact]

  // plotPtAttributeB is NOT used by react vis.  But used by our custom 'Should Component Render'
  // to signal a change in the getter functions.
  return (
    <SeriesClass
      className={`RenderLayer_${renderIndex}`}
      plotPtAttributeB={plotPtAttributeB}
      fillType='literal'
      opacityType='literal'
      sizeType='literal'
      strokeType='literal'
      customComponent={internalMarkShape}
      fill={color}
      key={`${sKey}_${renderIndex}`}
      size={visMarkSize}
      opacity={seriesOpacity}
      stroke={strokeColor}
      //style={{strokeLinejoin: "round"}}
      //strokeLinejoin={"round"}
      strokeWidth={lineThickness}
      index={sKey}
      data={plotPts}

      getY0={getLeft0}
      getY={getLeft}
      getX0={getBottom0}
      getX={getBottom}
    />
  )
}
