import { PureComponent } from 'react'

type OwnProps = {
  width: number
  height: number
  foregroundColor: string
}
export default class SVGpin extends PureComponent<OwnProps> {

  render() {
    return (
      <svg
        className={'rc_SVGpin'}
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 96 96"
        preserveAspectRatio='none'
        width={this.props.width}
        height={this.props.height}
        style={{
          transform: 'scaleX(-1)',
          display: 'block',
        }}
      >
        <g id="XMLID_1_">
          <path
            stroke={this.props.foregroundColor}
            strokeWidth="2"
            id="XMLID_3_"
            d="M56.4,17.8v3.6l2.3,4.5L38.2,39.6H21c-2,0-3,2.4-1.5,3.9l14.3,14.3L15.5,80.5l22.7-18.2 l14.3,14.3c1.5,1.5,3.9,0.4,3.9-1.5V57.8l13.6-20.5l4.5,2.3h3.6c2,0,3-2.4,1.5-3.9L60.3,16.2C58.8,14.7,56.4,15.8,56.4,17.8z"
            fill='green'
          />
        </g>
      </svg>
    )
  }
}
