import type { Column } from '../types'
import type { ReactNode } from 'react'
import type { DerivedColAttributes } from './getDefaultTableComputedData'
import type { FormattingObj } from '../sharedFunctions/numberFormat'
import type {HighlightArray}  from '../sharedFunctions/parseScryInputStringTypes'
import type { SubStringInfo, ScryFormula } from './formulaTypes'
import type { ErrorRows, StatsGroup } from './getDefaultTableComputedData'
import type {
  DepValuesParams, DepValuesRefs, DepValuesOther, DepValuesResult,
  RowNameErrorParams, RowNameErrorRefs, RowNameErrorOther, RowNameErrorResult,
  ColErrorParams, ColErrorRefs, ColErrorResult, ColErrorOther,
  FormulaParams, FormulaRefs, FormulaOther, FormulaResult,
  StatsParams, StatsRefs, StatsOtherArgs, StatsResult,
  StatsBarLayoutParams, StatsBarLayoutRefs, StatsBarLayoutResult,
  SortParams, SortRefs, SortOtherArgs, SortResult,
  BasicFormulaParams, 
} from './updateTableComputedData'
import type { TableHeightObj } from './layoutCalculator'
import type { StatsNumbers, StatsStrings, StatsBoolTrueFalse } from './updateStatsShared'

import { getCommasOnlyFormattingObj } from '../sharedFunctions/numberFormat'
import { list } from 'radash'
import invariant from 'invariant'
import { isScryHyperlink_fromTableValue } from '../sharedFunctions/isTypeHyperlink'
import { getTableComputedData } from '../appCode/getMemoizedComputedData'
import isScryNumber from '../sharedFunctions/isTypeNumber'
import constants from '../sharedComponents/constants'
import { errorCheckLines } from './formulaErrorCheckLines'
import { errorCheckFormulaStructure } from './formulaErrorCheckStructure'
import { formulaParser } from './formulaParser'
import {
  reservedColNames, reservedKeywords,
  reservedFuncNames, reservedOperators,
  reservedIllegalOperators,
  reservedIllegalPython
} from './formulaTypes'
import { RPN_createPrograms } from './RPN_createPrograms'
import { RPN_evaluate } from './RPN_evaluate'
import { RPN_optimize } from './RPN_optimize'
import updateStatsShared from './updateStatsShared'
import { measureText } from '../sharedFunctions/measureText'
import { numberFormat,
  numberFormatReactNode,
  getFormattingObj } from '../sharedFunctions/numberFormat'
import { sessionStateChangeDispatch } from '../sharedComponents/reactDispatch'

function lastVal<T>(a: Array<T>): T {
  return a[a.length - 1]
}

export const errorCheckColOrder = (isDeletedArr: boolean[], colOrderIn: number[], colTitle: string[]): void => {

    const numCols = isDeletedArr.length
    for ( const colKey of colOrderIn ) {
      if (colKey < 0 || colKey >= numCols) {
        const msg = `colOrderIn Error: Out-of-range colKey ${colKey}.  NumCols === ${numCols}`
        invariant(false, msg)
      }
    }
    /* Example reverse map data array:
       Info concerning which colKeys are present; And how many copies of this colKey (zero, one, or tooMany)
       Example:
    [ [3],    // 1st value is colKey '0', which maps to colOrderIn index 3. Maybe an error if this is a deleted colKey.
      [4,5],  // 2nd value is colKey '1'. which maps to colOrderIn index 4 and 5.  Error as duplicate colKey.
      [],     // 3rd value is colKey '2', which was NOT found in colOrderIn!  Maybe an error if this is a non-deleted colKey.
      ...
    ] */
    const reverseMap = Array.from({ length: numCols }, () => new Array(0))
    for ( const [colIndex, colKey] of colOrderIn.entries() ) {
      if (colKey >= 0 && colKey < numCols) { reverseMap[colKey].push(colIndex) }
    }

    for (let colKey = 0; colKey < numCols; colKey++) {
      const title = colTitle[colKey]
      if (isDeletedArr[colKey] && reverseMap[colKey].length > 0) {
        const msg = `colOrderIn Error: isDeletedCol "${title}" found at colOrderIn indices ` + JSON.stringify(reverseMap[colKey])
        invariant(false, msg)
      }
      if (reverseMap[colKey].length === 0 && isDeletedArr[colKey] === false) {
        const msg = `colOrderIn Error: missing colKey ${colKey} ("${title}"). Or perhaps colKey was not marked as deleted.`
        invariant(false, msg)
      }
      if (reverseMap[colKey].length > 1) {
        const msg = `colOrderIn Error: duplicate colKey ${colKey} ("${title}") at colOrderIn indices` + JSON.stringify(reverseMap[colKey])
        invariant(false, msg)
      }
    }
}

export const getDerivedColOrder = ( isDeletedArr: boolean[], colOrderIn: number[] ): number[]=> { 
    // Resource colOrder (colOrderIn) --> Considered a 'suggestion'
    // isDeletedArr --> (By colOrder; We assume this is a trusted, accurate list of ALL 
    // columns, sequentially order by colKey

    // Every time user adds/deletes/reorders columns, we will save the new colOrder to 
    // the tablelook resource as our best 'next suggestion'.
    // However, we will never rely on colOrder in tablelook to be legal.  We will always
    // assume that a change in colOrder, numCols, etc by the publisher may result in
    // an 'illegal' tablelook colOrder.  Hence colOrder is ALWAYS potentially illegal.
    // This function should preserve the suggested tablelook colOrder 'as-best-possible', while 
    // correcting errors to make the derivedColOrder legal.  There are many legal
    // solutions.  Up to us to decide 'how' we choose to fix errors.   
    const derivedColOrder = colOrderIn.slice()
    const numCols = isDeletedArr.length
    const priorFoundColKeysArr : number[] = new Array(numCols).fill(0)   
                                         
    // First, we create a list of colIndices to delete
    //    - out-of-range
    //    - isDeleted_ByColIndex === true  
    //    - duplicates (defined as the 2nd match, going left to right; We keep the first, leftmost duplicate colKey)
    // Then we will remove these colKeys from colOrderIn, but removal is righty-to-left to simplify splicing.
    const colIndicesToDelete : number[] = []
    for ( const [colIndex, colKey] of colOrderIn.entries() ) {
      if ( colKey < 0 || colKey >= numCols || isDeletedArr[colKey] || priorFoundColKeysArr[colKey] > 0 ) {
        colIndicesToDelete.push(colIndex)
      } else {
        priorFoundColKeysArr[colKey]++ 
      }
    }
    for ( let i = colIndicesToDelete.length-1; i >= 0; i-- ) {
      derivedColOrder.splice( colIndicesToDelete[i], 1)
    }
    // Second, any missing colKeys??
    // Defined as isDeleted === false && not currently found in derivedColOrder
    // I will insert at end of derivedColOrder array.
    // This specifically addresses new columns, added by the publisher,
    // but invisible to an out-of-date tablelook resource.
    for ( const [colKey, isDeleted] of isDeletedArr.entries() ) {
      if ( isDeleted === false && derivedColOrder.indexOf(colKey) === -1 ) { 
        derivedColOrder.push(colKey)
      }
    }
    return derivedColOrder
}


export const calcColumnStats_async = (paramsObj: StatsParams, refsObj: StatsRefs, otherArgsObj: StatsOtherArgs): StatsResult => {
  // Method #2: Calc stats in an async function.  This function returns right away and a render
  // can occur.  Then the async function runs.  
  // TtGetComputedData will notice the memoization array has changed and update tableComputedData.
  // Note: I've seen some signs that react will run this almost immediatly and if its long
  // the user will see a 'stuck' screen.  If it gets very long we will need a webworker.
  calcColumnStats(paramsObj, refsObj, otherArgsObj).then(result => {
    const tableComputedData = getTableComputedData(otherArgsObj.tableid)
    if (!tableComputedData) { return }
    tableComputedData.memoizedStats = [{ result: result, paramsObj, refsObj }, ...tableComputedData.memoizedStats]
  })
  // We don't return anything here.  We are going to rerender when the stats are available and then they will be found
  // in the memoizedStats array.
  return null
}

// Define the worker outside the function to keep it alive between calls
let worker: Worker | null = null;

// Function to initialize the worker
function initWorker() {
  const updateSharedScript = updateStatsShared.toString()
  const script = `
    self.updateStatsShared = ${updateSharedScript}
    let idleTimeout;
    function resetIdleTimeout() {
      if (idleTimeout) {
        clearTimeout(idleTimeout);
      }
      idleTimeout = setTimeout(() => {
        postMessage({ 'PROCESS_STATS': 'EXITING'})
        self.close();
      }, 30000); // 30 seconds
    }
    onmessage = function(e) {
      resetIdleTimeout();
      const { paramsObj, refsObj, otherArgsObj } = e.data;
      const result = updateStatsShared(paramsObj, refsObj, otherArgsObj);
      postMessage(result);
      resetIdleTimeout();

    }
  `;
  const blob = new Blob([script], { type: 'application/javascript' })
  worker = new Worker(URL.createObjectURL(blob))
}

export const calcColumnStats_worker = (paramsObj: StatsParams, refsObj: StatsRefs, otherArgsObj: StatsOtherArgs): StatsResult => {
  // Initialize the worker if it doesn't exist
  if (worker === null) {
    initWorker();
  }
  if (!worker) { return null; }
  worker.onmessage = (e) => {
    // If the worker is exiting, terminate it
    if (e.data.PROCESS_STATS === 'EXITING' && worker) {
      worker.terminate();
      worker = null;
      return;
    }
    const tableComputedData = getTableComputedData(otherArgsObj.tableid);
    if (!tableComputedData) { return; }
    tableComputedData.memoizedStats = [{ result: e.data, paramsObj, refsObj }, ...tableComputedData.memoizedStats];
    const statsIndex = tableComputedData.memoizedStats.length
    sessionStateChangeDispatch([{ newVal: statsIndex, path: 'statsBarDataIndex'}], 'update StatsBar')
    //console.log('Worker finished ' + statsIndex);
  };

  worker.onerror = (e) => {
    console.log('Error in worker: ', e);
  };

  // Post message to the existing worker
  worker.postMessage({ paramsObj, refsObj, otherArgsObj });

  return null;
};

// Optional: Function to terminate the worker when it's no longer needed
export const terminateWorker = () => {
  if (worker) {
    worker.terminate();
    worker = null;
  }
};


const calcColumnStats = async (paramsObj: StatsParams, refsObj: StatsRefs, otherArgsObj: StatsOtherArgs): Promise<StatsResult> => {
  return updateStatsShared(paramsObj, refsObj, otherArgsObj)
}


export const calcDependentColumnValues_memoizedFunc = (paramsObj: DepValuesParams, refsObj: DepValuesRefs, otherArgsObj: DepValuesOther): DepValuesResult => {
  const { isMissingDepCol, isNotCalculableDepCol, colKeyDepCol } = paramsObj
  const { tableValuesWorking } = otherArgsObj
  const numRows = tableValuesWorking[0].length
  // We ALWAYS create an new array reference. 
  // If we are calling this function, we assume that the values are changing.
  // Start with the assumption the calculation returns an array is empty cells.
  const newColData = tableValuesWorking[colKeyDepCol] = new Array(numRows).fill('')
  if (isMissingDepCol || isNotCalculableDepCol) {
    return { newColData, isMissing: true, errorObj: {} }
  }
  // Here we execute the dependent column's formula (over rows 0 to row numRows-1)
  const { program_OPT } = paramsObj.parsedScryFormula
  const { errorObj } = RPN_evaluate(program_OPT, 0, numRows - 1, colKeyDepCol, tableValuesWorking)
  return { newColData: tableValuesWorking[colKeyDepCol], errorObj, isMissing: false }
}



export const formulaParseAndErrorCheck_memoizedFunc = (paramsObj: FormulaParams, refsObj: FormulaRefs, otherArgsObj: FormulaOther): FormulaResult => {
  const { colKey, colTitleArr, isBadColNameBecauseRedundantArr, isDeletedArr, isBadColNameArr,
    formula, internalDataTypeArr, useOptimization } = paramsObj
  const parsedScryFormula = formulaParser(formula, colKey, colTitleArr, isBadColNameBecauseRedundantArr, isDeletedArr, isBadColNameArr)
  const result = convertToUserColNames(parsedScryFormula.formulaSubStrings, paramsObj)
  parsedScryFormula.formulaStrings = result.formulaStrings
  parsedScryFormula.formulaSubStrings = result.formulaSubStrings
  errorCheckLines(parsedScryFormula, colKey, colTitleArr, internalDataTypeArr)
  errorCheckFormulaStructure(parsedScryFormula, colTitleArr)
  const isBadFormulaSyntax = (parsedScryFormula.errorID !== '')
  // early return when a syntax error:
  if (isBadFormulaSyntax) { return { parsedScryFormula, isBadFormulaSyntax } }
  // else, create the RPN and optimize program:
  RPN_createPrograms(parsedScryFormula)
  if (useOptimization) {
    parsedScryFormula.program_OPT = RPN_optimize(parsedScryFormula.program_RPN)
  } else {
    parsedScryFormula.program_OPT = parsedScryFormula.program_RPN
  }
  return { parsedScryFormula, isBadFormulaSyntax }
}



export const findShortestCircularReferencePath = (cirDepColKeys: number[],
  inputArgsArr: number[][], isDepColumnArr: boolean[]): number[] => {

  // This is NOT the Edsger Dijkstra shortest path algorithm.
  // This is a simpler approach.  
  // Dijkstra 'trims' the possible alternatives by marking visited nodes.  
  // Hence, his approach would be much faster for large networks (100+ nodes?).
  // Instead, simpler (brute force) approach below:
  //    Enumerate all paths of length 2. Exit for first path where first node === last node (never the case for length 2!)
  //    Enumerate all paths of length 3. Exit for first path where first node === last node
  //    Enumerate all paths of length 4. Exit for first path where first node === last node
  //    . . .
  // The 'paths array' will grow in length exponentially each time we increase
  // the enumerated path lengths ( 1,2,3, ... )

  // In the example below, you can think either 'network node', or 'depColKey'  ( colKey === network node )
  // For example, if we have 4 unresolved depCols (labeled A,B,C,D) paths Array is initialized as:
  // 1)  [   [nodeA], [nodeB], [nodeC], [nodeD] ]
  // 2) What are all paths of length '2'?  Doesn't matter whether we walk the nodes based
  //     on the inputs to above nodes, for the outputs (those colKeys that will subsequently used results of A,B,C,D)
  //     But it is easier to walk 'backwards' using each node's inputs array.
  //     We keep walking backwards, increasing the path length, by using each nodes inputs.
  //     Eventually, one (or more) paths will circle around to nodeA, nodeB, ...
  // 3) So paths of length 2 are:
  //    [
  //      [nodeA, inputA1],  [nodeA, inputA2],
  //      [nodeB, inputB1],  [nodeB, inputB2],  [nodeB, inputB3],
  //      [nodeC, inputC1],
  //      [nodeD, inputD1],
  //    ]
  // 4) So paths of length 3 are:
  //    [
  //      [nodeA, inputA1, inputA1:1],  [nodeA, inputA1, inputA1:2], [nodeA, inputA1, inputA1:3],
  //      [nodeA, inputA2, inputA2:1],  [nodeA, inputA2, inputA2:2],
  //      [nodeB, inputB1, inputB1:1],
  //      ...
  //    ]
  //
  // 5 ) Continue adding all permutations of paths until the first colKey in path === last colKey in path.
  let paths: Array<Array<number>> = cirDepColKeys.map(key => [key])  // Step '1)' above.
  const maxPathLength = cirDepColKeys.length   // Prevents an infinite loop while debugging.
  let shortestPath: Array<number> = []
  let loopCounter = 1
  while (shortestPath.length === 0 && loopCounter <= maxPathLength) {
    const nextPaths = []
    for (const path of paths) {
      if (shortestPath.length > 0) break
      const lastKey = lastVal(path)
      const nextInputs = inputArgsArr[lastKey]
      // Next loop: For each current path of length 'n',
      // we are going to create at set of new paths of length 'n+1'
      // The permutations will use the nextInputs (dependent cols only)
      // for the lastKey in this path.
      for (const nextInput of nextInputs) {
        if (!isDepColumnArr[nextInput]) { continue }
        const newPath = path.slice()
        newPath.push(nextInput)
        if (newPath[0] === nextInput) {
          shortestPath = newPath
          break
        } else {
          nextPaths.push(newPath)
        }
      }
    }
    paths = nextPaths
    loopCounter++
  }
  shortestPath.pop()   // Remove the linking colKey at end of path.  -- same as the 1st colKey in array.
  return shortestPath
}

export const createCircularDependencyErrorMessage = (colKey: number, shortestPath: Array<number>,
  colTitleArr: Array<string>): string => {
  // Rotate the shortest path so current colKey is first
  const thisPath = shortestPath.slice()
  while (thisPath[0] !== colKey) {
    const temp = thisPath.shift()
    if (temp !== undefined) {
      thisPath.push(temp)
    }
  }
  const thinSpace = constants.thinSpaceChar
  const thisColName = colTitleArr[colKey]
  let text = `'${thisColName}', uses itself in its calculation: (Circular Dependency).<br>`
  for (const pathKey of thisPath) {
    text += `${thinSpace}'${colTitleArr[pathKey]}'\u2192${thinSpace}uses${thinSpace}`
  }
  text += `${thinSpace}'${colTitleArr[thisPath[0]]}'`
  return text;
}



export const errorCheckColNames = (columns: Column[]): { errorID: string, errorType: string }[] => {
  const errors = [] // Return an array of errorCodes, usually '' meaning no error.
  type NameToColKey = {
    // key is column name, value is column key
    [key: string]: number
  }
  const priorNames: NameToColKey = {}
  for (const [colKey, thisCol] of columns.entries()) {
    errors[colKey] = { errorID: '', errorType: '' }   // assumption
    if (thisCol.isDeleted) continue
    let name = thisCol.colTitle.trim()
    // remove potential spaces prior to the open parens in functions:  'abs  ('  =>   'abs('
    name = name.replace(/ *\(/, '(')
    // Missing or Canonical Name Error
    if (name === '') {
      errors[colKey] = { errorType: 'missing', errorID: 'Missing column name.' }
      continue
    }
    if (name.includes(constants.COL_KEY_CANONICAL_FORM)) {
      errors[colKey] = { errorType: 'canonicalForm', errorID: `Column name can not include "${constants.COL_KEY_CANONICAL_FORM}"` }
      continue
    }
    if (name === '_') {
      errors[colKey] = { errorType: 'underscore', errorID: `Column name can not be the underscore character.` }
      continue
    }
    // Name is a reserved keywords, operator, or function name.
    name = name.toLowerCase()
    if (reservedColNames.indexOf(name) >= 0) {
      switch (true) {
        case reservedKeywords.indexOf(name) >= 0:
        case reservedIllegalPython.indexOf(name) >= 0:
          errors[colKey] = { errorType: 'reserved', errorID: `Python keyword '${name}' is illegal column name.` }
          break
        case reservedOperators.indexOf(name) >= 0:
        case reservedIllegalOperators.indexOf(name) >= 0:
          errors[colKey] = { errorType: 'reserved', errorID: `'${name}' operator is an illegal column name.` }
          break
        case reservedFuncNames.indexOf(name) >= 0:
          errors[colKey] = { errorType: 'reserved', errorID: `Python func '${name}' is illegal column name.` }
          break
        default:
          errors[colKey] = { errorType: 'reserved', errorID: `'${name}' is an illegal column name.` }
      }
    }
    // Name is a valid number; but illegal as a colName !
    const result = isScryNumber(name, 1)  // second arg says include any number longer than 1 char.
    if (result.numberType !== 'DATA_ERR') {   // Not a DATA_ERR means a MISSING_VALUE or some type of valid number.
      errors[colKey] = { errorType: 'number', errorID: `Column name can not be a number.` }
      continue
    }
    // Redundant name testing is case insentive; no leading/trailing spaces; one space between words.
    let testName = thisCol.colTitle.toLowerCase()
    testName = testName.trim()
    testName = testName.replace(/ +/, ' ')
    const priorColKey = priorNames[testName]
    if (priorColKey !== undefined) {
      errors[colKey] = { errorType: 'redundant', errorID: 'Redundant column name.' }
      errors[priorColKey] = { errorType: 'redundant', errorID: 'Redundant column name.' }
    } else {
      priorNames[testName] = colKey   // add new name to our test suite
    }
  }

  return errors
}



export const colDataErrorCheck_memoizedFunc = (paramsObj: ColErrorParams, refObj: ColErrorRefs, otherArgsObj: ColErrorOther): ColErrorResult => {
  const { internalDataType } = paramsObj
  const { columnData } = refObj
  const erroneousCells: ErrorRows = {}
  const missingCells: ErrorRows = {}
  const numberCache = new Map<string, { numberType: string }>()
  for (const [rowKey, cellValue] of columnData.entries()) {
    switch (internalDataType) {
      case 'number':
        if (cellValue === '') { missingCells[rowKey] = '' }
        else {
          let result = numberCache.get(cellValue)
          if (!result) {
            // Not yet been parsed?  Then parse it now.
            // We don't require all the number error checking and type identification
            // built into the function isScryNumber.  Our number parser can first
            // ask the obvious question isNaN().  We give up some information about
            // the type of number, but the function is much faster.  We use the
            // default (slower) version of isScryNumber for parsing user inputs,
            // parsing formulas, etc.
            //
            // Format: isScryNumber( (cellValue:string, lineNum=0, minLen=0, fullCheck=true)
            // You can see from the function args, that isScryNumber() is intended for
            // the most general case of formula parsing, and provide useful error messages
            // for syntax that is 'close' to our accepted number formats.
            result = isScryNumber(cellValue, 0, 0, false)
            numberCache.set(cellValue, result)
          }
          if (result.numberType === 'DATA_ERR') {
            erroneousCells[rowKey] = ''
          }
        }
        break
      case 'hyperlink': {
        if (cellValue === '') { break }
        const result = isScryHyperlink_fromTableValue(cellValue)
        if (result.errorMsg !== '') { erroneousCells[rowKey] = '' }
        break
      }
      default:
        // Do nothing for other data types
        break
    }
  }
  return { erroneousCells, missingCells }
}



export const getInputArgColKeysAndErrorCheckInputs = (scryFormula:ScryFormula, colKey:number,  derivedColAttributesArray:DerivedColAttributes[] ) : 
                      { inputArgColKeys:number[], isBadFormulaColRef: boolean, errorID: string, highlightArray: HighlightArray } => {
  // scryFormula MAY arrive at this function with priorInputArgColKeys.
  // Clear them.
  const inputArgColKeys: number[] = []
  // Default return values when there are no errors:
  let errorID : string = ''
  let highlightArray : HighlightArray = []
  let isBadFormulaColRef = false

  // We check ALL referenced input columns.
  // Any one could set errorID.
  // If multiple col input errors, the last input arg with an error is returned.
  // Doesn't really matter, just easier to code.
  for (const [lineNum, thisLine] of scryFormula.formulaSubStrings.entries()) {
    for (const thisSubString of thisLine) {
      const { name, value } = thisSubString
      const inputColKey = Number(value)
      if (scryFormula && name === 'colName' && inputArgColKeys.indexOf(inputColKey) === -1) {
        inputArgColKeys.push(inputColKey)
        // Note the elseIf construction determines the priority when there are multiple errors.
        // THESE ARE NOT ERRORS ON THE CURRENT COLUMN!
        // THEY ARE ERRORS ON THE REFERENCE TO A BAD INPUT COLUMN.
        if (derivedColAttributesArray[inputColKey].isDeleted) {
          const title = derivedColAttributesArray[inputColKey].colTitle
          errorID = `Formula references a deleted column ' ${title} '.<br>Select a new column.`
          highlightArray = [{ lineNum, start: thisSubString.start, end: thisSubString.end }]
          isBadFormulaColRef = true
        }
        else if (derivedColAttributesArray[inputColKey].colTitle === '') {
          errorID = `Formula references a column with a missing name.<br>Give all columns a name.`
          highlightArray = [{ lineNum, start: thisSubString.start, end: thisSubString.end }]
          isBadFormulaColRef = true
        }
        else if (derivedColAttributesArray[inputColKey].isBadColNameBecauseRedundant) {
          const colTitle = derivedColAttributesArray[inputColKey].colTitle
          errorID = `Formula references duplicate column name ' ${colTitle} '.<br>Rename column or select a new column.`
          highlightArray = [{ lineNum, start: thisSubString.start, end: thisSubString.end }]
          isBadFormulaColRef = true
        }
        else if (derivedColAttributesArray[inputColKey].isBadColName) {
          const colTitle = derivedColAttributesArray[inputColKey].colTitle
          errorID = `Formula references illegal column name ' ${colTitle} '.<br>Rename column or select a new column.`
          highlightArray = [{ lineNum, start: thisSubString.start, end: thisSubString.end }]
          isBadFormulaColRef = true
        }
      }
    }
  }
  return {inputArgColKeys, errorID, highlightArray, isBadFormulaColRef}
}


export const convertToUserColNames = (formulaSubStrings: SubStringInfo[][], paramsObj: BasicFormulaParams): { formulaStrings: string[], formulaSubStrings: SubStringInfo[][] } => {
    const newSubStrings: SubStringInfo[][] = []
    const newStrings: string[] = []
    const { isBadColNameArr, colTitleArr } = paramsObj
    for (const [lineNum, thisLine] of formulaSubStrings.entries()) {
      newSubStrings[lineNum] = []
      let priorEndPosition = 0
      let newStrg = ''
      for (const thisSubString of thisLine) {
        let newText = thisSubString.text.slice()
        if (thisSubString.name === 'colName') {
          const colKey = Number(thisSubString.value)
          const isBadColName = isBadColNameArr[colKey]
          if (!isBadColName) {
            newText = colTitleArr[colKey]
          }
        }
        const end = priorEndPosition + newText.length
        newSubStrings[lineNum].push({
          name: thisSubString.name,
          text: newText,
          value: thisSubString.value,
          start: priorEndPosition,
          end
        })
        newStrg += newText
        priorEndPosition = end
      }
      newStrings.push(newStrg)
    }
    return { formulaStrings: newStrings, formulaSubStrings: newSubStrings }
}



export const convertToTableidColNames = (formulaSubStrings: SubStringInfo[][]): { formulaStrings: string[], formulaSubStrings: SubStringInfo[][] } => {
    const newSubStrings: SubStringInfo[][] = []
    const newStrings: string[] = []

    for (const [lineNum, thisLine] of formulaSubStrings.entries()) {
      newSubStrings[lineNum] = []
      let priorEndPosition = 0
      let newStrg = ''

      for (const thisSubString of thisLine) {
        let text
        if (thisSubString.name === 'colName') {
          // In canonical resource format, every substring followed by ' '. (space)
          text = constants.COL_KEY_CANONICAL_FORM + String(thisSubString.value) + '_ '
        } else {
          text = thisSubString.text.slice()
        }
        newStrg += text
        const end = newStrg.length
        newSubStrings[lineNum].push(
          {
            name: 'colName',
            text,
            value: thisSubString.value,
            start: priorEndPosition,
            end: newStrg.length
          })
        priorEndPosition = end
      }
      newStrings.push(newStrg)
    }
    return { formulaStrings: newStrings, formulaSubStrings: newSubStrings }
}




export const createTableidSpacedStrings = (scryFormula: ScryFormula): { formulaStrings: string[], formulaSubStrings: SubStringInfo[][] } => {
    // Rules for the table spacing format.
    //  1 - One space AFTER each token except: 'uniSub', indent, comment, endExp, emptySpace
    //  2 - Internal 'extra' spaces removed from constExp, colNames
    //  3 - No changes to spacing inside comments.  Keep what the user wrote.
    //  4 - Indentation set to INDENTATION_LENGTH spaces per level.
    //  5 - No space between consecutive parens operators )e.g. '((' or '))'

    // This function is not useful if the input formula isBadFormulaSyntax
    // Check for this BEFORE calling the function.
    const newSubStrings: SubStringInfo[][] = []
    const newStrings: string[] = []
    let newLineNum = -1
    for (const [fLineNum, thisLine] of scryFormula.formulaSubStrings.entries()) {
      if (scryFormula.isBlankLine[fLineNum]) { continue }
      newLineNum++   // May differ from formula lineNumber because we skip blank lines.
      newSubStrings[newLineNum] = []
      let newStrg = ''
      let priorMatch: SubStringInfo = { name: 'None', text: '', value: 0, start: 0, end: 0 }
      for (const formulaSubString of thisLine) {
        // Each canonicalSubstring starts as a copy of the formulaSubString.
        // Except: start/end character positions will no longer will align to the input text -- they are zero'ed.
        // Except: spacing inside the text will be standardized based on canonical rules for spaces.
        const newSS = {
          name: formulaSubString.name,
          text: '',  // reset to use 'standard' spacing rules (below)
          value: formulaSubString.value,
          start: 0,
          end: 0
        }

        let name: string = newSS.name
        // We can treat each set of func, op_, and flow subStrings by their respective class.
        if (name.slice(0, 4) === 'func') { name = 'func' }
        if (name.slice(0, 3) === 'op_') { name = 'op_' }
        if (name.slice(0, 4) === 'flow') { name = 'flow' }

        switch (name) {
          case 'endExp':
          case 'endReturn':
          case 'emptySpace': newSS.text = ''; break
          case 'indent': {
            const value = Number(newSS.value)
            // if parsing never got to creation of canonical form, then there was an error
            // and we don't have an accounting of the indentation level.  In this case
            // indent.value will be its intial default value of -1.
            // Otherwise indent.value corresponds to the 'level' of indentation.
            const numSpaces = (value === -1) ? newSS.text.length : value * constants.FORMULA_INDENT_LENGTH
            newSS.text = (' ').repeat(numSpaces)
            break
          }
          case 'uniSub': newSS.text = '-'; break   // NO SPACE!
          case 'comment': newSS.text = formulaSubString.text.trimRight(); break
          case 'constExp':  // no internal spaces, no multiply sign, no + before exp, and  E -> e
            newSS.text = formulaSubString.text.replace(/ /g, '').replace('E', 'e').replace('+', '') + ' '; break

          case 'unmatched':
          case 'constFlt':
          case 'constB60':
          case 'constB60B60':
          case 'constInt': {
            let temp = formulaSubString.text.trim()
            if (temp.slice(-1) === '.') { temp = temp.slice(0, -1) }
            newSS.text = temp + ' '
            break
          }
          case 'varName':
          case 'setVarName': newSS.text = formulaSubString.value + ' '; break

          case 'constTrue': newSS.text = 'True '; break
          case 'constFalse': newSS.text = 'False '; break
          case 'constpi': newSS.text = 'pi '; break
          case 'func': newSS.text = newSS.name.slice(4) + '( '; break
          case 'flow': newSS.text = newSS.name.slice(4) + ' '; break
          case 'op_': newSS.text = newSS.name.slice(3) + ' '; break
          case 'None': newSS.text = 'None '; break
          case 'colName':
            newSS.text = formulaSubString.text.trim()
            // Replace multiple spaces between colName words with a single space.
            newSS.text = newSS.text.replace(/ +/g, ' ') + ' '
            break

          default:
            invariant(false, `Unrecognized opCode ${name}`)
        }
        // Remove the prior space between adjacent paren
        // TODO: Figure out what John was trying to accomplish with this code
        // if ( priorMatch.name === 'op_(' && name === 'op_(' ) {
        //   priorMatch.text = priorMatch.text[0]
        //   priorMatch.end--
        // }
        // if ( priorMatch.name === 'op_)' && name === 'op_)' ) {
        //   priorMatch.text = priorMatch.text[0]
        //   priorMatch.end--
        // }
        newSS.start = priorMatch.end
        newSS.end = newSS.start + newSS.text.length
        newStrg += newSS.text
        newSubStrings[newLineNum].push(newSS)
        if (name !== 'emptySpace') { priorMatch = formulaSubString }
      } // Next subString
      newStrings[newLineNum] = newStrg
    }  // next formula line
    // Add two blank lines.
    // Just as a convenience to our users for cursor placement.
    // These 'blank lines' will NOT show up in pretty printed formats.
    // Only the formula editor.
    newSubStrings.push([{ name: 'indent', text: '', start: 0, end: 0, value: 0 }])
    newSubStrings.push([{ name: 'indent', text: '', start: 0, end: 0, value: 0 }])
    newStrings.push('')
    newStrings.push('')
    return { formulaStrings: newStrings, formulaSubStrings: newSubStrings }
}



export const getErroneousRowNames2_memoizedFunc = (paramsObj: RowNameErrorParams, refsObj: RowNameErrorRefs,
    _: RowNameErrorOther): RowNameErrorResult => {
    /*
    Timing data -- Using an Object vrs Map (100 repeats)
                                                              Object      Map
    MarathonData - RowKeys are first/last name strings:     2330 msec    749 msec   ~3x faster
    100K Random Values - RowKeys are integers from 1-100K:   356 msec    700 msec   ~2x slower

    Perhaps the Obj using integer attributes is internally treated as an array ??
    I will use the Map, because most rowKeys will be arbitrary strings, and also
    because Map is better choice for reducing the worse-case corners.
    */
    const { keyColumns, numRows } = paramsObj
    const numCols = keyColumns.length
    const missingRowNames: ErrorRows = {}
    const duplicateRowNames: ErrorRows = {}
    // Case of NO KeyColumns defined by user.  Then we have no missing or erroneous rowNames.
    // We have an error, but its not related to missing or duplicate cell values.
    if (numCols === 0) {
      return { missingRowNames, duplicateRowNames, doesKeyColumnExist: false }
    }
    // Errors to check:
    // 1) Missing rowName -- If ALL isKey values are empty, then illegal rowName.
    //       However, for any single isKey column, empty values are allowed.
    //       For example isKey columns: '1st Name', 'MiddleName', 'LastName'
    //       may have missing middle names and this is considered legal.
    // 2) Duplicate rowName -- The concatenation of all isKey values must be unique across all rows.
    //       We will allow hyperlinks as keyCols.  And allow number columns, where we the internal
    //       string format is used. (number value may or may not even be legal; We are only
    //       testing for uniqueness here.)

    const namesMap = new Map() // Temp local object we use to test uniqueness.
    for (let rowKey = 0; rowKey < numRows; rowKey++) {
      let rowName = ''
      for (const colKey of keyColumns) { // Build the concatenated rowName:
        rowName += refsObj[colKey][rowKey]
      }
      // 'Missing' rowName testing.  Higher precedence than duplicate testing.
      if (rowName === '') { missingRowNames[rowKey] = '' }
      else { // Duplicate rowName testing.
        if (!namesMap.has(rowName)) { // Case: 1st occurance of this rowName
          namesMap.set(rowName, rowKey)
        }
        else { // Case: duplicate occurance of this rowName
          const priorMatchingRowKey = namesMap.get(rowName)
          duplicateRowNames[priorMatchingRowKey] = ''  // 2 rowKeys, BOTH marked as duplicate!
          duplicateRowNames[rowKey] = ''
        }
      }
    }
    return { duplicateRowNames, missingRowNames, doesKeyColumnExist: true }
}





/* SOME HELPER FUNCTIONS FOR PLACING NEW SCROLLTOP IN CASE OF: Change in rowFiltering

    When the number of filtered rows or the mix of filtered rows changes,
    then where should we position the table's scrollTop?

    Option #1 : Like sort function, we set the scrollTop to zero.
    - Advantage: Easy rule.
    - Disadvantage: If I'm looking at a table and want to see some specific
      row(s) added or removed, I'd like to see 'nearly' the same group of
      rows displayed both before and after the filtering operation.  I'd
      rather not be forced to scroll to see whether my desired action resulted
      in the desired result.

    Option #2: Set scrollTop so the 'same' rowIndex is at the top of the
    visible rows.  NOT necessarily the same scrollTop!
    Or in the case where top visible rowIndex was deleted, use the 'closest'
    available rowIndex.  Specifically we minimize: abs(priorTopRowIndex - closestTopRowIndex)
    - Advantage: User gets prompt visual feedback, without having to scroll to
      refind thier prior place.
    - Disadvantage:  We need to calculate the 'best' new scrollTop AFTER fitlering.
      We have the information to calculate this value inside the tableComputedData.
      But it is up to the rendering code (TableParent.js) to choose to dispatch
      any desired change in scrollTop.

    I choose option #2.  We add two additional params to the tableComputedData.
    We only care about these params for the Table, hence the filtering code
    for plotXyComputedData series does not have these equivalents.

*/


// The next function finds the sequential rowIndex that corresponds to the rule:
// 1 - The previously first visible row, if not deleted, remains the first visible row.
// 2 - All previously visible rows, if not deleted, remain visible -- although
//     the process of adding new rows may push some or all of them out of view
//     at the bottom of the visible portion of the table.

// Or one can just understand this from the algorithm.
//     The first diplayed row is the prior displayed row, or is deleted, the
//     next sequentially undeleted row.

// There is no equivalent rule used for column sorting.  In that case we set
// the first visible row to '0'   ( scrollTop set to 0 )

export const findNextUnfilteredRowIndex = (newRowOrder: number[], priorRowOrder: number[], priorTopRowIndex: number): number => {
    const newRowIndices = new Map<number, number>()
    for (const [index, key] of newRowOrder.entries()) {
      newRowIndices.set(key, index)
    }
    let i = priorTopRowIndex
    let foundIndex = -1
    let direction = 1
    while (foundIndex === -1 && i >= 0 && i < priorRowOrder.length) {
      const priorRowKey = priorRowOrder[i]
      const newRowIndex = newRowIndices.get(priorRowKey)
      if (newRowIndex !== undefined) {
        foundIndex = newRowIndex
      } else {
        i += direction
        if (i === priorRowOrder.length) {
          direction = -1
          i = priorTopRowIndex - 1
        }
      }
    }
    return foundIndex !== -1 ? foundIndex : -1
}



export const calcNewScrollTop = (newRowOrder: number[], priorRowOrder: number[],
    h: TableHeightObj, currentScrollTop: number): number => {
    // NOTE: If you see this function oscillating between two topRow indices, read not below.
    const priorTopRowIndex = Math.trunc(currentScrollTop / h.rowHeight)
    if (h.dataAllocated >= h.dataRequired) {
      // case of no vertical scrollBar
      return 0
    } else {
      const nextLargerRowIndex = findNextUnfilteredRowIndex(newRowOrder, priorRowOrder, priorTopRowIndex)
      // Case of 'no larger index found'
      if (nextLargerRowIndex === -1) { return 0 }
      let newScrollTop = nextLargerRowIndex * h.rowHeight
      const maxScrollAllowed = h.dataRequired - h.dataAllocated
      newScrollTop = Math.min(newScrollTop, maxScrollAllowed)
      return newScrollTop
    }
}



export const createStatsBarLayout_memoizedFunc = (paramsObj: StatsBarLayoutParams, refsObj: StatsBarLayoutRefs): StatsBarLayoutResult => {

  const { colTitle, colDataType, formatRule, fontSize, canEdit, formattingObj } = paramsObj
  let statsType = (colDataType.slice(0, 6) === 'number') ? 'number' : colDataType
  if (statsType === 'number' && formatRule === 'boolTrueFalse') { statsType = 'boolTrueFalse' }
  const userFormattingObj = { ...formattingObj, allowsPrefixSuffix: false }

  const SPACER = 14
  const fontSizeStrg = `${fontSize}px`
  const titleWidth = SPACER + measureText(`"${colTitle}"`, fontSizeStrg, 'bold')

  const commasOnlyFormattingObj = getCommasOnlyFormattingObj()
  // Replace next line with getNoExponentFormattingObj( )
  // Then overwrite for this particular use model.
  const percentFormat3 = getFormattingObj('defaultEng', { suffix: '%', precisionMode: 'min', precisionMin: 3 })

  const { columnStats } = refsObj
  const groupArr: StatsGroup[] = []  // A group is set 4 values:  1st line name:val & 2nd line name:val
  let label1, label2, value1, value2, labelWidth, valueWidth
  // If stats object exists, create the stats formating information.

  // These 2 stats shared by most data types:
  const addCellCountStats = () : number  => {
    if (!columnStats) return 0
    label1 = 'Valid Cells:'
    label2 = (canEdit) ? 'Empty / Errors:' : 'Empty Cells:'
    // The commasOnlyFormattingObj will never revert to exponential notation in following usage.
    // Hence, treating value1 and value 2 as simple strings AND as a reactNode object works fine.
    const s = columnStats  // Generic type works because all following properties are
    // valid for all stat dataTypes.
    value1 = numberFormat(String(s.validCount), commasOnlyFormattingObj, 'html')
    const value1StrgForLengthCalc = numberFormat(String(s.validCount), commasOnlyFormattingObj, 'measureOnlyString') as string
    const missingCountStrg = numberFormat(String(s.missingCount), commasOnlyFormattingObj, 'html')
    const erroneousCountStrg = numberFormat(String(s.erroneousCount), commasOnlyFormattingObj, 'html')
    const missingAndErroneousCount = numberFormat(String(s.erroneousCount + s.missingCount), commasOnlyFormattingObj, 'html')
    value2 = (canEdit) ? `${missingCountStrg} / ${erroneousCountStrg}` : missingAndErroneousCount
    //let value2StrgForLengthCalc = numberFormat(String(s.validCount), commasOnlyFormattingObj, 'measureOnlyString') as string
    //colWidths.push( SPACER + Math.max( measureText(label1, fontSizeStrg), measureText(label2, fontSizeStrg)))
    //colWidths.push( SPACER + Math.max( measureText(value1, fontSizeStrg), measureText(value2, fontSizeStrg)))
    labelWidth = SPACER + Math.max(measureText(label1, fontSizeStrg), measureText(label2, fontSizeStrg))
    valueWidth = SPACER + Math.max(measureText(value1StrgForLengthCalc, fontSizeStrg), measureText(value2 as string, fontSizeStrg))
    groupArr.push({
      label1, label2, value1, value2,
      labelWidth, valueWidth,
      groupWidth: labelWidth + valueWidth
    })
    return s.validCount
  }

  if (columnStats) {
    switch (statsType) {

      case 'number': {
        const sN = columnStats as StatsNumbers
        const validCount = addCellCountStats()
        if ( validCount === 0 ) { break }  // No stats if no valid cells.

        const formattedStats = getFormattedNumberStats(sN, userFormattingObj)
        label1 = 'Min:'
        label2 = 'Max:'
        const { minVal, maxVal, minStrg, maxStrg } = formattedStats
        labelWidth = SPACER + Math.max(measureText(label1, fontSizeStrg), measureText(label2, fontSizeStrg))
        valueWidth = SPACER + Math.max(measureText(minStrg, fontSizeStrg), measureText(maxStrg, fontSizeStrg))
        groupArr.push({
          label1, label2,
          value1: minVal,
          value2: maxVal,
          labelWidth, valueWidth,
          groupWidth: labelWidth + valueWidth
        })

        label1 = 'Mean:'
        label2 = 'StdDev:'
        const { meanVal, stddVal, meanStrg, stddStrg } = formattedStats
        labelWidth = SPACER + Math.max(measureText(label1, fontSizeStrg), measureText(label2, fontSizeStrg))
        valueWidth = SPACER + Math.max(measureText(meanStrg, fontSizeStrg), measureText(stddStrg, fontSizeStrg))
        groupArr.push({
          label1, label2,
          value1: meanVal,
          value2: stddVal,
          labelWidth, valueWidth,
          groupWidth: labelWidth + valueWidth
        })

        label1 = 'Skewness:'
        label2 = 'Ex. Kurtosis:';
        const { skewVal, kurtVal, skewStrg, kurtStrg } = formattedStats
        labelWidth = SPACER + Math.max(measureText(label1, fontSizeStrg), measureText(label2, fontSizeStrg))
        valueWidth = SPACER + Math.max(measureText(skewStrg, fontSizeStrg), measureText(kurtStrg, fontSizeStrg))
        groupArr.push({
          label1, label2,
          value1: skewVal,
          value2: kurtVal,
          labelWidth, valueWidth,
          groupWidth: labelWidth + valueWidth
        })
        break
      }

      case 'boolTrueFalse': {
        const sB = columnStats as StatsBoolTrueFalse
        const validCount = addCellCountStats()
        if ( validCount === 0 ) { break }  // No stats if no valid cells.

        label1 = 'True:'
        label2 = 'False:'
        const value1a = numberFormat(String(sB.trueCount), commasOnlyFormattingObj, 'html')
        const value2a = numberFormat(String(sB.falseCount), commasOnlyFormattingObj, 'html')
        const value1b = numberFormat(String(sB.trueCount / sB.validCount * 100), percentFormat3, 'html')
        const value2b = numberFormat(String(sB.falseCount / sB.validCount * 100), percentFormat3, 'html')
        value1 = `${value1a} (${value1b})`
        value2 = `${value2a} (${value2b})`
        labelWidth = SPACER + Math.max(measureText(label1, fontSizeStrg), measureText(label2, fontSizeStrg))
        valueWidth = SPACER + Math.max(measureText(value1, fontSizeStrg), measureText(value2, fontSizeStrg))
        groupArr.push({
          label1, label2, value1, value2,
          labelWidth, valueWidth,
          groupWidth: labelWidth + valueWidth
        })
        break
      }

      case 'hyperlink':
        addCellCountStats()
        break


      case 'string': {
        const sS = columnStats as StatsStrings
        const validCount = addCellCountStats()
        if ( validCount === 0 ) { break }  // No stats if no valid cells.

        // Twenty most frequent strings, given enough strings
        let numStrings = Math.min(sS.freqRankToNameArr.length, 20)
        // Going to ignore Stats for Strings that have a frequency count <= 2
        // They are ordered most frequent to least frequent, so we just stop
        // at index i:
        for (let i = 0; i < numStrings; i++) {
          if (sS.nameToFreqObj[sS.freqRankToNameArr[i]] <= 2) {
            numStrings = i
            break
          }
        }

        const numStringPairs = Math.ceil(numStrings / 2)
        for (let i = 0; i < numStringPairs; i++) {
          label1 = sS.freqRankToNameArr[2 * i]
          const freq = sS.nameToFreqObj[label1]
          label1 = `"${label1}":`
          const value1a = numberFormat(String(freq), commasOnlyFormattingObj, 'html')
          const value1b = numberFormat(String(freq / sS.validCount * 100), percentFormat3, 'html')
          value1 = `${value1a} (${value1b})`

          if (2 * i + 1 < numStrings) {
            // Second string in this pair is available.
            label2 = sS.freqRankToNameArr[2 * i + 1]
            const freq = sS.nameToFreqObj[label2]
            label2 = `"${label2}":`
            const value2a = numberFormat(String(freq), commasOnlyFormattingObj, 'html')
            const value2b = numberFormat(String(freq / sS.validCount * 100), percentFormat3, 'html')
            value2 = `${value2a} (${value2b})`
          } else {
            label2 = ''
            value2 = ''
          }
          labelWidth = SPACER + Math.max(measureText(label1, fontSizeStrg), measureText(label2, fontSizeStrg))
          valueWidth = SPACER + Math.max(measureText(value1, fontSizeStrg), measureText(value2, fontSizeStrg))
          groupArr.push({
            label1, label2, value1, value2,
            labelWidth, valueWidth,
            groupWidth: labelWidth + valueWidth
          })

        }
        break
      }
      //case 'datetime':
      //  return 4 //count, min, median, max
      //case 'geopoint':
      //  return 6 // count, center, northmost, southmost, minLong, maxLong
      default:
        break

    }

  }

  const groupLeftArr = []
  let cumWidth = titleWidth
  for (const [i, thisGroup] of groupArr.entries()) {
    groupLeftArr[i] = cumWidth
    cumWidth += thisGroup.groupWidth
  }
  groupLeftArr.push(cumWidth)
  // This is them memoized Result Obj:
  return {
    statsBarLayout: { titleWidth, groupArr, groupLeftArr }
  }
}


const getFormattedNumberStats = (sN: StatsNumbers, meanFormattingObj: FormattingObj): {
    minVal: ReactNode, maxVal: ReactNode, meanVal: ReactNode, stddVal: ReactNode, skewVal: ReactNode, kurtVal: ReactNode,
    minStrg: string, maxStrg: string,  meanStrg: string, stddStrg: string, skewStrg: string, kurtStrg: string } => {

    const MinSignificantFigures = meanFormattingObj.precMode === 'fixed' ? 0 : 4
    const { mean, stdDevAdj: stdd, skewnessAdj: skew, excessKurtosisAdj: kurt, min, max } = sN
    // Mean and StdDev have the same units as the data.  Hence, if the number formation
    // if B60 or B6060, then we should use this 'time' format for mean and stdDev.
    // Skewness and kurtosis have no units and represent a characteristic of shape.
    // They should always use Eng Format.
    const { precMode, precisionMin, precisionFixed } = meanFormattingObj
    const meanPrecision = (precMode === 'fixed') ? precisionFixed : precisionMin  // modes 'std' and 'min' use precisionMin # of digits.
    const statsPrecision = Math.max(meanPrecision, MinSignificantFigures)
    const overRides = {
      precision: statsPrecision,
      precisionMin: statsPrecision,
      precisionFixed: statsPrecision,
    }
    const skewKurtFormattingObj = getFormattingObj('defaultEng', overRides)
    skewKurtFormattingObj.precMode = 'std'  // Pads with zeros so pair of stats always appear same length when rendered.
    const statsFormattingObj = {...meanFormattingObj, ...overRides}
    return {
      minVal : numberFormatReactNode(String(min ), statsFormattingObj),
      maxVal : numberFormatReactNode(String(max ), statsFormattingObj),
      meanVal: numberFormatReactNode(String(mean), statsFormattingObj),
      stddVal: numberFormatReactNode(String(stdd), statsFormattingObj),
      skewVal: numberFormatReactNode(String(skew), skewKurtFormattingObj),
      kurtVal: numberFormatReactNode(String(kurt), skewKurtFormattingObj),
      minStrg:  numberFormat(String(min ), statsFormattingObj, 'measureOnlyString') as string,
      maxStrg:  numberFormat(String(max ), statsFormattingObj, 'measureOnlyString') as string,
      meanStrg: numberFormat(String(mean), statsFormattingObj, 'measureOnlyString') as string,
      stddStrg: numberFormat(String(stdd), statsFormattingObj, 'measureOnlyString') as string,
      skewStrg: numberFormat(String(skew), skewKurtFormattingObj, 'measureOnlyString') as string,
      kurtStrg: numberFormat(String(kurt), skewKurtFormattingObj, 'measureOnlyString') as string,
    }
}





type CompareFunc = (a: string, b: string) => number

export const sortRows = (paramsObj: SortParams, _: SortRefs, otherArgsObj: SortOtherArgs): SortResult => {
    const { rowSortColIds } = paramsObj
    const { internalDataTypes, hideErroneousValues, getTableValue, numRowsUnfiltered } = otherArgsObj
    // Start with and array of all rowKeys, any order will work.
    // Sort re-orders the array 'in-place'.  This array is the return value.
    const sortedRowKeys = list(numRowsUnfiltered - 1)
    const numSortRules = rowSortColIds.length
    // the default initial value for rowSortColIds is []  // No rules. 
    // In this case we leave the rows as 0 to n-1
    if (numSortRules === 0) { return { sortedRowKeys } }
    const colKeyArr = rowSortColIds.map(thisStrgIndex => Math.abs(Number(thisStrgIndex)))

    const compareFuncArr = rowSortColIds.map((thisStrgIndex, i): CompareFunc => {
      const internalDataType = internalDataTypes[i]
      const sortDirectionMultiplier = thisStrgIndex[0] === '-' ? -1 : 1
      switch (internalDataType) {
        case 'hyperlink':
        case 'string':
          return (a: string, b: string) => sortDirectionMultiplier * a.localeCompare(b)
        case 'number':
          // case 'numberSeconds' :
          // case 'numberDegrees' :
          // case 'boolean'       :
          return (a: string, b: string) => sortDirectionMultiplier * (Number(a) - Number(b))
        default:
          throw new TypeError(`Missing internalDataType ${internalDataType} in rowSort switch`)
      }
    })

    // This is the pairwise comparison function.  
    // It is called it recursively, but ONLY if/when we need to break a tie!!  
    // For initial sorting, sortRuleIndex = 0;  ( sort by the primary rule0 )
    // In case of tie, we call function with sortRuleIndex = 1    (if rule0 is a tie, sort by rule1)
    // In case of a second tie, we call with sortRuleIndex = 2    (if rule1 is a tie, sort by rule2)
    // etc, until we break the tie or run out of sortRuleIndices. ( ... )
    const sortingComparisonFunction = (rowKeyA: number, rowKeyB: number, sortRuleIndex: number): number => {
      if (sortRuleIndex === numSortRules) return -1  // we've run out of indices for breaking ties!  -1 retains rowKey order.
      const colKey = colKeyArr[sortRuleIndex]
      const { value: a, isErroneous: aErr } = getTableValue(colKey, rowKeyA, hideErroneousValues)
      const { value: b, isErroneous: bErr } = getTableValue(colKey, rowKeyB, hideErroneousValues)
      // Errors to top - regardless of sortDirection 1, -1 value
      if (aErr && bErr) {
        // If both errors, then we know neither value is '' (empty).
        // So next just consider this a sort of illegal valued cells.
        // Same treatment of 'Pair of legal values cells' (below).
        // Except that since both are errors, they go 'above' the legal valued cells.
        if (a === b) { return sortingComparisonFunction(rowKeyA, rowKeyB, sortRuleIndex + 1) }   // Tie !! call recusively!
        return compareFuncArr[sortRuleIndex](a, b)
      }
      if (aErr) { return -1 }
      if (bErr) { return 1 }
      // Empty cells to bottom - regardless of sortDirection 1, -1 value
      if (a === '' && b === '') { return sortingComparisonFunction(rowKeyA, rowKeyB, sortRuleIndex + 1) }   // Tie !! call recusively!
      if (a === '') { return 1 }
      if (b === '') { return -1 }
      // Pair of legal valued cells.
      // Could be number, string, boolean, hyperlink, ...
      if (a === b) { return sortingComparisonFunction(rowKeyA, rowKeyB, sortRuleIndex + 1) }   // Tie !! call recusively!
      return compareFuncArr[sortRuleIndex](a, b)
    }

    // Intialize the colKey to use for primary sort as rowSortColIds[0]
    // If a tie, we will use the secondary sort key at rowSortColIds[1]
    //  ...  (until we run out of keys)
    const initialSortRuleIndex = 0
    sortedRowKeys.sort((rowKeyA, rowKeyB) => {
      return sortingComparisonFunction(rowKeyA, rowKeyB, initialSortRuleIndex)
    })
    return { sortedRowKeys }
}




