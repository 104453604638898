const joi = require('joi')

const loginAttributes = {
  login: joi.string().allow('')
    .description('email or username to login with.'),
  password: joi.string().allow('')
    .description('Password to test against the stored password hash.'),

  // server side managed data (read only to clients)
  token: joi.string().allow(null)//.meta('readonly')
    .description('The server generated json web token to use on subsequent requests'),
}

module.exports = loginAttributes
