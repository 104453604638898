import type { PlotXyComputedData, SparsePlotPt } from './xy_plotTypes'
import type { PriorMemoizedResult } from '../sharedFunctions/getObjectDiff'

import { getMemoizedResult } from '../sharedFunctions/getObjectDiff'
import { lastVal } from '../sharedFunctions/utils'
import { EPSILON, parseRenderedLayerID } from './plotUtils'

// We want a stable reference, so this return can still be used as memoized result
const emptyArray = Array<SparsePlotPt>()

export type CullPlotPtsResults = {
  seriesRowPlotPts_culled: SparsePlotPt[]
  culledSeriesRangeA: [number, number]
  culledSeriesRangeB: [number, number]
}

const emptyResult: CullPlotPtsResults = {
  seriesRowPlotPts_culled: emptyArray,
  culledSeriesRangeA: [+Infinity, -Infinity],
  culledSeriesRangeB: [+Infinity, -Infinity]
}

export type CullPlotPtsParams = {
  minA: number
  maxA: number
  minB: number
  maxB: number
  isParametric: boolean
  isLine: boolean
  isBconstrained: boolean
  minA_constrained: number
  maxA_constrained: number
  minB_constrained: number
  maxB_constrained: number
}

export type CullPlotPtRefs = {
  seriesRowPlotPts_sortedInA: SparsePlotPt[]
}

export type CullPlotPtOtherArgs = {
}

let memoizedCulledResultsArray = Array<PriorMemoizedResult<CullPlotPtsParams, CullPlotPtRefs, CullPlotPtsResults>>()

export const createCulledPts_SingleSeries_memoizedFunc = (plt: PlotXyComputedData, sKey: number, DEBUG: boolean): CullPlotPtsResults => {

  const seriesRowPlotPts_sortedInA = plt.seriesRowPlotPts_sortedInA[sKey]
  if (seriesRowPlotPts_sortedInA.length === 0) {
    return emptyResult
  }
  // These are single series extents, NOT the range over ALL SERIES.
  const { isMinForced: isMinForcedA, isMaxForced: isMaxForcedA } = plt.basisA
  const { isMinForced: isMinForcedB, isMaxForced: isMaxForcedB } = plt.basisB
  const { min: minA_constrained, max: maxA_constrained } = plt.basisA.rangeCulledAllSeries
  const { min: minB_constrained, max: maxB_constrained } = plt.basisB.rangeCulledAllSeries
  const { minA, maxA, minB, maxB } = plt.seriesAttributesArray[sKey].filteredPtsStats

  // Shortcut when NO CULLING REQUIRED:
  if (!(isMinForcedA || isMaxForcedA || isMinForcedB || isMaxForcedB)) {
    if (DEBUG) { console.log(`  seriesRowPlotPts_culled[${sKey}] = seriesRowPlotPts_sortedInA[${sKey}]`) }
    return {
      seriesRowPlotPts_culled: plt.seriesRowPlotPts_sortedInA[sKey],
      culledSeriesRangeA: [minA, maxA],
      culledSeriesRangeB: [minB, maxB]
    }
  }
  // We need to determine whether the filtered pts (tableRow values) are rendered with a line.
  // This determines whether we need to include one additional tableRow value
  // immediately to the left and right of the visible data.  So the line can
  // extend 'off-the-domain-edge'.
  var isLine = false  // Assumption
  for (const renderedLayerID of plt.renderedLayersArr) {
    const { plottedValue, visTypeUser, sKeyArr } = parseRenderedLayerID(renderedLayerID)
    if (plottedValue === 'tableRow' &&
      sKeyArr.indexOf(sKey) >= 0 &&
      (visTypeUser === 'line' || visTypeUser === 'marksLine')) {
      isLine = true
    }
  }
  const paramsObj: CullPlotPtsParams = {
    minA, maxA, minB, maxB, isLine,
    minA_constrained, maxA_constrained, minB_constrained, maxB_constrained,
    isBconstrained: (isMinForcedB || isMaxForcedB),
    isParametric: (plt.plotColDataType === '3Col')
  }
  const refsObj: CullPlotPtRefs = { seriesRowPlotPts_sortedInA }
  const otherArgsObj: CullPlotPtOtherArgs = {}
  const maxNumMemoized = 2 * plt.seriesOrder.length + 1
  const workerFunc = cullRowPlotPts_SingleSeries_memoizedFunc
  const priorResults = memoizedCulledResultsArray
  var { result, isNewResult, newMemArr } = getMemoizedResult(priorResults, paramsObj, refsObj, otherArgsObj, workerFunc, maxNumMemoized)
  if (isNewResult) {
    memoizedCulledResultsArray = newMemArr // Update memoized array
  }
  if (DEBUG) {
    if (isNewResult) {
      console.log(`  Calc  seriesRowPlotPts_culled[${sKey}]. ` +
        `filtered=${seriesRowPlotPts_sortedInA.length} culled=${result.seriesRowPlotPts_culled.length}`)
    } else {
      console.log(`  Reuse seriesRowPlotPts_culled[${sKey}]. ` +
        `filtered=${seriesRowPlotPts_sortedInA.length} culled=${result.seriesRowPlotPts_culled.length}`)
    }
  }
  return result
}



const cullRowPlotPts_SingleSeries_memoizedFunc = (paramsObj: CullPlotPtsParams, refsObj: CullPlotPtRefs, _: CullPlotPtOtherArgs): CullPlotPtsResults => {
  const { minA, maxA, minB, maxB, isParametric, isLine, isBconstrained,
    minA_constrained, maxA_constrained,
    minB_constrained, maxB_constrained } = paramsObj
  const seriesRowPlotPts_sortedInA = refsObj.seriesRowPlotPts_sortedInA
  if (seriesRowPlotPts_sortedInA.length === 0) {
    return emptyResult
  }

  if (isParametric) {
    // Difficult algorithm to get correct.
    // So instead, I use a conservative algorithm (may retain many of out-of-range pts)
    // ONLY REQUIREMENT: if (series is completely 'out-of-view') then must return empty array.
    if (maxA <= minA_constrained || minA >= maxA_constrained ||
      maxB <= minB_constrained || minB >= maxB_constrained) {
      return emptyResult
    }
    // Otherwise, don't make the effort to cull each pt.  Because difficult/slow in
    // the case of isLine:True.  I assume a parametric plot will have on order
    // of hundreds to thousands of points per series-- but unlikely to exceed many thousand.
    // So I don't do any culling at all.
    return {
      seriesRowPlotPts_culled: seriesRowPlotPts_sortedInA,
      culledSeriesRangeB: [minA, maxA],
      culledSeriesRangeA: [minB, maxB]
    }
  }
  const extendRange = (isLine) ? 1 : 0
  var { culledPts } = cullPlotPts_Aaxis_binarySearch(seriesRowPlotPts_sortedInA,
    minA_constrained, maxA_constrained, extendRange)
  if (isBconstrained) {
    culledPts = cullPlotPts_Baxis_forEachSegment2(culledPts, minB_constrained, maxB_constrained)
  }
  if (culledPts.length === 0) {
    return emptyResult
  }
  var newMinB = +Infinity
  var newMaxB = -Infinity
  for (var i = 0; i < culledPts.length; i++) {
    var val = culledPts[i].B
    newMinB = Math.min(newMinB, val)
    newMaxB = Math.max(newMaxB, val)
  }
  return {
    seriesRowPlotPts_culled: culledPts,
    culledSeriesRangeA: [culledPts[0].A, lastVal(culledPts).A],
    culledSeriesRangeB: [newMinB, newMaxB]
  }
}



// Include all pts connecting to any 'inRange' point.  (keep connecting line segments.)
// But we do NOT include the connecting pts in our determination of new rangeB
const cullPlotPts_Baxis_forEachPoint2 =
  (seriesPts: SparsePlotPt[], lowIn: number, highIn: number): SparsePlotPt[] => {

    //var minB = Infinity
    //var maxB = -Infinity
    //if (seriesPts.length === 0 ) {
    //  return {culledPts:[], minB, maxB }
    //}
    const low = lowIn - Math.abs(lowIn * EPSILON)
    const high = highIn + Math.abs(highIn * EPSILON)
    const culledSeries = seriesPts.filter((thisPt, i) =>
      (thisPt.B <= high && thisPt.B >= low)
    )
    return culledSeries
  }

const cullPlotPts_Baxis_forEachSegment2 =
  (seriesPts: SparsePlotPt[], lowIn: number, highIn: number): SparsePlotPt[] => {

    const low = lowIn - Math.abs(lowIn * EPSILON)
    const high = highIn + Math.abs(highIn * EPSILON)
    // special case of a series with only a single point
    if (seriesPts.length === 1) {
      return cullPlotPts_Baxis_forEachPoint2(seriesPts, lowIn, highIn)
    }

    const lastIndex = seriesPts.length - 1
    const culledSeries = seriesPts.filter((thisPt, i) => {
      // Special treatment 1st pt.
      if (i === 0) {
        return !((seriesPts[0].B > high && seriesPts[1].B > high)
          ||
          (seriesPts[0].B < low && seriesPts[1].B < low))
      }

      // Special treatment last pt.
      if (i === lastIndex) {
        return !((seriesPts[i - 1].B > high && seriesPts[i].B > high)
          ||
          (seriesPts[i - 1].B < low && seriesPts[i].B < low))
      }

      // Full testing for all other points
      if (seriesPts[i - 1].B > high && seriesPts[i].B > high && seriesPts[i + 1].B > high) { return false }
      if (seriesPts[i - 1].B < low && seriesPts[i].B < low && seriesPts[i + 1].B < low) { return false }
      return true
    })
    return culledSeries
  }




const findGreatestPtIndexLessThanValue = (arr: SparsePlotPt[], testValue: number, start: number, end: number): number => {
  let mid = Math.floor((start + end) / 2)
  if (start === mid) {
    return start
  }
  if (arr[mid].A >= testValue) {
    return findGreatestPtIndexLessThanValue(arr, testValue, start, mid)
  } else {
    return findGreatestPtIndexLessThanValue(arr, testValue, mid, end)
  }
}

const findSmallestPtIndexGreaterThanValue = (arr: SparsePlotPt[], testValue: number, start: number, end: number): number => {
  let mid = Math.ceil((start + end) / 2)
  if (end === mid) {
    return end
  }
  if (arr[mid].A <= testValue) {
    return findSmallestPtIndexGreaterThanValue(arr, testValue, mid, end)
  } else {
    return findSmallestPtIndexGreaterThanValue(arr, testValue, start, mid)
  }
}


// Specific to Pts.A value
// Series MUST be sorted by pts.A value PRIOR to calling this func.
export const cullPlotPts_Aaxis_binarySearch = (thisSeries: SparsePlotPt[],
  lowM: number, highP: number, numPaddedPts: number
): {
  culledPts: SparsePlotPt[],
  numCulledFrontEnd: number,
  numCulledBackEnd: number,
} => {

  // numPaddedPts is the number of additional pts 'beyond' culled Range to include in the culledArray.
  // So any potential 'partial' line segment that
  // crosses the domain border will still be drawn.
  // When drawing mode does NOT include lines, numPaddedPts should be zero
  // When drawing mode does include lines, numPaddedPts should be one.
  if (thisSeries.length === 0) {
    return { culledPts: [], numCulledFrontEnd: 0, numCulledBackEnd: 0 }
  }
  const firstValue = thisSeries[0].A
  const lastValue = (lastVal(thisSeries)).A
  if (lastValue < lowM) {
    return { culledPts: [], numCulledFrontEnd: thisSeries.length, numCulledBackEnd: 0 }
  }
  if (firstValue > highP) {
    return { culledPts: [], numCulledFrontEnd: 0, numCulledBackEnd: thisSeries.length }
  }

  if (firstValue >= lowM) {
    var lowOutsideIndex = 0
    var lowInsideIndex = 0
  } else {
    lowOutsideIndex = findGreatestPtIndexLessThanValue(thisSeries, lowM, 0, thisSeries.length) // This is one index 'left' of requested lowM
    lowInsideIndex = lowOutsideIndex + 1
  }
  if (lastValue <= highP) {
    var highInsideIndex = thisSeries.length - 1
    var highOutsideIndex = thisSeries.length - 1
  } else {
    highOutsideIndex = findSmallestPtIndexGreaterThanValue(thisSeries, highP, 0, thisSeries.length) // This is one index 'right' of requested highP
    highInsideIndex = highOutsideIndex - 1
  }
  const lowIndex = (numPaddedPts === 1) ? lowOutsideIndex : lowInsideIndex
  const highIndex = (numPaddedPts === 1) ? highOutsideIndex : highInsideIndex
  const culledPts = thisSeries.slice(lowIndex, highIndex + 1)  // startIndex (inclusive) to endIndex (exclusive)
  //return {culledPlotPts, firstIndex:lowIndex, lastIndex:highIndex}
  return {
    culledPts,
    numCulledFrontEnd: lowIndex,
    numCulledBackEnd: thisSeries.length - 1 - highIndex
  }
}




/*
Here is some efficient code asking whether two line segments intersect.
Very clever!!!  But not currently needed.  See this link:
https://bryceboe.com/2006/10/23/line-segment-intersection-algorithm/

type Point = {x:number, y:number}

// Are these three points oriented in a clockwise or counter-clockwise direction?
const isCCW = ( p1:Point, p2:Point, p3:Point ): boolean => {
  return (p3.y-p1.y)*(p2.x-p1.x) > (p2.y-p1.y)*(p3.x-p1.x)
}

const doesIntersect = ( A:Point, B:Point, C:Point, D:Point ) : boolean => {
  return isCCW(A,C,D) !== isCCW(B,C,D) && isCCW(A,B,C) !== isCCW(A,B,D)
}
*/
