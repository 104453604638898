import { CSSProperties, Component } from 'react'
import NotificationItem from './NotificationItem'
import Constants from './constants'
import type { Notification, Position } from './constants'
import type { GetStylesObject } from './styles'

type Props = {
  position: Position
  notifications: Notification[]
  getStyles: GetStylesObject
  onRemove: (uid: number) => void
  noAnimation: boolean
  allowHTML: boolean
}

class NotificationContainer extends Component<Props> {
  constructor(props: Props) {
    super(props)
    // Fix position if width is overrided
    this._style = props.getStyles.container(props.position)

    if (
      props.getStyles.overrideWidth &&
      (props.position === Constants.positions.tc ||
        props.position === Constants.positions.bc)
    ) {
      this._style.marginLeft = -(props.getStyles.overrideWidth / 2)
    }
  }

  _style: CSSProperties

  render() {
    var notifications

    if (
      [
        Constants.positions.bl,
        Constants.positions.br,
        Constants.positions.bc
      ].indexOf(this.props.position) > -1
    ) {
      this.props.notifications.reverse()
    }

    notifications = this.props.notifications.map((notification) => {
      return (
        <NotificationItem
          ref={ 'notification-' + notification.uid }
          key={ notification.uid }
          notification={ notification }
          getStyles={ this.props.getStyles }
          onRemove={ this.props.onRemove }
          noAnimation={ this.props.noAnimation }
          allowHTML={ this.props.allowHTML }
        />
      )
    })

    return (
      <div
        className={ 'notifications-' + this.props.position }
        style={ this._style }
      >
        {notifications}
      </div>
    )
  }
}

export default NotificationContainer
