import type { DerivedColAttributes,
              TableComputedData  }  from '../computedDataTable/getDefaultTableComputedData'
import type { FpParentState, SetFpParentState}  from './FpParent'
import type { FpChildRenderProps }  from '../appCode/getSbFpConfigMappings'

import {insertColumn, deleteColumn} from '../viewTable/actionHideDeleteCol'
import {useContext, useState}       from 'react'
import {TableComputedDataContext}   from '../viewTableTriple/TtGetComputedData'
import dayjs                        from 'dayjs'
import {thinSpaceChar, nonBreakingSpace} from '../sharedComponents/constants'

const tripleSpaceChar = nonBreakingSpace + nonBreakingSpace + nonBreakingSpace

export type InsertActions = 'new' | 'cloned' | 'restored'

type ColRestoreTableProps = {
    colActionsState: ColActionsState,
    setColActionsState: SetColActionsState,
    fpParentState: FpParentState,
    setFpParentState: SetFpParentState,

}
type ColActionsState = {
    deletedColKeyArr: number[],
    restoreArr: boolean[],
    hover0: boolean,
    hover1: boolean,
    hover2: boolean,
    hover3: boolean,
    isRestoreMenuSelected: boolean,
}
type SetColActionsState = ( _:ColActionsState ) => void


const createInitialState = ( derivedColAttributesArray: Array<DerivedColAttributes> ) 
                                        : { deletedColKeyArr : number[], restoreArr: boolean[] }=> {
      const deletedCols: number[] = []
      const restoreArr: boolean[] = []
      for ( const [colKey, thisCol] of derivedColAttributesArray.entries() ) {
        if ( thisCol.isDeleted ) {
          deletedCols.push(colKey)
          restoreArr[colKey] = false
        }
      }
      const compareFunc = (colKeyA: number, colKeyB: number) => {
        const colADeleteDate = new Date(derivedColAttributesArray[colKeyA].deletedDate)
        const colBDeleteDate = new Date(derivedColAttributesArray[colKeyB].deletedDate)
        return colBDeleteDate.getTime() - colADeleteDate.getTime()
      }
      const deletedColKeyArr = deletedCols.slice().sort( compareFunc  )
      return { deletedColKeyArr, restoreArr }
}



// Animation and state change as restore button changes state
const handleRestoreClick = ( selectedColKey: number, colActionsState : ColActionsState, 
            setColActionsState: SetColActionsState, tCD: TableComputedData ) : void => {
    // Whatever the current state, toggle it.
    const {restoreArr} = colActionsState
    const newState = restoreArr[selectedColKey] = !restoreArr[selectedColKey]
    if (newState) {setColActionsState( { ...colActionsState, restoreArr } )}
    if ( newState === true ) {
        // insert the restored column
        insertColumn( selectedColKey, 'restored', selectedColKey, tCD)
    } else {
        // newState is false; delete selectedColKey
        // From the restore/delete menu, the current popup is on some selected column
        // and the restored (or immediately re-deleted) column will appear the the 
        // right of the selected col.  
        // We do NOT want to change the original deleted date just because user's are
        // playing inside this popup window.
        // And since the current selected column always stays visible (any restored/deleted cols
        // are immediately to the selected column's right), then there is no need to reset the active
        // column stats colKey.
        deleteColumn( selectedColKey, setDeletionDateFalse, resetActiveStatsColKeyFalse, tCD )
    }
}

const setDeletionDateTrue = true
const setDeletionDateFalse = false
const resetActiveStatsColKeyFalse = false 
const resetActiveStatsColKeyTrue = true

    // We need an accurate estimate and accounting of the total height of the child, 
    // so we can increase the default height in the case of many deleted columns 
    // extending the length of the restore table beyond the default height.
const initialTopMargin = 20
const hoverBoxHeight = 50
const hoverBoxGap = 15   
const restoreTableTopGap = 10
//const hoverBoxHeightPerOption =  hoverBoxHeight + hoverBoxGap
const deletedRowsHeaderHeight = 40
//const heightPerDeletedCol = 20  // 20px per row in table ( per deleted col )
const totalConstChildHeight = initialTopMargin +  4*(hoverBoxHeight+hoverBoxGap) + deletedRowsHeaderHeight



export const TableFp_HeaderColRestore : React.FC<FpChildRenderProps> = ( props )=> {
    const {fpParentState, setFpParentState, activeFp} = props
    const {childWidth, childHeight} = fpParentState
    const { primaryKey:colKey }= activeFp
    const tCD = useContext( TableComputedDataContext )
    const [colActionsState, setColActionsState] = useState( {...createInitialState(tCD.derivedColAttributesArray), 
                                                            isRestoreMenuSelected: true,
                                                            hover0:false, hover1:false, 
                                                            hover2:false, hover3:false })
    const {deletedColKeyArr, isRestoreMenuSelected, hover0, hover1, hover2, hover3} = colActionsState   
    const numDeletedCols = deletedColKeyArr.length
    const {fpWidth} = fpParentState
    // We need an accurate estimate and accounting of the total height of the child, 
    // so we can increase the default hieght in the case of many deleted columns 
    // extending the length of the restore table beyond the default height.
    
    const actionStyle = {
        //position:'relative',
        height: 18, 
        width: 520,
        marginTop: 0, 
        paddingLeft: 0,
        fontSize: 16, fontWeight: 'bold',
        //background: 'orange'
    }
    const descriptionStyle = {
        width: 520,
        height: 0,
        paddingLeft: 20, 
        fontSize: 14,
        background: 'red'
    }
    const hoverBoxStyle = {
        marginLeft: 40,
        width: 520,
        height: hoverBoxHeight,
        marginTop:hoverBoxGap,
        background: 'pink',
    }
    return (
        <div className='rc_TableFp_HeaderColRestore'>

                < div 
                   onMouseOver={ ()=> setColActionsState({...colActionsState, hover0:true }) }
                   onMouseOut ={ ()=> setColActionsState({...colActionsState, hover0:false}) }
                   onClick    ={ ()=> insertColumn( colKey, 'new', undefined, tCD) } 
                   style ={{...hoverBoxStyle, marginTop: initialTopMargin, 
                            backgroundColor: hover0 ? 'lightblue' : 'unset'}}
                >
                    <div  style={{...actionStyle, marginTop:10}}>
                        {'Insert a new dependent column'}
                    </div>
                    <div style={descriptionStyle}>
                        {`New column values are defined by a formula, and dependent on existing data.`} <br/>
                        {`For example, to calculate an average:${tripleSpaceChar}MyNewCol = (` + 
                                                                        thinSpaceChar + 'Col1' +
                                                                        thinSpaceChar + '+' +
                                                                        thinSpaceChar + 'Col2' +
                                                                        thinSpaceChar + ')' +
                                                                        thinSpaceChar + '/' +
                                                                        thinSpaceChar + '2'}
                    </div>
                </div>


                < div 
                   onMouseOver={ ()=> setColActionsState({...colActionsState, hover1:true }) }
                   onMouseOut ={ ()=> setColActionsState({...colActionsState, hover1:false}) }
                   onClick    ={ ()=> insertColumn( colKey, 'cloned', undefined, tCD) }  
                   style ={{...hoverBoxStyle, backgroundColor: hover1 ? 'lightblue' : 'unset'}}
                >
                    <div  style={actionStyle}>
                        {'Insert a clone of this column'}
                    </div>
                    <div style={descriptionStyle}>
                        {'If a similar column exist, it is easier to clone that column.'} <br/>
                        {'This is most helpful when writing and debugging formulas.'}
                    </div>
                </div>

     
                < div 
                   onMouseOver={ ()=> setColActionsState({...colActionsState, hover2:true }) }
                   onMouseOut ={ ()=> setColActionsState({...colActionsState, hover2:false}) }
                   onClick    ={ ()=> deleteColumn( colKey, setDeletionDateTrue, resetActiveStatsColKeyTrue, tCD) } 
                   style ={{...hoverBoxStyle, backgroundColor: hover2 ? 'lightblue' : 'unset'}}
                >
                    <div  style={actionStyle}>
                        {'Delete this column'}
                    </div>
                    <div style={descriptionStyle}>
                        {'Removes column from the visible table, but NOT from the internal data.'}<br/>
                        {'Deleted columns can only be restored by the table owner.'}
                    </div>
                </div>


            { numDeletedCols > 0 && 
            <> 
                < div 
                   onMouseOver={ ()=> setColActionsState({...colActionsState, hover3:true }) }
                   onMouseOut ={ ()=> setColActionsState({...colActionsState, hover3:false}) }
                   onClick    ={ ()=> setColActionsState({...colActionsState, isRestoreMenuSelected:true}) } 
                   style ={{...hoverBoxStyle, backgroundColor: hover3 ? 'lightblue' : 'unset'}}
                >
                    <div  style={actionStyle}>
                        {'Restore a deleted column'}
                    </div>
                    <div style={descriptionStyle}>
                        {`A table of prior deleted columns, with the option to restore one or more of`}<br/>
                        {'these columns.'}
                    </div>
                </div>

                { isRestoreMenuSelected && 
                    <ColRestoreTable
                        colActionsState={colActionsState}
                        setColActionsState={setColActionsState}
                        fpParentState={fpParentState}
                        setFpParentState={setFpParentState}
                    /> 
                }
            </> 
            }
    
        </div>
    )
}





const ColRestoreTable : React.FC<ColRestoreTableProps> = ( props )=> {
    const tCD = useContext( TableComputedDataContext )
    const {derivedColAttributesArray} = tCD
    const {colActionsState, setColActionsState} = props
    const {deletedColKeyArr, restoreArr} = colActionsState


    // These widths constitute a single row in the tab

    const widthDate = 165
    const widthTitle= 180
    const widthType = 100
    const gapLeftOfRestore = 0  // gap between dataType and first select box
    const widthRecoverCol = 70
    const gapRightOfTable = 10
    const restoreTableWidth = widthDate + widthTitle + widthType + 
                       gapLeftOfRestore + widthRecoverCol + gapRightOfTable
    const centeringGap = (props.fpParentState.childWidth - restoreTableWidth) / 2

    return (

          <div  className={"rc_EditColRestore"}
            style = {{
              //background: 'orange',
              marginLeft: centeringGap, 
              width: restoreTableWidth, 
              fontWeight:'bold',
              userSelect: 'none',  // Text cannot be selected / copied
          }}>
          


            <div className={'TitleBar'}
              style={{fontWeight:'bold', 
                    marginTop: restoreTableTopGap, 
                    backgroundColor: 'lightgray',
                    height:deletedRowsHeaderHeight}}>

                <div style={{
                  display: 'inline-block', width:widthDate, textAlign: 'center', //background: 'red',
                  }}>
                    <span>{'Date - Time'}</span><br/>
                    <span>{'of Deletion'}</span>
                </div>

                <div style={{
                  display: 'inline-block', width:widthTitle, textAlign: 'center', //background: 'yellow',
                  }}>
                  <span>{'Displayed'}</span><br/>
                  <span>{'Column Name'}</span>
                </div>

                <div style={{
                  display: 'inline-block', width:widthType, textAlign: 'center', //background: 'blue'
                  }} >
                  <span>{'Data'}</span><br/>
                  <span>{'Type'}</span>
                </div>

                <div style={{
                    display: 'inline-block', width:widthRecoverCol,  textAlign: 'center', //background: 'green',     
                  }}>
                  <span>{'Recover'}</span><br/>
                  <span>{'Column'}</span>
                </div>

            </div>

            <div className={'TableContainer'}
              style={{
                width:'100%', height: 144,
                fontWeight:'normal',
              }}>

                <div className={'TableContent'}
                style={{
                    width:'100%', height:'100%',
                    overflow: 'auto'
                }}>

                { deletedColKeyArr.length > 0

                    ? deletedColKeyArr.map( colKey=>{
                          const {colTitle, colDataType, deletedDate} = derivedColAttributesArray[colKey]
                          const formattedDate = dayjs(deletedDate).format('YYYY MMMDD - HH:mm')
                          return (
                            <div key={colKey} style={{fontWeight:'normal'}}>
                                <div style={{ display: 'inline-block', width:widthDate, textAlign:'center',
                                              whiteSpace:'nowrap', overflow:'hidden', verticalAlign:'bottom'}}
                                >{formattedDate}</div>
                                <div style={{ display: 'inline-block', width:widthTitle, textAlign:'center', 
                                              whiteSpace:'nowrap', overflow:'hidden', verticalAlign:'bottom'}}
                                >{`'${colTitle}'`}</div>
                                <div style={{ display: 'inline-block', width:widthType, textAlign:'center', 
                                              whiteSpace:'nowrap', overflow:'hidden', verticalAlign:'bottom'}}
                                >{`${colDataType}`}
                                </div>
                                <input type={'checkbox'} name={'restoreCheck'}
                                    checked={ restoreArr[colKey] }
                                    onChange={ ()=>handleRestoreClick(colKey, colActionsState, setColActionsState, tCD)}
                                    style={{display: 'inline-block', verticalAlign:'bottom',
                                      textAlign: 'center', width:widthRecoverCol}}/>
                            </div>
                          )
                      })

                    : <div style={{marginTop: 30, marginLeft: 100, fontSize:16}}>
                        {'This table has no prior deleted columns.'}
                      </div>

                }

                </div>
            </div>
        </div>


    )
}

