import { useEffect, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { initAuth, setAuthToken, setAuthUserId } from '../redux/appReducer'
import { asyncDispatch } from '../sharedFunctions/utils'
import { refreshLoginThunk } from '../redux/loginThunks'

const useInitAuth = () => {
  const dispatch = useDispatch()
  const intervalRef = useRef<number | null>(null)

  // Initialize authentication when the hook is called
  // useEffect is not used here because it waits for the component to render.
  useMemo(() => {
    dispatch(initAuth())
  }, [dispatch])
  
  // Manage storage events and refresh login token every 2 hours
  useEffect(() => {
    const storageChangeHandler = (e: StorageEvent): void => {
      const { key, newValue } = e
      if (key === 'token') {
        dispatch(setAuthToken(newValue || ''))
      }
      if (key === 'userid') {
        dispatch(setAuthUserId(newValue || ''))
      }
    }

    // Add storage event listener
    window.addEventListener('storage', storageChangeHandler)

    // Set interval to refresh login token every 2 hours
    intervalRef.current = window.setInterval(() => {
      asyncDispatch(dispatch, refreshLoginThunk())
    }, 7200000)

    // Cleanup when this hook is unmounted
    return () => {
      window.removeEventListener('storage', storageChangeHandler)
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [dispatch])
}

export default useInitAuth
