import type {FpLayout} from '../appCode/getSbFpConfigMappings'
import type {ActiveFp} from '../types'
//import type { RootState } from '../redux/store'  // my testPalette given access to sessionState

//import {useSelector}      from 'react-redux'
import {useContext}       from 'react'
import {TableComputedDataContext}   from '../viewTableTriple/TtGetComputedData'

// A function that return another function!
export const useGetFpNone : ( activeFp: ActiveFp )  =>  FpLayout = (activeFp: ActiveFp) => { 
    useContext( TableComputedDataContext )
    return {    
        activeFp,
        titleBarStrg: 'none',
        isDraggable: false,
        isResizableX: false,
        isResizableY: false,
        minResizeX: 100,
        minResizeY: 100,
        maxResizeX: 100,
        maxResizeY: 100,
        tabInfoArr: [],
        childInfoArr: [],
        getSelectedCellGeometry: () => {return {left:0, top:0, width:0, height:0, isCellVisible:false}},
    }
}
