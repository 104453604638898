import type { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { set } from 'lodash' // need the mutate in place behavior of lodash
import React, { Component } from 'react'
import type { Context, RefObject } from 'react'
import { ReactReduxContext, ReactReduxContextValue } from 'react-redux'
import { typedKeys } from '../../sharedFunctions/utils'
import NotifySystem from '../react-notification-system/NotificationSystem'
import type { Notification } from '../react-notification-system/constants'
import reducer, { actions } from '../../redux/notificationsReducer'
import type { Style } from '../react-notification-system/styles'

export type GenericStore = {
  dispatch: Dispatch<AnyAction>
}

type Props = {
  allowHTML: boolean
  notifications: Notification[]
  store: GenericStore
  style: Style
}
class Notifications extends Component<Props> {

  constructor(props: Props) {
    super(props)

    this.notifyRef = React.createRef<NotifySystem>()
  }

  notifyRef: RefObject<NotifySystem>

  system = (): NotifySystem | null => {
    return this.notifyRef.current
  }

  componentDidUpdate(prevProps: Props) {
    const { notifications, store } = this.props
    const notificationIds = notifications.map(notification => notification.uid)
    const systemNotifications = this.system()?.state.notifications || []

    if (notifications.length > 0) {
      // Get all active notifications from react-notification-system
      /// and remove all where uid is not found in the reducer
      for (const notification of systemNotifications) {
        if (notificationIds.indexOf(notification.uid) < 0) {
          if (notification.uid) {
            this.system()?.removeNotification(notification.uid)
          }
        }
      }

      for (const notification of notifications) {
        this.system()?.addNotification({
          ...notification,
          onRemove: () => {
            if (notification.uid) {
              store.dispatch(actions.hide(notification.uid))
            }
            notification.onRemove && notification.onRemove(notification)
          }
        })
      }
    }

    if ((prevProps.notifications !== notifications) && notifications.length === 0) {
      this.system()?.clearNotifications()
    }
  }

  shouldComponentUpdate(nextProps: Props) {
    return this.props !== nextProps
  }

  render() {
    const { notifications, store, ...rest } = this.props

    return (
      <NotifySystem ref={this.notifyRef} {...rest} />
    )
  }
}

type ContextProps = Props & {
  context?: Context<ReactReduxContextValue<any, AnyAction>>
}

const NotificationsWithContext = (props: ContextProps) => {
  const Context = props.context || ReactReduxContext

  if (Context == null) {
    throw new Error('Please upgrade to react-redux v6')
  }

  return (
    <Context.Consumer>
      {(otherProps) => {
        const { store } = otherProps
        return <Notifications {...props} store={store} />
      }}
    </Context.Consumer>
  )
}

// Tie actions to Notifications component instance
for (const key of typedKeys(actions)) {
  set(NotificationsWithContext, key, actions[key])
}

NotificationsWithContext.reducer = reducer

export default NotificationsWithContext
