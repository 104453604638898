import type { ReactNode } from 'react'

import {Component} from 'react'
import constants              from '../sharedComponents/constants'
import HorzTaperedDivider     from '../sharedComponents/HorzTaperedDivider'
import {createUnitsReactNode} from '../viewTable/ColumnTitle'

const FLOATING_PALETTE_BACKGROUND = constants.FLOATING_PALETTE_BACKGROUND
const nbSpace = constants.nonBreakingSpace

type OwnProps = {
  height: number,
  top: number,
  left: number,
  className? : string,
  onDismissClick: () => void,
}

type DefaultProps = {
  height: number
  width: number | string
  top: number
  left: number
}

type Props = OwnProps & DefaultProps
export class HelpPanelNumberFormats extends Component<Props> {
  static defaultProps: DefaultProps = {
    height: 20,
    width: 20,
    top: 0,
    left: 0,
  }
  render() {
    const {className, height, top, left, width} = this.props
    const height2 = height === 0 ? '100%' : height

    return (

      <div className={className ?? 'rc_HelpPanelNumberFormats'}
        style={{position:'relative', top, left, height:height2, width}}
        onClick={this.props.onDismissClick} >

          <HorzTaperedDivider background={FLOATING_PALETTE_BACKGROUND}/>
          <div className={'FormatExamplesTitle'}
            style={{textAlign: 'center'}}>
            Example Number Formats</div>
          <table style={{ textAlign:'center', lineHeight:1 }}>
              <tbody>
                <tr><td> 1.2</td><td> 10 ** 3</td><td>1e3</td><td>1:23</td><td>True</td></tr>
                <tr><td> 1.23456e4</td><td>-10 **-3</td><td>-1E-3</td><td>1:23:45</td><td>False</td></tr>
                <tr><td> 12345.6</td><td>1 * 10 ** 3</td><td>5e3</td><td>-1:23:45.67</td><td>1</td></tr>
                <tr><td> (No commas!)</td><td>3.1*10**3</td><td>3.14E3</td><td>0:12:34</td><td>0</td></tr>
              </tbody>
          </table>

      </div>
    )
  }
}


export class HelpPanelColKey extends Component<Props> {
  static defaultProps: DefaultProps = {
    height: 20,
    width: '100%',
    top: 0,
    left: 0,
  }
  render() {
    const {className, height, top, left, width} = this.props
    const height2 = height === 0 ? '100%' : height
    return (
      <div
        className={className ?? 'rc_HelpPanelColKey'}
        onClick={this.props.onDismissClick}
        style={{
          position:'relative', top, left,
          width,
          lineHeight: 1.2,
          fontFamily: 'serif', fontSize: 16,
          height:height2,
        }}>
              <div  style={{ fontWeight: 'bold', textAlign: 'center', marginTop:6 }}>
                {'Row names and Key columns help :'}
              </div>

              <div style={{marginTop:6, marginLeft:0, marginRight:0}}>
                {
                  "Every row must have a unique rowName.  The column containing "
                + `rowNames is called the 'Key' column. "EmployeeID" or "LastName" would be good examples. `
                + "For large tables, you may need to set 2 or more Key columns. For example, you may "
                + `need both "FirstName" and "LastName" set as 'Keys' to prevent duplicate rowName errors.`
                }
              </div>
              <div style={{marginTop:8, marginLeft:0, marginRight:0, marginBottom:6}}>
                  {
                    "If publishing a table, it is recommended to drag your 'Key' columns to the far left.  They will "
                  + "lock in place and not shift with the horizontal scroll bar."
                  }
              </div>
              <div style={{height:6}}/>
      </div>
    )
  }
}


export class HelpPanelDescription extends Component<Props> {
  static defaultProps: DefaultProps = {
    height: 20,
    width: '100%',
    top: 0,
    left: 0,
  }
  render() {
    const {className, height, top, left, width} = this.props
    const height2 = height === 0 ? '100%' : height
    return (
      <div
        className={className ?? 'rc_HelpPanelDescription'}
        onClick={this.props.onDismissClick}
        style={{
          position:'relative', width, top, left,
          lineHeight: 1.2,
          fontFamily: 'serif', fontSize: 16,
          height:height2,
        }}>
              <div  style={{ fontWeight: 'bold', textAlign: 'center', marginTop:6 }}>
                {'Description help :'}
              </div>
              <div style={{marginTop:5, marginLeft:0, marginRight:0, marginBottom:6}}>
                  {
                    "Document your data! "
                    + "What seems obvious to you will not be to others. Assume roughly equal "
                    + "efforts to build your table/plots, and to document the table/plots. "
                    + "Always credit/reference all sources."
                  }
              </div>
              <div style={{marginTop:8, marginLeft:0, marginRight:0}}>
                {
                  "The target audience for descriptions is your 'peer' group. "
                + "Explain how the table, data, or plots were created.  You may "
                + "also include your interpretations -- "
                + "however, make clear the distinction between the data and your opinions."
                }
              </div>
              <div style={{marginTop:8, marginLeft:0, marginRight:0, marginBottom:6}}>
                  {
                    "Provide links to external data sources. If the data was transferred from your own spreadsheet, "
                    + "it is not necessary to provide a link.  However, if you "
                    + "do make the spreadsheet public then the corresponding link "
                    + "is recommended."
                  }
              </div>
              <div style={{marginTop:8, marginLeft:0, marginRight:0, marginBottom:6}}>
                  {
                    "Linking to wikipedia or other 3rd party information is an easy way to provide "
                    + "background using pre-existing sources.  You can cut and paste text with links "
                    + "directly to this editor."
                  }
              </div>
              <div style={{height:5}}/>
      </div>
    )
  }
}



export class HelpPanelPrefix extends Component<Props> {
  static defaultProps: DefaultProps = {
    height: 20,
    width: '100%',
    top: 0,
    left: 0,
  }
  render() {
    const {className, height, top, left, width} = this.props
    const height2 = height === 0 ? '100%' : height
    return (
      <div
        className={className ?? 'rc_HelpPanelPrefix'}
        onClick={this.props.onDismissClick}
        style={{
          position:'relative', top, left, width,
          lineHeight: 1.2,
          fontFamily: 'serif', fontSize: 16,
          height:height2,
        }}>
              <div style={{ fontWeight: 'bold', textAlign: 'center', marginTop:2 }}>
                {'Prefix and Suffix help :'}
              </div>

              <div style={{marginTop:6, marginLeft:0, marginRight:0}}>
                {
                  "Prefix and Suffix work well in small tables (< 20 rows). "
                + `However, they can add more clutter than information to large tables. `
                }
              </div>
              <div style={{marginTop:8, marginLeft:0, marginRight:0}}>
                {"Use prefix and suffix sparingly."}
              </div>
              <div style={{height:6}}/>
      </div>
    )
  }
}


export class HelpPanelUnits extends Component<Props> {
  static defaultProps: DefaultProps = {
    height: 20,
    width: '100%',
    top: 0,
    left: 0,
  }
  render() {
    const {className, height, top, left, width} = this.props
    const height2 = height === 0 ? '100%' : height
    const bullet = "\u00A0\u25Cf" + nbSpace
    const thinSpace = constants.thinSpaceChar
    const dvd = thinSpace+'/'+thinSpace
    const formatExampleStrg = 'm**3/(truck*days)\u00A0\u00A0'
    const FormattedExampleNode : ReactNode = createUnitsReactNode(formatExampleStrg)
    return (
      <div
        className={className ?? 'rc_HelpPanelUnits'}
        onClick={this.props.onDismissClick}
        style={{
          position:'relative', top, left, width,
          lineHeight: 1.2,
          fontFamily: 'serif', fontSize: 16,
          height:height2,
        }}>
            <div style={{ fontWeight: 'bold', textAlign: 'center', marginTop:2 }}>
              {'Units help :'}
            </div>

            <div style={{display:'inline-block', marginTop:6, verticalAlign:'top'}}>{bullet}</div>
            <div style={{display:'inline-block', marginTop:6}}>
              <div>{'Some formatting is provided for multiply, divide, and integer exponents.'}</div>
              <div>Example:
                <span style={{fontFamily:'sans-serif', fontSize:14}}>
                  {'\u00A0\u00A0\u00A0' + formatExampleStrg + '\u00A0\u00A0=>\u00A0\u00A0\u00A0'}
                  {FormattedExampleNode}
                </span>
              </div>
            </div>

            {/*<div style={{display:'inline-block', marginTop:6, verticalAlign:'top'}}> */}
            <div>
                <div style={{display:'inline-block', marginTop:6, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:6}}>
                  {'One can copy/paste unicode symbols from the web (or this list):'}<br/>
                  {'Example units:\u00A0\u00A0\u00A0\u212B, \u00B0F, \u00B0C, \u00B0K, \u2126, \u03C9'}<br/>
                  {'Example currencies:\u00A0\u00A0\u00A0\u20AC, $, $(US), \u00A5, \u0E3F, \u20BF, \u0024, \u20B9, ' +
                        '\u20B1, \u20AA, \u20BD, \u20A4, \u00A3, \u20A9, \u20B9, \u20B4'}<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:6, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:6}}>
                  {"'Unitless' is allowed, but not very useful. See alternatives below."}<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:6, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:6}}>
                  <b>{'Unitless Counts:'}</b>{' Most countable objects have no standard unit. If your'}<br/>
                  {"column name makes the object counted clear, use 'Count' for the units."}<br/>
                  {"Else, the units are the name of the counted object."}<br/>
                  {'Examples:\u00A0\u00A0Count,\u00A0\u00A0# Cars,\u00A0\u00A0# Replies,\u00A0\u00A0# Households,'}
                  {'\u00A0\u00A0bits,\u00A0\u00A0bytes'}<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:6, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:6}}>
                  <b>{'Unitless Proportions:'}</b>{' The ratio of two values: A/B, where A and B have '}<br/>
                  {"the same units. For example, my height divided by average height. The"}<br/>
                  {"units are 'm/m' (meters/meters), or even better written as 'height/height'."}<br/>
                  {'Examples:\u00A0\u00A0time/time,\u00A0\u00A0velocity/velocity (%),\u00A0\u00A0#Cars'+dvd+'#People,'}<br/>
                  {'#Replies'+dvd+'#Mailed (%),\u00A0\u00A0CO2'+dvd+'Air (ppm-molar),'}
                  {'\u00A0\u00A0Lead'+dvd+'H2O (ppb-mass)'}<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:6, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:6}}>
                  <b>{'Unitless Nonlinear Scales:'}</b>{" For well known scales, use the scale name:"}<br/>
                  {"Ex: Richter, Neper, pH, Fujita, Decibels(sound), Magnitude(stellar), VEI"}<br/>
                  {"For nonlinear scales of your own invention, give the scale's definition:"}<br/>
                  {'Ex:\u00A0\u00A0Log10(time/1sec),\u00A0\u00A0-ln(power/1watt),\u00A0\u00A0Log2(totalBits/1bit)'}<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:6, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:6}}>
                  {"Be creative. Any hint is better than 'Unitless'."}<br/>
                </div>
            </div>

            <div style={{height:6}}/>

      </div>
    )
  }
}


const blueBack={ background: constants.SCRY_VERYLIGHT_BLUE}
const redText={ color: '#ff0000'}

export class HelpPanelFormulas extends Component<Props> {
  static defaultProps: DefaultProps = {
    height: 400,
    width: '100%',
    top: 0,
    left: 0,
  }
  render() {
    const {className, top, left, width} = this.props
    //if ( height === 0 ) { var height2 = '100%' }
    const bullet = "\u00A0\u25Cf" + nbSpace
    const vertGap = 8
    const exampleGap = 12
    const indent = '\u00A0\u00A0\u00A0\u00A0'
    return (
      <div
        className={className ?? 'rc_HelpPanelFormulas'}
        onClick={this.props.onDismissClick}
        style={{
          position:'relative', top, left, width,
          lineHeight: 1.2,
          fontFamily: 'serif', fontSize: 16,
          height:400,   // Input height is ignored for this usage.
          overflowY: 'scroll',
        }}>
            <div style={{ fontWeight: 'bold', textAlign: 'center', marginTop:2 }}>
              {'Formulas help :'}
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:vertGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:vertGap,
                             width: '94%', wordWrap: 'break-word'}}>
                  <b>{"DataTypes:"}</b>{"\u00A0\u00A0Numbers\u00A0\u00A0(Future: Strings, TimeStamps, GeoPoints )"}<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:vertGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:vertGap,
                             width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Numeric Operators:"}</b>{"\u00A0\u00A0+,\u00A0\u00A0-,\u00A0\u00A0*,\u00A0\u00A0/,\u00A0\u00A0**,\u00A0\u00A0// (floor divide),\u00A0\u00A0% (modulo)"}<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:vertGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:vertGap,
                             width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Boolean Operators:"}</b>{"\u00A0\u00A0<,\u00A0\u00A0<=,\u00A0\u00A0>,\u00A0\u00A0>=,\u00A0\u00A0==,\u00A0\u00A0!=,\u00A0\u00A0or, \u00A0and,\u00A0\u00A0not"}<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:vertGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:vertGap,
                             width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Numeric Functions:"}</b><br/>
                    {"abs, max, min, ceil, floor, trunc, log, log10, exp, sqrt, isnan"}<br/>
                    {"cos, sin, tan, acos, asin, atan, atan2, radians, degrees"}<br/>
                    {"Usage: x = sin(valueRadians);\u00A0\u00A0x = sin(radians(valueDegrees))"}<br/>
                    {"resultRadians = asin(x);\u00A0\u00A0resultdegrees = degrees(asin(x))"}<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:vertGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:vertGap,
                             width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Round Function:"}</b><br/>
                    {"round(x):   Python rounds midpoint values to the even integers."}<br/>
                    {"round(1.5) = 2,  round(2.5) = 2,  round(-3.5) = -4, round(-4.5) = -4"}<br/>
                    {"round(x,2): Optional 2nd argument rounds to different significant digits"}<br/>
                    {"round(12.123,1)= 12.1,  round(12.123,2)= 12.12, round(12.12,-1)= 10 "}<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:vertGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:vertGap,
                             width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Constants:"}</b>{"\u00A0\u00A0pi, True, False, None.\u00A0\u00A0For Euler's number use exp(1)"}<br/>
                  {"True/False are internal values 0/1. (pi + True + False) => 4.1415..."}<br/>
                {"None is an empty value. Returning None creates an empty cell."}<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:vertGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:vertGap,
                             width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Control Keywords:"}</b>{"\u00A0\u00A0if,\u00A0\u00A0elif,\u00A0\u00A0else,\u00A0\u00A0return,\u00A0\u00A0pass"}<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:vertGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:vertGap,
                             width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Access Table Values:"}</b>
                  {"\u00A0\u00A0by column name:  abs("}<span style={blueBack}>Age</span>{")"}<br/>
                  {"Matched names have blue backgrounds. Matching is upper/lower case and spacing insensitive. "+
                  "There is no access to values by row or cell name. " +
                  "\u00A0\u00A0Future: Column statistics: mean("}<span style={blueBack}>Age</span>), count(<span style={blueBack}>Age</span>), ...<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:vertGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:vertGap,
                             width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Error Cells (red values):"}</b>
                  {"\u00A0\u00A0Illegal table values are displayed in red text, " +
                  "for example value '"}<span style={redText}>7.a3</span>{"' in a numeric column. " +
                  "Formulas will also return the illegal value "}
                  <span style={redText}>NaN</span>
                  {" (pronounced: 'Not a Number') if:"}<br/>
                  {"- Division by zero"}<br/>
                  {"- log() or sqrt() of negative values"}<br/>
                  {"- Out-of-range inverse trig functions"}<br/>
                  {"- A column value used in the computation is Empty or Illegal."}<br/>
                  {"Error cells are easily spotted by red text. " +
                  "When a column is sorted, error cells, if present, will always sort to the top."}<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:vertGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:vertGap,
                             width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Empty Cells:"}</b>
                  {" have no values (blank).  Some missing data is expected in large tables.  HOWEVER! - "+
                  "When users view your table, all error cells are also displayed as empty cells.  Table viewers " +
                  "never see "}<span style={redText}>red</span>{" error cells. After all, they cannot fix " +
                  "nor do anything with error cells. " +
                  "To view your table as others will see the table, use 'View Mode', found in the top menu bar."} <br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:vertGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:vertGap,
                             width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Fixing Error Cells:"}</b>
                  {"\u00A0\u00A0This is not a requirement. " +
                  "They are only visible to the table owner, or if you allow the table to be cloned or CSV to be downloaded, " +
                  "visible to the next table owner. " +
                  "Review them and fix them if appropriate. But one can keep erroneous values if useful. " +
                  "For example, perhaps keeping the value '"}
                  <span style={redText}>forties?</span>
                  {"' as an entry in the "}
                  <span style={blueBack}>Age</span>{" column."}<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:vertGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:vertGap,
                             width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Writing formulas to NOT return illegal values:"}</b>
                  {"\u00A0\u00A0The tool's default behavior is return '"}
                  <span style={redText}>NaN</span>
                  {"' (seen by users as an Empty cell) for numerical errors. " +
                  "However, you can use function isnan() to force return values of your choice. " +
                  "Function isnan() is pronounced 'is Not a Number' and returns True if its single argument is not a number. " +
                  "See formula examples below."}<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:vertGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:vertGap,
                             width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Debugging Formulas:"}</b>
                  {"\u00A0\u00A0If a calculated cell does not return the expected value, click on the cell " +
                  "to open a view.  It will show the cell's specific calculation. This is the easiest way " +
                  "to debug formulas."}<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:vertGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:vertGap,
                             width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Write formulas for clarity, not speed:"}</b>{'\u00A0\u00A0'}
                  {"Assume a target user of age 15+ "+
                  "and no programming experience. Break long expressions into "+
                  "short expressions. Use descriptive 'named values'. No need to "+
                  "compress, eliminate, reorder, or otherwise optimize operations to improve speed -- because "+
                  "formulas are internally optimized and computation is very fast. Use comments!"}<br/>
                </div>
            </div>

            <div style={{ textAlign: 'center', marginTop:20, marginBottom:0 }}>{'Formula Examples :'}</div>

            <div>
                <div style={{display:'inline-block', marginTop:exampleGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:exampleGap,
                           width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Example #1: Single line expresion"}</b><br/><br/>
                  # Comments start with a hash<br/>
                  # Most formula's will be a one line expression<br/>
                  # Blank lines in a formula are OK:<br/><br/>
                  {"return\u00A03 * max( "}<span style={blueBack}>{'\u00A0Age\u00A0'}</span>, 20 )
                  {' - 6\u00A0\u00A0\u00A0\u00A0# Comments after expressions OK'}
                </div>
            </div>


            <div>
                <div style={{display:'inline-block', marginTop:exampleGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:exampleGap,
                           width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Example #2: Named values; Multi-line formulas"}</b><br/><br/>
                  # Calculate BMI (Body Mass Index) from height and weight<br/>
                  # A 'namedValue' is a temporary value to save part of the<br/>
                  # calculation.  In the next example, namedValue 'BMI' is not<br/>
                  # needed, but it adds clarity to the formula.<br/><br/>
                  BMI = <span style={blueBack}>{'\u00A0weight\u00A0'}</span>
                  {' * 703 /'}
                  <span style={blueBack}>{'\u00A0height\u00A0'}</span> ** 2  # weight in lbs, height in inches<br/>
                  return BMI<br/>
                </div>
            </div>


            <div>
                <div style={{display:'inline-block', marginTop:exampleGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:exampleGap,
                           width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Example #3: Average test score"}</b><br/><br/>
                  # Calculate the average of three test scores: <br/>
                  sum = <span style={blueBack}>{'\u00A0Test #1\u00A0'}</span> + <span style={blueBack}>{'\u00A0Test #2\u00A0'}</span>
                  {' + '}
                  <span style={blueBack}>{'\u00A0Test #3\u00A0'}</span><br/>
                  return sum / 3<br/>
                </div>
            </div>


            <div>
                <div style={{display:'inline-block', marginTop:exampleGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:exampleGap,
                           width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Example #4: Average of best 2 of 3 test scores"}</b><br/><br/>
                  # Calculate the average of best 2 of 3 test scores: <br/>
                  sumAll = <span style={blueBack}>{'\u00A0Test #1\u00A0'}</span> + <span style={blueBack}>{'\u00A0Test #2\u00A0'}</span>
                  {' + '}
                  <span style={blueBack}>{'\u00A0Test #3\u00A0'}</span><br/>
                  {"worstTest = min( "}<span style={blueBack}>{'\u00A0Test #1\u00A0'}</span> , <span style={blueBack}>{'\u00A0Test #2\u00A0'}</span>
                  {' , '}
                  <span style={blueBack}>{'\u00A0Test #2\u00A0'}</span>{" )"}<br/>
                  sum_Best2scores = sumAll - worstTest<br/>
                  return sum_Best2scores / 2<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:exampleGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:exampleGap,
                           width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Example #5: 'if' branching"}</b><br/><br/>
                  {"# Return a column of True/False, where True if age is 20 years or less"}<br/>
                  {"# True/False are internally numbers of value 0 or 1"}<br/>
                  {"# Hence this function returns either the number value 0 or 1."}<br/>
                  {"# But True and False make the formula easier to read!"}<br/>
                  {"# The calculated column values will display as numbers 0 or 1"}<br/>
                  {"# Set the column format to 'True False'.  Make the table easier to read!"}<br/>
                  {"# When working with Yes/No or 0/1 data, better to use: True/False."}<br/><br/>
                  answer = False<br/>
                  if <span style={blueBack}>{'\u00A0Age\u00A0'}</span>{" <= 20 :"}
                  {indent+"# If true, execute indented lines"}<br/>
                  {indent+"answer = True"}<br/>
                  return answer<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:exampleGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:exampleGap,
                           width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Example #6: Multiple return statements"}</b><br/><br/>
                  {"# Return a column of True/False, where True if age 20 years or less"}<br/>
                  {"# This is an alternative to example #5, but easier to read "}<br/>
                  {"# Set this column's format to 'True False' to see text rather than 0/1."}<br/><br/>
                  if <span style={blueBack}>{'\u00A0Age\u00A0'}</span>{" <= 20 :"}<br/>
                  {indent+"return True"}<br/>
                  return False<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:exampleGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:exampleGap,
                           width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Example #7: Another alternative to Examples #5, #6."}</b><br/>
                  {"# Return a column of True/False, where True if age 20 years or less"}<br/>
                  {"# Set this column's format to 'True False' to see text rather than 0/1."}<br/><br/>
                  return <span style={blueBack}>{'\u00A0Age\u00A0'}</span>{" <= 20"}<br/>
                </div>
            </div>


            <div>
                <div style={{display:'inline-block', marginTop:exampleGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:exampleGap,
                           width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Example #8: if/else branching"}</b><br/><br/>
                  {"# Return an 'effective' age, adjusting for whether a person smokes"}<br/>
                  {"# 'Is Smoker?' is a column of True/False values"}<br/><br/>
                  if <span style={blueBack}>{'\u00A0Is Smoker?\u00A0'}</span>
                  {" :\u00A0\u00A0\u00A0# If true, execute indented lines"}<br/>
                  {indent+"effectiveAge = "}<span style={blueBack}>{'\u00A0Age\u00A0'}</span> + 5<br/>
                  {'else :'}
                  {indent+indent+indent+indent+indent+"# If prior test false, execute indented lines"}<br/>
                  {indent+"effectiveAge = "}<span style={blueBack}>{'\u00A0Age\u00A0'}</span><br/>
                return effectiveAge<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:exampleGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:exampleGap,
                           width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Example #9: if/elif/else branching"}</b><br/><br/>
                  {"# Return an ageGroup index"}<br/>
                  {"# value of 1 for age 20yrs or less;"}<br/>
                  {"# value of 2 for 21 to 40yrs;"}<br/>
                  {"# value of 3 for 41 to 50yrs;"}<br/>
                  {"# value of 4 for 51 to 60yrs;"}<br/>
                  {"# value of 5 for age 61yrs or over"}<br/><br/>
                  if <span style={blueBack}>{'\u00A0Age\u00A0'}</span>{' <= 20 :'}<br/>
                  {indent+"ageGroup = 1"}<br/>
                  elif <span style={blueBack}>{'\u00A0Age\u00A0'}</span>{' <= 40 :'}<br/>
                  {indent+"# elif is pronouced 'Else If'"}<br/>
                  {indent+"# Try this test if prior test failed"}<br/>
                  {indent+"# All ages 20yrs or younger were already assigned ageGroup 1"}<br/>
                  {indent+"# So we only need to ask if age <= 40 for this test"}<br/>
                  {indent+"ageGroup = 2"}<br/>
                  elif <span style={blueBack}>{'\u00A0Age\u00A0'}</span>{' <= 50 :'}<br/>
                  {indent+"# Try this test if ALL prior tests failed"}<br/>
                  {indent+"ageGroup = 3"}<br/>
                  elif <span style={blueBack}>{'\u00A0Age\u00A0'}</span>{' <= 60 :'}<br/>
                  {indent+"ageGroup = 4"}<br/>
                  {'else :'}<br/>
                  {indent+"# Execute this indented block if ALL prior tests failed"}<br/>
                  {indent+"ageGroup = 5"}<br/>
                  return ageGroup<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:exampleGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:exampleGap,
                           width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Example #10: Prior example with early returns"}</b><br/><br/>
                  {"# Determine an ageGroup index from Age"}<br/>
                  if <span style={blueBack}>{'\u00A0Age\u00A0'}</span>{' <= 20 :'}<br/>
                  {indent+"return 1"}<br/>
                  elif <span style={blueBack}>{'\u00A0Age\u00A0'}</span>{' <= 40 :'}<br/>
                  {indent+"return 2"}<br/>
                  elif <span style={blueBack}>{'\u00A0Age\u00A0'}</span>{' <= 50 :'}<br/>
                  {indent+"return 3"}<br/>
                  elif <span style={blueBack}>{'\u00A0Age\u00A0'}</span>{' <= 60 :'}<br/>
                  {indent+"return 4"}<br/>
                  {'else :'}<br/>
                  {indent+"return 5"}<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:exampleGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:exampleGap,
                           width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Example #11: Prior example with nested if statement"}</b><br/><br/>
                  {"# Return an ageGroup index"}<br/>
                  {"# EXCEPT age 37 is ageGroup -1 !!!!!"}<br/><br/>
                  if <span style={blueBack}>{'\u00A0Age\u00A0'}</span>{' <= 20 :'}<br/>
                  {indent+"return 1"}<br/>
                  elif <span style={blueBack}>{'\u00A0Age\u00A0'}</span>{' <= 40 :'}<br/>
                  {indent+"if "}<span style={blueBack}>{'\u00A0Age\u00A0'}</span>{' == 37 :'}<br/>
                  {indent+indent+indent+"return -1"}<br/>
                  {indent+"else :  # This else belongs to line:\u00A0\u00A0 if Age == 37"}<br/>
                  {indent+indent+indent+"return 2"}<br/>
                  elif <span style={blueBack}>{'\u00A0Age\u00A0'}</span>{' <= 50 :'}<br/>
                  {indent+"return 3"}<br/>
                  elif <span style={blueBack}>{'\u00A0Age\u00A0'}</span>{' <= 60 :'}<br/>
                  {indent+"return 4"}<br/>
                  {"else :  # This else belongs to line: if Age <= 20"}<br/>
                  {indent+"return 5"}<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:exampleGap, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:exampleGap,
                           width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Example #12: isnan  (is Not a Number)"}</b><br/><br/>
                  {"# Return 'Age' + 'Age Adjustment'"}<br/>
                  {"# Where 'Age Adjustment' is based on other health factors"}<br/>
                  {"# If either 'Age' or 'AgeAdjustment' is missing or erroroneous, the"}<br/>
                  {"# default behavior is the calculation will return 'NaN'"}<br/>
                  {"# Use isnan( ) to optionally override the default behavior"}<br/><br/>
                  {"if isnan( "}<span style={blueBack}>{'\u00A0Age Adjustment\u00A0'}</span>{' ) :'}<br/>
                  {indent+indent+"# Takes this path if 'Age adjustment' is missing or erroneous"}<br/>
                  {indent+indent+"# returns age (no adjustment), rather than 'NaN'"}<br/>
                  {indent+indent+"return "}<span style={blueBack}>{'\u00A0Age\u00A0'}</span><br/>
                  {"if isnan( "}<span style={blueBack}>{'\u00A0Age\u00A0'}</span>{' ) :'}<br/>
                  {indent+indent+"# No age! What to return?"}<br/>
                  {indent+indent+"# Return an empty cell, rather than 'NaN'"}<br/>
                  {indent+indent+"return None"}<br/>
                  {"# Otherwise both values are valid, so return the sum"}<br/>
                  return <span style={blueBack}>{'\u00A0Age\u00A0'}</span> + <span style={blueBack}>{'\u00A0Age Adjustment\u00A0'}</span><br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:vertGap+8, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:vertGap+8,
                             width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Compound Questions:\u00A0\u00A0Using keywords 'and', 'or', 'not'."}</b><br/>
                  {"Write tests as you would speak. For example:"}<br/>
                  {"To select the young or the old: if age<10 or age>60"}<br/>
                  {"To select males that own dogs: if isMale and isDogOwner"}<br/>
                  {"To select males that do not own dogs:  if isMale and not isDogOwner"}<br/>
                  {"However, this test:\u00A0\u00A0not ( isYoung and not isDogOwner )"}<br/>
                  {"is difficult to understand. Because people do not speak this way. "+
                  "If you have trouble writing a test, first write it in your natural language. "+
                  "Then write the formula to match your language.  See next example."}<br/>
                </div>
            </div>

            <div>
                <div style={{display:'inline-block', marginTop:vertGap+8, verticalAlign:'top'}}>{bullet}</div>
                <div style={{display:'inline-block', marginTop:vertGap+8,
                             width: '94%', wordWrap: 'break-word'}}>
                  <b>{"Example #13: Complex testing"}</b><br/>
                  {"We will write a formula to return True or False, depending on who qualifies " +
                  "for a college tuition tax credit."}<br/>
                  {"To qualify for the credit:"}<br/>
                  {"\u00A0\u00A0- If a dependent of one parent and income less than $100K"}<br/>
                  {"\u00A0\u00A0- If a dependent of married couple and income less than $150K"}<br/>
                  {"\u00A0\u00A0- If you support yourself (independent) and income less than $50K"}<br/>
                  {"However, these people cannot get the credit:"}<br/>
                  {"\u00A0\u00A0- Enrolled in 2yr program and received 2 prior credits"}<br/>
                  {"\u00A0\u00A0- Enrolled in 4yr program and received 4 prior credits"}<br/>
                  {"\u00A0\u00A0- Those who are not a citizens."}<br/>
                  {"\u00A0\u00A0- Those not enrolled in an approved college."}<br/>
                  {"\u00A0\u00A0- Those not full-time students ( class load < 16 hours )."}<br/>
                  {"Write the formula to match how you would speak. " +
                  "The rules for NOT getting the credit have higher priority, so ask these questions first."}<br/>
                </div>
            </div>


            <div>
                <div style={{display:'inline-block', marginTop:vertGap+8, marginLeft:50, wordWrap: 'break-word'}}>
                  {"# Description: Does this person qualify for a tuition tax credit?"}<br/>
                  {"# NOTE: Column names can include symbols such as +, /, and #"}<br/>
                <br/>

                {'# Tests that eliminate people who cannot get a tax credit.'}<br/>
                  {'if\u00A0\u00A0not '}<span style={blueBack}>{'\u00A0Is Citizen\u00A0'}</span>{' :'}<br/>
                      {indent+"return False"}<br/>

                  {'elif\u00A0\u00A0'}<span style={blueBack}>{'\u00A0Is 2yr Degree\u00A0'}</span>
                  {' and '}<span style={blueBack}>{'\u00A0# Prior Credits\u00A0'}</span>{' >= 2 :'}<br/>
                      {indent+"return False"}<br/>

                  {'elif\u00A0\u00A0'}<span style={blueBack}>{'\u00A0Is 4+yr Degree\u00A0'}</span>
                  {' and '}<span style={blueBack}>{'\u00A0# Prior Credits\u00A0'}</span>{' >= 4 :'}<br/>
                  {indent+"return False"}<br/>

                  {'elif\u00A0\u00A0 not'}<span style={blueBack}>{'\u00A0Is College Student\u00A0'}</span><br/>
                  {indent+"return False"}<br/>

                  {'elif\u00A0\u00A0'}<span style={blueBack}>{'\u00A0Class Load (hrs/wk)\u00A0'}</span>{' < 16 :'}<br/>
                  {indent+"return False"}<br/><br/>

                  {'# Tests that include people who qualify for a tax credit.'}<br/>
                  {'elif\u00A0\u00A0'}<span style={blueBack}>{'\u00A0Is Independent\u00A0'}</span>
                  {' and '}<span style={blueBack}>{'\u00A0Income\u00A0'}</span>{' < 50e3 :'}<br/>
                  {indent+"return True"}<br/>

                  {'elif\u00A0\u00A0'}<span style={blueBack}>{'\u00A0Is Dependent\u00A0'}</span>
                  {' and '}<span style={blueBack}>{'\u00A0# Parents\u00A0'}</span>{' == 1'}
                  {' and '}<span style={blueBack}>{'\u00A0Income\u00A0'}</span>{' < 100e3 :'}<br/>
                  {indent+"return True"}<br/>

                  {'elif\u00A0\u00A0'}<span style={blueBack}>{'\u00A0Is Dependent\u00A0'}</span>
                  {' and '}<span style={blueBack}>{'\u00A0# Parents\u00A0'}</span>{' == 2'}
                  {' and '}<span style={blueBack}>{'\u00A0Income\u00A0'}</span>{' < 150e3 :'}<br/>
                  {indent+"return True"}<br/><br/>

                  {"# The default for those who do not qualify is 'False'"}<br/>
                  {"else :"}<br/>
                  {indent+"return False"}<br/>
                </div>
            </div>





            {/* final cosmetic vert spacer */}
            <div style={{height:10}}/>

      </div>
    )
  }
}
