
// See design note: documentation/CreatePlot_DesignOverview.txt
import type { AnyAction, Dispatch } from '@reduxjs/toolkit'
import type { MouseEvent, ReactNode } from 'react'
import type { RouteComponentProps } from 'react-router-dom'
import type { RootState } from '../redux/store'
import type { MenuItems } from '../sharedComponents/EditorMenuButton'
import type { Table, Plot, Tablelook, LightweightMod, PlotColDataType, RouterHistory } from '../types'

import {PureComponent, Fragment}   from 'react'
import {connect}                from 'react-redux'
import { withRouter } from 'react-router-dom'
import EditorMenuButton         from '../sharedComponents/EditorMenuButton'
import constants                from '../sharedComponents/constants'
import {getThumbnail}  from '../redux/thumbnailThunks'
import {clonePlotThunk, createPlotThunk} from '../redux/plotThunks'
import dynamics                 from 'dynamics.js'

import FloatingPalette      from '../floatingPalette/FloatingPalette'
import HorzTaperedDivider   from '../sharedComponents/HorzTaperedDivider'
import { asyncDispatch } from '../sharedFunctions/utils'
import SVGwrapper2          from '../SVGs/SVGwrapper2'
import {ScatterPoints,
        ScatterLines,
        ScatterParametric,
        HistogramVert,
        ProbDensityVert,
        Percentile,
        HistogramHorz,
        ProbDensityHorz,
        StackedBarsVert,
        StackedAreaVert,
        SideBySideBarsVert,
        StackedBarsHorz,
        StackedAreaHorz,
        SideBySideBarsHorz, } from './plotPickerSVGs'
import { TableComputedData } from '../computedDataTable/getDefaultTableComputedData'


const FLOATING_PALETTE_BACKGROUND = constants.FLOATING_PALETTE_BACKGROUND
const TOTAL_HEIGHT = 612
const TOTAL_WIDTH  = 502
const INITIAL_PLACEMENT_TOP  = constants.TOP_MENUBAR_HEIGHT + 50
const INITIAL_PLACEMENT_LEFT = 200

const FAMILY_TITLE_TOPMARGIN = 8
const FAMILY_TITLE_HEIGHT = 20
const FAMILY_TITLE_FONT_SIZE = 18

const PLOT_TITLE_TOPMARGIN = 14
const PLOT_TITLE_HEIGHT = 15
const PLOT_TITLE_FONT_SIZE = 14

// This set the size of the SVG icons in phase1 (plot picker)
const SVG_CONTAINER_WIDTH   = 80
const SVG_CONTAINER_HEIGHT  = 66
const SVG_INNER_SQUARE_SIZE = 60

export const FLOATING_PALETTE_TITLE_BAR_HEIGHT = 20
export const FLOATING_PALETTE_TITLE_BAR_FONTSIZE = 14
export const FLOATING_PALETTE_BLUE_OUTLINE_WIDTH = 4
export const FLOATING_PALETTE_BLACK_OUTLINE_WIDTH = 1

const FLOATING_PALETTE_TITLE_HEIGHT = constants.FLOATING_PALETTE_TITLE_BAR_HEIGHT + constants.FLOATING_PALETTE_BLUE_OUTLINE_WIDTH
const FLOATING_PALETTE_LEFT_BORDER_WIDTH= constants.FLOATING_PALETTE_BLACK_OUTLINE_WIDTH + constants.FLOATING_PALETTE_BLUE_OUTLINE_WIDTH

// Where the selected plot type is positioned in phase3:
const SVG_LEFT_CENTERED_PHASE3 = 200
const SVG_LEFT_SHIFTED_PHASE3 = 320
const SELECTION_LEFT_CENTERED_PHASE3 = 120
const SELECTION_LEFT_SHIFTED_PHASE3 = 200
const SELECTION_TOP_PHASE3 = 160
const SELECTION_SCALE_PHASE3 = 2


const COL_INPUT_SELECTOR_WIDTH = 200

const SVG_CONTAINER_BOTTOMMARGIN = 10
const LEFT_RIGHT_MARGIN  = 6

const singleTitleRowHeight =
  + PLOT_TITLE_HEIGHT
  + SVG_CONTAINER_HEIGHT

const doubleTitleRowHeight =
  + 2 * PLOT_TITLE_HEIGHT
  + SVG_CONTAINER_HEIGHT

const scatterFamilyContainerHeight =
  + FAMILY_TITLE_TOPMARGIN
  + FAMILY_TITLE_HEIGHT
  + PLOT_TITLE_TOPMARGIN
  + singleTitleRowHeight
  + SVG_CONTAINER_BOTTOMMARGIN

const distributionFamilyContainerHeight =
    + FAMILY_TITLE_TOPMARGIN
    + FAMILY_TITLE_HEIGHT
    + PLOT_TITLE_TOPMARGIN
    + 1 * doubleTitleRowHeight
    + SVG_CONTAINER_BOTTOMMARGIN

const clonedPlotContainerHeight =
  + FAMILY_TITLE_TOPMARGIN
  + FAMILY_TITLE_HEIGHT
  + SVG_CONTAINER_HEIGHT
  + 30


const familyContainerStyle: React.CSSProperties = {
  width:6*SVG_CONTAINER_WIDTH + 2 * LEFT_RIGHT_MARGIN,
  background: FLOATING_PALETTE_BACKGROUND,
  flex: '1 1 auto',
}
const familyTitleStyle: React.CSSProperties = {
  fontSize : FAMILY_TITLE_FONT_SIZE,
  fontWeight: 'bold',
  height: FAMILY_TITLE_HEIGHT,
  paddingTop: (FAMILY_TITLE_HEIGHT - FAMILY_TITLE_FONT_SIZE)/2,
  marginTop: FAMILY_TITLE_TOPMARGIN,
  marginBottom: PLOT_TITLE_TOPMARGIN,
  textAlign: 'center',
  //background: 'pink',
}
const plotTitleStyleLine1: React.CSSProperties = {
  fontSize : PLOT_TITLE_FONT_SIZE,
  height: PLOT_TITLE_HEIGHT,
  paddingTop: (PLOT_TITLE_HEIGHT - PLOT_TITLE_FONT_SIZE)/2,
  textAlign: 'center',
}
const plotTitleStyleLine2: React.CSSProperties = {
  fontSize : PLOT_TITLE_FONT_SIZE,
  height: PLOT_TITLE_FONT_SIZE,
  paddingTop: (PLOT_TITLE_HEIGHT - PLOT_TITLE_FONT_SIZE)/2,
  textAlign: 'center',
}
const svgContainerStyle: React.CSSProperties = {
  width:SVG_CONTAINER_WIDTH,
  height:SVG_CONTAINER_HEIGHT,
  flex: '0 0 auto',
  //background:'orange',
}
const rowStyle: React.CSSProperties = {
  marginLeft: LEFT_RIGHT_MARGIN,
  marginRight: LEFT_RIGHT_MARGIN,
  display:'flex',
  flexFlow: 'row nowrap',
}
const plotOnClickContainerStyle: React.CSSProperties = {
  width:SVG_CONTAINER_WIDTH,
}

const handleCreateNewPlotNoOp = (event: MouseEvent<HTMLDivElement>, plotColDataType: PlotColDataType, renderedLayerID: string, isTransposed: boolean, title1: string, title2: string, childIcon: ReactNode): void => { }

// Define a react 'button' that displays each plot style alternative.
// The button consists of a title1, optional title2, SVG container,
// and a child ReactNode which is a SVG image.
type PlotSelectionProps = {
  title1:string,
  title2:string,
  plotColDataType: PlotColDataType,
  renderedLayerID: string, 
  isTransposed: boolean,
  childIcon: ReactNode,
  handleCreateNewPlot: (event: MouseEvent<HTMLDivElement>, plotColDataType: PlotColDataType, renderedLayerID: string, isTransposed: boolean, title1: string, title2: string, childIcon: ReactNode) => void,
  phase: number,
  plotName?: string
}
class PlotSelectionButton extends PureComponent<PlotSelectionProps> {

  render() {
    const {title1, title2, plotColDataType, renderedLayerID, isTransposed, childIcon, phase, handleCreateNewPlot} = this.props
    const height = (title2 === '') ? singleTitleRowHeight : doubleTitleRowHeight
    return (
          <div className={'PlotSelectionButton'} style={{...plotOnClickContainerStyle, height}}
            onClick={ phase === 1
                        ? (e)=>handleCreateNewPlot(e, plotColDataType, renderedLayerID, isTransposed, title1, title2, childIcon)
                        : ()=>{}
                    }>
              <div className={'PlotTitle'} style={plotTitleStyleLine1}>{title1}</div>
              { title2 !== '' &&
              <div className={'PlotTitle'} style={plotTitleStyleLine2}>{title2}</div>
              }
              <div className={'PlotIcon'}  style={svgContainerStyle}>
                <SVGwrapper2> {childIcon} </SVGwrapper2>
              </div>
          </div>
    )
  }
}

type IndexAnimationObj = {
  index: number
}

type RouteMatchProps = {
}
type OwnProps = {
  userid: string,
  tableComputedData: TableComputedData,
  plot    : Plot | null,
  closePopup  : () => void,
}
type StateProps = {
  plotThumbnailURL: string
}
type DispatchProps = {
  clonePlot: (plot: Plot, mods: LightweightMod[], tablelook: Tablelook, userid: string, history: RouterHistory) => void
  createPlotResource: (mods: LightweightMod[], table: Table, tablelook: Tablelook, history: RouterHistory) => void
  ensureThumbnail: (plotid: string) => void
}
type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<RouteMatchProps>
type LocalState = {
  userid: string,
  currentLeft:number,
  currentTop: number,
  priorPlot: Plot | null,
  selectionX: number,
  selectionY: number,
  SelectionComponent: ReactNode,

  plotColDataType: PlotColDataType,
  renderedLayerID: string,
  isTransposed: boolean,

  title1:string,
  title2:string,
  leftAxisColIndex: number,
  bottomAxisColIndex: number,
  parametricColIndex: number,
  isLeftColRequired: boolean,
  isBottomColRequired: boolean,
  isParametricColRequired: boolean,
  phase: number,       // Three subsequent phases called 1, 2, and 3
}
class CreatePlotXyRender extends PureComponent<Props, LocalState> {

  constructor (props: Props) {
    super(props)
    this.state = {
      userid: '', 
      currentLeft: INITIAL_PLACEMENT_LEFT,
      currentTop : INITIAL_PLACEMENT_TOP,
      priorPlot: null,
      selectionX: 0,
      selectionY: 0,
      SelectionComponent: null,

      plotColDataType: '2Col',
      renderedLayerID: '', 
      isTransposed: false,

      title1:'',
      title2:'',
      leftAxisColIndex: -1,    // Minus 1 means unset colKey (renders as empty)
      bottomAxisColIndex: -1,
      parametricColIndex: -1,
      isLeftColRequired: false,
      isBottomColRequired: true,
      isParametricColRequired: false,
      phase: 1,
    }
  }

  // Nodes used for animation during Phase2
  phase1MainDiv : HTMLDivElement | null = null
  phase3MainDiv : HTMLDivElement | null = null
  phase2ComponentDiv: HTMLDivElement | null = null

  componentDidMount () {
    const {plot, ensureThumbnail} = this.props
    if (plot) {
      ensureThumbnail(plot.id)
    }
  }

  componentDidUpdate ():void {
    if ( this.state.phase !== 2 ) { return }

    // If this is phase two, we start the 'transition animation'.
    // On completion of the animation, we setState to phase '3'.
    const {selectionX, selectionY} = this.state
    const {phase1MainDiv, phase3MainDiv, phase2ComponentDiv} = this
    const svgLeftPhase3 = (this.state.isLeftColRequired) ? SVG_LEFT_SHIFTED_PHASE3 : SVG_LEFT_CENTERED_PHASE3
    dynamics.animate (
      // Next two objects are Start && Stop parameter values.
      { index: 0   },
      { index: 500 },
      { // Options:
        change: (obj: IndexAnimationObj)=> {
          // Phase 1 opacity goes from 1.00 to 1.00 over first 100 steps
          var opacity1 = 1 - (Math.min( obj.index, 100 ) / 100)
          // Phase 3 opacity goes from 0 to 1.00 over last 100 steps
          var opacity3 = Math.max( obj.index-400, 0)/100
          // Component Left/Top goes from (selectionX, selectionY) to (svgLeftPhase3, SELECTION_TOP_PHASE3)
          // over index of 100 to 400
          var positionIndex = Math.max(Math.min((obj.index - 100), 300),0)
          var deltaX = svgLeftPhase3  - selectionX
          var deltaY = SELECTION_TOP_PHASE3 - selectionY
          var positionX = selectionX + (deltaX * positionIndex / 300)
          var positionY = selectionY + (deltaY * positionIndex / 300)
          // Component Scale goes from 1 to SELECTION_SCALE_PHASE3 over index 100 to 300
          var deltaScale = SELECTION_SCALE_PHASE3 - 1
          var scale = 1 + (deltaScale * positionIndex / 300)
          if (phase1MainDiv && phase3MainDiv && phase2ComponentDiv) {
            phase1MainDiv.style.opacity= String(opacity1)
            phase3MainDiv.style.opacity= String(opacity3)
            phase2ComponentDiv.style.left= `${positionX}px`
            phase2ComponentDiv.style.top = `${positionY}px`
            phase2ComponentDiv.style.transform = `scale(${scale})`
          }
        },
        complete:( ) => {
          if (phase1MainDiv && phase3MainDiv && phase2ComponentDiv) {
            // Reset react DomTree to it's pre-animation state:
            phase1MainDiv.style.opacity = '1'
            phase3MainDiv.style.opacity = '0'
            phase2ComponentDiv.style.left= `${selectionX}px`
            phase2ComponentDiv.style.top = `${selectionY}px`
            phase2ComponentDiv.style.transform = 'scale(1)'
          }
          this.setState({phase:3})
        },
        duration: 500,
      }
    )


  }


  onActiveDrag = (left:number, top:number) : void => {
    // Constraint the position:
    top = Math.max( 20, top )
    top = Math.min( window.innerHeight - TOTAL_HEIGHT - 20, top )
    left= Math.max( 20, left )
    left= Math.min( window.innerWidth - TOTAL_WIDTH - 20, left )
    this.setState( {currentLeft:left, currentTop:top} )
  }

  handleSetLeftAxisColIndex = ( colIndex: string ):void => {
    this.setState({ leftAxisColIndex: Number(colIndex) })
  }
  handleSetBottomAxisColIndex = ( colIndex: string ):void => {
    this.setState({ bottomAxisColIndex: Number(colIndex) })
  }
  handleSetParametricColIndex = ( colIndex: string ):void => {
    this.setState({ parametricColIndex: Number(colIndex)})
  }

  handleRevertBackToPhase1 = ()=>{
    this.setState({
      selectionX: 0,
      selectionY: 0,
      SelectionComponent: null,
      title1:'',
      title2:'',
      leftAxisColIndex: -1,
      bottomAxisColIndex: -1,
      parametricColIndex: -1,
      isLeftColRequired: false,
      isBottomColRequired: true,
      isParametricColRequired: false,
      phase: 1,

      plotColDataType: '2Col',
      renderedLayerID: '',
      isTransposed: false,
    })
  }


  // Partial creation from information in Phase1
  handleCreateNewPlot1 = ( e: MouseEvent<HTMLDivElement>, plotColDataType:PlotColDataType, renderedLayerID:string,
           isTransposed:boolean, title1:string, title2:string,  SelectionComponent:ReactNode ): void => {

    var isBottomColRequired     = true   // Assumption
    var isLeftColRequired       = true   // Assumption
    var isParametricColRequired = false  // Assumption
    if (plotColDataType === '1Col' && !isTransposed) { isLeftColRequired = false }
    if (plotColDataType === '1Col' &&  isTransposed) { isBottomColRequired = false }
    if (plotColDataType === '3Col') { isParametricColRequired = true }

    const rect = e.currentTarget.getBoundingClientRect()
    this.setState({
      selectionX: rect.left - this.state.currentLeft,
                  //+ constants.FLOATING_PALETTE_BLUE_OUTLINE_WIDTH
                  //+ constants.FLOATING_PALETTE_BLACK_OUTLINE_WIDTH,
      selectionY: rect.top - this.state.currentTop,
                  //+ constants.FLOATING_PALETTE_TITLE_BAR_HEIGHT
                  //+ constants.FLOATING_PALETTE_BLUE_OUTLINE_WIDTH,
      SelectionComponent,
      phase: 2,
      title1,
      title2,
      isBottomColRequired,
      isLeftColRequired,
      isParametricColRequired,
      plotColDataType, 
      renderedLayerID,
      isTransposed,
    })
  }



  // Final plot creation including information from phase1 and phase3
  handleCreateNewPlot3 = () => {
    var { plotColDataType, renderedLayerID, isTransposed, leftAxisColIndex,
          bottomAxisColIndex, parametricColIndex} = this.state
    const {history, closePopup, createPlotResource} = this.props
    const {table, tablelook} = this.props.tableComputedData
    const leftAxisColKey = (leftAxisColIndex === -1 ) ? -1 : this.props.tableComputedData.derivedColOrder[leftAxisColIndex]
    const bottomAxisColKey = (bottomAxisColIndex === -1 ) ? -1 : this.props.tableComputedData.derivedColOrder[bottomAxisColIndex]
    const parametricColKey = (parametricColIndex === -1 ) ? -1 : this.props.tableComputedData.derivedColOrder[parametricColIndex]
    // Convert left/bottom axis references to basis A or B references, depending on the transpose
    const colKeyA = isTransposed ? leftAxisColKey   : bottomAxisColKey
    const colKeyB = isTransposed ? bottomAxisColKey : leftAxisColKey
    var colTitleB = (colKeyB >=0) ? table.attributes.columns[colKeyB].colTitle : ''
    var colTitleA = (colKeyA >=0) ? table.attributes.columns[colKeyA].colTitle : ''
    // Pick some reasonable choice for the 1st series title.
    var seriesTitle = colTitleB  // assumption
    if ( plotColDataType === '1Col' ) {
      seriesTitle = colTitleA
    } else if ( plotColDataType === '3Col' ) {
      seriesTitle = 'Parametric1'
    }
    // Plot Level Attributes:
    const renderedLayersArr = [ renderedLayerID ]
    const mods = []
    mods.push({path:'attributes.plotColDataType',newVal:plotColDataType})
    mods.push({path:'attributes.renderedLayersArr',newVal:renderedLayersArr})
    mods.push({path:'attributes.isTransposed',newVal:isTransposed})
    mods.push({path:'attributes.mainTitle', newVal: 'My New Plot'})
    // 1st series attributes
    mods.push({path:'attributes.series[0].seriesTitle', newVal: seriesTitle})
    mods.push({path:'attributes.series[0].colKeyA',     newVal: colKeyA})
    mods.push({path:'attributes.series[0].colKeyB',     newVal: colKeyB})
    if ( plotColDataType === '3Col' ) {
      mods.push({path:'attributes.series[0].sortBy', newVal: 'valueColKey'})
      mods.push({path:'attributes.series[0].sortByColKey', newVal: parametricColKey})
    }
    // Axis titles set to match the assigned data's column Title
    mods.push({path:'attributes.basisA.axisTitle', newVal: colTitleA})
    if ( plotColDataType !== '1Col' ) {
      mods.push({path:'attributes.basisB.axisTitle', newVal: colTitleB})
    }
    //Create a default Title based on the data
    //Question: Should parametric / 3col titles be different?
    var mainTitle = colTitleA
    if ( plotColDataType !== '1Col' ) {
      mainTitle = colTitleB + ' vs ' + colTitleA
    }
    mods.push({path:'attributes.mainTitle', newVal: mainTitle})
    createPlotResource( mods, table, tablelook, history )
    closePopup( )
  }


  handleCloneActivePlot = ( ): void => {
    const {plot, clonePlot, history, closePopup, userid, tableComputedData} = this.props
    const {tablelook} = tableComputedData
    if (!plot) { return }
    let mods = []
    mods.push({path:'attributes.mainTitle', newVal:'CLONED PLOT: ' + plot.attributes.mainTitle})
    clonePlot( plot, mods, tablelook, userid, history )
    closePopup( )
  }

  setPhase1MainDivNode = (element: HTMLDivElement | null): void => {
    this.phase1MainDiv = element
  }
  setPhase2ComponentDivNode = (element: HTMLDivElement | null): void => {
    this.phase2ComponentDiv = element
  }
  setPhase3MainDivNode = (element: HTMLDivElement | null): void => {
    this.phase3MainDiv = element
  }

  render() {

    //console.log( 'Call to CreatePlot - props =', this.props )
    const {currentLeft, currentTop, phase, selectionX, selectionY,
        title1, title2, plotColDataType, renderedLayerID, isTransposed,
        SelectionComponent, leftAxisColIndex, bottomAxisColIndex, parametricColIndex,
        isLeftColRequired, isBottomColRequired, isParametricColRequired } = this.state
    const {closePopup, plot, plotThumbnailURL, tableComputedData} = this.props
    const {table, tablelook} = tableComputedData
    const svgLeftPhase3 = (isLeftColRequired) ? SVG_LEFT_SHIFTED_PHASE3 : SVG_LEFT_CENTERED_PHASE3

    // Table and Tablelook resources may not yet be available
    // In which case we just don't open the popup (yet).
    if ( !table || !tablelook ) { return null }

    return (

      <FloatingPalette
        left={currentLeft}
        top={currentTop}
        height={TOTAL_HEIGHT}
        width={TOTAL_WIDTH}
        onClose={closePopup}
        onActiveDrag={this.onActiveDrag}
        title={ (phase <= 2) ? 'Choose a Plot Style:' : 'Define the 1st Data Series:' }
        isDraggable={true}
      >

{/* The plotPicker options */}
{ phase <= 2 &&
              <div className={'Phase2ComponentImage'}
                ref={ this.setPhase1MainDivNode }>
                        < CreatePlotPhase1Render
                          plot={plot}
                          handleCreateNewPlot={this.handleCreateNewPlot1}
                          handleCloneExistingPlot={this.handleCloneActivePlot}
                          currentThumbnail={plotThumbnailURL}   // format is dataURL
                        />
              </div>
}

{phase === 2 && <Fragment>

              <div className={'ScaledSelectionContainer'}
                ref={this.setPhase2ComponentDivNode}
                style={{
                  position:'absolute', left:selectionX, top:selectionY,
                  transform: `scale(1)`,
                  opacity:1,
                }}>
                        <PlotSelectionButton  title1={title1} title2={title2}
                          plotColDataType={plotColDataType} renderedLayerID={renderedLayerID}
                          isTransposed={isTransposed}
                          handleCreateNewPlot={ handleCreateNewPlotNoOp } phase={2}
                          childIcon={SelectionComponent}
                        />
              </div>

              <div className={'opacityContainer'}
                ref={ this.setPhase3MainDivNode }
                style={{
                  position:'absolute',
                  left:FLOATING_PALETTE_LEFT_BORDER_WIDTH,
                  top:FLOATING_PALETTE_TITLE_HEIGHT,
                  opacity:0
                }}>
                      <CreatePlotPhase3Render
                        tableComputedData={tableComputedData}
                        leftAxisColIndex={leftAxisColIndex}
                        bottomAxisColIndex={bottomAxisColIndex}
                        parametricColIndex={parametricColIndex}
                        handleSetLeftAxisColIndex={this.handleSetLeftAxisColIndex}
                        handleSetBottomAxisColIndex={this.handleSetBottomAxisColIndex}
                        handleSetParametricColIndex={this.handleSetParametricColIndex}
                        handleCreateNewPlot3={this.handleCreateNewPlot3}
                        handleRevertBackToPhase1={this.handleRevertBackToPhase1}
                        isLeftColRequired={isLeftColRequired}
                        isBottomColRequired={isBottomColRequired}
                        isParametricColRequired={isParametricColRequired}
                      />
              </div>

</Fragment>}


{/* The columnData picker options */}
{ phase === 3 && <Fragment>
            <div className={'opacityContainer'}
              ref={ this.setPhase3MainDivNode }
              style={{
                position:'absolute',
                left:FLOATING_PALETTE_LEFT_BORDER_WIDTH,
                top:FLOATING_PALETTE_TITLE_HEIGHT,
              }}>
                      <CreatePlotPhase3Render
                        tableComputedData={tableComputedData}
                        leftAxisColIndex={leftAxisColIndex}
                        bottomAxisColIndex={bottomAxisColIndex}
                        parametricColIndex={parametricColIndex}
                        handleSetLeftAxisColIndex={this.handleSetLeftAxisColIndex}
                        handleSetBottomAxisColIndex={this.handleSetBottomAxisColIndex}
                        handleSetParametricColIndex={this.handleSetParametricColIndex}
                        handleCreateNewPlot3={this.handleCreateNewPlot3}
                        handleRevertBackToPhase1={this.handleRevertBackToPhase1}
                        isLeftColRequired={isLeftColRequired}
                        isBottomColRequired={isBottomColRequired}
                        isParametricColRequired={isParametricColRequired}
                      />
            </div>

            <div className={'ScaledSelectionContainer'}
              style={{
                position:'absolute', left:svgLeftPhase3, top:SELECTION_TOP_PHASE3,
                transform: `scale(${SELECTION_SCALE_PHASE3})`,
              }}>
                      <PlotSelectionButton  title1={title1} title2={title2}
                        plotColDataType={plotColDataType} renderedLayerID={renderedLayerID}
                        isTransposed={isTransposed}
                        handleCreateNewPlot={ ()=>{} } phase={3}
                        childIcon={SelectionComponent}
                      />
            </div>

</Fragment>}



      </FloatingPalette>
    )
  }
}



const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps => (
  {
    clonePlot: (plot:Plot, mods: LightweightMod[], tablelook:Tablelook, userid:string,
                   history: RouterHistory): void => {
      // clonePlotThunk is only called from two locations:
      //     1) Here - When we want to clone a plot using clonePlot in plot picker.
      //     2) ReactDispatch - When 1st style edit is made to another owner's plot.
      asyncDispatch(dispatch, clonePlotThunk(plot, mods, tablelook, userid, history))
    },
    createPlotResource: (mods: LightweightMod[], table: Table, tablelook: Tablelook,
                   history: RouterHistory ): void => {
      asyncDispatch(dispatch, createPlotThunk(mods, table, tablelook, history))
    },
    ensureThumbnail: (plotid: string): void => {
      asyncDispatch(dispatch, getThumbnail(plotid))
    }
  }
)

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const {plot} = ownProps
  const plotid = plot ? plot.id : ''
  return {
    plotThumbnailURL: state.thumbnail[plotid] ?? null
  }
}

const CreatePlotConnected = connect(mapStateToProps, mapDispatchToProps)(CreatePlotXyRender)
const CreatePlot = withRouter(CreatePlotConnected)

export default CreatePlot




type Phase1Props = {
  plot    : Plot | null,
  handleCreateNewPlot: (event: MouseEvent<HTMLDivElement>, plotColDataType: PlotColDataType, renderedLayerID: string, isTransposed: boolean, title1: string, title2: string, childIcon: ReactNode) => void
  handleCloneExistingPlot: ()=>void,
  currentThumbnail: string ,   // format is dataURL
}
class CreatePlotPhase1Render extends PureComponent<Phase1Props> {

  render() {
    const {plot, handleCreateNewPlot, handleCloneExistingPlot, currentThumbnail} = this.props
    const plotid = plot ? plot.id : ''
    var cloneHeaderMessage = ( plot ) ? 'Clone the Currently Active Plot' : 'Clone an Existing Plot'
    var mainTitle = ( plot ) ? plot.attributes.mainTitle : ''

    return (
      <div className={'rc_CreatePlot'}
        style={{
          position:'relative',
          display: 'flex', flexFlow: 'column nowrap',
        }}
      >
        <div className={'ScatterFamilyContainer'} style={{...familyContainerStyle, height:scatterFamilyContainerHeight}} >
            <div className={'FamilyTitle'} style={familyTitleStyle}> Scatter Plot Family </div>
            <div className={'RowOfPlotOptions'} style={{...rowStyle, marginLeft: 1.5 * SVG_CONTAINER_WIDTH}}>
              <PlotSelectionButton  title1={'Points'} title2={''} 
                  plotColDataType={'2Col'} renderedLayerID={'tableRow:marks:[0]'} isTransposed={false}
                  handleCreateNewPlot={handleCreateNewPlot} phase={1}
                  childIcon={ <ScatterPoints svgSquareSize={SVG_INNER_SQUARE_SIZE}/> }
                />
              <PlotSelectionButton  title1={'Lines'} title2={''} 
                  plotColDataType={'2Col'} renderedLayerID={'tableRow:line:[0]'} isTransposed={false}
                  handleCreateNewPlot={handleCreateNewPlot} phase={1}
                  childIcon={ <ScatterLines svgSquareSize={SVG_INNER_SQUARE_SIZE}/> }
                />
              <PlotSelectionButton  title1={'Parametric'} title2={''}  
                  plotColDataType={'3Col'} renderedLayerID={'tableRow:line:[0]'} isTransposed={false}
                  handleCreateNewPlot={handleCreateNewPlot} phase={1}
                  childIcon={ <ScatterParametric svgSquareSize={SVG_INNER_SQUARE_SIZE}/> }
                />
            </div>
         </div>


         <HorzTaperedDivider background={FLOATING_PALETTE_BACKGROUND} />
         <div className={'DistributionFamilyContainer'} style={{...familyContainerStyle, height:distributionFamilyContainerHeight}} >
              <div className={'FamilyTitle'} style={familyTitleStyle}>Distribution Plot Family</div>
              <div className={'RowOfPlotOptions'} style={{...rowStyle, marginLeft: 0.5 * SVG_CONTAINER_WIDTH}}>
                  <PlotSelectionButton  title1={'Histogram'} title2={'Vert Bars'} 
                    plotColDataType={'1Col'} renderedLayerID={'freq:bars:[0]'} isTransposed={false}
                    handleCreateNewPlot={handleCreateNewPlot} phase={1}
                    childIcon={ <HistogramVert svgSquareSize={SVG_INNER_SQUARE_SIZE}/> }
                  />
                  <PlotSelectionButton  title1={'Probability'} title2={'Density'} 
                    plotColDataType={'1Col'} renderedLayerID={'s_freq:line:[0]'} isTransposed={false}
                    handleCreateNewPlot={handleCreateNewPlot} phase={1}
                    childIcon={ <ProbDensityVert svgSquareSize={SVG_INNER_SQUARE_SIZE}/> }
                  />
                  <PlotSelectionButton  title1={'Percentile'} title2={' '} 
                    plotColDataType={'1Col'} renderedLayerID={'percentile:marks:[0]'} isTransposed={false}
                    handleCreateNewPlot={handleCreateNewPlot} phase={1}
                    childIcon={ <Percentile svgSquareSize={SVG_INNER_SQUARE_SIZE}/> }
                  />
                  <PlotSelectionButton  title1={'Histogram'} title2={'Horz Bars'} 
                    plotColDataType={'1Col'} renderedLayerID={'freq:bars:[0]'} isTransposed={true}
                    handleCreateNewPlot={handleCreateNewPlot} phase={1}
                    childIcon={ <HistogramHorz svgSquareSize={SVG_INNER_SQUARE_SIZE}/> }
                  />
                  <PlotSelectionButton  title1={'Probability'} title2={'Density'} 
                    plotColDataType={'1Col'} renderedLayerID={'s_freq:line:[0]'} isTransposed={true}
                    handleCreateNewPlot={handleCreateNewPlot} phase={1}
                    childIcon={ <ProbDensityHorz svgSquareSize={SVG_INNER_SQUARE_SIZE}/> }
                  />
              </div>
          </div>


          <HorzTaperedDivider background={FLOATING_PALETTE_BACKGROUND} />
          <div className={'StackedFamilyContainer'} style={{...familyContainerStyle, height:distributionFamilyContainerHeight}} >
               <div className={'FamilyTitle'} style={familyTitleStyle}>Bar & Stacked Family</div>
               <div className={'RowOfPlotOptions'} style={rowStyle}>
                  <PlotSelectionButton  title1={'Stacked'} title2={'Vert Bars'} 
                    plotName={'stackedBars'} isTransposed={false}
                    plotColDataType={'2Col'} renderedLayerID={'tableRow:stackedBars:[0]'}
                    handleCreateNewPlot={handleCreateNewPlot} phase={1}
                    childIcon={ <StackedBarsVert svgSquareSize={SVG_INNER_SQUARE_SIZE}/> }
                  />
                  <PlotSelectionButton  title1={'Stacked'} title2={'Vert Area'} 
                    plotName={'stackedArea'} isTransposed={false}
                    plotColDataType={'2Col'} renderedLayerID={'tableRow:stackedArea:[0]'}
                    handleCreateNewPlot={handleCreateNewPlot} phase={1}
                    childIcon={ <StackedAreaVert svgSquareSize={SVG_INNER_SQUARE_SIZE}/> }
                  />
                  <PlotSelectionButton  title1={'SideBySide'} title2={'Vert Bars'} 
                    plotName={'sideBySideStackedBars'} isTransposed={false}
                    plotColDataType={'2Col'} renderedLayerID={'tableRow:bars:[0]'}
                    handleCreateNewPlot={handleCreateNewPlot} phase={1}
                    childIcon={ <SideBySideBarsVert svgSquareSize={SVG_INNER_SQUARE_SIZE}/> }
                  />
                  <PlotSelectionButton  title1={'Stacked'} title2={'Horz Bars'} 
                    plotName={'stackedBars'} isTransposed={true}
                    plotColDataType={'2Col'} renderedLayerID={'tableRow:stackBars:[0]'}
                    handleCreateNewPlot={handleCreateNewPlot} phase={1}
                    childIcon={ <StackedBarsHorz svgSquareSize={SVG_INNER_SQUARE_SIZE}/> }
                  />
                  <PlotSelectionButton  title1={'Stacked'} title2={'Horz Area'} 
                    plotName={'stackedArea'} isTransposed={true}
                    plotColDataType={'2Col'} renderedLayerID={'tableRow:stackedArea:[0]'}
                    handleCreateNewPlot={handleCreateNewPlot} phase={1}
                    childIcon={ <StackedAreaHorz svgSquareSize={SVG_INNER_SQUARE_SIZE}/> }
                  />
                  <PlotSelectionButton  title1={'SideBySide'} title2={'Horz Bars'} 
                    plotName={'sideBySideStackedBars'} isTransposed={true}
                    plotColDataType={'2Col'} renderedLayerID={'tableRow:bars:[0]'}
                    handleCreateNewPlot={handleCreateNewPlot} phase={1}
                    childIcon={ <SideBySideBarsHorz svgSquareSize={SVG_INNER_SQUARE_SIZE}/> }
                  />
               </div>
           </div>



           <HorzTaperedDivider background={FLOATING_PALETTE_BACKGROUND} />
           <div className={'ClonedPlotContainer'} style={{...familyContainerStyle, height:clonedPlotContainerHeight}} >
                <div className={'FamilyTitle'} style={{...familyTitleStyle,marginBottom:4}}>
                  {cloneHeaderMessage}
                </div>

    { plotid === ''
    ?
                <div className={'TextMessage'}
                  style = {{
                    marginTop: 20,
                    textAlign: 'center'
                  }} >
                    <div>First select a plot from the</div>
                    <div>existing plots on the left.</div>

                </div>

    :
        <Fragment>
                <div className={'CloneOption'}
                  style={{ display:'flex', flexFlow: 'row nowrap', justifyContent: 'center' }}
                >
                    <div className={'PlotSelectionButton'}
                      style={{ width:SVG_CONTAINER_WIDTH, height:SVG_CONTAINER_HEIGHT }}
                      onClick={()=>handleCloneExistingPlot(  )}
                    >
                           <SVGwrapper2>
                               <img src={currentThumbnail}
                                 alt=''
                                 style={{ maxHeight:'100%', maxWidth:'100%' }}/>
                           </SVGwrapper2>
                    </div>
                </div>

                <div className={'Plot Title'}
                  style={{
                    fontSize:PLOT_TITLE_FONT_SIZE,
                    textAlign: 'center'}}
                >{mainTitle} </div>

        </Fragment>
    }

            </div>
       </div>
    )
  }
}




type Phase3Props = {
  tableComputedData: TableComputedData,
  leftAxisColIndex:number,
  bottomAxisColIndex: number,
  parametricColIndex: number,
  handleSetLeftAxisColIndex: (colIndex: string) => void,
  handleSetBottomAxisColIndex: (colIndex: string) => void,
  handleSetParametricColIndex: (colIndex: string) => void,
  handleCreateNewPlot3: ()=>void,
  handleRevertBackToPhase1: ()=>void,
  isLeftColRequired: boolean,
  isBottomColRequired: boolean,
  isParametricColRequired: boolean,
}
class CreatePlotPhase3Render extends PureComponent<Phase3Props> {
  render() {
    const {tableComputedData, leftAxisColIndex, bottomAxisColIndex, parametricColIndex,
      handleSetLeftAxisColIndex, handleSetBottomAxisColIndex, handleSetParametricColIndex,
      isLeftColRequired, isBottomColRequired, isParametricColRequired,
      handleRevertBackToPhase1, handleCreateNewPlot3,  } = this.props
    const columns  = tableComputedData.table.attributes.columns
    const nbs = constants.nonBreakingSpace
    const isInsufficientInformation =
      (isLeftColRequired   && leftAxisColIndex === -1) ||
      (isBottomColRequired && bottomAxisColIndex === -1) ||
      (isParametricColRequired && parametricColIndex === -1)
    const colKeyMenuItems: MenuItems = {}
    var numMenuItems = 0
    for ( const [index, colKey] of tableComputedData.derivedColOrder.entries() ) {
      let {colTitle} = columns[colKey]
      numMenuItems++
      colKeyMenuItems[index] = {
        displayedName: colTitle,
        menuText: [colTitle]
      }
    }
    const SELECT_LINE_HEIGHT = constants.SELECT_MENU_SINGLE_LINE_HEIGHT


    // Depending of whether the leftAxis colKey is required, the plotImage will
    // either be centered in the popup, or pushed to the far right.
    // This next code will position of column selection component in a corresponding
    // centered, or right shifted position:
    var bottomColPicker_leftPlacement = (isLeftColRequired) ? SELECTION_LEFT_SHIFTED_PHASE3 : SELECTION_LEFT_CENTERED_PHASE3

    return (

      <div className={'CreatePlotPhase3Container'}>
          <div className={'InstructionsForSetColData'}
            style={{position:'absolute', left:40, top:25}}>
                <div style={{ width:420, display: 'inline-block', fontSize: 16 }}>
                  { 'Define the first set of plotted data (1st Series). ' +
                    'This will create a starter plot. You can then edit the plot style, ' +
                    `axes, title, add/edit additional plotted data, ${nbs}.${nbs}.${nbs}.`
                  }
                </div>
          </div>


{ isLeftColRequired &&
          <div className={'leftAxisColKeyContainer'}
            style={{position:'absolute', left:40, top:170,
              width: COL_INPUT_SELECTOR_WIDTH,
              textAlign: 'center',
            }}
          >

          {'Left Axis Values:'}

          <EditorMenuButton
            menuItems={colKeyMenuItems}
            onSelection={handleSetLeftAxisColIndex}
            selectedValue={String(leftAxisColIndex)}
            height={24}
            widthDisplayText={COL_INPUT_SELECTOR_WIDTH}
            widthDisplayCaret={20}
            textAlign={'center'}
            menuWidth={COL_INPUT_SELECTOR_WIDTH}
            menuLeft={COL_INPUT_SELECTOR_WIDTH}
            menuTop={ numMenuItems > 22
                      ? -(SELECT_LINE_HEIGHT*11)
                      : -(SELECT_LINE_HEIGHT*numMenuItems)/2
                    }
            displayValueOverrideText={leftAxisColIndex === -1 ? 'Choose a Table Column' : ''}
            displayValueColor={leftAxisColIndex === -1 ? 'red' : 'unset'}/>
        </div>
}

{ isBottomColRequired &&
          <div className={'bottomAxisColKeyContainer'}
            style={{position:'absolute',
              left:bottomColPicker_leftPlacement, top:290,
              width: COL_INPUT_SELECTOR_WIDTH,
              textAlign: 'center',
            }}
          >

          {'Bottom Axis Values:'}

          <EditorMenuButton
            menuItems={colKeyMenuItems}
            onSelection={handleSetBottomAxisColIndex}
            selectedValue={String(bottomAxisColIndex)}
            height={24}
            widthDisplayText={COL_INPUT_SELECTOR_WIDTH}
            widthDisplayCaret={20}
            textAlign={'center'}
            menuWidth={COL_INPUT_SELECTOR_WIDTH}
            menuLeft={COL_INPUT_SELECTOR_WIDTH}
            menuTop={-SELECT_LINE_HEIGHT*(numMenuItems+6)/2}
            displayValueOverrideText={ bottomAxisColIndex === -1 ? 'Choose a Table Column' : ''}
            displayValueColor={bottomAxisColIndex === -1 ? 'red' : 'unset'}/>
        </div>
}


{ isParametricColRequired &&
          <Fragment>
          <div className={'InstructionsForParameterPlot'}
            style={{position:'absolute', left:40, top:350}}>
                <div style={{ width:420, display: 'inline-block', fontSize: 16 }}>
                  { 'Parametric plots have a specific order for connecting points. ' +
                    "The order is given by the column of 'parametric' values that " +
                    'generated the plot coordinates.'
                  }
                </div>
          </div>

          <div className={'ParametricAxisColKeyContainer'}
            style={{position:'absolute',
              left:bottomColPicker_leftPlacement, top:420,
              width: COL_INPUT_SELECTOR_WIDTH,
              textAlign: 'center',
            }}
          >

          {'Parametric Order Values:'}

          <EditorMenuButton
            menuItems={colKeyMenuItems}
            onSelection={handleSetParametricColIndex}
            selectedValue={String(parametricColIndex)}
            height={24}
            widthDisplayText={COL_INPUT_SELECTOR_WIDTH}
            widthDisplayCaret={20}
            textAlign={'center'}
            menuWidth={COL_INPUT_SELECTOR_WIDTH}
            menuLeft={COL_INPUT_SELECTOR_WIDTH-20}
            menuBottom={ numMenuItems > 16
                            ? -(SELECT_LINE_HEIGHT*8)
                            : -(SELECT_LINE_HEIGHT*numMenuItems)/2
                       }
            displayValueOverrideText={ parametricColIndex === -1 ? 'Choose a Table Column' : ''}
            displayValueColor={ parametricColIndex=== -1 ? 'red' : 'unset'}/>
        </div>
        </Fragment>
}

          <button className={'backButton'}
            onClick={ handleRevertBackToPhase1  }
            style={{
              fontSize: 16, fontWeight: 'bold',
              display: 'block',
              height: 40, width: 140,
              position: 'absolute', left: 80, top: 500,
              borderRadius: 6, borderStyle: 'outset', borderWidth: 2,
              borderColor: constants.COLHEADER_INPUT_BORDER_COLOR,
              verticalAlign: 'center',
            }}>
              {'Back'}
          </button>

          <button className={'createNewPlot3'}
            onClick={ handleCreateNewPlot3  }
            disabled={isInsufficientInformation}
            style={{
              fontSize: 16, fontWeight: 'bold',
              display: 'block',
              height: 40, width: 140,
              position: 'absolute', left: 270, top: 500,
              borderRadius: 6, borderStyle: 'outset', borderWidth: 2,
              borderColor: constants.COLHEADER_INPUT_BORDER_COLOR,
              verticalAlign: 'center',
            }}>
              {'Create My Plot'}
          </button>

      </div>
    )
  }
}
