import { list } from 'radash'
import { ReactNode } from 'react'

import type { ScryFormula } from './formulaTypes'
import type { FormatRule, FormattingObj } from '../sharedFunctions/numberFormat'
import type { ActiveFp } from '../types'
import type {
  TableHeightObj,
  TableWidthObj,
  TableStyleObj
} from './layoutCalculator'
import type {
  Table, Tabledata, Tablelook, 
  InternalColDataType,
  TableValues
} from '../types'
import type { PriorMemoizedResult } from '../sharedFunctions/getObjectDiff'
import type { ColumnStats } from '../computedDataTable/updateStatsShared'
import type {
  DepValuesParams, DepValuesRefs, DepValuesResult,
  RowNameErrorParams, RowNameErrorRefs, RowNameErrorResult,
  ColErrorParams, ColErrorRefs, ColErrorResult,
  FilterParams, FilterRefs, FilterResult,
  FormulaParams, FormulaRefs, FormulaResult,
  SortParams, SortRefs, SortResult,
  StatsParams, StatsRefs,
  StatsBarLayoutParams, StatsBarLayoutRefs, StatsBarLayoutResult,
  StatsResult
} from './updateTableComputedData'


import { DerivedFilterRule } from '../sharedFunctions/filterRows'
import { getFormattingObj, NumberFormattingMode } from '../sharedFunctions/numberFormat'
import { getDefaultTable, getDefaultTablelook, getDefaultTabledata } from '../types'
import { defaultWidthObj, defaultHeightObj, defaultStyleObj } from './layoutCalculator'
import { getDefaultActiveFp } from '../types'




export type DomNode = HTMLElement | undefined | null;

export type TableDomNodes = {
  // Keeping things the proper width and leftOffset as width varies:
  mainTitle: DomNode,
  publisherTitle: DomNode,
  statsBar: DomNode,
  layoutMain: DomNode,
  search2MainContainer: DomNode,
  lastMovingUnhideControl: DomNode,
  tableToolTips: DomNode,

  // Parent Node of each column subunit.
  // One column node per header column
  // RowsPerGroup nodes per data column
  columnsHead: Array<DomNode>,        // Array may be longer than number of Columns --                                                         //    columnsHead.length may not be valid!!
  columnsData: Array<Array<DomNode>>,  // [rowGroupIndex,colKey] -- columnsData.length may not be valid!

  // These are two empty divs behind the pinned rows.
  // Only purpose is during 'unPin' operation.  We need
  // the background behind the disappearing row to have the
  // correct alternating row color.
  pinnedRowsBackgroundLocked: DomNode,
  pinnedRowsBackgroundMoving: DomNode,

  // Coordinate reference to each of the four main table blocks.
  // These nodes control the placement and size of each block.
  headLocked_block: DomNode,
  headMoving_block: DomNode,
  dataLocked_block: DomNode,
  dataMoving_block: DomNode,

  headMoving_container: DomNode,
  dataLocked_container: DomNode,
  dataMoving_container: DomNode,

  headMoving_content: DomNode,
  dataLocked_content: DomNode,
  dataMoving_content: DomNode,

  lockedRowGroups: Array<DomNode>,        // by [rowGroupIndex]
  movingRowGroups: Array<DomNode>,        // by [rowGroupIndex]
  lineNumRowGroups: Array<DomNode>,        // by [rowGroupIndex]

  // Scrolling related nodes:
  actionScroll: DomNode,  // large div over-lapping all four data areas.  Owner of
  // of the touch-scroll event handler.
  vertScrollRail: DomNode,  // for control of the 'length' of the rail
  vertScrollThumb: DomNode,  // for control of the 'length' and 'offset' of the thumb
  horzScrollRail: DomNode,  // for control of the 'length' of the rail
  horzScrollThumb: DomNode,  // for control of the 'length' and 'offset' of the thumb
}

const getNullColArray = (numCols: number): DomNode[] => { return new Array(numCols).fill(null) }

export const getDefaultTableDomNodes = (numCols: number, numRowGroups: number): TableDomNodes => {
  return {
    mainTitle: null,
    publisherTitle: null,
    statsBar: null,   // Keep centered during width changes
    lastMovingUnhideControl: null,   // Needed because this control translates with scrollLeft.
    search2MainContainer: null,
    layoutMain: null,
    tableToolTips: null,

    // Parent Node of each column subunit.
    columnsHead: getNullColArray(numCols),
    columnsData: new Array(numRowGroups).fill(getNullColArray(numCols)),   // [rowGroupIndex,colKey]

    // These are two empty divs behind the pinned rows.
    // Only purpose is during 'unPin' operation.  We need
    // the background behind the disappearing row to have the
    // correct alternating row color.
    pinnedRowsBackgroundLocked: null,
    pinnedRowsBackgroundMoving: null,

    // Coordinate reference to each of the four main table blocks.
    // These nodes control the placement and size of each block.
    headLocked_block: null,
    headMoving_block: null,
    dataLocked_block: null,
    dataMoving_block: null,

    headMoving_container: null,
    dataLocked_container: null,
    dataMoving_container: null,

    headMoving_content: null,
    dataLocked_content: null,
    dataMoving_content: null,

    lockedRowGroups: new Array(numRowGroups).fill(null),
    movingRowGroups: new Array(numRowGroups).fill(null),
    lineNumRowGroups: new Array(numRowGroups).fill(null),

    // Scrolling
    actionScroll: null,
    vertScrollRail: null,
    vertScrollThumb: null,
    horzScrollRail: null,
    horzScrollThumb: null,
  }
}

export type ErrorRows = {
  [key: number]: string  // key is rowKey that is Erroneous; value is always empty string.
  // Could be done with Map, Set, or Obj w/ integer keys, or sparse array.
  // Obj w/ integer keys internally uses a sparse matrix, but is a bit
  //     easier on the eyes in the DEBUG_CONSOLE.
  // Obj w/ integer keys and sparse matrix have identical speed,
  //     and both are faster than a Set or Map.
  //     (As long as keys are integers !!)
}


// This is the set of resouce attributes that are needed to layout a table (width, height, style)
// Plus: DomNodes required by tableResponsiveState animations.
// Specifically, tableResponsiveState module uses a perturbation model, where layout
// calculator is repeatable run (once per frame) with typically only one of the
// following props modified.


type TablelookAttributes = Tablelook["attributes"]
type OtherTableLayoutProps = {
  sortedRowKeys: number[],
  numRowsUnfiltered: number,
  colWidth_ByColIndex: number[],
  isHidden_ByColIndex: boolean[], 
  hiddenVal_ByColIndex: number[],
  isDeletedArr: boolean[],
  tableLayoutHeight: number,
  tableLayoutWidth: number,
  tableTitle: string,
  publisherTitle: string,
  createdDate: string | undefined,
  updatedDate: string | undefined,
  canEdit: boolean,
  pinnedRowKeys: number[],
}
export type TableLayoutProps = TablelookAttributes & OtherTableLayoutProps

export const getDefaultTableLayoutProps = (numRows: number, numCols: number): TableLayoutProps => {
  const newObj: TableLayoutProps = {
    ...getDefaultTablelook(numRows, numCols).attributes,
    sortedRowKeys: list(numRows - 1),
    numRowsUnfiltered: numRows,
    colWidth_ByColIndex: [],
    isHidden_ByColIndex: [],
    hiddenVal_ByColIndex: [],
    isDeletedArr: [],
    tableLayoutHeight: 1000,
    tableLayoutWidth: 1000,
    tableTitle: 'default',
    publisherTitle: 'default',
    createdDate: undefined,
    updatedDate: undefined,
    canEdit: false,
    pinnedRowKeys: [],
  }
  return newObj
}

  /* 
    Ways to access the tableData(col,row) values:
    IN ALL CASES, THE RETURNED VALUE IS A STRING!
    1) getTableValue(colKey, rowKey, hideErroneousValues)     // tableData resource value
          Next function is a formatting wrapper around getTableValue()
    2.1) getFormattedTableData(colKey, rowKey, 'noHTML' )      // number formatting; but no raised exponents '3.14e9'
    2.2) getFormattedTableData(colKey, rowKey, 'html' )        // number formatting; raised exponents  3.14*10^9
    2.3) getFormattedTableData(colKey, rowKey, 'MeasureOnly' ) // ~proper length; potentially garbage value.
    2.4) getFormattedTableData(colKey, rowKey, 'noHTML_noPreSuffixCommas' ) // Used for CSV output
          Next function is wrapper around getFormattedTableData()
    3) getFormattedTableData_fromColKeyRowIndex(colKey, rowIndex, outputOption ) 
  */


export type FormattedTableDataReturnType = {
  value: string,
  isErroneous: boolean
  isMissingRowName: boolean
}

export type FormattedTableDataMode = NumberFormattingMode | 'noHTML_noPreSuffixCommas'    
export type GetTableValue = { 
              (colKey: number, rowKey: number, hideErroneousValues: boolean): FormattedTableDataReturnType }
export type GetFormattedTableData = { 
              (colKey: number, rowKey: number, outputOption: FormattedTableDataMode): FormattedTableDataReturnType }
const defaultGetTableValue = ( a: number,  b: number,  c: boolean) => 
                                             { return { value: '', isErroneous: true, isMissingRowName: false } }
const defaultGetFormattedTableData = (a: number, b: number, c: FormattedTableDataMode) => 
                                             { return { value: '', isErroneous: true, isMissingRowName: false } }


export type TableTripletName = 'activeTableTriplet' | 'searchTableTriplet'

export type StatsGroup = {   // This is the block of  label1 : value1
  label1: string,
  label2: string,
  value1: ReactNode,    // May use a raised exponent, but usually a string
  value2: ReactNode,    // ditto
  labelWidth: number,
  valueWidth: number,
  groupWidth: number
}

export type StatsBarLayout = {
  groupArr: StatsGroup[],  // The text and sizes within a 'n' groups
  groupLeftArr: number[],  // The Left offset of each statsGroup
  titleWidth: number,      // width to allocate to the colName
}

export const getDefaultStatsBarLayout = (): StatsBarLayout => {
  const newObj: StatsBarLayout = {
    groupArr: [],
    groupLeftArr: [],
    titleWidth: 0
  }
  return newObj
}


export type DerivedColAttributes = {
  colTitle: string,   // Needed by the parsing function.
//  colTitleIncludesHash: boolean,
  colDataType: string,   // column dataType (redundant with columns array)
  isKey: boolean,
  internalDataType: InternalColDataType,
  formatRule: FormatRule,
  formattingObj: FormattingObj,
  constrainedScaledColWidth: number,

  shouldForceUnits: boolean,
  forcedUnits: string,   // Application defined value which will over-ride user value is shouldForceUnits===True
  units: string,   // User defined units.

  hiddenVal: number,   // 0 means NOT hidden.  Integer > 0 is the 'order' of hidden cols to expose (highest value first.)
  isHidden: boolean, // boolean version of above numeric value
  isDepCol: boolean,  // redundant with columns array
  isDeleted: boolean,
  isDeletedNotRestorable: boolean,
  deletedDate: string,
  badColNameErrorID: string,   // Col Naming errorID
  erroneousCells: ErrorRows,
  missingCells: ErrorRows,
  formulaStrgs: Array<string> | null | undefined,
  parsedScryFormula: ScryFormula | null | undefined,  // Null for independent columns.
  // Must be present for Dependent Cols,
  // even if the formula is an empty string

  isBadColName: boolean,  // Illegal colName: reserved, redundant, missing, canonicalForm, underscore, number
  isBadColNameBecauseRedundant: boolean, //  illegal because 'redundant'
  isBadFormulaColRef: boolean,  // Validy of formula's external colName references; Fails for illegal colName or circular references.
  isBadFormulaSyntax: boolean,  // Fails verification text.  Correctness of syntax without external col references checks
  isMismatchedType: boolean,  // None of the col cell data matches the specified col dataType

  isNotCalculable: boolean,  //  Can't ber calculated; Error in current colFormula, isMissing col Input
  isMissingCol: boolean,  //               badFormula (Dep only) || badDataType (Ind only) || inputCol isMissing (Dep only)
}



export const getDefaultDerivedColAttributes = (): DerivedColAttributes => {
  const newObj: DerivedColAttributes = {
    colTitle: '',
    // colTitleIncludesHash: false,
    colDataType: 'string',
    isKey: false,
    internalDataType: 'string',
    formatRule: 'defaultString',
    formattingObj: getFormattingObj('defaultString'),
    constrainedScaledColWidth: 200,

    shouldForceUnits: false,
    forcedUnits: '',   // Application defined value which will over-ride user value is shouldForceUnits===True
    units: '',   // User defined units.

    hiddenVal: 0,      // 0 means NOT hidden.  Greater than 0 is the 'order' of hidden cols to expose (highest value first.)
    isHidden: false, // boolean version of above hidden value
    isDepCol: false,  // redundant with columns array
    isDeleted: false,
    isDeletedNotRestorable: false,
    deletedDate: '',
    badColNameErrorID: '',   // Col Naming errorID
    erroneousCells: {},
    missingCells: {},
    formulaStrgs: null,
    parsedScryFormula: null,  // Null for independent columns.
    // Must be present for Dependent Cols,
    // even if the formula is an empty string

    isBadColName: false,  // Illegal colName: reserved, redundant, missing, canonicalForm, underscore, number
    isBadColNameBecauseRedundant: false, //  illegal because 'redundant'
    isBadFormulaColRef: false,  // Validy of formula's external colName references; Fails for illegal colName or circular references.
    isBadFormulaSyntax: false,  // Fails verification text.  Correctness of syntax without external col references checks
    isMismatchedType: false,  // None of the col cell data matches the specified col dataType

    isNotCalculable: false,  // badColName || badFormula (Dep only) || badDataType (Ind only)
    isMissingCol: false,  //               badFormula (Dep only) || badDataType (Ind only) || inputCol isMissing (Dep only)
  }
  return newObj
}




export type TableComputedData = {

  // sessionState information that passes through
  // to the tableComputedData calculations, rendering, or updating:
  scrollLeft: number,
  scrollTop: number,
  currentTableTriplet: TableTripletName,
  sessionStateRenderIndex: number,
  sessionStateActiveFp: ActiveFp,

  table: Table,
  tablelook: Tablelook,
  tabledata: Tabledata,

  tableid: string,
  tabledataid: string,
  tablelookid: string,
  userid: string,
  tableOwnerid: string,

  username: string,
  canEdit: boolean,
  menuOption_isEditMode: boolean,
  isPublisherRendered: boolean,
  isSearchComponentRendered: boolean,
  isTableGridRendered: boolean,

  derivedColOrder: Array<number>, 
  pinnedRowKeys: number[],
  numRowsUnfiltered: number,

  // Prior cache calculations:
  memoizedColumnCellErrors_byColKey: Array<Array<PriorMemoizedResult<ColErrorParams, ColErrorRefs, ColErrorResult>>>,
  memoizedParsedFormula_byColKey: Array<Array<PriorMemoizedResult<FormulaParams, FormulaRefs, FormulaResult>>>,
  memoizedTableValuesDep: Array<Array<PriorMemoizedResult<DepValuesParams, DepValuesRefs, DepValuesResult>>>,
  memoizedRowOrder: Array<PriorMemoizedResult<SortParams, SortRefs, SortResult>>,   // Done on the unfiltered rows.
  memoizedFilteredRowKeysArr: Array<PriorMemoizedResult<FilterParams, FilterRefs, FilterResult>>,   // Done on the memoizedRowOrder (hence filtered, then sorted)
  memoizedErroneousRowNames: Array<PriorMemoizedResult<RowNameErrorParams, RowNameErrorRefs, RowNameErrorResult>>,
  memoizedStats: Array<PriorMemoizedResult<StatsParams, StatsRefs, StatsResult>>,
  memoizedStatsBarLayout: Array<PriorMemoizedResult<StatsBarLayoutParams, StatsBarLayoutRefs, StatsBarLayoutResult>>,

  // Layout
  tableWidth: number,
  tableWidthIncludingSideBar: number,
  tableHeight: number,
  widthObj: TableWidthObj,
  heightObj: TableHeightObj,
  styleObj : TableStyleObj,

  // derivedColOrder and these supporting arrays exclude isDeleted columns, and future categories of deletions.
  isHidden_ByColIndex : boolean[], 
  hiddenVal_ByColIndex: number[],
  colWidth_ByColIndex : number[], 
  priorHidden_ByColIndex : number[], 

  numColsHiddenInGapBetweenTables : number,   // Whether we render the 'unhide' control between tables (value > 0)
  numColsHiddenRightOfMovingTable : number,   // Whether we render the 'unhide' control right of the moving table (value > 0)

  derivedColAttributesArray: Array<DerivedColAttributes>,
  depColumnRecalcOrder: Array<number>,

  tableValuesWorking: TableValues, // A combined array, with:
  //   -- references tableData.attributes.values (independent column values)
  //   -- references to memoized prior dependent column calculations.
  // Consumes no space on its own.
  // Remember, these are all referenced column value arrays, and
  // should NEVER be modified directly.  READ-ONLY !
  // This is the source of raw table values for the getTableValue()
  // Error structures
  duplicateRowNames: ErrorRows,
  missingRowNames: ErrorRows,
  doesKeyColumnExist: boolean,

  filteredRowKeys: Array<number>,
  unsortedRowKeys: Array<number>,  // Need a constant array reference to tell memoized stats calculation: "same array; no changes".
  sortedRowKeys: Array<number>,
  filterRuleCounts: Array<number>,
  derivedFilterRuleArray: Array<DerivedFilterRule>,

  stats_OfActiveStatsColKey: ColumnStats | null,
  stats_LegalActiveStatsColKey: number,
  statsBarLayout: StatsBarLayout,

  layoutProps: TableLayoutProps,
  newFilteredScrollTop: number,

  tableDomNodes: TableDomNodes,

  // table value accessor functions
  getTableValue: GetTableValue,
  getFormattedTableData: GetFormattedTableData,
  getFormattedTableData_fromColKeyRowIndex: GetFormattedTableData,

}


export const getDefaultTableComputedData = (numCols: number): TableComputedData => {
  const currentTableTriplet: TableTripletName = 'activeTableTriplet'
  const newObj = {
    // sessionState information that passes through
    // to the tableComputedData calculations, rendering, or updating:
    scrollLeft: 0,
    scrollTop: 0,
    currentTableTriplet,
    sessionStateRenderIndex: 0,
    sessionStateActiveFp: getDefaultActiveFp(),

    table: getDefaultTable(1),      // 1 column
    tablelook: getDefaultTablelook(1, 1), // 1 row, 1 col
    tabledata: getDefaultTabledata(),

    tableid: '',
    tabledataid: '',
    tablelookid: '',
    userid: '',
    tableOwnerid: '',

    username: '',
    canEdit: false,
    menuOption_isEditMode: true,
    isPublisherRendered: true,
    isSearchComponentRendered: false,
    isTableGridRendered: true,

    derivedColOrder: [],
    pinnedRowKeys: [],
    numRowsUnfiltered: 1,

    // Prior cache calculations:
    memoizedColumnCellErrors_byColKey: Array.from({ length: numCols }, () => new Array(0)),
    memoizedParsedFormula_byColKey: Array.from({ length: numCols }, () => new Array(0)),
    memoizedTableValuesDep: Array.from({ length: numCols }, () => new Array(0)),
    memoizedRowOrder: Array(0),  // Done on the unfiltered rows.
    memoizedFilteredRowKeysArr: Array(0),  // Done on the memoizedRowOrder (hence filtered, then sorted)
    memoizedErroneousRowNames: Array(0),
    memoizedStats: Array(0),
    memoizedStatsBarLayout: Array(0),

    stats_OfActiveStatsColKey: null,
    stats_LegalActiveStatsColKey: -1,
    statsBarLayout: getDefaultStatsBarLayout(),

    // Layout
    tableWidth: 100,
    tableWidthIncludingSideBar: 100,
    tableHeight: 100,
    widthObj: defaultWidthObj,
    heightObj: defaultHeightObj,
    styleObj : defaultStyleObj,

    // derivedColOrder and these supporting arrays exclude isDeleted columns
    isHidden_ByColIndex : new Array(0), 
    hiddenVal_ByColIndex: new Array(0),
    colWidth_ByColIndex : new Array(0),
    priorHidden_ByColIndex : new Array(0),

    numColsHiddenInGapBetweenTables : 0,  // Whether we render the 'unhide' control between tables (value > 0)
    numColsHiddenRightOfMovingTable : 0,  // Whether we render the 'unhide' control right of the moving table (value > 0)

    derivedColAttributesArray: new Array(0),
    depColumnRecalcOrder: new Array(0),

    tableValuesWorking: Array.from({ length: numCols }, () => new Array(0)),

    // Error structures
    duplicateRowNames: {},
    missingRowNames: {},
    doesKeyColumnExist: false,

    filteredRowKeys: new Array(0),
    unsortedRowKeys: new Array(0),
    sortedRowKeys: new Array(0),
    filterRuleCounts: new Array(0),
    derivedFilterRuleArray: new Array(0),

    layoutProps: getDefaultTableLayoutProps(1, 0),   // One row, zero columns
    newFilteredScrollTop: 0,

    tableDomNodes: getDefaultTableDomNodes(1, 1),

    getTableValue: defaultGetTableValue,
    getFormattedTableData : defaultGetFormattedTableData,
    getFormattedTableData_fromColKeyRowIndex: defaultGetFormattedTableData,
  }
  return newObj
}

