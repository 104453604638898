const joi = require('joi')

const JsonMLAttributes = joi.object().pattern(/[-a-z]/, joi.string().allow(''))
const JsonMLNode = joi.array().items(
  joi.string(),
  JsonMLAttributes,
  joi.lazy(() => JsonMLNode)
)

const sourcemlAttributes = {
  nodes: JsonMLNode
    .description('The conents of the page in JsonML (JSON Markup Language).'),

  // server side managed data (read only to clients)
  createdDate: joi.date().iso().allow(null).meta('readonly')
    .description('Read only date source was imported.'),
}

module.exports = sourcemlAttributes
