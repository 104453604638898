import type { ReactNode } from 'react'
import type { FormattingObj } from '../sharedFunctions/numberFormat'
import type { State, Props } from './CrosshairsXY'

import invariant from 'invariant'
import { Fragment } from 'react'
import { parseRenderedLayerID } from '../computedDataPlotXY/plotUtils'
import { isBasicPlotPt, isSparsePlotPt } from '../computedDataPlotXY/xy_plotTypes'
import constants from '../sharedComponents/constants'
import {
  getCommasOnlyFormattingObj, 
  getFormattingObj, 
  numberFormatMeasureOnly,
  numberFormatNoHTML,
  numberFormatReactNode
} from '../sharedFunctions/numberFormat'
import { measureText } from '../sharedFunctions/measureText'
import { FONT_SIZE } from './CrosshairsXY'

const thinSpaceChar = constants.thinSpaceChar
const subtractChar = constants.subtractChar
const hairSpaceChar = constants.hairSpaceChar
const plusMinusChar = constants.plusMinusChar
const italix_x_Char = constants.italix_x_Char
const nbSpace = constants.nonBreakingSpace
const doubleSpace = nbSpace + nbSpace
const MultiplyElement = constants.MultiplyElement //  This is HTML text version;  NOT string version

const openParen = <big key={0}>( </big>
const closeParen = <big key={1}> )</big>
const log10Node = (
  <Fragment>
    {'Log'}
    <sub>10 </sub>
  </Fragment>
)


export type InfoLine = {
  opacity: number
  measTextArray: string[]
  valueNodeArray: ReactNode[]
}

export type InfoTable = InfoLine[]

const numberFormat = (inNumber: number, formattingObj: FormattingObj): string => {
  return numberFormatNoHTML(String(inNumber), formattingObj)
}

export const createDebuggingContent = (
  state: State,
  props: Props,
): {
  infoTable: InfoTable
  widthLeftOfColon: number
  widthRightOfColon: number
  widthTable1: number
  widthTable2: number
  widthTable3: number
} => {
  var {
    mouseX_px,
    mouseY_px,
    mouseFoundX_px,
    mouseFoundY_px,
    mouseFoundX_value,
    mouseFoundY_value,
    plotPtBottom_value,
    plotPtLeft_value,
    plotPtBottom_px,
    plotPtLeft_px,
    delaunayArrayIndex,
    seriesTitle,
    sKey,
  } = state

  if (delaunayArrayIndex === -1) {
    //update calling function to handle this case
    return {
      infoTable: [{
        opacity: 0,
        measTextArray: ['', '', '', ''],
        valueNodeArray: ['', '', '', ''],
      }],
      widthLeftOfColon: 0,
      widthRightOfColon: 0,
      widthTable1: 0,
      widthTable2: 0,
      widthTable3: 0,
    }
  }

  const { leftAxis, bottomAxis } = props.plotXyComputedData
  const renderedLayer = props.plotXyComputedData.delaunayLayersArray[delaunayArrayIndex]
  const { plottedValueRoot } = parseRenderedLayerID(renderedLayer.renderedLayerID)
  // We disire the option to modify the formatting, hence make a copy!
  const bottomFormattingObj = { ...bottomAxis.tickFormattingObj }
  const leftFormattingObj = { ...leftAxis.tickFormattingObj }
  if (plottedValueRoot === 'tableRow' || plottedValueRoot === 'pinnedRow') {
    // Use the left/bottom axis tick formatting
    // This formatting MAY include a fixed exponent.
    // Not clear whether to used fixed exponent formatting for crosshairs or not.
    // But easy to clear the fix exponent style here if we choose.
  } else {
    // This is some seedHisto calculated statistic, or similar.
    // Add additional resolution (if needed) for this format!!
    // Use 5 significant figures, unless user has set an even higher level.
    bottomFormattingObj.precMode = 'min'
    bottomFormattingObj.precision = 5
    leftFormattingObj.precMode = 'min'
    leftFormattingObj.precision = 5
  }
  const mousePxToLeftDomain = leftAxis.mousePxToPlotDomainValue
  const mousePxToLeftTick = leftAxis.mousePxToPlotTickValue
  const mousePxToBottomDomain = bottomAxis.mousePxToPlotDomainValue
  const mousePxToBottomTick = bottomAxis.mousePxToPlotTickValue

  const infoTable = Array<InfoLine>()
  const defaultEngFormatObj = getFormattingObj('defaultEng', { precisionMin: 4})

  // Line 1
  var measTextArray = [`passed mouse (X,Y) px :`, `(${mouseX_px}, ${mouseY_px})`, '', '']
  var valueNodeArray = measTextArray.slice()
  infoTable.push({ opacity: 1, measTextArray, valueNodeArray })

  // Line 2
  var domainY = mousePxToLeftDomain(mouseY_px)
  var domainX = mousePxToBottomDomain(mouseX_px)
  var Xstrg = numberFormat(domainX, defaultEngFormatObj)
  var Ystrg = numberFormat(domainY, defaultEngFormatObj)
  measTextArray = [`Maps to domain (X,Y) :`, `(${Xstrg}, ${Ystrg})`, '', '']
  valueNodeArray = measTextArray.slice()
  infoTable.push({ opacity: 1, measTextArray, valueNodeArray })

  // Line 3
  var tickY = mousePxToLeftTick(mouseY_px)
  var tickX = mousePxToBottomTick(mouseX_px)
  Xstrg = numberFormat(tickX, bottomFormattingObj)
  Ystrg = numberFormat(tickY, leftFormattingObj)
  measTextArray = [`Maps to tickValues (X,Y) :`, `(${Xstrg}, ${Ystrg})`, '', '']
  valueNodeArray = measTextArray.slice()
  infoTable.push({ opacity: 1, measTextArray, valueNodeArray })

  // Line 4
  tickY = mousePxToLeftTick(mouseY_px)
  tickX = mousePxToBottomTick(mouseX_px)
  measTextArray = [`FoundSeries key :`, `${sKey}  '${seriesTitle}'`, '', '']
  valueNodeArray = measTextArray.slice()
  infoTable.push({ opacity: 1, measTextArray, valueNodeArray })

  // Line 5
  measTextArray = [`found (snapped) px :`, `(${mouseFoundX_px}, ${mouseFoundY_px})`, '', '']
  valueNodeArray = measTextArray.slice()
  infoTable.push({ opacity: 1, measTextArray, valueNodeArray })

  // Line 7
  var bottomStrg = numberFormat(plotPtBottom_px, defaultEngFormatObj)
  var leftStrg = numberFormat(plotPtLeft_px, defaultEngFormatObj)
  measTextArray = [`plotPt px (greenLines):`, `(${bottomStrg}, ${leftStrg})`, '', '']
  valueNodeArray = measTextArray.slice()
  infoTable.push({ opacity: 1, measTextArray, valueNodeArray })
  var result = calcCrossHairsColumnWidths(infoTable)

  // Line 8
  bottomStrg = numberFormat(mouseFoundX_value, bottomFormattingObj)
  leftStrg = numberFormat(mouseFoundY_value, leftFormattingObj)
  measTextArray = [`found (snapped) value:`, `(${bottomStrg}, ${leftStrg})`, '', '']
  valueNodeArray = measTextArray.slice()
  infoTable.push({ opacity: 1, measTextArray, valueNodeArray })

  // Line 9
  bottomStrg = numberFormat(plotPtBottom_value, bottomFormattingObj)
  leftStrg = numberFormat(plotPtLeft_value, leftFormattingObj)
  measTextArray = [`plotPt value :`, `(${bottomStrg}, ${leftStrg})`, '', '']
  valueNodeArray = measTextArray.slice()
  infoTable.push({ opacity: 1, measTextArray, valueNodeArray })

  // Next function will reveiw the infoTable, and set the
  // worse case col widths for rendering.
  result = calcCrossHairsColumnWidths(infoTable)
  return result
}

const calcCrossHairsColumnWidths = (infoTable: InfoTable) => {
  // There are three columns in the crosshairs format.  3 columns is worse
  // case, but often only first 2 are diplayed as formatting doesn't
  // we choosen doesn't need a third column.

  // If the crosshairs consist of 5 rows of information, then
  // then the rendered width is determined by the longest string length.
  // This is true ov columns 0,2,3.  Column 1 (2nd column) gets special
  // treatment
  // DEFAULTS;  Columns are pre-seeded with these widths, hence
  // the rendered widths can never be narrower than these default widths.
  // These widths can only grow, as we find the maximum width needed across
  // all crosshair's rows.
  /* The naming convention:
        widthLabel0 : Usually a text 'name' placed  left of the aligning colon.
        widthlabel1 : Usually a text 'name/header' right of the aligning colon.
        widthTable1,2,3 : Usually a single values column, but sometimes double, or triple column format.
                          If only one column is needed, widths 2,3 are zero.
  */
  var widthLabel0 = measureText('ALabel', `${FONT_SIZE}px`, 'bold')
  var widthLabel1 = 0 //measureText('Billy MacPhearson-Chu', `${FONT_SIZE}px`, 'bold' )
  var widthTable1 = 0
  var widthTable2 = 0
  var widthTable3 = 0

  // The typical formatting is two colulmns, with widths of:
  //    widthLabel0 and widthLabel1.
  //    These columns are usually labels (strings) as opposed to values (numbers)
  // The typical formatting is two columns, with widths of:
  //    widthLabel0 and widthLabel1.
  //    These columns are usually labels (strings) as opposed to values (numbers)
  // For each row in crosshairs
  for (let i = 0; i < infoTable.length; i++) {
    var txtLine = infoTable[i].measTextArray // An array of 4 strings.  One for each column

    // Get the widths of these four strings.
    var thisWidth0 = measureText(txtLine[0], `${FONT_SIZE}px`, 'bold')
    var thisWidth1 = measureText(txtLine[1], `${FONT_SIZE}px`, 'bold')
    var thisWidth2 = measureText(txtLine[2], `${FONT_SIZE}px`, 'bold')
    var thisWidth3 = measureText(txtLine[3], `${FONT_SIZE}px`, 'bold')

    // widthTable0,2,3 are the widest of the strings in that column
    widthLabel0 = Math.max(widthLabel0, thisWidth0)
    widthTable2 = Math.max(widthTable2, thisWidth2)
    widthTable3 = Math.max(widthTable3, thisWidth3)

    // We make an exception for column 1, which is usually missing
    // for most formatting.  We want the maximum width assuming some
    // rows have only one string, and some rows have two strings
    if (txtLine[2] === '') {
      widthLabel1 = Math.max(widthLabel1, thisWidth1)
    } else {
      widthTable1 = Math.max(widthTable1, thisWidth1)
    }
  }

  // We need to convert our width dimensions From the browser window 'pxs',
  // to the scale used inside the plot.  That is the division below.
  // Max width LEFT  of the formatting colon => widthLabel0
  // Max width RIGHT of the colon : Worse case of the headerRow (widthLable0) or 1+2+3 columns
  var widthLeftOfColon = widthLabel0
  var widthRightOfColon = Math.max(widthLabel1, widthTable1 + widthTable2 + widthTable3)
  return {
    infoTable,
    widthLeftOfColon,
    widthRightOfColon,
    widthTable1,
    widthTable2,
    widthTable3,
  }
}

export const createCrossHairsContent = (
  state: State,
  props: Props,
): {
  infoTable: InfoTable
  widthLeftOfColon: number
  widthRightOfColon: number
  widthTable1: number
  widthTable2: number
  widthTable3: number
} => {
  //return { infoTable: [], widthLeftOfColon: 0, widthRightOfColon: 0, widthTable1: 0, widthTable2: 0, widthTable3: 0 };

  //let measTextArray = [ titleB, textValue, '', '']
  //let valueNodeArray= [ titleB, valueNode, '', '']
  //infoTable.push({opacity:1, measTextArray , valueNodeArray })

  const { sKey, plotPtIndex, plotPt, /*seriesFormattingObjA, seriesFormattingObjB,*/ seriesTitle } = state
  //const {columns} = props.table.attributes
  var {
    seriesAttributesArray,
    seriesOrder,
    userDataBaseKeyCols,
    userDataBaseKeyColTitles,
    basisA,
    basisB,
    // seriesPlottedData,
    histogramBinIndex,
    derivedColAttributesArray,
    isTransposed,
    isStacked_anyLayer,
  } = props.plotXyComputedData
  const { getTableValue } = props.tableComputedData
  var infoTable = Array<InfoLine>() // One of our return objects.  Contains the crosshairs formatted text, by row.

  const appendBlankLine = () => {
    infoTable.push({
      opacity: 1,
      measTextArray: ['', '', '', ''],
      valueNodeArray: ['', '', '', ''],
    })
  }

  const appendUserColKeyValues = () => {
    // List the colName/values of all table columns defined as 'Keys'
    // However, this is colName is often redundant (identical to) an axisName/value.
    // So skip colName/Values that are redundant.
    // Use only the axisName and it's corresponding value. (added by another function)
    var titleA = basisA.axisTitleShort ? basisA.axisTitleShort : basisA.axisTitle
    var titleB = basisB.axisTitleShort ? basisB.axisTitleShort : basisB.axisTitle
    userDataBaseKeyCols.forEach((colKey, index) => {
      var title = userDataBaseKeyColTitles[index]
      let { formattingObj, internalDataType } = derivedColAttributesArray[colKey]
      let newFormattingObj = { ...formattingObj, allowsPrefixSuffix: false }
      var { value } = getTableValue(colKey, plotPtIndex, true)
      if (internalDataType === 'number') {
        var measText = numberFormatMeasureOnly(value, newFormattingObj)
        var valueNode = numberFormatReactNode(value, newFormattingObj)
      } else {
        measText = String(value)
        valueNode = String(value)
      }
      if (title !== titleA && title !== titleB) {
        let measTextArray = [title + ' :', measText, '', '']
        let valueNodeArray = [title + ' :', valueNode, '', '']
        infoTable.push({ opacity: 1, measTextArray, valueNodeArray })
      }
    })
  }

  const appendSeries_seriesTitle = () => {
    let measTextArray = ['Series Name :', seriesAttributesArray[sKey].seriesTitle, '', '']
    let valueNodeArray = measTextArray
    infoTable.push({ opacity: 1, measTextArray, valueNodeArray })
  }

  const appendBasisA_NameValue = () => {
    const titleA = basisA.axisTitleShort ? basisA.axisTitleShort + ' :' : basisA.axisTitle + ' :'
    let textValue: string
    let valueNode: ReactNode
    if (basisA.internalDataType === 'string' && isSparsePlotPt(plotPt)) {
      textValue = plotPt.Astring
      valueNode = plotPt.Astring
    } else if (basisA.internalDataType === 'string' && isBasicPlotPt(plotPt)) {
      textValue = '(no text)'
      valueNode = '(no text)'
    } else {
      // 'number'}
      let seriesFormattingObjA = getFormattingObj(basisA.tickFormatRule, {
        prefix: '',
        suffix: '',
      })

      textValue = numberFormatMeasureOnly(String(plotPt.A), seriesFormattingObjA)
      valueNode = numberFormatReactNode(String(plotPt.A), seriesFormattingObjA)
    }
    let measTextArray = [titleA, textValue, '', '']
    let valueNodeArray = [titleA, valueNode, '', '']
    infoTable.push({ opacity: 1, measTextArray, valueNodeArray })
  }

  const appendBasisB_NameValue = (formattingObj: FormattingObj | null = null) => {
    const titleB = basisB.axisTitleShort ? basisB.axisTitleShort + ' :' : basisB.axisTitle + ' :'
    let textValue: string, valueNode: ReactNode | string
    if (basisB.internalDataType === 'string' && isSparsePlotPt(plotPt)) {
      textValue = plotPt.Bstring
      valueNode = plotPt.Bstring
    } else if (basisB.internalDataType === 'string' && isBasicPlotPt(plotPt)) {
      textValue = '(no text)'
      valueNode = '(no text)'
    }
    // Must be number format
    else if (formattingObj) {
      // Use the formatting passed to this function.
      textValue = numberFormatMeasureOnly(String(plotPt.B), formattingObj)
      valueNode = numberFormatReactNode(String(plotPt.B), formattingObj)
    } else {
      // Use the current plotPt column's formattingObj
      let seriesFormattingObjB = getFormattingObj(basisB.tickFormatRule, {
        prefix: '',
        suffix: '',
      })

      textValue = numberFormatMeasureOnly(String(plotPt.B), seriesFormattingObjB)
      valueNode = numberFormatReactNode(String(plotPt.B), seriesFormattingObjB)
    }
    let measTextArray = [titleB, textValue, '', '']
    let valueNodeArray = [titleB, valueNode, '', '']
    infoTable.push({ opacity: 1, measTextArray, valueNodeArray })
  }

  const appendHistogramBinWidth = () => {
    let binCenterVal = plotPt.A
    let binHalfWidthVal = basisA.seedHisto!.binWidthOptions[histogramBinIndex] / 2
    let binStartVal = binCenterVal - binHalfWidthVal
    let binStopVal = binCenterVal + binHalfWidthVal
    let formattingObjA = getFormattingObj(basisA.tickFormatRule, {
      prefix: '',
      suffix: '',
    })

    // These probably need to change to seriesFormattingObjA and B: ??? or tick formatting? or what?
    let binStart = numberFormatMeasureOnly(String(binStartVal), formattingObjA)
    let binStop = numberFormatMeasureOnly(String(binStopVal), formattingObjA)
    let binHalfWidth = numberFormatMeasureOnly(String(binHalfWidthVal), formattingObjA)
    let binCenter = numberFormatMeasureOnly(String(binCenterVal), formattingObjA)

    let binStartNode = numberFormatReactNode(String(binStartVal), formattingObjA)
    let binStopNode = numberFormatReactNode(String(binStopVal), formattingObjA)
    let binHalfWidthNode = numberFormatReactNode(String(binHalfWidthVal), formattingObjA)
    let binCenterNode = numberFormatReactNode(String(binCenterVal), formattingObjA)
    // These text strings are ONLY for measuring Width. They should be equal or bit wider than the html displayed text.
    const measLine0 = ['Bin :', `${binCenter}${doubleSpace}${plusMinusChar}${doubleSpace}${binHalfWidth}`, '', '']
    const measLine1 = [
      '',
      `${binStart}${doubleSpace}<${doubleSpace}${italix_x_Char}${doubleSpace}<=${doubleSpace}${binStop}`,
      '',
      '',
    ]

    const valuesLine0 = [
      'Bin :',
      <span>
        {binCenterNode}
        {doubleSpace}
        <span style={{ fontSize: '120%' }}>{plusMinusChar}</span>
        {doubleSpace}
        {binHalfWidthNode}
      </span>,
      '',
      '',
    ]
    const valuesLine1 = [
      '',
      <span>
        {binStartNode}
        {doubleSpace + '<' + doubleSpace}
        <i>{'x'}</i>
        {doubleSpace + '<' + thinSpaceChar + '=' + doubleSpace}
        {binStopNode}
      </span>,
      '',
      '',
    ]

    infoTable.push({
      opacity: 1,
      measTextArray: measLine0,
      valueNodeArray: valuesLine0,
    })
    infoTable.push({
      opacity: 1,
      measTextArray: measLine1,
      valueNodeArray: valuesLine1,
    })
  }

  //const getBvaluesBySeries = ( ) : { cumB    :Array<number>, valueB      :Array<number>,
  //                                   percentB:Array<number>, numSeriesPts:Array<number>} => {
  const getBvaluesBySeries = (): {
    cumB: number[]
    valueB: number[]
    percentB: number[]
    numSeriesPts: number[]
  } => {
    const cumB = Array<number>()
    const valueB = Array<number>()
    const percentB = Array<number>()
    const numSeriesPts = Array<number>()
    if (!isSparsePlotPt(plotPt)) {
      return {cumB, valueB, percentB, numSeriesPts}
    }
    var runningCountTotal = 0
    const binIndex = plotPt.rowKey

    seriesOrder.forEach((sKey) => {
      let thisData = isStacked_anyLayer ? seriesPlottedData[0] : seriesPlottedData[sKey]
      if (isStacked_anyLayer) {
        valueB[sKey] = thisData[binIndex].arrayB[sKey]
      } else {
        valueB[sKey] = thisData[binIndex].B
      }
      percentB[sKey] = (valueB[sKey] / seriesAttributesArray[sKey].numPoints) * 100
      runningCountTotal += valueB[sKey]
      cumB[sKey] = runningCountTotal
      numSeriesPts[sKey] = seriesAttributesArray[sKey].numPoints
    })
    return { cumB, valueB, percentB, numSeriesPts }
  }

  var stackedB = plotPt.stackedB
  var stackedB0 = plotPt.stackedB0
  var arrayB = plotPt.arrayB

  appendBlankLine()
  const percentFormattingObj = getFormattingObj( 'defaultEng', {suffix:'%', precisionMode:'std', precision:3 } )
  const countFormattingObj = getCommasOnlyFormattingObj()
  const tempIdenfier: string = 'Needs to come from renderedLayer plottedValueB and userVisType'

  switch (tempIdenfier) {
    case 'histogramCount':
    case 'histogramPercent':
    case 'histogramStacked':
      // String axis needs something very different from number axis
      if (basisA.internalDataType === 'string') {
        appendSeries_seriesTitle()
        appendBasisA_NameValue()
      }
      if (basisA.internalDataType === 'number') {
        appendHistogramBinWidth()
      }
      appendBlankLine()
      appendBlankLine()

      // Table column headers:
      if (tempIdenfier === 'histogramStacked') {
        var measTextArray1 = ['', 'Series', 'Stacked', '']
        var valueNodeArray1 = measTextArray1
        var measTextArray2 = ['', 'Count', 'Count', '']
        var valueNodeArray2 = measTextArray2
      } else {
        measTextArray1 = ['', 'Series', 'Series', 'Series']
        valueNodeArray1 = measTextArray1
        measTextArray2 = ['', 'Count', 'Total', 'Percent']
        valueNodeArray2 = measTextArray2
      }
      infoTable.push({
        opacity: 1,
        measTextArray: measTextArray1,
        valueNodeArray: valueNodeArray1,
      })
      infoTable.push({
        opacity: 1,
        measTextArray: measTextArray2,
        valueNodeArray: valueNodeArray2,
      })
      appendBlankLine()

      var { cumB, valueB, percentB, numSeriesPts } = getBvaluesBySeries()

      // We order the listed series in SeriesOrder (left-to=right) for:
      //    side-by-side histograms
      //    stacked plots when basisA is the axisLeft (isTransposed === true)
      // We order in reverseSeriesOrder (top-to-bottom) for:
      //    stacked plots when isTransposed === false
      let keyOrder = isStacked_anyLayer && isTransposed === false ? seriesOrder.slice().reverse() : seriesOrder

      keyOrder.forEach((sKey) => {
        let seriesTitleLabel = seriesTitle + ' :'
        let countMeas = numberFormatMeasureOnly(String(valueB[sKey]), countFormattingObj)
        let cumCountMeas = numberFormatMeasureOnly(String(cumB[sKey]), countFormattingObj)
        let countNode = numberFormatReactNode(String(valueB[sKey]), countFormattingObj)
        let cumCountNode = numberFormatReactNode(String(cumB[sKey]), countFormattingObj)
        if (tempIdenfier === 'histogramStacked') {
          var measTextArray = [seriesTitleLabel, countMeas, cumCountMeas, '']
          var valueNodeArray = [seriesTitleLabel, countNode, cumCountNode, '']
        } else {
          let percentMeas = numberFormatMeasureOnly(String(percentB[sKey]), percentFormattingObj)
          let percentNode = numberFormatReactNode(String(percentB[sKey]), percentFormattingObj)
          let seriesPtsMeas = numberFormatMeasureOnly(String(numSeriesPts[sKey]), countFormattingObj)
          let seriesPtsNode = numberFormatReactNode(String(numSeriesPts[sKey]), countFormattingObj)
          measTextArray = [seriesTitleLabel, countMeas, seriesPtsMeas, percentMeas]
          valueNodeArray = [seriesTitleLabel, countNode, seriesPtsNode, percentNode]
        }
        //let opacity = ( sKey === sKey ) ? 1 : 0.5   ????
        let opacity = 1
        infoTable.push({ opacity, measTextArray, valueNodeArray })
      })
      break

    case 'pdfStacked':
      // String axis needs something very different from number axis
      if (basisA.internalDataType === 'string') {
        appendBasisA_NameValue()
      }

      // Table column headers:
      measTextArray1 = ['', 'Series', 'Stacked', '']
      valueNodeArray1 = measTextArray1
      measTextArray2 = ['', 'PDF', 'PDF', '']
      valueNodeArray2 = measTextArray2
      infoTable.push({
        opacity: 1,
        measTextArray: measTextArray1,
        valueNodeArray: valueNodeArray1,
      })
      infoTable.push({
        opacity: 1,
        measTextArray: measTextArray2,
        valueNodeArray: valueNodeArray2,
      })
      appendBlankLine()

      // We order the listed series in SeriesOrder (left-to=right) for:
      //    side-by-side histograms
      //    stacked plots when basisA is the axisLeft (isTransposed === true)
      // We order in reverseSeriesOrder (top-to-bottom) for:
      //    stacked plots when isTransposed === false
      keyOrder =
        isStacked_anyLayer && isTransposed === false
          ? seriesOrder.slice().reverse()
          : seriesOrder(({ cumB, valueB } = getBvaluesBySeries()))
      var formattingObj = getFormattingObj('defaultEng', {
        precisionMode: 'std',
        precisionMin: 3,
      })
      keyOrder.forEach((orderedSeriesKey) => {
        let seriesTitleLabel = seriesTitle + ' :'
        let countMeas = numberFormatMeasureOnly(String(valueB[sKey]), formattingObj)
        let cumCountMeas = numberFormatMeasureOnly(String(cumB[sKey]), formattingObj)
        let countNode = numberFormatReactNode(String(valueB[sKey]), formattingObj)
        let cumCountNode = numberFormatReactNode(String(cumB[sKey]), formattingObj)
        var measTextArray = [seriesTitleLabel, countMeas, cumCountMeas, '']
        var valueNodeArray = [seriesTitleLabel, countNode, cumCountNode, '']
        let opacity = orderedSeriesKey === sKey ? 1 : 0.5
        infoTable.push({ opacity, measTextArray, valueNodeArray })
      })
      break

    case 'pdfCount':
      // We can get both fractional or very large counts
      // Use 4 significant figures, 'std'
      formattingObj = getFormattingObj('defaultEng', {
        precisionMode: 'std',
        precisionMin: 3,
      })
      appendSeries_seriesTitle()
      appendBasisA_NameValue()
      appendBasisB_NameValue(formattingObj)
      break

    case 'pdfPercent':
      appendSeries_seriesTitle()
      appendBasisA_NameValue()
      appendBasisB_NameValue(percentFormattingObj)
      break

    case 'percentileNormalProb':
      appendUserColKeyValues()
      appendSeries_seriesTitle()
      appendBasisA_NameValue()
      invariant(isSparsePlotPt(plotPt), 'Got dense or basic plot point in percentileNormalProb')
      if (plotPt.B < 10) {
        appendBasisB_NameValue({ ...percentFormattingObj, precision: 3 })
      } else if (plotPt.B < 90) {
        appendBasisB_NameValue({ ...percentFormattingObj, precision: 4 })
      } else if (plotPt.B < 99.9) {
        appendBasisB_NameValue({ ...percentFormattingObj, precision: 5 })
      } else if (plotPt.B < 99.99) {
        appendBasisB_NameValue({ ...percentFormattingObj, precision: 6 })
      } else {
        appendBasisB_NameValue({ ...percentFormattingObj, precision: 7 })
      }
      break

    case 'xyScatterPoints':
    case 'xyScatterLines':
    case 'xyScatterPointLines':
      appendUserColKeyValues()
      appendSeries_seriesTitle()
      appendBasisA_NameValue()
      appendBasisB_NameValue()
      break

    case 'xyScatterLinearFit':
      appendUserColKeyValues()
      appendSeries_seriesTitle()
      appendBasisA_NameValue()
      appendBasisB_NameValue()
      appendBlankLine()
      var measTextArray = ['Equation of', 'best fit :', '', '']
      infoTable.push({
        opacity: 1,
        measTextArray,
        valueNodeArray: measTextArray,
      })

      // Create the linearFit expressions
      var coefFormattingObj = getFormattingObj('defaultEng', {
        precisionMin: 4,
      })
      var C0value = seriesAttributesArray[sKey].C0
      var C1value = seriesAttributesArray[sKey].C1
      var titleB = basisB.axisTitleShort ? basisB.axisTitleShort : basisB.axisTitle
      var titleA = basisA.axisTitleShort ? basisA.axisTitleShort : basisA.axisTitle

      // Adjustment to logarithmicScale rendered equations.
      if (basisA.willUseLogWithNegativeData) {
        titleA = subtractChar + hairSpaceChar + titleA
        C1value *= -1
      }
      if (basisB.willUseLogWithNegativeData) {
        titleB = subtractChar + hairSpaceChar + titleB
        C0value *= -1
        C1value *= -1
      }

      const C0valueAbs = Math.abs(C0value) // strip the potential minus sign from C0Value; Inserted later as subtraction operator
      const C1valueAbs = Math.abs(C1value) // strip the potential minus sign from C!Value; Inserted later as unity minus operator
      var C0strg = numberFormatMeasureOnly(String(C0valueAbs), coefFormattingObj)
      var C0node = numberFormatReactNode(String(C0valueAbs), coefFormattingObj)
      var C1strg = numberFormatMeasureOnly(String(C1valueAbs), coefFormattingObj)
      var C1node = numberFormatReactNode(String(C1valueAbs), coefFormattingObj)

      if (basisB.willUseLogarithmicScale) {
        // Next is NOT what you see; this is approximation to measure
        var txt0 = `Log 10 ( ${titleB}  ) =` // This is NOT what you see; this is approximation to measure
        var node0: ReactNode = (
          <span>
            {log10Node}
            {openParen}
            {titleB}
            {closeParen}
            {' ='}
          </span>
        )
      } else {
        txt0 = titleB + ' ='
        node0 = titleB + ' ='
      }
      if (basisA.willUseLogarithmicScale) {
        // Next is NOT what you see; this is approximation to measure
        var titleA_txt = `Log 10( ${titleA}  )`
        var titleA_node = (
          <span>
            {log10Node}
            {openParen}
            {titleA}
            {closeParen}
          </span>
        )
      } else {
        titleA_txt = `( ${titleA}  )`
        titleA_node = (
          <span>
            {openParen}
            {titleA}
            {closeParen}
          </span>
        )
      }
      let C0operator = C0value > 0 ? ' + ' : ` ${subtractChar} `
      let C1operator = C1value > 0 ? '' : subtractChar + thinSpaceChar
      // Next is NOT what you see; this is approximation to measure
      let txt1 = C1operator + doubleSpace + C1strg + ' * ' + titleA_txt + ' ' + C0operator + doubleSpace + C0strg
      let node1 = (
        <span>
          {C1operator}
          {C1node}
          {thinSpaceChar}
          {MultiplyElement}
          {thinSpaceChar}
          {titleA_node}
          {C0operator}
          {C0node}
        </span>
      )
      infoTable.push({
        opacity: 1,
        measTextArray: [txt0, txt1, '', ''],
        valueNodeArray: [node0, node1, '', ''],
      })
      break

    case 'xyScatterSmoothFit':
      appendUserColKeyValues()
      appendSeries_seriesTitle()
      appendBasisA_NameValue()
      appendBasisB_NameValue()
      break

    case 'normStackedBars':
    case 'normStackedArea':
    case 'stackedBars':
    case 'stackedArea':
      if (tempIdenfier === 'normStackedBars' || tempIdenfier === 'normStackedArea') {
        var normalizationFactor = 0
        arrayB.forEach((val: number) => (normalizationFactor += Math.abs(val)))
        normalizationFactor /= 100
      } else {
        normalizationFactor = 1
      }

      // Each plotPt contains the entire stack of information (all series values):
      // pt.B = [ the table B value;  sparse array referenced by sKey ]
      // The plotting program stacks these from most negative cum value to most positive.
      // And with this order, it creates the respective stackedB0 and stackedB cum values.
      // pt.stackedB0 = [ sparse array referenced by sKey ]
      // pt.stackedB  = [ sparse array referenced by sKey ]
      // The table values are pt.stackedB[i] - pt.stackedB0[i]
      // The cum (stacked) values are pt.stackedB[i]

      // We want the crosshairs to display the stack from most positive (top) to most negative (bottom line).
      // We can re-create the proper order by inspecting the stackedB values.
      // Get a sorted version of seriesOrder, but sorted from greatest to least stackedB values.
      const seriesOrder_GreatestBtoLeastB = seriesOrder.slice().sort((a, b) => stackedB[b] - stackedB[a])
      appendUserColKeyValues()
      appendBasisA_NameValue()
      appendBlankLine()
      infoTable.push({
        opacity: 1,
        measTextArray: ['', 'Table', 'Stacked'],
        valueNodeArray: ['', 'Table', 'Stacked'],
      })
      appendBlankLine()
      seriesOrder_GreatestBtoLeastB.forEach((plottedOrderIndex) => {
        let bValTxt = String((stackedB[plottedOrderIndex] - stackedB0[plottedOrderIndex]) / normalizationFactor)
        let cumValTxt = String(stackedB[plottedOrderIndex] / normalizationFactor)
        let seriesTitleLabel = seriesTitle + ' :'
        let opacity = sKey === plottedOrderIndex ? 1 : 0.5

        // Percent formatting for normed plots.
        // Otherwise, user defined formatting of the table values
        let stackedFormattingObj =
          tempIdenfier.slice(0, 4) === 'norm'
            ? percentFormattingObj
            : {
              ...seriesAttributesArray[sKey].formattingObjB,
              allowsPrefixSuffix: false,
            }
        let measTextArray = [
          seriesTitleLabel,
          numberFormatMeasureOnly(bValTxt, stackedFormattingObj),
          numberFormatMeasureOnly(cumValTxt, stackedFormattingObj),
          '',
        ]
        let valueNodeArray = [
          seriesTitleLabel,
          numberFormatReactNode(bValTxt, stackedFormattingObj),
          numberFormatReactNode(cumValTxt, stackedFormattingObj),
          '',
        ]
        infoTable.push({ opacity, measTextArray, valueNodeArray })
      })
      break

    case 'sideBySideStackedBars':
      //var reverseSeriesOrder = ( seriesOrder.slice() ).reverse()
      appendUserColKeyValues()
      appendBasisA_NameValue()
      appendBlankLine()
      let formattingObjSideBySide = {
        ...seriesAttributesArray[sKey].formattingObjB,
        allowsPrefixSuffix: false,
      }
      seriesOrder.forEach((i) => {
        let seriesTitleLabel = seriesTitle + ' :'
        let value = String(stackedB[i] - stackedB0[i])
        let opacity = i === sKey ? 1 : 0.5
        let measTextArray = [seriesTitleLabel, numberFormatMeasureOnly(value, formattingObjSideBySide), '', '']
        let valueNodeArray = [seriesTitleLabel, numberFormatReactNode(value, formattingObjSideBySide), '', '']
        infoTable.push({ opacity, measTextArray, valueNodeArray })
      })
      break

    default:
  }

  appendBlankLine()
  return calcCrossHairsColumnWidths(infoTable)
}
