const adjectives = [
  'able',
  'abundant',
  'acceptable',
  'according',
  'accurate',
  'active',
  'actual',
  'additional',
  'adorable',
  'adult',
  'afraid',
  'aggressive',
  'agreeable',
  'alive',
  'alone',
  'amazing',
  'ancient',
  'angry',
  'annual',
  'anxious',
  'asleep',
  'automatic',
  'available',
  'aware',
  'bad',
  'basic',
  'beautiful',
  'best',
  'better',
  'bewildered',
  'big',
  'bitter',
  'black',
  'blue',
  'boiling',
  'boring',
  'brave',
  'breezy',
  'brief',
  'bright',
  'brilliant',
  'broad',
  'broken',
  'brown',
  'bumpy',
  'busy',
  'calm',
  'capable',
  'careful',
  'certain',
  'cheap',
  'chemical',
  'chilly',
  'chubby',
  'civil',
  'classic',
  'clean',
  'clear',
  'clever',
  'close',
  'clumsy',
  'cold',
  'colossal',
  'commercial',
  'common',
  'complete',
  'complex',
  'confident',
  'conscious',
  'consistent',
  'constant',
  'content',
  'cooing',
  'cool',
  'correct',
  'crazy',
  'creative',
  'creepy',
  'critical',
  'crooked',
  'cuddly',
  'cultural',
  'curious',
  'curly',
  'current',
  'curved',
  'cute',
  'damaged',
  'damp',
  'dangerous',
  'dark',
  'deafening',
  'dear',
  'decent',
  'deep',
  'delicious',
  'delightful',
  'dependent',
  'desperate',
  'different',
  'difficult',
  'direct',
  'dirty',
  'distinct',
  'double',
  'downtown',
  'drab',
  'dramatic',
  'dry',
  'due',
  'dusty',
  'eager',
  'early',
  'eastern',
  'easy',
  'effective',
  'efficient',
  'electrical',
  'electronic',
  'elegant',
  'emotional',
  'empty',
  'entire',
  'equal',
  'equivalent',
  'even',
  'exact',
  'excellent',
  'exciting',
  'existing',
  'expensive',
  'external',
  'extra',
  'extreme',
  'faint',
  'fair',
  'faithful',
  'false',
  'familiar',
  'famous',
  'fancy',
  'fast',
  'fat',
  'female',
  'fierce',
  'filthy',
  'final',
  'financial',
  'fine',
  'firm',
  'first',
  'fit',
  'flaky',
  'flat',
  'fluffy',
  'foreign',
  'formal',
  'former',
  'free',
  'freezing',
  'frequent',
  'fresh',
  'friendly',
  'full',
  'funny',
  'general',
  'gentle',
  'gifted',
  'gigantic',
  'glad',
  'glamorous',
  'global',
  'good',
  'grand',
  'gray',
  'greasy',
  'great',
  'green',
  'gross',
  'grumpy',
  'guilty',
  'handsome',
  'happy',
  'hard',
  'healthy',
  'heavy',
  'helpful',
  'helpless',
  'high',
  'hissing',
  'historical',
  'hollow',
  'honest',
  'hot',
  'huge',
  'hungry',
  'icy',
  'ill',
  'illegal',
  'immediate',
  'immense',
  'important',
  'impossible',
  'impressive',
  'individual',
  'inevitable',
  'informal',
  'initial',
  'inner',
  'internal',
  'itchy',
  'jealous',
  'jolly',
  'juicy',
  'junior',
  'just',
  'kind',
  'known',
  'large',
  'last',
  'late',
  'latter',
  'lazy',
  'leading',
  'least',
  'left',
  'legal',
  'light',
  'little',
  'lively',
  'local',
  'logical',
  'lonely',
  'long',
  'loose',
  'lost',
  'loud',
  'low',
  'lower',
  'lucky',
  'mad',
  'main',
  'major',
  'male',
  'mammoth',
  'massive',
  'medical',
  'melodic',
  'melted',
  'mental',
  'miniature',
  'minor',
  'mobile',
  'modern',
  'mushy',
  'mysterious',
  'narrow',
  'nasty',
  'national',
  'natural',
  'nearby',
  'neat',
  'necessary',
  'negative',
  'nervous',
  'new',
  'next',
  'nice',
  'noisy',
  'normal',
  'numerous',
  'nutritious',
  'obedient',
  'objective',
  'obnoxious',
  'obvious',
  'odd',
  'official',
  'ok',
  'old',
  'open',
  'opposite',
  'orange',
  'ordinary',
  'original',
  'other',
  'overall',
  'panicky',
  'particular',
  'past',
  'perfect',
  'personal',
  'petite',
  'physical',
  'plain',
  'pleasant',
  'political',
  'poor',
  'popular',
  'positive',
  'possible',
  'potential',
  'powerful',
  'practical',
  'pregnant',
  'present',
  'pretty',
  'previous',
  'prickly',
  'primary',
  'prior',
  'private',
  'proper',
  'proud',
  'public',
  'puny',
  'pure',
  'purple',
  'purring',
  'quaint',
  'quick',
  'quiet',
  'rainy',
  'rapid',
  'rare',
  'raspy',
  'raw',
  'ready',
  'real',
  'realistic',
  'reasonable',
  'recent',
  'red',
  'regular',
  'relevant',
  'relieved',
  'remarkable',
  'remote',
  'repulsive',
  'rich',
  'right',
  'rotten',
  'rough',
  'round',
  'royal',
  'sad',
  'safe',
  'salty',
  'same',
  'scared',
  'scary',
  'scrawny',
  'screeching',
  'secret',
  'secure',
  'senior',
  'sensitive',
  'separate',
  'serious',
  'several',
  'severe',
  'sexual',
  'shallow',
  'sharp',
  'short',
  'shy',
  'sick',
  'silly',
  'similar',
  'simple',
  'single',
  'skinny',
  'slight',
  'slow',
  'small',
  'smart',
  'smooth',
  'soft',
  'solid',
  'sorry',
  'southern',
  'spare',
  'sparkling',
  'sparse',
  'special',
  'specific',
  'spiritual',
  'square',
  'steep',
  'sticky',
  'still',
  'straight',
  'strange',
  'strict',
  'strong',
  'stupid',
  'successful',
  'such',
  'sudden',
  'sufficient',
  'suitable',
  'super',
  'sure',
  'suspicious',
  'sweet',
  'swift',
  'tall',
  'tart',
  'tasteful',
  'technical',
  'teeny',
  'temporary',
  'tender',
  'terrible',
  'thankful',
  'thick',
  'thin',
  'thundering',
  'tight',
  'tiny',
  'total',
  'tough',
  'true',
  'typical',
  'ugly',
  'uneven',
  'unfair',
  'unique',
  'united',
  'unlikely',
  'unusual',
  'upper',
  'upstairs',
  'uptight',
  'useful',
  'usual',
  'valuable',
  'various',
  'vast',
  'victorious',
  'visible',
  'visual',
  'warm',
  'weak',
  'weekly',
  'weird',
  'western',
  'wet',
  'whispering',
  'white',
  'whole',
  'wide',
  'wild',
  'willing',
  'wise',
  'witty',
  'wonderful',
  'wooden',
  'working',
  'worried',
  'worthy',
  'wrong',
  'yellow',
  'young',
  'yummy',
  'zealous',
]

module.exports = adjectives
