import { PureComponent } from 'react'
import { numberFormat, getCommasOnlyFormattingObj } from '../sharedFunctions/numberFormat'

type OwnProps = {
  width: number
  height: number
  numRowsUnfiltered: number
  numRowsFiltered: number
}

export default class SVGfilter extends PureComponent<OwnProps> {

  render() {
    const { numRowsUnfiltered, numRowsFiltered } = this.props
    const isFiltered = (numRowsUnfiltered !== numRowsFiltered)
    const commasOnlyFormattingObj = getCommasOnlyFormattingObj()
    const textColor = isFiltered ? '#A04040' : '#404040'
    const funnelFillColor = '#B0B0B0'
    return (
      <svg
        className={'rc_SVGfilter'}
        xmlns="http://www.w3.org/2000/svg"
        style={{ isolation: 'isolate', display: 'block' }}
        version="1.1"
        viewBox="0 0 1100 1100"
        preserveAspectRatio='none'
        width={this.props.width}
        height={this.props.height}
      >
        <text x='512' y='220' textAnchor='middle' fill={textColor} fontSize='266' >
          {numberFormat(String(numRowsUnfiltered), commasOnlyFormattingObj, 'noHtml')}
        </text>
        <path d="m 266,300  192,224 v 192 l 128,96 V 480 L 768,300 Z" fill={funnelFillColor} />
        <path d="m 256,300  192,224 v 192 l 128,96 V 480 L 768,300 Z m 64,32 H 704 L 544,464 V 704 L 480,656 V 464 Z" fill={textColor} />
        <text x='512' y='1050' textAnchor='middle' fill={textColor} fontSize='266' >
          {numberFormat(String(numRowsFiltered), commasOnlyFormattingObj, 'noHtml')}
        </text>
      </svg>
    )
  }
}
