import React, { Component } from 'react'
import { isScryHyperlink_fromUserInputs } from '../sharedFunctions/isTypeHyperlink'
import HyperlinkButton from '../sharedComponents/HyperlinkButton'
import reactDispatch from '../sharedComponents/reactDispatch'
import { cleanScryInputText2 } from '../sharedFunctions/cleanScryInputText'
import type { CleanTextOptions } from '../sharedFunctions/cleanScryInputText'
import { deepClone } from '../sharedFunctions/utils'
import type { TextWithLinks } from '../types'


type Props = {
  parentMode: '' | 'cellEditor' | 'colDescription' | 'tableDescription' | 'plotDescription' | 'plotSeriesDescription',
  tableid: string,
  tabledataid: string,
  plotid?: string,

  colOrSeriesKey: number,
  rowKey: number,
  linkIndex: number,
  textWithLinks: TextWithLinks,  // Used to clone, then modify for reactUrl and label state changes.

  inputName: string,
  inputUrl: string,
  currentCursor: number,
  synchCurrentName: (newValue: string) => void,
  setCurrentNameNode: ( node : HTMLInputElement | null ) => void,
}

type LocalState = {
  currentUrl: string,
  currentName: string,    // Only used for 'cellEditor' mode.  Otherwise the parent owns the currentName.

  // Next two are calculated with the isScryHyperlink module:
  canonicalUrl: string,  // Not what they typed, but what we will use when 'test' button pushed
  tableValue: string,
  errorMsg: string,      // Any errors concerning Url they input??

  lastColOrSeriesKey: number,
  lastRowKey: number,
  lastLinkIndex: number,
}

export default class HyperlinkEditor extends Component<Props, LocalState> {

  constructor(props: Props) {
    super(props)
    this.state={
      currentUrl: '',
      currentName: '',

      canonicalUrl: '',
      tableValue: '',
      errorMsg: '',

      lastColOrSeriesKey:-1,
      lastRowKey:-1,
      lastLinkIndex:-1,
    }
  }

  static defaultProps = {
    tableid: '',
    //plotid: '',
    tabledataid: '',
    colOrSeriesKey: -1,
    rowKey: -1,
    linkIndex: -1,
    textWithLinks: { text: '', links: [] },
    currentCursor: 0,
    setCurrentNameNode: (n: HTMLInputElement | null) => {},
    synchCurrentName: (n: string) => {},
  }

  TIMEOUT_DELAY = 300
  timeoutID_name: NodeJS.Timeout | string | number | undefined = undefined
  timeoutID_Url: NodeJS.Timeout | string | number | undefined = undefined


  // Re-initialize the internal values (Name and Url) whenever row/col key changes.
  // The state for the currentName is maintained by the parent.  As it
  // is needed by TextWithLinks component to keep the two synchronized.
  // Exception:  In 'cellEditor' mode, there is no parent.  This component
  // controls its own currentName and currentUrl states.
  static getDerivedStateFromProps( nextProps:Props,   prevState:LocalState ) {
    const {rowKey, colOrSeriesKey, linkIndex, inputUrl, inputName} = nextProps
    const {lastRowKey, lastColOrSeriesKey, lastLinkIndex} = prevState
    if ( lastColOrSeriesKey !== colOrSeriesKey || lastRowKey !== rowKey || lastLinkIndex !== linkIndex ) {
       const isScryHyperlinkResult = isScryHyperlink_fromUserInputs( inputName, inputUrl )
       return { currentUrl:inputUrl,
                currentName: inputName,
                canonicalUrl: isScryHyperlinkResult.canonicalUrl,
                errorMsg: isScryHyperlinkResult.errorMsg,
                tableValue: isScryHyperlinkResult.tableValue,
                lastRowKey:rowKey,
                lastLinkIndex: linkIndex,
                lastColOrSeriesKey:colOrSeriesKey }
    }
    return { }
  }

  componentWillUnmount = () => {
    if ( this.timeoutID_name !== undefined && this.props.parentMode === 'cellEditor') {
      this.updateCellValue( )
      clearTimeout(this.timeoutID_name)
    }
    if ( this.timeoutID_Url !== undefined ) {
      this.updateReactUrl( )
      clearTimeout(this.timeoutID_Url)
    }
  }

  updateCurrentUrl = ( e: React.ChangeEvent<HTMLInputElement> ): void => {

    const options: CleanTextOptions = { deleteLeadingSpaces: true,
                      ignoreLineFeeds: true,
                      ignoreSpaces: true,
                      maxCharCount: 500,
                    };
    var {newValue, newSelectionStop} = cleanScryInputText2( e.target.value, Number(e.target.selectionEnd), options  )
    if ( e.target.value.length !== newValue.length ) {
      e.target.value = newValue.slice()  // This line also resets selection start to useless value.
      e.target.selectionStart = newSelectionStop
      e.target.selectionEnd   = newSelectionStop
    }

    // Create a canonicalUrl and errorCheck input:
    const isScryHyperlinkResult = isScryHyperlink_fromUserInputs( this.state.currentName, newValue )
    this.setState({
      currentUrl  : newValue,
      canonicalUrl: isScryHyperlinkResult.canonicalUrl,
      errorMsg    : isScryHyperlinkResult.errorMsg,
      tableValue  : isScryHyperlinkResult.tableValue,
    })

    clearTimeout(this.timeoutID_Url)
    this.timeoutID_Url = setTimeout( this.updateReactUrl, this.TIMEOUT_DELAY)
  }



  updateCurrentName = ( e: React.ChangeEvent<HTMLInputElement> ): void => {
    let { parentMode } = this.props
    const options = { deleteLeadingSpaces: true,
                      ignoreLineFeeds: true,
                      mergeSpaces: false,
                      maxCharCount: 100,
                    };

    var {newValue, newSelectionStop} = cleanScryInputText2( e.target.value, Number(e.target.selectionEnd), options  )
    if ( parentMode !== 'cellEditor' && parentMode !== '' && newValue.slice(-1) !== ' ') {
      // Case where the user attempts to delete the last required space
      // from a TextWithLinks link.  We need to put it back.  But don't
      // adjust the cursor.  Will Appear to user like they backspaced over
      // the required space, rather than deleting it.
      newValue += ' '
    }
    if ( e.target.value.length !== newValue.length ) {
      e.target.value = newValue.slice()  // This line also resets selection start to useless value.
      e.target.selectionStart = newSelectionStop
      e.target.selectionEnd   = newSelectionStop
    }
    const isScryHyperlinkResult = isScryHyperlink_fromUserInputs( newValue, this.state.currentUrl )
    this.setState( {currentName: newValue, tableValue: isScryHyperlinkResult.tableValue } )
    // Case where the parent owns the currentName:
    if ( parentMode !== 'cellEditor' && parentMode !== '' ) {
      this.props.synchCurrentName( newValue )
    }
    // Case where this component owns the currentName
    else if ( parentMode === 'cellEditor' ) {
      clearTimeout(this.timeoutID_name)
      //console.log( 'setting timeout_name', newValue)
      this.timeoutID_name = setTimeout( this.updateCellValue, this.TIMEOUT_DELAY)
    }
  }



  updateReactUrl = ():void => {
    if ( this.props.parentMode === 'cellEditor' ) {
      this.updateCellValue( )
      return
    }
    // Info for TextWithLinks components
    const {colOrSeriesKey, linkIndex, textWithLinks} = this.props
    const newDescription = deepClone(textWithLinks)
    newDescription.links[linkIndex].href = this.state.canonicalUrl
    if ( this.props.parentMode === 'colDescription' ) {
        const mods = [{
          newVal: newDescription,
          path: `attributes.columns[${colOrSeriesKey}].colDescription`
        }]
        reactDispatch(mods, 'Column description link changed', '', 'tables', this.props.tableid)
    }
    else if ( this.props.parentMode === 'plotSeriesDescription' ) {
        const mods = [{
          newVal: newDescription,
          path: `attributes.series[${colOrSeriesKey}].seriesDescription`
        }]
        reactDispatch(mods, 'Series description link changed', '', 'plots', this.props.plotid)
    }
  }


  updateCellValue = ():void => {
    const {colOrSeriesKey, rowKey, tabledataid} = this.props
    const {tableValue} = this.state
    // We save the state, whether the cellValue is legal or not!
    let mods = [{newVal: tableValue, path: `attributes.tableValues[${colOrSeriesKey}][${rowKey}]`}]
    reactDispatch(mods, 'Table value hyperlink changed', '', 'tabledatas', tabledataid)
  }


  testLink = ( ) => {
    window.open(this.state.canonicalUrl, '_blank',
       'rel=noopener noreferrer,'+
       'menubar=yes,'+
       'location=yes,'+
       'resizable=yes,'+
       'scrollbars=yes,'+
       'status=yes')
  }

  render() {
    const {parentMode, inputName} = this.props
    const {currentUrl, currentName, errorMsg} = this.state
    const fontSize   = parentMode === 'cellEditor' ? '16px' : '14px'
    const widthTotal = parentMode === 'cellEditor' ? 420 : 350
    const widthLabel = parentMode === 'cellEditor' ? 55 : 45
    const gapLabelInput = 5
    const gapInputButton = 10
    const marginRight = 7
    const widthTestButton = 70
    const inputPaddingLeft = 6
    const widthLinkName = widthTotal - widthLabel - gapLabelInput - marginRight - widthTestButton - gapInputButton
    const widthLinkUrl  = widthTotal - widthLabel - gapLabelInput - marginRight
    //console.log( 'render hyperlink editor' )
    var topGap = 4
    var bottomGap = 4
    var centerGap = 2
    if ( parentMode === 'cellEditor' ) {
      topGap = 10
    }

    const isDisabled = (errorMsg !== '')
    const displayedErrorMsg = (errorMsg === '')
      ? ' ' // This empty Space is invisible, but forces msg area size its height properly
      : errorMsg


    return (
      <div className={'rc_HyperlinkEditor'}
        style={{fontSize: fontSize, marginTop: topGap }}>

        {/* Line 1 */}
        <div className={'Line_1'}
          style={{ position:'relative', width:widthTotal }} >

            <label
              style={{
                display: 'inline-block',
                textAlign: 'right',
                width: widthLabel,
              }}
              htmlFor='hyperlinkName'>
              {'Label\u2009:'}
            </label>

            <input
              id='hyperlinkName'
              ref={ this.props.setCurrentNameNode }
              onChange={this.updateCurrentName}
              type='text'
              style={{
                display: 'inline-block',
                width: widthLinkName, height: 20,
                marginLeft: gapLabelInput,
                paddingLeft: inputPaddingLeft,
                verticalAlign: 'text-middle',
                borderRadius: 4, borderStyle: 'solid', borderWidth: 1,
              }}
              value={parentMode==='cellEditor' ? currentName : inputName}
              autoComplete='off'
              spellCheck='false'
            />

          < div style={{opacity: isDisabled? 0.5 : 'unset', pointerEvents: isDisabled? 'none' : 'unset' }}>
            <HyperlinkButton
              height={20}
              width={widthTestButton}
              handleClick={this.testLink}
              mode={'cellEditor'}
              top={0}
              right={marginRight}/>
          </div>

        </div>


        {/* Line 2 */}
        <div style={{marginTop: centerGap, marginBottom: bottomGap }} >
            <label
              style={{
                display: 'inline-block',
                textAlign: 'right',
                width: widthLabel,
              }}
              htmlFor='hyperlinkUrl'>
                {'URL :'}
            </label>

            <input
              id='hyperlinkUrl'
              onChange={this.updateCurrentUrl}
              style={{
                display: 'inline-block',
                width: widthLinkUrl, height: 20,
                marginLeft: gapLabelInput,
                paddingLeft: inputPaddingLeft,
                verticalAlign: 'text-middle',
                borderRadius: 4, borderStyle: 'solid', borderWidth: 1,
                color: isDisabled ? 'red' : 'unset'
              }}
              autoComplete='off'
              spellCheck='false'
              type='text'
              value={currentUrl}
            />
        </div>

        {/* Line 3  Error message */}
{ parentMode === 'cellEditor' &&
        <div
          style={{
            height: 20,
            paddingTop: 2, paddingBottom: 2,
            color:'red',
            paddingLeft:widthLabel + 12,
            fontSize : '14px',
          }}>
          { displayedErrorMsg }
        </div>
}

      </div>
    )
  }
}
