import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {ErrorTypes}  from '../../shared/ErrorTypes'
import type {ErrorType} from '../../shared/ErrorTypes'
import type {JsonApiError} from '../jsonapi/types'
import type {Notification} from '../viewNotifications/types'

// export const NOTIFY_ERROR = 'NOTIFY_ERROR'
// export const NOTIFY_INFO = 'NOTIFY_INFO'
// export const NOTIFY_JSONAPI_ERROR = 'NOTIFY_JSONAPI_ERROR'
// export const NOTIFY_REPLAY = 'NOTIFY_REPLAY'
// const NOTIFY_SAVE = 'NOTIFY_SAVE'
// const NOTIFY_VISIBILITY = 'NOTIFY_VISIBILITY'
// export const NOTIFY_WARNING = 'NOTIFY_WARNING'

// export const notifyError = createAction(NOTIFY_ERROR, (errorType: ErrorType, message: string): Object => ({errorType, message}))
// export const notifyInfo = createAction(NOTIFY_INFO, (message: string): Object => ({message}))
// export const notifyJsonApiError = createAction(NOTIFY_JSONAPI_ERROR, (errors: Array<JsonApiError>, type?: ErrorType): Object => ({errors, type}))
// export const replayNote = createAction(NOTIFY_REPLAY)
// export const saveNote = createAction(NOTIFY_SAVE, (notification: Notification): Notification => (notification))
// export const setNoteVisibility = createAction(NOTIFY_VISIBILITY, (index: number, visible: boolean): Object => ({index, visible}))
// export const notifyWarning = createAction(NOTIFY_WARNING, (title: string, message: string): Object => ({message, title}))

// export type NotifyAction = ActionType<typeof notifyError>
//                           | ActionType<typeof notifyInfo>
//                           | ActionType<typeof notifyJsonApiError>
//                           | ActionType<typeof replayNote>
//                           | ActionType<typeof saveNote>
//                           | ActionType<typeof setNoteVisibility>
//                           | ActionType<typeof notifyWarning>

export type NotifyState = {
  lastThreeNotes: Array<Notification>,
  lastThreeVisible: Array<boolean>,
  mostRecentNoteIndex: number
}

// This is what will appear when the user first clicks the replay button.
const initialNote: Notification = {
  autoDismiss: 0,
  errorID: '',
  errorType: ErrorTypes.INFO,
  linkToHelpDisplayText: '',
  linkToHelpURL: '',
  message: 'This button replays your three most recent notifications.',
  noteID: '',
  position: 'tr',
  timeStamp: 0,
  title: '',
}

const defaultState: NotifyState = {
  lastThreeNotes: [
    {
      ...initialNote,
    },
    {
      ...initialNote,
    },
    {
      ...initialNote,
    },
  ],
  lastThreeVisible: [
    false,
    false,
    false
  ],
  mostRecentNoteIndex: 0
}

// const notifyReducer = (currentState: NotifyState = defaultState, action: NotifyAction): NotifyState => {

//   switch (action.type) {
//     case NOTIFY_SAVE: {
//       const notification = action.payload
//       const nextIndex = (currentState.mostRecentNoteIndex + 1) % 3
//       const newState = immutable.wrap(currentState)
//                         .set(['mostRecentNoteIndex'], nextIndex)
//                         .set(['lastThreeNotes', nextIndex], notification)
//                         .value()
//       return newState
//     }

//     case NOTIFY_VISIBILITY: {
//       if (action.payload) {
//         const {index, visilbe} = action.payload
//         const newState = immutable.wrap(currentState)
//                           .set(['lastThreeVisible', index], visilbe)
//                           .value()
//         return newState
//       } else {
//         return currentState
//       }
//     }

//     default:
//       return currentState
//   }
// }

type ErrorPayload = {
  errorType: ErrorType
  message: string
}

type JsonApiErrorPayload = {
  errors: Array<JsonApiError>
  type?: ErrorType
}

type NotifyVisibilityPayload = {
  index: number
  visible: boolean
}

type NotifyWarningPayload = {
  title: string
  message: string
}

const notifySlice = createSlice({
  name: 'notify',
  initialState: defaultState,
  reducers: {
    notifyError(state, action: PayloadAction<ErrorPayload>): void {
      console.log(`notifyError ${JSON.stringify(action.payload)}`)
    },
    notifyInfo(state, action: PayloadAction<string>): void {
    },
    notifyJsonApiError(state, action: PayloadAction<JsonApiErrorPayload>): void {
    },
    replayNote(state): void {
    },
    saveNote(state, action: PayloadAction<Notification>): void {
      const notification = action.payload
      const nextIndex = (state.mostRecentNoteIndex + 1) % 3
      state.mostRecentNoteIndex = nextIndex
      state.lastThreeNotes[nextIndex] = notification
    },
    setNoteVisibility(state, action: PayloadAction<NotifyVisibilityPayload>): void {
      if (action.payload) {
        const {index, visible} = action.payload
        state.lastThreeVisible[index] = visible
      }
    },
    notifyWarning(state, action: PayloadAction<NotifyWarningPayload>): void {
    }
  }
})

export const {
  notifyError,
  notifyInfo,
  notifyJsonApiError,
  replayNote,
  saveNote,
  setNoteVisibility,
  notifyWarning
} = notifySlice.actions

export const NOTIFY_ERROR = notifySlice.actions.notifyError.toString()
export const NOTIFY_INFO = notifySlice.actions.notifyInfo.toString()
export const NOTIFY_JSONAPI_ERROR = notifySlice.actions.notifyJsonApiError.toString()
export const NOTIFY_REPLAY = notifySlice.actions.replayNote.toString()
export const NOTIFY_WARNING = notifySlice.actions.notifyWarning.toString()

export default notifySlice.reducer
