import { PureComponent } from 'react'

type OwnProps = {
  width: number
  height: number
  color: string
}
export default class SVGresizeCol extends PureComponent<OwnProps> {
  render() {
    return (
      <svg
        className={'rc_SVGresizeCol'}
        xmlns="http://www.w3.org/2000/svg"
        style={{ isolation: 'isolate', display: 'block' }}
        viewBox="0 0 1200 1200"
        preserveAspectRatio='none'
        width={this.props.width}
        height={this.props.height}
      >
        <clipPath id="_clipPath_KFZexnhNrksHHhQ2okwNGQT4wGQJ1HLe">
          <rect x="0" y="0" width="1200" height="1200" transform="matrix(1,0,0,1,0,0)" />
        </clipPath>
        <g clipPath="url(#_clipPath_KFZexnhNrksHHhQ2okwNGQT4wGQJ1HLe)">
          <g id="Group">
            <path d=" M -10 600 L 360 170 L 360 450 L 500 450 L 495.785 0 L 690 0 L 690 450 L 820 450 L 820 170 L 1200 600 L 830 1010 L 830 750 L 690 750 L 690 1200 L 490 1200 L 490 750 L 360 750 L 360 1020 L -10 600 Z "
              fill={this.props.color} />
            <path d=" M 51.282 600 L 307.692 305.66 L 307.692 498.113 L 543.59 498.113 L 543.59 45.283 L 646.154 45.283 L 646.154 498.113 L 882.051 498.113 L 882.051 305.66 L 1138.462 600 L 882.051 894.34 L 882.051 701.887 L 646.154 701.887 L 646.154 1154.717 L 543.59 1154.717 L 543.59 701.887 L 307.692 701.887 L 307.692 894.34 L 51.282 600 Z "
              fill={this.props.color} />
          </g>
        </g>
      </svg>
    )
  }
}
