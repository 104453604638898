import { Component } from 'react'
import type { ReactNode } from 'react'
import './Progress.css'

type Props = {
  progressMsg: string[]
}

class Progress extends Component<Props> {

  static defaultProps: Props = {
    progressMsg: ['Loading']
  }

  render() {
    const frameDuration = 0.1
    const numItems = 7
    const animationDuration = numItems * 2 * frameDuration
    const itemStyles = []
    const {progressMsg} = this.props

    for (let i = 0; i < numItems; i++) {
      const itemStyle = {
        animation: `colorShift ${animationDuration}s linear infinite`,
        animationDelay: `${(i*frameDuration) - animationDuration}s`,
      }
      itemStyles.push(itemStyle)
    }

    // If parent explicitly passed NO progress message, don't render the widget.
    // The default if no progressMsg is passed is one line message 'Loading . . . '
    // The general message is:
    //        'Some 1st work completed
    //        'Some 2nd work completed
    //        'Some current work in progress . . . '
    if (progressMsg.length === 0) { return null }

    return (
      <span className={'rc_Progress'}
        style={{
          position: 'relative',
          left: 20, top: 20,
          fontSize: 20,
        }} >
          {progressMsg.map( (thisLine,i) : ReactNode => {
              if ( i < progressMsg.length - 1 ) {
                return ( <div key={i} >{thisLine}</div> )
              } else {
                return <span key={i} >{thisLine}</span>
              }
          })}
          {itemStyles.map( (itemStyle, i) => (<span key={i} style={itemStyle}>.</span>))}
      </span>
    )
  }
}

export default Progress
