import React from 'react';


export const SVGresizeTextEdit : React.FC = () => {
  return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 50 50"
        height='100%'
        width='100%'
        //style ={{background: 'yellow' }}
      >

        <path
          d="M 10,50 L 50,10 M 30,50 L 50,30"
          style ={{fill: 'none',
                   stroke:'#000000',
                   strokeWidth:2,
                   strokeLinecap:'round'
                  }}
        />
      </svg>
  )
}
// "M 0,50 L 50,0 M 25,50 L 50,25"
// "M 0,0 L 50,0 L 50,50 L 0,50 L 0,0" 
