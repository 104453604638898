import type { FormatRule, FormattingObj } from '../sharedFunctions/numberFormat'
import type { PlotXyComputedAxis, PlotXyComputedData } from './xy_plotTypes'

import invariant from 'invariant'
import { list } from 'radash'
import { thinSpaceChar } from '../sharedComponents/constants'
import {
  getFormattingObj,
  isB60Format,
  numberFormatNoHTML
} from '../sharedFunctions/numberFormat'
import { lastVal } from '../sharedFunctions/utils'
import { EPSILON, getBinWidthOptions } from './plotUtils'

// LOGARITHMIC TICK LABELS:
// We render a black grid line at each decade.
// And render light (grey) grid lines between decades at values intermediate values 2,3,4,5,6,7,8,9
// As the range (number of decades) grows we need to label fewer and fewer
// light grid lines.  And eventually we don't even render the light grid lines
// between decades. Following constants define the break points where we modify
// tick labeling rules.  (
const STARTRANGE_FOR_SPARSE_DECADES = 12  // Every 3rd decade, 5th, 10th, ...
const STARTRANGE_FOR_ONLY_DECADES_NO_LIGHT_GRIDLINES = 7.01
const STARTRANGE_FOR_DECADE_WITH_LIGHT_GRIDLINES = 4.01
const STARTRANGE_FOR_GRIDLINES_WITH_125_LABELS = 2.1
const STARTRANGE_FOR_GRIDLINES_WITH_12468_LABELS = 1.301  // Range > 1 to 20. (20x)
const STARTRANGE_FOR_GRIDLINES_WITH_1234568_LABELS = 0.8    // Range > 1.5 to 10  (6.7x)
// When logRange <= STARTRANGE_FOR_GRIDLINES_WITH_1234568_LABELS (decades) we treat
// the logarithm scale as a 'distorted' linear plot. And use the formatting rules
// for linear tick labeling.

// When plotting B60 formats, this is the max allowed tick value prior to
// forcing the formatted tick labels into 'exponential seconds format'
// or '-exponential seconds format'
const B60B60_MAX_VALUE = 3600 * 1e3   //seconds
const B60_MAX_VALUE = 60 * 1e3        //seconds
const B60_MIN_RANGE = 0.1           //seconds
const STARTRANGE_FOR_B60_TENS_LABELS = 4.01
const STARTRANGE_FOR_B60_1246_LABELS = 2.1
const STARTRANGE_FOR_B60_12468_LABELS = 1.302  // Range from 1 to 20 === 1.301029996 decades.


// This function fills out the AxisRender object 'in-place'

const smartNumberAxisScale = (axis: PlotXyComputedAxis, isForcedEvenNumTicks: boolean = false): void => {

  var { rangeSmartAxisAllSeries, tickFormatRule,
    isMinForced, isMaxForced, willUseLogarithmicScale } = axis
  var { min, max } = rangeSmartAxisAllSeries
  // safety catch.  Otherwise chrome will lock up and need to be restarted
  if (min >= Number.MAX_VALUE || max <= -Number.MAX_VALUE ||
    min === Infinity || max === -Infinity || min === max) {
    invariant(false, `rangeCulled into smartNumberAxisScale was not properly set:  min:${min} , max:${max}`)
  }


  // Is this a linear axis, and a B60 formatRule, but range of the data is not suitable for B60 format?
  // Specifically, |maxVal| or |minVal| exceeds 999:59:59  ( or 9999:59 for B60)
  // OR: maxVal-minVal < 00:00:00.1
  // In this case, we are going to place tick lines and labels using exponential Base10 rules.
  // We will overwrite the formatRule from B10 to 'defaultEng'
  // We do this early and both the tick positions and tick label formatting will use B10.
  var didForceB60rule_to_PlusExponentialSeconds = false
  var didForceB60rule_to_MinusExponentialSeconds = false
  if (tickFormatRule === 'B60B60seconds' && (Math.max(Math.abs(max), Math.abs(min)) >= B60B60_MAX_VALUE)) {
    didForceB60rule_to_PlusExponentialSeconds = true
    tickFormatRule = 'defaultEng'
  } else if (tickFormatRule === 'B60seconds' && (Math.max(Math.abs(max), Math.abs(min)) >= B60_MAX_VALUE)) {
    didForceB60rule_to_PlusExponentialSeconds = true
    tickFormatRule = 'defaultEng'
  } else if (isB60Format(tickFormatRule) && max - min <= B60_MIN_RANGE) {
    didForceB60rule_to_MinusExponentialSeconds = true
    tickFormatRule = 'defaultEng'
  }

  //var tickVisValues, tickVisValuesLight = null, tickUserValues
  let result, tickVisValuesLight = null
  //, tickSizeExponent, domainConstrained, domainUnconstrained
  if (axis.willUseLogarithmicScale) {
    result = getTickValuesLogarithmic(min, max, isForcedEvenNumTicks, tickFormatRule);
  } else {
    // A linear axis OR was forced to a linear axis because axis contains zero.
    result = getTickValuesLinear(min, max, isForcedEvenNumTicks, tickFormatRule);
  }
  let { tickVisValues, tickUserValues, domainConstrained, tickSizeExponent } = result
  if (!axis.willUseLogarithmicScale) {
    domainConstrained = [tickVisValues[0], lastVal(tickVisValues)]
  }
  const domainUnconstrained: [number, number] = [...domainConstrained]

  axis.rangeUnconstrainedSmartAxis.min =
    Number((axis.reverseNonlinearXform(domainConstrained[0]).toPrecision(12)))
  axis.rangeUnconstrainedSmartAxis.max =
    Number((axis.reverseNonlinearXform(domainConstrained[1]).toPrecision(12)))


  // Now we potentially contract (shorten) domainConstrained.
  // It can be shorter because when the user asks for an extent that does not
  // fall exactly on a tick mark.  In which case we set the extent to
  // exactly what the user requested.  And we delete the tickmark if that
  // tickmark has been pushed out beyond the user's specified axis domain.
  var minVis: number = axis.nonlinearXform(min)
  var maxVis: number = axis.nonlinearXform(max)
  // Code for the firstTick mark (minTick)
  if (isMinForced && domainConstrained[0] < minVis - EPSILON) {
    domainConstrained[0] = minVis
    if (tickVisValues[0] < domainConstrained[0]) {
      tickVisValues = tickVisValues.slice(1)
      tickUserValues = tickUserValues.slice(1)
    }
  }
  // Code for the lastTick mark (maxTick)
  if (isMaxForced && domainConstrained[1] > maxVis + EPSILON) {
    domainConstrained[1] = maxVis
    if (lastVal(tickVisValues) > domainConstrained[1]) {
      tickVisValues = tickVisValues.slice(0, -1)
      tickUserValues = tickUserValues.slice(0, -1)
    }
  }


  // Create Formatted Tick Labels : 'tickUserStringsNoHTML'
  // By 'noHTML' we mean NO raised exponents.
  // However, the strings we create here will have the proper
  // 'precision' (number of digits).  Precision is NOT determined per individual
  // tick labels, but by the set of tick labels constituting the entire axis.
  // So there is a consistency to the tick labeling across the entire axis.
  //
  // Tick-label formatting for plots is different from Table value formatting.
  // For tables:
  //      numberFormat() function does two steps: Formats the text; Adds the HTML if needed.
  // For plots:
  //      react-vis has an input for the tick labels (strings-noHTML) and an input for a
  //      formatting function. Hence, we need to break the formatting into two steps:
  //      1) This smartAxis algorithm creates the tick 'text', without any HTML (code which follows)
  //      2) ComponentXY will pass react-vis a 'tick formatting function'.
  //         We only use the react-vis formatting function to add the HTML. (raise exponents)
  //         The more difficult text formating (including precision) is done here in the smartAxis module.
  //         The 'addHTML' step is effectively the same code used for raising exponents
  //         in the Table formatting flow.  The difference is for plots, raising the exponent
  //         is done late in the process, within the react-vis rendering function.

  const isLinear = !axis.willUseLogarithmicScale
  const isB60 = isB60Format(tickFormatRule)
  var formattingObj = getFormattingObj('defaultEng')
  var labelsPerDecade: number = -1   // Value of '1' means default HTML formatting will render 1*e6 as 10^6 (drops significand)

  // Shared function used for linear scales, and 'slightly distorted' logarithmic scales.
  const get_linear_scale_formatting_obj = (): FormattingObj => {
    // Next expression uses the tickUserValues range, rather than the domain range.
    // They are identical in the case of a linear scale.  However, are very
    // different in the case of a logarithmic scale.
    let maxValue = Math.max(Math.abs(lastVal(tickUserValues)), Math.abs(tickUserValues[0]))

    // find maxValueExponent :
    // What is the placeIndex of it's first digit?
    // For example 123456 =>  maxValueExponent = 5  (10^5 place)
    // 12.345 => maxValueExponent = 1 (10^1)
    let maxValueExponent = (maxValue === 0) ? 0 : Math.floor(Math.log10(maxValue))
    let numDigits = maxValueExponent - tickSizeExponent + 1
    var forcedExp = 0

    if (didForceB60rule_to_PlusExponentialSeconds || didForceB60rule_to_MinusExponentialSeconds) {
      // RULE_ExtremeTemporal
      // Forced usage of B10 ticks and B10 tick labeling. Because range of temporal values
      // is so far out of range of the hhh,hhh:mm:ss.xx format.  This extreme case will
      // never be used by sensible person.  But we need to cover the full range.
      return getFormattingObj('scientific', {forcedExp: tickSizeExponent, isForcedExp:true, firstExpUsageUpper:1, firstExpUsageLower:-1 })
    } else if (isB60) {
      // RULE_TypicalTemporal
      // Appears as hhhhh:mm:ss.xxxxxx
      return getFormattingObj(tickFormatRule, { precisionFixed: -tickSizeExponent, precisionMode: 'fixed' })
    } else {
      // Engineering notation, with 3 exceptions for number of the forms: 0.X, 0.0X, 0.XX
      // We also skip e-3 notation, instead writing out the 4-6 digit number.
      forcedExp = Math.floor(maxValueExponent / 3) * 3
      // Skip e-3 terms of the forms:  0.X, 0.XX, 0.0X
      if ((numDigits <= 2 && maxValueExponent === -1) || (numDigits === 1 && maxValueExponent === -2)) { forcedExp = 0 }
    }
    if (forcedExp === 3) { forcedExp = 0 }   // Skip exponential form for xxx * e3
    return getFormattingObj('scientific', { forcedExp, isForcedExp:true, firstExpUsageUpper:1, firstExpUsageLower:-1 })
  }

  let logRange: number
  // Linear scales (both B10 and B60)
  if (isLinear) {
    logRange = NaN
    formattingObj = get_linear_scale_formatting_obj()
  }
  else if (willUseLogarithmicScale && !isB60) {
    // Define logRange using the same min/max values that were used earlier to
    // determine the tickLocations and tickLabels.  DO NOT use the range of the
    // domain as that is slightly larger than the criteria used to create the labels.
    logRange = Math.abs(Math.log10(max) - Math.log10(min))
    switch (true) {

      case logRange > STARTRANGE_FOR_ONLY_DECADES_NO_LIGHT_GRIDLINES:
        // No light gridlines or ticklabels.  Only dark line and labels at decades.
        labelsPerDecade = 1
        formattingObj = getFormattingObj('scientific', { firstExpUsageUpper: 1, firstExpUsageLower: -1, precisionMin: 1, precisionMode: 'std' })
        break

      case logRange > STARTRANGE_FOR_DECADE_WITH_LIGHT_GRIDLINES:
        // show light exponential grid lines.  Tick labels at only decades.
        labelsPerDecade = 1
        var cullingResult = cullVisiblePlotLabels(tickVisValues, tickUserValues, tickFormatRule, 'tens');
        ({ tickVisValues, tickVisValuesLight, tickUserValues } = cullingResult)
        formattingObj = getFormattingObj('scientific', { firstExpUsageUpper: 3, firstExpUsageLower: -3, precisionMin: 1, precisionMode: 'std' })
        break

      case logRange > STARTRANGE_FOR_GRIDLINES_WITH_125_LABELS:
        // show light exponential grid lines.  Tick labels at only 1,2,5 gridlines.
        labelsPerDecade = 3
        cullingResult = cullVisiblePlotLabels(tickVisValues, tickUserValues, tickFormatRule, '125s');
        ({ tickVisValues, tickVisValuesLight, tickUserValues } = cullingResult)
        formattingObj = getFormattingObj(tickFormatRule, { precisionMin: 15, precisionMode: 'min' })
        break

      case logRange > STARTRANGE_FOR_GRIDLINES_WITH_12468_LABELS:
        // show light exponential grid lines.  Tick labels at only 1,2,4,6,8 gridlines.
        labelsPerDecade = 5
        cullingResult = cullVisiblePlotLabels(tickVisValues, tickUserValues, tickFormatRule, '12468s');
        ({ tickVisValues, tickVisValuesLight, tickUserValues } = cullingResult)
        formattingObj = getFormattingObj(tickFormatRule, { precisionMin: 15, precisionMode: 'min' })
        break

      case logRange > STARTRANGE_FOR_GRIDLINES_WITH_1234568_LABELS:
        // show light exponential grid lines.  Tick labels at only 1,2,3,4,5,6,8 gridlines.
        labelsPerDecade = 7
        cullingResult = cullVisiblePlotLabels(tickVisValues, tickUserValues, tickFormatRule, '1234568s');
        ({ tickVisValues, tickVisValuesLight, tickUserValues } = cullingResult)
        formattingObj = getFormattingObj(tickFormatRule, { precisionMin: 15, precisionMode: 'min' })
        break

      default:
        // LogRange small enough that we treat it as a distorted linear plot.
        labelsPerDecade = -1
        formattingObj = get_linear_scale_formatting_obj()
    }
  }
  // willUseLogarithmic, B10 rules:
  else if (willUseLogarithmicScale && isB60) {
    logRange = Math.abs(Math.log10(max) - Math.log10(min))
    switch (true) {

      case logRange > STARTRANGE_FOR_B60_TENS_LABELS:
        // show light exponential grid lines.  Tick labels at only tens gridlines.
        labelsPerDecade = 3
        cullingResult = cullVisiblePlotLabels(tickVisValues, tickUserValues, tickFormatRule, 'tens');
        ({ tickVisValues, tickVisValuesLight, tickUserValues } = cullingResult)
        break

      case logRange > STARTRANGE_FOR_B60_1246_LABELS:
        // show light exponential grid lines.  Tick labels at only 1,2,5 gridlines.
        labelsPerDecade = 3
        cullingResult = cullVisiblePlotLabels(tickVisValues, tickUserValues, tickFormatRule, '1246s');
        ({ tickVisValues, tickVisValuesLight, tickUserValues } = cullingResult)
        break

      case logRange > STARTRANGE_FOR_B60_12468_LABELS:
        // show light exponential grid lines.  Tick labels at only 1,2,4,6,8 gridlines.
        labelsPerDecade = 5
        cullingResult = cullVisiblePlotLabels(tickVisValues, tickUserValues, tickFormatRule, '12468s');
        ({ tickVisValues, tickVisValuesLight, tickUserValues } = cullingResult)
        break

      default:
        // LogRange small enough that we treat it as a distorted linear plot.
        labelsPerDecade = -1
    }

    // Do we need to show some fixed digits for this set of tickUserValues?
    const smallestTickVal = Math.min(tickUserValues[0], lastVal(tickUserValues))
    var fixedDigits = - Math.floor(Math.log10(smallestTickVal) + 0.00000000001)
    // worse case, we need 0, 1, or 2 fixed digits.  Because at 00:00:00.001 we switch to 1e-3 notation
    fixedDigits = Math.min(fixedDigits, 2)
    if (fixedDigits > 0) {
      // We have exponential labels of 0:00.1, 0:00:.2,  0:00.01, 0:00.02, . . .
      // Hence we need to use fixed precision format.
      formattingObj = getFormattingObj(tickFormatRule, { precisionFixed: fixedDigits, precisionMode: 'fixed' })
    } else {
      // No fractional seconds tickLabels:
      formattingObj = getFormattingObj(tickFormatRule, { precisionMin: 15, precisionMode: 'min' })
    }
  }

  else {
    logRange = NaN
    if (process.env.NODE_ENV === 'development') {
      invariant(false, `missing formattingcase for isLinear, willUseLogarithmicScale, isB60:
        ${String(isLinear)}, ${String(willUseLogarithmicScale)}, ${String(isB60)}`
      )
    }
  }

  // SmartAxisScale has nearly full control over the formattingObj
  // However, the plotXyComputedData creation code knows whether we need to
  // add a '%' sign or not (axisB distribution/stacked plots)
  // This value can be found in the basis.suffixStrg parameter.
  axis.tickUserStringsNoHTML = []
  axis.tickUserStringsMeasureOnly = []
  var suffix = axis.tickFormatSuffixStrg
  suffix = (suffix === '') ? suffix : thinSpaceChar + suffix

  for (const thisLabel of tickUserValues) {
    let thisStringNoHTML = numberFormatNoHTML(String(thisLabel), formattingObj)
    axis.tickUserStringsNoHTML.push(thisStringNoHTML)
    if (willUseLogarithmicScale && logRange > STARTRANGE_FOR_DECADE_WITH_LIGHT_GRIDLINES) {
      // Only the decades are label. We can simplify the labels to 10^Exp.  ( rather than 1*10^Exp )
      // Down stream addHTML function will replace '1e' in every tick label with '10'.
      // At this time, we are only interested in a string of proper measurable length.
      // Since replacing '1e' with '10' does not change the length, the measureOnly
      // string will simple equal the current formatted string value.
      axis.tickUserStringsMeasureOnly.push(String(thisStringNoHTML) + suffix)
    } else {
      // Final format will be exponential (e.g. 3*10^7 where the exponent is raised).
      // Down stream addHTML will replace 'e' with the text with '* 10'
      // So we make this replacement now in our the MeasureOnly string.
      axis.tickUserStringsMeasureOnly.push(String(thisStringNoHTML).replace('e', '* 10') + suffix)
    }
  }
  axis.tickVisValues = tickVisValues
  axis.tickVisValuesLight = tickVisValuesLight
  axis.tickUserValues = tickUserValues
  axis.domainConstrained = domainConstrained
  axis.domainUnconstrained = domainUnconstrained
  axis.domainExtended = domainConstrained  // Assume equality at this pt. in flow
  axis.labelsPerDecade = labelsPerDecade
  axis.tickFormattingObj = formattingObj
}


const cullVisiblePlotLabels = (tickVisValues: number[], tickUserValues: number[],
  formatRule: string, mode: 'tens' | '125s' | '1246s' | '126s' | '12468s' | '1234568s'): {
    tickVisValues: number[], tickVisValuesLight: number[], tickUserValues: number[]
  } => {

  // We label the axis, and use dark tick marks at the values listed 'mode'
  // These label values use a 'dark' tick mark.
  // Any remaining tick values use a 'light' grid mark.
  // Mode '125s'   -- keep ticks labels starting with 1,2,5      B10 formats only
  // Mode '1246s'  -- keep ticks labels starting with 1,2,4,6    B60 formats only
  // Mode '1246s'  -- keep ticks labels starting with 1,2,6      B60 formats only
  // Mode '12468s' -- keep ticks labels starting with 1,2,4,6,8  B10 or B60 formats only
  // Mode '1234568s' -- keep ticks labels starting with 1,2,3,4,5,6,8  B10 or B60 formats only
  // Mode 'tens'   -- keep tick labels starting with 1           B10 formats only (B60 are forced to B10 format logrithm plots of large range)
  var tickVisValuesLight = Array<number>()
  tickVisValues.forEach((x, i) => {
    var userVal = tickUserValues[i]
    if (isB60Format(formatRule) && userVal >= 60) { userVal /= 60 }  // convert userVal to minutes
    if (formatRule === 'B60B60seconds' && userVal >= 60) { userVal /= 60 }  // convert userVal to hours

    var expForm = userVal.toExponential()
    var firstDigit = (expForm[0] === '-') ? expForm[1] : expForm[0]
    var keepFlag = false
    if (firstDigit === '1') { keepFlag = true }
    if (mode === '125s' && (firstDigit === '2' || firstDigit === '5')) { keepFlag = true }
    if (mode === '126s' && (firstDigit === '2' || firstDigit === '6')) { keepFlag = true }
    if (mode === '1246s' && (firstDigit === '2' || firstDigit === '4' || firstDigit === '6')) { keepFlag = true }
    if (mode === '12468s' && Number(firstDigit) % 2 === 0) { keepFlag = true }
    if (mode === '1234568s' && (Number(firstDigit) <= 6 || Number(firstDigit) === 8)) { keepFlag = true }
    if (!keepFlag) {
      delete tickVisValues[i]   // Making sparse arrays
      delete tickUserValues[i]
      // Deleted tick locations 'moved' to tickVisValuesLight
      tickVisValuesLight.push(x)
    }
  })
  // compressing sparse arrays
  tickVisValues = tickVisValues.filter(x => true)
  tickUserValues = tickUserValues.filter(x => true)
  return { tickVisValues, tickUserValues, tickVisValuesLight }
}

type TickValues = {
  tickVisValues: number[]
  tickUserValues: number[]
  domainConstrained: [number, number]
  tickSizeExponent: number
}

const getTickValuesLinear = (minValIn: number, maxValIn: number,
  isForcedEvenNumTicks: boolean, formatRule: FormatRule): TickValues => {

  // What does EPSILON do below?
  // For limits at 'nice' tick locations (range significand 1,2,5) always use the 'fewer' number of ticks (nudge range significant higher)
  // We can choose either direction, as n ticks or 2n ticks are both OK for this transistion case.
  // But by using EPSILON, we eliminate the choice being made by computational roundoff.
  const maxVal = maxValIn * (1 - EPSILON)
  const minVal = minValIn * (1 + EPSILON)
  const { values: binWidthArr, tickSizeExponent } = getBinWidthOptions(minVal, maxVal, formatRule, false)
  const tickSize = binWidthArr[0]
  const startVal = getStartingTickValue(minVal, tickSize)
  const stopVal = getStopingTickValue(maxVal, tickSize)
  let numTicks = Math.round((stopVal - startVal) / tickSize)  // This is expression should be exact, except for computational representation errors.  So just round result.
  if (isForcedEvenNumTicks && numTicks % 2 !== 0) {
    numTicks++
  }
  const tickVisValues = []
  for (let i = 0; i <= numTicks; i++) {
    tickVisValues[i] = Number((startVal + i * tickSize).toPrecision(14))   // Get rid of accumulated binary imprecision
  }
  const domainConstrained: [number, number] = [tickVisValues[0], lastVal(tickVisValues)]

  return { tickVisValues, tickUserValues: tickVisValues.slice(), domainConstrained, tickSizeExponent }
}


// This function needs to work for +/- minVal
const getStartingTickValue = (minVal: number, tickSize: number): number => {
  if (minVal > 0) {
    return Math.floor(minVal / tickSize * (1 + EPSILON)) * tickSize
  } else {
    return Math.floor(minVal / tickSize * (1 - EPSILON)) * tickSize
  }
}

// This function needs to work for +/- minVal
const getStopingTickValue = (maxVal: number, tickSize: number): number => {
  if (maxVal > 0) {
    return Math.ceil(maxVal / tickSize * (1 - EPSILON)) * tickSize
  } else {
    return Math.ceil(maxVal / tickSize * (1 + EPSILON)) * tickSize
  }
}


const logTenthsTable = list(0, 10).map(x => {
  if (x === 0) return Math.log10(9) - 1     // so when I loop and reach x===1, I can look back one tick to equivalent of x=0.9
  if (x === 1) return 0
  return Math.log10(x)
})
//console.log( logTenthsTable, 'logTenthsTable' )


const getTickVisValuesAtNinePerDecade = (minVal: number, maxVal: number): number[] => {
  if (maxVal <= minVal) return []
  const minValLog = Math.log10(minVal)
  const maxValLog = Math.log10(maxVal)
  const minValLogTest = minValLog * (1 + EPSILON)
  const maxValLogTest = maxValLog * (1 - EPSILON)
  const startInteger = Math.floor(minValLog)
  const stopInteger = Math.floor(maxValLog) + 1
  const tickVisValues = []
  for (let i = startInteger; i <= stopInteger; i++) {
    for (let j = 1; j <= 9; j++) {
      // because we want to start saving ticks at the tick 'before' minValLogTest
      // j+1 , j-1 ?? Because we want to include the two ticks immediately 'outside' the minVal/maxVal range.
      //console.log( i,j, i+logTenthsTable[j-1], i+logTenthsTable[j], i+logTenthsTable[j+1])
      if (i + logTenthsTable[j + 1] < minValLogTest) { continue }
      if (i + logTenthsTable[j - 1] > maxValLogTest) { break }
      tickVisValues.push(i + logTenthsTable[j])
    }
  }
  return tickVisValues
}

const getTickVisValuesAtOneOrLessPerDecade = (minVal: number, maxVal: number): number[] => {
  const minValLog = Math.log10(minVal)
  const maxValLog = Math.log10(maxVal)
  //const minValLogTest = minValLog*(1+EPSILON)
  //const maxValLogTest = maxValLog*(1-EPSILON)
  var startInteger = Math.floor(minValLog)
  var stopInteger = Math.ceil(maxValLog)
  var logRange = stopInteger - startInteger  // can be near zero to -309 to +309
  var stepSize

  if (logRange <= STARTRANGE_FOR_SPARSE_DECADES) { stepSize = 1 }
  else if (logRange <= 30) { stepSize = 3 }  // 5-10 ticks
  else if (logRange <= 50) { stepSize = 5 }  // 7-10 ticks
  else if (logRange <= 100) { stepSize = 10 } // 6-10
  else if (logRange <= 200) { stepSize = 20 } // 6-10
  else if (logRange <= 250) { stepSize = 25 } // 5-10
  else if (logRange <= 500) { stepSize = 50 } // 6-10
  else { stepSize = 100 }

  if (startInteger % stepSize !== 0) {
    startInteger = Math.floor(startInteger / stepSize) * stepSize
  }
  if (stopInteger % stepSize !== 0) {
    stopInteger = Math.ceil(stopInteger / stepSize) * stepSize
  }

  const tickVisValues = []
  for (let i = startInteger; i <= stopInteger; i += stepSize) {
    tickVisValues.push(i)
  }
  return tickVisValues
}


//const getTickVisValues_Temporal = ( minVal:number, maxVal:number, formatRule:string,
//    isTicksBetweenDecades:boolean ) : {tickVisValues: Array<number> , tickUserValues: Array<number>} => {

const getTickVisValues_Temporal = (minVal: number, maxVal: number, formatRule: string,
  isTicksBetweenDecades: boolean): { tickVisValues: number[], tickUserValues: number[] } => {

  var tickVisValues = []
  const log3600 = Math.log10(3600)
  const log600 = Math.log10(600)
  const log60 = Math.log10(60)
  const log10 = 1
  const log6 = Math.log10(6)
  const minLogVal = Math.log10(minVal)
  const maxLogVal = Math.log10(maxVal)
  var lastVal = Math.min(1, Math.floor(minLogVal))
  var lastLogIntIndex = 1, nextLogIntIndex = 1, tickLogIncrement = 1

  // Need a pretty crude epsilon here, because addition of incremental log can accumulate error.
  // But this is OK because we are creating discrete values with lots of room between values.
  const epsilonBy10 = 10 * EPSILON

  do {

    // THESE ARE THE COUNTING RULES FOR 9 TICKS PER DECADE
    if (isTicksBetweenDecades) {
      // Next 'index' corresponds to the first digit of the tickLocation.
      // They will vary from 1 to 9, then recycle.
      if (lastLogIntIndex === 9) {
        nextLogIntIndex = 1  // recycle
        tickLogIncrement = Math.log10(10 / 9)
      }
      // Special case at values of 60 and 3600
      else if (Math.abs(lastVal - log60) < epsilonBy10) {
        nextLogIntIndex = 2
        tickLogIncrement = Math.log10(2)
      }
      else if (Math.abs(lastVal - log3600) < epsilonBy10 && formatRule === 'B60B60seconds') {
        nextLogIntIndex = 2
        tickLogIncrement = Math.log10(2)
      }
      else {
        nextLogIntIndex = lastLogIntIndex + 1
        tickLogIncrement = Math.log10(nextLogIntIndex) - Math.log10(lastLogIntIndex)
      }
    }
    // THESE ARE THE COUNTING RULES FOR 1 TICK PER DECADE
    if (!isTicksBetweenDecades) {
      if (lastVal === log10 || (formatRule === 'B60B60seconds' && Math.abs(lastVal - log600) < epsilonBy10)) {
        tickLogIncrement = log6
      } else {
        tickLogIncrement = 1
      }
    }

    var thisTickVal = lastVal + tickLogIncrement
    if (thisTickVal > minLogVal + epsilonBy10) {
      // Next line retroactively adds the tick value immediately prior to thisTickVal
      if (tickVisValues.length === 0) { tickVisValues.push(lastVal) }
      // Next line adds the thisTickValue immediately following prior tickValue
      tickVisValues.push(thisTickVal)
    }
    lastVal = thisTickVal
    lastLogIntIndex = nextLogIntIndex
  }
  // Keep looping until last added value > last required maxLogVal
  while (thisTickVal <= maxLogVal + epsilonBy10)

  var tickUserValues = tickVisValues.map(x => Math.pow(10, x))
  tickUserValues = tickUserValues.map(x => {
    if (x > 1) { return Math.round(x) }   // always integers when > 1
    return Number(Number(x).toPrecision(1)) // significand always a single precision digit from 1 to 9
  })
  return { tickVisValues, tickUserValues }
}



const getTickValuesLogarithmic = (minVal: number, maxVal: number,
  isForcedEvenNumTicks: boolean, formatRule: FormatRule): TickValues => {

  var unSignedMaxLog = Math.log10(maxVal)
  var unSignedMinLog = Math.log10(minVal)

  // logRange is effectively same as maxVal/minVal   -- Measure of how badly a logScale is needed.
  // Tells us the 'magnitude' of the exponential scale needed.
  // Value of 5 means scale spans 5 orders of magnitude
  // Value of 0.5 means Max/min ~ 3.1x
  // Factor of 2 between max/min is ~.30
  // Value of 0.01 is 'nearly linear', for example a scale from 100 to 101.  Still has an exponetial scale, just not practically useful.
  var logRange = unSignedMaxLog - unSignedMinLog

  // The input min/max are right at the edge of the plotted data.
  // Looks nicer for the scales to extend a bit beyond the first/last plotted feature.
  unSignedMinLog -= logRange * 0   // No extension; but may change my mind again!
  unSignedMaxLog += logRange * 0   // No extension
  logRange *= 1.04
  minVal = Math.pow(10, unSignedMinLog)
  maxVal = Math.pow(10, unSignedMaxLog)

  var tickUserValues: number[], tickVisValues: number[]
  var domainConstrained: [number, number], tickSizeExponent: number = 0

  // Labels at only exact powers of ten.  No light gridlines between decades.
  if (logRange > STARTRANGE_FOR_ONLY_DECADES_NO_LIGHT_GRIDLINES) {
    if (isB60Format(formatRule)) {
      let addTicksBetweenDecades = false;
      ({ tickVisValues, tickUserValues } = getTickVisValues_Temporal(minVal, maxVal, formatRule, addTicksBetweenDecades))
    } else { // numbers
      tickVisValues = getTickVisValuesAtOneOrLessPerDecade(minVal, maxVal)
      tickUserValues = tickVisValues.map(x => Number(Math.pow(10, x).toPrecision(1)))
    }
    domainConstrained = [tickVisValues[0], lastVal(tickVisValues)]
  }

  // include tickVisValues for the light gridlines.  May or May not actually use tick labels (decided later.)
  else if (logRange > STARTRANGE_FOR_GRIDLINES_WITH_1234568_LABELS) {
    if (isB60Format(formatRule)) {
      let addTicksBetweenDecades = true;
      ({ tickVisValues, tickUserValues } = getTickVisValues_Temporal(minVal, maxVal, formatRule, addTicksBetweenDecades))
    } else {
      tickVisValues = getTickVisValuesAtNinePerDecade(minVal, maxVal)
      tickUserValues = tickVisValues.map(x => Number(Math.pow(10, x).toPrecision(1)))
    }
    domainConstrained = [tickVisValues[0], lastVal(tickVisValues)]
  }

  // NO light gridlines.  Just create a 'distorted' linear axis.
  else {
    ({ tickVisValues, tickUserValues, tickSizeExponent } = getTickValuesLinear(minVal, maxVal, false, formatRule))
    domainConstrained = [Math.log10(tickUserValues[0]), Math.log10(lastVal(tickUserValues))]
    // We are going to drop the first tick value, and anchor the bottom of the plot to minVal.
    // UNLESS: keeping tickUserValues[0] does not significantly add dead space to the bottom of the plot. (More than 8%)
    if (tickUserValues[0] === 0 || Math.log10(minVal) - Math.log10(tickUserValues[0]) > 0.08 * logRange) {
      // Drop the first tick and adjust the domainConstrained
      tickUserValues = tickUserValues.slice(1)
      // New minDomain is the smaller of the newFirstTick, or the space needed to display the first minVal.
      var newMinDomain = Math.min(Math.log10(tickUserValues[0]), Math.log10(minVal) - .02 * logRange)
      //var newMinDomain = Math.log10(minVal) - .02*logRange
      domainConstrained = [newMinDomain, Math.log10(lastVal(tickUserValues))]
    }
    tickVisValues = tickUserValues.map(x => Math.log10(Math.abs(x)))
    invariant(tickUserValues[0] > 0, 'This code never expects a tick StartVal <= zero.')
  }

  return { tickVisValues, tickUserValues, domainConstrained, tickSizeExponent }
}


// This function modifies the axis object 'in place'
export const smartAxisScale = (axis: PlotXyComputedAxis,
  isForcedEvenNumTicks: boolean = false): void => {

  switch (axis.internalDataType) {
    // jps_todo  need to support TrueFalse columns
    case 'string':
      smartStringAxisScale(axis)
      return
    case 'number':
      smartNumberAxisScale(axis, isForcedEvenNumTicks)
      return
    default:
      invariant(false, `Missing case in switch. internalDataType is ${axis.internalDataType}`)
  }

  //console.log( 'basis returned from SmartAxis ', axis)
}


const smartStringAxisScale = (axis: PlotXyComputedAxis): void => {
  // We set some defaults that allow us to proceed.
  // Is this two step process still needed?
  const { rangeSmartAxisAllSeries } = axis
  axis.rangeUnconstrainedSmartAxis = { ...rangeSmartAxisAllSeries }
  axis.tickVisValues = []
  axis.tickVisValuesLight = null
  axis.tickUserValues = []
  axis.tickUserStringsNoHTML = []
  // Max length in constants file for enumerated string axis is 20 characters.
  // Next estimate of 16 as intial axis sizing value is probably conservative enough
  axis.tickUserStringsMeasureOnly = ['asdfasdfasdfasdf']
  axis.tickFormatRule = 'defaultString'
  axis.tickFormattingObj = getFormattingObj('defaultString')
  axis.domainConstrained = [rangeSmartAxisAllSeries.min, rangeSmartAxisAllSeries.max]
  axis.domainUnconstrained = [...axis.domainConstrained]
  axis.domainExtended = axis.domainConstrained  // Assumption; Domain MAY be further extended later!
}


/*
This next function decides how to label a string axis tickUserLabels.
Three cases:
    1) Few enough ticks that each gets a label.
       No change to tickVisValues, tickUserValues
       tickVisValuesLight is empty (null)
       tickUserStrings is filled in with strings; same length as tickUserValues
    2) Between 1 and 3 times as many requested labels as there is room
       Label every other, or every third tick.
       tickVisValues, tickUserValues compacted to only those tick with string labels Between 1/2 to 1/3 its original length
       tickVisValuesLight is filled with the unlabeled strings.  (no label shows, but a light grid line is drawn.)
       tickUserStrings is filled in with strings; same length as compacted tickUserValues;
    3) More than 3 requested ticks per labeled tick.
       Same as above, but greater compaction.
       And we don't draw the tickVisValuesLight.  This value remains null.

  Function returns a boolean value:
    True - There was label compaction;  Only some tickLabels are shown
    False- There was NO label compaction;  All labels are shown.

    Why do we care about this return value?  Because if there was compaction,
    we can optionally run the plotCalculator again to recover some space.
    Because we've deleted tickLabels, the 'longest string label' may not be shorter.
    And the space recovered is actually the orthogonal direction.  For example,
    compacting the vertical axis string labels may free up 'width'.
*/

export const smartAxisScale_Part2_StringDataTypesOnly =
  (axis: PlotXyComputedAxis, plt: PlotXyComputedData) => {

    const { stringTextToIndex_sortedKeys, axisName, basisName,
      rangeSmartAxisAllSeries, willUseLogarithmicScale } = axis
    const { min: firstStringIndex, max: lastStringIndex } = rangeSmartAxisAllSeries
    var axisLength = (axisName === 'Bottom' || axisName === 'Top')
      ? plt.plotWidthObj.plottedData
      : plt.plotHeightObj.plottedData
    const fontSize = plt.plotStyleObj.fontSizeTickLabel[basisName]
    const maxLabelsThatFitThisAxisLength = Math.floor(axisLength / fontSize * 0.9)
    const numPotentialLabels = lastStringIndex - firstStringIndex + 1
    const numLabelsPerTick = Math.ceil(numPotentialLabels / maxLabelsThatFitThisAxisLength)

    // In the case of firstStringIndex === lastStringIndex, this zero-range
    // case is OK for strings, (NOT fine for numbers).
    // We will just give smart axis a range +/- 1/2 from the known value.
    // This is the same operation as 'fixDegenerateDataRange()', however
    // it's very simple for an axis that we know to be enumerated with integers.
    if (firstStringIndex === lastStringIndex) {
      axis.rangeSmartAxisAllSeries.min -= 0.5
      axis.rangeSmartAxisAllSeries.max += 0.5
    }
    smartNumberAxisScale(axis)

    // For scales with narrow range, the tick locations MAY be fractional values.
    // For example, a scale from one to five is:
    // [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5]
    // We ONLY want tick marks and labels at integer locations.
    // Delete non-integer ticks
    const newValues = Array(0)
    const newMeasure = Array(0)
    const newNoHTML = Array(0)
    const newVisVals = Array(0)
    for (let i = 0; i < axis.tickUserValues.length; i++) {
      var thisTickVal = axis.tickUserValues[i]
      if (thisTickVal % 1 === 0) {     // isInteger
        newMeasure.push(axis.tickUserStringsMeasureOnly[i])
        newNoHTML.push(axis.tickUserStringsNoHTML[i])
        newValues.push(axis.tickUserValues[i])
        newVisVals.push(axis.tickVisValues[i])
      }
    }
    axis.tickUserValues = newValues
    axis.tickUserStringsMeasureOnly = newMeasure
    axis.tickUserStringsNoHTML = newNoHTML
    axis.tickVisValues = newVisVals
    axis.tickVisValuesLight = []  // Assumption: No light grid lines between labels


    // This function creates the string plotCalculator measures (for length) but NOT
    // the 'pretty formatted' string we will render.  Both are ~ the same pixel length.
    // We don't need the 'right' string for plotCalculator -- Just a string of ~ same leng
    const createOrdinalString = (strg: string, thisVal: number) => {
      const thisValString = String(thisVal)
      //   if (thisVal < 10000) { return strg + ' -' + thisValString +  'th' }
      //   const lastThreeDigits = thisValString.slice(-3)
      //  const firstDigits = thisValString.slice(0,thisValString.length-3)
      return strg + ' -\u2009' + thisValString + 'th'
    }

    // Shared function for those cases (most) where we ignore the tick values
    // provided by smartaxis (always integer numbers for a string axis), and
    // replace this tick integers with the corresponding 'enumerated string'.
    const createAllOtherTickArraysFromTickValuesArray = (userTickValues: number[]) => {
      axis.tickVisValues = []
      axis.tickUserStringsMeasureOnly = []
      axis.tickUserStringsNoHTML = []
      for (const thisVal of userTickValues) {
        let strg = (stringTextToIndex_sortedKeys) ? stringTextToIndex_sortedKeys[thisVal - 1] : ''
        // Now append the ordinal number to the string:  ' - 1st', ' - 2nd', ....
        let ordinalStrg = strg + ' - ' + String(thisVal) + 'th' // ordinalSuffix(thisVal)
        axis.tickVisValues.push(thisVal)
        axis.tickUserStringsMeasureOnly.push(ordinalStrg)
        axis.tickUserStringsNoHTML.push(strg)
      }
    }

    // Case 1:  Log Scales
    if (willUseLogarithmicScale) {
      axis.tickUserStringsMeasureOnly = []
      axis.tickUserStringsNoHTML = []
      axis.tickUserValues.forEach((thisVal) => {
        // Merge the 'stringText' and the numerical index (rank order)
        let strg = (stringTextToIndex_sortedKeys) ? stringTextToIndex_sortedKeys[thisVal - 1] : ''
        axis.tickUserStringsMeasureOnly.push(createOrdinalString(strg, thisVal))
        axis.tickUserStringsNoHTML.push(strg)
      })
      return
    }

    // Case 2:  Many strings ( >55 )
    if (numPotentialLabels > 55) {
      // Special case when the first userString requested is '1' -- Most popular case!
      // And the axis is linear.  Smart axis has no label at the the location '1' as
      // This value is usually between evenly spaced tick marks.  Force a tickLabel at
      // coordinate '1'.
      if (firstStringIndex === 1 && axis.tickUserValues[0] === 0) {
        axis.tickUserValues.shift()
      }
      if (firstStringIndex === 1 && axis.tickUserValues[0] !== 1) {
        axis.tickUserValues.unshift(1)
      }
      if (lastVal(axis.tickUserValues) > lastStringIndex) {
        axis.tickUserValues.pop()
      }
      createAllOtherTickArraysFromTickValuesArray(axis.tickUserValues)
      return
    }

    // Case 3:  Sufficient room for one label for each enumerated string.
    if (numLabelsPerTick === 1) {
      axis.tickUserValues = list(firstStringIndex, lastStringIndex)
      createAllOtherTickArraysFromTickValuesArray(axis.tickUserValues)
      return
    }

    // Case 4:  Room for one label for every other string, or 3rd, or 4th string.
    var indexRange = list(firstStringIndex, lastStringIndex)
    axis.tickVisValuesLight = []
    axis.tickUserValues = []
    for (const i of indexRange) {
      if ((i - firstStringIndex) % numLabelsPerTick === 0) {
        axis.tickUserValues.push(i)
      } else {
        axis.tickVisValuesLight.push(i)
      }
    }
    createAllOtherTickArraysFromTickValuesArray(axis.tickUserValues)
    return
  }


export default smartAxisScale
