import { PureComponent } from 'react'
import { SCRY_DARK_BLUE, SCRY_WHITE } from '../sharedComponents/constants'
import PlotSummary from './PlotSummary'

type OwnProps = {
  tableid: string
  currentPlotid: string
  closePopup: () => void
  tableOwnerid: string
  userid: string
  userPlotIds: string[]
  tableOwnerPlotIds: string[]
}

type LocalState = {
  currentScrollTop: number
  lastTableid: string
}

class PlotsList extends PureComponent<OwnProps, LocalState> {

  constructor(props: OwnProps) {
    super(props)
    this.state = {
      currentScrollTop: 0,
      lastTableid: '',
    }
  }

  static getDerivedStateFromProps(nextProps: OwnProps, prevState: LocalState) {
    const { tableid } = nextProps
    if (prevState.lastTableid !== nextProps.tableid) {
      return { currentScrollTop: 0, lastTableid: tableid }
    }
    return {}
  }

  componentDidUpdate() {
    if (this.scrollDiv) {
      this.scrollDiv.scrollTop = this.state.currentScrollTop
    }
  }

  scrollDiv: HTMLDivElement | null = null

  initRef = (element: HTMLDivElement | null): void => {
    this.scrollDiv = element
  }

  onScroll = () => {
    if (this.scrollDiv) {
      this.setState({ currentScrollTop: this.scrollDiv.scrollTop })
    }
  }

  render() {
    const { currentPlotid, tableOwnerid, tableOwnerPlotIds,
      userid, userPlotIds, closePopup } = this.props
    /*
               console.log( 'PlotsList plotIDs:' )
               console.log( '    TableOwnerPlotIds:')
               tableOwnerPlotIds.forEach( thisID => console.log( '    ', thisID ) )
               console.log( '    UserPlotIds:')
               userPlotIds.forEach( thisID => console.log( '    ', thisID ) )
               console.log( '' )
    */
    // If no plots, then don't render the PlotsList's parent div which has a
    // SCRY_WHITE background. By rendering nothing, the parent NavColumn consumes
    // 100% of the height and we see a fully SCRY_DARKBLUE background.
    const tableOwnerPlotIdsTotal = tableOwnerPlotIds ? tableOwnerPlotIds.length : 0
    const userPlotIdsTotal = userPlotIds ? userPlotIds.length : 0
    if (tableOwnerPlotIdsTotal + userPlotIdsTotal === 0) {
      return null
    }

    return (
      <div className={'rc_PlotsList'}
        ref={this.initRef}
        onScroll={this.onScroll}
        style={{
          flexGrow: 1,
          overflow: 'auto',
          position: 'relative',
          width: '100%',
          backgroundColor: SCRY_WHITE
        }}>

        {userid && userPlotIds && userPlotIds.length > 0 &&
          <div>
            <div className={'MyPlotsLabel'}
              style={{
                backgroundColor: SCRY_DARK_BLUE,
                color: 'white',
                //fontSize: '12px',
                fontWeight: 'bold',
                paddingBottom: '10px',
                paddingLeft: '16px',
                paddingTop: '10px',
              }}>
              {'My Plots'}
            </div>
            <div className={'initialVertSpacer'} style={{ height: 4, width: '100%' }} />
            <div className={'PlotSummaryContainer'}>
              {userPlotIds && userPlotIds.map((thisPlotid) => (
                <PlotSummary
                  key={thisPlotid}
                  plotid={thisPlotid}
                  currentPlotid={currentPlotid}
                  closePopup={closePopup}
                />
              ))}
            </div>
          </div>
        }

        {tableOwnerid !== userid && tableOwnerPlotIdsTotal > 0 &&
          <div>
            <div style={{
              backgroundColor: SCRY_DARK_BLUE,
              color: 'white',
              //fontSize: '12px',  //16 nominal
              fontWeight: 'bold',
              paddingBottom: '0px',
              paddingLeft: '16px',
              paddingTop: '10px',
            }}>Table Owner Plots</div>

            <div style={{
              backgroundColor: SCRY_DARK_BLUE,
              color: 'white',
              fontSize: '15px',
              //fontWeight: 'bold',
              paddingBottom: '10px',
              paddingLeft: '56px',
              paddingTop: '0px',
            }}>( copies )</div>

            <div style={{
              borderColor: SCRY_DARK_BLUE,
              borderWidth: '0px 0px 0px 6px',
              borderStyle: 'solid'
            }}>
              <div className={'initialVertSpacer'} style={{ height: 4, width: '100%' }} />
              <div className={'PlotSummaryContainer'}>
                {tableOwnerPlotIds && tableOwnerPlotIds.map((thisPlotid) => (
                  <PlotSummary
                    key={thisPlotid}
                    plotid={thisPlotid}
                    currentPlotid={currentPlotid}
                    closePopup={closePopup}
                  />
                ))}
              </div>
            </div>
          </div>
        }

      </div>
    )
  }
}

export default PlotsList
