import { PureComponent } from 'react'
import { TABLE_CONTROLS_OPACITY } from '../sharedComponents/constants'

type Props = {
  width: number
  height: number
  color: string
  opacity: number
}
export default class SVGdragCol extends PureComponent<Props> {
  render() {
    return (
      <svg
        className={'rc_SVGdragCol'}
        xmlns="http://www.w3.org/2000/svg"
        style={{
          isolation: 'isolate',
          display: 'block',
          opacity: TABLE_CONTROLS_OPACITY
        }}
        viewBox="0 0 40 40"
        preserveAspectRatio='none'
        width={this.props.width}
        height={this.props.height}
      >
        <path d=" M 0 20 L 9 29 L 9 11 L 0 20 Z  M 12 40 L 28 40 L 28 0 L 12 0 L 12 40 M 16 36 L 16 4 24 4 24 36 16 36  Z    M 31 29 L 40 20 L 31 11 L 31 29 Z "
          fill={this.props.color}
        />
      </svg>
    )
  }
}
