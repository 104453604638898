import type { Dispatch, SetStateAction } from 'react'
import type { RootState } from '../redux/store'
import type { DraggableData } from 'react-draggable'
import type { FpName, ActiveFp,  } from '../types'
import type { TabInfoArr, FpChildInfo, FpChildInfoArr, SbChildInfo } from '../appCode/getSbFpConfigMappings'

import { sessionStateChangeDispatch } from '../sharedComponents/reactDispatch'
import { NAV_COLUMN_WIDTH, TOP_MENUBAR_HEIGHT, 
         HIGHLIGHT_COLOR, HIGHLIGHT_WIDTH,
         HIGHLIGHT_OPACITY_TABLE } from '../sharedComponents/constants'
import { useState } from 'react'
import { useSelector} from 'react-redux'
import { DraggableCore } from 'react-draggable'
import { getTabControlHeight } from '../sharedComponents/TabControl3'
import { FP_CONTAINER_ADDITIONAL_HEIGHT, 
         FP_CONTAINER_ADDITIONAL_WIDTH, FpContainer } from './FpContainer'
import { FpSelectedCellGeometry,
         getFpLayout_ByFpName } from '../appCode/getSbFpConfigMappings'
import { logTime, startTimer, stopTimer } from '../sharedFunctions/timer'
import { isEqual } from 'radash'





const isTIMER_ENABLED = false

// Used for Fp responsiveState code.
type FpGeometry = {fpLeft:number, fpTop:number, fpWidth:number, fpHeight:number}

let cyanLineDomNode : SVGPolylineElement | null = null
let fpDomNode : HTMLDivElement | null = null
// These are the initial react state of the cyanLine
// We save react domState values at start of responsive state changes
// And restore them at the end of the responsive state changes.
let fpResponsiveState_initialPoints : string = ''
let fpResponsiveState_initialVisibility : string = ''
let fpResponsiveState_fpGeometry : FpGeometry = {fpLeft:0, fpTop:0, fpWidth:0, fpHeight:0}  // FP position, NOT cyanLine position


export type FpParentState = {
    fpLeft : number
    fpTop: number
    fpName : FpName
    legalDisplayedTabIndex : number
    childWidth : number
    childHeight : number
    isActiveChildDrag : boolean
    fpWidth : number
    fpHeight : number
}
export type SetFpParentState = Dispatch<SetStateAction<FpParentState>>

/* Coord system:
    (0,0) of the floating palette's rendered position is the upperLeft of the viewWindow.

    However, next four constraints are with respect to the upperLeft of the browser window.
    Simply because it makes them easier to emperically set and independent of NavBar width and MenuBar height.
*/

// The FP 'close button' must always be visible to get user's out-of-trouble.
// These 'offsets' guarantee the close button is always visible.
export const FP_MIN_TOP_RIGHT_OFFSET = 15  // Minimum TopRight corner of FP titleBar to Top Right corner of view window.
export const FP_MIN_BOTTOM_LEFT_OFFSET = 120  // Minimum BottomLeft corner of FP titleBar to bottom left corner of view window. 


// Dragging state: Constants over any given dragStart, drag, dragStop
let drag_offsetX = 0
let drag_offsetY = 0
let isActiveDrag = false

const onDragStartHandler = ( position: DraggableData, fpGeometry: FpGeometry) => {
    if (isTIMER_ENABLED) { startTimer('dragFP') }
    // drag_offset is the (x,y) of the mouseDown w/ respect to the topLeft corner of floating palette
    const {fpLeft, fpTop} = fpGeometry
    drag_offsetX = position.x - fpLeft
    drag_offsetY = position.y - fpTop
    isActiveDrag = true
}

const onDragHandler = ( position: DraggableData, fpParentState: FpParentState, setFpParentState: SetFpParentState ) => {
    if (isTIMER_ENABLED) { logTime('dragFP', 'onDragHandler') }
    const fpLeft = position.x - drag_offsetX
    const fpTop = position.y - drag_offsetY
    const tempFpParentState = {...fpParentState, fpLeft, fpTop}
    // ?? We can ignore the 'does not meet contraints' return value here. 
    const { newLeft, newTop}  = checkConstraints( tempFpParentState )
    const newState = {...fpParentState, fpLeft:newLeft, fpTop:newTop}
    if (isEqual( newState, fpParentState) === false ) { 
      setFpParentState(newState) 
    }
}

const onDragStopHandler = (cellGeometry:FpSelectedCellGeometry, fpGeometry:FpGeometry, fpParentState:FpParentState, setFpParentState:SetFpParentState) => {
    if (isTIMER_ENABLED) { stopTimer('dragFP') }
    shiftIfFpBlocksSelectedCell( cellGeometry, fpGeometry, fpParentState, setFpParentState )  
    isActiveDrag = false
}

/*
const constrainPosition= (fpParentState:FpParentState, setFpParentState:SetFpParentState) : void => {
    // Constraints are applied applied to the UPPER LEFT corner of the floating palette such that the close button is always be visible!
    // Otherwise, OK to allow the fP to extend below or left of the browser window (aka user choosing to move it mostly off screen)
    // IF you wish to visualize these expressions, just drag the FP arond it four extents on the browser window.
    // You can see the effect and reason for each term by adding/removing terms below.
    // NOT obvious how to see every term!
    // Remember, (0,0) is the upperLeft of the view, NOT the upperLeft of the browser window.
    const { newLeft, newTop}  = checkConstraints( fpParentState )
    const newState = {...fpParentState, fpLeft:newLeft, fpTop:newTop}
    if (isEqual( newState, fpParentState) === false ) { 
      setFpParentState(newState) 
    }
}
    */

const checkConstraints = ( fpParentState: FpParentState ) : { doesMeetConstraints: boolean, newLeft:number, newTop: number}=> {
    // Constraints are applied applied to the UPPER LEFT corner of the floating palette such that the close button is always be visible!
    // Otherwise, OK to allow the fP to extend below or left of the browser window (aka user choosing to move it mostly off screen)
    // IF you wish to visualize these expressions, just drag the FP arond it four extents on the browser window.
    // You can see the effect and reason for each term by adding/removing terms below.
    // NOT obvious how to see every term!
    // Remember, (0,0) is the upperLeft of the view, NOT the upperLeft of the browser window.
    // Code written for clarity, not efficiency.
    const {fpWidth, fpHeight} = fpParentState
    let {fpLeft, fpTop} = fpParentState
    const leftEdgeMinPosition = (FP_MIN_BOTTOM_LEFT_OFFSET - fpWidth)
    const rightEdgeMaxPosition = window.innerWidth - FP_MIN_TOP_RIGHT_OFFSET
    const leftEdgeMaxPosition = rightEdgeMaxPosition - fpWidth
    const topEdgeMinPosition = FP_MIN_TOP_RIGHT_OFFSET
    const topEdgeMaxPosition = window.innerHeight - Math.min( FP_MIN_BOTTOM_LEFT_OFFSET, fpHeight + 5)
    // Recheck validity of all four constraints (at the same time)
    // Otherwise, setting them sequentially may invalidate an earlier constraint.
    let doesMeetConstraints = true  // Assumption
    fpLeft = Math.min( fpLeft, leftEdgeMaxPosition )
    fpLeft = Math.max( fpLeft, leftEdgeMinPosition )
    if ( fpLeft > leftEdgeMaxPosition || fpLeft < leftEdgeMinPosition ) { doesMeetConstraints = false }
    fpTop = Math.min( fpTop,  topEdgeMaxPosition  )
    fpTop = Math.max( fpTop, topEdgeMinPosition)
    if ( fpTop > topEdgeMaxPosition || fpTop < topEdgeMinPosition ) { doesMeetConstraints = false }

    return { 
      doesMeetConstraints, 
      newLeft:fpLeft, 
      newTop:fpTop 
    }
}




const shiftIfFpBlocksSelectedCell = ( cellGeometry:FpSelectedCellGeometry, fpGeometry:FpGeometry,
                    fpParentState:FpParentState, setFpParentState:SetFpParentState) :void => {
    const {fpWidth, fpHeight} = fpParentState
    const {fpLeft:fpLeftStart, fpTop:fpTopStart} = fpParentState
    //console.log( fpLeftStart, fpTopStart, fpWidth, fpHeight )
    let fpLeft = fpLeftStart, fpTop = fpTopStart
    const {left:cellLeft, top:cellTop, width:cellWidth, height:cellHeight} = cellGeometry
    const cellBottom = cellTop + cellHeight
    const cellRight = cellLeft + cellWidth
    // define 'blocking' the selected cell as an intersecting FP and selected cell. 
    const isNotBlockingCell = ( ) : boolean => {
      const isNotBlocking = ( fpTop + fpHeight < cellTop  || fpTop  > cellBottom || fpLeft+fpWidth  < cellLeft || fpLeft > cellRight )
      return isNotBlocking
    }
    // define 'meeting constraints' as passing our constraints check
    const isMeetingConstraints = () : boolean => {
      const {doesMeetConstraints} = checkConstraints( fpGeometry )
      return doesMeetConstraints
    }
    const shiftFP = ( ) : void => {
      const newState = {...fpParentState, fpLeft, fpTop}
      if ( isEqual( newState, fpParentState) === false ) {  setFpParentState(newState) }
    }
    if ( isNotBlockingCell() ) { return }

    // WE ARE BLOCKING THE SELECTED CELL. SHAME ON US !!!

// Attempt : Top of view, right below Menubar. 
    fpTop = 10   // Ten px below the MenuBar
    if ( isNotBlockingCell() && isMeetingConstraints() ) { shiftFP(); return }
    fpTop = fpTopStart
// Attempt: shift left; up to but not over the NavCol 
    fpLeft = 10
    if ( isNotBlockingCell() && isMeetingConstraints() ) { shiftFP(); return }
    fpLeft = fpLeftStart
// Attempt : Top of view, partially obscuring Menubar. 
    fpTop = 10 - TOP_MENUBAR_HEIGHT  // Ten px below top of window
    if ( isNotBlockingCell() && isMeetingConstraints() ) { shiftFP(); return }
    fpTop = fpTopStart
// Attempt: Just below the selected cell
    fpTop = cellTop + cellHeight + 50
    if ( isNotBlockingCell() && isMeetingConstraints() ) { 
      shiftFP(); 
      return 
    }
    fpTop = fpTopStart
// Attempt: Right side of view (potentially over sideBar)
    fpLeft = window.innerWidth - FP_MIN_TOP_RIGHT_OFFSET - NAV_COLUMN_WIDTH - 50
    if ( isNotBlockingCell() && isMeetingConstraints() ) { shiftFP(); return }
    fpLeft = fpLeftStart
// Attempt: shift all the way left (over the NavCol)
    fpLeft = 10 - NAV_COLUMN_WIDTH
    if ( isNotBlockingCell() && isMeetingConstraints() ) { shiftFP(); return }
    fpLeft = fpLeftStart
// Final choice and final position
// Do NOT shift its position.  Just leave it blocking the selected cell.
// Otherwide, any new state here will trigger an infinite loop between attempts
// to NOT block the cell and also meet constraints. (in bounds of the visible window)
    fpLeft = fpLeftStart
    fpTop  = fpTopStart
    return            
}


/*///////////////////////////////////////////////////////////////////////////////////////
COORDINATE SYSTEM:
      (0,0) of the floating palette's rendered position is the upperLeft of the viewWindow.
      The FP should render above the NavCol and MenuBar

      Current FpParent is rendered by the view, but code is written for 0,0 to be the upperLeft of the view.
      So for now:  shift the FP left by NAVCOL_WIDTH and up by TOP_MENUBAR_HEIGHT
////////////////////////////////////////////////////////////////////////////////////////*/


export const FpParent : React.FC = () => {
    const activeFp = useSelector( (state:RootState) => state.session.activeFp )
    const {fpName, primaryKey, secondaryKey, tertiaryKey} = activeFp
    if ( activeFp.fpName === 'none' ) { return null }
    const remountKey = `${fpName}_${primaryKey}_${secondaryKey}_${tertiaryKey}`
    return <FpParentBody key={remountKey} activeFp={activeFp}/> 
}

const FpParentBody : React.FC<{activeFp:ActiveFp}> = (props) => {
    const {activeFp} = props
    const {left:sessionLeft, top:sessionTop, fpName} = activeFp
    //console.log( 'FpParentBody', activeFp )
    //const tCD = useContext( TableComputedDataContext )
    const fpSessionState = useSelector( (state:RootState) => state.session.fpStates_ByFpName[fpName] )
    //const canEdit = tCD.canEdit   
    const fpLayout = getFpLayout_ByFpName[fpName](activeFp)  // This is a function; Call it to get the layout.
    const { isDraggable, tabInfoArr, childInfoArr, getSelectedCellGeometry } = fpLayout
    const {legalDisplayedTabIndex, childInfo} = getLegalDisplayedTabIndexAndChildInfo( tabInfoArr, childInfoArr, fpSessionState.activeTab)
    const {childWidth: childInitialWidth, childHeight: childInitialHeight} = childInfo as FpChildInfo
    const parentInitialWidth = childInitialWidth + FP_CONTAINER_ADDITIONAL_WIDTH
    const parentInitialHeight = childInitialHeight + FP_CONTAINER_ADDITIONAL_HEIGHT
    // 1st render ONLY:  sessionState is Master, renderedState is Slave !!
    // Initial values of fpParentState are combination of:
    //   -- design time constants.
    //   -- resource values
    //   -- sessionState values
    const [ fpParentState, setFpParentState ] = useState( 
            {fpLeft:sessionLeft, fpTop:sessionTop, fpName, legalDisplayedTabIndex, 
            childWidth: childInitialWidth, childHeight: childInitialHeight, isActiveChildDrag: false, 
            fpWidth: parentInitialWidth, fpHeight:parentInitialHeight} )
    // At this point, fpParentState is the Master.  sessionState/table resource values are slaves
    // Slaves are kept in sync with fpParentState (by dispatching state changes), however all 
    // rendering changes come directly or indirectly from fpParentState. 

    // State changes to FpParent may be triggered by:
    // 1. Dragging the floating palette changes fpLeft, fPTop
    // 2. Rendered child may change its own childWidth/Height (Handled by the childComponent)
    // 3. Constraints on child position as it changes size may change fpLeft, fpTop
    // 3. Tab selection may change the childWidth/Height (Handled by the TabControl3 component)
    const {childWidth, childHeight, isActiveChildDrag, fpWidth, fpHeight, fpLeft, fpTop } = fpParentState
    //console.log( 'FpParentBody', fpLeft, fpTop  )
    const newFpWidth  = childWidth  + FP_CONTAINER_ADDITIONAL_WIDTH
    let   newFpHeight = childHeight + FP_CONTAINER_ADDITIONAL_HEIGHT
    const {totalTabControlHeight} = getTabControlHeight( tabInfoArr )
    newFpHeight += totalTabControlHeight
    if ( fpWidth !== newFpWidth || fpHeight !== newFpHeight ) {
      setFpParentState( {...fpParentState, fpWidth:newFpWidth, fpHeight:newFpHeight} )
    }
  

    const fpGeometry = {fpLeft, fpTop, fpWidth:newFpWidth, fpHeight:newFpHeight} // new position or size ??
    const cellGeometry = getSelectedCellGeometry(activeFp)
    if (isActiveDrag === false && isActiveChildDrag === false && false) {
      console.log( 'WHYA AM I HERE')
      constrainPositionDuringDrag( fpParentState, setFpParentState ) 
      shiftIfFpBlocksSelectedCell(cellGeometry, fpGeometry, fpParentState, setFpParentState) 
    }
    // Calc the cyan highlight line around the selected cell, and the 
    // connecting line to the center of the floating palette.
    const points : string = calcHighlightLine( cellGeometry, fpGeometry )
    // During any view animation, the view animation code will import
    // and call fpResponsiveState_redrawCyanLine() on every frame.
    // Here we need to save the initial state of the cyanLine SVG, 
    // so we can restore it at the end of view animations.
    fpResponsiveState_fpGeometry = fpGeometry
    /*
    style={{position: 'relative',  // Fixed to the browser window
      left:-NAV_COLUMN_WIDTH, 
      top:-TOP_MENUBAR_HEIGHT,
      height:window.innerHeight, 
      width:window.innerWidth }} */
    return (
      <div className={'rc_FpParent'}
        style={{
          position: 'absolute',  // Fixed to the browser window
          left:-NAV_COLUMN_WIDTH, 
          top:-TOP_MENUBAR_HEIGHT,
          // 1,1 Prevents the floating palette from blocking mouse events.
          // full width/height blocks mouse events.
          // 0,0 dimensions do NOT block mouse events, but also do not render FpParent
          height: 1, // window.innerHeight, 
          width: 1,  // window.innerWidth,
        }}
        ref={ (n)=>fpDomNode=n }
      > 
          <FpCyanHighlightLine 
              points={points}
              isCellVisible={cellGeometry.isCellVisible}
              setCyanLineDomNode={ (n:SVGPolylineElement)=>{ cyanLineDomNode = n }}
          />

          <DraggableCore
            enableUserSelectHack={false}
            handle='.floatingPaletteTitleBar'
            onStart={ ( _, data ) => onDragStartHandler(data, fpGeometry )  }
            onDrag ={ ( _, data ) => onDragHandler( data, fpParentState, setFpParentState )  }
            onStop ={ ( ) => onDragStopHandler( cellGeometry, fpGeometry, fpParentState, setFpParentState) }  
            disabled={!isDraggable}>
                <div
                  style={{
                    // Positioned with a transform, w/ respect to upperLeft of view. 
                    position: 'absolute', left:0, top:0,
                    transform: `translate(${fpLeft}px, ${fpTop}px)`,
                    width:fpWidth, height:fpHeight,
                  }}>
                      <FpContainer 
                        legalDisplayedTabIndex={legalDisplayedTabIndex}
                        fpLayout={fpLayout} 
                        fpParentState={fpParentState}
                        setFpParentState={setFpParentState}
                      />
                </div>
          </DraggableCore>

      </div>   
)} 

type CyanLineProps = {
    points:string, 
    isCellVisible:boolean,
    setCyanLineDomNode: (n:SVGPolylineElement)=>void
}

export const FpCyanHighlightLine : React.FC<CyanLineProps> = (props) => {
    const {points, setCyanLineDomNode, isCellVisible} = props
    return (
    <div> 
        <svg className={`rc_FpCyanHighlightLine`}
          style={{ position: 'relative', left:0, top:0,  // relative to parent 
          pointerEvents: 'none',  // So the svg does not block mouse events.
          // This svg needs to be wider than the view window.
          // Because when the sideBar is open, the cyan line MAY extend to the right of the view window.
          // OK to just make a conservative guess here as this div is invisible to clicks and rendering,
          // othe than the visible cyanLine.
          width:window.innerWidth, height:window.innerHeight 
        }}>
            <polyline
              ref={ setCyanLineDomNode }
              points={points}
              style={{
                  stroke:     HIGHLIGHT_COLOR,
                  strokeWidth:HIGHLIGHT_WIDTH,
                  opacity: HIGHLIGHT_OPACITY_TABLE,
                  fill: 'none',
                  strokeLinecap:"round",
                  strokeLinejoin:"bevel",
                  visibility: isCellVisible ? 'unset' : 'hidden',
                  }}
            />
        </svg>
    </div>
)}



// This returns the set of points defining the highlight string
// of the floating palette.
// 1st four args are the selected cell.
// 2nd four args are the floating palette geometry.
// Draw a highlight string around the selected cell,
// and connecting the center of the selected cell (obj1) to the
// center of floating paletter (obj2)
export const calcHighlightLine = (  cellGeometry: FpSelectedCellGeometry, fpPosition: FpGeometry ) : string => {
    const {width:cellWidth, height:cellHeight} = cellGeometry
    let   {left:cellLeft, top:cellTop} = cellGeometry
    // Views define the cell position with respect to the upperLeft of the view.
    // The cyanLine and FpParent are rendered with respect to the upperLeft of the browser window.
    cellLeft += NAV_COLUMN_WIDTH
    cellTop  += TOP_MENUBAR_HEIGHT
    const {fpLeft, fpTop, fpWidth, fpHeight} = fpPosition 
    // top, bottom, left, right are the Cell Highlight coords (center of the hightlight line)
    // We offset (grow) the outline by 1/2 the width of the outline.
    // Hence the inside edge of the outline hugs the selected cell.
    const offset = (HIGHLIGHT_WIDTH+1)/2  // Size the cell border box 'up' by half the lineWidth
    const top    = cellTop - offset
    const bottom = top + cellHeight + 2*offset
    const left   = cellLeft - offset
    const right  = left + cellWidth + 2*offset
    const topString   = ' ' + String(top)
    const bottomString= ' ' + String(bottom)
    const rightString = '   ' + String(right)
    const leftString  = '   ' + String(left)
    // (newX, newY) is the intersection point between the cell outline and
    // the line connecting the center of the palette to the center of the cell.
    // This intersection point could be on located on any one of the four cell outline edges!
    let newX: number, newY: number
    // Next function returns the coordinates of upperLeft, lowerLeft, ...
    // The four corners of the highlight around the cell.
    const ul = ():string => { return leftString+topString }
    const ur = ():string => { return rightString+topString }
    const ll = ():string => { return leftString+bottomString }
    const lr = ():string => { return rightString+bottomString }
    const intersection = ():string => { return `  ${String(newX)} ${String(newY)}  ` }
    // (paletteX, paletteY) is the coordinate in the center of the floating palette.
    const paletteX = fpLeft + fpWidth/2
    const paletteY = fpTop  + fpHeight/2
    const cellEditorEndpt = ` ${String(paletteX)} ${String(paletteY)}`
    // (cellX, cellY) is the coordinate in the center of the selected cell
    const cellX = cellLeft + (cellWidth)/2
    const cellY = cellTop  + (cellHeight)/2
    let proportion: number
    let highlightLine: string
    switch ( true ) {
      case paletteY > bottom :
        // Calculation assuming we intersect the yBottomBox
        proportion = (cellY-bottom)/(paletteY-cellY)
        newY = cellY - proportion*(paletteY-cellY)
        newX = cellX - proportion*(paletteX-cellX)
        highlightLine = cellEditorEndpt+intersection()+ll()+ul()+ur()+lr()+intersection()
        break
      case paletteY < top :
        // Calculation assuming we intersect the yTopBox
        proportion = (cellY-top)/(cellY-paletteY)
        newY = cellY + proportion*(paletteY-cellY)
        newX = cellX + proportion*(paletteX-cellX)
        highlightLine = cellEditorEndpt+intersection()+ur()+lr()+ll()+ul()+intersection()
        break
      default :
        // We know for certain we intersect either xLeftBox or xRightBox
        // We will just use the y2 value, ignoring the small amount that
        // could be justifiably clipped.
        newY = cellY
        if (paletteX<cellX) {
          newX = left
          highlightLine = cellEditorEndpt+intersection()+ul()+ur()+lr()+ll()+intersection()
        } else {
          newX = right
          highlightLine = cellEditorEndpt+intersection()+lr()+ll()+ul()+ur()+intersection()
        }
    }
    if (newX > right) {
      // Means prior calculation does NOT intersect the top or bottom axis.
      // Redo calculation to xRightBox !
      proportion = (right-cellX)/(paletteX-cellX)
      newY = cellY + proportion*(paletteY-cellY)
      newX = cellX + proportion*(paletteX-cellX)
      highlightLine = cellEditorEndpt+intersection()+lr()+ll()+ul()+ur()+intersection()
    }
    if (newX < left) {
      // Means prior calculation does NOT intersect the top or bottom axis.
      // Redo calculation to xLeftBox !
        proportion = (cellX - left)/(cellX-paletteX)
        newY = cellY + proportion*(paletteY-cellY)
        newX = cellX + proportion*(paletteX-cellX)
        highlightLine = cellEditorEndpt+intersection()+ul()+ur()+lr()+ll()+intersection()
    }
    return highlightLine
}


export const fpResponsiveStateStart = () : void => {
    if (cyanLineDomNode) {
      fpResponsiveState_initialPoints = cyanLineDomNode.getAttribute('points') || ''
      fpResponsiveState_initialVisibility = cyanLineDomNode?.style.visibility || ''
    }
}


export const fpResponsiveState_redrawCyanLine = ( cellGeometry: FpSelectedCellGeometry ) : void => {
    // Will redraw the cyanLine around the selected cell, and connecting the center of the selected cell to the center of the floating palette.
    // MAY shift the floating palette to avoid blocking the selected cell.
    // MAY hide/unhide the cyanLine if the selected cell is not visible. 
    // On 'Stop', the next render should EXACTLY match the final responsive state render. (cyanLine, FP position, CyanLine visibility)
    // Also, on 'Stop', if isCellVisible===false, then an open FpParent should be closed. (This is FpParent's responsibility)
    const cyanLinePoints: string = calcHighlightLine( cellGeometry, fpResponsiveState_fpGeometry )
    if (cyanLineDomNode && fpDomNode) {
      cyanLineDomNode.setAttribute( 'points', cyanLinePoints )
      // Two options for visibility: 
      // 1) hide ONLY the cyanLine when cell is out-of-view, 
      // 2) hide both the cyanLine and FP is cell is ouot-of-view
      // Note the cyanLine is ownded by the FpParent, so option two only needs to hide the FP.
      // This isVisible or not can toggle on/off will dragging.
      // If cell is out-of-view at end of scrolling, then 'hidden' becomes permanent by closing the FP.
      //cyanLineDomNode.style.visibility = cellGeometry.isCellVisible ? 'unset' : 'hidden'
      fpDomNode.style.visibility = cellGeometry.isCellVisible ? 'unset' : 'hidden'
    }
    lastRedraw_isCellVisible = cellGeometry.isCellVisible
}

let lastRedraw_isCellVisible = true 

export const fpResponsiveStateStop = ( ) : void => {
    // At the end of some responsive state changes, the selected cell position may have changed.
    // And perhaps is no longer visible.  In this case we will close the floating palette. 
    if (cyanLineDomNode && fpDomNode) {
      cyanLineDomNode.setAttribute( 'points', fpResponsiveState_initialPoints )
      //cyanLineDomNode.style.visibility = fpResponsiveState_initialVisibility
      fpDomNode.style.visibility = fpResponsiveState_initialVisibility
    }
    if (lastRedraw_isCellVisible === false) { closeFP()}
    //console.log( 'call to rpResponsiveStateStop', lastRedraw_isCellVisible )
}


////////////////////////////////////////////////////////
//      Helper Functions
////////////////////////////////////////////////////////

// The current active tab may not be visible in viewOnly mode.  This function 
// returns the first visible tab.  It does not change the activeTab state value.
// Only the user clicking a tab changes the activeTab state value.
export const getLegalDisplayedTabIndexAndChildInfo = (viewOnlyTabs: TabInfoArr, childInfoArr: FpChildInfoArr, activeTab: number) 
                                    : { legalDisplayedTabIndex: number,
                                        childInfo: FpChildInfo | SbChildInfo } => {
    // We assume the activeTab is legal. 
    // This is true IFF we find it amoung the list of viewOnlyTabs.
    // viewOnly tabs are what will be rendered, and it can change
    // depending on user's canEdit status.
    for ( let i=0; i<viewOnlyTabs.length; i++ ) {
      if ( viewOnlyTabs[i].tabIndex === activeTab ) { 
        return { 
            // For this tab at the i'th index.  It is legal to display.
            // And the corresponding childInfo is at the i'th index.
            legalDisplayedTabIndex: i,
            childInfo: childInfoArr[i]
        }
      }
    }
    // The active tab is not visible in viewOnly mode!  Use the first visible tab.
    const legalDisplayedTabIndex = viewOnlyTabs[0].tabIndex
    return {
        legalDisplayedTabIndex,
        childInfo: childInfoArr[legalDisplayedTabIndex]
    }
}

export const closeFP = ( ) : void => {
    const mods = [{ newVal: 'none',  path: 'activeFp.fpName' },
                  { newVal: -1,  path: 'activeFp.primaryKey' },
                  { newVal: -1,  path: 'activeFp.secondaryKey'} ]
    sessionStateChangeDispatch( mods, 'Close FloatingPalette' )
}

