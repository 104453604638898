import type { InternalColDataType } from '../types'
import type { ErrorRows } from './getDefaultTableComputedData'
import type { StatsParams, StatsRefs, StatsOtherArgs } from './updateTableComputedData'

// In addition to unique stats for each internal dataType, we add an
// addional type to represent Number's rendered in boolean True/False format.
// Only purpose is to create custom stats and display boolean True/False data.
// Does not effect or apply to the internal format and/or formula calculations.

export type StatsDataType = 'boolTrueFalse' | InternalColDataType

export type StatsNumbers = {
  validCount: number,
  missingCount: number,
  erroneousCount: number,
  sum: number,
  sumSqrd: number,
  sumCubed: number,
  sumFourth: number,
  moment2: number,
  moment3: number,
  moment4: number,
  min: number,
  max: number,
  mean: number,
  variance: number,
  stdDev: number,
  skewness: number,
  kurtosis: number,
  excessKurtosis: number,
  varianceAdj: number,
  stdDevAdj: number,
  skewnessAdj: number,
  kurtosisAdj: number,
  excessKurtosisAdj: number
}

export type StatsBoolTrueFalse = {
  validCount: number,
  missingCount: number,
  erroneousCount: number,
  trueCount: number,
  falseCount: number,
}

export type StatsHyperlinks = {
  validCount: number,
  missingCount: number,
  erroneousCount: number,
}

export type StatsStrings = {
  validCount:number,
  missingCount:number,
  erroneousCount:number,  // ALWAYS zero for this dataType. Included only so some shared code works.
  nameToFreqObj: { [key:string] : number },
  freqRankToNameArr: string[],
}

export type ColumnStats = StatsStrings | StatsHyperlinks | StatsBoolTrueFalse | StatsNumbers | null

export interface StatsFunctions {
  [key: string]: (_: StatsParams, __: StatsRefs, otherArgsObj: StatsOtherArgs) => { columnStats: ColumnStats; };
}



//This function can be used inline or in a worker thread.

const updateStatsShared = ( _:StatsParams, __:StatsRefs, 
                           otherArgsObj: StatsOtherArgs) : { columnStats: ColumnStats} => {

  // Numerical Moments calculated in two passes.
  // NO loss of required precision when 'mean / stddev' is a large value.
  const genNumberStats = (colValues : string[], erroneousCells: ErrorRows, 
                           filteredRows : number[] ) : StatsNumbers=> {
    let min = Number.POSITIVE_INFINITY
    let max = Number.NEGATIVE_INFINITY
    let validCount=0, missingCount=0, erroneousCount=0
    let sum=0, sumSqrd=0, sumCubed=0, sumFourth=0, mean=0
    let valueNumerical=0
    //let moment2test=0, moment3test=0, moment4test=0

    // First pass to get the mean
    for ( const rowKey in filteredRows ) {
      if ( erroneousCells[rowKey] !== undefined ) { erroneousCount++; continue }
      const value  = colValues[rowKey]
      if ( value === '' ) { missingCount++; continue }
      validCount++
      const valueNumerical = Number( value )
      sum      += valueNumerical
      min = valueNumerical < min ? valueNumerical : min
      max = valueNumerical > max ? valueNumerical : max
    }

    // 2nd pass to get the higher order moments about the mean
    if (validCount > 0) {
      mean = sum / validCount
      for ( const rowKey in filteredRows ) {
        if ( erroneousCells[rowKey] !== undefined ) {continue}
        if ( colValues[rowKey] === '' ) {continue}
        valueNumerical = Number( colValues[rowKey] ) - mean
        sumSqrd   += valueNumerical**2
        sumCubed  += valueNumerical**3
        sumFourth += valueNumerical**4
      }
    }

    if ( validCount === 0 ) {
      return {min:0, max:0, validCount, missingCount, erroneousCount, sum:0, sumSqrd:0,
        sumCubed:0, sumFourth:0, moment2:0, moment3:0, moment4:0, mean:0,
        variance:0,       stdDev:0,    skewness:0,    kurtosis:0,    excessKurtosis:0,
        varianceAdj:0, stdDevAdj:0, skewnessAdj:0, kurtosisAdj:0, excessKurtosisAdj:0}
    }

    const n = validCount
    // These initial values are the values for degenerate case of all values in the column are identical
    let variance=0, stdDev=0, skewness=0, kurtosis=3, excessKurtosis=0
    let varianceAdj=0, stdDevAdj=0, skewnessAdj=0, kurtosisAdj=0, excessKurtosisAdj=0

    if (validCount > 1) {
      variance = sumSqrd/ (validCount)        // biased
      if (variance < 0) {variance = 0}        // Ignore potential computational noise
      stdDev   = Math.sqrt( variance )        // biased
      varianceAdj = variance * n / (n-1)      // unbiased
      stdDevAdj   = Math.sqrt( varianceAdj )  // unbiased
    }
    if (validCount > 2 && stdDev > 0 ) {
      skewness = sumCubed / ( validCount * stdDev**3 )         // biased
      // From Microsoft365 SKEW function
      skewnessAdj = n / ((n-1)*(n-2)) * sumCubed / stdDev**3   // unbiased
    }
    if (validCount > 3 && stdDev > 0 ) {
      kurtosis = sumFourth / ( validCount * variance*variance)  // biased
      excessKurtosis = kurtosis - 3                           // biased
      const term1 = n*(n+1) / ((n-1)*(n-2)*(n-3))
      const term2 = 3*(n-1)**2 / ((n-2)*(n-3))
      kurtosisAdj = term1 * sumFourth / (variance*variance)     // unbiased
      excessKurtosisAdj = kurtosisAdj - term2                 // unbiased
    }
    //console.log( 'validCount, mean, stdDev,    skewness,    kurtosis,    excessKurtosis' )
    //console.log(  validCount, mean, stdDev,    skewness,    kurtosis,    excessKurtosis )
    //console.log( 'validCount, mean, stdDevAdj, skewnessAdj, kurtosisAdj, excessKurtosisAdj' )
    //console.log(  validCount, mean, stdDevAdj, skewnessAdj, kurtosisAdj, excessKurtosisAdj )
    return {min, max, validCount, missingCount, erroneousCount, sum, sumSqrd,
      sumCubed, sumFourth, moment2:0, moment3:0, moment4:0, mean,
      variance,    stdDev,    skewness,    kurtosis,    excessKurtosis,
      varianceAdj, stdDevAdj, skewnessAdj, kurtosisAdj, excessKurtosisAdj,}
  }
  

  const genBooleanStats = (colValues : string[], erroneousCells: ErrorRows, 
                                    filteredRows : number[] ) : StatsBoolTrueFalse => {
      let validCount=0, missingCount=0, erroneousCount=0, falseCount=0, trueCount=0
      for ( const rowKey in filteredRows ) {
        if ( erroneousCells[rowKey] !== undefined ) { erroneousCount++; continue }
        const value  = colValues[rowKey]
        if ( value === '' ) { missingCount++; continue }
        validCount++
        if ( Number(value) === 0 ) { falseCount++ }
        else { trueCount++ }
      }
      return {validCount, missingCount, erroneousCount, trueCount, falseCount }
  }


  const genStringStats = (colValues : string[], filteredRows : number[] ) : StatsStrings => {
      let validCount=0, missingCount=0
      const MAX_CHAR = 20 //no more than 20 characters for stats
      const nameToFreqObj : { [key:string] : number } = { }
      for ( const rowKey in filteredRows ) {
        // For stats, string value is constrained to the
        // first line of text, first 20 characters.
        let value = colValues[rowKey].slice(0, MAX_CHAR)
        value = value.split(/[\r\n]+/)[0]
        if (value === '') { missingCount++; continue }
        else { validCount++ }

        if ( colValues[rowKey].length > MAX_CHAR ) { value += '...' }
        if ( nameToFreqObj[value] ) {  nameToFreqObj[value] += 1 }   // repeat string
        else {  nameToFreqObj[value] = 1 } // new string
      }
      // Sort keys by frequency count; alphabetical as 2nd sort criteria.
      const keys = Array.from( Object.keys(nameToFreqObj) )
      keys.sort( (a,b) => {
        if (nameToFreqObj[a] < nameToFreqObj[b] ) return 1
        if (nameToFreqObj[a] > nameToFreqObj[b] ) return -1
        return a.localeCompare(b)
      })
      return  {validCount, missingCount, erroneousCount:0, nameToFreqObj, freqRankToNameArr:keys}
  }


  const genHyperlinkStats = (colValues : string[], erroneousCells: ErrorRows, 
                                filteredRows : number[] ) : StatsHyperlinks=> {
      let validCount=0, missingCount=0, erroneousCount=0
      for ( const rowKey in filteredRows ) {
        if ( erroneousCells[rowKey] !== undefined ) { erroneousCount++; continue }
        if ( colValues[rowKey] === '' ) { missingCount++; continue }
        validCount++
      }
      return {validCount, missingCount, erroneousCount }
  }


  // EXECUTION START HERE !!!!!!!!
  // EXECUTION START HERE !!!!!!!!
  // EXECUTION START HERE !!!!!!!!
  // EXECUTION START HERE !!!!!!!!

  const {statsDataType, columnRef, rowKeys, erroneousCells } = otherArgsObj
  //const rows : number[] = isUnfilteredStats
  //          ? Array.from({ length: columnRef.length }, (value, index) => index)
  //          : rowKeys
  //var type : string = internalDataType   // assumption
  //if (formatRule === 'boolTrueFalse' ) { type = 'boolTrueFalse' }
  let columnStats : ColumnStats = null
  switch (statsDataType) {
    case 'number'        : columnStats = genNumberStats   (columnRef, erroneousCells, rowKeys); break;
    case 'boolTrueFalse' : columnStats = genBooleanStats  (columnRef, erroneousCells, rowKeys); break;
    case 'string'        : columnStats = genStringStats   (columnRef,                 rowKeys); break;
    case 'hyperlink'     : columnStats = genHyperlinkStats(columnRef, erroneousCells, rowKeys); break;
    //case 'datetime':
    //case 'geopoint':
    default:
  }
  // Return value for memoized data must be in the form of Object. (called the 'result')
  return {columnStats}
}

export default updateStatsShared

