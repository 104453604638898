import type { ChangeEvent, CSSProperties, ReactNode }   from 'react'
import type {FpLayout, FpChildRenderProps }             from '../appCode/getSbFpConfigMappings'
import type { SetFpParentState, FpParentState }         from './FpParent'
import type { ActiveFp }                                from '../types'
import type { RootState }                               from '../redux/store'  // my testPalette given access to sessionState

import {useSelector}                from 'react-redux'
import {useContext, useState}       from 'react'
import {TableComputedDataContext}   from '../viewTableTriple/TtGetComputedData'
import {activeFpToTableViewTile}    from '../viewTable/getTableTile'  
import reactDispatch                from '../sharedComponents/reactDispatch'
import {numberFormatReactNode, 
        numberFormatNoHTML }        from '../sharedFunctions/numberFormat'
import isScryNumber                 from '../sharedFunctions/isTypeNumber'
import constants                    from '../sharedComponents/constants'
import HorzTaperedDivider           from '../sharedComponents/HorzTaperedDivider'
import {cleanScryInputText2}        from '../sharedFunctions/cleanScryInputText'
import { isEqual } from 'lodash'

const LABEL_WIDTH = 90
const INPUT_WIDTH = 244
const INPUT_BORDER_WIDTH = 2   // This is the width of the border around the input box.
const TOTAL_WIDTH = LABEL_WIDTH + INPUT_WIDTH
const ROW_HEIGHT  = 20
const TOTAL_HEIGHT_VIEWMODE = 2.45*ROW_HEIGHT
const TOTAL_HEIGHT_EDITMODE = 4.55*ROW_HEIGHT
const TOTAL_HEIGHT_WITH_EXAMPLES = 9.7*ROW_HEIGHT
const SCRY_NUMBER_INPUT_MAX_CHARACTERS  = constants.SCRY_NUMBER_INPUT_MAX_CHARACTERS
const FLOATING_PALETTE_BACKGROUND       = constants.FLOATING_PALETTE_BACKGROUND
const HELP_BUTTON_BACKGROUND            = constants.HIGHLIGHT_COLOR_HALF
const BORDER_COLOR                      = constants.COLHEADER_INPUT_BORDER_COLOR
// Debug will make the textInput visible (green) and offset the underlying color coded text, so both are visible.
// In normal operation, textInput is transparent and overlays the color coded text.
const DEBUG = false

// Every named FP must provide this function.  It is called by the FpParent component.
// Specifically, the parent needs this set of information BEFORE it creates 
// the FpParent, FpContainer, and internal content. 
export const useGetTableCellNumber : (activeFp: ActiveFp) => FpLayout = (activeFp) => {
    const tCD = useContext( TableComputedDataContext )
    return { 
        activeFp,     
        titleBarStrg: tCD.canEdit? 'Cell Number Editor' : 'Cell Number Viewer', 
        isDraggable: true, 
        isResizableX: false,
        isResizableY: false,
        minResizeX: 300,
        minResizeY: 200,
        maxResizeX: 800,
        maxResizeY: 600,
        tabInfoArr : [{  
            tabIndex: 0,                           
            tabStrg1: 'placeholder', 
            tabStrg2 : 'placeholder', 
            isErroneous: false,
        }],
        childInfoArr : [{  
            childWidth: TOTAL_WIDTH,  
            childHeight: (tCD.canEdit) ? TOTAL_HEIGHT_EDITMODE : TOTAL_HEIGHT_VIEWMODE,  
            RenderedChild: TableFp_CellNumber , // This needs some generic type definition!!!
        }],
        getSelectedCellGeometry: ( ) => { return activeFpToTableViewTile(tCD) }
    }
}


const TIMEOUT_DELAY = 500
let timeoutID: NodeJS.Timeout | string | number | undefined = undefined

const timeoutUpdateResource = ( newInternalValue: string, colKey:number, rowKey:number, tabledataid: string ) => {
    const mods = [{
        newVal: newInternalValue,
        path: `attributes.tableValues[${colKey}][${rowKey}]`
    }]
    reactDispatch(mods, 'cell value change', '', 'tabledatas', tabledataid)
}

type WorkingValues = {
    workingEditValue: string,
    workingTableDataValue: string
}
type SetWorkingValues = ( _:WorkingValues)=>void



const onEditInput = (   e: ChangeEvent<HTMLInputElement>, colKey: number, rowKey: number, tabledataid: string,
                        workingValues: WorkingValues,  setWorkingValues: SetWorkingValues) : void => {
    /* Responsible for:
        1 - scrubbing the input text ( clean; truncate length; clean again)
        2 - Probable state changes for both workingEditValue and workingTableDataValue; May be different values!
        3 - Schedules a reactDispatch to update tableData's resource. */
    const {newValue: newValue0, newSelectionStop: newSelectionStop0} = cleanScryInputText2( e.target.value, e.target.selectionEnd ?? 0 )
    const editorValue0 = newValue0.slice(0,SCRY_NUMBER_INPUT_MAX_CHARACTERS+2 )
    const {newValue: editorValue, newSelectionStop} = cleanScryInputText2( editorValue0, newSelectionStop0  )
    if ( e.target.value.length !== editorValue.length ) {
      e.target.value = editorValue.slice()  // This line also resets selection start to useless value.
      e.target.selectionStart = newSelectionStop
      e.target.selectionEnd   = newSelectionStop
    }
    //console.log('editValue', editorValue)
    const {internalFormat: canonicalFormat, numberType } = isScryNumber( editorValue )
    const isValidNumber = (numberType !== 'DATA_ERR' && numberType !== 'MISSING_VALUE')
    const tableDataValue = (isValidNumber) ? canonicalFormat : editorValue
    const newWorkingValues = { workingEditValue: editorValue, workingTableDataValue: tableDataValue }
    if ( !isEqual(workingValues, newWorkingValues) ) {
        setWorkingValues( newWorkingValues )
    }
    clearTimeout(timeoutID)
    timeoutID = setTimeout( ()=> timeoutUpdateResource(tableDataValue, colKey, rowKey, tabledataid), TIMEOUT_DELAY)
}

const toggleIsHelpDisplayed = ( isHelpDisplayed: boolean, setIsHelpDisplayed: (arg:boolean)=>void, canEdit: boolean,
                                fpParentState: FpParentState, setFpParentState: SetFpParentState) : void => {
    isHelpDisplayed = !isHelpDisplayed
    const childHeight = getChildHeight(canEdit, isHelpDisplayed)
    setChildHeight(childHeight, fpParentState, setFpParentState)
    setIsHelpDisplayed(isHelpDisplayed)
}


const setChildHeight = (childHeight: number, fpParentState: FpParentState, setFpParentState: SetFpParentState) : void => {
    if ( fpParentState.childHeight !== childHeight ) {
        setFpParentState({...fpParentState, childHeight})  // re-renderFpParent.
    }
}

const setChildHeightAysnc = (childHeight: number, fpParentState: FpParentState, setFpParentState: SetFpParentState) : void => {
    if ( fpParentState.childHeight !== childHeight ) {
        setFpParentState((curState: FpParentState) => ({...curState, childHeight}))  // re-renderFpParent.
    }
}   


const getChildHeight = (canEdit:boolean, isHelpDisplayed:boolean) : number => {
    let childHeight = TOTAL_HEIGHT_VIEWMODE
    if ( canEdit ) {childHeight = TOTAL_HEIGHT_EDITMODE}
    if ( canEdit && isHelpDisplayed ) {childHeight = TOTAL_HEIGHT_WITH_EXAMPLES}
    return childHeight
}
    

export const TableFp_CellNumber : React.FC<FpChildRenderProps> = (props)=> {
    const tableComputedData = useContext( TableComputedDataContext )
    const activeFp = useSelector( (state:RootState) => state.session.activeFp )
    const {setFpParentState, fpParentState} = props
    const {primaryKey: colKey, secondaryKey: rowKey } = activeFp
    //console.log('Rendering TableFp_CellNumber', props.remountKey, colKey, rowKey)
    const {canEdit, derivedColAttributesArray, getTableValue, tabledataid} = tableComputedData
    const hideErroneousValues = !canEdit
    const {value:initialInternalValue, isErroneous} = getTableValue(colKey, rowKey, hideErroneousValues)
    // LOCAL STATE: workingTableDataValue -- What is periodically passed to the tableData resource.
    //              workingEditValue:     -- What is displayed in the input box. 
    // IF Not A Number, then workingTableDataValue = workingEditValue = garbage the user typed.
    // IF valid number, then workingTableDataValue = canonical form which may or may not be what user typed
    //                  and  workingEditValue = what user types, which may not be canonical form, nor the displayed formatted form.
    // On initialization, the workingTableDataValue is set to the tableData value.
    //                    the workingEditValue is set to the formatted(tableData value), full precision (or garbarge if NaN)
    const {formattingObj} = derivedColAttributesArray[colKey]
    const fullPrecisionFormattingObj = {...formattingObj, allowsPrefixSuffix:false, useCommas:false, forceFullPrecision:true}
    const initialEditValue = isErroneous ? initialInternalValue : numberFormatNoHTML(initialInternalValue, fullPrecisionFormattingObj )
    const [workingValues, setWorkingValues] = useState( {workingEditValue:initialEditValue, workingTableDataValue:initialInternalValue} )
    const [isHelpDisplayed, setIsHelpDisplayed] = useState( false ) 
    const {workingEditValue, workingTableDataValue} = workingValues
    // Periodically, our editor will update the tableData resource.
    // However, TableData resource is the slave to this local state.
    // TableData resource may be temporarily out of sync with workingTableDataValue.
    // Updates to tableData may be valid numbers, or garbage, depending on user input.
    // There are 4 values displayed in the editor (viewMode);  (One is transparent).
    // Internal Value:  What is saved in tableData resource, unformatted & precision as saved.
    //                  MAY not be a valid number!
    // formattedValue:  The users preferred number format & precision. 
    //                  If value is a valid number it shows in user format.
    //                  If value is '' (Missing), shows as 'MISSING VALUE' 
    //                  If value NaN, shows as 'Not A Number' in editMode;  'MISSING VALUE' in viewMode.
    // highlightedValue:  The color coded (color shows potential errors) verions of what user is inputting.
    //                  The highlightedValue is HTML and NOT what the user is editing.
    // editedValue:     The value owned by the input box.  But text is transparent.  So not visible.
    //                  Hence the user appears to be editing color coded text.
    //                  This is just an illusion.  The input's workingEditValue is edited, but invisible.
    //                  Alignment of the inputCursor to underlying text is an emperical exercise.
    const scryNumberResult = isScryNumber( workingTableDataValue )
    let displayedInternalValue: string
    let displayedFormattedValue: ReactNode
    if (scryNumberResult.numberType === 'MISSING_VALUE' ) {  // Empty string; No cell value; Neither right or wrong.
        displayedInternalValue  = 'Missing Value'
        displayedFormattedValue = 'Missing Value'
    } else if ( scryNumberResult.numberType === 'DATA_ERR' && canEdit ) {
        displayedInternalValue  = workingEditValue   // publishers will see the garbage they typed.
        displayedFormattedValue = 'Not A Number'
    } else if ( scryNumberResult.numberType === 'DATA_ERR' ) {   // viewer mode
        displayedInternalValue  = 'Missing Value'    // viewers will not see garbage publisher typed.
        displayedFormattedValue = 'Missing Value'
    } else {  // string is a valid number.
        displayedInternalValue  = scryNumberResult.internalFormat
        displayedFormattedValue = numberFormatReactNode(displayedInternalValue, formattingObj)
    }
    // Here we build a colorCoded HTML string for the underlying div.
    // We replace all spaces with HTML non-breaking spaces ( &nbsp; )
    // And the erroneous characters with red: <span>erroneous substring</span>
    const highlightArray = scryNumberResult.highlightArray
    const highlightTextFragmentArray = []   // substrings of characters, with either red or black color coding.
                                          // Could be one substring (all black no errors).
                                          // Could be worse case  [black, red, black, red, ... ]
    let lastCharPosition = 0
    for (let i=0; i<highlightArray.length; i++) {
      const thisHighlight = highlightArray[i]
      highlightTextFragmentArray.push( workingEditValue.slice(lastCharPosition, thisHighlight.start) ) // black characters
      highlightTextFragmentArray.push(   // red characters
        <span key={thisHighlight.start} style={{color:'red'}}>
          {workingEditValue.slice(thisHighlight.start, thisHighlight.end)}
        </span>
      )
      lastCharPosition = thisHighlight.end
    }
    highlightTextFragmentArray.push( workingEditValue.slice(lastCharPosition) ) // Final 'black' characters (may not be any).

    // On initial render, the useGetTableCellNumber has initialized the FpParent height to this same value:
    // After initial render, this child determines its own height, and use the setFpParentState to update the parent.
    const childHeight = getChildHeight(canEdit, isHelpDisplayed)
    setChildHeightAysnc(childHeight, fpParentState, setFpParentState)

    // Rendering layout constants and styles:
    const fontSize = 14
    const height = ROW_HEIGHT
    const boxSizing = 'border-box'
    const display = 'inline-block'
    const paddingTop = (ROW_HEIGHT - fontSize)/2 - 2
    const marginTop  = 5
    const marginLeft = 5  // space left of the input box
    const paddingLeft = 5 // space right of the input box'es left border
    const labelStyle: CSSProperties = {
      fontSize, height, boxSizing,display,paddingTop,marginTop,
      textAlign: 'right',
      width: LABEL_WIDTH,
      //background: 'orange',
    }
    const valueStyle: CSSProperties = {
      fontSize, height, boxSizing,display,paddingTop,marginTop,paddingLeft,marginLeft,
      textAlign: 'left',
      width: INPUT_WIDTH - marginLeft - paddingLeft,
    }
    const underlyingControlStyle: CSSProperties = {
      fontSize, height, boxSizing,display,marginTop,marginLeft,paddingTop, paddingLeft,
      background: 'white',
      overflow: 'hidden',
      width: INPUT_WIDTH - marginLeft - paddingLeft,
      position:'absolute', 
      // Includes emperical alignment terms: input cursor to the underlying text.
      top:2*ROW_HEIGHT + 1,
      left: LABEL_WIDTH + (DEBUG ? 80 : 0) + 2,
    }
    const inputControlStyle: CSSProperties = {
      fontSize, height, boxSizing, marginTop, paddingTop,marginLeft, paddingLeft, 
      //paddingLeft:paddingLeft - 2,  // Need to subtract 2 because this box has a left border of width 2
      width: INPUT_WIDTH - marginLeft - paddingLeft,
      background: 'transparent',
      //color: 'transparent',  
      color: DEBUG ? 'green' : 'transparent',
      caretColor:'black',
      borderRadius: 4, borderWidth: INPUT_BORDER_WIDTH, borderStyle: 'inset', borderColor: BORDER_COLOR,
      // Make emperical adjustments to align overlay here:
      position:'absolute', top:2*ROW_HEIGHT, left:LABEL_WIDTH
    }
    let messageText = scryNumberResult.warningID
    let messageColor = 'black'   // assumption
    if ( scryNumberResult.errorID !== '') {
      messageText = scryNumberResult.errorID
      messageColor = 'red'
    }
    const errorMessageStyle: CSSProperties = {
      fontSize, height, boxSizing,display, paddingTop, marginTop,
      width: TOTAL_WIDTH,
      textAlign: 'center',
      color: messageColor,
    }

    return (
      <div className={'rc_EditCellNumber'}
        style={{
          width: TOTAL_WIDTH,    // Need some extra room for the blue floating palette outline and spacing.
          height: childHeight,
          position: 'relative',
        }}>
 
        <div style={{...labelStyle, position:'absolute', top:0, left:0}}>Internal:</div>
        <div style={{...valueStyle, position:'absolute', top:0, left:LABEL_WIDTH}}>
            {displayedInternalValue}
        </div>

        <div style={{...labelStyle, position:'absolute', top:ROW_HEIGHT, left:0}}>Formatted:</div>
        <div style={{...valueStyle, position:'absolute', top:ROW_HEIGHT, left:LABEL_WIDTH}}>
            {/* This is how html text with exponents are aligned vertically using the baseline of the text,
                rather than the top of the text (default).  Top of the text does not work because
                it varies depending on the presence or absence of an exponent.  This trick used for all
                data cells, and anywhere else we may render html with optional exponents. */}
            <div style={{position:'absolute', bottom:0, left:0, paddingBottom:2, paddingLeft}}>
                {displayedFormattedValue}
            </div>
        </div>

        {canEdit && <>
            <div style={{...labelStyle, position:'absolute', top:2*ROW_HEIGHT, left:0}}>Edit Value:</div>
            <span className={'colorCodedInputValue'}
                style={underlyingControlStyle}>
                    {highlightTextFragmentArray}
            </span>
            <input
                onChange={ (e)=> onEditInput(e, colKey, rowKey, tabledataid, 
                                                workingValues, setWorkingValues) }
                style={inputControlStyle}
                type='text'
                value={workingEditValue}
                maxLength={SCRY_NUMBER_INPUT_MAX_CHARACTERS+2}
                autoComplete='off'
                spellCheck='false'
            />
            <div className={'ErrorMessage'}
                style={{...errorMessageStyle, position:'absolute', top:3*ROW_HEIGHT, left:0}}>
                {messageText}
            </div>

            { isHelpDisplayed &&
                <div className={'NumberHelpText'} 
                    onClick={()=> setIsHelpDisplayed(!isHelpDisplayed) }
                >
                    <div style={{position:'absolute', top:4.3*ROW_HEIGHT, left:0 , height: ROW_HEIGHT, width:'100%'}}>
                    <HorzTaperedDivider  background={FLOATING_PALETTE_BACKGROUND}/>
                    </div>
                    <div className={'FormatExamplesTitle'}
                    style={{...errorMessageStyle, color:'black', position:'absolute', top:4.6*ROW_HEIGHT, left:0}}>
                    Example Number Formats
                    </div>
                    <table
                    style={{ position:'absolute', top:5.8*ROW_HEIGHT, left:0, fontSize:14, textAlign:'center', lineHeight:1 }}
                    width={TOTAL_WIDTH}
                    >
                    <tbody>
                        <tr><td> 1.2</td><td> 10 ** 3</td><td>1e3</td><td>1:23</td><td>True</td></tr>
                        <tr><td> 1.23456e4</td><td>-10 **-3</td><td>-1E-3</td><td>1:23:45</td><td>False</td></tr>
                        <tr><td> 12345.6</td><td>1 * 10 ** 3</td><td>5e3</td><td>-1:23:45.67</td><td>1</td></tr>
                        <tr><td> (No commas!)</td><td>3.1*10**3</td><td>3.14E3</td><td>0:12:34</td><td>0</td></tr>
                    </tbody>
                    </table>
                </div>
            }

            <button className={'helpWithFormatsButton'}
                onClick={()=>toggleIsHelpDisplayed( isHelpDisplayed, setIsHelpDisplayed, canEdit, fpParentState, setFpParentState)}
                style={{
                    position:'absolute', right:5, top:3*ROW_HEIGHT+8,
                    height: ROW_HEIGHT, width: ROW_HEIGHT,
                    fontWeight: 'bold', //borderRadius: .3*ROW_HEIGHT,
                    paddingTop:  (ROW_HEIGHT-fontSize)/2 - 5,
                    outline:'none',
                    paddingLeft: (ROW_HEIGHT-fontSize)/2 + 1,
                    background: (isHelpDisplayed) ? HELP_BUTTON_BACKGROUND : 'unset',
                    ...constants.STYLE_EDITOR_RAISED_BUTTON
                }}>{'?'}</button>
        </> }
    </div> 
    )
}
