import { PureComponent } from 'react'
import type { ChangeEvent } from 'react'

type Props = {
  autoComplete: string
  autoFocus?: boolean
  id: string
  name: string
  onChange: (name: string, value: string) => void
  tabIndex?: number
  type?: string
  value: string
}

class FormTextInput extends PureComponent<Props> {

  changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const {onChange} = this.props
    onChange(e.target.name, e.target.value)
  }

  render () {
    const {autoComplete, autoFocus, id, name, tabIndex, type, value} = this.props

    return (
      <input
        className={'rc_FormTextInput'}
        autoComplete={autoComplete}
        autoCapitalize='off'
        autoCorrect='off'
        autoFocus={autoFocus ? true : false}
        id={id}
        name={name}
        onChange={this.changeHandler}
        style={{
          backgroundColor: '#fff',
          backgroundPosition: 'right 8px center',
          backgroundRepeat: 'no-repeat',
          border: '1px solid #d1d5da',
          borderRadius: '3px',
          boxShadow: 'inset 0 1px 2px rgba(27,31,35,0.075)',
          color: '#24292e',
          minHeight: '34px',
          outline: 'none',
          padding: '6px 8px',
          verticalAlign: 'middle',
          width: '100%',
        }}
        tabIndex={tabIndex ? tabIndex : undefined}
        type={type ? type : 'text'}
        value={value}
      />
    )
  }
}

export default FormTextInput
