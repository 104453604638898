import type {ViewName} from '../types'
import { useLocation } from 'react-router-dom';
import { SEARCH_TABLE_ID } from '../sharedComponents/constants';

const useAppView = () => {
  const location = useLocation();
  let viewName : ViewName= 'sitePageView';
  let viewId : string = '';

  if (location.pathname === '/sitePage') {
    viewName = 'sitePageView';
  } else if (location.pathname === '/search') {
    viewName = 'searchView';
    viewId = SEARCH_TABLE_ID;
  } else if (location.pathname.startsWith('/table/')) {
    viewName = 'tableView';
    // Extract the table id from the URL
    const match = location.pathname.match(/^\/table\/([^/]+)$/);
    if (match) {
      viewId = match[1];
    }
  } else if (location.pathname.startsWith('/plot/')) {
    viewName = 'plotXyView';
    // Extract the plot id from the URL
    const match = location.pathname.match(/^\/plot\/([^/]+)$/);
    if (match) {
      viewId = match[1];
    }
  } else if (location.pathname === '/home') {
    viewName = 'homeView';
  }

  return { viewName, viewId };
};

export default useAppView;