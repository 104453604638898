import type { ControlPosition, DraggableData, DraggableEvent } from 'react-draggable'
import type { PlotHeightObj, PlotWidthObj, PlotStyleObj } from '../computedDataPlotXY/xy_plotCalculator'
import type { PlotFocusID } from './xy_plotCalculatorInv'

import { Component } from 'react'
import Draggable from 'react-draggable'
import { px } from '../sharedFunctions/utils'
import { xy_plotCalculatorInv, xy_plotCalculator_nameToCellObj } from './xy_plotCalculatorInv'

const initialX = 20
const initialY = 40
const topY = initialY - 11
const leftX = initialX - 11
var focusCellDiv: HTMLDivElement | null = null
var outlineCellDiv: HTMLDivElement | null = null
var draggableDiv: HTMLDivElement | null = null


const handleDrag = (data: DraggableData, w: PlotWidthObj, h: PlotHeightObj, s: PlotStyleObj, seriesOrder: number[]): void => {
  //console.log( initialX + data.x, initialY + data.y )
  //console.log( `( ${geometry.localX}, ${geometry.localY} )` )

  // Give an (x,y) click or drag location, what is the underlying feature?
  const geometry: PlotFocusID = xy_plotCalculatorInv(initialX + data.x, initialY + data.y, w, h, s /*, seriesOrder*/)
  if (focusCellDiv) {
    focusCellDiv.style.top = px(geometry.cellTop)
    focusCellDiv.style.left = px(geometry.cellLeft)
    focusCellDiv.style.height = px(geometry.cellHeight)
    focusCellDiv.style.width = px(geometry.cellWidth)
  }
  // Now, given the name of the underlying feature, calculate the corresponding position
  // Above background tests the click function.
  // This outline tests the function needed for hightlighting.
  // They should match exactly.
  const highlightBox = xy_plotCalculator_nameToCellObj(geometry.name, geometry.seriesKey, seriesOrder, w, h, s)
  if (outlineCellDiv) {
    outlineCellDiv.style.top = px(highlightBox.top)
    outlineCellDiv.style.left = px(highlightBox.left)
    outlineCellDiv.style.height = px(highlightBox.height)
    outlineCellDiv.style.width = px(highlightBox.width)
  }

}

const handleStop = (): void => {
  if (focusCellDiv) {
    focusCellDiv.style.top = px(0)
    focusCellDiv.style.left = px(0)
    focusCellDiv.style.height = px(0)
    focusCellDiv.style.width = px(0)
  }
  if (outlineCellDiv) {
    outlineCellDiv.style.top = px(0)
    outlineCellDiv.style.left = px(0)
    outlineCellDiv.style.height = px(0)
    outlineCellDiv.style.width = px(0)
  }
  if (draggableDiv) {
    draggableDiv.style.transform = `translate( 0px, 0px )`
    draggableDiv.style.top = px(topY)
    draggableDiv.style.left = px(leftX)
  }
}

type OwnProps = {
  seriesOrder: number[]
  plotWidthObj: PlotWidthObj
  plotHeightObj: PlotHeightObj
  plotStyleObj: PlotStyleObj
}

export default class ActionDebugPlotFocusCalc extends Component<OwnProps> {

  initFocusCellDiv = (element: HTMLDivElement | null): void => {
    focusCellDiv = element
  }
  initOutlineCellDiv = (element: HTMLDivElement | null): void => {
    outlineCellDiv = element
  }
  initDraggableDiv = (element: HTMLDivElement | null): void => {
    draggableDiv = element
  }

  onDrag = (e: DraggableEvent, data: DraggableData) => {
    e.preventDefault( )
    e.stopPropagation( )
    const { plotWidthObj, plotHeightObj, plotStyleObj, seriesOrder } = this.props
    handleDrag(data, plotWidthObj, plotHeightObj, plotStyleObj, seriesOrder)
  }

  onStop = (e: DraggableEvent, _: DraggableData) => {
    e.preventDefault( )
    e.stopPropagation( )
    handleStop()
  }

  constrolPosition: ControlPosition = { x: 0, y: 0 }

  render() {
    return (
      <div className={'ActionDebugPlotFocusCalc'} >

        <div className={'focusCellDebug'}
          ref={this.initFocusCellDiv}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: 0,
            height: 0,
            opacity: 0.1,
            background: 'blue',
          }} />

        <div className={'focusCellDebug'}
          ref={this.initOutlineCellDiv}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: 0,
            height: 0,
            border: '1px solid black',
            boxSizing: 'border-box',
            background: 'transparent',
          }} />

        <Draggable
          onDrag={this.onDrag}
          onStop={this.onStop}
          position={this.constrolPosition}
        >
          <div className={'ActionDebugControl'}
            ref={this.initDraggableDiv}
            style={{
              position: 'absolute',
              top: topY,
              left: leftX,
              width: 21,
              height: 21,
              background: 'green',
              opacity: 0.3,
              border: '2px solid green',
              boxSizing: 'border-box',
            }} >
            <div style={{
              position: 'absolute',
              top: 6,
              left: 6,
              width: 5,
              height: 5,
              background: 'blue'
            }}
            />
          </div>
        </Draggable>
      </div>

    )
  }
}
