import type { OptionalHTMLDivElement } from '../types'
import type { PlotWidthObj, PlotHeightObj, PlotStyleObj } from '../computedDataPlotXY/xy_plotCalculator'
import type { PlotXyComputedData } from '../computedDataPlotXY/xy_plotTypes'

import { getGeometricNameFromSelectionName } from '../computedDataPlotXY/plotUtils'
import {
  defaultPlotHeightObj,
  defaultPlotStyleObj,
  defaultPlotWidthObj
} from '../computedDataPlotXY/xy_plotCalculator'
import rStateFloatingPalette from '../floatingPalette/rStateFloatingPalette'
import constrainPosition from '../floatingPalette/constrainPosition'
import calcHighlightLine from '../floatingPalette/calcHighlightLine'
import { get_floatingPalette_isActiveDrag } from '../floatingPalette/HighlightedFloatingPalette'
//import { px } from '../sharedFunctions/utils'
import { xy_plotCalculator_nameToCellObj } from './xy_plotCalculatorInv'


var rStateXY_Plot = {

  // DOM nodes - Some minimal number of nodes we modify responsively.
  mainTitle: null as OptionalHTMLDivElement,
  publisherTitle: null as OptionalHTMLDivElement,
  focalPlaneContainer: null as OptionalHTMLDivElement,
  plotFocalPlane: null as OptionalHTMLDivElement,
  componentXY: null as OptionalHTMLDivElement,
  componentXY_TransformContainer: null as OptionalHTMLDivElement,




  // Current plot parameters and layout configuration:
  // These are the state values we 'perturb' during dynamic responsive state changes.
  plt: null as null | PlotXyComputedData,
  widthObj: defaultPlotWidthObj,
  heightObj: defaultPlotHeightObj,
  styleObj: defaultPlotStyleObj,


  // Next dom nodes are all on the axisStyle tab layout
  // THEY DO NOT BELONG TO THE PLOT'S DOM TREE !
  // There is only one of each name, minLimit, maxLimit, labelAngle
  // And are used to responsively responsively transpose or mirroring axis
  axisName: { basisA: null as OptionalHTMLDivElement, basisB: null as OptionalHTMLDivElement, basisC: null as OptionalHTMLDivElement },
  minLimit: { basisA: null as OptionalHTMLDivElement, basisB: null as OptionalHTMLDivElement, basisC: null as OptionalHTMLDivElement },
  maxLimit: { basisA: null as OptionalHTMLDivElement, basisB: null as OptionalHTMLDivElement, basisC: null as OptionalHTMLDivElement },
  labelAngle: { basisA: null as OptionalHTMLDivElement, basisB: null as OptionalHTMLDivElement, basisC: null as OptionalHTMLDivElement },
  transposeButtonOutline: null as null | Element,
  // End of domNodes for responsively transpose or mirroring axis


  /* During responsive state changes:
    rStateXY_Plot.widthObj
    rStateXY_Plot.heightObj
    rStateXY_Plot.plt.widthObj
    rStateXY_Plot.plt.heightObj
      Above for state objects do NOT change.  They always represent the last react render

    rStateXY_Plot.w_last
    rStateXY_Plot.h_last
      These two initialized to match the react state at the start of a responsive action.
      They respresent the last 'frame' rendered.  Hence the start with the react state,
      then incrementally modifify positions, once per frame.
      We know exactly which geometric parameters are modified for any given frame
      by comparing the desired width/hieght layout with w_last and h_last.

    At the end of a responsive state change, we need to return the dom tree to its
    initial state!! Else the react dom tree comparison MAY not find all the proper
    changes that need to be re-rendered.  Very difficult to debug!   This almost
    never happens in real state changes, but we MUST protect for this anyway.  Otherwise
    days will be spent doing the debugging.

    Hence, for a dynamic state change:
      1) rStateXY_Plot.widthObj, rStateXY_Plot.heightObj are the intial layout,
         are the initial domTree, are MUST be the final domTree.
      2) w_last, h_last initialized to the initial layout.
      3) Dynamically calculate a new widthObj/heightObj for each frame.
      4) Compare the dynamic widthObj/heightOBj to w_last/h_last.  Modify the
         domTree for any terms that have changed.   After modifying the domTree
         set w_last/h_last to the new domTree values.
      5) At end of dynamic response, w_last/h_last represent last (final) dynamic
         rendered frame.
      6) We need to reset the domTree to its initial values.  We compare w_last/h_last
         to original rStateXY_Plot.widthObj, rStateXY_Plot.heightObj.  Any differences
         we reset the domTree to original rStateXY_Plot.widthObj, rStateXY_Plot.heightObj.
         This does NOT change the visible view!  Because we will now call a react state
         change, which proceeds to change the domTree BACK to the w_last/h_last values!
         Seems like a null total effect.  HOWEVER, it forces the require react re-render.
         And the re-rendered react view will exactly match the last dynamically rendered
         view.  Hence no flickering.

  */

  h_last: defaultPlotHeightObj,
  w_last: defaultPlotWidthObj,
  s_last: defaultPlotStyleObj,

  plotXySideBarAnimationStart: ( plotXyComputedData : PlotXyComputedData): void => {
    //console.log( 'Begin responsive resizePlot')
    //rStateXY_Plot.w_last = plotXyComputedData.plotWidthObj
   // rStateXY_Plot.h_last = plotXyComputedData.plotHeightObj
    //rStateXY_Plot.s_last = plotXyComputedData.plotStyleObj
  },

  plotXySideBarAnimationStop: (): void => {
    // restore state at time of AnimationStart
    //rStateXY_Plot.plotXySideBarAnimation(rStateXY_Plot.widthObj, rStateXY_Plot.heightObj, rStateXY_Plot.styleObj)
  },

  plotXySideBarAnimation: (width:number): void => {
    /*
    const { mainTitle, publisherTitle, focalPlaneContainer, plotFocalPlane,
      w_last, s_last, h_last, componentXY, componentXY_TransformContainer } = rStateXY_Plot

    if (mainTitle && w.mainTitleLeft !== w_last.mainTitleLeft) {
      mainTitle.style.transform = `translate(${w.mainTitleLeft}px, 0px)`
    }
    if (publisherTitle && w.publisherTitleLeft !== w_last.publisherTitleLeft) {
      publisherTitle.style.transform = `translate(${w.publisherTitleLeft}px, 0px)`
    }
    if (w.leftMarginPlusCenteringGap !== w_last.leftMarginPlusCenteringGap && componentXY) {
      componentXY.style.marginLeft = px(w.leftMarginPlusCenteringGap)
    }
    if (h.centeringGap !== h_last.centeringGap && componentXY) {
      componentXY.style.marginTop = px(h.centeringGap)
    }
    if (s.plotTransform !== s_last.plotTransform && componentXY_TransformContainer) {
      componentXY_TransformContainer.style.transform = s.plotTransform
    }
    if (focalPlaneContainer && plotFocalPlane && w.focalPlanePx !== w_last.focalPlanePx) {
      //console.log( 'Set focalPlane width to ', w.focalPlanePx )
      focalPlaneContainer.style.width = px(w.focalPlanePx)
      plotFocalPlane.style.width = px(w.focalPlanePx)
    }
    rStateXY_Plot.h_last = h
    rStateXY_Plot.w_last = w
    rStateXY_Plot.s_last = s
    */
  },

  updateFloatingPaletteHighlight: (w: PlotWidthObj, h: PlotHeightObj, s: PlotStyleObj): void => {
    const { selectionName, minorStateSeriesKey,
      floatingPaletteLeft: fpLeft, floatingPaletteTop: fpTop,
      floatingPaletteWidth: fpWidth, floatingPaletteHeight: fpHeight,
      availableWidthIncludingSideBar, availableHeight } = rStateFloatingPalette.layoutObj
    const isActiveDrag = get_floatingPalette_isActiveDrag()

    if (rStateXY_Plot.plt) {
      let plt = rStateXY_Plot.plt
      let seriesOrder = plt.seriesOrder
      // Map 'basisA', 'basisB', 'basisC' to the geometric axis location:  'leftAxis', 'bottomAxis', ...
      const geometricName = getGeometricNameFromSelectionName(selectionName, plt)
      var result = xy_plotCalculator_nameToCellObj(geometricName, minorStateSeriesKey, seriesOrder, w, h, s)
      var { left, top, width, height } = result
      // plotWidthObj does not include a sideBar width.
      // But we can back into the value by watching the w.focalPlanePx change.
      const sideBarDynamicWidth = availableWidthIncludingSideBar - w.focalPlanePx
      const { constrainedLeft, constrainedTop } = constrainPosition(
        left, top, width, height, fpLeft, fpTop, fpWidth, fpHeight,
        availableWidthIncludingSideBar, availableHeight, sideBarDynamicWidth, isActiveDrag)
      var highlight = calcHighlightLine(left, top, width, height, constrainedLeft, constrainedTop, fpWidth, fpHeight)
      const visibility = 'unset'
      rStateFloatingPalette.redraw(constrainedLeft, constrainedTop, fpWidth, fpHeight, highlight, visibility)
    }
  },

}


export type ResponsiveStatePlot = typeof rStateXY_Plot

const initializeResponsiveStateXY_Plot = (plt: PlotXyComputedData): void => {
  rStateXY_Plot.plt = plt
  rStateXY_Plot.widthObj = (plt) ? plt.plotWidthObj : defaultPlotWidthObj
  rStateXY_Plot.heightObj = (plt) ? plt.plotHeightObj : defaultPlotHeightObj
  rStateXY_Plot.styleObj = (plt) ? plt.plotStyleObj : defaultPlotStyleObj
}

export { rStateXY_Plot, initializeResponsiveStateXY_Plot }
