import type {
  BasicPlotPoint,
  DataName,
  DataNameRoot,
  DensePlotPt,
  PlotXyComputedData,
  SeedHistoPlotPts
} from './xy_plotTypes'

import invariant from 'invariant'
import { isEqual } from 'radash'
import { logTime } from '../sharedFunctions/timer'
import { calcKernelWidthAndSmoothingIndex } from './plotUtils'
import { getSeedHistogram, getAvalue_From_Index } from './seedHistogram'
import { getSmoothedDataBySeries } from './seedSmoothing'
import { getDefaultDensePlotPt, getDefaultPlotPtsInfoObj } from './xy_plotTypes'



/////////////////////////////////////
//
//    For classical Histograms with basisA.dataType === 'number'
//
//    !! Histograms with basisA.dataType === 'string':
//         - DO NOT use this function.
//         - Since axis can be so dense as to be effectively continuous
//           they are treated like 'mean' or 'sum', except the
//           value being plotted is 'freq'.
//         - This case uses 'getPlotPts_binnedSeedHistoStats'
//
//    Although the data comes from the SeedHisto data structure,
//    this plot needs unique treatment because the data is
//    're-binned' into a user defined binWidth.   And the plotPts
//    are placed at the center of these bins.  String axis DO NOT
//    and CANNOT use the equivalent 're-binned', hence no need or
//    desire to use this function.
//
/////////////////////////////////////


export const getPlotPts_classicHistogram =
  (plt: PlotXyComputedData, DEBUG: boolean = false): SeedHistoPlotPts => {

    // Create or retrieve (if it exists) seedHisto.
    const seedHisto = getSeedHistogram(plt, DEBUG)

    const { basisA, seriesOrder } = plt
    const numSeries = seriesOrder.length

    const { data, arrayBinWidth, Aindex_LeftPlotEdge, Aindex_RightPlotEdge, binWidthOptions } = seedHisto
    const arrayBinsPerUserBinWidth = seedHisto.arrayBinsPerUserBinWidth =
      Math.round(binWidthOptions[seedHisto.legalBinWidthIndex] / arrayBinWidth)
    const numFilteredPts = plt.seriesFilteredRowKeys.map(thisSeries => thisSeries.length)

    // Return value initializations:
    const densePlotPts: DensePlotPt[] = []
    const maxB = Array(numSeries).fill(-Infinity)
    const minB = Array(numSeries).fill(+Infinity)
    const maxBstacked = Array(numSeries).fill(-Infinity)
    const minBstacked = Array(numSeries).fill(+Infinity)
    const maxBhistoPercent = Array(numSeries).fill(-Infinity)
    const minBhistoPercent = Array(numSeries).fill(+Infinity)
    const maxBhistoStackedPercent = Array(numSeries).fill(-Infinity)
    const minBhistoStackedPercent = Array(numSeries).fill(+Infinity)

    var startIndex = Infinity, stopIndex = -Infinity
    for (var sKey = 0; sKey < seriesOrder.length; sKey++) {
      if (data[sKey].isEmptySeries) { continue }
      // start and stop points are the Aindex_FirstPDFzero and FinalPDFzero
      // These will always have values of zero.  And always located on left/right edge of first/final nonZero bin.
      var binCenterOfFirstCount = Math.round(data[sKey].Aindex_FirstCount / arrayBinsPerUserBinWidth) * arrayBinsPerUserBinWidth
      var binCenterOfFinalCount = Math.round(data[sKey].Aindex_FinalCount / arrayBinsPerUserBinWidth) * arrayBinsPerUserBinWidth
      data[sKey].Aindex_FirstHistoCenter = binCenterOfFirstCount
      data[sKey].Aindex_FinalHistoCenter = binCenterOfFinalCount
      startIndex = Math.min(startIndex, data[sKey].Aindex_FirstHistoCenter)
      stopIndex = Math.max(stopIndex, data[sKey].Aindex_FinalHistoCenter)
    }
    seedHisto.Avalue_FirstHistoCenter = getAvalue_From_Index(startIndex, seedHisto)
    seedHisto.Avalue_FinalHistoCenter = getAvalue_From_Index(stopIndex, seedHisto)

    // We can further shrink the range by extending a maximun of ONLY one plotted
    // point beyond the left/right plot edges.
    // Snap the plotEdges to the first 'ongrid' index that is just beyond the plot's edges
    const snappedLeftEdge = Math.floor(Aindex_LeftPlotEdge / arrayBinsPerUserBinWidth) * arrayBinsPerUserBinWidth
    const snappedRightEdge = Math.ceil(Aindex_RightPlotEdge / arrayBinsPerUserBinWidth) * arrayBinsPerUserBinWidth
    startIndex = Math.max(startIndex, snappedLeftEdge)
    stopIndex = Math.min(stopIndex, snappedRightEdge)

    // Are there 'zero counts' (no data) over the entire 'culled' range?
    // We must ask this question by series!
    const isEmptyOverRangeCulled = Array<boolean>()
    for (const sKey of seriesOrder) {
      const stats_numSamplesPerBinCumulative = data[sKey].stats_numSamplesPerBinCumulative
      if (stats_numSamplesPerBinCumulative) {
        const lastSampleIndex = stats_numSamplesPerBinCumulative[stopIndex + arrayBinsPerUserBinWidth / 2]
        const firstSampleIndex = stats_numSamplesPerBinCumulative[startIndex - arrayBinsPerUserBinWidth / 2 - 1]
        isEmptyOverRangeCulled[sKey] = (lastSampleIndex - firstSampleIndex === 0)
      } else {
        isEmptyOverRangeCulled[sKey] = true
      }
    }

    var totalCountBySeries = Array(seriesOrder.length).fill(0)
    var totalCountForLargestSeries = 0

    for (let i = startIndex; i <= stopIndex; i += arrayBinsPerUserBinWidth) {
      const thisPt = getDefaultDensePlotPt({ Aindex: i, A: getAvalue_From_Index(i, seedHisto) }, numSeries)
      // It is possible that the first A value (zero freq location prior to
      // first real data) extends beyond the rangeFiltered and may actually
      // be less than zero (Case of fat binWidths and first Freq data slightly > 0).
      // Not a problem, except when the basisA axis is logarithmic and the seedHisto
      // is linear.  In this case, first point can have a negative A value, but plotted
      // on a logarithmic scale.  It will plot, but at wrong position: Log10(abs(Avalue)).
      // The zero bin left of the 1st non-zero bin does is plotted for appearance value.
      // So OK to just skip this corner case (don't plot histo points on logarithm basisA if
      // the A value is negative).
      if (basisA.willUseLogarithmicScale && thisPt.A <= 0) { continue }

      var currentPosStackValue = 0
      var currentPosStackValuePercent = 0
      for (const sKey of seriesOrder) {
        let plottedValueB = 0
        const stats_numSamplesPerBinCumulative = data[sKey].stats_numSamplesPerBinCumulative
        if (stats_numSamplesPerBinCumulative) {
          plottedValueB = stats_numSamplesPerBinCumulative[i + arrayBinsPerUserBinWidth / 2]
            - stats_numSamplesPerBinCumulative[i - arrayBinsPerUserBinWidth / 2]
        }
        totalCountBySeries[sKey] += plottedValueB
        let plottedValueBpercent = plottedValueB / numFilteredPts[sKey] * 100

        // index 'i' will range over the worse case range of ALL series.
        // However, a narrow histogram sitting 'within' the range of a wide histogram
        // does not need all this range.  In fact, we don't want it to plot over the
        // entire range.  We want it's smoothed fit line to only extend as far
        // as that series's dataRange.
        var isOutOfRange = (i < data[sKey].Aindex_FirstHistoCenter || i > data[sKey].Aindex_FinalHistoCenter)
        if (isOutOfRange || plottedValueB === 0) {
          thisPt.isOutOfRange[sKey] = true
          plottedValueB = 0
          plottedValueBpercent = 0
        }

        thisPt.isRealValuedPt[sKey] = true
        thisPt.isInterpolatedPt[sKey] = false
        thisPt.B[sKey] = plottedValueB
        thisPt.freq[sKey] = plottedValueB
        thisPt.BhistoPercent[sKey] = plottedValueBpercent
        thisPt.B0stacked[sKey] = currentPosStackValue
        thisPt.B0histoStackedPercent[sKey] = currentPosStackValuePercent
        thisPt.Bstacked[sKey] = currentPosStackValue + plottedValueB
        thisPt.BhistoStackedPercent[sKey] = currentPosStackValuePercent + plottedValueBpercent
        currentPosStackValue += plottedValueB
        currentPosStackValuePercent += plottedValueBpercent

        if (!isOutOfRange) {
          maxB[sKey] = Math.max(maxB[sKey], plottedValueB)
          minB[sKey] = Math.min(minB[sKey], plottedValueB)
          maxBstacked[sKey] = Math.max(maxBstacked[sKey], thisPt.Bstacked[sKey])
          minBstacked[sKey] = Math.min(minBstacked[sKey], thisPt.Bstacked[sKey])

          maxBhistoPercent[sKey] = Math.max(maxBhistoPercent[sKey], plottedValueBpercent)
          minBhistoPercent[sKey] = Math.min(minBhistoPercent[sKey], plottedValueBpercent)
          minBhistoStackedPercent[sKey] =
            Math.min(minBhistoStackedPercent[sKey], thisPt.BhistoStackedPercent[sKey])
          maxBhistoStackedPercent[sKey] =
            Math.max(maxBhistoStackedPercent[sKey], thisPt.BhistoStackedPercent[sKey])
        }
      }
      densePlotPts.push(thisPt)
    }

    logTime('id_PlotXyComputedData', 'Get histogram plotPts')
    totalCountForLargestSeries = Math.max(...totalCountBySeries)
    const temp = {
      densePlotPts, isEmptyOverRangeCulled,
      minB, maxB,
      minBhistoPercent, maxBhistoPercent,
      minBstacked, maxBstacked,
      minBhistoStackedPercent, maxBhistoStackedPercent,
      totalCountBySeries, totalCountForLargestSeries
    }
    // next line seals and typechecks obj.
    if (DEBUG) { console.log(`  Calc  plotPts of classicalHistogram (currently no memoization)`) }
    return getDefaultPlotPtsInfoObj(temp)
  }



/////////////////////////////////////
//
//    For ALL statisically binned data: mean, sum, sig, min, max
//    For 'freq' histograms with basisA.dataType === 'string'
//    For All smoothed fitted data: smth_freq (PDF), smth_mean, smth_sum, ...
//
//    Data values are located at the center of each SeedHisto binned data.
//    Data values are always interpolated when a SeedHisto bin has no data (freq === 0)
//
/////////////////////////////////////




export const getPlotPts_binnedSeedHistoStats =
  (plt: PlotXyComputedData, dataNameRoot: DataNameRoot, isSmoothed: boolean, DEBUG: boolean = false): SeedHistoPlotPts => {

    // Create or retrieve existing seedHistogram (it will be created if/when needed)
    const seedHisto = getSeedHistogram(plt, DEBUG)
    // Next lines compare the smoothing index of the requested plotPts (userLineSmoothingArr)
    // and the smoothing value of the currently saved plotPts.
    // We can reuse these plotpts if the smoothing index is unchanged.
    // However, if user is playing with the smoothing slider, then we need to
    // regenerate new plotPts (from the seedHistogram) for change to smoothing index.
    // Worse case, the seedHisto has to create a new set of smoothed points at this
    // smoothing index, and we then need to create a new set of plotPts.
    const userLineSmoothingArr = plt.seriesAttributesArray.map(thisSeries => {
      if (!isSmoothed) { return 0 }
      let result = calcKernelWidthAndSmoothingIndex(seedHisto, thisSeries.seriesLineSmoothing)
      return result.smoothingIndex
    })
    // Get the previously saved set of prior plotPts.  They may or may not exist!
    var plotPts = seedHisto.plotPtsByDataName[`${isSmoothed ? 'smth_' : ''}${dataNameRoot}` as DataName]
    const lastLineSmoothingIndex = (plotPts) ? plotPts.smoothingOrBinWidthIndex : null
    const isSmoothingIndexMatch = isEqual(userLineSmoothingArr, lastLineSmoothingIndex)
    // If the plotPts exist, and isSmoothingIndexMatch, then typical case!  Just re-use what exists.
    if (plotPts && isSmoothingIndexMatch) {
      if (DEBUG) { console.log(`  Reuse plotPts for ${isSmoothed ? 'smoothed ' : ' '}${dataNameRoot}`) }
      return plotPts
    }
    // Fall through means plotPts don't exist, OR the smoothing index was changed which
    // invalidates the current set of plotPts.  Create new plotPts.
    plotPts = createPlotPts_binnedSeedHistoStats(plt, dataNameRoot, isSmoothed, DEBUG)
    if (DEBUG) { console.log(`  Calc  plotPts for ${isSmoothed ? 'smoothed ' : ' '}${dataNameRoot}`) }
    return plotPts
  }




const createPlotPts_binnedSeedHistoStats =
  (plt: PlotXyComputedData, dataNameRoot: DataNameRoot, isSmoothed: boolean, DEBUG: boolean = false): SeedHistoPlotPts => {

    const seedHisto = getSeedHistogram(plt, DEBUG)
    const numSeries = plt.seriesOrder.length
    var smoothingOrBinWidthIndex = []
    for (var sKey = 0; sKey < numSeries; sKey++) {
      if (!isSmoothed) { smoothingOrBinWidthIndex[sKey] = 0 }
      else {
        // Use the smoothing index from the user's resource state.
        var seriesLineSmoothing = seedHisto.data[sKey].seriesLineSmoothing
        var { smoothingIndex } = calcKernelWidthAndSmoothingIndex(seedHisto, seriesLineSmoothing)
        smoothingOrBinWidthIndex[sKey] = smoothingIndex
      }
    }

    //  Allways use the smoothed, convolved data because it
    //  contains all the interpolated pts.  Required for stacking.
    const dataBySeries = getSmoothedDataBySeries(plt, dataNameRoot, isSmoothed)
    const freqBySeries = Array<number[] | null>()
    for (sKey = 0; sKey < plt.seriesOrder.length; sKey++) {
      freqBySeries[sKey] = seedHisto.data[sKey].freq.binnedVal
    }

    // Return value initializations:
    // Always return arrays[sKey]
    // For consistency in meaning and coding for the downstream code.
    // Although for stacked plots, this will be an excess of information.
    // But may prove useful to have this min/max by series for future reasons.
    // Hence, the rule to track min/max by series for all renderedLayers.
    const densePlotPts: DensePlotPt[] = []
    var minPositiveB_allSeries = +Infinity
    const maxB = Array(numSeries).fill(-Infinity)
    const minB = Array(numSeries).fill(+Infinity)
    const maxBstacked = Array(numSeries).fill(-Infinity)
    const minBstacked = Array(numSeries).fill(+Infinity)
    const maxBstacked100Percent = Array(numSeries).fill(-Infinity)
    const minBstacked100Percent = Array(numSeries).fill(+Infinity)
    const minBhistoPercent = Array(numSeries).fill(0)
    const minBhistoStackedPercent = Array(numSeries).fill(0)
    const maxBhistoPercent = Array(numSeries).fill(0)
    const maxBhistoStackedPercent = Array(numSeries).fill(0)

    const willUseLogarithmicScaleA = plt.basisA.willUseLogarithmicScale

    // For Histograms ('1Col'), the smoothed valB data is linear freq counts.
    // For XY plots ('2Col' & '3Col'), the valB data MAY be smoothed linear or smoothed LogB values.
    // ALL RENDERED points are in the linear domain of react.  (Specifically log10(valB) in case of LogB scales.
    // Hence, sometimes the smoothed data needs to be converted 'back' to its 'rowValue equivalent' scale.
    // As all potted points (by design) are in units corresponding to the table values.
    // For example, suppose the smoothe B value in the log Domain is '2'.
    // Then the equivalent table/row value is '100' and this is the value we MUST have in the plot pt.
    // The getB getter function will convert back to '2' then plot it on the log scale.
    // Seems like an extra unnecessary conversion, however, ALL plotted data (for example row pts) must
    // use the same scale and getter functions.
    var inverseScaleBxform = (x: number): number => (x)  // assumption
    if (plt.plotColDataType !== '1Col' && plt.basisB.willUseLogarithmicScale) {
      inverseScaleBxform = (x) => Math.pow(10, x)
    }
    const { data } = seedHisto
    const { seriesOrder } = plt
    const isNumericHisto = (dataNameRoot === 'freq' && seedHisto.isBasisAdataTypeNumber)
    const isStringHisto = (dataNameRoot === 'freq' && seedHisto.isBasisAdataTypeString)
    const isStringXYplot = (dataNameRoot !== 'freq' && seedHisto.isBasisAdataTypeString)
    if (!isSmoothed && isNumericHisto && process.env.NODE_ENV === 'development') {
      invariant(false, 'This module never intended for nonSmoothed histograms of numberDataType.')
    }

    if (isStringHisto || isStringXYplot) {
      //const {min, max} = plt.basisA.rangeCulledAllSeries
      //var startLoopIndex = getIndex_From_Avalue( min, seedHisto )
      //var stopLoopIndex  = getIndex_From_Avalue( max, seedHisto )
      var seriesInRangeFirstIndex = data.map(thisSeriesData => thisSeriesData.Aindex_FirstCount)
      var seriesInRangeFinalIndex = data.map(thisSeriesData => thisSeriesData.Aindex_FinalCount)
      var validMinimumFreqPerBin = (isStringHisto) ? 0 : 1
    }
    else if (isNumericHisto && isSmoothed) {
      seriesInRangeFirstIndex = data.map(thisSeriesData => thisSeriesData.Aindex_FirstPDFzero)
      seriesInRangeFinalIndex = data.map(thisSeriesData => thisSeriesData.Aindex_FinalPDFzero)
      validMinimumFreqPerBin = 0
    }
    else if (dataNameRoot === 'variance' || dataNameRoot === 'varianceLogB') {  // Exception !
      seriesInRangeFirstIndex = Array<number>(data.length).fill(
        Math.min(...data.map(thisSeriesData => thisSeriesData.Aindex_First2Count))
      )
      seriesInRangeFinalIndex = Array<number>(data.length).fill(
        Math.max(...data.map(thisSeriesData => thisSeriesData.Aindex_Final2Count))
      )
      validMinimumFreqPerBin = 2
    }
    else {  // common case of scatter plot: mean, sum, min, max (not freq or variance)
      seriesInRangeFirstIndex = data.map(thisSeriesData => thisSeriesData.Aindex_FirstCount)
      seriesInRangeFinalIndex = data.map(thisSeriesData => thisSeriesData.Aindex_FinalCount)
      validMinimumFreqPerBin = 1
    }
    var startLoopIndex = Math.min(...seriesInRangeFirstIndex)
    var stopLoopIndex = Math.max(...seriesInRangeFinalIndex)



    // This next value is the number of plotPts within the constrained A-axis view.
    // Used in order to set our 'empty plotPts' flags.
    // NOT the same as the total freq for any given series!
    var totalPlotPtsBySeries = Array(numSeries).fill(0)
    // Next value is total freq by series over the entire filtered dataSet
    // (Includes data that is out-of-view on constrained axis plots)
    // Used to normalized PDF plots by the total freq of each series.
    var totalFreqBySeries = plt.seriesFilteredRowKeys.map(series => series.length)

    for (let i = startLoopIndex; i <= stopLoopIndex; i++) {
      const thisPtOverride = {
        Aindex: i,
        A: getAvalue_From_Index(i, seedHisto) // - seedHisto.arrayBinWidth/2
        // Note about above half bin correction index.
        // Not sure whether to plot point at center bin or left bin edge.
        // I know we CANNOT use above correction for string axis, else the
        // first bin A value can be less than zero, negating the ability
        // to use log basisA scales.
      }
      const thisPt = getDefaultDensePlotPt(thisPtOverride, numSeries)
      // It is possible that the first A value (zero freq location prior to
      // first real data) extends beyond the rangeFiltered and may actually
      // be less than zero (Case of fat binWidths and first Freq data slightly > 0).
      // Not a problem, except when the basisA axis is logarithmic and the seedHisto
      // is linear.  In this case, first point can have a negative A value, but plotted
      // on a logarithmic scale.  It will plot, but at wrong position: Log10(abs(Avalue)).
      // The zero bin left of the 1st non-zero bin is only plotted for appearance value.
      // So OK to just skip this corner case (don't plot histo points on logarithm basisA if
      // the A value is negative).
      if (willUseLogarithmicScaleA && thisPt.A <= 0) { continue }
      // Initializations for tracking current stack heights
      var currentPosStackValue = 0
      var currentNegStackValue = 0
      var currentPosHistoStackedPercentValue = 0

      for (let dummyIndex = 0; dummyIndex < numSeries; dummyIndex++) {
        // Loop in seriesOrder to stack properly!
        sKey = seriesOrder[dummyIndex]
        if (seedHisto.data[sKey].isEmptySeries) {
          thisPt.isOutOfRange[sKey] = true
          thisPt.freq[sKey] = 0
          var plottedValueB = 0
        } else if (i < seriesInRangeFirstIndex[sKey] || i > seriesInRangeFinalIndex[sKey]) {
          thisPt.isOutOfRange[sKey] = true
          thisPt.freq[sKey] = 0
          plottedValueB = inverseScaleBxform(0)
        } else {
          thisPt.isOutOfRange[sKey] = false
          const seriesFreq = freqBySeries[sKey]
          if (seriesFreq !== null) {
            thisPt.freq[sKey] = seriesFreq[i]
          }
          // The seed Histo may contain a 'smth Log fit' of the data.
          // Because we do smoothing for log scales use the log(plotValue)
          // But ALL plotted points contain the linear scale values
          // Hence, here is where smoothing of log data
          // is converted to plottable linear points:
          plottedValueB = inverseScaleBxform(dataBySeries[sKey][i])
        }
        // Freq must be >= zero for Freq; 1 for defaults; 2 for variance.
        // Wierd construction because null and 0 both equal boolean (false).
        // Hence we need to be more explicite to slip 'null' (missing) values:
        thisPt.isRealValuedPt[sKey] = (thisPt.freq[sKey] !== null &&
          thisPt.freq[sKey] >= validMinimumFreqPerBin)
        thisPt.isInterpolatedPt[sKey] = false  // Assumption
        // An interpolated point must be 'inRange' and not realValued (interpolated between two real value.
        if (thisPt.isOutOfRange[sKey] === false && !thisPt.isRealValuedPt[sKey]) {
          thisPt.isInterpolatedPt[sKey] = true
        }
        thisPt.B[sKey] = plottedValueB

        // plotted PercentB is a percentage of the totalFreq!!  Used for histograms
        if (totalFreqBySeries[sKey] === 0) {
          var plottedPercentB = 0
        } else {
          plottedPercentB = thisPt.BhistoPercent[sKey] = plottedValueB / totalFreqBySeries[sKey] * 100
        }


        if (plottedValueB >= 0) { // This code stacks the positive B values.
          thisPt.B0stacked[sKey] = currentPosStackValue
          thisPt.Bstacked[sKey] = currentPosStackValue + plottedValueB
          currentPosStackValue += plottedValueB
          // Also stacks the BhistoPercent Values (they are always positive)
          thisPt.B0histoStackedPercent[sKey] = currentPosHistoStackedPercentValue
          thisPt.BhistoStackedPercent[sKey] = currentPosHistoStackedPercentValue + plottedPercentB
          currentPosHistoStackedPercentValue += plottedPercentB
        }
        if (plottedValueB < 0) { // This code stacks the negative B values.
          thisPt.B0stacked[sKey] = currentNegStackValue
          thisPt.Bstacked[sKey] = currentNegStackValue + plottedValueB
          currentNegStackValue += plottedValueB
        }
        // We considered ALL points when finding max/min ranges - Specifically those
        // at real value points, AND those at interpolated points.
        // TRUE - Interpolated point range can never exceed the realPts range for most plots.
        // HOWEVER - For stacked plots, an interpolated/stacked value can sometimes be the min/max value.
        //     Consider two stacked series:
        //     First series is a positive square wave located between 2 & 3.
        //     Second series is a postive square wave located between 0 & 5.
        //     If stacked, the max value will be an interpolated pt belonging to the second series, between 2 & 3.
        // Hence, we loop over ALL points; Both real plotted points AND interpolated points.
        if (!thisPt.isOutOfRange[sKey]) {
          totalPlotPtsBySeries[sKey]++
          maxB[sKey] = Math.max(maxB[sKey], plottedValueB)
          minB[sKey] = Math.min(minB[sKey], plottedValueB)
          maxBstacked[sKey] = Math.max(maxBstacked[sKey], thisPt.Bstacked[sKey])
          minBstacked[sKey] = Math.min(minBstacked[sKey], thisPt.Bstacked[sKey])
          minBhistoPercent[sKey] = Math.min(minBhistoPercent[sKey], thisPt.BhistoPercent[sKey])
          maxBhistoPercent[sKey] = Math.max(maxBhistoPercent[sKey], thisPt.BhistoPercent[sKey])
          minBhistoStackedPercent[sKey] = Math.min(minBhistoStackedPercent[sKey], thisPt.BhistoStackedPercent[sKey])
          maxBhistoStackedPercent[sKey] = Math.max(maxBhistoStackedPercent[sKey], thisPt.BhistoStackedPercent[sKey])
        }
        if (plottedValueB > 0)
          minPositiveB_allSeries = Math.min(plottedValueB, minPositiveB_allSeries)
      }
      var stackMag = currentPosStackValue - currentNegStackValue
      thisPt.stackMag = stackMag
      densePlotPts.push(thisPt)
      if (stackMag !== 0) {   // Skip stacks that have zero magnitudes.
        for (sKey = 0; sKey < maxBstacked.length; sKey++) {
          maxBstacked100Percent[sKey] =
            Math.max(currentPosStackValue / stackMag, maxBstacked100Percent[sKey])
          minBstacked100Percent[sKey] =
            Math.min(currentNegStackValue / stackMag, minBstacked100Percent[sKey])
        }
      }
      // For this stack, what is smallest ratio of valueB[0] / stackMag ?
      // Used much later to help properly scale the minB value for stacked/Normalized
      // plot with a basisB logarithmic axis.  We need some idea what to choose
      // for the minB plot domain.
      var minB_sKey0_Normed = 1    // default
      if (thisPt.B[0] && thisPt.isRealValuedPt) {
        minB_sKey0_Normed = Math.min(minB_sKey0_Normed, thisPt.B[0] / stackMag)
      }
    }
    // Are there 'zero counts' (no data) over the entire 'culled' range?
    // We must ask this question by series!
    // Our plot of this series (legend) should show 'Data Out Of View'
    const isEmptyOverRangeCulled = Array<boolean>()
    const totalCountBySeries = Array<number>()
    for (const sKey of seriesOrder) {
      isEmptyOverRangeCulled[sKey] = (totalPlotPtsBySeries[sKey] === 0)
      totalCountBySeries[sKey] = plt.seriesFilteredRowKeys[sKey].length
    }
    const returnData = {
      densePlotPts, isEmptyOverRangeCulled, smoothingOrBinWidthIndex,
      minB, maxB,
      minPositiveB_allSeries, // The smallest Positive B value
      minBstacked, maxBstacked,
      minBstacked100Percent, maxBstacked100Percent,
      minBhistoPercent, maxBhistoPercent,
      minBhistoStackedPercent, maxBhistoStackedPercent,
      totalCountBySeries,
      totalCountForLargestSeries: Math.max(...totalCountBySeries),
    }
    // Next line typeChecks and seals object
    const seedHistoPlotPts = getDefaultPlotPtsInfoObj(returnData)
    seedHisto.plotPtsByDataName[`${isSmoothed ? 'smth_' : ''}${dataNameRoot}` as DataName] = seedHistoPlotPts
    return seedHistoPlotPts
  }


// export const createSingleAreaPolygon  = ( plotPts, sKey, getBottom, getLeft, getBottom0, getLeft0 ) => {
//   // We will create are clockwise polygon.
//   // Loop thought data '0 to n-1' across the top edge of polygon
//   // Followed by loop from 'n-1 to 0' across the bottom edge of polygon
//   // No need to close polygon for reactVis, but this may differ for another library.
//   // Points use A/B coords (as opposed to x/y) because we use the getter functions
//   // to map them to x/y depending on isTransposed.
//   const areaPolygon = []
//   const numPoints = plotPts.length
//   plotPts.forEach( thisPt => {
//     areaPolygon.push({
//       x: getBottom(thisPt, sKey),
//       y: getLeft(  thisPt, sKey)
//     })
//   })
//   for (let i = numPoints-1; i>=0; i--) {
//     var thisPt = plotPts[i]
//     areaPolygon.push({
//       x: getBottom0(thisPt, sKey),
//       y: getLeft0(  thisPt, sKey)
//     })
//   }
//   return areaPolygon
// }



export const getPlotPts_linFit = (plt: PlotXyComputedData, sKey: number): {
  interpolatedPlotPts: BasicPlotPoint[]
  exterpolatedPlotPts: BasicPlotPoint[]
  minB: number
  maxB: number
} => {

  const { basisA, basisB, seriesAttributesArray } = plt
  var { filteredPtsStats } = seriesAttributesArray[sKey]
  if (filteredPtsStats.numFilteredPts <= 1) {
    // Don't render a linFit line
    return { interpolatedPlotPts: [], exterpolatedPlotPts: [], minB: +Infinity, maxB: -Infinity }
  }
  const isLogA = basisA.willUseLogarithmicScale
  const isLogB = basisB.willUseLogarithmicScale
  let minA = filteredPtsStats.minA
  let maxA = filteredPtsStats.maxA
  var A_Left, A_Right, fitCoef
  switch (true) {
    case (!isLogA && !isLogB):
      fitCoef = filteredPtsStats.linfit_AB
      break
    case (isLogA && !isLogB):
      fitCoef = filteredPtsStats.linfit_LogAB
      break
    case (!isLogA && isLogB):
      fitCoef = filteredPtsStats.linfit_ALogB
      break
    case (isLogA && isLogB):
      fitCoef = filteredPtsStats.linfit_LogALogB
      break
    default:
      invariant(false, `reached impossible case in getPlotPts_linFit when isLogA: ${isLogA} and isLogB: ${isLogB}`)
  }
  // Case of vertically stacked filtered pts:
  // We won't render a linFit line.
  if (minA === maxA) {
    return { interpolatedPlotPts: [], exterpolatedPlotPts: [], minB: +Infinity, maxB: -Infinity }
  }
  const { intercept_BfA, slope_BfA } = fitCoef
  if (isLogA) { minA = Math.log10(minA); maxA = Math.log10(maxA) }
  // We don't need equivalent expression for minB/maxB.
  // They are calculated from the linear fit expression, which
  // has coefficients that already take into account lin/log basisB.

  // Left and right extents of visible plot
  // Domain MAY be flipped, but we are working with min on left, max on right.
  // If domain is flipped, doesn't matter to us here as the line we render
  // will also be flipped.
  A_Left = basisA.domainExtended[0]
  A_Right = basisA.domainExtended[1]
  if (A_Left > A_Right) { var temp = A_Left; A_Right = A_Left; A_Left = temp };

  minA = Math.max(minA, A_Left)
  maxA = Math.min(maxA, A_Right)
  const B_Left = slope_BfA * A_Left + intercept_BfA
  const B_Right = slope_BfA * A_Right + intercept_BfA
  var B_atMinA = slope_BfA * minA + intercept_BfA
  var B_atMaxA = slope_BfA * maxA + intercept_BfA
  var exterpolatedPlotPts: BasicPlotPoint[] = [
    { type: 'basic', A: A_Left, B: B_Left },
    { type: 'basic', A: A_Right, B: B_Right }
  ]
  const interpolatedPlotPts: BasicPlotPoint[] = [
    { type: 'basic', A: minA, B: B_atMinA },
    { type: 'basic', A: maxA, B: B_atMaxA }
  ]

  // We will place extra delaunay pts on the exterpolated line.
  // Spacing ~100 across the plot, but try to find a 'good' spacing,
  // such that the A coords fall on nice values.  No perfect answer,
  // but if we have some clues for 'nice locations', use them.
  if (basisA.isLogarithmic) {
    // No 'nice' solution.  Just use 101 points across the A domain
    var numDelaunayPts = 101
    exterpolatedPlotPts = linFit_fillInDelaunayPts(exterpolatedPlotPts, 101)
  }

  else if (basisA.internalDataType === 'string') {
    // Use the locations of the reactVis 'text' labels??
    // Todo: JPS
  }

  // IF the seed histo is present (and linear basisA), then best choice is already decided;
  else if (basisA.seedHisto) {
    const { arrayBinsPerAxisTick, arrayBinsPerSmallestBinWidthOption,
      axisFullWidth, axisTickWidth } = basisA.seedHisto
    var ptsPerMajorTick = arrayBinsPerAxisTick / arrayBinsPerSmallestBinWidthOption
    var numTicks = Math.round(axisFullWidth / axisTickWidth)
    numDelaunayPts = numTicks * ptsPerMajorTick + 1
    exterpolatedPlotPts = linFit_fillInDelaunayPts(exterpolatedPlotPts, numDelaunayPts)
  }

  // No seedHisto, and linear scale, normal 'unforced' axis:
  else if (!basisA.isMinForced && !basisA.isMaxForced) {
    numTicks = basisA.tickVisValues.length
    numDelaunayPts = numTicks * 10 + 1
    exterpolatedPlotPts = linFit_fillInDelaunayPts(exterpolatedPlotPts, numDelaunayPts)
  }

  else {
    // default
    exterpolatedPlotPts = linFit_fillInDelaunayPts(exterpolatedPlotPts, 101)
  }

  // Did not matter up to this point whether min/max are properly defined.
  // But does matter for the minB/maxB return values.
  if (B_atMinA > B_atMaxA) {
    temp = B_atMinA
    B_atMinA = B_atMaxA
    B_atMaxA = temp
  }


  // This entire line and plotPts are calculated in Log coordinate system.
  // (Both basisA and basisB axis. )
  // This is OK as we just define the getter functions to plot the points
  // as defined (we DO NOT use the basisA or basisB.nonLinearXfrm() function.
  // HOWEVER:
  // We are also returning minB and maxB dataRange.  This needs to be
  // in the tick axis coordinated system.  If the basisB is logarithmic,
  // then these numbers need to be 10^minB and 10^maxB
  if (isLogB) {
    B_atMinA = Math.pow(10, B_atMinA)
    B_atMaxA = Math.pow(10, B_atMaxA)
  }

  return { interpolatedPlotPts, exterpolatedPlotPts, minB: B_atMinA, maxB: B_atMaxA }
}


const linFit_fillInDelaunayPts = (endPts: BasicPlotPoint[], numPts: number): BasicPlotPoint[] => {
  const deltaA = (endPts[1].A - endPts[0].A) / (numPts - 1)
  const deltaB = (endPts[1].B - endPts[0].B) / (numPts - 1)
  const startA = endPts[0].A
  const startB = endPts[0].B
  const newPts = Array(numPts)
  for (let i = 0; i < numPts; i++) {
    newPts[i] = {
      A: startA + i * deltaA,
      B: startB + i * deltaB
    }
  }
  return newPts
}
