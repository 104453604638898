import { rgb } from 'd3-color'
import { Component } from 'react'
import { DropTarget } from 'react-dnd'
import type { ConnectDropTarget, DropTargetConnector, DropTargetMonitor } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import Progress from '../sharedComponents/Progress'

type OwnProps = {
  onFilesDrop: (files: File[]) => void,
  totalWidth: number,
  totalHeight: number,
  passedErrMsg: string[],
  progressMsg: string[],
}
type CollectProps = {
  canDrop: boolean,
  connectDropTarget: ConnectDropTarget,
  isOver: boolean,
}
type Props = OwnProps & CollectProps

class FileDropTargetRender2 extends Component<Props> {
  render() {
    const {connectDropTarget, isOver, passedErrMsg, totalHeight, progressMsg} = this.props

    return connectDropTarget(
      <div
        className="DnD_dropTarget"
        style={{
          width: '100%',
          height: totalHeight,
          position: 'relative',
        }}>

        <div style={{
          borderColor: isOver ? rgb(100,100,100).formatHex() : 'darkgray',
          borderRadius: '15px',
          borderStyle: 'dashed',
          borderWidth: '4px',
          position: 'absolute',
          left: 10,
          right: 10,
          top: 10,
          bottom: 10,
          background: 'rgba( 0,0,0,0 )',
        }}>

          <div
              className={'DnD_optionalErrMsg'}
              style={{
                fontSize: 18,
                paddingTop: 20,
                paddingLeft: 30,
                height: totalHeight - 120,   // This height is consumed, whether errMsg exist or not.
                //background:'green',
                color: (passedErrMsg.length > 0) ? 'red' : 'unset'
              }}>
              { passedErrMsg.length > 0 && passedErrMsg.map( (thisLine, i) => {
                return  <div key={i}>{thisLine}</div>
              })}
              {passedErrMsg.length === 0 &&
                <Progress progressMsg={progressMsg} />
              }
          </div>

          {progressMsg.length === 0 &&
          <div style={{
            color: isOver ? rgb(60,60,60).formatHex() : 'darkgray',
            fontSize: 'xx-large',
            paddingTop: 0,
            textAlign: 'center',
            //background: 'pink'
          }}>
            {'Drag file or paste here'}<br/>
            {'Include column names'}
          </div>
          }

        </div>
      </div>
    )
  }
}

const fileTarget = {
  drop: (props: Props, monitor: DropTargetMonitor): void => {
    const files = monitor.getItem().files
    props.onFilesDrop(files)
  },
}

const collect = (connect: DropTargetConnector, monitor: DropTargetMonitor): CollectProps => (
  {
    canDrop: monitor.canDrop(),
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  }
)

const FileDropTarget2 = DropTarget([NativeTypes.FILE], fileTarget, collect)(FileDropTargetRender2)
export default FileDropTarget2
