import { PureComponent } from 'react'
import constants from '../sharedComponents/constants'

const { SCRY_BLUE } = constants

type OwnProps = {
  width: number
  height: number
}
export default class SVGdatatablesLogo extends PureComponent<OwnProps> {

  render() {
    return (
      <svg className={'rc_SVGdatatablesLogo'} height="32" version="1.1" width="32" xmlns="http://www.w3.org/2000/svg">
        <g transform="scale(0.75)">
          <rect height="8" style={{ fill: SCRY_BLUE }} width="12" x="0" y="0" />
          <rect height="8" style={{ fill: SCRY_BLUE }} width="18" x="14" y="0" />
          <rect height="6" style={{ fill: SCRY_BLUE }} width="12" x="0" y="10" />
          <rect height="6" style={{ fill: SCRY_BLUE }} width="12" x="0" y="18" />
          <rect height="6" style={{ fill: SCRY_BLUE }} width="12" x="0" y="26" />
          <rect height="6" style={{ fill: SCRY_BLUE }} width="18" x="14" y="10" />
          <rect height="6" style={{ fill: SCRY_BLUE }} width="18" x="14" y="18" />
          <rect height="6" style={{ fill: SCRY_BLUE }} width="18" x="14" y="26" />
        </g>
      </svg>
    )
  }
}
