import type {
  BasicPlotPoint,
  DataNameRoot,
  DensePlotPt,
  PlotPt,
  PlotXyComputedAxis,
  PlotXyComputedData,
  PlottedValue,
  ReactLayer,
  ReactVisType,
  SeedHistoPlotPts,
  SparsePlotPt,
  VisTypeUser
} from './xy_plotTypes'
import invariant from 'invariant'
import { findClosestNamedColor } from '../sharedComponents/ShapeAndColorPicker'
import { stdDevFromPercentile } from '../sharedFunctions/mathScry'
import { logTime } from '../sharedFunctions/timer'
import { lastVal } from '../sharedFunctions/utils'
import { plotLayoutConsts } from '../viewPlotXY/plotXyLayoutConsts'
import {
  getPlotPts_binnedSeedHistoStats,
  getPlotPts_linFit,
  getPlotPts_classicHistogram
} from './getPlotPts'
import { getPercentilePts_withMemory } from './percentilePlots'
import { calcKernelWidthAndSmoothingIndex, parseRenderedLayerID } from './plotUtils'
import { getAvalue_From_Index, getIndex_From_Avalue } from './seedHistogram'
import { getDefaultReactLayer, isDensePlotPt } from './xy_plotTypes'

// These constants passed as function arguments
// Named only for the purpose of making code readable.
const useInterpPts_False = false
const useStackNorm_False = false
const constantsFileLineThickness = plotLayoutConsts.nominalPlotLineThickness

//  Format for 'renderedLayerID'
//
//    plottedValue        visTypeUser      sKeyArr
//
//    freq               :  marks        :  [3,1]
//    percentile         :  line
//                       :  marksLine
//    mean               :  bars
//    sum                :  area
//    tableRow           :  stackedBars
//                       :  stackedArea
//    linFit             :  stackedBars100%
//
//    ALSO AVAILABLE IN DEVELOPMENT MODE
//    min,
//    max,
//    variance,





//    linFit selection in resource can map internally to:
//        freq  '1Col' plots
//

//
//    Also available for testing:
//    min, max, variance, smth_min, smth_max, smth_variance, smth_mean, smth_sum
//
//  Above set of potential permutations is a 'sparse' array.  Not all are
//  legal. To be determined: how to tell the user Interface which corners
//  are currently supported;  And function to verify (or reject) any given
//  string as a valid supported corner.
//
//  Each corner will create at least one, but up to three reactLayers (per series).
//  A react layer is a single call to ComponentXY/renderSingleReactLayer.
//  reactLayers are bundled into canvasLayering sets, currently named and stacked in
//  this order:
//      'bottom',     opaque features; vistypes area and bars
//      'rows'        row tables values plot as marks, line, or marksLine
//      'statLines'   (mean, sum, freq, ...)
//      'linFit'      linear fitted lines
//      'smthFit'     smth lines and or curved best statistical fits.
//      'pinnedRows'  'star' marker superimposed over the underlying 'row' marker.
//
//  Lines may be plotted on various canvas layers.  Depends on the purpose:
//      area plot 'out-lines', which are rendered above potentially opaque areas.
//      row data lines, plotted as 'line' or 'marksLine is on the 'row' canvas
//      lines for stats (or the thin outline of areas) lie on the 'statsLine' above.
//      linear fits (consisting of three react layers) assigned to the 'linfit' canvas
//      smoothed fits (also consisting of three react layers) to the 'smthfit' canvas


const getReactVisType = (visTypeUser: VisTypeUser, isTransposed: boolean): ReactVisType => {
  switch (visTypeUser) {
    case 'marksLine': { return 'LineMarkSeries' }
    case 'marks': { return 'MarkSeries' }
    case 'line': { return 'LineSeries' }
    case 'bars':
    case 'stackedBars':
    case 'stackedBars100%':
      return (isTransposed) ? 'RectScryLeftA' : 'RectScryBottomA'
    case 'area':
    case 'stackedArea':
    case 'stackedArea100%':
      return (isTransposed) ? 'AreaHorizontalSeries' : 'AreaSeries'
    default:
      if (process.env.NODE_ENV === 'development') {
        invariant(false, `Unrecognized visTypeUser: ${visTypeUser}`)
      }
      return 'MarkSeries'
  }
}


const getSideBySideBarOffsets = (plt: PlotXyComputedData, plotPtsInfo: SeedHistoPlotPts): {
  barOffsetsArr_a0: number[]
  barOffsetsArr_a: number[]
} => {
  // This function calculates, then sets: basisA.a0_OffsetArray, basisA.a_OffsetArray
  // The values will be one of three results:
  //     Case of NOT plotting any type of bars (no offsets to plottedValueA):
  //           pass null & null (same as [0,0,...] offsets)
  //     Case of 'stackedBars' or 'stackedBars100%'
  //           [-delta, -delta, ....],  [+delta, +delta, ...]
  //           The plotted position in A is a bar of width 2*delta centered at A.
  //     Case of 'bars' we place bars 'side-by-side'   Example with 4 side-by-side series:
  //           a0 = [-.2,-.1, 0, +.1, ]   a=[ -.1, 0, .1, .2 ]
  //           Above example: bars are width of 0.1, 2 left of plottedValueA, and 2 to the right.

  // Above alternatives are clear.  But there is another usage model:
  //    Assume I have 3 series, but I don't wish them to be stacked. (hence default
  //    in react-vis would be 'side-by-side'.)
  //    BUT for each plotted 'a' coord, there is only a single series that has a
  //    plottable value (others are freq=0). For example, series 0 is 'US states', 1 is
  //    'Canadian Provences', and 3rd is 'other countries'.  I'm plotting the average
  //    finish time for each state, provence, and other country.  If I use bars, then
  //    there is only one 'real valued' bar for any a-coord.  No use plotting them
  //    side-by-side, when I know there can be only one 'real' bar per a-coord !
  //
  //    This would occur whenever I plot visType bars, but I choose to color
  //    code 'disjoint' subsets of the data using different colors per subset.
  //    We don't need to ask the user.  We can test the series bars as to whether
  //    they represent 'disjoint' series data sets.  Doesn't matter what the user's
  //    intention is.  Always use centered bars if there is only one bar per
  //    rendered a-coord.  (until some test case says otherwise)

  // Offset rules for side-by-side bars.
  //     1) Total space available is the legalUserBinWidth.  It will never be
  //        smaller than arrayBinsPerMinIntervalInA * , but can be larger.
  //     2) Plot constants file has a value for the % of available width to
  //        allocate to an empty space between seriesSets.  This allcation
  //        changes depending on whether we have a number or string axis.
  //     3) Width for each bar is:  (AvailableWidth*(1-space) ) / numSeries.
  //     4) Like react vis, we will only plot bars for 'non-empty' series.
  //        In other words, don't allocate space for empty series.
  //

  const { seriesOrder, basisA, isHisto } = plt
  const numSeries = seriesOrder.length
  var isDisjointSeriesSets = true // Assumption
  const plotPts = plotPtsInfo.densePlotPts
  if (plotPts) {
    for (const thisPlotPt of plotPts) {
      var thisPlotPt_seriesMax = Math.max(...thisPlotPt.freq)
      var thisPlotPt_seriesSum = thisPlotPt.freq.reduce((sum, freq) => sum += freq)
      // IF seriesMax === seriesSum, THEN the number of sKeys with a nonzero freq is either zero or 1.
      if (thisPlotPt_seriesMax !== thisPlotPt_seriesSum) {
        isDisjointSeriesSets = false
      }
    }
  }
  // How many sKeys, and which sKeys have data?
  var nonEmptySeriesKeys = Array<number>()
  seriesOrder.forEach(sKey => {
    if (plotPtsInfo.isEmptyOverRangeCulled[sKey]) { return }
    nonEmptySeriesKeys.push(sKey)
  })
  const numNonEmptySeriesKeys = nonEmptySeriesKeys.length

  // What is the binWidth?
  let binWidth: number
  if (basisA.internalDataType === 'string') {
    // one bar per string, up until string density exceeds 1 per seedHisto bin width.
    // HOWEVER, when as thin as the arrarybinWidth, only first series shows (dominates)
    // Better to just render lines of width 1 which appear to render as a aliased single
    // pixel.  Net effect is two or more colors still visible.   Probably no a corner
    // case to worry about, as usually only one series for this type of analysis.
    //binWidth = Math.max( 1, basisA.seedHisto.arrayBinWidth/2 )
    binWidth = Math.max(1)
  } else if (isHisto) {
    const { legalBinWidthIndex, binWidthOptions } = basisA.seedHisto!
    binWidth = binWidthOptions[legalBinWidthIndex]
  } else {
    // scatter plot bars
    const { arrayBinWidth, arrayBinsPerMinIntervalInA } = basisA.seedHisto!
    binWidth = arrayBinWidth * arrayBinsPerMinIntervalInA
  }
  // how much to allocate to blank space between each series set?
  let barSizeFactor
  if (basisA.internalDataType === 'string' && numNonEmptySeriesKeys === 1) {
    barSizeFactor = plotLayoutConsts.barSizeFactor_stringAxis
  } else {
    barSizeFactor = plotLayoutConsts.barSizeFactor_numberAxis
  }

  // Case of a single bar per binWidth. It is centered and
  // covers most of the available binWidth space.
  if (isDisjointSeriesSets || plt.isStacked_anyLayer ||
    plt.isStacked100Percent_allLayers) {
    var offset = binWidth * barSizeFactor / 2
    return {
      barOffsetsArr_a0: Array<number>(numSeries).fill(-offset),
      barOffsetsArr_a: Array<number>(numSeries).fill(+offset)
    }
  }
  // Calculate the side-by-side positions of each nonEmpty series.
  // JUST the offsets from the nominal 'centered' coordinate.
  const barOffsetsArr_a0 = Array(numSeries).fill(0)   // Initializes empty series as well!
  const barOffsetsArr_a = Array(numSeries).fill(0)
  const seriesBarWidth = binWidth * barSizeFactor / numNonEmptySeriesKeys
  var aCurrent = - binWidth * barSizeFactor / 2  // leftmost rendered edge 'offset' of 1st side-by-side bar.
  for (let sKey in nonEmptySeriesKeys) {
    barOffsetsArr_a0[sKey] = aCurrent
    aCurrent += seriesBarWidth
    barOffsetsArr_a[sKey] = aCurrent
  }
  return { barOffsetsArr_a0, barOffsetsArr_a }
}


export const createReactLayer = (plt: PlotXyComputedData,
  thisRenderedLayerID: string,
  DEBUG: boolean): ReactLayer[] => {

  // valueType is every thing after the last (second) colon

  var { sKeyArr, isSmoothed, plottedValue, plottedValueRoot,
    visTypeUser } = parseRenderedLayerID(thisRenderedLayerID)
  const { basisB, basisA, seriesAttributesArray, isPercentile,
    isStacked_anyLayer, isStacked100Percent_allLayers, plotColDataType,
    seriesRowPlotPts_sortedInA, pinnedRowKeys } = plt
  const willUseLogarithmicScaleB = basisB.willUseLogarithmicScale
  const isVisTypeBars = (visTypeUser === 'bars' ||
    visTypeUser === 'stackedBars' ||
    visTypeUser === 'stackedBars100%')
  const isBottomCanvas = (isVisTypeBars ||
    visTypeUser === 'area' ||
    visTypeUser === 'stackedArea' ||
    visTypeUser === 'stackedArea100%')
  var plotPtsInfo: SeedHistoPlotPts, allSeriesReactLayer: ReactLayer
  var Bscale = 1          // assumption
  const reactLayers = Array<ReactLayer>()    // This is our return value !!!

  const getBaseReactLayer = (plottedValue: PlottedValue) => (
    getDefaultReactLayer(plottedValue, {
      plotColDataType,
      renderedLayerID: thisRenderedLayerID,
      visTypeUser,
      visTypeReact: getReactVisType(visTypeUser, plt.isTransposed),
      lineThickness: constantsFileLineThickness,
      isSmoothed,
      // As default, put the main rendered data on layeringOrder 2
      // This leaves room to add cosmetic layers either below (0,1)
      // or above (3,4, ...)
      layeringOrder: 2,
    })
  )
  // This function appends the series specific data to the baseReactLayer
  const setReactLayerSeriesAttributes = (baseReactLayer: ReactLayer, sKey: number, sOrderIndex: number): ReactLayer => {
    const { markShape, markSize, color, seriesOpacity } = seriesAttributesArray[sKey]
    return { ...baseReactLayer, markShape, markSize, color, seriesOpacity, sKey, sOrderIndex }
  }

  let useInterpPts: boolean
  let plotPtsRoot: DataNameRoot
  let thisReactLayer: ReactLayer

  switch (true) {

    // Table Row data cannot be plotted in case of isStacked100Percent_allLayers
    case plottedValueRoot === 'tableRow':
      //return []   // For debugging to shut down this term.return []
      // Table Row data cannot be plotted in case of isStacked100Percent_allLayers
      if (isStacked100Percent_allLayers) { return [] }
      allSeriesReactLayer = getBaseReactLayer(plottedValueRoot)
      allSeriesReactLayer.description = 'Row data always plotted as pts.'
      allSeriesReactLayer.getA0 = bindGetValA(basisA, useInterpPts_False)
      allSeriesReactLayer.getA = bindGetValA(basisA, useInterpPts_False)
      allSeriesReactLayer.canvasLayeringSet = 'rows'
      allSeriesReactLayer.canvasOpacity = 1
      allSeriesReactLayer.plotPtAttributeB0 = 'B0'
      allSeriesReactLayer.plotPtAttributeB = 'B'
      Bscale = 1
      allSeriesReactLayer.getB = bindGetValB(basisB, 'B0', useInterpPts_False, useStackNorm_False, Bscale)
      allSeriesReactLayer.getB = bindGetValB(basisB, 'B', useInterpPts_False, useStackNorm_False, Bscale)
      setGetLeftAndBottomTransforms(allSeriesReactLayer, plt.isTransposed)
      sKeyArr.forEach((sKey, sOrderIndex) => {
        if (plt.seriesAttributesArray[sKey].numCulledPoints === 0) { return }
        thisReactLayer = setReactLayerSeriesAttributes(allSeriesReactLayer, sKey, sOrderIndex)
        thisReactLayer.minB = seriesAttributesArray[sKey].filteredPtsStats.minBculled //result.culledRangeB.min
        thisReactLayer.maxB = seriesAttributesArray[sKey].filteredPtsStats.maxBculled
        const data = plt.seriesRowPlotPts_culled[sKey]
        thisReactLayer.plotPts = plt.seriesRowPlotPts_culled[sKey]
        reactLayers.push(thisReactLayer)
        // Any pinned rows to Render?
        // Use the same color, but star shape for the mark.
        if (pinnedRowKeys.length > 0) {
          var pinnedData = Array<SparsePlotPt>()
          data.forEach(thisPt => {
            if (pinnedRowKeys.includes(thisPt.rowKey)) { pinnedData.push(thisPt) }
          })
          if (pinnedData.length > 0) {

            var pinnedLayer = { ...thisReactLayer }
            pinnedLayer.description = 'PinnedRows data as star.'
            pinnedLayer.layeringOrder = 2
            pinnedLayer.plotPts = pinnedData
            pinnedLayer.markShape = 'star'
            pinnedLayer.markSize = 1.0
            pinnedLayer.canvasLayeringSet = 'pinnedRows'
            reactLayers.push(pinnedLayer)

            var underPinnedLayer = { ...pinnedLayer }
            underPinnedLayer.description = 'PinnedRows data as star contrasting outline.'
            underPinnedLayer.layeringOrder = 1
            underPinnedLayer.color = getContrastingUnderColor(pinnedLayer.color)
            underPinnedLayer.markSize = 1.2
            reactLayers.push(underPinnedLayer)
          }
        }
      })
      logTime('id_PlotXyComputedData', `Create renderedLayer: ${thisRenderedLayerID}`)
      return reactLayers




    case plottedValueRoot === 'freq':
      allSeriesReactLayer = { ...getBaseReactLayer(plottedValueRoot) }
      const isBasisA_dataTypeString = basisA.internalDataType === 'string'
      plotPtsRoot = plottedValueRoot
      if (isSmoothed) {  // Data comes from the seedHistogram
        // We plot all interpolated pts;  No binWidth option!
        // Rendered layers always go to the 'smthFit' canvas
        allSeriesReactLayer.description = 'Smoothed Histogram Freq count on numeric Axis.'
        plotPtsInfo = getPlotPts_binnedSeedHistoStats(plt, plotPtsRoot, isSmoothed, DEBUG)
        allSeriesReactLayer.canvasLayeringSet = (isBottomCanvas) ? 'bottom' : 'smthFit'
        useInterpPts = isSmoothed
      } else if (isBasisA_dataTypeString) {
        // Data comes from the seedHistogram !!
        // We need to distiguish between basisB linear or logScale, so the
        // smoothing algorithm works properly.
        // We plot each enumerated string freq;  No binWidth option!
        allSeriesReactLayer.description = 'Freq counts of basisA String dataType.'
        plotPtsInfo = getPlotPts_binnedSeedHistoStats(plt, plotPtsRoot, false, DEBUG)
        allSeriesReactLayer.canvasLayeringSet = (isBottomCanvas) ? 'bottom' : 'rows'
        useInterpPts = false
      } else {   // Data comes from the 'classical' histogram function.
        // Render only points at the center of histogram bins.
        // If bars/area, rendering layers go to 'bottom canvas, else go to 'row' canvas.
        allSeriesReactLayer.description = 'Classic Histogram Freq count on numeric Axis.'
        plotPtsInfo = getPlotPts_classicHistogram(plt, DEBUG)
        allSeriesReactLayer.canvasLayeringSet = (isBottomCanvas) ? 'bottom' : 'rows'
        useInterpPts = false
      }
      const { densePlotPts } = plotPtsInfo
      if (densePlotPts) {
        allSeriesReactLayer.plotPts = densePlotPts
      }
      allSeriesReactLayer.canvasOpacity = 1
      const { isHistogramPercent } = basisB
      const isHistogramCount = !isHistogramPercent

      var barOffsetsArr_a = null, barOffsetsArr_a0 = null
      if (isVisTypeBars) {
        ({ barOffsetsArr_a, barOffsetsArr_a0 } = getSideBySideBarOffsets(plt, plotPtsInfo))
      }
      var shouldSkipZeroHeightBars = (allSeriesReactLayer.visTypeUser === 'stackedBars')
      allSeriesReactLayer.getA0 = bindGetValA(basisA, useInterpPts, barOffsetsArr_a0, shouldSkipZeroHeightBars)
      allSeriesReactLayer.getA = bindGetValA(basisA, useInterpPts, barOffsetsArr_a, shouldSkipZeroHeightBars)

      // B plot values for histograms are multiple by 'Bscale'.
      // For the smallest bin width, the Bscale equal 1.
      // If uses changes to binWidth such that is 'n' times wider the '1',
      //    then the plot will look very similar, with fatter bins, but the
      //    vertical scale will need be larger by 5X.  And the scale units
      //    (count per bin) will be larger by 5X.
      // No problem with the classical histogram function, as it will count
      // ~5x more samples per bin (on average).
      // However, don't wish to and another variable to the smoothing algorithm
      // and the smoothed set of freq values.  Since this curve is the same
      // regardless of the discrete binWidth for the bars.  But we what to
      // plot the curve using the identical 'B units' as the bars, which are
      // in units of 'Count (or percent) per binWidth'.  So I will use Bscale
      // to multiple the smoothed plotPt B values by 1X, 2X, 5X, ...
      // so the smoothed line properly overlays the classical histogram.
      const { binWidthOptions, legalBinWidthIndex } = basisA.seedHisto!
      Bscale = (basisA.internalDataType === 'number' && isSmoothed)
        ? binWidthOptions[legalBinWidthIndex] / lastVal(binWidthOptions) : 1

      // For logarithmic B, and bar and area plots, we need to set the B0 value to 'zero'
      // But this will dump! => Log10(zero) = -Infinity
      // We need a legal value at (or below) the bottom of the rendered plot.  Next equations are sufficient,
      // however, lots of ways to choose an acceptable solution for histograms if these become a problem.
      if (basisB.isLogarithmic) {
        basisB.legalZeroValue_preLog = (isHistogramPercent)
          ? 0.1 / plotPtsInfo.totalCountForLargestSeries
          : 0.1
      }
      sKeyArr.forEach((sKey, sOrderIndex) => {
        var thisReactLayer = setReactLayerSeriesAttributes(allSeriesReactLayer, sKey, sOrderIndex)
        // If this is the bottom canvas, then 'bottomSeriesOpacity' overrides and 'per series' values.
        // Per Series values used for lines/pts styling.
        // Bottom canvas opacity (bars/area) uses a common opacity value (at least for current design.
        if (isBottomCanvas) { thisReactLayer.seriesOpacity = plt.bottomCanvasOpacity }
        if (!isStacked_anyLayer && isHistogramCount) {
          thisReactLayer.minB = 0
          thisReactLayer.maxB = plotPtsInfo.maxB[sKey] * Bscale
          thisReactLayer.plotPtAttributeB0 = 'B0'
          thisReactLayer.plotPtAttributeB = 'B'
          thisReactLayer.getB0 = bindGetValB(basisB, thisReactLayer.plotPtAttributeB0,
            useInterpPts, useStackNorm_False, Bscale)
          thisReactLayer.getB = bindGetValB(basisB, thisReactLayer.plotPtAttributeB,
            useInterpPts, useStackNorm_False, Bscale)
        } else if (!isStacked_anyLayer && isHistogramPercent) {
          thisReactLayer.minB = 0
          thisReactLayer.maxB = plotPtsInfo.maxBhistoPercent[sKey] * Bscale
          thisReactLayer.plotPtAttributeB0 = 'B0'
          thisReactLayer.plotPtAttributeB = 'BhistoPercent'
          thisReactLayer.getB0 = bindGetValB(basisB, thisReactLayer.plotPtAttributeB0,
            useInterpPts, useStackNorm_False, Bscale)
          thisReactLayer.getB = bindGetValB(basisB, thisReactLayer.plotPtAttributeB,
            useInterpPts, useStackNorm_False, Bscale)
        } else if (isStacked_anyLayer && isHistogramCount) {
          thisReactLayer.minB = 0
          thisReactLayer.maxB = plotPtsInfo.maxBstacked[sKey] * Bscale
          thisReactLayer.plotPtAttributeB0 = 'B0stacked'
          thisReactLayer.plotPtAttributeB = 'Bstacked'
          thisReactLayer.getB0 = bindGetValB(basisB, thisReactLayer.plotPtAttributeB0,
            useInterpPts, useStackNorm_False, Bscale)
          thisReactLayer.getB = bindGetValB(basisB, thisReactLayer.plotPtAttributeB,
            useInterpPts, useStackNorm_False, Bscale)
        } else if (isStacked_anyLayer && isHistogramPercent) {
          thisReactLayer.minB = 0
          thisReactLayer.maxB = plotPtsInfo.maxBhistoStackedPercent[sKey] * Bscale
          thisReactLayer.plotPtAttributeB0 = 'B0histoStackedPercent'
          thisReactLayer.plotPtAttributeB = 'BhistoStackedPercent'
          thisReactLayer.getB0 = bindGetValB(basisB, thisReactLayer.plotPtAttributeB0,
            useInterpPts, useStackNorm_False, Bscale)
          thisReactLayer.getB = bindGetValB(basisB, thisReactLayer.plotPtAttributeB,
            useInterpPts, useStackNorm_False, Bscale)
        }
        setGetLeftAndBottomTransforms(thisReactLayer, plt.isTransposed)
        reactLayers.push(thisReactLayer)

        // Some cosmetic outlines:
        if (visTypeUser === 'area') {
          reactLayers.push(createThinAreaOutline(thisReactLayer))
        }
        if (isSmoothed && visTypeUser === 'line') {
          // next layer specifed to sit 'under' the fitted curve.
          // Provides a contrasting color to make the fitted curves appear
          // different from lines.
          reactLayers.push(createSmthFitUnderlayer(thisReactLayer))
        }
        // Optional debugging annotations:
        if (basisA.seedHisto!.DEBUG && isSmoothed &&
          !basisA.seedHisto!.data[sKey].isEmptySeedHistoFreqBinning) {
          const result = createSmthFitDebugAnnotation(plt, sKey, thisReactLayer, plottedValueRoot as DataNameRoot)
          reactLayers.push(result.leftEdgePlotLine)
          reactLayers.push(result.rightEdgePlotLine)
          reactLayers.push(result.linFitPlotLine)
        }
      })
      logTime('id_PlotXyComputedData', `Create renderedLayer: ${thisRenderedLayerID}`)
      return reactLayers




    case isPercentile:
      const rangeA = basisA.rangeCulledAllSeries
      const result = getPercentilePts_withMemory(seriesRowPlotPts_sortedInA, rangeA, DEBUG)
      basisB.rangeCulledAllSeries = result.rangeCulledAllSeriesB
      basisB.nonlinearXform = (basisB.isPercentileNormalProb) ? (x: number): number => stdDevFromPercentile(x) : (x: number): number => (NaN)
      allSeriesReactLayer = { ...getBaseReactLayer('percentile') }
      allSeriesReactLayer.description = 'Percentile values on numeric basisA data.'
      allSeriesReactLayer.getA0 = (x) => (undefined)
      allSeriesReactLayer.getA = bindGetValA(basisA, useInterpPts_False)
      allSeriesReactLayer.canvasLayeringSet = 'rows'
      allSeriesReactLayer.canvasOpacity = 1
      allSeriesReactLayer.plotPtAttributeB0 = 'B0'
      allSeriesReactLayer.plotPtAttributeB = 'B'
      Bscale = 1
      allSeriesReactLayer.getB = bindGetValB(basisB, 'B0', useInterpPts_False, useStackNorm_False, Bscale)
      allSeriesReactLayer.getB = bindGetValB(basisB, 'B', useInterpPts_False, useStackNorm_False, Bscale)
      setGetLeftAndBottomTransforms(allSeriesReactLayer, plt.isTransposed)
      sKeyArr.forEach((sKey, sOrderIndex) => {
        thisReactLayer = setReactLayerSeriesAttributes(allSeriesReactLayer, sKey, sOrderIndex)
        thisReactLayer.plotPts = result.culledPercentileData[sKey]
        if (thisReactLayer.plotPts.length === 0) { return }   // SKIP:  no ReactLayer pushed to output
        thisReactLayer.minB = Math.min(...result.minBbySeries)
        thisReactLayer.maxB = Math.max(...result.maxBbySeries)
        reactLayers.push(thisReactLayer)
      })
      logTime('id_PlotXyComputedData', `Create renderedLayer: ${thisRenderedLayerID}`)
      return reactLayers



    case plottedValueRoot === 'mean':
    case plottedValueRoot === 'sum':
    case plottedValueRoot === 'min':
    case plottedValueRoot === 'max':
    case plottedValueRoot === 'variance': {
      // return []   // For debugging to shut down this term.
      plotPtsRoot = plottedValueRoot
      if (willUseLogarithmicScaleB && plotPtsRoot === 'mean') { plotPtsRoot = 'meanLogB' }
      if (willUseLogarithmicScaleB && plotPtsRoot === 'variance') { plotPtsRoot = 'varianceLogB' }
      plotPtsInfo = getPlotPts_binnedSeedHistoStats(plt, plotPtsRoot, isSmoothed, DEBUG)
      // This is a rarely used plot level attribute, that is calculated and embedded in the
      // densePlotPts information.  Search createPlotXyComputedData.js to see when/where/how it is used.
      plt.minB_sKey0_Normed = plotPtsInfo.minB_sKey0_Normed
      allSeriesReactLayer = { ...getBaseReactLayer(plottedValueRoot) }
      allSeriesReactLayer.description = `${isSmoothed ? 'Smoothed ' : ''}${plottedValue} created from SeedHisto binning.`
      allSeriesReactLayer.canvasLayeringSet = isBottomCanvas ? 'bottom' : 'statLines'
      allSeriesReactLayer.canvasOpacity = 1
      const { densePlotPts } = plotPtsInfo
      if (densePlotPts) {
        allSeriesReactLayer.plotPts = densePlotPts
      }
      Bscale = 1
      // Stacked area plots MUST include interpolated points! Otherwise inflection points in
      // one series may not be properly represented in a 'higher' series that has no real value
      var shouldPlotInterpPts = (visTypeUser === 'stackedArea' ||
        visTypeUser === 'stackedArea100%' ||
        isSmoothed)
      var useStackNormalization = (visTypeUser === 'stackedArea100%' || visTypeUser === 'stackedBars100%')

      barOffsetsArr_a = null
      barOffsetsArr_a0 = null
      if (isVisTypeBars) {
        ({ barOffsetsArr_a, barOffsetsArr_a0 } =
          getSideBySideBarOffsets(plt, plotPtsInfo))
      }
      allSeriesReactLayer.getA0 = bindGetValA(basisA, shouldPlotInterpPts, barOffsetsArr_a0)
      allSeriesReactLayer.getA = bindGetValA(basisA, shouldPlotInterpPts, barOffsetsArr_a)
      // Safety catch for case where smoothing of data creates 'negative'
      // data, even if all the B data is positive and the user was allowed
      // to use a logarithmicB axis.
      // Use PlotPtsInfo to find the largest Bvalue over all series.
      // Use filteredPtsStats to find smallest Bvalue over all series ( I use
      // the filteredPts, NOT the plotPts minimum, because the plotPts min
      // can jump around and value significantly up to -Infinity!
      // Set the minB value used for the smartAxis equal to
      // the filteredPts minB, extended lower by 8%.  This axis math is
      // all done assuming a logB scale.
      if (isSmoothed && basisB.isLogarithmic) {
        let minB_filtered = +Infinity
        for (let thisSeries of plt.seriesAttributesArray) {
          minB_filtered = Math.min(minB_filtered, thisSeries.filteredPtsStats.minB)
        }
        minB_filtered = Math.log10(minB_filtered)
        let maxLogBallSeries = Math.log10(Math.max(...plotPtsInfo.maxB))
        let minPlottedLogB = minB_filtered - 0.08 * (maxLogBallSeries - minB_filtered)
        let minPlottedB = basisB.legalZeroValue_preLog = Math.pow(10, minPlottedLogB)
        for (let sKey of sKeyArr) {
          plotPtsInfo.minB[sKey] = Math.max(plotPtsInfo.minB[sKey], minPlottedB)
        }
      }


      //sKeyArr.forEach( (sKey, sOrderIndex) => {
      var sOrderIndex = -1
      for (let sKey of sKeyArr) {
        sOrderIndex++
        if (plotPtsInfo.isEmptyOverRangeCulled[sKey]) { continue }
        thisReactLayer = setReactLayerSeriesAttributes(allSeriesReactLayer, sKey, sOrderIndex)

        // ASSUMPTION:  What every we decide to plot, ALL plotted points are also delaunay points.
        thisReactLayer.isRendered = true
        thisReactLayer.hasCrosshairs = true
        // EXCEPTION: If we plotted Interpolated points && this data !isSmoothed, THEN we plotted
        // the interpolated points for the purpose of building a valid stacked area plot.
        // In this case, the interpolated points plotted (not actually visible as they
        // fall on a straight line segment), are needed for proper area rendering, but
        // otherwise should be invisible (non-existing) points from viewer's perspective.
        // Hence we DO NOT want these pts showing up in the delaunay crosshairs.
        var isInterpolated_But_NonDelaunay_Points = (shouldPlotInterpPts && !isSmoothed)
        if (isInterpolated_But_NonDelaunay_Points) {
          thisReactLayer.hasCrosshairs = false
        }

        // If this is the bottom canvas, then 'bottomSeriesOpacity' overrides and 'per series' values.
        // Per Series values used for lines/pts styling.
        // Bottom canvas opacity (bars/area) uses a common opacity value (at least for current design.
        if (isBottomCanvas) { thisReactLayer.seriesOpacity = plt.bottomCanvasOpacity }
        if (isStacked_anyLayer || isStacked100Percent_allLayers) {
          thisReactLayer.minB = plotPtsInfo.minBstacked[sKey]
          thisReactLayer.maxB = plotPtsInfo.maxBstacked[sKey]
          thisReactLayer.plotPtAttributeB0 = 'B0stacked'
          thisReactLayer.plotPtAttributeB = 'Bstacked'
          thisReactLayer.getB0 = bindGetValB(basisB, 'B0stacked', shouldPlotInterpPts, useStackNormalization, Bscale)
          thisReactLayer.getB = bindGetValB(basisB, 'Bstacked', shouldPlotInterpPts, useStackNormalization, Bscale)
        }

        else {   // plot is not stacked.
          thisReactLayer.minB = plotPtsInfo.minB[sKey]
          thisReactLayer.maxB = plotPtsInfo.maxB[sKey]
          thisReactLayer.plotPtAttributeB0 = 'B0'
          thisReactLayer.plotPtAttributeB = 'B'
          thisReactLayer.getB0 = bindGetValB(basisB, 'B0', shouldPlotInterpPts, useStackNorm_False, Bscale)
          thisReactLayer.getB = bindGetValB(basisB, 'B', shouldPlotInterpPts, useStackNorm_False, Bscale)
        }
        // Make the input line (thisReactLayer) a bit thinner,
        // when we have a underlying contrasting outline (when isSmoothed fit)
        if (isSmoothed) { thisReactLayer.lineThickness = constantsFileLineThickness * 0.9 }
        setGetLeftAndBottomTransforms(thisReactLayer, plt.isTransposed)
        reactLayers.push(thisReactLayer)

        if (visTypeUser === 'area') {
          var outLine = createThinAreaOutline(thisReactLayer)
          outLine.description = `${isSmoothed ? 'Smoothed ' : ''}${plottedValue} area outline.`
          reactLayers.push(outLine)
        }
        if (isInterpolated_But_NonDelaunay_Points) {
          var delaunayLayer = Object.assign({}, thisReactLayer)
          // We need to build a delaunay layer, but exclude all the
          // interpolate points.
          delaunayLayer.isRendered = false
          delaunayLayer.hasCrosshairs = true
          delaunayLayer.description = `Delaunay only layer for ${plottedValue}.`
          // Having basisA NOT include interpolated pts is enough for reactVis and
          // delaunay code skip these points.
          setGetLeftAndBottomTransforms(delaunayLayer, plt.isTransposed)
          reactLayers.push(delaunayLayer)
        }
        if (isSmoothed) {
          var underLayer = createSmthFitUnderlayer(thisReactLayer)
          reactLayers.push(underLayer)
        }
        // Optional debugging annotations:
        if (basisA.seedHisto!.DEBUG && isSmoothed && plottedValueRoot === 'mean') {
          const result = createSmthFitDebugAnnotation(plt, sKey, thisReactLayer, plotPtsRoot)
          reactLayers.push(result.leftEdgePlotLine)
          reactLayers.push(result.rightEdgePlotLine)
          reactLayers.push(result.linFitPlotLine)
        }
      }
      logTime('id_PlotXyComputedData', `Create renderedLayer: ${thisRenderedLayerID}`)
      return reactLayers
    }



    case plottedValueRoot === 'linFit':
      // return []   // For debugging to shut down this term.
      allSeriesReactLayer = { ...getBaseReactLayer(plottedValueRoot) }
      allSeriesReactLayer.canvasLayeringSet = 'linFit'
      allSeriesReactLayer.canvasOpacity = 1
      allSeriesReactLayer.plotPtAttributeB0 = ''
      allSeriesReactLayer.plotPtAttributeB = 'B'
      allSeriesReactLayer.markShape = 'none'
      allSeriesReactLayer.markSize = 2
      allSeriesReactLayer.hasCrosshairs = false
      allSeriesReactLayer.isRendered = true
      // allSeriesReactLayer.visType = 'LineSeries'
      allSeriesReactLayer.getA0 = () => null
      allSeriesReactLayer.getA = x => x.A
      allSeriesReactLayer.getB0 = () => null
      allSeriesReactLayer.getB = x => x.B
      setGetLeftAndBottomTransforms(allSeriesReactLayer, plt.isTransposed)
      sKeyArr.forEach((sKey, sOrderIndex) => {
        thisReactLayer = setReactLayerSeriesAttributes(allSeriesReactLayer, sKey, sOrderIndex)
        var { exterpolatedPlotPts, interpolatedPlotPts, minB, maxB } = getPlotPts_linFit(plt, sKey)
        if (exterpolatedPlotPts.length === 0) { return [] }
        // Interpolated line
        thisReactLayer.description = 'Linear Fit - Interpolated Color line'
        thisReactLayer.lineThickness = constantsFileLineThickness * 0.9
        thisReactLayer.hasCrosshairs = false   // pt delaunay points on the exterpolated lline.
        thisReactLayer.seriesOpacity = 1
        thisReactLayer.layeringOrder = 2
        thisReactLayer.plotPts = interpolatedPlotPts
        thisReactLayer.minB = minB
        thisReactLayer.maxB = maxB
        reactLayers.push(thisReactLayer)
        // Interpolated line, underlayer creating the outline
        var underlayer = createSmthFitUnderlayer(thisReactLayer)
        underlayer.layeringOrder = 1
        reactLayers.push(underlayer)
        // Exterpolated line
        var exterpolatedlayer = { ...thisReactLayer }
        exterpolatedlayer.plotPts = exterpolatedPlotPts
        exterpolatedlayer.layeringOrder = 1
        exterpolatedlayer.lineThickness = constantsFileLineThickness
        exterpolatedlayer.seriesOpacity = 0.3
        exterpolatedlayer.hasCrosshairs = true
        exterpolatedlayer.description = 'Linear Fit - Exterpolated pale colored line'
        reactLayers.push(exterpolatedlayer)
      })
      logTime('id_PlotXyComputedData', `Create renderedLayer: ${thisRenderedLayerID}`)
      return reactLayers



    default:
      invariant(false, `Unrecognized RendereredLayerID ${thisRenderedLayerID}`)
  }  // End of switch

}


// For a 'area' reactLayer, we add a thin 'outline' on the 'statsLine' canvas.
const createThinAreaOutline = (thisReactLayer: ReactLayer): ReactLayer => {
  const thinOutlineLayer = Object.assign({}, thisReactLayer)
  thinOutlineLayer.visTypeUser = 'line'
  thinOutlineLayer.visTypeReact = 'LineSeries'
  thinOutlineLayer.description = `Thin line 'above' opaque underlying areas`
  thinOutlineLayer.canvasLayeringSet = 'statLines'
  thinOutlineLayer.seriesOpacity = 1
  thinOutlineLayer.lineThickness = constantsFileLineThickness * .5
  thinOutlineLayer.hasCrosshairs = false
  // Not necessary to specify a layering order, as area is always on the bottom canvas
  // and this thin outline is always above, on the statsLines canva.
  return thinOutlineLayer
}

const getContrastingUnderColor = (color: string) => {
  var underColor = '#000000'  // assumption  (this black outline)
  var { colorTitle } = findClosestNamedColor(color)
  if (colorTitle === 'Black' || colorTitle === 'Navy') { underColor = '#bbbbbb' }
  return underColor
}

const createSmthFitUnderlayer = (thisReactLayer: ReactLayer): ReactLayer => {
  // Put a contrasting outline color on fitted lines.
  const underLayer = { ...thisReactLayer }
  underLayer.color = getContrastingUnderColor(thisReactLayer.color)
  underLayer.visTypeUser = 'line'
  underLayer.visTypeReact = 'LineSeries'
  underLayer.description = `Contrasting color line 'below' fitted line.`
  underLayer.layeringOrder = 1   // Main colored lines on '2';  extrapolated linFit line on '0'
  underLayer.lineThickness = constantsFileLineThickness * 1.6
  underLayer.hasCrosshairs = false
  underLayer.seriesOpacity = 1
  // Also, make the input line (thisReactLayer) a bit thinner,
  // to give the underLayer a bit better contrast.
  thisReactLayer.lineThickness = constantsFileLineThickness * 0.9
  return underLayer
}


const bindGetValA = (basisA: PlotXyComputedAxis, useInterpPts: boolean, barOffsetsArr: number[] | null = null, shouldSkipZeroHeightBars: boolean = false) => {
  var usePreXformOffsets = false   // assumption
  var usePostXformOffsets = false   // assumption
  if (barOffsetsArr) {
    var isLogBins = Boolean(
      basisA.seedHisto &&
      basisA.seedHisto.willUseLogarithmicSeedHistoBins)
    if (basisA.internalDataType === 'string') {
      usePreXformOffsets = true
    }
    else if (isLogBins) {
      usePostXformOffsets = true
    } else {
      usePreXformOffsets = true
    }

  }


  return (x: PlotPt, sKey: number) => {
    const { nonlinearXform } = basisA
    if (!isDensePlotPt(x)) {
      // not isDensePlotPt means this is a BasicPlotPoint or SparsePlotPt
      const b = x as BasicPlotPoint
      return nonlinearXform(b.A)
    } else {
      // not BasicPlotPoint or SparsePlotPt means it must be a DensePlotPt
      let x1: number, x2: number

      // Skip all 'isOutOfRange' points.
      // This is a flag 'by series'!
      // So we may need to plot a smoothed line at d = 10 for sKey[0]
      // But data for sKey 1 has not yet started.  So sKey[1] value is
      // currently 'out of range'.
      if (x.isOutOfRange[sKey]) return NaN

      // Return all 'realValuedPts'.
      // And conditionally return interpolatedPts
      if (x.isRealValuedPt[sKey] || useInterpPts) {
        x1 = x.A
      } else {
        return NaN
      }

      // When react renders a polygon of zero height (they exist in our array of stacked values)
      // it doesn't know to skip the request.  Instead, react renders a pixel 'tall' artifact
      // that appears as a colored line across our stacked bars, where their should be nothing
      // of that color.
      // We can optionally skip rendering zero height bars.
      // For stackedPolygons, this is correct answer.
      // For side-by-side polygons, doesn't matter as the artifact at hieght = 0 is
      // buried under the axis line. (Our choice to put axis line on top of data.)
      if (shouldSkipZeroHeightBars && x.B[sKey] === 0) { return NaN }

      // When plotting bars we use our own calculated left/right bar coords.
      // Three cases:
      // 1) not plotting bars || linear axis - both Offset inputs are null.
      // 2) basisA.isLog && seedHistogram binning isLog - Offset applied AFTER nonlinear Xform.
      //      Displayed bars are all identical width in the rendered plot.
      // 3) basisA.isLog, but seedHistogram binning is linear.
      //    This is special case for plots where the dataRange is <= 1 decade.
      //    allows the binning to be expressed and plotted in sensible units.
      //      Displayed bars decrease in width as one moves from min-to-max A values.

      if (usePreXformOffsets && barOffsetsArr) { x1 += barOffsetsArr[sKey] }
      x2 = nonlinearXform(x1)
      if (usePostXformOffsets && barOffsetsArr) { x2 += barOffsetsArr[sKey] }

      return x2
    }
  }
}

const bindGetValB = (basisB: PlotXyComputedAxis, extractedAttribute: string, useInterpPts: boolean, useStackNorm: boolean, Bscale: number): (d: any, sKey: number) => number => {

  return (x: PlotPt, sKey: number): number => {
    const { nonlinearXform, legalZeroValue_preLog } = basisB
    // We can identify SparsePlotPt or BasicPlotPoint, if isDensePlotPt returns false. 
    // For these PlotPts (rows, percentiles) the only option is to return the B value.
    if (!isDensePlotPt(x)) {
      const b = x as BasicPlotPoint
      return nonlinearXform(b.B)
    } else {
      // must be a DensePlotPt
      let x1: number

      // Return all realValuePts.
      // And conditionally return interpolated pts
      if (x.isRealValuedPt[sKey] || (x.isInterpolatedPt[sKey] && useInterpPts)) {
        const key = extractedAttribute as keyof DensePlotPt
        const values: number[] = x[key] as number[]
        x1 = values[sKey] * Bscale
      } else {
        return NaN
      }

      // Normalize to 'Stack100%'
      const x3 = (useStackNorm && x.stackMag !== 0) ? 100 * x1 / x.stackMag : x1
      // For log plots, B0 value CAN NEVER be <= 0;  Because log(0) = -Infinity
      // We pass some number (above) that is above zero, yet below the bottom axis B value.
      // legalZeroValue_preLog is set null by default.
      // legalZeroValue_preLog, IF SET, is always positive and represents
      // the lower bound of plotted pts.  MAY be the bottom axis B value,
      // or may be even lower.
      const x4 = (legalZeroValue_preLog && x3 <= legalZeroValue_preLog) ? legalZeroValue_preLog : x3
      // log10 or probNormal transform (3 choices)
      // This function is owned by the basis object.
      const x5 = nonlinearXform(x4)
      //console.log( x1, x5)
      return x5
    }
  }
}



const setGetLeftAndBottomTransforms = (renderedLayer: ReactLayer, isTransposed: boolean): void => {
  if (isTransposed) {
    renderedLayer.getLeft0 = renderedLayer.getA0
    renderedLayer.getLeft = renderedLayer.getA
    renderedLayer.getBottom0 = renderedLayer.getB0
    renderedLayer.getBottom = renderedLayer.getB
  } else {
    renderedLayer.getLeft0 = renderedLayer.getB0
    renderedLayer.getLeft = renderedLayer.getB
    renderedLayer.getBottom0 = renderedLayer.getA0
    renderedLayer.getBottom = renderedLayer.getA
  }
}





const createSmthFitDebugAnnotation = (plt: PlotXyComputedData, sKey: number, underLayer: ReactLayer, valueTypeRoot: DataNameRoot): {
  leftEdgePlotLine: ReactLayer
  rightEdgePlotLine: ReactLayer
  linFitPlotLine: ReactLayer
} => {
  // This debug function currently only designed for 'mean' or 'freq string Axis' data
  const { basisB, basisA } = plt
  const seedHisto = basisA.seedHisto!
  const { legalZeroValue_preLog } = basisB
  let seriesLineSmoothing = seedHisto.data[sKey].seriesLineSmoothing
  let { kernelWidthInNumBins, smoothingIndex, kernelWidthInA } =
    calcKernelWidthAndSmoothingIndex(seedHisto, seriesLineSmoothing)
  const linFitBinnedVal = seedHisto.data[sKey][valueTypeRoot].linFitBinnedVal
  const { Aindex_FirstCount, Aindex_FinalCount } = seedHisto.data[sKey]

  const linFitPlotPts = Array<BasicPlotPoint>()
  // Sometimes the linFitBinnedVals are logarithmic values (in case where we need a line in logarithmic plots).
  // In this case, easier to convert this 'line in log domain' to the corresponding value of the tick marks.
  // As is done in all other plotted data!
  const BscaleXform = (valueTypeRoot === 'meanLogB' || valueTypeRoot === 'varianceLogB')
    ? (x: number): number => Math.pow(10, x)
    : (x: number): number => x
  for (var i = Aindex_FirstCount; i <= Aindex_FinalCount; i++) {
    linFitPlotPts.push({ type: 'basic', A: getAvalue_From_Index(i, seedHisto), B: Math.max(BscaleXform(linFitBinnedVal[i]), (legalZeroValue_preLog ? legalZeroValue_preLog : -Infinity)) })
  }

  const linFitPlotLine = { ...underLayer }
  linFitPlotLine.description = 'linFit baseline for smooothing. Debugging line.'
  linFitPlotLine.color = '#e6194b'  // 'red' in our colorPicker
  linFitPlotLine.lineThickness = constantsFileLineThickness
  // linFitPlotLine.hasCrossHairs = false
  linFitPlotLine.getA = (d, _sKey) => basisA.nonlinearXform(d.A)
  linFitPlotLine.getB = (d, _sKey) => basisB.nonlinearXform(d.B)   // I don't support stacking. This is for debugging only!
  setGetLeftAndBottomTransforms(linFitPlotLine, plt.isTransposed)
  linFitPlotLine.plotPts = linFitPlotPts

  // This is the geometry for the two cyan colored lines summarizing
  // the antisymmetry point calculations.
  const antiSymmetryPts = seedHisto.data[sKey][valueTypeRoot].antiSymmetryPts[smoothingIndex]
  const { x1_index, x2_index, y1_val, y2_val,
    slope1_perBin, slope2_perBin } = antiSymmetryPts
  const x1 = getAvalue_From_Index(x1_index, seedHisto)
  const x2 = getAvalue_From_Index(x2_index, seedHisto)
  const x1plus = (seedHisto.willUseLogarithmicSeedHistoBins)
    ? x1 * Math.pow(10, kernelWidthInA)
    : x1 + kernelWidthInA
  const x2minus = (seedHisto.willUseLogarithmicSeedHistoBins)
    ? x2 / Math.pow(10, kernelWidthInA)
    : x2 - kernelWidthInA
  const Aindex_at_x1plus = getIndex_From_Avalue(x1plus, seedHisto)
  const Aindex_at_x2minus = getIndex_From_Avalue(x2minus, seedHisto)

  // The B 'linFitBinnedVal' values may be logarithms in case of meanLogB and varianceLogB
  var BlinFit_at_x1plus = linFitBinnedVal[Aindex_at_x1plus]
  var BlinFit_at_x2minus = linFitBinnedVal[Aindex_at_x2minus]
  var BlinFit_FirstPt = linFitBinnedVal[Aindex_FirstCount]
  var BlinFit_LastPt = linFitBinnedVal[Aindex_FinalCount]

  const leftEdgePlotPts: BasicPlotPoint[] = [
    { type: 'basic', A: x1, B: BscaleXform(BlinFit_FirstPt + y1_val) },
    { type: 'basic', A: x1plus, B: BscaleXform(BlinFit_at_x1plus + y1_val + kernelWidthInNumBins * slope1_perBin) }
  ]
  const rightEdgePlotPts: BasicPlotPoint[] = [
    { type: 'basic', A: x2, B: BscaleXform(BlinFit_LastPt + y2_val) },
    { type: 'basic', A: x2minus, B: BscaleXform(BlinFit_at_x2minus + y2_val + kernelWidthInNumBins * slope2_perBin) }
  ]

  const leftEdgePlotLine = { ...linFitPlotLine }
  leftEdgePlotLine.description = 'Left antisymmetricPt fit debugging line.'
  leftEdgePlotLine.plotPts = leftEdgePlotPts
  leftEdgePlotLine.color = 'cyan'
  const rightEdgePlotLine = { ...linFitPlotLine }
  rightEdgePlotLine.description = 'Right antisymmetricPt fit debugging line.'
  rightEdgePlotLine.plotPts = rightEdgePlotPts
  rightEdgePlotLine.color = 'cyan'

  return { leftEdgePlotLine, rightEdgePlotLine, linFitPlotLine }
}

