import {isValidUuid} from './isValidUuid'

//  HERE HERE !!
// See my notes about this function in ‘documentation/gettingDataInputOurApp.txt’



export type ScryHyperlink = {

  // The tableData value. May be created here from next to values below.
  // Or may be the input value which creates next to values below.
  // Both are the same info but two formats.
  // Either one defines the other.
  tableValue: string

  // The tableValue resource, split into label and url
  // Or the textWithLinks where links are already broken into these two terms.
  tableLabel: string
  tableUrl: string
  canonicalUrl: string

  // True if this is a link to an internal Scry resource
  // Canonical Form for internal links DOES NOT start with http
  // Canonical Form for external links ALWAYS starts with http
  // OriginalInputUrl may or may not include http.
  // We prepend http when needed (external links)
  // We delete http when undesired (internal links)
  isInternal: boolean
  internalResource: '' | 'Plot' | 'Table'

  // errorMsg nominally an empty string
  // IF missing both label AND canonicalUrl, this is NOT a link error; just an empty table cell
  errorMsg : string
}

export const getDefaultScryHyperlink = () : ScryHyperlink => {
  const obj: ScryHyperlink = {
    tableLabel: '',
    tableUrl: '',

    tableValue: '',
    canonicalUrl: '',

    isInternal: false,
    internalResource: '',
    errorMsg : '',
  }
  Object.seal( obj )
  return obj
}

// This function assumes a Hyperlink column's cell value will
// be forced into our hyperlink format.  It can find some errors, like
//    - missing URL completely
//    - has the http(s)://, however missing any subsequent path
//    - found a relative path, but malformed uuid
// But otherwise, we assume the path is valid.  Only the user testing
// their paths will verify a 'valid path'.
// Hence if we find random text, this function (default mode) inserts 'http://'  (assumes this is a link!)

export const isScryHyperlink_fromTableValue = ( inValue:string, prependHTTP:boolean=true ) : ScryHyperlink => {
  var isHyperLinkResult = getDefaultScryHyperlink()
  // Step 1) write tableValue (a single text string from our tableValues resource) to isHyperlink result
  // Step 2) Split result.tableValue and save results to tableLabel/tableUrl
  isHyperLinkResult.tableValue = inValue
  isHyperLinkResult.tableLabel = getHyperlinkLabel( isHyperLinkResult.tableValue )
  isHyperLinkResult.tableUrl   = getHyperlinkUrl( isHyperLinkResult.tableValue )
  // Step 3) Calc derived information:
  // canonicalUrl: '',
  // isInternal: false,
  // internalResource: '',
  // errorMsg : '',
  // errorType: '' ,
  isHyperLinkResult = errorCheckUrl( isHyperLinkResult, prependHTTP )
  return isHyperLinkResult
}


// We use this function to error check inputs from our own editor!  Where we have both
// a label and an URL.
export const isScryHyperlink_fromUserInputs = ( label:string , url:string, prependHTTP:boolean=true ) : ScryHyperlink => {
  var isHyperLinkResult = getDefaultScryHyperlink()
  // Step 1: write inputs to tableLabel & tableUrl
  // If tableUrl undefined, set it to empty string.
  isHyperLinkResult.tableLabel = label
  isHyperLinkResult.tableUrl = (isHyperLinkResult.tableUrl) ? '' : url
  // Step 2) Calc derived information:
  // canonicalUrl: '',
  // isInternal: false,
  // internalResource: '',
  // errorMsg : '',
  // errorType: '' ,
  isHyperLinkResult = errorCheckUrl( isHyperLinkResult, prependHTTP )
  // Step 3: generate the tableValue (merges label and canonicalUrl
  // If there is no label (yet or never) use the url as the label
  if ( isHyperLinkResult.tableLabel === '' ) {
    isHyperLinkResult.tableValue = `[[${isHyperLinkResult.tableUrl}]]((${isHyperLinkResult.canonicalUrl}))`
  } else {
    isHyperLinkResult.tableValue = `[[${isHyperLinkResult.tableLabel}]]((${isHyperLinkResult.canonicalUrl}))`
  }
  return isHyperLinkResult
}

/*
// We use this function to error check inputs from an external source (loaded *csv table).
// The external value MAY or PROBABLY NOT in our internal format.  But the proper code
// is to assume the input value (a string) IS IN OUR URL FORMAT!
// In which case, if it is NOT in our format (probably), we force it into our format by
// setting the label = inputString; And url = inputString.
// Then we test whether the url is valid or not.
export const isScryHyperlink_fromImportedCVSvalues = ( inputString:string ) : ScryHyperlink => {
  const label = getHyperlinkLabel( inputString )
  const url   = getHyperlinkUrl  ( inputString )
  // This next (3rd argument) is optional.  The default behavior is to force any
  // url that we cannot recognize into a valid from, by appending: 'http://'
  // However, in this application, we cannot assume the user intends this value
  // to be an URL.  Just the opposite!  We are asking whether a column of values
  // 'appears' to a column of hyperlinks.  So we will test for all the valid
  // hyperlink formats we support.  But if we don't match any supported formats,
  // then the assumption will be 'This is not a hyperlink'.
  const append_http_ifMissing = false // Normally, this defaults to True !
  return isScryHyperlink_fromUserInputs( label, url, append_http_ifMissing )
}
*/



// INTERNAL TABLE VALUE FORMAT:
//  "[[label text]]((url text))"
const label_re = new RegExp( '^\\[\\[(.*)\\]\\]\\(\\(' )
const url_re   = new RegExp( '\\]\\]\\(\\((.*)\\)\\)$' )

export const getHyperlinkLabel = (cellValue: string ) : string => {
  const m = cellValue.match( label_re )
  // When NOT written as a [[label]]((link)) format, assume the input value is the link && label===link
  return ( m && m[1] ) ? m[1] : cellValue
}

const getHyperlinkUrl = (cellValue: string ) : string => {
  const m = cellValue.match( url_re )
  // When written as [[label]](()) => (link input box is empty string)
  // return a linkUrl of ''  (empty string)
  if ( m && m[1] === '' ) return ''
  // When NOT written as a [[label]]((link)) format, assume the input value is the link
  return ( m && m[1] ) ? m[1] : cellValue
}



// NOTE:  IF YOU ONLY WANT TO ERROR CHECK A url:
// Use the above exported function:
//   let {errorMsg} = isScryHyperlink_fromUserInputs( 'do not care', urlString )

export const errorCheckUrl = (isScryHyperlinkResult: ScryHyperlink, appendHTTP:boolean=true ) : ScryHyperlink  => {
  const tableUrl = isScryHyperlinkResult.tableUrl
  var uuid = isScryHyperlinkResult.tableUrl.slice(-36)
  var doesEndInValidUuid = isValidUuid( uuid )
  isScryHyperlinkResult.isInternal = false // assumption

  // Starts with 'table' path:
  if (tableUrl.slice(0,6) === 'table/' || tableUrl.slice(0,7) === '/table/') {
    isScryHyperlinkResult.isInternal = true
    isScryHyperlinkResult.internalResource = 'Table'
    if (!doesEndInValidUuid) {
      isScryHyperlinkResult.errorMsg = 'Invalid format for table UUID'
      isScryHyperlinkResult.canonicalUrl = tableUrl
    } else {
      isScryHyperlinkResult.canonicalUrl = '/table/'+uuid
    }
    return isScryHyperlinkResult
  }

  // Starts with 'plot' path
  if (tableUrl.slice(0,5) === 'plot/' || tableUrl.slice(0,6) === '/plot/') {
    isScryHyperlinkResult.isInternal = true
    isScryHyperlinkResult.internalResource = 'Plot'
    if (!doesEndInValidUuid) {
      isScryHyperlinkResult.errorMsg = 'Invalid format for plot UUID'
      isScryHyperlinkResult.canonicalUrl = tableUrl
    } else {
      isScryHyperlinkResult.canonicalUrl = '/plot/'+uuid
    }
    return isScryHyperlinkResult
  }

  // ends with 'table/uuid'
  if (doesEndInValidUuid && tableUrl.slice(-42,-36) === 'table/') {
    isScryHyperlinkResult.canonicalUrl = '/table/'+uuid
    isScryHyperlinkResult.isInternal = true
    isScryHyperlinkResult.internalResource = 'Table'
    return isScryHyperlinkResult
  }

  // ends with 'plot/uuid'
  if (doesEndInValidUuid && tableUrl.slice(-41,-36) === 'plot/') {
    isScryHyperlinkResult.canonicalUrl = '/plot/'+uuid
    isScryHyperlinkResult.isInternal = true
    isScryHyperlinkResult.internalResource = 'Plot'
    return isScryHyperlinkResult
  }

  if ( tableUrl.length === 0 ) {
    isScryHyperlinkResult.errorMsg = 'Missing the URL.'
    isScryHyperlinkResult.canonicalUrl = tableUrl
    return isScryHyperlinkResult
  }
  if (tableUrl.match( /^http:\/\// )  && tableUrl.length < 8 ) {
    isScryHyperlinkResult.errorMsg = 'Missing the URL path.'
    isScryHyperlinkResult.canonicalUrl = tableUrl
    return isScryHyperlinkResult
  }
  if (tableUrl.match( /^https:\/\// ) && tableUrl.length < 9 ) {
    isScryHyperlinkResult.errorMsg = 'Missing the URL path.'
    isScryHyperlinkResult.canonicalUrl = tableUrl
    return isScryHyperlinkResult
  }

  // If we find an initial 'http://' or 'https://' then no errors!
  if ( tableUrl.match( /^https?:\/\// ) ) {
    isScryHyperlinkResult.canonicalUrl = tableUrl
    return isScryHyperlinkResult    // NO ERROR!
  }

  // Below this point we have two choices;
  // 1) Parent function expects a url (colDataType is a hyperlink).  THEN append http://
  // 2) Parent function is asking whether colDataType is a hyperlink.  And we ask
  //    this question by looking at the values and asking 'Do they look like hyperlinks?'
  //    In this case we DO NOT append 'http://, but instead return a error that this does
  //    not look like it was intended to be a hyperlink.

  if ( appendHTTP ) {
    isScryHyperlinkResult.canonicalUrl = 'http://' + tableUrl
    return isScryHyperlinkResult  // NO ERROR!
  }

  // ELSE:  appendHTTP === false     Does not look like a hyperlink.

  isScryHyperlinkResult.errorMsg = `Missing a leading 'http(s)://'.`
  isScryHyperlinkResult.canonicalUrl = tableUrl
  return isScryHyperlinkResult




/*

  // Any inputs reaching this point in the flow are 'external links'
  // CanonicalForm MUST begin with 'http://' or 'https://'
  if ( tableUrl.match( /^https?:\/\// ) ) {
    isScryHyperlinkResult.canonicalUrl = tableUrl
  } else {
    isScryHyperlinkResult.canonicalUrl = 'http://' + tableUrl
  } */

}
