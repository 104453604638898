import type { PlotWidthObj, PlotHeightObj, PlotStyleObj } from '../computedDataPlotXY/xy_plotCalculator'


const matchList = (coord: number, matchList: object): { key: string, offset: number, dimension: number } => {
  let lastOffset: number = 0
  let lastDimension: number = 0
  let dimension: number, offset:number
  let output = {
    key: '',
    offset: 0,
    dimension: 0,
  }
  Object.entries(matchList).every(
    ([key, value]) => {
      dimension = Number(value)
      offset = lastOffset + lastDimension
      if (coord < offset + dimension) {
        output = { key, offset, dimension }
        return false
      }
      lastOffset = offset
      lastDimension = dimension
      return true
    }
  )
  return output
}

type Inv = {
  reactVisLegendHeight: number
  reactVisGapLegendPlot: number
  reactVisPlottedDataHeight: number
  reactVisBottomAxisHeight: number
  reactVisHeight: number
  reactVisTop: number
  reactVisPlottedDataTop: number
  reactVisPlottedDataBottom: number
  reactVisBottom: number
  reactVisLeft: number

  leftMarginPlusCenteringGap: number
  reactVisLeftAxisWidth: number
  reactVisPlottedDataWidth: number
  reactVisOverhangOfLastBottomAxisTickLabel: number
  reactVisHalfOfLastBottomAxisTickLabel: number
  reactVisOverhangOfFirstBottomAxisTickLabel: number
  reactVisWidth: number
  reactVisPlottedDataLeft: number
  reactVisPlottedDataRight: number
  reactVisRight: number
  reactVisBottomAxisLeft: number
  reactVisBottomAxisRight: number
  reactVisBottomAxisWidth: number
}

const getDefaultInv = (): Inv => ({
  reactVisLegendHeight: 0,
  reactVisGapLegendPlot: 0,
  reactVisPlottedDataHeight: 0,
  reactVisBottomAxisHeight: 0,
  reactVisHeight: 0,
  reactVisTop: 0,
  reactVisPlottedDataTop: 0,
  reactVisPlottedDataBottom: 0,
  reactVisBottom: 0,
  reactVisLeft: 0,
  leftMarginPlusCenteringGap: 0,
  reactVisLeftAxisWidth: 0,
  reactVisPlottedDataWidth: 0,
  reactVisOverhangOfLastBottomAxisTickLabel: 0,
  reactVisHalfOfLastBottomAxisTickLabel: 0,
  reactVisOverhangOfFirstBottomAxisTickLabel: 0,
  reactVisWidth: 0,
  reactVisPlottedDataLeft: 0,
  reactVisPlottedDataRight: 0,
  reactVisRight: 0,
  reactVisBottomAxisLeft: 0,
  reactVisBottomAxisRight: 0,
  reactVisBottomAxisWidth: 0,
})
// The plot Calculator maps the reactVisArea in terms of the SVG pixels, NOT screen pixels.
// For the SVG plot, the plotCalculator works only in svg pixels.
// The svg is then scaled in both x,y (s.reactVisScale), and translated to center the plot.
// However, for the PlotFocalPlane component, the required coordinate system is screen pixels.
// The conversion from the plotCalcular svg pixels to the FocalPlane screen pixels is done
// in this function:
const calcPlotCoordsInScreenPx = (w: PlotWidthObj, h: PlotHeightObj, s: PlotStyleObj): Inv => {
  const inv = getDefaultInv()
  inv.reactVisLegendHeight = s.reactVisScale * h.legend
  inv.reactVisGapLegendPlot = s.reactVisScale * h.gapLegendPlot
  inv.reactVisPlottedDataHeight = s.reactVisScale * h.plottedData
  inv.reactVisBottomAxisHeight = s.reactVisScale * h.totalBottomAxis
  inv.reactVisHeight = inv.reactVisLegendHeight + inv.reactVisPlottedDataHeight + inv.reactVisBottomAxisHeight

  // Top coordinates, from top to bottom
  inv.reactVisTop = h.topMargin + h.mainTitle + h.publisherTitle + h.gapPublisherLegend + h.centeringGap
  inv.reactVisPlottedDataTop = inv.reactVisTop + inv.reactVisLegendHeight + inv.reactVisGapLegendPlot
  inv.reactVisPlottedDataBottom = inv.reactVisPlottedDataTop + inv.reactVisPlottedDataHeight
  inv.reactVisBottom = inv.reactVisPlottedDataBottom + inv.reactVisBottomAxisHeight

  // SVG from left to right
  inv.reactVisLeft = w.leftMarginPlusCenteringGap // TODO: Ask JPS if this is right
  inv.leftMarginPlusCenteringGap = w.leftMarginPlusCenteringGap
  inv.reactVisLeftAxisWidth = s.reactVisScale * w.totalLeftAxis
  inv.reactVisPlottedDataWidth = s.reactVisScale * w.plottedData
  inv.reactVisOverhangOfLastBottomAxisTickLabel = s.reactVisScale * w.overhangOfLastBottomAxisTickLabel
  inv.reactVisHalfOfLastBottomAxisTickLabel = 0.5 * inv.reactVisOverhangOfLastBottomAxisTickLabel // TODO Ask JPS if this is right
  inv.reactVisOverhangOfFirstBottomAxisTickLabel = s.reactVisScale * w.overhangOfFirstBottomAxisTickLabel

  // Total SVG width
  inv.reactVisWidth = inv.reactVisLeftAxisWidth + inv.reactVisPlottedDataWidth // + inv.reactVisRightWidth TODO Ask JPS if there should be reactVisRightWidth

  // Plotted Data left/right coordinates
  inv.reactVisPlottedDataLeft = inv.leftMarginPlusCenteringGap + inv.reactVisLeftAxisWidth
  inv.reactVisPlottedDataRight = inv.reactVisPlottedDataLeft + inv.reactVisPlottedDataWidth
  inv.reactVisRight = inv.leftMarginPlusCenteringGap + inv.reactVisWidth

  // SVG bottomAxis left/Right coordinates:
  inv.reactVisBottomAxisLeft = inv.reactVisPlottedDataLeft - inv.reactVisOverhangOfFirstBottomAxisTickLabel
  inv.reactVisBottomAxisRight = inv.reactVisPlottedDataRight + inv.reactVisOverhangOfLastBottomAxisTickLabel
  inv.reactVisBottomAxisWidth = inv.reactVisBottomAxisRight - inv.reactVisBottomAxisLeft
  return inv
}


// Given a name:
//   'titleMain', 'publisherMain', 'legend', 'leftAxis', 'bottomAxis'
// plus the seriesKey and seriesOrder (for legend) and the w,h,s objects:
// Return the geometric locations {top, left, height, width} that will be
// used for the highlighter outline when selected.
export const xy_plotCalculator_nameToCellObj = (name: string, seriesKey: number, seriesOrder: number[],
  w: PlotWidthObj, h: PlotHeightObj, s: PlotStyleObj): { top: number, left: number, height: number, width: number } => {

  let returnValue = { top: 0, left: 0, width: 0, height: 0 }
  let inv: Inv
  let rowIndex:number, colIndex:number, startLegendLeft:number, foundIndex:number

  switch (name) {
    case 'mainTitle':
      returnValue = {
        top: h.topMargin,
        left: w.mainTitleLeft,
        width: w.mainTitle,
        height: h.mainTitle,
      }
      break

    case 'publisherTitle':
      returnValue = {
        top: h.topMargin + h.mainTitle,
        left: w.publisherTitleLeft,
        width: w.publisherTitle,
        height: h.publisherTitle,
      }
      break

    case 'leftAxis':
      inv = calcPlotCoordsInScreenPx(w, h, s)
      returnValue = {
        top: inv.reactVisPlottedDataTop - (inv.reactVisPlottedDataHeight * .05 * s.reactVisScale),
        left: w.leftMarginPlusCenteringGap,
        width: inv.reactVisLeftAxisWidth,
        height: inv.reactVisPlottedDataHeight + (inv.reactVisPlottedDataHeight * .1 * s.reactVisScale),
      }
      break

    case 'bottomAxis':
      inv = calcPlotCoordsInScreenPx(w, h, s)
      returnValue = {
        top: inv.reactVisPlottedDataBottom,
        height: inv.reactVisBottomAxisHeight,
        left: inv.reactVisBottomAxisLeft,
        width: inv.reactVisBottomAxisWidth,
      }
      break

    case 'legend':
      inv = calcPlotCoordsInScreenPx(w, h, s)
      rowIndex = 1 // assumption
      colIndex = 1 // assumption
      startLegendLeft = inv.reactVisPlottedDataLeft + (w.legendGapBetweenItems / 2 * s.reactVisScale)  // assume first legend
      w.legendSeriesKeys.forEach((thisRowsKeys, i) => {
        foundIndex = thisRowsKeys.indexOf(seriesKey)
        if (foundIndex >= 0) {
          rowIndex = i
          colIndex = foundIndex
          // Calc the starting 'left' position for this legend
          const legendWidthsForThisRow = w.legendWidths[rowIndex]
          for (let j = 0; j < colIndex; j++) {
            startLegendLeft += (legendWidthsForThisRow[j] + w.legendGapBetweenItems) * s.reactVisScale
          }
        }
      })
      if (rowIndex >= 0 || colIndex >= 0) {
        returnValue = {
          top: inv.reactVisTop + (w.legendGapBetweenItems / 4 * s.reactVisScale) + (rowIndex * h.legendRowHeight * s.reactVisScale),
          left: startLegendLeft + (w.legendGapBetweenItems / 4 * s.reactVisScale),
          width: (w.legendWidths[rowIndex][colIndex] + w.legendGapBetweenItems / 2) * s.reactVisScale,
          height: (h.legendRowHeight - w.legendGapBetweenItems / 2) * s.reactVisScale,
        }
      }
      break

    default:

  }

  // mainTitle, publisherTitle, and bottomAxis could all extend beyond viewable area!
  // We could be smarter about defining the bottomAxis extents.  But we still need
  // this code to handle titles that are too wide.  So easier to handle the
  // bottomAxis here, using the same rules as applied to the title widths.

  // Constrain the selection box to such that all left/right sides fall within
  // the viewable area.  At this time, only needed for left/width direction.
  // But could easily be extended to the top/height direction.
  if (returnValue.left < 5) {
    returnValue.width = returnValue.width - (5 - returnValue.left)
    returnValue.left = 5
  }
  if (returnValue.left + returnValue.width > w.focalPlanePx - 5) {
    returnValue.width = w.focalPlanePx - 5 - returnValue.left
  }

  return returnValue
}




// Given a (x,y) point, and the widthObj, and heightObj,
// find the corresponding location in the layout.
// This is the object we will set and return!
const outDefault = {
  name: 'empty',
  seriesKey: -1,
  cellTop: 0,
  cellLeft: 0,
  cellWidth: 0,
  cellHeight: 0,
  localX: 0,
  localY: 0
}
Object.seal(outDefault)

export type PlotFocusID = typeof outDefault

export const xy_plotCalculatorInv = (x:number, y:number, w:PlotWidthObj, h:PlotHeightObj, s:PlotStyleObj): PlotFocusID => {

  // The plot Calculator maps the reactVisArea in terms of the SVG pixels, NOT screen pixels.
  // Cleaner if we keep the plotCalculator written in SVG pixels, but we need
  // equivalent screen pixels for this inverse function.  Do all the screen pixels
  // calculations here (rather than in the plot calculator.)

  const inv = calcPlotCoordsInScreenPx(w, h, s)
  const out = { ...outDefault }

  const CROSSHAIRS_OVERLAP_TOLERANCE = 5

  // First check is whether we are hovering over the plotted data
  // area.  This supports the Crosshairs component when hovering over
  // the raw plotted data.  It is the only check that is time sensitive
  // and the only reason it comes first.

  // We want the hoverable CrossHairs area to extend slightly beyond
  if (y + CROSSHAIRS_OVERLAP_TOLERANCE >= inv.reactVisPlottedDataTop &&
    y - CROSSHAIRS_OVERLAP_TOLERANCE <= inv.reactVisPlottedDataBottom &&
    x + CROSSHAIRS_OVERLAP_TOLERANCE >= inv.reactVisPlottedDataLeft &&
    x - CROSSHAIRS_OVERLAP_TOLERANCE <= inv.reactVisPlottedDataRight) {

    out.name = 'plottedData'
    out.seriesKey = -1
    out.localX = (x - inv.reactVisTop) / s.reactVisScale
    out.localY = (y - inv.reactVisLeft) / s.reactVisScale
    out.cellTop = inv.reactVisPlottedDataTop - CROSSHAIRS_OVERLAP_TOLERANCE
    out.cellLeft = inv.reactVisPlottedDataLeft - CROSSHAIRS_OVERLAP_TOLERANCE
    out.cellHeight = inv.reactVisPlottedDataHeight + 2 * CROSSHAIRS_OVERLAP_TOLERANCE
    out.cellWidth = inv.reactVisPlottedDataWidth + 2 * CROSSHAIRS_OVERLAP_TOLERANCE
    return out
  }



  // Layout heights, from top to bottom:
  const heightList = {
    topMargin: h.topMargin,
    mainTitle: h.mainTitle,
    publisherTitle: h.publisherTitle,
    gapPublisherLegend: h.gapPublisherLegend,
    centeringGapTop: h.centeringGap,
    reactVisLegendHeight: inv.reactVisLegendHeight,
    reactVisGapLegendPlot: inv.reactVisGapLegendPlot,
    reactVisPlottedDataHeight: inv.reactVisPlottedDataHeight,
    reactVisBottomAxisHeight: inv.reactVisBottomAxisHeight,
    centeringGapBottom: h.centeringGap,
    bottomMargin: h.bottomMargin
  }

  // Layout widths, from left to right:
  const widthList = {
    centeringGapLeft: w.leftMarginPlusCenteringGap,
    reactVisLeftAxisWidth: inv.reactVisLeftAxisWidth,
    reactVisPlottedDataWidth: inv.reactVisPlottedDataWidth,
    reactVisHalfOfLastBottomAxisTickLabel: inv.reactVisHalfOfLastBottomAxisTickLabel,
    centeringGapRight: w.centeringGap
  }


  const { key: hKey, offset: top, dimension: height } = matchList(y, heightList)
  const { key: wKey, offset: left, dimension: width } = matchList(x, widthList)
  out.localX = x
  out.localY = y
  out.seriesKey = -1

  // If we are within the reactVis area, calc the local X,Y:
  //if (false && y >= inv.reactVisTop && y <= inv.reactVisBottom && x >= inv.reactVisLeft && x <= inv.reactVisRight) {
  //  out.localX = (x - inv.reactVisLeft) / s.reactVisScale
   // out.localY = (y - inv.reactVisTop) / s.reactVisScale
 // }

  if (hKey === 'mainTitle' && x >= w.mainTitleLeft && x <= w.mainTitleLeft + w.mainTitle) {
    out.name = 'mainTitle'
    out.cellTop = top
    out.cellLeft = w.mainTitleLeft
    out.cellWidth = w.mainTitle
    out.cellHeight = h.mainTitle
    return out
  }
  if (hKey === 'publisherTitle' && x >= w.publisherTitleLeft && x <= w.publisherTitleLeft + w.publisherTitle) {
    out.name = 'publisherTitle'
    out.cellTop = top
    out.cellLeft = w.publisherTitleLeft
    out.cellWidth = w.publisherTitle
    out.cellHeight = h.publisherTitle
    return out
  }
  if (hKey === 'reactVisBottomAxisHeight' && wKey === 'reactVisPlottedDataWidth') {
    out.name = 'bottomAxis'
    out.cellTop = top
    out.cellLeft = left
    out.cellWidth = width
    out.cellHeight = height
    return out
  }
  if (hKey === 'reactVisPlottedDataHeight' && wKey === 'reactVisLeftAxisWidth') {
    out.name = 'leftAxis'
    out.cellTop = top
    out.cellLeft = left
    out.cellWidth = width
    out.cellHeight = height
    return out
  }
  if (hKey === 'reactVisLegendHeight' && wKey === 'reactVisPlottedDataWidth') {
    out.name = 'empty'   // assumption
    const scaledRowHeight = h.legendRowHeight * s.reactVisScale
    const rowIndex = Math.floor((y - top) / scaledRowHeight)
    // Don't identify any space below the know number of lines as an addition line!
    if (rowIndex > h.numLegendRows - 1) { return out }
    out.cellTop = top + rowIndex * scaledRowHeight
    out.cellHeight = scaledRowHeight
    out.cellLeft = 0
    out.cellWidth = 0
    const legendWidthsForThisRow = w.legendWidths[rowIndex]
    let stopLegendLocation = inv.reactVisPlottedDataLeft + w.legendGapBetweenItems * s.reactVisScale / 2
    legendWidthsForThisRow.forEach((thisWidth, i) => {
      const startLegendLocation = stopLegendLocation
      stopLegendLocation += (thisWidth + w.legendGapBetweenItems) * s.reactVisScale
      if (x >= startLegendLocation && x <= stopLegendLocation) {
        out.cellLeft = startLegendLocation
        out.cellWidth = stopLegendLocation - startLegendLocation
        out.seriesKey = w.legendSeriesKeys[rowIndex][i]
        out.name = 'legend'
      }
    })
    // Above algorithm MAY NOT find a valid legend selection.
    // Only return here if name was changed from 'empty' to 'legend'
    if (out.name === 'legend') return out
  }

  // xy is NOT over a valid selection area:
  return out
}
