import React, { PureComponent } from 'react'
import type { JSX } from 'react'

class FormGroup extends PureComponent<{
  children: JSX.Element | JSX.Element[]
}> {
  render () {
    const {children} = this.props
    return (
      <div className={'rc_FormGroup'}
        style={{
          margin: '15px 0',
        }}
      >
        {children}
      </div>
    )
  }
}

export default FormGroup
