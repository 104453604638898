const joi = require('joi')


const Axis = {
  axisTitle: joi.string().allow(''),
  axisSubTitle: joi.string().allow(null),
  labelAngle: joi.number(),
  fontScaleTickValues: joi.number(),
  usersMinDomain: joi.string().allow('').description('String representation of a valid js Number'),
  usersMaxDomain: joi.string().allow('').description('String representation of a valid js Number'),
  isLogarithmic: joi.boolean().description('Boolean flag is this scale logarithmic'),
  isPercentileNormalProb: joi.boolean().description('Boolean flag to use NormalProb scale for percentile plots'),
  isHistogramPercent: joi.boolean().description('Boolean flag to scale freq counts to percent of series counts for histograms'),
  isMinDomainAuto: joi.boolean().description('Scale min value based on range or users input.'),
  isMaxDomainAuto: joi.boolean().description('Scale max value based on range or users input.'),
  stringOrder: joi.string().valid( 'alphabetical','1stSeriesValue','allSeriesValues')
    .description('If a string axis, sort the strings alphabetically, or some 1st series statistic, or sum a all series statistics'),
  stringOrderDirection: joi.string().valid( 'descending', 'ascending' )
    .description('If a string axis, order by ascending or descending direction'),
}


const FilterRule = {
    colKey: joi.number().integer()
      .description('Column ID for this filter rule'),
    enabled: joi.boolean().description('Boolean flag indicating if this rule should be applied.'),
    key: joi.number().integer().description('Unique (within this list of row filters) number to identify this filter rule'),
    relation: joi.string().valid('unset','', '<', '<=', '<>', '=', '==', '>', '>=', 'all', 'any', 'none')
      .description('Contextual Query Language (CQL) relation to use when filtering the cells of this the column identified by colKey'),
    value: joi.string().allow('').allow(null)
      .description('space separated list of terms for the specified relation')
  }


const plotAttributes = {
  mainTitle: joi.string().allow('')
    .description(`The plot's main title`),
  publisherTitle: joi.string().allow('')
    .description(`The plot's publisher title`),
  plotDescription: joi.object().keys({
    text: joi.string().allow(''),
    links: joi.array().items(joi.object().keys({
      startCharIndex: joi.number().integer(),
      stopCharIndex: joi.number().integer(),
      href: joi.string().allow('')
    }))
  }),
  updatedDate: joi.date().iso().allow(null).description('Date plot last modified.'),
  aspectRatio: joi.number(),
  oopsFlag: joi.boolean().allow(null).description('Boolean flag indicating if this plot is experiencing an oops.'),

  renderedLayersArr: joi.array()
    .items(joi.string()).allow(null)
    .description('Strings encoding a set of renderedLayers.'),

  histogramBinIndex: joi.number().integer(),
  bottomCanvasOpacity: joi.number(),

  fontScaleTitle: joi.number(),
  fontScalePublisher: joi.number(),
  fontScaleLegend: joi.number(),
  fontScaleAxisNames: joi.number(),

  seriesOrder: joi.array()
    .items(joi.number().integer())
    .description('Array of series ids in the order they should display.'),
  commonSeriesFilter: joi.array().items(  joi.object().keys(FilterRule).allow(null)  ).allow(null),
  minorState: joi.object().keys({
    isSideBarVisible: joi.boolean().description('is sideBar open/closed?'),
    sideBarTabIndex: joi.number().integer(),
    sideBarSeriesKeyExpanded: joi.number().integer(),
    isEditorOpen: joi.boolean(),
    cellEditor_left: joi.number(),
    cellEditor_top : joi.number(),
    selection: joi.object().keys({
        name: joi.string().valid('basisA', 'basisB', 'basisC', 'legend', 'mainTitle', 'publisherTitle', '' ),
        seriesKey: joi.number().integer(),
      }).description('selected legend seriesKey in a table.'),
  }),
  series: joi.array().items(joi.object().keys({
      color: joi.string(),
      markSize: joi.number(),
      markShape: joi.string().valid('', 'circle', 'diamond', 'plus', 'square', 'star', 'triangle'),
      seriesOpacity: joi.number(),
      seriesKey: joi.number().integer(),
      seriesTitle: joi.string().allow(''),
      seriesSamplingDensity: joi.number(),

      seriesDescription: joi.object().keys({
        text: joi.string().allow(''),
        links: joi.array().items(joi.object().keys({
          startCharIndex: joi.number().integer(),
          stopCharIndex: joi.number().integer(),
          href: joi.string().allow('')
        }))
      }),
      seriesFilter: joi.array().items(joi.object().keys(FilterRule).allow(null)).allow(null),
      sortBy: joi.string().valid('valueA', 'valueColKey', 'unset'),
      sortByColKey: joi.number().integer(),
      colKeyA: joi.number().integer(),
      colKeyB: joi.number().integer(),
      colKeyC: joi.number().integer(),
      seriesLineSmoothing: joi.number(),
    }).allow(null))
    .description('Definition for series displayed on the plot.'),
  plotColDataType: joi.string(),
  basisA: joi.object().keys(Axis)
    .description('Definition for the axis nominally mapped to plotPoints A.'),
  basisB: joi.object().keys(Axis)
    .description('Definition for the 1st axis nominally mapped to plotPoints B.'),
  basisC: joi.object().keys(Axis)
    .description('Definition for the 2nd optional axis nominally mapped to plotPoints C.'),
  isTransposed: joi.boolean().description('is dataA mapped to basisA or basisB'),
  isMirrored:   joi.boolean().description('is dataB mapped to basisB or basisC'),
  // server side managed data (read only to clients)
  createdDate: joi.date().iso().allow(null).meta('readonly').description('Read only date plot created.'),
}

module.exports = plotAttributes
