import type { Plot, PlotColDataType } from '../types'
import type { TableComputedData } from '../computedDataTable/getDefaultTableComputedData'
import type { PlotXyComputedData } from './xy_plotTypes'

import { findClosestNamedColor } from '../sharedComponents/ShapeAndColorPicker'
import { getFormattingObj } from '../sharedFunctions/numberFormat'
import { getClosestEnumeratedValue } from '../sharedFunctions/utils'
import { plotLayoutConsts } from '../viewPlotXY/plotXyLayoutConsts'
import {
  create_sKeyArrStrg_from_seriesOrder,
  mapBasisToAxis,
  parseRenderedLayerID,
  ruleForBasisDataTypeAndFormatValues0,
  validLineSmoothing
} from './plotUtils'
import {
  getDefaultPlotXyComputedAxis,
  getDefaultPlotXyComputedData,
  getDefaultPlotXyComputedSeriesAttributes
} from './xy_plotTypes'


const getDefaultRenderedLayersArr = (plotColDataType: PlotColDataType, seriesOrder: number[]): string[] => {
  const seriesStrg = create_sKeyArrStrg_from_seriesOrder(seriesOrder)
  if (plotColDataType === '2Col') { return [`tableRow:marks:${seriesStrg}`] }
  if (plotColDataType === '1Col') { return [`freq:marks:${seriesStrg}`] }
  return [`tableRow:line:${seriesStrg}`]
}

export const initializePlotXyComputedData = (plot: Plot, tableComputedData: TableComputedData,
  menuOption_isEditMode: boolean, username: string, DEBUG: boolean): PlotXyComputedData => {

  const p = getDefaultPlotXyComputedData(plot) // sealed object! Transfers those plotAttributes as needed.
  //const isSideBarVisible = plot.attributes.minorState.isSideBarVisible
  const { tableHeight, tableWidth, pinnedRowKeys, tableid, tablelookid, derivedColOrder,
    derivedColAttributesArray, } = tableComputedData
  const numTableColumns = derivedColAttributesArray.length
  p.DEBUG = DEBUG
  p.canEdit = (tableComputedData.userid === plot.relationships?.owner?.data.id && menuOption_isEditMode)

  p.derivedColAttributesArray = derivedColAttributesArray
  p.focalPlaneHeightPx = tableHeight
  p.focalPlaneWidthPx = tableWidth
  p.pinnedRowKeys = pinnedRowKeys
  p.tableid = tableid
  p.tablelookid = tablelookid
  //p.isSideBarVisible = isSideBarVisible
  p.aspectRatio = getClosestEnumeratedValue(plot.attributes.aspectRatio, plotLayoutConsts.aspectRatioOptions)
  const { mainTitle, publisherTitle } = plot.attributes
  p.mainTitle = plot.attributes.mainTitle
  p.publisherTitle = plot.attributes.publisherTitle
  p.mainTitleDisplayed =
    (!mainTitle || mainTitle.length === 0) ? 'Missing A Plot Title' : mainTitle
  p.publisherTitleDisplayed =
    (!publisherTitle || publisherTitle.length === 0) ? username : publisherTitle
  p.userDataBaseKeyCols = derivedColOrder.filter(
    (colKey: number): boolean => derivedColAttributesArray[colKey].isKey
  )
  p.userDataBaseKeyColTitles = derivedColOrder.map(
    thisKey => derivedColAttributesArray[thisKey].colTitle
  )
  p.horizontalGridLines = {
    tickVisValues: [0, 1],
    gridColor: plotLayoutConsts.nominalGridColor
  }
  p.verticalGridLines = {
    tickVisValues: [0, 1],
    gridColor: plotLayoutConsts.nominalGridColor
  }
  p.commonSeriesFilter = plot.attributes.commonSeriesFilter
  const isTransposed = p.isTransposed = plot.attributes.isTransposed
  const isMirrored = p.isMirrored = plot.attributes.isMirrored
  const basisA = p.basisA = getDefaultPlotXyComputedAxis(plot.attributes.basisA)
  const basisB = p.basisB = getDefaultPlotXyComputedAxis(plot.attributes.basisB)
  p.basisC = getDefaultPlotXyComputedAxis(plot.attributes.basisC)

  // In out engineering interface, we allow a '1Col' plot to be made '2Col' plot resource object.
  // But if this is the case, we should override the basisB.isMaxDomainAuto to ALWAYS be true
  if (p.plotColDataType === '1Col') {
    p.basisB.isMaxDomainAuto = true
    p.basisB.isMinDomainAuto = true
  }

  //////////////////////////////////////////////////////////////////////////
  //   Some common 'if' questions;  Makes the code easier to read
  //////////////////////////////////////////////////////////////////////////

  const { plotColDataType, seriesOrder } = p
  if (p.renderedLayersArr.length === 0) {
    p.renderedLayersArr = getDefaultRenderedLayersArr(plotColDataType, seriesOrder)
  }
  const { plottedValue, plottedValueRoot } = parseRenderedLayerID(p.renderedLayersArr[0])
  p.isHisto = plottedValueRoot === 'freq'
  p.isPercentile = (plottedValue === 'percentile')
  p.isStacked_anyLayer = false  // assumption
  p.isStacked100Percent_allLayers = false  // assumption
  for ( const thisRenderedLayerID of p.renderedLayersArr ) {
      const { visTypeUser } = parseRenderedLayerID(thisRenderedLayerID)
      if (visTypeUser === 'stackedBars' || visTypeUser === 'stackedArea') {
        p.isStacked_anyLayer = true
      }
      if (visTypeUser === 'stackedBars100%' || visTypeUser === 'stackedArea100%') {
        p.isStacked100Percent_allLayers = true
      }
  }

  //////////////////////////////////////////////////////////////////////////
  //   Set the series specific attributes.
  //////////////////////////////////////////////////////////////////////////

  for ( const [sKey, thisSeries] of plot.attributes.series.entries() ) {
      const { colKeyA, colKeyB, colKeyC, sortBy, sortByColKey } = thisSeries
      const s = p.seriesAttributesArray[sKey] = getDefaultPlotXyComputedSeriesAttributes() // sealed obj.
      s.colKeyA = (colKeyA < 0 || colKeyA >= numTableColumns) ? -1 : colKeyA   // A is nominally position on bottom axis
      s.colKeyB = (colKeyB < 0 || colKeyB >= numTableColumns) ? -1 : colKeyB   // B is nominally position on left/right axis
      s.colKeyC = (colKeyC < 0 || colKeyC >= numTableColumns) ? -1 : colKeyC   // C is nominally show by marker size, etc.
      s.sortBy = sortBy
      s.sortByColKey = sortByColKey

      s.isNoData = undefined
      s.isOutOfView = false
      s.isDataTypeMismatch = false

      // For unset, deleted, and dataTypeMismatch,
      // For the user entry interface we need three flags (colKeyA, colKeyB, sortByColKey)
      // For the plot legend, we need only one composite flag. For example
      // 'isUnsetSeries' is true IF any of colKeyA, colKeyB, sortByColKey are 'unset'
      s.isUnsetSeriesColKeyA = (s.colKeyA === -1)
      s.isUnsetSeriesColKeyB = (s.colKeyB === -1)
      s.isUnsetSeriesSortByColKey = (s.sortByColKey === -1)
      s.isDeletedTableColKeyA = (s.colKeyA >= 0)
        ? derivedColAttributesArray[s.colKeyA].isDeleted
        : false   // colKeyA is 'unset' not 'isDeleted'
      s.isDeletedTableColKeyB = (s.colKeyB >= 0)
        ? derivedColAttributesArray[s.colKeyB].isDeleted
        : false
      s.isDeletedTableSortByColKey = (s.sortByColKey >= 0)
        ? derivedColAttributesArray[s.sortByColKey].isDeleted
        : false

      switch (plotColDataType) {
        case '3Col':
          s.isDeletedCol = s.isDeletedTableColKeyA || s.isDeletedTableColKeyB || s.isDeletedTableSortByColKey
          s.isUnsetSeriesColKey = s.isUnsetSeriesColKeyA || s.isUnsetSeriesColKeyB || s.isUnsetSeriesSortByColKey
          break
        case '2Col':
          s.isDeletedCol = s.isDeletedTableColKeyA || s.isDeletedTableColKeyB
          s.isUnsetSeriesColKey = s.isUnsetSeriesColKeyA || s.isUnsetSeriesColKeyB
          break
        case '1Col':
          s.isDeletedCol = s.isDeletedTableColKeyA
          s.isUnsetSeriesColKey = s.isUnsetSeriesColKeyA
          break
        default:
          s.isDeletedCol = s.isDeletedTableColKeyA
    }

    s.internalDataTypeA = s.colKeyA === -1 ? 'number' : derivedColAttributesArray[s.colKeyA].internalDataType
    s.internalDataTypeB = s.colKeyB === -1 ? 'number' : derivedColAttributesArray[s.colKeyB].internalDataType
    s.internalDataTypeC = s.colKeyC === -1 ? 'number' : derivedColAttributesArray[s.colKeyC].internalDataType

    // If this is a distribution plot, then override colKeyB dataType.  FORCE it to 'number'
    // This may happen, but only if/when we allow the user to make a distribution plot from an XY defined plot.
    if (plotColDataType === '1Col') {
      s.internalDataTypeB = 'number'
    }

    s.formatRuleA = s.colKeyA === -1 ? 'defaultEng' : derivedColAttributesArray[s.colKeyA].formatRule
    s.formatRuleB = s.colKeyB === -1 ? 'defaultEng' : derivedColAttributesArray[s.colKeyB].formatRule
    s.formatRuleC = s.colKeyC === -1 ? 'defaultEng' : derivedColAttributesArray[s.colKeyC].formatRule
    s.formattingObjA = s.colKeyA === -1 ? getFormattingObj('defaultEng') : derivedColAttributesArray[s.colKeyA].formattingObj
    s.formattingObjB = s.colKeyB === -1 ? getFormattingObj('defaultEng') : derivedColAttributesArray[s.colKeyB].formattingObj
    s.formattingObjC = s.colKeyC === -1 ? getFormattingObj('defaultEng') : derivedColAttributesArray[s.colKeyC].formattingObj;
    ({ value: s.color, colorTitle: s.colorTitle } = findClosestNamedColor(thisSeries.color))
    s.markSize = thisSeries.markSize
    s.seriesOpacity = thisSeries.seriesOpacity
    s.markShape = thisSeries.markShape
    s.seriesSamplingDensity =
      // We expect an enumerated valid option.  If we can't find it, default to 1 (100%).
      (plotLayoutConsts.samplingDensityOptions.indexOf(thisSeries.seriesSamplingDensity) === -1)
        ? 1
        : thisSeries.seriesSamplingDensity
    s.numRandomSampledFamilies = 1

    s.seriesTitle = thisSeries.seriesTitle
    s.seriesDescription = thisSeries.seriesDescription
    s.errMsg = ''
    s.seriesFilter = thisSeries.seriesFilter
    s.seriesLineSmoothing = validLineSmoothing(thisSeries.seriesLineSmoothing)
  }



  // JPS_ToDo  ; Expand ruleForBasis... for the C axis.
  const result = ruleForBasisDataTypeAndFormatValues0(p.seriesAttributesArray,
    seriesOrder, derivedColAttributesArray, plotColDataType)

  basisA.internalDataType = result.internalDataTypeA
  basisB.internalDataType = result.internalDataTypeB
  basisA.tickFormatRule = result.formatRuleA  // Axis tick labels have their own formatting rules.
  basisB.tickFormatRule = result.formatRuleB  // But we follow the basic 'rule' type of the data (temporal or other)
  basisA.tickFormattingObj = getFormattingObj(result.formatRuleA)
  basisB.tickFormattingObj = getFormattingObj(result.formatRuleB)

  // Create a double link between basisA,B,C and axes: bottom,top,left,right
  mapBasisToAxis(p, isTransposed, isMirrored)

  // We've defined an internal dataType for basisA and basisB.
  // It corresponds to the dataType of the first displayed legend.
  // Set the potential colKey dataType error flags,
  // Unlikely, but possible to have a dataType error for
  // SortByColKey (by changing that column's dataType in the table)
  // So we will include that check as well.
  for ( const s of p.seriesAttributesArray ) {
    if (plotColDataType === '1Col') {
      // Only colKeyA and axisA is required;  By internal design, axisB is defined properly (later).
      s.isDataTypeMismatchA = (s.internalDataTypeA !== basisA.internalDataType)
      s.isDataTypeMismatch = s.isDataTypeMismatchA
    } else if (plotColDataType === '2Col') {
      s.isDataTypeMismatchA = (s.internalDataTypeA !== basisA.internalDataType)
      s.isDataTypeMismatchB = (s.internalDataTypeB !== basisB.internalDataType)
      s.isDataTypeMismatch = (s.isDataTypeMismatchA || s.isDataTypeMismatchB)
    } else if (plotColDataType === '3Col') {
      s.isDataTypeMismatchA = (s.internalDataTypeA !== basisA.internalDataType)
      s.isDataTypeMismatchB = (s.internalDataTypeB !== basisB.internalDataType)
      let sortByColDataType = (s.sortByColKey >= 0)
        ? derivedColAttributesArray[s.sortByColKey].internalDataType
        : 'number'
      s.isDataTypeMismatchSortByCol = (sortByColDataType !== 'number')
      s.isDataTypeMismatch = (s.isDataTypeMismatchA || s.isDataTypeMismatchB
        || s.isDataTypeMismatchSortByCol)
    }
    if (DEBUG) {
      if (s.isUnsetSeriesColKey) {
        console.log('  isUnsetSeriesColKey error for series:', s.seriesTitle)
      } else if (s.isDeletedCol) {
        console.log('  isDeletedCol error for series', s.seriesTitle)
      } else if (s.isDataTypeMismatch) {
        console.log('  isDataTypeMismatch error for series', s.seriesTitle)
      }
    }

  }  // Next sKey

  return p
}