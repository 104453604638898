import type {CSSProperties}   from 'react'
import type {DomNode}         from '../computedDataTable/getDefaultTableComputedData'
import type {SbChildInfo, TabInfoArr}      from '../appCode/getSbFpConfigMappings'
import type {ViewName}        from '../types'
import type {RootState}       from '../redux/store'

import {useSelector}                            from 'react-redux'
import useAppView                               from '../hooks/useAppView'
import {TabControl3, getTabControlHeight}       from '../sharedComponents/TabControl3'
import {NAV_COLUMN_WIDTH, TOP_MENUBAR_HEIGHT,
        SIDEBAR_INTERNAL_WIDTH, SIDEBAR_BLUE_BORDER_WIDTH,
        SIDEBAR_TOTAL_LEFT_BORDER_WIDTH, SIDEBAR_BLACK_LEFT_EDGE_WIDTH,
        SIDEBAR_TOTAL_WIDTH, SIDEBAR_RANGE_OF_MOTION,
        getViewWidth_sideBarClosed }            from '../sharedComponents/constants'
import {SCRY_BLUE, SCRY_WHITE, SCRY_DARK_BLUE}  from '../sharedComponents/constants'
import {sessionStateChangeDispatch}             from '../sharedComponents/reactDispatch'
import dynamics                                 from 'dynamics.js'
import SVGwrapper2                              from '../SVGs/SVGwrapper2'
import {wasSuspendPointerEventsSuccessful,
        restorePointerEvents}                   from '../sharedFunctions/pointerEvents'
import {useGetSbLayout_ByView}                  from '../appCode/getSbFpConfigMappings'
import {getLegalDisplayedTabIndexAndChildInfo}  from '../floatingPaletteNew/FpParent'

type Direction = 'open' | 'close'

const SIDEBAR_BUTTON_DEBUG = false
const SIDEBAR_OPENCONTROL_WIDTH = 14
const SIDEBAR_OPENCONTROL_HEIGHT = 50
const TOP_BUTTON_OFFSET_FROM_BOTTOM_OF_MENU_BAR = 70

// Open close buttons - shared styles.
const buttonStyle : CSSProperties = {
  border: `solid 1px black`, boxSizing : 'border-box',
  backgroundColor: SCRY_BLUE,
  width: SIDEBAR_OPENCONTROL_WIDTH, height: SIDEBAR_OPENCONTROL_HEIGHT,
}

let sideBarDomNode : DomNode = null // This one shifts left/right;  Main visible animation
let buttonDomNode  : DomNode = null // This one shifts right/left (exact opposite of main animation)
                                    // It effectively 'cancels' the main sidebar shift, such that
                                    // the open control button appears motionless (stuck next
                                    // to the right edge of the visible browser window).

// Top Level Shell - quick exit when view uses no sideBar 
export const SbParent3 : React.FC = () => {
    const {viewName, viewId} = useAppView()
    const sbSessionState = useSelector( (state:RootState) => state.session.sbStates_ByView[viewName] )
    const sbLayoutFunction = useGetSbLayout_ByView[viewName]
    const sbLayout = sbLayoutFunction()
    if ( !sbSessionState || !sbLayout ) { return null }
    const {isSBopen, activeTab} = sbSessionState
    const {tabInfoArr, childInfoArr} = sbLayout
    const {legalDisplayedTabIndex, childInfo} = getLegalDisplayedTabIndexAndChildInfo( tabInfoArr, childInfoArr, activeTab )
    const {SbChildComponent} = childInfo as SbChildInfo
    //const RenderedChild : React.FC = sbLayout.childInfoArr[legalDisplayedTabIndex].SbChildComponent
    return <SbParentBody 
      key={viewName}   // To trigger re-render when viewName changes.
      numTabs={sbLayout.tabInfoArr.length}
      legalDisplayedTabIndex={legalDisplayedTabIndex}
      parentAnimationStart={sbLayout.parentAnimationStart}
      parentAnimation={sbLayout.parentAnimation}
      parentAnimationStop={sbLayout.parentAnimationStop}
      viewName={viewName} 
      viewId={viewId}    // To trigger re-render when underlying resource changes.
      tabInfoArr={sbLayout.tabInfoArr}
      isSBopen={isSBopen}
      RenderedChild={SbChildComponent}
    />
}

type SbProps = {
  numTabs: number,
  legalDisplayedTabIndex: number,
  parentAnimationStart : ()=>void,
  parentAnimation      : (width:number)=>void, 
  parentAnimationStop  : ()=>void,  
  viewName: ViewName,
  viewId: string,
  tabInfoArr: TabInfoArr,
  isSBopen: boolean,
  RenderedChild: React.FC
}
  
const SbParentBody : React.FC<SbProps> = (props) => {
    //console.log( 'render SbParentBody' )
    const {viewName, numTabs, legalDisplayedTabIndex, RenderedChild, tabInfoArr, isSBopen,
           parentAnimationStart, parentAnimation, parentAnimationStop} = props
    const {totalTabControlHeight} = getTabControlHeight( tabInfoArr )
    const toggleSideBar = ( ) => {
        // Direction is opposite of the current state.
        const direction : Direction = (isSBopen) ? 'close' : 'open'
        const isSBopenPath = `sbStates_ByView.${viewName}.isSBopen`
        resizeTableSideBar(direction, isSBopenPath, parentAnimationStart, parentAnimation, parentAnimationStop) 
    }
    // The placement sets sideBar 'out-of-view' to the left of the main view space,
    // with exception of 3 pixels consisting of a blue border on left and
    // 1px black line on right.  This three pixel border is entirely 
    // cosmetic, to the left edge of viewArea matches the right edge of viewArea.
    // WHEN sideBar opening/closing, we move it with a transform, from the
    // nominal 'out-of-view' placment.
    // WHEN sideBar is opening, the 'open' utton is shifted in the opposite 
    // direction!! Since it is a child of the the container, which is
    // is in the process of opening, the net shift of the button is to
    // appear motionless.  The opening sideBar will occlude the open button.
    let shift : number = 0
    shift = (isSBopen) ? +SIDEBAR_RANGE_OF_MOTION : 0
    const openButtonTransform  = `translate(${shift}px,0px)`
    shift = (isSBopen) ? -SIDEBAR_RANGE_OF_MOTION : 0
    const sideBarLeftTransform = `translate(${shift}px,0px)`
    return (
      <div className={'rc_SideBarParent'} 
        ref={ (n)=> sideBarDomNode=n } 
        onMouseUp={ (e)=>e.stopPropagation() }
        onMouseDown={ (e)=>e.stopPropagation() }
        style={{ 
          position: 'absolute',   
          left: window.innerWidth - NAV_COLUMN_WIDTH - SIDEBAR_TOTAL_LEFT_BORDER_WIDTH,
          top : 0,  
          width: SIDEBAR_TOTAL_WIDTH,
          height: window.innerHeight - TOP_MENUBAR_HEIGHT,  
          transform: sideBarLeftTransform,
        }}>

        {/* Use this 'open' button for final layout.  
            Its lies below of the sideBar; 
            Not visible when sidebar is open. */}
{!SIDEBAR_BUTTON_DEBUG &&
                <div className={'SideBarOpenButton'}
                    ref={ (n)=>buttonDomNode=n } 
                    onClick={ ()=>toggleSideBar() }
                    style={{ ...buttonStyle,
                        position: 'absolute',
                        left: -SIDEBAR_OPENCONTROL_WIDTH, 
                        top: TOP_BUTTON_OFFSET_FROM_BOTTOM_OF_MENU_BAR,
                        transform: openButtonTransform,
                        borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px',
                        borderRight: 'none', }}>
                    <SVGwrapper2>
                          <ArrowSVG
                            width={SIDEBAR_OPENCONTROL_WIDTH-4}
                            height={SIDEBAR_OPENCONTROL_HEIGHT-30}
                            direction={'pointingLeft'}/>
                    </SVGwrapper2>
                </div>
}
              <div className={'SideBarBlueBorder'}
                style={{
                  /* Disable 'visibility' when a sideBar out-of-view:
                    Save this for later.  At some time we should have our tab
                    key (on keyboard) cycle through user inputs.  This may
                    include input located in the sideBar.  However, they may
                    be rendered by out-of-view, which would be quite confusing
                    to the use model.  In this case, we want to set the 
                    out-of-view input to 'hidden', hence they are skipped.
                    Why not just set the values here and now?  Because
                    we also need to use the responsive animation functions
                    to set these controls visible BEFORE an animation to
                    open the sideBar begins.  Save for later, because not
                    obvious how to do this best, and can't test whether it
                    works until we do a lot of other similar work. */
                  visibility: isSBopen? 'visible' : 'visible',   
                  position: 'absolute', 
                  boxSizing: 'border-box',
                  top:0, left:0, height: '100%', width: '100%',
                  overflow: 'hidden',
                  borderColor: SCRY_DARK_BLUE,
                  borderStyle: 'solid',
                  borderWidth: `0px ${SIDEBAR_BLUE_BORDER_WIDTH}px  ${SIDEBAR_BLUE_BORDER_WIDTH}px ${SIDEBAR_BLUE_BORDER_WIDTH}px`,
                }} >

                    {numTabs > 1 &&
                        <div
                          className={'TabControl_AllocatedSpace'}
                          style={{
                            // Tab control had better fit exactly in this space.
                            position: 'absolute', left: 0,  top: 0,
                            width: SIDEBAR_INTERNAL_WIDTH + SIDEBAR_BLACK_LEFT_EDGE_WIDTH,
                            height: totalTabControlHeight, 
                            boxSizing: 'border-box',
                            overflow: 'visible',  // This is for debugging.  Should never be any
                            borderLeft: `{SIDEBAR_BLACK_LEFT_EDGE_WIDTH}px solid green`,
                          }}>
                              <TabControl3 
                                tabInfoArr={tabInfoArr} 
                                activeTabSessionPath={`sbStates_ByView.${viewName}.activeTab`}
                                width={SIDEBAR_INTERNAL_WIDTH}
                                legalDisplayedTabIndex={legalDisplayedTabIndex}/> 
                        </div>
                    }     

                        <div
                          className={'SbContent_AllocatedSpace'}
                          style={{
                            // FP child had better fit width exactly.
                            // Undecided yet about inadequate height.
                            position: 'absolute', left: 0, top: totalTabControlHeight,
                            width: SIDEBAR_INTERNAL_WIDTH + SIDEBAR_BLACK_LEFT_EDGE_WIDTH, 
                            height: window.innerHeight - TOP_MENUBAR_HEIGHT - 
                                        totalTabControlHeight - SIDEBAR_BLUE_BORDER_WIDTH,
                            overflow: 'hidden',  // May be overflow at bottom, but not top.
                            borderLeft: `1px solid black`,  // cosmetic only  
                            borderRight: `1px solid black`,
                            borderBottom: `1px solid black`,
                            backgroundColor: SCRY_WHITE,
                          }}>
                                <RenderedChild/>
                        </div> 
                </div>

{/* Use this 'open' button for debugging layout.  Its on top of the sideBar */}
{SIDEBAR_BUTTON_DEBUG &&
                <div className={'SideBarOpenButton'}
                    onClick={ ()=>toggleSideBar() }
                    style={{ ...buttonStyle,
                        position: 'absolute',
                        left: -SIDEBAR_OPENCONTROL_WIDTH, 
                        top: TOP_BUTTON_OFFSET_FROM_BOTTOM_OF_MENU_BAR,
                        transform: openButtonTransform,
                        borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px',
                        borderRight: 'none', }}>
                    <SVGwrapper2>
                        <ArrowSVG
                            width={SIDEBAR_OPENCONTROL_WIDTH-4}
                            height={SIDEBAR_OPENCONTROL_HEIGHT-30}
                            direction={'pointingRight'}/>
                    </SVGwrapper2>
                </div>
}
                <div className={'SideBarCloseButton'}
                    onClick={ ()=>toggleSideBar() }
                    style={{ ...buttonStyle,
                        position: 'absolute',
                        left: SIDEBAR_BLUE_BORDER_WIDTH + SIDEBAR_BLACK_LEFT_EDGE_WIDTH, 
                        top: TOP_BUTTON_OFFSET_FROM_BOTTOM_OF_MENU_BAR,
                        borderTopRightRadius: '6px', borderBottomRightRadius: '6px',
                        borderLeft: 'none', }}>
                    <SVGwrapper2>
                          <ArrowSVG
                            width={SIDEBAR_OPENCONTROL_WIDTH-4}
                            height={SIDEBAR_OPENCONTROL_HEIGHT-30}
                            direction={'pointingRight'}/>
                    </SVGwrapper2>
                </div>
      </div>
    )
}


type ArrowSVGProps = {
  width: number,
  height: number,
  direction: string,
}

const ArrowSVG : React.FC<ArrowSVGProps> = ( props ) => {
  const {width, height, direction} = props
  let transformText = 'scale(1,1)'   // As drawn is pointingLeft.
  if (direction === 'pointingRight') { transformText = 'scale(-1,1)' }
  return (
        <svg
           className='SideBarButtonArrow'
           xmlns="http://www.w3.org/2000/svg"
           version="1.1"
           width={width}
           height={height}
           viewBox="0 0 16 32"
           preserveAspectRatio='none'
           style={{display:'block', transform: transformText }}>
          <path
             style={{
               fill:'none',
               stroke: '#000000',
               strokeWidth: 6,
               strokeLinecap: 'round',
             }}
             d="M 13,32 l -8,-16 8,-16" />
        </svg>
  )
}


type SideBarAnimationObj = {
  mainViewWidth: number
  styleBarPlacement: number
}

export const resizeTableSideBar = ( direction: Direction, 
                                    isSBopenPath: string, 
                                    parentAnimationStart: ()=>void, 
                                    parentAnimation     : (width:number)=>void, 
                                    parentAnimationStop : ()=>void ) => {
    if (! wasSuspendPointerEventsSuccessful( `Table SideBar - ${direction}` )) { return }
    parentAnimationStart( )
    // IF sideBar is closed, it's visibility is also 'unset'.  This is so tabbing the
    // inputs will NOT sequence throught the rendered, but out-of-view potential sideBar
    // inputs.  But we must make the sideBar visible before we begin the animation to 
    // expose it.
    if ( direction=== 'open' && sideBarDomNode) {
        sideBarDomNode.style.visibility = 'visible'
    }  
    const mainViewWidth = getViewWidth_sideBarClosed()
    const startingWidth = (direction==='open') 
                              ? mainViewWidth 
                              : mainViewWidth - SIDEBAR_RANGE_OF_MOTION
    const startingSideBarTransform = (sideBarDomNode) ? sideBarDomNode.style.transform : `translate(0px,,0px)`
    // These two parameters are for resizing the main view width by SIDEBAR_RANGE_OF_MOTION
    const mainViewWidthStart = startingWidth
    const mainViewWidthStop  = startingWidth + ((direction==='open') ? - SIDEBAR_RANGE_OF_MOTION 
                                                                     : + SIDEBAR_RANGE_OF_MOTION )
    //console.log( "widthStart/stop", mainViewWidthStart, mainViewWidthStop ) 
    // These two parameters are for using transform to shift the sideBar by TOTAL_RANGE_OF_MOTION:
    const styleBarPlacementStart = (direction==='open') ?  0 : -SIDEBAR_RANGE_OF_MOTION   
    const styleBarPlacementStop  = (direction==='open') ?  -SIDEBAR_RANGE_OF_MOTION : 0  
    dynamics.animate (
      { mainViewWidth : mainViewWidthStart, styleBarPlacement : styleBarPlacementStart }, // Start
      { mainViewWidth : mainViewWidthStop,  styleBarPlacement : styleBarPlacementStop },  // Stop
      { // Options:
        change: (animationObj: SideBarAnimationObj)=> {
            const {mainViewWidth, styleBarPlacement} = animationObj
            parentAnimation( mainViewWidth ) 
            if ( sideBarDomNode ) { sideBarDomNode.style.transform = `translate(${+styleBarPlacement}px,0px)`  }
            if ( buttonDomNode  ) { buttonDomNode.style.transform  = `translate(${-styleBarPlacement}px,0px)`  }
        },

        complete: () => {
          restorePointerEvents( `Table SideBar - ${direction}` )
          parentAnimationStop( ) 
          if ( sideBarDomNode) { sideBarDomNode.style.transform = startingSideBarTransform  }
          // Now we are setting isSBopen to the new value (located at sessionStatePath)
          const isSBopen = ( direction === 'open' )
          sessionStateChangeDispatch([{newVal: isSBopen, path: isSBopenPath }], `${direction} sideBar`)
        },
        duration: 400,
        friction: 300,
      }
    )
}