import type {TabInfoArr} from '../appCode/getSbFpConfigMappings'

import { sessionStateChangeDispatch } from './reactDispatch'
import {SCRY_WHITE, SCRY_BLUE}   from '../sharedComponents/constants'

const DEBUG = false // Set to true to see the blocking shapes between tabs.

const TAB_HEIGHT_singleLine = 20
const TAB_HEIGHT_doubleLine = 32
const FONT_SIZE = 14
const BOTTOM_BORDER_HEIGHT = 1  // The black line under non-selected tabs.
const GAP_BETWEEN_TABS = 7   
const GAP_ABOVE_TABS = 6   
const MARGIN_LEFT_RIGHT = 3  
const TAB_TOP_CORNER_RADIUS = 10  //curvature of the top half of the tab.
const INACTIVE_TAB_COLOR = SCRY_BLUE
const ACTIVE_TAB_COLOR = SCRY_WHITE
const TAB_BACKGROUND_COLOR = '#222222'

// FpParent needs to know the height of the tab control, so it can position the children.
// This function will always run AFTER getFpLayout(), so tabInfoArr only contain displayed tabs.
// Return value is either:  0 for no tabs, 'TAB_HEIGHT_singleLine', or 'TAB_HEIGHT_doubleLine'
// To return 'TAB_HEIGHT_singleLine', all displayed tabs must have tabStrg2 === ''
export const getTabControlHeight = ( tabInforArr: TabInfoArr ) : 
        { tabHeight:number, totalTabControlHeight:number, isSingleLineTab: boolean }=> {
    const numTabs = tabInforArr.length
    if ( numTabs <= 1 ) { 
      // No tab control rendered when only a single tab.
      return {tabHeight:0, totalTabControlHeight: 0, isSingleLineTab: false} 
    } 
    const secondTabStrgTotalLength = tabInforArr.reduce( (acc, tabInfo) => acc + tabInfo.tabStrg2.length, 0)
    const tabHeight = ( secondTabStrgTotalLength > 0 ) ? TAB_HEIGHT_doubleLine : TAB_HEIGHT_singleLine
    return {
      tabHeight,
      totalTabControlHeight : tabHeight + GAP_ABOVE_TABS,
      isSingleLineTab: (tabHeight === TAB_HEIGHT_singleLine)
    }
}


type TabControl3Props = {
  tabInfoArr: TabInfoArr,
  activeTabSessionPath: string 
  width: number,
  legalDisplayedTabIndex: number  
}

export const TabControl3 : React.FC<TabControl3Props> = ( props ) => { 
    const { tabInfoArr, width, legalDisplayedTabIndex, activeTabSessionPath} = props
    const foregroundColor = 'black'
    const numTabs = tabInfoArr.length
    const {tabHeight, totalTabControlHeight, isSingleLineTab } = getTabControlHeight(tabInfoArr)
    const tabHalfHeight = tabHeight / 2
    const ovalHalfHeight = 1 * tabHalfHeight   // Some percentage of tabHalfHeight; emperically determined.

    if ( numTabs <= 1 ) { return null } // No tab control needed if only one tab.
    const setTabIndex = ( clickedIndex: number ): void => {
        // Session state needs to save the tabIndex into sbStates_ByView
        // The clickedIndex into the viewOnly tabs is NOT the same as the tabIndex.
        // Because the clickedIndex MAY be with respect to tabs only visible in viewOnly mode.
        // The next line extracts the original tabIndex into the TabInfoArr
        const {tabIndex} = tabInfoArr[clickedIndex]
        sessionStateChangeDispatch([{newVal: tabIndex, path: activeTabSessionPath }], `Tab ${tabIndex+1} Selection`)
    }
    const tabWidth = Math.trunc( (width - 2*MARGIN_LEFT_RIGHT)/ numTabs )
    const initialLeftOffset = ( width - (tabWidth * numTabs) ) / 2  -1 // Will be equal to or slightly > than MARGIN_LEFT_RIGHT
    let   runningLeftOffset1 = initialLeftOffset - tabWidth
    let   runningLeftOffset2 = initialLeftOffset - tabWidth
    return (
        <div className={'rc_TabControl'} 
            style={{
                position: 'relative', left:0, top:0,
                width : '100%',
                height: totalTabControlHeight,
                backgroundColor: TAB_BACKGROUND_COLOR,
                overflow: 'hidden',
            }}>

        { tabInfoArr.map( (thisTab, index) => { 
            runningLeftOffset1 += tabWidth
            const isActive = (index === legalDisplayedTabIndex) 
            const {tabStrg1, tabStrg2, isErroneous} = thisTab
            const key = tabStrg1+tabStrg2
            return (
                <div key={key} >

                    <div className={'Tab_bottomHalf'}
                      style={{
                        display: 'block',
                        position: 'absolute',
                        left: runningLeftOffset1, //+ GAP_BETWEEN_TABS/2,
                        top: tabHalfHeight+ GAP_ABOVE_TABS,
                        width:tabWidth,
                        height: tabHalfHeight,
                        backgroundColor: (isActive) ? ACTIVE_TAB_COLOR : INACTIVE_TAB_COLOR,
                        color: (isActive) ? foregroundColor : 'white',
                        textAlign : 'center',
                        fontSize: FONT_SIZE,
                        fontWeight: (isActive) ? 'bold' : 'normal',
                        borderBottom: (isActive) ? 'none' : `${BOTTOM_BORDER_HEIGHT}px solid ${'black'}`,
                        boxSizing: 'border-box', }}
                      onClick={ ()=>setTabIndex(index) }
                    > 
                            <div style={{ color: isErroneous ? 'red' : 'black', marginTop:-2}}>   {tabStrg2}  </div>
                    </div>

                    <div className={'Tab_topHalf'}
                      style={{
                        // Render AFTER bottomHalf, so text stays on top of the two halves
                        // Visually necessary to single line tabs.
                        display: 'block',
                        position: 'absolute',
                        borderTopLeftRadius: TAB_TOP_CORNER_RADIUS ,
                        borderTopRightRadius: TAB_TOP_CORNER_RADIUS ,
                        left: runningLeftOffset1 + GAP_BETWEEN_TABS/2,
                        top: GAP_ABOVE_TABS,
                        width:tabWidth-GAP_BETWEEN_TABS,
                        height:tabHalfHeight,
                        backgroundColor: isActive ? ACTIVE_TAB_COLOR : INACTIVE_TAB_COLOR,
                        color: isActive ? foregroundColor : 'white',
                        textAlign : 'center',
                        fontSize: FONT_SIZE,
                        fontWeight: isActive ? 'bold' : 'normal', 
                      }}
                      onClick={ ()=>setTabIndex(index) }
                    >
                          <div style={{ 
                              paddingTop: isSingleLineTab ? 2 : 1,
                              color: isErroneous ? 'red' : 'black' 
                            }}>   {tabStrg1}  </div>
                    </div>

                </div>
            )}
        )}

        { tabInfoArr.map( (thisTab) => { 
                    // Second loop to render the blocking shapes between tabs.
                    // Could be done in first loop if I used Z index.
                    // But avoiding Z index throughout the code to make
                    // event handling debug easier.
                    const {tabStrg1, tabStrg2} = thisTab
                    const key = tabStrg1+tabStrg2
                    runningLeftOffset2 += tabWidth
                    return (
                      <div className={'Oval'}
                        key={key}
                        style={{
                          position: 'absolute',
                          left: runningLeftOffset2 + tabWidth - GAP_BETWEEN_TABS/2 ,
                          top: GAP_ABOVE_TABS - BOTTOM_BORDER_HEIGHT,
                          width:GAP_BETWEEN_TABS,
                          height:2*tabHalfHeight,
                          borderRadius: `${GAP_BETWEEN_TABS/2}px/${ovalHalfHeight}px`,
                          backgroundColor: (DEBUG) ? 'pink' : TAB_BACKGROUND_COLOR,
                          opacity: (DEBUG) ? 0.5 : 1,
                        }}/>
                    )
        })}
          
        
        <div className={'First_Oval'}
            style={{
                // This is cosmetic blocking shape between tabs
                // There is always one more space, than number of tabs, 
                // so either 1st or last blocking space must be placed here.
                position: 'absolute',
                left: MARGIN_LEFT_RIGHT - GAP_BETWEEN_TABS / 2, 
                top: GAP_ABOVE_TABS - BOTTOM_BORDER_HEIGHT,
                width:GAP_BETWEEN_TABS + 1,
                height:2*tabHalfHeight + 1,
                borderRadius: `${GAP_BETWEEN_TABS/2}px/${ovalHalfHeight}px`,
                backgroundColor: (DEBUG) ? 'pink' : TAB_BACKGROUND_COLOR,
                opacity: (DEBUG) ? 0.5 : 1,
              }} /> 
    </div>

)}