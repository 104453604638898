
import { Component } from 'react'
import constants from '../sharedComponents/constants'

const nbSpace = constants.nonBreakingSpace
const bullet = "\u25Cf" + nbSpace
const indent = nbSpace+nbSpace+nbSpace+nbSpace
const mR = 10  // marginRight

type Props = {
  height: number,
  width: string | number,
  top: number,
  left: number,
  onDismissClick: () => void,
}

export class HelpPanelCloneTable extends Component<Props> {
  static defaultProps = {
    height: 20,
    width: '100%',
    top: 0,
    left: 0,
  }
  render() {
    const {height, top, left, width} = this.props
    return (
        <div
            className={'rc_HelpPanelCloneTable'}
            onClick={this.props.onDismissClick}
            style={{
                position:'relative', width, top, left, height,
                lineHeight: 1.2,
                fontFamily: 'serif', fontSize: 16,
                //background:'red',
                overflowY:'scroll', 
            }}>
                <p style={{ fontWeight: 'bold', textAlign: 'center', marginTop:6 }}>
                    {'Cloning the current Active Table:'}
                </p>

                <p style={{marginRight:mR}}>
                This option will create a copy of the currently active table.
                The cloned table will have a 'new' unique table ID, meaning it
                can be edited independently of the original source.
                It will have your user ID, assigning table ownership to you.
                It will point to the current activeTable as its 'predecessor'.</p>

                <p style={{marginRight:mR}}>
                It is undecided at this time what 'rights of ownership' will 
                be given to table owners.  For example, perhaps we grant then the
                right to allow/prevent cloning.  Or the right to allow/prevent 
                downloading a *.csv file.  At this time (alpha testing), 
                all tables are public.</p>
                 
        </div>
    )
  }
}


export class HelpPanelNewTable extends Component<Props> {
  static defaultProps = {
    height: 20,
    width: '100%',
    top: 0,
    left: 0,
  }
  render() {
    const {height, top, left, width} = this.props
    return (
      <div
        className={'rc_HelpPanelNewTable'}
        onClick={this.props.onDismissClick}
        style={{
          position:'relative', width, top, left, height,
          lineHeight: 1.2,
          fontFamily: 'serif', fontSize: 16,
          //background:'red',
          overflowY:'scroll', 
        }}>
            <p style={{ fontWeight: 'bold', textAlign: 'center', marginTop:6 }}>
                {'How to Create a New Table:'}
            </p>
            <p>This is the proper option for creating your first table!</p>

            <p style={{marginRight:mR}}>
            Alpha testers: At this time all tables are public!  Any data uploaded
            here will be visible to everybody with an active login account.
            </p>

            <p style={{marginRight:mR}}>{bullet}<b>Column Names:</b>{indent}
            Your source data MUST include column names in the first row.
            This serves two purposes:<br/>
            1 - It adds proper documentation to the source data.<br/>
            2 - If you need to edit, then reload your source data (perhaps a year later), you will
            be thankful you have matching source column names!</p>

            <p style={{marginRight:mR}}>This tool tracks 2 column names for every column.
            The ‘Source-Column-Name’ is the name in the source data.  The  ‘Displayed-Column-Name'
            is the name displayed at the top of a table column, as well as in table 
            formulas, your plots, and all 3rd party plots referencing 
            your table.  As table owner, you can change the displayed name at any time.  But internally,
            all column references (yours and 3rd party references) always point to 
            your original source column name.</p>

            <p style={{marginRight:mR}}>Create source names to be descriptive! Don't be concerned about length.
            They need to make sense one or two years later, if or when you wish to reload modified or new data.  
            Only you, the table owner will see this name.  The 'source name' will also become the 
            first, default ‘displayed name’.  Later, you can change the 
            'displayed name' as often as you wish -- something that seems 
            reasonable for reading and writing column formulas (5-20 characters).</p> 

            <p style={{marginRight:mR}}>{bullet}<b>Which source data columns to load?</b>{indent}
            As a general rule, if you are unsure whether you
            want to load a source data column, then it is better to load the data now.
            As table owner, you can hide the column from users by 'deleting' the column.
            And as table owner, you can restore a deleted column at any later time.  Load what you
            think you MAY need.  But don't show the column unless it's adding value.</p>

            <p style={{marginRight:mR}}>{bullet}<b>Appending new row data:</b>{indent}       
            Some tables are intended to grow (number of rows) over time, as new data 
            becomes available.  For example once per week, once per 
            month, or at sporadic time intervals.  For example, a block of 100 rows was 
            loaded on Dec-25-2022, a second block of 70 rows 
            was appended on Jul-01-2023, ... </p> 
            <p style={{marginRight:mR}}>For these tables, one column will be named something like ‘Date of Input’.  
            Usually, one will know in advance that your table data will grow over time.  
            But if you are unsure, add the column to your source data now, and just delete that
            column until you may need it.</p>

            <p style={{marginRight:mR}}>{bullet}<b>Row Order Dependent Data:</b>{indent}     
            Some data tables rely on a correct row order (for example, standard financial 
            statements such as a Balance Sheet or Profit & Loss statements).  In this 
            case the source data MUST be in the proper row order.  This is usually not 
            a problem.  For this type of source data, whether from a spreadsheet or 
            pasted from another source, it will almost certainly be properly order.  
            Once the data is loaded, there is an option to ‘force’ the data to display 
            in the order it was loaded.  In practice, this is the same as simply disabling 
            the sort controls.  If you load ‘row order dependent data’, it is a 
            good idea to set this option right away (located in the right-side table formatting panel).</p>

            <p style={{marginRight:mR}}>{bullet}<b>More Notes for Spreadsheet Creators:</b></p>
            <p style={{marginRight:mR}}> When you load data pasted from a spreadsheet, you will be loading the table 
            values, NOT the formulas.  If your table values are formatted (for example 
            rounded values), then the loaded values are only as precise as the spreadsheet’s 
            formatting.  If precision is important, then it is best to set all spreadsheet 
            cells to ‘no formatting’ before loading the source data.</p>

            <p style={{marginRight:mR}}>This tool is technically a database, but has the look 
            and feel of a beginner’s spreadsheet. In a database:<br/>
            {indent}Rows are equivalent; each uniquely named ('key' column); order is flexible.<br/>  
            {indent}Columns are equivalent; each uniquely named; order is flexible.<br/>
            {indent}The view is frequently reordered and/or filtered.<br/>
            In a spreadsheet:<br/>
            {indent}Rows are referenced by position; row names are not required.<br/> 
            {indent}Columns are referenced by position; col names are not required.<br/>
            {indent}Almost never is a spreadsheet reordered -- huge effort adjusting references!<br/>
            {indent}(Albeit, Mac 'Numbers' optionally supports referencing by column name.)</p>
   
            <p style={{marginRight:mR}}>Neither the spreadsheet nor database approach is superior, 
            just different technologies for different applications.  
            For this application, the publication of effectively static data, 
            a database is the preferred approach.  Furthermore, most 
            spreadsheets of even modest size ({'>'}100 rows) will treat the rows equally anyway.
            Hence, this tool features formulas supporting column names, but there is 
            no equivalent formula syntax for row names.</p>

            <p style={{marginRight:mR}}>For all tables, 
            it is best NOT to include calculated (dependent) 
            columns in your source data.  Instead, create your table from only 
            independent column data, then use the tool to create dependent columns using 
            a formula.  A formula is always the best and
            proper description for a dependent column.  Also, columns with formulas 
            are ~100x faster to calculate on the client, rather than downloading pre-calculated values 
            from a server.</p>  

            <p>NOTE: In the current tool ‘formulas’ only work for number data types.  
                Support for additional data types is planned (strings, date/time, 
                geo-position, etc).</p>

        </div>
                  
    )
  }
}



export class HelpPanelReplaceData extends Component<Props> {
  static defaultProps = {
    height: 20,
    width: '100%',
    top: 0,
    left: 0,
  }
  render() {
    const {height, top, left, width} = this.props
    return (
        <div
            className={'rc_HelpPanelReplaceData'}
            onClick={this.props.onDismissClick}
            style={{
                position:'relative', width, top, left, height,
                lineHeight: 1.2,
                fontFamily: 'serif', fontSize: 16,
                //background:'red',
                overflowY:'scroll', 
            }}>

            <p style={{ fontWeight: 'bold', textAlign: 'center', marginTop:6 }}>
            Retains the activeTable formating, but replaces table values
            </p>

            <p style={{marginRight:mR}}>
            This option will keep any work you have already invested in your table,
            but allow you to 'replace' the table values.</p>

            <p>Why are you changing the table values?  If you are creating a new table
            and this is part of the process of getting the correct data into the table, 
            then 'replacing' the data values is appropriate.</p>
            
            <p> However, if you have already
            been playing with the data (created plots), then you should ask: How does 
            the prior data compare with the new data?  In which case both prior and new
            data should be available in the same table.  In this case you should use
            'append new rows'.  Furthermore, if the table is already published
            it would be poor form to simply 'replace' the data.</p> 
            
            <p>Whether you 'replace' or 'append new rows' depends on the maturity of the 
            table, how many data values are changing, and why are they changing.
            If you are unsure, it is better to use the 'append rows' 
            option, such that your table includes all prior data, as well as the 
            latest revised data.</p>

            <p><b>Rules of 'replacement' by priority:</b></p>

            <p style={{marginRight:mR}}>
            1. If the data is identical size (number rows and number columns) AND there is
            a 1-to-1 match of the 'source-column-names', then there is a complete overwrite of
            all activeTable values.</p>

            <p style={{marginRight:mR}}>
            2. If the number of rows in the replacement data differs from the original data, 
            then the new table will match the number of rows in the replacement data.</p>   

            <p style={{marginRight:mR}}>
            3. A 'new column' in the replacement data is defined as a new 'source-column-name'
            not found in the activeTable.  New columns will use the default column formatting.</p>

            <p style={{marginRight:mR}}>
            4. A 'missing column' in the replacement data is defined as a 'source-column-name'
            in the activeTable that is not found in the replacement data.  These columns,
            along with any work you have put into that column, will be deleted from the activeTable.</p>

            <p style={{marginRight:mR}}>
            5. If there are any 'new columns' or 'missing columns', the tool will provide you
            a list by 'source-column-name'.  If it is not as you expect, then you can abort
            the process.</p>

        </div>
    )
  }
}


export class HelpPanelAppendRows extends Component<Props> {
  static defaultProps = {
    height: 20,
      width: '100%',
    top: 0,
    left: 0,
  }
  render() {
    const {height, top, left, width} = this.props
    return (
        <div
            className={'rc_HelpPanelAppendRows'}
            onClick={this.props.onDismissClick}
            style={{
                position:'relative', width, top, left, height,
                lineHeight: 1.2,
                fontFamily: 'serif', fontSize: 16,
                //background:'red',
                overflowY:'scroll', 
            }}>

            <p style={{ fontWeight: 'bold', textAlign: 'center', marginTop:6 }}>
            Appends additional rows to the active table:
            </p>

            <p style={{marginRight:mR}}>
            This option will keep both your table formatting and current table rows.
            It will append 1 or more new rows.</p>

            <p>Whether you occasionally, weekly, or yearly append new row data, you should
            have a column for 'Date of Upload' (or similar name).  There are two use cases:</p>
            <p style={{marginRight:mR, marginLeft:20}}>
            1. The new rows are (mostly) identical to prior rows, but data is from a different
            date/time. In this case you MUST have the 'Date of Upload' column as it becomes a
            requirement for a unique row name (rows keys).</p>
            <p style={{marginRight:mR, marginLeft:20}}>
            2. The new rows are new data points, but uploaded at a later date.  In 
            this case 'Date of Upload' is not required, but it would be a good practice to
            include this information.</p>

            <p>An alternative to 'append rows', is to edit your original source data file,
            so it includes all the prior data values, as well as new data values. If your table
            does not already include some column for 'Date of Upload', then this is the best
            alternative.</p> 

            <p><b>Rules, by priority:</b></p>

            <p style={{marginRight:mR}}>
            1. All existing data values are NOT modified.  The number of new rows will 
            always equal the prior rows plus the new rows.</p>

            <p style={{marginRight:mR}}>
            2. If your new rows have duplicate names (row keys) as prior rows, then your
            table MUST have a column equivalent to 'Date of Upload'.  Otherwise, you will
            get an error for duplicate row names, which will be nearly impossible to fix
            without adding this column.  If your table does not already have such a column,
            then the best alternative is to go back to your original data source and append the
            new rows and the 'Date of Upload' column.</p>   

            <p style={{marginRight:mR}}>
            3. At no time is any column or any data deleted!  If your appended data is missing
            a column, the column is automatically inserted into the appended rows, but filled with empty values.  
            If the prior data is missing a column, the column is automatically inserted into the prior rows, but 
            filled with empty values.</p>

            <p style={{marginRight:mR}}>
            4. If there are any 'new columns' or 'missing columns', the tool will provide you
            a list by 'source-column-name'.  If it is not as you expect, then you can abort
            the process.</p>

        </div>
    )
  }
}



export class HelpPanelPreviewApproveDiff extends Component<Props> {
  static defaultProps = {
    height: 400,
    width: '100%',
    top: 0,
    left: 0,
  }
  
  render() {
    const {height, top, left, width} = this.props
    return (
        <div
            className={'rc_HelpPanelPreviewApproveDiff'}
            onClick={this.props.onDismissClick}
            style={{
                position:'relative', width, top, left, height,
                lineHeight: 1.2,
                fontFamily: 'serif', fontSize: 16,
                //background:'red',
                overflowY:'scroll', 
            }}>
                <p style={{ fontWeight: 'bold', textAlign: 'center', marginTop:6 }}>
                    {'Preview Diferences; Approve or Abort Replacement:'}
                </p>
                <p>This option will compare table values in the active table to
                the new of source data.  A summary of the differences will be
                displayed.  You will have two options:</p>

                <p><b>Accept:</b> The differences are as expected.  Replace the
                active table values with the source data.
                Following the rules of the 'Update (replace)' help panel.</p> 

                <p><b>Abort:</b> The differences are NOT as expected.  Do not
                modify any values in the active table.  I will edit either my source
                data or active table values to match my expectations.</p> 
        </div>
    )  }
}
