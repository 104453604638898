import { isScryHyperlink_fromTableValue } from '../sharedFunctions/isTypeHyperlink'
import isScryNumber from '../sharedFunctions/isTypeNumber'
import { FormatRule } from '../sharedFunctions/numberFormat'
import type { ScryNumberType } from '../sharedFunctions/parseScryInputStringTypes'
import { typedKeys } from '../sharedFunctions/utils'
import type { ColDataTypes } from '../types'

type TypeCounter = {
  [key in ColDataTypes]: number
}

type FormatCounter = {
  [key in ScryNumberType]: number
}

export const detectColDataTypeAndFormat = (colData: string[] ) : {colDataType: ColDataTypes, formatRule: FormatRule, parsedData: string[] } => {

  const numRowsUnfiltered = colData.length
  const typeCounter: TypeCounter = {
    string: 0,
    number: 0,
    numberSeconds: 0,
    // numberDegrees: 0,
    hyperlink: 0
  }

  // We only need the isScryNumber return types that
  // are actually a valid number.  We will use a format
  // corresponding to the majority type.
  const numberFormatCounter: FormatCounter = {
    DATA_ERR : 0,
    INTEGER : 0,
    FLOAT : 0,
    EXPONENTIAL : 0,
    B60 : 0,
    B60B60 : 0,
    // B60degrees : 0,
    // B60B60degrees : 0,
    BOOL_TF : 0,
    IEEE: 0,
    MISSING_VALUE : 0,
  }

  // At what value do we say, 'I have enough samples and one seems to dominate.  This is a sufficient guess-timate'
  const LARGE_ENOUGH_SAMPLE_SIZE = 100
  const rowsBetweenSamples = Math.max(1, Math.floor(numRowsUnfiltered / 50 / 2))  // spread them out, but not too much! Many be empty.

  // We don't know in advance the dataType
  // We will create 'n - 1' sparse arrays, one for each currently supported dataType (except strings)
  // We don't need a dataType === string version, because the tableData array is exactly the input colData array.
  // But we do need an array for each other data type because:
  //      Ex: text === '2:14'  The value in tableData should be '134'
  // When the majority dataType is decided, then we know which array to return.
  // It it will contain (best case) only that dataType.  But in cases where
  // the row fails to find the majority dataType, the value will be the input string.
  // and empty strings will remain empty strings.
  //
  // Example:
  // Input data is ['1:23', '1:2', 0:45, 13]
  // The colData creating by the isNumbers() testing is
  //               [ '83' , '1:2', 13]
  // 'numberSeconds' is the majority data type and 'B60' is the majority format.
  //
  // We should return (colDataType:'number', format:'B60seconds', colData['83', '1:2', '45', '13']
  // If user doesn't change the format, they display as
  //      edit Mode:  [ '1:23', '1:2', '0:45', '0:13' ]   // Second illegal value displayed in 'red'
  //      view Mode:  [ '1:23', '',    '0:45', '0:13' ]   // Second illegal value displayed as empty cell.

  const parsedNumbers    = []
  const parsedHyperlinks = []
  // And more as we add new dataTypes


  let countRowsWithContent = 0
  for (let rowKey = 0; rowKey < numRowsUnfiltered; rowKey += rowsBetweenSamples ) {

    // We don't know in advance the dataType
    // We will create 'n' sparse arrays, one for each currently supported dataType
    const value = colData[rowKey]
    if (value === '') { continue }   // Skip emtpy cells; they have no information.
    if ( countRowsWithContent > LARGE_ENOUGH_SAMPLE_SIZE ) { break }
    countRowsWithContent += 1

    // Is this a valid number format?   (Note we ALWAYS convert value to our internal format)
    //console.log( 'rowKey', rowKey, 'Samples so far', countRowsWithContent, 'thisValue', value )
    const scryNumberResult = isScryNumber(value)
    if (scryNumberResult.numberType !== 'DATA_ERR') {
        switch( scryNumberResult.numberType ) {
          case 'INTEGER':
          case 'FLOAT':
          case 'EXPONENTIAL' :
          case 'BOOL_TF' :
          case 'IEEE':
              typeCounter.number++
              break
          case 'B60' :
          case 'B60B60' :
              typeCounter.numberSeconds++
              break
          // case 'B60degrees' :
          // case 'B60B60degrees' :
          //     typeCounter.numberDegrees++
          //     break
          default:
        }
        numberFormatCounter[scryNumberResult.numberType]++
        // Overwrite the input format into our internal 'canonical values'
        parsedNumbers[rowKey] = scryNumberResult.internalFormat
        continue
    }


    // Is this a valid Hyperlink format?
    // Could start with simple http ...
    // Or could be our own hyperlink cell format.
    const prependHTTP = false
    const scryHyperlinkResult = isScryHyperlink_fromTableValue(value, prependHTTP )
    if ( scryHyperlinkResult.errorMsg === '' ) {   // No error msg means this passed hyperlink testing
      typeCounter.hyperlink++
      // Overwrite the input format into our internal format
      // (It may or may not have already been in this format)
      parsedHyperlinks[rowKey] = scryHyperlinkResult.tableValue
      continue
    }

    // Default is DataType string.
    typeCounter.string++
  }

  // JPS:  threshold 50.01%  ( half will set a dataType!)
  const threshold = 0.5001 * countRowsWithContent
  let thisColType: ColDataTypes = 'string'   // Assumption
  let formatRule: FormatRule = 'defaultString'
  let majorityCount: number = 0
  let majorityKey: ScryNumberType = 'DATA_ERR'

  for (const typeKey of typedKeys<TypeCounter>(typeCounter)) {
    if (typeCounter[typeKey] >= threshold) {
      thisColType = typeKey
    }
  }

  if (thisColType === 'hyperlink') {
    formatRule = 'defaultLink'
  } else if (thisColType === 'number' ||
           thisColType === 'numberSeconds' /*||
           thisColType === 'numberDegrees'*/ ) {
    // What is the most common number format?
    for (const colType of typedKeys<FormatCounter>(numberFormatCounter)) {
      var thisCount = numberFormatCounter[colType]
      if ( thisCount > majorityCount ) {
        majorityCount = thisCount
        majorityKey = colType
      }
    }

    // From types.ts module.  ~line 175
    // Here are the current formatRule options:
    //  'defaultString'
    //  'defaultLink'
    //  'defaultEng'
    //  'scientific'
    //  'noExponent'
    //  'internal'
    //  'boolTrueFalse'
    //  'B60seconds'
    //  'B60B60seconds'
    //  'B60degrees'
    //  'B60B60degrees'


    switch ( majorityKey ) {
      case 'INTEGER' :
      case 'FLOAT' :
      case 'EXPONENTIAL' :
        formatRule = 'defaultEng'
        break
      case 'B60' :
        formatRule = 'B60seconds'
        thisColType = 'numberSeconds'
        break
     case 'B60B60' :
        formatRule = 'B60B60seconds'
        thisColType = 'numberSeconds'
        break
      // case 'B60degrees' :
      // case 'B60B60degrees' :
      //   formatRule = majorityKey
      //   thisColType = 'numberDegrees'
      //   break
      case 'BOOL_TF' :
        formatRule = 'boolTrueFalse'
        break
      default :
        formatRule = 'defaultEng'
    }
  } else {
    formatRule = 'defaultString'
  }

  // We know the majority key, but we sampled only a subset of numbers.
  // For dataTypes with a canonical internal format, we need
  // to parse every number in the column
  // Start at rowKey[1]  (zero is the column header string)
  // For small numRows, we parse each row value twice
  // However for large numRows, we parse only once.
  // Example:
  //    100 rows:
  //         find majority dataType            100 parses
  //         find the internal canonical value 100 parses
  //    100K rows
  //         find majority dataType            100  parses
  //         find the internal canonical value 100K parses
  for (let rowKey = 1; rowKey < numRowsUnfiltered; rowKey++ ) {
    let value = colData[rowKey]
    if ( thisColType === 'number' || thisColType === 'numberSeconds' /*|| thisColType === 'numberDegrees'*/ ) {
      let result = isScryNumber( colData[rowKey] )
      parsedNumbers[rowKey] = result.internalFormat
    }
    if ( thisColType === 'hyperlink' ) {
      if (value === '') {
        parsedHyperlinks[rowKey] = value
      } else {
        const scryHyperlinkResult = isScryHyperlink_fromTableValue(value)
        parsedHyperlinks[rowKey] = scryHyperlinkResult.tableValue
      }
    }
  }

  // Return the parsedData array consistent with the majority dataType
  var parsedData
  switch (thisColType) {
      // case 'numberDegrees':
      case 'numberSeconds':
      case 'number':
        parsedData = parsedNumbers
        break
      case 'hyperlink':
        parsedData = parsedHyperlinks
        break
      default :  parsedData = colData  // Default is text format and parsed values are the input values.
  }
  //console.log( types, numberTypeCount, type, formatRule )
  return {colDataType:thisColType, formatRule, parsedData}
}

