import type { Property } from 'csstype'
import { CSSProperties } from 'react'
import { MarkShape } from '../types'

// Don't change this after it has been put into use!
// Because users will eventually see this in our output files, and
// potentially add it to their input *.cvs file.  Once released to
// the public, this value is essentially immutable.
export const RESERVED_HYPERLINK_LABEL_TEXT = '_Hyperlink Labels For_'

export const MIN_COL_WIDTH_AT_NOMINAL_SCALE = 50
export const MAX_NUMBER_PINNED_ROWS = 20
export const STRING_CELL_MAX_DISPLAYED_LENGTH = 50  // When rendering table cells with strings, this is 
// the max number of characters to render.
export const TABLE_CONTROLS_OPACITY: number = 0.5

export const MAX_NUM_SERIES_XY_PLOTS = 20
export const PUBLISHER_DEFAULT_TITLE = 'Publisher name (and access to info) here.'
export const MAX_LENGTH_TABLE_TITLE = 50
export const MAX_LENGTH_PLOT_TITLE = 50

export const MAX_LENGTH_COL_UNITS = 20
export const MAX_LENGTH_COL_NAME = 40
export const MAX_LENGTH_PRESUFFIX = 8

export const ROW_SORT_COL_IDS_ARR_MAX_LENGTH = 10

// New plots;  Or default if the resource value is not valid.
export const DEFAULT_MARK_SHAPE: MarkShape = 'square'
export const DEFAULT_MARK_SIZE = 2

export const SEARCH_TABLE_ID = '54366EAF-05F5-4CA4-9B38-9B446615857D'
export const SEARCH_TABLEDATA_ID = 'A1F00B07-BFF9-4FC6-B792-B827FA0DABDC'

export const COL_KEY_CANONICAL_FORM = '_ColKey_'   // Obscure string used to encode a colName; used in canonical-format.
export const FORMULA_INDENT_LENGTH = 4   // Number of spaces for python indentation
export const MAX_CHAR_COUNT_PER_EXPRESSION = 50

export const CONTROL_SLIDER_HEIGHT: number = 20
export const CONTROL_NAME_WIDTH: number = 100
export const CONTROL_SLIDER_WIDTH: number = 120
export const CONTROL_DISPLAY_WIDTH: number = 100
export const CONTROL_GAP_WIDTH: number = 4
export const CONTROL_SLIDER_PLUS_DISPLAY_WIDTH = CONTROL_SLIDER_WIDTH + CONTROL_DISPLAY_WIDTH + CONTROL_GAP_WIDTH
export const SLIDER_TOTAL_WIDTH = CONTROL_NAME_WIDTH + CONTROL_GAP_WIDTH + CONTROL_SLIDER_PLUS_DISPLAY_WIDTH

export const SIDEBAR_INTERNAL_WIDTH = 260
export const SIDEBAR_BLUE_BORDER_WIDTH = 2
export const SIDEBAR_BLACK_LEFT_EDGE_WIDTH = 1
export const SIDEBAR_TOTAL_WIDTH = SIDEBAR_INTERNAL_WIDTH + 2 * SIDEBAR_BLUE_BORDER_WIDTH
  + SIDEBAR_BLACK_LEFT_EDGE_WIDTH
export const SIDEBAR_TOTAL_LEFT_BORDER_WIDTH = SIDEBAR_BLUE_BORDER_WIDTH + SIDEBAR_BLACK_LEFT_EDGE_WIDTH
export const SIDEBAR_RANGE_OF_MOTION = SIDEBAR_INTERNAL_WIDTH + SIDEBAR_BLUE_BORDER_WIDTH


export const HIGHLIGHT_OPACITY_TABLE = 0.5
export const HIGHLIGHT_OPACITY_XYPLOT = 0.8
export const HIGHLIGHT_WIDTH = 10
export const HIGHLIGHT_COLOR = '#00e0ff'
export const HIGHLIGHT_COLOR_HALF = '#90e0ff'  // When I don't want opacity:0.5, but roughly same color.
export const ALLOWED_LEFT_OVERLAP_OF_EDITOR_WITH_NAVBAR = 50
export const ALLOWED_RIGHT_OVERLAP_OF_EDITOR_WITH_SIDEBAR = 150
//const SELECTION_BLUE = '#51CBEE'

export const NAV_COLUMN_WIDTH = 180
export const SEARCHVIEW_RESERVED_HEIGHT = 80 //+ 150   // 2nd term is temporary room for a text message
export const TOP_MENUBAR_HEIGHT = 40

export const LINK_COLOR = '#0088ff'
export const SCRY_BLUE = '#5588EE'
export const SCRY_LIGHT_BLUE = '#A2BBEE'
export const SCRY_VERYLIGHT_BLUE = '#D4DDFF'
export const SCRY_WHITE = '#F7F7FF' // '#C8D5EE' //'#DBE1EE'
export const SCRY_DARK_BLUE = '#2669EE'

export const FLOATING_PALETTE_BACKGROUND = SCRY_WHITE
export const FLOATING_PALETTE_TITLE_BAR_HEIGHT = 20
export const FLOATING_PALETTE_TITLE_BAR_FONTSIZE = 14
export const FLOATING_PALETTE_BLUE_OUTLINE_WIDTH = 4
export const FLOATING_PALETTE_BLACK_OUTLINE_WIDTH = 1

export const COLHEADER_INPUT_BORDER_COLOR = '#888888' // The grey color around inputs and custom selection popup menus.
export const SCRY_SELECTION_HIGHTLIGHT_COLOR = '#005FCC'  // Blue with touch of cyan. Not sure how to match browsers.

export const STYLE_EDITOR_RAISED_BUTTON: CSSProperties = {
  boxShadow: '0 1px 2px',
  borderRadius: '4px',
  background: 'rgb(230,230,230)',
  fill: 'rgb(230,230,230)',     // This one is for svg transpose button
  borderStyle: 'outset',
  borderWidth: 1,
  borderColor: COLHEADER_INPUT_BORDER_COLOR
}

export const SCRY_NUMBER_INPUT_MAX_CHARACTERS = 24

export const STYLE_PALETTE_TEXT_INPUT_STYLES: CSSProperties = {   // Defined such that it matches the default style of input selector
  background: 'rgb(256,256,256)',
  borderColor: 'rgb(166,166,166)',
  borderWidth: 2,
  borderRadius: 4,
  borderStyle: 'inset',
  boxSizing: 'borderBox' as Property.BoxSizing,
  paddingLeft: 5,
  paddingRight: 5,
  resize: 'none'
}

// Resizable Floating Palettes
export const STRING_EDITOR_MIN_WIDTH = 340
export const STRING_EDITOR_MAX_WIDTH = 600

export const STRING_EDITOR_MIN_HEIGHT = 64
export const STRING_EDITOR_MAX_HEIGHT = 400

// Custom selection Menu ( EditorMenuButton component )
export const SELECT_MENU_SINGLE_LINE_HEIGHT = 17


// Next two widths cannot be changed without permission from 'the almighty'.
//    Because:
//    Making MAX_LINE_LENGTH shorter means current formulas may no longer be valid!
//    Making MAX_LINE_LENGTH longer is OK, but it's a ratchet.  Can never be lowered afterwards!
//    Hence, DO NOT MOVE IN EITHER DIRECTION.
//
//    ALSO: Changing fontSize larger will have same problem.
//    Consider fontSize fixed.
//
//    Changing COR_FORMULA_EDITOR_WIDTH is OK, as long as it always leaves from for
//    the expression paddingLeft and sufficient room on the right to always add
//    one additional character beyond the MAX_LINE_LENGTH.  The one additional
//    character allows the user to trigger a 'line too long' error message.
export const COL_FORMULA_EDITOR_WIDTH = 550
export const COL_FORMULA_MAX_LINE_LENGTH = 490
export const COL_FORMULA_EDITOR_FONT_SIZE = 16

export const DESCRIPTION_EDITOR_FONT_SIZE = 16


export const FONT_FAMILY_CSS_FORMAT: string = 'sans-serif'
export const FONT_FAMILY_LOGO_FORMAT: string = 'serif'
export const zeroWidthSpace = String.fromCharCode(0x200B)
export const nonBreakingSpace = String.fromCharCode(0x00A0)
export const thinSpaceChar = String.fromCharCode(0x2009)  // Half the normal space width  \u2009
export const hairSpaceChar = String.fromCharCode(0x200A)
export const smallPercentChar = String.fromCharCode(0xFE6A)

export const divideChar = String.fromCharCode(0x2215)  // longer slash
export const subtractChar = String.fromCharCode(0x2212)  // longer dash
export const doubleSlashChar = String.fromCharCode(0x2AFD)  // double slash
export const arrowPtsLeft = String.fromCharCode(0x21D0)  // back arrow (points from right to left)
export const arrowPtsRight = String.fromCharCode(0x21D2)  // back arrow (points from right to left)
export const plusMinusChar = String.fromCharCode(0x00B1)
export const italix_x_Char = String.fromCharCode(0x0967)
// This one is used for table and plot numbers of the form 3*10**2
export const multiply10Exp = `<span style="font-size:65%; position:relative; bottom:0.15em;"}>\u273D</span>`
export const multiplyChar = `<span style="font-size:65%; position:relative; bottom:0.15em;"}>\u273D</span>`
// line above and below are the same rendered symbol.  Line below is a valid react Fragment.
export const MultiplyElement = <span style={{ fontSize: '65%', position: 'relative', bottom: '0.15em' }}>{'\u273D'}</span>



export const TEXTAREA_OVER_DIV_StyleDiv: CSSProperties = {
  lineHeight: '120%',
  textRendering: 'auto',
  letterSpacing: 'normal',
  wordSpacing: 'normal',
  textTransform: 'none',
  textIndent: '0px',
  textShadow: 'none',
  display: 'inline-block',
  textAlign: 'start',
  appearance: 'textarea' as Property.Appearance,
  borderWidth: '0px',
  borderStyle: 'solid',
  borderColor: 'black',
  padding: '3px 8px',    // Cosmetic 8 px on left and right
  overflow: 'hidden',
  whiteSpace: 'pre-wrap',      // Any extra white space at end of a line is truncated.
  //whiteSpace: 'break-spaces',    // Any extra white space at end of a line is carried over to next line.
  overflowWrap: 'break-word',   // Same as setting wordWrap
  fontFamily: 'sans-serif',
  fontSize: DESCRIPTION_EDITOR_FONT_SIZE,
}

export const TEXTAREA_OVER_DIV_StyleTextArea: CSSProperties = {
  ...TEXTAREA_OVER_DIV_StyleDiv,
  color: 'transparent',
  background: 'transparent',
  caretColor: 'black',
  resize: 'none',
}

export const CUSTOM_SELECT_POPUP_MENU_OUTLINE = {
  background: 'white',
  borderWidth: 3,
  borderStyle: 'solid',
  borderColor: COLHEADER_INPUT_BORDER_COLOR,
  listStyleType: 'none',
  zIndex: 99,
  borderRadius: '5px',
  boxShadow: '0 3px 12px rgba(0, 0, 0, 1)',
}

// SYSTEM COLORS -- Search CSS Color Module Level 4 for how
// systems define there default colors.  I've done nothing at this time
// to force us to the same colors when/where appropriate.

const constants = {

  RESERVED_HYPERLINK_LABEL_TEXT,

  PUBLISHER_DEFAULT_TITLE,

  MIN_COL_WIDTH_AT_NOMINAL_SCALE,
  MAX_NUMBER_PINNED_ROWS,
  STRING_CELL_MAX_DISPLAYED_LENGTH,

  TABLE_CONTROLS_OPACITY,

  TEXTAREA_OVER_DIV_StyleTextArea,
  TEXTAREA_OVER_DIV_StyleDiv,

  MAX_NUM_SERIES_XY_PLOTS,

  MAX_LENGTH_TABLE_TITLE,
  MAX_LENGTH_PLOT_TITLE,

  MAX_LENGTH_COL_UNITS,
  MAX_LENGTH_COL_NAME,
  MAX_LENGTH_PRESUFFIX,

  ROW_SORT_COL_IDS_ARR_MAX_LENGTH,

  DEFAULT_MARK_SHAPE,
  DEFAULT_MARK_SIZE,

  SEARCH_TABLE_ID,
  SEARCH_TABLEDATA_ID,

  SIDEBAR_INTERNAL_WIDTH,
  SIDEBAR_BLUE_BORDER_WIDTH,
  SIDEBAR_BLACK_LEFT_EDGE_WIDTH,
  SIDEBAR_TOTAL_WIDTH,
  SIDEBAR_TOTAL_LEFT_BORDER_WIDTH,
  SIDEBAR_RANGE_OF_MOTION,

  COL_KEY_CANONICAL_FORM,
  FORMULA_INDENT_LENGTH,
  MAX_CHAR_COUNT_PER_EXPRESSION,
  COL_FORMULA_EDITOR_FONT_SIZE,
  SCRY_NUMBER_INPUT_MAX_CHARACTERS,

  DESCRIPTION_EDITOR_FONT_SIZE,

  COL_FORMULA_EDITOR_WIDTH,
  COL_FORMULA_MAX_LINE_LENGTH,

  CONTROL_GAP_WIDTH,
  CONTROL_DISPLAY_WIDTH,
  CONTROL_NAME_WIDTH,
  CONTROL_SLIDER_HEIGHT,
  CONTROL_SLIDER_WIDTH,
  CONTROL_SLIDER_PLUS_DISPLAY_WIDTH,
  SLIDER_TOTAL_WIDTH,

  STYLE_EDITOR_RAISED_BUTTON,
  STYLE_PALETTE_TEXT_INPUT_STYLES,

  FONT_FAMILY_CSS_FORMAT,
  FONT_FAMILY_LOGO_FORMAT,
  //FONT_SIZE,
  NAV_COLUMN_WIDTH,
  SEARCHVIEW_RESERVED_HEIGHT,
  TOP_MENUBAR_HEIGHT,
  HIGHLIGHT_OPACITY_TABLE,
  HIGHLIGHT_OPACITY_XYPLOT,
  HIGHLIGHT_WIDTH,
  HIGHLIGHT_COLOR,
  HIGHLIGHT_COLOR_HALF,
  STRING_EDITOR_MAX_WIDTH,
  STRING_EDITOR_MIN_WIDTH,
  STRING_EDITOR_MAX_HEIGHT,
  STRING_EDITOR_MIN_HEIGHT,
  LINK_COLOR,
  SCRY_BLUE,
  SCRY_LIGHT_BLUE,
  SCRY_VERYLIGHT_BLUE,
  SCRY_WHITE,
  SCRY_DARK_BLUE,
  COLHEADER_INPUT_BORDER_COLOR,
  SCRY_SELECTION_HIGHTLIGHT_COLOR,
  CUSTOM_SELECT_POPUP_MENU_OUTLINE,

  FLOATING_PALETTE_BACKGROUND,
  FLOATING_PALETTE_TITLE_BAR_HEIGHT,
  FLOATING_PALETTE_TITLE_BAR_FONTSIZE,
  FLOATING_PALETTE_BLUE_OUTLINE_WIDTH,
  FLOATING_PALETTE_BLACK_OUTLINE_WIDTH,

  SELECT_MENU_SINGLE_LINE_HEIGHT,

  ALLOWED_LEFT_OVERLAP_OF_EDITOR_WITH_NAVBAR,
  ALLOWED_RIGHT_OVERLAP_OF_EDITOR_WITH_SIDEBAR,
  zeroWidthSpace,
  nonBreakingSpace,
  thinSpaceChar,
  smallPercentChar,
  hairSpaceChar,
  multiplyChar,
  multiply10Exp,
  MultiplyElement,
  divideChar,
  subtractChar,
  doubleSlashChar,
  plusMinusChar,
  italix_x_Char,
  arrowPtsLeft,
  arrowPtsRight,
}

export default constants

export const getViewWidth_sideBarClosed = ( ): number => {
  return  window.innerWidth - NAV_COLUMN_WIDTH - SIDEBAR_TOTAL_LEFT_BORDER_WIDTH
}
