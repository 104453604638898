const joi = require('joi')

const userspiAttributes = {
  email: joi.string()
    .description('The user’s email, as entered by the user'),
  firstname: joi.string().allow(null)
    .description('The user’s given name.'),
  lastname: joi.string().allow(null)
    .description('The user’s family name (surname).'),
  password: joi.string().allow(null)
    .description('The user’s password. Password is write only, never returned from server, since only the password hash is stored.'),
  username: joi.string().regex(/^[A-Za-z]+[-0-9A-Z_a-z]+[0-9A-Za-z]+$/)
    .description('The user’s public username, as entered by the user'),

  // server side managed data (read only to clients)
  email_canonical: joi.string().meta('readonly')
    .description('The user’s email, converted to connonical form.'),
  password_hash: joi.string().allow(null).meta('readonly')
    .description('The user’s password, hashed with bcrypt.'),
  username_canonical: joi.string().meta('readonly')
    .description('The user’s canonical username, all lower case and dashes convered to underscores'),
}

module.exports = userspiAttributes
