import invariant from 'invariant'
import { ScryInputString } from './parseScryInputStringTypes'

export type MatchInfo = {
  name:string,
  text:string,
  start:number,
  end:number,
  value: number | string ,
}

type TagOptions = {
  searchStart: string,
  searchStop: string,
  searchImmediatelyBefore: string,
  searchImmediatelyAfter: string,
  searchStartOfString: boolean,
  searchEndOfString: boolean,
}

export const newScryInputString = (inStrg: string): ScryInputString => {
  return { inputStrg: inStrg,
    matchArr: [{ name: 'unmatched', text: inStrg, start: 0, end: inStrg.length, value: 0 }] }
}

export const SISclearResults = (scryInputString: ScryInputString): void => {
  scryInputString.matchArr = [{ name: 'unmatched', text: scryInputString.inputStrg, start: 0, end: scryInputString.inputStrg.length, value: 0 }]
}
export const SISgetMatchInfo = (scryInputString: ScryInputString, index: number): MatchInfo => {
  if (index === -1 || index >= scryInputString.matchArr.length) return { name: '', text: '', start: -1, end: -1, value: 0 }
  return scryInputString.matchArr[index]
}
export const SISgetIndex = (scryInputString: ScryInputString, name: string, count: number = 0): number => {
  let numFound = 0
  for (let i = 0; i < scryInputString.matchArr.length; i++) {
    let thisTerm = scryInputString.matchArr[i]
    if (thisTerm.name === name) {
      if (count === numFound) { return i }
      numFound++
    }
  }
  return -1
}
export const SIStagEmptySpace = (scryInputString: ScryInputString, tagName: string): void => {
  scryInputString.matchArr.forEach(thisMatch => {
    if (thisMatch.name === 'unmatched' && thisMatch.text.trim().length === 0) { thisMatch.name = tagName }
  })
}
export const SISisCompleteMatch = (scryInputString: ScryInputString): boolean => {
  for (let i = 0; i < scryInputString.matchArr.length; i++) {
    if (scryInputString.matchArr[i].name === 'unmatched') { return false }
  }
  return true
}
export const SIStag = (scryInputString: ScryInputString, regexp: RegExp, name: string, value: number | string, options: Partial<TagOptions>, minLen: number = 1): number => {
  const { searchStart = '', searchStop = '', searchImmediatelyBefore = '', searchImmediatelyAfter = '', searchStartOfString = false, searchEndOfString = false } = options
  invariant(!regexp.global, 'Cannot pass a RegExp with global flag to ScryParse.')

  // Make sure the options are passed correctly.  Difficult to debug if they are not.
  if (process.env.NODE_ENV === 'development') {
    let keys = Object.keys(options)
    keys.forEach( thisKey => {
      if (
        thisKey === 'searchImmediatelyAfter' ||
        thisKey === 'searchImmediatelyBefore' ||
        thisKey === 'searchStartOfString' ||
        thisKey === 'searchEndOfString' ||
        thisKey === 'searchStart' ||
        thisKey === 'searchStop' ) {
        // All's well!
      } else {
        invariant( false, `Unrecognized option ${thisKey} passed to ScryInputString class.` )
      }
    })
  }
  let arrIndex
  let startIndex = 0
  let endIndex = scryInputString.inputStrg.length
  let thisTerm: MatchInfo | null = null
  let patchStart: number = 0
  let patch: string = ''
  let m: RegExpExecArray | null = null

  if (searchStartOfString === true) {
    if (scryInputString.matchArr[0].name !== 'unmatched') { return -1 }
    startIndex = scryInputString.matchArr[0].start
    endIndex = scryInputString.matchArr[0].end
  }
  if (searchEndOfString === true) {
    let lastSubStringIndex = scryInputString.matchArr.length - 1
    if (scryInputString.matchArr[lastSubStringIndex].name !== 'unmatched') { return -1 }
    startIndex = scryInputString.matchArr[lastSubStringIndex].start
    endIndex = scryInputString.matchArr[lastSubStringIndex].end
  }
  if (searchStart !== '') {
    arrIndex = SISgetIndex(scryInputString, searchStart)
    invariant(arrIndex >= 0, `Following searchStart subsegment in ScryInputString does not exist: "${searchStart}"`)
    if (arrIndex !== -1) { startIndex = scryInputString.matchArr[arrIndex].start }
  }
  if (searchStop !== '') {
    arrIndex = SISgetIndex(scryInputString, searchStop)
    invariant(arrIndex >= 0, `Following searchStop subsegment in ScryInputString does not exist: "${searchStop}"`)
    if (arrIndex !== -1) { endIndex = scryInputString.matchArr[arrIndex].end }
  }
  if (searchImmediatelyAfter !== '') {
    arrIndex = SISgetIndex(scryInputString, searchImmediatelyAfter)
    invariant(arrIndex >= 0, `Following searchAfter subsegment in ScryInputString does not exist: "${searchImmediatelyAfter}"`)
    if (arrIndex === -1 || arrIndex === scryInputString.matchArr.length - 1) { return -1 }
    // If the text immediately following this named term is already assigned a name, return false
    if (scryInputString.matchArr[arrIndex + 1].name !== 'unmatched') { return -1 }
    startIndex = scryInputString.matchArr[arrIndex + 1].start
    endIndex = scryInputString.matchArr[arrIndex + 1].end
  }
  if (searchImmediatelyBefore !== '') {
    arrIndex = SISgetIndex(scryInputString, searchImmediatelyBefore)
    invariant(arrIndex >= 0, `Following searchBefore subsegment in ScryInputString does not exist: "${searchImmediatelyBefore}"`)
    // If we can't find the named term, or named term is already at start of string, return failure
    if (arrIndex === -1 || arrIndex === 0) { return -1 }
    // If the text immediately preceeding this named term is already assigned a name, return false
    if (scryInputString.matchArr[arrIndex - 1].name !== 'unmatched') { return -1 }
    startIndex = scryInputString.matchArr[arrIndex - 1].start
    endIndex = scryInputString.matchArr[arrIndex - 1].end
  }

    // Search each currently unmatched 'patch' in inputStrg
    // Search stops ( loop breaks ) at first successful match
    //console.log('search of string, with regExp:', this.inputStrg, regexp )
  for (arrIndex = 0; arrIndex < scryInputString.matchArr.length; arrIndex++) {
    thisTerm = scryInputString.matchArr[arrIndex]
    if (thisTerm.name !== 'unmatched') { continue }
    if (thisTerm.start < startIndex) { continue }
    if (thisTerm.end > endIndex) { continue }
    patchStart = thisTerm.start
    patch = scryInputString.inputStrg.slice(patchStart, thisTerm.end)
    while (true) {
        // matching is not a yes/no question.
        // We may find a match that is < minLen.
        // So finding a match does not necessary return a tagged substring.
        // Nor can we stop if we find a match < minLen.  We need to 'skip'
        // over the 'too short' match, and check for another
        // match (within this same patch) that is >= minLen.
        // We are done with this while loop ONLY if we 'm' is returned
        // undefined, OR we tag the first valid match > minLen.
      m = regexp.exec(patch)
      if (m) {
        invariant(!(m && !m[1]), `RegExp: ${regexp.source} passed to ScryInputString MUST be structured to return the match in m[1]`)
        invariant(!(m && m[2]), `RegExp: ${regexp.source} passed to ScryInputString MUST be structured to return m and m[1] only. Never m[2]. Use non-capturing groups (?: )`)
        // We consider this a 'match' IFF the matched string's length exceeds minLen
        if (m[1].length >= minLen) break
        // Case where we found a match < minLen.
        // Doesn't mean there is not a 2nd match at some later position in the patch
        // Trim the current match from the left side of patch.
        // Then ask the match? question again.
        patchStart += m.index + m[1].length
        patch = scryInputString.inputStrg.slice(patchStart, thisTerm.end)
      } else {
        break // Always exit loop on NO match
      }
    } // end while loop
    if (m) break
  } // end for loop

  // Following conditionals ALL necessary to instruct Flow that later references are valid.
  // TODO: review this for typescript
  if ( !(patch && m && m[1] && thisTerm && arrIndex !== null && arrIndex !== undefined && m[1].length >= minLen) ) { return -1 }

  // Here begins the internal state changes for a successful match.
  // Make state changes to matchArr IFF a successful match (or replace).  No exceptions!

  let matchIndexStart = patchStart + m.index // index of patch + index of match within patch
  let matchIndexEnd = matchIndexStart + m[1].length
  let newMatchedTerm = { name: name, text: m[1], start: matchIndexStart, end: matchIndexEnd, value: value }
  let preceedingUnmatchedPatch = { name: 'unmatched', text: scryInputString.inputStrg.slice(thisTerm.start, matchIndexStart), start: thisTerm.start, end: matchIndexStart, value: 0 }
  let trailingUnmatchedPatch = { name: 'unmatched', text: scryInputString.inputStrg.slice(matchIndexEnd, thisTerm.end), start: matchIndexEnd, end: thisTerm.end, value: 0 }
  // Replace the 'unmatched' patch we searched with the newly named (aka matched) term
  scryInputString.matchArr[arrIndex] = newMatchedTerm
  // Maybe insert a trailing unmatched patch:
  if (trailingUnmatchedPatch.start < trailingUnmatchedPatch.end) {
    scryInputString.matchArr.splice(arrIndex + 1, 0, trailingUnmatchedPatch)
  }
  // Maybe insert a preceeding unmatched patch:
  if (preceedingUnmatchedPatch.start < preceedingUnmatchedPatch.end) {
    scryInputString.matchArr.splice(arrIndex, 0, preceedingUnmatchedPatch)
    arrIndex++ // We just shifted the tagged new match by one index to the right
  }
  return arrIndex
}