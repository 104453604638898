import {Component} from 'react'

class Home extends Component {
  render() {

    const alphaTestMessage = 'Alpha Testers:\n\n' +
    "This page not yet designed.\n\n"+
    "Your 'Home' will list:\n"+
    "    Table (including links) to your tables (private and public) as well as all \n" +
    "        plots you've created using 3rd party tables.\n" +
    "    Recent activity by others with respect to your public tables:\n" +
    "        Views, downLoads, # times cloned, # plots created, etc.\n"+
    "    Cumulative activity: Same stats as above, but since table creation.\n"+
    "    Links to search results (a table) that you spent time creating and wish to save.\n"+
    "    Links to your account information \n"+
    "    etc.\n\n" +
    "All users must be signed in and will be routed to the login page.\n\n"

    return (
          <textarea className={'rc_Home'}
            style = {{
              position: 'absolute', left: 0, top: 0,
              marginLeft: 100, marginTop: 20,
              background: 'inherit',
              width: 760, height: 500, resize:'none',
              color: 'green',
              borderWidth: 0,
              fontSize: 20,
            }}
            readOnly={true}
            value={alphaTestMessage}
          />
    )
  }
}

export default Home
