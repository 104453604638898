import type { OptionalHTMLDivElement } from '../types'

import {TableComputedDataContext}   from '../viewTableTriple/TtGetComputedData'
import {useContext}           from 'react'
import invariant              from 'invariant'
import dynamics               from 'dynamics.js'
import {startTimer, logTime, 
        stopTimer}            from '../sharedFunctions/timer'
import {wasSuspendPointerEventsSuccessful,
  restorePointerEvents}       from '../sharedFunctions/pointerEvents'

const SIZE = 20
const OPACITY = [0.2, 0.4, 0.6, 0.8]
const DEBUG = false
const TIMER = false
const durationOfAnimation = (DEBUG) ? 2000 : 200  // Open/close speed in msec  ( 250 nominal )

type ShutterName = 'locked' | 'moving' | 'pinned' | 'rowNum'
type ShutterDomNodesByName = {
  [key in ShutterName]: OptionalHTMLDivElement
}
const shutterDomNodes: ShutterDomNodesByName = {
  moving  : null,
  rowNum  : null,
  locked  : null,
  pinned  : null,
}
const setShutterDomNode = (element: OptionalHTMLDivElement, name: ShutterName ): void => {
  shutterDomNodes[name] = element
}


/* 
Shutter size is the size of the data/pinnedRows to block, plus 4*SIZE tapering opacity on top/bottom sides.
Total height of the shutter is 'dataHeight   + 8*SIZE' 
                            or 'pinnedHeight + 8*SIZE'.

During close-TopToBottom, the shutter's 'top' should slew between:
  Start as 'out-of-view' above the data: -(data+8*Size) as referenced from top of data.
  End as opaque area overlapping the data: -(4*SIZE) as referenced from top of data.

TURN ON DEBUG AND MOST WILL BE CLEAR  :-)
*/


type ShutterProps = {
  windowHeight: number,
  name: ShutterName,
  backgroundColor: string,
  doDataRowsExist: boolean
}
const RenderShutter : React.FC<ShutterProps> = (props) => {
    const {windowHeight, backgroundColor, doDataRowsExist, name } = props
    if ( !doDataRowsExist ) return null
    return (
        <div className={'rc_Shutter'}
          ref={ node=> setShutterDomNode(node, name) }
          style={{
            width:'100%',
            position: 'relative',
            top: windowHeight + 4*SIZE,  // Parking position (no animation) is offscreen to the bottom
          }}
        >
            {/* Consists of a large 'fully blocking' shutter of opacity 1,
                which is the height of the data/pinnedRows to block.
                Plus a 'opacity taper' both above and below the main block
                for a visually smoother transistion.  Math expressions
                including 4*SIZE and 8*SIZE are accounting for the 4 extra
                transition blocks above and below the opaque shutter. */}
            <div style={{height:SIZE, opacity:OPACITY[0], backgroundColor }}/>
            <div style={{height:SIZE, opacity:OPACITY[1], backgroundColor }}/>
            <div style={{height:SIZE, opacity:OPACITY[2], backgroundColor }}/>
            <div style={{height:SIZE, opacity:OPACITY[3], backgroundColor }}/>
            <div style={{height:windowHeight ,opacity:1, backgroundColor }}/>
            <div style={{height:SIZE, opacity:OPACITY[3], backgroundColor }}/>
            <div style={{height:SIZE, opacity:OPACITY[2], backgroundColor }}/>
            <div style={{height:SIZE, opacity:OPACITY[1], backgroundColor }}/>
            <div style={{height:SIZE, opacity:OPACITY[0], backgroundColor }}/>
        </div>
    )
}




export const ActionSort : React.FC = () => {
    const tableComputedData = useContext(TableComputedDataContext)
    const { rowNumbers:widthRowNumbers, lockedAllocated, movingAllocated, lockedLeft,
      centeringOffset: rowNumbersLeft, movingLeft} = tableComputedData.widthObj
    const backgroundColor = tableComputedData.styleObj.fieldColor
    const { dataAllocated, headTop, headerHeight, dataTop, pinnedHeight, 
            doDataRowsExist} = tableComputedData.heightObj
    const pinnedRowsTop = headTop + headerHeight 
    const background = (DEBUG) ? 'green' : backgroundColor
    global_dataHeight = dataAllocated  // Initial value but varies continuously in responsive state.
    global_pinnedHeight = pinnedHeight // Initial value but varies continuously in responsive state.
    return (
      <div className={'rc_ActionSort'}
        style={{
          position:'absolute',
          top:0, left:0,
          height: '100%',
          width:  '100%',
          overflow: 'hidden',
        }}
      >
        <div className={'relativeCoordSystem'} style={{position:'relative'}}>

            <div className={'MovingContainer'}
              style={{
                position: 'absolute', 
                width : movingAllocated,
                height: dataAllocated,
                top   : dataTop,
                left  : movingLeft,
                //background: 'green',
                overflow: 'hidden',
              }}>
                <RenderShutter
                  windowHeight={dataAllocated}
                  name={'moving'}
                  backgroundColor={background}
                  doDataRowsExist={doDataRowsExist}
                />
            </div>

            <div className={'LockedContainer'}
              style={{
                position: 'absolute',
                width : lockedAllocated,
                height: dataAllocated,
                top   : dataTop,
                left  : lockedLeft,
                overflow: 'hidden',
              }}>
                <RenderShutter
                  windowHeight={dataAllocated}
                  name={'locked'}
                  backgroundColor={background}
                  doDataRowsExist={doDataRowsExist}
                />
            </div>


            <div className={'DataRowNumContainer'}
              style={{
                position: 'absolute',
                width : widthRowNumbers,
                height: dataAllocated,
                top   : dataTop,
                left  : rowNumbersLeft,
                overflow: 'hidden',
              }} >
                <RenderShutter
                  windowHeight={dataAllocated}
                  name={'rowNum'}
                  backgroundColor={background}
                  doDataRowsExist={doDataRowsExist}
                />
            </div>


            <div className={'PinnedRowNumContainer'}
              style={{
                position: 'absolute',
                width : widthRowNumbers,
                height: pinnedHeight,
                top   : pinnedRowsTop,
                left  : rowNumbersLeft,
                overflow: 'hidden',
                //background: 'red',
              }}>
                <RenderShutter
                  windowHeight={pinnedHeight}
                  name={'pinned'}
                  backgroundColor={background}
                  doDataRowsExist={doDataRowsExist}
                />
            </div>

        </div>
      </div>
    )
}


// Saved values from the ActionSort rending function.
// These are constant over an animation, but change whenever the table is resized.
let global_dataHeight   : number = 0  
let global_pinnedHeight : number = 0  


const redrawShutter = ( offsetData: number, offsetPinned: number ) : void => {
  //console.log( 'pinnedOffset', offsetPinned, 'dataOffset', offsetData)
  const dataOffsetPx = `${offsetData}px`
  const pinnedOffsetPx = `${offsetPinned}px`
  if (shutterDomNodes.moving) { shutterDomNodes.moving.style.top = dataOffsetPx }
  if (shutterDomNodes.locked) { shutterDomNodes.locked.style.top = dataOffsetPx }
  if (shutterDomNodes.rowNum) { shutterDomNodes.rowNum.style.top = dataOffsetPx }
  if (shutterDomNodes.pinned) { shutterDomNodes.pinned.style.top = pinnedOffsetPx }
}

type ShutterAnimationObj = {
  offsetData: number
  offsetPinned: number
}

const SORT_ANIMATION_NAME = 'Sort Column'

const shutterAnimate = ( command: string, direction: string, reactDispatchFunc: () => void ) : void => {
  // console.log ('Call to animate shutter:', command, direction)
  // Currently using close-topToBottom, open-bottomToTop for my animations
  //console.log( command, direction )
  const closePosition = -4*SIZE
  let startDataOffset: number, stopDataOffset: number, startPinnedOffset: number, stopPinnedOffset: number
  switch( `${command}-${direction}` ) {
    case 'open-topToBottom':
      startDataOffset = closePosition
      stopDataOffset = global_dataHeight
      startPinnedOffset = closePosition
      stopPinnedOffset = global_pinnedHeight
      break
    case 'close-topToBottom':
      startDataOffset = -(global_dataHeight+8*SIZE)
      stopDataOffset = closePosition
      startPinnedOffset = -(global_pinnedHeight+8*SIZE)
      stopPinnedOffset = closePosition
      break

    case 'open-bottomToTop':
      startDataOffset = closePosition
      stopDataOffset = -(global_dataHeight+8*SIZE)
      startPinnedOffset = closePosition
      stopPinnedOffset = -(global_pinnedHeight+8*SIZE)
      break
    case 'close-bottomToTop':
      startDataOffset = global_dataHeight
      stopDataOffset = closePosition
      startPinnedOffset = global_pinnedHeight
      stopPinnedOffset = closePosition
      break
    default:
      invariant (false, 'Illegal argumuments passed to shutterAnimate' )
  }

  dynamics.animate (
    // First two args are Start && Stop values
    { offsetData: startDataOffset,  offsetPinned: startPinnedOffset},
    { offsetData: stopDataOffset,   offsetPinned: stopPinnedOffset},
    { // Options:
      change: (obj: ShutterAnimationObj)=> { redrawShutter ( obj.offsetData, obj.offsetPinned ) },
      complete:( ) => {
        //console.log( 'shutter animation complete', command ) 
        if (command === 'close' ) {
            logTime( 'id_Sort', 'End Close Shutter animation.' )
            reactDispatchFunc( )  // state change; tableComputedData execution; sorted table rendered;
            window.requestAnimationFrame(  ()=>{
              logTime( 'id_Sort', 'Begin Open Shutter animation.' )
              shutterAnimate( 'open', 'bottomToTop', () => {} )
            })
        }
        if (command === 'open') {
            restorePointerEvents(SORT_ANIMATION_NAME)
            logTime( 'id_Sort', 'End of Open Shutter animation.' )
            stopTimer( 'id_Sort' )    
        }
      },
      duration: durationOfAnimation,
    }
  )
}


export const sortHandler = ( reactDispatchFunc: () => void ) : void => {
  if (!wasSuspendPointerEventsSuccessful( SORT_ANIMATION_NAME )) { return }
  if (TIMER) { startTimer( 'id_Sort') }
  logTime( 'id_Sort', 'Start of Close Shutter animation.' )
  shutterAnimate( 'close', 'topToBottom', reactDispatchFunc)
}
