import fastDeepEqual from 'fast-deep-equal'
// import invariant from 'invariant'

// const isObjectRecursive = (obj: any, objChain: Array<Object> = []): boolean => {
//   if (obj && typeof obj === 'object') { // null is an object, but not one to examine
//     if (objChain.indexOf(obj) >= 0) {
//       // console.log(objChain)
//       return true
//     } else {
//       objChain.push(obj)
//       const keys = Object.keys(obj)
//       for (let i = 0; i < keys.length; i++) {
//         if (isObjectRecursive(obj[keys[i]], objChain)) {
//           return true
//         }
//       }
//       objChain.pop()
//     }
//   }
//   return false
// }
//
// export const isObjectRecursiveTest = () => {
//   const a = {a: 'apple'}
//   invariant(isObjectRecursive(a) === false, 'test a failed')
//   const b = {b: 'boy', recurse: {}}
//   b.recurse = b
//   invariant(isObjectRecursive(b) === true, 'test b failed')
//   const c = {c: {d: {e: {}}}}
//   c.c.d.e = c
//   invariant(isObjectRecursive(c) === true, 'test c failed')
//   const d = {d: 'dog'}
//   const e = {d1: d, d2: d, d3: d}
//   invariant(isObjectRecursive(e) === false, 'test d failed')
// }

// isObjectRecursiveTest()

// basically fast-deep-equal, but with added support for functions
export function equivalent(a: any, b: any): boolean {
  
  if (a === b) {
    return true
  }

  if (typeof a === 'function' && typeof b === 'function') {
    return a.toString() === b.toString()
  }

  return fastDeepEqual(a, b)
}

export default equivalent
