import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { Component } from 'react'
import { Button, Menu, MenuItem, Wrapper } from 'react-aria-menubutton'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import type { RouteComponentProps } from 'react-router-dom'
import { setAuthToken } from '../redux/appReducer'
import type { RootState } from '../redux/store'

import './NavUserMenu.css'

const DEBUG = true

type RouteMatchProps = {
}
type OwnProps = {
}
type StateProps = {
  loggedIn: boolean
  username: string
  role: string
  userid: string
  token: string
}
type DispatchProps = {
  removeAuthToken: () => void
}
type Props = RouteComponentProps<RouteMatchProps> & OwnProps & StateProps & DispatchProps

class NavUserMenuRender extends Component <Props> {


  onSelection = (value: string): void => {
    const {history, removeAuthToken, userid} = this.props
    switch (value) {
      case 'profile':
        history.push(`/user/${userid}/tables`)
        break
      case 'stars':
        history.push(`/user/${userid}/stars`)
        break
      case 'my account':
        history.push('/settings')
        break
      case 'error':
        throw new Error('Test Error')
      case 'signout':
        removeAuthToken()
        history.push('/')
        break
      case 'userCreate':
        history.push('/userCreate')
        break
      default:
        break
    }
  }

  render() {
    const {loggedIn, username, role} = this.props
    const linkStyle = {
      color: '#fff',
      fontWeight: 'bold',
      textDecoration: 'none',
    }
    if (loggedIn) {
      return (
        <Wrapper className='NavMB' onSelection={this.onSelection}>
          <Button className='NavMB-trigger'>{username ? username : 'Settings'}</Button>
          <Menu>
            <ul className='NavMB-menu'>
{ /*
              <li className='NavMB-menuItemWrapper'>
                <MenuItem className='NavMB-menuItem' value='profile'>Your Profile</MenuItem>
              </li>
              <li className='NavMB-menuItemWrapper'>
                <MenuItem className='NavMB-menuItem' value='stars'>Your Stars</MenuItem>
              </li>
              <hr/>
              <li className='NavMB-menuItemWrapper'>
                <MenuItem className='NavMB-menuItem' value='settings'>Settings</MenuItem>
              </li>
*/  }
{DEBUG &&
              <li className='NavMB-menuItemWrapper'>
                <MenuItem className='NavMB-menuItem' value='error'>Throw Error</MenuItem>
              </li>
}
{role === 'SCRY_ADMIN' &&
              <li className='NavMB-menuItemWrapper'>
                <MenuItem className='NavMB-menuItem' value='userCreate'>Create User</MenuItem>
              </li>
}
              <li className='NavMB-menuItemWrapper'>
                <MenuItem className='NavMB-menuItem' value='signout'>Sign out</MenuItem>
              </li>
            </ul>
          </Menu>
        </Wrapper>
      )
    } else {
      return (
        <div>
          <Link
          style={linkStyle}
          to='/login'>Sign in</Link>{/* or <Link
            style={linkStyle}
            to='/join'>Sign up</Link>*/}
        </div>
      )
    }
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const userid = state.app?.authUserId ?? ''
  //login component will load user attributes
  //on page refresh, TopLevelUI will load user attributes
  const userAttributes = state.api?.resources?.users?.[userid]?.attributes ?? {}

  return {
    loggedIn: !!state.app?.authToken,
    username: userAttributes.username ?? '',
    role: userAttributes.role ?? '',
    userid,
    token: state.app?.authToken ?? '',
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps => (
  {
    removeAuthToken: (): void => {
      dispatch(setAuthToken(''))
    },
  }
)

const NavUserMenuConnected = connect(mapStateToProps, mapDispatchToProps)(NavUserMenuRender)
const NavUserMenu = withRouter(NavUserMenuConnected)
export default NavUserMenu
