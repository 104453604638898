import type { StatsBarLayout } from '../computedDataTable/getDefaultTableComputedData'
import type {
  ColumnStats,
  StatsDataType
} from '../computedDataTable/updateStatsShared'
import type { DerivedFilterRule } from '../sharedFunctions/filterRows'
import type { FormattingObj, NumberFormattingMode } from '../sharedFunctions/numberFormat'
import type {
  ColValues, InternalColDataType, ActiveFp,
  Table, TableValues,
  Tabledata,
  Tablelook
} from '../types'
import type { ScryFormula } from './formulaTypes'
import type {
  FormattedTableDataMode,
  FormattedTableDataReturnType,
  ErrorRows,
  GetTableValue,
  TableComputedData,
  TableLayoutProps
} from './getDefaultTableComputedData'


import invariant from 'invariant'
import { isEqual, list } from 'radash'
import { create_sKeyArrStrg_from_seriesOrder } from '../computedDataPlotXY/plotUtils'
import { getDefaultDerivedColAttributes } from '../computedDataTable/getDefaultTableComputedData'
import { createDerivedFilterRuleArray, filterRows_memoizedFunc } from '../sharedFunctions/filterRows'
import { getMemoizedResult, hashParamsAndRefs } from '../sharedFunctions/getObjectDiff'
import { getHyperlinkLabel } from '../sharedFunctions/isTypeHyperlink'
import { getFormattingObj, lookupTableForForcedUnits, numberFormat, 
         stringFormat } from '../sharedFunctions/numberFormat'
import { logTime, startTimer, stopTimer } from '../sharedFunctions/timer'
import { deepClone } from '../sharedFunctions/utils'
import { formattingOptions_by_colDataType } from '../types'
import {
  getDefaultStatsBarLayout,
  getDefaultTableComputedData
} from './getDefaultTableComputedData'
import { heightCalculator, styleCalculator, widthCalculator } from './layoutCalculator'
import {
  calcDependentColumnValues_memoizedFunc,
  calcNewScrollTop,
  colDataErrorCheck_memoizedFunc,
  createCircularDependencyErrorMessage,
  createStatsBarLayout_memoizedFunc,
  errorCheckColNames,
  findShortestCircularReferencePath,
  formulaParseAndErrorCheck_memoizedFunc,
  getErroneousRowNames2_memoizedFunc,
  getInputArgColKeysAndErrorCheckInputs,
  errorCheckColOrder,
  getDerivedColOrder,
  sortRows,
  calcColumnStats_worker,
} from './updateTableSupportFuncs'
//import { LightweightMod }   from '../types'
import constants from '../sharedComponents/constants'
//import { TextDecoder } from 'util'

const isTIMER_ENABLED = false   // When timing, set DEBUG to false; Console.log takes a lot of time.
const DEBUG = false
const lastVal = (a:Array<any>):any => a[ a.length-1 ]

export type TableComputedDataParams = {
  menuOption_isEditMode: boolean
  username: string
  tableHeight: number
  tableWidth: number
  tableWidthIncludingSideBar: number
  renderIndex: number
  sessionStateScrollLeft: number
  sessionStateScrollTop: number
  sessionStateActiveFp: ActiveFp
  activeStatsColKey: number
  isPublisherRendered: boolean
  isSearchBarRendered: boolean
  isTableGridRendered: boolean
}

export type TableComputedDataRefs = {
  table: Table
  tabledata: Tabledata
  tablelook: Tablelook
}

export type TableComputedDataOtherArgs = {
  priorTableComputedData: TableComputedData
}

export type TableComputedDataResult = {
  tableComputedData: TableComputedData
}

export type BasicFormulaParams = {
  colTitleArr: string[]
  isBadColNameArr: boolean[]
}

export type FormulaParams = BasicFormulaParams & {
  // This information is for cross-column error checking.
  isDeletedArr: boolean[]
  isDepColumnArr: boolean[]
  internalDataTypeArr: InternalColDataType[]
  isBadColNameBecauseRedundantArr: boolean[]
  useOptimization: boolean
  isJestTestCall: boolean
  formula: string[]
  colKey: number
}
export type FormulaRefs = {}
export type FormulaOther = {}
export type FormulaResult = {
  parsedScryFormula: ScryFormula
  isBadFormulaSyntax: boolean
}


export type DepValuesParams = {
  colKeyDepCol: number
  isNotCalculableDepCol: boolean
  isMissingDepCol: boolean
  inputColKeys: number[]
  isErroneousInputs: boolean[]
  isMissingInputs: boolean[]
  parsedScryFormula: ScryFormula
}
// TODO: JKS talk to JPS about not using an object like a sparse array
type DepValuesRefsBase = {
  [key: number]: ColValues
}
export type DepValuesRefs = DepValuesRefsBase & {
  //parsedScryFormula: ScryFormula
}
export type DepValuesOther = {
  tableValuesWorking: TableValues
}
export type DepValuesResult = {
  newColData: ColValues
  errorObj: ErrorRows
  isMissing: boolean
}


export type ColErrorParams = {
  internalDataType: InternalColDataType
}
export type ColErrorRefs = {
  columnData: ColValues
}
export type ColErrorOther = {}
export type ColErrorResult = {
  erroneousCells: ErrorRows
  missingCells: ErrorRows
}


export type RowNameErrorParams = {
  keyColumns: number[]
  numRows: number
}
export type ColKeyToColValuesMap = {
  [key: number]: ColValues
}
export type RowNameErrorRefs = ColKeyToColValuesMap
export type RowNameErrorOther = {}
export type RowNameErrorResult = {
  duplicateRowNames: ErrorRows
  missingRowNames: ErrorRows
  doesKeyColumnExist: boolean
}


export type FilterParams = {
  derivedFilterRules: DerivedFilterRule[]
  numRows: number
}
export type FilterRefs = ColKeyToColValuesMap
export type FilterOtherArgs = {
  DEBUG: boolean
  getTableValue: GetTableValue,
}
export type FilterResult = {
  filterRuleCounts: number[]
  filteredRowKeys: number[]
}


export type SortParams = {
  rowSortColIds: string[]
}
export type SortRefs = {}  // Perhaps needs to be 'never' ?  TODO: JPS
export type SortOtherArgs = {
  hideErroneousValues: boolean
  internalDataTypes: InternalColDataType[]
  numRowsUnfiltered: number,
  getTableValue: GetTableValue,
}
export type SortResult = {
  sortedRowKeys: number[]
}


export type StatsHashParams = {
  colKey: number    // Not needed as reflected in columnRef.  Passed as useful debug info.
  statsDataType: StatsDataType  // InternalDataType | 'booleanTrueFalse'
  // We DON'T need the filtering objects!
  // Changes in filtering, are reflected as changes in rowKeys[]
}
export type StatsHashRefs = {
  columnRef: ColValues
  rowKeys: number[]
  erroneousCells: ErrorRows
}
export type StatsHashOtherArgs = {
  DEBUG: boolean,
  tableid: string
}
export type StatsParams = {
  statsHashCode: number
}
export type StatsRefs = {}
export type StatsOtherArgs = StatsHashParams & StatsHashRefs & StatsHashOtherArgs
export type StatsResult = null | {
  columnStats: ColumnStats
}


export type StatsBarLayoutParams = {
  colTitle: string,
  colDataType: string,
  formatRule: string,
  formattingObj: FormattingObj,
  fontSize: number,
  canEdit: boolean,
}
export type StatsBarLayoutRefs = {
  columnStats: ColumnStats
}
export type StatsBarLayoutResult = {
  statsBarLayout: StatsBarLayout
}



export const updateTableComputedData_memoizedFunc = (inputParamsObj: TableComputedDataParams,
  inputRefsObj: TableComputedDataRefs, inputOtherArgsObj: TableComputedDataOtherArgs): TableComputedDataResult => {

  //console.log( 'Call tableComputedData', inputParamsObj.tableWidth )
  if (DEBUG) { console.log(`\n  CALLING updateTableComputedData( )`) }
  if (isTIMER_ENABLED) { startTimer('id_TableComputedData') }

  // Starting point (initialization) of a new tableComputedData is the prior calculated value !!
  // No need to create an new object reference.  In practice we will only
  // overwrite the prior tableComputedData with data that must change.
  // Changing the tableid will effectively overwrite the entire structure.
  // Edits on the same tableid will replace a minimal amount of information.
  // Difficult computations use memoized values from the prior calculations (if available),
  // otherwise, this function slows down to calculate the newly needed results.
  // 'Fast-to-compute' data is done every pass through this function.
  //
  // tableComputedData MUST be a superset of information, specifically:
  //    table, tablelook, tabledata, user, and owner resources
  // A renderedTable should be a function of only the tableComputedData.
  //
  // Not a requirement, just a convention we've choosen.  May still be
  // exceptions to the 'convention', but any exceptions are not intentional.

  const { table, tablelook, tabledata } = inputRefsObj
  const { tableWidth, tableHeight, username, menuOption_isEditMode,
    sessionStateScrollLeft, sessionStateScrollTop, sessionStateActiveFp,
    isPublisherRendered, renderIndex, tableWidthIncludingSideBar,
    isTableGridRendered, activeStatsColKey } = inputParamsObj
  const { priorTableComputedData } = inputOtherArgsObj
  const numCols = table.attributes.columns.length

  // What is a good starting point?
  // The getDefaultTableComputedData value will always work as a starting point.
  // But it doesn't carry over any memoized results from the last calculation.
  // Hence, it is the worse case (slowest) test case for this function.
  // At this time getDefaultTableComputedData is used IFF:
  //    - tableid changes && new tableid has NO prior memoized data.
  //    - numRows changed (Could be made faster, but rare event not worth the coding)

  const shouldStartFromScratch = priorTableComputedData === undefined ||
                                    table.id !== priorTableComputedData.tableid ||
                                    tabledata.attributes.tableValues[0].length !==
                                    priorTableComputedData.numRowsUnfiltered

  let tCD: TableComputedData
  if (shouldStartFromScratch) {
    tCD = getDefaultTableComputedData(numCols)
  } else {
    // Case of starting from tableComputedData
    // Only thing we really desire or re-use is the prior memoized results.
    // Aug/2024 - Added sortedRowKey
    tCD = { ...priorTableComputedData }
  }

  // These are parameters from sessionState that map to tableComputedData
  tCD.scrollLeft = 0  // Ignore the values from the last sessionState or update
  tCD.scrollTop = 0  // In rare occasions, they MAY be invalid because of window
  // sizing change since last sessionState value was written.
  // Later in the function (after widthObj and heightObj are calced)
  // we will put constraints on the scroll positions, such that
  // it is the 'closest legal value' to the sessionState value.
  // Rarely will the constraint actually be needed.
  tCD.currentTableTriplet = 'activeTableTriplet'
  tCD.sessionStateRenderIndex = renderIndex
  tCD.sessionStateActiveFp = sessionStateActiveFp

  tCD.tableid = table.id
  tCD.tablelookid = tablelook.id
  tCD.tabledataid = tabledata.id

  tCD.table = table
  tCD.tabledata = tabledata
  tCD.tablelook = tablelook

  tCD.username = username
  tCD.menuOption_isEditMode = menuOption_isEditMode
  tCD.tableWidth = tableWidth
  tCD.tableHeight = tableHeight
  tCD.tableWidthIncludingSideBar = tableWidthIncludingSideBar

  tCD.isPublisherRendered = isPublisherRendered
  tCD.isTableGridRendered = isTableGridRendered

  const userid       = tCD.userid       = tablelook.relationships?.owner?.data.id
  const tableOwnerid = tCD.tableOwnerid = table.relationships?.owner?.data.id
  const canEdit      = tCD.canEdit      = (userid === tableOwnerid && menuOption_isEditMode)
  const numLockedCols= tablelook.attributes.numLockedCols
  const numRowsUnfiltered = tCD.numRowsUnfiltered = tabledata.attributes.tableValues[0].length
  
  // Straight copies of some attributes:
  tCD.pinnedRowKeys     = tablelook.attributes.pinnedRowKeys

  // Fast access to commonly used resource information.
  const {
    memoizedColumnCellErrors_byColKey,
    memoizedParsedFormula_byColKey,
    memoizedTableValuesDep,
    memoizedErroneousRowNames,
    memoizedFilteredRowKeysArr,
    memoizedRowOrder,
    memoizedStats,
  } = tCD

  const { columns, isSortable } = table.attributes
  const { lookColumns, rowFilters, rowSortColIds } = tablelook.attributes
  const hideErroneousValues = (!canEdit)

  // We never directly touch the tableValues, whether they come from
  // the tableData resource (independent columns) or from our column caculator
  // memoized result (dependent columns).  But we need a reference to each
  // column of data, in one common array which I will call
  //     tableValuesWorking
  // These columns begin as empty arrays of unknown (don't care) references.
  //
  // HERE, we copy the tableData independent column references to tableValuesWorking.
  // LATER - when dependent column formulas are calculated, memoizedTableValuesDep is
  // the true owner of the tableValues for dependent columns, and those references
  // will be transferred to tableValuesWorking.
  //
  // Hence, the purpose of tableValuesWorking is:
  //     Holds the references to column data, so we can track when/if colData changes.
  //     The App's access to the data, however I never access this array
  //         directly, but only indirectly through getTableValue()
  //     NEVER access this array directly, unless it becomes a neccessity.
  //     Otherwise, keep to the rule that all cell value access is via getTableValue()
  //
  // This array is always 'filled' in with references.
  // Hence, doesn't matter what is already present in the array at this point in time.
  // The old data is almost always valid, but ALMOST doesn't help.
  // In the case where we clone a column, the existing tableValuesWorking is 'one column short'!
  // I simply recreate the tableValuesWorking array on every pass. (guarantees the proper length)
  // Which means we MUST always write the calculated depCol values to this array:
  //       1) IF we are calculating first time, each row value is written one by one to tableValues
  //       So we will see them in our working tableValuesWorking when memoization func returns.
  //       2) However, if  memoization returns a saved value, then we MUST copy the array reference
  //       to tableValuesWorking because the RPN execution does NOT run and does Not fill in
  //       the tableValuesWorking for us.
  const tableValuesWorking = list(columns.length - 1).map(() => new Array(0))
  const { tableValues } = tabledata.attributes
  for (const [colKey, thisCol] of columns.entries()) {
    if (!thisCol.isDepCol) {
      tableValuesWorking[colKey] = tableValues[colKey]
    }
  }
  tCD.tableValuesWorking = tableValuesWorking


  // MUST errCheck colNames as a complete group, in order
  // to find redundant colNames.
  // This does the full verification of colName errors:
  //     missing, reserved, or redundant, ...
  const errorCheckArray = errorCheckColNames(columns)
  let reUsedColKeys: number[] = []
  let reCalcColKeys: number[] = []

  ////////////////////////////////////////////////////////////////////////
  // CREATE DERIVED COLUMN ATTRIBUTES;
  // We set some (~half) of the attributes.
  // Others are currently defaults, to be set as they are calculated.
  ////////////////////////////////////////////////////////////////////////

  const newColumns = []
  const globalScale = tablelook.attributes.globalScale
  for (const [colKey, thisCol] of columns.entries()) {
    const lookColumnAttributes = lookColumns[colKey]
    newColumns[colKey] = {
      ...getDefaultDerivedColAttributes(),
      ...tCD.derivedColAttributesArray[colKey],
      colTitle: thisCol.colTitle,
      // We force a legal width at this time, (as opposed to inside the layoutCalculator).
      // Because we need the layoutCalculator to violate this minimum width rule when
      // the calculator is used for animations, specifically hiding or unHiding a column.
      constrainedScaledColWidth: Math.round(Math.max(constants.MIN_COL_WIDTH_AT_NOMINAL_SCALE, lookColumnAttributes.width) * globalScale) ,
      hiddenVal: lookColumnAttributes.hidden,
      isHidden: lookColumnAttributes.hidden > 0,
      colDataType: thisCol.colDataType,
      isKey: thisCol.isKey,
      internalDataType: (thisCol.colDataType.slice(0, 6) === 'number') ? 'number' : thisCol.colDataType as InternalColDataType,
      isDepCol: thisCol.isDepCol,
      isDeleted: thisCol.isDeleted,
      isDeletedNotRestorable: thisCol.isDeletedNotRestorable,
      deletedDate: thisCol.deletedDate,
      badColNameErrorID: errorCheckArray[colKey].errorID,
      isBadColName: (errorCheckArray[colKey].errorID !== ''),
      isBadColNameBecauseRedundant: (errorCheckArray[colKey].errorType === 'redundant'),
    }
  }
  const derivedColAttributesArray = tCD.derivedColAttributesArray = newColumns

  /////////////////////////////////////////////////////////////////////////
  //  Column Formatting:
  /////////////////////////////////////////////////////////////////////////

  for (const [i, thisLook] of lookColumns.entries()) {
    const { colDataType, units } = columns[i]
    let formatRule = thisLook.formatRule
    if (formattingOptions_by_colDataType[colDataType].indexOf(formatRule) === -1) {
      // We have an potential problem where column.colDataType and lookColumn.formatRule
      // are NOT orthogonal.  A colDataType and the formatRule may be incompatible!
      // This should ONLY occur if the column.colDataType is changed by table owner,
      // and the linked tablelook.formatRule (owed by another person) is no longer
      // a legal option.  In this rare case we will use the 1st formatting option
      // available from the legal list.  We will NOT change the lookColumn.formatRule
      // saved state.  Up to the tablelook owner to change this recorded state if they
      // so choose to change the displayed default format.
      formatRule = formattingOptions_by_colDataType[colDataType][0]  // 1st option in available formats is the default
      derivedColAttributesArray[i].formatRule = formatRule
      derivedColAttributesArray[i].formattingObj = getFormattingObj(formatRule)
    } else { // expected path:
      derivedColAttributesArray[i].formatRule = formatRule 
      derivedColAttributesArray[i].formattingObj = getFormattingObj(formatRule, { ...thisLook })
    }
    derivedColAttributesArray[i].units = units
    const { shouldForceUnits, forcedUnits } = lookupTableForForcedUnits(colDataType, formatRule)
    derivedColAttributesArray[i].shouldForceUnits = shouldForceUnits
    derivedColAttributesArray[i].forcedUnits = forcedUnits
  }

  if (isTIMER_ENABLED) { logTime('tableComputedData', 'tableComputedData initializations.') }

  ////////////////////////////////////////////////////////////////////////
  // Error check cell values -- ONLY independent columns at this time.
  // This check is memoized.  We must know the 'Cell errors or Missing values'
  // for independent columns PRIOR to calculating the dependent column values.
  ////////////////////////////////////////////////////////////////////////

  reUsedColKeys = new Array(0)
  reCalcColKeys = new Array(0)
  let newMemResults = new Array(0)
  for (const [colKey, thisCol] of derivedColAttributesArray.entries()) {
    if (thisCol.isDeleted || thisCol.isDepCol) { continue }  // uses default initialized of {}
    const colErrorParamsObj: ColErrorParams = { internalDataType: thisCol.internalDataType }
    const colErrorRefsObj: ColErrorRefs = { columnData: tableValues[colKey] }
    const colErrorOtherObj: ColErrorOther = {}
    const maxNumMemoized = 1  // Enough for the user play with three on/off selections (venn diagrams, ... )
    const memoFunc = colDataErrorCheck_memoizedFunc
    const colErrorPriorResults = memoizedColumnCellErrors_byColKey[colKey] // Only one saved prior result per colkey.
    const { result, isNewResult, newMemArr } = getMemoizedResult(colErrorPriorResults, colErrorParamsObj, colErrorRefsObj,
      colErrorOtherObj, memoFunc, maxNumMemoized)
    newMemResults[colKey] = newMemArr
    if (isNewResult) { reCalcColKeys.push(colKey) } else { reUsedColKeys.push(colKey) }
    derivedColAttributesArray[colKey].missingCells = result.missingCells
    derivedColAttributesArray[colKey].erroneousCells = result.erroneousCells

    // We can now do the error check for a column with no valid data of the current dataType
    // Compare the number of rows with the number of keys in the erroneousCells object
    // If equal, every row has a corresponding error key.  There is no valid data.
    // We make an exception for a table of one row.  Is a single missing value truely
    // a dataType misMatch?  I choose the answer 'no', because our search table may
    // return a single line table.  (when no matches) with empty cells.
    const isAllDataMissingOrWrongType = (numRowsUnfiltered > 1 &&
      Object.keys(result.erroneousCells).length +
      Object.keys(result.missingCells).length >= numRowsUnfiltered)
      derivedColAttributesArray[colKey].isMismatchedType = isAllDataMissingOrWrongType   // entire column missing!

  }
  tCD.memoizedColumnCellErrors_byColKey = newMemResults
  if (DEBUG && reUsedColKeys.length > 0) {
    console.log(`  Reuse column ErrorChecking for independent colKeys ${create_sKeyArrStrg_from_seriesOrder(reUsedColKeys)}`)
  }
  if (DEBUG && reCalcColKeys.length > 0) {
    console.log(`  Calc  column ErrorChecking for independent colKeys ${create_sKeyArrStrg_from_seriesOrder(reCalcColKeys)}`)
  }
  if (isTIMER_ENABLED) { logTime('id_TableComputedData', 'Find  missing or erroneous dependent colValues') }

  ////////////////////////////////////////////////////////////////////////
  // Derived column data, reorganized by colKey.
  // Typical format to pass colData attributes to memoized worker functions.
  ////////////////////////////////////////////////////////////////////////

  const isDepColumnArr      = derivedColAttributesArray.map( c => c.isDepCol )
  const isKeyColumnArr      = derivedColAttributesArray.map( c => c.isKey )  // Refers to database 'key' icon in header
  const colTitleArr         = derivedColAttributesArray.map( c => c.colTitle )
  const isDeletedArr        = derivedColAttributesArray.map( c => c.isDeleted )
  const isBadColNameArr     = derivedColAttributesArray.map( c => c.isBadColName )
  const internalDataTypeArr = derivedColAttributesArray.map( c => c.internalDataType )
  const formattingObjArr    = derivedColAttributesArray.map( c => c.formattingObj )
  const erroneousCellsArr   = derivedColAttributesArray.map( c => c.erroneousCells )
  const hiddenValArr        = derivedColAttributesArray.map( c => c.hiddenVal  )
  const isHiddenArr         = derivedColAttributesArray.map( c => c.isHidden  )
  const constrainedScaledColWidthArr    = derivedColAttributesArray.map( c => c.constrainedScaledColWidth )
  const isBadColNameBecauseRedundantArr = derivedColAttributesArray.map( c => c.isBadColNameBecauseRedundant )
   
/*
  // Some temp developer code to test errorCheckColOrder()
      let badColOrder = tablelook.attributes.colOrder.slice()
      let isDeletedArrTest = isDeletedArr.slice()
      isDeletedArr[7] = true
      isDeletedArr[ isDeletedArr.length ] = false   // publisher inserts a new column
      badColOrder.splice( 6,0, 7 ) // publisher inserts a new column
      errorCheckColOrder(isDeletedArr, badColOrder, colTitleArr)

  // Some temp developer code to test the 'repair' functionality in getDerivedColOrder()
      let badColOrder = tablelook.attributes.colOrder.slice()
      let isDeletedArrTest = isDeletedArr.slice()
      isDeletedArr[7] = true   // This should remove colKey 7 from derivedColOrder
      isDeletedArr[5] = true   // This should remove colKey 28 from derivedColOrder
      badColOrder[7] = 3   // This will duplicate colKey 3; 2nd copy should be deleted
                           // Also, it removes what ever colKey was at index 7, and this colKey
                           // should be re-inserted (at end of colOrder as our best guess position.)
      let testResult = getDerivedColOrder( isDeletedArr, badColOrder )
*/

  if ( DEBUG ) { errorCheckColOrder(isDeletedArr, tablelook.attributes.colOrder, colTitleArr) }  
  const derivedColOrder = getDerivedColOrder( isDeletedArr, tablelook.attributes.colOrder )
  tCD.derivedColOrder =  derivedColOrder
  const hiddenVal_ByColIndex = tCD.hiddenVal_ByColIndex = derivedColOrder.map( index => hiddenValArr[index] )
  const isHidden_ByColIndex = tCD.isHidden_ByColIndex = derivedColOrder.map( index => isHiddenArr[index] )
  const colWidth_ByColIndex = tCD.colWidth_ByColIndex = derivedColOrder.map( index => constrainedScaledColWidthArr[index] )


  ////////////////////////////////////////////////////////////////////////
  // Parse & compile the dependent column formulas.
  // This step is memoized because parsing formulas is compute
  // But more importantly, because we need to know whether a parsed column
  // formula has been modified (or not) before we can ask the question
  // of which columns need dependent values re-calculated. (Which is
  // compute intensive)
  //
  // This requires a lot of information beyond just the 'text' of the
  // formula.  We need to do all the required error checking within
  // a parsed formula, PLUS the error checking between columns.
  // For example: Any colTitle change requires all dependent formulas
  // to be re-parsed.  Else how to know whether that formula references
  // the modified colTitle?
  ////////////////////////////////////////////////////////////////////////

  reUsedColKeys = new Array(0)
  reCalcColKeys = new Array(0)
  newMemResults = new Array(0)
  for (const colKey of derivedColOrder ) {
    const thisCol = derivedColAttributesArray[colKey]
    if (!thisCol.isDepCol) { continue }
    // Although MOST of the paramsObj is common across all series,
    // the creation of this object must be NEW, for every colKey.
    // Its NOT reusable because a copy is being kept witjh the memoized result.
    const formulaParamsObj: FormulaParams = {
      // This information is for cross-column error checking.
      colTitleArr, isDeletedArr, isBadColNameArr, isDepColumnArr, internalDataTypeArr,
      isBadColNameBecauseRedundantArr,
      useOptimization: true,
      isJestTestCall: false,
      // Next value is a large memoized structure with parsed and compiled program
      // However, we CANNOT use this as a reference because there is some global
      // data additionally added to the sturcture, concerning whether the input columns
      // to this formula are legal (not deleted, properly parsed formula, ... )
      // Hence we do a deep compare of the entire object.
      formula: columns[colKey].formula,
      colKey: colKey
    }
    const formulaRefsObj: FormulaRefs = {}
    const formulaOtherArgsObj: FormulaOther = {}
    const memoFunc = formulaParseAndErrorCheck_memoizedFunc
    const maxNumMemoized = 2
    const formulaPriorResultsArr = memoizedParsedFormula_byColKey[colKey]
    // <FormulaParams, FormulaRefs, FormulaOther, FormulaResult>
    const { result, isNewResult, newMemArr } = getMemoizedResult(formulaPriorResultsArr, formulaParamsObj, formulaRefsObj,
      formulaOtherArgsObj, memoFunc, maxNumMemoized)
    newMemResults[colKey] = newMemArr
    if (isNewResult) { reCalcColKeys.push(colKey) } else { reUsedColKeys.push(colKey) }
    thisCol.parsedScryFormula = deepClone(result.parsedScryFormula)
    //thisCol.parsedScryFormula = result.parsedScryFormula
    thisCol.isBadFormulaSyntax = (result.parsedScryFormula.errorID !== '')
  }
  tCD.memoizedParsedFormula_byColKey = newMemResults
  if (DEBUG && reUsedColKeys.length > 0) {
    console.log(`  Reuse parsed/compiled formulas for colKeys ${create_sKeyArrStrg_from_seriesOrder(reUsedColKeys)}`)
  }
  if (DEBUG && reCalcColKeys.length > 0) {
    console.log(`  Calc  parsed/compiled formulas for colKeys ${create_sKeyArrStrg_from_seriesOrder(reCalcColKeys)}`)
  }
  if (DEBUG && reCalcColKeys.length + reUsedColKeys.length === 0) {
    console.log(`  No formulas to parse & compile`)
  }
  if (isTIMER_ENABLED) { logTime('id_TableComputedData', 'Parse and compile dependent column formulas') }


  /////////////////////////////////////////////////////////////////////////
  //  Classify each column with 'isNotCalculable', 'isMissing', and flags.
  /////////////////////////////////////////////////////////////////////////
  /*
  DEFINITION OF AN 'isNotCalculable' COLUMN:
      1) A Column cannot be calculated if:
            - Invalid formula (many ways to write an invalid formula)
            - Valid formula, but circular dependency -- (global characteristic)
            - Valid formula, but reference to deleted col -- (global characteristic)
            - DataType that is mismatched to the column values (no valid cells)
            (probably be adding to this list as needed)
      2) Above list DOES NOT include isBadColumnName (missing, redundant, illegal colNames)
         Hence a bad column name does NOT make for the current column be unCalculable. 
         But any other column that depend on this column WILL be isMissing, because of a 
         bad input reference to any column with isBadColumnName.
      3) Either an isNotCalculable OR isBadColName is something that the publisher must fix.
         Either problem will result in a 'RED' column name.
         But a badColName does NOT prevent a column from being calculated.
         With an appropriate error message, depending on the source of error.

  DEFINITION OF A 'isMissingCol' COLUMN
      1) All isNotCalculatable columns.
      2) If an input arg to an otherwise good column 'isMissing' or 'isBadColName'


  Another definition of !isMissing: If we can show current correct cell data,
  then we should, even if the column itself is erroneous (for example a redundant colName.)

  A 'isMissing' column is shorthand for 'All cells are empty'.  However, the
  tableData[][] and depTableData[][] may not actually be empty!  For example,
  the tableData[][] could be full of strings, yet the column is missing because
  the dataType === number.

  A depCol may already be tagged as 'isMissing' (formula error, input arg problem, ... )
  But it may also be tagged at the time of formula calculations.  Because isMissing will
  propagate to 'downstream' dependent columns.  In either case, the logic to calculate
  a column of dependent values includes:
       if (isMissinge) return new Array(numRows).fill('')
  And the column will appear in the table as empty.
  
  Depending of the various flags (isBadColName, isBadFormulaSyntax, isBadFormulaColRef, 
      isMismatchedTyp, isNotCalculable', isMissingCol ) the error message should point to
      the proper source of the problem.  First priority is any error in the current col.
      2nd priority is any error in one of the column dependencies.
      scryFormula.errorID = `Formula references a deleted column ' ${title} '.<br>Select a new column.`
      scryFormula.errorID = `Formula references a column with a missing name.<br>Give all columns a name.`
      scryFormula.errorID = `Formula references duplicate column name ' ${colTitle} '.<br>Rename column or select a new column.`
      scryFormula.errorID = `Formula references illegal column name ' ${colTitle} '.<br>Rename column or select a new column.`
  */

  const inputArgsArr: number[][] = Array.from({ length: derivedColAttributesArray.length }, ( ) => new Array(0))
  const dependentColKeysArr: number[] = new Array(0)  // Flat list of all legal (not deleted) dependent columns.
  for (const colKey of derivedColOrder) {  
    const thisCol = derivedColAttributesArray[colKey]
    if ( isDepColumnArr[colKey] ) {
      dependentColKeysArr.push(colKey)
      const { parsedScryFormula } = thisCol
      //invariant(parsedScryFormula, `in updateTableComputedData isDepColumnArr[colKey] is true, but parsedScryFormula is ${parsedScryFormula}`)
      // If an error for this formula already exist, skip the input dependency error checking
      if (parsedScryFormula?.errorID === '') {
        const result = getInputArgColKeysAndErrorCheckInputs(parsedScryFormula, colKey, derivedColAttributesArray)
        thisCol.isBadFormulaColRef = result.isBadFormulaColRef
        parsedScryFormula.inputArgColKeys = result.inputArgColKeys
        parsedScryFormula.errorID = result.errorID
        parsedScryFormula.highlightArray = result.highlightArray
        inputArgsArr[colKey] = result.inputArgColKeys
      } else {
        inputArgsArr[colKey] = []
      }
    }
  }

  // This block of code tags columns as isErroneous and/or isMissing.
  for (const colKey of derivedColOrder ) {
    const thisCol = derivedColAttributesArray[colKey]
    const { isDepCol, isDeleted, isBadColName, isBadFormulaSyntax, isBadFormulaColRef, isMismatchedType } = thisCol
    thisCol.isNotCalculable = thisCol.isMissingCol = false   // assumptions
    switch (true) {
      case isDeleted :   
          thisCol.isNotCalculable = false   // Don't believe it matters how we set this flag.
          thisCol.isMissingCol = true      // A reference to a deleted column will give error message that input col was deleted
                                           // But we need to set isMissingCol true so it will propagate to potential dependencies.
          break
      case isDepCol :   
          if (isBadFormulaSyntax || isBadFormulaColRef) { thisCol.isNotCalculable = true }
          // An isNotCalculable column is ALWAYS a isMissingCol.
          // But, this column may be calculable, but an input column has some proble (isMissing).
          // One early bad column can create a sequence of column references leading to subsequent isMissingCols.
          if (thisCol.isNotCalculable || isBadFormulaColRef) { thisCol.isMissingCol = true }
          break
      case !isDepCol :   
          if (isBadColName || isMismatchedType) { thisCol.isMissingCol = true }
          break
    }
  }
  const isNotCalculableColArr   = derivedColAttributesArray.map( c => c.isNotCalculable )
  const isMissingColArr     = derivedColAttributesArray.map( c => c.isMissingCol   )

  /*
  NOTE:  There may still be additional 'isErroneous' columns, because we haven't yet
    checked for circular dependencies in otherwise errorless formulas & inputs.

  Determine the proper order for processing dependent columns
  When/if this algorithm gets stuck (cannot find which of a group
  of dependent colKeys must come next), then this MUST be a circular dependency.
  Rare, but important to identify them.
  Every dependent column should appear only once in the outputs:
      dependentColKeysArr.length === colCalculationOrder.length + circularDependencies.length
  */
  const depColumnRecalcOrder: number[] = [];
  let colKeysToProcess = dependentColKeysArr.slice();
  let nextColKeysToProcess: number[] = [];
  
  const isSomeInputUnresolved = (inputColKey: number, colKeysToProcess: number[]): boolean => {
      return inputArgsArr[inputColKey].some(
          (inputColKey) => colKeysToProcess.includes(inputColKey)
      );
  };
  
  while (colKeysToProcess.length > 0) {
      for (const inputColKey of colKeysToProcess) {
          if (isSomeInputUnresolved(inputColKey, colKeysToProcess)) {
              nextColKeysToProcess.push(inputColKey);
          } else {
              depColumnRecalcOrder.push(inputColKey);
          }
      }
      if (nextColKeysToProcess.length === 0) {
          break;  // DONE! all columns have been moved to colCalculationOrder[]
      }
      if (nextColKeysToProcess.length < colKeysToProcess.length) {  // Yet more columns to move.
          colKeysToProcess = nextColKeysToProcess;
          nextColKeysToProcess = [];  // restart loop with an empty array
          continue;
      }
      // Stuck! Must be a circular reference!
      break;
  }
  tCD.depColumnRecalcOrder = depColumnRecalcOrder
  const circularReferences = nextColKeysToProcess

  /////////////////////////////////////////////////////////////////////////
  //    Handling of circular colKey references:
  //    We expect nextColKeysToProcess array to be empty.  Same as saying 'All
  //    dependent columns now appear the the depColumnRecalcOrder array'.
  //    IF NOT, then those columns remaining in nextColKeysToProcess are involved
  //    in some circular reference relationship.  Likely a short circular relationship of
  //    perhaps 2 to 4 colKeys.  But in theory, could be many possible paths over a
  //    large set of colKeys!  Function findShortestCircularReferencePath()
  //    finds the 'shortest' path.  My assumption: "Fixing the shortest path is
  //    both easiest for the user, and likely to break most of any remaining loops".
  //    Hence I will mark ONLY the shortest circular path as isErroneous.  And mark
  //    other colKeys potentially involved in this mess as simply 'isMissing'.
  //    If I am wrong and fixing the shortest loop removes some but not all
  //    circular dependencies, then user will get a new, different shortest loop
  //    and this will also need to be fixed.
  /////////////////////////////////////////////////////////////////////////

  if (circularReferences.length > 0) {
    const shortestCircularPath = findShortestCircularReferencePath(circularReferences, inputArgsArr, isDepColumnArr)
    for (const colKey of circularReferences) {
      derivedColAttributesArray[colKey].isMissingCol = true  // All unresolved dependent colKeys will be 'empty' columns
      if (shortestCircularPath.includes(colKey)) {   // Those involved the in the shortest loop will also show as isErroneous.
        const { parsedScryFormula } = derivedColAttributesArray[colKey]
        invariant(parsedScryFormula, `in updateTableComputedData circularReferences contains colKey ${colKey}, but parsedScryFormula is ${parsedScryFormula}`)
        derivedColAttributesArray[colKey].isBadFormulaColRef = true
        derivedColAttributesArray[colKey].isNotCalculable = true
        parsedScryFormula.errorID =
          createCircularDependencyErrorMessage(colKey, shortestCircularPath, colTitleArr)
      }
    }
  }

  if (isTIMER_ENABLED) { logTime('id_TableComputedData', 'isMissing, isErroneous, recalcOrder, circularDependencies') }

  /////////////////////////////////////////////////////////////////////////
  //  Calculation from:  dependent column formulas
  //                to:  tableValuesDep
  /////////////////////////////////////////////////////////////////////////

  const depValuesOtherArgs = { tableValuesWorking }
  reUsedColKeys = new Array(0)
  reCalcColKeys = new Array(0)
  newMemResults = new Array(0)
  for (const colKeyDepCol of depColumnRecalcOrder) {
    const depValuesParams: DepValuesParams = {
      colKeyDepCol,   // Dependent colKey we are calculating
      isNotCalculableDepCol: isNotCalculableColArr[colKeyDepCol],
      isMissingDepCol: isMissingColArr[colKeyDepCol],
      inputColKeys: inputArgsArr[colKeyDepCol],           // colKeys of this formula's inputs
      isErroneousInputs: inputArgsArr[colKeyDepCol].map(colKey => isNotCalculableColArr[colKey]),
      isMissingInputs: inputArgsArr[colKeyDepCol].map(colKey => isMissingColArr[colKey]),
      parsedScryFormula: derivedColAttributesArray[colKeyDepCol].parsedScryFormula!
    }
    const depValuesRefs = {} //{  parsedScryFormula: derivedColAttributesArray[colKeyDepCol].parsedScryFormula! }
    // Include a reference for each columnData array that is used for filtering
    for (const inputColKey of inputArgsArr[colKeyDepCol]) {
      (depValuesRefs as DepValuesRefsBase)[inputColKey] = tableValuesWorking[inputColKey]
    }
    //John: The Refs here are failing comparison. need to fix.
    const memoFunc = calcDependentColumnValues_memoizedFunc
    const maxNumMemoized = 1
    const { result, isNewResult, newMemArr } = getMemoizedResult(memoizedTableValuesDep[colKeyDepCol], depValuesParams,
      depValuesRefs, depValuesOtherArgs, memoFunc, maxNumMemoized)
    newMemResults[colKeyDepCol] = newMemArr
    if (isNewResult) { reCalcColKeys.push(colKeyDepCol) } else { reUsedColKeys.push(colKeyDepCol) }
    tableValuesWorking[colKeyDepCol] = result.newColData
    derivedColAttributesArray[colKeyDepCol].isMissingCol = result.isMissing
    derivedColAttributesArray[colKeyDepCol].erroneousCells = result.errorObj
  }
  tCD.memoizedTableValuesDep = newMemResults
  if (DEBUG && reUsedColKeys.length > 0) {
    console.log(`  Reuse depColumn calculations for colKeys ${create_sKeyArrStrg_from_seriesOrder(reUsedColKeys)}`)
  }
  if (DEBUG && reCalcColKeys.length > 0) {
    console.log(`  Calc  depColumn calculations for colKeys ${create_sKeyArrStrg_from_seriesOrder(reCalcColKeys)}`)
  }
  if (isTIMER_ENABLED) { logTime('id_TableComputedData', 'Calculate dependent column values.') }

  /////////////////////////////////////////////////////////////////////////
  //  Row Names (key columns) Error Checking:
  //
  //     1) Identify missing and duplicate row names. These are saved in 2 data
  //        structures, so we have options when building error messages.
  //     2) We need to do this BEFORE row sorting, because erroneous cells
  //        are always sorted to the top of a column.  Hence, identifying
  //        erroneous cells (for any reason) must precede sorting.
  //     3) We will create an array of the 'Key columns'
  //        If there are none, then missing and duplicate row names structures
  //        are just 'empty'.  No 'Key columns' error message is easy to
  //        create in the UI interface.
  //        If there are 1 to 'n' columns, then the order will ALWAYS be
  //        sequential for lowest to largest colKey.   Doesn't matter if
  //        the user rearranges the column locations.  Our algorithm here
  //        should be independent of the user's key column's positions.
  //
  /////////////////////////////////////////////////////////////////////////

  const keyColumns = new Array(0)
  for (let i = 0; i < tableValuesWorking.length; i++) {
    if (!isDeletedArr[i] && isKeyColumnArr[i]) { keyColumns.push(i) }
  }

  if (keyColumns.length > 0) {
    const rowNameErrorParamsObj: RowNameErrorParams = { keyColumns, numRows: numRowsUnfiltered }
    const rowNameErrorRefsObj: RowNameErrorRefs = {}
    for (const keyColumn of keyColumns) {
      rowNameErrorRefsObj[keyColumn] = tableValuesWorking[keyColumn]
    }
    const rowNameErrorOtherObj: RowNameErrorOther = {}
    const maxNumMemoized = 4  // Enough for the user to experiment with additional keyCols to find unique keys
    const memoFunc = getErroneousRowNames2_memoizedFunc
    const { result, isNewResult, newMemArr } = getMemoizedResult(memoizedErroneousRowNames, rowNameErrorParamsObj,
                                                    rowNameErrorRefsObj, rowNameErrorOtherObj, memoFunc, maxNumMemoized)
    tCD.memoizedErroneousRowNames = newMemArr
    tCD.duplicateRowNames = result.duplicateRowNames
    tCD.missingRowNames = result.missingRowNames
    tCD.doesKeyColumnExist = result.doesKeyColumnExist
    if (DEBUG) {
      if (isNewResult) { console.log(`  Calc  erroneous row names`) }
      else             { console.log(`  Reuse erroneous row names`) }
    }
  } else { // No key columns
    tCD.duplicateRowNames = {}
    tCD.missingRowNames = {}
    tCD.doesKeyColumnExist = false
  }

  if (isTIMER_ENABLED) { logTime('id_TableComputedData', 'RowNames isDuplicate, isMissing error checking.') }


  /////////////////////////////////////////////////////////////////////////
  //  Create the getTableValue accessorFunction.
  //  This is the fundamental function to retrieve internal values (no formatting).
  //  At the very end of this module, there are various wrappers around this
  //  function, designed for the unique needs of various modules.
  /////////////////////////////////////////////////////////////////////////


  const getTableValue = tCD.getTableValue =
    (colKey: number, rowKey: number, hideErroneousValues: boolean = false): FormattedTableDataReturnType => {
      let value = tableValuesWorking[colKey][rowKey]
      //  isDuplicateRowName && hideErronewousValues ?
      //  Then we will still display the rowName (NOT convert it to an empty string)
      //  Hence if user looks closely they will find one or more duplicate row Names.
      //  This is OK because 'unique row names' are a considered a 'good practice', but
      //  not actually required for the functioning of our table.
      //  In other words, highlight row names in 'red' IFF we are 'showing ErroneousValues' and name is duplicate key.
      const isKey = isKeyColumnArr[colKey]
      const isMissingRowName = (isKey && tCD.missingRowNames[rowKey] !== undefined)
      const isErroneousCell = erroneousCellsArr[colKey][rowKey] === ''
      const isDuplicateRowName = (!hideErroneousValues && isKey && tCD.duplicateRowNames[rowKey] !== undefined)
      const isErroneous = isDuplicateRowName || isMissingRowName || isErroneousCell
      if (isErroneous && hideErroneousValues) { value = '' }
      return { value, isErroneous, isMissingRowName }
    }

  /////////////////////////////////////////////////////////////////////////
  //  Filter rows
  /////////////////////////////////////////////////////////////////////////

  // Next structure fast to calculate.  Not worth memoization code, even though
  // we wish to compare new structure to prior structure.
  const newDerivedFilterRules = createDerivedFilterRuleArray(rowFilters, derivedColAttributesArray)
  // This next value used to potentially set a new scrollTop position (later in the flow)
  const didDerivedFiltersChange = !isEqual(tCD.derivedFilterRuleArray, newDerivedFilterRules)
  tCD.derivedFilterRuleArray = newDerivedFilterRules

  const filterParamsObj = {
    derivedFilterRules: newDerivedFilterRules,
    numRows: numRowsUnfiltered,
  }
  const filterRefsObj: ColKeyToColValuesMap = {}
  // Include a refObj for each columnData array that is used for filtering
  for (const thisRule of filterParamsObj.derivedFilterRules) {
    const { colKey: colKeyVal, enabled } = thisRule
    if (colKeyVal === -1 || !enabled) { continue }   // skip empty and disabled fitler rules.
    filterRefsObj[colKeyVal] = tableValuesWorking[colKeyVal]
  }
  const filterOtherArgsObj = { DEBUG, getTableValue }
  let maxNumMemoized = 8  // Enough for the user play with three on/off selections (venn diagrams, ... )
  const memoFunc = filterRows_memoizedFunc
  const { result, isNewResult, newMemArr } = getMemoizedResult(memoizedFilteredRowKeysArr, filterParamsObj, filterRefsObj,
                                                                  filterOtherArgsObj, memoFunc, maxNumMemoized)
  tCD.memoizedFilteredRowKeysArr = newMemArr
  tCD.filteredRowKeys = result.filteredRowKeys
  tCD.filterRuleCounts = result.filterRuleCounts
  if (DEBUG) {
    if (isNewResult) {
      console.log(`  Calc  filteredRowKeys`)
    } else {
      console.log(`  Reuse filteredRowKeys`)
    }
  }
  if (isTIMER_ENABLED) { logTime('id_TableComputedData', 'Create filteredRowKeys') }

  /////////////////////////////////////////////////////////////////////////
  //  Sort Table rows (all rows)
  //
  //  SORT ORDER depends on many factors
  //     1) changes if/when we push a colHeader sort button -> change in rowSortColIds
  //     2) how we wish to sort potential erroneous values -> change in hideErroneousValues
  //     3) any change in the column data values
  //     4) any change in the column data type
  //     5) any change in filtering.
  //
  //  HOWEVER -- we do NOT want a resort every time someone touches the data.
  //             we will re-sort ONLY if a colHeader sort button is pushed
  //  Approach -- We pass sort all the information in 1-5 above.  But it
  //             is passed through the otherArgsObj.
  //  ONLY the rowSortColIds is passed in the ParamsObj and ONLY a change
  //             in this data structure will re-sort the table.
  //
  //  Also, in the SideBar Publish tab:  Changing the show/hide sort controls
  //  will change the sortedRowKeys array.
  /////////////////////////////////////////////////////////////////////////

  if (tCD.unsortedRowKeys.length !== numRowsUnfiltered) {
    // Constant array reference!  So memoized stats don't need to be recalculated.
    tCD.unsortedRowKeys = list(numRowsUnfiltered - 1)
  }

  if (isSortable === false) {
    tCD.sortedRowKeys = tCD.unsortedRowKeys
  } else {  // We sort the unfiltered row set.
    const sortParamsObj: SortParams = { rowSortColIds }
    const sortRefsObj: SortRefs = {}
    const sortOtherArgsObj: SortOtherArgs = {
      hideErroneousValues,
      internalDataTypes: rowSortColIds.map(
        thisStringIndex => (derivedColAttributesArray[Math.abs(Number(thisStringIndex))].internalDataType)
      ),
      numRowsUnfiltered,
      getTableValue,
    }
    const maxNumMemoized = 4  // To handle ascending/descending clicks between two columns
    const { result, isNewResult, newMemArr } = getMemoizedResult(memoizedRowOrder, sortParamsObj, sortRefsObj,
      sortOtherArgsObj, sortRows, maxNumMemoized)
      tCD.memoizedRowOrder = newMemArr
    if (DEBUG) {
      if (isNewResult) { console.log(`  Calc  Sorted rows`) }
      else { console.log(`  Reuse Sorted rows`) }
    }
    if (isTIMER_ENABLED) { logTime('id_TableComputedData', 'Create Sorted RowKeys') }
    tCD.sortedRowKeys = result.sortedRowKeys
  }
  const sortedRowKeys = tCD.sortedRowKeys

  /////////////////////////////////////////////////////////////////////////
  //                        Calculating Column Stats
  //
  //  This can be time consuming, but also aren't critical that we have them 'immediately'.
  //  Hence, we use a workerThread:
  //
  //  1. We use the same support function for calculating unfiltered stats, and filtered stats.  Filtered stats
  //     are considered an example of the general case.  Hence filtered and unfiltered stats are
  //     all kept in the same memoization array.
  //
  //  2. We will only calc '1' column of stats at a time.  Worse case tenths of a second.
  //     But this is acceptable as the can't tell from a simple single 'col click'.
  //
  //  3. Use '1' statsMemoizationArr.  For all types of filtering (including unfiltered stats),
  //     over all columns.  The stats dataObj is very small compared to the work effort.
  //     But still not critical we have results immediately.  I will allocated a array
  //     length of 3 x NumColumns.  And 'if' I'm interested and stats over many different
  //     filtering subsets (e.g. Venn Diagram), then 3*numCols is still plenty of working space.
  //
  /////////////////////////////////////////////////////////////////////////

  // Default stats in the case of sessionState.activeStatsColKey === -1
  // Which by our definition means no 'activeCol' is currently selected
  // We set the activeCol's stats to null
  // Null is our signal to NOT render the stats.  Space is allocated, but nothing is visible.
  tCD.stats_OfActiveStatsColKey = null
  const activeStatsColIndex = derivedColOrder.indexOf( activeStatsColKey)
  const isActiveStatsColVisible = activeStatsColKey >=0 && 
                                activeStatsColKey < numCols &&
                                !isHidden_ByColIndex[activeStatsColIndex] &&
                                isDeletedArr[activeStatsColKey] === false
  tCD.stats_LegalActiveStatsColKey = (isActiveStatsColVisible) ? activeStatsColKey : -1
  if (isActiveStatsColVisible) {
    const statsCol = derivedColAttributesArray[activeStatsColKey]
    // Stats extend an additional dataType to custom format 'boolTrueFalse' formatting style.
    let statsDataType = statsCol.internalDataType as StatsDataType
    if (statsDataType === 'number' && statsCol.formatRule === 'boolTrueFalse') {
      statsDataType = 'boolTrueFalse'
    }
    const statsHashParamsObj = {
      colKey: activeStatsColKey,
      statsDataType: statsDataType,
    }
    const statsHashRefsObj = {
      columnRef: tableValuesWorking[activeStatsColKey],
      erroneousCells: erroneousCellsArr[activeStatsColKey],
      rowKeys: sortedRowKeys,
    }
    const statsParamsObj = { statsHashCode: hashParamsAndRefs(statsHashParamsObj, statsHashRefsObj) }
    const statsRefsObj = {}
    const statsOtherArgsObj = {
      ...statsHashParamsObj,
      ...statsHashRefsObj,
      DEBUG,
      tableid: table.id
    }
    const memoFunc = calcColumnStats_worker
    maxNumMemoized = 3 * numCols
    //console.log( 'statsHashCode', statsParamsObj.statsHashCode )
    const { result, isNewResult, newMemArr } = getMemoizedResult(memoizedStats, statsParamsObj, statsRefsObj,
      statsOtherArgsObj, memoFunc, maxNumMemoized)
    tCD.memoizedStats = newMemArr
    tCD.stats_OfActiveStatsColKey = (result === null)
      ? tCD.stats_OfActiveStatsColKey = null
      : tCD.stats_OfActiveStatsColKey = result.columnStats
    if (DEBUG) {
      if (isNewResult) {
        console.log(`  Calc  ColumnStats[${activeStatsColKey}]`)
      } else {
        console.log(`  Reuse ColumnStats[${activeStatsColKey}]`)
      }
    }
    if (isTIMER_ENABLED) { logTime('id_TableComputedData', 'Calculate unfiltered column stats.') }
  }


  /////////////////////////////////////////////////////////////////////////
  //  Table layout: width, height, style objects
  /////////////////////////////////////////////////////////////////////////

  // layoutProps =
  //   - the set of attributes that require a re-calc of width/height/style objects (a relayout).
  //   - the input properties into the layoutCalculator functions.
  //   - the 'current layout' properties saved in responsive state as 'inputProps'.
  //   - the object we 'perturb' in responsive state to get modified layouts between react renders.
  const layoutProps: TableLayoutProps = {
      ...deepClone(tablelook.attributes), // deep clone is needed for now because otherwise we end up
      // trying to write to the actual state, which is read-only.
      colWidth_ByColIndex,
      isHidden_ByColIndex, 
      hiddenVal_ByColIndex,
      isDeletedArr, 
      sortedRowKeys,
      numRowsUnfiltered,
      tableLayoutHeight: tableHeight,
      tableLayoutWidth: tableWidth,
      tableTitle: table.attributes.tableTitle,
      publisherTitle: table.attributes.publisherTitle,
      createdDate: table.attributes.createdDate,
      updatedDate: table.attributes.updatedDate,
      pinnedRowKeys: tablelook.attributes.pinnedRowKeys,
      canEdit,
      isPublisherRendered,
      isTableGridRendered,
  }
  tCD.layoutProps = layoutProps

  // These are fast to calculate;  No memoization at this time.
  const w = tCD.widthObj = widthCalculator(layoutProps)
  const h = tCD.heightObj = heightCalculator(layoutProps)
  tCD.styleObj  = styleCalculator(layoutProps)
  tCD.scrollLeft = Math.max( 0, Math.min( sessionStateScrollLeft, w.movingRequired - w.movingAllocated))
  tCD.scrollTop  = Math.max( 0, Math.min( sessionStateScrollTop , h.dataRequired   - h.dataAllocated  ))
  if (isTIMER_ENABLED) { logTime( 'id_TableComputedData', 'Create widthObj, heightObj, styleObj' ) }

  /////////////////////////////////////////////////////////////////////////
  //  Values needed to be displayed for the 'unhide' controls.
  //  These are the 'hidden' columns preceeding a visible 'parent' column
  //  We need to delay this calculation until after the widthObj is known.
  //  Because how we display hidden columns is slighly different, depending
  //  on isCombinedTable.
  /////////////////////////////////////////////////////////////////////////

  const priorHidden_ByColIndex = tCD.priorHidden_ByColIndex = Array(derivedColOrder.length).fill(0)
  let isHiddenAccumulator = 0
  for ( let colIndex = 0; colIndex<derivedColOrder.length; colIndex++ ) {
      if ( !w.isCombinedTable && colIndex === numLockedCols) { isHiddenAccumulator = 0 }  // first moving column initialed to '0' priorHiddenCols
      priorHidden_ByColIndex[colIndex] = isHiddenAccumulator
      isHiddenAccumulator = isHidden_ByColIndex[colIndex] ? isHiddenAccumulator+1 : 0
  }
  // Last column may have 0 to n prior hidden children.
  // BUT! Last column itself MAY also be hidden.
  // If it is hidden then the 'unhide' control will appear to the right of the table (above vert scroll)
  if ( lastVal(isHidden_ByColIndex) ) { 
    // unhide control value is 'prior' hidden children columns, plus '1' for the hidden parent itself!
      priorHidden_ByColIndex[numCols-1]++
      tCD.numColsHiddenRightOfMovingTable = priorHidden_ByColIndex[numCols-1]
  } else {
      tCD.numColsHiddenRightOfMovingTable = 0
  }
  // Same argument (as above) for the last lockedColumn
  if ( !w.isCombinedTable && numLockedCols > 0 && isHidden_ByColIndex[numLockedCols - 1] ) { 
      priorHidden_ByColIndex[numLockedCols-1]++
      tCD.numColsHiddenInGapBetweenTables = priorHidden_ByColIndex[numLockedCols-1]
  } else {
      tCD.numColsHiddenInGapBetweenTables = 0
  }

  /////////////////////////////////////////////////////////////////////////
  //                        statsBarLayout
  //
  //  The rendered stats CAN change, even if we begin with the same memoizedStats result.
  //  Because the rendered values depend on:
  //        - user choice of formatting
  //        - tableStyle.fontSize
  //        - available window width
  //        - whether you are tableOwner or not (effects how erroneous values are handled)
  //
  //  I desire to ONLY re-render this object when the text changes (due to change
  //  in underlying stats, or any of the above).
  //
  //  I can avoid a re-render by asking 'did something change' either here, or in the react
  //  component.  However, easiest way to ask these question is to use the existing
  //  memoization wrapper.  Where I can list out my params and refs, and return a
  //  previous result if no change.  In this case I'm using memoization NOT to
  //  save computational work, but to ask 'shouldComponentUpdate'.  An easier approach
  //  then writing custom react update functions.
  ///////////////////////////////////////////////////////////////////////////

  // This next value is often 'null', in which case the default layout is 'empty'
  // and the statsBar has nothing to render. (blank area)
  tCD.statsBarLayout = getDefaultStatsBarLayout()  // Assumption; nothing to render.
  const activeStats = tCD.stats_OfActiveStatsColKey
  if (activeStats) {
      const { colTitle, colDataType, formatRule, formattingObj } = derivedColAttributesArray[activeStatsColKey]
      const statsBarLayoutParams = {
        colTitle, colDataType, formatRule, formattingObj, canEdit,
        fontSize: tCD.styleObj.cellFontSize,
      }
      const statsBarLayoutRefs = { columnStats: activeStats }
      maxNumMemoized = 2 * numCols
      const memoFunc = createStatsBarLayout_memoizedFunc
      const { result, isNewResult, newMemArr } = getMemoizedResult(tCD.memoizedStatsBarLayout,
                                 statsBarLayoutParams, statsBarLayoutRefs, {}, memoFunc, maxNumMemoized)
      tCD.memoizedStatsBarLayout = newMemArr
      tCD.statsBarLayout = result.statsBarLayout

      if (DEBUG) {
        if (isNewResult) { console.log(`  Calc  statsBarLayout`) }
        else { console.log(`  Reuse statsBarLayout`) }
      }
      if (isTIMER_ENABLED) { logTime('id_TableComputedData', 'Create statsBarLayout') }
    }

  /////////////////////////////////////////////////////////////////////////
  //  If filtering changed, we can find a 'better' scrollTop
  //  position.  ( We over-ride the current resource 'scrollTop' value)
  //  Not a requirement, but a polite touch.
  /////////////////////////////////////////////////////////////////////////

  if (didDerivedFiltersChange && priorTableComputedData) {
      const currentScrollTop = tablelook.attributes.minorState.scrollTop
      layoutProps.minorState.scrollTop = calcNewScrollTop(tCD.sortedRowKeys,
        priorTableComputedData.sortedRowKeys, tCD.heightObj, currentScrollTop)
      if (DEBUG) {
        console.log(`    DerivedFiltersChanged; Calc new ScrollTop`)
      }
  }


  /////////////////////////////////////////////////////////////////////////
  //  Create Accessor functions to retrieve the tableData by (row,col)
  /////////////////////////////////////////////////////////////////////////

  const { STRING_CELL_MAX_DISPLAYED_LENGTH } = constants

  /* 
    Ways to access the tableData(col,row) values:
    IN ALL CASES, THE RETURNED VALUE WILL BE A STRING!
    1) getTableValue(colKey, rowKey, hideErroneousValues)     // tableData resource value
          Next function is formatting wrapper around getTableValue()
    2) getFormattedTableData(colKey, rowKey, 'noHTML' )      // number formatting; but no raised exponents '3.14e9'
    3) getFormattedTableData(colKey, rowKey, 'HTML' )        // number formatting; raised exponents  3.14*10^9
    4) getFormattedTableData(colKey, rowKey, 'MeasureOnly' ) // proper length; potentially garbage value.
    5) getFormattedTableData(colKey, rowKey, 'noHTML_noPreSuffixCommas' ) // Used for CSV output
          Next function is wrapper around getFormattedTableData()
          Used to access pinnedRow values
    6) getFormattedTableData_fromColKeyRowIndex(colKey, rowIndex, outputOption ) 
  */
    

  // From (rowKey, colKey) return the formatted HTML version of the table value.
  // This is used for pinnedRows
  const getFormattedTableData = (colKey:number, rowKey:number, mode:FormattedTableDataMode): FormattedTableDataReturnType => {
      const { value: tableVal, isErroneous, isMissingRowName } = getTableValue(colKey, rowKey, hideErroneousValues)
      let formattingObj: FormattingObj, numberFormattingMode : NumberFormattingMode, lineFeedIndex: number
      if ( mode === 'noHTML_noPreSuffixCommas' ) {
          formattingObj = {
            ...formattingObjArr[colKey],
            useCommas: false,
            prefix: '',
            suffix: '',
          }
          numberFormattingMode = 'noHtml'
      } else {
        formattingObj = formattingObjArr[colKey]
        numberFormattingMode = mode
      }
      let value: string = ''
      switch (internalDataTypeArr[colKey]) {

        case 'number':
          if (!isErroneous) { value = numberFormat(tableVal, formattingObj, numberFormattingMode) }
          else { value = tableVal }
          break
        case 'hyperlink':
          value = getHyperlinkLabel(tableVal) as string
          break
        default:
          value = stringFormat(tableVal, formattingObjArr[colKey])
          lineFeedIndex = value.indexOf('\n')
          if (lineFeedIndex >= 0) { value = value.slice(0, lineFeedIndex) }
          if (value.length > STRING_CELL_MAX_DISPLAYED_LENGTH) {
            value = value.slice(0, STRING_CELL_MAX_DISPLAYED_LENGTH) + ' ...'
          }
      }
      return { value, isErroneous, isMissingRowName }
  }
  tCD.getFormattedTableData = getFormattedTableData

  // From (rowIndex, colKey) return the formatted HTML version of the table value.
  // This is what is displayed in the sorted table cells
  tCD.getFormattedTableData_fromColKeyRowIndex = 
             (colKey:number,rowIndex:number, mode:FormattedTableDataMode ) : FormattedTableDataReturnType => {
    if (rowIndex >= numRowsUnfiltered || rowIndex < 0) {
      // this is a common case because rowGroups may extend beyond end of valid rows.
      return { value: '', isErroneous: false/*, url:''*/, isMissingRowName: false }
    }
    const rowKey = sortedRowKeys[rowIndex]
    return getFormattedTableData(colKey, rowKey, mode)
  }

  stopTimer('id_TableComputedData')
  return { tableComputedData:tCD }
}

