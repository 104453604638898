import type { MultiRelationship, Resource, ResourceType, SingleRelationship } from './jsonapi/types'
import type { FormatRule } from './sharedFunctions/numberFormat'
import type { RootState } from './redux/store'

import * as H from 'history'
import { plotLayoutConsts } from './viewPlotXY/plotXyLayoutConsts'
import constants from './sharedComponents/constants'
import { set } from 'lodash' // need the mutate in place behavior of lodash
import { list } from 'radash'
import uuidv4 from 'uuid/v4'
import { getDefaultSingleRelationship, getDefaultMultiRelationship } from './jsonapi/types'
import { typedKeys } from './sharedFunctions/utils'


export type RouterHistory = H.History<H.LocationState>
export type GetStateFunc = () => RootState


export type GenericObject = {
  [key: string]: any
}

export type EmbeddedLink = {
  startCharIndex: number,
  stopCharIndex: number,
  href: string
}

export type TextWithLinks = {
  text: string,
  links: Array<EmbeddedLink>,
}


export const getDefaultTextWithLinks = (): TextWithLinks => {
  return { text: '', links: [] }
}


export type DraggableData = {
  node: HTMLElement,
  // lastX + deltaX === x
  x: number,
  y: number,
  deltaX: number,
  deltaY: number,
  lastX: number,
  lastY: number,
};

export type FilterRelation = 'all' | 'any' | '==' | '>' | '>=' | '=' | '<' | '<=' | 'none' | '<>' | 'unset'

export type FilterRule = {
  colKey: number,
  enabled: boolean,
  key: number,
  relation: FilterRelation,
  value: string,
}

export const getEmptyFilterRule = (): FilterRule => {
  const newFilterRule: FilterRule = { colKey: -1, enabled: true, key: 0, relation: 'unset', value: '' }
  return Object.seal(newFilterRule)
}


export type ParseError = {
  code: string,
  message: string,
  row: number,
  type: string,
}

type ParseMeta = {
  aborted: boolean,
  cursor: number,
  delimiter: string,
  fields?: Array<string>,
  linebreak: string,
  truncated: boolean,
}

export type ParseResult = {
  data: Array<Array<string>>,
  errors: Array<ParseError>,
  meta: ParseMeta,
}

export type Sourcesv = Resource & {
  attributes: {
    createdDate: string,
    errors: Array<ParseError>,
    parseMeta: ParseMeta,
    rows: Array<Array<string>>,
  },
  relationships?: {
    owner: SingleRelationship,
    source: SingleRelationship,
  }
}

export type StringOrderTypes = 'alphabetical' | '1stSeriesValue' | 'allSeriesValues'

export type StringOrderDirection = 'ascending' | 'descending'

export type Axis = {
  axisTitle: string,
  axisSubTitle: string | null,
  labelAngle: number,
  fontScaleTickValues: number,
  isLogarithmic: boolean,             // logarithmic axis
  isPercentileNormalProb: boolean,    // type of nonlinear axis for percentile plots only
  isHistogramPercent: boolean,        // type of axis linear normalization for histograms only
  isMinDomainAuto: boolean,
  isMaxDomainAuto: boolean,
  usersMinDomain: string,
  usersMaxDomain: string,
  stringOrder: StringOrderTypes,
  stringOrderDirection: StringOrderDirection,
}

export const getDefaultAxis = (): Axis => {
  const stringOrder: StringOrderTypes = 'alphabetical'
  const stringOrderDirection: StringOrderDirection = 'ascending'
  const newAxis = {
    axisTitle: '',
    axisSubTitle: null,
    labelAngle: plotLayoutConsts.xAxisLabelAngleForNewPlots,
    fontScaleTickValues: 1,
    isLogarithmic: false,
    isPercentileNormalProb: false,
    isHistogramPercent: false,
    isMinDomainAuto: true,
    isMaxDomainAuto: true,
    usersMinDomain: '',   // empty value has not yet been set by the user. In which case it defaults to the auto value.
    usersMaxDomain: '',
    stringOrder,
    stringOrderDirection
  }
  return Object.seal(newAxis)
}

export type MarkShape = 'square' | 'diamond' | 'plus' | 'triangle' | 'circle' | 'star' | 'none'

export type SortBy = 'valueA' | 'valueColKey' | 'unset'

export type PlotXYseries = {
  seriesTitle: string,
  seriesDescription: TextWithLinks,
  seriesFilter: Array<FilterRule>,

  color: string,
  markShape: MarkShape,
  markSize: number,

  seriesOpacity: number,
  seriesLineSmoothing: number,
  seriesSamplingDensity: number,

  sortBy: SortBy,
  sortByColKey: number,
  colKeyA: number,
  colKeyB: number,
  colKeyC: number,
}

export const getDefaultPlotXYseries = (): PlotXYseries => {
  const sortBy: SortBy = 'valueA'
  const newSeries = {
    color: '#0082c8', // 'blue'
    markSize: constants.DEFAULT_MARK_SIZE,
    markShape: constants.DEFAULT_MARK_SHAPE,

    seriesTitle: 'Undefined Series',
    seriesDescription: { text: '', links: [] },
    seriesFilter: [],

    seriesOpacity: 1,
    seriesLineSmoothing: plotLayoutConsts.seriesLineSmoothingForNewPlots,
    seriesSamplingDensity: plotLayoutConsts.seriesSamplingDensityOptionForNewPlots,

    sortBy,
    sortByColKey: -1,
    colKeyA: -1,
    colKeyB: -1,
    colKeyC: -1,
  }
  return Object.seal(newSeries)
}

// Moves to popups 'parent' module.
export type TableSelectionName = 'mainTitle' | 'publisherTitle' | 'tableHeader' | 'pinnedCell' | 'dataCell' | 'rowFiltering' | ''

export type TableSelection = {
  name: TableSelectionName,
  colIndex: number,
  rowIndex: number,
}

// Moves to popups 'parent' module.
export type PlotSelectionName = 'mainTitle' | 'publisherTitle' | 'legend' | 'basisA' | 'basisB' | 'basisC' | ''

export type XyPlotSelection = {
  name: PlotSelectionName,
  seriesKey: number,
}

// Replaced with plot.attributes.popupLocationSize = [ {posX, posY, sizeX0, sizeY0, sizeX1, sizeY1, ... }, ... ]
export type StringEditorSize = {
  width: number,
  height: number,
}

export type MinorStatePlot = {           // Remove all (after backup server strategy fixed
  isSideBarVisible: boolean,            // Moves to local state; default closed entering App
  sideBarTabIndex: number,              // Moves to local state; default 0 entering App
  sideBarSeriesKeyExpanded: number,     // remove
  cellEditor_left: number,               // all plot pop-ups
  cellEditor_top: number,
  isEditorOpen: boolean,                 // Remove; selection value === '' will work fine.
  selection: XyPlotSelection,            // Moves to local state;  No popups on new plot or entering App.
}

export const getDefaultMinorStatePlot = (): MinorStatePlot => {
  const name: PlotSelectionName = ''
  const msPlot = {
    isSideBarVisible: false,
    sideBarTabIndex: 0,
    sideBarSeriesKeyExpanded: -1,
    cellEditor_left: 0,
    cellEditor_top: 0,
    isEditorOpen: false,
    selection: { name, seriesKey: -1 }
  }
  return Object.seal(msPlot)
}


export type MinorStateTableLook = {
  isSideBarVisible: boolean,
  sideBarTabIndex: number,
  scrollLeft: number,
  scrollTop: number,
  cellEditor_left: number,
  cellEditor_top: number,
  isEditorOpen: boolean,
  stringEditorSize: Array<StringEditorSize>,
  selection: TableSelection,
}

export const getMinorStateTableLook = (): MinorStateTableLook => {
  const name: TableSelectionName = ''
  const msObj = {
    isSideBarVisible: false,
    sideBarTabIndex: 0,
    scrollLeft: 0,
    scrollTop: 0,
    isEditorOpen: false,
    cellEditor_left: 100,
    cellEditor_top: 300,
    stringEditorSize: [],
    selection: {
      name,
      colIndex: 0,
      rowIndex: 0
    }
  }
  return Object.seal(msObj)
}

export const validPlotColDataTypes = ['1Col', '2Col', '3Col'] as const
export type PlotColDataType = typeof validPlotColDataTypes[number]

export type Plot = Resource & {
  attributes: {

    minorState: MinorStatePlot,

    createdDate?: string,
    plotDescription: TextWithLinks,
    mainTitle: string,
    publisherTitle: string,
    updatedDate?: string,

    renderedLayersArr: string[],
    plotColDataType: PlotColDataType,

    aspectRatio: number,
    histogramBinIndex: number,
    bottomCanvasOpacity: number,

    // fontSizes are a 'scale'.  For example 0.5 to 1.3 times 'nominal'
    fontScaleTitle: number,
    fontScalePublisher: number,
    fontScaleLegend: number,
    fontScaleAxisNames: number,

    commonSeriesFilter: Array<FilterRule>,
    series: Array<PlotXYseries>,
    seriesOrder: Array<number>,
    basisA: Axis,
    basisB: Axis,
    basisC: Axis,
    isTransposed: boolean,
    isMirrored: boolean,
  },
  relationships?: {
    owner?: SingleRelationship,
    table: SingleRelationship,
    tags?: MultiRelationship,
    originatorPlot: SingleRelationship,
  }
}

export type ModOp = 'add' | 'del' | 'set'

export type Mod = {
  newVal: any,
  oldVal?: any,
  op: ModOp,
  path: string,
  resId: string,
  resType: ResourceType,
}

export type MinorMod = {
  newVal: any,
  op: ModOp,
  path: string,
}

export type LightweightMod = {
  newVal: any,
  path: string,
  op?: ModOp,
  resId?: string,
  resType?: ResourceType,
}


export const getDefaultPlotXy = (mods: Array<LightweightMod>, table: Table): Plot => {
  const type: ResourceType = 'plots'
  const plotColDataType: PlotColDataType = '2Col'
  const newPlot = {
    id: uuidv4(),
    type,
    attributes: {

      minorState: getDefaultMinorStatePlot(),
      plotColDataType,

      plotDescription: { text: 'New Plot', links: [] },
      mainTitle: `New Untitled Plot`,
      publisherTitle: '',
      aspectRatio: plotLayoutConsts.aspectRatioOptionForNewPlots,
      series: new Array<PlotXYseries>(),
      seriesOrder: [0],

      renderedLayersArr: new Array<string>(),

      histogramBinIndex: plotLayoutConsts.histogramBinIndexForNewPlots,
      bottomCanvasOpacity: plotLayoutConsts.bottomCanvasOpacityForNewPlots,
      commonSeriesFilter: new Array<FilterRule>(),

      fontScaleTitle: 1,
      fontScalePublisher: 1,
      fontScaleLegend: 1,
      fontScaleAxisNames: 1,

      isTransposed: false,
      isMirrored: false,

      basisA: { ...getDefaultAxis(), axisTitle: 'Untitled Axis' },
      basisB: { ...getDefaultAxis(), axisTitle: 'Untitled Axis' },
      basisC: { ...getDefaultAxis(), axisTitle: 'Untitled Axis' },

    },
    relationships: {
      table: {
        data: {
          id: table.id,
          type: table.type
        }
      },
      originatorPlot: {
        data: null
      }
    }
  }

  newPlot.attributes.series[0] = getDefaultPlotXYseries()
  Object.seal(newPlot)
  for (const mod of mods) {
    set(newPlot, mod.path, mod.newVal)
  }
  return newPlot
}


export type Login = Resource & {
  attributes: {
    login: string,
    password: string,
    token?: string,
    role?: string,
  },
}

export type JsonMLNode = [
  string,
  undefined | GenericObject | string | JsonMLNode[],
  undefined | string | JsonMLNode[]
]

export type Source = Resource & {
  attributes: {
    createdDate: string,
    filename: string,
    importType: string,
    nodes: JsonMLNode,
    size: number,
    title: string,
    url: string,
  },
  relationships?: {
    owner: SingleRelationship,
    sourcedata: SingleRelationship,
  }
}

export type Sourceml = Resource & {
  attributes: {
    createdDate: string,
    nodes: JsonMLNode,
  },
  relationships?: {
    owner: SingleRelationship,
    source: SingleRelationship,
  }
}

export type Userspi = Resource & {
  attributes: {
    email: string,
    firstname?: string,
    lastname?: string,
    password?: string,
    username: string,
    email_canonical?: string,
    password_hash?: string,
    username_canonical?: string,
  },
  relationships?: {
    user?: SingleRelationship,
  }
}

// Several modules need to know the mapping of available formatting options by colDataType.
// This is a good a file as any for this info.
const numberGeneralFormats: FormatRule[] = [
  'defaultEng',
  'scientific',
  'noExponent',
  'boolTrueFalse'
]

export type InternalColDataType = 'hyperlink' | 'string' | 'number'
export type ColDataTypes = InternalColDataType | 'numberSeconds' //| 'numberDeg'

export type FormatOptions = {
  [key in ColDataTypes]: FormatRule[]
}

// order within arrays === order seen in the drop down selection list.
export const formattingOptions_by_colDataType: FormatOptions = {
  hyperlink: ['defaultLink'],
  string: ['defaultString'],
  number: [...numberGeneralFormats, 'internal'],
  numberSeconds: ['B60B60seconds', 'B60seconds', ...numberGeneralFormats, 'internal'],
//  numberDegrees: ['B60B60degrees', 'B60degrees', ...numberGeneralFormats, 'internal'],
}

export type Column = {
  colDescription: TextWithLinks,
  formula: Array<string>,
  isDeleted: boolean,
  isDeletedNotRestorable: boolean,
  deletedDate: string,
  isDepCol: boolean,
  isKey: boolean,
  colTitle: string,
  colSourceTitle: string,
  units: string,
  colDataType: ColDataTypes,
}

export const getDefaultTableColumn = (overrideObj?: Partial<Column>): Column => {
  const colDataType: ColDataTypes = 'string'
  const newColumn = {
    colDescription: getDefaultTextWithLinks(),
    formula: [],
    isDeleted: false,
    isDeletedNotRestorable: false,
    deletedDate: '',
    isDepCol: false,
    isKey: false,
    colTitle: '',
    colSourceTitle: '',
    units: '',
    colDataType,
  }
  if (overrideObj) {
    for (const key of typedKeys(newColumn)) {
      if (overrideObj[key] !== undefined) {
        newColumn[key] = overrideObj[key]
      }
    }
  }
  return Object.seal(newColumn)
}

export type ASTNode = {
  alternate?: ASTNode,
  argument?: ASTNode,
  arguments?: Array<ASTNode>,
  callee?: ASTNode,
  computed?: boolean,
  consequent?: ASTNode,
  elements?: Array<ASTNode>,
  left?: ASTNode,
  name?: string,
  object?: ASTNode,
  operator?: string,
  property?: ASTNode,
  right?: ASTNode,
  test?: ASTNode,
  type: string,
  value?: number,
}

export type DataSourceType = 'genData' | 'csvCreate'

export type DataSourceObj = {
  dataSourceType: DataSourceType,
  date: string,
  file: File,
  numRows: number,
  numCols: number,
}




export type Table = Resource & {
  attributes: {
    columns: Array<Column>,
    createdDate?: string,
    updatedDate?: string,
    tableDescription: TextWithLinks,
    isSortable: boolean,
    numRowsUnfiltered: number,
    numStars?: number,
    tableTitle: string,
    publisherTitle: string,
    curatorNotes: string,
    dataSources: DataSourceObj[],
  },
  relationships?: {
    owner: SingleRelationship,
    tabledata: SingleRelationship,
    originatorTable?: SingleRelationship,
    tags: MultiRelationship,
  }
}

export const getDefaultTable = (numCols: number): Table => {
  const type: ResourceType = 'tables'
  const newTable = {
    id: '',
    type,
    attributes: {
      columns: new Array<Column>(),
      createdDate: '',
      updatedDate: '',
      tableDescription: getDefaultTextWithLinks(),
      isSortable: true,
      numRowsUnfiltered: 0,
      numStars: 0,
      tableTitle: '',
      publisherTitle: '',
      curatorNotes: '',
      dataSources: new Array<DataSourceObj>(),
    },
    relationships: {
      owner: getDefaultSingleRelationship(),
      tabledata: getDefaultSingleRelationship(),
      //originatorTable: getDefaultSingleRelationship(),  // Inits to null, which causes a problem downstream.
      tags: getDefaultMultiRelationship(),
    }
  }
  for (let i = 0; i < numCols; i++) {
    newTable.attributes.columns[i] = getDefaultTableColumn()
  }
  return Object.seal(newTable)
}

export type PrecisionMode = 'min' | 'std' | 'fixed'

export type Columnlook = {
  hidden: number,  //A hidden index of zero means 'Visible'; hidden index > 0 means hidden.
  width: number,
  formatRule: FormatRule,
  prefix: string,
  suffix: string,
  precisionMode: PrecisionMode,
  precisionMin: number,
  precisionFixed: number,
}

export const getDefaultColumnlook = (overrideObj?: Partial<Columnlook>): Columnlook => {
  const formatRule: FormatRule = 'defaultEng'
  const precisionMode: PrecisionMode = 'min'
  const newColumnlook = {
    hidden: 0,  // A hidden index of zero means 'Visible'; hidden index > 0 means hidden.
    width: 100,
    formatRule,
    prefix: '',
    suffix: '',
    precisionMode,
    precisionMin: 6,
    precisionFixed: 2,
  }
  if (overrideObj) {
    for (const key of typedKeys(newColumnlook)) {
      if (overrideObj[key] !== undefined) {
        newColumnlook[key] = overrideObj[key]
      }
    }
  }
  return Object.seal(newColumnlook)
}


export type Tablelook = Resource & {
  attributes: {

    minorState: MinorStateTableLook,

    isBrightField: boolean,
    greyHeadCells: number,
    greyDataCells: number,
    greyGridHorz: number,
    greyGridVert: number,
    greyAlternateRow: number,

    borderThickness: number,
    tableSpacing: number,
    rowSpacing: number,
    globalScale: number,
    isRowNumberVisible: boolean,
    publisherTitleFontScale: number,
    mainTitleFontScale: number,

    columns: Array<Column>,
    colOrder: Array<number>,
    lookColumns: Array<Columnlook>,
    rowFilters: Array<FilterRule>,

    rowSortColIds: Array<string>,   // Example: ['+3','-2','-0','+1'] => means ascending or descending colKey
    numLockedCols: number,
    pinnedRowKeys: Array<number>,
    createdDate?: string,
    updatedDate?: string,
    recentPlots: Array<string>,    // uuid
  },
  relationships?: {
    table?: SingleRelationship,
    owner?: SingleRelationship,
  }
}

export const getDefaultTablelook = (numRowsUnfiltered: number, numCols: number): Tablelook => {

  const type: ResourceType = 'tablelooks'
  const newTablelook = {
    id: '',
    type,
    attributes: {
      minorState: getMinorStateTableLook(),

      isBrightField: true,
      greyHeadCells: 0.0,
      greyDataCells: 0.4,
      greyGridHorz: 0.6,
      greyGridVert: 0.6,
      greyAlternateRow: 0,

      borderThickness: 2,
      tableSpacing: 10,
      rowSpacing: .9,
      globalScale: .9,
      isRowNumberVisible: true,
      publisherTitleFontScale: 1.2,
      mainTitleFontScale: 1.8,

      columns: new Array<Column>(),
      colOrder: list(numCols - 1),
      lookColumns: list(numCols - 1).map(() => getDefaultColumnlook()),
      rowSortColIds: new Array<string>(),
      rowFilters: new Array<FilterRule>(),
      numLockedCols: 0,
      pinnedRowKeys: new Array<number>(),
      recentPlots: new Array<string>(),    // uuid's
      //createdDate: null,
      //updatedDate: null,
    },
    relationships: {

    },
  }
  return Object.seal(newTablelook)
}



export type CellValue = string
export type ColValues = Array<CellValue>
export type TableValues = Array<ColValues>

export type Tabledata = Resource & {
  attributes: {
    tableValues: TableValues,
  },
  relationships?: {
    table: SingleRelationship,
    owner: SingleRelationship,
  }
}

export const getDefaultTabledata = (): Tabledata => {
  const type: ResourceType = 'tabledatas'
  const newTabledata = {
    id: '',
    type,
    attributes: {
      tableValues: []
    },
    relationships: {
      owner: getDefaultSingleRelationship(),
      table: getDefaultSingleRelationship()
    }
  }
  return Object.seal(newTabledata)
}

export type Tag = Resource & {
  attributes: {
    numTables: number,
  }
}

export type User = Resource & {
  attributes: {
    username: string,
    createdDate: string,
    numTables: number,
    updatedDate: string,
    numFollowers: number,
    userDescription: TextWithLinks,
    role: string,
  },
  relationships: {
    follows: MultiRelationship,
    stars: MultiRelationship,
    tables: MultiRelationship,
  }
}

export type SliderFuncType = 'enum' | 'linear'

export type SliderControlParams = {
  styleName: string,
  resourceName: string,
  funcType: SliderFuncType,
  valMin: number,
  valMax: number,
  numSteps: number,
  enumLabels: Array<string>,
  displayFixed: number,
  onInit: (val: number) => number, // convert the stored value (enum string for example) into a number.
  onStart: (val: number) => void,
  onDrag: (val: number) => void,
  onStop: (val: number) => void,
}

export const getDefaultSliderControlParams = (): SliderControlParams => {
  const funcType: SliderFuncType = 'linear'
  const newObj: SliderControlParams = {
    styleName: '',
    resourceName: '',
    funcType,
    valMin: 0,
    valMax: 0,
    numSteps: 0,
    enumLabels: [],
    displayFixed: 0,
    onInit: (val: number) => Number(val),
    onStart: (val: number) => Number(val),
    onDrag: (val: number) => Number(val),
    onStop: (val: number) => Number(val),
  }
  return Object.seal(newObj)
}

export type ScryUIView = 'homeView' | 'searchView' | 'sitePageView' | 'tableView' | 'xyPlotView'

export type OptionalHTMLElement = HTMLElement | null
export type OptionalHTMLDivElement = HTMLDivElement | null
export type OptionalSVGElement = SVGElement | null



// ActiveView
export type ViewName =  'tableView' | 'searchView' | 'plotXyView' | 'sitePageView' | 'homeView'
export type ActiveView= {
    viewName: ViewName
    isOwner: boolean
    canEdit: boolean
}

export type FpName = 
        'none' | 'fpTest' |
        // menuBar
        'menuBarUndoHistory' |
        // NavCol:
        'navColPlotCreate' | 'navColPlotDelete' | 'navColPlotDownload' |
        // table:  
        'tableMainTitle' | 'tablePublisherTitle' | 'tableRowFiltering'  | 'tableColHeader' |
        'tableCellNumber' | 'tableCellString' | 'tableCellHyperlink' | 'tableCellFormula' | 'tableCellDateTime'

// ActiveFp - Is the floating palette open, and if so, where(left,top) and what content?
export type TertiaryKey = 'empty' |
   'pinnedCell' | 'dataCell' // Needed because two cells (pinned row and data row) may have identical primary and secondary keys.
                             // Not needed when opening an editor, but needed if you ever need to read activeFp to determine
                             // which of two otherwise identical cells was previously clicked.   
export type ActiveFp = {
    fpName : FpName   // ONLY one floating palette at a time.
    primaryKey: number
    secondaryKey: number
    tertiaryKey: TertiaryKey
    left: number
    top: number
}
export const getDefaultActiveFp = () : ActiveFp => {
    const {NAV_COLUMN_WIDTH, TOP_MENUBAR_HEIGHT} = constants
    return {fpName: 'none', primaryKey: 0, secondaryKey: 0, 
      left: NAV_COLUMN_WIDTH+50, top: TOP_MENUBAR_HEIGHT+50, tertiaryKey: 'empty'} 
} 


/*
RESOURCE CHANGE LOG:
Sept30, 1923 - Added tablelook.attributes."rowSortColIds": [],  (Signed colKey's in order of sortRows priority)
Oct 10. 1923 - Deleted tablelook.attributes.rowOrder  (Replaced with above 'rules' for sort order)
*/