
import type { Property } from 'csstype'
import type { ReactNode } from 'react'
import { PureComponent } from 'react'
import { DraggableCore } from 'react-draggable'
import type { DraggableEvent, DraggableData } from 'react-draggable'
import { addNumberFormatReactNodeSyntax } from '../sharedFunctions/numberFormat'
import SVGsliderKnob from '../SVGs/SVGsliderKnob'
import SVGwrapper2 from '../SVGs/SVGwrapper2'
import { getDefaultSliderControlParams } from '../types'
import type { SliderControlParams } from '../types'
import { SCRY_BLUE, SCRY_LIGHT_BLUE } from './constants'


const DEBUG = false
const KNOB_SIZE = 15
const CAPTURE_SIZE = 23   // Note: CAPTURE_HIEGHT===TITLE_HEIGHT===SLIDER_DISPLAY_HEIGHT  (support 1 or 2 line display options)
const SLIDER_RAIL_THICKNESS = 5

const TITLE_FONT_SIZE = 14 // These font sizes, and this palette layout, DOES NOT vary with table scale!
const TITLE_HEIGHT = CAPTURE_SIZE   // Note: CAPTURE_HIEGHT===TITLE_HEIGHT===SLIDER_DISPLAY_HEIGHT  (support 1 or 2 line display options)

const DEFAULT_TITLE_WIDTH = 105  // Value when not passed as a property from parent.
const SLIDER_WIDTH = 100
const SLIDER_DISPLAY_HEIGHT = CAPTURE_SIZE  // Note: CAPTURE_HEIGHT===TITLE_HEIGHT===SLIDER_DISPLAY_HEIGHT  (support 1 or 2 line display options)
const SLIDER_DISPLAY_GAP = 0
const DISPLAY_WIDTH_1LINE = 34
const DISPLAY_WIDTH_2LINE = 120
const DISPLAY_FONT_SIZE = 14

export const TOTAL_SLIDER_CONTROL_HEIGHT_PER_LINE = TITLE_HEIGHT
const SNAP_THRESHOLD = 2

type RowSliderProps = {
  currentValue: number
  params: SliderControlParams
  indent: number
  preSpacer: number
  layoutLines: number
}
type DefaultProps = {
  visibility: Property.Visibility
  marginRight: number
  titleWidth: number
  sliderWidth: number
  displayWidth: number | undefined
}
type Props = RowSliderProps & DefaultProps

class SliderControl extends PureComponent<Props> {

  static defaultProps: DefaultProps = {
    visibility: 'unset',
    marginRight: 0,
    titleWidth: DEFAULT_TITLE_WIDTH,
    sliderWidth: SLIDER_WIDTH,
    displayWidth: undefined,
  }

  sliderRailLength: number = 0
  knobDomNode: HTMLDivElement | null = null
  displayTextDomNode: HTMLSpanElement | null = null
  isDragActive: boolean = false

  p: SliderControlParams = getDefaultSliderControlParams()

  // The deltaX between the two values equals
  // the slop in how well the user centered the cursor
  // over the knob at dragStart.  If cursor was exactly
  // centered in 'x' over the knob, then next two values
  // will be identical and deltaX = 0
  startDragDeltaX: number = 0

  initialCursorX: number = 0  // Raw x coord of cursor on HandleStart
  initialKnobX: number = 0
  initialValue: number = 0

  currentKnobX: number = 0 // value in units of 'px'.  knobX= 0 corresponds to the far left extreme of slider travel
  currentValue: number = 0    // value in range from valMin to valMax.

  valMinPrime: number = 0
  valMaxPrime: number = 0
  currentValuePrime: number = 0

  calcKnobXfromValue = ( value: number ): number => {
    const fractionalSliderDisplacement = (value - this.valMinPrime) / (this.valMaxPrime - this.valMinPrime)
    const result = fractionalSliderDisplacement * this.sliderRailLength
    return result
  }

  constrainKnobX = ( inputKnobX:number ): number => {
    // Can't be dragged beyond limits of the rail:
    if ( inputKnobX < 0 ) {
      return 0
    }
    if ( inputKnobX > this.sliderRailLength ) {
      return this.sliderRailLength
    }
    return inputKnobX
  }

  snapKnobX = ( inputKnobX: number ): number => {
    const constrainedKnobX = this.constrainKnobX(inputKnobX)
    // Snap knob to Xcoord of closest valid step
    const fractionalSliderDisplacement = constrainedKnobX / this.sliderRailLength
    const displacementInSteps = Math.round( fractionalSliderDisplacement * this.p.numSteps )
    const result = (displacementInSteps / this.p.numSteps) * this.sliderRailLength
    return result
  }

  calcValueFromKnobX = ( knobX: number ): number => {
    const fractionalSliderDisplacement = knobX / this.sliderRailLength
    var newValue = fractionalSliderDisplacement * (this.valMaxPrime - this.valMinPrime) + this.valMinPrime
    if (this.p.funcType === 'enum') {
      return Math.round(newValue)
    }
    return newValue
  }

  formatValue = ( value: number ): string | ReactNode => {
    if (this.p.funcType === 'enum') {
      let enumStrg =  this.p.enumLabels[Math.round(value)]
      if ( isNaN(Number(enumStrg))) {
        return enumStrg
      }
      let suffix = ''
      return addNumberFormatReactNodeSyntax( enumStrg, suffix )
    }
    // Non-enumerated values (calculated from min/max)
    return value.toFixed( this.p.displayFixed )
  }


  latestX: number = 0   // Used for debouncing

  setKnobColor = (color: string): void => {
    const thirdLevelChild = this.knobDomNode?.firstChild?.firstChild?.firstChild as HTMLElement
    if (thirdLevelChild) { thirdLevelChild.style.fill = color }
  }


  handleStart = ( e: DraggableEvent, position: DraggableData):void => {
    // We can convert the x value into a direct
    // measure of the center of the slider knob
    // by adjusting by 1/2 the knob's capture area.
    // ( x above will be zero if one starts the drag
    // by clicking exactly on the left edge of capture area.)
    e.preventDefault()
    e.stopPropagation()
    this.latestX = position.x - (CAPTURE_SIZE-1)/2 + 1
    this.startDragDeltaX = this.currentKnobX - this.latestX
    this.setKnobColor(SCRY_BLUE)
    this.isDragActive = true
    this.p.onStart( this.currentValue )
  }


  // debouncer
  handleDrag = ( e: DraggableEvent, position: DraggableData ): void => {
    e.preventDefault()
    e.stopPropagation()
    // x1 is the cursor 'x position' with respect
    // to the center of the draggable knob.
    let x1 = position.x - (CAPTURE_SIZE-1)/2 + 1
    if ( this.latestX === x1 ) { return }
    this.latestX = x1
    // Calculate the a new currentKnobX.
    // And only updateDrag if value has changed
    let unConstrainedX = x1 - this.startDragDeltaX
    if (this.p.numSteps > SNAP_THRESHOLD) {
      var newKnobX = this.snapKnobX( unConstrainedX )
    } else {
      newKnobX = this.constrainKnobX( unConstrainedX )
    }
    if ( newKnobX !== this.currentKnobX ) { this.updateDrag( newKnobX ) }
  }

  updateDrag = ( newKnobX: number ): void => {
    this.currentKnobX = newKnobX
    if (this.knobDomNode) {
      this.knobDomNode.style.transform = `translate(${this.currentKnobX}px, 0px)`
    }
    this.currentValue = this.calcValueFromKnobX( this.currentKnobX )
    if (this.displayTextDomNode) {
      this.displayTextDomNode.textContent = this.formatValue(this.currentValue)
    }
    this.p.onDrag( this.currentValue )
  }

  handleStop = ( e: DraggableEvent ): void => {
    e.preventDefault()
    e.stopPropagation()
    this.setKnobColor(SCRY_LIGHT_BLUE)
    this.isDragActive = false
    this.p.onStop( this.currentValue )
  }

  initKnobDomNode = ( element: HTMLDivElement | null ): void => {
    this.knobDomNode = element
  }
  initDisplayTextDomNode = ( element: HTMLDivElement | null ): void => {
    this.displayTextDomNode = element
  }

  render() {
    if ( !this.isDragActive ) {
        // Use input props 'currentValue' to set currentKnobX position
        // on render.  Except during an active drag, in which
        // case the onDrag handler sets the currentKnobX position
        // But NOT during active Drag events.
        this.sliderRailLength = this.props.sliderWidth - CAPTURE_SIZE
        this.p = this.props.params

        this.valMinPrime = this.p.valMin
        this.valMaxPrime = this.p.valMax
        this.currentValuePrime = this.currentValue

        this.currentValue = this.p.onInit(this.props.currentValue)
        this.currentKnobX = this.snapKnobX( this.calcKnobXfromValue( this.currentValue ))
    }

    const {indent, preSpacer, layoutLines, visibility, marginRight,
       titleWidth, sliderWidth, displayWidth} = this.props

    // 3 alternatives for the display with:
    //    - defined by the calling parent
    //    - undefined, 1 layout line format; use the default in this file
    //    - undefined, 2 layout line format; use the default in this file
    var displayWidthRendered = displayWidth // assumption
    if (displayWidth === undefined && layoutLines === 1) {
      displayWidthRendered = DISPLAY_WIDTH_1LINE
    }
    if (displayWidth === undefined && layoutLines === 2) {
      displayWidthRendered = DISPLAY_WIDTH_2LINE
    }

    return (
      <div className={'rc_SliderControl'}
        style={{
          flex: '0 0 auto',
          width: '100%',
          marginTop:preSpacer,
          marginLeft: indent,
          height: TITLE_HEIGHT * layoutLines,
          visibility:visibility,
        }}
      >

{this.p.numSteps > 0 &&
      <div className={'SliderControl'} style={{height:TITLE_HEIGHT}}>

          <div className={'StyleName'}
            style={{
              display: (layoutLines === 1) ? 'inline-block' : 'block',
              width:   (layoutLines === 1) ? titleWidth : '100%',
              fontSize :  TITLE_FONT_SIZE,
              height: TITLE_HEIGHT,
              paddingTop: 3,  // Emperically aligns with the slider rail.
              overflow: 'hidden',
              background: DEBUG ? 'pink' : 'none',
            }}
          >  {this.p.styleName} </div>

          <div className={'SliderRailContainer'}
            style={{
              position : 'relative',
              display: 'inline-block',
              width: sliderWidth,
              height: SLIDER_DISPLAY_HEIGHT,
              background: DEBUG ? 'green' : 'none',
            }}
          >

              <div className={'SliderRail'}
                style={{
                  marginLeft: (CAPTURE_SIZE-1)/2,
                  marginTop:  (CAPTURE_SIZE - SLIDER_RAIL_THICKNESS )/2 ,
                  width: this.sliderRailLength,
                  height: SLIDER_RAIL_THICKNESS,
                  background: '#808080',
                  boxSizing : 'border-box',
                  borderWidth: '0px 1px 1px 0px',
                  borderStyle: 'solid',
                  borderColor: '#404040',
                  borderRadius: SLIDER_RAIL_THICKNESS/2,
                }}
              />

              <DraggableCore
                enableUserSelectHack={false}
                onStart={ ( e, data ) => this.handleStart(e, data )  }
                onDrag ={ ( e, data ) => this.handleDrag( e, data )  }
                onStop ={ ( e ) => this.handleStop( e )  }
              >
                  <div className={'DraggableSliderKnob'}
                    ref={ this.initKnobDomNode }
                    style={{
                      height: CAPTURE_SIZE,
                      width: CAPTURE_SIZE,
                      position:'absolute',
                      transform : `translate(${this.currentKnobX}px, 0px)`,
                      left: 0,
                      top: 0 ,
                      background: DEBUG ? 'olive' : 'transparent',
                    }}
                  >
                      <SVGwrapper2>
                        <SVGsliderKnob width={KNOB_SIZE} height={KNOB_SIZE} />
                      </SVGwrapper2>
                  </div>

              </DraggableCore>

          </div>

          <div className={'inlineContainer'}
            style={{
              display: 'inline-block',
              width: displayWidthRendered,
              height: SLIDER_DISPLAY_HEIGHT,
              paddingLeft: SLIDER_DISPLAY_GAP,
              marginRight,
            }}
          >
                <div className={'SliderDisplayText'}
                  ref={ this.initDisplayTextDomNode }
                  style={{
                    display: 'table-cell',
                    height: SLIDER_DISPLAY_HEIGHT,
                    width: displayWidthRendered,
                    fontSize: DISPLAY_FONT_SIZE,
                    //paddingTop: (SLIDER_DISPLAY_HEIGHT - DISPLAY_FONT_SIZE - 1) / 2,
                    paddingBottom: 4,
                    textAlign: (layoutLines === 1) ? 'right' : 'left',
                    verticalAlign:'bottom',
                    overflow:'hidden',
                    background: DEBUG ? 'yellow' : 'transparent',
                  }}>
                    {this.formatValue(this.currentValue)}
                </div>
        </div>
      </div>
}
      </div>
    )
  }
}


export default SliderControl
