export const ErrorTypes = {
  AUTH_ERR: 'AUTH_ERR',
  DATA_ERR: 'DATA_ERR',
  FATAL_ERR: 'FATAL_ERR',
  SERVER_ERR: 'SERVER_ERR',
  INFO: 'INFO',
  VALID: 'VALID',
  WARNING: 'WARNING',
}

