import type {TableComputedData}     from '../computedDataTable/getDefaultTableComputedData'
import type {SliderControlParams}   from '../types'

import {TableComputedDataContext}   from '../viewTableTriple/TtGetComputedData'
import { useContext }               from 'react'
import SliderControl          from '../sharedComponents/SliderControl'
import reactDispatch, {sessionStateChangeDispatch} from '../sharedComponents/reactDispatch'
import {heightCalculator, 
        widthCalculator}      from '../computedDataTable/layoutCalculator'
import StyleLinearSlider,
    {getClosestSnappedValue}  from '../sharedComponents/StyleLinearSlider'
import StyleTitle             from '../sharedComponents/StyleTitle'
import StyleSelectBox         from '../sharedComponents/StyleSelectBox'
import {defaultStyleObj}      from '../computedDataTable/layoutCalculator'


// Local state variables to support Global Scale and Row Spacing sliders:
let lastValue : number = 0

// Shared function used when we change rowHeight or globalScale
type ScrollingFunction = (rowSpacing:number, globalScale:number) => { scrollLeft:number, scrollTop:number }

const create_ScrollingFunction = ( tableComputedData: TableComputedData ) : ScrollingFunction => {
    const {layoutProps, heightObj:oldH, scrollTop, scrollLeft} = tableComputedData
    const {globalScale: oldGlobalScale} = layoutProps
    return ( 
       (rowSpacing:number, globalScale:number) : {scrollLeft:number, scrollTop:number} => {
        const perturbedProps = {...layoutProps, rowSpacing, globalScale }
        const newH = heightCalculator( perturbedProps )
        const newW = widthCalculator( perturbedProps )
        const rowHeightRatio = (newH.rowHeight / oldH.rowHeight  )
        const rowWidthRatio  = globalScale / oldGlobalScale
        let newScrollTop  = Math.round( scrollTop * rowHeightRatio )
        let newScrollLeft = Math.round( scrollLeft * rowWidthRatio )
        // Constrain by the values in next height obj, NOT existing height obj.
        newScrollTop = Math.max( newScrollTop, 0 )
        newScrollTop = Math.min( newScrollTop, newH.dataRequired - newH.dataAllocated )
        newScrollLeft = Math.max( newScrollLeft, 0 )
        newScrollLeft = Math.min( newScrollLeft, newW.movingRequired - newW.movingAllocated )
        return { scrollLeft: newScrollLeft, scrollTop:newScrollTop}
      }
    )
}


const SLIDER_INDENT = 60
const SLIDER_PRESPACER = 10
const SELECT_INDENT = 40
const SELECT_PRESPACER = 4
/*
type Props = { 
  tableComputedData : TableComputedData,
  //tabName1: string,   // Used only by parent wrapper (tabControl); Must be defined here for typescript
  //tabName2: string,   // Ditto 
} */


const showRowNumbers = () => { reactDispatch( [{ newVal: true,  path: 'attributes.isRowNumberVisible' }] , 'Show Row Numbers') }
const hideRowNumbers = () => { reactDispatch( [{ newVal: false, path: 'attributes.isRowNumberVisible' }] , 'Hide Row Numbers') }

export const TableSb_TabSizing : React.FC = (  ) => {
    const tableComputedData = useContext( TableComputedDataContext )
    const {tablelook, styleObj} = tableComputedData
    const { globalScale, mainTitleFontScale, publisherTitleFontScale, borderThickness, rowSpacing,
            isRowNumberVisible, tableSpacing} = tablelook.attributes
    const {foregroundColor} = styleObj
    const getNewScrollPositions = create_ScrollingFunction( tableComputedData )
    const currentRowSpacing  = tableComputedData.layoutProps.rowSpacing
    const currentGlobalScale = tableComputedData.layoutProps.globalScale

    /* ------------------------------
        Global Scale   // Needs a custom drag function; Call SliderControl directly!
    --------------------------------*/
    const globalScaleParams: SliderControlParams = {
      styleName: "Global Scale:",
      resourceName: "globalScale",
      funcType: "linear",
      valMin: defaultStyleObj.globalScale_min,
      valMax: defaultStyleObj.globalScale_max,
      numSteps: defaultStyleObj.globalScale_numSteps,
      enumLabels: [],
      displayFixed: defaultStyleObj.globalScale_fixedPrec,
      onInit: (value: number): number => {
        return getClosestSnappedValue( value,
          defaultStyleObj.globalScale_min,
          defaultStyleObj.globalScale_max,
          defaultStyleObj.globalScale_numSteps)
      },
      onStart: (val) => {
        lastValue = Number( val.toFixed(2) )
      },
      onDrag: (inVal) => {
        const val = Number( inVal.toFixed(2) )
        if (val !== lastValue ) {
          const {scrollLeft, scrollTop} = getNewScrollPositions( currentRowSpacing, val )
          let mods = []
          mods.push({ newVal: val, path: `attributes.globalScale` })
          reactDispatch( mods, 'Style - Row Spacing', 'drag' )
          mods = []
          mods.push({ newVal: Math.round( scrollTop ), path: `activeTableScrollTop` })
          mods.push({ newVal: Math.round( scrollLeft), path: `activeTableScrollLeft` })
          sessionStateChangeDispatch( mods, 'Style - Global Scale' )
        }
        lastValue = val
      },
      onStop: () => { sessionStateChangeDispatch( [], 'Style - Global Scale' ) }
    }

    /* ------------------------------
        Row Spacing   // Needs a custom drag function; Call SliderControl directly!
    --------------------------------*/
    const rowSpacingParams: SliderControlParams = {
      styleName: "Row Spacing:",
      resourceName: "rowSpacing",
      funcType: "linear",
      valMin: defaultStyleObj.rowSpacing_min,
      valMax: defaultStyleObj.rowSpacing_max,
      numSteps: defaultStyleObj.rowSpacing_numSteps,
      enumLabels: [],
      displayFixed: defaultStyleObj.rowSpacing_fixedPrec,
      onInit: (value: number): number => {
        return getClosestSnappedValue( value,
          defaultStyleObj.rowSpacing_min,
          defaultStyleObj.rowSpacing_max,
          defaultStyleObj.rowSpacing_numSteps)
      },
      onStart: (val) => {
        lastValue = lastValue = Number( val.toFixed(2) )
      },
      onDrag: (inVal) => {
        const val = Number( inVal.toFixed(2) )
        if (val !== lastValue ) {
          const {scrollTop} = getNewScrollPositions( val, currentGlobalScale )
          let mods = []
          mods.push({ newVal: val, path: `attributes.rowSpacing` })
          reactDispatch( mods, 'Style - Row Spacing', 'drag' )
          mods = []
          mods.push({ newVal: scrollTop, path: `activeTableScrollTop` })
          sessionStateChangeDispatch( mods, 'Style - Global Scale' )
        }
        lastValue = val
      },
      onStop: () => { reactDispatch( [], 'Style - Row Spacing', 'dragStop' ) },
    }

    return (

          <div className={'rc_Table_TabSizing'}
            style={{
              position:'relative',
              height: '100%',
              width: '100%',
              color: foregroundColor,
              display: 'flex', flexFlow: 'column nowrap', //justifyContent:'flexStart',
            }}>

                <StyleTitle  text0={'Table Sizes'} text1={undefined}/>

                <StyleSelectBox
                  indent={SELECT_INDENT}
                  id={'showRowNums'}
                  preSpacer={SELECT_PRESPACER}
                  onClick={ showRowNumbers }
                  isSelected={ isRowNumberVisible? true : false }
                  text={'Show Row Numbers'}
                  foregroundColor={foregroundColor} />

                <StyleSelectBox
                  indent={SELECT_INDENT}
                  id={'hideRowNums'}
                  preSpacer={SELECT_PRESPACER}
                  onClick={ hideRowNumbers }
                  isSelected={isRowNumberVisible? false : true }
                  text={'Hide Row Numbers'}
                  foregroundColor={foregroundColor} />

                <SliderControl
                  preSpacer={SLIDER_PRESPACER + 10}
                  indent={SLIDER_INDENT}
                  currentValue ={ globalScale }
                  params={globalScaleParams}
                  layoutLines={2}
                />

                <StyleLinearSlider
                  preSpacer={SLIDER_PRESPACER}
                  indent={SLIDER_INDENT}
                  styleName={"Table Title Size:"}
                  modPath={'attributes.mainTitleFontScale'}
                  currentValue={mainTitleFontScale}
                  valMin={defaultStyleObj.mainTitleFontScale_min}
                  valMax={defaultStyleObj.mainTitleFontScale_max}
                  numSteps={defaultStyleObj.mainTitleFontScale_numSteps}
                  displayFixed={defaultStyleObj.mainTitleFontScale_fixedPrec}
                  layoutLines={2}
                />

                <StyleLinearSlider
                  preSpacer={SLIDER_PRESPACER}
                  indent={SLIDER_INDENT}
                  styleName={'Publisher Title Size:'}
                  modPath={'attributes.publisherTitleFontScale'}
                  currentValue={publisherTitleFontScale}
                  valMin={defaultStyleObj.publisherTitleFontScale_min}
                  valMax={defaultStyleObj.publisherTitleFontScale_max}
                  numSteps={defaultStyleObj.publisherTitleFontScale_numSteps}
                  displayFixed={defaultStyleObj.publisherTitleFontScale_fixedPrec}
                  layoutLines={2}
                />

                <StyleLinearSlider
                  preSpacer={SLIDER_PRESPACER}
                  indent={SLIDER_INDENT}
                  styleName={'Table Borders:'}
                  modPath={'attributes.borderThickness'}
                  currentValue={borderThickness}
                  valMin={defaultStyleObj.borderThickness_min}
                  valMax={defaultStyleObj.borderThickness_max}
                  numSteps={defaultStyleObj.borderThickness_numSteps}
                  displayFixed={defaultStyleObj.borderThickness_fixedPrec}
                  layoutLines={2}
                />

                <StyleLinearSlider
                  preSpacer={SLIDER_PRESPACER}
                  indent={SLIDER_INDENT}
                  styleName={'Table Spacing:'}
                  modPath={'attributes.tableSpacing'}
                  currentValue={tableSpacing}
                  valMin={defaultStyleObj.tableSpacing_min}
                  valMax={defaultStyleObj.tableSpacing_max}
                  numSteps={defaultStyleObj.tableSpacing_numSteps}
                  displayFixed={defaultStyleObj.tableSpacing_fixedPrec}
                  layoutLines={2}
                />

                <SliderControl
                  preSpacer={SLIDER_PRESPACER}
                  indent={SLIDER_INDENT}
                  currentValue ={rowSpacing }
                  params={rowSpacingParams}
                  layoutLines={2}
                />

          </div>
    ) 
  
}
