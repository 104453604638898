import type { FpSessionStates_ByFpName, SbSessionStates_ByView } from '../appCode/getSbFpConfigMappings'
import type { ActiveFp } from '../types'

import { getDefaultFpSessionStates_ByFpName , 
         getDefaultSbSessionStates_ByView } from '../appCode/getSbFpConfigMappings'
import { getDefaultActiveFp } from '../types'


export type SessionState = {
  // Force state changes tied to window size changes
  windowInnerWidth: number,
  windowInnerHeight: number,

  // Floating pallette and sideBar states
  activeFp : ActiveFp,
  fpStates_ByFpName : FpSessionStates_ByFpName,
  sbStates_ByView : SbSessionStates_ByView,

  lastSearchQueryString: string,

  activeTableid: string, // The tableId of the active table, not the search table
  currentTableid: string, // The tableId of the current view, including search tableid.

  activeStatsColKey : number,
  statsBarDataIndex: number,

  activeTableScrollTop : number,
  activeTableScrollLeft: number, 
  currentTableTriplet  : string,

  menuOption_isEditMode: boolean,

  renderIndex: number,

  renderPlotToSVG: boolean
}

export const getDefaultSessionState = () : SessionState => {
  const state = {
    windowInnerWidth: window.innerWidth,
    windowInnerHeight: window.innerHeight,

    activeFp: getDefaultActiveFp(),
    fpStates_ByFpName : getDefaultFpSessionStates_ByFpName(),
    sbStates_ByView : getDefaultSbSessionStates_ByView(),

    lastSearchQueryString: '',

    activeTableid: '',
    currentTableid: '',

    activeStatsColKey: -1, 
    statsBarDataIndex: 0,

    activeTableScrollTop : 0,
    activeTableScrollLeft: 0, 
    currentTableTriplet  : '',
    menuOption_isEditMode: true,
    renderIndex: 0,
    renderPlotToSVG: false,
  }
  //console.log('getDefaultSessionState()')
  return state
}


