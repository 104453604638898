import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import invariant from 'invariant'
import { ErrorTypes } from '../../shared/ErrorTypes'
import { createResource, ensureResource, updateResource } from '../jsonapi'
import type { ResourceError } from '../jsonapi/types'
import { asyncDispatch } from '../sharedFunctions/utils'
import type { GetStateFunc, Login, RouterHistory } from '../types'
import { setAuthToken, setAuthUserId } from './appReducer'
import { notifyJsonApiError } from './notifyReducer'

export const loginThunk = (login: Login, history: RouterHistory) => {
    return async (dispatch: Dispatch<AnyAction>) => {
        try {
            const json = await asyncDispatch(dispatch, createResource(login))
            invariant(json && json.data, 'loginThunk did not get a valid reponse from createResource')
            invariant(!Array.isArray(json.data), 'loginThunk got multiple items back from createResource')
            const userid = json?.data?.id ?? null
            const token = json?.data?.attributes?.token ?? null
            if (token) {
                // success
                dispatch(setAuthUserId(userid))
                dispatch(setAuthToken(token))
                await asyncDispatch(dispatch, ensureResource('users', userid))
                if (history.length > 2) {
                    history.goBack()
                } else {
                    history.push('/')
                }
            }
        } catch (err) {
            const resError = err as ResourceError
            const errors = resError.response?.data?.errors ?? null
            if (errors) {
                dispatch(notifyJsonApiError({ errors, type: ErrorTypes.AUTH_ERR }))
            }
        }
    }
}

export const refreshLoginThunk = () => {
    return async (dispatch: Dispatch<AnyAction>, getState: GetStateFunc) => {
        try {
            const { authUserId, authToken } = getState().app
            if (!authUserId || !authToken) {
                return
            }
            const login: Login = {
                id: authUserId,
                type: 'logins',
                attributes: {
                    login: '',
                    password: '',
                    token: authToken
                },
            }
            const json = await asyncDispatch(dispatch, updateResource(login))
            invariant(json && json.data, 'refreshLoginThunk did not get a valid reponse from updateResource')
            invariant(!Array.isArray(json.data), 'refreshLoginThunk got multiple items back from updateResource')
            const token = json.data.attributes.token
            if (token) {
                dispatch(setAuthToken(token));
            } else {
                dispatch(setAuthToken('')) //this will trigger navigation back to login screen.
            }

        } catch (err) {
            const resError = err as ResourceError
            const errors = resError.response?.data?.errors ?? null
            if (errors) {
                dispatch(notifyJsonApiError({ errors, type: ErrorTypes.AUTH_ERR }))
            }
            dispatch(setAuthToken('')) //this will trigger navigation back to login screen.
        }
    }
}
