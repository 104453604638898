import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import constants, {
  //SCRY_DARK_BLUE,
  SCRY_BLUE,
  SCRY_WHITE,
  NAV_COLUMN_WIDTH,
  TOP_MENUBAR_HEIGHT,
} from '../sharedComponents/constants'
import SVGdatatablesLogo from '../SVGs/SVGdatatablesLogo'
import SVGwrapper2 from '../SVGs/SVGwrapper2'
import NavColButton from './NavColButton'
import NavColLink from './NavColLink'
import PlotsList from './PlotsList'
import { PlotXyComputedDataContext } from '../viewTableTriple/TtGetComputedDataPlotXy'
import type { Plot, Table } from '../types'

interface OwnProps {
  table: Table | null
  plotid: string
  view: string
  plot: Plot | null
  tableOwnerid: string
  userid: string
  tableOwnerPlotIds: string[]
  userPlotIds: string[]
  closePopup: () => void
  setActivePopup: (popup: string) => void
}

const NavColumn: React.FC<OwnProps> = ({
  table,
  plotid,
  view,
  plot,
  tableOwnerid,
  userid,
  tableOwnerPlotIds,
  userPlotIds,
  closePopup,
  setActivePopup,
}) => {
  const plotXyComputedData = useContext(PlotXyComputedDataContext)
  const isTableResourcesAvailable = !!table
  const isPlotResourcesAvailable = !!plot && !!plotXyComputedData
  const isCreatePlotDisplayed =
    (view === 'tableView' && isTableResourcesAvailable) || (view === 'xyPlotView' && isPlotResourcesAvailable)
  const isDownloadPlotDisplayed = view === 'xyPlotView' && isPlotResourcesAvailable
  const isDeletePlotDisplayed = view === 'xyPlotView' && isPlotResourcesAvailable
  const isDownloadTableDisplayed = view === 'tableView' && isTableResourcesAvailable
  const isPlotsListDisplayed = !!table

  const setCreatePlotActive = (): void => {
    closePopup()
    setActivePopup('createPlot')
  }

  const setCreateTableActive = (): void => {
    closePopup()
    setActivePopup('createTable')
  }

  const setDownloadPlotActive = (): void => {
    closePopup()
    setActivePopup('downloadPlot')
  }

  const setDeletePlotActive = (): void => {
    closePopup()
    setActivePopup('deletePlot')
  }

  const setDownloadTableActive = (): void => {
    closePopup()
    setActivePopup('downloadTable')
  }

  return (
    <div
      className={'NavColumn'}
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: NAV_COLUMN_WIDTH,
        height: '100%',
        backgroundColor: SCRY_BLUE,
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: `0px ${rightBlackBorderWidth}px 0px 0px`,
        color: 'rgba(255,255,255,0.75)',
        display: 'flex',
        flexFlow: 'column nowrap',
        flexShrink: 0,
      }}
    >
      <div
        className={'DataTablesUpperLeft'}
        onClick={closePopup}
        style={{
          width: NAV_COLUMN_WIDTH - rightBlackBorderWidth,
          height: TOP_MENUBAR_HEIGHT - 1,
          boxSizing: 'border-box',
          borderColor: 'black',
          borderStyle: 'solid',
          borderWidth: `0px 0px 1px 0px`,
        }}
      >
        <Link
          to={'/sitePage'}
          style={{
            width: '100%',
            height: '100%',
            paddingLeft: 10,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            textDecoration: 'none',
            background: view === 'sitePage' ? 'lightgrey' : SCRY_WHITE,
          }}
        >
          <div
            style={{
              display: 'flex',
              width: logoWidth,
              paddingTop: (TOP_MENUBAR_HEIGHT - logoHeight) / 2,
            }}
          >
            <SVGwrapper2>
              <SVGdatatablesLogo height={TOP_MENUBAR_HEIGHT} width={logoWidth} />
            </SVGwrapper2>
          </div>
          <div
            style={{
              display: 'flex',
              width: dataTablesWidth,
            }}
          >
            {dataTablesLogo}
          </div>
        </Link>
      </div>
      <div
        className={'spacer1'}
        style={{
          width: '100%',
          marginTop: 16,
        }}
      />
      <NavColLink closePopup={closePopup} isSelected={view === 'searchView'} url="/search">
        {`Search (\u00A0all tables)`}
      </NavColLink>
      {false ? (
        <>
          <NavColLink isSelected={view === 'home'} closePopup={closePopup} url="/home">
            {'Home\u00A0\u00A0\u00A0(my\u00A0tables)'}
          </NavColLink>
        </>
      ) : null}
      <div
        className={'spacer1'}
        style={{
          width: '100%',
          marginTop: 24,
          backgroundColor: SCRY_BLUE,
        }}
      />
      {!!table && isTableResourcesAvailable ? (
        <NavColLink isSelected={view === 'tableView'} url={`/table/${table ? table.id : ''}`} closePopup={closePopup}>
          <div>Active Table</div>
          <div
            className={'activeTable_Title'}
            style={{
              marginLeft: 8,
              fontWeight: 'normal',
              fontSize: 16,
              fontFamily: 'serif',
              width: NAV_COLUMN_WIDTH - 40,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {table ? table.attributes.tableTitle : ''}
          </div>
        </NavColLink>
      ) : (
        <div
          style={{
            paddingTop: 8,
            paddingBottom: 8,
            visibility: 'hidden',
          }}
        >
          <div>Active Table</div>
          <div
            className={'activeTable_Title'}
            style={{
              marginLeft: 8,
              fontWeight: 'normal',
              fontSize: 16,
              fontFamily: 'serif',
              width: NAV_COLUMN_WIDTH - 40,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {'text'}
          </div>
        </div>
      )}
      <div
        className={'spacer2'}
        style={{
          width: '100%',
          marginTop: 30,
        }}
      />
      <NavColButton onClick={setCreateTableActive}>{'Create Private Table'}</NavColButton>
      {isCreatePlotDisplayed && <NavColButton onClick={setCreatePlotActive}>{'Create Private Plot'}</NavColButton>}
      {isDownloadPlotDisplayed && <NavColButton onClick={setDownloadPlotActive}>{'Download Plot'}</NavColButton>}
      {isDeletePlotDisplayed && <NavColButton onClick={setDeletePlotActive}>{'Delete Plot'}</NavColButton>}
      {isDownloadTableDisplayed && <NavColButton onClick={setDownloadTableActive}>{'Download Table'}</NavColButton>}
      <div
        className={'spacer3'}
        style={{
          width: '100%',
          marginTop: 20,
        }}
      />
      {isPlotsListDisplayed && (
        <PlotsList
          tableid={table ? table.id : ''}
          currentPlotid={plotid}
          closePopup={closePopup}
          tableOwnerid={tableOwnerid}
          userid={userid}
          tableOwnerPlotIds={tableOwnerPlotIds}
          userPlotIds={userPlotIds}
        />
      )}
    </div>
  )
}

export default NavColumn


const logoWidth = 24
const logoHeight = 22
const rightBlackBorderWidth = 2
const logoLargeCaseFontSize = 26
const logoSmallCaseScale = '90%'
const dataTablesWidth = 100 // Placeholder for the actual width'
const dataTablesLogo: JSX.Element =
  <span
    className={'rc_NavColumn'}
    style={{
      width: '100%',
      color: 'black',
      fontWeight: 'bold',
      paddingLeft: 12,
      paddingTop: (TOP_MENUBAR_HEIGHT - logoLargeCaseFontSize) / 2 - 1,
      fontFamily: constants.FONT_FAMILY_LOGO_FORMAT,
      fontSize: logoLargeCaseFontSize
    }}
  >
    {'D'}
    <span style={{ fontSize: logoSmallCaseScale }}>
      {'ata'}
    </span>

    <span style={{ position: 'relative', left: '-0.1em' }}>
      {'T'}
    </span>
    <span style={{ fontSize: logoSmallCaseScale, position: 'relative', left: '-0.2em' }}>
      {'ables'}
    </span>
  </span>
