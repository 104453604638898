/* DESIGN NOTES FOR ROW FILTERING.  USED FOR BOTH:
      - TABLE ROW FILTERING
      - PLOT SERIES FILTERING


Definitions:

'tableValue': The value from tableData[colKey][rowKey]
   Should be used throughout the filtering code (this module and all modules).

'ruleValue': The value from the rowFiltering pop-up.  Should be used
   throughout the filtering code.

'empty tableValue or ruleValue' :  defined as: ''  It displays in
   the both  the table and RuleFilterItem interface as an empty cell.
   Both cases are valid!!  Filtering can be used to eliminate all
   empty cells, or conversely to retain only empty cells.

'erroneous tableValue or ruleValue':   a non-empty string,
   but it fails to match the required column's dataType.  Consider
   a column of type 'number'.
      For row test: 34 < abc, then 'abc' is an erroneous ruleValue.
      For row test: abc < 34, then 'abc' is an erroneous tableValue.
   The string and link dataTypes have NO erroneous values.

'unset colKey':  the initial value (rule.colKey = 'unset') for an empty rule.

'isDeleted colKey': rule.colKey is >=0, but the column was subsequently deleted.

'relationsMenu' is the set of relations that depend on that column's dataType/format.
       const relationsMenuOptions = {
         timeRange: timeRangeMenuObject,  // not currently supported
         string: stringMenuObject,
         hyperlink: hyperlinkMenuObject,
         number: numberMenuObject,
         boolean: booleanMenuObject,
         common: commonMenuObject,
       }

'common relationsMenu': is defined as the set of relations that are common to
   all dataType/Format relations menus.  Currently this set includes ONLY the
   equal and notEqual relations.   The commonRelationsMenu is used when we
   don't have the needed information to pick to proper relationsMenu.
   Specifically, we use the CommonRelationsMenu when the colKey is 'unset'
   or the colKey has been deleted.

'validRelation':  Belongs to the set of supported relations we've defined
      for this matchColumn's dataType and format.

'invalidRelation':  Does not belong to the set of supported relations we've
      defined for this matchColumn's dataType and format.




DESIGN

1 - A row filter test returns true/false for each table row.  It is a
    simple truth table.  But the number of corners is large!

        - 3 cases for the tableValue:  (empty, valid, isErroneous)
        - 3 cases for ruleValue: (empty, valid, isErroneous)
        - (N + 1) cases for the number of relations: ( 'validRelations' + 'invalidRelation' )
        - (M + 2) cases for the number of colKeys; aka dataType/format: (valid + 'unset' + 'isDeleted' )

2 - isErroneous tableValues:

    Assume we want the rowFiltering to be the same, regardless of whether
    the user is in 'EditMode' or 'ViewMode'.  Then I see only one option for
    our design.

    Remember, in EditMode, isErroneous tableValues appear as red text.  But when
    the user switches to ViewMode, all isErroneous table cells are replaced
    with empty cells.

    Hence, our rule for isErroneous tableValues MUST be to treat them as 'empty tableValues'.
    The filtering code simply treats 'isErroneous tableValues' as 'empty tableValues'.

    Consequences of this rule:
    This means filtering cannot be used to separate erroneous cells from empty cells!

    However, current design/documentation for isErroneous tableValues makes it clear
    that the sorting function will always move isErroneous tableValues to the top
    of the column (regardless of whether sort is ascending or decending).  Therefore,
    if the tableOwner wishes to work on isErroneous tableValues, then either the
    row filtering should be disabled, or the rowFilters can be set to retain only
    'empty tableValues'.

3 - invalidRelations:

    The code can/does treat these two as equivalent.  We test for an 'invalid'
    relation by asking whether the rule.relation is defined:

      thisRelationPopupMenu = getRelationMenu( derivedColAttributesArray, rule.colKey )
      isResourceRelationValid = Boolean( thisRelationPopupMenu[rule.relation] )

    if (isResourceRelationValid === false) then we display an empty relation selection
    input.  Hopefully interpreted by the user as 'Please set me'.  The corresponding
    selection pop-up menu will ONLY display valid relation options.

    Rules with 'invalidRelations' are treated the same as 'disabled rules' (skipped).
    Hence look for this code to appear before the row looping, in the same
    area as the isEnabled code.

4 - empty tableValues and/or ruleValues

    The code for handling 'empty tableValues' is very difficult to co-mingle with
    the code for valid tableValues.  For example, consider a column of Number
    dataType.  The table cell values are saved as strings.  Hence we must cast it
    to a number for the relationship testing:   Number(tableValue) <= Number(ruleValue)

    However casting an empty tableValue changes its meaning:  Number('') => 0
    Hence, 'empty tableValue' testing must occur before, and independent of,
    the number relational testing.

    Fortunately, we can write one common block of code for empty testing, and this
    common block of code simply preceeds all dataType specific relationship testing.

    The 'empty' testing truth table is 2 x 2 x 4
      2 - empty verses non-empty tableValue
      2 - empty verses non-empty ruleValue
      4 - relationships:  equal('==');   !equal('<>');  partialMatch('=');  all other

    if ( 'empty tableValue' || 'empty ruleValue' ) {
      use 2x2x4 truth table
    } else {
      proceed to dataType dependent relationship testing
    }

    The truth table collapses to rather simple code.  This code sits within the
    row looping, and preceeds any dataType specific relational testing.

    The current coded solution is:
        if ( tableValue === '' || ruleValue === '' ) {
            if      relation is '==' and ruleValue === tableValue, (then both are empty)    return true
            else if relation is '<>' and ruleValue !== tableValue, (then only one is empty) return true
            else if relation is '= ' and ruleValue === '', (consider empty a partial match to everything) return true
            else return false;  (Fallthrough cases: isEqual but only one is empty, notEqual but both are empty)
        } else {
          proceed to dataType dependent relationship testing
        }

    I believe this to be equivalent to the underlying 2x2x4 truth table.
    But you can create your own truth table and corresponding code if
    my logic above is flawed.

5 - unset Columns:

      A) We leave the matchColumn input empty
      B) We treat the entire rule as invalid (skip this rule)

6 - isDeletedColumns:

    These columns were deleted by the tableOwner.  However, the resulting
    bad rule may belong to one or more entirely different plot owner(s).  We
    could simply write 'Deleted Column' in the column selector.  However this
    doesn't give useful feedback to plot owners concerning which column
    was deleted.

    Hence, we choose an alternative where:
      A) 'Match Column' display shows the deleted column name, but in red.
      B) The deleted column (colKey) no longer appears in the menu options,
         although the name may still be present as a possible replacement
         column. (e.g. tableowner deletes column 'age colKey 3', then adds
         a new column 'age colKey 14')
      C) filterRule.value may still be valid, but no reason to assume it
         is not valid.  We don't touch the ruleValue.  Most likely, the user
         will pick a new (similar) column and leave the ruleValue
         unchanged.  Hence, we let (force) user to decide whether the
         ruleValue should be modified or not.
      D) We display 'Deleted Column' (red) in the ruleValue field. When they
         fix the match column, the original ruleValue will be displayed.

7 - isErroneous ruleValues:

    ruleValues may be invalid (wrong dataType) because they were entered that
    way, or because a col dataType change turns a prior good value into a bad value.
    Since we cannot prevent isErroneous ruleValues, no matter how smart the
    input screening, we accept that:

    isErroneous ruleValues can/will exist.  They can't be prevented.
    So there is no big advantage to prevent them on input. And no big advantage to
    prevent them from writing to the resource (unlike other input editors, like
    plot axis limit overrides, where we never write bad values to the resource).

    Hence, accepting that isErroneous ruleValues can exist, we adopt these rules:

    A) Allow users to input an erroneous value, but display erroneous ruleValues in red.
    B) Dispatch all ruleValue edits, including erroneous values, to the resource.
    C) When EditFilterItem is mounted, if ruleValue is legal:
         Initialize ruleValue with format of the match colKey (same as CellEditors).
         For example match value '123' becomes '2:03' in mm:ss format.
         Match value '123' becomes 'True' in booleanTrueFalse format.
    D) Although the initial ruleValue uses the column format, edits to the ruleValue
       can be in any legal format, as written by the users (same as CellEditors).
    E) ruleValue edits are 'cleaned' using function cleanScryInputText2()
       (same as CellEditors and all user inputs).
    F) On changes to dataType, if a ruleValue becomes erroneous, it is displayed in
       red. We make NO attempt to fix the ruleValue, or remove the erroneous ruleValue
       from the resource.
    G) For all dataTypes and relations, we will consider an isErroneous ruleValue
       to invalidate the rule.  Hence, we skip rules with bad/red values (same as
       skipping rules with invalid relations, isDeleted colKeys, or 'unset' colKeys).

*/



import invariant from 'invariant'
import { list } from 'radash'
import { getHyperlinkLabel } from '../sharedFunctions/isTypeHyperlink'
import { isScryNumber } from '../sharedFunctions/isTypeNumber'
import type { DerivedColAttributes } from '../computedDataTable/getDefaultTableComputedData'
import type { FilterOtherArgs, FilterParams, FilterRefs, FilterResult } from '../computedDataTable/updateTableComputedData'
import type { FilterRelation, FilterRule, InternalColDataType } from '../types'
import type { MenuItems } from '../sharedComponents/EditorMenuButton'


// Derived from and similar to column dataTypes.  But also allows for a 'boolean' and 'common' set of relations.
type RelationType = InternalColDataType | 'boolean' | 'common' | 'timeRange'

// Next two types are used in both updateTableComputedData and xy_createPlotXyComputedData
export type DerivedFilterRule = {
  colKey: number,
  enabled: boolean,
  key: number,
  relation: FilterRelation,
  value: string,
  isDeleted: boolean, // Is this column key deleted?
  // Given isDeleted & colKey's dataType/format, which relation menu to display and option to use for filtering?
  relationType: RelationType,
  internalDataType: InternalColDataType,
}


export const createDerivedFilterRuleArray = (filterArray: FilterRule[],
  derivedColAttributesArray: DerivedColAttributes[]): DerivedFilterRule[] => {

  const result = filterArray.map(thisRule => {
    // Case of an empty rule (colKey === -1 or any out-of-range colKey)
    if (thisRule.colKey < 0 || thisRule.colKey >= derivedColAttributesArray.length) {
      const defaultDerivedFilterRule: DerivedFilterRule = {
        ...thisRule, isDeleted: false, internalDataType: 'string', relationType: 'common'
      }
      return defaultDerivedFilterRule
    }
    // Case of valid colKey:
    var { isDeleted, formatRule, internalDataType } = derivedColAttributesArray[thisRule.colKey]
    // relationType almost equal to the internalDataType, except for couple of exceptions.
    var relationType: RelationType = internalDataType  // assumption
    if (internalDataType === 'number' && formatRule === 'boolTrueFalse') { relationType = 'boolean' }
    if (isDeleted) { relationType = 'common' }
    return { ...thisRule, isDeleted, internalDataType, relationType }
  })
  return result
}


const isSubstring = (ruleValue: string, tableValue: string): boolean => {
  if (ruleValue) {
    return tableValue.indexOf(ruleValue) > -1
  }
  // This path should never be taken
  if (process.env.NODE_ENV === 'development') {
    invariant(false, `Unexpected empty ruleValue in rowFiltering`)
  }
  return true
}


const testString = (relationType: string, ruleValue: string, tableValue: string): boolean => {
  const lcRuleValue = ruleValue.toLowerCase()
  const lcTableValue = tableValue.toLowerCase()
  switch (relationType) {
    case '=':
      return isSubstring(lcRuleValue, lcTableValue)
    case '==':
      return lcTableValue === lcRuleValue
    case '<>':
      return lcTableValue !== lcRuleValue
    case 'all': {
      const testFn = (subvalue: string): boolean => { return isSubstring(subvalue, lcTableValue) }
      const allRuleValues = lcRuleValue.split(' ')
      return allRuleValues.every(thisVal => testFn(thisVal))
    }
    case 'any':
    case 'none': {
      let temp = lcRuleValue.trim()
      const allTestWords = temp.split(' ')
      const result = allTestWords.some(thisRule => {
        return isSubstring(thisRule, lcTableValue)
      })
      return (relationType === 'any') ? result : !result
    }

    case '<':
      return lcTableValue < lcRuleValue
    case '>':
      return lcTableValue > lcRuleValue
    // These two redundant with existing rules.  And difficult
    // to get the '=' part of the ruleValue to be usefull anyway.
    //case '<=':
    //  return lcTableValue <= lcRuleValue
    //case '>=':
    //  return lcTableValue >= lcRuleValue
    default:
      if (process.env.NODE_ENV === 'development') {
        invariant(false, `Unrecognized relationType "${relationType}" in string rowFiltering`)
      }
      return true
  }
}



const testNumber = (relationType: string, ruleValue: string, tableValue: number): boolean => {
  if (ruleValue) {
    const numRuleValue = Number(ruleValue)
    const numTableValue = Number(tableValue)
    if (!Number.isFinite(numRuleValue)) return false
    switch (relationType) {
      case '<':
        return numTableValue < numRuleValue
      case '<=':
        return numTableValue <= numRuleValue
      case '<>':
        return numTableValue !== numRuleValue
      case '==':
        return numTableValue === numRuleValue
      case '>':
        return numTableValue > numRuleValue
      case '>=':
        return numTableValue >= numRuleValue
      default:
        // This path should never be taken
        if (process.env.NODE_ENV === 'development') {
          invariant(false, `Unrecognized relationType "${relationType}" in testNumber rowFiltering`)
        }
    }
  }
  // This path should never be taken
  if (process.env.NODE_ENV === 'development') {
    invariant(false, `Unexpected empty ruleValue in testNumber rowFiltering`)
  }
  return true
}

const testBoolean = (relationType: string, ruleValue: string, tableValue: string): boolean => {
  const booleanRuleValue = Boolean(Number(ruleValue))
  const booleanTableValue = Boolean(Number(tableValue))
  if (relationType === '==') { return (booleanTableValue === booleanRuleValue) }
  if (relationType === '<>') { return (booleanTableValue !== booleanRuleValue) }
  // This path should never be taken
  if (process.env.NODE_ENV === 'development') {
    invariant(false, `Unrecognized relationType "${relationType}" in testBoolean rowFiltering`)
  }
  return true
}


export const testRule = (rule: FilterRule, tableValue: string, relationType: string): boolean => {
  const { relation, value: ruleValue } = rule

  // COMMON CODE FOR HANDLING AN EMPTY ruleValue OR tableValue
  // isErroneous values have already been converted to '' values.
  if (tableValue === '' || ruleValue === '') {
    // In next test:  tableValue === ruleValue  => implies both are empty values
    if (relation === '==' && ruleValue === tableValue) return true
    // In next test:  tableValue !== ruleValue  => implies only one is an empty value
    if (relation === '<>' && ruleValue !== tableValue) return true

    // Consider an empty ruleValue a partial match to every string.
    // Hence '=' 'any' 'all' always match an empty string.
    // 'none' without a value written should therefore mean exclude an empty string;
    // But we expect users to write '<>' to exclude empty strings.  Hence, consider
    // 'none' with an empty value to be an edit in progress.
    if ((relation === '=' ||
      relation === 'any' ||
      relation === 'all')
      && ruleValue === '') return true

    // Truth table for relation === 'none'
    if (relation === 'none' && tableValue === ruleValue) return false
    if (relation === 'none' && tableValue !== ruleValue) return false
    // Fallthrough cases:
    //  isEqual but only one is empty
    // notEqual but both are empty
    return false
  }

  // Else neither ruleValue and tableValue is empty.
  // Fall through to dataType specific value comparisons
  else {
    if (relationType === 'string') {
      return testString(relation, ruleValue, tableValue)
    } else if (relationType === 'number') {
      return testNumber(relation, ruleValue, Number(tableValue))
    } else if (relationType === 'hyperlink') {
      const linkLabel = getHyperlinkLabel(tableValue.toString())
      return testString(relation, ruleValue, linkLabel)
    } else if (relationType === 'boolean') {
      return testBoolean(relation, ruleValue, tableValue)
    }
    // This path should never be taken
    if (process.env.NODE_ENV === 'development') {
      invariant(false, `Unrecognized relationType "${relationType}" in rowFiltering`)
    }
    return false
  }
}


export const filterRows_memoizedFunc = (paramsObj: FilterParams, refObjs: FilterRefs, otherArgsObj: FilterOtherArgs): FilterResult => {
  // Note: refsObj contains the column references of columns needed for filtering.
  // But these are ONLY used to determine whether the column data/resource changed.
  // Here we reference the tableValues using the getTableValue(colKey,rowKey) access function.
  const { derivedFilterRules, numRows } = paramsObj
  const { getTableValue } = otherArgsObj
  const numRowsStart = numRows
  const numFilters = derivedFilterRules.length
  const filterRuleCounts = new Array(0)
  var currentFilteredRowKeys = list(0, numRowsStart - 1)   // Assumption is 'all' rows
  // shortcut for the common case where filters do not exist.
  // Or more likely, filters exist but none are currently active.
  var isAnyFilterEnabled = derivedFilterRules.some(thisRule => thisRule.enabled && thisRule.colKey >= 0)
  if (numFilters === 0 || !isAnyFilterEnabled) {
    return {
      filteredRowKeys: currentFilteredRowKeys,
      filterRuleCounts: Array(numFilters).fill(numRowsStart),
    }
  }
  // One loop through currentFilteredRowKeys for each derivedFilterRule
  for (const thisRule of derivedFilterRules) {
    var { colKey, relationType, enabled, isDeleted, internalDataType, relation } = thisRule
    var isNotValidFilter = (colKey < 0 || !enabled || isDeleted ||
      Boolean(relationsMenuOptions[relationType][relation]) === false ||   // Relation is not valid
      (internalDataType === 'number' && isScryNumber(thisRule.value).errorID !== ''))
    if (isNotValidFilter) {
      filterRuleCounts.push(currentFilteredRowKeys.length)
      continue
    }
    const nextFilteredRowKeys = new Array(0)   // Fill this array with rowKeys passing the thisRule
    for (const rowKey of currentFilteredRowKeys) {
      var { value: tableValue, isErroneous } = getTableValue(colKey, rowKey, false)
      // General rule for an isErroneous tableValue:
      // We want the number of fitlered rows to be identical, whether editMode or viewMode
      // Hence, all isErroneous values need to be treated the same a viewMode ( a empty string)
      if (isErroneous) { tableValue = '' }
      const isPassing = testRule(thisRule, tableValue, relationType)
      if (isPassing) { nextFilteredRowKeys.push(rowKey) }
    }
    // Reset currentFilteredRowKeys a reduced set of rowKeys passing above thisRule.
    currentFilteredRowKeys = nextFilteredRowKeys
    filterRuleCounts.push(nextFilteredRowKeys.length)
  }
  return { filterRuleCounts, filteredRowKeys: currentFilteredRowKeys }
}






const indent = '\u00A0\u00A0'

const stringMenuObject: MenuItems = {
  '=': { displayedName: '=', menuText: ['=', indent + 'Partial string match'] },
  '==': { displayedName: '≡', menuText: ['≡', indent + 'Identical (full) match'] },
  '<>': { displayedName: '≢', menuText: ['≢', indent + 'Not Identical'] },
  'any': { displayedName: 'Any of', menuText: ['Any of', indent + 'Finds any word'] },
  'all': { displayedName: 'All of', menuText: ['All of', indent + 'Finds all words'] },
  'none': { displayedName: 'None of', menuText: ['None of', indent + 'Excludes any word'] },
  '<': { displayedName: '<', menuText: ['<', indent + 'Alphabetically before'] },
  '>': { displayedName: '>', menuText: ['>', indent + 'Alphabetically after'] },
  //'<='  : {displayedName : '≤', menuText: ['≤', indent + 'Alphabetically before or identical to'] },
  //'>='  : {displayedName : '≥', menuText: ['≥', indent + 'Alphabetically after or identical to'] },
}

const hyperlinkMenuObject = stringMenuObject

const numberMenuObject: MenuItems = {
  '==': { displayedName: '=', menuText: ['=', indent + 'equal'] },
  '<>': { displayedName: '≠', menuText: ['≠', indent + 'not equal'] },
  '<': { displayedName: '<', menuText: ['<', indent + 'less than'] },
  '>': { displayedName: '>', menuText: ['>', indent + 'greater than'] },
  '<=': { displayedName: '≤', menuText: ['≤', indent + 'less than or equal'] },
  '>=': { displayedName: '≥', menuText: ['≥', indent + 'greater than or equal'] }
}


const booleanMenuObject: MenuItems = {
  '==': { displayedName: '=', menuText: ['=', indent + 'equal to'] },
  '<>': { displayedName: '≠', menuText: ['≠', indent + 'not equal'] }
}


/* PostGIS approach:
Alternatives for points, line, polygons, and 2d ranges (timeRanges)
( I think these work better than 'set theory')
        -- Distance(geometry, geometry) : number
      Equals(geometry, geometry) : boolean
      Disjoint(geometry, geometry) : boolean
      Intersects(geometry, geometry) : boolean
      Touches(geometry, geometry) : boolean
      Crosses(geometry, geometry) : boolean
      Overlaps(geometry, geometry) : boolean
      Contains(geometry, geometry) : boolean
        -- Length(geometry) : number
        -- Area(geometry) : number
        -- Centroid(geometry) : geometry

      PostGIS is missing the equivalent of <, <=, >, >=
*/



const timeRangeMenuObject: MenuItems = {
  // Intersects
  '=': {
    displayedName: '≈', menuText: ['=', indent + 'Time ranges overlapping',
      indent + '(partial or complete overlap)']
  },
  // Equals - subset of intersects)
  '==': {
    displayedName: '≡', menuText: ['≡', indent + 'Time ranges identical',
      indent + '(data start/end = testValue start/end)']
  },
  // Equals - subset of intersects)
  '<>': {
    displayedName: '≢', menuText: ['≢', indent + 'Time ranges not identical',
      indent + '(data start/end != testValue start/end)']
  },
  // Disjoint
  // Touches
  // Contains: Data completely within value)  - subset of intersects)

  // Overlaps: Data completely Overlapping value - subset of intersects)
  'all': {
    displayedName: '∈', menuText: ['∈', indent + 'Time ranges within',
      indent + '(data start ≥ testValue start)',
      indent + '(data  end  ≤ testValue  end)']
  },

  '<': {
    displayedName: '<', menuText: ['<', indent + 'Time ranges before',
      indent + '(data end < test value start)']
  },
  '>': {
    displayedName: '>', menuText: ['>', indent + 'Time ranges after',
      indent + '(data start > test value stop)']
  },

  '<=': {
    displayedName: '≤', menuText: ['≤', indent + 'Time ranges before and/or within',
      indent + '(data end ≤ test value end)']
  },
  '>=': {
    displayedName: '≥', menuText: ['≥', indent + 'Time ranges within and/or after',
      indent + '(data start ≥ test value start)']
  },
}

const commonMenuObject = booleanMenuObject   // For now.  May change; May eventually become an empty set.

type RelationsMenuOptions = {
  [key in RelationType]: MenuItems
}

const relationsMenuOptions: RelationsMenuOptions = {
  timeRange: timeRangeMenuObject,
  string: stringMenuObject,
  hyperlink: hyperlinkMenuObject,
  number: numberMenuObject,
  boolean: booleanMenuObject,
  common: commonMenuObject,
}

export const getRelationMenu = (derivedColAttributesArray: Array<DerivedColAttributes>, colKey: number): MenuItems => {
  if (colKey < 0 || colKey >= derivedColAttributesArray.length) { return commonMenuObject }
  const { isDeleted, formatRule, internalDataType } = derivedColAttributesArray[colKey]
  if (isDeleted) { return commonMenuObject }
  if (internalDataType === 'number' && formatRule === 'boolTrueFalse') { return booleanMenuObject }
  return relationsMenuOptions[internalDataType]
}
