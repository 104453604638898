import type { TableComputedData }       from '../computedDataTable/getDefaultTableComputedData'
import type {DomNode}                   from '../computedDataTable/getDefaultTableComputedData'
import type {ViewTable_UnderlyingTile}  from './getTableTile'
import type {DraggableData}             from 'react-draggable'

import {TableComputedDataContext}       from '../viewTableTriple/TtGetComputedData'
import {getTableFpNameFromCaseName,
        activeFpToTableViewTile_rState} from './getTableTile'
import {useContext}                     from 'react'
import {xyClickToTableViewTile}         from './getTableTile'
import Draggable                        from 'react-draggable'
import {FpCyanHighlightLine, 
        calcHighlightLine}              from '../floatingPaletteNew/FpParent'  


let cyanLineDomNode : SVGPolylineElement        

const initialX = 15
const initialY = 200
const topY  = initialY - 11
const leftX = initialX - 11
let focusColDiv   : DomNode = null
let focusRowDiv   : DomNode = null
let focusCellDiv  : DomNode = null
let textDiv       : DomNode = null
let draggableDiv  : DomNode = null
const initFocusRowDiv = (element: DomNode): void => { focusRowDiv = element }
const initFocusColDiv = (element: DomNode): void => { focusColDiv = element }
const initFocusCellDiv = (element: DomNode): void => { focusCellDiv = element }
const initTextDiv      = (element: DomNode): void => { textDiv = element }
const initDraggableDiv = (element: DomNode): void => { draggableDiv = element }
const px = (value: number) : string => { return `${value}px` }



const handleDrag = ( data : DraggableData, tCD: TableComputedData ): void => {
    // This first block of code tests the underlying mouseClick and mouseMove code.
    // Given any mouse XY coordinate (with respect to the upper left corner of the view)
    // what is the underlying tile?  Each view has its own layout and tile names (caseName).
    // Hence, the function we call to convert mouse XY to tile is different for each view.

    const tile : ViewTable_UnderlyingTile  = xyClickToTableViewTile( initialX + data.x, initialY + data.y, tCD )
    const { caseName, colKey, rowKey, tertiaryKey, rowIndex, colIndex,
            cellTop, cellLeft, cellHeight, cellWidth,
            parentTop, parentHeight, parentLeft, parentWidth } = tile
    const fpName = getTableFpNameFromCaseName(caseName, colKey, tCD)
    
    // Now visually display the underlyingTile geometry.  Fastest way to debug above function.
    // Show the current 'horz slice'        
    if ( focusRowDiv ) {
      focusRowDiv.style.top = px(parentTop)
      focusRowDiv.style.height = px(parentHeight)
    }
    // Show the current 'vert slice'
    if ( focusColDiv ) {
      focusColDiv.style.left = px(parentLeft)
      focusColDiv.style.width = px(parentWidth)
    }
    // show the current clicked 'tile'
    if ( focusCellDiv && caseName !== 'empty' ) {
      focusCellDiv.style.top    = px(cellTop)
      focusCellDiv.style.left   = px(cellLeft)
      focusCellDiv.style.height = px(cellHeight)
      focusCellDiv.style.width  = px(cellWidth)
    } else if ( focusCellDiv ) {
      // out-of-bounds; no visible cell
      focusCellDiv.style.top    = px(0)
      focusCellDiv.style.left   = px(-200)
      focusCellDiv.style.height = px(0)
      focusCellDiv.style.width  = px(0)
    }
    // Render the encoded caseName information on the screen.
    // Looks almose exactly the the activeFp session state object.
    // However, not all caseNames correspond to a fpName.
    // Any caseName that DES NOT open the floating palette represents
    // some mouse action that is initiats a view event handler.
    // For example, in table view: sorting, filtering, hide/unhide, etc.
    if ( textDiv ) {
      const text =  `${caseName}\r\n` + 
                    `fpName: ${fpName}\r\n` +
                    `row Index/key: ${rowIndex}/${rowKey}\r\n` +
                    `col Index/key: ${colIndex}/${colKey}\r\n` +
                    `tertiaryKey: ${tertiaryKey}\r\n`
      textDiv.style.background = 'lightgreen'
      textDiv.textContent = text
    }

    // This second block of code will test how the activeFp maps to the view.
    // This can debug a series of transforms that start with the activeFp and end with the cyanLine.
    // caseName -> corresponding fpName (some, but not all caseNames correspond to a fpName -> 
    // -> create a 'working' activeFp obj (just a local variable! NOT setting session state) -> 
    // -> convert activeFp to the corresponding tile (each view must support this function) -> 
    // -> calc the cyan line coords (owned by FpParent) -> set the cyan Line domnode points
    //
    // This 2nd set of transforms should match the cell that was clicked and highlighted above.
    // However, the starting point is the fpName!  
    const activeFp = {fpName, primaryKey:colKey, secondaryKey:rowKey, tertiaryKey, left:0, top:0}
    const {scrollLeft, scrollTop, widthObj:w, heightObj:h, derivedColOrder, sortedRowKeys, pinnedRowKeys} = tCD
    const selectedCell = activeFpToTableViewTile_rState( scrollLeft, scrollTop, w, h, activeFp, 
                                                          derivedColOrder, sortedRowKeys, pinnedRowKeys )
    // There is no need to actually render the FpParent for this tool 
    const fpPosition= ( {fpTop:0, fpLeft:0, fpWidth:0, fpHeight:0} )
    const points = calcHighlightLine( selectedCell, fpPosition )
    if ( cyanLineDomNode ) {
      cyanLineDomNode.setAttribute('points', points)
      cyanLineDomNode.style.visibility = selectedCell.isCellVisible ? 'unset' : 'hidden'
    }        
}


const handleStop = ( ): void => {
    if ( focusRowDiv ) {
      focusRowDiv.style.top = px(initialY)
      focusRowDiv.style.height = px(0)
    }
    if ( focusColDiv ) {
      focusColDiv.style.left = px(initialX)
      focusColDiv.style.width = px(0)
    }
    if ( focusCellDiv ) {
      focusCellDiv.style.top    = px(0)
      focusCellDiv.style.left   = px(-20)
      focusCellDiv.style.height = px(0)
      focusCellDiv.style.width  = px(0)
    }
    if ( textDiv ) {
      textDiv.textContent = ''
      textDiv.style.background = 'unset'
    }
    if (draggableDiv ) {
      draggableDiv.style.transform = `translate( 0px, 0px )`
      draggableDiv.style.top = px(topY)
      draggableDiv.style.left = px(leftX)
    }
    if ( cyanLineDomNode ) {
      cyanLineDomNode.setAttribute('points', '0 0 0 0')
    } 
}


export const ActionDebugGetTableTile : React.FC = ( ) => {
    //const {viewName} = useAppView()
    const tCD = useContext(TableComputedDataContext) 
    return (

      <div className={'rc_ActionDebugGetTableTile'}>
          <div className={'textWindow'}
              ref= { initTextDiv }
              style={{
                position: 'absolute', top:300, left: 0,
                width: 190, height: 90,
                fontSize: '14px',
                padding: 6,
                whiteSpace: 'pre-wrap',  
              }}/>
          <div className={'focusRowDebug'}
              ref= { initFocusRowDiv }
              style={{ background: 'magenta',
                      opacity: '.2',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: 0,
              }}/>
          <div className={'focusColDebug'}
              ref= { initFocusColDiv }
              style={{ background: 'cyan',
                       opacity: '.2',
                       position: 'absolute',
                       top: 0,
                       left: 0,
                       width: 0,
                       height: '100%',
              }}/>
          <input className={'focusCellDebug'}
              ref= { initFocusCellDiv }
              style={{ position: 'absolute',
                      top: 0,
                      left: -20,
                      width: 0,
                      height: 0,
                      border: '2px solid red',
                      background: 'transparent',
                      boxSizing: 'border-box',
              }}/>

          <FpCyanHighlightLine
              points={"0 0 0 0"} 
              isCellVisible={false}
              setCyanLineDomNode={(n)=>cyanLineDomNode=n}
          />

          <Draggable  
              onDrag ={ (e, data) => handleDrag( data, tCD )}
              onStop ={ () => handleStop( )}
              position={{x:0, y:0}}
              >
                <div  className={'ActionDebugControl'}
                  ref= { initDraggableDiv }
                  style={{position: 'absolute',
                          top: topY , left: leftX,
                          width: 21, height: 21}}>

                          <div style={{
                              width:'100%', height:'100%', 
                              background : 'green', opacity: 0.3}}/>
                          <div style={{
                              position: 'absolute', top:0, left:0,
                              width:5, height:5, 
                              marginLeft:8, marginTop:8,
                              background:'blue'}}/>
                </div>
          </Draggable>
      </div>
    )
}