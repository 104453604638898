import {PureComponent} from 'react'
import type {TableDomNodes, DomNode} from '../computedDataTable/getDefaultTableComputedData'
import SVGwrapper2 from '../SVGs/SVGwrapper2'
import SVGhideUnhide  from '../SVGs/SVGhideUnhide'
import SVGsortCol from '../SVGs/SVGsortCol'
import SVGdragCol from '../SVGs/SVGdragCol'
import {TABLE_CONTROLS_OPACITY} from '../sharedComponents/constants'

type OwnProps = {
  foregroundColor: string,
  colHeight: number,
  priorHiddenColumns:number,
  isSortable: boolean,
}

export class ColumnControls extends PureComponent<OwnProps> {
  render() {
    const {colHeight, foregroundColor, priorHiddenColumns, isSortable} = this.props

    return (
        <div className={`rc_ColumnControls`}
          style = {{ position: 'absolute', top:0, left:0,
          height: colHeight, width: '100%', }} >

          {/* Only purpose of next container is to truncate the controls by 4px on the right edge */ }
          <div className={'TruncatingImageOnRightEdgeBy4px'}
          style={{ position:'relative', height:'100%', width:'calc(100% - 4px)', overflow:'hidden' }} >

              <div className='SVGsizedContainer'
              style={{ position:'absolute', height: colHeight, width: colHeight }}>
                  <SVGwrapper2>
                      <SVGhideUnhide
                        color={foregroundColor}
                        typeID={'hideAndUnhide'}
                        indexID={priorHiddenColumns}
                        width={colHeight}
                        height={colHeight}
                      />
                  </SVGwrapper2>
              </div>


              {/* Next SVG is the DnD image.  But it is NOT the capture area.  This is managed in ActionDndCol.js */}
              <div className='SVGsizedContainer'
              style={{ position:'absolute', left: colHeight, height: colHeight, width: colHeight }}>
                  <SVGwrapper2>
                      <SVGdragCol
                        color={foregroundColor}
                        width={colHeight}
                        height={colHeight}
                        opacity={TABLE_CONTROLS_OPACITY}
                      />
                  </SVGwrapper2>
              </div>

              <div className='SVGsizedContainer'
              style={{ position:'absolute', left: 2*colHeight, height: colHeight, width: colHeight }}>
                  { isSortable &&
                      <SVGwrapper2>
                          <SVGsortCol
                            color={foregroundColor}
                            width={colHeight}
                            height={colHeight}
                          />
                      </SVGwrapper2>
                  }
              </div>

          </div>
        </div>
    )
  }
}





type OwnProps2 = {
  foregroundColor: string,
  colHeight: number,
  numColsHiddenInGapBetweenTables:number,
  // gapLockedMoving: number,
}
export class ColumnControlsLastLockedHidden extends PureComponent<OwnProps2> {
  render() {
    const {colHeight, foregroundColor, numColsHiddenInGapBetweenTables, } = this.props
    if ( numColsHiddenInGapBetweenTables === 0 ) return null
    return (
        <div className={'LastLockedHiddenControl'}
        style = {{  
          position: 'absolute', 
          right: -colHeight,  // 0 will put this control with it's right edge abutting lockedTable's right edge.
                                    // This shifts control so control's left edge abutts lockedTable's right edge.
          width:  colHeight,
          //background: 'pink', 
          height: colHeight }} >

              <SVGwrapper2>
                <SVGhideUnhide
                  color={foregroundColor}
                  typeID={'unhideOnly'}
                  indexID={numColsHiddenInGapBetweenTables}
                  width={colHeight}
                  height={colHeight}
                />
              </SVGwrapper2>
        </div>
      )
    }
  }



// Case of lastMovingColumn hidden.
// This is a scrolled Container, containing ONLY the control.
// the 'left' offset of the icon should correspond to the
// scroll control space between the thumb and the right edge of scrollControl.
// In units of px, this is:
// ContentWidth - ContainerWidth - ScrollLeft
// = movingRequired - movingAllocated - sizeRecommendation.scrollLeft
// This should be the value passed in from LayoutMain.
// And should be expression used in scrollLeft update function.

type OwnProps3 = {
  tableDomNodes: TableDomNodes,
  foregroundColor: string,
  colHeight: number,
  priorHiddenColumns:number,
  lastMovingRightWidth: number,
  scrollLeftOffset: number,
}
export class ColumnControlsLastMovingHidden extends PureComponent<OwnProps3> {

  refLastMovingUnhideControl = (element: DomNode): void => {
    this.props.tableDomNodes.lastMovingUnhideControl = element
  }

  render() {
    const {colHeight, scrollLeftOffset, foregroundColor,
      lastMovingRightWidth, priorHiddenColumns } = this.props
    if ( priorHiddenColumns === 0 ) return null
    return (
          <div className={'lastMovingUnhideControl_container'}
          style={{ position: 'absolute', right:0, top:0,
          height: colHeight, width: lastMovingRightWidth,
          //overflow: 'visible',
          overflow: 'hidden', }}>

              <div className={'lastMovingUnhideControl_content'}
              ref={ this.refLastMovingUnhideControl }
              style={{ position:'relative', transform: `translate( ${scrollLeftOffset}px, 0px)`,
              height: colHeight, width: colHeight,}} >

                  <SVGwrapper2>
                      <SVGhideUnhide
                        color={foregroundColor}
                        typeID={'unhideOnly'}
                        indexID={priorHiddenColumns}
                        width={colHeight}
                        height={colHeight}/>
                  </SVGwrapper2>

              </div>
          </div>
    )
  }
}
