import {ViewTable}        from '../viewTable/ViewTable'

type TtSwapViewAnimation_Props = {
  sourcePlotXyComputedState : string, 
  workingPlotXyComputedState : string, 
}


// Currently just a placeholder  JPS 2021-08-30
export const TtSwapViewAnimation: React.FC<TtSwapViewAnimation_Props> = ( ) => {
    return (
      <>
        <ViewTable/> 
      </>
    )
}

