import { MatchInfo } from "./parseScryInputString"

type ScryHighlightColorMode = 'redChar' | 'redBack'   // Errors displayed as red characters OR black/white text on red background ??

export type HighlightArray = Array<{
    lineNum:number,
    start:number,
    end:number,
    colorMode?: ScryHighlightColorMode
  }>

export type ScryNumberType = 'DATA_ERR' | 'INTEGER' | 'FLOAT' | 'EXPONENTIAL' |
                      'B60' | 'B60B60' | 'BOOL_TF' | 'MISSING_VALUE' | 'IEEE'

// Type returned from custom function that finds and parses all number formats:
export interface ScryNumber {
  internalFormat: string,
  originalInput: string,
  numberType: ScryNumberType,
  errorID : string,
  warningID: string,
  highlightArray: HighlightArray,
}

// Type returned from multiple functions that tag 'specific matching strings' such as colNames, funcNames, flow control Keywords, ...
// Nominally emtpy strings return, which means I may or may not have tagged this string, but no errors to report so OK to continue
export interface ScryTaggingErrorCheck {
  errorID : string,
  warningID: string,
  highlightArray: HighlightArray,
}

export interface ScryInputString {
  inputStrg: string
  matchArr: Array<MatchInfo>
}

export const getDefaultScryNumber = ():ScryNumber => {
  const temp : ScryNumber = {
    internalFormat: '',
    originalInput: '',
    numberType: 'MISSING_VALUE',
    errorID : '',
    warningID : '',
    highlightArray: []
  }
  Object.seal(temp)
  return temp
}
