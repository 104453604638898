import { PureComponent } from 'react'
import type { ReactNode } from 'react'

const DEBUG = false

/*
WRAPPER'S PURPOSE:
- Center the control in it's available width and height.
- Size the SVG image to svgWidth and svgHeight input props.
- Scale the control's size proportionally along with the browser's magnification scaling.
*/
type Props = {
  children: ReactNode
}

class SVGwrapper2 extends PureComponent<Props> {


  render ( ) {
      //const {svgHeight, svgWidth, child  } = this.props;

      // Following CSS will center a SVG.
      // Note: The size of the container is set by the parent.
      // Container may be bigger than the svgSize.
      // svgSize is passed to the child icon.
      const imgStyle={
        height: '100%', width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: DEBUG ? 'grey' : 'transparent',
      }

      return (
        <div className={'rc_SVGwrapper2'} style={imgStyle}>
          {this.props.children}
        </div>
      )
  }
}

export default SVGwrapper2
