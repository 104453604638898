import type { Property } from 'csstype'
import { Component } from 'react'
import { getClosestEnumeratedIndex, typedKeys } from '../sharedFunctions/utils'
import type { SliderControlParams } from '../types'
import reactDispatch from './reactDispatch'
import SliderControl from './SliderControl'

/*
// This function assumes all enumerated options are Array<number> and ordered from
// smallest to largest.  IF one wished to create some other type of enumerated list,
// or provide a set of options from largest to smallest, then this function will need
// to be generalized (or choose not to use this func).

export const getClosestEnumeratedValue = ( val: number, options: Array<number> ) : number => {
  return options[  getClosestEnumeratedIndex(val, options) ]
}

const getClosestEnumeratedIndex = ( inValue: number, options: Array<number> ) : number => {
  if ( isNaN(inValue)) { return Math.floor(options.length/2) }
  let foundIndex = options.indexOf(inValue)
  if ( foundIndex >=0 ) { return foundIndex }
  // Otherwise, find the closest legal alternative and return the array index.
  if ( inValue < options[0] ) { return 0 }
  const numOptions = options.length
  for (let i=1; i<numOptions; i++) {
    if (options[i] > inValue) {
      return ( inValue > (options[i]+options[i-1])/2 ) ? i : i-1
    }
  }
  return (numOptions - 1)
}

*/

type OwnProps = {
  indent: number     // slider placement in SideBar
  preSpacer: number  // slider placement in SideBar
  enumLabels: string[]
  enumValues: number[]
  modPath: string | string[]
  currentValue: number
  styleName: string
  layoutLines: number
}
type DefaultProps = {
  visibility: Property.Visibility
  marginRight: number
  titleWidth: number | undefined
  sliderWidth: number | undefined
  displayWidth: number | undefined
}
type Props = OwnProps & DefaultProps

class StyleEnumSlider extends Component<Props> {

  static defaultProps: DefaultProps = {
    visibility: 'unset',
    marginRight: 0,
    titleWidth: undefined,
    sliderWidth: undefined,
    displayWidth: undefined,
  }

  // Unfortunately, this component needs a shouldComponentUpdate
  // Because sometimes the enumLabels/enumValues are derived from
  // the server state values.  And since these are new arrays on
  // every render, we must directly compare the array values.
  shouldComponentUpdate ( nextProps: Props ) {
    //console.log( 'shouldUpdate?')
    const keys = typedKeys(nextProps)
    return keys.some( thisKey => {
      var nextVal = nextProps[thisKey]
      var lastVal = this.props[thisKey]
      if (thisKey === 'enumLabels' || thisKey === 'enumValues') {
        if ((nextVal as Array<any>).length !== (lastVal as Array<any>).length ) {
          return true
        } else {
          return (nextVal as Array<any>).some( (val,i)=> nextVal[i] !== lastVal[i] )
        }
      } else {
        return ( nextVal !== lastVal )
      }
    })
  }

  sliderParams: SliderControlParams | null = null
  lastIndex: number | undefined

  render() {
    const {enumLabels, enumValues, modPath, styleName, currentValue, indent, displayWidth,
           preSpacer, layoutLines, visibility, marginRight, titleWidth, sliderWidth} = this.props
    this.sliderParams = {
      styleName: styleName,
      resourceName: '',
      funcType: 'enum',
      valMin: 0,
      valMax: enumLabels.length-1,
      numSteps: enumLabels.length-1,
      enumLabels: enumLabels,
      displayFixed: 0,
      // value comes from state.  Find the closest valid
      // enumerated value.  (We are correcting any state values
      // what may not be valid.)  Specifically, IF/WHEN we change the legal
      // enumerated values, this trick allows us to NOT have to update
      // all the corresponding slider state values.
      onInit: (value: number): number => {
        return getClosestEnumeratedIndex( value, enumValues )
      },
      onStart: (index: number): void => {
        //console.log( 'onStart', index  )
        this.lastIndex = index
      },
      onDrag: (index: number): void => {
        let newVal = enumValues[index]
        let mods = []
        //console.log( 'onDrag', index, newVal, modPath, enumValues, enumLabels  )
        if ( typeof(modPath) === 'string' ) {
          mods.push({ newVal: newVal, path: modPath })
        } else {
          // modPath is an array (for example, push this value to every sKey )
          // Depends on the usage by the Parent.
          // For example, in plotXy'engine' interface, we desire the smoothing
          // slider modify the seriesLineSmoothing parameter for all sKeys.
          // Hence the modPath should be an array of 'n' paths for 'n' sKeys.
          modPath.forEach( thisPath => mods.push({ newVal: newVal, path: thisPath }) )
        }
        reactDispatch( mods, `Style - ${styleName}`, 'drag' )
      },
      onStop: (index: number): void => { reactDispatch( [], `Style - ${styleName}`, 'dragStop' ) },
    }
  

    return (
      <SliderControl
        indent={indent}
        preSpacer={preSpacer}
        titleWidth={titleWidth}
        currentValue={currentValue}
        params={this.sliderParams}
        layoutLines={layoutLines}
        visibility={visibility}
        marginRight={marginRight}
        sliderWidth={sliderWidth}
        displayWidth={displayWidth}
      />
    )
  }
}

export default StyleEnumSlider
