import joi from 'joi'
import type { ValidationError, ValidationResult } from 'joi'
import { get, isLength, set, toNumber, toPath } from 'lodash'
import changeAttributes from '../../shared/types/changeAttributes'
import loginAttributes from '../../shared/types/loginAttributes'
import plotAttributes from '../../shared/types/plotAttributes'
import sourceAttributes from '../../shared/types/sourceAttributes'
import sourcemlAttributes from '../../shared/types/sourcemlAttributes'
import sourcesvAttributes from '../../shared/types/sourcesvAttributes'
import tableAttributes from '../../shared/types/tableAttributes'
import tabledataAttributes from '../../shared/types/tabledataAttributes'
import tablelookAttributes from '../../shared/types/tablelookAttributes'
import tagAttributes from '../../shared/types/tagAttributes'
import userAttributes from '../../shared/types/userAttributes'
import userspiAttributes from '../../shared/types/userspiAttributes'
import type {Attributes, Resource, ResourceType} from '../jsonapi/types'
import type {GenericObject, Mod} from '../types'
import { isObject } from './isObject'

// export type ValidationDetail = {
//   message: string,
//   path: Array<string>,
//   type: string,
//   context: {
//     key: string,
//     label: string,
//   }
// }
// export type ValidationError = Error & {
//   name: string,
//   isJoi: boolean,
//   details: Array<ValidationDetail>,
//   annotate: (stripColors: boolean) => string,
//   _object: Object,
// }

// export type ValiationResult = {
//   error?: ValidationError,
//   value: Object,
// }

type ResourceToAttributesMap = {
  [key in ResourceType]: GenericObject
}

const types: ResourceToAttributesMap = {
  changes: changeAttributes,
  logins: loginAttributes,
  plots: plotAttributes,
  searchtables: tableAttributes,
  sourcemls: sourcemlAttributes,
  sources: sourceAttributes,
  sourcesvs: sourcesvAttributes,
  tabledatas: tabledataAttributes,
  tablelooks: tablelookAttributes,
  tables: tableAttributes,
  tags: tagAttributes,
  users: userAttributes,
  userspis: userspiAttributes,
}

const validate = (resource: Resource): ValidationResult<Attributes> => {
  const schema = types[resource.type]
  if (schema) {
    return joi.validate<Attributes>(resource.attributes, schema, { abortEarly: false })
  } else {
    return {
      error: {
        annotate: () => ('no schema'),
        name: 'no schema',
        message: 'no schema',
        isJoi: false,
        details: [],
        _object: null
      },
      value: resource,
      then: (onfulfilled, onrejected) => (new Promise((onfulfilled, onrejected) => {onrejected('no schema')})),
      catch: (onrejected) => (new Promise(((onfulfilled, onrejected) => {onrejected('no schema')})))
    }
  }
}

type ResourceToReadOnlyAttrMap = {
  [key in ResourceType]: string[] | null
}

const readOnlyAttributes: ResourceToReadOnlyAttrMap = {
  changes: null,
  logins: null,
  plots: null,
  searchtables: null,
  sourcemls: null,
  sources: null,
  sourcesvs: null,
  tabledatas: null,
  tablelooks: null,
  tables: null,
  tags: null,
  users: null,
  userspis: null,
}

const getReadOnlyAttributes = (type: ResourceType): Array<string> => {
  if (!readOnlyAttributes[type]) {
    // parse the schema for type and cache for re-use
    const schema = types[type]
    if (schema) {
      const typeReadOnlyAttributes: string[] = []
      const attributes = Object.keys(schema)
      for (const attribute of attributes) {
        if (schema[attribute]._meta.indexOf('readonly') >= 0) {
          typeReadOnlyAttributes.push(attribute)
        }
      }
      readOnlyAttributes[type] = typeReadOnlyAttributes
    } else {
      readOnlyAttributes[type] = []
    }
  }
  return readOnlyAttributes[type]!
}

const applyMod = (object: Resource, mod: Mod): void => {
  const {newVal: value, path: strPath} = mod

  // fills all arrays with null, so there are no sparse arrays
  const path = toPath(strPath)
  const length = path.length
  const lastIndex = length - 1
  let index = -1
  let nested = object

  while (nested != null && ++index < length) {
    const key = path[index]
    let newValue = value;

    if (index !== lastIndex) {
      let objValue = get(nested, key)
      newValue = isObject(objValue)
        ? objValue
        : (isLength(toNumber(path[index + 1])) ? [] : {})
    }

    // null fill arrays as necessary
    if (Array.isArray(nested)) {
      const numericKey = toNumber(key)
      if (isLength(numericKey)) {
        const curLen = nested.length
        if (numericKey >= curLen) {
          nested.length = numericKey + 1
          nested.fill(null, curLen, numericKey)
        }
      }
    }

    set(nested, key, newValue)
    nested = get(nested, key)
  }
}

const verifyMod = (type: ResourceType, mod: Mod): ValidationError | null => {
  if (mod.op === 'del') {
    return null
  }
  const schema = types[type]
  const mockResource = {
    id: '',
    type,
    attributes: {}
  }
  applyMod(mockResource, mod)
  const nomalizedPath = toPath(mod.path)
  if (nomalizedPath[0] === 'attributes') {
    const testObj = mockResource.attributes
    const rootAttribute = nomalizedPath[1]
    const testSchema = {
      [rootAttribute]: schema[rootAttribute]
    }
    const result = joi.validate(testObj, testSchema, {convert: false})
    return result.error
  } else {
    return null
  }
}

const validator = {
  getReadOnlyAttributes,
  validate,
  verifyMod,
}

export default validator
