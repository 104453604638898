import { CSSProperties } from 'react'
import { FONT_FAMILY_CSS_FORMAT } from '../sharedComponents/constants'

var canvas: HTMLCanvasElement | null = null

// Measures Text as it would display in HTML or SVG (leading and trailing white
// space are ignored, internal runs of white space are treated as a single space).
// Non breaking space (&nbsp; or \u00a0 or option+space on the Mac) is respected
// as a printing character.
export const measureText = (text: string, fontSize: string, fontStyle = 'normal',
    fontFamily: string = FONT_FAMILY_CSS_FORMAT, shouldTrim: boolean = true, shouldCleanSpaces: boolean = true ): number => {

  if (!canvas) {
    canvas = document.createElement('canvas')
  }
  // don't use \s to match whitespace, because it matches non-breaking space, but html will display non-breaking space
  // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp#character-classes
  if (text) {
    if ( shouldTrim ) { text = text.trim() }
    if ( shouldCleanSpaces ) {
      text = text.replace(/[ \f\n\r\t\v\u1680\u2000-\u200a\u2028\u2029\u202f\u205f\u3000\ufeff]+/g, ' ')
    }
    const context = canvas.getContext('2d')
    if (context) {
      context.font = `${fontStyle} ${fontSize} ${fontFamily}`
      const metrics = context.measureText(text)
      return metrics.width
    }
  }
  return 0
}

export const getStringWidth = (inputString: string, style: CSSProperties) => {
  const hiddenElement = document.createElement('div')
  for (const [key, value] of Object.entries(style)) {
    hiddenElement.style.setProperty(key, value)
  }

  hiddenElement.style.visibility = 'hidden'
  hiddenElement.style.position = 'absolute'
  hiddenElement.style.top = '-9999px'
  hiddenElement.style.left = '-9999px'
  hiddenElement.textContent = inputString

  document.body.appendChild(hiddenElement)
  const { width } = hiddenElement.getBoundingClientRect()
  document.body.removeChild(hiddenElement)

  return width
}

export default measureText

export const measureLongestText = ( inArr: Array<string>, fontSize: string, fontStyle:string='normal', fontFamily: string = FONT_FAMILY_CSS_FORMAT) : number => {
  var longest = 0
  inArr.forEach( strg=> longest=Math.max( longest, measureText(strg, fontSize, fontStyle, fontFamily )) )
  return longest
}


export const measureLongestStringMappingText = ( stringMap: Array<{mappedText: string}>, userValues: Array<number>,
        fontSize: string, fontStyle: string = 'normal', fontFamily: string = FONT_FAMILY_CSS_FORMAT ) : number => {
  var longest = 0
  const mapLen = stringMap.length
  userValues.forEach( i => {
    if (i <=0 || i >= mapLen ) {
      var thisString = ''
    } else {
      thisString = stringMap[i].mappedText
    }
    longest = Math.max( longest, measureText( thisString, fontSize, fontStyle, fontFamily ))
  })
  return longest
}


export const measureHTMLwidth = (htmlText: string, fontSize: string, fontFamily: string = FONT_FAMILY_CSS_FORMAT): number => {
  if ( htmlText && document && document.body ) {
    let testElement = document.createElement('mySpan')
    testElement.style.font = fontFamily
    testElement.style.fontSize = fontSize
    testElement.style.height = 'auto'
    testElement.style.width = 'auto'
    testElement.style.position = 'absolute'
    testElement.style.whiteSpace = 'no-wrap'
    testElement.innerHTML = htmlText
    //$FlowFixMe
    document.body.appendChild(testElement)
    let width = Math.ceil(testElement.clientWidth)
    //$FlowFixMe
    document.body.removeChild(testElement)
    return width
  }
  return 0
}




//const nonBreakingSpace = String.fromCharCode( parseInt('00a0',16))


// Function to find (by character count) when a formula expression string is too long (illegal).
// Does NOT trim the formula before measurement.  Leading indents count as used space.
export const findLegalCharCount = ( strg: string, maxLegalLength:number, fontSize: number, fontStyle:string='normal',
     fontFamily: string = FONT_FAMILY_CSS_FORMAT ) : {isLegal:boolean, legalCharCount:number} => {

  // This function supports the user input string for column formula editing.
  // It needs to measure string length INCLUDING all white space.
  // ( no longer true!! as cleanScryTextInput removes redundant spaces)
  // This helper function replaces all the spacebar characters with a non-breaking space.
  //const full = (strg:string) : string => { return strg.replace( / /g, nonBreakingSpace) }

  var currentTestStrg = strg
  const shouldTrim = false
  const shouldCleanSpaces = false
  var currentTestStrgLength:number  = measureText( strg, fontSize+'px', fontStyle, fontFamily, shouldTrim, shouldCleanSpaces )
  if ( currentTestStrgLength < maxLegalLength ) {
    return {isLegal:true, legalCharCount: strg.length}  // Early exit for legal strings.
  }

  const ASPECT_RATIO = 0.6   // Probably closer to 0.6.  But setting less <=0.5 for a more robust conversion.
  const FONT_WIDTH = fontSize * ASPECT_RATIO

  // One crude estimate, followed by 3 newton search refinements to get to answer (within 0-1 characters of the answer).
  // If speed becomes a problem, this convergence could be easily improved.
  var currentCharCount = Math.round( maxLegalLength / FONT_WIDTH )
  //console.log( 'seedValue =', currentCharCount )
  let loopCounter = 0
  while (loopCounter < 3) {
    currentTestStrg  = strg.slice(0,currentCharCount)
    currentTestStrgLength = measureText( currentTestStrg, fontSize+'px', fontStyle, fontFamily,  shouldTrim, shouldCleanSpaces )
    currentCharCount += Math.round( (maxLegalLength - currentTestStrgLength) / FONT_WIDTH )
    loopCounter++
    //console.log( 'newton refinement =', currentCharCount )
  }

  // Unfortunately, newton search can oscillate and it is not clear at this time how to robustly fix.
  // So follow up the newton search with a simple test of nearby solutions.
  // Add two characters to the newton search result, then step backwards, character by character
  // until we find the first legal solution.
  currentCharCount += 3
  do {
    currentCharCount--
    currentTestStrg = strg.slice(0,currentCharCount)
    currentTestStrgLength = measureText( currentTestStrg, fontSize+'px', fontStyle, fontFamily,  shouldTrim, shouldCleanSpaces )
  }
  while ( currentTestStrgLength > maxLegalLength )
  return {isLegal:false, legalCharCount: currentCharCount}
}



export const getCursorXY = (input : HTMLTextAreaElement,
           selectionPoint : number ) : {x:number, y:number} => {

  const { offsetLeft: inputX, offsetTop: inputY } = input
  // create a dummy element that will be a clone of our input
  const div = document.createElement('div')
  // get the computed style of the input and clone it onto the dummy element
  const copyStyle = getComputedStyle(input)
  for (const [key, value] of Object.entries(copyStyle)) {
    div.style.setProperty(key, value)
  }

  // we need a character that will replace whitespace when filling our dummy element if it's a single line <input/>
  const swap = '.'
  const inputValue = input.tagName === 'INPUT' ? input.value.replace(/ /g, swap) : input.value
  // set the div content to that of the textarea up until selection
  const textContent = inputValue.substr(0, selectionPoint)
  // set the text content of the dummy element div
  div.textContent = textContent
  if (input.tagName === 'TEXTAREA') div.style.height = 'auto'
  // if a single line input then the div needs to be single line and not break out like a text area
  if (input.tagName === 'INPUT') div.style.width = 'auto'
  // create a marker element to obtain caret position
  const span = document.createElement('span')
  // give the span the textContent of remaining content so that the recreated dummy element is as close as possible
  span.textContent = inputValue.substr(selectionPoint) || '.'
  // append the span marker to the div
  div.appendChild(span)
  // append the dummy element to the body
  // $FlowFixMe
  document.body.appendChild(div)
  // get the marker position, this is the caret position top and left relative to the input
  const { offsetLeft: spanX, offsetTop: spanY } = span
  // lastly, remove that dummy element
  // NOTE:: can comment this out for debugging purposes if you want to see where that span is rendered
  // $FlowFixMe
  document.body.removeChild(div)
  // return an object with the x and y of the caret. account for input positioning so that you don't need to wrap the input
  //console.log( inputY, spanY )
  return { x: inputX + spanX, y: inputY+spanY }
}
