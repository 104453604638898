const joi = require('joi')


const tableAttributes = {
  tableTitle: joi.string()
    .description('The table title'),
  publisherTitle: joi.string().allow('')
    .description('The publisher title'),
  tableDescription: joi.object().keys({
    text: joi.string().allow(''),
    links: joi.array().items(joi.object().keys({
      startCharIndex: joi.number().integer(),
      stopCharIndex: joi.number().integer(),
      href: joi.string().allow('')
    }))
  }),
  dataSources: joi.array().items(
    joi.object().keys({
      dataSourceType: joi.string().valid( 'searchEngine','genData','csvCreate' ),
      date: joi.date().iso().allow(null).description('Read only date table created.'),
      file: joi.object().allow(null).description('System File object; null if source is not a *.csv file.'),
      numRows:  joi.number().integer().description('Count of rows in source data.'),
      numCols:  joi.number().integer().description('Count of cols in source data.')
    })
  ),
  curatorNotes: joi.string().allow('').description('Notes by and available only to the curator.'),
  isSortable: joi.boolean().description('false means row order is constrained to input row order'),
  columns: joi.array().items(joi.object().keys({
      colTitle: joi.string().allow(''),
      colSourceTitle: joi.string().allow(''),
      units: joi.string().allow(''),
      colDescription: joi.object().keys({
        text: joi.string().allow(''),
        links: joi.array().items(joi.object().keys({
          startCharIndex: joi.number().integer(),
          stopCharIndex: joi.number().integer(),
          href: joi.string().allow('')
        }))
      }),
      formula: joi.array().items(joi.string().allow(''))
               .description('Array of formula strings. One string per line.'),
      isDeleted: joi.boolean(),
      isDeletedNotRestorable: joi.boolean(),
      isPublished: joi.boolean(),
      isChoosenNotForPublication: joi.boolean(),
      deletedDate: joi.string().allow(''),
      isDepCol: joi.boolean(),
      isKey: joi.boolean()
        .description('Flag indicating whether this column is part of the row key or not.'),
      colDataType: joi.string().valid('string', 'number', 'numberSeconds', 'numberDegrees', 'hyperlink')
    }).allow(null))
    .description('Definition for the columns of the table'),
  updatedDate: joi.date().iso().description('Date table last modified.'),

  // server side managed data (read only to clients)
  createdDate: joi.date().iso().allow(null).meta('readonly').description('Read only date table created.'),
  numRowsUnfiltered: joi.number().integer().description('Read only count of rows in table.'),
  numStars: joi.number().integer().allow(null).meta('readonly').description('Read only count of stars for table.'),
}

module.exports = tableAttributes
