import { Component } from 'react'
import { Button, Menu, MenuItem, Wrapper } from 'react-aria-menubutton'
import constants  from '../sharedComponents/constants'
import SVGselectCaret from '../SVGs/SVGselectCaret'

import './EditorMenuButton.css'  // Copied, then modified sharedComponents/AriaMenuButton.css

export type MenuItemValue = {
  displayedName: string
  menuText: string[]
  optionalPreGap?: number
}

export type MenuItems = {
  [key: string]: MenuItemValue
}

type DisplayStyle = {
  top: number
  fontSize: number
  fontWeight: string
  lineHeight: number | string
  paddingTop: number
}
class EditorMenuButton extends Component<{
      menuItems: MenuItems,
      onSelection: (value: string) => void,
      onClick: () => void,
      selectedValue: string,
      widthDisplayText: number,
      widthDisplayCaret: number,
      textAlign: 'left' | 'center',
      height: number,
      menuWidth: number,
      menuTop: number|string,
      menuRight: number|string,
      menuLeft: number|string,
      menuBottom: number|string,
      // These next two values give us the ability to
      // put any message we want into the menu button.
      // If displayedValueOverrideText is empty '',
      // then these next two values are ignored.
      displayValueOverrideText: string,
      displayValueColor: string,
      // Next param will determine whether the selection
      // display is styled like a:
      //    selector input
      //    or button
      shouldStyleAsButton: boolean,  // defaults to false
      fontSizeSelectButton: number,
      fontSizeMenuLine0: number,
      fontSizeMenuLine1: number,

    }> {

  static defaultProps = {
    onClick: ()=>{},
    shouldStyleAsButton: false,
    fontSizeSelectButton: 14,
    fontSizeMenuLine0 : 14,
    fontSizeMenuLine1 : 13,
    displayValueOverrideText: '',
    displayValueColor: 'black',
    widthDisplayCaret: 20,
    textAlign: 'left',
    menuTop: 'unset',
    menuBottom: 'unset',
    menuLeft: 'unset',
    menuRight: 'unset'
  }

  render() {
    var {menuItems, onSelection, onClick, selectedValue, height,
         menuWidth, menuTop, menuRight, menuLeft, menuBottom,
         widthDisplayText, widthDisplayCaret, textAlign, shouldStyleAsButton,
         fontSizeSelectButton, fontSizeMenuLine0, fontSizeMenuLine1, displayValueColor,
         displayValueOverrideText } = this.props

    // Typical usage model: If the current selectedValue is not valid, parent can pass
    // any invalid selectedValue they wish.  Options:
    // 1-  Pass '' so invalid option is not displayed.
    // 2-  If you wish to display the current invalid setting, you can pass that.
    // 3-  If you wish to display some message, such as 'Deleted Col', you can pass that
    //     as well, but you must make sure the message you pass is also an invalid option.
    // 4-  Or one could change the invalid option to a valid option and pass that.
    var title = menuItems[selectedValue] ? menuItems[selectedValue].displayedName : ''
    if ( displayValueOverrideText !== '' ) {
      title = displayValueOverrideText
    }
    const menuKeys = Object.keys(menuItems)
    const isAnyMenuTextMultiLine = menuKeys.some( (thisKey) => {
      return (menuItems[thisKey].menuText.length > 1)
    })
    const lastMenuIndex = menuKeys.length-1

    const SVGcaretWidth = (widthDisplayCaret === 0) ? '0' : '10px'
    const borderSize = 2

    // Menu item text styles vary, depending on the text to render,
    // and the line number.  (Each menu option has at least two text lines,
    // and optionally, as many text lines as we wish to use for the explanation.)
    // The first line of the option (1st line is the 'relation') is 'bold'
    // The subsequent explanation (2nd or later lines) is 'normal'
    //
    // ALSO: we choose our own special style for relations that are math symbols.
    // Because:
    //   1) Not all math symbols support 'bold' so we must fake it with fontSize & lineHeight.
    //   2) Chrome renders math symbols differently depending on the context
    //      around the symbol.  If it looks like an expression, the symbols render larger.
    //      If it looks like text, they render smaller.  Since we render the symbol in
    //      isolation, Chrome renders the smaller version.
    // Hence our need for controlling menu styles, depending on text's value.

    const getOurMenuStyle = ( textString: string, lineNum: number, numLines: number, optionalPreGap: number | undefined = 0, isFirstMenuOption: boolean, isLastMenuOption: boolean ) => {
      if ( isAnyMenuTextMultiLine === false ) {
        // Styling for a set of single line choices
        var fontSize = 15
        var fontWeight = 'normal'
        var marginLeft = 6
        var lineHeight: number | string = 1
        var paddingTop = 0 + optionalPreGap
        var paddingBottom = 0
        var paddingLeft = 0
        // Extra spacing for 1st and last Menu options:
        if (isFirstMenuOption) {paddingTop = 6}
        if (isLastMenuOption ) {paddingBottom = 4}
        height = constants.SELECT_MENU_SINGLE_LINE_HEIGHT + paddingTop + paddingBottom
        return { height, fontSize, fontWeight, marginLeft, lineHeight, paddingTop, paddingBottom}
      }

      // Else, this is a multiline (per some or all menu options) style
      // First line is bold, larger text.
      // Following lines or normal smaller text.
      // Last line (within each multiline option) is followed by a divider.

      fontSize   = (lineNum===0) ? fontSizeMenuLine0: fontSizeMenuLine1
      fontWeight = (lineNum===0 && isAnyMenuTextMultiLine) ? 'bold' : 'normal'
      marginLeft = 6
      lineHeight = 'unset'
      if ( numLines === 1 ) {
        // height spacings for a one-line, bold text menu item
        paddingTop = 4
        paddingBottom = 4

      } else {
        paddingTop = 0
        paddingBottom = 0
        if ( lineNum === 0 ) {paddingBottom = 4; paddingTop = 4}  // Gap before/after 1st bold line
        if ( lineNum === numLines-1 ) {paddingBottom = 4}  // Gap after last normal line
      }
      height = 16  + paddingTop + paddingBottom
      paddingTop += optionalPreGap
      paddingLeft = (lineNum===0) ? 0 : 0   // Currently not used, instead text with: indent = '\u00A0\u00A0'

      switch ( textString ) {
        case '≡' : fontSize+=21; fontWeight='normal'; lineHeight=0.4; marginLeft=+3; paddingTop=0; break
        case '≢' : fontSize+=21; fontWeight='normal'; lineHeight=0.4; marginLeft=+3; paddingTop=0;break
        case '∋' :
        case '∈' : fontSize+=11; fontWeight='bold';   lineHeight=0.6; marginLeft=-1; paddingTop=0;break
        case '≈' : fontSize+=9;  fontWeight='bold';   lineHeight=0.8; marginLeft= 0; paddingTop=0;break
        case '<' :
        case '>' :
        case '=' :
        case '≠' :
        case '≤' :
        case '≥' : fontSize+=6; fontWeight='bold'; paddingTop=0; break
        default  :
      }
      return { height, fontSize, fontWeight, marginLeft, paddingTop, paddingBottom, lineHeight, paddingLeft}
    }

    // Above is the style seen in the popup menu options
    // Below is the style used for the display (relation current selection box)
    const getOurDisplayStyle = ( textString: string ): DisplayStyle => {
      const borderSize = 2
      let fontSize  = fontSizeSelectButton
      let fontWeight= 'unset'
      let lineHeight: number | string = 'unset'
      let top = 0
      let paddingTop = 0
      // Exceptions to get math symbols to render properly
      // Determine emperically
      switch ( textString ) {
        case '≡' : fontSize += 19; fontWeight='normal'; lineHeight=0.45; top= 0; break
        case '≢' : fontSize += 19; fontWeight='normal'; lineHeight=0.45; top= 0; break
        case '∋' :
        case '∈' : fontSize += 11; fontWeight='bold';   lineHeight=0.6; top= -4; break
        case '≈' : fontSize += 9;  fontWeight='bold';   lineHeight=0.7 ; top= -4; break
        case '<' :
        case '>' :
        case '=' :
        case '≠' :
        case '≤' :
        case '≥' : fontSize += 4;  fontWeight='bold'; top= -3; break
        // For standard text:
        // -1 was set emperically
        default  : paddingTop = (height - borderSize*2 - fontSizeSelectButton)/2 - 1

      }
      return { top, fontSize, fontWeight, lineHeight, paddingTop}
    }

    const buttonStyle = ( shouldStyleAsButton )
      ? constants.STYLE_EDITOR_RAISED_BUTTON
      : { border: borderSize,
          borderStyle:'inset',
          borderColor: constants.COLHEADER_INPUT_BORDER_COLOR,
          borderRadius: 4, }


    return (
      <Wrapper className="EditorMenuButton"
        onSelection={onSelection}
        onClick={onClick}
        style={{
          height, width: widthDisplayText + widthDisplayCaret,
          display: 'inline-block',
        }}>

          <Button  className="EditorMenuButton-trigger"
            style = {{
              height: '100%', width: '100%',
              display: 'inline-block',
              position:'relative',
              background: 'white',
              //border: borderSize, borderStyle:'inset', borderColor, borderRadius: 4,
              cursor: 'pointer',
              userSelect: 'none',
              color: displayValueColor,
              ...buttonStyle,
          }} >

                {/* Need to shift this text vertically because the text fontSize
                  may be much larger than what is expected by the button height.
                  This is because the filter 'relation' menu button requires
                  an oversized font (as compared to the rowHeight) in order
                  to be acceptably visible.  This is easily determined by
                  just asking if the fontSize for SelectButton is oversize
                  compared to the fontSize for typical menu text. */}
                <div className={'textOffsetDiv'}
                  style={{
                    position: 'absolute', left:0,
                    height: '100%', width:widthDisplayText-2*borderSize,
                    textAlign,
                    overflow: 'hidden',
                    ...getOurDisplayStyle(title),
                    //background: 'pink',
                  }}
                >
                  {title}
                </div>
                <div style={{
                    position: 'absolute', top:0, left:widthDisplayText-2*borderSize,
                    height: '100%', width:widthDisplayCaret,
                    paddingLeft: 5,
                  }}>
                  <SVGselectCaret
                      height={'100%'}
                      width={SVGcaretWidth}
                      color='black' />
                </div>

          </Button>
          <Menu>
            <ul className="EditorMenuButton-menu"
              style={{
                ...constants.CUSTOM_SELECT_POPUP_MENU_OUTLINE,
                position: 'absolute',
                top: menuTop,
                right: menuRight,
                bottom: menuBottom,
                left: menuLeft,
                paddingLeft: 0,
                width: menuWidth,
              }}>

              {
                 menuKeys.map((thisKey, i) => {
                  if ( thisKey === '' ) { return null }
                  var isFirstMenuOption = (i===0)
                  var isLastMenuOption  = (i=== menuKeys.length-1)
                  let itemClass = 'EditorMenuButton-menuItem';
                  if (selectedValue === thisKey) {
                    itemClass += ' is-selected';
                  }

                  return (

                      <MenuItem className={itemClass}
                        key={i}
                        value={thisKey}
                        style={{
                          cursor: 'pointer',
                          width: '100',
                          //marginTop    : (i===0) ? 5 : 0,              // Some space above 1st menu choice
                          //marginBottom : (i===lastMenuIndex) ? 3 : 0,  // Some space after last menu choice
                          //overflow: 'hidden',
                          paddingLeft: isAnyMenuTextMultiLine ? '3px 8px' : '1px 8px',
                          borderBottom: (i===lastMenuIndex || !isAnyMenuTextMultiLine) ? '0px' : '2px solid #666',
                        }}
                      >
                          { menuItems[thisKey].menuText.map( (textString,lineNum) => {
                            let optionalPreGap = ( menuItems[thisKey].optionalPreGap )
                                ? menuItems[thisKey].optionalPreGap
                                : 0
                            return  <div
                                      style={{
                                        textAlign: 'left',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        ...getOurMenuStyle( textString, lineNum, menuItems[thisKey].menuText.length,
                                                            optionalPreGap, isFirstMenuOption, isLastMenuOption )
                                      }}
                                      key={lineNum}
                                    >
                                        {textString} <br/>
                                    </div>
                          })}
                      </MenuItem>

                  )
                })
              }
            </ul>
          </Menu>
      </Wrapper>
    )
  }
}

export default EditorMenuButton
