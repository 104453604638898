//import type { RootState }           from '../redux/store'  // my testPalette given access to sessionState
import type { ActiveFp } from '../types'
import type {FpLayout, FpChildRenderProps} from '../appCode/getSbFpConfigMappings'

//import {useSelector}                from 'react-redux'
import {useContext}                 from 'react'
import {TableComputedDataContext}   from '../viewTableTriple/TtGetComputedData'
import {activeFpToTableViewTile}    from '../viewTable/getTableTile'

const WIDTH = 250
const HEIGHT = 150

export const useGetTablePublisherTitle : (arg0:ActiveFp)=>FpLayout = (activeFp)=>{ 
    const tCD = useContext( TableComputedDataContext )
    const width = WIDTH
    const height = HEIGHT
    // Only a single tab, so it will not display tabs.
    const tab0 = {  
        tabIndex: 0,      // We set activeTab value, even when tabs are not displayed.                              
        tabStrg1: 'none', // Only a single tab, so this is not displayed.
        tabStrg2 : 'none',  
        isErroneous: false,
    }
    const child0 = {
        childWidth: width,  
        childHeight: height,  
        RenderedChild: TableFp_PublisherTitle
    }
    // Single tab, Not dependent on canEdit or tabIndex
    return { 
        activeFp,     // To use this test palette, assign this function to the  'tableMainTitle' key in getFpLayout_ByFpName
        titleBarStrg: 'Publisher Overview', 
        isDraggable: true, 
        isResizableX: false,
        isResizableY: false,
        minResizeX: 100,
        minResizeY: 100,
        maxResizeX: 100,
        maxResizeY: 100,
        tabInfoArr: [tab0], 
        childInfoArr: [child0], 
        getSelectedCellGeometry: ( ) => { return activeFpToTableViewTile(tCD) } 
    }
}


const TableFp_PublisherTitle : React.FC<FpChildRenderProps> = () => {
    const fontSize = '16px'
    return (
      <div className={'rc_TableFp_MainTitle'}
        style={{fontSize: fontSize, width:WIDTH, height:HEIGHT}}>
          {'Publisher Info Placeholder'}
      </div>
    )
}

