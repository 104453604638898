import type { ChangeEvent }         from 'react'
import type { FpLayout, 
    FpChildRenderProps }            from '../appCode/getSbFpConfigMappings'
import type { ActiveFp }                                from '../types'

import {useContext, useState}       from 'react'
import {TableComputedDataContext}   from '../viewTableTriple/TtGetComputedData'
import {activeFpToTableViewTile}    from '../viewTable/getTableTile'  
import reactDispatch                from '../sharedComponents/reactDispatch'
import {cleanScryInputText2}        from '../sharedFunctions/cleanScryInputText'

const DEFAULT_WIDTH = 200
const DEFAULT_HEIGHT = 40
const MAX_WIDTH = 700
const MAX_HEIGHT = 500

type ChildState = { value: string, childWidth: number, childHeight: number }
type SetChildState= ( _:ChildState)=>void

// Every named FP must provide this function.  It is called by the FpParent component.
// Specifically, the parent needs this set of information BEFORE it creates 
// the FpParent, FpContainer, and internal content. 
export const useGetTableCellString : (activeFp: ActiveFp) => FpLayout = (activeFp) => {
    const tCD = useContext( TableComputedDataContext )
    return { 
        activeFp,     
        titleBarStrg: tCD.canEdit? 'Cell String Editor' : 'Cell String Viewer', 
        isDraggable: true, 
        isResizableX: true,
        isResizableY: false,
        minResizeX: DEFAULT_WIDTH,
        minResizeY: DEFAULT_HEIGHT,
        maxResizeX: MAX_WIDTH,
        maxResizeY: MAX_HEIGHT,
        tabInfoArr : [{  
            tabIndex: 0,                           
            tabStrg1: 'placeholder', 
            tabStrg2 : 'placeholder', 
            isErroneous: false,
        }],
        childInfoArr : [{  
            childWidth: DEFAULT_WIDTH,  
            childHeight: DEFAULT_HEIGHT,  
            RenderedChild: TableFp_CellString, // This needs some generic type definition!!!
        }],
        getSelectedCellGeometry: ( ) => { return activeFpToTableViewTile(tCD) }
    }
}


const TIMEOUT_DELAY = 500
let timeoutID: NodeJS.Timeout | string | number | undefined = undefined

const timeoutUpdateResource = ( newValue: string, colKey:number, rowKey:number, tabledataid: string ) => {
    const mods = [{
        newVal: newValue,
        path: `attributes.tableValues[${colKey}][${rowKey}]`
    }]
    reactDispatch(mods, 'cell value change', '', 'tabledatas', tabledataid)
}


const onEditInput = (   e: ChangeEvent<HTMLTextAreaElement>, colKey:number, rowKey:number, tabledataid: string,
           childState:ChildState,  setChildState: SetChildState) : void => {
        /* Responsible for:
        1 - scrubbing the input text ( clean; truncate length; clean again)
        2 - Probable state changes for both workingEditValue and workingTableDataValue; May be different values!
        3 - Schedules a reactDispatch to update tableData's resource. */
        const {newValue:newValue0, newSelectionStop: newSelectionStop0} = cleanScryInputText2( e.target.value, e.target.selectionEnd ?? 0 )
        ////const editorValue0 = newValue0.slice(0,SCRY_NUMBER_INPUT_MAX_CHARACTERS+2 )
        const {newValue, newSelectionStop} = cleanScryInputText2( newValue0, newSelectionStop0  )
        if ( e.target.value.length !== newValue.length ) {
            e.target.value = newValue.slice()  // This line also resets selection start to useless value.
            e.target.selectionStart = newSelectionStop
            e.target.selectionEnd   = newSelectionStop
        }
        if ( childState.value !== newValue ) {
            setChildState( {...childState, value:newValue} )
        }
        clearTimeout(timeoutID)
        timeoutID = setTimeout( ()=> timeoutUpdateResource(newValue, colKey, rowKey, tabledataid), TIMEOUT_DELAY)
}


const TableFp_CellString : React.FC<FpChildRenderProps> = (props)=> {
    const tCD = useContext( TableComputedDataContext )
    const {activeFp} = props
    const {primaryKey: colKey, secondaryKey: rowKey } = activeFp
    const {canEdit, getTableValue, tabledataid} = tCD
    const hideErroneousValues = false // No such thing as erroneous values for strings.
    const {value:initialInternalValue} = getTableValue(colKey, rowKey, hideErroneousValues)
    const [childState, setChildState] = useState( { value: initialInternalValue,
                                                    childWidth: DEFAULT_WIDTH, 
                                                    childHeight: DEFAULT_HEIGHT})
    //console.log('Rendering TableFp_CellString', colKey, rowKey, childWidth, childHeight)
    return (
        <div className={'rc_EditCellString'} 
            style={{ 
                position: 'absolute', left: 0, top: 0, // relative to parent
                width: '100%', height: '100%',
                //backgroundColor: 'blue' 
            }} >
                <textarea 
                    onChange={ e => onEditInput(e, colKey, rowKey, tabledataid, childState, setChildState) }
                    style={{
                        resize: 'none',
                        overflowY: 'scroll',
                        width: '100%',
                        height:'100%',
                        fontFamily: 'serif',
                        fontSize: '18px',
                        paddingLeft:10, paddingRight: 20,  // Leave room for scroll bar on right
                        paddingTop: 10, paddingBottom: 10,
                        borderWidth: 0,
                        //backgroundColor: 'green',
                    }}
                    value={value}
                    autoComplete='off'
                    spellCheck='false'
                    readOnly={!canEdit} />

        </div>
    )
}
