import {TableComputedDataContext}   from '../viewTableTriple/TtGetComputedData'
import { useContext }               from 'react'
import reactDispatch          from '../sharedComponents/reactDispatch'
import StyleLinearSlider      from '../sharedComponents/StyleLinearSlider'
import StyleTitle             from '../sharedComponents/StyleTitle'
import StyleSelectBox         from '../sharedComponents/StyleSelectBox'
import {defaultStyleObj}      from '../computedDataTable/layoutCalculator'

const SLIDER_INDENT = 60
const SLIDER_PRESPACER = 10
const SELECT_INDENT = 70
const SELECT_PRESPACER = 4

const setBrightField = () => { reactDispatch( [{ newVal: true,  path: 'attributes.isBrightField' }] , 'Set Bright Field') }
const setDarkField   = () => { reactDispatch( [{ newVal: false, path: 'attributes.isBrightField' }] , 'Set Dark Field') }


export const TableSb_TabShading : React.FC = (  ) => {
    const tableComputedData = useContext( TableComputedDataContext )
    const tablelook = tableComputedData.tablelook
    const {isBrightField, greyHeadCells, greyDataCells, greyAlternateRow,
      greyGridHorz, greyGridVert} = tablelook.attributes
    const {foregroundColor} = tableComputedData.styleObj
    return (

          <div className={'rc_Table_TabShading'}
            style={{
              position:'relative',
              height: '100%',
              width: '100%',
              boxSizing: 'border-box',
              color: foregroundColor,
              display: 'flex', flexFlow: 'column nowrap', //justifyContent:'flexStart',
            }}>

                <StyleTitle  text0={'Table Shading'} text1={undefined}/>

                <StyleSelectBox
                  indent={SELECT_INDENT}
                  id={'brightField'}
                  preSpacer={SELECT_PRESPACER}
                  onClick={ setBrightField }
                  isSelected={ isBrightField? true : false }
                  text={'Bright Field'}
                  foregroundColor={foregroundColor}
                  isVisible={false} />

                <StyleSelectBox
                  indent={SELECT_INDENT}
                  id={'darkField'}
                  preSpacer={SELECT_PRESPACER}
                  onClick={ setDarkField }
                  isSelected={isBrightField? false : true }
                  text={'Dark Field'}
                  foregroundColor={foregroundColor}
                  isVisible={false} />

                <StyleLinearSlider
                  indent={SLIDER_INDENT}
                  preSpacer={SLIDER_PRESPACER + 10}
                  styleName={'Header Shading:'}
                  modPath={'attributes.greyHeadCells'}
                  currentValue={greyHeadCells}
                  valMin={defaultStyleObj.greyHeadCells_min}
                  valMax={defaultStyleObj.greyHeadCells_max}
                  numSteps={defaultStyleObj.greyHeadCells_numSteps}
                  displayFixed={defaultStyleObj.greyHeadCells_fixedPrec}
                  layoutLines={2}
                />

                <StyleLinearSlider
                  indent={SLIDER_INDENT}
                  preSpacer={SLIDER_PRESPACER}
                  styleName={'Data Shading:'}
                  modPath={'attributes.greyDataCells'}
                  currentValue={greyDataCells}
                  valMin={defaultStyleObj.greyDataCells_min}
                  valMax={defaultStyleObj.greyDataCells_max}
                  numSteps={defaultStyleObj.greyDataCells_numSteps}
                  displayFixed={defaultStyleObj.greyDataCells_fixedPrec}
                  layoutLines={2}
                />

                <StyleLinearSlider
                  indent={SLIDER_INDENT}
                  preSpacer={SLIDER_PRESPACER}
                  styleName={'Alternate Rows:'}
                  modPath={'attributes.greyAlternateRow'}
                  currentValue={greyAlternateRow}
                  valMin={defaultStyleObj.greyAlternateRow_min}
                  valMax={defaultStyleObj.greyAlternateRow_max}
                  numSteps={defaultStyleObj.greyAlternateRow_numSteps}
                  displayFixed={defaultStyleObj.greyAlternateRow_fixedPrec}
                  layoutLines={2}
                />

                <StyleLinearSlider
                  indent={SLIDER_INDENT}
                  preSpacer={SLIDER_PRESPACER}
                  styleName={'Horizontal Grid:'}
                  modPath={'attributes.greyGridHorz'}
                  currentValue={greyGridHorz}
                  valMin={defaultStyleObj.greyGridHorz_min}
                  valMax={defaultStyleObj.greyGridHorz_max}
                  numSteps={defaultStyleObj.greyGridHorz_numSteps}
                  displayFixed={defaultStyleObj.greyGridHorz_fixedPrec}
                  layoutLines={2}
                />

                <StyleLinearSlider
                  indent={SLIDER_INDENT}
                  preSpacer={SLIDER_PRESPACER}
                  styleName={'Vertical Grid:'}
                  modPath={'attributes.greyGridVert'}
                  currentValue={greyGridVert}
                  valMin={defaultStyleObj.greyGridVert_min}
                  valMax={defaultStyleObj.greyGridVert_max}
                  numSteps={defaultStyleObj.greyGridVert_numSteps}
                  displayFixed={defaultStyleObj.greyGridVert_fixedPrec}
                  layoutLines={2}
                />

          </div>
    )
}
