/*
const erf1 = (x:number) : number => {
  const t = 1 / (1 + .3275911*x )
  return 1 - ((
     +.254829592 * t
     -.284496736 * t*t
    +1.421413741 * Math.pow(t,3)
    -1.453152027 * Math.pow(t,4)
    +1.061405429 * Math.pow(t,5)
    ) * Math.exp( -(x*x) ))
}
*/

var M_2_SQRTPI = 1.12837916709551257

var ERFC_COF = [
  -2.8e-17, 1.21e-16, -9.4e-17, -1.523e-15, 7.106e-15,
   3.81e-16, -1.12708e-13, 3.13092e-13, 8.94487e-13,
  -6.886027e-12, 2.394038e-12, 9.6467911e-11,
  -2.27365122e-10, -9.91364156e-10, 5.059343495e-9,
   6.529054439e-9, -8.5238095915e-8, 1.5626441722e-8,
   1.303655835580e-6, -1.624290004647e-6,
  -2.0278578112534e-5, 4.2523324806907e-5,
   3.66839497852761e-4, -9.46595344482036e-4,
  -9.561514786808631e-3, 1.9476473204185836e-2,
   6.4196979235649026e-1, -1.3026537197817094
]
var ERFC_COF_LAST = ERFC_COF[ERFC_COF.length - 1]


const erfc = (x: number): number => {
  const erfccheb = (y: number): number => {
    var d = 0.0, dd = 0.0, temp = 0.0,
        t = 2.0 / (2.0 + y), ty = 4.0 * t - 2.0
    for (var i = 0, l = ERFC_COF.length - 1; i < l; i++) {
      temp = d
      d = ty * d - dd + ERFC_COF[i]
      dd = temp
    }
    return t * Math.exp(-y * y + 0.5 * (ERFC_COF_LAST + ty * d) - dd)
  }
  return x >= 0.0 ? erfccheb(x) : 2.0 - erfccheb(-x)
}


const invErfc = (p: number): number => {
  if (p <= 0.0) {
    return Infinity
  }
  else if (p >= 2.0) {
    return -Infinity
  }
  else {
    var pp = p < 1.0 ? p : 2.0 - p
    var t = Math.sqrt(-2.0 * Math.log(pp / 2.0))
    var x = -0.70711 * ((2.30753 + t * 0.27061) / (1.0 + t * (0.99229 + t * 0.04481)) - t)

    var err1 = erfc(x) - pp
    x += err1 / (M_2_SQRTPI * Math.exp(-x * x) - x * err1)
    var err2 = erfc(x) - pp
    x += err2 / (M_2_SQRTPI * Math.exp(-x * x) - x * err2)
    return p < 1.0 ? x : -x
  }
}

const invErf2 = (p: number): number => {
  return -invErfc(p + 1)
}

/*
// invErf1 slightly more accurate than invErf2
// And ~4x faster.
const invErf1 = (x:number) : number => {
  const t = -2*Math.log( 1-Math.abs(x) )
  return Math.sign(x) * Math.sqrt(
                                    (t - Math.log(
                                                  1+t+(
                                                        (.01167845*t + .1066561)*t*t / (( .02118035*t + .3710243)*t + 1 )
                                                      )
                                                 )
                                    ) / 2
                                  )
}


//  Which two functions pair up best (least errors when roundtripped?)
// functions to test: erf1, invErf1,  erfc, invErf2
// Worse case error from this experiment to the right
for (let i=0; i<=5.0001; i+=.2 ) {
  console.log( i.toFixed(2),
    'erf1:invErf1', (i - invErf1(erf1(i))),     // 4e-5 for stdev < 2  6e-4 for 2-5
    'erf1:invErf2', (i - invErf2(erf1(i))),     // 2e-5           < 2  6e-4
    'erfc:invErf1', (i - invErf1(1-erfc(i))),   // 3e-5                3e-5
    'erfc:invErf2', (i - invErf2(1-erfc(i)))    // 5e-15               1e-6
    )
}


// Any significant computational cost or difference:
var t0 = new Date()
for (let j=0; j<10000; j++) {
  for (let i=0; i<=5.0001; i+=.2 ) {
      invErf1(erf1(i))     // 4e-5 for stdev < 2  6e-4 for 2-5
  }
}
var t1 = new Date()
console.log( 'invErf1(erf1(i))', t1-t0 )    // time 21 msec

t0 = new Date()
for (let j=0; j<10000; j++) {
  for (let i=0; i<=5.0001; i+=.2 ) {
      invErf2(erf1(i))     // 2e-5           < 2  6e-4
  }
}
t1 = new Date()
console.log( 'invErf2(erf1(i))', t1-t0 )    // time 164 msec

t0 = new Date()
for (let j=0; j<10000; j++) {
  for (let i=0; i<=5.0001; i+=.2 ) {
      invErf1(1-erfc(i))   // 3e-5                3e-5
  }
}
t1 = new Date()
console.log( 'invErf1(1-erfc(i))', t1-t0 ) // time 36 msec

t0 = new Date()
for (let j=0; j<10000; j++) {
  for (let i=0; i<=5.0001; i+=.2 ) {
      invErf2(1-erfc(i))    // 5e-15               1e-6
  }
}
t1 = new Date()
console.log( 'invErf2(1-erfc(i))', t1-t0 )   // time 147 msec  (to plot 300 pts in NormProbPlot ~ 2msec)


///////////////////////////////////////////////////////////////////////////////////
//  CONCLUSION:  go with erfc & invErf2
//  Best round trip accuracy by far; slowest time, but time is still negligible.
///////////////////////////////////////////////////////////////////////////////////




// erf1 version
export const percentileFromStdDev = (stDevs:number):number => {
  //var a = 0.5 + mathfn.erf(stDevs*.7071067811865475)/ 2
  var b = stDevs >= 0
      ? 0.5 + erf1( stDevs*.7071067811865475)  / 2
      : 0.5 - erf1(-stDevs*.7071067811865475)  / 2
  return b
}
*/

// Given a standard deviation, what is the cumulative Percentile?
export const percentileFromStdDev = (stDevs: number): number => {
  var a = stDevs >= 0
      ? 1 - erfc( stDevs*.7071067811865475)  / 2
      :   + erfc(-stDevs*.7071067811865475)  / 2
  return 100 * a   // return a percent from 0 to 100.
}

// Given a cumulative Percentile, what is the number of std deviations?
export const stdDevFromPercentile = (cumPercent: number): number => {
  const value = cumPercent / 100  // The value in our plot pt varies from 0 to 100%
                                       // Erf functions expect values from 0 to 1 exclusive of values zero and 1
  return invErf2( value*2 - 1 ) * 1.4142135623730951
}


// A gaussian shape that does not extend to infinity.  Extends +/- 2 stdDevs.
export const truncGaussian = (delStdDev: number): number => {
  // Start with the standard Gaussian function (x-coord in stdDeviations)
  // Return amplitude at 'x'.
  // Our version is truncated at +/- 2 (std deviations.)
  // So we can reduce the range of our convolution from +/- infinity to +/-2 stdDevs
  // And we shift the function vertically downward by .0539909665132, such that our point of truncation
  // if shifted 'down' to sit on the x-axis. Hence, truncGaus(-2) === truncGaus(+2) === 0
  // The area under the std Gaussian function is 1.00000
  // Area under the trucated version is:
  // 1.00
  // - 2( .0227500628882 )    for the area in the two tails we truncated
  // // - 4( .0539909665132 )    for the vertical shift.
  // = 0.7385360081708
  // So we scale up (after shift) the value by 1/0.7385360081708 = 1.3540301203143663
  // Hence our truncatedGaus now has an area = 1.00000
  // truncGaus = 1.3540301203143663 * Math.max( 0, stdGaussian(x) - .0539909665132 )
  const stdGaus =  0.3989422804014 * Math.exp( -0.5*delStdDev*delStdDev)
  const verticallyShiftedGaus =  Math.max( 0, stdGaus - .0539909665132 )
  const ourGaus = 1.3540301203143663 * verticallyShiftedGaus
  return ourGaus
}
//Code to calculate above coefficients:
//console.log( '1/sqrt(2pi)', 1 / ( Math.sqrt( 2 * 3.14159265358979)) )
//console.log( 'tail size', percentileFromStdDev(-2) )
//console.log( 'GausVal', truncGaussian(-2), truncGaussian(-2) - .0539909665132 )
//console.log( 'newArea', 1/( 1 - 2*.0227500628882 - 4*.0539909665132) )


// Support func to getNormallyDistributedRandomNumber
export const getNormallyDistributedRandomNumber =
        (mean: number, stddev: number) : {val0: number, val1: number} => {
  const pair = boxMullerTransform()
  return {
    val0: pair.val0 * stddev + mean,
    val1: pair.val1 * stddev + mean
  }
}

const boxMullerTransform = () : {val0: number, val1: number} => {
  const u1 = Math.sqrt( -2 * Math.log( Math.random() ) )
  const u2 = 2 * Math.PI*( Math.random() )
  const z1 = u1 * Math.cos(u2)
  const z2 = u1 * Math.sin(u2)
  return { val0:z1, val1:z2 }
}


// Support func to getPoissonDistributedRandomNumber
//      https://hpaulkeeler.com/simulating-poisson-random-variables-direct-method/
export const getPoissonDistributedRandomNumber = (lambda: number): number => {
   // For large lambda (>30) there are faster algorithms.
   // Don't need speed for my current purposes, so I'll
   // just use the following algorithm, regardless of size of lambda.
   return method_poissionStochasticProccess(lambda)
}

const method_poissionStochasticProccess = (lambda: number): number => {
  // Computation time of this function is proportional to lambda.
  var sum   =  0
  var count = -1
  var invLambda = 1/lambda
  while (sum < 1 ) {
    // Term on right side is always negative because log( interval 0 to 1)
    // is negative.  Hence, sum is an always increasing term.
    sum -= invLambda * Math.log( Math.random() )
    count++
  }
  return count
}
