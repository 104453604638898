import type { ReactElement } from 'react'
import type { FpChildRenderProps, FpLayout } from '../appCode/getSbFpConfigMappings'
import type { ParentType } from '../jsonapi/types'
import type { RootState } from '../redux/store'
import type { UndoPayload } from '../redux/undoReducer'
import { defaultUndoLog } from '../redux/undoReducer'
import { useSelector } from 'react-redux'

const ROW_HEIGHT = 25

const MenuBarFp_UndoHistory: React.FC<FpChildRenderProps> = () => {

  const parentType: ParentType = 'tables' // to do: figure out this for plots
  const parentId = useSelector( (state: RootState) => state.session.activeTableid ) // to do: figure out this for plots
  const undoLog = useSelector( (state: RootState) => state.undo.parents[parentType][parentId] )

  const renderUndoAction = (action: UndoPayload, reverseIndex: number, array: Array<UndoPayload>): ReactElement => {
    const {undoPointerIndex, redoLimitIndex} = undoLog || defaultUndoLog()
    // since the array is reversed, we have to reverse the indexes.
    const lastIndex = array.length - 2 // minus 2 because of the extra action for inital load
    const reverseUndoPointerIndex = lastIndex - undoPointerIndex
    const reverseRedoLimitIndex = lastIndex - redoLimitIndex
    const {time, title, trigger} = action
    const secondsAgo = (Date.now() - time) / 1000
    return (
      <div
        key={reverseIndex}
        style={{
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
        }}
      >
        <div style={{flexShrink: 0, width: '25px', textAlign: 'right', color: 'green'}}>
          {reverseIndex === reverseUndoPointerIndex &&
            <svg
              data-rh='undo pointer'
              height='12px'
              version='1.1'
              viewBox='0 0 512 512'
              width='12px'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M 0,112 512,256 0,396 112,256 Z'
                fill='green'
              />
            </svg>
          }
          {reverseIndex === reverseRedoLimitIndex &&
            <svg
              data-rh='redo limit'
              height='12px'
              version='1.1'
              viewBox='0 0 512 512'
              width='12px'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M 0,64 H 512 V 0 H 0 Z'
                fill='black'
              />
            </svg>
          }
        </div>
        <div style={{flexShrink: 0, width: '35px'}}>{trigger}</div>
        <div style={{flexGrow: 1, width: '200px'}}>{title}</div>
        <div style={{flexShrink: 0, textAlign: 'right', width: '60px'}}>{isNaN(secondsAgo) ? '' : `${Math.floor(secondsAgo/60)}m ${Math.round(secondsAgo%60)}s`}</div>
      </div>
    )
  }
    
  if (undoLog) {
    const {actions} = undoLog
    const reverseActions = [...actions].reverse()
    // push an item for the initial state
    reverseActions.push({
      mods: [],
      time: NaN,
      title: 'initial load',
      trigger: 'do',
      parentId,
      parentType,
      actionGroup: '',
      minorState: {},
    })
    return (
      <div
        style={{
          backgroundColor: 'LightGray',
          color: 'black',
          fontSize: 'small',
          fontWeight: 'normal',
          height: '100%',
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            height: `${ROW_HEIGHT}px`,
            padding: '5px',
          }}
        >
          <div style={{flexShrink: 0,}}>Type</div>
          <div style={{flexGrow: 1, textAlign: 'center'}}>Action</div>
          <div style={{flexShrink: 0, textAlign: 'right'}}>Age</div>
        </div>
        <div
          style={{
            backgroundColor: 'white',
            height: `calc(100% - ${ROW_HEIGHT}px)`,
            overflow: 'auto',
            padding: '5px',
          }}
        >
          <div>
            {reverseActions.map(renderUndoAction)}
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export const useGetUndoHistory = (): FpLayout => {
  const parentType: ParentType = 'tables' // to do: figure out this for plots
  const parentId = useSelector( (state: RootState) => state.session.activeTableid ) // to do: figure out this for plots
  const undoLog = useSelector( (state: RootState) => state.undo.parents[parentType][parentId] )
  const {actions} = undoLog

  return { 
    activeFp: {
      fpName : 'menuBarUndoHistory',
      primaryKey: 0,
      secondaryKey: 0,
      tertiaryKey: 'empty',
      left: 0,
      top: 0
    },
    titleBarStrg: 'Undo History',
    isDraggable: true,
    isResizableX: false,
    isResizableY: false,
    minResizeX: 100,
    minResizeY: 100,
    maxResizeX: 100,
    maxResizeY: 100,
    tabInfoArr: [{
        tabIndex: 0,
        tabStrg1: '',
        tabStrg2: '',
        isErroneous: false,
      }],
    childInfoArr: [{
      childWidth: 340,
      childHeight: (Math.max(actions.length, 1) * ROW_HEIGHT),
      RenderedChild: MenuBarFp_UndoHistory
    }],
    getSelectedCellGeometry: () => ({left: 0, top: 0, width: 0, height: 0, isCellVisible: false}), // No cyan highlight
  }
}
