import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { get } from 'radash'
import { ErrorTypes } from '../../shared/ErrorTypes'
import { ensureList, ensureResource } from '../jsonapi'
import type { ResourceType } from '../jsonapi/types'
import { notifyError, notifyJsonApiError } from './notifyReducer'
import { GetStateFunc} from '../types'
import { asyncDispatch, getErrorMessage } from '../sharedFunctions/utils'

const errorHandler = (err: unknown, dispatch: Dispatch<AnyAction>): void => {
  const errors = get(err, 'response.data.errors', null)
  const authToken = localStorage.getItem('token') || ''
  //Do not create a gui error if the user is not logged in
  if (!authToken) {
   return
  } else if (errors) {
    dispatch(notifyJsonApiError({errors, type: ErrorTypes.WARNING}))
  } else {
    dispatch(notifyError({errorType: ErrorTypes.WARNING, message: getErrorMessage(err)}))
  }
}

export const notifyEnsureList = (type: ResourceType, listId: string, offset: number = 0, limit: number = 1000, force: boolean = false) => {
  return async (dispatch: Dispatch<AnyAction>, getState: GetStateFunc): Promise<void> => {
    try {
      await asyncDispatch(dispatch, ensureList(type, listId, offset, limit, force))
    } catch (err) {
      errorHandler(err, dispatch)
    }
  }
}

export const notifyEnsureResource = (type: ResourceType, id: string, include?: string, force: boolean = false) => {
  return async (dispatch: Dispatch<AnyAction>, getState: GetStateFunc): Promise<void> => {
    try {
      await asyncDispatch(dispatch, ensureResource(type, id, include, force))
    } catch (err) {
      errorHandler(err, dispatch)
    }
  }
}

const ensure = {
  list: notifyEnsureList,
  resource: notifyEnsureResource
}

export default ensure
