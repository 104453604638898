import { PureComponent } from 'react'
import type { JSX } from 'react'

type Props = {
  children: JSX.Element | JSX.Element[] | string
  htmlFor: string
}
class FormLabel extends PureComponent<Props> {
  render () {
    const {children, htmlFor} = this.props
    return (
      <label
        className={'rc_FormLabel'}
        style={{
          display: 'block',
          fontWeight: 'bold',
        }}
        htmlFor={htmlFor}
      >
        {children}
      </label>
    )
  }
}

export default FormLabel
