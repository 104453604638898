export class TimerHelper {
  constructor(callback: () => void, delay: number) {
    this.callback = callback
    this.remaining = delay
    this.resume()
  }

  callback: () => void
  remaining: number
  timerId: string | number | NodeJS.Timeout | undefined
  start: number = 0

  pause = (): void => {
    clearTimeout(this.timerId)
    this.remaining -= (new Date()).getTime() - this.start
  }

  resume = (): void => {
    this.start = (new Date()).getTime()
    clearTimeout(this.timerId)
    this.timerId = setTimeout(this.callback, this.remaining)
  }

  clear = (): void => {
    clearTimeout(this.timerId)
  }
}

const Helpers = {
  Timer: TimerHelper
}
  
export default Helpers
