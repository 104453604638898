import { ReactElement } from 'react'

export type Position = 'tl' | 'tr' | 'tc' | 'bl' | 'br' | 'bc'

export type Level = 'success' | 'error' | 'warning' | 'info'

export type Dismissible = 'both' | 'button' | 'click' | 'none'

export type Positions = {
  [key in Position]: Position
}

export type Levels = {
  [key in Level]: Level
}

export type NotificationAction = {
  callback?: () => void
  label?: string
}

export type Notification = {
  title: string | null
  message: string | null
  level?: Level
  position: Position
  autoDismiss: number // Seconds;  Zero seconds means user MUST click to dismiss.
  dismissible?: Dismissible | boolean
  action?: NotificationAction
  uid?: number
  cursor?: string // accepts CSS cusor values such as auto, default, none, pointer
  onAdd?: (notification: Notification) => void
  onRemove?: (notification: Notification) => void
  className?: string
  children?: ReactElement
  ref?: string
}

export type Constants = {
  positions: Positions
  levels: Levels
  notification: Notification
}

const CONSTANTS: Constants = {

  // Positions
  positions: {
    tl: 'tl',
    tr: 'tr',
    tc: 'tc',
    bl: 'bl',
    br: 'br',
    bc: 'bc'
  },

  // Levels
  levels: {
    success: 'success',
    error: 'error',
    warning: 'warning',
    info: 'info'
  },

  // Notification defaults
  notification: {
    title: null,
    message: null,
    position: 'tr',
    autoDismiss: 5,
    dismissible: 'both'
  }
}

export default CONSTANTS
