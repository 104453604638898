import type {TableComputedData} from '../computedDataTable/getDefaultTableComputedData'

import {TableComputedDataContext}   from '../viewTableTriple/TtGetComputedData'
import { useContext }               from 'react'
import reactDispatch          from '../sharedComponents/reactDispatch'
import StyleTitle             from '../sharedComponents/StyleTitle'
import StyleSelectBox         from '../sharedComponents/StyleSelectBox'

const SELECT_INDENT = 10
const SELECT_PRESPACER = 0

const setIsSortableTrue = ( tCD: TableComputedData) => {
    const table = tCD.table
    if (table) {
      reactDispatch( [{newVal: true,  path: 'attributes.isSortable'}], 'Set isSortableTrue',  '', 'tables', table.id)
    }
}

const setIsSortableFalse = (tCD: TableComputedData) => {
    const table = tCD.table
    if (table) {
      reactDispatch( [{newVal: false, path: 'attributes.isSortable'}], 'Set isSortableFalse', '', 'tables', table.id)
    }
}

export const TableSb_TabPublish : React.FC = (  ) => {
    const tableComputedData = useContext( TableComputedDataContext )
    const {table, styleObj} = tableComputedData
    const {isSortable} = table.attributes
    const {foregroundColor} = styleObj
    return (

          <div className={'rc_Table_TabPublish'}
            style={{
              position:'relative',
              height: '100%',
              width: '100%',
              color: foregroundColor,
              display: 'flex', flexFlow: 'column nowrap', //justifyContent:'flexStart',
            }}>

                <StyleTitle  text0={'Publish - This tab is only'} text1={'visible to the table owner.'}/>

                <StyleSelectBox 
                  indent={SELECT_INDENT}
                  id={'viewMode'}
                  preSpacer={14}
                  onClick={ ()=> setIsSortableTrue( tableComputedData ) }
                  isSelected={isSortable ? true : false }
                  text={'Show sort controls.'}
                  foregroundColor={foregroundColor} />

                <StyleSelectBox 
                  indent={SELECT_INDENT}
                  id={'viewMode'}
                  preSpacer={SELECT_PRESPACER}
                  onClick={ ()=> setIsSortableFalse( tableComputedData ) }
                  isSelected={isSortable ? false : true }
                  text={'Hide sort; Use input CSV order.'}
                  foregroundColor={foregroundColor} />

              <div style={{marginTop:50, marginLeft:10, marginRight:5, fontSize: 16}}>
                {`Future:`}
              </div>  
              <div style={{marginTop:16, marginLeft:10, marginRight:5, fontSize: 14}}>
                {`- Options for setting which 'rights' are to be granted to viewers.`}
              </div>
              <div style={{marginTop:16, marginLeft:10, marginRight:5, fontSize: 14}}>
                {`- Summary of table errors.  Tables are usable, even with errors.` +
                `  However, all errors/omissions must be resolved prior to publishing tables.  `
                + `For example: units, descriptions (metadata), legal formatting, etc.`}
              </div>
              <div style={{marginTop:16, marginLeft:10, marginRight:5, fontSize: 14}}>
                {`- Which data to publish?  Not all columns need to be published. ` +
                `A table may have columns not yet ready for publication, ` +
                `or never intended for the publication.`}
              </div>   
              <div style={{marginTop:16, marginLeft:10, marginRight:5, fontSize: 14}}>
                {`- ??`}
              </div>   

          </div>
    )

}
