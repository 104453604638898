import type { DraggableData, 
              DraggableEvent }          from 'react-draggable'
import type { TableComputedData, DomNode }  from '../computedDataTable/getDefaultTableComputedData'
import type { TableWidthObj, 
              TableStyleObj }           from '../computedDataTable/layoutCalculator'

import {MIN_COL_WIDTH_AT_NOMINAL_SCALE} from '../sharedComponents/constants'

import {widthCalculator,
        enableForcedCombinedTable,
        disableForcedCombinedTable}     from '../computedDataTable/layoutCalculator'
import dynamics                         from 'dynamics.js'
import reactDispatch, {sessionStateChangeDispatch} from '../sharedComponents/reactDispatch'
import {wasSuspendPointerEventsSuccessful,
        restorePointerEvents}           from '../sharedFunctions/pointerEvents'
import {initTableResponsiveState,
        restoreInitialTableResponsiveState,
        updateTableBlocks, colResize, 
        //updateFloatingPaletteHighlight
        }                               from './tableResponsiveState'

const DEBUG = false

type ResizeColAnimationObj = {
  sLeft: number
  eEdge: number
}

const lastVal = (a:Array<any>):any => a[ a.length-1 ]

// How many px !inside! left edge of table before autoscroll function kicks in?
// Autoscrolling for right edge begins right at the table's right edge.
const AUTOSCROLLING_LEFT_BEGINS_AT_PX  = MIN_COL_WIDTH_AT_NOMINAL_SCALE + 10  // Anything smaller creates a corner case not currently handled.
const AUTOSCROLLING_RANGE = 35  // Starting at above position, increase velocity geometrically over this number of pixels
const MIN_VELOCITY     =  20/60 // Pixels per frame at AUTOSCROLLING_BEGINS_AT_PX
const MAX_VELOCITY     = 200/60 // Pixels per frame at AUTOSCROLLING_BEGINS_AT_PX + AUTOSCROLLING_RANGE
const geometricScalePerPx   =  Math.exp (Math.log( MAX_VELOCITY / MIN_VELOCITY ) /  AUTOSCROLLING_RANGE )

const MY_CURSOR_SIZE = 21
// Screen coord where this icon sits when not in use.
const MY_CURSOR_LEFT = -40  // Screen coord where this icon sits when not in use.
const MY_CURSOR_TOP  = 100

//Internal column resize State
var isDragActive: boolean = false  // Set true onDragStart;  Set false onDragStop
var cursorNode: DomNode = null
var colKey = 0       // Internal column ID.  NOT the visual column location.
var colIndex = 0
var startScrollLeft = 0 // initial scrollLeft
var startCenterLine= 0  // initial exact X of the column edge (very center of the drag target)
var startColWidth = 0
var startTouchLeft = 0

var cursorPosition = 0
var centerLine = 0     // motion constrained version of cursorPosition

// startDragX is initial cursor position on mouseDown;
// ActionScroll coord system; Reference from left edge of viewWidthPx.
var startDragX = 0
var currentScrollLeftFloat = 0
var velocityPerFrame = 0
var isMovingCol = false
var movingLeft2 = 0
var maxMovingRight = 0
var autoScrollLeftPosition = 0
var maxCenterLinePositionSoFar = 0
var contentWidthRightOfCenterLineOnDragStart = 0
var unusableRightWidth = 0
const px = (x : number) : string => `${x}px`
var localDragCaptureArray :Array<DomNode> = []
var w : TableWidthObj
var s : TableStyleObj
var tableComputedData : TableComputedData

// This is the width as rendered, in px.   Different from the saved tableLook width due to a global size multiplier.
//
// THE TRICK TO THE NEXT FUNCTION EXPRESSION IS:
// This is NOT the width we would 'measure' from dragging the right edge.
// It is the width we 'want'.  We are controlling both the right edge AND the left edge!!!
// For example - Column starts at width=100;  I drag the right edge 10-to-right.
// Next function calculates the a width of 115;
// THEREFORE: The desired and rendered image will be:  rightColEdge= 10-to-right; leftColEdge= 5-to-left
// This next calculation is controlling both the right and left edges!!!!

// For example, when the table has room to grow in width, if I 'grow' right edge by 10 px,
// the left edge will also 'grow' by 10px.  Keeping the table centered!  In the domain where
// the table width is < available room, then 1px of cursor movement = 2px of width change!
// Another example-- during autoscrolling, the right edge is fixed.  And the left edge is
// shifting!   Next calculation is the width resulting from:
//      dragging right edge (user input) -- the deltaRightEdgeDrag term.
//      potentially shifting left edge to keep table centered.  -- the deltaTouchLeft()  term
//      potentially shifting left edge due to auto scrolling.   -- the deltaScrollLeft() term

const getColWidthFromMousePosition = ( centerLine: number ) : number => {
  const minScaledColumnWidth = MIN_COL_WIDTH_AT_NOMINAL_SCALE * tableComputedData.tablelook.attributes.globalScale
  const deltaRightEdgeDrag = centerLine - startCenterLine
  let colWidth = Math.max( Math.round( startColWidth + deltaRightEdgeDrag - deltaScrollLeft() - deltaTouchLeft() ), minScaledColumnWidth ) 
  if (DEBUG) {
    console.log( '    deltaRightEdgeDrag', deltaRightEdgeDrag )
    console.log( '    deltaScrollLeft   ', deltaScrollLeft() )
    console.log( '    deltaTouchLeft    ', deltaTouchLeft() )
    console.log( '    deltaRightEdgeDrag', deltaRightEdgeDrag )
    console.log( '    startColWidth     ', startColWidth, ' => endColWidth:' , colWidth )
  }
  return colWidth
}

export const handleResizeColStart = ( e: DraggableEvent, data: DraggableData, indexIn: number,
            colKeyIn: number, xCenterLineIn: number,  cursorNodeIn: DomNode,
            dragCaptureArray: DomNode[], 
            inputTableComputedData: TableComputedData) : void => {
       
    e.preventDefault( )
    tableComputedData = inputTableComputedData
    w = tableComputedData.widthObj
    s = tableComputedData.styleObj

    // xCenterLineIn is with respect to the left edge of table viewWidthPx.
    isDragActive = true
    currentScrollLeftFloat = startScrollLeft = tableComputedData.scrollLeft
    startTouchLeft = w.touchLeft
    initTableResponsiveState( tableComputedData )
    enableForcedCombinedTable( w.isCombinedTable )
    movingLeft2 = w.movingLeft
    // maxMovingRight is the location where autoscrolling is triggered.
    // It is the right edge of the view, minus the scroll bar space.
    // IF the table is full width (horz scroll bar), this position corresponds
    // to the rightmost column's right edge.
    maxMovingRight = w.viewWidthPx - w.minLeftRightMargin - s.gapTableScroll - s.scrollThumbWidth - w.borderThickness
    cursorNode = cursorNodeIn
    colIndex  = indexIn //  i=0 is always the first column on the far left
    colKey = colKeyIn
    isMovingCol = ( w.isCombinedTable || colIndex >= w.numLockedCols )
    startColWidth = w.displayedColWidths[colIndex]
    contentWidthRightOfCenterLineOnDragStart =
            lastVal(w.startColLeft) // Last value in this array = startColLeft[last colIndex] + displayedColWidths[last colIndex]
                                    // Hence this array.length === numCols+1
            // This term may be the last col or any other column.                         
          - (w.startColLeft[colIndex] + w.displayedColWidths[colIndex])
    startDragX = data.x
    cursorPosition = centerLine = startCenterLine = xCenterLineIn
    maxCenterLinePositionSoFar = 0
    unusableRightWidth = 0
    /* Make the custom cursor visible and properly positioned.
      The large, bold, prebuilt dragCol cursor Icon belongs to the top level
      Action Scroll Container.  The data.y (and data.x) are coordinates within
      the drag capture div.  Which scrolls in both x and y.  Hence cursor coord =
      function of : where we click, the scroll position, and which dragDiv (data or state) was clicked.
    */
    var cursorIcon_Y = data.y - MY_CURSOR_SIZE - tableComputedData.scrollTop
    if (cursorNode) {
      cursorNode.style.left = px(xCenterLineIn - (MY_CURSOR_SIZE-1)/2 - 2)
      cursorNode.style.top  = px(cursorIcon_Y)
      cursorNode.style.visibility  = 'unset'
    }
    // As cursor passes over the other col Drag targets (they are stationary, in
    // spite of how underlying display looks) we DO NOT want the cursor arrow to
    // change to the colResize cursor
    localDragCaptureArray = dragCaptureArray
    localDragCaptureArray.forEach( (el) => {
      if ( el ) { el.style.cursor = 'default'}
    })
    colResizeStateMachine( )
}

export const handleResizeColDrag = ( e: DraggableEvent, data:DraggableData ) : void => {
    // StateMachine executes every frame.
    // This func executes sporatically, on mouse movement.
    // Mouse movement is tracked by setting the global param cursorPosition.
    e.preventDefault( )
    e.stopPropagation( )
    const deltaMouse  = data.x - startDragX
    cursorPosition = startCenterLine + deltaMouse
}

const colResizeStateMachine = () : void => {
    if ( isDragActive === false ) {
      // EXIT PATH !!   EXIT PATH !!   EXIT PATH !!
      return
    }
    // centerLine tracks the mouse position, with exceptions of constraints!  Easily
    // visible as the greenLine is locked in place and no-longer tracks the mouse.

    // CASE:  Locked columns
    // Simple case where we only need to concern ourselves with min/max resize extents.
    // No auto-scrolling, hence no underlying shift in the x-coord system.
    if ( !isMovingCol ) {
      centerLine = cursorPosition
      centerLine = Math.max ( centerLine, centerAtMinWidth( ))
      centerLine = Math.min ( centerLine, maxMovingRight - 5 )
      let newScaledWidth = getColWidthFromMousePosition( centerLine )
      if (DEBUG) {
        console.log( 'cursorPosition & centerline, newScaledWidth: LockedColDrag', cursorPosition, centerLine, newScaledWidth)
      }
      redrawLayout ( centerLine, newScaledWidth, currentScrollLeftFloat, unusableRightWidth )
      window.requestAnimationFrame( colResizeStateMachine )
      return
    }

    // Moving col that may also be autoscrolling.
    maxCenterLinePositionSoFar = Math.max( maxCenterLinePositionSoFar, centerLine )
    autoScrollLeftPosition = Math.min( maxCenterLinePositionSoFar, movingLeft2 +  AUTOSCROLLING_LEFT_BEGINS_AT_PX)
    // Constraints on centerLine position
    centerLine = cursorPosition
    centerLine = Math.max ( centerLine, autoScrollLeftPosition )  // resize Icon and edge can't go left of minLeftAutoScroll location
    centerLine = Math.min ( centerLine, maxMovingRight )         // resize Icon and edge can't go right of the largest allowed right table Edge
    centerLine = Math.max (centerLine, centerAtMinWidth( ) )      // resize Icon and edge can't go left such that we go narrower than minWidth
    // velocityPerFrame is a value used inside auto-scrolling function.
    // This velocity depends on how far (left or right) the cursor position exceeds the autoScrolling edge.
    var gasPeddleValue = 0
    if (cursorPosition > maxMovingRight ) {
      gasPeddleValue = Math.min( cursorPosition - maxMovingRight, AUTOSCROLLING_RANGE )
    }
    if (cursorPosition < autoScrollLeftPosition ) {
      gasPeddleValue = Math.min( autoScrollLeftPosition - cursorPosition, AUTOSCROLLING_RANGE )
    }
    velocityPerFrame = MIN_VELOCITY * Math.pow(geometricScalePerPx, gasPeddleValue)
    // Do we need to pull the right edge to the left? (In cases where there is no room to drag a rightmost column edges leftward.)
    // var 'unusableRightWidth' is nominally zero, but positive value reflects the
    // distance the right table edge was dragged in the left direction.
    // This term called 'unusableRightWidth' because the implementation is to 'lie'
    // to the layout calculator.   Once unusableRigthWidth has some non-zero value,
    // we can't ignore this if the scrollBar disappears.   Once this term is set,
    // only way to clear it back to zero is for the expression that sets it to
    // return a zero.  (Once term is set it stays set until colResize_stop or the
    // user resizes the column such that expression goes to zero.
    if ( currentScrollLeftFloat > 0 || unusableRightWidth > 0 )  {   // ScrollBar present
      unusableRightWidth  = Math.max( 0, maxMovingRight - centerLine - contentWidthRightOfCenterLineOnDragStart )
    } else {  // Case of no scroll bar.  Table centered.  Dragging left works without using above trick.
      unusableRightWidth = 0
    }
    let newScaledWidth = getColWidthFromMousePosition( centerLine )
    if (DEBUG) {
      console.log( 'cursorPosition & centerline, newScaledWidth: LockedColDrag', cursorPosition, centerLine, newScaledWidth)
    }
    redrawLayout ( centerLine, newScaledWidth, currentScrollLeftFloat, unusableRightWidth )
    // CASE:  Autoscroll Left
    if ( cursorPosition < autoScrollLeftPosition && currentScrollLeftFloat > 0 && isDragActive ) {
      currentScrollLeftFloat -= velocityPerFrame
      currentScrollLeftFloat = Math.max( currentScrollLeftFloat, 0 )
    }
    // CASE:  Autoscroll Right
    if ( cursorPosition > maxMovingRight && isDragActive ) {
      currentScrollLeftFloat += velocityPerFrame
    }
    window.requestAnimationFrame( colResizeStateMachine )
}

export const handleResizeColStop  = ( data: DraggableData ) => {
    isDragActive = false
    // Clean up: Hide resize cursor; turn back on the 'col-resize' when over edges feedback.
    if (cursorNode) {
      cursorNode.style.left = px(MY_CURSOR_LEFT)
      cursorNode.style.top  = px(MY_CURSOR_TOP)
      cursorNode.style.visibility  = 'hidden'
    }
    localDragCaptureArray.forEach( (el) => {
      if (el) { el.style.cursor = 'col-resize'}
    })
    // var 'unusableRightWidth' is potentially the amount we have 'illegally'
    // dragged the right edge of table to the left.  We may need to recover
    // all or none of this amount (relaxation phase back to original right extent.)
    // Available amount we can recover can never exceed the current scrollLeft.
    const newScaledWidth  = getColWidthFromMousePosition( centerLine )
    const newScrollLeft = Math.round(Math.max( currentScrollLeftFloat - unusableRightWidth, 0 ))
    if ( unusableRightWidth > 0 ) {  // Relaxation to full extent prior to final dispatch
        if (!wasSuspendPointerEventsSuccessful ( 'Table right Edge relaxation after colResize' )) {
          return
        }
        dynamics.animate (
          // First two args are Start && Stop values
          {sLeft: currentScrollLeftFloat, eEdge:unusableRightWidth},
          {sLeft: newScrollLeft,          eEdge:0},
          { // Options:
            change: (obj: ResizeColAnimationObj)=> {
              redrawLayout ( centerLine, newScaledWidth, obj.sLeft, obj.eEdge )
            },
            complete: ( )=> {
              restorePointerEvents('Table right Edge relaxation after colResize')
              restoreInitialTableResponsiveState()
              dispatchColResize(newScaledWidth, newScrollLeft)
            },
            delay: 200,
            duration: 700,  //700 nominal
            friction: 100,
          },
        )
    } else {
      disableForcedCombinedTable()
      restoreInitialTableResponsiveState()
      dispatchColResize( newScaledWidth, newScrollLeft )
    }
}

const dispatchColResize = ( newScaledWidth:number, newScrollLeft: number): void => {
    // tablelook width resource does NOT include the global.scale param
    let newTablelookWidth = Math.round( 10 * newScaledWidth / tableComputedData.layoutProps.globalScale) / 10   // one decimal of accuracy
    let mods = [{ newVal: newTablelookWidth, path: `attributes.lookColumns[${colKey}].width` }]
    reactDispatch( mods, 'column resize' )
    mods = [{ newVal: newScrollLeft, path: `activeTableScrollLeft` }]
    sessionStateChangeDispatch( mods, 'column resize' )
}

const deltaScrollLeft = () :number => { return startScrollLeft - currentScrollLeftFloat }
const deltaTouchLeft  = () :number => { return w.touchLeft - startTouchLeft}

// What is the centerLine location at MIN_COL_WIDTH_AT_NOMINAL_SCALE ?
// We will never drag 'left' of this value.
// The pointer will drag to the left
// But the resize cursor will lock in place at MIN_COLUMN_WIDTH.
const centerAtMinWidth = ( ) : number => {
  return startCenterLine - startColWidth + deltaTouchLeft() + deltaScrollLeft() + MIN_COL_WIDTH_AT_NOMINAL_SCALE
}

const redrawLayout = ( centerLine: number, draggedWidth:number, scrollLeft:number, unusableRightWidth:number ) : void => {
    var newColWidthArr = tableComputedData.layoutProps.colWidth_ByColIndex.slice()
    newColWidthArr[colIndex] = draggedWidth
    var perturbedProps = {...tableComputedData.layoutProps, colWidth_ByColIndex: newColWidthArr }
    w = widthCalculator( perturbedProps, unusableRightWidth )
    translateResizeIcon( centerLine )
    colResize( colIndex, w )
    updateTableBlocks( w, scrollLeft ) 
    //updateFloatingPaletteHighlight( w )
    return
}

const translateResizeIcon = ( newCenterLine:number ) : void => {
    if (cursorNode) {
      // Not sure why centering requires an additional term of -2. Observed & set emperically.
      cursorNode.style.left = px( newCenterLine - (MY_CURSOR_SIZE-1)/2 - 2 )
    }
}
