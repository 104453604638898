import type { InternalColDataType, PlotColDataType, StringOrderDirection, StringOrderTypes } from '../types'
import type { BasisName, SparsePlotPt, StringAxis_TextToIndexObj, StringEnumerationObject } from './xy_plotTypes'

import { list } from 'radash'
import invariant from 'invariant'
import { parseRenderedLayerID } from './plotUtils'

/*
   Empty String rule:
        For a string column, we consider '' a valid string name.  But this makes for
        a poor (invisible) tick label!
        IF the string axis value is '', THEN it will be enumerated as '(no text)'.
        Hence it will appear as '(no text)' in the textToIndexObj array.
        And will appear as '(no text)' for a rendered plot's tick label.
*/

export type StringIndexParams = {
  seriesOrder: number[]
  plotColDataType: PlotColDataType
  basisName: BasisName
  opposingBasisName: BasisName
  opposingBasisDataType: InternalColDataType
  stringOrderDirection: StringOrderDirection
  stringOrder: StringOrderTypes
  isHistoOrPercentile: boolean
  renderedLayersArr: string[]
  isStacked: boolean
}

export type StringIndexRefs = SparsePlotPt[][]

export type StringIndexOtherArgs = {
}

export type StringIndexResult = {
  textToIndexObj: StringAxis_TextToIndexObj
  sortedKeys: string[]
}


export const createTextToIndexObj = (paramsObj: StringIndexParams, refsObj: StringIndexRefs, otherArgs: StringIndexOtherArgs): StringIndexResult => {

  const { seriesOrder, plotColDataType, basisName, opposingBasisName,
    opposingBasisDataType, stringOrderDirection } = paramsObj
  const numSeries = seriesOrder.length
  const stringName = `${basisName}string` as keyof SparsePlotPt  // values of 'Astring', 'Bstring', or 'Cstring'
  const isScatterData = (plotColDataType === '2Col')
  const isOpposingBasisDataRequired = (isScatterData && opposingBasisDataType === 'number')

  // CODE TO GENERATE INITIAL OR NEW STRING MAPS:
  const textToIndexObj: StringAxis_TextToIndexObj = {}
  // If the string name is on one basis, what is the plotted value on th opposing basis ?
  const getPlotPtValue = (plotColDataType === '2Col' && opposingBasisName === 'A')
    ? (plotPt: SparsePlotPt) => plotPt.A
    : (plotPt: SparsePlotPt) => plotPt.B

  // 1st pass through filtered Pts:  Build textToIndexObj mapping.
  // This pass DOES the enumerating, and statistics gathering.
  // Order of series processing is not important here.
  for (let sKey = 0; sKey < numSeries; sKey++) {
    const seriesRowPlotPts = refsObj[sKey]
    for (const thisPt of seriesRowPlotPts) {
      let thisEnumStrg = (thisPt[stringName] === '') ? '(no text)' : thisPt[stringName] as string
      if (!textToIndexObj[thisEnumStrg]) {
        textToIndexObj[thisEnumStrg] = createNewStringEnumerationObject(thisEnumStrg, numSeries)
      }
      let thisEnumObj = textToIndexObj[thisEnumStrg]
      if (isOpposingBasisDataRequired) {
        let value = getPlotPtValue(thisPt)
        thisEnumObj.sum[sKey] += value
        thisEnumObj.max[sKey] = Math.max(thisEnumObj.max[sKey], value)
        thisEnumObj.min[sKey] = Math.min(thisEnumObj.min[sKey], value)
      }
      thisEnumObj.rowKey[sKey].push(thisPt.rowKey)
      thisEnumObj.freq[sKey]++
    }
  }
  const strgKeys = Object.keys(textToIndexObj)

  // 2nd pass through textToIndexObj mapping.
  // Calculate all the potential values that could be used for string sorting criteria.
  // Do this now, NOT in the sort, else the logic is repeated N(logN) times.
  strgKeys.forEach(strgKey => {
    // This function also NOT dependent on seriesOrder
    for (let sKey = 0; sKey < numSeries; sKey++) {
      const thisEnumObj = textToIndexObj[strgKey]
      const mean = []
      let { freq, sum, min, max } = thisEnumObj
      // We can only accumlate sorting criteria information IF the sKey actually includes row points!
      if (freq[sKey] > 0) {
        thisEnumObj.mean[sKey] = mean[sKey] = sum[sKey] / freq[sKey]

        // These values represent the worse case (largest or smallest) values over all series
        thisEnumObj.allSeriesSumMax = Math.max(thisEnumObj.allSeriesSumMax, sum[sKey])
        thisEnumObj.allSeriesSumMin = Math.min(thisEnumObj.allSeriesSumMin, sum[sKey])

        thisEnumObj.allSeriesFreqMax = Math.max(thisEnumObj.allSeriesFreqMax, freq[sKey])
        thisEnumObj.allSeriesFreqMin = Math.min(thisEnumObj.allSeriesFreqMin, freq[sKey])

        thisEnumObj.allSeriesMeanMax = Math.max(thisEnumObj.allSeriesMeanMax, mean[sKey])
        thisEnumObj.allSeriesMeanMin = Math.min(thisEnumObj.allSeriesMeanMin, mean[sKey])

        thisEnumObj.allSeriesRowMax = Math.max(thisEnumObj.allSeriesRowMax, max[sKey])
        thisEnumObj.allSeriesRowMin = Math.min(thisEnumObj.allSeriesRowMin, min[sKey])

        // These values represent the stacked value over all series
        // There is no such thing as rowStacked, because it rows values can
        // consist of many points at the same A coordinate
        thisEnumObj.allSeriesSumStacked += sum[sKey]
        thisEnumObj.allSeriesFreqStacked += freq[sKey]
        thisEnumObj.allSeriesMeanStacked += mean[sKey]
      }
    }
  })


  // 3rd pass through textToIndexObj mapping.
  // Sort the strings, as per user defined ordering criteria
  // This next function DOES DEPEND on series Order.  '1st series is what the user sees!
  const sortCriteria = getStringAxisSortingCriteria(paramsObj)
  const sortedKeys = sortEnumeratedStrings(textToIndexObj, sortCriteria, stringOrderDirection, seriesOrder)
  // In the textToIndexObj, add the sorted, integerIndex.
  // Redundant info to the sortedKeys, but reminder that the first integer index
  // for the first string is '1', not zero.
  sortedKeys.forEach((thisKey, i) => { textToIndexObj[thisKey].mappedIndex = i + 1 })
  return { textToIndexObj, sortedKeys }
}



const createNewStringEnumerationObject = (thisStrg: string, numSeries: number): StringEnumerationObject => {
  let rowKey = list(numSeries - 1).map(i => { return Array<number>() })
  const temp: StringEnumerationObject = {
    mappedIndex: 0,
    mappedText: thisStrg,
    rowKey,

    // These arrays serve two purposes:
    // 1) The [0] value is the value used to sortBy '1stSeriesValue'
    // 2) After the array is filled out, we use to subsequently determine
    //    the allSeries sorting values, which or either the max/min over all series.
    freq: Array(numSeries).fill(0),
    sum: Array(numSeries).fill(0),
    mean: Array(numSeries).fill(0),
    max: Array(numSeries).fill(-Infinity),
    min: Array(numSeries).fill(+Infinity),

    allSeriesFreqMax: -Infinity,
    allSeriesMeanMax: -Infinity,
    allSeriesSumMax: -Infinity,
    allSeriesRowMax: -Infinity,

    allSeriesFreqMin: +Infinity,
    allSeriesMeanMin: +Infinity,
    allSeriesSumMin: +Infinity,
    allSeriesRowMin: +Infinity,

    allSeriesFreqStacked: 0,
    allSeriesMeanStacked: 0,
    allSeriesSumStacked: 0,
    // Row values cannot be stacked!
  }
  return Object.seal(temp)
}


export type StringSortCriteria =
  'alphabetical' |

  '1st_freq' |
  '1st_sum' |
  '1st_mean' |
  '1st_rowMin' |
  '1st_rowMax' |

  'all_freqMax' |
  'all_freqMin' |
  'all_freqStacked' |

  'all_sumMax' |
  'all_sumMin' |
  'all_sumStacked' |

  'all_meanMax' |
  'all_meanMin' |
  'all_meanStacked' |

  'all_rowMax' |
  'all_rowMin'



// These are the rules for sorting a string axis.
// The sorting rule depends on:
//          1) User's stringSortCriteria:  'alphabetical', '1stSeriesValue', 'allSeriesValues'
//          2) User's plot type:
//                   Distribution plots ('1ColPlotType') always use freq as the sort criteria
//                   Scatter plots ('2ColPlotType') sort order depends on what is rendered.
//                   Parametric plots ('3ColPlotType') string axis not allowed. This function never called.
//          3) What the user has requested to plot:  sum, mean, rows data.
const getStringAxisSortingCriteria = (paramsObj: StringIndexParams): StringSortCriteria => {

  const { stringOrder, stringOrderDirection, opposingBasisDataType,
    isHistoOrPercentile, renderedLayersArr, isStacked } = paramsObj

  let isMeanPlotted = false  // assumptions
  let isSumPlotted = false
  let areRowsPlotted = false
  for (const thisRenderedLayer of renderedLayersArr) {
    const { plottedValueRoot } = parseRenderedLayerID(thisRenderedLayer)
    if (plottedValueRoot === 'mean') { isMeanPlotted = true }
    if (plottedValueRoot === 'sum') { isSumPlotted = true }
    if (plottedValueRoot === 'tableRow') { areRowsPlotted = true }
  }

  // Note:  I will treat all 'isStacked100%' same as 'Not Stacked' & '1stSeriesValue'
  // We can consider this to be sorting by isStacked100% as primary sort (all values
  // identical) and '1stSeriesValue' as the secondary sort.  Hence no need to include
  // this case as unique.

  if (!(stringOrder === 'alphabetical' ||
    stringOrder === '1stSeriesValue' ||
    stringOrder === 'allSeriesValues')) {
    invariant(false, `Unrecognized StringOrder value of ${stringOrder} into getStringAxisSortingCriteria`)
  }

  if (stringOrder === 'alphabetical') { return 'alphabetical' }

  // This block handles all Freq (isHisto) plots.
  // And special case where both basisA/B are dataType String, in which
  // case freq is the only usuable criteria for ordering.
  if (isHistoOrPercentile || opposingBasisDataType === 'string') {
    if (stringOrder === '1stSeriesValue') { return '1st_freq' }
    // Else all cases that follow are 'allSeriesValues'
    if (isStacked) { return 'all_freqStacked' }
    // Else all cases that follow are unstacked:
    if (stringOrderDirection === 'ascending') { return 'all_freqMin' }
    // Else stringOrderDirection === descending
    return 'all_freqMax'
  }

  /*
    Now we must set an arbitrary priority based on what is being rendered.
    Worse case is a plot rendering all three of: sum, mean, and row values.

    I declare 'sum' the highest priority (if a sum value is plotted,
    then string sorting is ALWAYS based sum's value.)   Reasoning:
    The sum value will be at the top of the graph, and dwarf the
    magnitude of the mean values and/or plotted row values.  If the
    plot is designed to show the sum, then mean/row values must be
    secondary to the user, else why bury this information at the
    bottom of the plot?  Hence we will sort based on the sum's value.

    Second, I declare 'mean' to be the next highest priority.  If any
    mean value is plotted, then I assume this is probably more important
    than the scatter plot of row values surrounding the mean.  This
    assumption is best of two alternatives, but the only real argument
    is it is probably the better of two unknowable alternatives.

    This leaves plotted row points (usually has a large B-value scatter) as
    the final remaining criteria for string sorting.
  */


  // Sort by Sum:
  if (isSumPlotted) {
    if (stringOrder === '1stSeriesValue') { return '1st_sum' }
    // else stringOrder is 'allSeriesValues'
    if (isStacked) { return 'all_sumStacked' }
    // Else not stacked or stacked100%
    if (stringOrderDirection === 'ascending') { return 'all_sumMin' }
    // Else stringOrderDirection === descending
    return 'all_sumMax'
  }

  // Sort by Mean:
  if (isMeanPlotted) {
    if (stringOrder === '1stSeriesValue') { return '1st_mean' }
    // else stringOrder is 'allSeriesValues'
    if (isStacked) { return 'all_meanStacked' }
    // Else not stacked or stacked100%
    if (stringOrderDirection === 'ascending') { return 'all_meanMin' }
    // Else stringOrderDirection === descending
    return 'all_meanMax'
  }

  // Sort by row data values (a large scatter of values for any given enumerated string)

  if (areRowsPlotted) {
    if (stringOrderDirection === 'ascending' && stringOrder === '1stSeriesValue') { return '1st_rowMin' }
    if (stringOrderDirection === 'descending' && stringOrder === '1stSeriesValue') { return '1st_rowMax' }
    if (stringOrderDirection === 'ascending' && stringOrder === 'allSeriesValues') { return 'all_rowMin' }
    if (stringOrderDirection === 'descending' && stringOrder === 'allSeriesValues') { return 'all_rowMax' }
  }

  invariant(false, `Found a missed case in getStringAxisSortingCriteria.`)
  return 'alphabetical'
}




const sortEnumeratedStrings = (map: StringAxis_TextToIndexObj, sortCriteria: string,
  direction: string, seriesOrder: number[]): string[] => {
  const keys = Object.keys(map)
  const numSeries = seriesOrder.length
  let sKey
  const isNumber = (x: string): boolean => !isNaN(Number(x))
  switch (sortCriteria) {
    case 'alphabetical':
      // primary
      keys.sort((a, b) => {
        // Strings are always unique.  Never a case where a===b
        if (isNumber(a) && isNumber(b)) {
          if (Number(a) > Number(b)) { return -1 } else { return 1 }
        }
        if (isNumber(a) && isNaN(Number(b))) { return (-1) }
        if (isNaN(Number(a)) && isNumber(b)) { return (1) }
        // Else two strings.
        if (a.localeCompare(b) < 0) { return 1 }
        if (a.localeCompare(b) > 0) { return -1 }
        // secondary freqAll:  // Should never get this far? As strings are always unique
        if (map[a].allSeriesFreqMax < map[b].allSeriesFreqMax) { return 1 }
        if (map[a].allSeriesFreqMax > map[b].allSeriesFreqMax) { return -1 }
        // tertiary freq1st
        if (map[a].freq[0] < map[b].freq[0]) { return 1 }
        if (map[a].freq[0] > map[b].freq[0]) { return -1 }
        return 0
      })
      break

    // 1st_mean
    // 1st_sum
    // 1st_freq
    // 1st_min
    // 1st_max

    case '1st_mean':
      // Sort by Mean Values
      keys.sort((a, b) => {
        // Primary sort; 1st series mean; Secondary:
        for (let i = 0; i < numSeries; i++) {
          sKey = seriesOrder[i]
          if (map[a].mean[sKey] < map[b].mean[sKey]) { return 1 }
          if (map[a].mean[sKey] > map[b].mean[sKey]) { return -1 }
        }
        // aphabetical final tie breaker
        return a.localeCompare(b)
      })
      break

    case '1st_sum':
      // Sort by Mean Values
      keys.sort((a, b) => {
        // Primary sort; 1st series mean; Secondary: 2
        for (let i = 0; i < numSeries; i++) {
          sKey = seriesOrder[i]
          if (map[a].sum[sKey] < map[b].sum[sKey]) { return 1 }
          if (map[a].sum[sKey] > map[b].sum[sKey]) { return -1 }
        }
        // aphabetical final tie breaker
        return a.localeCompare(b)
      })
      break

    case '1st_rowMax':
      keys.sort((a, b) => {
        // Primary sort; 1st series max; Secondary:
        for (let i = 0; i < numSeries; i++) {
          sKey = seriesOrder[i]
          if (map[a].max[sKey] < map[b].max[sKey]) { return 1 }
          if (map[a].max[sKey] > map[b].max[sKey]) { return -1 }
        }
        // aphabetical final tie breaker
        return a.localeCompare(b)
      })
      break

    case '1st_rowMin':
      keys.sort((a, b) => {
        // Primary sort; 1st series max; Secondary: 2
        for (let i = 0; i < numSeries; i++) {
          sKey = seriesOrder[i]
          if (map[a].min[sKey] < map[b].min[sKey]) { return 1 }
          if (map[a].min[sKey] > map[b].min[sKey]) { return -1 }
        }
        // aphabetical final tie breaker
        return a.localeCompare(b)
      })
      break

    case '1st_freq':
      keys.sort((a, b) => {
        // Primary sort; 1st series max; Secondary:
        for (let i = 0; i < numSeries; i++) {
          sKey = seriesOrder[i]
          if (map[a].freq[sKey] < map[b].freq[sKey]) { return 1 }
          if (map[a].freq[sKey] > map[b].freq[sKey]) { return -1 }
        }
        // aphabetical final tie breaker
        return a.localeCompare(b)
      })
      break


    // allSeriesSumMax
    // allSeriesSumMin
    // allSeriesSumStacked

    case 'all_sumMin':
      // Sort by MaxVal in descending order
      keys.sort((a, b) => {
        if (map[a].allSeriesSumMin < map[b].allSeriesSumMin) { return 1 }
        if (map[a].allSeriesSumMin > map[b].allSeriesSumMin) { return -1 }
        // aphabetical final tie breaker
        return a.localeCompare(b)
      })
      break

    case 'all_sumMax':
      // Sort by MaxVal in descending order
      keys.sort((a, b) => {
        if (map[a].allSeriesSumMax < map[b].allSeriesSumMax) { return 1 }
        if (map[a].allSeriesSumMax > map[b].allSeriesSumMax) { return -1 }
        // aphabetical final tie breaker
        return a.localeCompare(b)
      })
      break

    case 'all_sumStacked':
      // Sort by MaxVal in descending order
      keys.sort((a, b) => {
        if (map[a].allSeriesSumStacked < map[b].allSeriesSumStacked) { return 1 }
        if (map[a].allSeriesSumStacked > map[b].allSeriesSumStacked) { return -1 }
        // aphabetical final tie breaker
        return a.localeCompare(b)
      })
      break

    // allSeriesMeanMax
    // allSeriesMeanMin
    // allSeriesMeanStacked

    case 'all_meanMin':
      // Sort by MaxVal in descending order
      keys.sort((a, b) => {
        if (map[a].allSeriesMeanMin < map[b].allSeriesMeanMin) { return 1 }
        if (map[a].allSeriesMeanMin > map[b].allSeriesMeanMin) { return -1 }
        // aphabetical final tie breaker
        return a.localeCompare(b)
      })
      break

    case 'all_meanMax':
      // Sort by MaxVal in descending order
      keys.sort((a, b) => {
        if (map[a].allSeriesMeanMax < map[b].allSeriesMeanMax) { return 1 }
        if (map[a].allSeriesMeanMax > map[b].allSeriesMeanMax) { return -1 }
        // aphabetical final tie breaker
        return a.localeCompare(b)
      })
      break

    case 'all_meanStacked':
      // Sort by MaxVal in descending order
      keys.sort((a, b) => {
        if (map[a].allSeriesMeanStacked < map[b].allSeriesMeanStacked) { return 1 }
        if (map[a].allSeriesMeanStacked > map[b].allSeriesMeanStacked) { return -1 }
        // aphabetical final tie breaker
        return a.localeCompare(b)
      })
      break

    // allSeriesFreqMax
    // allSeriesFreqMin
    // allSeriesFreqStacked

    case 'all_freqMin':
      // Sort by MaxVal in descending order
      keys.sort((a, b) => {
        if (map[a].allSeriesFreqMin < map[b].allSeriesFreqMin) { return 1 }
        if (map[a].allSeriesFreqMin > map[b].allSeriesFreqMin) { return -1 }
        // aphabetical final tie breaker
        return a.localeCompare(b)
      })
      break

    case 'all_freqMax':
      // Sort by MaxVal in descending order
      keys.sort((a, b) => {
        if (map[a].allSeriesFreqMax < map[b].allSeriesFreqMax) { return 1 }
        if (map[a].allSeriesFreqMax > map[b].allSeriesFreqMax) { return -1 }
        // aphabetical final tie breaker
        return a.localeCompare(b)
      })
      break

    case 'all_freqStacked':
      // Sort by MaxVal in descending order
      keys.sort((a, b) => {
        if (map[a].allSeriesFreqStacked < map[b].allSeriesFreqStacked) { return 1 }
        if (map[a].allSeriesFreqStacked > map[b].allSeriesFreqStacked) { return -1 }
        // aphabetical final tie breaker
        return a.localeCompare(b)
      })
      break

    //  allSeriesRowMax
    //  allSeriesRowMin

    case 'all_rowMin':
      // Sort by MaxVal in descending order
      keys.sort((a, b) => {
        if (map[a].allSeriesRowMin < map[b].allSeriesRowMin) { return 1 }
        if (map[a].allSeriesRowMin > map[b].allSeriesRowMin) { return -1 }
        // aphabetical final tie breaker
        return a.localeCompare(b)
      })
      break

    case 'all_rowMax':
      // Sort by MaxVal in descending order
      keys.sort((a, b) => {
        if (map[a].allSeriesRowMax < map[b].allSeriesRowMax) { return 1 }
        if (map[a].allSeriesRowMax > map[b].allSeriesRowMax) { return -1 }
        // aphabetical final tie breaker
        return a.localeCompare(b)
      })
      break


    default:   // Missed option!!!
      if (process.env.NODE_ENV === 'development') {
        invariant(false, `Missing Case "${sortCriteria}" in sortEnumeratedStrings`)
      }
  }

  if (direction === 'ascending') {
    keys.reverse()
  }
  return keys
}



export const isPerfectStringEnumeration = (map: StringAxis_TextToIndexObj): boolean => {
  // Return true if every frequency === 1 for every enumerated string/seriesKey combo
  // Make an exception for missing string names, where freq may be numerous.
  // Same as saying: Each series has one or no values for each enumeration.
  for (const key in map) {
    const thisEnumeration = map[key]
    if (thisEnumeration.mappedText === '(no text)') {
      // do nothing
    } else {
      const maxFreq = Math.max(...thisEnumeration.freq)
      if (maxFreq > 1) return false
    }
  }
  return true
}
