import {PureComponent} from 'react'
import type {TableDomNodes, DomNode} from '../computedDataTable/getDefaultTableComputedData'

type OwnProps = {
  titleTxt: string,
  titleTop: number,
  titleHeight: number,
  titleWidth: number,
  titleLeft: number,
  fontSize: number,
  tableDomNodes:  TableDomNodes,
}
export default class CellTitle extends PureComponent<OwnProps> {

  refMainTitle = (element: DomNode): void => {
    this.props.tableDomNodes.mainTitle = element
  }

  render ( ) {
    const {titleHeight, fontSize, titleTop, titleTxt, titleWidth, titleLeft} = this.props
    return (
      <div className={'rc_CellTitle'}
        ref={ this.refMainTitle }
        style={{
          position: 'absolute', top: titleTop,
          height: titleHeight, width: titleWidth,
          transform: `translate( ${titleLeft}px, 0px )`,
          fontSize, fontWeight: 'bold',
          paddingTop: (titleHeight-fontSize)/2,
          textAlign: 'center', whiteSpace: 'nowrap',
          //background: 'pink',
        }}>
        {titleTxt}
      </div>
    )
}}
