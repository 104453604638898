import type { GetStateFunc, Userspi } from '../types'
import type { AnyAction, Dispatch } from '@reduxjs/toolkit'
import type { JsonApiDocument, Resource } from '../jsonapi/types'
import type { RootState } from '../redux/store'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import adjectives from '../../shared/adjectives'
import animals from '../../shared/animals'
import { ErrorTypes } from '../../shared/ErrorTypes'
import titleCase from '../../shared/titleCase'
import { createResource } from '../jsonapi'
import ensure from '../redux/ensure'
import { notifyJsonApiError } from '../redux/notifyReducer'
import FormGroup from '../sharedComponents/FormGroup'
import FormLabel from '../sharedComponents/FormLabel'
import FormNote from '../sharedComponents/FormNote'
import FormSubmitButton from '../sharedComponents/FormSubmitButton'
import FormTextInput from '../sharedComponents/FormTextInput'
import InfiniteScroll from '../sharedComponents/InfiniteScroll'
import Main from '../sharedComponents/Main'
import { asyncDispatch } from '../sharedFunctions/utils'

type LocalState = {
  userspi: Userspi
  suggestedUsernames: string[]
  progress: string
  newUserID: string
}

type EnsureUserFunc = (userid: string) => void
type DispatchProps = {
  createUserspi: (userspi: Userspi, ensureUser: EnsureUserFunc) => Promise<string | undefined>
  ensureUser: EnsureUserFunc
}

type StateProps = {
  role: string
  userid: string
}
type Props = DispatchProps & StateProps

class UserCreateRender extends Component<Props, LocalState> {

  constructor(props: Props) {
    super(props)
    this.state = {
      userspi: {
        id: '',
        type: 'userspis',
        attributes: {
          email: '',
          username: '',
          password: '',
        },
      },
      suggestedUsernames: [],
      progress: 'Waiting',
      newUserID: '',
    }
  }

  componentDidMount() {
    const { ensureUser, userid } = this.props
    this.generateMoreSuggestedUsernames(0)
    ensureUser(userid)
  }

  onChange = (name: string, value: string): void => {
    // Because the inputs are named to match their corresponding values in state,
    // it's super easy to update the state
    const { userspi } = this.state
    userspi.attributes[name] = value
    this.setState({ userspi })
  }

  onSubmit = async (event: React.FormEvent) => {
    // get our form data out of state
    const { userspi } = this.state
    const { createUserspi, ensureUser } = this.props
    this.setState({ progress: 'Creating user...' })

    event.preventDefault()

    try {
      // Await the createUserspi function and then update the state
      const newUserID = await createUserspi(userspi, ensureUser)
      if (newUserID) {
        this.setState({ newUserID: newUserID })
        this.setState({ progress: `User ${newUserID} \ncreated successfully.` })
      } else {
        this.setState({ progress: 'User creation failed.' })
      }
    } catch (error) {
      console.error(error)
    }
  }

  getRandomInt = (max: number): number => {
    max = Math.floor(max)
    return Math.floor(Math.random() * max)
  }

  generateMoreSuggestedUsernames = (page: number): void => {
    const { suggestedUsernames } = this.state
    const adjLen = adjectives.length
    const animalLen = animals.length
    for (let i = 0; i < 20; i++) {
      const adjective = adjectives[this.getRandomInt(adjLen)]
      const animal = animals[this.getRandomInt(animalLen)]
      const suggestion = `${titleCase(adjective)}-${titleCase(animal)}`
      suggestedUsernames.push(suggestion)
    }
    this.setState({ suggestedUsernames })
  }

  onSuggestionClick = (e: React.MouseEvent) => {
    const { userspi } = this.state
    userspi.attributes.username = e.currentTarget.textContent || ''
    this.setState({ userspi })
  }


  render() {
    const { userspi, suggestedUsernames } = this.state
    const { role } = this.props

    return (
      role !== 'SCRY_ADMIN' ? <div>Not authorized</div> :
        <div>
          <Main className='userCreate'>
            <div style={{
              margin: '0 auto',
              paddingTop: '30px',
              width: '750px',
            }}>
              <div style={{
                margin: '0 auto 30px',
                overflow: 'hidden',
                textAlign: 'left',
              }}>
                <h1 style={{
                  fontSize: '45px',
                  fontWeight: 'normal',
                  letterSpacing: '-1px',
                  lineHeight: 1.1,
                  marginBottom: 0,
                  marginTop: 0,
                }}>Create User</h1>
                <p style={{
                  color: '#586069',
                  fontSize: '21px',
                  marginBottom: 0,
                  marginTop: '2px',
                }}>The easy way to explore data on the web.</p>
              </div>
              <div style={{
                float: 'left',
                width: '450px'
              }}>
                <form acceptCharset='UTF-8' onSubmit={this.onSubmit} style={{
                  paddingBottom: '15px',
                }}>
                  <h2 style={{
                    fontSize: '22px',
                    fontWeight: 300,
                    marginBottom: '4px',
                  }}>
                    Create your free account
                  </h2>
                  <FormGroup>
                    <FormLabel htmlFor='user_username'>Username</FormLabel>
                    <FormTextInput
                      autoComplete='username'
                      autoFocus={true}
                      id='user_username'
                      name='username'
                      value={userspi.attributes.username}
                      onChange={this.onChange}
                    />
                    <FormNote>This will be your public username. Restricted to uppercase and lowercase letters, decimal digits, dashes, and underscores.</FormNote>
                  </FormGroup>
                  <FormGroup>
                    <FormLabel htmlFor='user_email'>Email address</FormLabel>
                    <FormTextInput
                      autoComplete='email'
                      id='user_email'
                      name='email'
                      value={userspi.attributes.email}
                      onChange={this.onChange}
                    />
                    <FormNote>We’ll occasionally send updates about your account to this inbox. We’ll never share your email address with anyone.</FormNote>
                  </FormGroup>
                  <FormGroup>
                    <FormLabel htmlFor='user_password'>Password</FormLabel>
                    <FormTextInput
                      autoComplete='new-password'
                      id='user_password'
                      name='password'
                      type='password'
                      value={userspi.attributes.password || ''}
                      onChange={this.onChange}
                    />
                    <FormNote>Use at least one lowercase letter, one numeral, and seven characters.</FormNote>
                  </FormGroup>
                  <p style={{
                    borderBottom: '1px solid #eee',
                    borderTop: '1px solid #eee',
                    margin: '15px 0',
                    padding: '15px 0',
                  }}>By clicking on ‘Create free account’ below, you are agreeing to the <Link
                    to='/terms'>Terms of Service</Link> and the <Link
                      to='/privacy'>Privacy Policy</Link>.
                  </p>
                  <FormSubmitButton id='signup_button'>Create free account</FormSubmitButton>
                </form>
                <div style={{
                  color: 'dimgray',
                  fontSize: 'large',
                  textAlign: 'center',
                }}>{this.state.progress}</div>
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                float: 'right',
                height: '450px',
                overflow: 'auto',
                width: '250px',
              }}>
                <span style={{
                  color: 'dimgray',
                  flexShrink: 0,
                  fontSize: 'large',
                }}>Suggested Usernames:</span>
                <div style={{
                  flexGrow: 1,
                  overflow: 'auto',
                }}>
                  <InfiniteScroll
                    loadMore={this.generateMoreSuggestedUsernames}
                    hasMore={suggestedUsernames.length < 1000}
                  >
                    {suggestedUsernames.map((username, index) => (
                      <span
                        key={index}
                        onClick={this.onSuggestionClick}
                        style={{
                          backgroundColor: 'wheat',
                          borderRadius: '6px',
                          color: 'dimgray',
                          display: 'block',
                          margin: '6px',
                          padding: '4px 6px',
                          userSelect: 'none',
                        }}
                      >{username}</span>
                    ))}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </Main>
        </div>
    )
  }
}

const createUserspiThunk = (userspi: Userspi, ensureUser: EnsureUserFunc) => {
  return async (dispatch: Dispatch<AnyAction>, getState: GetStateFunc): Promise<string | undefined> => {
    // Return the Promise chain
    return asyncDispatch(dispatch, createResource(userspi)).then((jsonApiDocument: JsonApiDocument) => {
      const data = jsonApiDocument.data
      const newSpi: Resource | null = data ?
        Array.isArray(data) ? data[0] : data
        : null
      const userid = newSpi?.relationships?.user.data.id
      if (userid) {
        ensureUser(userid)
      }
      return userid
    }).catch((err) => {
      const errors = err?.response?.data?.errors ?? null
      if (errors) {
        dispatch(notifyJsonApiError({ errors, type: ErrorTypes.WARNING }))
      }
    })
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => (
  {
    createUserspi: (userspi: Userspi, ensureUser: EnsureUserFunc): Promise<string | undefined> => {
      return asyncDispatch(dispatch, createUserspiThunk(userspi, ensureUser))
    },
    ensureUser: (userid: string): void => {
      asyncDispatch(dispatch, ensure.resource('users', userid))
    },
  }
)

const mapStateToProps = (state: RootState): StateProps => {
  const userid = localStorage.getItem('userid') || ''
  console.log('role', state?.api?.resources?.users?.[userid]?.attributes?.role ?? '')
  return {
    userid,
    role: state?.api?.resources?.users?.[userid]?.attributes?.role ?? ''
  }
}


const UserCreate = connect(mapStateToProps, mapDispatchToProps)(UserCreateRender)
export default UserCreate
