import {PureComponent} from 'react'

type OwnProps = {
  width: number,
  height:number,
  color: string,
}
export default class SVGpointhand extends PureComponent<OwnProps> {
  render ( ) {
    return (
      <svg
      className={'rc_SVGresizeCol'}
      xmlns="http://www.w3.org/2000/svg"
      style={{isolation:'isolate', display:'block'}}
      x="0px" y="0px"
	    viewBox="0 0 32 32"
      preserveAspectRatio='none'
    >
      <path d="M11.3,20.4c-0.3-0.4-0.6-1.1-1.2-2c-0.3-0.5-1.2-1.5-1.5-1.9
          c-0.2-0.4-0.2-0.6-0.1-1c0.1-0.6,0.7-1.1,1.4-1.1c0.5,0,1,0.4,1.4,0.7c0.2,0.2,0.5,0.6,0.7,0.8c0.2,0.2,0.2,0.3,0.4,0.5
          c0.2,0.3,0.3,0.5,0.2,0.1c-0.1-0.5-0.2-1.3-0.4-2.1c-0.1-0.6-0.2-0.7-0.3-1.1c-0.1-0.5-0.2-0.8-0.3-1.3c-0.1-0.3-0.2-1.1-0.3-1.5
          c-0.1-0.5-0.1-1.4,0.3-1.8c0.3-0.3,0.9-0.4,1.3-0.2c0.5,0.3,0.8,1,0.9,1.3c0.2,0.5,0.4,1.2,0.5,2c0.2,1,0.5,2.5,0.5,2.8
          c0-0.4-0.1-1.1,0-1.5c0.1-0.3,0.3-0.7,0.7-0.8c0.3-0.1,0.6-0.1,0.9-0.1c0.3,0.1,0.6,0.3,0.8,0.5c0.4,0.6,0.4,1.9,0.4,1.8
          c0.1-0.4,0.1-1.2,0.3-1.6c0.1-0.2,0.5-0.4,0.7-0.5c0.3-0.1,0.7-0.1,1,0c0.2,0,0.6,0.3,0.7,0.5c0.2,0.3,0.3,1.3,0.4,1.7
          c0,0.1,0.1-0.4,0.3-0.7c0.4-0.6,1.8-0.8,1.9,0.6c0,0.7,0,0.6,0,1.1c0,0.5,0,0.8,0,1.2c0,0.4-0.1,1.3-0.2,1.7
          c-0.1,0.3-0.4,1-0.7,1.4c0,0-1.1,1.2-1.2,1.8c-0.1,0.6-0.1,0.6-0.1,1c0,0.4,0.1,0.9,0.1,0.9s-0.8,0.1-1.2,0c-0.4-0.1-0.9-0.8-1-1.1
          c-0.2-0.3-0.5-0.3-0.7,0c-0.2,0.4-0.7,1.1-1.1,1.1c-0.7,0.1-2.1,0-3.1,0c0,0,0.2-1-0.2-1.4c-0.3-0.3-0.8-0.8-1.1-1.1L11.3,20.4z"
            stroke={this.props.color}
            strokeWidth="1.00"
            strokeLinecap="round"
            fill={'white'} />
      <line clipPath="url(#SVGID_2_)" 
            stroke={this.props.color} 
            strokeWidth="0.75" 
            strokeLinecap="round" x1="19.6" 
            y1="20.7" 
            x2="19.6" 
            y2="17.3"/>
      <line clipPath="url(#SVGID_2_)" 
            stroke={this.props.color} 
            strokeWidth="0.75" 
            strokeLinecap="round" 
            x1="17.6" 
            y1="20.7" 
            x2="17.5" 
            y2="17.3"/>
      <line clipPath="url(#SVGID_2_)" 
            stroke={this.props.color}
            strokeWidth="0.75" 
            strokeLinecap="round" 
            x1="15.6" 
            y1="17.3" 
            x2="15.6" 
            y2="20.7"/>
    </svg>
    )
  }
}

/*
<line clipPath="url(#SVGID_2_)" 
fill={this.props.color} 
stroke={this.props.color} 
strokeWidth="0.75" 
strokeLinecap="round" x1="19.6" 
y1="20.7" 
x2="19.6" 
y2="17.3"/>

<line clipPath="url(#SVGID_2_)" 
fill={this.props.color} 
stroke="#000000" 
strokeWidth="0.75" 
strokeLinecap="round" 
x1="17.6" 
y1="20.7" 
x2="17.5" 
y2="17.3"/>

<line clipPath="url(#SVGID_2_)" 
fill={"none"} 
stroke={this.props.color}
strokeWidth="0.75" 
strokeLinecap="round" 
x1="15.6" 
y1="17.3" 
x2="15.6" 
y2="20.7"/>
*/