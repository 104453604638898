import type {SessionState}      from '../appCode/getDefaultSessionState'
import type {TableComputedData}   from '../computedDataTable/getDefaultTableComputedData'
import type {RootState}           from '../redux/store'
import type {Table, 
             Tabledata, 
             Tablelook}           from '../types'
import type {TableComputedDataParams,
      TableComputedDataRefs,
      TableComputedDataOtherArgs} from '../computedDataTable/updateTableComputedData'

import invariant from 'invariant'
import React, {createContext} from 'react'
import {getDefaultTableComputedData}  from '../computedDataTable/getDefaultTableComputedData'
import {paramsToListId }          from '../jsonapi'
import {connect}                  from 'react-redux'
import {updateTableComputedData_memoizedFunc}  from '../computedDataTable/updateTableComputedData'
import {getMemoizedResult }       from '../sharedFunctions/getObjectDiff'
import constants                  from '../sharedComponents/constants'
import {TtSwapViewAnimation}      from './TtSwapViewAnimation'
import {getMemoizedTableComputedData, 
    setMemoizedTableComputedData} from '../appCode/getMemoizedComputedData'
import type {MemoizedTableComputedData} from '../appCode/getMemoizedComputedData'


export const TableComputedDataContext = createContext( getDefaultTableComputedData(1) )


type OwnProps = {
  tableid: string,
}
type MappedProps = {
  sessionState: SessionState,
  memoizedTableComputedData: MemoizedTableComputedData[],
  refsObj: TableComputedDataRefs,
  paramsObj: TableComputedDataParams,
}
type Props = OwnProps & MappedProps


// This stopped working for statsBar async/worker updates.  it sets sessionState directly now.
// we can remove this if its not needed.  to be discussed.
// JPS : Aug, 2024
// Found bug in updateTableComputedData, resulting in memoSum incremented in an infinite loop.
// Simple bug to fix, but otherwise would have gone unnoticed.
// Added a check for infinite loop below:
const memoizedArraySum = (tableComputedData: TableComputedData ) : number => {
  if (!tableComputedData) { return 0 }
  let memoSum = 0
  for (const [key, value] of Object.entries(tableComputedData)) {
    if (key.includes('memoized') && Array.isArray(value)) {
      memoSum += value.length
    }
  }
  invariant( memoSum < 200, 'Infinite loop detected in memoizedArraySum' )
  //console.log( memoSum, 'memoSum' )
  return memoSum
} 


const TtGetComputedDataRendered: React.FC<Props> = (props) => {
  const { sessionState, memoizedTableComputedData, refsObj, paramsObj, tableid } = props;
  const priorTableComputedData = memoizedTableComputedData[0]?.result.tableComputedData;
  const otherArgsObj: TableComputedDataOtherArgs = { priorTableComputedData };
  // add the array lengths to the paramsObj
  const memoSum  = memoizedArraySum(priorTableComputedData);
  paramsObj.renderIndex = sessionState.renderIndex + memoSum;
  const maxNumMemoized = 1;
  const thisFunc = updateTableComputedData_memoizedFunc;
  const debugMemoization = false;
  const answer = getMemoizedResult(memoizedTableComputedData, paramsObj, refsObj, otherArgsObj, thisFunc, maxNumMemoized, debugMemoization);
  setMemoizedTableComputedData(tableid, answer.newMemArr);
  const tableComputedData = answer.result.tableComputedData;

  return (  
    <TableComputedDataContext.Provider value={tableComputedData}>
    <div className={'rc_TtGetComputedData'}
      style={{ 
        // Define the position and size of available rendering window
        position:'absolute',
        left: constants.NAV_COLUMN_WIDTH,
        top: constants.TOP_MENUBAR_HEIGHT,
        width: tableComputedData.tableWidth,
        height: tableComputedData.tableHeight,
      }}> 
      <div style={{ 
        // Reset the (0,0) origin as the upper-left.
        position: 'relative', top:0, left:0, width:'100%', height: '100%'}}>

          <TtSwapViewAnimation
              sourcePlotXyComputedState={'placeholder'}
              workingPlotXyComputedState={'placeholder'}/>

      </div>
    </div>
    </TableComputedDataContext.Provider>
  );
}



const mapStateToProps = (state: RootState, ownProps: OwnProps): MappedProps => {
  const {tableid} = ownProps
  const userid = state?.app.authUserId ?? ''
  const username = state?.api?.resources?.users?.[userid]?.attributes?.username ?? ''
  // I'M ASSUMING BY THIS POINT TABLE ID IS KNOWN AND ALL RESOURCES AVAILABLE.
  const table = state?.api?.resources?.tables?.[tableid] as Table ?? null
  const tabledataid  = table ? table.relationships?.tabledata.data.id : ''
  const tabledata = state?.api?.resources?.tabledatas?.[tabledataid] as Tabledata ?? null
  const userTablelookListId = paramsToListId({
    'filter[table][data][id]': tableid,
    'filter[owner][data][id]': userid,
  })
  const tablelookid = state?.api?.lists?.tablelooks?.[userTablelookListId]?.ids?.[0] ?? ''
  const tablelook = state?.api?.resources?.tablelooks?.[tablelookid] as Tablelook ?? null
  const sessionState = state.session

  // What is the available heigth/width ( is sideBar open/closed) ?
  // I will make the ASSUMPTION: sideBar open/close state is a TableTriplet parameter.
  // If I open side-bar for any one view, it stays open on all views.
  // Hence, the table/plot images being swapped are mapped to the same space.
  // Not a technical requirement, just a first guess at desired behavior.
  const {isSBopen}  = sessionState.sbStates_ByView['tableView']
  const {TOP_MENUBAR_HEIGHT, NAV_COLUMN_WIDTH, 
          SIDEBAR_TOTAL_LEFT_BORDER_WIDTH, 
          //SEARCHVIEW_RESERVED_HEIGHT,
          SIDEBAR_RANGE_OF_MOTION} = constants
  const tableWidthIncludingSideBar = window.innerWidth - NAV_COLUMN_WIDTH - SIDEBAR_TOTAL_LEFT_BORDER_WIDTH
  const tableWidth = tableWidthIncludingSideBar - (isSBopen ? SIDEBAR_RANGE_OF_MOTION : 0 )
  const tableHeight = window.innerHeight - TOP_MENUBAR_HEIGHT

  // There is ALWAYS a valid tableid (which may have 0, 1, or 2) associated plotid's.
  // We know whether it is a search table or not.  
  // We will also be rendering tables for other reasons, and these tables may have 
  // different looks and options set for rendering, for example
  //    Is the searchBar and controls present?
  //    Should the 'publisher' line be rendered?
  //    Should the table grid be rendered?  (easy way to get a CSV looking table)
  //    ???
  // We pass this information to the updateTableComputedData() function.
  const isSearchTable = (tableid === constants.SEARCH_TABLE_ID)
  let isPublisherRendered, isSearchBarRendered, isTableGridRendered
  if (isSearchTable) {
    // heigth -= SEARCHVIEW_RESERVED_HEIGHT
    isPublisherRendered = false
    isSearchBarRendered = true
    isTableGridRendered = true
  } else {
    isPublisherRendered = true
    isSearchBarRendered = false
    isTableGridRendered = true
  }

  // Create or restore tableComputedData:
  const refsObj   : TableComputedDataRefs = {
    table, 
    tabledata, 
    tablelook,
  }
  const paramsObj : TableComputedDataParams = {
    menuOption_isEditMode : sessionState.menuOption_isEditMode,
    username,
    tableHeight,
    tableWidth,
    tableWidthIncludingSideBar,
    sessionStateScrollLeft  : sessionState.activeTableScrollLeft,
    sessionStateScrollTop   : sessionState.activeTableScrollTop,
    sessionStateActiveFp    : sessionState.activeFp,
    activeStatsColKey       : sessionState.activeStatsColKey,
    isPublisherRendered,
    isSearchBarRendered,
    isTableGridRendered, 
    // Next param is always a constant, except when some debugging code in app is being used.
    renderIndex: sessionState.renderIndex
  }

  const memoizedTableComputedData = getMemoizedTableComputedData( table.id )

  //The new tableComputedData is created in the TtGetComputedDataRendered component.


  return {
    sessionState,
    memoizedTableComputedData,
    refsObj,
    paramsObj,
    //tableComputedData
  }
}




const TtGetComputedData = connect(mapStateToProps,null) (TtGetComputedDataRendered)
export default TtGetComputedData
