import { AnyAction, Middleware } from '@reduxjs/toolkit'
import invariant from 'invariant'
import { ErrorTypes } from '../../shared/ErrorTypes'
import type { MiddlewareFunc, StoreType } from './store'
import { deepClone } from '../sharedFunctions/utils'
import { notifyError, notifyInfo, notifyJsonApiError, notifyWarning, replayNote, saveNote, setNoteVisibility } from './notifyReducer'
import { actions } from './notificationsReducer'
import type { Notification } from '../viewNotifications/types'

const formatAndDisplayNotification = (store: StoreType, note: Notification): void => {
  if (note.errorType === ErrorTypes.AUTH_ERR) {
    note.title = `AUTHORIZATION ERROR`
    store.dispatch(actions.error(note))
  }
  if (note.errorType === ErrorTypes.FATAL_ERR) {
    note.title = `STRUCTURAL ERROR (${note.errorID.toLowerCase()}) --Prevents saving to DataBase`
    store.dispatch(actions.error(note))
  }
  if (note.errorType === ErrorTypes.SERVER_ERR) {
    note.title = `SERVER ERROR: ${note.title}`
    store.dispatch(actions.error(note))
  }
  if (note.errorType === ErrorTypes.WARNING) {
    if (!note.title) {
      note.title = 'WARNING'
      if (note.errorID) {
        note.title += ` (${note.errorID.toLowerCase()})`
      }
    }
    store.dispatch(actions.warning(note))
  }
  if (note.errorType === ErrorTypes.INFO) {
    note.title = ''
    store.dispatch(actions.success(note))
  }
  if (note.errorType === ErrorTypes.DATA_ERR) {
    note.title = `DATA ERROR (${note.errorID.toLowerCase()})`
    store.dispatch(actions.error(note))
  }
}

// Don't need a smart/safe ID.  Just some algorithm
// where the ID is valid for at least 10-20 sec.
const issueNotification = (store: StoreType, note: Notification, isTerse: boolean): void => {
  const lineCount = note.message?.split(/<br>|<\/TR>/).length || 1
  const state = store.getState()
  const verboseMessage = note.message // Don't lose the 'verbose' alternative.
  if (lineCount > 2 && isTerse) {
    const m = note.message?.match(/(^.*?(<br>|<\/TR>).*?)(<br>|<\/TR>)/)
    if (m && m[1]) {
      note.message = m[1]
    } else {
      invariant(false, 'Should always find at least 2 lines in this error message')
    }
  }
  // Let display time = f( lineCount )
  note.autoDismiss = isTerse ? 10 : 6.5 + 1.2 * lineCount

  // Test for a repeating note (true when this note this currently being diplayed.)
  // If true, we simply abort issuing the notification.
  for (let i = 0; i < 3; i++) {
    const priorNote = state.notify.lastThreeNotes[i]
    if (priorNote) {
      const {
        noteID: priorID,
        timeStamp: priorTS
      } = priorNote
      if (priorID && priorID === note.noteID && (note.timeStamp - priorTS) / 1000 < note.autoDismiss) {
        return // Don't issue a repeat notification !
      }
    }
  }

  formatAndDisplayNotification(store, note)
  note.message = verboseMessage // Save verbose version of message for replayed notes
  store.dispatch(saveNote(note))
}

export const issueReplayNotification = (store: StoreType, note: Notification, index: number): void => {
  const copyNote = deepClone(note)
  copyNote.message += '<br><br>Click to dismiss a replayed notification.'
  copyNote.autoDismiss = 0 // Forces user to click to dismiss
  store.dispatch(setNoteVisibility({index, visible: true}))
  copyNote.onRemove = (): void => {
    store.dispatch(setNoteVisibility({index, visible: false}))
  }
  formatAndDisplayNotification(store, copyNote)
}

const notesMiddleware: Middleware<(store: StoreType) => (next: MiddlewareFunc) => MiddlewareFunc> =
  (store: StoreType) => (next: MiddlewareFunc) => (
    (action: AnyAction): AnyAction => {
      // Default values unless over-ridden by individual actions
      // <br> in the message is a line feed.
      const note: Notification = {
        autoDismiss: 10, // Seconds;  Zero seconds means user MUST click to dismiss.
        errorID: '',
        errorType: ErrorTypes.INFO,
        linkToHelpDisplayText: '', // default is to use the URL if it is present.
        linkToHelpURL: '', // missing means no help link will be displayed.
        message: '',
        noteID: '',
        position: 'tr', // Changing this means a component design change.
        timeStamp: Date.now(),
        title: '',
      }

      const state = store.getState()
      if (replayNote.match(action)) {
        const mostRecentIndex = state.notify.mostRecentNoteIndex
        for (let i = 3; i > 0; i--) { // Search backwards. Most recent to least recent.
          const index = (mostRecentIndex + i) % 3 // value between 0 and 2;
          const isVisible = state.notify.lastThreeVisible[index]
          if (!isVisible) {
            const priorNote = state.notify.lastThreeNotes[index]
            issueReplayNotification(store, priorNote, index)
            return next(action) // early exit from for loop.
          }
        }
        return next(action)
      } else if (notifyError.match(action)) {
        const {errorType, message} = action.payload
        note.errorType = errorType
        note.message = message
      } else if (notifyInfo.match(action)) {
        const message = action.payload
        note.message = message
      } else if (notifyJsonApiError.match(action)) {
        const {errors, type} = action.payload
        for (const error of errors) {
          const noteCopy = deepClone(note)
          noteCopy.errorType = type ? type : ErrorTypes.SERVER_ERR
          noteCopy.message = error.detail
          noteCopy.title = (type === ErrorTypes.INFO) ? '' : error.title
          issueNotification(store, noteCopy, false)
        }
        return next(action)
      } else if (notifyWarning.match(action)) {
        const {message, title} = action.payload
        note.errorType = ErrorTypes.WARNING
        note.message = message
        note.title = title
      } else {
        return next(action)
      }
    // switch (action.type) {

    //   case NOTIFY_REPLAY: {
    //     const mostRecentIndex = state.notify.mostRecentNoteIndex
    //     for (let i = 3; i > 0; i--) { // Search backwards. Most recent to least recent.
    //       const index = (mostRecentIndex + i) % 3 // value between 0 and 2;
    //       const isVisible = state.notify.lastThreeVisible[index]
    //       if (!isVisible) {
    //         const priorNote = state.notify.lastThreeNotes[index]
    //         issueReplayNotification(store, priorNote, index)
    //         return next(action) // early exit from for loop.
    //       }
    //     }
    //     return next(action)
    //   }

    //   case NOTIFY_ERROR: {
    //     const {errorType, message} = action.payload
    //     note.errorType = errorType
    //     note.message = message
    //     break
    //   }

    //   case NOTIFY_INFO: {
    //     const {message} = action.payload
    //     note.message = message
    //     break
    //   }

    //   case NOTIFY_JSONAPI_ERROR: {
    //     const {errors, type} = action.payload
    //     errors.forEach((error: JsonApiError): void => {
    //       const noteCopy = Object.assign({}, note)
    //       noteCopy.errorType = type ? type : ErrorTypes.SERVER_ERR
    //       noteCopy.message = error.detail
    //       noteCopy.title = (type === ErrorTypes.INFO) ? '' : error.title
    //       issueNotification(store, noteCopy, false)
    //     })
    //     return next(action)
    //   }

    //   case NOTIFY_WARNING:  {
    //     const {message, title} = action.payload
    //     note.errorType = ErrorTypes.WARNING
    //     note.message = message
    //     note.title = title
    //     break
    //   }

    //   default:
    //     return next(action)
    // }

      // All the if statements without a return above will lead to issuing a notification.
      issueNotification(store, note, false)
      return next(action)
    }
  )

export default notesMiddleware
