import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import * as H from 'history'
import { PureComponent } from 'react'
import {connect} from 'react-redux'
import { asyncDispatch } from '../sharedFunctions/utils'
import type { Plot, Table } from '../types'
import FloatingPalette from '../floatingPalette/FloatingPalette'
import constants from '../sharedComponents/constants'
import {deletePlotThunk} from '../redux/plotThunks'
import {deleteThumbnail} from '../redux/thumbnailThunks'


const TOTAL_HEIGHT = 402;
const TOTAL_WIDTH = 452;
const NUMERAL_INDENT = 10;
const INSTRUCTION_INDENT = 16;

const INSTRUCTION_WIDTH = TOTAL_WIDTH - INSTRUCTION_INDENT - 30;
const INITIAL_PLACEMENT_TOP = window.innerHeight - TOTAL_HEIGHT - 300;
const INITIAL_PLACEMENT_LEFT = window.innerWidth - TOTAL_WIDTH - 100;

export type DeletePlotFunction = (plot: Plot) => void
export type RemoveThumbnailFunction = (plotid: string) => void

type OwnProps = {
  plot?: Plot,
  table?: Table,
  history: H.History,
  closePopup: () => void,
  setDeletePlot: (deletePlotFunc: DeletePlotFunction, removeThumbFunc: RemoveThumbnailFunction, plot: Plot, tableid: string) => void,
}

type LocalState = {
  currentLeft: number,
  currentTop: number,
}

type DispatchProps = {
  deletePlot: ( plot: Plot ) => void,
  removeThumbnail: ( plotid: string) => void,
}
type Props = OwnProps & DispatchProps

class DeletePlotXyComputedData extends PureComponent<Props, LocalState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentLeft: INITIAL_PLACEMENT_LEFT,
      currentTop: INITIAL_PLACEMENT_TOP,
    };
  }
  componentDidMount  ():void { }

  componentDidUpdate ():void { }

  onActiveDrag = (left: number, top: number): void => {
    // Constraint the position:
    top = Math.max(20, top);
    top = Math.min(window.innerHeight - TOTAL_HEIGHT - 20, top);
    left = Math.max(20, left);
    left = Math.min(window.innerWidth - TOTAL_WIDTH - 20, left);
    this.setState({ currentLeft: left, currentTop: top });
  };

  render() {
    const { closePopup, setDeletePlot, plot, table, deletePlot, removeThumbnail } = this.props;
    const { currentLeft, currentTop } = this.state;
    const year = plot?.attributes?.createdDate?.slice(0,4)
    const title= `"${plot?.attributes.mainTitle}"`
    const URL= `http://datatables/plot/${plot?.id}`
    if (!plot || !table) {
      return null
    }
    const tableid = table.id
    return (
      <FloatingPalette
        left={currentLeft}
        top={currentTop}
        height={TOTAL_HEIGHT}
        width={TOTAL_WIDTH}
        onClose={closePopup}
        onActiveDrag={this.onActiveDrag}
        title="Delete Plot:"
        isDraggable={true}
      >
        <div className={"rc_DeletePlot"} style={{ fontSize: 14 }}>
          <div className={"spacer"} style={{ height: 10 }} />
          <div
            style={{
              marginLeft: NUMERAL_INDENT,
              width: INSTRUCTION_INDENT,
              display: "inline-block",
              verticalAlign: "top",
            }}
          >
            {"1."}
          </div>

          <div style={{ width: INSTRUCTION_WIDTH, display: "inline-block" }}>
            {"Do you want to delete this plot for all users?\nThis cannot be undone."}
          </div>

          <div className={"spacer"} style={{ height: 6 }} />
          <div
            style={{
              marginLeft: NUMERAL_INDENT,
              width: INSTRUCTION_INDENT,
              display: "inline-block",
              verticalAlign: "top",
            }}
          >
            {""}
          </div>
          <div
            style={{
              width: INSTRUCTION_WIDTH,
              display: "inline-block",
              background: "rgb(255,231,240)",
            }}
          >
            {`Publisher's_Info, ${year}, ${title}, ` + URL}
          </div>
          <button className={"deleteButton"} onClick={() => setDeletePlot(deletePlot, removeThumbnail, plot, tableid)}
            style=
            {{
              fontSize: 14,
              fontWeight: "bold",
              display: "block",
              height: 30,
              width: 90,
              position: "relative",
              left: 165,
              top: 10,
              borderRadius: 6,
              borderStyle: "outset",
              borderWidth: 2,
              borderColor: constants.COLHEADER_INPUT_BORDER_COLOR,
              verticalAlign: "center",
            }}>
            {"DELETE"}
          </button>
        </div>
      </FloatingPalette>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps => (
  {
    deletePlot: (plot:Plot): void => {
      asyncDispatch(dispatch, deletePlotThunk(plot))
    },
    removeThumbnail: (plotid: string): void => {
      asyncDispatch(dispatch, deleteThumbnail(plotid))
    }
  }
)
const DeletePlot = connect(null, mapDispatchToProps)(DeletePlotXyComputedData)


export default DeletePlot;
