
import uuidv4           from 'uuid/v4'
import fileDownload     from '../sharedFunctions/fileDownload'
import slugify          from 'slugify'
import {TableComputedData, GetTableValue}  from '../computedDataTable/getDefaultTableComputedData'
import {getFormattingObj, basicNumberFormat} from '../sharedFunctions/numberFormat'
import {getNormallyDistributedRandomNumber}  from '../sharedFunctions/mathScry'
import {getPoissonDistributedRandomNumber}   from '../sharedFunctions/mathScry'

export type Delimiter = 'comma' | 'tab' | 'unitSeparator'
export type FormatChoice = 'internal' | 'formatted' | 'noPrefixSuffixCommas'

const saveTableAsCSV =  ( tableComputedData: TableComputedData,
                          downloadAllColumns: boolean,
                          downloadAllRows: boolean,
                          useDisplayedNames: boolean,
                          delimiterChoice: Delimiter,
                          formatChoice: string  ): void => {

    const {colNames, outputData} = createColNamesAndData( tableComputedData, 
                                                        downloadAllColumns, downloadAllRows,
                                                        useDisplayedNames, formatChoice )
    const tableTitle = slugify( tableComputedData.table.attributes.tableTitle ) 
    var delimiter = ','
    switch (delimiterChoice) {
      case 'comma'        : delimiter = ','     ; break;
      case 'tab'          : delimiter = String.fromCharCode(9) ; break;
      case 'unitSeparator': delimiter = String.fromCharCode(31); break;
      default             : delimiter = ','
    }
    import('papaparse').then( module => {   
      let csvString = module.unparse({fields:colNames, data:outputData}, {delimiter} )
      fileDownload(csvString, tableTitle + '.csv', 'text/csv')
    })
    //TODO: Make this default for admin users.
    //downloadResources( tableComputedData )
}



const createColNamesAndData=( tableComputedData: TableComputedData,
                  downloadAllColumns: boolean,
                  downloadAllRows: boolean,
                  useDisplayedNames: boolean,
                  formatChoice: string  )
                : {colNames: string[], outputData: string[][] } => {

    const {table, tabledata, tablelook} = tableComputedData
    // Create the rowKeys to loop over, depending on downloadAllRows flag:
    const {sortedRowKeys} = tableComputedData   // Filtered rows in sorted order
    const numRowsUnfiltered = tabledata.attributes.tableValues[0].length
    const rowKeysToOutputInOrder = ( downloadAllRows ) ?  [...Array( numRowsUnfiltered ).keys() ]  : sortedRowKeys
    // Create the colKeys to loop over, depending on downloadAllCols flag:
    const {derivedColOrder} = tableComputedData
    const tableColumns = table.attributes.columns
    const colKeysToOutputInOrder : number[] = []
    if ( downloadAllColumns ) {
      for ( let colKey=0; colKey <tableColumns.length; colKey++ ) {
        colKeysToOutputInOrder.push( colKey )  // Order 0 to n, but missing isDeleted columns
      }
    } else {
      for ( let colIndex=0; colIndex<tableColumns.length; colIndex++ ) {
        let colKey = derivedColOrder[colIndex]
        if ( tablelook.attributes.lookColumns[colKey].hidden > 0 ) {continue} // Skip hidden columns
        // Otherwise push this column onto the output colArray
        colKeysToOutputInOrder.push( colKey )   // Order of derivedColOrder
      }
    }
    //console.log( 'colArray', colArray )
    var {columns} = table.attributes
    const colNames : string[] = []   // papaParse nomenclature for the column names in first row.
    for ( let colKey of colKeysToOutputInOrder) {
      colNames.push( (useDisplayedNames) ? columns[colKey].colTitle : columns[colKey].colSourceTitle )
    }
    // Use different cell value formatting, depending on the formatChoice:
    const {getTableValue, getCellText_fromColKeyAndRowKey, 
           getCellText_noPrefixSuffixCommas} = tableComputedData
    var accessFunc : GetTableValue
    switch ( formatChoice ) {
      case 'internal'             : accessFunc = getTableValue; break;
      case 'formatted'            : accessFunc = getCellText_fromColKeyAndRowKey; break;
      case 'noPrefixSuffixCommas' : accessFunc = getCellText_noPrefixSuffixCommas; break;
      default                     : accessFunc = getTableValue
    }
    // A double loop over rowArray and colArray:
    var outputData: string[][] = []
    outputData = []
    for ( const rowKey of rowKeysToOutputInOrder) {
      var thisRow : string[] = []
      for (const colKey of colKeysToOutputInOrder) {
        let {value} = accessFunc(colKey, rowKey, false)
        thisRow.push( value )
      }
      outputData.push(thisRow)
    }
    return {colNames, outputData}
}




export const downloadResources =( tableComputedData: TableComputedData ): void => {
    const {table, tablelook, tabledata} = tableComputedData
    if ( process.env.NODE_ENV !== 'development' ) { return }
    // This section outputs table, tabledata, and tablelook json objects.
    // IFF we are in the 'development' environment.
    // The set of 3 files is given 3 NEW uuid's.
    // Pointer to the owner uuid's are not changed.
    const createNewID = false

    if ( createNewID ) {
      var newTableid     = uuidv4()
      var newTabledataid = uuidv4()
      var newTablelookid = uuidv4()
    } else {
      newTableid     = table.id
      newTabledataid = tabledata.id
      newTablelookid = tablelook.id
    }

    let newTable     = structuredClone(table)
    let newTabledata = structuredClone(tabledata)
    let newTablelook = structuredClone(tablelook)

    newTablelook.id = newTablelookid
    if ( newTablelook.relationships?.table ) {
      newTablelook.relationships.table.data.id = newTableid
      delete newTablelook.links
      delete newTablelook.relationships.table.links
      delete newTablelook.relationships.table.meta
      delete newTablelook.relationships.owner?.links
      delete newTablelook.relationships.owner?.meta
      delete newTablelook.relationships.originatorTable?.links
      delete newTablelook.relationships.originatorTable?.meta
    }

    newTabledata.id = newTabledataid
    if ( newTabledata.relationships?.table ) {
      newTabledata.relationships.table.data.id = newTableid
      delete newTabledata.links
      delete newTabledata.relationships.table.links
      delete newTabledata.relationships.table.meta
      delete newTabledata.relationships.owner.links
      delete newTabledata.relationships.owner.meta
    }

    newTable.id = newTableid
    if ( newTable.relationships?.tabledata ) {
      newTable.relationships.tabledata.data.id = newTabledataid
      delete newTable.links
      delete newTable.relationships.tabledata.links
      delete newTable.relationships.tabledata.meta
      delete newTable.relationships.owner.links
      delete newTable.relationships.owner.meta
      delete newTable.relationships.tags.links
      delete newTable.relationships.tags.meta
    }

    let tableJSON     = JSON.stringify( newTable )
    let tabledataJSON = JSON.stringify( newTabledata )
    let tablelookJSON = JSON.stringify( newTablelook )
    fileDownload(tableJSON,     'table.json'    , 'text/csv')
    fileDownload(tabledataJSON, 'tabledata.json', 'text/csv')
    fileDownload(tablelookJSON, 'tablelook.json', 'text/csv')
}



// No longer needed, but an example of how I used js to generate a table
// This would also need to create the colNames.
export const jpsFuncToCreate100KrowsOfRandomValues = ( ) : string[][] => {
  const numRows = 100000
  const numColumns = 12
  const formatObj = getFormattingObj( 'scientific') // default is 6 digits of precision.
  const outputData = []
  for (let rowIndex=0; rowIndex<numRows; rowIndex++) {
    var thisRow = []
    for (let colIndex=0; colIndex<numColumns; colIndex++ ) {
        thisRow[0] = String(rowIndex+1)
        var pairValues = getNormallyDistributedRandomNumber(0,1)
        thisRow[1] = basicNumberFormat( String(pairValues.val0), formatObj)
        thisRow[2] = basicNumberFormat( String(pairValues.val1), formatObj)
        pairValues = getNormallyDistributedRandomNumber(0,1)
        thisRow[3] = basicNumberFormat( String(pairValues.val0), formatObj)

        thisRow[4] = basicNumberFormat( String(2*Math.random()-1), formatObj)  // Range -1 (inclusive) to 1 (exclusive)
        thisRow[5] = basicNumberFormat( String(2*Math.random()-1), formatObj)
        thisRow[6] = basicNumberFormat( String(2*Math.random()-1), formatObj)

        thisRow[7]  = String( getPoissonDistributedRandomNumber(1) )
        thisRow[8]  = String( getPoissonDistributedRandomNumber(2) )
        thisRow[9]  = String( getPoissonDistributedRandomNumber(4) )
        thisRow[10] = String( getPoissonDistributedRandomNumber(8) )
        thisRow[11] = String( getPoissonDistributedRandomNumber(16) )
    }
    outputData.push( thisRow )
  }
  return outputData
}


export default saveTableAsCSV
