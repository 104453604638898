import { Component } from 'react'
import Tooltips from './Tooltips'
import type { TooltipState } from '../redux/tooltipReducer'
import type { TableComputedData } from '../computedDataTable/getDefaultTableComputedData'

type Props = {
  tableComputedData: TableComputedData
  isBrightField: boolean
  toolTipResource: TooltipState
}

export default class TooltipContainerRender extends Component<Props> {


  render() {
    const { tableComputedData, isBrightField, toolTipResource } = this.props
    const { toolTipStr, toolTipTargetHeight, toolTipTargetLeft, toolTipTargetTop,
      toolTipTargetWidth, toolTipPlacement, toolTipVisible, toolTipAddedExtension } = toolTipResource
    const containerBottom = tableComputedData.heightObj.viewHeightPx
    const containerLeft = 0
    const containerRight = tableComputedData.widthObj.viewWidthPx
    const containerTop = 0
    return (
      <div className={'rc_TooltipContainer'}
        style={{ left: '0', top: '0', position: 'absolute' }}
      >
        <Tooltips
          containerBottom={containerBottom}
          containerLeft={containerLeft}
          containerRight={containerRight}
          containerTop={containerTop}
          isBrightField={isBrightField}
          toolTipStr={toolTipStr}
          targetHeight={toolTipTargetHeight}
          targetLeft={toolTipTargetLeft}
          targetTop={toolTipTargetTop}
          targetWidth={toolTipTargetWidth}
          toolTipPlacement={toolTipPlacement}
          toolTipAddedExtension={toolTipAddedExtension}
          toolTipVisible={toolTipVisible}
        />
      </div>
    )
  }
}
