import invariant  from 'invariant'
import constants  from '../sharedComponents/constants'
import type {ScryInputString, ScryNumber, HighlightArray} from './parseScryInputStringTypes'
import {SIStag, SIStagEmptySpace, SISgetMatchInfo, newScryInputString} from './parseScryInputString'

const SCRY_NUMBER_INPUT_MAX_CHARACTERS = constants.SCRY_NUMBER_INPUT_MAX_CHARACTERS
const nbSpace = constants.nonBreakingSpace

// How positive lookahead is used to limit the length a matched strings.
// Search for this web reference:
// Regex – using positive lookahead assertion to limit total string lengths
// by Rahul Singla | Jun 27, 2016 | Coding, Javascript, Regex, Tech | 0 comments

// To limit the capture to strings of total length >= minLen, we use lookahead:
// (?=.{minLen,})
// Not clear how to, or whether it is possible to capture groups of exactly a fixed length.
// But that's OK.   >=minLen words well for our needs.

// When capturing Signed numbers, the leading set of characters MAY be:
//   [+-]? *       A possible sign, plus arbitrary space between the sign and first number/radix.
// HOWEVER, when there is NO sign, then this will capture leading spaces before
// the first number/radix. Which screws up the minLen count.
// So the optional sign is captured with non-capturing group:
// (?:[+-] *)?     This lumps the sign and space after it together. Take them all
// as a group, but don't take potential leading spaces by themselves !


const MAX_NUMBER_DIGITS_FOR_INTEGERS = 15
const multiplyChar  = String.fromCharCode(0x2217)  // Splat that is not raised like the asteric => \u2217



/////////////////////////////////////////////////////
/////////////////////////////////////////////////////
//
//            Exponential number testing
//
/////////////////////////////////////////////////////
/////////////////////////////////////////////////////

// mode = EXPRESSION:   does not capture a leading sign;  Find 'e', 'E' ,  but not  '*10**' operator  (Used for expression parsing)
// mode = NUMBER:   captures leading sign;   Finds 'e', 'E', '*10**' operators   (Used for Cell Value editor)
// minLen = ONLY match strings 'longerThan or Equal' to this minimum character length.


// To limit the capture to strings of total length >= minLen, we tried lookahead:
// (?=.{minLen,})
// But can't make any variation of lookahead foolproof.  And it is expensive inside regEx
// Therefore, we pass the minLen parameter to ScryInputString:
//    ScryInputString will look for a match of any length, but only tag matches of length >= minLen
//    Default




// EXPONENTIAL OPERATOR 'SignedE10'    finds operator:  3 * 10**   BUT NOT e,E
//const regExpExponentialOperator_SignedE10       =   /([+-]? *[\d.]+ *\* *10 *\*\* *[+-]? *[\d.]+)/i
const reSignedE10 = new RegExp( `((?:[+-] *)?[\\d\\.]+ *\\* *10 *\\*\\* *[+-]? *[\\d\\.]+)`, 'i' )
export const findExponentialOperator_SignedE10 = ( inStrg:ScryInputString, tagName:string, minLen:number=1 ) : number => {
  return SIStag(inStrg,  reSignedE10, tagName, 0, {}, minLen )
}

// EXPONENTIAL OPERATOR 'impliedE10'    finds operator:  -10**-3  (implied significand) BUT NOT e,E
const reImpliedE10 = new RegExp( `((?:[+-] *)?10 *\\*\\* *[+-]? *[\\d\\.]+)`, 'i' )
export const findExponentialOperator_ImpliedE10 = ( inStrg:ScryInputString, tagName:string, minLen:number=1 ) : number => {
  return SIStag(inStrg,  reImpliedE10, tagName, 0, {}, minLen )
}

// EXPONENTIAL OPERATOR 'Signed E' finds operators:   e, E,   BUT NOT 10**
//const regExpExponentialOperator_SignedE       =   /([+-]? *[\d.]+ *[*]? *[eE] *[+-]? *[\d.]+)/i
const reSignedE = new RegExp( `((?:[+-] *)?[\\d\\.]+ *[*]? *[eE] *[+-]? *[\\d\\.]+)`, 'i' )
export const findExponentialOperator_SignedE  = ( inStrg:ScryInputString, tagName:string, minLen:number=1 ) : number => {
  return SIStag(inStrg,  reSignedE, tagName, 0, {}, minLen )
}

// EXPONENTIAL OPERATOR 'E' finds operators:   e, E,   BUT NOT 10**
// This one is used for expressions!
//   - Does not capture a leading sign.
//   - Does not search for an illegal '*' between significand and 'e'.
//   - Does not search for the form  '10**'
//   - ONLY captures strings of length >= lenMin
//   `(?=.{${minLen},})([\\d.]+ *[*]? *[eE] *[+-]? *[\\d.]+)`
const reE = new RegExp( `([\\d.]+ *[*]? *[eE] *[+-]? *[\\d.]+)`, 'i' )
export const findExponentialOperator_E  = ( inStrg:ScryInputString, tagName:string, minLen:number=1) : number => {
  return SIStag(inStrg, reE, tagName, 0, {}, minLen )
}

export const findNumberExp = (parseStrg : ScryInputString, lineNum:number,
    mode:'EXPRESSION' | 'NUMBER', minLen:number = 1 ) : ScryNumber => {
  const result: ScryNumber = {
    internalFormat : parseStrg.inputStrg,
    originalInput  : parseStrg.inputStrg,
    numberType : 'MISSING_VALUE',
    errorID : '',
    warningID : '',
    highlightArray : [],
  }
  // This is the potential index where a match was made.  -1 means failure to match
  let matchIndex = -1
  let isE=false, isSignedE=false, isSignedE10=false, isImpliedE10=false
  let isNegative = false
  let tempWords: string[] = []
  let sigOpExpArr: string[] = []

  if ( mode === 'EXPRESSION' ) {  // Parse ignoring any leading +/- sign.
    matchIndex = findExponentialOperator_E (  parseStrg, 'constExp', minLen )
    if ( matchIndex >=0 ) isE = true
  }
  if ( mode === 'NUMBER' && matchIndex === -1 ) { // parsing a 'NUMBER', including any leading +/- sign.
    matchIndex  = findExponentialOperator_SignedE  (  parseStrg, 'constExp', minLen )
    if ( matchIndex >=0 ) isSignedE = true
  }
  if ( mode === 'NUMBER' && matchIndex === -1 ) { // parsing a 'NUMBER', including any leading +/- sign.
    matchIndex  = findExponentialOperator_SignedE10  (  parseStrg, 'constExp', minLen )
    if ( matchIndex >=0 ) isSignedE10 = true
  }
  if ( mode === 'NUMBER' && matchIndex === -1 ) { // parsing a 'NUMBER', including any leading +/- sign.
    matchIndex  = findExponentialOperator_ImpliedE10  (  parseStrg, 'constExp', minLen )
    if ( matchIndex >=0 ) isImpliedE10 = true
  }

  if ( !isSignedE && !isSignedE10 && !isE && !isImpliedE10) { return result }
  result.numberType = 'EXPONENTIAL'
  let {text:capturedText, start, end} = SISgetMatchInfo(parseStrg,   matchIndex )

  if ( isSignedE || isSignedE10 || isImpliedE10 ) {
      // Indentify, then replace the leading sign with a space.
      const firstChar = (capturedText.trim())[0]   // sign is first char of trimmed text
      if (firstChar === '-' ) {
        isNegative = true
        capturedText = capturedText.replace( /^( *)-/, '$1 ' )
      } else if ( firstChar === '+' ) {
        isNegative = false
        capturedText = capturedText.replace( /^( *)\+/, '$1 ' )
      }
  }

  // Split the captured string into 3 words: [significand, expOperator, exponent]
  // !!! These need to be identical length to the captured string!!!
  if ( isSignedE || isE ) {
    tempWords = capturedText.split( /[eE]/ )
    sigOpExpArr = [ tempWords[0], 'e', tempWords[1] ]
  } else if ( isSignedE10 ) {
    tempWords = capturedText.split( '*' )
    // '3*10**5'    => [ '-3 ','10','','5' ]
    // we remap to  => [ '-3 '+' ' , ' e' + '  ' , '5' ]
    tempWords[1] = tempWords[1] + ' '   // Add back the '*' character
    tempWords[1] = tempWords[1].replace( '10', ' e' ) + '  '  // add back the '**' character
    sigOpExpArr = [ tempWords[0], tempWords[1], tempWords[3] ]
    // sigOpExpArr total string length MUST be the same as capturedText.
  } else {  // isImpliedE10
    tempWords = capturedText.split( '**' )
    // '10 ** -5'    => [ '10 ',' -5' ]
    // we remap to  => [ ' e' + '  '  , ' -5' ]
    //console.log( 'tempWords length', tempWords[0].length, tempWords[1].length, 'Total capturedText', capturedText.length )
    let newExpOp = tempWords[0].replace( '10', ' e' ) + '  '
    sigOpExpArr = [ '', newExpOp, tempWords[1] ]
    //console.log( 'sigOpExpArr length', 0, sigOpExpArr[1].length, sigOpExpArr[2].length )
    // sigOpExpArr total string length MUST be the same as capturedText.
  }

  // For highlighting, where do the three words start/end
  const startSignificand = start
  const endSignificand   = startSignificand + sigOpExpArr[0].length
  const startExpOp   = endSignificand
  const endExpOp     = startExpOp + sigOpExpArr[1].length
  const startExpValue= endExpOp
  const endExpValue  = startExpValue + sigOpExpArr[2].length

  const significandText = sigOpExpArr[0].trim()
  let   expValueText = sigOpExpArr[2].trim()

  // Numbers defined by 'e' or 'E' should not have '*' between significand/'e'
  // Is the last character of the significand the '*' multiply sign?
  if ( (isSignedE || isE) && significandText.slice(-1) === '*' ) {
    result.errorID = `Illegal '${multiplyChar}' between value and 'e'`
    result.highlightArray = [{ lineNum, start:startSignificand, end:endExpOp}]
    return result
  }

  if ( (isSignedE || isE) && (capturedText.trim()).includes(' ') ) {
    let txt = capturedText.replace( / +/g, ' ' )
    result.warningID = `'${txt}'${nbSpace+nbSpace}saved as: ${capturedText.replace( / */g, '')}`
  }

  const significandIsTooManyDecimalPts = significandText.split('.').length > 2
  if ( significandIsTooManyDecimalPts ) {
    result.errorID = "More than one radix/decimal point: '.'"
    result.highlightArray = [{lineNum, start:startSignificand, end:endSignificand}]
    return result
  }

  const index = expValueText.indexOf('.')
  const exponentIsNotInteger = ( index !== -1 && index !== expValueText.length-1 )
  if ( exponentIsNotInteger ) {
    result.errorID = "Expect integer exponent."
    result.highlightArray = [{lineNum, start:startExpValue, end:endExpValue}]
    return result
  }


  // Conversion to canonical form.
  expValueText = expValueText.replace( / */g, '' )   // remove space between potential sign and digits
  let expNumber = Number( expValueText )
  let sigNumber = isImpliedE10 ? 1 : Number( significandText )
  let internalValueStrg = String(sigNumber) + 'e' + String(expNumber)
  let internalValueNumber = Number(internalValueStrg)
  parseStrg.matchArr[matchIndex].value = internalValueNumber

  // Range Test
  if (Math.abs(internalValueNumber) > 1.79e308 ) {
    result.errorID = 'Valid number range is +/- 1.79e308'
    result.highlightArray = [{lineNum, start, end}]
    return result
  }

  // Else return success.
  result.internalFormat= (isNegative) ?  '-' + internalValueStrg : internalValueStrg
  result.errorID = ''
  result.highlightArray = []
  return result
}


/////////////////////////////////////////////////////
/////////////////////////////////////////////////////
//
//            FLOAT number testing
//
/////////////////////////////////////////////////////
/////////////////////////////////////////////////////

// FLOAT
// We will consider 1.0 a float, .0 a float.
// And will consider 1. an integer
// Hence a FLOAT is any number with the tenths value defined.
// Optional digits before or after the required '.\d'
// MUST capture a decimal and at least one digit/decimal after decimal
// Will capture FLOATS with multiple decimal pts.
// Easier to flag this as an illegal float, rather two adjacent floats.

// This test is BEFORE integer test, so to call this a float, we must
// insure that at least on digit follows the decimal pt.
const reUFloat = new RegExp( `(\\d*\\.(?:\\d|\\.)+ *)` )
export const findUnsignedFloat = ( inStrg:ScryInputString, tagName:string, minLen:number ) : number => {
  return SIStag(inStrg, reUFloat, tagName, 0, {}, minLen )
}
const reSFloat = new RegExp( `((?:[+-] *)?\\d*\\.(?:\\d|\\.)+ *)` )
export const findSignedFloat = ( inStrg:ScryInputString, tagName:string, minLen:number ) : number => {
  return SIStag(inStrg, reSFloat, tagName, 0, {}, minLen )
}


export const findNumberFloat = (parseStrg : ScryInputString, lineNum:number,
                  mode:'SIGNED'|'UNSIGNED', minLen: number = 1 ) : ScryNumber => {

  const result: ScryNumber = {
    internalFormat : parseStrg.inputStrg,
    originalInput  : parseStrg.inputStrg,
    numberType : 'MISSING_VALUE',
    errorID : '',
    warningID: '',
    highlightArray : [],
  }
  let index = -1

  if ( mode === 'SIGNED' ) {
    index = findSignedFloat( parseStrg, 'constFlt', minLen )
  } else {  // 'UNSIGNED'
    index   = findUnsignedFloat( parseStrg, 'constFlt', minLen )
  }
  if ( index === -1 ) { return result }

  result.numberType = 'FLOAT'
  const {text, start, end} = SISgetMatchInfo(parseStrg,  index )

  // Error check for >1 decimal pt
  const numDecimalPts = text.split('.').length - 1
  if (numDecimalPts > 1 ) {
    result.errorID = "More than one radix/decimal point: '.'"
    result.highlightArray = [{lineNum,start, end}]
    return result
  }

  // Coerce to number and back to string for Canonical form
  let noSpacesText = text.replace( / */g, '' )
  let internalValueNumber = Number( noSpacesText )
  parseStrg.matchArr[index].value = internalValueNumber
  result.internalFormat   = String( internalValueNumber )

  if (Math.abs(internalValueNumber) > 1.79e308 ) {
    result.errorID = 'Valid number range is +/- 1.79e308'
    result.highlightArray = [{lineNum,start, end}]
    return result
  }

  return result
}

/////////////////////////////////////////////////////
/////////////////////////////////////////////////////
//
//            INTEGER number testing
//
/////////////////////////////////////////////////////
/////////////////////////////////////////////////////

// minimum INTEGER  'd'   | 'd.'  with optional d before
// minimum FLOAT    '.d'   with options d before or after
// MUST !! identify FLOATS first, then search for INTEGERS second
// IF integer search preceeds floats, then .1234  will be captured as integer '1234'.
// Can't use look behind to process the decimal preceeding 1234 because look behind
// is not supported in FireFox.

const reUInt = new RegExp( `(\\d+\\.?)` )
export const findUnsignedInteger = ( inStrg:ScryInputString, tagName:string, minLen:number ) : number => {
  return SIStag(inStrg, reUInt, tagName, 0, {}, minLen )
}
const reSInt = new RegExp( `((?:[+-] *)?\\d+\\.?)` )
export const findSignedInteger = ( inStrg:ScryInputString, tagName:string, minLen:number  ) : number => {
  return SIStag(inStrg, reSInt, tagName, 0, {}, minLen )
}

export const findNumberInteger = (parseStrg : ScryInputString, lineNum:number,
         mode:'SIGNED'|'UNSIGNED', minLen:number=1 ) : ScryNumber => {

  const result: ScryNumber = {
    internalFormat : parseStrg.inputStrg,
    originalInput  : parseStrg.inputStrg,
    numberType : 'MISSING_VALUE',
    errorID : '',
    warningID : '',
    highlightArray : [],
  }
  let index = -1

  if ( mode === 'SIGNED' ) {
    index = findSignedInteger  ( parseStrg, 'constInt', minLen )
  } else {  // 'UNSIGNED'
    index     = findUnsignedInteger( parseStrg, 'constInt', minLen )
  }
  if ( index === -1 ) { return result }

  result.numberType = 'INTEGER'
  const {text, start, end} = SISgetMatchInfo(parseStrg,  index )
  const digitsOnlyText = text.replace( /[+-]/, ' ' )
  const numDigits = digitsOnlyText.trim().length
  if (numDigits > MAX_NUMBER_DIGITS_FOR_INTEGERS ) {
    result.errorID = `Integers must be <= ${MAX_NUMBER_DIGITS_FOR_INTEGERS} digits.`
    result.highlightArray = [{lineNum,start, end}]
    return result
  }
  // Coerce to number and back to string for Canonical form
  let noSpacesText = text.replace( / */g, '' )
  let internalValueNumber = Number(noSpacesText)
  parseStrg.matchArr[index].value = internalValueNumber
  let internalValueStrg   = String( internalValueNumber )
  result.internalFormat= internalValueStrg
  result.errorID = ''
  result.highlightArray = []
  return result
}


/////////////////////////////////////////////////////
//   B60seconds number testing
/////////////////////////////////////////////////////

const reB60S = new RegExp( `((?:[+-] *)?\\d*:\\d+\\.?\\d*)` )
const findB60Signed = ( parseStrg:ScryInputString, tagName:string, minLen:number=1 ) : number => {
  return SIStag(parseStrg, reB60S, tagName, 0, {}, minLen )
}
const reB60U = new RegExp( `(\\d*:\\d+\\.?\\d*)` )
const findB60Unsigned = ( parseStrg:ScryInputString, tagName:string, minLen:number=1 ) : number => {
  return SIStag(parseStrg, reB60U, tagName, 0, {}, minLen )
}
const reB60B60S = new RegExp( `((?:[+-] *)?\\d*:\\d+:\\d+\\.?\\d*)` )
const findB60B60Signed = ( parseStrg:ScryInputString, tagName:string, minLen:number=1 ) : number => {
  return SIStag(parseStrg, reB60B60S, tagName, 0, {}, minLen )
}
const reB60B60U = new RegExp( `(\\d*:\\d+:\\d+\\.?\\d*)` )
const findB60B60Unsigned = ( parseStrg:ScryInputString, tagName:string, minLen:number=1 ) : number => {
  return SIStag(parseStrg, reB60B60U, tagName, 0, {}, minLen )
}


export const findNumberB60seconds = (parseStrg : ScryInputString, lineNum:number,
     mode: 'SIGNED'|'UNSIGNED', minLen:number=0  ) : ScryNumber => {

  const result: ScryNumber = {
    internalFormat : parseStrg.inputStrg,
    originalInput  : parseStrg.inputStrg,
    numberType : 'MISSING_VALUE',
    errorID : '',
    warningID: '',
    highlightArray : [],
  }
  let index = -1

  if ( mode === 'SIGNED' ) {
    index = findB60Signed( parseStrg, 'constB60', minLen )
  } else {  // 'UNSIGNED'
    index   = findB60Unsigned( parseStrg, 'constB60', minLen )
  }
  if ( index === -1 ) { return result }

  let {text, start } = SISgetMatchInfo(parseStrg,  index )
  result.numberType = 'B60'

  let m = text.match( /([+-]? *)(\d*)(:\d+)(\.?\d*)/ )
  invariant ( m && m[4]!== undefined, `Expected 4 numeric parts in string: ${text}` ) // m[4] often empty string.
  let sign = m[1]
  let leadInt = m[2]
  let b60 = m[3]
  let fracPart = m[4]

  let startLeadInt = start + sign.length
  let startB60     = startLeadInt + leadInt.length

  // Needs to be at least one leading digit prior to the ':'
  if ( leadInt.length === 0 ) {
    result.errorID = `Expect an integer ( 0${b60}? ) before colon.`
    result.highlightArray = [{lineNum, start:startB60, end:startB60+b60.length}]
    return result
  }
  // Need to be exactly 2 digits after the ':'
  if ( b60.length !== 3 ) {
    result.errorID = 'Expected 2 integers following the colon.'
    result.highlightArray = [{lineNum, start:startB60, end:startB60+b60.length}]
    return result
  }
  // b60 value cannot exceed 59
  const b60Value = Number( b60.slice(1) )
  if ( b60Value > 59) {
    result.errorID = 'Value cannot exceed 59.'
    result.highlightArray = [{lineNum, start:startB60, end:startB60+b60.length}]
    return result
  }

  // fractional value is zero if length is zero (no fract part) or length is one (a single decimal pt.)
  const fractionalValue = (fracPart.length > 1) ? Number(fracPart) : 0

  const isNegative = sign.includes( '-' )
  let internalValueNumber = Number(leadInt) * 60 + b60Value + fractionalValue
  if (isNegative) internalValueNumber *= -1
  result.internalFormat = String(internalValueNumber)
  parseStrg.matchArr[index].value = internalValueNumber
  return result
}

export const findNumberB60B60seconds = (parseStrg : ScryInputString, lineNum:number,
     mode: 'SIGNED'|'UNSIGNED', minLen:number=0  ) : ScryNumber => {

  const result: ScryNumber = {
    internalFormat : parseStrg.inputStrg,
    originalInput  : parseStrg.inputStrg,
    numberType : 'MISSING_VALUE',
    errorID : '',
    warningID: '',
    highlightArray : [],
  }
  let index = -1

  if ( mode === 'SIGNED' ) {
    index = findB60B60Signed( parseStrg, 'constB60B60', minLen )
  } else {  // 'UNSIGNED'
    index   = findB60B60Unsigned( parseStrg, 'constB60B60', minLen )
  }
  if ( index === -1 ) { return result }

  let {text, start} = SISgetMatchInfo(parseStrg,  index )
  result.numberType = 'B60B60'

  let m = text.match( /([+-]? *)(\d*)(:\d+)(:\d+)(\.?\d*)/ )
  invariant ( m && m[5] !== undefined, `Expected 5 numeric parts in string: ${text}` ) // m[5] often empty string.
  let sign = m[1]
  let leadInt = m[2]
  let b60_1 = m[3]
  let b60_2 = m[4]
  let fracPart = m[5]

  let startLeadInt = start + sign.length
  let startB60_1   = startLeadInt + leadInt.length
  let startB60_2   = startB60_1 + b60_1.length


  // Needs to be at least one leading digit prior to the ':'
  if ( leadInt.length === 0 ) {
    result.errorID = `Expect an integer ( 0${b60_1}${b60_2}? ) before colon.`
    result.highlightArray = [{lineNum, start: startB60_1 , end:startB60_2 + b60_2.length}]
    return result
  }
  // Need to be exactly 2 digits after the ':'
  if ( b60_1.length !== 3 ) {
    result.errorID = 'Expected 2 integers following the colon.'
    result.highlightArray = [{lineNum, start: startB60_1 , end:startB60_2 + b60_2.length}]
    return result
  }
  // b60 value cannot exceed 59
  let b60_1Value = Number( b60_1.slice(1) )
  if ( b60_1Value > 59) {
    result.errorID = 'Value cannot exceed 59.'
    result.highlightArray = [{lineNum, start: startB60_1 , end:startB60_1 + b60_1.length}]
    return result
  }
  // Need to be exactly 2 digits after the ':'
  if ( b60_2.length !== 3 ) {
    result.errorID = 'Expected 2 integers following the colon.'
    result.highlightArray = [{lineNum, start: startB60_2 , end:startB60_2 + b60_2.length}]
    return result
  }
  // b60 value cannot exceed 59
  let b60_2Value = Number( b60_2.slice(1) )
  if ( b60_2Value > 59) {
    result.errorID = 'Value cannot exceed 59.'
    result.highlightArray = [{lineNum, start: startB60_2 , end:startB60_2 + b60_2.length}]
    return result
  }

  // fractional value is zero if length is zero (no fract part) or length is one (a single decimal pt.)
  const fractionalValue = (fracPart.length > 1) ? Number(fracPart) : 0

  const isNegative = sign.includes( '-' )
  let internalValueNumber = Number(leadInt)*3600 + b60_1Value*60 + b60_2Value + fractionalValue
  if (isNegative) internalValueNumber *= -1
  result.internalFormat = String(internalValueNumber)
  parseStrg.matchArr[index].value = internalValueNumber
  return result
}


/////////////////////////////////////////////////////
//   BOOL_TF  number testing
/////////////////////////////////////////////////////

// minLen never used here in the application.
// But added it to make the number test module work for BOOLs
// at the same time as numbers.
const reTrue = new RegExp( `(true)`, 'i' )
const findBooleanTrue = ( parseStrg:ScryInputString, tagName:string, minLen:number=1  ) : number => {
  return SIStag(parseStrg, reTrue, tagName, 0, {}, minLen )
}
const reFalse = new RegExp( `(false)`, 'i' )
const findBooleanFalse = ( parseStrg:ScryInputString, tagName:string, minLen:number=1 ) : number => {
  return SIStag(parseStrg, reFalse, tagName, 0, {}, minLen )
}

export const findNumberBoolean = (parseStrg : ScryInputString, lineNum:number, minLen:number ) : ScryNumber => {

  const result: ScryNumber = {
    internalFormat : parseStrg.inputStrg,
    originalInput  : parseStrg.inputStrg,
    numberType : 'MISSING_VALUE',
    errorID : '',
    warningID: '',
    highlightArray : [],
  }

  let falseIndex = -1
  const trueIndex = findBooleanTrue( parseStrg, 'BOOL_TF', minLen )
  if (trueIndex === -1) {
    falseIndex = findBooleanFalse( parseStrg, 'BOOL_TF', minLen )
  }
  // Early exit if search was unsuccessful
  if ( trueIndex === -1 && falseIndex === -1 ) { return result }
  result.numberType = 'BOOL_TF'
  result.internalFormat = (trueIndex >= 0) ? '1' : '0'
  // For this numberType, there are no internal errors.
  // Either we sucessfully find a boolean, or we return
  // a MISSING_VALUE
  return result
}


const findComma = ( inStrg: ScryInputString ) : number => {
  const regExpr = new RegExp( `(,)`, 'i' )
  return SIStag(inStrg,  regExpr, 'comma', 0, {}, 0 )
}


// Utility function to check a CURRENTLY VALID number for any 'unmatched' (unexpected,extra) characters
const anyUnmatchedSubstrings = ( parseStrg: ScryInputString, lineNum:number ) : HighlightArray => {
  SIStagEmptySpace(parseStrg,  'emptySpace' )
  const highlightArray: HighlightArray = []
  parseStrg.matchArr.forEach( thisSubString => {
    const {start,end, name} = thisSubString
    if (name === 'unmatched' ) { highlightArray.push({lineNum, start, end}) }
  })
  return highlightArray
}


////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
//
//      MAIN external function:
//
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const isScryNumber = ( userVal:string,
                              lineNum:number=0,
                              minLen:number=1,
                              fullCheck:boolean=true ): ScryNumber => {

      /*
      'fullCheck' option:
          default true:  For parsing user inputs (Best example is formulas) where
          we desire to know the type of number, and provide an error message for number syntax
          that is close, but not legal ( e.g.   1:23:4, -2e--7, ... )
      'fullCheck' is set false
          When we simply need a true/false result.  (Best example is input column,
          internalDataType==='number' where a quick isNaN() can speed up the function)
      */


      let result: ScryNumber = {
        originalInput  : userVal,
        internalFormat : userVal,
        numberType     : 'DATA_ERR',
        errorID        : '',
        warningID      : '',
        highlightArray : [],
      }

      // CASE:  INPUT IS undefined - Consider this a bug in the calling function!
      if ( userVal === undefined ) {
        result.numberType = 'DATA_ERR'
        result.errorID = `Input string is undefined.`
        result.highlightArray = []
        return result
      }

      // CASE:  USER INPUT EXCEEDS MAX LENGTH STRING
      // Precedes Missing Value testing, because typing a lot of empty spaces
      // results in no error messages.
      if ( userVal.length > SCRY_NUMBER_INPUT_MAX_CHARACTERS ) {
        result.numberType = 'DATA_ERR'
        result.errorID = `Input exceeds ${SCRY_NUMBER_INPUT_MAX_CHARACTERS} max characters.`
        result.highlightArray = [{lineNum, start:SCRY_NUMBER_INPUT_MAX_CHARACTERS, end:userVal.length}]
        return result
      }

      // This function expects userVal to have no linefeeds or tabs
      // Could probably work if we needed to extend to allow any whitespace.
      // But for now we assume only whitespace allowed is a space.
      // This is the object we must return to parent.

      //const userVal = userValIn

      // CASE:  MISSING VALUE
      if ( userVal === '' || userVal.trim().length === 0 ) {
        result.numberType = 'MISSING_VALUE'
        return result
      }

      if ( !fullCheck ) {
        if ( !isNaN(Number(userVal)) ) {
          result.numberType = 'IEEE'
          return result
        }
      }

      let parseStrg:ScryInputString = newScryInputString( userVal )
      let wasNumberFound = false   // Set to true as soon as we match a known number format.

      // Test if number contains NO DIGITS. If not, is this a BOOLEAN?
      // We have to ask about digits first because findNumberBoolean
      // and find exponentialOperator both search for 'e'.  We can
      // handle all the corner cases if we decide up front whether to
      // test this string as a number or as a boolean.  We decide
      // up front by asking whether the input string includes any digits.
      const didFindDigit = userVal.match( /(\d)/ )
      if (!didFindDigit ) { // No digits in input string
        result = findNumberBoolean (parseStrg, lineNum, minLen )
        wasNumberFound = (result.numberType === 'BOOL_TF')
        if ( wasNumberFound && result.errorID !== '') { return result }
      }

      //  No comma's allowed.  Because we cannot use comma as a number
      //  separator (groups of 3) in the formula editor.  In python
      //  formula's (and all SW languages) the comma is used to
      //  speparate arguments.  So if it is illegal in formula's, then
      //  we should make it illegal in the number editor.
      //  Albeit, we will allow it to be used in formatted numbers as
      //  shown in the table, statsBar, rowNumbers, etc.
      const matchIndex = findComma( parseStrg )
      if ( matchIndex !== -1 ) {
        let {start,end} = parseStrg.matchArr[matchIndex]
        result.errorID = "Comma within a number not supported."
        result.highlightArray = [{lineNum, start, end}]
        result.numberType = 'DATA_ERR'
        return result
      }

      // Search for numeric formats begins here.
      // As soon as we recoginize a specific format,
      // this flag is set to true.  So we avoid testing
      // against any other format.  This requires we test
      // for formats in a specific order:
      // B60  - it is base60 format if we find a ':'
      // Exponential -  if we find an e,E, or 10**
      // Float - if we find a decimal pt
      // Integer - Last remaining attempt to identify the number.
      // Labeling the input string with a specific format is one question.
      // Is the string a valid number is a 2nd question!
      // For example, just finding a ':' does NOT make a valid B60 input.
      // However, we will call it B60 regardless, no matter what
      // format we 'believe' the user may be trying to enter.
      // Hence , ':' will always mean B60 error testing.
      //  'e,E,10**2' will always mean exponential error testing.
      // Followed by FLOAT test and finally INTEGER test.

      // CASE: B60B60seconds
      if (!wasNumberFound) {
        result = findNumberB60B60seconds (parseStrg, lineNum, 'SIGNED', minLen )
        wasNumberFound = (result.numberType === 'B60B60' )
        if ( wasNumberFound && result.errorID !== '') {
          result.numberType = 'DATA_ERR'
          return result
        }
      }

      // CASE: B60seconds
      if (!wasNumberFound) {
        result = findNumberB60seconds (parseStrg, lineNum, 'SIGNED', minLen )
        wasNumberFound = (result.numberType === 'B60' )
        if ( wasNumberFound && result.errorID !== '') {
          result.numberType = 'DATA_ERR'
          return result
        }
      }

      if (!wasNumberFound) {
        result = findNumberExp (parseStrg, lineNum, 'NUMBER', minLen )
        wasNumberFound = (result.numberType === 'EXPONENTIAL')
        if (result.numberType === 'EXPONENTIAL' && result.errorID !== '') {
          result.numberType = 'DATA_ERR'
          return result
        }
      }


      // CASE: FLOAT
      if (!wasNumberFound) {
        result = findNumberFloat (parseStrg, lineNum, 'SIGNED', minLen )
        wasNumberFound = (result.numberType === 'FLOAT')
        if (wasNumberFound && result.errorID !== '') {
          result.numberType = 'DATA_ERR'
          return result
        }
      }

      // CASE: INTEGER
      if (!wasNumberFound) {
        result = findNumberInteger (parseStrg, lineNum, 'SIGNED', minLen )
        wasNumberFound = (result.numberType === 'INTEGER')
        if ( wasNumberFound && result.errorID !== '') {
          result.numberType = 'DATA_ERR'
          return result
        }
      }

      result.highlightArray = anyUnmatchedSubstrings( parseStrg, lineNum )
      // The 'valid number' return path
      if ( result.highlightArray.length === 0 ) { return result }

      // Unexpected, extra characters return path.
      result.numberType = 'DATA_ERR'
      result.errorID = 'Found non-numeric or extra characters.'
      return result
  }



export default isScryNumber
