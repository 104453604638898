import type { AnyAction, Dispatch } from '@reduxjs/toolkit'
import type { RootState } from '../redux/store'
import type { Notification } from './react-notification-system/constants'
import type { GenericStore } from './react-notification-system-redux3/Notifications'
import { Component } from 'react'
import Notifications from './react-notification-system-redux3/Notifications'
import { connect } from 'react-redux'

type OwnProps = {
}
type StateProps = {
  notifications: Notification[]
}
type DispatchProps = {
  dispatch: Dispatch<AnyAction>,
}
type Props = OwnProps & StateProps & DispatchProps

class NotifyRender extends Component<Props> {
  render() {
    const { notifications, dispatch } = this.props

    //Scry custom styling
    const defaultColors = {
      success: {
        rgb: '94, 164, 0',
        hex: '#5ea400'
      },
      error: {
        rgb: '236, 61, 61',
        hex: '#ec3d3d'
      },
      warning: {
        rgb: '235, 173, 23',
        hex: '#ebad1a'
      },
      info: {
        rgb: '54, 156, 199',
        hex: '#369cc7'
      }
    }

    const defaultShadowOpacity = '0.8'

    const style = {
      Dismiss: {
        DefaultStyle: {
          opacity: 0,
        }
      },
      Containers: {
        tr: {
          top: '64px',
          right: '10px',
          width: 460,
        }
      },
      NotificationItem: {
        DefaultStyle: { // Applied to every notification, regardless of the notification level
          margin: '10px 0 0',     // 1st value sets vertical space between notes
          borderRadius: '10px',
          paddingLeft: '20px',  // space from left boundary to textAlign
          transition: '0.3s ease-in-out',
        },
        success: {
          borderBottom: '3px solid' + defaultColors.success.hex,
          borderRight: '3px solid' + defaultColors.success.hex,
          borderTop: '0px solid',
          // shadow: 1st value horizontal position
          // 2nd vertical position
          // 3rd blur distance
          // 4rth optional spread
          // color
          WebkitBoxShadow: '2px 4px 10px -5px rgba(' + defaultColors.success.rgb + ',' + defaultShadowOpacity + ')',
          MozBoxShadow: '2px 4px 10px -5px rgba(' + defaultColors.success.rgb + ',' + defaultShadowOpacity + ')',
          boxShadow: '2px 4px 10px -5px rgba(' + defaultColors.success.rgb + ',' + defaultShadowOpacity + ')'
        },
        warning: {
          borderBottom: '3px solid' + defaultColors.warning.hex,
          borderRight: '3px solid' + defaultColors.warning.hex,
          borderTop: '0px solid',
          WebkitBoxShadow: '2px 4px 10px -5px rgba(' + defaultColors.warning.rgb + ',' + defaultShadowOpacity + ')',
          MozBoxShadow: '2px 4px 10px -5px rgba(' + defaultColors.warning.rgb + ',' + defaultShadowOpacity + ')',
          boxShadow: '2px 4px 10px -5px rgba(' + defaultColors.warning.rgb + ',' + defaultShadowOpacity + ')'
        },
        error: {
          borderBottom: '3px solid' + defaultColors.error.hex,
          borderRight: '3px solid' + defaultColors.error.hex,
          borderTop: '0px solid',
          WebkitBoxShadow: '2px 4px 10px -5px rgba(' + defaultColors.error.rgb + ',' + defaultShadowOpacity + ')',
          MozBoxShadow: '2px 4px 10px -5px rgba(' + defaultColors.error.rgb + ',' + defaultShadowOpacity + ')',
          boxShadow: '2px 4px 10px -5px rgba(' + defaultColors.error.rgb + ',' + defaultShadowOpacity + ')'
        },
        info: {
          borderBottom: '3px solid' + defaultColors.info.hex,
          borderRight: '3px solid' + defaultColors.info.hex,
          borderTop: '0px solid',
          WebkitBoxShadow: '2px 4px 10px -5px rgba(' + defaultColors.info.rgb + ',' + defaultShadowOpacity + ')',
          MozBoxShadow: '2px 4px 10px -5px rgba(' + defaultColors.info.rgb + ',' + defaultShadowOpacity + ')',
          boxShadow: '2px 4px 10px -5px rgba(' + defaultColors.info.rgb + ',' + defaultShadowOpacity + ')'
        },
      }
    }

    const store: GenericStore = {
      dispatch
    }

    return (
      <Notifications
        notifications={notifications}
        style={style}
        allowHTML={true}
        store={store}
      />
    )
  }
}

const mapDispatch = (dispatch: Dispatch<AnyAction>): DispatchProps => ({
  dispatch
})

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const notifications = state.notifications
  return {
    notifications
  }
}

const Notify = connect(mapStateToProps, mapDispatch)(NotifyRender)
export default Notify
