const joi = require('joi')

const tabledataAttributes = {
  tableValues: joi.array().items(
        joi.array().sparse(true).items( joi.string().allow('').allow(null),
    ).allow(null))
    .description('The values of the table data'),
  updatedDate: joi.date().iso().description('Date table data last modified.'),

  // server side managed data (read only to clients)
  createdDate: joi.date().iso().allow(null).meta('readonly')
    .description('Read only date table data created.'),
}

module.exports = tabledataAttributes
