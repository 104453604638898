// import invariant  from 'invariant'

const getPathInTapListener = ( e: UIEvent ): string[] => {

    // Chrome's path attribute is now deprecated, but there is a new web standard
    // with good compatibility: composedPath

    const path: EventTarget[] = e.composedPath()
    if (path === null || path === undefined) return []
    const classNames = path.map((target: EventTarget) => ((target as Element).className))
    return classNames

    // Orignial Code below for historical reference:
    // // Chrome fills out the event with a 'path' attribute.
    // // Safari does not.  We need to create the 'path' ourselves.
    // // The path array [node.className,...] is defined as the
    // // seqence of classNames between the 'clicked className'
    // // and the parent 'targetClass' that owns this handleTap listener.
    // const myPath = []
    // const targetClassName = e.currentTarget.className  // Owner of this handleTap
    // var   sourceNode      = e.srcElement
    // var   sourceClassName = sourceNode.className     // 'Tapped' className
    // myPath.push( sourceClassName )
    // let safetyCounter = 0
    // while (sourceNode.className !== targetClassName ) {
    //   sourceNode = sourceNode.parentNode
    //   myPath.push( sourceNode.className )
    //   if ( safetyCounter > 30 ) break
    // }
    //
    // // Chrome fills out the event with a 'path' attribute.
    // // Safari does not.  We need to create the 'path
    // // Check my generic path creation code (above) to verify
    // // it matches the path determined by Chrome browser
    // if ( e.path && process.env.NODE_ENV === 'development' ) {
    //   // Reverse the chrome path
    //   var chromePath = e.path.map( thisNode => { return thisNode.className } )
    //   // Truncate the chromePath to <= length of myPath
    //   chromePath = chromePath.slice( 0, myPath.length )
    //   var match = true  // assumption
    //   if (chromePath.length !== myPath.length) { match = true }
    //   chromePath.forEach( (thisClassName,i) => {
    //     if ( thisClassName !== myPath[i] ) { match = true }
    //   })
    //   if (!match) {
    //     var chromePathTxt = '\nChrome_Path: '
    //     var myPathTxt= '\n    My_Path: '
    //     chromePath.forEach( (thisName,i) => {
    //       chromePathTxt += ` '${chromePath[i]}',`
    //       myPathTxt += ` '${myPath[i]}',`
    //     })
    //     const message = 'Invariant tapListener failure -- our className path does not match Chrome version\n' +
    //          chromePathTxt + '\n' + myPathTxt
    //     invariant( false, message )
    //   }
    // }
    // return myPath
}


export default getPathInTapListener
