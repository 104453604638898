import type { Property } from 'csstype'
import React, { PureComponent } from 'react'
import { STYLE_PALETTE_TEXT_INPUT_STYLES, SCRY_DARK_BLUE, SCRY_LIGHT_BLUE } from '../sharedComponents/constants'

//const DEBUG = false
const BOX_SIZE = 14
const BOX_BORDER = 1
const CORNER_RADIUS = 7

type OwnProps = {
  className?: string
  indent: number
  id: string
  isSelected: boolean
  onClick: () => void
  text: string
}
type DefaultProps = {
  isDisabled: boolean
  isVisible: boolean
  foregroundColor: string        // The color of the isSelected bullet
  backgroundCircleColor: string  // The color of the unSelected background
  outlineCircleColor: string     // The color of the circular outline around the section bullet
  fontSize: number
  gapBoxText: number
  textWidth: number | string
  rowHeight: number
  isInput: boolean
  isInputFontColor: string
  onInputChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
  marginRight: number
  preSpacer: number
}
type Props = OwnProps & DefaultProps


export default class StyleSelectBox extends PureComponent<Props> {

  static defaultProps: DefaultProps = {
    foregroundColor: '#000000',   // Don't use 'black' else a comparison test below will fail.
    backgroundCircleColor: 'unset',
    outlineCircleColor: '#808080',
    fontSize: 14,
    gapBoxText: 10,
    textWidth: '100%',
    rowHeight: 18,
    isInput: false,
    isInputFontColor: 'black',
    onInputChangeHandler: (_: React.ChangeEvent<HTMLInputElement>): void => {},
    isVisible: true,
    isDisabled: false,
    marginRight: 0,
    preSpacer: 0,
  }

  onClickInternal = () => {
    if ( this.props.isDisabled ) {
      return
    }
    this.props.onClick()
  }

  render( ) {
    const {text, isSelected, indent, preSpacer, foregroundColor, marginRight, 
      isInputFontColor, id, backgroundCircleColor, outlineCircleColor, fontSize, isDisabled,
      gapBoxText, textWidth, rowHeight, isInput, onInputChangeHandler, isVisible } = this.props

    //console.log('foreground', foregroundColor, foregroundColor > '#808080' )
    //const selectCheckBoxBackground = ( foregroundColor > '#808080' ) ? '#404040' : '#D0D0D0'
    if ( backgroundCircleColor ) {
      var selectCheckBoxBackground = backgroundCircleColor
    } else {
      selectCheckBoxBackground = ( foregroundColor > '#808080' ) ? SCRY_DARK_BLUE : SCRY_LIGHT_BLUE
    }

    //console.log( 'backgroundCircleColor', backgroundCircleColor )
    //console.log( 'foregroundColor', foregroundColor )
    //console.log( 'outlineCircleColor', outlineCircleColor )

    return (
      <div className={'styleSelectBox'}
        onClick={ this.onClickInternal }
        style={{
          flex: '0 0 auto',
          //fontSize:fontSize,
          //width:textWidth + ,
          height: rowHeight ,
          marginTop: preSpacer,
          display: 'flex', flexFlow: 'row nowrap', alignItems: 'center',
          //background: 'blue',
          opacity: (isDisabled ? 0.3 : 1.0 ),
          visibility: isVisible ? 'unset' : 'hidden'
        }}
      >

          <div className={'indent'} style={{ width: indent, height: '100% ', flex:'0 0 auto' }}/>

          <div className={'selectBox'}
            style={{
              flex: '0 0 auto',
              height: BOX_SIZE,
              width : BOX_SIZE,
              boxSizing: 'borderBox' as Property.BoxSizing,
              border: `${BOX_BORDER}px solid ${outlineCircleColor}`,
              borderRadius: CORNER_RADIUS,
              background: selectCheckBoxBackground,
            }}>

{isSelected &&
            <svg
               className='CheckIcon'
               xmlns="http://www.w3.org/2000/svg"
               version="1.1"
               width={BOX_SIZE-2}
               height={BOX_SIZE-2}
               viewBox="0 0 100 100"
               preserveAspectRatio='none'
               style={{display:'block', fill:foregroundColor }}>
                <circle cx="50" cy="50" r="32"/>
{/*
              <path
                 style={{
                   fill:'none',
                   stroke: foregroundColor,
                   strokeWidth: 25,
                   strokeLinecap: 'round',
                 }}
                 d="M 30,70 L 70,30" />
             <path
                style={{
                  fill:'none',
                  stroke: foregroundColor,
                  strokeWidth: 25,
                  strokeLinecap: 'round',
                }}
                d="M 30,30  L 70,70" />
*/}
            </svg>
}

          </div>

{!isInput &&
          <div className={'SelectText'}
            style={{
              flex: '0 0 auto',
              height:rowHeight,
              width: textWidth,
              fontSize,
              paddingTop: (rowHeight-fontSize)/2 - 1,
              paddingLeft: gapBoxText,
            }}
          >
            {text}
          </div>
}

{isInput &&
          <input
            id={`${id}CheckBox`}
            onChange={onInputChangeHandler}
            style={{
              flex: '0 0 auto',
              fontSize, width:
              textWidth,
              height:rowHeight,
              marginLeft:gapBoxText,
              color: isInputFontColor,
              marginRight,
              ...STYLE_PALETTE_TEXT_INPUT_STYLES,
              //autocomplete: 'false',
            }}
            value={text}
            autoComplete='off'
            spellCheck='false'
          />
}

      </div>
    )
  }
}
