import type { KeyboardEvent }       from 'react'                 
import type { TableComputedData }   from '../computedDataTable/getDefaultTableComputedData'
import type { LightweightMod }      from '../types'
import type { ActiveFp }            from '../types'

import {sessionStateChangeDispatch} from '../sharedComponents/reactDispatch'
import {getTableFpNameFromColKey, isTableCellEditor}   from './getTableTile'


 
export const handlerKeyPress = (event: KeyboardEvent, tCD: TableComputedData, activeFp: ActiveFp ) => {
    if (event.key === 'Escape') { 
        const sessionMods : LightweightMod[] = 
           [{newVal:'none', path: 'activeFp.fpName'},
            {newVal: -1, path: 'activeFp.primaryKey'},
            {newVal: -1, path: 'activeFp.secondaryKey'}]
        sessionStateChangeDispatch( sessionMods, 'Select New Cell' )
        return
    }
    // Only other keyPress we currently recognize is to step (by row or by column) to an adjacent cell.
    // And this stepping support ONLY applies if/when the current fpName is a tableCellEditor
    if ( isTableCellEditor( activeFp.fpName ) === false ) { return }
    switch (event.key) {
        case 'ArrowDown' : moveSelection( 0, 1, tCD, activeFp ); break
        case 'ArrowLeft' : moveSelection(-1, 0, tCD, activeFp ); break
        case 'ArrowRight': moveSelection( 1, 0, tCD, activeFp ); break
        case 'ArrowUp'   : moveSelection( 0,-1, tCD, activeFp ); break
        default:
    }
}


    // This function finds the 'next' column when using the left/right arrow keys.
    // Where 'next' is defined as the adjacent VISIBLE column.  We skip over
    // hidden columns. And for case of lastVisibleLockedColIndex and moving right,
    // we also skip over any moving columns currently scrolled left 'out of view'.
    // Hence, when moving right, we jump from lastVisibleLockedColIndex to first 'on-screen' moving column.
    // This is NOT the behavior when moving left, because we will autoscroll until we scrollLeft reaches 0.
    
const getNextVisibleColIndex = (currentColIndex : number , deltaIndex : number, tCD:TableComputedData ) : number => {
    const {isHidden_ByColIndex, widthObj:w, scrollLeft } = tCD
    const numColumns = isHidden_ByColIndex.length
    const {numLockedCols, startColLeft} = w
    // Working copy because I MAY change a visible column to a hidden if it is scrolled out-of-view to the left
    const isNotVisibleCol = isHidden_ByColIndex.slice()
    // Special case of lastVisibleLockedColIndex
    let isLastVisibleLockedColIndex = false  // assumption
    const isLockedColIndex = currentColIndex < numLockedCols
    if ( isLockedColIndex ) {
        const lockedColsRightOfCurrent = isNotVisibleCol.slice(currentColIndex+1, numLockedCols)
        isLastVisibleLockedColIndex = lockedColsRightOfCurrent.every( (x) => x === true )
    }
    // Code to skip over moving columns that are scrolled out of view to the left.
    // Here we set the isNotVisibleCol flag to true for these columns.
    // Only necessary when moving right from lastVisibleLockedColIndex
    if (isLastVisibleLockedColIndex && deltaIndex === 1) {
        for ( let i = numLockedCols; i < numColumns; i++ ) {
            if (startColLeft[i] < scrollLeft) {  // This line to skip paritally visible columns
            //if (startColLeft[i] + displayedColWidths[i]< scrollLeft) {  // This line to step to partially visible columns
                isNotVisibleCol[i] = true
            }
        }
    }
    let nextColIndex = currentColIndex  // assumed return value
    while ( true ) {
        nextColIndex += deltaIndex
        // NO visible columns left (or right) of currentColIndex
        if ( nextColIndex < 0 || nextColIndex >= numColumns ) { 
            // exit path if user is at far left, going left,
            //                   or at far right, going right.
            // In these cases, we are ignoring the arrow key.
            return currentColIndex 
        }
        // But if we have a column to move to, then we exit here.
        if ( !isNotVisibleCol[nextColIndex] ) { 
            return nextColIndex 
        }
    }  
}


const moveSelection = (horz: number, vert:number, tCD: TableComputedData, activeFp: ActiveFp ): void => {
    const {fpName, primaryKey: currentPrimaryKey, secondaryKey: currentSecondaryKey, tertiaryKey: currentPinnedDataCellKey } = activeFp    
    const {sortedRowKeys, widthObj, heightObj, pinnedRowKeys, scrollLeft, scrollTop, derivedColOrder} = tCD

    // Table cells are ordered by Index:  Here we need to convert col/row keys of activeFp to their index.
    const currentColIndex = derivedColOrder.indexOf( currentPrimaryKey )    
    const currentRowIndex = ( currentPinnedDataCellKey === 'dataCell' ) ? sortedRowKeys.indexOf( currentSecondaryKey )
                                                                        : pinnedRowKeys.indexOf( currentSecondaryKey )
    // AFTER shifting the table position, we will need to do the opposite:  convert Indices to keys                                                                   

    const { movingAllocated, startColLeft, displayedColWidths, numLockedCols, isCombinedTable } = widthObj
    const { rowHeight, dataAllocated } = heightObj
    const sessionMods : LightweightMod[] = []
    // Case of Left/Right arrow keys:
    // horz means 'horizontal' arrow key. 
    // horz and vert possible values are: -1, 0, +1
    if (horz !== 0) {
        const nextColIndex = getNextVisibleColIndex( currentColIndex, horz, tCD )
        const leftEdge   = startColLeft[ nextColIndex ]
        const rightEdge  = leftEdge + displayedColWidths[ nextColIndex ]
        const isScrolledCol = isCombinedTable || currentColIndex >= numLockedCols
        const nextColKey = derivedColOrder[nextColIndex]
        sessionMods.push( {newVal:nextColKey, path: 'activeFp.primaryKey'})
        sessionMods.push( {newVal:nextColKey, path: 'activeStatsColKey'})
        // Potentially need new scrollLeft position ??
        if ( isScrolledCol && rightEdge > scrollLeft + movingAllocated ) {
            sessionMods.push({newVal:rightEdge-movingAllocated, path: 'activeTableScrollLeft'})
        }
        if ( isScrolledCol && leftEdge < scrollLeft ) {
            sessionMods.push({newVal:leftEdge, path: 'activeTableScrollLeft'})
        }
        // We need to set the fpName by the dataType of this column.
        //const {colDataType} = tCD.derivedColAttributesArray[nextColKey]
        const fpName = getTableFpNameFromColKey( nextColKey, tCD )
        sessionMods.push({newVal:fpName, path: 'activeFp.fpName'})
        sessionStateChangeDispatch( sessionMods, 'Step Cell Editor Location' )
        return
    }

    // Beyond this point, Case of up/down arrow keys
    const numPinnedRows = pinnedRowKeys.length
    let topEdge = 0
    let bottomEdge = 0
    // Assumptions: No change in fpName, primaryKey, secondaryKey, or tertiaryKey
    let newRowIndex = currentRowIndex
    let newPinnedDataCellKey = currentPinnedDataCellKey  
    let newFpName = fpName 

    // All cases of moving 'up' one row
    if ( vert === -1 ) { 
        // Define all 3 cases of moving to (or staying) on colHeader cell:
        if ( fpName === 'tableColHeader'  ||
            ( currentPinnedDataCellKey === 'pinnedCell' && currentRowIndex === 0 ) ||
            ( currentPinnedDataCellKey === 'dataCell'   && currentRowIndex === 0 && numPinnedRows === 0 )) {
            newFpName  = 'tableColHeader'
            newRowIndex = 0
        }
        // moving to next higher pinned row.
        else if ( currentPinnedDataCellKey === 'pinnedCell' ) {
            newRowIndex = currentRowIndex - 1
        }
        // Case of topmost dataRow and need to index to bottom of pinnedRows
        else if ( currentPinnedDataCellKey === 'dataCell' && currentRowIndex === 0 && numPinnedRows > 0 ) {
            newRowIndex = pinnedRowKeys.length - 1
            newPinnedDataCellKey = 'pinnedCell'
        }
        // Case of arbitrary data row moving to next higher dataRow
        else if ( currentPinnedDataCellKey === 'dataCell' && currentRowIndex > 0 ) {
            newRowIndex = currentRowIndex - 1
            topEdge = newRowIndex * rowHeight
            bottomEdge = (newRowIndex+1) * rowHeight
        }
    }

    // All cases of moving 'down' one row
    if ( vert === +1 ) { 
        // Case of moving from header to topmost pinnedRow
        // New need to choose the proper editor for this colDataType
        if ( fpName === 'tableColHeader' && numPinnedRows > 0 ) {
            newFpName = getTableFpNameFromColKey( currentPrimaryKey, tCD )
            newPinnedDataCellKey = 'pinnedCell'
            newRowIndex = 0
        }
        // Case of moving from 'head' to topmost data row.
        else if ( fpName === 'tableColHeader' && numPinnedRows === 0 ) {
            newFpName = getTableFpNameFromColKey( currentPrimaryKey, tCD )
            newPinnedDataCellKey = 'dataCell'
            newRowIndex = 0
            topEdge = 0
            bottomEdge = rowHeight
        }
        // Case of moving from pinnedRows to topmost data row.
        else if ( currentPinnedDataCellKey === 'pinnedCell' && currentRowIndex === numPinnedRows-1 ) {
            newRowIndex = 0
            newPinnedDataCellKey = 'dataCell'
            topEdge = 0
            bottomEdge = rowHeight
        }
        // Case moving down from a pinnedRow to a pinnedRow
        else if ( currentPinnedDataCellKey === 'pinnedCell' ) {
            newRowIndex = currentRowIndex + 1
        }
        // Case of arbitrary data row moving to next higher dataRow
        else if ( currentPinnedDataCellKey === 'dataCell' ) {
            newRowIndex = currentRowIndex + 1
            newRowIndex = Math.min( newRowIndex, sortedRowKeys.length - 1 )
            topEdge = newRowIndex * rowHeight
            bottomEdge = (newRowIndex+1) * rowHeight
        }
    }

    // Any change in the secondaryKey ( aka change in rowIndex/rowKey)
    if ( currentRowIndex !== newRowIndex ) {  
        const newRowKey = (newPinnedDataCellKey === 'dataCell') ? sortedRowKeys[newRowIndex]
                                                                : pinnedRowKeys[newRowIndex]
        sessionMods.push({ newVal: newRowKey, path: 'activeFp.secondaryKey' })
    }
    // Any change in the tertiaryKey ( aka change in pinnedDataCellKey)
    if ( currentPinnedDataCellKey !== newPinnedDataCellKey ) {                                                                 
        sessionMods.push({ newVal: newPinnedDataCellKey, path: 'activeFp.tertiaryKey' })
    }
    // Any change fpName ( aka change from tableColHeader to an number of cell editors.
    // Or change from any number of cellEditors to tableColHeader
    if ( fpName !== newFpName ) {                                                                 
        sessionMods.push({ newVal: newFpName, path: 'activeFp.fpName' })
    }
    // Potentially any need to move scrollTop position ??
    if ( newPinnedDataCellKey === 'dataCell' ) {
        if ( bottomEdge > scrollTop + dataAllocated ) {
        sessionMods.push({newVal: bottomEdge - dataAllocated, path: 'activeTableScrollTop'})
        }
        if ( topEdge < scrollTop ) {
        sessionMods.push({newVal: topEdge,                    path: 'activeTableScrollTop'})
        }
    }
    sessionStateChangeDispatch( sessionMods, 'Shift Cell Editor to adjacent cell' )
}