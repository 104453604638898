import type { Dispatch } from '@reduxjs/toolkit'
import type { ActiveFp, LightweightMod, Plot } from '../types'
import type { FpChildRenderProps, FpLayout } from '../appCode/getSbFpConfigMappings'

import { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getDefaultActiveFp } from '../types'
import { deletePlotThunk } from '../redux/plotThunks'
import { deleteThumbnail } from '../redux/thumbnailThunks'
import constants from '../sharedComponents/constants'
import Progress from '../sharedComponents/Progress'
import { sessionStateChangeDispatch } from '../sharedComponents/reactDispatch'
import { restorePointerEvents, wasSuspendPointerEventsSuccessful } from '../sharedFunctions/pointerEvents'
import { asyncDispatch } from '../sharedFunctions/utils'
import { TableComputedDataContext } from '../viewTableTriple/TtGetComputedData'
import { PlotXyComputedDataContext } from '../viewTableTriple/TtGetComputedDataPlotXy'

const TOTAL_HEIGHT = 402;
const TOTAL_WIDTH = 452;
const NUMERAL_INDENT = 10;
const INSTRUCTION_INDENT = 16;

const INSTRUCTION_WIDTH = TOTAL_WIDTH - INSTRUCTION_INDENT - 30;

// Every named FP must provide this function. It is called by the FpParent component.
// Specifically, the parent needs this set of information BEFORE it creates
// the FpParent, FpContainer, and internal content.
export const useGetPlotDelete: (activeFp: ActiveFp) => FpLayout = (activeFp) => {
    const tCD = useContext( TableComputedDataContext )

    return {
        activeFp,
        titleBarStrg: 'Delete Plot',
        legalDisplayedTabIndex: 0,
        isDraggable: true,
        tabInfoArr: [{
            tabIndex: 0,
            tabStrg1: 'placeholder',
            tabStrg2: 'placeholder',
            isErroneous: false,
        }],
        childInfoArr: [{
            childWidth: TOTAL_WIDTH,
            childHeight: TOTAL_HEIGHT,
            RenderedChild: NavColFp_PlotDelete,
        }],
        getSelectedCellGeometry: () => ({left: 0, top: 0, width: 0, height: 0, isCellVisible: false}), // No cyan highlight
    }
}

type LocalState = {
  isDeletingPlot: boolean
}

const POINTER_SUSPEND_NAME = 'Delete Plot'

const asyncDeleteAndCleanup = async (plot: Plot, dispatch: Dispatch, 
    setState: React.Dispatch<React.SetStateAction<LocalState>>
  ): Promise<void> => {
    try {
      await Promise.all([
        asyncDispatch(dispatch, deletePlotThunk(plot)),
        asyncDispatch(dispatch, deleteThumbnail(plot.id))
      ])  
    } finally {
      setState({isDeletingPlot: false})
      restorePointerEvents(POINTER_SUSPEND_NAME)
      const mods: LightweightMod[] = [{
          newVal: getDefaultActiveFp(),
          path: 'activeFp'
      }]
      sessionStateChangeDispatch(mods, 'Close Delete Plot')
    }
}

const NavColFp_PlotDelete: React.FC<FpChildRenderProps> = (props)=> {
    const tCD = useContext( TableComputedDataContext )
    const plotXyComputedData = useContext( PlotXyComputedDataContext )
    const dispatch = useDispatch()
    const [localState, setState] = useState<LocalState>({isDeletingPlot: false})

    if ( !tCD || !plotXyComputedData ) {
        return null
    }

    const plot = plotXyComputedData.plotResourceObj

    const deletePlotAndThumbnail = (): void => {
      if (localState.isDeletingPlot) {
        return
      }

      if ( !wasSuspendPointerEventsSuccessful(POINTER_SUSPEND_NAME) ) {
        return
      }

      asyncDeleteAndCleanup(plot, dispatch, setState)
    }

    const year = plot.attributes.createdDate?.slice(0,4)
    const title= `"${plot.attributes.mainTitle}"`
    const URL= `http://datatables/plot/${plot.id}`

    if (localState.isDeletingPlot) {
      return (
        <div className={"rc_DeletePlot"} style={{ fontSize: 14 }}>
          <div>Deleting Plot {title}</div>
          <Progress />
        </div>
      )
    }

    return (
        <div className={"rc_DeletePlot"} style={{ fontSize: 14 }}>
          <div className={"spacer"} style={{ height: 10 }} />
          <div
            style={{
              marginLeft: NUMERAL_INDENT,
              width: INSTRUCTION_INDENT,
              display: "inline-block",
              verticalAlign: "top",
            }}
          >
            {"1."}
          </div>

          <div style={{ width: INSTRUCTION_WIDTH, display: "inline-block" }}>
            {"Do you want to delete this plot for all users?\nThis cannot be undone."}
          </div>

          <div className={"spacer"} style={{ height: 6 }} />
          <div
            style={{
              marginLeft: NUMERAL_INDENT,
              width: INSTRUCTION_INDENT,
              display: "inline-block",
              verticalAlign: "top",
            }}
          >
            {""}
          </div>
          <div
            style={{
              width: INSTRUCTION_WIDTH,
              display: "inline-block",
              background: "rgb(255,231,240)",
            }}
          >
            {`Publisher's_Info, ${year}, ${title}, ` + URL}
          </div>
          <button className={"deleteButton"} onClick={deletePlotAndThumbnail}
            style=
            {{
              fontSize: 14,
              fontWeight: "bold",
              display: "block",
              height: 30,
              width: 90,
              position: "relative",
              left: 165,
              top: 10,
              borderRadius: 6,
              borderStyle: "outset",
              borderWidth: 2,
              borderColor: constants.COLHEADER_INPUT_BORDER_COLOR,
              verticalAlign: "center",
            }}>
            {"DELETE"}
          </button>
        </div>
    )
}
