//mport type { RootState }           from '../redux/store'  // my testPalette given access to sessionState
import type {FpLayout, FpChildRenderProps} from '../appCode/getSbFpConfigMappings'
import type {ActiveFp}             from '../types'

//import {useSelector}                from 'react-redux'
import {useContext}                 from 'react'
import {TableComputedDataContext}   from '../viewTableTriple/TtGetComputedData'
import {activeFpToTableViewTile}    from '../viewTable/getTableTile'
import EditColHeader             from '../fp_table_old/EditColHeader' 
import {TableFp_HeaderColRestore}   from './TableFp_HeaderColRestore'


const DEFAULT_WIDTH = 600
const DEFAULT_HEIGHT = 400


/* 
    RULES FOR WIDTH AND HEIGHT:

    1 - IFF (isResizableX===false) then each tab has its own fixed width 
    2 - IFF (isResizableX===true ) then width for all tabs is shared and can vary over minResizeX to maxResizeX.

        Same rules apply for height.
        So four possible combinations:
    1 - isResizableX=false, isResizableY=false  fully fixed size, potentially different for each tab.  
    2 - isResizableX=false, isResizableY=true   fixed width (by Tab), but height is shared and can vary.
    3 - isResizableX=true, isResizableY=false   fixed height (by Tab), but width is shared and can vary.
    4 - isResizableX=true, isResizableY=true    shared width and height, can vary.

        Up to you to design a layout that works within these rules.
*/

//const MARGIN_LR = 10    // Left and right margin
//const MARGIN_TB = 10    // Top and bottom margin
//const MAX_NUM_FILTERS = 8


export const useGetTableColHeader : (arg0:ActiveFp)=>FpLayout = (activeFp)=>{ 
    // ALL floating palettes within must use identical useContext and useSelector calls.
    // I believe this is true, even when changing views.  (Any change that would
    // allow the floatPalette to remain open between fpName changes or view changes)
    const tCD = useContext( TableComputedDataContext )
    //useSelector( (state:RootState) => state.session.fpStates_ByFpName['tableColHeader'] )
    const {canEdit, derivedColAttributesArray} = tCD
    const {primaryKey}= activeFp
    const isDepCol = derivedColAttributesArray[primaryKey].isDepCol
    const tab0 = {  
        tabIndex: 2,                               
        tabStrg1: 'Description', 
        tabStrg2 : '', 
        isErroneous: false,
    }
    const child0 = {
        childWidth: DEFAULT_WIDTH,  
        childHeight: DEFAULT_HEIGHT,  
        RenderedChild: DefaultChild
    }
    const tab1 = {  
        tabIndex: 0,      // MUST set this value, even when tabs are not displayed.             
        tabStrg1: 'Name, Type', 
        tabStrg2 : '', 
        isErroneous: false,
    }
    const child1 = {
        childWidth: DEFAULT_WIDTH,  
        childHeight: DEFAULT_HEIGHT,  
        RenderedChild: DefaultChild
    }
    const tab2 = {  
        tabIndex: 1,                            
        tabStrg1: 'Format',
        tabStrg2 : '', 
        isErroneous: false,
    }
    const child2 = {
        childWidth: DEFAULT_WIDTH,  
        childHeight: DEFAULT_HEIGHT,  
        RenderedChild: DefaultChild
    }
    const tab3 = {  
        tabIndex: 3,                           
        tabStrg1: 'Formula', 
        tabStrg2 : '', 
        isErroneous: false,
    }
    const child3 = {
        childWidth: DEFAULT_WIDTH,  
        childHeight: DEFAULT_HEIGHT,  
        RenderedChild: DefaultChild
    }
    const tab4 = {  
        tabIndex: 4,                                 
        tabStrg1: 'New, Delete', 
        tabStrg2 : '', 
        isErroneous: false,
    }
    const child4 = {
        childWidth: DEFAULT_WIDTH,  
        childHeight: DEFAULT_HEIGHT,  
        RenderedChild: TableFp_HeaderColRestore
    }

    const result = { 
        activeFp,     // To use this test palette, assign this function to the  'tableMainTitle' key in getFpLayout_ByFpName
        titleBarStrg: 'Column Header Editor', 
        isDraggable: true, 
        isResizableX: true,
        isResizableY: true,
        minResizeX: 300,
        minResizeY: 200,
        maxResizeX: 800,
        maxResizeY: 600,
        tabInfoArr: [tab0, tab1, tab2], 
        childInfoArr: [child0, child1, child2], 
        getSelectedCellGeometry: ()=> { return activeFpToTableViewTile(tCD) }
    }
    if (isDepCol) { 
        result.tabInfoArr.push(tab3)
        result.childInfoArr.push(child3)
    }
    if (canEdit) {
        result.tabInfoArr.push(tab4)
        result.childInfoArr.push(child4)
    }
    return result
}



const DefaultChild : React.FC<FpChildRenderProps> = ( props )=> {
    const tCD = useContext( TableComputedDataContext )
    const {activeFp} = props
    return (
        <EditColHeader
            table={tCD.table}
            tablelook={tCD.tablelook}
            tabledataid={tCD.tabledata.id}
            tableComputedData={tCD}
            colKey={activeFp.primaryKey}
            duplicateRowNames={tCD.duplicateRowNames}
            missingRowNames={tCD.missingRowNames}
        />
    )
}



