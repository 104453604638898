import type { InternalColDataType } from '../types'
import type { ArgCounts, ScryFormula, SubStringInfo } from './formulaTypes'

import constants                            from '../sharedComponents/constants'
import {findLegalCharCount}                 from '../sharedFunctions/measureText'
import {
  compOpToDisplayTextMap,
  functionNamesAndArgCountObject, 
  isComparisionOpCode,
  isFunctionOpCode,
  reservedFuncKeys,
  reservedIllegalPython
} from './formulaTypes'

const thinSpace = constants.thinSpaceChar
//import type { DerivedColAttributes } from '../computedDataTable/getDefaultTableComputedData'
//const lastVal = (a:[any]):any => a[ a.length-1 ]


const verifyExponentials = ( scryFormula:ScryFormula, lineNum:number ) :void => {

  // Objectives
  // 1. We throw an error if the user writes a 2nd level exponent.  (e.g. 7**2**3)
  //    (They can avoid this by using multi-line construction)
  // 2. This function also determines which set of characters are 'raised' in an exponent.

  // Which characters of the HTML display format are 'raised' ?  <sup> .... </sup>
  // For example:  a = 3**(2*b) - 4   We need to raise '(2*b)'
  // We wish to insert a zero length SubString called 'endExp' where the HTML
  // displayFormat will insert the equivalent of </sup> to drop the text.
  // In the example above, 'endExp' would immediately follow 'op_)'
  // This subString will have no length (startChar equals endChar).
  // And the subString is ignored at RPN creation.  The subString is NOT part
  // of the expression -- just a token necessary for creating the display format.

  // Consider:
  //      ... 3 ** unitySub anyValue nextOperator ...
  //      RAISED SUBSTRINGS: unitySub, anyValue
  //      IF nextOperator === 'op_**'   -- 2nd level exponentiation.  We will (by choice) set an error.
  //      IF nextOperator !== 'op_**'   -- MUST be lower precedence. Insert 'endExp'
  //      IF nextOperator is missing; Then end of expression; same as lower precedence;  Insert 'endExp'

  // Now consider:
  //      ... 3 ** ( 3/4 )           nextOperator
  //      ... 3 ** func( ... )       nextOperator
  //      ... 3 ** ( func(...) - 1 ) nextOperator
  //      same rules as above, but we raise everything in the balanced parens

  // Algorithm:
  //    At subString 'op_**' set isRaised === true.
  //    Move forward through subStrings to the 1st value, OR the completion of any balanced parens.
  //    if ( nextOperator exist && nextOperator is 'op_**' ) then set an error. (2nd level exponent)
  //    else nextOperator MUST be lower precedence OR does not exist.  Set isRaised = false. Insert 'endExp' subString.

  const insertionIndexArr = []  // may be more than one raised exponent in a expression.
  let isRaised = false  // becomes true after an exponentiation operator.
  let parensCounter = 0 // Counts only raised parens. greater than zero means some complex raised term

  const expr = scryFormula.formulaSubStrings[lineNum]
  for ( let index=1; index<expr.length; index++ ) {    // index === 0 is always the indent
      if ( scryFormula.errorID !== '' ) { break } // Early abort
      const {name,start,end} = expr[index]

      switch( true ) {

          case name === 'op_**' :
            if ( !isRaised ) { // this sets us to '1st level of exponentiation
              isRaised = true
              parensCounter = 0
            } else {  // already raised!  2nd level exponentiation is an error.
              scryFormula.highlightArray.push({lineNum, start, end})
              scryFormula.errorID = 'Two levels of exponentiation are difficult to read.<br>Please break expression into two lines.'
              insertionIndexArr.push ( index )
            }
            break

          case name.slice(0,4) === 'func':
          case name === 'op_(':
            if (isRaised) { parensCounter++}
            break

          case name === 'op_)':
            // Case where the close paren is paired with an open paren NOT part of exponent.
            if (isRaised && parensCounter === 0) {
              insertionIndexArr.push ( index )
              parensCounter = 0
              isRaised = false
            }
            // Case where the close paren is part of the exponent; Must be the 2nd case!
            if (isRaised && parensCounter > 0  ) { parensCounter--}
            break

          case name.slice(0,3) === 'op_' :   // All other ops except the 3 above
            if ( isRaised && parensCounter === 0 ) {
              insertionIndexArr.push ( index )
              isRaised = false
            }
            break
          case name === 'comment':
            if ( isRaised ) {
              insertionIndexArr.push ( index )
              isRaised = false
            }
            break
          default:
            // subString 'uniSub' falls through to here. It does not trigger raise or lower either way.
      }  // end of switch
  }      // end of forLoop over expr

  // We've run out of expression and still isRaised?
  // Then we append 'endExp' substring at end of expression.
  if ( isRaised ) {
    insertionIndexArr.push( expr.length )
  }
  // Error or not, we insert the 'endExp' subStrings.  The display format will
  // not be created, but useful to add them to formulaSubStrings for debug.
  // Insert them from highest index to lowest, as makes insertion easier.
  for (let i=insertionIndexArr.length-1; i>=0; i--) {
    const index = insertionIndexArr[i]
    const charPosition = expr[ index-1 ].end
    expr.splice(index, 0, {name:'endExp', text:'', start:charPosition, end:charPosition, value:0} )
  }
}

type Token = '' | 'op' | 'value' | 'openParen' | 'closeParen'

const verifyTokenSequence = ( scryFormula:ScryFormula, lineNum:number ) :void => {

  // Objectives:
  //    - Merge consecutive +/- operators.
  //    - Verify expression sequence is alternating value/op:  ( value/op/value/op/ ..... /value/op/value )
  //    - Identify and rename unary minus operators. ('op_-' tag changed to 'uniSub')
  //    - Identify and remove unary plus  operators.
  //    - Treat all 'not' as a unary operator.
  //    - Error checking.


  // Specific errors to check:
  //    - Missing operator - identified as two consecutive values
  //    - Missing value - two consecutive operators (consecutive +/- operators have been merged)
  //    - Dangling operator before expression - Expression cannot begin with an operator other than 'uniSub'
  //    - Dangling operator after expression - An expression MUST end with a value.
  //    - Misplaced '(' - Open  parens must have op    to its left, value to its right.
  //    - Misplaced ')' - Close parens must have value to its left, op    to its right.
  //    - Set of empty parens (or function with no arguments)    For example:  a=3+()  or: a=3+abs()
  //    - DOES NOT check for balanced parens.  Very different algorithm.

  // Rules
  //          - For this algorithm, think four general 'classes' of tokens:
  //    1)  value   --  varName, colName, setVarName, const...
  //    2)  op      --  token names starting 'op_'  (with parens and colon exceptions below)
  //    3)  ignored --  'indent', 'comment', 'emptySpace', 'flow...', 'op_:'
  //    4)  parens  --  'op_(',  'op_)', 'func...'
  //
  //    5)  Consider open/close parens.  They identify precedence order, HOWEVER they
  //        do not affect the value/op/value/op ...  sequence.  We can remove all
  //        open/close parens and it will not effect our error checking the sequence.
  //        Never-the-less, we will also track the open/close parens because it's a
  //        good opportunity to error check their placement.  A balanced parens
  //        check is more global and uses a different function/algorithm. (later)
  //
  //    6)  Consider the imaginary function:  identity( ....  )
  //        This function is mathematically equivalent to a set of parenthesis.
  //        When checking for errors of construction, we can assume the identity()
  //        function AND ALL FUNCTIONS, are the equivalent to a set of parenthesis.
  //        This is why the parens class includes:  'op_)', 'op_(', AND 'func...'
  //
  //     7) One can debate whether the comma 'op_,' is an operator or not.
  //        For this algorithm, we WANT to consider 'op_,' an operator, because
  //        it also fits the value/op/value/op/.... sequencing rules.
  //        So for sequencing purposes, a ',' is same as error checking '+' or '*'
  //     8) The colon  'op_:' is a python operator.  However we are not supporting
  //        python's ternary if construction.  Therefore, a colon is only valid
  //        at the end of a 'flow..' expression ( if, else, elif ).  This function
  //        will ignore 'op_:'.  It is easier to error test colons during verification of flow keywords.
  //     9) Before setting an error for consecutive operators, we check
  //        whether they are +/- operators which can be merged. They can
  //        only be merged if the subStrings are immediately adjacent, meaning
  //        there is no parens class token sitting between them.
  //     10) Before setting an error for consecutive operators, we check
  //         whether the 2nd operator is +/-, in which case it is a unary +/-.
  //         We retag unaryPlus as empty space.  We retag unaryMinus as 'uniSub'.
  //     11) Consecutive operators NOT covered by above two exceptions are an error.
  //     12) An expression can start with 'op_-', which is tagged 'uniSub'.
  //         And expression can start with 'op_not' which is always unary.
  //         Otherwise an expression starting with an operator is an error.
  //     13) An expression ending with an operator is an error.
  //     14) Two consecutive values are always an error. (no parens exceptions like for operators)
  //     15) Open Parens (and Functions) can start a sequence, or must fall between
  //         an operator on the left and value on the right.  Otherwise error.
  //         Since easiest to check for op on left or start of expr, that is the algorithm's test.
  //         Note: 1 OR MORE open parens can fit between a op/value   (e.g.  op/openParen/openParen/value)
  //     16) Close Parens can end a sequence, or must fall between
  //         a value on the left and operator on the right.  Otherwise error.
  //         Since easiest to check for value on left or end of expr, that is the algorithm's test.
  //         Note: 1 OR MORE close parens can fit between a value/op  (e.g.  value/closeParen/closeParen/op)
  //     17) Adjacent 'op_(' and 'op_)' will fail because one or more of the following:
  //            - open paren not between op on left and value on right.
  //            - close paren not between value on left and op on right.
  //            - two consecutive ops (missing value)
  //         However, we'll check for this special case specifically so we can
  //         flag the error with something more direct: 'Set of empty parens not allowed.'
  //     18) This algorithm may create a 'uniPlus' tag name, but ONLY temporarily.  Any 'uniPlus'
  //         token is converted to 'emptySpace' before exiting this function.
  //
  //     19) Debugging this module.  One needs to test each thisSubString.name against all preceding
  //         tokens.  All the permutations are listed explicitly and it's quick to walk through
  //         the list.  HOWEVER, if you edit any line of code, you better restart your testing
  //         from the beginning!  Fortunately, errors are easy to debug. Unfortunately, every
  //         code change, no matter how simple and obvious, does propagate to the next
  //         set of permutations.  And assuming the next set of permuations 'should' be OK
  //         is not a good idea.  (from experience.)



  // ALGORITHM STARTS HERE:

  // Next variables hold the 3 most recent subStrings:
  // thisSubString - The current token being processed
  // subStrings[1] - The prior Token
  // sequence[1]   - The prior Token class:   ''|'op'|'value'|'openParen'|'closeParen'
  // subStrings[0] - The prior prior Token
  // sequence[0]   - The prior prior Token class:   ''|'op'|'value'|'openParen'|'closeParen'

  let thisSubString : SubStringInfo
  let lastSubString : SubStringInfo
  let subStrings    : SubStringInfo[] = []
  let sequence      : Token[] = []

  // Function to set the priorToken and priorPriorToken:
  const pushNewToken = ( token: Token ): void => {
    subStrings.shift()
    sequence.shift()
    subStrings.push( thisSubString )
    sequence.push( token )
  }

  // This function converts a substring into empty space. (erases the token)
  // Used when managing legal sequences like:  a ++ - - + b
  const erase = ( subString: SubStringInfo ) : void => {
    subString.text = ' '.repeat( subString.text.length )
    subString.name = 'emptySpace'
  }

  // 'Flips' the subString name and text for sequential plus/minus operators:
  const flipSign = ( subString: SubStringInfo) : void => {
    if ( subString.name === 'op_-' || subString.name === 'uniSub' ) {
      subString.text =  subString.text.replace( '-', '+' )
      subString.name = (subString.name === 'op_-' ) ? 'op_+' : 'uniPlus'
    }
    else if ( subString.name === 'op_+' || subString.name === 'uniPlus' ) {
      subString.text =  subString.text.replace( '+', '-' )
      subString.name = (subString.name === 'op_+' ) ? 'op_-' : 'uniSub'
    }
  }


  // Initialize to the 'indent' subString.
  const expr = scryFormula.formulaSubStrings[lineNum]
  thisSubString = expr[0]  // Always an 'indent'

  // lastSubString almost always equals subStrings[1].
  // Exception being the 'op_not' which are not pushed onto
  // the subStrings or sequence arrays.  The 'not' operation
  // is considered neither an operator nor a value in this function,
  // as the primary goal of the entire function is to verify an
  // value,op,value,op,value, ...   sequence.
  // Yet we will want to know whether there are two 'not' operations
  // in a row.  Hence, we can check lastSubString, rather than subStrings[1]
  lastSubString = expr[0]
  subStrings  = [ thisSubString, thisSubString ]
  sequence    = [ '', '' ]

  const token1name = expr[1] ? expr[1].name : ''
  const isFlowStatement = ( token1name==='flowif' || token1name ==='flowelse' || token1name==='flowelif' )

  for ( let index=1; index<expr.length; index++ ) {  // index 0 is always the indent
      if ( scryFormula.errorID !== '' ) { break } // Early abort
      lastSubString = thisSubString
      thisSubString = expr[index]
      const name = thisSubString.name

      switch( true ) {

          // values Class
          case name === 'varName':
          case name === 'setVarName':
          case name === 'colName':
          case name.slice(0,5) === 'const':
                // follows ''      -- natural case - pushNewToken
                // follows op_+    -- natural case - pushNewToken
                // follows op_-    -- natural case - pushNewToken
                // follows uniSub  -- natural case - pushNewToken
                // follows uniPlus -- natural case - pushNewToken
                // follows op_...  -- natural case - pushNewToken
                // follows value   -- Missing operator
                // follows op_)    -- Missing operator
                // follows op_(    -- natural case - pushNewToken

                if ( sequence[1] === 'value' || sequence[1] === 'closeParen' ) {
                  // 1st check for improper use of 'value x value'  (bad multiply sign)
                  if ( thisSubString.text.match( /^ *[xX][\d.]/ )) {
                    scryFormula.errorID = `'${thisSubString.text[0]}' is not a valid operator.<br>Use ' * ' (asterisk) for multiplication.`
                    scryFormula.highlightArray = [{ lineNum, start:thisSubString.start, end:thisSubString.start+1}]
                  }
                  else if ( thisSubString.text[0] === 'x' || thisSubString.text[0] === 'X' ) {
                    scryFormula.errorID = `'${thisSubString.text[0]}' is not a valid operator.<br>Use ' * ' (asterisk) for multiplication.`
                    scryFormula.highlightArray = [{ lineNum, start:thisSubString.start, end:thisSubString.start+1}]
                  } else {
                    scryFormula.errorID = `Expected some operation ( -, +, /, ...) between<br>'${subStrings[1].text}' and '${thisSubString.text}'`
                    scryFormula.highlightArray = [{ lineNum, start:subStrings[1].start, end:thisSubString.end}]
                  }
                } else {
                  pushNewToken( 'value' )
                }
                break

          // open Parens
          case name === 'op_(':
          case name.slice(0,4) === 'func':
                // follows ''      -- natural case - pushNewToken
                // follows op_+    -- natural case - pushNewToken
                // follows op_-    -- natural case - pushNewToken
                // follows uniSub  -- natural case - pushNewToken
                // follows uniPlus -- natural case - pushNewToken
                // follows op_...  -- natural case - pushNewToken
                // follows value   -- Missing operator
                // follows op_)    -- Missing operator
                // follows op_(    -- natural case - consecutive open Parens; do nothing
                if ( sequence[1] === 'openParen' ) {
                  // do nothing. A sequence of two open parens is same as one open paren
                  // DO NOT push pushNewToken.
                  // However, update prior token's start/end positions
                  subStrings[1].start = expr[index].start
                  subStrings[1].end = expr[index].end
                } else if ( sequence[1] === 'value' || sequence[1] === 'closeParen') {
                  scryFormula.errorID = `Expected an operation (-,+,/, ...) between '${subStrings[1].text}' and '${thisSubString.text}'`
                  scryFormula.highlightArray = [ {lineNum, start:subStrings[1].start, end:thisSubString.end } ]
                } else {
                  pushNewToken( 'openParen' )
                }
                break


          case name === 'op_)':
                // follows ''      -- error - Can't start expression with ')'
                // follows '',op_+ -- error - Can't start expression with '+)'
                // follows '',op_- -- error - Can't start expression with '-)'
                // follows op_+    -- error - Missing Value
                // follows op_-    -- error - Missing Value
                // follows uniSub  -- error - Missing Value
                // follows uniPlus -- error - Missing Value
                // follows op_...  -- error - Missing Value
                // follows value   -- natural case
                // follows op_)    -- natural case - consecutive close Parens; do nothing
                // follows op_(    -- error - Empty parens not allowed
                if ( sequence[1] === 'closeParen' ) {
                  // do nothing. A sequence of two close parens is identical to one close paren
                  // DO NOT push pushNewToken.
                  // However, update prior token's start/end positions
                  subStrings[1].start = expr[index].start
                  subStrings[1].end = expr[index].end
                } else if ( sequence[1] === '' ||
                           (sequence[0] === '' && subStrings[1].name === 'uniSub')  ||
                           (sequence[0] === '' && subStrings[1].name === 'uniPlus')  ) {
                  scryFormula.errorID = `Can not start an expression with ')', '+)', or '-)'`
                  scryFormula.highlightArray = [ {lineNum, start:subStrings[1].start, end:thisSubString.end } ]
                } else if ( sequence[1] === 'openParen' ) {
                  scryFormula.errorID = `Illegal set of empty parenthesis or empty function.`
                  scryFormula.highlightArray = [ {lineNum, start:subStrings[1].start, end:thisSubString.end } ]
                } else {
                  pushNewToken( 'closeParen' )
                  if ( sequence[0] === 'op') {
                    scryFormula.errorID = `Expected a value between '${subStrings[0].text}' and '${thisSubString.text}'`
                    scryFormula.highlightArray = [ {lineNum, start:subStrings[0].start, end:thisSubString.end } ]
                  }
                }
                break



          case name === 'op_:':
                // If this is a if, elif, else flow Statement, just ignore the colon
                // The code for these flow statements will manage the colon
                // If this is some other expression, a colon is considered unregocnized character
                if ( !isFlowStatement ) {
                  scryFormula.errorID = `' : ' operator is only valid at the end of if,else,elif statements.`
                  scryFormula.highlightArray = [{lineNum, start:thisSubString.start, end:thisSubString.end  }]
                }
                break


          case name === 'op_+':
                // follows ''      -- 'uniPlus'  // temporary for later error testing
                // follows op_+    -- erase
                // follows op_-    -- erase
                // follows uniSub  -- erase
                // follows uniPlus -- erase
                // follows op_...  -- erase
                // follows value   -- natural case - pushNewToken
                // follows op_)    -- natural case - pushNewToken
                // follows op_(    -- erase
                if ( sequence[1] === '' ) {
                  thisSubString.name = 'uniPlus'
                  pushNewToken( 'op' )
                } else if ( sequence[1] === 'value' || sequence[1] === 'closeParen' ) {
                  pushNewToken( 'op' )
                } else {
                  erase( thisSubString )
                }
                break


          case name === 'op_-':
                // follows ''      -- convert to 'uniSub'
                // follows op_+    -- merge (flip sign; erase)
                // follows op_-    -- merge
                // follows uniSub  -- merge
                // follows uniPlus -- merge
                // follows op_...  -- convert to 'uniSub'
                // follows value   -- natural case - pushNewToken
                // follows op_)    -- natural case - pushNewToken
                // follows op_(    -- convert to 'uniSub'
                if (subStrings[1].name === 'op_-'   ||
                    subStrings[1].name === 'op_+'   ||
                    subStrings[1].name === 'uniSub' ||
                    subStrings[1].name === 'uniPlus' ) {  flipSign(subStrings[1]); erase(thisSubString) }
                else if ( sequence[1] === '' || sequence[1] === 'op' || sequence[1] === 'openParen' ) {
                  thisSubString.name = 'uniSub'
                  pushNewToken( 'op' )
                } else {
                  pushNewToken( 'op' )
                }
                break

          case name === 'op_not':
                // follows ''      -- natural case - but don't push pushNewToken (neither a 'op' or 'value')
                // follows op_+    -- natural case - but don't push pushNewToken (neither a 'op' or 'value')
                // follows op_-    -- natural case - but don't push pushNewToken (neither a 'op' or 'value')
                // follows uniSub  -- natural case - but don't push pushNewToken (neither a 'op' or 'value')
                // follows uniPlus -- natural case - but don't push pushNewToken (neither a 'op' or 'value')
                // follows op_...  -- natural case - but don't push pushNewToken (neither a 'op' or 'value')
                // follows value   -- Missing operator
                // follows op_)    -- Missing operator
                // follows op_(    -- natural case - but don't push pushNewToken (neither a 'op' or 'value')
                // follows op_not  -- erase the two consecutive not operators
                if ( sequence[1] === 'value' || sequence[1] === 'closeParen' ) {
                  scryFormula.errorID = `Expected some operation ( -, +, /, ...) between<br>'${subStrings[1].text}' and '${thisSubString.text}'`
                  scryFormula.highlightArray = [{ lineNum, start:subStrings[1].start, end:thisSubString.end}]
                }
                if ( lastSubString.name === 'op_not' ) {
                  erase( lastSubString )
                  erase( thisSubString )
                }
                // DO NOT push 'not' operators onto the subStrings[] or sequence[]  arrays.
                // 'not' is considered neither a binary operator nor a value.
                break

          // All other operators -- Excluding :  +   -   :   (    )   not
          case name.slice(0,3) === 'op_':
                // follows ''      -- Error - Can't start an expression with a binary operator
                // follows op_+    -- Error - Two consecutive operators (Missing value)
                // follows op_-    -- Error - Two consecutive operators (Missing value)
                // follows uniSub  -- Error - Two consecutive operators (Missing value)
                // follows uniPlus -- Error - Two consecutive operators (Missing value)
                // follows op_...  -- Error - Two consecutive operators (Missing value)
                // follows value   -- natural case - pushNewToken
                // follows op_)    -- natural case - pushNewToken
                // follows op_(    -- Error - Two consecutive operators (Missing value)
                if ( sequence[1] === 'value' || sequence[1] === 'closeParen' ) {
                  pushNewToken( 'op' )
                }
                else if ( sequence[1] === '' && scryFormula.errorID === '' ) {
                  scryFormula.errorID = 'Expect expressions to start with a value'
                  scryFormula.highlightArray = [{lineNum, start:thisSubString.start, end:thisSubString.end  }]
                }
                else {   // lastOpOrValue === 'op'
                  scryFormula.errorID = `Expected a value between ' ${subStrings[1].text} ' and ' ${thisSubString.text} '`
                  scryFormula.highlightArray = [{ lineNum, start:subStrings[1].start, end:thisSubString.end  }]
                }
                break;

          // ignore class;  Everything else
          default:
        }
    }   // End of for Each SubString

    // Last token should be a 'value'
    if ( sequence[1] === 'op' && scryFormula.errorID === '' ) {
      scryFormula.errorID = `Expected a value at end of expression.`
      scryFormula.highlightArray = [{ lineNum, start:subStrings[1].start, end:thisSubString.end  }]
    }

    // Clean-up pass to remove any 'uniPlus' tokens
    expr.forEach( thisToken => {
      if (thisToken.name === 'uniPlus') {
        thisToken.name = 'emptySpace'
        thisToken.text = thisToken.text.replace( '+', ' ' )
      }
    })
}

const verifyBalancedParen = ( scryFormula:ScryFormula, lineNum:number ) :void => {

  // Objectives:
  //   1. ONLY verifies the count of open and close parens.  They must be
  //       balanced (aka equal count).
  //   2. If an unequal count, highlight the open/close parens at fault.
  //
  // Rules:
  //   1. Only in certain cases can we narrow down 'which' part of
  //        the expression is at fault.
  //   2. In that section of the expression at fault, we highlight
  //        each open/close paren in red.
  //   3. Like some other verify functions, 'func...' is equivalent to 'op_('.
  //   4. Imagine an expression with a 'missing ('.  We can likewise say it
  //      may have an 'extra )'.  We cannot tell the difference.   However,
  //      the algorithm works best to search for a 'missing ('.  But the error
  //      message should report 'missing ('   OR   'extra )'.
  //   5. To narrow down that part of the expression that may have a 'missing ('
  //      start at the left, count parens (+1 for open Parens, -1 for close Parens.)
  //      If/when the count < 0, then we know there is a 'missing (' to the left
  //      of this point.   Highlight all open/close parens (including functions)
  //      to the left of this point.
  //   6. To find a 'missing (', start at right side of expression, count parens
  //      ( +1 for close Parens, -1 for open Parens.)  If/when the count < 0, then
  //      we know we have a 'missing (' to the right of that point.  Highlight
  //      all parens to the right in red.  Again, error message reports the
  //      general failure; which in this case is 'missing ('   OR    'extra )'.
  //
  //   7. There is another case where the algorithm could further narrow down
  //      the range within an expression where the error may be located.
  //      I'll describe here, but it is not currently worth doing.
  //   8. Imagine the syntax   a = max( 1*(b+c), . . .
  //      Assume an extra or missing parenthesis in the first argument of
  //      the min function.  For example:  1*(b+c     or   1*b+c)
  //   9. We know the parens for the first arg must be balanced.  So we can
  //      assume the range of the potential error is the first arg.  And only
  //      highlight that area.
  //  10. This can extended to each function argument, except the last.  This
  //      because the 'op_,' operator gives us additional information.
  //        -Between any commas within the same function, parens must be balanced.
  //        -Between 'func...' and the first 'op_,' parens must be balance.
  //        -However, does not work for the last argument.  Because we have no
  //         additional information to tell us where to stop counting close parens.
  //  11. We currently only have three functions with more than one arg:
  //          max, min, atan2
  //      Hence unlikely error, that can be caught in only three functions.
  //      Plus the code needed to refine our range of error is messy because
  //      it needs to be recursive, for functions args containing functions.
  //      Hence, not worth the effort in foreseeable future.
  const expr = scryFormula.formulaSubStrings[lineNum]
  let parensCounter = 0
  let highlightArray = []
  // start from left, move right. checking for extra closeParen
  for (let i=1; i<expr.length; i++) {
    const {name, start, end} = expr[i]
    if (name === 'op_(' || name.slice(0,4) === 'func') {
      parensCounter++
      highlightArray.push({lineNum, start, end})
    }
    if (name === 'op_)') {
      parensCounter--
      highlightArray.push({lineNum, start, end})
    }
    if (parensCounter < 0) {
      scryFormula.errorID = `Unbalanced Parens.  Missing ' ( ' or extra ' ) ' `
      scryFormula.highlightArray = highlightArray
      break
    }
  }
  if (scryFormula.errorID !== '' ) return  // Early abort


  // start from right, move left. checking for extra openParen
  parensCounter = 0
  highlightArray = []
  for (let i=expr.length-1; i>=1; i--) {
    const {name, start, end} = expr[i]
    if (name === 'op_)' ) {
      parensCounter++
      highlightArray.push({lineNum, start, end})
    }
    if (name === 'op_(' || name.slice(0,4) === 'func') {
      parensCounter--
      highlightArray.push({lineNum, start, end})
    }
    if (parensCounter < 0) {
      scryFormula.errorID = `Unbalanced Parens.  Extra ' ( ' or missing ' ) '`
      scryFormula.highlightArray = highlightArray
      break
    }
  }

  if (scryFormula.errorID !== '' ) return  // Early abort

  // Third pass:
  // At this time we know we have balanced parens.
  // Look for comma 'op_,' outside of a set of parens
  parensCounter = 0
  for (let i=1; i<expr.length; i++) {
    const {name, start, end} = expr[i]
    if (name === 'op_(' || name.slice(0,4) === 'func') {
      parensCounter++
      highlightArray.push({lineNum, start, end})
    }
    if (name === 'op_)') {
      parensCounter--
      highlightArray.push({lineNum, start, end})
    }
    if (name === 'op_,' && parensCounter === 0 ) {
      scryFormula.errorID = `Comma ',' only valid inside parenthesis to separate arguments.`
      scryFormula.highlightArray = [{lineNum, start, end}]
      break
    }

  }


}


const verifyColType_selfRef = (scryFormula:ScryFormula, lineNum:number, colKey:number, 
                                colTitleArr: string[], internalDataTypeArr: InternalColDataType[] ) :void => {
    // Objective:
    //     A formula cannot reference its own value for use in expressions.
    //     (The column being calculated cannot depend upon its own current values -- circular reference)
    //
    //     We only support colNames of internalDataType === numbers at this time (no string or hyperlinks)
    //     There are 3 number subtypes.  So check the dataType using derivedColAttributes.internalDataType

    const expr = scryFormula.formulaSubStrings[lineNum]
    for (let i=1; i<expr.length; i++) {
        // Checking for self-reference against an individual colKey:
        if ( expr[i].name === 'colName' && expr[i].value === colKey ) {
            const name = thinSpace + colTitleArr[colKey] + thinSpace
            scryFormula.errorID = `Cannot calculate the column named '${name}',<br>from a formula that also uses  '${name}'.`
            scryFormula.highlightArray = [{lineNum, start:expr[i].start, end:expr[i].end}]
            break
        }
        if ( expr[i].name === 'colName' ) {
            const opCodeColKey = Number( expr[i].value )
            const internalDataType = internalDataTypeArr[opCodeColKey]
            const opCodeColName = colTitleArr[opCodeColKey]
            if ( internalDataType !== 'number' ) {
                const type = thinSpace + internalDataType + thinSpace
                scryFormula.errorID = `Columns with formulas must be DataType ' Number ' at this time.<br>` +
                `Column ' ${opCodeColName} ' is DataType: ${type}.`
                scryFormula.highlightArray = [{lineNum, start:expr[i].start, end:expr[i].end}]
                break
            }
        }
    }
}


const verifyVarNameNotReservedPython = ( scryFormula:ScryFormula, lineNum:number ) :void => {

  // Objective:
  //    1) Don't allow varNames that match a list a reservedIllegalPython
  //         examples: 'for', 'global', 'while', ...
  //    2) Provide a teaching error message that says "I see you are trying
  //       to intentionally (or non-intentionally) write python that we
  //       do not currently support.  We may or may not choose to support
  //       this keyword in the future.  But either way, we don't want
  //       python keywords recognized and used as var names.

  // Rules:
  //    1) From a list of all python keywords, we break into two groups.
  //       What we currently support, and what remains.
  //           reservedKeywords = [ 'if', 'else', 'elif', 'pass', 'None', 'return', True, False, pi, ]
  //           reservedIllegalPython = [ 'for', 'in', 'is', 'try', 'while', ... ]
  //       We just check each varName value (forced to lower case) to see if it matches our illegal list.

  const expr = scryFormula.formulaSubStrings[lineNum]
  for (let i=1; i<expr.length; i++) {
    const {name, start, end, value} = expr[i]
    if ( !(name === 'varName' || name === 'setVarName')) continue
    const varName = String(value).toLowerCase()
    if ( reservedIllegalPython.indexOf( varName ) >= 0 ) {
      scryFormula.errorID = `Python keyword '${value}' is not currently supported.`
      scryFormula.highlightArray.push({lineNum, start, end})
      break
    }
  }
}



const verifyFuncArgCount = ( scryFormula:ScryFormula, lineNum:number ) :void => {

  // Objectives:
  //  1. The list of supported functions is found in the types module.
  //     For each function we have specied the number of required args
  //     and number of optional args.  The data structure looks like so:
  //
  //         export const functionObject = {
  //              abs:  [1,0],     // name : [ requiredArgs, optionalArgs ]
  //              max:  [2,100],   // variable length
  //              min:  [2,100],   // variable length
  //              atan2 [2,0],
  //              ceil: [1,0],
  //
  //     Verify: requiredArgs <= expressionArgs <= requiredArgs + optionalArgs

  // Rules:
  //   1. We assume parens are balanced.  This check MUST follow the balanced parens checker.
  //   2. We assume the token sequence ( value,op,value, ... ) is valid.
  //      This check MUST follow the token sequence checker.
  //   3. Walk each expression, by token, from left to right.
  //      Pause at each 'func...' token.  Call a helper 'look-ahead'
  //      function.  This helper function will count the arguments.
  //   4. Look-ahead function begins at the 'func...' token. We set the
  //      parens counter to +1 where counter++ is each 'op_(' or 'func...'.
  //      And counter-- for each 'op_)'.  Walk to the right of the current function
  //      until the parens counter = 0.   This is the close paren for that function!
  //      The look-ahead can stop looking-ahead when parens counter reaches zero.
  //   5. Look-ahead will also count the 'op_,' along the way.  However, any given
  //      comma MAY be owned by the current function being verified.  However,
  //      a function may have nested functions, and these nested functions may
  //      also have commas.  We ONLY want to count commas that belong to
  //      the current function being verified.
  //   6. Increment the comma counter IFF the current parens counter === 1
  //      If parens counter > 1, then the comma belongs to a nested function.
  //      This rule ONLY works if we have previously verified the op sequence
  //      and balanced parens.
  //   7. The number of arguments for the current function is the comma counter
  //      plus one.  We add one for the last argument, when the parens counter
  //      goes to zero.
  //   8. Error if: requiredArgs <= commaCount+1 <= requiredArgs + optionalArgs.
  //   9. Python syntax:  x,y = (3,4) is valid.   However, we do not support
  //      the definition of the tuple on the right( tuple definition), nor
  //      the destructuring of the tuple on the left (tuple assignment).
  //      The verifyAssignment function understands the left side of '=' and
  //      will catch 'tuple assignment'.  We need to error check for the
  //      'tuple definition', and the best time is same time we count func args.
  //  10. If we treat the open paren as a function, then we simply need to
  //      verify that 'function open paren' has one arg.  Of course, if it does
  //      not, we will want a custom error message. We don't need to worry about
  //      zero args.  That is caught in the verifyTokenSeqence check.


  //  Rules for embedding the argument count into the token:

  //   11. Only one function round() supports an optional arg.  For now I choose
  //       to simple replace the twoArg version with the round2(x,int) function name.
  //   12. The min/max functions take variable length args. Hence the arg
  //       count should be embedded with the token, then eventually the opCode.
  //       So we set the funcmin.value (or funcmax.value) to the numArgs.
  //       ( Also, argCount could be put on the stack, but any later optimization
  //       code would want to keep it off the stack and embed it with the
  //       function opcode. )
  //   12. Also, we have min(), max() functions that accept up to 100 args.
  //       The others all have a fixed arg count. Hence nothing needs to be
  //       encoded with the token if the arg count is fixed.

  //  Rule for a misplaced (or likely misused) comma.  We reserve the comma
  //  operator as a separator between func arguments.  It CANNOT also be used
  //  as a separator in long numbers ( e.g. 2,432,108.6 ).  Therefore, this
  //  application (in all code) uses '.' as the radix operator (decimal pt)
  //  and DOES NOT allow the comma to be used in numbers -- but only as the
  //  separator between function arguments.

  //  13. Throw an error for the 'op_,'  (comma operator) if it appears outside
  //      a pair of parens.  We know we are 'outside' by counting the cumulative
  //      number of '(' minus ')'.   We assume parens are already balanced,
  //      hence any 'op_,' substring when the cum paren count === 0 is a
  //      comma outside a set of parenthesis.


  const expr = scryFormula.formulaSubStrings[lineNum]
  for (let i=1; i<expr.length; i++) {
    const {name, start, end} = expr[i]
    if ( !(name.slice(0,4) === 'func' || name === 'op_(') ) continue

    // number args testing code begins here
    const highlightArray = []   // We build this on the fly.  Just-in-case we error!
    highlightArray.push({lineNum,start,end})
    let funcName: string
    let requiredArgs: number
    let optionalArgs: number
    if ( name === 'op_(' ) {
      funcName = 'openParen'
      requiredArgs = 1
      optionalArgs = 0
    } else {  // This is some named function
      funcName = name.slice(4)
      if (!isFunctionOpCode(name)) {
        scryFormula.errorID = `Unexpected function name ${funcName}. Supported functions are: ${reservedFuncKeys.join(', ')}`
        scryFormula.highlightArray = highlightArray
        break
      }

      const argCounts: ArgCounts = functionNamesAndArgCountObject[name]
      requiredArgs = argCounts[0]
      optionalArgs = argCounts[1]
    }

    // Lookahead function begins here:
    let parensCounter = 1
    let commaCounter  = 0
    for (let j=i+1; j<expr.length; j++) {
      const {name:name2, start, end} = expr[j]
      if ( name2 === 'op_(' || name2.slice(0,4) === 'func') {
        parensCounter++
      } else if ( name2 === 'op_,' && parensCounter === 1 ) {
        commaCounter++
        highlightArray.push({lineNum,start,end})
      } else if ( name2 === 'op_)' ) {
        parensCounter--
        if ( parensCounter === 0 ) {
          highlightArray.push({lineNum,start,end})
          break
        }
      }
    }
    // Lookahead function ends here.
    if ( funcName === 'openParen' && commaCounter > 0 ) {
      scryFormula.errorID = `Unexpected comma separator.<br>Python tuple syntax not supported.`
      scryFormula.highlightArray = highlightArray
      break
    }
    if ( commaCounter + 1 < requiredArgs ) {
      scryFormula.errorID = `${funcName} function requires at least ${requiredArgs} arguments`
      scryFormula.highlightArray = highlightArray
      break
    }
    if ( commaCounter + 1 > requiredArgs + optionalArgs ) {
      scryFormula.errorID = `${funcName} function must not exceed ${requiredArgs+optionalArgs} arguments`
      scryFormula.highlightArray = highlightArray
      break
    }
    if ( funcName === 'min' || funcName === 'max' || funcName === 'round') {
      expr[i].value = commaCounter + 1
    }
  }

}


const verifyKeywordExpr = ( scryFormula:ScryFormula, lineNum:number ) :void => {

  // Objectives
  //    - 'if' 'elif' 'else' and 'pass' require additional error checks.
  //    - operator 'op_:' requires additional error checks.
  //    - Warnings on wrong case (upper/lower) for flow and const tokens.
  const expr = scryFormula.formulaSubStrings[lineNum]
  let lastTokenIndex = expr.length - 1
  if    ( expr[lastTokenIndex].name === 'comment' )    { lastTokenIndex-- }
  while ( expr[lastTokenIndex].name === 'emptySpace' ) { lastTokenIndex-- }
  const isFlowExpr = ( expr[1].name.slice(0,4) === 'flow' )
  const exprKey    =   expr[1].name.slice(4)
  const isIfExpr   = ( exprKey === 'if' )
  const isElseExpr = ( exprKey === 'else' )
  const isElifExpr = ( exprKey === 'elif' )
  const isPassExpr = ( exprKey === 'pass' )
  if (isFlowExpr && expr[1].text.trim() !== exprKey ) {
    scryFormula.warningID = `WARNING: ' ${expr[1].text.trim()} ' saved as Python: ' ${exprKey} '.`
  }

  for (let i=1; i<expr.length; i++) {
    const {name, text, start, end } = expr[i]   // Current Substring
    if ( scryFormula.errorID !== '' ) break

    const isConstToken = (
      name === 'constTrue' ||
      name === 'constFalse' ||
      name === 'constpi' )

    const isAndOrNotOperator = (
      name === 'op_and' ||
      name === 'op_or'  ||
      name === 'op_not' )

    // Warning for constKeyWords using the wrong case:
    if ( isConstToken ) {
      const constName = name.slice(5)
      if (constName !== text.trim()) {
         scryFormula.warningID = `WARNING: ' ${text.trim()} ' saved as Python: ' ${constName} '.`
      }
    }

    // Warning for 'None' using the wrong case:
    if ( name === 'None' ) {
      if (name !== text.trim()) {
         scryFormula.warningID = `WARNING: ' ${text.trim()} ' saved as Python: 'None'.`
      }
    }

    // Warning for and/or/not using the wrong case:
    if ( isAndOrNotOperator ) {
      const opName = name.slice(3)
      if (opName !== text.trim()) {
         scryFormula.warningID = `WARNING: ' ${text.trim()} ' saved as Python: ' ${opName} '.`
      }
    }
    // A colon token anywhere but if, else, elif is illegal
    // This error was already caught in the token sequence evaluation.
    // So it should never trigger here unless we change order of verifications.
    if ( name === 'op_:' && !(isIfExpr || isElseExpr || isElifExpr )) {
      scryFormula.errorID = "Unexpected colon ' : ' operator. (please delete)"
      scryFormula.highlightArray = [{lineNum, start, end}]
      break
    }
    // A colon in a IfElseElif line NOT at the end of the
    if ( scryFormula.errorID === '' && i !== lastTokenIndex && name === 'op_:' && (isIfExpr || isElseExpr || isElifExpr ) ) {
      scryFormula.errorID = `Expected this ' : ' to be at the end of the expression.`
      scryFormula.highlightArray = [{lineNum, start, end}]
      break
    }
    // Error if a flow keyword is NOT at index === 1 (the start of the line).
    if ( scryFormula.errorID === '' && i !== 1 && name.slice(0,4) === 'flow' ) {
      switch ( name.slice(4) ) {
        case 'if'  :   scryFormula.errorID = "'if' only supported at start of a line. (Keeping it simple)"; break
        case 'else':   scryFormula.errorID = "'else' only supported at start of a line. (Keeping it simple)"; break
        case 'elif':   scryFormula.errorID = "'elif' must be at the start of the line."; break
        case 'pass':   scryFormula.errorID = "'pass' must be at the start of the line."; break
        case 'return': scryFormula.errorID = "'return' must be at the start of the line."; break
        default:
      }
      scryFormula.highlightArray = [{lineNum, start, end}]
    }
  }

  const temp = thinSpace + exprKey + thinSpace
  // The if, elif, else expressions MUST end in a ':'
  if ( scryFormula.errorID === '' && (isIfExpr || isElifExpr ) && expr[lastTokenIndex].name !== 'op_:' ) {
    scryFormula.errorID = `'${temp}' expression must end with a ' : '`
    scryFormula.highlightArray = [{lineNum, start:0, end:expr[lastTokenIndex].end}]
  }
  // Should be nothing after the pass keyword
  if ( scryFormula.errorID === '' && isPassExpr && lastTokenIndex >= 2 ) {
    scryFormula.errorID = "Nothing (except a comment) should follow ' pass ' keyword."
    scryFormula.highlightArray = [{lineNum, start:expr[1].start, end:expr[1].end}]
  }
  // Should be a value or expression between 'else' and colon  (and: 'if' and colon)
  if (scryFormula.errorID === '' && ( isIfExpr || isElifExpr ) && expr[2].name === 'op_:' ) {
    scryFormula.errorID = `Expected a value or comparison between '${temp}' and ' : '`
    scryFormula.highlightArray = [{lineNum, start:expr[1].start, end:expr[2].end}]
  }
  // Should be nothing but a colon after the else keyword
  if ( scryFormula.errorID === '' && isElseExpr && ( !expr[2] || expr[2].name !== 'op_:' )) {
    scryFormula.errorID = `Proper syntax for an else statement is ' else : '`
    scryFormula.highlightArray = [{lineNum, start:expr[1].start, end:expr[expr.length-1].end}]
  }
}



const verifyMalformedVarName = ( scryFormula:ScryFormula, lineNum:number ) :void => {
    // This is an oddball verification function.  Unlike any of
    // the others. Because it must 'know' what errors will
    // will get caught downstream, after this function.

    // Objectives:
    //   1. Identify and report malFormed varName's.
    //   2. Identify and report unknown (un-supported) function names.

    // Specific Errors to Check:
    //   1. Malformed varNames (Suspected; judgement made by the context)
    //   2. Function names that do not exist

    // Rules:
    //   1. We assume this is the FIRST verification run after parsing tokens.
    //      The formula subStrings at this time have the following characteristics:
    //          - All known tokens are tagged.
    //          - MAY be unmatched strings (un-tagged characters)  NOT an error yet!
    //          - Mal-formed constants have already been identified during parsing.
    //            Hence, no such thing as malformed constants into this function.
    //          - We do not need the concept of a  'malformed colName'. A colName is
    //            either a match or not!  Users get acceptable feedback on
    //            what is or is not a valid colName with the blue background.
    //   3. How do we look for 'is this a malformed varName?'
    //      Unlike constants, we don't have enough information to conclude
    //      some text is 'almost a varName'.
    //   4. It is important that we 'try' to report malformed varNames as best
    //      we can.  Otherwise, we have NO feedback on what constitutes
    //      a legal varName.  Other than trial and error.
    //   5. We will define a varName to be slightly more restrictive than python:
    //      - A Scry varName starts with a letter, and may contain letters, numbers,
    //         and/or underscore.  VarNames are case sensitive.
    //      - We are slighty more restrictive because python allows varNames to
    //        begin with a underscore.  And the underscore (by convention) has
    //        meaning in python.  We will consider varNames beginning with '_'
    //        to be malformed.  This is the one case we can directly test!!
    //        So we parse for varNames assuming they MAY start with an underscore;
    //        Then report that we do not support the '_'.  (THIS HAPPENS IN
    //        THE PARSE VARNAMES FUNCTION THAT WAS ALREADY RUN UPSTREAM.)
    //      - Concerning case sensitivity, we could relax the caseSensitive requirement.
    //        But it is not difficult to restrict our user's to caseSensitive varNames.
    //        Because when we check for 'varName used before set', then we can also check
    //        whether the unknown 'varName' is really just a caseSensitive mismatch.

    // Algorithm -- Just how does one identify a malformed varName?
    //   1.  We scan the tokens for a varName.  Our interest is 'what follows the varName'
    //       In other words, every potential mal-formed varName MUST begin with a valid varName.
    //       For example a+1myName23.4  parses as: 'a', '+', 'const 1', 'myName23', 'const .4'
    //       The second varName appears to be malformed.
    //   2.  We exhaustively list everything that can follow a varName.
    //   3.  For each varName/?? pair, we have three possible actions:
    //          A) The token following the varName, whether valid or invalid, has
    //             some default downstream behavior.
    //                - If the downstream default is no error. Great! We do nothing here -- simply ignore.
    //                - If the downstream error message is good. Great! We do nothing here -- simply ignore.
    //          B) Given we are looking at a 'pair' of tokens in this module, and the 1st token
    //             is known to be a varName, can we do 'better' than the default downstream behavior?
    //             'Better' is defined as a more targeted error message. -- The answer is sometimes.
    //          C) When 'varName' is followed by 'unmatched', the default error is 'unrecognized
    //             characters.'  This is the most common example of a malformed varName.  So we
    //             do 'better' by setting errorID to 'malformed varName'.  Hence the default
    //             downstream behavior is over-ridden. (First set error has priority).

    const expr = scryFormula.formulaSubStrings[lineNum]
    for (let i=1; i<expr.length; i++) {

        if ( scryFormula.errorID !== '' ) { break }
        const thisToken = expr[i]
        const nextToken = expr[i+1] ? expr[i+1] : {name:'emptySpace',text:'',start:0,end:0,value:0}
        const { name, start } = thisToken

        switch (true) {

            // This switch is emulating are set of:  'else if( ... )'
            // Order is key to exhausting all the available cases.

            // We are only interested it what varNames ( valid, mal-formed, ?? )
            // Next line will cull all tokens except 'varName'
            case name !== 'varName':
              break

            // Does varName+nextToken.text === 'someText ('
            // Down stream, this will fail as a Missing Operator between a 'value' and openParen.
            // Better to call this an unknown function.
            case (nextToken.name === 'op_(') :
              scryFormula.errorID = 'Function name is not recognized.'
              scryFormula.highlightArray = [{lineNum, start, end:nextToken.end}]
              break


            // If there is an empty space after the varName, we
            // will assume the varName is NOT mal-formed.
            case (nextToken.text[0] === ' ') :
              break

            // In all cases that follow, the varName and nextToken.text will be abutted  (no whitespace)

            // Ignore everything where the nextToken is an operator or comment
            // Exception being open paren which was caught above
            case (nextToken.name.slice(0,3) === 'op_' ) :
            case (nextToken.name === 'comment' ) :
              break

            // At this pt in testing, we have a 'varName' abutted to either:
            //    const...
            //    func...
            //    flow...
            //    unmatched   (must be some nonletter, nonDigit, nonOperator characters).

            // Case of varName, followed by unMatched.
            // Almost certainly a mal-formed varName!
            case (nextToken.name === 'unmatched') :
              scryFormula.errorID = "A 'Named Value' begins with a letter;<br>And Contains only letters, numbers, or '_'."
              scryFormula.highlightArray = [{lineNum, start, end:nextToken.end}]
              break

            // varName, followed by func
            // This is one case that squeezes throught the parser, and that is a varName
            // of length 1 (single character varName.) For example babs( ) will parse as 'varName a', 'func abs'
            // I'm going to let the above case just fall through (downstream behavior)
            // Otherwise, if the varName.length > 1, this will parse as a 'varName', 'func...'
            // Which can be interpreted as a malformed function name!
            case ( nextToken.name.slice(0,4) === 'func' ) :
              scryFormula.errorID = 'Function name is not recognized.'
              scryFormula.highlightArray = [{lineNum, start, end:nextToken.end}]
              break

            // CASE:  varName, abutted by any flow keyword.
            // This case is not possible.  Because the keyword will be (rightly) considered part of the varName.
            // If this is NOT what user intended, then error will be caught as 'varName used before set'.
            // Do nothing

            // CASE: varName, abutted by a const --
            //         myName123    captures as a 'varName'.
            //         myName123e14 captures as a 'varName'.
            //         myNameTrue   captures as a 'varName'.
            //         myNameTrue   captures as a 'varName'.
            // If varName above is NOT what user intended, then error will be caught as 'varName used before set'.
            // Do nothing

            // CASE: varName, abutted by a const --
            //         myName123.  captured as 'varName' followed by unmatched '.'
            // The '.' will be an unmatched character.  This case already caught above as mal-formed varName.

            // CASE: varName, abutted by a const --
            //         myName123.123  captured as 'varName', 'constFlt'  (no space!)
            // This case captured down stream as 'Missing operator between two values.
            // I think better to flag it now as mal-formed varName.
            case ( nextToken.name === 'constFlt' ) :
              scryFormula.errorID = "A 'Named Value' begins with a letter.<br>And contains only letters, numbers, or '_'."
              scryFormula.highlightArray = [{lineNum, start, end:nextToken.end}]
              break

            // CASE: varName, abutted by a const --
            //         myName1e-14
            // Depending of the length of the varName this will be captured as:
            //        varName, constExp              This case is captured down stream as 'Missing operator between two values'.
            //        varName, 'op_-', constInt      This case is valid syntax and does not throw an error.
            // I will flag the first case now, as mal-formed varName.
            // Going to let the second case pass through without error.
            case ( nextToken.name === 'constExp'  ) :
              scryFormula.errorID = "A 'Named Value' begins a letter.<br>And contains only letters, numbers, or '_'."
              scryFormula.highlightArray = [{lineNum, start, end:nextToken.end}]
              break

            // CASE: varName, abutted by a const --
            //         me2:14,    captured as 'varName', 'constB60'     downstream error of 'Missing operator between values'
            //         me2:14:15  captured as 'varName', 'constB60B60'  downstream error of 'Missing operator between values'
            //         longName2:14,      captured as 'varName', 'constB60'    upstream error of 'Expected interger before colon'
            //         longerName2:14:15  captured as 'varName', 'constB60B60' upstream error of 'Expected interger before colon'
            // Easy to tag the first two as mal-formed varNames
            case ( nextToken.name === 'constB60' || nextToken.name === 'constB60B60'  ) :
              scryFormula.errorID = "A 'Named Value' begins with a letter.<br>And contains only letters, numbers, or '_'."
              scryFormula.highlightArray = [{lineNum, start, end:nextToken.end}]
              break
            // Unfortunately, the last two cases never get this far (upstream error). Can't do anything to improve
            // the message at this location in the code flow.

            default:

        } // end switch
    }     // end subStrings for loop
}



const verifyCompareSequence = ( scryFormula:ScryFormula, lineNum:number ) :void => {

    // Objectives
    //   1. Error checking of two or more sequential compare operators.

    // Rules:
    //       1. Python evualuates:   A cmp B cmp C
    //          as:             (A cmp B) && (B cmp C)
    //          This is a special rule built into the python compiler.
    //          It cannot be emulated using any precedence rule, nor our current
    //          RPN generating algorithm
    //       2. After we spot this sequence, we have to options:
    //             - Spot the sequence and replace with the python equivalent calculation.
    //             - Spot the sequence and throw an error.
    //       3. We choose to throw an error.  User should write the code unambiguously.
    //       4. It is not as easy to spot this sequence as one would first think.
    //          For example, this fits the sequence:
    //              abs(A) cmp (3 / ColName ) cmp ( const**2 - sin( colName )
    //       5. Again, for this verification, we can treat functions as a set of parens.
    //       6. This test is easier if we assume we have already verified the
    //          the general sequence of  ...   val, op, val, op, ...
    //
    //          And this test is easier if we assume we have balanced parens.
    //
    //          In which case we can search for a shorter sequence:
    //                cmpOp, ( valueExpression ), cmpOp.
    //          Because we know some value or valueExpression preceeds the sequence,
    //          and some value or valueExpression follows the sequence.
    //          We also know the parens between the two cmpOp MUST be balanced.
    //       7. Finally, we assume both cmp operators and math operators can intermixed in
    //          regular expressions, as well as 'if' 'else' 'elif' flow control expressions.
    //          -- Where a number equivalent to false if zero, else true.
    //          -- And where a boolean false equivalent to integer 0, else 1.
    //          Hence, the troublesome sequence we seek MAY BE in any or every expression line.

    let op1 = ''
    let op2 = ''
    if ( scryFormula.errorID !== '' ) { return } // Early abort
    const expr = scryFormula.formulaSubStrings[lineNum]
    if (expr.length < 6) { return }   // We need at least 6 tokens to have sufficient room for an illegal sequence!
    for (let i=2; i<expr.length; i++) {   // For loop so we can break
      let exprName = expr[i].name
      if ( isComparisionOpCode(exprName) ) {
        let isIllegalSequence = false
        let j = i+2
        op1 = compOpToDisplayTextMap[ exprName ]

        // we will look ahead for the desired (illegal) sequence
        // CASE: cmp, val, cmp sequence
        exprName = expr[i+2]?.name
        if ( expr[i+2] && isComparisionOpCode(exprName)) {
          isIllegalSequence = true
          op2 = compOpToDisplayTextMap[ exprName ]
        }

        // CASE: potential cmp, '(' , 1 or more tokens, ')' , cmp  sequence
        if ( expr[i+1].name === 'op_(' || expr[i+1].name.slice(0,4) === 'func' ) {
          let parenCounter = 1
          while ( parenCounter > 0 ) {
            if ( expr[j].name === 'op_(' || expr[j].name.slice(0,4) === 'func' ) { parenCounter++ }
            if ( expr[j].name === 'op_)' ) { parenCounter-- }
            j++
          }
          exprName = expr[j].name
          if (expr[j] && isComparisionOpCode(exprName)  ) {
            isIllegalSequence = true
            op2 = compOpToDisplayTextMap[ exprName ]
          }
        }
        if ( isIllegalSequence === true ) {
          // Found illegal Seqence.  Set the ErrorID and break out of this for loop.
          scryFormula.errorID =
          `Please use parenthesis to define an order of operation:<br>(A${op1}B)${op2}C , &nbsp;A${op1}(B${op2}C) , &nbsp;(A${op1}B) and (B${op2}C)`
          scryFormula.highlightArray = [
            {lineNum, start: expr[i].start, end: expr[i].end},
            {lineNum, start: expr[j].start, end: expr[j].end}
          ]
          break
        }
      }
    }
  }




const verifyAssignmentOp = ( scryFormula:ScryFormula, lineNum:number, lastLineNum:number ) :void => {

    // Objectives
    //   1. Error checking of assignment operator.
    //   2. Error checking syntax 'left' of assignment operator (should be token 'setVarName')

    // Specific Errors to Check:
    //   1. Python multiple assignment not supported:  a = b = c = 1
    //   2. Python tuple unpacking not supported:  x,y = 3,4
    //   3. if, else, elif:   found '=', but did you mean '=='
    //   4. Other flow statements (return,  pass) should have no '='
    //   5. Assigning a value to a colName
    //   6. Assigning a value to nothing (missing return)
    //   7. Left of '='  no varName
    //   8. Left of '='  varName plus other crap
    //   9. Every line must start with either: flow keyword or varName


    // Algorithm:
    //       1. We will NOT support the python constructions:
    //              a=b=c=1   -- multiple assignment
    //              x,y = 3,4 -- tuple assignment
    //              (3,4)     -- tuples
    //
    //       2. Valid assignment construction is ALWAYS: setVarName = expression
    //          If they try to assign the value to something other than a varName
    //          we MUST call out that error here.  For example, assigning the value
    //          to a colName (likely error) will NOT be caught downstream.
    //       6. Every line must begin with a keyword, or  'setVarName ='.
    //          An expression in isolation has no purpose.  User should either:
    //            - use 'return' to the assign value to the current column
    //            - use a varName = to assign to a temporary local variable.
    //            - or comment the line out.
    //       7. If this is the last line of the formula, we can assume the
    //          error is a missing return.   We will consider this a warning,
    //          and inform the user we have inserted the required python 'return'.
    //          This is a likely error for newbies and common error for
    //          everybody else. So tell them the mistake, but don't force them to write
    //          the 'return'.  We just insert a subString at index 1:
    //                 {name:'return', text:'', start:0, end:0}.
    //          The tableidSpacing Format function will insert the text 'return '


    const expr = scryFormula.formulaSubStrings[lineNum]
    const isFlowLine = ( expr[1].name.slice(0,4) === 'flow' )
    let   numAssignments = 0
    const   highlightAssignments = []

    for (let i=1; i<expr.length; i++) {   // For loop so we can break
      if ( scryFormula.errorID !== '' ) { break } // Early abort
      if ( expr[i].name   !== 'op_=' ) { continue }

      // ONLY assignment tokens '=' reach this code.
      const thisToken  = expr[i]
      //var priorToken = expr[i-1]
      const { start, end } = thisToken
      numAssignments++
      // May or may not use this.  But create it along the way:
      highlightAssignments.push({ lineNum, start, end })

      if (numAssignments > 1) { continue }

      // Beyond this point, this is first occurance of 'op_='.
      // Could be legal.  OR could somehow messed up on left of assignment.

      // CASE:  Should be NO assignment operators in a 'flow' expression.
      // This is any expression that begins with if, else, elseif, pass, return.
      if ( isFlowLine ) {
        const keyword = expr[1].name.slice(4)
        if (keyword === 'pass' || keyword === 'return') {
          scryFormula.errorID = `Illegal '='.  Lines beginning with '${keyword}' cannot use '=' operator. `
          scryFormula.highlightArray = [
             {lineNum, start, end},                              // highlight the equal sign
             {lineNum, start:expr[1].start, end:expr[1].end}    // highlight the keyword at start of line
          ]
        } else {  // keyword === if, else, elsi
          scryFormula.errorID = `Illegal '='.  Did you mean '=='?`
          scryFormula.highlightArray = [ {lineNum, start, end}]
        }
        break
      }

      // At what index (prior to the first '=') is the varName token?
      // May or may not even be one!
      let varNameIndex = -1
      for (let j=1; j<=i; j++) {
        if (expr[j].name === 'varName') {varNameIndex = j; break;}
      }


      // CASE:  valid assignment operation:
      // setVarName at index 1; 'op_=' at index 2;
      // Change the 'varName' to 'setVarName'
      // The distinction is needed downstream in the 'used before set' analysis
      if ( varNameIndex === 1 && i === 2 ) {
        expr[1].name = 'setVarName'
      }

      // CASE:
      // Python allows:  x,y = 3,4
      // We will not support 'tuple assignment'.
      if ( expr[varNameIndex+1].name === 'op_,' ) {
        scryFormula.errorID = `'Tuple assignment' not supported.<br>Use multiple lines. Keeping it simple.`
        scryFormula.highlightArray = [ {lineNum, start:expr[varNameIndex+1].start, end:expr[i-1].end}]
        break
      }

      // CASE: colName = ...
      if ( expr[1].name === 'colName' && i === 2 ) {
        scryFormula.errorID = `Cannot save this value directly to a named column.<br>Use 'return' to write a value to the current column.`
        scryFormula.highlightArray = [ {lineNum, start:expr[1].start, end:expr[i].end}]
        break
      }

      // CASE: A varName plus extra junk left of the assignment
      if (varNameIndex !== -1 && i > 2) {
        scryFormula.errorID = `Only some 'Named Value' (one word) may be left of the '='.`
        scryFormula.highlightArray = []
        for ( let j=1; j<i; j++ ) {
          if ( j === varNameIndex ) continue
          scryFormula.highlightArray.push( {lineNum, start:expr[j].start, end:expr[j].end} )
        }
        break
      }

      // CASE: NO varName left of the assignment.
      if ( varNameIndex === -1 ) {
        scryFormula.errorID = `Expected a 'Named Value' before the '='.`
        scryFormula.highlightArray = [ {lineNum, start, end}]
        break
      }

  }   // end forLoop

  // Python supports multiple assignments in the same line:   a = b = c = 1
  // We will support only one assignment.
  if ( scryFormula.errorID === '' &&  numAssignments >= 2 ) {
    scryFormula.errorID = 'Multiple assignment operators not supported. (Keeping it simple)'
    scryFormula.highlightArray = highlightAssignments
    return
  }
  // Case of NO assignment operator when every line (except flow lines) must have have.
  // DON'T apply this test to the last line.  Let the 'return' error checking function
  // downstream handle that with a better error message
  if ( lineNum < lastLineNum && scryFormula.errorID === '' &&
       numAssignments === 0 && !isFlowLine && !scryFormula.isCommentLine[lineNum] ) {
    scryFormula.errorID = "Valid lines must begin with:<br>if, elif, else, pass, '#', return, or 'named_value ='"
    scryFormula.highlightArray = [{lineNum, start:0, end: expr[ expr.length-1 ].end }]
    return
  }

}


// RULES FOR 'blankLines' and 'commentLines':
//
//  0. The ScryFormula object includes three arrays:
//        isBlankLine[lineNum]
//        isCommentLine[lineNum]
//        isExpression[lineNum]

//  1. Every line in the formula is one, but only one of the above 3 choices.
//  2. A 'blankLine' line is defined as a line in the formula with nothing
//     to display.  The text will be zero length, or contain only whitespace.
//     The formulaSubStrings will be an 'indent' only, or 'indent'+'emptyspace'.
//  3. An 'commentLine' expression is defined as a line with a comment, but
//     no python expression
//     Same as saying a line with only: 'indent','emptySpace','comment'.
//     Same as saying:  A 'blankLine' + 'commment'
//  4. The tableidSpacing format will delete 'blankLines' between expressions.
//     But also add two blank lines at the end of the formula.  For convenience
//     to the user to enter text on a new line without having to enter a lineFeed.
//  6. After editing begins, the location and number of 'blank' lines is no
//     longer under our control.  The user sees and edits whatever they
//     write.  However, the tableidSpacing Format version created on each edit,
//     and saved to state on each edit, will never include blank lines.
//        Code found in Component: EditColFormula -- updateReactState()
//  7. A formula with only blank lines === 'missing formula',
//  8. A formula with only empty lines === 'erroneous formula'
//  9. An 'erroneous Formula' means some text exist, but it has an error.
//     The state value is exactly as the user typed; and the errorID is set.
//     (Same behavior as erroneous cell data where we save the erroneous text to state.)
// 10. When a formula has an error, we save to state 'exactly what was typed'.
//     Including the blank lines.


const verifyMaxExpressionLength = (scryFormula: ScryFormula ) : void => {
    const FONT_SIZE  = constants.COL_FORMULA_EDITOR_FONT_SIZE
    const MAX_LENGTH = constants.COL_FORMULA_MAX_LINE_LENGTH
    if ( scryFormula.errorID !== '' ) { return }
    scryFormula.highlightArray = []
    scryFormula.formulaStrings.forEach( (thisExpr, lineNum) => {
      const {isLegal} = findLegalCharCount( thisExpr, MAX_LENGTH, FONT_SIZE )
      if ( !isLegal ) {
        scryFormula.errorID = 'Expression exceeds maximum allowed length.'
        scryFormula.highlightArray.push({lineNum, start:0, end:thisExpr.length, colorMode:'redBack'})
      }
    })
}

export const errorCheckLines = ( scryFormula:ScryFormula, colKey:number, colTitleArr:string[], 
                 internalDataTypeArr: InternalColDataType[], isJestTestCall:boolean = false ) : ScryFormula => {

    // This function modifies scryFormula insitu.  Value is also returned,
    // but does not really need to be reassigned to anything.
    if ( scryFormula.errorID !== '' ) { return scryFormula }
    let lastExpressionLineNum = -1
    scryFormula.formulaSubStrings.forEach( ( _, lineNum) => {
      if ( scryFormula.isExpression[lineNum] ) { lastExpressionLineNum=lineNum }
    })

    // This is the only test done on all lines per call.
    // (As opposed to a single expression per call).
    // This is so we can highlight all expressions (lines) that
    // are too wide, since one errorID will work for all.
    // DO NOT call if this is Jest Testing.  As the measureText function
    // does not work outside of react.  Nor is a limit on lineLength
    // required for Jest Tests.
    if (scryFormula.errorID === '' && !isJestTestCall ) { 
      verifyMaxExpressionLength(scryFormula ) 
    }

    // Line level verifications:
    scryFormula.formulaSubStrings.forEach( (thisExpr, lineNum) => {
        if ( !scryFormula.isExpression[lineNum] ) { return }  // skip non-expressions
        if (scryFormula.errorID === '' ) verifyMalformedVarName(scryFormula, lineNum )
        // Tag 'unmatched' subStrings
        if (scryFormula.errorID === '' ) {
          thisExpr.forEach( (subString) => {
            const {start, end, name} = subString
            if (name === 'unmatched' ) {
              scryFormula.highlightArray.push({lineNum, start, end})
              scryFormula.errorID = 'Found unexpected characters.'
            }
          })
        }
        // Flag 'None' as an error if found anywhere EXCEPT as a return value.
        // Do this very early because it is the equivalent of unexpected characters.
        if (scryFormula.errorID === '' ) {
          for ( let i=0; i < thisExpr.length; i++ ) {
            const {start, end, name} = thisExpr[i]
            if ( name === 'None' && thisExpr[1].name !== 'flowreturn' && i !== 2 ) {
              scryFormula.errorID = `Illegal 'None'. This keyword is only<br>supported for returns ('return None').`
              scryFormula.highlightArray = [{lineNum, start, end}]
              break
            }
          }
        }
        if (scryFormula.errorID === '' ) verifyKeywordExpr    (scryFormula, lineNum )
        if (scryFormula.errorID === '' ) verifyVarNameNotReservedPython (scryFormula, lineNum )
        if (scryFormula.errorID === '' ) verifyAssignmentOp   (scryFormula, lineNum, lastExpressionLineNum )
        if (scryFormula.errorID === '' ) verifyTokenSequence  (scryFormula, lineNum )
        if (scryFormula.errorID === '' ) verifyExponentials   (scryFormula, lineNum )
        if (scryFormula.errorID === '' ) verifyBalancedParen  (scryFormula, lineNum )
        if (scryFormula.errorID === '' ) verifyFuncArgCount   (scryFormula, lineNum )
        //if (scryFormula.errorID === '' ) verifyKeywordExpr    (scryFormula, lineNum )   // Moved this 1st; So far appears fine comming 1st.  Nov, 2020 jps
        if (scryFormula.errorID === '' ) verifyCompareSequence(scryFormula, lineNum )
        if (scryFormula.errorID === '' ) verifyColType_selfRef(scryFormula, lineNum, colKey, colTitleArr, internalDataTypeArr )
    })

    return scryFormula
}





