import React, { Component } from 'react'
import { GenericObject } from '../types'

type OwnProps = {
}

type DefaultProps = {
  delay: number
  position: string
}

type Props = OwnProps & DefaultProps

type LocalState = {
  at: string
  content: string
  forceRender: number
  left: number
  target: Element | null
  top: number
}

class ReactHint extends Component<Props, LocalState> {
  static defaultProps = {
    delay: 100,
    position: 'bottom'
  }

  state = {
    at: '',
    content: '',
    forceRender: 0,
    left: 0,
    target: null,
    top: 0,
  }

  _container: HTMLDivElement | null = null
  _hint: HTMLDivElement | null = null
  _timeout: NodeJS.Timeout | undefined

  componentDidMount() {
    this.toggleEvents(this.props, true)
  }

  componentWillUnmount() {
    this.toggleEvents(this.props, false)
    clearTimeout(this._timeout)
  }

  manageEventListener = (eventName: string, handler: EventListener, flag: boolean): void => {
    if (flag) {
      document.addEventListener(eventName, handler)
    } else {
      document.removeEventListener(eventName, handler)
    }
  }

  toggleEvents = (props: Props, flag: boolean): void => {
    this.manageEventListener('click', this.toggleHint, flag)
    this.manageEventListener('focusin', this.toggleHint, flag)
    this.manageEventListener('mouseover', this.toggleHint, flag)
    this.manageEventListener('touchend', this.toggleHint, flag)
  }

  toggleHint = (event: Event): void => {
    const target = event.target instanceof Node ? event.target : null
    clearTimeout(this._timeout)
    this._timeout = setTimeout(() => this.setState({}, () => (
      this.getHint(target)
    )), this.props.delay)
  }

  getHint = (el: Node | null): { target: Element | null, forceRender?: number } => {
    const { content, forceRender } = this.state

    while (el) {
      if (el === document) {
        break
      }
      if (el instanceof Element && el.hasAttribute('data-rh')) {
        const newState = {
          forceRender,
          target: el,
        }
        if (content !== el.getAttribute('data-rh')) {
          newState.forceRender = forceRender + 1
        }
        return newState
      }
      el = el.parentNode
    }
    return { target: null }
  }

  shouldComponentUpdate(props: OwnProps, state: LocalState) {
    return !this.shallowEqual(state, this.state) ||
      !this.shallowEqual(props, this.props)
  }

  shallowEqual = (a: GenericObject, b: GenericObject): boolean => {
    const keys = Object.keys(a)
    return keys.length === Object.keys(b).length &&
      keys.reduce((result, key) => result &&
        ((typeof a[key] === 'function' &&
          typeof b[key] === 'function') ||
          a[key] === b[key]), true)
  }

  componentDidUpdate() {
    if (this.state.target) {
      this.getHintData(this.state, this.props)
    }
  }

  getHintData = (state: LocalState, props: Props): void => {
    const { position } = props
    const { target } = state
    if (target) {
      const content = target.getAttribute('data-rh') || ''
      const at = target.getAttribute('data-rh-at') || position

      if (this._container) {
        const {
          top: containerTop,
          left: containerLeft
        } = this._container ? this._container.getBoundingClientRect() : { top: 0, left: 0 }

        if (this._hint) {
          const {
            width: hintWidth,
            height: hintHeight
          } = this._hint.getBoundingClientRect()

          const {
            top: targetTop,
            left: targetLeft,
            width: targetWidth,
            height: targetHeight
          } = target.getBoundingClientRect()

          let top, left
          switch (at) {
            case 'left':
              top = targetHeight - hintHeight >> 1
              left = -hintWidth
              break

            case 'right':
              top = targetHeight - hintHeight >> 1
              left = targetWidth
              break

            case 'top':
              top = -hintHeight
              left = targetWidth - hintWidth >> 1
              break

            case 'bottom':
            default:
              top = targetHeight
              left = targetWidth - hintWidth >> 1
          }

          this.setState({
            at,
            content,
            left: (left + targetLeft - containerLeft) | 0,
            top: (top + targetTop - containerTop) | 0,
          })
        }
      }
    }
  }

  setContainerNode = (element: HTMLDivElement | null): void => {
    this._container = element
  }
  setHintNode = (element: HTMLDivElement | null): void => {
    this._hint = element
  }

  render() {
    const { target, content, at, top, left } = this.state
    return (
      <div
        className={'rc_ReactHint'}
        ref={this.setContainerNode}
        style={{ position: 'relative' }}
      >
        {target &&
          <div
            className={`react-hint react-hint--${at}`}
            ref={this.setHintNode}
            style={{ top, left }}
          >
            <div className={`react-hint__content`}>
              {content}
            </div>
          </div>
        }
      </div>
    )
  }
}

export default ReactHint
