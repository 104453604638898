const joi = require('joi')

const sourcesvAttributes = {
  rows: joi.array().items(joi.array().items(
      joi.string().allow('').allow(null),
    ).allow(null))
    .description('The rows of the csv file'),
  errors: joi.array().items(joi.object().keys({
      type: joi.string().valid('Quotes', 'Delimiter', 'FieldMismatch'),
      code: joi.string().valid('MissingQuotes', 'UndetectableDelimiter', 'TooFewFields', 'TooManyFields'),
      message: joi.string().allow('').allow(null),
      row: joi.number().integer(),
      index: joi.number().integer().allow(null),
    }).allow(null))
    .description('Array of errors encountered in parsing the csv file'),
  parseMeta: joi.object().keys({
      cursor: joi.number().integer(),
      delimiter: joi.string(),
      linebreak: joi.string(),
      aborted: joi.boolean(),
      fields: joi.array().items(
          joi.string().allow('').allow(null),
        ).allow(null),
      truncated: joi.boolean(),
    }).allow(null)
    .description('Meta information about parsing the csv file'),

  // server side managed data (read only to clients)
  createdDate: joi.date().iso().allow(null).meta('readonly')
    .description('Read only date source was imported.'),
}

module.exports = sourcesvAttributes
