import imageCompression from 'browser-image-compression'
import type { AnyAction, Dispatch } from '@reduxjs/toolkit'
import invariant from 'invariant'
import { debounce } from 'lodash'
import type { PlotHeightObj, PlotWidthObj } from '../computedDataPlotXY/xy_plotCalculator'
import type { GetStateFunc } from '../types'
import { createThumbnailAddAction, createThumbnailDeleteAction } from './thumbnailReducer'
import axios from 'axios';

let recent_delete: string = '' // don't update the thumbnail if it was just deleted
let isUpdating: boolean = false // flag to keep track of whether an update is in progress
const DEBUG = false

// Multipel requests for the same plotid will be ignored.
// this happens because the thumbnail isn't ready for every render.
const processingPlotIds = new Set<string>();

export const getThumbnail = (plotid: string) => {
  return async (dispatch: Dispatch<AnyAction>, getState: GetStateFunc): Promise<void> => {
    if (processingPlotIds.has(plotid)) {
      return;
    }
    try {
      const authToken = getState().app.authToken;
      const baseURL = window._datatables_.serverURL;
      const dataURL = getState().thumbnail[plotid];
      if (dataURL) { 
        return;
      }
      if (plotid === recent_delete) { 
        return;
      }
      processingPlotIds.add(plotid);
      const response = await axios.get(`${baseURL}minio`, {
        params: { plotid },
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json', // Ensure this is a simple header
        },
        responseType: 'blob',
      });

      if (response.status === 200) {
        const dataUrl = window.URL.createObjectURL(response.data);
        dispatch(createThumbnailAddAction({ plotid, dataUrl }));
        if (DEBUG) { console.log('Thumbnail downloaded successfully: ', plotid); }
      } else {
        if (DEBUG) { console.log('Thumbnail not found: ', plotid); }
      }
    } catch (error) {
      if (DEBUG) { console.log('Error downloading thumbnail:', error); }
    } finally {
      processingPlotIds.delete(plotid);
    }
  };
};

export const deleteThumbnail = (plotid: string) => {
  return async (dispatch: Dispatch<AnyAction>, getState: GetStateFunc): Promise<void> => {
    try {
      const baseURL = window._datatables_.serverURL;
      const authToken = getState().app.authToken;
      const response = await axios.delete(`${baseURL}minio`, {
        data: { plotid },
        headers: {
          Authorization: `Bearer ${authToken}`, // Replace with your actual auth token
        },
      });

      if (response.status === 200) {
        dispatch(createThumbnailDeleteAction(plotid));
        console.log('Thumbnail deleted successfully.');
        recent_delete = plotid;
      } else {
        console.log('Error deleting thumbnail:', response.statusText);
      }
    } catch (error) {
      console.log('Error deleting thumbnail:', error);
    }
  };
};

const MIME_TYPE = 'image/png'

const dataURItoBlob = (dataURI: string): File | null => {
  try {
    const byteString = atob(dataURI.split(',')[1]) // exception MBDEBUG
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    const blob = new Blob([ia], { type: MIME_TYPE })
    return blob as File
  } catch (error) {
    console.error('caught exception in dataURItoBlob', error)
    return null
  }
}

const createThumbnail = (w: PlotWidthObj, h: PlotHeightObj): string => {

  // w => plotWidthObj
  // h => plotHeightObj

  // Find each canvas layer
  // Scale an offset the react-canvas such that ONLY the plottedData is included in the thumbnail
  //    - We scale the canvas such that ONLY the plotted data will fit into our thumbnail context
  //    - We offset the canvas in 'y' so the legend is located 'above' are new context (hence cropped)
  //    - We offset the canvas in 'x' so the left axis is located 'left' of the new context (hence cropped)
  //    - We blur what remains by copy 5 slightly offset images.
  // We add left/right axis lines right at the edge of the new context object.
  // return an image in dataUrl format (string)

  const canvases: HTMLCollectionOf<HTMLCanvasElement> = document.getElementsByClassName('rv-xy-canvas-element') as HTMLCollectionOf<HTMLCanvasElement>

  if (canvases.length > 0) {
    const bigCanvas = canvases[0]
    if (process.env.NODE_ENV === 'development') {
      invariant(bigCanvas.width === Math.floor(w.reactVisArea) &&
        (bigCanvas.height === Math.floor(h.reactVisArea)),
        'Canvas dimensions used for thumbnails NOT the same size as plotCalculator')
    }

    /* You will need to draw a picture to understand the coordinates.

    1) A large 'bigCanvas' rectangle of size (bigWidth, bigHeight)
       This is the canvas resolution drawn by reactVis.  It is NOT the size
       in screen pixels.  We render plot SVG at higher resolution than
       screeen pixels.

    2) A much smaller 'scaledBigCanvas' rectangle reduced by factor of
       (widthScale, heightScale) and of size ( scaledBigWidth, scaledBigHeight ).
       This will be the 'reduced resolution canvas' that is at the
       resolution of the thumbnail.  It is NOT the size of the thumbnail, but
       it is at the proper resolution.  It is larger than the thumbnail, because
       it includes four edges of non-plotted data ( topLegend, leftAxis, bottomAxis, rightMargin)
       However, it will be the proper scale such that the data area will fit within a 256x256 pixel context,

    3) A 'smallCanvas' size of (smallWidth, smallHeight ) representing the plotted data.
       It will have the aspect ratio of the plot. It sits within the scaledBigCanvas with
       a upperLeft offsets equivalent to the (leftAxis, legendHeight) Given its size and
       offset, it will crop ( topLegend, leftAxis, bottomAxis, rightMargin) and capture
       ONLY the rectangle plotted data.

    */

    if (bigCanvas instanceof HTMLCanvasElement) {
      // copy into smaller temporary canvas
      const bigWidth = Math.floor(w.plottedData)
      const bigHeight = Math.floor(h.plottedData)
      let smallHeight = 512
      let smallWidth = 512 // at least twice the size we will display at (for high density screens)
      if (bigWidth > bigHeight) {
        smallHeight = Math.round(bigHeight * smallWidth / bigWidth)
      } else {
        smallWidth = Math.round(bigWidth * smallHeight / bigHeight)
      }
      const heightScale = smallHeight / bigHeight
      const widthScale = smallWidth / bigWidth

      const smallCanvas: HTMLCanvasElement = document.createElement('canvas')
      smallCanvas.width = smallWidth
      smallCanvas.height = smallHeight
      const context = smallCanvas.getContext('2d')
      invariant(context != null, 'got null for 2d context in createThumbnail')
      const scaledBigWidth = w.reactVisArea * widthScale
      const scaledBigHeight = h.reactVisArea * heightScale
      const yOffset = - h.totalLegend * heightScale
      const xOffset = - w.totalLeftAxis * widthScale

      for (let i = 0; i < canvases.length; i++) {
        // drawing same image, 5 times to 'blur' or degrade the resolution.
        // We draw the entire (bigWidth, bigHeight) image, but it is clipped
        // by the context with is the smller (smallWidth, smallHeight)
        const canvas = canvases[i]
        // context.drawImage() will crop all four edges that do not
        // fit with the smallCanvas dimensions.
        context.drawImage(canvas, xOffset - 1, yOffset, scaledBigWidth, scaledBigHeight)
        context.drawImage(canvas, xOffset, yOffset + 1, scaledBigWidth, scaledBigHeight)
        context.drawImage(canvas, xOffset + 1, yOffset, scaledBigWidth, scaledBigHeight)
        context.drawImage(canvas, xOffset, yOffset - 1, scaledBigWidth, scaledBigHeight)
        context.drawImage(canvas, xOffset, yOffset, scaledBigWidth, scaledBigHeight)
      }

      const path = 'left bottom'
      context.lineWidth = 5
      context.strokeStyle = 'black'
      if (path === 'left bottom') {
        // These coords truncate half the line.
        // Just make the line fatter to compensate until you are pleased.
        context.moveTo(0, 0)
        context.lineTo(0, smallHeight)
        context.lineTo(smallWidth, smallHeight)
      }
      context.stroke()
      // save the dataURL
      const dataUrl = smallCanvas.toDataURL(MIME_TYPE)
      return dataUrl
    }
  } else {   // canvases.length === 0 because there is no data to plot (perhaps all filtered, .... )
    const smallCanvas = document.createElement('canvas')
    smallCanvas.width = 256
    smallCanvas.height = 256
    const context = smallCanvas.getContext('2d')
    invariant(context != null, 'got null for 2d context in createThumbnail')
    context.font = 'bold 35pt Courier'
    context.fillText('NO DATA', 30, 150)
    return smallCanvas.toDataURL(MIME_TYPE)
  }

  return ''
}

/**
 * Returns a hash code from a string
 * @param  {string} str The string to hash.
 * @return {number}    A 32bit integer
 * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
function hashCode(str: string): number {
  let hash = 0
  invariant(str !== null, 'hashCode: str !== null')
  invariant(str.length > 0, 'hashCode: str.length > 0')
  for (let i = 0, len = str.length; i < len; i++) {
    const chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}


const debouncedUpdate = debounce((dispatch: Dispatch<AnyAction>, getState: GetStateFunc, plotid: string, dataUrl: string) => {

  if (isUpdating || dataUrl === null || dataUrl === undefined) {
    if (DEBUG) {
      console.log('update already in progress, skipping');
    }
    return;
  }
  if (DEBUG) {
    console.log('uploading thumbnail');
  }
  isUpdating = true;

  const blobData = dataURItoBlob(dataUrl) || null;
  if (!blobData) {
    //console.log('Failed to convert data URL to blob')
    isUpdating = false;
    return;
  }
  if (DEBUG) {
    const now = new Date();
    console.log(now.getMinutes(), now.getSeconds(), now.getMilliseconds(), 'TN UPDATE: ', plotid, hashCode(dataUrl));
  }
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  imageCompression(blobData, options).then(compressedFile => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const compressedDataUrl = reader.result as string;
      const authToken = getState().app.authToken;

      uploadThumbnail(plotid, compressedDataUrl, authToken).then(() => {
        isUpdating = false;
        if (DEBUG) {
          console.log('Thumbnail upload dispatched A', plotid);
        }
        dispatch(createThumbnailAddAction({ plotid, dataUrl }));
      }).catch((error) => {
        isUpdating = false; 
        console.log('Error uploading thumbnail:', error);
      });
    };
    reader.readAsDataURL(compressedFile);
  }).catch(error => {
    isUpdating = false;
    console.log('Error compressing thumbnail:', error);
  });
}, 1000, { trailing: true, leading: false, maxWait: 5000 });


const uploadThumbnail = async (plotid: string, dataUrl: string, authToken: string): Promise<void> => {
  try {
    const baseURL = window._datatables_.serverURL;
    const response = await axios.post(`${baseURL}minio`, {
      plotid,
      dataUrl,
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (response.status === 200) {
      if (DEBUG) { console.log('Thumbnail uploaded successfully: ', plotid); }
    }
  } catch (error) {
    if (DEBUG) { console.log('Error uploading thumbnail:', error); }
    throw error;
  }
};


export const postThumbnail = (plotid: string, plotWidthObj: PlotWidthObj, plotHeightObj: PlotHeightObj) => {
  return async (dispatch: Dispatch<AnyAction>, getState: GetStateFunc): Promise<void> => {
    if (DEBUG) {
      const now = new Date()
      console.log(now.getMinutes(), now.getSeconds(), now.getMilliseconds(), 'dispatchCall: ', plotid)
    }
    const dataUrl = createThumbnail(plotWidthObj, plotHeightObj)
    const state = getState()
    const storedUrl = state.thumbnail[plotid] ?? null
    if (storedUrl === dataUrl) {
      if (DEBUG) {
        console.log('Thumbnail already up to date, same Url')
      }
      return
    }

    dispatch(createThumbnailAddAction({ plotid, dataUrl }))
    debouncedUpdate(dispatch, getState, plotid, dataUrl)
  }
}
