import {set} from 'radash'
import invariant  from 'invariant'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import type { ParentType, ResourceType } from '../jsonapi/types'
import { clonePlotThunk } from '../redux/plotThunks'
import { asyncDispatch } from '../sharedFunctions/utils'
import type { LightweightMod, MinorMod, Mod, Plot, RouterHistory, Tablelook } from '../types'
import { createDoAction, createMinorAction } from '../redux/undoReducer'
import { updateSession } from '../redux/sessionReducer'

// Local state set at initialization.
export var resourceNameLocal  : ResourceType = 'tablelooks'
export var resourceidLocal    : string = ''
var resource2nameLocal : ParentType = 'tables'
var resource2idLocal   : string = ''
var dispFunc: Dispatch<AnyAction> | null = null  // Reference to any react created dispatch function.
var plot: Plot | null = null
var userid: string = ''
var history: RouterHistory | null = null
var tablelook: Tablelook | null = null

export const initializeDispatch = (
  // These args are the defaults we use for createDoAction( )
  dispFuncIn: Dispatch<AnyAction>,
  arg1: ResourceType,
  arg2: string,
  arg3: ParentType,
  arg4: string,

  // These args are for the 2nd purpose of function:
  // Cloning a plot if/when a plot attribute is modified
  in_plot: Plot | null = null,
  in_userid: string = '',
  in_history: RouterHistory | null = null,
  in_tablelook: Tablelook | null = null
//
) : void => {
  //console.log( 'initializeDispatch Called', arg1, arg2, arg3, arg4 )
  dispFunc      = dispFuncIn
  resourceNameLocal  = arg1
  resourceidLocal    = arg2
  resource2nameLocal = arg3
  resource2idLocal   = arg4
  plot = in_plot
  userid = in_userid
  history = in_history
  tablelook = in_tablelook
}

const reactDispatch = (
  // Next two args are ALWAYS used!
  mods: LightweightMod[],
  changeDescription: string,
  // This arg to identify active drags and dragStop
  actionGroup: string = '',

  // We can override this on reactDispatch call where this may be 'table'/tableid or 'tabledata'/tabledataid.
  // Otherwise,  if missing, our initialization has set these values
  resourceName: ResourceType = resourceNameLocal,
  resourceid: string    = resourceidLocal,

  // This are the MASTER resource and for tables is the 'table'/tableid
  // For plots, this is 'plot'/plotid.
  // This is the top level resource from which the Action can find the
  // corresponding tabledataid and tablelookid.
  resource2name: ParentType = resource2nameLocal,
  resource2id: string   = resource2idLocal,
) :void => {
    invariant(dispFunc, 'reactDispatch called before initializeDispatch')
    if ( process.env.NODE_ENV !== 'production' ) {
      invariant( changeDescription !== '', 'Missing unDo messageString' )
    }
    const fullMods: Mod[] = mods.map((mod: LightweightMod): Mod => (
      {resId: resourceid, resType: resourceName, op: 'set', ...mod}
    ))
    // console.log('MBDEBUG - we better load a new list!')

    // This is the path that clones a plot resource BEFORE modifications IFF:
    //    The mods array contains at least one modification to the plot.
    //    The resource to be modified is a 'plot'.
    //    The plot resource loaded at initialization exists and is NOT owned by the current user

    var isPlotModification = false // assumption
    if ( mods.length > 0 && mods.some( thisMod => (thisMod.resType === 'plots') ) ) {
      isPlotModification = true
    }
    if ( isPlotModification && history && plot && plot.relationships
         && userid !== plot.relationships.owner?.data.id ) {
     console.log( 'reactDispatch cloning new plot.  mods:', mods )
     asyncDispatch( dispFunc, clonePlotThunk(plot, fullMods, tablelook, userid, history) )
    }

    // This is the path which modifies an existing resource
    else if ( dispFunc ) {
      const thisAction = createDoAction({
        parentType: resource2name,
        parentId: resource2id,
        title: changeDescription,
        mods: fullMods,
        actionGroup
      })
      dispFunc( thisAction )
    }

}

export const reactMinorDispatch = (
  mods: LightweightMod[],
  // These args usually unused.  The Local variables above will be used.
  // However, if we need an exception, where a dispatch is to some resource
  // other than the current locally defined resources, then use the
  // args supplied by the calling function, instead of the local variables.
  resourceName: ResourceType = resourceNameLocal,
  resourceid: string    = resourceidLocal,
  resource2name: ParentType = resource2nameLocal,
  resource2id: string   = resource2idLocal,
) :void => {

  invariant(dispFunc, 'reactMinorDispatch called before initializeDispatch')

  const minorMods: MinorMod[] = mods.map((mod: LightweightMod): MinorMod => (
    {...mod, op: 'set'}
  ))

  // This is the path that clones a plot resource BEFORE modifications IFF:
  //    The mods array contains at least one modification.
  //    The resource to be modified is a 'plot'.
  //    The plot resource loaded at initialization exists and is NOT owned by the current user
  if ( mods.length > 0 && resourceName === 'plots' && history &&
       plot && plot.relationships && userid !== plot.relationships.owner?.data.id ) {
    console.log( 'reactMinorDispatch cloning plot Thunk.  mods:', mods ) 
    asyncDispatch( dispFunc, clonePlotThunk(plot, mods, tablelook, userid, history) )
  }
  
  else if ( dispFunc ) {
    dispFunc( createMinorAction( {
      mods: minorMods,
      resId: resourceid,
      resType: resourceName,
      parentType: resource2name,
      parentId: resource2id
    }))
  }

}


type SetSessionStateType = { (_:Object) : void }
// eslint-disable-next-line @typescript-eslint/no-unused-vars
var setSessionState     : SetSessionStateType 

export const initSessionStateDispatch = ( setState: SetSessionStateType ) => {
  setSessionState     = setState
}

export const sessionStateChangeDispatch = ( mods: LightweightMod[] , unDoMessage: string ) : void => {
  // if ( process.env.NODE_ENV !== 'production' ) {
  //   invariant( unDoMessage !== '', 'sessionState change is missing the unDo message' )
  // }
  const stateChanges = mods.reduce( (acc, mod) => set( acc, mod.path, mod.newVal ), {} )

    if ( dispFunc ) {
      // Update the Redux state
      try {
        dispFunc( updateSession( stateChanges ) ) 
      } catch (err) {
        console.error('sessionStateChangeDispatch error:', err)
      }
    }
}

export default reactDispatch
