import { PureComponent } from 'react'

type OwnProps = {
  width: number
  height: number
  color: string
}
export default class SVGkey extends PureComponent<OwnProps> {
  render() {
    //console.log( 'render SVG key', this.props.width, this.props.height, this.props.color  )
    return (
      <svg
        className={'rc_SVGkey'}
        xmlns="http://www.w3.org/2000/svg"
        style={{ isolation: 'isolate', display: 'block' }}
        viewBox="1 20 100 100"
        preserveAspectRatio='none'
        width={this.props.width}
        height={this.props.height}
      >
        <g id="Group">
          <path d=" M 34.333 20 C 15.931 20 1 34.931 1 53.333 C 1 71.736 10.722 85.625 29.125 85.625 C 34.507 85.625 38.153 81.632 42.667 79.375 L 48.917 85.625 L 64.542 85.625 L 64.542 99.167 L 80.167 99.167 L 80.167 114.792 L 84.333 120 L 101 120 L 101 104.375 L 61.417 61.667 C 63.674 57.153 66.625 54.549 66.625 49.167 C 66.625 30.764 52.736 20 34.333 20 Z  M 30.167 28.333 C 33.887 28.333 34.418 31.522 34.418 35.242 C 34.418 36.73 34.333 38.304 34.333 39.792 C 34.854 45.174 27.573 54.559 21.833 54.375 C 16.625 54.375 10.375 55.417 10.375 50.208 C 10.375 45 24.958 28.333 30.167 28.333 Z "
            fill={this.props.color} />
        </g>
      </svg>
    )
  }
}
