//import type { RootState }           from '../redux/store'  // my testPalette given access to sessionState
import type { SbLayout, SbChildInfo } from '../appCode/getSbFpConfigMappings'

import {useContext}                 from 'react'
import {TableComputedDataContext}   from '../viewTableTriple/TtGetComputedData'
import { TableSb_TabSizing } from './TableSb_TabSizing'
import { TableSb_TabShading } from './TableSb_TabShading'
import { TableSb_TabPublish } from './TableSb_TabPublish'
import {tableSideBarAnimationStart,
    tableSideBarAnimation,
    tableSideBarAnimationStop}    from '../viewTable/tableResponsiveState'



//import { useContext, useMemo } from 'react';


const childInfoArr : SbChildInfo[] = [
    { SbChildComponent: TableSb_TabSizing },  
    { SbChildComponent: TableSb_TabShading },  
    { SbChildComponent: TableSb_TabPublish },  
]

export const useGetTableSb: () => SbLayout = () => {
    const tCD = useContext(TableComputedDataContext);
    const tabInfoArr = [
        {
            tabIndex: 0,
            tabStrg1: 'Table',
            tabStrg2: 'Sizing',
            isErroneous: false,
        },
        {
            tabIndex: 1,
            tabStrg1: 'Table',
            tabStrg2: 'Shading',
            isErroneous: false,
        } 
    ]
    const {canEdit} = tCD
    if (canEdit) tabInfoArr.push({
            tabIndex: 2,
            tabStrg1: 'Publish',
            tabStrg2: 'Table',
            isErroneous: false,
    })

    return {
        viewName: 'tableView',
        parentAnimationStart: () => tableSideBarAnimationStart(tCD),
        parentAnimation: (width: number) => tableSideBarAnimation(width),
        parentAnimationStop: () => tableSideBarAnimationStop(),
        tabInfoArr,
        childInfoArr
    }
}