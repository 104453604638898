import { PureComponent } from 'react'
import constants from '../sharedComponents/constants'

const fontSize = constants.COL_FORMULA_EDITOR_FONT_SIZE

type OwnProps = {
  width: number,
  height: number,
}

type DefaultProps = {
  wrnMessage: string,
  errMessage: string,
}

// Common code for rendering error messages immediately to the right
// of either the Formula: or Description: labels.

export default class EditColMessageDisplay extends PureComponent<OwnProps & DefaultProps > {

  static defaultProps: DefaultProps = {
    wrnMessage: '',
    errMessage: '',
  }

  render ( ) {
    const {width, height, errMessage, wrnMessage } = this.props
    const message = ( errMessage === '' ) ? wrnMessage : errMessage
    const color   = ( errMessage !== '' ) ? 'red'      : 'black'
    const messageArray : Array<string> = message.split( '<br>' )
    const isTwoLineMessage = ( messageArray.length > 1 )
    const paddingTop = (isTwoLineMessage) ? 0 : (height - fontSize)/2

    return (
      <div className={'rc_EditColMessageDisplay'}
        style={{ height, width, fontSize }}>

          <div className={'ErrorMsgText'}
            style = {{
              display: 'inline-block',
              width: '100%', height: '100%',
              overflow:'hidden',
              //background: 'pink',
              paddingTop,
              whiteSpace: 'nowrap',
              paddingLeft: 0,
              color,
            }}>

                  <div>{messageArray[0]}</div>
                  { isTwoLineMessage && <div>{messageArray[1]}</div> }

          </div>
      </div>
    )
}}
