import { PureComponent } from 'react'

type OwnProps = {
  width: string
  height: string
  color: string
}

export default class SVGsortCol extends PureComponent<OwnProps> {

  render() {
    return (
      <svg
        className={'rc_SVGselectCaret'}
        xmlns="http://www.w3.org/2000/svg"
        style={{ isolation: 'isolate', display: 'block' }}
        viewBox="0 0 40 40"
        preserveAspectRatio='none'
        width={this.props.width}
        height={this.props.height}
      >
        <path d=" M 3 17   L   20 24    37 17  "
          stroke={this.props.color}
          strokeWidth="5"
          strokeLinecap="butt"
          fill={'none'}
        />
      </svg>
    )
  }
}
