import type { ActiveFp, LightweightMod } from '../types'
import type { FpChildRenderProps, FpLayout } from '../appCode/getSbFpConfigMappings'

import { useContext } from 'react'
import constants from '../sharedComponents/constants'
import { sessionStateChangeDispatch } from '../sharedComponents/reactDispatch'
import StyleEnumSlider from '../sharedComponents/StyleEnumSlider'
import StyleLinearSlider from '../sharedComponents/StyleLinearSlider'
import { plotLayoutConsts } from '../viewPlotXY/plotXyLayoutConsts'
import { TableComputedDataContext } from '../viewTableTriple/TtGetComputedData'
import { PlotXyComputedDataContext } from '../viewTableTriple/TtGetComputedDataPlotXy'


const TOTAL_HEIGHT = 402
const TOTAL_WIDTH = 452
const NUMERAL_INDENT = 10
const INSTRUCTION_INDENT = 16
const SLIDER_INDENT = 100
const SLIDER_SPACING = -2
// Space allocated to the slider titles
// Must be wide enough to handle the worse case slider title.
// Yet should be identical for all sliders for nice alignment.
// When not passed, uses the slider control's default TITLE_WIDTH
const SLIDER_TITLE_WIDTH = 120

const INSTRUCTION_WIDTH = TOTAL_WIDTH - INSTRUCTION_INDENT - 30

// Every named FP must provide this function. It is called by the FpParent component.
// Specifically, the parent needs this set of information BEFORE it creates
// the FpParent, FpContainer, and internal content.
export const useGetPlotDownload: (activeFp: ActiveFp) => FpLayout = (activeFp) => {
    const tCD = useContext( TableComputedDataContext )

    return {
        activeFp,
        titleBarStrg: 'Download Plot',
        legalDisplayedTabIndex: 0,
        isDraggable: true,
        tabInfoArr: [{
            tabIndex: 0,
            tabStrg1: 'placeholder',
            tabStrg2: 'placeholder',
            isErroneous: false,
        }],
        childInfoArr: [{
            childWidth: TOTAL_WIDTH,
            childHeight: TOTAL_HEIGHT,
            RenderedChild: NavColFp_PlotDownload,
        }],
        getSelectedCellGeometry: () => ({left: 0, top: 0, width: 0, height: 0, isCellVisible: false}), // No cyan highlight
    }
}

const NavColFp_PlotDownload: React.FC<FpChildRenderProps> = (props)=> {
    const tCD = useContext( TableComputedDataContext )
    const plotXyComputedData = useContext( PlotXyComputedDataContext )

    if ( !tCD || !plotXyComputedData ) {
        return null
    }

    const plot = plotXyComputedData.plotResourceObj
    const year = plot.attributes.createdDate?.slice(0,4) || String(new Date().getFullYear())
    const title = `"${plot.attributes.mainTitle}"`
    const URL = `http://datatables/plot/${plot.id}`
    // We need to know with basis (A,B,C) is assigned to bottom axis.
    // This is the basis that owns the tick label angle.
    const bottomBasisPath = plotXyComputedData.bottomAxis.basisPath
    const leftBasisPath = plotXyComputedData.leftAxis.basisPath
    const labelAngle = plotXyComputedData[bottomBasisPath].labelAngle
    const bottomTickSize = plot.attributes[bottomBasisPath].fontScaleTickValues
    const leftTickSize = plot.attributes[leftBasisPath].fontScaleTickValues

    const setRenderSVG = () => {
        const mods: LightweightMod[] = [{
            newVal: true,
            path: 'renderPlotToSVG'
        }]
        sessionStateChangeDispatch(mods, 'Generate Plot SVG')
    }

    return (
        <div className={'rc_DownLoadPlot'} style={{ fontSize: 14 }}>

            <div className={'spacer'} style={{ height: 10 }}/>
            <div
                style={{
                    marginLeft: NUMERAL_INDENT,
                    width: INSTRUCTION_INDENT,
                    display: 'inline-block',
                    verticalAlign: 'top',
                }}
            >{'1.'}</div>

            <div style={{ width: INSTRUCTION_WIDTH, display: 'inline-block' }}>
                {'The top lines (title, publisher) are not downloaded. If republishing, ' +
                'credit the original sources. You may cut & paste this reference:' }
            </div>

            <div className={'spacer'} style={{ height: 6 }}/>
            <div
                style={{
                    marginLeft: NUMERAL_INDENT,
                    width: INSTRUCTION_INDENT,
                    display: 'inline-block',
                    verticalAlign: 'top',
                }}
                >{''}</div>
            <div style={{ width: INSTRUCTION_WIDTH, display: 'inline-block', background: 'rgb(255,231,240)' }}>
                { `Publisher's_Info, ${year}, ${title}, ` + URL }
            </div>

            <div className={'spacer'} style={{ display: 'block', height: 14 }}/>
            <div
                style={{
                    marginLeft: NUMERAL_INDENT,
                    width: INSTRUCTION_INDENT,
                    display: 'inline-block',
                    verticalAlign: 'top',
                }}
            >{'2.'}</div>

            <div style={{ width: INSTRUCTION_WIDTH, display: 'inline-block' }}>
                {'You can set the plot size after it is pasted into your application. ' +
                'But roughly set the shape of the plot now, before download. This will help ' +
                'you chose reasonable font sizes.'
                }
            </div>

            <StyleEnumSlider
                indent={SLIDER_INDENT}
                preSpacer={10}
                titleWidth={SLIDER_TITLE_WIDTH}
                enumLabels={plotLayoutConsts.aspectRatioLabels}
                enumValues={plotLayoutConsts.aspectRatioOptions}
                modPath='attributes.aspectRatio'
                currentValue={plot.attributes.aspectRatio}
                styleName='Aspect Ratio'
                layoutLines={1}
            />

            <div className={'spacer'} style={{ display:'block', height: 10 }}/>
            <div
                style={{
                    marginLeft: NUMERAL_INDENT,
                    width: INSTRUCTION_INDENT,
                    display: 'inline-block',
                    verticalAlign: 'top',
                }}
            >{'3.'}</div>

            <div style={{ width: INSTRUCTION_WIDTH, display: 'inline-block' }}>
                {'Set the font sizes as you wish:'}
            </div>

            <StyleLinearSlider
                indent={SLIDER_INDENT}
                preSpacer={6}
                titleWidth={SLIDER_TITLE_WIDTH}
                styleName={'Legend'}
                modPath={'attributes.fontScaleLegend'}
                currentValue={plot.attributes.fontScaleLegend}
                valMin={plotLayoutConsts.fontSizeMinFactor}
                valMax={plotLayoutConsts.fontSizeMaxFactor}
                numSteps={60}
                displayFixed={2}
                layoutLines={1}
            />

            <StyleLinearSlider
                indent={SLIDER_INDENT}
                preSpacer={SLIDER_SPACING}
                titleWidth={SLIDER_TITLE_WIDTH}
                styleName={'Axis Names'}
                modPath={'attributes.fontScaleAxisNames'}
                currentValue={plot.attributes.fontScaleAxisNames}
                valMin={plotLayoutConsts.fontSizeMinFactor}
                valMax={plotLayoutConsts.fontSizeMaxFactor}
                numSteps={60}
                displayFixed={2}
                layoutLines={1}
            />

            <StyleLinearSlider
                indent={SLIDER_INDENT}
                preSpacer={SLIDER_SPACING}
                titleWidth={SLIDER_TITLE_WIDTH}
                styleName={'Left Axis Values'}
                modPath={`attributes.${leftBasisPath}.fontScaleTickValues`}
                currentValue={leftTickSize}
                valMin={plotLayoutConsts.tickFontSizeMinFactor}
                valMax={plotLayoutConsts.tickFontSizeMaxFactor}
                numSteps={40}
                displayFixed={2}
                layoutLines={1}
            />

            <StyleLinearSlider
                indent={SLIDER_INDENT}
                preSpacer={SLIDER_SPACING}
                titleWidth={SLIDER_TITLE_WIDTH}
                styleName={'Bottom Axis Values'}
                modPath={`attributes.${bottomBasisPath}.fontScaleTickValues`}
                currentValue={bottomTickSize}
                valMin={plotLayoutConsts.tickFontSizeMinFactor}
                valMax={plotLayoutConsts.tickFontSizeMaxFactor}
                numSteps={40}
                displayFixed={2}
                layoutLines={1}
            />

            <StyleEnumSlider
                indent={SLIDER_INDENT}
                preSpacer={SLIDER_SPACING}
                titleWidth={SLIDER_TITLE_WIDTH}
                styleName='Bottom Axis Angle'
                enumLabels={plotLayoutConsts.xTickAngleLabels}
                enumValues={plotLayoutConsts.xTickAngleValues}
                modPath={`attributes.${bottomBasisPath}.labelAngle`}
                currentValue={labelAngle}
                layoutLines={1}
            />

            <button
                className={'downloadButton'}
                onClick={setRenderSVG}
                style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    display: 'block',
                    height: 30,
                    width: 90,
                    position: 'relative',
                    left: 165,
                    top: 10,
                    borderRadius: 6,
                    borderStyle: 'outset',
                    borderWidth: 2,
                    borderColor: constants.COLHEADER_INPUT_BORDER_COLOR,
                    verticalAlign: 'center',
                }}>
                    {'Download'}
            </button>

        </div>
    )
}
