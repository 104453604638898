import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { Component, FormEvent } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import queryString from '../../shared/queryString'
import { Login } from '../types'
import { loginThunk } from '../redux/loginThunks'
import FormLabel from '../sharedComponents/FormLabel'
import FormSubmitButton from '../sharedComponents/FormSubmitButton'
import FormTextInput from '../sharedComponents/FormTextInput'
import Main from '../sharedComponents/Main'
import NavWrapper from '../sharedComponents/NavWrapper'
import ViewWrapper from '../sharedComponents/ViewWrapper'
import { asyncDispatch } from '../sharedFunctions/utils'
import TopMenuBar from '../viewMenuBar/TopMenuBar'

type DispatchProps = {
  createLogin: (login: Login) => void
}

type Props = DispatchProps & RouteComponentProps

type LoginState = {
  login: Login
}

class UserLoginRender extends Component<Props, LoginState> {

  state = {
    login: {
      id: '',
      type: 'logins',
      attributes: {
        login: '',
        password: '',
      },
    } as Login
  }

  onChange = (name: string, value: string) => {
    // Because the inputs are named to match their corresponding values in state,
    // it's super easy to update the state
    const { login } = this.state
    login.attributes[name] = value
    this.setState({ login })
  }

  onSubmit = (event: FormEvent<HTMLFormElement>) => {
    // get our form data out of state
    const { login } = this.state
    const { createLogin } = this.props

    event.preventDefault()

    createLogin(login)
  }


  render() {
    const { login } = this.state
    const { location } = this.props
    const queryParams = location.search ? queryString.parse(location.search) : null
    const message = queryParams ? queryParams.message : undefined
    const headingStr = message === 'import' ? 'Sign in to import tables' : 'Sign in to DataTables'
    const subheadingStr = message === 'import' ? 'Once signed in, close this and restart the import.' : ''

    return (
      <NavWrapper>
        <ViewWrapper>
          <TopMenuBar innerWidth={window.innerWidth} />
          <Main style={{ backgroundColor: 'whitesmoke' }}>
            <div style={{
              margin: '0 auto',
              width: '340px',
            }}>
              <form acceptCharset='UTF-8' onSubmit={this.onSubmit}>
                <div style={{
                  fontWeight: '300',
                  letterSpacing: '-0.5px',
                  textAlign: 'center',
                  marginBottom: '20px',
                }}>
                  <h1>{headingStr}</h1>
                  <em>{subheadingStr}</em>
                </div>
                <div style={{
                  backgroundColor: '#fff',
                  border: '1px solid #d8dee2',
                  borderRadius: '5px',
                  padding: '20px',
                }}>
                  <FormLabel htmlFor='login_field'>Username{/* or email address*/}</FormLabel>
                  <div style={{
                    marginBottom: '15px',
                    marginTop: '5px',
                  }}>
                    <FormTextInput
                      autoComplete='username'
                      autoFocus={true}
                      id='login_field'
                      name='login'
                      tabIndex={1}
                      value={login.attributes.login}
                      onChange={this.onChange}
                    />
                  </div>
                  <FormLabel htmlFor='password'>Password{/*<Link to='/password_reset' style={{
                    float: 'right',
                    fontSize: '12px',
                    textDecoration: 'none',
                  }}>Forgot password?</Link>*/}
                  </FormLabel>
                  <div style={{
                    marginBottom: '15px',
                    marginTop: '5px',
                  }}>
                    <FormTextInput
                      autoComplete='current-password'
                      id='password'
                      name='password'
                      tabIndex={2}
                      type='password'
                      value={login.attributes.password}
                      onChange={this.onChange}
                    />
                  </div>
                  <FormSubmitButton id='commit' tabIndex={3} width='100%'>Sign in</FormSubmitButton>
                </div>
              </form>
              {/*<p style={{
              border: '1px solid #d8dee2',
              borderRadius: '5px',
              marginBottom: '10px',
              marginTop: '16px',
              padding: '15px 20px',
              textAlign: 'center',
            }}>
              New to DataTables? <Link to='/join'>Create an account</Link>.
            </p>*/}
            </div>
          </Main>
        </ViewWrapper>
      </NavWrapper>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: RouteComponentProps): DispatchProps => (
  {
    createLogin: (login: Login) => {
      return asyncDispatch(dispatch, loginThunk(login, ownProps.history))
    }
  }
)

const UserLoginConnected = connect(null, mapDispatchToProps)(UserLoginRender)
const UserLogin = withRouter(UserLoginConnected)
export default UserLogin

