
import type { TertiaryKey, ActiveFp, FpName } from '../types' 
import type { FpSelectedCellGeometry } from '../appCode/getSbFpConfigMappings'
import type { TableHeightObj, TableWidthObj } from '../computedDataTable/layoutCalculator'
import type { TableComputedData} from '../computedDataTable/getDefaultTableComputedData'

import invariant from 'invariant'

export const matchList = ( coord: number, matchList : object ): { key : string, offset: number, dimension: number} => {
  let lastOffset: number = 0
  let lastDimension: number = 0
  let output = {
    key: '',
    offset: 0,
    dimension: 0,
  }
  Object.entries(matchList).every(
    ([key,value]) => {
      const dimension = Number(value)
      const offset = lastOffset + lastDimension
      if ( coord < offset + dimension ) {
        output = { key, offset, dimension }
        return false
      }
      lastOffset = offset
      lastDimension = dimension
      return true
    }
  )
  return output
}

type TableFocusCaseName = 'empty' | 
                        'tableMainTitle' | 'tablePublisherTitle' | 'tableRowFiltering' | 
                        'tableColHeader' | 'tablePinnedCell' | 'tableDataCell' |
                        'pinnedRowNumber' | 'dataRowNumber' |
                        'drag' | 'sortAscending' | 'sortDescending' |  'hide' | 'unhide' 

export type ViewTable_UnderlyingTile = {
    // These four values outline the semi-transparent colored divs in debugging component.
    parentTop : number,
    parentLeft: number,
    parentWidth: number,
    parentHeight: number,  
    // These are the values that MAY be written to the activeFp state.
    colKey: number,
    rowKey: number,
    tertiaryKey: TertiaryKey,
    // These values are individual table cells
    colIndex: number,
    rowIndex: number,
    cellTop : number,
    cellLeft: number,
    cellWidth: number,
    cellHeight: number,
    // Unique name for the onMouse event handlers
    // Sometimes the caseName calls for an action (sort, hide, unhide, etc.)
    // Sometimes the caseName will set activeFp and open the floating palette.
    caseName: TableFocusCaseName 
}


// Given a (x,y) point, and the widthObj, and heightObj,
// find the corresponding location in the layout.
export const xyClickToTableViewTile = ( x: number, y: number, tCD: TableComputedData) : ViewTable_UnderlyingTile => {

  const {widthObj:w, heightObj:h, styleObj:s, sortedRowKeys, 
         derivedColOrder, scrollLeft, scrollTop:scrollTopIn, pinnedRowKeys } = tCD
  const {startColLeft, displayedColWidths} = w
  const stopColLeft = ( colIndex: number ) : number => {
    return startColLeft[colIndex] + displayedColWidths[colIndex]
  }
  let defaultReturn : ViewTable_UnderlyingTile = {
    parentTop : 0,
    parentLeft: 0,
    parentWidth: 0,
    parentHeight: 0,
    colKey: -1,
    rowKey: -1,
    tertiaryKey: 'empty',
    colIndex: 0,
    rowIndex: 0,
    cellTop : 0,
    cellLeft: 0,
    cellWidth: 0,
    cellHeight: 0,
    caseName: 'empty'
  }
  // Check rowFilter icon first. It is a special case because it is NOT placed at 
  // the intersection of a horz and vert slice.
  const funnelLeft = w.centeringOffset
  const funnelTop  = h.colControlsTop
  const funnelSize = h.scaledFunnelIconSize
  if (x > funnelLeft && x < funnelLeft + funnelSize && y > funnelTop && y < funnelTop + funnelSize) {
      return { ...defaultReturn,
        parentTop : funnelLeft,
        parentLeft: funnelTop,
        parentWidth: funnelSize,
        parentHeight: funnelSize,
        cellTop : funnelTop,
        cellLeft: funnelLeft,
        cellWidth: funnelSize,
        cellHeight: funnelSize,
        caseName: 'tableRowFiltering'
      }
  }
  // Everything else is located by horz and vert slices.
  // Here I define the slices:
  const grid = h.borderThickness
  // vertSlices, from top to bottom:
  const heightList = {
      gapTopMainTitle : h.gapTopMainTitle,
      mainTitle     : h.mainTitle,
      publisherTitle  : h.publisherTitle,
      gapSourceTitleControls : h.gapSourceTitleControls,
      colControls: h.colControls,
      gapControlsHead: h.gapControlsHead + grid,
      head : h.headerHeight,
      pinned: h.pinnedHeight,
      gapBorderHeadData: h.gapHeadData + 2*grid,
      data : h.dataAllocated,
      horzScroll: h.horzScrollControlHeight + grid,
      emptySpace: h.emptySpaceBelowTable,
      statsHeight: h.statsHeight,
  }
  // horzSlices, from left to right:
  const widthList = {
      leftMargin : w.centeringOffset,
      rowNumbers : w.rowNumbers,
      lockedLeftGridBorder : grid,
      locked : w.lockedAllocated,
      gapBorderLockedMoving : (w.isCombinedTable || w.numLockedCols === 0 ) ? 0 : w.gapLockedMoving + 2*grid,
      moving : w.movingAllocated,
      movingRightGridBorder : grid,
      scroll : s.gapTableScroll + s.scrollThumbWidth,
      rightMargin : w.centeringOffset,
  }
  const { key: focusHorzSlice, offset:parentTop,  dimension:parentHeight } = matchList ( y, heightList )
  const { key: focusVertSlice, offset:parentLeft, dimension:parentWidth  } = matchList ( x, widthList )
  defaultReturn = { ...defaultReturn, parentTop, parentLeft, parentWidth, parentHeight }
  // default definition for cell geometry is the parent geometry
  // otherwise, the cell is some subset of the parent geometry (smaller rectangle within the parent rectangle)
  let cellTop = parentTop
  let cellLeft = parentLeft
  let cellWidth = parentWidth
  let cellHeight = parentHeight
  // default definitions for colKey, rowKey, indices, etc:
  let yRow:number=0, rowIndex:number=0, rowKey:number=0, colIndex:number=0, colKey:number=0
  // Calc the cell position and indices (colKey and rowKey )
  let xCursorLocationInContent = 0
  //let xCursorLocationInCell = 0
  let cellTopWithRespectToContainer = 0
  let cellTopWithRespectToView = 0
  let tertiaryKey: TertiaryKey = 'empty'
  const scrollTop = (focusHorzSlice === 'data') ? scrollTopIn : 0
  let caseName: TableFocusCaseName = 'empty'
  // Check the mainTitle and publisherTitle first.  
  // They are special cases we cannot treat horizontal and vertical slices independently.
  if ( focusHorzSlice === 'mainTitle' ) {
      caseName = ( x < w.mainTitleLeft || x > w.mainTitleLeft + w.mainTitle ) ? 'empty' : 'tableMainTitle'
      cellTop = parentTop
      cellHeight = parentHeight
      cellLeft = w.mainTitleLeft
      cellWidth = w.mainTitle
      return { ...defaultReturn, caseName, cellTop, cellLeft, cellWidth, cellHeight }   
  }
  if (focusHorzSlice === 'publisherTitle' ) {
      caseName = ( x < w.publisherTitleLeft || x > w.publisherTitleLeft + w.publisherTitle ) ? 'empty' : 'tablePublisherTitle'
      cellTop = parentTop
      cellHeight = parentHeight
      cellLeft = w.publisherTitleLeft
      cellWidth = w.publisherTitle
      return { ...defaultReturn, caseName, cellTop, cellLeft, cellWidth, cellHeight } 
  }

  switch ( focusHorzSlice ) {
    case 'colControls':
        cellTop = parentTop
        cellHeight = parentHeight
        break
    case 'data' :
    case 'pinned' :
        yRow = y - parentTop + scrollTop
        rowIndex = Math.max( 0, Math.trunc( yRow / h.rowHeight))
        rowKey = (focusHorzSlice === 'data') ? sortedRowKeys[rowIndex] : pinnedRowKeys[rowIndex ]
        cellTopWithRespectToContainer = rowIndex * h.rowHeight - scrollTop
        cellTopWithRespectToView = cellTopWithRespectToContainer + parentTop
        cellTop = cellTopWithRespectToView
        cellHeight = h.rowHeight
        tertiaryKey = (focusHorzSlice === 'data') ? 'dataCell' : 'pinnedCell'
        break
    case 'head' :
        cellTop = parentTop
        cellHeight = parentHeight
        break
    default:
        caseName = 'empty'
  }

  let i = 0
  switch ( focusVertSlice ) {
    case 'locked' :
    case 'moving' :
        //xCursorLocationInContainer = (x - parentLeft)
        xCursorLocationInContent = (focusVertSlice === 'locked') ? (x-parentLeft) : (x-parentLeft+scrollLeft)
        if (focusVertSlice === 'locked') {
          for (i=0; i< w.numLockedCols; i++ ) {
            if ( xCursorLocationInContent < stopColLeft(i) ) { break }
          }
        } else if (focusVertSlice === 'moving' && !w.isCombinedTable) {
          for (i=w.numLockedCols; i< startColLeft.length; i++ ) {
            if ( xCursorLocationInContent < stopColLeft(i) ) { break }
          }
        } else if (focusVertSlice === 'moving' &&  w.isCombinedTable) {
          for (i=0; i< startColLeft.length; i++ ) {
            if ( xCursorLocationInContent < stopColLeft(i) ) { break }
          }
        }
        i = Math.min( i, startColLeft.length-1 )   // Safety check
        colIndex = i
        colKey = derivedColOrder[i]
        cellWidth= displayedColWidths[i]
        cellLeft = parentLeft + stopColLeft(i) - cellWidth
        if ( w.isCombinedTable || focusVertSlice === 'moving' ) cellLeft -= scrollLeft
        if (focusHorzSlice === 'head') { caseName = 'tableColHeader' }
        else if (focusHorzSlice === 'data') { caseName = 'tableDataCell' }
        else if (focusHorzSlice === 'pinned') { caseName = 'tablePinnedCell' }
        break
    case 'rowNumbers':
        caseName = (focusHorzSlice === 'pinned') ? 'pinnedRowNumber' : 'dataRowNumber'
        colKey = -1
        cellLeft = widthList.leftMargin
        cellWidth = widthList.rowNumbers
        break
    case 'gapBorderLockedMoving' :
    case 'scroll' :
        // A 'unhide' control MAY be present in the gap between tables or above the vertical scroll bar.
        // At this point, we just want to avoid returning the 'defaultUnderlyingTile' which is 'emptySpace'.
        break
    default:
        caseName = 'empty'
  }

  // Extra refinement of location needed for col controls
  if (focusHorzSlice === 'colControls') {
      const xCursorLocationInCell = x - cellLeft
      const yCursorLocationInCell = y - parentTop
      //out.controlName = ''
      // Controls set into square containers, of h.colControls in both x and y.
      if ( focusVertSlice === 'locked' || focusVertSlice === 'moving' ) {
          // Next line looks strange because we assign a height to a width.
          // This works because the control is a square and the square size is defined by h.colControls.
          cellWidth = h.colControls
          if ( xCursorLocationInCell < 1*h.colControls ) {
            caseName = 'hide'   // May end up being 'hide' or 'unhide'
          } else if ( xCursorLocationInCell < 2*h.colControls ) { 
            caseName = 'drag'; cellLeft += h.colControls
          } else if ( xCursorLocationInCell < 3*h.colControls ) {
            caseName = 'sortAscending'   // May end up being 'sortAscending' or 'sortDescending'
            cellLeft += 2 * h.colControls
          } else { 
            caseName = 'empty'
          }
      }
      if (caseName === 'sortAscending') {
          cellHeight = h.colControls/2
          if ( yCursorLocationInCell >  h.colControls/2 ) { 
            caseName = 'sortDescending'; cellTop += h.colControls/2
          } else { 
            caseName = 'sortAscending'
          } 
      }
      if ( caseName === 'hide' && colKey >= 0 ) {
          // Was this click the 'hide', 'unhide' or 'empty' space in case of no hidden columns?
          if (colIndex > 0 && displayedColWidths[colIndex - 1] > 0) {
            // no unhide control; We will let the capture area include the full colControls height.
            caseName = 'hide'
          } else if ( yCursorLocationInCell >  h.colControls/2 ) { 
            // if the yCoord is in the bottom half of the control, then it is a 'hide' control.`
            // Same as above, but we need to split the control hieght into halves for both hide/unhide
            cellTop += h.colControls/2
            cellHeight = h.colControls/2
            caseName = 'hide' 
          } else {
            // cursor is top half and unhide control is shown
            cellHeight = h.colControls/2
            caseName = 'unhide'
          }  
      }

      // Is the last lockedCol unhide control present?
      // zero width column means the unhide control is displayed
      if (  w.numLockedCols && displayedColWidths[w.numLockedCols-1] === 0 && focusVertSlice === 'gapBorderLockedMoving') {
          caseName = 'unhide'
          colKey = derivedColOrder[w.numLockedCols-1]
          colIndex = w.numLockedCols-1
          cellLeft = parentLeft
          cellWidth = parentWidth
      }
      const lastColIndex = displayedColWidths.length - 1
      if ( displayedColWidths[lastColIndex] === 0   // zero width column means the unhide control is displayed
              && focusVertSlice === 'scroll'        // Click area is intersection of scroll Col and controls Row
              // Right edge of table must be in current scroll view. (allow it to be 'near'.)
              && w.movingRequired - ( w.movingAllocated + scrollLeft ) < 20 ) { 
          caseName = 'unhide'
          colKey = derivedColOrder[lastColIndex]
          colIndex = lastColIndex
          cellLeft = parentLeft
          cellWidth = parentWidth
      }
  }
  return {
    parentTop, parentLeft, parentWidth, parentHeight,
    colKey, rowKey, tertiaryKey, colIndex, rowIndex,
    cellTop, cellLeft, cellWidth, cellHeight, caseName }
}


// Given the activeFp, return the TableViewTile
// Returns the geometric locations {top, left, height, width} that will be
// used for the highlighter outline when selected.  Alse whether the activeFp
// selected cell is visible (may be scrolled out-of-view)

// THIS INTERFACE SUPPORTS RENDERING. 
// ALL of these inputs can potentially change between renders.
// And tCD is up-to-date each render and the source of truth for all of these values.
export const activeFpToTableViewTile = ( tCD: TableComputedData) : FpSelectedCellGeometry => {
    const {scrollLeft, scrollTop, widthObj:w, heightObj:h, derivedColOrder, 
                          sortedRowKeys, pinnedRowKeys, sessionStateActiveFp} = tCD
    return activeFpToTableViewTile_rState( scrollLeft, scrollTop, w, h, sessionStateActiveFp, 
                                           derivedColOrder, sortedRowKeys, pinnedRowKeys )
}

// THIS INTERFACE SUPPORTS responsiveState animations.
// Where tCD is static but scroll positions, widthObj, and heightObj are dynamic.
// We CANNOT use the above interface because the tCD is NOT recalculated each animation frame!
export const activeFpToTableViewTile_rState = (scrollLeft:number, scrollTop:number, w:TableWidthObj, h:TableHeightObj,
                                        activeFp:ActiveFp, derivedColOrder:Array<number>, sortedRowKeys:Array<number>, 
                                        pinnedRowKeys:Array<number> ) : FpSelectedCellGeometry => {
    const {fpName, primaryKey:colKey, secondaryKey:rowKey, tertiaryKey} = activeFp                    
    const expectedTableFP = ['none', 'tableMainTitle', 'tablePublisherTitle', 'tableRowFiltering', 'tableColHeader',
    'tableCellNumber', 'tableCellString', 'tableCellHyperlink', 'tableCellFormula', 'tableCellDateTime'] 
    if( !expectedTableFP.includes(fpName) ) {
      if ( process.env.NODE_ENV === 'development') {
        invariant( false, `Unrecoginized FP name: ${fpName}`)
      } else {
        return {top:0, left:0, width:200, height:50, isCellVisible:true}
      }
    }
    let colIndex, left, width, right, rowIndex, top, height, isMovingCol, movingRight
    let isCellVisible = true // assumption
    //console.log( fpName, colKey, rowKey, tertiaryKey )
    switch (fpName) {
      case 'tableMainTitle' :
          return {top:h.gapTopMainTitle, left:w.mainTitleLeft, width:w.mainTitle, height:h.mainTitle, isCellVisible:true}
      case 'tablePublisherTitle' :
          return {top:h.gapTopMainTitle+h.mainTitle, left:w.publisherTitleLeft, width:w.publisherTitle, height:h.publisherTitle, isCellVisible:true}
      case 'tableRowFiltering' :
          return {top:h.colControlsTop, left:w.centeringOffset, width:h.scaledFunnelIconSize, height:h.scaledFunnelIconSize, isCellVisible:true}
      case 'tableColHeader' :
      case 'tableCellNumber':
      case 'tableCellString':
      case 'tableCellHyperlink':
      case 'tableCellFormula':
      case 'tableCellDateTime':
          // cell Left and Width calculations
          colIndex = derivedColOrder.indexOf(colKey)
          isMovingCol = (colIndex >= w.numLockedCols)
          left = (colIndex < w.numLockedCols) ? w.lockedLeft + w.startColLeft[colIndex] : w.movingLeft + w.startColLeft[colIndex] - scrollLeft
          width = w.displayedColWidths[colIndex]
          right = left + width
          // Moving Col MAY be partially or fully out-of-view to the left or right
          if (isMovingCol ) {
              if (right <= w.movingLeft) { 
                isCellVisible = false
              } else {
                // The cell left edge cannot be 'left' of the movingTable left edge.
                left = Math.max(left, w.movingLeft)
                width = right - left
              }
              // Case of selected cell partially or fully out-of-view to the right
              movingRight = w.movingLeft + w.movingAllocated
              if ( left >= movingRight) { 
                isCellVisible = false
              } else {
                // The cell right edge cannot be 'left' of the movingTable left edge.
                right = Math.min(right, w.movingLeft + w.movingAllocated)
                width = right - left
              }
          } 
          // cell Top and Height calculations
          height = h.rowHeight  // assumption
          rowIndex = (tertiaryKey === 'dataCell') ? sortedRowKeys.indexOf(rowKey)
                                                  : pinnedRowKeys.indexOf(rowKey)
          if (fpName === 'tableColHeader') {
            top = h.headTop + h.borderThickness
            height = h.headerHeight
            return {top, left, width, height, isCellVisible}
          } 
          if  (tertiaryKey === 'pinnedCell') {
            top = h.headTop + h.borderThickness + h.headerHeight + rowIndex * h.rowHeight
            return {top, left, width, height, isCellVisible}
          }
          // All that remains is the top/height/isCellVisible calculations for either dataCell
          top = (rowIndex * h.rowHeight) - scrollTop + h.dataTop
          // top/height now set, but may be scrolled out-of-view! 
          if ( top + height < h.dataTop || top > h.dataTop + h.dataAllocated ) {
            return {top, left, width, height, isCellVisible: false }                   
          }
          // cell MAY be partially visible at the top or bottom of scrolled view
          if ( top < h.dataTop ) {
            height = height - (h.dataTop - top)
            top = h.dataTop
          } else if ( top + height > h.dataTop + h.dataAllocated ) {
            height = h.dataTop + h.dataAllocated - top
          }
          return {top, left, width, height, isCellVisible}
      default: 
        return {top:0, left:0, width:0, height:0, isCellVisible:false}
    }
}



export const getTableFpNameFromCaseName = (caseName: TableFocusCaseName, colKey:number, tCD:TableComputedData) : FpName => {
    switch (caseName) {
        case 'tableMainTitle' :
            return 'tableMainTitle' 
        case 'tablePublisherTitle' :
            return 'tablePublisherTitle'
        case 'tableRowFiltering' :
            return 'tableRowFiltering'
        case 'tableColHeader' :
            return 'tableColHeader'
        case 'tablePinnedCell' :
        case 'tableDataCell' :   
            return getTableFpNameFromColKey( colKey, tCD )
        default:
            return 'none'
    } 
}  



export const getTableFpNameFromColKey = (colKey: number, tCD:TableComputedData) : FpName => {
    const {colDataType} = tCD.derivedColAttributesArray[colKey]
    let newFpName : FpName = 'none'
    switch( colDataType ) {
        case 'string'           : {newFpName = 'tableCellString'; break}
        case 'number'           : {newFpName = 'tableCellNumber'; break}
        case 'numberSeconds'    : {newFpName = 'tableCellNumber'; break}
        case 'numberDegrees'    : {newFpName = 'tableCellNumber'; break}
        case 'hyperlink'        : {newFpName = 'tableCellHyperlink'; break}
        case 'depColExpression' : {newFpName = 'tableCellFormula'; break}
        case 'dateTime'         : {newFpName = 'tableCellDateTime'; break}
        default                 : {newFpName = 'none'; break}
    }
    return newFpName
}

// Keypress handler will step the cell location (by index), but 
// only if current fpName is a some table cell/header editor.
export const isTableCellEditor = (fpName: FpName) : boolean => {  
  return ( 
        fpName === 'tableColHeader' || 
        fpName === 'tableCellNumber' || 
        fpName === 'tableCellString' || 
        fpName === 'tableCellHyperlink' || 
        fpName === 'tableCellFormula' || 
        fpName === 'tableCellDateTime' 
  )
}
