import './ie11polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import {Provider}  from 'react-redux'
// store must be imported before App to prevent bizzare import/export conflicts
import store from './client/redux/store'
import App from './client/viewApp/App'

import './client/viewApp/index.css'

const rootElement = document.getElementById('root')
if (rootElement) {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  )
}
