import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import ErrorBoundary from './ErrorBoundary'
import Notify from '../viewNotifications/Notify'
import TopLevelUI from '../tablesParent/TopLevelUI'
import Install from '../viewImport/Install'
import ReactHint from '../viewTooltips/ReactHint'
import UserCreate from '../viewUser/UserCreate'
import UserLogin from '../viewUser/UserLogin'
import UserSettings from '../viewUser/UserSettings'
import '../viewTooltips/index.css'
import { initSessionStateDispatch } from '../sharedComponents/reactDispatch'
import { RootState } from '../redux/store'
import { SessionState } from '../appCode/getDefaultSessionState'
import { updateSession } from '../redux/sessionReducer'
import useInitAuth from '../hooks/useInitAuth'


type Props = {}

export var appElement: HTMLDivElement | null | undefined = null

const App: React.FC<Props> = () => {

  useInitAuth() // First hook to run
  const dispatch = useDispatch()
  const session = useSelector((state: RootState) => state.session)
  const [userPages, setUserPages] = useState(false)
  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth)
  const [windowInnerHeight, setWindowInnerHeight] = useState(window.innerHeight)

  useEffect(() => {
    // Initialize session state, mouse wheel event listener, and window resize event listener
    const setSessionHandler = (session: Partial<SessionState>) => {
      dispatch(updateSession(session))
    }
    initSessionStateDispatch(setSessionHandler)

    // Find parent element with specific class name
    const findParentOfClass = (target: EventTarget & ChildNode, className: string): HTMLElement | null => {
      let p = target.parentNode
      while (p && !(p instanceof HTMLElement && p.className === className)) {
        p = p.parentNode
      }
      return p instanceof HTMLElement ? p : null
    }

    // Handle mouse wheel events
    const handleMouseWheel = (e: WheelEvent) => {
      const deltaX = e.deltaX
      const deltaY = e.deltaY
      if ((deltaX !== 0 || deltaY !== 0) && e.target instanceof HTMLElement) {
        const touchContainerData = findParentOfClass(e.target, 'TouchContainer')
        if (touchContainerData) {
          if (
            (deltaX < 0 && touchContainerData.scrollLeft === 0) ||
            (deltaX > 0 &&
              Math.abs(
                touchContainerData.scrollLeft + touchContainerData.clientWidth - touchContainerData.scrollWidth,
              ) < 0.6) ||
            (deltaY < 0 && touchContainerData.scrollTop === 0) ||
            (deltaY > 0 &&
              Math.abs(
                touchContainerData.scrollTop + touchContainerData.clientHeight - touchContainerData.scrollHeight,
              ) < 0.6)
          ) {
            e.preventDefault()
          }
        }
      }
    }
    const handleWindowResize = () => {
      setWindowInnerWidth(window.innerWidth)
      setWindowInnerHeight(window.innerHeight)
    }

    window.addEventListener('wheel', handleMouseWheel, { passive: false })
    window.addEventListener('resize', handleWindowResize, false)

    // Cleanup on unmount
    return () => {
      window.removeEventListener('wheel', handleMouseWheel)
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [dispatch, setWindowInnerWidth, setWindowInnerHeight, setUserPages,
                windowInnerWidth, windowInnerHeight,userPages, session])



  return (
    <ErrorBoundary name="App">
      <DndProvider backend={HTML5Backend}>
        <Router>
          <div
            className={'rc_AppRouter'}
            style={{
              height: '100%',
              width: '100%',
              position: 'relative',
              left: 0,
              top: 0,
              overflow: 'hidden',
            }}
          >
            <Notify />
            <ReactHint />
            <Switch>
              <Route path="/login" component={UserLogin} />
              <Route path="/sitePage" render={() => <TopLevelUI view={'sitePageView'} />} />
              <PrivateRoute path="/search" render={() => <TopLevelUI view={'searchView'} />} />
              <PrivateRoute path="/table/:tableid" render={() => <TopLevelUI view={'tableView'} />} />
              <PrivateRoute path="/plot/:plotid" render={() => <TopLevelUI view={'xyPlotView'} />} />
              {userPages ? (
                <>
                  <PrivateRoute path="/home" render={() => <TopLevelUI view={'homeView'} />} />
                </>
              ) : null}
              <PrivateRoute path="/userCreate" render={() => <UserCreate />} />
              {userPages ? (
                <>
                  <PrivateRoute path="/install" render={() => <Install />} />
                  <PrivateRoute path="/settings" render={() => <UserSettings />} />
                </>
              ) : null}
              <PrivateRoute path="/" render={() => <Redirect to="/search" />} />
            </Switch>
          </div>
        </Router>
      </DndProvider>
    </ErrorBoundary>
  )
}

type RouteProps = {
  path: string
  render: () => JSX.Element
}

const PrivateRoute = (props: RouteProps) => {
  return localStorage.getItem('token') ? <Route {...props} /> : <Redirect push to="/login" />
}

export default App