import { AnyAction, Dispatch } from "@reduxjs/toolkit"
import { GetStateFunc } from "../types"


export function typedKeys<T>(obj: Object): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>
}

export function typedKeyValue<T extends object | ArrayLike<T[keyof T]>>(obj: T): Array<[keyof T, T[keyof T]]> {
  return Object.entries(obj) as Array<[keyof T, T[keyof T]]>;
}

export async function asyncDispatch<ReturnType>(dispatch: Dispatch<AnyAction>, thunk: (dispatch: Dispatch<AnyAction>, getState: GetStateFunc) => Promise<ReturnType>): Promise<ReturnType> {
  return dispatch(thunk as unknown as AnyAction) as unknown as Promise<ReturnType>
}


export const getClosestEnumeratedValue = (val: number, options: number[]): number => {
  return options[getClosestEnumeratedIndex(val, options)]
}


export const getErrorMessage = (error: unknown): string => {
  if (error instanceof Error) {
    return error.message
  }
  const message = JSON.stringify(error)
  console.warn(`caught non error object: ${message}`)
  return message
}



export function deepClone<T>(source: T): T {
  return structuredClone(source)
}

export const getClosestEnumeratedIndex = (inValue: number, options: number[]): number => {
  if (isNaN(inValue)) {
    return Math.floor(options.length / 2)
  }
  let foundIndex = options.indexOf(inValue)
  if (foundIndex >= 0) {
    return foundIndex
  }
  // Otherwise, find the closest legal alternative and return the array index.
  if (inValue < options[0]) {
    return 0
  }
  const numOptions = options.length
  for (let i = 1; i < numOptions; i++) {
    if (options[i] > inValue) {
      return (inValue > (options[i] + options[i - 1]) / 2) ? i : i - 1
    }
  }
  return (numOptions - 1)
}


export const removeAttrFromObject = <O extends object, A extends keyof O>(
  object: O,
  attr: A
): Omit<O, A> => {
  const newObject = { ...object }

  if (attr in newObject) {
    delete newObject[attr]
  }

  return newObject
}

export function lastVal<T>(a: Array<T>): T {
  return a[a.length - 1]
}

export const px = (value: number): string => { return `${value}px` }
