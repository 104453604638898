import type { AnyAction, Middleware, PayloadAction } from '@reduxjs/toolkit'
import type { AxiosRequestConfig } from 'axios'
import { initAuth, setAuthToken, setAuthUserId } from './appReducer'
import { apiClearState, setAxiosConfig } from '../jsonapi'
import type { JsonApiDocument } from '../jsonapi/types'
import type { MiddlewareFunc, StoreType } from './store'

const isTokenExpired = (token: string): boolean => {
  const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
  return (Math.floor((new Date()).getTime() / 1000)) >= expiry;
}

const initAuthHandler = (store: StoreType): void => {
  let authToken = localStorage.getItem('token') || ''
  const authUserId = localStorage.getItem('userid') || ''
  if (authToken && isTokenExpired(authToken)) {
    authToken = ''
  }
  store.dispatch(setAuthToken(authToken))
  store.dispatch(setAuthUserId(authUserId))
}

const setAuthTokenHandler = (store: StoreType, action: PayloadAction<string>): void => {
  const authToken = action.payload
  if (authToken) {
    if (authToken !== localStorage.getItem('token')) {
      localStorage.setItem('token', authToken)
    }
  } else {
    localStorage.removeItem('token')
    store.dispatch(apiClearState())
  }
  const axiosConfig: AxiosRequestConfig<JsonApiDocument> = {baseURL: `${window._datatables_.serverURL}jsonapi/`}
  if (authToken) {
    axiosConfig.headers = {'Authorization': `bearer ${authToken}`}
  }
  store.dispatch(setAxiosConfig(axiosConfig))
}

const setAuthUserIdHandler = (store: StoreType, action: PayloadAction<string>): void => {
  const authUserId = action.payload
  if (authUserId) {
    if (authUserId !== localStorage.getItem('userid')) {
      localStorage.setItem('userid', authUserId)
    }
  } else {
    localStorage.removeItem('userid')
  }
  //window._LTracker.push({session_id: authUserId})
}

const authMiddleware: Middleware<(store: StoreType) => (next: MiddlewareFunc) => MiddlewareFunc> =
  (store: StoreType) =>
    (next: MiddlewareFunc) => (
      (action: AnyAction): AnyAction => {
        if (initAuth.match(action)) {
          initAuthHandler(store)
        } else if (setAuthToken.match(action)) {
          setAuthTokenHandler(store, action)
        } else if (setAuthUserId.match(action)) {
          setAuthUserIdHandler(store, action)
        }
        return next(action)
      }
    )

export default authMiddleware
