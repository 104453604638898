/*
 * Compare two objects by reducing an array of keys in obj1, having the
 * keys in obj2 as the intial value of the result. Key points:
 *
 * - All keys of obj2 are initially in the result.
 *
 * - If the loop finds a key (from obj1, remember) not in obj2, it adds
 *   it to the result.
 *
 * - If the loop finds a key that are both in obj1 and obj2, it compares
 *   the value. If it's the same value, the key is removed from the result.
 */

// JPS note:  From Johan Persson in StackOverflow

import { isEqual } from 'radash'
import invariant from 'invariant'
import type { GenericObject } from '../types'

export const getObjectDiff = (obj1:GenericObject, obj2:GenericObject) => {
    const diff = Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
            result.push(key);
        } else if (isEqual(obj1[key], obj2[key])) {
            const resultKeyIndex = result.indexOf(key);
            result.splice(resultKeyIndex, 1);
        }
        return result;
    }, Object.keys(obj2));
    return diff;
}


// JPS version.
// IF diff result is ONLY 1 attribute, then do a further recursive diff on that attribute.
// This will essentially follow a path down the obj tree, until
// it comes to a leaf element or an node with multiple attribute differences.
export const getObjectDiffRecursive = (obj1:GenericObject, obj2:GenericObject): string => {

    if (typeof( obj1 ) !== 'object' ) { return '' }  // Exit path for a leaf node of Obj1
    if (typeof( obj2 ) !== 'object' ) { return '' }  // Exit path for a leaf node of Obj2

    /*
    const diff = Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
            result.push(key);
        } else if (isEqual(obj1[key], obj2[key])) {
            const resultKeyIndex = result.indexOf(key);
            result.push(key);
        }
        return result;
    }, Object.keys(obj2));
*/

    let diff = getObjectDiff(obj1, obj2)

    if (diff.length === 0) { return '' }   // exit path; identical objects
    if (diff.length > 1  ) { return '[' + diff.toString() + ']' }  // exit path: node with multiple changes
    const thisKey = String( diff[0] )
    const childKey = getObjectDiffRecursive(obj1[thisKey], obj2[thisKey]) // recursive call:
    if ( childKey === '' ) { return thisKey }  // Child was a leaf node
    return thisKey + '.' + childKey  // prepending a parent node to child node(s)
}

let objectID = new WeakMap();
let nextID = 1;
const DEBUG = false

/**
 * Returns a hash code from an object
 * @param  {object} obj The object to hash.
 * @forceObjRef {boolean}  Force the hash to always use objectId (reference)
 * @return {number}    A 32bit integer
 **/
function hashString(str: string): number {
    let hash = 5387;
    for (let i = 0, len = str.length; i < len; i++) {
        let chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash
}
function getObjectId(obj: object): string {
    if (!objectID.has(obj)) {
        objectID.set(obj, nextID++);
    }
    return objectID.get(obj).toString();
}
function getObjectString(obj: object, forceObjRef: boolean): string {
    let str;
    if (forceObjRef) {
        str = getObjectId(obj);
    } else {
        try {
            str = JSON.stringify(obj, Object.keys(obj).sort());
        } catch (e) {
            str = getObjectId(obj);
        }
    }
    return str;
}
export function hashCode(obj: object, forceObjRef: boolean = false): number {
    if (DEBUG && !forceObjRef) {
        console.trace('hashCode: forceObjRef is false.  This is a performance hit.  Use sparingly.');
    }
    const str = getObjectString(obj, forceObjRef);
    return hashString(str);
}
export function hashParamsAndRefs( paramsObj: object, refsObj: {[key:string]: any } ) : number {
    var partialHashString : string = String( hashCode(paramsObj))
    for ( const key in refsObj ) {
        let indexedItem = refsObj[key]
        if (!indexedItem ) {continue}
        // 2nd argument 'true' forces hash to use the object's reference.
        partialHashString += String( hashCode( indexedItem, true ))
    }
    return hashCode( {partialHashString} )
}



function isRefsShallowlyEqual<ObjType>(typedObj1: ObjType, typedObj2: ObjType):
    { isRefsMatch : boolean, diff : string[] } {
    const obj1: GenericObject = typedObj1 as GenericObject
    const obj2: GenericObject = typedObj2 as GenericObject

    // Args Must be two objects (never missing);
    // Else consider this a coding error.
    var objInputsValid =
              (typeof obj1) === 'object' &&
              typeof obj2 === 'object' &&
              obj1 !== null &&
              obj2 !== null
    if ( process.env.NODE_ENV === 'development' && !objInputsValid) {
        invariant ( objInputsValid, 'isRefsShallowlyEqual: An input is NOT an object' )
    }
    if (!objInputsValid) {
        return { isRefsMatch: false, diff: ['One or more input NOT an object'] }
    }

    // And MUST be the same length
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)
    //const keys1len = keys1.length
    //const keys2len = keys2.length
    //if ( process.env.NODE_ENV === 'development' ) {
        // We allow either object to be '0' keys,
        // BUT if keys are present in both objects, then they had better be equal length!
    //    var isUnequalLength = keys1len > 0 && keys2len > 0 &&  keys1len !== keys2len
    //    invariant ( !isUnequalLength, 'isRefsShallowlyEqual: Number of keys are NOT equal.' )
    //}
    if (keys1.length !== keys2.length) {
        return { isRefsMatch: false, diff: ['Comparison objects are not the same length'] }
    }

    const diffArray = []
    for ( const key of keys1 ) {
        var val1 = obj1[key]
        var val2 = obj2[key]

        //console.log ( key, obj1[key], obj2[key] )
        if (obj1[key] === obj2[key]) { continue }
        // RULE: null and undefined are equal
        if ((val1 === null || val1 === undefined) &&  (val2 === null || val2 === undefined)) { continue }
        // RULE: Empty arrays: define as equal, even if differing references.
        // I try to use code written as: emptyArray = new Array(0)
        // and consistentlly return 'emptyArray'.  However, for now I'll
        // let this approach be a preference,  unless this causes problems
        // and we with to later enforce a stringent 'same reference' approach.
        if ( (Array.isArray(val1) && val1.length === 0 ) &&
             (Array.isArray(val2) && val2.length === 0 ) ) { continue }
        diffArray.push( key )
    }
    if (diffArray.length > 0) {
        return { isRefsMatch: false, diff: diffArray }
    }
    return { isRefsMatch: true, diff: [] }
}


export type PriorMemoizedResult<Params, Refs, Result> = {
    result:    Result,
    paramsObj: Params,
    refsObj:   Refs,
}

export type MemoizedResult<Params, Refs, Result> = {
    result:      Result,
    isNewResult: boolean,
    newMemArr: Array<PriorMemoizedResult<Params, Refs, Result>>,
}

export function findInPriorResults<Params, Refs, Result>(
    priorResults: Array<PriorMemoizedResult<Params, Refs, Result>>, 
    paramsObj: Params, 
    refsObj: Refs
  ): number | false {
    if (priorResults.length === 0) {
      return false;
    }
    const foundResult = priorResults.find((priorResult) => {
      // quick check with isRefsShallowlyEqual
      const {isRefsMatch, diff} = isRefsShallowlyEqual<Refs>(refsObj, priorResult.refsObj);
      if (!isRefsMatch) {
        if (DEBUG) {
            console.log( `getMemoizedResult -- Refs Comparison Failed for priorResults[${priorResults.indexOf(priorResult)}]`)
            console.log( `     new   refsObj:`, refsObj)
            console.log( `     prior refsObj:`, priorResult.refsObj )
            console.log( `     differences:`, diff)
            // Some custom code to debug a specific problem.
            // May be useful for next problem.
            //        if (diff[0] === 'tablelook') {
            //            let obj0 = refsObj.tablelook
            //            let obj1 = priorResults[i].refsObj.tablelook
            //            let deepDiff = getObjectDiffRecursive( obj0, obj1)
            //            if (deepDiff === '') { deepDiff = 'DeepComparison of objects finds no differences' }
            //            console.log( `     deepDiff of tablelook: `, deepDiff)
        }
        return false;
      }
      if (isEqual(paramsObj, priorResult.paramsObj) && isEqual(refsObj, priorResult.refsObj)) {
        return true;
      }
        return false;
    });
  
    return foundResult ? priorResults.indexOf(foundResult) : false;
  }

export function getMemoizedResult<Params extends GenericObject, Refs, Other, Result>(
                priorResults : Array<PriorMemoizedResult<Params, Refs, Result>>,
                paramsObj    : Params,
                refsObj      : Refs,
                otherArgsObj : Other,
                memoFunc   : (arg0:Params, arg1:Refs, arg2:Other) => Result,
                maxNumMemoized :number,
                DEBUG : boolean = false ) : MemoizedResult<Params, Refs, Result> {

    // Some memoized priorResults are saved by colKey:   [ priorResultsArr, priorResultsArr, .... ]
    // If we need to add a new column, then the above array will be short by one element.
    // Hence each location where we saved priorResults by colKey, they will all be short by one element.
    // Here I assume that there was no mistake in calling this function, and IF the input priorResuts
    // is not defined, than we replace it with an empty array.
    if ( !priorResults ) { priorResults = new Array(0) }
    let newMemoizedResults: Array<PriorMemoizedResult<Params, Refs, Result>> = []
    // Check if this result is already memoized
    const priorIndex = findInPriorResults(priorResults, paramsObj, refsObj)
    if ( priorIndex !== false ) {
        // Reorder the array so that the most recently used result is first
        newMemoizedResults = [priorResults[priorIndex], ...priorResults.slice(0,priorIndex), ...priorResults.slice(priorIndex+1)]
        return {result: priorResults[priorIndex].result, isNewResult:false, newMemArr:newMemoizedResults}
    }

    // No prior match.  Create new memoized result:
    if ( DEBUG ) { console.log( `getMemoizedResult:  No prior match.  Calling worker function to calc newResult` ) }
    const result = memoFunc( paramsObj, refsObj, otherArgsObj )
    // Special case where the worker returns: result === null
    // DO NOT memoized 'null' results (specifically, don't push this result to front of the memoization array)
    // We will interpret 'null' as meaning "I don't have the result at this time", but I'm working on it (worker, async, ?? )
    if ( result ) {
        //if ( maxNumMemoized === 1 ) {
        //    newMemoizedResults = [{ result, paramsObj, refsObj }]
       // } else {
            newMemoizedResults = [{ result, paramsObj, refsObj }, ...priorResults]
            newMemoizedResults.length = Math.min( newMemoizedResults.length, maxNumMemoized )
            //if (newMemoizedResults.length > maxNumMemoized) {
             //   newMemoizedResults = newMemoizedResults.slice(0, maxNumMemoized);
            //}
        //}
    } else {
        // Older memoized results are still valid.
        newMemoizedResults = priorResults
    }
    return {result, isNewResult:true, newMemArr:newMemoizedResults}
  }


export const getObjectDiffPaths = (obj1: GenericObject, obj2: GenericObject, ignorePaths: string[] = [], path: string = ''): string => {
    if (obj1 == null || obj2 == null) {
        return '';
    }
    if (typeof obj1 !== 'object' || Array.isArray(obj1)) {
        if (typeof obj1 === 'function' || typeof obj2 === 'function') {
            return '';
        }
        return isEqual(obj1, obj2) ? '' : `${path}: ... -> ...`;
    }

    const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
    const diff = [...keys].reduce((result, key) => {
        const currentPath = path ? `${path}.${key}` : key;
        if (ignorePaths.includes(currentPath)) {
            return result;
        }
        if (!obj2.hasOwnProperty(key)) {
            result.push(`${currentPath}: ${JSON.stringify(obj1[key])} -> undefined`);
        } else if (!isEqual(obj1[key], obj2[key])) {
            if (typeof obj1[key] !== 'object' || typeof obj2[key] !== 'object') {
                if (typeof obj1[key] !== 'function' && typeof obj2[key] !== 'function') {
                    result.push(`${currentPath}: ${JSON.stringify(obj1[key])} -> ${JSON.stringify(obj2[key])}`);
                }
            } else {
                result.push(getObjectDiffPaths(obj1[key], obj2[key], ignorePaths, currentPath));
            }
        }
        return result;
    }, Array<string>());

    if (diff.length === 0) {
        return '';
    }

    return diff.join('\n').trim();
};

