// polyfills to run in Internet Explorer 11
import 'core-js/es/symbol'
import 'core-js/es/map';
import 'core-js/es/set';

Number.isInteger = Number.isInteger || function(value) {
  return typeof value === 'number' &&
    isFinite(value) &&
    Math.floor(value) === value
}

Number.isSafeInteger = Number.isSafeInteger || function (value) {
   return Number.isInteger(value) && Math.abs(value) <= Number.MAX_SAFE_INTEGER
}
