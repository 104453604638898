import type { Property } from 'csstype'
import { PureComponent } from 'react'
import type { SliderControlParams } from '../types'
import SliderControl from './SliderControl'
import reactDispatch from './reactDispatch'


// IF/WHEN we edit our source code for slider parameters: min/max/numSteps,
// THEN:
// The current user 'State Values' may not align with a modified min/max/numSteps!
// To avoid have to modify any server side slider state values, we will just
// read the StateValue as it was last saved, and use this function to snap
// it to a closest legal snapping value.  In other words, will will read whatever
// happens to be saved in state, whether 'inSpec' or not.  And snap this value
// to the closest valid value.
export const getClosestSnappedValue = ( val: number, min: number, max: number, numSteps: number ): number => {
  if ( isNaN(val) ) {
    val = (min+max)/2
  }
  val = Math.min( max, Math.max( min, val))
  let stepSize = (max - min) / numSteps
  val = val - min + stepSize /2
  let steps = Math.floor( val / stepSize )
  return min + steps * stepSize
}



type OwnProps = {
  indent: number     // slider placement in SideBar
  preSpacer: number  // slider placement in SideBar
  displayFixed: number
  modPath: string | string[]
  numSteps: number
  currentValue: number
  styleName: string
  valMax: number
  valMin: number
  layoutLines: number
}
type DefaultProps = {
  visibility: Property.Visibility
  marginRight: number
  titleWidth: number | undefined
  sliderWidth: number | undefined
  displayWidth: number | undefined
}
type Props = OwnProps & DefaultProps

class StyleLinearSlider extends PureComponent<Props> {

  lastValue = 0
  sliderParams: SliderControlParams | null = null

  static defaultProps = {
    visibility: 'unset',
    marginRight: 0,
    titleWidth: undefined,
    sliderWidth: undefined,
    displayWidth: undefined
  }

  render() {
    const {displayFixed, modPath, numSteps, styleName, valMax, valMin, marginRight, displayWidth,
      currentValue, indent, preSpacer, layoutLines, visibility, titleWidth, sliderWidth } = this.props
    //console.log( 'StyleLinearSlider', styleName, indent, preSpacer, visibility, currentValue )
    this.sliderParams = {
      styleName,
      resourceName: '',
      funcType: 'linear',
      valMin: valMin,
      valMax: valMax,
      numSteps: numSteps,
      enumLabels: [],
      displayFixed: displayFixed,
      onInit: (value: number): number => {
        return getClosestSnappedValue( value, valMin, valMax, numSteps )
      },
      onStart: (val: number): void => {
        if (typeof val === 'number' && val <= valMax && val >= valMin ) {
          this.lastValue = val
        } else {
          this.lastValue = (valMin + valMax) / 2
        }
      },
      onDrag: (val: number): void => {
        if (val !== this.lastValue ) {
          let mods = []
          if ( typeof(modPath) === 'string' ) {
            mods.push({ newVal: val, path: modPath })
          } else {
            // modPath is an array (for example, push this value to every sKey )
            // Depends on the usage by the Parent.
            // For example, in plotXy 'engine' interface, we desire the smoothing
            // slider modify the seriesLineSmoothing parameter for all sKeys.
            // Hence the modPath should be an array of 'n' paths for 'n' sKeys.
            modPath.forEach( thisPath => mods.push({ newVal: val, path: thisPath }) )
          }
          reactDispatch( mods, `Style - ${styleName}`, 'drag' )
        }
        this.lastValue = val
      },
      onStop: (val: number): void => { reactDispatch( [], `Style - ${styleName}`, 'dragStop' ) },
    }

    return (
      <SliderControl
        indent={indent}
        preSpacer={preSpacer}
        titleWidth={titleWidth}
        currentValue={currentValue}
        params={this.sliderParams}
        layoutLines={layoutLines}
        visibility={visibility}
        marginRight={marginRight}
        sliderWidth={sliderWidth}
        displayWidth={displayWidth}
      />
    )
  }
}

export default StyleLinearSlider
