import { CSSProperties, Component, JSX, ReactInstance } from 'react'
import { typedKeys } from '../../sharedFunctions/utils'
import NotificationContainer from './NotificationContainer'
import Constants from './constants'
import type { Level, Notification, Position } from './constants'
import Styles from './styles'
import type { Container, ElementKey, ElementsMap, GetStylesObject, OverrideStyle, Style } from './styles'
import NotificationItem from './NotificationItem'

type Props = {
  style: Style
  noAnimation: boolean
  allowHTML: boolean
  newOnTop: boolean
}

type LocalState = {
  notifications: Notification[]
}
class NotificationSystem extends Component<Props, LocalState> {
  static defaultProps = {
    style: {},
    noAnimation: false,
    allowHTML: false,
    newOnTop: false,
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      notifications: []
    }
    this.uid = 3400
    this._isMounted = false
    this.overrideWidth = 0
    this.overrideStyle = {}
    this.elements = {
      notification: 'NotificationItem',
      title: 'Title',
      messageWrapper: 'MessageWrapper',
      dismiss: 'Dismiss',
      action: 'Action',
      actionWrapper: 'ActionWrapper'
    }

    this._getStyles = {
      overrideWidth: this.overrideWidth,
      overrideStyle: this.overrideStyle,
      elements: this.elements,
      setOverrideStyle: this.setOverrideStyle,
      wrapper: this.wrapper,
      container: this.container,
      byElement: this.byElement
    }
  }

  uid: number
  _isMounted: boolean
  overrideWidth: number
  overrideStyle: OverrideStyle
  elements: ElementsMap
  _getStyles: GetStylesObject

  componentDidMount() {
    this.setOverrideStyle(this.props.style)
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  setOverrideStyle = (style: Style): void => {
    this.overrideStyle = style
  }

  wrapper = (): CSSProperties => {
    if (!this.overrideStyle) return {}
    return Object.assign({}, Styles.Wrapper, this.overrideStyle.Wrapper)
  }

  container = (position: Position): Style => {
    if (!this.overrideStyle || !this.overrideStyle.Containers) return {}
    var override: Container = this.overrideStyle.Containers

    this.overrideWidth = Styles.Containers.DefaultStyle.width

    if (override.DefaultStyle && override.DefaultStyle.width) {
      this.overrideWidth = override.DefaultStyle.width
    }

    if (override[position] && override[position].width) {
      this.overrideWidth = override[position].width
    }

    return Object.assign(
      {},
      Styles.Containers.DefaultStyle,
      Styles.Containers[position],
      override.DefaultStyle,
      override[position]
    )
  }

  byElement = (element: ElementKey): (level: Level) => Style => {
    return (level: Level) => {
      var _element = this.elements[element]
      if (!this.overrideStyle) return {}
      var override = this.overrideStyle[_element] || {}
      return Object.assign(
        {},
        Styles[_element].DefaultStyle,
        Styles[_element][level],
        override.DefaultStyle,
        override[level]
      )
    }
  }

  _didNotificationRemoved = (uid: number): void => {
    var notification: Notification | undefined = this.state.notifications.find(
      ( value: Notification ) => (value.uid === uid)
    )
    var notifications = this.state.notifications.filter((toCheck) => (
      (toCheck.uid === uid) ? false : true
    ))

    if (this._isMounted) {
      this.setState({ notifications: notifications })
    }

    if (notification && notification.onRemove) {
      notification.onRemove(notification)
    }
  }

  addNotification = (notification: Notification): Notification | null => {
    var _notification = Object.assign({}, Constants.notification, notification)
    var notifications = this.state.notifications
    var i


    if (!_notification.level) {
      throw new Error('notification level is required.')
    }

    if (Object.keys(Constants.levels).indexOf(_notification.level) === -1) {
      throw new Error(`'${_notification.level}' is not a valid level.`)
    }

    // eslint-disable-next-line
    if (isNaN(_notification.autoDismiss)) {
      throw new Error(`'autoDismiss' must be a number.`)
    }

    if (
      Object.keys(Constants.positions).indexOf(_notification.position) === -1
    ) {
      throw new Error(`'${_notification.position}' is not a valid position.`)
    }

    // Some preparations
    _notification.position = _notification.position.toLowerCase() as Position
    _notification.level = _notification.level.toLowerCase() as Level
    _notification.autoDismiss = parseInt(String(_notification.autoDismiss), 10)

    _notification.uid = _notification.uid || this.uid
    _notification.ref = 'notification-' + _notification.uid
    this.uid += 1


    // do not add if the notification already exists based on supplied uid
    for (i = 0; i < notifications.length; i += 1) {
      if (notifications[i].uid === _notification.uid) {
        return null
      }
    }

    if (this.props.newOnTop) {
      notifications.unshift(_notification)
    } else {
      notifications.push(_notification)
    }


    if (typeof _notification.onAdd === 'function') {
      _notification.onAdd(_notification)
    }

    this.setState({
      notifications: notifications
    })

    return _notification
  }

  getNotificationRef = (notificationUid: number): NotificationItem | null => {
    var foundNotification = null

    for (const containerKey of Object.keys(this.refs)) {
      if (containerKey.indexOf('container') > -1) {
        const containerInstance: ReactInstance = this.refs[containerKey]
        const container: NotificationContainer = containerInstance as NotificationContainer
        for (const _notification of Object.keys(container.refs)) {
          // var uid = notification.uid ? notification.uid : notification
          if (_notification === `notification-${notificationUid}`) {
            // NOTE: Stop iterating further and return the found notification.
            // Since UIDs are uniques and there won't be another notification found.
            foundNotification = container.refs[_notification] as NotificationItem
            break
          }
        }
        if (foundNotification) {
          break
        }
      }
    }

    return foundNotification
  }

  removeNotification = (notificationUid: number) => {
    var foundNotification = this.getNotificationRef(notificationUid)
    return foundNotification && foundNotification._hideNotification()
  }

  editNotification = (notificationUid: number, newNotification: Notification): void => {
    var foundNotification = null
    // NOTE: Find state notification to update by using
    // `setState` and forcing React to re-render the component.
    // var uid = notification.uid ? notification.uid : notification

    var newNotifications = this.state.notifications.filter(function(stateNotification) {
      if (notificationUid === stateNotification.uid) {
        foundNotification = stateNotification
        return false
      }

      return true
    })

    if (!foundNotification) {
      return
    }

    newNotifications.push(Object.assign({}, foundNotification, newNotification))

    this.setState({
      notifications: newNotifications
    })
  }

  clearNotifications = (): void => {
    for (const refKey of Object.keys(this.refs)) {
      if (refKey.indexOf('container') > -1) {
        const containerInstance: ReactInstance = this.refs[refKey]
        const container: NotificationContainer = containerInstance as NotificationContainer
        for (const _notification of Object.keys(container.refs)) {
          const notificationItem: NotificationItem = container.refs[_notification] as NotificationItem
          notificationItem._hideNotification()
        }
      }
    }
  }

  render() {
    var containers: JSX.Element[] = []
    var notifications = this.state.notifications

    if (notifications.length) {
      for (const position of typedKeys(Constants.positions)) {
        var _notifications = notifications.filter((notification) => {
          return position === notification.position
        })

        if (!_notifications.length) {
          continue
        }

        containers.push(
          <NotificationContainer
            ref={ 'container-' + position }
            key={ position }
            position={ position }
            notifications={ _notifications }
            getStyles={ this._getStyles }
            onRemove={ this._didNotificationRemoved }
            noAnimation={ this.props.noAnimation }
            allowHTML={ this.props.allowHTML }
          />
        )

      }
    }

    return (
      <div className='notifications-wrapper' style={ this.wrapper() }>
        {containers}
      </div>
    )
  }
}

export default NotificationSystem
