import {PureComponent} from 'react'

type Props = {
  width:number,
  height:number,
}
export default class SVGcloseIcon extends PureComponent<Props> {
  render () {
    return (
      <svg
        className={'rc_SVGdragCol'}
        xmlns="http://www.w3.org/2000/svg"
        style={{isolation:'isolate', display:'block'}}
        viewBox="0 0 40 40"
        preserveAspectRatio='none'
        width={this.props.width}
        height={this.props.height}
      >
          <path d=" M 0 6 L 6 0 L 20 14 L 34 0 L 40 6 L 26 20 L 40 34 L 34 40 L 20 26 L 6 40 L 0 34 L 14 20 L 0 6 Z "
            fill={'black'}
          />
      </svg>
    )
  }
}