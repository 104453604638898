import type { JSX } from 'react'
import { PureComponent } from 'react'
import { SCRY_BLUE } from '../sharedComponents/constants'

type OwnProps = {
  children: JSX.Element | JSX.Element[] | string,
  onClick: () => void,
}

class NavColButton extends PureComponent<OwnProps> {
  render() {
    const { children, onClick } = this.props
    return (
      <button
        className={'rc_NavColButton'}
        onClick={onClick}
        style={{
          appearance: 'none',
          background: SCRY_BLUE,
          border: 'none',
          color: '#fff',
          cursor: 'pointer',
          fontSize: 'inherit',
          fontWeight: 'bold',
          outline: 'none',
          paddingTop: '6px',
          paddingBottom: '6px',
          paddingLeft: '16px',
          textAlign: 'left',
          userSelect: 'none',
          whiteSpace: 'nowrap',
        }}
      >
        {children}
      </button>
    )
  }
}

export default NavColButton
