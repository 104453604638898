/*

RESULTS FROM  BENCHMARKING SPEED:

Test table: parametric Equations:
28 columns by 1000 rows.
No Optimization                      -  92.3 msec   117%
Commenting out lines that are unused -  78.8 msec   100%  (consider this the comparison test)
Constants optimization               -  64.9 msec    82%
No unused variables optimization     -  63.7 msec    81%  (Eliminates 'parameters' and unused lines.)
Merged Verb-Noun optimization        -  64.5 msec    82%  ( will probably drop this if test hold out over time)

28,000 cells / 63.7 msec =
440 Mega-cells per second =
2.3 micro-seconds per cell

Comparison to native jave-script:
Python editor runs ~ 26% speed of native javascript
Consider 1/4 the efficiency of native javascript.

Above test:
  1000 rows by 28 cols, same formula, hypotrochoid X - Unused lines left in, but removed by our optimization
  1000 rows by 28 cols, some js formula hypotrochoid - Unused lines left in/out; No speed difference, hence removed by jsEngine.

*/

import type { TableValues } from '../types'
import type { OpCodeFunctionMap, OpCodeName, ResultByFormulaLineNum, ScryProgram, TempVarsObject } from './formulaTypes'
import type { ErrorRows } from './getDefaultTableComputedData'

import { lastVal } from '../sharedFunctions/utils'
import { isExecutableOpCode } from './formulaTypes'

const stack: (string | number)[] = new Array(100).fill(0)  // Intentionally un-typed.  Must be correct by construction.

let sPtr = -1             // Stack Pointer
let pPtr = -1             // Program Pointer
let rPtr  = 0             // first rowKey to calculate
let endRow= 1             // last  rowKey to calculate
let val: string | number = 0 // Each opcode optionally contains a value, accessable via this global
let isAnotherRow = true   // We set this flag false after last rowKey has been calculated.

let tmp           // tmp var used in some opCode functions
let n = 0         // tmp var used in some opCode functions
let a = 0         // tmp var used in some opCode functions
let raw = 0       // tmp var used in some opCode functions
let rounded = 0   // tmp var used in some opCode functions

let errorObj: ErrorRows // We create our list of erroneous cells (one list per column) on the fly.
let tmpVars: TempVarsObject // Zero to 'n' temporary user 'namedValues'
let resultByFormulaLineNum: ResultByFormulaLineNum[]

let d: TableValues  // tableData (columns

export const RPN_evaluate = ( program: ScryProgram, startRow: number, endRowIn: number,
  depColKey: number, tableValues: TableValues, isInstrumented: boolean = false ): {
    errorObj: ErrorRows, resultByFormulaLineNum: ResultByFormulaLineNum[]
  } => {

  sPtr = -1
  pPtr = -1
  rPtr = startRow
  endRow = endRowIn
  d = tableValues

  isAnotherRow = true
  errorObj = {}
  resultByFormulaLineNum = []
  tmpVars = {}

  // Convert the sequence of opcodes to a sequence of executable functions and values.
  const {funcArray, valArray} = createProgramUsingArrays( program, depColKey, isInstrumented )

  // Initialize the data structure that will hold the resultByFormulaLineNum.
  // In this mode, the program is longer and less efficient as it needs
  // to gather information about a specific cell's computation.
  // However, the isInstrumented mode is intended for a single row and to be used
  // only when ( startRow===endRow ).  The program has additional instructions
  // inserted to track:
  //    - which formula lines were executed and which were skipped
  //    - The evaluated expression value for each executed line.
  //    - The current tmpVars values used for each line's calculation.
  if ( isInstrumented ) {
    for (let i=0; i<=lastVal(program).lineNum; i++) {
      resultByFormulaLineNum[i] = {wasExecuted:false, exprResult:'', tmpVars: {} }
    }
  }

  // Here be the program execution.
  // This loop, along with opCodes below, could be considered the 'Python Emulator'.
  // The program will set 'isAnotherRow=false' when we finish 'endRow'
  while ( isAnotherRow ) {
    pPtr++
    val = valArray[pPtr]    // This opcode's corresponding value (colName, tmpVar name, value, ... )
    funcArray[pPtr]()       // This opcode's corresponding function (operation)
  }



  // TESTING BLOCK to compare efficiency of PYTHON to HARDCODED javascript functions.
  // 0) Load the Parametrics Equations table
  // 1) Comment OUT the while loop above.
  // 2) Comment IN the hardwired calc below:
  // 3) Write the hard-coded test program below (very bottom of file)
  //    Write an identical function in Python colFormula editor,
  //    copy to all 28 columns in 'Parametric Equations' table.
  // 4) Turn on the timer option in file 'updateTableComputedData'

  //hardCodedJS( startRow, endRowIn, depColKey )



  // The errorObj is needed by the tableDerivtableComputedDataedState and only has meaning when we
  // calculate an entire column.
  // The resultByFormulaLineNum is needed by the formula viewer and
  // only has meaning when we calculate a single cell (unique rowKey, colKey).
  return {errorObj, resultByFormulaLineNum}
}



function initForNextRow( ) {
  if (rPtr === endRow) { isAnotherRow = false } // THIS IS WHERE THE WHILE LOOP EXITS after last row.  (Only exit)
  rPtr++  // Increment row pointer; Re-initialize the program and state variables.
  sPtr = -1  //Re-init stack pointer
  pPtr = -1  //Re-init the program pointer
}


export const executeUnaryOp = ( constValue: string | number, opCodeName: OpCodeName ) : string | number =>  {
  if (isExecutableOpCode(opCodeName)) {
    sPtr = 0
    stack[0] = constValue
    opFunc[opCodeName]( )
    return stack[0]
  } else {
    return constValue
  }
}

export const executeBinaryOp = ( constValue0: string | number, constValue1: string | number, opCodeName: OpCodeName ) : string | number =>  {
  if (isExecutableOpCode(opCodeName)) {
    sPtr = 1
    stack[0] = constValue0
    stack[1] = constValue1
    opFunc[opCodeName]( )
    // Need to cast potential true false values to a number.
    return Number( stack[0] )
  } else {
    return constValue0
  }
}


const opFunc: OpCodeFunctionMap = {

    // OPCODE NAMING CONVENTIONS:  Every opCode is one of:
    // NOUNS - formula constants, built-in constants, depColumn values, inDep values, and namedValues, stack Values.
    // VERBS - operators, functions, assignments
    // FLOW  - if, elif, else, return, colon (colon is NOT considered an operator in our parser)

    // For computational efficiency, there are big gains from  using VERB_NOUN pairs.
    // Because when taken together, there is no need to mess with the stack pointer.
    // VERB_NOUN paired opcodes are identified as 'verb name'_'noun name'.

    // We also can pair (or even triple) verbs, identified as 'verb name'_'verb name'.
    // For example 'funccos_radians', 'funclog_abs', 'funcsqrt_abs'


    'noop': ()=>{ },   // No operation.  Sometimes needed as a jump point if/when
                       // unused lines are deleted.

    // Elementary NOUNS - opcodes that put values on the stack
    'constant': ()=>{ sPtr++; stack[sPtr]  = val },
    'None'    : ()=>{ sPtr++; stack[sPtr]  = '' },
    'colName' : ()=>{ sPtr++; stack[sPtr]  = (d[Number(val)][rPtr]==='') ? NaN : Number(d[Number(val)][rPtr]) },
    //'colInd'  : ()=>{ sPtr++; stack[sPtr]  = (i[val][rPtr]==='') ? NaN : Number(i[val][rPtr])  // For production
      //sPtr++; stack[sPtr]  = i[val][rPtr]   // Temp code for short term usages.
    //},
    'varName' : ()=>{ sPtr++; stack[sPtr]  = (tmpVars[val]==='') ? NaN : tmpVars[val] },

    // Elementary Math operators - excluding those requiring division, which are listed below.
    'op_='  : ()=>{ tmpVars[val] = stack[sPtr]; sPtr-- },
    'uniSub': ()=>{         stack[sPtr] = Number(stack[sPtr]) * -1 },
    'op_*'  : ()=>{ sPtr--; stack[sPtr] = Number(stack[sPtr]) * Number(stack[sPtr+1]) },
    'op_+'  : ()=>{ sPtr--; stack[sPtr] = Number(stack[sPtr]) + Number(stack[sPtr+1]) },
    'op_-'  : ()=>{ sPtr--; stack[sPtr] = Number(stack[sPtr]) - Number(stack[sPtr+1]) },
    'op_**' : ()=>{ sPtr--; stack[sPtr] = Math.pow( Number(stack[sPtr]), Number(stack[sPtr+1]) ) },

    // Elementary Division - NOT protected by divide by zero.
    // Any divide by zero will put NaN on the stack
    'op_/'  : ()=>{ sPtr--; stack[sPtr] = Number(stack[sPtr]) / Number(stack[sPtr+1]) },
    'op_//' : ()=>{ tmp=stack[sPtr]; sPtr--;
                    stack[sPtr] = Math.floor(Number(stack[sPtr])/Number(tmp)) }, //Divide; then truncate towards neg infinity.
    'op_%'  : ()=>{ n  =Number(stack[sPtr]); sPtr--; a=Number(stack[sPtr]);
                    stack[sPtr]=a-n*Math.floor(a/n) },          //Verified over a%n, -a%n, a%-n, -a%-n

    // Elementary Boolean (comparison) operators
    // If both values are finite, pass the boolean result; Otherwise pass NaN
    // NOTE! There is two exceptions to Python:
    //   1) return True  or  1/0   // returns True  in python;  returns NaN in our emulator.
    //   2) return False and 1/0   // Returns False in python;  returns NaN in our emulator
    // See the file: "evaluationNotes.txt"
    'op_<'  : ()=>{ a=Number(stack[sPtr]); sPtr--; tmp=stack[sPtr];  // tmp is left operand; a is right operand
                    stack[sPtr]=(isFinite(Number(tmp)) && isFinite(a)) ? Number(Number(tmp)<a) : NaN },
    'op_>'  : ()=>{ sPtr--; tmp=stack[sPtr]; a=Number(stack[sPtr+1]);
                    stack[sPtr]=(isFinite(Number(tmp)) && isFinite(a)) ? Number(Number(tmp)>a) : NaN },
    'op_<=' : ()=>{ sPtr--; tmp=stack[sPtr]; a=Number(stack[sPtr+1]);
                    stack[sPtr]=(isFinite(Number(tmp)) && isFinite(a)) ? Number(Number(tmp)<=a) : NaN },
    'op_>=' : ()=>{ sPtr--; tmp=stack[sPtr]; a=Number(stack[sPtr+1]);
                    stack[sPtr]=(isFinite(Number(tmp)) && isFinite(a)) ? Number(Number(tmp)>=a) : NaN },
    'op_not': ()=>{ stack[sPtr]= isFinite(Number(stack[sPtr]))         ? Number(!stack[sPtr]) : NaN },

    'op_==' : ()=>{ sPtr--; tmp=stack[sPtr]; a=Number(stack[sPtr+1]);
                    // Yes - all these coercions are needed.
                    // Because tmp, a, and the equivalence result can all be booleans!
                    // In which case subsequent isFinite() and current === operator will give wrong results.
                    stack[sPtr]=(isFinite(Number(tmp)) && isFinite(a)) ? Number( Number(tmp) === Number(a) ) : NaN },
    'op_!=' : ()=>{ sPtr--; tmp=stack[sPtr]; a=Number(stack[sPtr+1]);
                    stack[sPtr]=(isFinite(Number(tmp)) && isFinite(a)) ? Number( Number(tmp) !== Number(a) ) : NaN },

//    'op_or' : ()=>{ sPtr--; tmp=stack[sPtr]; a=stack[sPtr+1];
//                    stack[sPtr]=(isFinite(tmp) && isFinite(a)) ? Number(tmp || a) : NaN },
//    'op_and': ()=>{ sPtr--; tmp=stack[sPtr]; a=stack[sPtr+1];
//                    stack[sPtr]=(isFinite(tmp) && isFinite(a)) ? Number(tmp && a ) : NaN },

    'op_and': ()=>{ sPtr--; tmp=stack[sPtr]; a=Number(stack[sPtr+1]);
                    stack[sPtr]=(isFinite(Number(tmp)) && isFinite(a)) ? Number( Boolean(tmp) && Boolean(a) ) : NaN },
    'op_or' : ()=>{ sPtr--; tmp=Number(stack[sPtr]); a=Number(stack[sPtr+1]);
                    const tmpFinite = isFinite(tmp); const aFinite = isFinite(a);
                    if      ( tmpFinite && !aFinite) { stack[sPtr] = Number(Boolean(tmp)) }
                    else if (!tmpFinite &&  aFinite) { stack[sPtr] = Number(Boolean(a))   }
                    else if ( tmpFinite &&  aFinite) { stack[sPtr] = Number(tmp || a) }
                    else                             { stack[sPtr] = NaN }
                  },

    'op_,': ()=>{},
    // Elementary Functions
    // Excluding the trig functions (found below)
    'funcabs'    : ()=>{ stack[sPtr]  = Math.abs(Number(stack[sPtr])) },
    'funcceil'   : ()=>{ stack[sPtr]  = Math.ceil(Number(stack[sPtr])) },
    'funcfloor'  : ()=>{ stack[sPtr]  = Math.floor(Number(stack[sPtr])) },
    'functrunc'  : ()=>{ stack[sPtr]  = Math.trunc(Number(stack[sPtr])) },
    'funcexp'    : ()=>{ stack[sPtr]  = Math.exp(Number(stack[sPtr])) },
    'funclog'    : ()=>{ stack[sPtr]  = Math.log(Number(stack[sPtr])) },
    'funclog10'  : ()=>{ stack[sPtr]  = Math.log10(Number(stack[sPtr])) },
    'funcsqrt'   : ()=>{ stack[sPtr]  = Math.sqrt(Number(stack[sPtr])) },
    'funcisnan'  : ()=>{ stack[sPtr]  = Number( !Number.isFinite( Number(stack[sPtr]) ))  },
    'funcmax'    : ()=>{ sPtr-= Number(val)-1; stack[sPtr] = Math.max( ...( stack.slice(sPtr,sPtr+Number(val)).map((i)=>(Number(i))))) },
    'funcmin'    : ()=>{ sPtr-= Number(val)-1; stack[sPtr] = Math.min( ...( stack.slice(sPtr,sPtr+Number(val)).map((i)=>(Number(i))))) },

                            // Python round for x.5 is 'to even'  !!
    'funcround'  : ()=>{
                              if (val === 1 ) {
                                // Single arg version
                                stack[sPtr] = pythonRound(Number(stack[sPtr])) // Single arg version
                                return
                              }
                              // Two arg version
                             sPtr--;     // This is the python 2 arg round()
                             const factor   = Math.pow(10, Number(stack[sPtr+1]))
                             stack[sPtr]  = pythonRound(Number(stack[sPtr]) * factor) / factor },




    /*  Next opcode allows developer to use the function name 'jpsfunc(  )'  to
    write an experimental function using javascript.  
    This requires 4 mods throughout the code:
    
    1) In this file, (above) search for the opcode 'colName',  This
        opcode reads the tableData value and pushes it to the stack.
        If you intend to read from a column that is dataType !== number, then
        you will need to comment out this error checking.
        Otherwise a runtime error. (Because you are pushing a unrecognized 
        column dataType onto the stack.)

    2) Module: 'formulas/errorCheckLines'
        Search for 'Column names must be Datatype ...'
        Need to force this error checking to 'false'.
        Otherwise compile time error that you are using a non-supported dataType.

    3) Module: 'formulas/types'
        Search for 'funcjpsfunc'.  You need to specify the number of arguments
        this function supports.
            Format:   funcjpsfunc[ # required args, # optional args ]
       Otherwise, compile time error that function is not recognized, or
       compile time error for wrong number of arguments.

    4) Write your function in the space below:
         Currently on  the stack are '1 to n' arguments.  If your function needs
         three args, then they are on the stack already before funcjpsfunc 
         opcode is exectuted.
         
      Grab the values;  Decrement the stack pointer by n-1 locations.  The
      Stack pointer is now pointing to your first argument.  This is the
      location where you need to put your function's result (1st arg is
      overwritten).

    WHEN DONE:  
    REMEMBER TO COMMENT OUT THE CHANGES #1,2,3 !!!!!!  DOESN'T MATTER WHETHER
    opcode BELOW IS LEFT ACTIVE OR NOT.  
    */

    'funcjpsfunc'    : ()=>{  
                              sPtr--
                              let p0 = stack[sPtr]
                              if (p0 === '' ) { return p0 } 
                              const p1 = stack[sPtr+1]
                              p0 = String(p0).replace(/ .*/,'')  // Take only the first word. Ingore middle initial, Sr. Jr, ect.
                              stack[sPtr]= p0 + ' ' +p1
                            },
                    


    // FLOW

    // The else keyword DOES NOT map to a computational instruction!
    // Linker knows how to handle the branching without putting
    // a 'flowelse' 'opcode' in the program.
    // However, doesn't hurt to add the flowelse opcode to
    // the program.  Linker can still figure out the
    // necessary jumping with or without a 'flowelse' opcode.

    // Why do I add 'flowelse' opcode to a program?  Because
    // there is no other way (as far as I can see) to
    // determine if an else statement was or was not
    // executed.  Of course, I just said 'else' is
    // never executed.  But this is not how it appears
    // to somebody reading the formula expressions.
    // So I would like to display, or not display (translucent)
    // the else statement depending on whether the program
    // flow did, or did not pass through the else statement.
    // Hence, the only purpose of the 'flowelse' opCode is to
    // mark the position, (specifically the lineNumber) of
    // each else statement. And when a program isInstrumented,
    // the resultByFormulaLineNum[lineNum].wasExecuted attribute
    // will reflectwhether the code flow did or did not pass
    // through the else statement.

    // Optimization will remove flowelse opcodes.
    // Instrumented programs are always unoptimized and will
    // include flowelse opcodes as the line number markers
    // described above.
    'jump'       : ()=>{ pPtr = Number(val) },
    'flowif'     : ()=>{ if ( !stack[sPtr] ) { pPtr = Number(val) }; sPtr-- },
    'flowelif'   : ()=>{ if ( !stack[sPtr] ) { pPtr = Number(val) }; sPtr-- },
    'flowelse'   : ()=>{},
    'flowreturn' : ()=>{
          if ( Number.isFinite( stack[sPtr] ) ) {
            d[Number(val)][rPtr] = String(stack[sPtr])
          } else if ( stack[sPtr] === '' ) {
            // This is the value saved if we return None or ''; Considered an 'empty' or 'missing' value.
            // NOT considered an error.
            d[Number(val)][rPtr] = ''
          } else {
            // Everything else: Undefined, NaN, Infinity, and -Infinity all saved as 'NaN'.
            // considered an error not-a-number
            errorObj[rPtr] = ''  // Add this row to the list of erroneous values.
            d[Number(val)][rPtr] = 'NaN'
          }
          initForNextRow( )
    },



    // TRIG functions:
    // The extra logic here is independent of Python, JS, or any other
    // language we potentially choose for the formula syntax.
    // These tweaks 'snap' the cos/sin/acos/asin/ functions to exact
    // values at right-angles.  
    //   - Perfect sin/cos values of 1 or 0.)
    //   - And perfect degrees values of 0/90/180.
    // Cost is I am giving up last 3 digits of resolution in the returned
    // values.  Effectively, I am forcing the expressions to snap to
    // 12 digits of accuracy, to avoid showing newbies the after effects
    // of computational roundoff and radian values being transcendental
    // at right angles.
    // Hence Python vrs our js code diverge near angles with return values of ~zero.
    // Force a 'zero' when doing trig on 'right' angles


    'funcacos'   : ()=>{         stack[sPtr]  = Math.acos(Number(stack[sPtr])) },
    'funcasin'   : ()=>{         stack[sPtr]  = Math.asin(Number(stack[sPtr])) },
    'funcatan'   : ()=>{         stack[sPtr]  = Math.atan(Number(stack[sPtr])) },
    'funcatan2'  : ()=>{ sPtr--; stack[sPtr]  = Math.atan2(Number(stack[sPtr]), Number(stack[sPtr+1])) },
    'funcradians': ()=>{ stack[sPtr] = Number(stack[sPtr]) * 1.7453292519943295e-2 },
    // epsilon of error for +/- 1e-10 degrees of slope => Corresponds to radians test of ~ 1e-12
    'funccos'    : ()=>{ raw = Math.cos(Number(stack[sPtr])); rounded = Math.round(raw);
                         stack[sPtr] = Math.abs(raw-rounded) > 1e-12 ? raw : rounded },
    'funcsin'    : ()=>{ raw = Math.sin(Number(stack[sPtr])); rounded = Math.round(raw);
                         stack[sPtr] = Math.abs(raw-rounded) > 1e-12 ? raw : rounded },
    'functan'    : ()=>{ raw = Math.tan(Number(stack[sPtr])); rounded = Math.round(raw/45)*45;  // rounded to 45's
                         if ( Math.abs(raw) > 1e11 ) { stack[sPtr] = NaN; return }
                         stack[sPtr] = Math.abs(raw-rounded) > 1e-12 ? raw : rounded },
    // epsilon of error for +/- 1e-10 degrees of slope => Corresponds to radians test of ~ 1e-8
    'funcdegrees': ()=>{ raw = Number(stack[sPtr]) * 57.29577951308232; rounded = Math.round(raw/45)*45; // rounded to 45's
                         stack[sPtr] = Math.abs(raw-rounded) > 1e-8 ? raw : rounded },


}

const pythonRound = ( x: number ) => {
  if ( Math.abs(x%1) === 0.5) {
    // We are exactly half way; Round to an even integer
    const rounded = Math.round(x)  // JavaScript rounds towards +infinity
    // If we rounded up to a odd integer, then correct our mistake.
    return (rounded%2 === 0) ? rounded : rounded -1
  }
  return Math.round(x)
}



const createProgramUsingArrays = ( program: ScryProgram, depColKey: number, isInstrumented: boolean) => {
  const funcArray: (() => void)[] = []
  const valArray: (string | number)[] = []

    //wasExecuted:boolean,
    //exprResult: string|number,
    //tmpVars: Object

  program.forEach( thisOpCode => {

    // Here is the additional 'instrumented' functionality
    // we insert to track the calculation by formula lineNum.
    // Two approaches to insert new func into the program:
    //    1) Insert new functions (equivalent opcodes)
    //       between existing opcodes.  However, this will
    //       change the jump addresses.  Which requires two
    //       passes through the data to 're-link' the program.
    //    2) Replace a current opcode with a wrapper function
    //       that contains the new functionality and the current
    //       opcode.  Hence, jump addresses into the program
    //       array do not change.  No need to re-link the
    //       program.   I choose option #2.
    if ( isInstrumented &&  ( thisOpCode.name === 'op_=' ||
                              thisOpCode.name === 'flowif' ||
                              thisOpCode.name === 'flowelif' ||
                              thisOpCode.name === 'flowelse' ||
                              thisOpCode.name === 'flowreturn' )) {
      funcArray.push(
        ()=>{
          // new functionality
          resultByFormulaLineNum[thisOpCode.lineNum] = {
            wasExecuted:true,
            exprResult:stack[sPtr],
            tmpVars: {...tmpVars}
          }
          // current OpCode
          const {name} = thisOpCode
          if (isExecutableOpCode(name)) {
            opFunc[name]()
          }
        }
      )
      valArray.push( thisOpCode.value )
    }

    else {
      const {name} = thisOpCode
      if (isExecutableOpCode(name)) {
        funcArray.push( opFunc[name] )
        valArray.push( thisOpCode.value )
      }
    }

  })

  // For every program, insert a final opcode to check for evaluations that
  // do NOT return.  This is our approach for run-time validation that a
  // formula successfully returns.  We do this whether the program is
  // instrumented or not.  There is no cost because this opCode will NEVER
  // be executed, except when a row fails to properly return.
  // We do NOT want to abort on the failing row. Just save an erroneous
  // value and continue program execution on the next row.
  funcArray.push(
    ()=>{
      d[depColKey][rPtr] = 'Missing Return'
      errorObj[rPtr] = ''    // Add this row to the list of erroneous values.
      initForNextRow( )
    }
  )
  valArray.push( '' )

  return { funcArray, valArray }
}




// How fast is js if I just code the function directly?
// Designed to use the Parametrics Equations table.
// Hardwired to dependent column 0 (index) for values
// 1001 rows by 28 calculated columns.

//JavaScrip hardcoded with below (expression of 17 operations) runs at ~41Mflops/sec
//Our python emulator runs at 14Mflops/sec.
//Our python emulator is 3x slower than running javaScript directly.


// One must write an identical function in Python, then
// copy the python to all 28 columns in 'Parametric Equations' table.


/*
const hardFunc = ( rowKey, loopCounter ) => {
  //var age         = (i[0][rowKey] === '') ? NaN : Number(i[0][rowKey])
  //var pacePerMile = (i[0][rowKey] === '') ? NaN : Number(i[0][rowKey])
  //var ageGraded   = (i[0][rowKey] === '') ? NaN : Number(i[0][rowKey])
  //var d = Math.log( Math.abs(age + ageGraded - pacePerMile +3.14 - loopCounter ))
  //var e = Math.floor( age * pacePerMile / 2 + 3**3 )
  //var f = ( age / d * e + Math.sqrt( Math.abs(i[0][rowKey])) ) / (2*Math.PI)
      var xCenter = 8
      var yCenter = 0
      var scale = 1.5
      var a = 1
      var b = .7
      var c = .9
      var theta = i[0][rowKey] / 1000 * 14 * Math.PI
      var x = ( a - b ) * Math.cos( theta ) - c * Math.cos( ( a / b - 1 ) * theta )
      var y = ( a - b ) * Math.sin( theta ) - c * Math.sin( ( a / b - 1 ) * theta )
      var x = scale * x + xCenter
      var y = scale * y + yCenter
  return y
}

const rowFunc = (rowKey, depColKey, loopCounter) => {
  var answer = hardFunc( rowKey, loopCounter )
 if ( Number.isFinite( answer )) {
   d[depColKey][rowKey] = String(answer)
 } else if ( answer === '' ) {
   d[depColKey][rowKey] = ''
 } else {
   // Everything else: NaN, Infinity, and -Infinity all saved as 'NaN'.
   d[depColKey][rowKey] = 'NaN'
   errorObj[rowKey] = ''
 }
}


// export here only to prevent linter unused function error.
const hardCodedJS = ( startRow, endRowIn, depColKey ) => {
  for ( let loopCounter = 0; loopCounter < 1; loopCounter++ ) {
    for ( let j=startRow; j<=endRowIn; j++ ) {
      rowFunc( j, depColKey, loopCounter )
    }
  }
}
*/
