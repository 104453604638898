import type { LightweightMod } from '../types'
import type {DomNode, TableComputedData} from '../computedDataTable/getDefaultTableComputedData'

import {MouseEvent}               from 'react'
import {TableComputedDataContext} from '../viewTableTriple/TtGetComputedData'
import {useContext}               from 'react'
import dynamics                   from 'dynamics.js'
import reactDispatch, {reactMinorDispatch}  from '../sharedComponents/reactDispatch'
import {wasSuspendPointerEventsSuccessful,
  restorePointerEvents}           from '../sharedFunctions/pointerEvents'
import SVGwrapper2                from '../SVGs/SVGwrapper2'
import SVGpin                     from '../SVGs/SVGpin'
import {MAX_NUMBER_PINNED_ROWS}   from '../sharedComponents/constants'


const DEBUG : boolean = false
const LEFT_PARKED_POSITION : number = -1000   // Location of the 'pin' icon, offscreen to left

type PinRowAnimationObj = {
  paramA: number
  Yposition: number
}

type UnpinRowAnimationObj = {
  opacity: number
}

/***********************************************
             ActionPinRow
    The component renders:

    1) Two Pin Icons; one for headContainer and one for dataContent.
       I want these icons within the dataContent-- so they truncate as they scroll out of view, just as do the line numbers.
       Like the column control icons, the pinRow icons DO NOT have an onClick handler.  The focal plane knows when a rowNumber is clicked.
       The icon is for visual guidance only. Says "clicking here (the xy coord corresponding to a rowNumber) will pin or un-pin the row."
    2) A column of transparent divs perfectly overlaying the data row numbers.  One for each row. These divs each have an onmouseenter event handler.
    3) The dataContent div holding the all the divs for #2 above.  This div has a onMouseLeave event handler.
    4) The dataContainer div, which is a view into the scrolling content div.  This div has overflow:hidden so it truncates the pinIcon
       just as rowNumbers are truncated when scrolled out of view.
    5) Equivalent divs overlaying the pinnedRows in the header
    6) Equivalent headContent div holding all of divs for #5 above.

    This component is completely invisible, with exception of a single PinRow icon which only appears over a rowNumber as mouse hovers.

***********************************************/


const mouseMoveHandler = (e : MouseEvent<HTMLDivElement>, typeID: string, 
                                 tableComputedData: TableComputedData ) : void => {
    // This code just makes the 'pin icon' visible or not.  
    // This render code does NOT support the handler's for animation. (They are owned by the focal plane)
    // The pin icon is always centered in the row's height. (Discrete jumps during mouseMove)
    const {top} = e.currentTarget.getBoundingClientRect()
    const y = e.clientY - top
    const { centeringOffset } = tableComputedData.widthObj
    const { pinnedTop, rowHeight, dataTop, dataAllocated: dataHeight } = tableComputedData.heightObj
    const { sortedRowKeys, pinnedRowKeys, scrollTop } = tableComputedData
    const numPinnedRowKeys = pinnedRowKeys.length
    const numSortedRowKeys = sortedRowKeys.length
    let yPosition : number = 0
    if ( typeID === 'head' ) {
      let pinnedRowIndex = Math.floor( y/rowHeight )
      pinnedRowIndex = Math.min( Math.max( 0, pinnedRowIndex), numPinnedRowKeys )
      yPosition = pinnedTop + pinnedRowIndex * rowHeight
      if (pinIconNode) { pinIconNode.style.transform = `translate( ${centeringOffset}px, ${yPosition}px )` }
    } else { // typeID === 'data'
      let dataRowIndex : number = Math.floor( (y + scrollTop) / rowHeight )  // RowNumber - 1 !
      dataRowIndex = Math.max( 0, Math.min (dataRowIndex, numSortedRowKeys) ) // safety limit.
      const snappedThisRowY = (dataRowIndex * rowHeight) - scrollTop  // The top edge of this Row with respect to dataTable
      yPosition = dataTop + snappedThisRowY // The top edge with respect to top of visible dataTable
      isDataRowNotSufficientlyVisible = yPosition + 0.4 * rowHeight < dataTop || yPosition + 0.6 * rowHeight > dataTop + dataHeight
      if ( isDataRowNotSufficientlyVisible ) {
        if (pinIconNode) { pinIconNode.style.transform = `translate( ${LEFT_PARKED_POSITION}px, ${yPosition}px )` }
      } else {
        if (pinIconNode) { pinIconNode.style.transform = `translate( ${centeringOffset}px, ${yPosition}px )` }
      }
    }
    yPositionStart = yPosition
    xPositionStart = centeringOffset
}

const mouseLeaveHandler = (  ) : void => {
    if (pinIconNode) { pinIconNode.style.transform = `translate( ${LEFT_PARKED_POSITION}px, 0px )` }
}

const initPinIconNode = (element: DomNode): void => {
    pinIconNode = element
}

export const ActionPinRow : React.FC = () => {
    const tableComputedData = useContext(TableComputedDataContext)
    const { rowNumbers:rowNumbersWidth, centeringOffset, pinIconSize:pinIconHeight} = tableComputedData.widthObj
    const { minScrollHeightBeforeHidden, pinnedHeight, dataTop, dataAllocated:dataHeight, 
             pinnedTop, rowHeight } = tableComputedData.heightObj
    const {foregroundColor} = tableComputedData.styleObj
    yPositionStop = pinnedTop + pinnedHeight - rowHeight
    return (
      <div className={'rc_ActionPinRow'}>
          <div className={'PinIconContainer'}
              ref={ initPinIconNode }
              style={{
                position:'absolute',
                top: 0, left: 0,
                transform: `translate( ${LEFT_PARKED_POSITION}px, 0px )`,
                height: rowHeight, width: pinIconHeight,
              }}>
                    <SVGwrapper2>
                      <SVGpin
                        foregroundColor={foregroundColor}
                        width={pinIconHeight}
                        height={pinIconHeight}
                      />
                    </SVGwrapper2>
          </div>
          <div className={'RowNumbersHead'}
              onMouseLeave={ ( ) => mouseLeaveHandler( ) }
              onMouseMove= { (e) => mouseMoveHandler( e, 'head', tableComputedData ) }
              style={{
                position: 'absolute',
                left: centeringOffset,
                top: pinnedTop, height: pinnedHeight,
                width: rowNumbersWidth,
                background: (DEBUG) ? 'orange' : 'unset',
                opacity: (DEBUG) ? 0.2 : 'unset',
              }}/>
          <div className={'RowNumbersData'}
              onMouseLeave={ ( ) => mouseLeaveHandler( ) }
              onMouseMove= { (e) => mouseMoveHandler( e, 'data', tableComputedData ) }
              style={{
                position: 'absolute',
                left: centeringOffset,
                top: dataTop, height: dataHeight,
                width: rowNumbersWidth,
                visibility: dataHeight < minScrollHeightBeforeHidden ? 'hidden' : 'unset',
                background: (DEBUG) ? 'orange' : 'unset',
                opacity: (DEBUG) ? 0.2 : 'unset',
              }}/>
      </div>
    )
}






let pinIconNode   : DomNode = null
let isDataRowNotSufficientlyVisible : boolean = true
let yPositionStart: number = 0
let yPositionStop : number = 0
let xPositionStart: number = 0
const xSwerveLeft : number = 30   // Nothing but the cosmetic curve to the pin path.
                                // If adjusting, make sure to use a full width 
                                // table (to edges of available space).


export const handlePinRow = ( rowKey: number, pinnedRowKeys: number[] ) : void => {
    if (!wasSuspendPointerEventsSuccessful( 'Pinning Row Icon' )) {
      return
    }
    if ( isDataRowNotSufficientlyVisible ) return
    const numPinnedRowsStart = pinnedRowKeys.length
    const newPinnedRowKeys = pinnedRowKeys.slice()  // Copies Array; New object/address
    const rowKeyCurrentIndex = newPinnedRowKeys.indexOf( rowKey )
    // Four cases:
    if (newPinnedRowKeys[numPinnedRowsStart-1] === rowKey) {
      // This rowKey is already last pinned row. Don't move it. Animation will still run.
    }  if (rowKeyCurrentIndex > -1 ) {
      // This row is already pinned.
      // Move this key from current location to last row.  SAME row count !
      newPinnedRowKeys.splice( rowKeyCurrentIndex, 1)
      newPinnedRowKeys.push(rowKey)
    } else if (numPinnedRowsStart < MAX_NUMBER_PINNED_ROWS)  {
      // Normal Case.  Room for a new pinned row, so append it to array.
      newPinnedRowKeys.push(rowKey)
    } else {
      // User wishes to pin another new row, but no room.
      //Replace last row with this newRowKey
      newPinnedRowKeys[MAX_NUMBER_PINNED_ROWS - 1] = rowKey
    }
    const startY = yPositionStart
    // stop y coordinate is always the last row of the current pinned rows.
    const stopY  = yPositionStop

    dynamics.animate (
      // Next two objects are Start && Stop parameter values.
      { paramA: 0,    Yposition: startY },
      { paramA: 3.14, Yposition: stopY  },
      { // Options:
        change: (obj: PinRowAnimationObj)=> {
          const sinValue = Math.sin( obj.paramA )      // y-coord of a half-circle curve
          const sqrtSinValue = Math.sqrt( sinValue ) // same basic shape as above but faster transistions
          const Xshift = xSwerveLeft * sqrtSinValue
          if (pinIconNode) {
            pinIconNode.style.transform = `translate( ${xPositionStart-Xshift}px, ${obj.Yposition}px)`
          }
        },
        complete:( ) => {
          restorePointerEvents('Pinning Row Icon')
          if (pinIconNode) { pinIconNode.style.transform = `translate( ${LEFT_PARKED_POSITION}px, 0px )` }
          reactDispatch( [ {newVal: newPinnedRowKeys, path: `attributes.pinnedRowKeys` } ], `Pin Row` )
        },
        duration: 400,  // 400 seems about right
        friction: 200,
      }
    )
}



export const handleUnpinRow = ( rowIndex: number, pinnedRowKeyArray: number[], 
                                  tableComputedData: TableComputedData ) : void => {

    if (!wasSuspendPointerEventsSuccessful( 'UnPinning Row Icon' )) { return }
    const {styleObj} = tableComputedData
    const {columnsHead, pinnedRowsBackgroundLocked, pinnedRowsBackgroundMoving} = tableComputedData.tableDomNodes
    const rowBackgroundColor = rowIndex%2 === 0 ? styleObj.cellColorEven : styleObj.cellColorOdd
    // The upPinned row will fade to 'invisible'  (Opacity = 0)
    // Hence the background behind the row must be set to the
    // proper background.
    if ( pinnedRowsBackgroundLocked ) {
      pinnedRowsBackgroundLocked.style.background = rowBackgroundColor
    }
    if ( pinnedRowsBackgroundMoving ) {
      pinnedRowsBackgroundMoving.style.background = rowBackgroundColor
    }
    const newPinnedRowKeys = pinnedRowKeyArray.slice()
    const didUnpinLastRow = ( newPinnedRowKeys.length - 1 === rowIndex ) ? true : false
    newPinnedRowKeys.splice( rowIndex, 1)  // delete rowIndex
    // If the row we are unpinning is currently hightlight, then close the
    // floating palette at the conclusion of the animation.
    const {activeEditor, selectionName, minorStateRowIndex } = tableComputedData.layoutProps
    let shouldCloseFloatingPalette    = false // assumption
    let shouldDecrementPinnedRowIndex = false // assumption
    let newRowIndex = 0 // assumption
    const isEditorOpen = (activeEditor !== '')
    if ( isEditorOpen && selectionName === 'pinnedCell' ) {
      shouldCloseFloatingPalette    = (minorStateRowIndex === rowIndex)
      shouldDecrementPinnedRowIndex = (minorStateRowIndex > rowIndex)
      newRowIndex = Math.max(0, minorStateRowIndex - 1)
    }

    dynamics.animate (
      { opacity: 1 },
      { opacity: 0 },
      { // Options:
        change: (obj: UnpinRowAnimationObj)=> {
          for ( const colNode of columnsHead ) {
            // deleted columns have no domNode:
            if (!colNode) { continue }
            // We don't save all domNodes, just frequently used, useful nodes.
            // And saving DomNodes at the cell level is way too aggressive.
            // We will need to 'drill down' to change the opacity of a single col/row cell.
            const headerStackRelativeNode = colNode?.firstChild as DomNode
            const pinnedColumnContainerNode = headerStackRelativeNode?.children[2] as HTMLElement
            const rc_DataCell = pinnedColumnContainerNode.children[rowIndex] as HTMLElement  
            rc_DataCell.style.opacity = String(obj.opacity)
          }
        },
        complete:( ) => { 
          setTimeout( myReactDispatch, 200 ) 
        },
        duration: 200,   // ~200 nominal
      }
  )


  const myReactDispatch = ( ) => {
      restorePointerEvents('UnPinning Row Icon')
      if (pinIconNode) {
        if (didUnpinLastRow) { pinIconNode.style.transform = `translate( ${LEFT_PARKED_POSITION}px, 0px )` }
        for ( const colNode of columnsHead ) {
          // deleted columns have no domNode:
          if (!colNode) { continue }
          // This row will be re-used!  We need to 'unset' the opacity attribute before 
          // dispatching a state change.
          const headerStackRelativeNode = colNode?.firstChild as DomNode
          const pinnedColumnContainerNode = headerStackRelativeNode?.children[2] as HTMLElement
          const rc_DataCell = pinnedColumnContainerNode.children[rowIndex] as HTMLElement  
          rc_DataCell.style.opacity = 'unset'
        }
      }
      const mods: LightweightMod[] = [{newVal: newPinnedRowKeys, path: `attributes.pinnedRowKeys`}]
      if ( shouldCloseFloatingPalette ) {
        reactMinorDispatch([
        {newVal:{name:'', colIndex:-1, rowIndex:-1}, path: 'attributes.minorState.selection'},
        {newVal:false, path: 'attributes.minorState.isEditorOpen'},
        ])
      }
      if ( shouldDecrementPinnedRowIndex ) {
        mods.push({newVal: newRowIndex, path: `attributes.minorState.selection.rowIndex` })
      }
      reactDispatch(mods, `Un-Pin Row` )
  }
}


