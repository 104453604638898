import { PureComponent } from 'react'
import type { JSX } from 'react'

class FormSubmitButton extends PureComponent<{
  children: JSX.Element | JSX.Element[] | string
  id: string
  tabIndex?: number
  width?: string
}> {
  render () {
    const {children, id, tabIndex, width} = this.props
    return (
      <button className={'rc_FormSubmitButton'}
        id={id}
        style={{
          appearance: 'none',
          backgroundColor: '#28a745',
          backgroundImage: 'linear-gradient(-180deg, #34d058 0%, #28a745 90%)',
          backgroundPosition: '-1px -1px',
          backgroundRepeat: 'repeat-x',
          backgroundSize: '110% 110%',
          border: '1px solid rgba(27,31,35,0.2)',
          borderRadius: '0.25em',
          color: '#fff',
          display: 'block',
          fontSize: '14px',
          fontWeight: 'bold',
          marginTop: '20px',
          padding: '6px 12px',
          userSelect: 'none',
          verticalAlign: 'middle',
          whiteSpace: 'nowrap',
          width: width ? width: undefined
        }}
        tabIndex={tabIndex ? tabIndex : undefined}
        type='submit'
      >
        {children}
      </button>
    )
  }
}

export default FormSubmitButton
