import type { SetFpParentState, FpParentState } from '../floatingPaletteNew/FpParent'
import type { ActiveFp, ViewName, FpName} from '../types'

// Floating Palette components:
import {useGetFpNone}               from '../floatingPaletteNew/FpNone'
import {useGetFpTest}               from '../floatingPaletteNew/FpTest'
import { useGetUndoHistory } from '../floatingPaletteNew/MenuBarFp_UndoHistory'
import { useGetPlotCreate } from '../floatingPaletteNew/NavColFp_PlotCreate'
import { useGetPlotDownload } from '../floatingPaletteNew/NavColFp_PlotDownload'
import { useGetPlotDelete } from '../floatingPaletteNew/NavColFp_PlotDelete'
import {useGetTableRowFiltering}    from '../floatingPaletteNew/TableFp_RowFiltering'
import {useGetTableMainTitle}       from '../floatingPaletteNew/TableFp_MainTitle'
import {useGetTablePublisherTitle}  from '../floatingPaletteNew/TableFp_PublisherTitle'
import {useGetTableCellNumber}      from '../floatingPaletteNew/TableFp_CellNumber'
import {useGetTableCellString}      from '../floatingPaletteNew/TableFp_CellString'
import {useGetTableColHeader}       from '../floatingPaletteNew/TableFp_Header'
import {sessionStateChangeDispatch} from '../sharedComponents/reactDispatch'

// Side Bar components:
import {useGetTableSb}              from '../sideBars/TableSb'



export type FpSessionState = { activeTab: number }
export type FpSessionStates_ByFpName = { [key in FpName] : FpSessionState }
export const getDefaultFpSessionStates_ByFpName = () : FpSessionStates_ByFpName => {
    return {
        menuBarUndoHistory  : {activeTab: 0},
        navColPlotCreate    : {activeTab: 0},
        navColPlotDelete    : {activeTab: 0},
        navColPlotDownload  : {activeTab: 0},
        none                : {activeTab: 0},
        fpTest              : {activeTab: 3},
        tableMainTitle      : {activeTab: 0},
        tablePublisherTitle : {activeTab: 0},
        tableRowFiltering   : {activeTab: 0},
        tableColHeader      : {activeTab: 0},
        tableCellHyperlink  : {activeTab: 0},
        tableCellFormula    : {activeTab: 0},
        tableCellNumber     : {activeTab: 0},
        tableCellString     : {activeTab: 0},
        tableCellDateTime   : {activeTab: 0},
    }
}

export type SbSessionState = {
    activeTab: number,
    isSBopen: boolean,
}
export type SbSessionStates_ByView = { [key in ViewName] : SbSessionState }
export const getDefaultSbSessionStates_ByView = () : SbSessionStates_ByView => {
    return {
        tableView : { activeTab: 0, isSBopen: false },
        searchView : { activeTab: 0, isSBopen: false },
        plotXyView : { activeTab: 0, isSBopen: false },  
        sitePageView : { activeTab: 0, isSBopen: false },
        homeView : { activeTab: 0, isSBopen: false }, 
    } 
}


// Properties passed to the floating palette children, with setState function used
// to talk back to the FpParentState.  All children designed to use this interface.
// Child may also have thier own additional state.
export type FpChildRenderProps = {
    activeFp: ActiveFp,
    fpParentState: FpParentState,
    setFpParentState: SetFpParentState
}

// This object is shared by Fp and Sb, and passed to the common TabController
// Consider it as belonging to the TabController.
export type TabInfoArr = {
    tabIndex: number, // So we can set activeTab value, even if displayed tabs array is sparse.
    tabStrg1: string
    tabStrg2: string
    isErroneous: boolean  // Tab isErroneous (red text) if FpChild has an error.
}[] 

export type FpChildInfo = {
    childWidth: number
    childHeight: number
    RenderedChild: React.FC<FpChildRenderProps>
}

export type FpChildInfoArr = FpChildInfo[]

export type FpSelectedCellGeometry = {
    left: number,
    top: number,
    width: number,
    height: number,
    isCellVisible: boolean
}

export type GetFpSelectedCellGeometry =  (_:ActiveFp) => FpSelectedCellGeometry

export type FpLayout = {
    activeFp: ActiveFp
    titleBarStrg: string
    isDraggable: boolean, 
    isResizableX: boolean,
    isResizableY: boolean,
    minResizeX: number,
    minResizeY: number,
    maxResizeX: number,
    maxResizeY: number,
    tabInfoArr: TabInfoArr 
    childInfoArr: FpChildInfoArr
    getSelectedCellGeometry: GetFpSelectedCellGeometry
}
export type GetFpLayout_ByFpName = { [key in FpName] : (arg:ActiveFp)=>FpLayout  }
export const getFpLayout_ByFpName : GetFpLayout_ByFpName = {
    menuBarUndoHistory  : useGetUndoHistory,
    navColPlotCreate    : useGetPlotCreate,
    navColPlotDelete    : useGetPlotDelete,
    navColPlotDownload  : useGetPlotDownload,
    none                : useGetFpNone, 
    fpTest              : useGetFpTest,  // To render the testPalette, assign useGetFpTest to any subsequent clicked table locations.
    tableMainTitle      : useGetTableMainTitle,
    tablePublisherTitle : useGetTablePublisherTitle,
    tableRowFiltering   : useGetTableRowFiltering,
    tableColHeader      : useGetTableColHeader,
    tableCellHyperlink  : useGetTableCellNumber,
    tableCellFormula    : useGetTableCellNumber,
    tableCellNumber     : useGetTableCellNumber, 
    tableCellString     : useGetTableCellString,
    tableCellDateTime   : useGetTableCellNumber,
}



// Sb specific information, imported from individual Sb files
// The information needed by SbParent3 component and TabController3 component 

export type SbAnimationStart = ()=>void 
export type SbAnimation      = (width:number)=>void 
export type SbAnimationStop  = ()=>void 
export type SbChildInfo = { SbChildComponent: React.FC }

export type SbLayout = {
    viewName: ViewName
    //legalDisplayedTabIndex: number  // may override session state
    parentAnimationStart: SbAnimationStart
    parentAnimation     : SbAnimation
    parentAnimationStop : SbAnimationStop
    tabInfoArr: TabInfoArr,
    childInfoArr: SbChildInfo[]
}
export type GetSbLayout_ByView = { [key in ViewName] : ( ()=>SbLayout | null )}
export const useGetSbLayout_ByView : GetSbLayout_ByView = {
    // null means no side bar for this view
    tableView : useGetTableSb,
    searchView : useGetTableSb, //() => null,
    plotXyView : () => null,
    sitePageView : () => null,
    homeView : () => null,
}




