import { PureComponent } from 'react'
import { SCRY_LIGHT_BLUE } from '../sharedComponents/constants'

type OwnProps = {
  width: number
  height: number
}
export default class SVGsliderKnob extends PureComponent<OwnProps> {
  render() {
    return (
      <svg
        className={'rc_SVGsliderKnob'}
        xmlns="http://www.w3.org/2000/svg"
        style={{ isolation: 'isolate', display: 'block' }}
        viewBox="0 0 200 200"
        preserveAspectRatio='none'
        width={this.props.width}
        height={this.props.height}
      >
        <circle cx="100" cy="100" r="90" style={{ stroke: '#404040', strokeWidth: 20, fill: SCRY_LIGHT_BLUE }} />
      </svg>
    )
  }
}
