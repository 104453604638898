import type {TableComputedData,
             TableLayoutProps}      from '../computedDataTable/getDefaultTableComputedData'
import type {TableWidthObj}         from '../computedDataTable/layoutCalculator'
import type {LightweightMod}        from '../types'


import {getDefaultTableColumn,
        getDefaultColumnlook}       from '../types'
import {batch}                      from 'react-redux'
import dynamics                     from 'dynamics.js'
import constants                    from '../sharedComponents/constants'
import reactDispatch, 
       {sessionStateChangeDispatch} from '../sharedComponents/reactDispatch'
import {widthCalculator,
        enableForcedCombinedTable,
        disableForcedCombinedTable} from '../computedDataTable/layoutCalculator'
//import {type Columnlook}            from '../types'
// import {deepClone}                  from '../sharedFunctions/utils'
import {wasSuspendPointerEventsSuccessful,
        restorePointerEvents}       from '../sharedFunctions/pointerEvents'
import {initTableResponsiveState,
        restoreInitialTableResponsiveState,
        updateTableBlocks, 
        colResize, 
        //updateFloatingPaletteHighlight,
        setColumnOpacity}           from './tableResponsiveState'
import {getTableComputedData}       from '../appCode/getMemoizedComputedData'
import {errorCheckColOrder}         from '../computedDataTable/updateTableSupportFuncs'


// TESTING -- TESTING -- TESTING:
// This module supports four functions:
//    1 - Hide   a column
//    2 - Unhide a column
//    3 - Delete a column
//    4 - Insert a column ( inserting a new or cloned col can be considered equivalent here).
//
// A change to any one function requires testing of all four functions.
// And there are a number of corners, each needing testing.
//
//   Corners cases:
// Prior column hidden or visible
// Next  column hidden or visible
// This  column locked or moving.
// This  table isCombinedTable true or false
// This  column last visible col or not (both locked or moving).
//
// That's 2**5 corners for each of four functions!
// Yes, 128 total tests.
// But each test is quick when code is functional.
// And any given bug is likely to affect multiple corners.
//
// Successful test:
//   - Does what is visually explanatory and correct.
//   - There should be NO glitches in the animation. (Other than the hidden
//     arrow icon and number of hidden columns, which may change its visibility
//     or value with each react render.)  The animation should scroll when
//     necessary; should smoothly alter the gap between locked and moving cols
//     when necessary; and should resize tables if/when necessary.

type WidthAnimationObj = {
  widthBase: number
  tableSpacing: number
}

type OpacityAnimationObj = {
  opacity: number
}


const execPerturbedWidthCalculator = ( colIndex:number, newWidth: number, newTableSpacing:number, layoutProps: TableLayoutProps ) : TableWidthObj => { 
    const perturbedColWidth_ByColIndex = layoutProps.colWidth_ByColIndex.slice()
    perturbedColWidth_ByColIndex[colIndex] = newWidth
    // Whether hidding or unHidding, during the animation the isHidden flag is 'false'.
    const perturbedIsHidden_ByColIndex = layoutProps.isHidden_ByColIndex.slice()
    perturbedIsHidden_ByColIndex[colIndex] = false
    perturbedColWidth_ByColIndex[colIndex] = newWidth
    const perturbedProps : TableLayoutProps = { 
      ...layoutProps,
      isHidden_ByColIndex : perturbedIsHidden_ByColIndex, 
      colWidth_ByColIndex : perturbedColWidth_ByColIndex,
      tableSpacing : newTableSpacing 
    }
    return widthCalculator( perturbedProps )
}


const redraw = ( layoutProps: TableLayoutProps, colIndex: number, scrollLeft:number, 
                          animationObj: WidthAnimationObj, isUnhideCol: boolean ) : number => {
    const {widthBase, tableSpacing} = animationObj
    const w = execPerturbedWidthCalculator( colIndex, widthBase, tableSpacing, layoutProps )
    colResize( colIndex, w )
    let adjustedScrollLeft = scrollLeft   // default is no change in scroll position.
    if ( isUnhideCol && colIndex >= w.numLockedCols ) {  
        // Then expanding column is in the moving table.
        // The new column of growing width may be on the right side of the table, and the growth
        // will extend beyond the visible right edge of table.  Worse case example is 'unhiding'
        // the last column.  In this case the newly grown column is completely invisible as it
        // completely grows outside the view of the visible table.  This can be solved by
        // increasing scrollLeft as necessary.  (The expanding column also scrolls into view.)
        // Rule: The right edge position of the growing column should never extend beyond
        // the table:  rightEdge of growing column <= scrollLeft + movingAllocated
        // Hence scrollLeft >= rightEdgeUnhidenCol - movingAllocated
        // We apply this rule ONLY  when 'unHiding' a column!! 
        // It's functional with 'hide', but not at all the smooth behavior we would want!
        adjustedScrollLeft = isUnhideCol ? Math.max( scrollLeft, w.startColLeft[colIndex+1] - w.movingAllocated )
                                      : scrollLeft
    }
    updateTableBlocks( w, adjustedScrollLeft )   // this func includes synch of horz scrollControl.
    //updateFloatingPaletteHighlight( w )
    return adjustedScrollLeft
}

// Problem:
//    Given a visible Col, which we want to hide.
//    And this column may already have hidden columns to its left (1 or more).
//    And we want to assign a hiddenIndex to this column that says: "Next unhide
//    operation, unhide me FIRST, because I was the last hidden".

//    If we hide another column, it needs to be the first unhidden.
//    In other words, given a set of 1 to n hidden columns under the single 'unhide control',
//    unhide that column which was most recently hidden.

//    Here is the worse case example that needs to be handled
//    We have seven columns, index 0 to 6.  There is a colKey assigned to each,
//    but easier to work with the column index left to right, and we just know
//    each column also has a colKey identifier.

//    A hidden index of zero means 'Visible'
//    hidden index > 0 means hidden.
//    When hiding and unhiding columns, assign a hidden index such that last Hidden === first unHidden.

//    Our seven columns have hidden Index of:
//    1,2,0,1,2,3,0   (indices of 0,1,2,3,4,5,6)

//    User sees:
//    col indices 2 and 6.
//    Col index 2 has two hidden columns 'beneath' it.
//    Col index 6 has 3 hidden columns 'beneath' it.

//    We want to hide colIndex 2.  After, we will only see colIndex6.
//    When we 'unhide' colIndex6, colIndex 2 should be exposed.
//
//    Algorithm:
//    When I 'hide index 2', I look to the left and right at all the hidden
//    columns. Of these hidden columns, what is the largest hidden index?
//    Col index 6 has a hidden index === 3.
//    So I assign the next higher index (4) to the column I am hiding.

//    Modified seven columns now have hidden indices of:
//    1,2,4,2,1,2,3,0
//    Only column at index 6 is visible.

//    If I unhide a column at visible colIndex 6, then:
//    What hidden index to my left is largest?
//    col Index 2 has the largest hidden index with a value of 4.
//    Hence it was last hidden, and first to be unhidden.
//    To unhide, I re-set the hidden index 4 to value of 0.
//    And we are back to where we started

// Used for 'hide' only
const findMaxValOfHiddenColumnsToRight = ( colIndexIn: number, layoutProps: TableLayoutProps, widthObj : TableWidthObj ) 
                                                               : { maxVal: number, colIndexOfMaxVal: number } => {
    const {isHidden_ByColIndex, hiddenVal_ByColIndex} = layoutProps                                                            
    const colIndex = colIndexIn
    let colIndexOfMaxVal = colIndexIn
    const numCol = isHidden_ByColIndex.length
    let maxVal = 0
    // Loop starts one column to the right of the column we wish to hide.
    for (let i = colIndex+1; i<numCol; i++ ) {
        const hiddenVal = hiddenVal_ByColIndex[i]
        if ( hiddenVal === 0 ) {break}    // Stop when we find a NOT hidden column.
        if ( hiddenVal >= maxVal ) {   // By using >=, we take the latest of any identical hiddenIndex
          maxVal = hiddenVal
          colIndexOfMaxVal = i
        }
        // Stop at right edge of locked table. Hidden controls don't extend across the locked/moving boundary
        if ( !widthObj.isCombinedTable && i === widthObj.numLockedCols-1 ) {break}
    }
    return {maxVal, colIndexOfMaxVal}
}


// Used for 'hide' and 'unhide'
const findMaxValOfHiddenColumnsToLeft = ( colIndexIn: number, layoutProps: TableLayoutProps, widthObj: TableWidthObj ) 
                                                              : { maxVal: number, colIndexOfMaxVal: number } => {                                                       
    const {isHidden_ByColIndex, hiddenVal_ByColIndex} = layoutProps
    let colIndex : number
    if ( isHidden_ByColIndex[colIndexIn] ) {
        // clicked 'unhide' controls are always visible columns.
        // EXCEPTION: the unhide control in-the-gap, and the unhide control right-of-table
        // IF the clicked column is hidden, it MUST be one of these two controls.
        // in which case we include this column in our search for column with max hiddenVal.
        colIndex = colIndexIn
    } else {
        // The current clicked 'unhide' control MUST be a visible column
        // No need to include this column in our search for max hiddenVaL.
        colIndex = colIndexIn - 1 
    }
    // Sequence through all priorHidden columns to find max hiddenVal
    let maxVal  = -1
    let colIndexOfMaxVal = -1
    for (let i = colIndex; i >= 0; i-- ) {
        const hiddenVal = hiddenVal_ByColIndex[i]
        if ( hiddenVal === 0 ) {break}    // Stop when we find a preceeding NOT hidden column.
        if ( hiddenVal >= maxVal ) {   // By using >=, we take the latest of any identical hiddenIndex
          maxVal = hiddenVal
          colIndexOfMaxVal = i
        }
        // Stop at left edge of moving table. Locked hidden cols NOT controlled by moving table controls.
        if ( !widthObj.isCombinedTable && i === widthObj.numLockedCols ) {break}
    }
    return {maxVal, colIndexOfMaxVal}
}


const isUnhideCol_True  = true
const isUnhideCol_False = false

export const unHideColHandler = (tableComputedData: TableComputedData, colIndex: number ) : number => {
    const {widthObj:preWidthObj, scrollLeft, layoutProps, derivedColOrder} = tableComputedData
    const {maxVal, colIndexOfMaxVal} = findMaxValOfHiddenColumnsToLeft( colIndex, layoutProps, preWidthObj )
    const colKeyOfMaxVal = derivedColOrder[colIndexOfMaxVal]
    if ( maxVal === 0 ) { return -1 } // Nothing to unhide.

    // We will be doing an animation!
    if (!wasSuspendPointerEventsSuccessful( 'unHideColHandler' )) { return 0 }
    const onStart_isCombinedTable = preWidthObj.isCombinedTable
    // What is the table layout AFTER unHiding this column?
    // We know the newColWidth will be non-zero.
    // But the gapBetween tables is not easy to predict!!  It is a non-linear function:
    //     Math.max( w.gapLockedMoving, Math.round(defaultStyleObj.colControlSize * 0.80 - w.borderThickness ))
    // But we can run the layout calculator to calculate the postAnimation layout.  
    // And from postLayout, we can determine the end result of above non-linear expression.
    // NOTE: During animation  we DO NOT want to emulate the above non-linear expression.
    //       We only need to know the start/stop gapLockedMoving tables.
    //       For the animation, we will simply interpolate linearly beteen start/stop values.
    const preWidth  = 0
    const postWidth = layoutProps.colWidth_ByColIndex[colIndexOfMaxVal]
    const postIsHidden_ByColIndex = layoutProps.isHidden_ByColIndex.slice()
    postIsHidden_ByColIndex[colIndexOfMaxVal] = false
    const postLayoutProps = {
      ...layoutProps,
      isHidden_ByColIndex: postIsHidden_ByColIndex, 
    }
    const postWidthObj = widthCalculator( postLayoutProps )
    const preTableSpacing  = preWidthObj.gapLockedMoving / layoutProps.globalScale
    const postTableSpacing = postWidthObj.gapLockedMoving / layoutProps.globalScale
    enableForcedCombinedTable( preWidthObj.isCombinedTable )
    initTableResponsiveState(tableComputedData)
    let   adjustedScrollLeft = 0

    dynamics.animate (
      { widthBase: preWidth,  tableSpacing: preTableSpacing },  // start values
      { widthBase: postWidth, tableSpacing: postTableSpacing},  // end   values
      { // Options:
        change: (obj: WidthAnimationObj)=> {
            adjustedScrollLeft = redraw( layoutProps, colIndexOfMaxVal, scrollLeft, obj, isUnhideCol_True ) 
        },
        complete:( ) => {
            disableForcedCombinedTable( )
            restorePointerEvents('unHideColHandler')
            restoreInitialTableResponsiveState( )
            // If we start with a non-combinedTable, and the final result will be a combinedTable,
            // Then we should pick a new scrollLeft, such that the full locked column set is displayed
            // specifically scrollLeft = 0
            if ( onStart_isCombinedTable === false ) {
              const nextRenderedWidthObj = execPerturbedWidthCalculator( colIndexOfMaxVal, postWidth, postTableSpacing, layoutProps)
              if (nextRenderedWidthObj.isCombinedTable === true) { adjustedScrollLeft = 0 }
            }
            const resourceMod = [{newVal: 0, path: `attributes.lookColumns[${colKeyOfMaxVal}].hidden` },
                        // Next mod sets the current statsBar info to the newly unhidden column
                        {newVal: colKeyOfMaxVal,     path: 'attributes.minorState.selection.colIndex'}]
            reactDispatch( resourceMod, 'unhide column' )
            // No need to make a scrollLeft mod change; the value is properly constrained 
            // in updateTableComputedData anyway.
            const mods = [
              {newVal: Math.round( adjustedScrollLeft ), path: `activeTableScrollLeft` },
              {newVal: colKeyOfMaxVal, path: 'activeStatsColKey'},  // -1 means no stats col to display
            ]
            sessionStateChangeDispatch( mods, 'Unhide Column' )
        },
        duration: 500,  //500 nominal
        friction: 400,
      }
    )
    return colKeyOfMaxVal
}


export const hideColHandler = (tableComputedData: TableComputedData,  colKeyIn: number ) : void => {
    if (!wasSuspendPointerEventsSuccessful( 'HideColHandler' )) { return }
    const { layoutProps, scrollLeft, widthObj: preWidthObj, derivedColOrder } = tableComputedData
    const colIndex = derivedColOrder.indexOf( colKeyIn )
    // What is the table layout AFTER hiding this column?
    // We know the newColWidth will be zero.
    // But the gapBetween tables is not easy to predict!!  It is a non-linear function:
    //     Math.max( w.gapLockedMoving, Math.round(defaultStyleObj.colControlSize * 0.80 - w.borderThickness ))
    // But we can run the layout calculator to calculate the postAnimation layout parameters.
    const preWidth  = layoutProps.colWidth_ByColIndex[colIndex]
    const postWidth = 0
    const postIsHidden_ByColIndex = layoutProps.isHidden_ByColIndex.slice()
    postIsHidden_ByColIndex[ colIndex ] = true
    const postLayoutProps = {
      ...layoutProps,
      isHidden_ByColIndex: postIsHidden_ByColIndex, 
    }
    const postWidthObj = widthCalculator( postLayoutProps )
    const preTableSpacing  = preWidthObj.gapLockedMoving / layoutProps.globalScale
    const postTableSpacing = postWidthObj.gapLockedMoving / layoutProps.globalScale
    enableForcedCombinedTable( preWidthObj.isCombinedTable )
    initTableResponsiveState(tableComputedData)
    const {maxVal:max1} = findMaxValOfHiddenColumnsToRight(colIndex, layoutProps, preWidthObj )
    const {maxVal:max2} = findMaxValOfHiddenColumnsToLeft( colIndex, layoutProps, preWidthObj )
    const maxVal = Math.max(max1,max2)
    dynamics.animate (
      { widthBase: preWidth,   tableSpacing: preTableSpacing },  // Start Values
      { widthBase: postWidth , tableSpacing: postTableSpacing},  // End   Values
      { // Options:
        change: (obj: WidthAnimationObj)=> {
            redraw( layoutProps, colIndex, scrollLeft, obj, isUnhideCol_False ) 
        },
        complete:( ) => {
            disableForcedCombinedTable( ) 
            restorePointerEvents('HideColHandler')
            restoreInitialTableResponsiveState( )
            disableForcedCombinedTable( )   
            const resourceMod = [{newVal: maxVal+1, path: `attributes.lookColumns[${colKeyIn}].hidden` }]
            reactDispatch( resourceMod, 'hide column')
            // Case of hiding the last (or near last) column, but scrollBar is at far right extremn.
            // We need to shift the scroll position 'smaller' such that movingRequired - movingAllocated !> scrollLeft.
            // This is also done in the redraw function.
            const endScrollLeft = Math.max( 0, Math.min( scrollLeft, postWidthObj.movingRequired - postWidthObj.movingAllocated))
            const sessionMods = [
              {newVal: Math.round( endScrollLeft ), path: `activeTableScrollLeft` },
              {newVal: -1, path: `activeStatsColKey` }
            ]
            sessionStateChangeDispatch( sessionMods, 'hide column' )
        },
        duration: 500,  // 500 nominal
        friction: 400,
      }
    )
}


export const deleteColHandler = (tableMods: LightweightMod[], tablelookMods: LightweightMod[], 
               colKey: number, tableComputedData: TableComputedData, shouldResetActiveStatsColKey: boolean ) : void => {
    if ( !wasSuspendPointerEventsSuccessful( 'DeleteColHandler' )) { return }
    initTableResponsiveState(tableComputedData)
    const {scrollLeft, layoutProps, widthObj, derivedColOrder} = tableComputedData
    const colIndex = derivedColOrder.indexOf(colKey)
    const {movingRequired, movingAllocated} = widthObj
    const widthToBeHidden = layoutProps.colWidth_ByColIndex[colIndex]
    const {tableSpacing} = layoutProps // Does not change
    // Step #1: define the function which erases a visible column.
    const fadeAnimation = ()=>{ dynamics.animate(
        { opacity: 1 },  // Start values
        { opacity: 0 },  // Stop values
        { // Options:
          change  : (obj: OpacityAnimationObj)=> setColumnOpacity( obj.opacity, colKey ),
          // A cosmetic pause:
          complete: ( ) =>  window.requestAnimationFrame( ()=>
                            window.requestAnimationFrame( ()=>
                            window.requestAnimationFrame( ()=>
                            window.requestAnimationFrame( ()=>
                            window.requestAnimationFrame( ()=>
                            window.requestAnimationFrame( ()=>
                            window.requestAnimationFrame( ()=>
                            window.requestAnimationFrame( ()=>hideAnimation() )))))))),
          duration: 550,   // 550 nominal
          friction: 400,
        }
    )}
    // Step #2: define the function which closes the visible remaining gap.
    // Uses the hide column functionality which handles all corner cases.
    const hideAnimation = ()=>{ dynamics.animate(
        { widthBase:widthToBeHidden, tableSpacing },  // start values
        { widthBase:0 ,              tableSpacing },  // stop  values
        { // Options:
          change: (obj: WidthAnimationObj)=> {
            redraw ( layoutProps, colIndex, scrollLeft, obj, isUnhideCol_False ) 
          },
          complete:( ) => {
            restoreInitialTableResponsiveState( )
            restorePointerEvents('DeleteColHandler')
            batch( ()=> {
              reactDispatch( tableMods, 'delete column')
              reactDispatch( tablelookMods, 'delete column')
              const legalScrollLeft = Math.max( 0, Math.min( scrollLeft, movingRequired - movingAllocated))
              const sessionMods = [{newVal: Math.round(legalScrollLeft), path: `activeTableScrollLeft` }]
              if (shouldResetActiveStatsColKey) {
                sessionMods.push({newVal: -1, path: `activeStatsColKey` })
              }
              sessionStateChangeDispatch( sessionMods, 'delete column' )
            })
          },
          duration: 350,  // 350 nominal
          friction: 400,
        }
    )}
    // Call the function to execute step #1.
    fadeAnimation()
}


// Dec, 2020 -- Why this animation is 'unique' compared to prior animations:
//
// Next animation requires a minimum of 2 ReactDispatches (2 react renders)!
// I believe every animation prior to this required only one react dispatch.
// (Although some animations, like column drag left/right, can issue more than
// one dispatch.  But this is really a 'final dispatch' issued multiple times.)
//
// This 'insert new column' animation cannot be done without 2 react dispatches.
// The first dispatch is required because the part of the domTree we want
// to animate (a newly inserted column) does not yet exist in the domTree.
// And the final dispatch is needed to set the React virtual domTree to exactly
// match the final state of the animation. (This is the single required dispatch
// in all prior animations.)
//
// What is unique about this animation?
// Two dispatches are needed because the part of the domTree we wish to animate:
//     -- does not exist AND cannot be predicted in advance! --
// This problem has been avoided in all prior animations because:
//     -- We usually animate something that is already visible.
//     -- If and when what we animate is NOT visible (for example sideBar)
//        we can still render it in advance and set it hidden, place it offscreen,
//        or some other pre-rendering technique.  And we can do this because
//        we know in advance how the rendered domTree will appear.

/* Animation sequence for inserting a new column.

  0) suspendPointEvents( )
  1) Render the new column -- but not visible!  I will use a column width
      of zero px.  It could be made invisible with opacity of zero or
      visibility of 'hidden'.  But the react render function doesn't need either
      opacity or visibility attributes.  But it does take a width attribute.
      Hence, I use width of zero.   There should be NO visible glitch from this render.
  1a) After this render we will require tableComputedData.  We newly rendered version,
      that includes the newly created column.  We access it using the tableid.
  2) After rendering is complete:
        2a) set opacity to zero.
        2b) initTableResponsiveState   // Capture the current attributes of virtualDom
  3) Animate column width change from zero to expandedWidth. (User sees empty gap emerge.)
  4) Animate column opacity change from zero to one (User sees column appear)
  5) On animation complete:
        5a) Set opacity back to zero
        5b) restorePointerEvents()
        5c) restoreInitialTableResponsiveState   // Reset virtualDom back to its prior state.
  6) Render the new column in its final expected state.
     There should be NO visible glitch from this 2nd render.  It should match exactly
     the final frame rendered by the animation.

*/



export const exposeNewColHandler = ( colKey: number, postWidth_notGlobalScaled: number, thisAction: string, 
         actionGroup: string, tableid: string ) : void => { 
    // We need the newest update of tableComputedData, that includes the new column!
    const tableComputedData = getTableComputedData(tableid)
    if (!tableComputedData) { return }
    if (! wasSuspendPointerEventsSuccessful( thisAction )) { return }
    initTableResponsiveState(tableComputedData)
    const {scrollLeft, derivedColOrder, layoutProps} = tableComputedData
    const {tableSpacing, globalScale} = layoutProps   // Does not change
    const colIndex = derivedColOrder.indexOf( colKey )
    const expandedWidth = postWidth_notGlobalScaled * globalScale
    setColumnOpacity( 0, colKey )
    // Next function dynamically opens the necessary space to insert the new column
    // In reality, the column is already rendered, and this is just 'unhiding' the column
    // by dynamically shifting it to the right.
    dynamics.animate (
        { widthBase: 0,             tableSpacing },  // start values
        { widthBase: expandedWidth, tableSpacing },  // stop  values
        { // Options:
          change  : (obj: WidthAnimationObj)=> {
            redraw ( layoutProps, colIndex, scrollLeft, obj, isUnhideCol_True ) 
          },
          // Cosmetic pause before exposing the new column:
          complete: ()=>  window.requestAnimationFrame( ()=>
                          window.requestAnimationFrame( ()=>
                          window.requestAnimationFrame( ()=>
                          window.requestAnimationFrame( ()=>
                          exposeNewColHandlerPart2( colKey, postWidth_notGlobalScaled, scrollLeft, thisAction, actionGroup ))))),
          duration: 350,  // nominal 350
          friction: 400,
        }
    )
}


const exposeNewColHandlerPart2 = ( colKey:number, postWidth_notGlobalScaled:number, scrollLeft: number, 
                                         thisAction:string, actionGroup:string ) => {
    dynamics.animate (
      { opacity: 0 },
      { opacity: 1 },
      { // Options:
          change  : (obj: OpacityAnimationObj) => setColumnOpacity( obj.opacity, colKey ),
          complete: ( ) => {
              restorePointerEvents(thisAction)
              restoreInitialTableResponsiveState( )
              const tablelookMods =[{newVal: 0, path: `attributes.lookColumns[${colKey}].hidden` }]
              reactDispatch( tablelookMods, thisAction, actionGroup )  // 3rd arg is the optional 'actionGroup'
              const sessionStateMods = [{newVal: Math.round( scrollLeft ), path: `activeTableScrollLeft` }]
              sessionStateChangeDispatch( sessionStateMods, thisAction )
           },
        duration: 550,  // nominal 550
        friction: 400,
      }
    )
}


export type InsertColActions = 'new'|'cloned'|'restored'


const DEBUG = false

// type InsertActions = 'new'|'cloned'|'restored'
export const insertColumn = ( selectedColKey: number, mode: InsertColActions , restoredColKey:number = -1,
                                        tCD : TableComputedData ) : void => {

    // All three modes use the same insert column animation.
    // But the new column that will appear depends on the mode.
    // This function defines the state change mods that vary,
    // depending on where/how we define the newly inserted column.
    const {table, tablelook, tabledata, derivedColOrder, widthObj:w, derivedColAttributesArray} = tCD
    const {numLockedCols} = w
    const {columns} = table.attributes
    const {lookColumns, globalScale } = tablelook.attributes
    const selectedColumn = columns[selectedColKey]
    const lookColumn = lookColumns[selectedColKey]
    const defaultNewColWidth = 120
    let   postWidth_notGlobalScaled : number = 0
    switch ( mode ) {
      case 'new'      : 
        postWidth_notGlobalScaled = defaultNewColWidth
        break
      case 'cloned'   : 
        postWidth_notGlobalScaled = derivedColAttributesArray[selectedColKey].constrainedScaledColWidth / globalScale
        break
      case 'restored' : 
        postWidth_notGlobalScaled = derivedColAttributesArray[restoredColKey].constrainedScaledColWidth / globalScale
        break
      default: break;
    }
    const newOrRestoredColKey = ( mode === 'restored' ) ? restoredColKey : columns.length
    const tableMods: LightweightMod[] = []
    const tableLookMods: LightweightMod[] = []
    const tableDataMods: LightweightMod[] = []

    const isDeletedArr_preInsert = derivedColAttributesArray.map( c => c.isDeleted )
    const colTitleArr_preInsert  = derivedColAttributesArray.map( c => c.colTitle )
    const isDeletedArr_postInsert= isDeletedArr_preInsert.slice()
    const colTitleArr_postInsert = colTitleArr_preInsert.slice()
    const indexOfSelectedColKey  = derivedColOrder.indexOf( selectedColKey )
    const newDerivedColOrder = derivedColOrder.slice()

    if ( mode === 'cloned' || mode === 'new' ) {
        const columnsCopy = columns.slice()
        const lookColumnsCopy = tablelook.attributes.lookColumns.slice()
        const dataColumnsCopy = tabledata.attributes.tableValues.slice()
        const newColTitle = ( mode === 'new' ) ? 'NEW COL' : 'CLONE: ' + selectedColumn.colTitle
        if ( mode === 'cloned' ) {
            const newCol = structuredClone( selectedColumn )
            const newLook = structuredClone( lookColumn )
            newCol.colTitle  = newColTitle
            newCol.isDepCol = true
            newCol.isKey = false
            newLook.hidden = 1000  // All inserted columns begin their life hidden; Then dynamically expose.
                                  // I set the hidden index very high to insure this is the first column to be 'un-hidden'.
            // if isDep, formula is a copy of current formula
            // else formula just copies prior column
            if (selectedColumn.isDepCol) {
              newCol.formula = structuredClone( selectedColumn.formula )
            } else {
              newCol.formula = [
                `return ${constants.COL_KEY_CANONICAL_FORM}${selectedColKey}_`
              ]
            }
            columnsCopy.push(newCol)
            lookColumnsCopy.push(newLook)
        } else {  // MODE === 'new'  -- new default dependent column requiring a newColKey
            const newCol = getDefaultTableColumn({
                        formula: ['return pi'],
                        isDepCol: true,
                        colDataType: 'number',
                        colTitle: newColTitle
                      })
            const newLook = getDefaultColumnlook({
                        hidden: 1000,  // All inserted columns begin thier life hidden; Then dynamically expose.
                        width : defaultNewColWidth,   
                        precisionFixed:0
                      })
            columnsCopy.push(newCol)
            lookColumnsCopy.push(newLook)
        }
        // Add the new or cloned column to all three table resources
        dataColumnsCopy.push(new Array(0))  // Empty array; New columns are ALWAYS dependent columns.
        tableMods.push({ newVal: columnsCopy,     path: `attributes.columns`, resId: table.id, resType: 'tables' })
        tableLookMods.push({ newVal: lookColumnsCopy, path: `attributes.lookColumns`, resId: tablelook.id, resType: 'tablelooks'  })
        tableDataMods.push({ newVal: dataColumnsCopy, path: `attributes.tableValues`, resId: tabledata.id, resType: 'tabledatas' })
        // new or cloned columns 'add a new column' to newDerivedColOrder, isDeletedArr, colTitleArr
        newDerivedColOrder.splice( indexOfSelectedColKey+1, 0, newOrRestoredColKey)
        isDeletedArr_postInsert.push( false )
        colTitleArr_postInsert.push( newColTitle )
    }

    if ( mode === 'restored' ) {
        // Differs from new or cloned because we are NOT creating a new column
        // Flip the isDeleted flag for this colkey
        tableMods.push({ newVal: false, path: `attributes.columns[${newOrRestoredColKey}].isDeleted`, resId: table.id, resType: 'tables' })
        tableLookMods.push({newVal: 1000, path: `attributes.lookColumns[${newOrRestoredColKey}].hidden`, resId: tablelook.id, resType: 'tablelooks'  })
        isDeletedArr_postInsert[newOrRestoredColKey] = false
        // And insert newly visible col into newDerivedColOrder
        newDerivedColOrder.splice( indexOfSelectedColKey + 1, 0, newOrRestoredColKey )
    }

    // Error check newDerivedColOrder 
    if ( DEBUG ) { errorCheckColOrder( isDeletedArr_postInsert, newDerivedColOrder, colTitleArr_postInsert ) }
    tableLookMods.push({newVal: newDerivedColOrder, path: 'attributes.colOrder'})
    // If selectedCol is locked, new col is locked, adding a new column to the lockedTable
    if ( indexOfSelectedColKey < numLockedCols ) {
        tableLookMods.push({newVal: numLockedCols + 1, path: 'attributes.numLockedCols'})
    }   

    const thisAction = `insert ${mode} column`
    const actionGroup = `insertCol${newOrRestoredColKey}`
    reactDispatch( tableMods, thisAction, actionGroup )  
    reactDispatch( tableLookMods, thisAction, actionGroup )  
    reactDispatch( tableDataMods, thisAction, actionGroup )  
    // The animation 'exposeNewColHandler' is called AFTER the reactDispatch/re-render.
    // Hence the column actually exist (and is rendered) prior to beginning the animation.
    // The animation is all for show!
    window.requestAnimationFrame( () => 
        exposeNewColHandler( newOrRestoredColKey, postWidth_notGlobalScaled, thisAction, actionGroup, table.id ) 
    )
}



export const deleteColumn = ( colKey: number, shouldSetDeletionDate: boolean, 
                 shouldResetActiveStatsColKey: boolean, tCD: TableComputedData) => {
    const tableMods: LightweightMod[] = []
    const tablelookMods: LightweightMod[] = []
    const date = String ( new Date() )
    const {table, tablelook, derivedColOrder, derivedColAttributesArray } = tCD
    const {numLockedCols} = tCD.widthObj
    const colIndex = derivedColOrder.indexOf( colKey )
    const isLockedCol = colIndex < numLockedCols
    const newDerivedColOrder = derivedColOrder.slice()
    newDerivedColOrder.splice( colIndex, 1 )  // Remove colKey from newDerivedColOrder array.
    // Always error check newDerivedColOrder before pushing to resource.
    // Once it is bad in the resource, pain in the butt to repair!!
    const nextIsDeletedArr = derivedColAttributesArray.map( c => c.isDeleted )
    nextIsDeletedArr[colKey] = true
    const colTitleArr  = derivedColAttributesArray.map( c => c.colTitle )
    if (DEBUG) { errorCheckColOrder( nextIsDeletedArr, newDerivedColOrder, colTitleArr ) }

    tableMods.push({newVal: true, path: `attributes.columns[${colKey}].isDeleted`, resId: table.id, resType: 'tables'})
    tablelookMods.push({newVal: newDerivedColOrder, path: `attributes.colOrder`, resId: tablelook.id, resType: 'tablelooks'})
    if (isLockedCol) {
      tablelookMods.push({newVal: numLockedCols-1, path: `attributes.numLockedCols`, resId: tablelook.id, resType: 'tablelooks'})
    }
    if ( shouldSetDeletionDate ) {
      // True if this function was called from the 'delete column menu.'
      // False if this function was called from the 'restore column control.'

      // When the column restore control changes the isDeleted flag, we DO NOT reset the deletion date.
      // Nor do we close the Editor (the currently visible column restore control)

      // Why this works--  IFF a column appears in the column restore control, THEN isDeleted === true.
      // If column restore control does repeated restore/re-delete sequence,  then we want the original (current)
      // deletion date.  NOT a new date based on indecision while using the column restore tool.
      // And we don't want to close the editor on deletion. -- We don't need to because the deleted choice
      // will never be the current selection colHeader.

      // Hence, rules are: ONLY set a column's deletion date IF it is deleted using the deleteColumn menu item.
      //                   NEVER change the deletion date from inside the restore column control.
      //                   NEVER exit the Editor from inside the restore column control.
      tableMods.push({ newVal: date, path: `attributes.columns[${colKey}].deletedDate`, resId: table.id, resType: 'tables'})
      tablelookMods.push({ newVal:{name:'', colIndex:-1, rowIndex:-1}, 
                  path: 'attributes.minorState.selection', resId: tablelook.id,  resType: 'tablelooks'})
      tablelookMods.push({ newVal:false, path: 'attributes.minorState.isEditorOpen', resId: tablelook.id, resType: 'tablelooks'})
    }
    // Next animation is done using the 'actionHideCol.js' module.
    deleteColHandler( tableMods, tablelookMods, colKey, tCD, shouldResetActiveStatsColKey )   
}
