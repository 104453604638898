import type { History } from 'history'
import type {MouseEvent}  from 'react'
import type { Column, LightweightMod }  from '../types'
import type {ViewTable_UnderlyingTile}       from './getTableTile'                  
import type { TableComputedData }   from '../computedDataTable/getDefaultTableComputedData'
import type { SessionState}         from '../appCode/getDefaultSessionState'
import type { FpName}               from '../types'

import reactDispatch                from '../sharedComponents/reactDispatch'
import {xyClickToTableViewTile}     from './getTableTile'
import {isScryHyperlink_fromTableValue}   from '../sharedFunctions/isTypeHyperlink'
import {handlePinRow, 
        handleUnpinRow}             from './ActionPinRow'
import {hideColHandler, 
        unHideColHandler}           from './actionHideDeleteCol'
import {sortHandler}                from './ActionSort'
import constants                    from '../sharedComponents/constants'
import {sessionStateChangeDispatch} from '../sharedComponents/reactDispatch'
import { measureText }              from '../sharedFunctions/measureText'
import {closeFP}                    from '../floatingPaletteNew/FpParent' 
import {getTableFpNameFromColKey, 
        isTableCellEditor }   from './getTableTile' 

/*
    NOTE !!

    The 'clicked' area is identified by the intersection of one rowSlice and one colSlice.
    Many 'clicked' tiles can map to the same floating palette name.  (for example cell editors)

    ALSO, the floating palette name, including rowKey and colKey, does NOT uniquely identify the intersection!
    For example, a pinned table cell and scrollable data cell can have the same activeFp: name, rowKey, and colKey.

    In order to draw the cyan highlight, we need to know the intersection of the rowSlice and colSlice.
    In order to render the floating palette, we need to know the floating palette name, rowKey, and colKey.

    These functions determine both sets of information.
    
    In cases of no match, any click in an 'empty' (unmatched) area will close the floating palette.
*/

let mouseDownTarget : ViewTable_UnderlyingTile | null = null

export const handlerMouseDown = (e: MouseEvent, tCD: TableComputedData): void => {
    const rect = e.currentTarget.getBoundingClientRect()
    const x = e.clientX - rect.left  
    const y = e.clientY - rect.top  
    mouseDownTarget = xyClickToTableViewTile( x, y, tCD )
    //console.log( 'Table FocalPlane mouseDownEvent' )
}

export const handlerMouseUp = (e: MouseEvent, tCD: TableComputedData, sessionState: SessionState,
            hideToolTip:()=>void , history:History ) : void => {
    // MouseDownTarget is never set if user clicks on the 'col resize handler'
    // Because the resize handlers are blocking the onClick from the focal plane.
    // However, we can/will capture the subsequent mouseUp event, because it is unlikely to be blocked.
    // Hence, if mouseDownTarget is not set, then this mouseUp event is the 'end of the colResize operation'
    if ( !mouseDownTarget ) {  return }
    // At this point, we ask if the mouseDown and mouseUp events are in the same 'caseName' area. 
    // If they are, then we can proceed with the 'click' event.
    const rect = e.currentTarget.getBoundingClientRect()
    const x = e.clientX - rect.left  
    const y = e.clientY - rect.top  
    const mouseUpTarget = xyClickToTableViewTile( x, y, tCD )
    const {caseName, colKey, rowKey, tertiaryKey} = mouseUpTarget
    const {caseName:downName, colKey:downColKey, rowKey:downRowKey, tertiaryKey: downTertKey} = mouseDownTarget 
    const isFocusObjSame = (  downName === caseName && 
                              downColKey === colKey && 
                              downRowKey === rowKey &&
                              downTertKey === tertiaryKey )
    mouseDownTarget = null   // Clear the mouseDown object
    //console.log( 'Table FocalPlane mouseUpEvent - isMouseDownSet, isFocusObjSame:', isFocusObjSame )
    if ( isFocusObjSame && mouseUpTarget) {
        handleClickEvent(mouseUpTarget, tCD, sessionState, hideToolTip, history )
    }
    mouseDownTarget = null   // Clear the mouseDown object
}

const handleClickEvent = ( underlyingTarget: ViewTable_UnderlyingTile, tCD: TableComputedData, 
                            sessionState: SessionState , hideTooltip: ()=>void, history:History ) : void => {
    const {caseName, colKey, rowKey, rowIndex, colIndex} = underlyingTarget
    const {columns, isSortable} = tCD.table.attributes
    const {canEdit} = tCD
    const {activeStatsColKey, activeFp} = sessionState
    const {fpName, primaryKey, secondaryKey } = activeFp
    const {rowSortColIds, pinnedRowKeys} = tCD.tablelook.attributes
    const colDataType : string = (colKey >= 0) ? columns[colKey].colDataType : ''

    // Click events where we close the floating palette: 
    // 1) if the FP is open, then click on the same cell, close the FP
    // 2) On some control clicks, close and open table cell editors.
    // 3) On hiding the column currently selected by FP, close the FP
    // (also the close button in FP, but that is handled elsewhere)
    // Note that we MUST 'early exit' for some cases, else palette will just re-open.
    // And for others, we need to close the palette AND later trigger some clicked behavior
    if (fpName==='tableColHeader' && caseName==='tableColHeader' && colKey===primaryKey) { closeFP(); return }
    if (isTableCellEditor(fpName) && caseName===fpName && colKey===primaryKey && rowKey===secondaryKey) { closeFP(); return }    
    const actionsClosingFP = ['drag', 'sortAscending', 'sortDescending']
    if ( isTableCellEditor(fpName) && actionsClosingFP.includes(caseName) ) { closeFP() }
    if ( isTableCellEditor(fpName) && caseName==='hide' && colKey===primaryKey) { closeFP() }
    // 'drag' clicks are handled by ActionDnDCol component (sits on top of LayoutMain).
    // Within the ActionDnDCol module, onDragStart will ask if the Fp name corresponds to
    // the colHeader, pinnedCell, or dataCell.  If so, it will close the floating palette.
    // Haven't tried to make the cyanline and DnD columns work together, and at this time
    // I don't think it is necessary or desired.  Enough going on the DnD columns that
    // perhaps best to just close the floating palette in these cases.

    hideTooltip()
    //console.log( 'handleValidMouseDownUpSequence', caseName, rowIndex, colIndex )
    // Conversion from rowIndex to rowKey needs to account for sortedRowKeys (missing rows & order)
    //const selectionColKey = derivedColOrder[ selectionColIndex ]
    //const selectionRowKey = (selectionName === 'pinnedCell')
    // ? pinnedRowKeys[ selectionRowIndex ]
    //  : sortedRowKeys[ selectionRowIndex ]
    let mods:LightweightMod[], tertiaryKey:string, nextRowSortColIds:string[]
    let newFpName : FpName = 'none'
    let postAnimationDispatchFunc : ()=>void
    const noPrimKey = {newVal: -1, path: 'activeFp.primaryKey'}
    const noSecKey = {newVal: -1, path: 'activeFp.secondaryKey'}

    switch ( caseName ) {
      case 'hide':
          if ( colKey >= 0 && colKey === activeStatsColKey) {
            // We hide the statsBar BEFORE starting the animation
            mods = [{newVal:-1, path: 'activeStatsColKey'}]  // -1 means no stats col to display
            sessionStateChangeDispatch( mods, 'Hide Column' )
          }
          hideColHandler(tCD, colKey)
          break
      case 'unhide':
          // We expose the statsBar at the END of the animation (inside unHide handler)
          unHideColHandler( tCD, colIndex )
          break
      case 'sortAscending':
      case 'sortDescending':
          if ( !isSortable) { break }
          // NOTE: rowSortColIds (number dataType) may include either +0 or -0 (both 
          //       (sort ascending colKey 0; sort descending colKey0 respectively)
          //       The sorting algorithm can recognize the difference!  
          //       So the rowSortColIds is an array of strings, not numbers.
          // Delete any prior references to the new colKey. Two copies of same keyresults in 2nd key having no effect.
          nextRowSortColIds = rowSortColIds.filter( sortColKey => Math.abs(Number(sortColKey)) !== colKey )
          // Push the new colKey to the front (highest priorty) sort rule.
          nextRowSortColIds.unshift( (caseName === 'sortAscending') 
                ? '+' + String(colKey) 
                : '-' + String(colKey) )
          // Constrain the set of sort rules to some max length:
          while (nextRowSortColIds.length > constants.ROW_SORT_COL_IDS_ARR_MAX_LENGTH) { nextRowSortColIds.pop() }
          mods = [{ newVal: nextRowSortColIds, path : 'attributes.rowSortColIds' }]
          // Create a stateChange function to be called later (between shutterClose & shutterOpen animations)
          postAnimationDispatchFunc = () => {
            reactDispatch( mods, 'tablelooks', tCD.tablelook.id )
            sessionStateChangeDispatch( [{newVal:colKey, path: 'activeStatsColKey'}], 'sideEffect' )
          }
          sortHandler( postAnimationDispatchFunc )
          break
      case 'pinnedRowNumber':
          handleUnpinRow( rowIndex, pinnedRowKeys, tCD )
          break
      case 'dataRowNumber':
          handlePinRow  ( rowKey, pinnedRowKeys )
          break

//////////////////////////////////////////////////////////////////////////////
//  This group of cases are 'floating palette' editors or viewers.  
/////////////////////////////////////////////////////////////////////////////

      case 'tableRowFiltering':
          mods = [{ newVal: 'tableRowFiltering' ,  path: 'activeFp.fpName' }, noPrimKey, noSecKey ]   
          sessionStateChangeDispatch( mods, 'Open Publisher Editor' )
          break
      case 'tablePinnedCell' :
      case 'tableDataCell' :
          if (!canEdit && colDataType === 'hyperlink') {
            // NO Fp EDITOR for hyperlinks.  Just open the link.
            handleHyperlinkClick(history, columns, colKey, rowKey, tCD)
            break
          }
          newFpName = getTableFpNameFromColKey( colKey, tCD)
          tertiaryKey = (caseName === 'tablePinnedCell') ? 'pinnedCell' : 'dataCell'
          mods = [{ newVal: newFpName, path: 'activeFp.fpName' },
                  { newVal: colKey, path: 'activeFp.primaryKey' },
                  { newVal: rowKey, path: 'activeFp.secondaryKey' },
                  { newVal: tertiaryKey, path: 'activeFp.tertiaryKey' },
                  { newVal: colKey, path: 'activeStatsColKey'}]  
          sessionStateChangeDispatch( mods, `Open ${newFpName} Editor` )
          break  
      case 'tableColHeader' :
          mods = [{newVal: 'tableColHeader', path: 'activeFp.fpName'},
                  { newVal: colKey, path: 'activeFp.primaryKey' },
                    noSecKey,  
                  { newVal: colKey, path: 'activeStatsColKey'}] 
          sessionStateChangeDispatch( mods, `Open tableColHeader Editor` )
          break
      case 'tableMainTitle':
          mods = [{ newVal: 'tableMainTitle' ,  path: 'activeFp.fpName' }, noPrimKey, noSecKey ]    
          sessionStateChangeDispatch( mods, 'Open TableTitle Editor' )
          break
      case 'tablePublisherTitle':
          mods = [{ newVal: 'tablePublisherTitle' ,  path: 'activeFp.fpName' }, noPrimKey, noSecKey ]    
          sessionStateChangeDispatch( mods, 'Open Publisher Editor' )
          break
      case 'empty':  // All other clicks will close the floating palette  
          break
      default:
          closeFP()
          break
  }
}



const handleHyperlinkClick = (history :History , columns:Column[], colKey:number, rowKey:number, tCD:TableComputedData) => {
  if (colKey >= 0 && colKey < columns.length && rowKey >= 0 ) {
    if (columns[colKey].colDataType === 'hyperlink') {
      const {getTableValue} = tCD
      const {value} = getTableValue(colKey, rowKey, true)
      const link = isScryHyperlink_fromTableValue( value )
      // Early exit (ignore click) for illegal links
      if (link.errorMsg !== '' ) { return }
      if (link.isInternal) {
        history.push(link.canonicalUrl)
      } else {
        window.open(link.canonicalUrl, '_blank', 'rel=noopener noreferrer,menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes')
      }
    }
  }
}


const IS_TOOLTIPS_ENABLED = true
let lastToolTipsTimeStamp : number = 0

export const handlerMouseMove = (e: MouseEvent, tCD: TableComputedData, 
           displayTooltip : (toolTipStr: string, toolTipTargetHeight: number, toolTipTargetLeft: number,
                             toolTipTargetTop: number, toolTipTargetWidth: number, toolTipPlacement: string,
                            toolTipAddedExtension: number ) =>void ) : void => {
    //console.log( 'call to handleMouseMove' )
    if (!IS_TOOLTIPS_ENABLED) { return }
    const rect = e.currentTarget.getBoundingClientRect()
    const x = e.clientX - rect.left  
    const y = e.clientY - rect.top  
    const underlyingTarget : ViewTable_UnderlyingTile = xyClickToTableViewTile( x, y, tCD )
    const {caseName, cellHeight, cellLeft, cellTop, cellWidth, colKey, rowKey} = underlyingTarget
    const tipAreas = ['tableRowFiltering', 'drag', 'sortAscending', 'sortDescending', 
                      'hide', 'unhide', 'tablePinnedCell', 'tableDataCell']
    const isActive = tipAreas.includes(caseName)
    if ( !isActive ) { return }

    const {isSortable} = tCD.table.attributes
    const {styleObj, getFormattedTableData} = tCD
    // Case: Mouse exits activeArea and/or mouse movement outside acticeArea:
    if ( !isActive ) {
      lastToolTipsTimeStamp = 0
      //console.log('case0', toolTipsActiveAreas, this.lastToolTipsTimeStamp)
      return
    }
    // Case: Mouse moves into active area:
    else if ( lastToolTipsTimeStamp === 0 ) {
      lastToolTipsTimeStamp  = Date.now()
      //console.log('case1', toolTipsActiveAreas, this.lastToolTipsTimeStamp)
      return
    }
    // Case: Mouse movement is within active area:
    else {
      const deltaTime : number = Date.now() - lastToolTipsTimeStamp
      //console.log('case2', toolTipsActiveAreas, deltaTime)
      // 500ms is too long.  200 makes it feel snappier.
      // Case: Mouse movement within active area for less than hover time:
      if ( deltaTime < 200 ) return
    }
    // Case: Case: Mouse movement within active area for greater than hover time:
    //console.log( 'case3, hovered for > 1 sec', caseName )
    //console.log(`${caseName}: ${cellTop}, ${cellLeft} ${cellHeight} x ${cellWidth}`)
    let newToolTipStr = null
    let measureStrg = ''
    let placement = 'above'      // Place tooltip above or below the colControl
    let toolTipAddedExtension = 0   // Tooltip has a nominal pointer nub; 8px in vertical height
                                    // This param extends (or shrinks the nub)
                                    // Used to place tooltip 'above' the lower sort button but
                                    // then extend the num pointer to point to the lower sort button.
                                    // Added in order for all colControl tool tips to appear 'above'
                                    // the colControls, rather than laying on top of the header cells.
    switch ( caseName ) {
        case 'tablePinnedCell':
        case 'tableDataCell':
          measureStrg   = getFormattedTableData(colKey, rowKey, 'measureOnlyString' ).value;
          newToolTipStr = getFormattedTableData(colKey, rowKey, 'noHtml' ).value;
          if (newToolTipStr) {
            const stringWidth = measureText(measureStrg, String(styleObj.cellFontSize));
            // ONLY show the tooltip if the string is wider than the cell; aka partially visible
            if ((stringWidth ) < cellWidth) {
              newToolTipStr = '';
            }
          }
          placement = 'above';
          break
        case 'drag':
          newToolTipStr = 'Drag to reorder column'
          placement = 'above'
          break
        case 'tableRowFiltering':
          newToolTipStr = 'Filter table rows'
          placement = 'above'
          toolTipAddedExtension = 10
          break
        case 'hide':
          newToolTipStr = 'Hide column'
          placement = 'above'
          toolTipAddedExtension = cellHeight
          break
        case 'unhide':
          newToolTipStr = 'Show hidden column'
          placement = 'above'
          break
        case 'sortAscending':
          if ( !isSortable ) { break }
          newToolTipStr = 'Sort ascending (A-Z, 0-9)'
          placement = 'above'
          break
        case 'sortDescending':
          if ( !isSortable ) { break }
          newToolTipStr = 'Sort descending (Z-A, 9-0)'
          placement = 'above'
          toolTipAddedExtension = cellHeight
          break
        default:
          newToolTipStr = ''
    }
    //console.log( 'newToolTipStr', newToolTipStr )
    if (newToolTipStr) {
      displayTooltip(newToolTipStr, cellHeight, cellLeft, cellTop, cellWidth, placement, toolTipAddedExtension )
    }
}