import type { AnyAction, Dispatch } from '@reduxjs/toolkit'
import type { Plot } from '../types'
import type { RootState } from '../redux/store'
import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getThumbnail } from '../redux/thumbnailThunks'
import { SCRY_WHITE } from '../sharedComponents/constants'
import HorzTaperedDivider from '../sharedComponents/HorzTaperedDivider'
import { asyncDispatch } from '../sharedFunctions/utils'
import SVGwrapper2 from '../SVGs/SVGwrapper2'

const HEIGHT_SPACER_TOP = 4
const HEIGHT_SPACER_ABOVE_SVG = 2
const HEIGHT_SVG = 60
const HEIGHT_SPACER_BOTTOM = 5

type OwnProps = {
  plotid: string
  currentPlotid: string
  closePopup: () => void
}
type StateProps = {
  dataURL: string
  plot: Plot | null
}
type DispatchProps = {
  ensureThumbnail: (plotid: string) => void,
}
type Props = OwnProps & StateProps & DispatchProps

class PlotSummaryRender extends PureComponent<Props> {
  componentDidMount() {
    const { ensureThumbnail, plotid } = this.props
    ensureThumbnail(plotid)
  }

  componentDidUpdate(prevProps: Props, prevState: StateProps) {
    const { ensureThumbnail, plotid } = this.props
    ensureThumbnail(plotid)
  }

  render() {
    const { currentPlotid, dataURL, plotid, plot, closePopup } = this.props
    if (!plot) {
      return null
    }
    const shouldRenderSVG = (dataURL !== null) //always render the image
    const shouldRenderActivePlot = (currentPlotid === plotid)
    const shouldRenderEmptySpace = (currentPlotid !== plotid) && (dataURL === null)
    const background = shouldRenderActivePlot ? '#DDDDDD' : SCRY_WHITE
    //const background = SCRY_WHITE
    const { mainTitle } = plot.attributes

    return (
      <div className={'rc_PlotSummary'}
        onClick={closePopup}
        style={{
          position: 'relative',
          width: '100%',
          paddingTop: 0,
          textAlign: 'center',
          background,
        }}
      >
        <Link
          to={`/plot/${plotid}`}
          style={{
            color: 'black',
            textDecoration: 'none',
            height: '100%',
            width: '100%',
          }}
        >
          <div
            className={'VertSpacer'}
            style={{
              height: HEIGHT_SPACER_TOP,
              width: '100%'
            }}
          />

          <div
            style={{
              color: 'black',
              textDecoration: 'none',
              fontSize: 14,
              margin: '-1px 5px 5px 5px',
              background: 'unset'
            }}
          >
            {mainTitle}
          </div>

          <div
            className={'VertSpacer'}
            style={{
              height: HEIGHT_SPACER_ABOVE_SVG,
              width: '100%'
            }}
          />

          {shouldRenderSVG &&
            <SVGwrapper2>
              <img src={dataURL}
                alt=''
                style={{
                  maxHeight: HEIGHT_SVG,
                  maxWidth: '70%',
                  //background: shouldRenderActivePlot ? 'lightGrey' : 'unset'
                }}
              />
            </SVGwrapper2>
          }

          {shouldRenderEmptySpace &&
            <div
              style={{
                height: 80,
                background
              }}
            />
          }

          <div
            className={'VertSpacer'}
            style={{
              height: HEIGHT_SPACER_BOTTOM,
              width: '100%'
            }}
          />

        </Link>

        <HorzTaperedDivider
          background={SCRY_WHITE}
          heightAtEdgeOfTaper={6}
          heightAtCenterOfTaper={2}
        />

      </div>
    )
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const { plotid } = ownProps
  const dataURL = state.thumbnail[plotid] ?? null
  const plot = state.api.resources.plots[plotid] as Plot ?? null
  if (dataURL === null) {
    return {
      dataURL,
      plot
    }
  }

  return {
    dataURL,
    plot,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps => (
  {
    ensureThumbnail: (plotid: string): void => {
      asyncDispatch(dispatch, getThumbnail(plotid))
    }
  }
)

const PlotSummary = connect(mapStateToProps, mapDispatchToProps)(PlotSummaryRender)

export default PlotSummary
