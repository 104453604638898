import { AnyAction, Middleware, PayloadAction } from '@reduxjs/toolkit'
import type { MiddlewareFunc, StoreType } from './store'
import { createTooltipDisplayAction, createTooltipVisibilityAction } from './tooltipReducer'
import type { TooltipState } from './tooltipReducer'

var showTooltipTimeoutID: string | number | NodeJS.Timeout | undefined = undefined
var clearTooltipTimeoutID: string | number | NodeJS.Timeout | undefined = undefined

const tooltipDisplayHandler = (store: StoreType, action: PayloadAction<TooltipState>): void => {
  //const {toolTipStr} = action.payload
  //const state = store.getState()
  
  //display the same toolTipStr even if it hasn't changed
  //this handles tooltips for a large area, like a table or plot.
  if (showTooltipTimeoutID) {
    clearTimeout(showTooltipTimeoutID)
  }
  showTooltipTimeoutID = setTimeout(() => {
    if (clearTooltipTimeoutID) {
      clearTimeout(clearTooltipTimeoutID)
    }
    clearTooltipTimeoutID = setTimeout(() => {
      store.dispatch(createTooltipVisibilityAction(false))
    }, 2000)
    store.dispatch(createTooltipVisibilityAction(true))
  }, 250)

}

const tooltipMiddleware: Middleware<(store: StoreType) => (next: MiddlewareFunc) => MiddlewareFunc> =
(store: StoreType) =>
  (next: MiddlewareFunc) => (
  (action: AnyAction): AnyAction => {
    if (createTooltipDisplayAction.match(action)) {
      tooltipDisplayHandler(store, action)
    }
    return next(action)
  }
)

export default tooltipMiddleware
