import { Component } from 'react'
import type { CSSProperties, ReactNode } from 'react'
import { SCRY_WHITE } from '../sharedComponents/constants'

class Main extends Component<{
  className?: string,
  children: ReactNode,
  style?: CSSProperties,
}> {
  render() {
    const {children, className, style} = this.props
    // The position: 'relative' line below is to allow Main to serve
    // as a container for absolutely positioned elements.
    const defaultStyle: CSSProperties = {
      backgroundColor: SCRY_WHITE, 
      flexGrow: 1,
      overflow: 'auto',
      position: 'relative',
      width: '100%',
    }
    return (
      <main
        className={className}
        style={{...defaultStyle, ...style}}
      >
        {children}
      </main>
    )
  }
}

export default Main
