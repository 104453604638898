import { Component } from 'react'
import type { ReactNode } from 'react'
import Progress from './Progress'

type OwnProps = {
  children: ReactNode
  loadMore: (page: number) => void
}

type DefaultProps = {
  hasMore: boolean
  threshold: number
}

type Props = OwnProps & DefaultProps

export default class InfiniteScroll extends Component<Props> {
  static defaultProps: DefaultProps = {
    hasMore: false,
    threshold: 250,
  }

  ignoreEvents = false
  pageLoaded = 0
  scrollComponent: HTMLDivElement | null = null

  componentDidMount() {
    if (this.scrollComponent) {
      const scrollEl = this.scrollComponent.parentNode
      if (scrollEl) {
        const {hasMore} = this.props
        scrollEl.addEventListener('scroll', this.scrollListener)
        scrollEl.addEventListener('resize', this.scrollListener)
        this.ignoreEvents = !hasMore
      }
    }
  }

  componentDidUpdate() {
    const {hasMore} = this.props

    this.ignoreEvents = !hasMore
    this.scrollListener()
  }

  componentWillUnmount() {
    if (this.scrollComponent) {
      const scrollEl = this.scrollComponent.parentNode
      if (scrollEl) {
        scrollEl.removeEventListener('scroll', this.scrollListener)
        scrollEl.removeEventListener('resize', this.scrollListener)
      }
    }
  }

  getParent = (el: Element): Element | null => {
    const node: ParentNode | null = el.parentNode
    return node instanceof Element ? node as Element : null
  }

  scrollListener = (): void => {
    if (this.ignoreEvents) {
      return
    }
    const el = this.scrollComponent
    if (el) {
      const scrollEl: Element | null = this.getParent(el)
      if (scrollEl) {
        const {loadMore, threshold} = this.props
        const offset = el.scrollHeight - scrollEl.scrollTop - scrollEl.clientHeight
        if (offset < threshold) {
          this.ignoreEvents = true
          // Call loadMore after setting ignoreEvents to allow for non-async loadMore functions
          this.pageLoaded += 1
          loadMore(this.pageLoaded)
        }
      }
    }
  }

  initInfiniteScroll = (element: HTMLDivElement | null): void => {
    this.scrollComponent = element
  }

  render() {
    const {children, hasMore} = this.props

    return (
      <div className={'rc_InfiniteScroll'}
        ref={this.initInfiniteScroll}
      >
        {children}
        {hasMore && <Progress/>}
      </div>
    )
  }
}
