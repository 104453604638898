import {list} from 'radash'
import {PureComponent} from 'react'
import {TABLE_CONTROLS_OPACITY} from '../sharedComponents/constants'
import {numberFormat, getCommasOnlyFormattingObj} from '../sharedFunctions/numberFormat'


////////////////////////////////////////////////////////////////////////////////////
//   ColumnLineNumsHead
////////////////////////////////////////////////////////////////////////////////////
type PropsHead = {
  lineNumColWidth: number,
  pinnedRowKeys: Array<number>,
  isRowNumberVisible: Boolean,
  sortedRowKeys: Array<number>,
  pinnedTopRelative: number,
  pinnedHeight: number,
  rowHeight: number,
  rowNumbersPaddingRight: number,
  fontSize: number,
  paddingTop: number,
}
export class ColumnLineNumsHead extends PureComponent<PropsHead> {
  render() {
    const { lineNumColWidth, pinnedRowKeys, pinnedTopRelative,
      pinnedHeight, isRowNumberVisible, sortedRowKeys,
      rowNumbersPaddingRight, paddingTop, fontSize,
      rowHeight, } = this.props
    if (!isRowNumberVisible ) return null
    const commasOnlyFormattingObj = getCommasOnlyFormattingObj( )
    return (

      <div className={'rc_ColumnLineNumsHead'}
      style = {{ position:'absolute', top:pinnedTopRelative,
      height: pinnedHeight, width: lineNumColWidth,
      textAlign : 'right', paddingRight: rowNumbersPaddingRight,
      paddingTop, fontSize, opacity : TABLE_CONTROLS_OPACITY }}>

            {pinnedRowKeys.map( rowKey=>{
            var lineNumber = sortedRowKeys.indexOf(rowKey) + 1
            // Pinned line numbers correspond to equivalent row in the table and its line number.
            // A pinned lineNumber may not exist if the pinned row has been filtered and does not appear in the table.
            // In case of no pinned lineNumber, we use ''.
            var rowNumText = (lineNumber>0) ? numberFormat(String(lineNumber), commasOnlyFormattingObj, 'noHtml' ) : ''
            return (
                <div className={'LineNumberCell'}
                key={rowKey} style={{ position: 'relative', height: rowHeight}}>

                    {rowNumText}

                </div>
            )})}

      </div>
    )
  }
}


////////////////////////////////////////////////////////////////////////////////////
//   ColumnLineNumsData
////////////////////////////////////////////////////////////////////////////////////
type PropsData = {
  firstRowIndex: number,
  rowsPerGroup: number,
  isRowNumberVisible: boolean,
  rowNumbersPaddingRight: number,
  fontSize: number,
  paddingTop: number,
  rowHeight: number,
}
export class ColumnLineNumsData extends PureComponent<PropsData> {
  render() {
    const { rowHeight, firstRowIndex, rowsPerGroup, fontSize,
      rowNumbersPaddingRight, paddingTop, isRowNumberVisible } = this.props
    if (!isRowNumberVisible ) return null
    const commasOnlyFormattingObj = getCommasOnlyFormattingObj()

    return (
        <div className={'rc_ColumnLineNumsData'}
        style={{ position: 'absolute', width: '100%',
        textAlign : 'right', paddingRight: rowNumbersPaddingRight,
        paddingTop, fontSize, opacity : TABLE_CONTROLS_OPACITY }}>

            { list(0,rowsPerGroup-1 ).map ( i=>{
            var lineNumber = firstRowIndex+i
            var rowNumText = numberFormat ( String(lineNumber+1), commasOnlyFormattingObj, 'noHtml' )
            return (
                <div className={'LineNumberCell'}
                key={lineNumber}
                style={{ position: 'relative', height: rowHeight}}>
                  {rowNumText}
                </div>
            )})}

        </div>
    )
  }
}
