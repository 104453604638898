const joi = require('joi')

const Mod = {
  newVal: joi.any(),
  oldVal: joi.any(),
  op: joi.string().allow('set', 'add', 'del'),
  path: joi.string(),
  resId: joi.string(),
  resType: joi.string(),
}

const Change = {
  mods: joi.array().items(joi.object().keys(Mod)),
  title: joi.string(),
  trigger: joi.string(),
  actionGroup: joi.string().allow(''),
}

const changeAttributes = {
  changes: joi.array().items(joi.object().keys(Change))
    .description('Array of changes.'),

  // server side managed data (read only to clients)
  createdDate: joi.date().iso().allow(null).meta('readonly')
    .description('Read only date change was applied.'),
}

module.exports = changeAttributes
