export type TapListener = {
  remove: () => void
}

function createTapListener(el: HTMLElement, callback: (event: UIEvent) => void, options?: boolean | AddEventListenerOptions): TapListener {
    var startX = 0
    var startY = 0
    var touchStarted = false
    var touchMoved = false
    // Assume that if a touchstart event initiates, the user is
    // using touch and click events should be ignored.
    // If this isn't done, touch-clicks will fire the callback
    // twice: once on touchend, once on the subsequent "click".
    var usingTouch = false
  
    el.addEventListener('click', handleClick, options)
    el.addEventListener('touchstart', handleTouchstart, options)
  
    function handleClick(e: MouseEvent) {
      if (usingTouch) return
      callback(e)
    }
  
    function handleTouchstart(e: TouchEvent) {
      usingTouch = true
  
      if (touchStarted) return
      touchStarted = true
  
      el.addEventListener('touchmove', handleTouchmove, options)
      el.addEventListener('touchend', handleTouchend, options)
      el.addEventListener('touchcancel', handleTouchcancel, options)
  
      touchMoved = false
      startX = e.touches[0].clientX
      startY = e.touches[0].clientY
    }
  
    function handleTouchmove(e: TouchEvent) {
      if (touchMoved) return
  
      if (
        Math.abs(e.touches[0].clientX - startX) <= 10
        && Math.abs(e.touches[0].clientY - startY) <= 10
      ) return
  
      touchMoved = true
    }
  
    function handleTouchend(e: TouchEvent) {
      touchStarted = false
      removeSecondaryTouchListeners()
      if (!touchMoved) {
        callback(e)
      }
    }
  
    function handleTouchcancel() {
      touchStarted = false
      touchMoved = false
      startX = 0
      startY = 0
    }
  
    function removeSecondaryTouchListeners() {
      el.removeEventListener('touchmove', handleTouchmove, options)
      el.removeEventListener('touchend', handleTouchend, options)
      el.removeEventListener('touchcancel', handleTouchcancel, options)
    }
  
    function removeTapListener() {
      el.removeEventListener('click', handleClick, options)
      el.removeEventListener('touchstart', handleTouchstart, options)
      removeSecondaryTouchListeners()
    }
  
    return {
      remove: removeTapListener,
    }
  }

  export default createTapListener