import React from 'react';
import constants from '../sharedComponents/constants';

const textContent = {
  intro: 'DataTables provides a standard, easy to use format for publishing, searching, and exploring data.',
  signedInUsers: 'Signed-in users can create tables and plots visible only to themselves. And later may choose to publish one or more of their tables as a service to the general public.',
  privateGroupAccounts: 'Private group accounts (paid accounts) can publish tables with access limited to the specified group.',
  contactInfo: [
    'DataTables.com is registered to and managed by:',
    'Scry Corporation',
    '2180 SW Burbank Place',
    'Portland OR, 97225-4919'
  ]
};

const WhoIsDataTables: React.FC = () => {
  return (
    <div
      className={'rc_WhoIsDataTables'}
      style={{
        // Define the position and size of available rendering window
        position: 'absolute',
        left: constants.NAV_COLUMN_WIDTH,
        top: constants.TOP_MENUBAR_HEIGHT,
        paddingLeft: '40px',
        paddingRight: '40px',
        marginTop: '80px',
        fontFamily: constants.FONT_FAMILY_LOGO_FORMAT,
        fontSize: 20,
        maxWidth: 700,
      }}
    >
      <div style={{ marginTop: 0 }}>
        {textContent.intro} <br />
      </div>

      <div style={{ marginTop: 20 }}>
        {textContent.signedInUsers} <br />
      </div>

      <div style={{ marginTop: 20 }}>
        {textContent.privateGroupAccounts} <br />
      </div>

      <div style={{ marginTop: 20, marginLeft: 20 }}>
        {textContent.contactInfo.map((line, index) => (
          <React.Fragment key={index}>
            {line} <br />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default WhoIsDataTables;