import invariant from 'invariant'

const DEBUG = (process.env.NODE_ENV === 'development') && false

// For our usage, read this global as 'animationInProgress'.
export var animationInProgress : string = ''

export const wasSuspendPointerEventsSuccessful = ( animationName: string ): boolean => {
  if ( DEBUG ) { console.log(`Call to "wasSuspendPointerEventsSuccessful()" for animation:`, animationName) }
  //if ( animationInProgress ) { return true }
  // Are two different animations attempting to run in parallel ?
  if ( animationInProgress !== '' ) {
    if ( DEBUG ) {
      invariant ( false,
      `Animation "${animationName}" attempting to suspend pointer events ` +
      `but prior animation "${animationInProgress}" was not finished` )
    }
    return false
  }
  // No problem path:
  animationInProgress = animationName
  const bodys = document.getElementsByTagName('body')
  bodys[0].style['pointerEvents'] = 'none'
  return true
}

export const restorePointerEvents = ( animationName: string ): void => {
  if ( DEBUG ) {
    console.log(`restorePointerEvents for animation:`, animationName)
  }
  if ( animationName !== animationInProgress && DEBUG ) {
    invariant ( false,
    `Attempting to restore pointer events, but animation blocking events: ` +
    `"${animationInProgress}" does not match animation restoring events: ` +
    `"${animationName}"` )
  }
  // No problem path:
  animationInProgress = ''
  const bodys = document.getElementsByTagName('body')
  bodys[0].style['pointerEvents'] = 'auto'
}
