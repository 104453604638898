import type {FpLayout}    from '../appCode/getSbFpConfigMappings'
import type {ActiveFp}    from '../types'

import {useContext}       from 'react'
import {TableComputedDataContext}   from '../viewTableTriple/TtGetComputedData'


// A simple test palette that exercises most (all?) degrees of freedom.
export const useGetFpTest : ( activeFp: ActiveFp )=> FpLayout =  (activeFp)=>{ 
    const tCD = useContext( TableComputedDataContext )
    const {canEdit} = tCD
    const tab0 = {  
        tabIndex: 0,                           
        tabStrg1: 'Hello', 
        tabStrg2 : '', 
        childWidth: 200,  
        isErroneous: false,
    }
    const child0 = {        
        childWidth: 200,  
        childHeight: 500,  
        RenderedChild: ( ) => {     
            return (
            <div className={'rc_Tab0Component'} style={{ width:'100%', height:'100%' }} >
                <div style={{ width:'100%', height:'100%', padding: 10 }}> 
                    Tab0, {activeFp.fpName} colKey:{activeFp.primaryKey} rowKey:{activeFp.secondaryKey}
                </div>
            </div>
            )} 
        }
    const tab1 = { 
        tabIndex: 1,                               
        tabStrg1: 'Hi', 
        tabStrg2 : 'Mom', 
        isErroneous: false,
    } 
    const child1 = {
        childWidth: 300,  
        childHeight: 400, 
        isErroneous: false,
        RenderedChild: ( ) => {     
            return (
            <div className={'rc_Tab0Component'} style={{ width:'100%', height:'100%' }} >
                <div style={{ width:'100%', height:'100%', padding: 10 }}> 
                    Tab1, {activeFp.fpName} colKey:{activeFp.primaryKey} rowKey:{activeFp.secondaryKey}
                </div>
            </div>
            )}
    }   
    const tab2 = {  
        tabIndex: 2,                              
        tabStrg1: 'Hey! So', 
        tabStrg2 : '', 
        isErroneous: true,
    }
    const child2 = {
        childWidth: 400, 
        childHeight: 300, 
        RenderedChild: ( ) => {     
            return (
            <div className={'rc_Tab0Component'} style={{ width:'100%', height:'100%' }} >
                <div style={{ width:'100%', height:'100%', padding: 10 }}> 
                    Tab2, {activeFp.fpName} colKey:{activeFp.primaryKey} rowKey:{activeFp.secondaryKey}
                </div>
            </div>
            )} 
    }
    const tab3 = {  
        tabIndex: 3,                         
        tabStrg1: 'Ya', 
        tabStrg2 : 'Think?', 
        isErroneous: true,
    }
    const child3 = {
        childWidth: 500, 
        childHeight: 200,
        RenderedChild: ( ) => {     
            return (
            <div className={'rc_Tab0Component'} style={{ width:'100%', height:'100%' }} >
                <div style={{ width:'100%', height:'100%', padding: 10 }}> 
                    Tab3, {activeFp.fpName}  colKey:{activeFp.primaryKey}  rowKey:{activeFp.secondaryKey}
                </div>
            </div>
            )}
    }
    if ( canEdit ) {
        return { 
            activeFp,     // To use this test palette, assign this function to the  'tableMainTitle' key in getFpLayout_ByFpName
            titleBarStrg: 'Test Palette', 
            isDraggable: true, 
            isResizableX: true,
            isResizableY: true,
            minResizeX: 300,
            minResizeY: 200,
            maxResizeX: 800,
            maxResizeY: 600,
            tabInfoArr: [tab0, tab1, tab2, tab3], 
            childInfoArr: [child0, child1, child2, child3],
            getSelectedCellGeometry: ()=> { return {left:0, top:0, width:100, height:100, isCellVisible:true} }
        }
    } else {  // 
        const viewOnlyTabs = [tab0, tab2]  
        return { 
            activeFp,     // To use this test palette, assign this function to the  'tableMainTitle' key in getFpLayout_ByFpName
            titleBarStrg: 'Test Palette', 
            isDraggable: true, 
            isResizableX: false,
            isResizableY: false,
            minResizeX: 300,
            minResizeY: 200,
            maxResizeX: 800,
            maxResizeY: 600,
            tabInfoArr: viewOnlyTabs,
            childInfoArr: [child0, child2],
            getSelectedCellGeometry: ()=> { return {left:0, top:0, width:100, height:100, isCellVisible:true} }
        }
    }
}