import { PureComponent } from 'react'
import constants from '../sharedComponents/constants'
import SVGwrapper2 from '../SVGs/SVGwrapper2'

type Props = {
  mode: 'cellEditor' | 'descriptionEditor',
  height: number,
  width: number,
  top: number,
  right: number,
  handleClick : ()=> void, 
  //parentBorderColor: string,
}

export default class HyperlinkButton extends PureComponent<Props> {

  static defaultProps = {
    parentBorderColor : constants.COLHEADER_INPUT_BORDER_COLOR
  }


  render( ) {
    const {handleClick, height, width, mode, top, right} = this.props

    // Assumption:  mode is 'cellEditor'
    var label = 'Test'
    if (mode === 'descriptionEditor' ) {
      label = 'Selection \u279C Create Link'
    }

    return (
        <button className={'rc_HyperlinkButton'}
          onClick={ handleClick }
          style={{
            fontSize: 14,
            position: 'absolute', top, right,
            height, width,
            ...constants.STYLE_EDITOR_RAISED_BUTTON
          }}>

              <div style={{
                display: 'inline-block',
                verticalAlign: 'top',
              }}>
                {label}
              </div>

              <div className={'SVG_container'}
              style={{height:13, width:15,
                marginLeft: 5,
                display:'inline-block',
              }} >
                  <SVGwrapper2>
                    <svg
                      className='inlinesvg'
                      version='1.1'
                      viewBox='0 0 1024 1024'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g fill={constants.SCRY_DARK_BLUE}>
                      <path
                         d="M 944,464 752,656 c -106,106 -278,106 -384,0 -17,-17 -30,-35 -41,-55 l 89,-89 c 4,-4 9,-7 14,-10 6,21 17,41 34,58 53,53 139,53 192,0 L 848,368 c 53,-53 53,-139 0,-192 -53,-53 -139,-53 -192,0 l -68,68 C 532,222 473,217 414,225 L 560,80 c 106,-106 278,-106 384,0 106,106 106,278 0,384 z m -508,316 -68,68 c -53,53 -139,53 -192,0 -53,-53 -53,-139 0,-192 L 368,464 c 53,-53 139,-53 192,0 17,17 27,36 34,58 5,-3 10,-5 14,-10 l 89,-89 c -11,-19 -25,-38 -41,-55 -106,-106 -278,-106 -384,0 L 80,560 c -106,106 -106,278 0,384 106,106 278,106 384,0 L 610,799 c -58,9 -118,3 -173,-19 z" />
                      </g>
                    </svg>
                  </SVGwrapper2>
              </div>
      </button>
    )
  }

}
