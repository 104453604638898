const animals = [
  'aardvark',
  'akbash',
  'albatross',
  'alligator',
  'alpaca',
  'amphibian',
  'anaconda',
  'angelfish',
  'ant',
  'anteater',
  'antelope',
  'antlion',
  'ape',
  'aphid',
  'armadillo',
  'asp',
  'avocet',
  'axolotl',
  'baboon',
  'badger',
  'bandicoot',
  'barb',
  'barnacle',
  'barracuda',
  'basilisk',
  'bass',
  'bat',
  'beagle',
  'bear',
  'beaver',
  'bedbug',
  'bee',
  'beetle',
  'bichon',
  'binturong',
  'bird',
  'birman',
  'bison',
  'blackbird',
  'bloodhound',
  'boa',
  'boar',
  'bobcat',
  'bonobo',
  'booby',
  'bovid',
  'boxer',
  'budgerigar',
  'buffalo',
  'bug',
  'bull',
  'bulldog',
  'bullfrog',
  'bumblebee',
  'butterfly',
  'buzzard',
  'caiman',
  'camel',
  'capybara',
  'caracal',
  'cardinal',
  'caribou',
  'carp',
  'cassowary',
  'cat',
  'caterpillar',
  'catfish',
  'catshark',
  'centipede',
  'cephalopod',
  'chameleon',
  'chamois',
  'cheetah',
  'chickadee',
  'chicken',
  'chihuahua',
  'chimpanzee',
  'chinchilla',
  'chinook',
  'chipmunk',
  'cicada',
  'cichlid',
  'civet',
  'clam',
  'clownfish',
  'coati',
  'cobra',
  'cockroach',
  'cod',
  'collie',
  'condor',
  'constrictor',
  'coonhound',
  'coral',
  'corgi',
  'cougar',
  'cow',
  'coyote',
  'coypu',
  'crab',
  'crane',
  'crawdad',
  'crayfish',
  'cricket',
  'crocodile',
  'crow',
  'cuckoo',
  'cuscus',
  'cuttlefish',
  'dachshund',
  'dalmatian',
  'damselfly',
  'deer',
  'dhole',
  'dingo',
  'dinosaur',
  'doberman',
  'dodo',
  'dog',
  'dolphin',
  'donkey',
  'dormouse',
  'dove',
  'dragon',
  'dragonfly',
  'drever',
  'duck',
  'dugong',
  'dunker',
  'eagle',
  'earthworm',
  'earwig',
  'echidna',
  'eel',
  'egret',
  'elephant',
  'elk',
  'emu',
  'ermine',
  'falcon',
  'fennec',
  'ferret',
  'finch',
  'firefly',
  'fish',
  'flamingo',
  'flea',
  'flounder',
  'fly',
  'flyingfish',
  'fowl',
  'fox',
  'foxhound',
  'frigatebird',
  'frog',
  'gar',
  'gazelle',
  'gecko',
  'gerbil',
  'gharial',
  'gibbon',
  'giraffe',
  'goat',
  'goldfish',
  'goose',
  'gopher',
  'gorilla',
  'grasshopper',
  'grebe',
  'greyhound',
  'grizzly',
  'grouse',
  'guan',
  'guanaco',
  'guineafowl',
  'gull',
  'guppy',
  'haddock',
  'halibut',
  'hammerhead',
  'hamster',
  'hare',
  'harrier',
  'havanese',
  'hawk',
  'hedgehog',
  'heron',
  'herring',
  'hippo',
  'honeybee',
  'hookworm',
  'hornet',
  'horse',
  'hound',
  'hoverfly',
  'hummingbird',
  'husky',
  'hyena',
  'hyrax',
  'ibis',
  'iguana',
  'impala',
  'indri',
  'insect',
  'jackal',
  'jaguar',
  'jay',
  'jellyfish',
  'kakapo',
  'kangaroo',
  'kingfisher',
  'kite',
  'kiwi',
  'koala',
  'koi',
  'krill',
  'kudu',
  'ladybird',
  'ladybug',
  'lamprey',
  'lapwing',
  'lark',
  'leech',
  'lemming',
  'lemur',
  'leopard',
  'limpet',
  'lion',
  'lionfish',
  'lizard',
  'llama',
  'lobster',
  'locust',
  'loon',
  'loris',
  'louse',
  'lungfish',
  'lynx',
  'macaque',
  'macaw',
  'mackerel',
  'magpie',
  'malamute',
  'mallard',
  'mammal',
  'manatee',
  'mandrill',
  'markhor',
  'marlin',
  'marmoset',
  'marmot',
  'marsupial',
  'marten',
  'mastiff',
  'mastodon',
  'mayfly',
  'meadowlark',
  'meerkat',
  'millipede',
  'mink',
  'minnow',
  'mite',
  'mockingbird',
  'mole',
  'mollusk',
  'mongoose',
  'mongrel',
  'monkey',
  'moorhen',
  'moose',
  'mosquito',
  'moth',
  'mouse',
  'mule',
  'muskox',
  'narwhal',
  'needlefish',
  'newt',
  'nighthawk',
  'nightingale',
  'numbat',
  'nutria',
  'ocelot',
  'octopus',
  'okapi',
  'olingo',
  'opossum',
  'orangutan',
  'orca',
  'oribi',
  'oriole',
  'ostrich',
  'otter',
  'owl',
  'ox',
  'oyster',
  'pademelon',
  'panda',
  'panther',
  'parakeet',
  'parrot',
  'parrotfish',
  'partridge',
  'peacock',
  'peafowl',
  'peccary',
  'pekingese',
  'pelican',
  'penguin',
  'perch',
  'pheasant',
  'pig',
  'pigeon',
  'pika',
  'pike',
  'pinscher',
  'piranha',
  'pitbull',
  'platypus',
  'pony',
  'poodle',
  'porcupine',
  'porpoise',
  'possum',
  'prawn',
  'primate',
  'puffin',
  'pug',
  'puma',
  'python',
  'quail',
  'quetzal',
  'rabbit',
  'raccoon',
  'rat',
  'rattlesnake',
  'raven',
  'ray',
  'reindeer',
  'reptile',
  'retriever',
  'rhinoceros',
  'roadrunner',
  'robin',
  'rodent',
  'rook',
  'rooster',
  'rottweiler',
  'roundworm',
  'sailfish',
  'salamander',
  'salmon',
  'sawfish',
  'scallop',
  'schnauzer',
  'scorpion',
  'seahorse',
  'seal',
  'serval',
  'shark',
  'sheep',
  'sheepdog',
  'shrew',
  'shrewmouse',
  'shrimp',
  'silkworm',
  'silverfish',
  'skink',
  'skunk',
  'sloth',
  'slug',
  'smelt',
  'snail',
  'snake',
  'snipe',
  'sole',
  'spaniel',
  'sparrow',
  'spider',
  'spitz',
  'sponge',
  'spoonbill',
  'squid',
  'squirrel',
  'starfish',
  'steelhead',
  'stingray',
  'stoat',
  'stork',
  'sturgeon',
  'swallow',
  'swan',
  'swift',
  'swordfish',
  'swordtail',
  'tahr',
  'takin',
  'tamarin',
  'tapir',
  'tarantula',
  'tarsier',
  'termite',
  'tern',
  'terrier',
  'tetra',
  'thrush',
  'tick',
  'tiger',
  'titi',
  'toad',
  'tortoise',
  'toucan',
  'trout',
  'tuatara',
  'tuna',
  'turkey',
  'turtle',
  'uakari',
  'uguisu',
  'unicorn',
  'urial',
  'vicuna',
  'viper',
  'vixen',
  'vole',
  'vulture',
  'wallaby',
  'walrus',
  'warbler',
  'warthog',
  'wasp',
  'waterbuck',
  'weasel',
  'whale',
  'whippet',
  'whitefish',
  'wildcat',
  'wildebeest',
  'wolf',
  'wolfhound',
  'wolverine',
  'wombat',
  'woodchuck',
  'woodlouse',
  'woodpecker',
  'worm',
  'wrasse',
  'wren',
  'yak',
  'zebra',
  'zebu',
]

module.exports = animals
