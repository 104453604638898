import React from 'react'
import { type AnyAction, type Dispatch } from '@reduxjs/toolkit'
import type { History } from 'history'
import { isEqual } from 'radash'
import type { ReactElement, ReactNode } from 'react'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import type { RouteComponentProps } from 'react-router-dom'
import ensure from '../redux/ensure'
import ErrorBoundary from '../viewApp/ErrorBoundary'
import Home from '../viewHome/Home'
import { paramsToListId } from '../jsonapi'
import { ResourceMap } from '../jsonapi/types'
import type { PlotXyComputedData } from '../computedDataPlotXY/xy_plotTypes'
import { isValidUuid } from '../sharedFunctions/isValidUuid'
import { NAV_COLUMN_WIDTH, SEARCH_TABLE_ID,
         SEARCH_TABLEDATA_ID, TOP_MENUBAR_HEIGHT,
         } from '../sharedComponents/constants'
import { wasSuspendPointerEventsSuccessful, restorePointerEvents } from '../sharedFunctions/pointerEvents'
import Progress from '../sharedComponents/Progress'
import { initializeDispatch, reactMinorDispatch } from '../sharedComponents/reactDispatch'
import type { RootState } from '../redux/store'
import type { TableComputedData } from '../computedDataTable/getDefaultTableComputedData'
import TtGetComputedData from '../viewTableTriple/TtGetComputedData'
import { asyncDispatch} from '../sharedFunctions/utils'
import type { Table, Tabledata, Tablelook, Plot, LightweightMod, ScryUIView } from '../types'
import WhoIsDataTables from '../viewHome/WhoIsDataTables'
import CreatePlot from '../fp_nav_menu_old/CreatePlot'
import CreateTable from '../fp_nav_menu_old/CreateTable'
import DeletePlot, { DeletePlotFunction, RemoveThumbnailFunction } from '../fp_nav_menu_old/DeletePlot'
import DownloadPlot from '../fp_nav_menu_old/DownloadPlot'
import DownloadTable from '../fp_nav_menu_old/DownloadTable'
import EditModeSelector from '../viewMenuBar/EditModeSelector'   // The menuBar pushbutton option f
import { PLOTS_SORT, plotsForTableListId, usersTablelookListId } from '../redux/ensureTableResourcesThunk'
import NavColumn from '../viewNavColumn/NavColumn'
// import type { PopupName } from '../viewNavColumn/NavColumn'
import NavTopButton from '../viewNavColumn/NavHomeButton'
import { ensurePlotResourcesThunk } from '../redux/plotThunks'
import TopMenuBar from '../viewMenuBar/TopMenuBar'
import UndoRedoButtons from '../viewMenuBar/UndoRedoButtons'
import {sessionStateChangeDispatch}   from '../sharedComponents/reactDispatch'
import { updateSession,} from '../redux/sessionReducer'
import { GetStateFunc } from '../types'
import { SessionState } from '../appCode/getDefaultSessionState'
import TtGetComputedDataPlotXy from '../viewTableTriple/TtGetComputedDataPlotXy'

import {getTableComputedData} from '../appCode/getMemoizedComputedData'


const DEBUG = false
const EMPTY_TABLE_OWNER_ARRAY: string[] = []
const EMPTY_USER_PLOT_ARRAY: string[] = []
const EMPTY_ALL_PLOTS_OBJECT = {}

export type PopupKey = 'createPlot' | 'createTable' | 'deletePlot' | 'downloadPlot' | 'downloadTable' | 'rowFiltering' | ''
export type PopupInstanceMap = {
  [key in PopupKey]: ReactElement | null
}

export type ActivePopupProps = {
  activePopup: PopupKey
  closePopup: () => void
  setRenderSVG: () => void
  setDeletePlot: (deletePlotFunc: DeletePlotFunction, removeThumbFunc: RemoveThumbnailFunction, plot: Plot, tableid: string) => void
  plot: Plot | null
  plotXyComputedData:PlotXyComputedData | null
  table: Table | null
  tablelook: Tablelook | null
  tabledata: Tabledata | null
  tableComputedData: TableComputedData | null
  history: History
  plotThumbnailURL: string
  userid: string
}

// Define a function that returns a potentially open popup (floating palette)
function OpenActivePopup(props: ActivePopupProps): JSX.Element | null {

  //console.log( 'Call to OpenActivePopup Component', props )
  const {activePopup, closePopup, setRenderSVG, setDeletePlot, plot, plotXyComputedData,
    table, tablelook, history, userid} = props

  const instanceMap: PopupInstanceMap = {
    downloadPlot : <DownloadPlot
        closePopup={closePopup }
        setRenderSVG={setRenderSVG}
        plot={plot}
        plotXyComputedData={plotXyComputedData} />,
    deletePlot : table && plot &&  <DeletePlot
        closePopup={closePopup}
        history={history}
        setDeletePlot={setDeletePlot}
        table={table}
        plot={plot} />,
    downloadTable : table && <DownloadTable
        closePopup={closePopup }
        tableId={table?.id} />,
    createPlot : table && tablelook && <CreatePlot
        userid={userid}
        tableComputedData={getTableComputedData( table?.id ) as TableComputedData}
        closePopup={closePopup}
        plot={plot}/>,
    createTable : <CreateTable
        closePopup={closePopup }
    />,
    rowFiltering : null,
    '' : null
  }

  return (
      <div className={'absolutePlacementDiv_for_navColumn_popups'}
        style={{position:'absolute', top:0, left:0, width:0, height:0}} >
            {instanceMap[activePopup]}
      </div>
  )
}

type OwnProps = {
  view: ScryUIView,
}

type RouteMatchProps = {
  tableid: string
  plotid: string
}


type StateProps = {
  tableid: string,
  table: Table | null,
  activeTable: Table | null,
  activeTableid: string,
  tablelookid: string,
  tablelook: Tablelook | null,   // look of active table
  tabledataid: string,
  tabledata: Tabledata | null,
  searchTablelook: Tablelook | null,  // look of the search table

  plot: Plot | null,
  plotid: string,
  plotThumbnailURL: string,
  // plotXyComputedData: PlotXyComputedData,

  tableOwnerid: string,
  plotOwnerid: string,
  userid: string,
  // username: string,

  tableOwnerPlotIds: Array<string>,
  userPlotIds: Array<string>,
  allPlots: ResourceMap,

  // tablesSort: string,
  // tablesQuery: string,
  // tablesTags: Array<string>,

  sessionState: SessionState,
  // lastSearchQueryString: string,
}

type EnsurePlotsFunction = (tableid: string, tableOwnerid: string, plotsSort: string, offset: number, force?: boolean) => void

type DispatchProps = {
  dispatchAction: Dispatch<AnyAction>,
  dispatchAsyncAction : <T extends (dispatch: Dispatch<AnyAction>, getState: GetStateFunc) => Promise<any>>(thunk: T) => Promise<ReturnType<T>>,
  ensurePlotsList: EnsurePlotsFunction,
  ensureTablelook: (userid: string) => void,
}
//Jason / John: should we support partial props like this?
type TopLevelUIProps = RouteComponentProps<RouteMatchProps> & OwnProps & StateProps & DispatchProps

interface LocalState {
  renderSVG: boolean;
  deletePlotPopup: boolean;
  activePopup: PopupKey;
  sortedUserPlotIds: Array<string>;
  sortedTableOwnerPlotIds: Array<string>;
  lastTableid: string;
  plotid: string;
  deletePlotFunc: DeletePlotFunction | null;
  removeThumbFunc: RemoveThumbnailFunction | null;
  tableComputedData: TableComputedData | null;
  plotXyComputedData: PlotXyComputedData | null;
  lastProps: Object;
}

//class TopLevelUIRender extends Component<TopLevelUIProps, LocalState> {
const TopLevelUIRender: React.FC<TopLevelUIProps> = (props: TopLevelUIProps) => {

  const [state, setState] = useState<LocalState>({
    renderSVG: false,
    deletePlotPopup: false,
    activePopup: '',
    sortedUserPlotIds: [],
    sortedTableOwnerPlotIds: [],
    lastTableid: '',
    plotid: '',
    deletePlotFunc: null,
    removeThumbFunc: null,
    tableComputedData: null,
    plotXyComputedData: null,
    lastProps: {}
  });

  const {tableid, tableOwnerid, userid, dispatchAction, dispatchAsyncAction, ensurePlotsList,
         view, tabledata, tablelook, tablelookid, table, userPlotIds, allPlots, tableOwnerPlotIds,
         plotid, plotOwnerid, plot, history} = props


  useEffect(() => {
    const stateChanges: Partial<LocalState> = {};
    if (plotid && state.deletePlotPopup === true) {
      stateChanges.activePopup = '';
      stateChanges.deletePlotPopup = false;
      plot && state.deletePlotFunc?.(plot);
      state.removeThumbFunc?.(plotid);
      stateChanges.plotid = undefined;
      history.replace(`/table/${tableid}`);
    }
  }, [plotid, history, tableid, plot, state])

  useEffect(() => {
    if (tableid && tableid !== state.lastTableid) {
      dispatchAction(updateSession({ 'currentTableid': tableid }));
      sessionStateChangeDispatch( [{newVal:-1, path: 'activeStatsColKey'}], 'Hide Stats' )

      if (view !== 'searchView') {
        sessionStateChangeDispatch([{ newVal: tableid, path: 'activeTableid' }], 'TopLevelUIRender');
        ensurePlotsList(tableid, userid, PLOTS_SORT, 0)
      }

      sessionStateChangeDispatch([{ newVal: tableid, path: 'currentTableid' }], 'TopLevelUIRender');
      setState(prevState => ({ ...prevState, activePopup: '', lastTableid: tableid }));
    }
  }, [tableid, view, dispatchAction, state.lastTableid, ensurePlotsList, userid]);

  useEffect(() => {
    const stateChanges: Partial<LocalState> = {};
    const sessionStateMods: LightweightMod[] = []

    if (plotid && plotid !== state.plotid) {
      dispatchAsyncAction(ensurePlotResourcesThunk(plotid));
      ensurePlotsList(tableid, userid, PLOTS_SORT, 0)
      stateChanges.plotid = plotid;
    }

    if (!(view === 'tableView' || view === 'searchView' || view === 'xyPlotView')) {
      setState(state => ({ ...state, ...stateChanges }));
      return;
    } else if (!table || !tablelook || !tabledata || (view === 'xyPlotView' && !plot)) {
      setState(state => ({ ...state, ...stateChanges }));
      return;
    }

    const updatedUserPlotIds = userPlotIds.filter(value => allPlots.hasOwnProperty(value));
    function sortPlotIds(plotIds: string[], recentPlotsArray: string[] = []) {
      return plotIds.slice().sort((a, b) => {
        const aIndex = recentPlotsArray.indexOf(a);
        const bIndex = recentPlotsArray.indexOf(b);
        if (aIndex === -1 && bIndex === -1) {
          return 0;
        } else if (aIndex === -1) {
          return 1;
        } else if (bIndex === -1) {
          return -1;
        } else {
          return aIndex - bIndex;
        }
      });
    }
    const sortedUserPlotIds = sortPlotIds(updatedUserPlotIds, tablelook?.attributes.recentPlots);
    const sortedTableOwnerPlotIds = sortPlotIds(tableOwnerPlotIds, tablelook?.attributes.recentPlots);

    if (!isEqual(state.sortedUserPlotIds, sortedUserPlotIds)) {
      stateChanges.sortedUserPlotIds = sortedUserPlotIds;
    }
    if (!isEqual(state.sortedTableOwnerPlotIds, sortedTableOwnerPlotIds)) {
      stateChanges.sortedTableOwnerPlotIds = sortedTableOwnerPlotIds;
    }

    if (sessionStateMods.length > 0) {
      sessionStateChangeDispatch(sessionStateMods, 'TopLevelUIRender');
    }
    setState(prevState => ({ ...prevState, ...stateChanges }));
  }, [plotid, tableid, tableOwnerPlotIds, view, userid,
    table, tablelook, tabledata, plot, userPlotIds, allPlots,
    state.deletePlotPopup, state.lastTableid, state.plotid, state.sortedUserPlotIds, state.sortedTableOwnerPlotIds,
    dispatchAsyncAction, dispatchAction, ensurePlotsList]);


  const closeBothPlotOrTable_HighlightedFloatingPalette = () :void => {
    if ( props.plot?.attributes.minorState.isEditorOpen ) {
      let mods = [
                    {newVal:{name:'', seriesKey:-1}, path: 'attributes.minorState.selection'},
                    {newVal:false, path: 'attributes.minorState.isEditorOpen'},
                  ]
      //console.log( 'Setting seriesKey in close floating palette' )
      reactMinorDispatch( mods, 'plots', props.plot.id )
    }
    // close Potentially Open Table Editors
    if ( props.tablelook?.attributes.minorState.isEditorOpen ) {
      let mods =[
                  {newVal:{name:'', colIndex:-1, rowIndex:-1}, path: 'attributes.minorState.selection'},
                  {newVal:false, path: 'attributes.minorState.isEditorOpen'},
                ]
      reactMinorDispatch( mods, 'tablelooks', props.tablelook.id )
    }
  }

  const closePopup= () :void => {
    // This handler is sent down to the views (Table or Plots)
    // Such that the child can display a popup, and have the
    // option to close any popup owned by TopLevelUI.
    setState({...state, activePopup: ''})
  }

  const setActivePopup = ( popupStrg: string ):void => {
    // Makes a state change to potentiall table or plot component
    // to close any floating palette editors they may have open.
    //console.log( `close popup; open${popupStrg}` )
    closeBothPlotOrTable_HighlightedFloatingPalette()
    setState({...state, activePopup: popupStrg as PopupKey})
  }

  const handleRowFilteringPopup = () : void => {
    // This case differs from setActivePopup( ) function because:
    //     setActivePopup() is used for those popups owned and rendered by TopLevelUI/NavColumn.
    //     rowFiltering popup is owned by tablelook's minorState and rendered by ViewTable
    if (!tablelook) return
    const currentMinorStateSelectionName = (tablelook) ? tablelook.attributes.minorState.selection.name : ''
    setState({...state, activePopup: '' }) // Closes potentially open NavColumn popups
    if ( currentMinorStateSelectionName === 'rowFiltering' ) {
      // RowFiltering already displayed. Toggle popup to 'closed'
      let mods =[
                  {newVal: {name:'', colIndex:-1, rowIndex:-1}, path: 'attributes.minorState.selection' },
                  {newVal:false, path: 'attributes.minorState.isEditorOpen'},
                ]
      reactMinorDispatch( mods, 'tablelooks', tablelook.id )
    } else {
      // Open rowFiltering popup
      let mods =[
                  {newVal: {name:'rowFiltering', colIndex:-1, rowIndex:-1}, path: 'attributes.minorState.selection'},
                  {newVal:true, path: 'attributes.minorState.isEditorOpen'},
                ]
      reactMinorDispatch( mods, 'tablelooks', tablelook.id )
    }
  }

  // Handler to initiate a save Plot (svg)
  const setRenderSVG = () => {
    if (state.renderSVG) { return }
    if ( !wasSuspendPointerEventsSuccessful( 'Save SVG') ) {
      return
    }
    setState({...state, renderSVG: true})
  }

  const saveSvgFinishedCallback = () => {
    restorePointerEvents('Save SVG')
    setState({...state, renderSVG : false, activePopup : '' })
  }

  // Handler to delete a Plot
  const setDeletePlot = (deletePlotFunc: DeletePlotFunction, removeThumbFunc: RemoveThumbnailFunction, plot: Plot, tableid: string) => {
    if (state.deletePlotPopup) { return }
    if ( !wasSuspendPointerEventsSuccessful( 'Delete SVG') ) {
      return
    }
    setState(({
      ...state,
      deletePlotPopup: true,
      lastTableid: tableid,
      deletePlotFunc: deletePlotFunc,
      removeThumbFunc: removeThumbFunc
    }))

  }

  const deletePlotFinishedCallback = () => {
    restorePointerEvents('Delete SVG')
    setState({...state, deletePlotPopup : false, activePopup : '' })
  }


  //console.log( 'Call to render TopLevelUI' )

  const sessionState : SessionState = props.sessionState

  //console.log( 'Tables Parent props and state:', props, state, activePopup)
  // Safety catch if the url is not a valid UUID.
  // Currently no safety catch if UUID does not exist.
  //If the view is not valid, redirect to the search page.
  if ( view === 'tableView' && !tableid ) {
    history.replace('/search')
    return null
  }
  if ( view === 'xyPlotView'  && !plotid  ) {
    history.replace('/search')
    return null
  }

  const requiresTableResources = ( view === 'searchView' || view === 'tableView' || view === 'xyPlotView' )
  if ( DEBUG && false) {
    console.log('TopLevelUI Render view=', view, 'props=', props )
    console.log( '    userid  = ', userid ? userid : 'null' )
    console.log( '    tableid = ', tableid ? tableid : 'null' )
    console.log( '    tableOwnerid = ', tableOwnerid ? tableOwnerid : 'null' )
    console.log( '    tabledataid = ', tabledata ? tabledata?.id : 'null' )
    console.log( '    tablelookid = ', tablelook ? tablelook?.id : 'null' )
    console.log( '    plotid = ', plotid ? plotid : 'null' )
    console.log( '    plotOwnerid = ', plotOwnerid ? plotOwnerid : 'null' )
    console.log( '    activePopup = ', state.activePopup )
    console.log( '    activeTableid = ', props.activeTableid ? props.activeTableid : 'null' )
  }

  const tableResourcesAvailable = Boolean(table && tabledata && tablelook )
  const requiresPlotResources = ( view === 'xyPlotView' )
  const plotResourcesAvailable = Boolean(table && tabledata && tablelook && plot )

  // CASE of resources not available yet.  Display the Progress component
  if (  (requiresTableResources && !tableResourcesAvailable) ||
        (requiresPlotResources  && !plotResourcesAvailable )) {
    const {  tableOwnerPlotIds, userPlotIds  } = props
    return (
      <div className={'rc_TopLevelUI'}
        style= {{  position: 'relative', height: '100%', width: '100%', overflow: 'hidden'}} >

          <NavColumn
            table={null}
            plot={null}
            plotid={''}
            tableOwnerid={''}
            userid={''}
            view={'searchView'}

            setActivePopup={setActivePopup}
            closePopup={closePopup}
            tableOwnerPlotIds={tableOwnerPlotIds}
            userPlotIds={userPlotIds}
          />

          <TopMenuBar innerWidth={window.innerWidth} />

          <div className={'EmptyFiller'}
            style={{display: 'flex', flexFlow: 'column nowrap', width: '100%'}}>
                <div style={{position:'absolute', top:TOP_MENUBAR_HEIGHT, left:NAV_COLUMN_WIDTH}}>
                  <Progress progressMsg={['Downloading Table']} />
                </div>
          </div>

      </div>
    )
  }

  // CASE where resources are available:
  //   - Configure the top menu bar based on the current displayed view
  //   - initialize the reactDispatch such that shortcut is pointing to either the tablelook or plot resource
  //   - initialize the plot or table responsive state, whichever is active.
  const topBarChildren : ReactNode[] = []
  const canEditPlot = ( plot && userid === plotOwnerid )
  const canEditTable= ( userid === tableOwnerid )


  // Configure the Menu bar, based on the current displayed view:
  switch ( view ) {
    case 'tableView':
        initializeDispatch( dispatchAction, 'tablelooks', tablelookid, 'tables', tableid, plot, userid, history, tablelook )
        topBarChildren.push( <UndoRedoButtons key={'undo'} parentId={tableid} parentType={'tables'} /> )
        //topBarChildren.push( <NavTopButton key={'tab1'} onClick={openSideBarToTab0}>Style</NavTopButton > )
        topBarChildren.push( <NavTopButton key={'rowFiltering'} onClick={handleRowFilteringPopup}>Filter Rows</NavTopButton > )
        if ( canEditTable ) {
          topBarChildren.push( <EditModeSelector key={'editMode'}
                                    view={view} sessionState={sessionState} /> )
        }
        break
    case 'searchView':
        initializeDispatch( dispatchAction, 'tablelooks', tablelookid, 'tables', tableid, plot, userid, history, tablelook )
        topBarChildren.push( <UndoRedoButtons key={'undo'} parentId={SEARCH_TABLE_ID} parentType={'tables'} /> )
        //topBarChildren.push( <NavTopButton key={'tab1'} onClick={openSideBarToTab1}>Style</NavTopButton > )
        //topBarChildren.push( <NavTopButton key={'tab0'} onClick={openSideBarToTab0}>Filter Rows</NavTopButton > )
        break
    case 'xyPlotView':
        topBarChildren.push( <UndoRedoButtons key={'undo'} parentId={plotid} parentType={'plots'} /> )
        //topBarChildren.push( <NavTopButton key={'tab0'} onClick={openSideBarToTab1}>Style</NavTopButton > )
        if ( canEditPlot ) {
          topBarChildren.push( <EditModeSelector key={'editMode'}
                                    view={view} sessionState={sessionState} /> )
        }
        break
    default:
  }

  // Render the TopLevelUI
  const { activeTableid, activeTable, plotThumbnailURL } = props
  const { plotXyComputedData, tableComputedData, sortedTableOwnerPlotIds, sortedUserPlotIds, activePopup } = state
  return (
    <div className={'rc_TopLevelUI'}>
      <ErrorBoundary
        tableid={activeTableid}
        plotid={plotid}
        view={view}
        name="TopLevelUI"
        parentId={activeTableid}
        parentType='tables'
      >



          <TopMenuBar innerWidth={window.innerWidth}> {topBarChildren} </TopMenuBar>

          <NavColumn
              table={activeTable}
              plotid={plotid}
              plot={plot}
              userid={userid}
              tableOwnerid={tableOwnerid}
              view={view}
              setActivePopup={setActivePopup}
              closePopup={closePopup}
              userPlotIds={sortedUserPlotIds}
              tableOwnerPlotIds={sortedTableOwnerPlotIds}
          />

              {{
                xyPlotView :
                    <TtGetComputedDataPlotXy
                      tableid={tableid}
                      plotid={plotid}
                      shouldRenderSVG={state.renderSVG}
                      shouldDeleteSVG={state.deletePlotPopup}
                      saveSvgFinishedCallback={saveSvgFinishedCallback}
                      deleteSvgFinishedCallback={deletePlotFinishedCallback}
                      sessionState={props.sessionState}/>,
                tableView :
                    <TtGetComputedData
                      tableid={tableid}/>,
                searchView :
                    <TtGetComputedData
                      tableid={SEARCH_TABLE_ID}/>,
                homeView : <Home/>,
                sitePageView: <WhoIsDataTables/>,
              }[view]}


          {/* Nav Column Popups must appear on top of the table/plot.
              Hence must be rendered AFTER the table/plot.  */ }
          <OpenActivePopup
            userid={userid}
            history={history}
            plotThumbnailURL={plotThumbnailURL}
            activePopup={activePopup}
            closePopup={closePopup}
            setRenderSVG={setRenderSVG}
            setDeletePlot={setDeletePlot}
            plot={plot}
            plotXyComputedData={plotXyComputedData}
            table={table}
            tabledata={tabledata}
            tablelook={tablelook}
            tableComputedData={tableComputedData}
          />

      </ErrorBoundary>
    </div>
  )
}


const mapStateToProps = (state: RootState, ownProps: OwnProps & RouteComponentProps<RouteMatchProps>): StateProps => {
  const {match, view} = ownProps

  const sessionState = state.session
  var tableid = ''
  var plotid = ''               // Assumption
  var plotOwnerid = ''          // Assumption
  var plot: Plot | null = null  // Assumption
  var plotThumbnailURL = ''     // Assumption
  var activeTableid = sessionState?.activeTableid

  const userid = state.app.authUserId
  // const username = state?.api?.resources?.users?.[userid]?.attributes?.username ?? ''
  // table link will reset the activeTableid.
  if (view === 'tableView') {
    var linkValue = match.params.tableid
    if ( linkValue && isValidUuid( linkValue )) { tableid = activeTableid = linkValue}
  }
  // plot link will also reset the activeTableid!
  // Usually a plot link is from the existing activeTable.
  // But not necessarily the case if the plotid comes from
  // an external link.
  if (view === 'xyPlotView') {
    linkValue = match.params.plotid
    if ( linkValue && isValidUuid( linkValue )) {
      plotid = linkValue
      plot = state?.api?.resources?.plots?.[plotid] as Plot ?? null
      plotThumbnailURL = state?.thumbnail?.[plotid] ?? null
      plotOwnerid = plot?.relationships?.owner?.data.id ?? ''
      tableid = plot?.relationships?.table.data.id ?? ''
    }
  }
  // defaults -- Assumptions if NO tableid can be identified.
  var table: Table | null = null
  var activeTable: Table | null = null
  var tableOwnerid = ''
  var tabledataid = ''
  var tabledata: Tabledata | null = null
  var userTablelookListId = ''
  var tablelookid = ''
  var tablelook: Tablelook | null = null
  var searchTablelook: Tablelook | null = null

  var tableOwnerPlotIds = EMPTY_TABLE_OWNER_ARRAY
  var userPlotIds = EMPTY_USER_PLOT_ARRAY
  var allPlots = EMPTY_ALL_PLOTS_OBJECT

  // const tablesSort = state.app.tablesSort
  // const tablesQuery = state.app.tablesQuery
  // const tablesTags = state.app.tablesTags
  // const lastSearchQueryString = session.lastSearchQueryString


  if (view === 'searchView') {
      table = state?.api?.resources?.tables?.[SEARCH_TABLE_ID] as Table ?? null
      activeTable = state?.api?.resources?.tables?.[activeTableid] as Table ?? null
      tableOwnerid = table?.relationships?.owner?.data?.id ?? ''
      tabledata = state?.api?.resources?.tabledatas?.[SEARCH_TABLEDATA_ID] as Tabledata ?? null

      userTablelookListId = usersTablelookListId(userid, SEARCH_TABLE_ID)
      tablelookid = state?.api?.lists?.tablelooks?.[userTablelookListId]?.ids?.[0] ?? ''
      searchTablelook = state?.api?.resources?.tablelooks?.[tablelookid] as Tablelook ?? null
      tableid = SEARCH_TABLE_ID
      tabledataid = SEARCH_TABLEDATA_ID
  }

  if ( tableid ) {
      table = state?.api?.resources?.tables?.[tableid] as Table ?? null
      tableOwnerid = table ? table.relationships?.owner.data.id : ''
      tabledataid  = table ? table.relationships?.tabledata.data.id : ''
      //hasOwner = _.has(state, ['api', 'resources', 'users', tableOwnerid])
      tabledata = state?.api?.resources?.tabledatas?.[tabledataid] as Tabledata ?? null
      userTablelookListId = paramsToListId({
        'filter[table][data][id]': tableid,
        'filter[owner][data][id]': userid,
      })
      //const userTablelookListId = usersTablelookListId(userid, tableid)
      tablelookid = state?.api?.lists?.tablelooks?.[userTablelookListId]?.ids?.[0] ?? ''
      tablelook = state?.api?.resources?.tablelooks?.[tablelookid] as Tablelook ?? null
  }
  if (view !== 'searchView' && table) {
    activeTable = table
  }

  if ( tableid && table ) {
      const tableOwnerPlotsListId = plotsForTableListId(tableid, tableOwnerid, PLOTS_SORT)
      const userPlotsListId = userid ? plotsForTableListId(tableid, userid, PLOTS_SORT): ''
      // Return constant (same address) empty arrays, rather than null, if lists don't exist.
      tableOwnerPlotIds = state?.api?.lists?.plots?.[tableOwnerPlotsListId]?.ids ?? EMPTY_TABLE_OWNER_ARRAY
      userPlotIds = userPlotsListId ? state?.api?.lists?.plots?.[userPlotsListId]?.ids ?? EMPTY_USER_PLOT_ARRAY : EMPTY_USER_PLOT_ARRAY
      allPlots = state?.api?.resources?.plots ?? EMPTY_ALL_PLOTS_OBJECT
  }

  const temp =  {
    tableOwnerid,
    plotOwnerid,
    userid,
    // username,

    tableid,
    tablelookid,
    tabledataid,
    plotid,

    plot,
    plotThumbnailURL,
    tabledata,
    tablelook,
    table,
    activeTable,
    activeTableid,
    searchTablelook,
    // lastSearchQueryString,

    tableOwnerPlotIds,
    userPlotIds,
    allPlots,

    // tablesSort,
    // tablesQuery,
    // tablesTags,

    sessionState,
  }
  return temp
}

// const buildListId = (tablesSort: string, tablesQuery: string, tablesTags: Array<string>): string => {
//   return paramsToListId({
//     'filter[tags][data][id]': tablesTags,
//     'query': tablesQuery.split(/\s+/).join('|'),
//     'sort': tablesSort,
//   });
// }


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps => ({

  dispatchAction: function<T extends AnyAction>( action: T ): T {
    return dispatch( action )
  },

  dispatchAsyncAction: function<T extends (dispatch: Dispatch<AnyAction>, getState: GetStateFunc) => Promise<any>>( thunk: T ): Promise<ReturnType<T>> {
    return asyncDispatch(dispatch, thunk);
  },

  ensurePlotsList: (tableid: string, tableOwnerid: string, plotsSort: string, offset: number, force: boolean = false): void => {
    const listId = plotsForTableListId(tableid, tableOwnerid, plotsSort)
    asyncDispatch(dispatch, ensure.list('plots', listId, offset, 1000, force))
  },

  ensureTablelook: (userid: string): void => {
    const userListId = usersTablelookListId(userid, SEARCH_TABLE_ID)
    asyncDispatch(dispatch, ensure.list('tablelooks', userListId, 0, 1000, false))
  },

})

const TopLevelUIconnected = connect(mapStateToProps, mapDispatchToProps)(TopLevelUIRender)
const TopLevelUI = withRouter(TopLevelUIconnected)
export default TopLevelUI
