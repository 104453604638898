import { Component } from 'react'
import Main from '../sharedComponents/Main'
import NavWrapper from '../sharedComponents/NavWrapper'
import TopMenuBar from '../viewMenuBar/TopMenuBar'
import ViewWrapper from '../sharedComponents/ViewWrapper'

class UserSettings extends Component {
  render() {
    return (
      <NavWrapper>
        <ViewWrapper>
          <TopMenuBar innerWidth={window.innerWidth} />
          <Main>
            <h1 style={{ marginLeft: '16px' }}>Settings</h1>
          </Main>
        </ViewWrapper>
      </NavWrapper>
    )
  }
}

export default UserSettings
